import React, { Component } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import {
  getUrl,
  getNomePagina,
  getDadosSellerPop,
  getCodigoEstabelecimento,
  getCodigoUsuario
} from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';
import { getDadosLogin, setDadosLogin, updateTema } from '../../shared/utils/Utils';
import { empresasRequestService } from '../../services/RequestServices/EmpresasRequest.service';

const PATH_MENU = menus.COD_13130;

export class ManterConfSistema extends Component {
  
  async atualizaDadosEstabelecimento() {
    let dadosLogin = getDadosLogin();
    
    let response = await empresasRequestService.GetByCNPJ([dadosLogin.cnpj]);
    
    if (response.data[0]){
      setDadosLogin(
        dadosLogin.cnpj,
        dadosLogin.url,
        dadosLogin.codigoEstabelecimento,
        dadosLogin.logo,
        dadosLogin.logoSmall,
        dadosLogin.tipoSiteUrl,
        dadosLogin.nome,
        dadosLogin.login,
        dadosLogin.empresas,
        dadosLogin.tipoSite,
        response.data[0].urLs[0].templateTema,
        dadosLogin.share
      );
  
      updateTema();
    }
  }
  
  componentWillUnmount() {
    this.atualizaDadosEstabelecimento();
  }
  
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <div className='row'>
          <Iframe
            url={
              getUrl() + '/ManterConfSistema.aspx?' + getDadosSellerPop(PATH_MENU) + '&EstaCod=' + getCodigoEstabelecimento() 
              + '&UsReCod=' + getCodigoUsuario()
            }
            className='iframePop'
          />
        </div>
      </LinxPostos>
    );
  }
}