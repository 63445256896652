import axios from 'axios';
import {
  getToken
} from '../../../authentication/Auth';
import moment from 'moment';
import {
  BASE_URL_GATEWAY
} from '../../../shared/constants/GatewayUrlConstant';
import {
  getUrlLinxReports
} from '../../../services/core/Auth.service';

export const LGPDService = {
  find,
  getFiltros,
  getTipoFiltro,
  getTipoSolicitacao,
  getSituacao,
  getModel,
  add,
  edit,
  anonimizar,
  deletar,
  concluir,
  Relatorio,
  eliminarDados
};

const urlGateway = axios.create({
  baseURL: `${BASE_URL_GATEWAY}`,
});

urlGateway.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {
  const response = await urlGateway.post('/core/api/v1/LGPD/listarcomfiltro', {
      query: appliedFilters
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function concluir(value) {
  const response = await urlGateway.post(`/core/api/v1/LGPD/concluir?id=${value}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function getTipoFiltro() {
  return ['Titular', 'Terceiro'];
}

async function add(model) {
  const response = await urlGateway.put('/core/api/v1/LGPD', model)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function edit(model) {
  const response = await urlGateway.post('/core/api/v1/LGPD', model)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function anonimizar(value) {
  const response = await urlGateway.post(`/core/api/v1/LGPD/anonimizar?id=${value}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function eliminarDados(value) {
  const response = await urlGateway.delete(`/core/api/v1/LGPD/eliminar?id=${value}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function deletar(value) {
  const response = await urlGateway.delete(`/core/api/v1/LGPD?id=${value}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function getTipoSolicitacao() {
  const response = await urlGateway.get('/core/api/v1/LGPD/tiposolicitacao');
  return response.data.data;
}

async function getSituacao() {
  return [{
      'codigo': 0,
      'descricao': 'Pendente'
    },
    {
      'codigo': 1,
      'descricao': 'Aguardando Conclusão'
    },
    {
      'codigo': 2,
      'descricao': 'Concluída'
    },
    {
      'codigo': 3,
      'descricao': 'Excluido'
    }
  ];
}

function getFiltros() {
  return {
    dataInicio: moment().startOf('month'),
    dataFim: moment().endOf('month'),
    tipoFiltro: null,
    tipoSolicitacao: [],
    situacao: [],
    filtro: ''
  }
}

function getModel() {
  return {
    tipoSolicitacao: [],
    impresso: false,
    email: false,
    emailResposta: '',
    observacao: '',
    nomeRequerente: '',
    documentoRequerente: '',
    identificaoVerificada: false,
    nomeTitular: '',
    documentoTitular: '',
    dataConclusao: '',
    tipoRequerente: 'Titular',
    dataSolicitacao: new Date()
  }
}

async function Relatorio(requisicao) {
  const response = await getUrlLinxReports()
    .get(`lgpd?id=${requisicao}`, {
      responseType: 'blob'
    })
    .then(response => {
      const file = new Blob([response.data], {
        type: 'application/pdf'
      });

      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch(error => {
      throw error;
    });
  return response;
}