import axios from 'axios';
import { TOKEN_PASS_AXIOS } from '../shared/constants/Const';
import { getCnpj, getUrl } from '../shared/utils/Utils';
import { BASE_URL_GATEWAY } from '../shared/constants/GatewayUrlConstant';
import { getToken } from '../authentication/Auth';
import { AxiosErrorService } from '../services/core/axiosError.service';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
 });

 urlLinxCore.interceptors.request.use(async config => {
   const token = getToken();
   if (token) {
     config.headers.Authorization = `Bearer ${token}`;
   }
   return config;
 });

export async function  getCentrosMonetariosCadastradosManualmente() {
  try {
    const result = await urlLinxCore.get(`CentroMonetario/ListarCadastradosManualmente`);
    const responseData = result.data.data || [];

    if(responseData.length === 0)
      return responseData;

    const centroMonetarios = responseData.map(centro => ({
      id: centro.id,
      nome: centro.nome,
      outrasCobrancas: centro.outrasCobrancas
    }));

    return centroMonetarios;

  } catch (error) {
    await new AxiosErrorService().execute(error);
  }
}

export function getCentrosMonetarios() {
  return axios.post(`LxApi/v1/CentroMonetario/Listar`, {
    query: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getCentrosMonetariosBoleto() {
  return axios.post(`LxApi/v1/CentroMonetario/Listar`, {
    cnpjEstabelecimento: getCnpj(),
  });
}
export function incluirCentroMonetario(codigoCentroMonetario, codigoBanco, agencia, digitoAgencia, conta, digitoConta, descricao, empresa, inativo, restrito) {
  return axios.post(`LxApi/v1/CentroMonetario/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    codigoCentroMonetario,
    codigoBanco,
    agencia,
    digitoAgencia,
    conta,
    digitoConta,
    descricao,
    empresa,
    inativo,
    restrito,
  });
}

export function alterarCentroMonetario(
  codigoCentroMonetario,
  codigoBanco,
  agencia,
  digitoAgencia,
  conta,
  digitoConta,
  descricao,
  inativo,
  restrito,
  rowVersion,
) {
  return axios.put(`LxApi/v1/CentroMonetario/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigoCentroMonetario,
    codigoBanco,
    agencia,
    digitoAgencia,
    conta,
    digitoConta,
    descricao,
    inativo,
    restrito,
    rowVersion,
  });
}

export function excluirCentroMonetario(codigoCentroMonetario) {
  return axios.delete(`LxApi/v1/CentroMonetario/Excluir`, {
    params: { codigoCentroMonetario, cnpjEstabelecimento: getCnpj() },
  });
}
