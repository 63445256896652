import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';

export const quitacaoService = {
  getTiposDataQuitacaoLote,
  realizarQuitacaoLote,
  consultaLotesQuitacao,
  realizarCancelamentoQuitacaoLote
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function getTiposDataQuitacaoLote() {
  const response = await urlLinxCore.get('/financeiro/TiposDataQuitacaoLote');
  return response.data.data;
}

async function realizarQuitacaoLote(body) {
  const response = await urlLinxCore
    .post('/financeiro/RealizarQuitacaoLote', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function realizarCancelamentoQuitacaoLote(body) {
  const response = await urlLinxCore
    .post('/financeiro/RealizarEstornoLote', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function consultaLotesQuitacao(codigosMovimentoQuitacao) {
  const response = await urlLinxCore.post('/financeiro/LoteQuitacao', codigosMovimentoQuitacao )
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}
 
