import { CharToInt } from './utils';
export function CheckIEPE(ie) {
  if (ie.length !== 14) return false;
  var nro = new Array(14);
  for (var i = 0; i <= 13; i++) nro[i] = CharToInt(ie.charAt(i));
  var b = 5;
  var soma = 0;
  for (i = 0; i <= 12; i++) {
    soma += nro[i] * b;
    b--;
    if (b === 0) b = 9;
  }
  var dig = 11 - (soma % 11);
  if (dig > 9) dig = dig - 10;
  return dig === nro[13];
} //pe
