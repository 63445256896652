import React from 'react';
import styles from './ListToolTip.module.scss'

export default function ListToolTip(props) {
    return (props.data && props.data.length ?
        <div className={`${styles.pill} ${styles.disabled}`}>
            <span>
                <i></i>
                {`${props.message}: ${props.data.length}`}
            </span>
        </div>
        :
        <>
        </>
    );
}