import { DataGrid, Column, Column as ButtonGrid } from 'devextreme-react/data-grid';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { InputCurrency } from '../../../../../../components/inputCurrency/inputCurrency';
import { formatStringToCurrency } from '../../../../../../shared/utils/Utils';
import AlterarPagamentoPedido from '../../../alterar-pagamentos-pedidos/AlterarPagamentoPedido';
import CustomToggle from './CustomToggle';
import { ModalPagamentoAnalitico } from './ModalPagamentoAnalitico';
import { useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { normalizeToBoolean } from '../../../../../../shared/utils/Utils';

const TurnoViewItem = props => {
  const { turno, item, idx, onChange, onBusy, onUnBusy } = props;
  const [openAccordion, setOpenAccordion] = useState(null);

  const [valorConferido, setValorConferido] = useState(0);
  const [currentItem, setCurrentItem] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [paramUtilizaCreditoParcelado, setParamUtilizaCreditoParcelado] = useState(null);
  const parametro604 = useSelector(selectConsignadoComodatoSharedData).parametros604;

  useEffect(() => {
    if (paramUtilizaCreditoParcelado !== null) return;
    if (!parametro604) return;
    setParamUtilizaCreditoParcelado(normalizeToBoolean(parametro604.valor));
  }, [paramUtilizaCreditoParcelado, parametro604]);

  useEffect(() => {
    if (!isEqual(currentItem, item)) setCurrentItem({ ...item });
  }, [item]);

  useEffect(() => {
    if (currentItem && currentItem.valorConferido !== valorConferido) setValorConferido(currentItem.valorConferido);
  }, [currentItem, valorConferido]);

  const handleEditPagamento = data => {
    setCurrentData(data);
    setModalOpen(!modalOpen);
  };

  const handleCloseEditPagamento = () => {
    setModalOpen(false);
  };

  const handleAfterSavePagamento = async turnoId => {
    if (props.afterSavePagamento) {
      await props.afterSavePagamento(turnoId);
      handleCloseEditPagamento();
    }
  };

  const handlerConferenciaChange = useCallback(
    e => {
      setValorConferido(e.data);
      const newValue = { ...currentItem, valorConferido: e.data };
      setCurrentItem(newValue);
      if (onChange) onChange(newValue);
    },
    [currentItem, onChange],
  );

  if (!currentItem) return null;

  return (
    <>
      <Card>
        <Card.Body>
          <Row style={{ marginRight: '32px' }}>
            <Col col={2}>
              <small></small>
              <br />
              <strong>{currentItem.descricaoFormaPagamento}</strong>
            </Col>
            <Col col={4}>
              <small>Recebimento</small>
              <br />
              <strong>{formatStringToCurrency(currentItem.valorRecebido || 0)}</strong>
            </Col>
            <Col col={2}>
              <small>Tesouraria</small>
              <InputCurrency
                onChange={(_, money) => {
                  handlerConferenciaChange({ data: parseFloat(money || '0') });
                }}
                value={valorConferido || 0}
                className="form-control text-right"
                disabled={turno.turnoConferido}
                name={`valorConferencia_${idx}`}
                placeholder="R$ 0,00"
              />
              <span className="d-print-block d-none">{formatStringToCurrency(valorConferido || 0)}</span>
            </Col>
            <Col col={2}>
              <small>Diferenca</small>
              <br />
              <strong>
                {formatStringToCurrency(
                  (currentItem.valorRecebido || 0) * -1 - (currentItem.valorSuprido || 0) + (currentItem.valorSangrado || 0) + (currentItem.valorConferido || 0),
                )}
              </strong>
              {[-7, -4].includes(currentItem.codigoFormaPagamento) && <ModalPagamentoAnalitico currentItem={currentItem} turno={turno}></ModalPagamentoAnalitico>}
            </Col>
          </Row>
        </Card.Body>
        <Accordion.Collapse eventKey={idx}>
          <Card.Body style={{ marginRight: '48px' }}>
            <DataGrid
              dataSource={(turno.pagamentos || []).filter(x => x.codigoFormaPagamento === currentItem.codigoFormaPagamento)}
              showBorders={true}
            >
              <Column dataField="numeroPedido" width={120} caption="Nº Pedido"></Column>
              <Column dataField="numeroParcela" width={120} caption="Nº Parcela"></Column>
              <Column dataField="tipoPedido.nome" width={200} caption="Tipo"></Column>
              <Column dataField="dataHora" dataType="date" caption="Data e Hora" width={200} format={'dd/MM/yyyy HH:mm'}></Column>
              <Column dataField="dataVencimento" dataType="date" caption="Data Vencimento" width={200} format={'dd/MM/yyyy'}></Column>
              <Column dataField="nomeCliente" caption="Cliente" minWidth={200}></Column>
              <Column dataField="valor" caption={'Total'} format="R$ #,##0.00" />
              <Column
                caption="Ações"
                type="buttons"
                buttons={[
                  {
                    hint: 'Editar os pagamentos deste pedido',
                    icon: 'edit',
                    disabled: e => turno.turnoConferido || (e.row.data.parcelas && e.row.data.parcelas.length > 0),
                    onClick: e => handleEditPagamento(e.row.data),
                  },
                ]}
              />
            </DataGrid>
          </Card.Body>
        </Accordion.Collapse>
        <CustomToggle eventKey={idx} openAccordion={openAccordion} setOpenAccordion={setOpenAccordion}>
          &nbsp;<i className={`fas ${openAccordion === idx ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
        </CustomToggle>
      </Card>
      {modalOpen && (
        <AlterarPagamentoPedido
          data={currentData}
          turno={turno}
          onBusy={onBusy}
          onUnBusy={onUnBusy}
          onClose={handleCloseEditPagamento}
          afterSavePagamento={handleAfterSavePagamento}
        />
      )}
    </>
  );
};

export default TurnoViewItem;
