import React, { useState, useEffect } from 'react';
import moment from 'moment';
import styles from './MovimentacaoEncerrantes.module.scss';
import { movimentacaoEncerrantesSservice } from './MovimentacaoEncerrantes.service'
import ContentLoader from 'react-content-loader';
import LinxInfoPill from '../../../components/linx/InfoPill/InfoPill';
import { getErrorMessage } from '../../../shared/utils/Utils';
import notify from 'devextreme/ui/notify';
import { DataGrid, Pager, SearchPanel, Grouping, GroupPanel, Column, ColumnChooser, Export, Paging, ColumnFixing, Sorting, Summary, TotalItem } from 'devextreme-react/data-grid';

export const MovimentacaoEncerrantes = props => {

    const [searching, setSearching] = useState(false);
    const [encerrantesPorTurno, setEncerrantesPorTurno] = useState([]); 
    const [encerrantesTotais, setEncerrantesTotais] = useState([]);

    const getMovimentacaoEncerrantesPorTurno = async () => {
        setSearching(true);
        
        const response = await movimentacaoEncerrantesSservice.getMovimentacaoEncerrantesPorTurno(props.dataInicioTurno);
        
        if (response.notificationLevel === 1) {
            if(response.data.length){
                setEncerrantesPorTurno(agrupaPorTurnoMacro(response.data));
                setEncerrantesTotais(agrupaPorCombustivel(response.data));
            } else {
                notify('Não foram encontrados encerrantes para esta pesquisa.', 'info', 5000);
            }
        } else {
            notify(getErrorMessage(response), 'error', 5000);
        }
        setSearching(false);
    };

    const agrupaPorTurnoMacro = (result) => {
        let retorno = [];
        
        let group = result.reduce((r, a) => {
            r[a.turnoMacro] = [...r[a.turnoMacro] || [], a];
            return r;
        }, {});

        for(let prop in group) {
            retorno.push(group[prop]);
        }

        return retorno;
    };

    const agrupaPorCombustivel = (result) => {
        let retorno = [];
        
        let group = result.reduce((r, a) => {
            r[a.combustivel] = [...r[a.combustivel] || [], a];
            return r;
        }, {});

        for(let prop in group) {
            retorno.push(
               {
                combustivel: prop,
                totalLitros: somaPropriedades(group, prop, 'totalLitros'), 
                afericao: somaPropriedades(group, prop, 'afericao'), 
                totalLitrosVenda: somaPropriedades(group, prop, 'totalLitrosVenda'), 
                totalRs: somaPropriedades(group, prop, 'totalRs'), 
                totalLitrosDocFiscal: somaPropriedades(group, prop, 'totalLitrosDocFiscal'), 
                totalRsDocFiscal: somaPropriedades(group, prop, 'totalRsDocFiscal'), 
                diferencaLitros: somaPropriedades(group, prop, 'diferencaLitros'), 
                diferencaRs: somaPropriedades(group, prop, 'diferencaRs')
               }
            );
        }

        return retorno;
    };

    const somaPropriedades = (arr, prop, filter) => {
        return arr[prop].reduce((r, a) => (r + a[filter]), 0)
    };

    const negativeCellRender = (data) => {
        return (
            data.value < 0 ?
            <div className={`${styles.negativeCellText}`}>{data.text}</div>
            : 
            data.text
        )
    };
    
    useEffect(() => {
        getMovimentacaoEncerrantesPorTurno();
    }, [])

    return (
        <div className={`${styles.movimentacaoEncerrantes} movimentacaoEncerrantes`}>
            {!searching && encerrantesPorTurno.length > 0 &&
                <>
                {encerrantesPorTurno.map(itemTurnoMacro => (
                    <div className={`${styles.results} shadow-sm p-3 bg-white`} key={itemTurnoMacro[0].turnoMacro}>
                        <LinxInfoPill
                            info={`
                                Turno ${itemTurnoMacro[0].numeroTurno} - ${moment(itemTurnoMacro[0].dataMovimento).format('DD/MM/YYYY') 
                                + ' Iniciado as '
                                + moment(itemTurnoMacro[0].horaInicio).format('hh:mm')}
                            `}
                        />
                        <DataGrid dataSource={itemTurnoMacro} showBorders={true} allowColumnResizing={true}
                            allowColumnReordering={false} columnResizingMode={'widget'} columnMinWidth={50} rowAlternationEnabled={true}>
                            <Sorting mode="multiple" />
                            <GroupPanel visible={false} />
                            <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                            <Export enabled={false} fileName="Encerrantes" />
                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                            <Grouping autoExpandAll={true} />
                            <ColumnFixing enabled={false} />
                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
                            <Paging defaultPageSize={20} />
                            <Column dataField="numeroTanque" caption="Tanque" width="60" />
                            <Column dataField="codigoBico" caption="Código Bico" width="60" visible={false}/>
                            <Column dataField="numeroBico" caption="Bico" width="50"/>
                            <Column dataField="combustivel" caption="Combustível" width="160"/>
                            <Column dataField="abertura" caption="Abertura" format={{ type: 'fixedPoint', precision: 2 }} />
                            <Column dataField="fechamento" caption="Fechamento" format={{ type: 'fixedPoint', precision: 2 }}/>
                            <Column dataField="totalLitros" caption="Total litros" format={{ type: 'fixedPoint', precision: 2 }}/>
                            <Column dataField="afericao" caption= "Aferição" format={{ type: 'fixedPoint', precision: 2 }} />
                            <Column dataField="totalLitrosVenda" caption= "Total litros venda" format={{ type: 'fixedPoint', precision: 2 }} cellRender={negativeCellRender} />
                            <Column dataField="totalRs" caption="Total R$" format={{ type: 'currency', precision: 3 }} cellRender={negativeCellRender} />
                            <Column dataField="preco" caption="Preço" format={{ type: 'currency', precision: 3 }} />
                            <Column dataField="totalLitrosDocFiscal" caption="Total litros Doc. Fiscal" format={{ type: 'fixedPoint', precision: 3 }}/>
                            <Column dataField="totalRsDocFiscal" caption= "Total R$ Doc. Fiscal" format={{ type: 'currency', precision: 3 }} />
                            <Column dataField="diferencaLitros" caption="Diferença litros" format={{ type: 'fixedPoint', precision: 3 }} cellRender={negativeCellRender} />
                            <Column dataField="diferencaRs" caption="Diferença R$" format={{ type: 'currency', precision: 3 }} cellRender={negativeCellRender} />
                            <Summary>
                                <TotalItem column="totalLitros" summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 3}} />
                                <TotalItem column="afericao" summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2}} />
                                <TotalItem column="totalLitrosVenda" summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2}} />
                                <TotalItem column="totalRs" summaryType="sum" valueFormat={{ type: 'currency', precision: 3}} />
                                <TotalItem column="totalLitrosDocFiscal" summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 3}} />
                                <TotalItem column="totalRsDocFiscal" summaryType="sum" valueFormat={{ type: 'currency', precision: 3}} />
                                <TotalItem column="diferencaLitros" summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 3}} />
                                <TotalItem column="diferencaRs" summaryType="sum" valueFormat={{ type: 'currency', precision: 3}} />
                            </Summary>
                        </DataGrid>
                    </div>
                ))}

                <div className={`${styles.results} shadow-sm bg-white p-3`}>
                    <h2>TOTAIS DO DIA</h2>
                    <DataGrid dataSource={encerrantesTotais} showBorders={true} allowColumnResizing={true}
                        allowColumnReordering={false} columnResizingMode={'widget'} columnMinWidth={50} rowAlternationEnabled={true}>
                        <Sorting mode="multiple" />
                        <GroupPanel visible={false} />
                        <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                        <Export enabled={true} fileName="EncerrantesTotais" />
                        <SearchPanel visible={true} highlightCaseSensitive={true} />
                        <Grouping autoExpandAll={true} />
                        <ColumnFixing enabled={false} />
                        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
                        <Paging defaultPageSize={20} />
                        <Column dataField="combustivel" caption="Combustível" />
                        <Column dataField="totalLitros" caption="Total litros" format={{ type: 'decimal', precision: 2 }}/>
                        <Column dataField="afericao" caption= "Aferição" format={{ type: 'decimal', precision: 2 }}/>
                        <Column dataField="totalLitrosVenda" caption= "Total litros venda" format={{ type: 'decimal', precision: 2 }} cellRender={negativeCellRender}/>
                        <Column dataField="totalRs" caption="Total R$" format={{ type: 'currency', precision: 2 }} cellRender={negativeCellRender} />
                        <Column dataField="totalLitrosDocFiscal" caption="Total litros Doc. Fiscal" format={{ type: 'decimal', precision: 2 }}/>
                        <Column dataField="totalRsDocFiscal" caption= "Total R$ Doc. Fiscal" format={{ type: 'currency', precision: 2 }} />
                        <Column dataField="diferencaLitros" caption="Diferença litros" format={{ type: 'decimal', precision: 2 }} cellRender={negativeCellRender} />
                        <Column dataField="diferencaRs" caption="Diferença R$" format={{ type: 'currency', precision: 2 }} cellRender={negativeCellRender} />
                        <Summary>
                            <TotalItem column="totalLitros" summaryType="sum" valueFormat={{ type: 'decimal', precision: 2}} />
                            <TotalItem column="afericao" summaryType="sum" valueFormat={{ type: 'decimal', precision: 2}} />
                            <TotalItem column="totalLitrosVenda" summaryType="sum" valueFormat={{ type: 'decimal', precision: 2}} />
                            <TotalItem column="totalRs" summaryType="sum" valueFormat={{ type: 'currency', precision: 2}} />
                            <TotalItem column="totalLitrosDocFiscal" summaryType="sum" valueFormat={{ type: 'decimal', precision: 2}} />
                            <TotalItem column="totalRsDocFiscal" summaryType="sum" valueFormat={{ type: 'currency', precision: 2}} />
                            <TotalItem column="diferencaLitros" summaryType="sum" valueFormat={{ type: 'decimal', precision: 2}} />
                            <TotalItem column="diferencaRs" summaryType="sum" valueFormat={{ type: 'currency', precision: 2}} />
                        </Summary>
                    </DataGrid>
                </div>
                </>
            }
            {!searching && encerrantesPorTurno.length === 0 &&
                <div className="shadow-sm bg-white p-3 text-center">
                    <h3>Sem registros para exibir</h3>
                </div>
            }
            {searching && 
                <div className="shadow-sm p-3 bg-white">
                    <ContentLoader
                        speed={1}
                        width={800}
                        height={300}
                        viewBox="0 0 800 300"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#dbdbdb"
                    >
                        <rect x="30" y="10" rx="3" ry="3" width="200" height="10" />
                        <rect x="30" y="40" rx="3" ry="3" width="800" height="15" />
                        <rect x="30" y="80" rx="3" ry="3" width="800" height="15" />
                        <rect x="30" y="120" rx="3" ry="3" width="800" height="15" />
                        <rect x="30" y="160" rx="3" ry="3" width="800" height="15" />
                        <rect x="30" y="200" rx="3" ry="3" width="800" height="15" />
                        <rect x="30" y="240" rx="3" ry="3" width="800" height="15" />
                    </ContentLoader>
                </div>
            }
        </div>
    );
};