import React, { Component } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import {
  getUrl,
  getNomePagina,
  getDadosSellerPop
} from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';

//mantiveram o mesmo código de menu
const PATH_MENU = menus.COD_22100;
const PATH_MENU_MATRIZ = menus.COD_22100;

export class ProdutosAjustePreco extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU_MATRIZ)}>
        <div className='row'>
          <Iframe
            referrerPolicy="unsafe-url"
            url={
              getUrl() + '/Seller8/telas/ProdutosAjustePreco.aspx?consolidado=false&' + getDadosSellerPop(PATH_MENU_MATRIZ, true)
            }
            className='iframePop'
          />
        </div>
      </LinxPostos>
    );
  }
}

export class MatrizProdutosAjustePreco extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU_MATRIZ)}>
        <div className='row'>
          <Iframe
            referrerPolicy="unsafe-url"
            url={
              getUrl() + '/Seller8/telas/ProdutosAjustePreco.aspx?consolidado=true&' + getDadosSellerPop(PATH_MENU_MATRIZ, true)
            }
            className='iframePop'
          />
        </div>
      </LinxPostos>
    );
  }
}
