import React, { Component } from 'react';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';

import {
  validaCPF,
  validaCNPJ,
  validaCpfOrCnpj,
  maskedCnpjCpf,
  maskCpfCnpj,
  isValidIE,
  isValidEmail,
  menuPermiteInclusao,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina,
  toFloatFormattedDisplay,
  getWeekDays,
  getQuinzenas,
  validaDadosLogin,
  sameInt,
  sameFloat,
  getCnpj,
  getDezenas
} from '../../../shared/utils/Utils';
import Botao from '../../../components/botao/Botao';
import SectionHeader from '../../../components/section/Header';
import moment from 'moment';

import {
  InputText,
  Checkbox,
  Checkitem,
  InputDate,
  Select,
  handleInputChange,
  makeInputUpperCase
} from '../../../components/formulario/Formulario';
import List from '../../../components/formulario/List';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  hideWait,
  showWait,
  tagAlert,
  showSaveError,
  showDeleteError,
  showInfo,
  hideAlert,
  showError,
  showQuestion
} from '../../../components/alert/Alert';

import { withRouter } from 'react-router-dom';

import {
  incluiPessoa,
  alteraPessoa,
  excluiPessoa,
  getPessoas,
  verifyPessoa,
  getCategorias,
  montaComboTiposPrazos,
  getPessoa
} from './Pessoas.service';

import {
  getEstados,
  getCidades,
  getCep
} from '../../../services/Localidades.service';
import {
  ALPHANUMERIC_PATTERN,
  FAKE_COD
} from '../../../shared/constants/Const';
import ReactTable from 'react-table';
import {
  changeInList,
  deleteInList,
  appendInList
} from '../../../shared/utils/TableUtils';
import { verifyFuncionario } from '../../../services/Funcionarios.service';

import { menus } from '../../../shared/constants/MenuConstants';
import Footer from '../../../components/core/footer/Footer';
import { getBombas } from '../tanques-bombas-bicos/bombas/Bombas.service';
import { getConfiguracaoGeral } from '../../configuracoes/configuracoes-gerais/ConfiguracoesGerais.service';
import {
  Params,
  FormaCalculoDataVencimento
} from '../../configuracoes/configuracoes-gerais/ConfiguracoesGerais';
import notify from 'devextreme/ui/notify';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';

// parece ser um bug, no options do Select se tiver um item com value: null dá uma série de erros
export const none = '';

const titulo = 'Pessoas';

export const TipoPessoa = {
  None: none,
  Fisica: 0,
  Juridica: 1
};

// enumerator somente com as classificações contempladas nesse cadastro
export const Classificacao = {
  Cliente: 1,
  Fornecedor: 2,
  Transportadora: 4,
  Fabricante: 5
};

// enumerator somente com as categorias ,"fixas", utilizadas em condições
export const Categoria = {
  DistribuidorProduto: 0, // classificação "fornecedor"
  DistribuidorCombustivel: 1, // classificação "fornecedor"
  FabricanteProduto: 4, // classificação "fabricante"
  ProdutorRural: 7, // classificação "cliente"
  Contador: 2 // classificação "fornecedor"
};

export const TiposPrazos = {
  None: none,
  Semanal: 1,
  Quinzenal: 2,
  Mensal: 3,
  PrazoFixo: 4,
  Dezenal: 5
};

const PATH_MENU = menus.COD_60500;

function apiToState(api) {
  const result = { ...api };

  const classificacoes = result.classificacoes;
  delete result.classificacoes; // deleta o array classificacoes
  result.isCliente = false;
  result.categoriaCliente = none;
  result.isFornecedor = false;
  result.categoriaFornecedor = none;
  result.crc = null;
  result.nomeContador = null;
  result.isFabricante = false;
  result.categoriaFabricante = none;
  result.isTransportadora = null;
  classificacoes.forEach(c => {
    switch (c.codigo) {
      case Classificacao.Cliente:
        result.isCliente = true;
        if (c.categoria) result.categoriaCliente = c.categoria.codigo;
        break;
      case Classificacao.Fornecedor:
        result.isFornecedor = true;
        if (c.categoria) {
          result.categoriaFornecedor = c.categoria.codigo
            ? c.categoria.codigo
            : FAKE_COD;
          result.crc = c.categoria.crc;
          result.nomeContador = c.categoria.nomeContador;
        }
        break;
      case Classificacao.Fabricante:
        result.isFabricante = true;
        if (c.categoria) result.categoriaFabricante = c.categoria.codigo;
        break;
      case Classificacao.Transportadora:
        result.isTransportadora = true;
        break;
      default:
        break;
    }
  });
  result.tipo = result.tipo.codigo; // transforma tipo.codigo em tipo
  result.ativo = !result.inativo;
  delete result.inativo;
  result.cep = result.endereco.cep;
  result.codigoUF = result.endereco.codigoUF;
  result.codigoMunicipio = result.endereco.codigoMunicipio;
  result.numero = result.endereco.numero;
  result.complemento = result.endereco.complemento;
  result.referencia = result.endereco.referencia;
  result.bairro = result.endereco.bairro;
  result.endereco = result.endereco.endereco;
  if (result.contatos) {
    result.contatoNome = result.contatos[0].nome;
    result.contatoDescricao = result.contatos[0].descricao;
    result.contatoDDD = result.contatos[0].ddd;
    result.contatoNumero = result.contatos[0].numero;
    result.contatoEMail = result.contatos[0].email;
  } else {
    result.contatoNome = null;
    result.contatoDescricao = null;
    result.contatoDDD = null;
    result.contatoNumero = null;
    result.contatoEMail = null;
  }
  delete result.contatos;
  return result;
}

export class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      codigo = null,
      cnpjCpf = null,
      inscricaoEstadual = null,
      inscricaoMunicipal = null,

      nome = null,
      apelido = null,
      isentoICMS = null,
      contribuinte = null,
      dataNascimento = null,
      identidade = null,
      orgaoEmissor = null,
      emiteNFe = null,
      ativo = true,
      tipo = null,

      isCliente = false,
      categoriaCliente = none,

      isFornecedor = false,
      categoriaFornecedor = none,
      crc = null,
      nomeContador = null,

      isFabricante = false,
      categoriaFabricante = none,

      isTransportadora = false,

      cep = null,
      codigoUF = null,
      codigoMunicipio = null,
      endereco = null,
      numero = null,
      complemento = null,
      referencia = null,
      bairro = null,

      contatoNome = null,
      contatoDescricao = null,
      contatoDDD = null,
      contatoNumero = null,
      contatoEMail = null,

      site = null,

      excluido = false,
      dataExclusao = null,
      rowVersion = null,

      utilizaFatura = false,
      obrigaAutorizado = false,
      tipoPrazo = null,
      diasFechamento = null,
      saldoDisponivel = null,
      creditoUtilizado = null,
      limiteCredito = null,
      carencia = null,
      clienteBloqueado = false,
      clienteEmitirNFe = false,
      clienteEmailFaturamento = false
    } = this.props.pessoa;

    this.state = {
      codigo,
      cnpjCpf,
      inscricaoEstadual,
      inscricaoMunicipal,

      nome,
      apelido,
      isentoICMS,
      contribuinte,
      dataNascimento,
      identidade,
      orgaoEmissor,
      emiteNFe,
      ativo,
      tipo,

      isCliente,
      categoriaCliente,

      isFornecedor,
      categoriaFornecedor,
      categoriaFornecedorOriginal: categoriaFornecedor,
      crc,
      nomeContador,

      isFabricante,
      categoriaFabricante,

      isTransportadora,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      contatoEMail,

      site,

      excluido,
      dataExclusao,
      rowVersion,

      listaDiasFechamento: [],
      listaTiposPrazos: [],
      utilizaFatura,
      obrigaAutorizado,
      tipoPrazo,
      diasFechamento,
      saldoDisponivel,
      creditoUtilizado,
      limiteCredito,
      carencia,
      clienteBloqueado,
      clienteEmitirNFe,
      clienteEmailFaturamento,
      autorizados: [
        {
          codigo: null,
          placa: '',
          nome: '',
          documento: '',
          setor: ''
        }
      ],

      categoriasClientes: [],
      categoriasFornecedores: [],
      categoriasFabricantes: [],
      estados: [],
      cidades: [],
      formaCalculoDataGeracao: false
    };
  }

  async componentDidMount() {
    function mapCombo(data, showNone, showCode) {
      const combo = data.map(item => {
        return {
          value: item.codigo ? item.codigo : FAKE_COD,
          label: item.descricao + `${showCode ? ' (' + item.codigo + ')' : ''}`
        };
      });
      if (showNone) {
        combo.unshift({ value: none, label: showNone });
      }
      return combo;
    }

    async function montaComboCategorias(classificacao, showNone) {
      const { data } = await getCategorias(classificacao);
      return mapCombo(data, showNone, false);
    }

    async function buscaPessoa(codigo) {
      const { data } = await getPessoa(codigo);
      if (data) {
        return data;
      }
    }
    const { data: dataTiposPrazos } = await montaComboTiposPrazos();
    const listaTiposPrazos = [];
    listaTiposPrazos.push({ label: 'Selecione o tipo de prazo', value: '' });
    dataTiposPrazos.forEach(item => {
      listaTiposPrazos.push({ label: item.descricao, value: item.codigo });
    });

    this.setState({
      categoriasClientes: await montaComboCategorias(
        Classificacao.Cliente,
        'Selecione um tipo de cliente'
      ),
      categoriasFornecedores: await montaComboCategorias(
        Classificacao.Fornecedor,
        'Selecione um tipo de fornecedor'
      ),
      categoriasFabricantes: await montaComboCategorias(
        Classificacao.Fabricante,
        'Selecione um tipo de fabricante'
      ),
      estados: [],
      cidades: [],
      //listaTiposPrazos: montaComboTiposPrazos()
      listaTiposPrazos
    });

    if (this.state.codigo) {
      var pessoaResult = await buscaPessoa(this.state.codigo);
      var creditoFaturamento = pessoaResult.creditoFaturamento;

      if (creditoFaturamento)
        this.setState({
          utilizaFatura: creditoFaturamento.utilizaFatura,
          obrigaAutorizado: creditoFaturamento.obrigaAutorizado,
          tipoPrazo: creditoFaturamento.tipoPrazo,
          diasFechamento: creditoFaturamento.diasFechamento,
          limiteCredito: creditoFaturamento.limiteCredito,
          creditoUtilizado: creditoFaturamento.creditoUtilizado,
          saldoDisponivel: creditoFaturamento.saldoDisponivel,
          carencia: creditoFaturamento.carenciaDiaVencimento,
          clienteBloqueado: creditoFaturamento.bloqueado,
          clienteEmitirNFe: creditoFaturamento.emitirNFe,
          clienteEmailFaturamento: creditoFaturamento.enviarEmailFatura,
          autorizados: pessoaResult.autorizados
            ? pessoaResult.autorizados
            : this.state.autorizados
        });
    }

    this.updateEstados();
    this.updateCidades(this.state.codigoUF);
    this.loadDiasFechamento(this.state.tipoPrazo);

    this.verificaFormaCalculoDiaVencimento();
  }

  verificaFormaCalculoDiaVencimento = async () => {
    const { data: resultValorFormaCalculo } = await getConfiguracaoGeral(
      Params.FormaCalculoDataVencimentoFatura
    );

    let valorFormaCalculo = resultValorFormaCalculo.data
      ? resultValorFormaCalculo.data.valor
      : 1;
    let formaCalculoDataGeracao = sameInt(
      valorFormaCalculo,
      FormaCalculoDataVencimento.DataGeracao
    );
    this.setState({
      formaCalculoDataGeracao
    });
  };

  backToScreen = () => {
    hideAlert(this);
    this.props.history.push('/cadastro/pessoas');
  };

  validateData = () => {
    let erro = null;
    if (
      !this.state.isCliente &&
      !this.state.isFornecedor &&
      !this.state.isFabricante &&
      !this.state.isTransportadora
    )
      erro = {
        field: 'isCliente',
        message:
          'Pelo menos uma das seguintes opções deve estar marcada: cliente, fornecedor, fabricante ou transportadora.'
      };
    else if (this.state.isFornecedor && this.state.categoriaFornecedor === none)
      erro = {
        field: 'categoriaFornecedor',
        message:
          'Quando a pessoa é um fornecedor, o tipo de fornecedor deve ser informado.'
      };
    else if (this.state.isFornecedor && this.isPF() && this.state.categoriaFornecedor === 6)
      erro = {
        field: 'categoriaFornecedor',
        message:
          'Quando a pessoa é um fornecedor e Pessoa física, não pode ser selecionado Rede Administradora/Bandeira Cartão.'
      };
    else if (this.state.isFabricante && this.state.categoriaFabricante === none)
      erro = {
        field: 'categoriaFabricante',
        message:
          'Quando a pessoa é um fabricante, o tipo de fabricante deve ser informado.'
      };
    else if (!this.state.nome)
      erro = {
        field: 'nome',
        message: `${this.isPF()
            ? 'O nome da pessoa deve ser informado.'
            : 'O nome da empresa deve ser informado.'
          }`
      };
    else if (moment(this.state.dataNascimento).isAfter(moment())) {
      erro = {
        field: 'dataNascimento',
        message: `A data de ${this.isPF() ? 'nascimento' : 'fundação'
          }, quando informada, não pode ser superior a data atual.`
      };
    } // TODO: Coloquei a validação aqui porque no onBlur não funcionou no InputDate
    else if (this.isPF() && !this.state.identidade)
      erro = {
        field: 'identidade',
        message: 'Para pessoa física, a identidade deve ser informada.'
      };
    else if (this.needIE() && !this.state.inscricaoEstadual) {
      if (this.isPJ() || (this.isPF() && this.isRural()))
        erro = {
          field: 'inscricaoEstadual',
          message: `${this.isPJ()
              ? 'A inscrição estadual da empresa deve ser informada.'
              : 'Para produtor rural, a inscrição estadual da pessoa deve ser informada.'
            }`
        };
    } else if (this.isContador() && !this.state.nomeContador)
      erro = {
        field: 'nomeContador',
        message:
          'Quando a pessoa é um fornecedor e o seu tipo é contador, o nome do contador deve ser informado.'
      };
    else if (this.isContador() && !this.state.crc)
      erro = {
        field: 'crc',
        message:
          'Quando a pessoa é um fornecedor e o seu tipo é contador, o CRC do contador deve ser informado.'
      };
    else if (!this.state.cep)
      erro = {
        field: 'cep',
        message: 'O CEP deve ser informado.'
      };
    else if (!this.state.endereco)
      erro = {
        field: 'endereco',
        message: 'O endereço (rua, av, logradouro) deve ser informado.'
      };
    else if (!this.state.bairro)
      erro = {
        field: 'bairro',
        message: 'O bairro deve ser informado.'
      };
    else if (!this.state.codigoUF)
      erro = {
        field: 'codigoUF',
        message: 'A unidade federativa (uf, estado) deve ser informada.'
      };
    else if (!this.state.codigoMunicipio)
      erro = {
        field: 'codigoMunicipio',
        message: 'A cidade deve ser informada.'
      };
    else if (this.state.contatoEMail && !isValidEmail(this.state.contatoEMail))
      erro = {
        field: 'contatoEMail',
        message: 'O email "' + this.state.contatoEMail + '" não é válido.'
      };
    // TODO: Coloquei a validação aqui porque no onBlur entra em loop showError com focus
    else if (
      this.needIE() &&
      !isValidIE(this.state.inscricaoEstadual, this.state.codigoUF)
    ) {
      erro = {
        field: '',
        message:
          'A inscrição estadual "' +
          this.state.inscricaoEstadual +
          '" não é válida.'
      };
    } else if (
      this.state.limiteCredito &&
      (this.state.limiteCredito < 1 || this.state.limiteCredito > 999999999.0)
    ) {
      erro = {
        field: 'limiteCredito',
        message: 'O limite de crédito deve ser entre 1,00 e 999.999.999,00.'
      };
    } else if (
      parseInt(this.state.tipoPrazo) === TiposPrazos.Mensal &&
      (this.state.diasFechamento < 1 || this.state.diasFechamento > 31)
    ) {
      erro = {
        field: 'diasFechamento',
        message:
          'O tipo de prazo Mensal permite dias de fechamento entre 1 e 31'
      };
    }
    else if (this.state.obrigaAutorizado) {
      if (this.trataAutorizados().length === 0) {
        erro = {
          field: 'obrigaAutorizado',
          message:
            'Deve ser informado ao menos um autorizado.'
        };
      }
    }


    if (erro) {
      showError(this, titulo, erro.message, erro.field);
      return false;
    } else return true;
  };

  trataAutorizados = () => {
    const { autorizados } = this.state;
    if (autorizados && autorizados.length) {
      return autorizados.filter(a => {
        return a.placa || a.nome;
      });
    }
    return [];
  };

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    let {
      codigo,
      cnpjCpf,
      inscricaoEstadual,
      inscricaoMunicipal,

      nome,
      apelido,
      isentoICMS,
      contribuinte,
      dataNascimento,
      identidade,
      orgaoEmissor,
      emiteNFe,
      ativo,
      tipo,

      isCliente,
      categoriaCliente,

      isFornecedor,
      categoriaFornecedor,
      categoriaFornecedorOriginal,
      crc,
      nomeContador,

      isFabricante,
      categoriaFabricante,

      isTransportadora,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      contatoEMail,

      site,

      rowVersion,

      utilizaFatura,
      obrigaAutorizado,
      tipoPrazo,
      diasFechamento,
      saldoDisponivel,
      creditoUtilizado,
      limiteCredito,
      carencia,
      clienteBloqueado,
      clienteEmitirNFe,
      clienteEmailFaturamento
    } = this.state;
    categoriaFornecedor = sameInt(categoriaFornecedor, FAKE_COD)
      ? 0
      : categoriaFornecedor;
    const autorizadosToSend = this.trataAutorizados();
    if (sameInt(tipoPrazo, TiposPrazos.PrazoFixo)) {
      diasFechamento = diasFechamento ? diasFechamento : 1;
      carencia = carencia ? carencia : 1;
    }
    // eslint-disable-next-line
    const [action, params] = this.isInserting()
      ? [
        incluiPessoa,
        [
          cnpjCpf,
          inscricaoEstadual,
          inscricaoMunicipal,

          nome,
          apelido,
          isentoICMS,
          contribuinte,
          dataNascimento,
          identidade,
          orgaoEmissor,
          emiteNFe,
          ativo,
          tipo,

          isCliente,
          categoriaCliente,

          isFornecedor,
          categoriaFornecedor,
          crc,
          nomeContador,

          isFabricante,
          categoriaFabricante,

          isTransportadora,

          cep,
          codigoUF,
          codigoMunicipio,
          endereco,
          numero,
          complemento,
          referencia,
          bairro,

          contatoNome,
          contatoDescricao,
          contatoDDD,
          contatoNumero,
          contatoEMail,

          site,

          utilizaFatura,
          obrigaAutorizado,
          tipoPrazo,
          diasFechamento,
          carencia,
          limiteCredito,
          clienteEmitirNFe,
          clienteEmailFaturamento,
          clienteBloqueado,
          saldoDisponivel,
          creditoUtilizado,
          autorizadosToSend
        ]
      ]
      : [
        alteraPessoa,
        [
          codigo,

          cnpjCpf,
          inscricaoEstadual,
          inscricaoMunicipal,

          nome,
          apelido,
          isentoICMS,
          contribuinte,
          dataNascimento,
          identidade,
          orgaoEmissor,
          emiteNFe,
          ativo,
          tipo,

          isCliente,
          categoriaCliente,

          isFornecedor,
          categoriaFornecedor,
          crc,
          nomeContador,

          isFabricante,
          categoriaFabricante,

          isTransportadora,

          cep,
          codigoUF,
          codigoMunicipio,
          endereco,
          numero,
          complemento,
          referencia,
          bairro,

          contatoNome,
          contatoDescricao,
          contatoDDD,
          contatoNumero,
          contatoEMail,

          site,

          utilizaFatura,
          obrigaAutorizado,
          tipoPrazo,
          diasFechamento,
          carencia,
          limiteCredito,
          clienteEmitirNFe,
          clienteEmailFaturamento,
          clienteBloqueado,
          saldoDisponivel,
          creditoUtilizado,
          autorizadosToSend,
          rowVersion
        ]
      ];
    const doSave = async () => {
      try {
        showWait(this, titulo, 'Por favor, aguarde enquanto a pessoa é salva.');
        await action(...params);
        hideWait(this);
        showInfo(
          this,
          titulo,
          `Pessoa ${this.isInserting()
            ? 'cadastrada'
            : this.isRestoring()
              ? 'restaurada'
              : 'alterada'
          } com sucesso!`,
          null,
          this.backToScreen
        );
      } catch (error) {
        showSaveError(this, titulo, error);
      }
    };

    const confirmSaveMethod = async resp => {
      if (resp) {
        await doSave();
      } else {
        hideAlert(this);
      }
    };

    if (this.validateData()) {
      try {
        if (this.wasContador()) {
          if (!ativo) {
            showQuestion(
              this,
              titulo,
              'Se o contador for inativado, o sistema irá remover o vínculo ' +
              'da empresa com ele.\nDeseja continuar?',
              null,
              confirmSaveMethod
            );
          } else if (!isFornecedor) {
            showQuestion(
              this,
              titulo,
              'Se o contador não estiver mais cadastrado como fornecedor, ' +
              'o sistema irá remover o vínculo da empresa com ele.' +
              '\nDeseja continuar?',
              null,
              confirmSaveMethod
            );
          } else if (categoriaFornecedor !== categoriaFornecedorOriginal) {
            showQuestion(
              this,
              titulo,
              'Se o contador tiver seu tipo de fornecedor alterado, ' +
              'o sistema irá remover o vínculo da empresa com ele.' +
              '\nDeseja continuar?',
              null,
              confirmSaveMethod
            );
          } else {
            await doSave();
          }
        } else {
          await doSave();
        }
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
  };

  handleExcluir = async () => {
    let podeExcluir = true;
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    if (this.state.isFabricante)
      podeExcluir = await this.podeDesativar(
        'Não é possível a exclusão porque '
      );

    const doDelete = async () => {
      try {

        const response = await excluiPessoa(this.state.codigo);

        if (response.notificationLevel === 1) {
          showInfo(
            this,
            titulo,
            'Pessoa excluída com sucesso!',
            null,
            this.backToScreen
          );
        } else {
          // showDeleteError(this, titulo, response.message);
          hideAlert(this);
          notify(response.message, 'error', 2000, 'top');
        }
      } catch (err) {
        showDeleteError(this, titulo, err);
      }
    };
    if (this.state.creditoUtilizado > 0) {
      podeExcluir = false;
      showError(
        this,
        titulo,
        'Não é possível a exclusão do cliente com crédito utilizado.'
      );
    }

    if (podeExcluir) {
      showQuestion(
        this,
        titulo,
        'Deseja realmente excluir essa pessoa?',
        null,
        async resp => {
          if (resp) {
            if (this.wasContador()) {
              showQuestion(
                this,
                titulo,
                'Se o contador for excluído, o sistema irá remover o ' +
                'vínculo da empresa com ele\nDeseja continuar?',
                null,
                async resp => {
                  if (resp) {
                    await doDelete();
                  } else {
                    hideAlert(this);
                  }
                }
              );
            } else {
              await doDelete();
            }
          } else {
            hideAlert(this);
          }
        }
      );
    }
  };

  handleCancelar = async () => {
    if (this.isVerifying()) {
      this.backToScreen();
    } else {
      showQuestion(
        this,
        titulo,
        'Deseja realmente cancelar a operação?',
        null,
        resp => {
          if (resp) {
            this.backToScreen();
          } else {
            hideAlert(this);
          }
        }
      );
    }
  };

  makeCombo = (list, showCode, showNull) => {
    let combo = list.map(item => {
      return {
        value: item.codigo,
        label: item.descricao + `${showCode ? ' (' + item.codigo + ')' : ''}`
      };
    });
    if (showNull) {
      combo.unshift({ value: none, label: showNull });
    }
    return combo;
  };

  updateEstados = async () => {
    const { data: listaEstados } = await getEstados(this.state.codigoUF);
    this.setState({
      estados: this.makeCombo(listaEstados, false, 'Selecione um estado')
    });
  };

  updateCidades = async estado => {
    if (!estado) {
      this.setState({
        cidades: [{ value: none, label: 'Selecione um estado primeiro' }]
      });
    } else {
      const { data: listaCidades } = await getCidades(estado);
      this.setState({
        cidades: this.makeCombo(listaCidades, false, 'Selecione uma cidade')
      });
    }
  };

  changeEstado = event => {
    const codigoUF = Number(event.target.value);
    this.setState({ codigoUF, codigoMunicipio: null, cidades: [] });
    this.updateCidades(codigoUF);
  };

  searchCep = async () => {
    const { data: dadosCep } = await getCep(this.state.cep);
    if (dadosCep && dadosCep.value) {
      const cep = dadosCep.value;
      let endereco =
        (cep.TIPO_LOGRADOURO ? String(cep.TIPO_LOGRADOURO).trim() + ' ' : '') +
        (!cep.OBS
          ? String(cep.LOGRADOURO).trim()
          : String(
            cep.LOGRADOURO.substring(0, cep.LOGRADOURO.indexOf(cep.OBS))
          ).trim());

      this.updateCidades(cep.ID_UF);
      this.setState({
        codigoUF: cep.ID_UF,
        endereco,
        bairro: cep.BAIRRO,
        codigoMunicipio: cep.COD_MUNICIPIO_IBGE
      });
    } else {
      showError(
        this,
        titulo,
        'Cep "' + this.state.cep + '" não encontrado.',
        'cep'
      );
    }
  };

  doRestoreData = response => {
    const {
      codigo,
      cnpjCpf,
      inscricaoEstadual,
      inscricaoMunicipal,
      nome,
      apelido,
      isentoICMS,
      contribuinte,
      dataNascimento,
      identidade,
      orgaoEmissor,
      emiteNFe,
      ativo,
      tipo,

      isCliente,
      categoriaCliente,

      isFornecedor,
      categoriaFornecedor,
      crc,
      nomeContador,

      isFabricante,
      categoriaFabricante,

      isTransportadora,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      contatoEMail,

      site,

      excluido,
      dataExclusao,
      rowVersion,

      utilizaFatura,
      obrigaAutorizado,
      tipoPrazo,
      diasFechamento,
      saldoDisponivel,
      creditoUtilizado,
      limiteCredito,
      carencia,
      clienteBloqueado,
      clienteEmitirNFe,
      clienteEmailFaturamento
    } = apiToState(response);

    this.setState({
      codigo,
      cnpjCpf,
      inscricaoEstadual,
      inscricaoMunicipal,
      nome,
      apelido,
      isentoICMS,
      contribuinte,
      dataNascimento,
      identidade,
      orgaoEmissor,
      emiteNFe,
      ativo,
      tipo,

      isCliente,
      categoriaCliente,

      isFornecedor,
      categoriaFornecedor,
      categoriaFornecedorOriginal: categoriaFornecedor,
      crc,
      nomeContador,

      isFabricante,
      categoriaFabricante,

      isTransportadora,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      contatoEMail,

      site,

      excluido,
      dataExclusao,
      rowVersion,

      utilizaFatura,
      obrigaAutorizado,
      tipoPrazo,
      diasFechamento,
      saldoDisponivel,
      creditoUtilizado,
      limiteCredito,
      carencia,
      clienteBloqueado,
      clienteEmitirNFe,
      clienteEmailFaturamento
    });
  };

  loadFromFunc = f => {
    if (f) {
      let contatoNome,
        contatoDescricao,
        contatoDDD,
        contatoNumero,
        contatoEMail = null;
      if (f.contatos && f.contatos.length > 0) {
        contatoNome = f.contatos[0].nome;
        contatoDescricao = f.contatos[0].descricao;
        contatoDDD = f.contatos[0].ddd;
        contatoNumero = f.contatos[0].numero;
        contatoEMail = f.contatos[0].email;
      }
      this.setState({
        tipo: TipoPessoa.Fisica,
        isentoICMS: true,
        Contribuinte: false,
        cnpjCpf: f.cpf,
        nome: f.nome,
        apelido: f.apelido,
        dataNascimento: f.dataNascimento
          ? moment(f.dataNascimento)
          : this.state.dataNascimento,
        identidade: f.identidade,
        orgaoEmissor: f.orgaoEmissor,
        cep: f.endereco.cep,
        codigoUF: f.endereco.codigoUF,
        codigoMunicipio: f.endereco.codigoMunicipio,
        numero: f.endereco.numero,
        complemento: f.endereco.complemento,
        referencia: f.endereco.referencia,
        bairro: f.endereco.bairro,
        endereco: f.endereco.endereco,
        contatoNome,
        contatoDescricao,
        contatoDDD,
        contatoNumero,
        contatoEMail
      });
    }
  };

  cnpjCpfIconeClick = async () => {
    const { cnpjCpf } = this.state;

    try {
      const { data: response } = await verifyPessoa(cnpjCpf);
      if (!!response.excluido) {
        showQuestion(
          this,
          titulo,
          `A pessoa com o ${validaCPF(cnpjCpf) ? 'CPF' : 'CNPJ'
          } "${maskedCnpjCpf(cnpjCpf)}
                    " foi excluída do sistema. Deseja restaurá-la?`,
          null,
          resp => {
            if (resp) {
              hideAlert(this);
              this.doRestoreData(response);
            } else {
              hideAlert(this);
            }
          }
        );
      } else {
        showError(
          this,
          titulo,
          `A pessoa com o ${validaCPF(cnpjCpf) ? 'CPF' : 'CNPJ'
          } "${maskedCnpjCpf(cnpjCpf)}
                    " já está cadastrada!`,
          'cnpjCpf'
        );
      }
    } catch (e) {
      if (validaCPF(cnpjCpf)) {
        const { data: responseF } = await verifyFuncionario(cnpjCpf);
        if (responseF) {
          if (responseF.excluido) {
            this.setState({
              tipo: TipoPessoa.Fisica,
              isentoICMS: true,
              contribuinte: false,
            });
          } else {
            showQuestion(
              this,
              titulo,
              'O CPF ' +
              maskedCnpjCpf(cnpjCpf) +
              ' ' +
              'já está cadastrado para o funcionário ' +
              responseF.nome +
              '.' +
              'Deseja visualizar os dados?',
              null,
              resp => {
                if (resp) {
                  hideAlert(this);
                  responseF.rowVersion = null;
                  this.loadFromFunc(responseF);
                  this.updateCidades(responseF.endereco.codigoUF);
                } else {
                  hideAlert(this);
                }
              }
            );
          }
        } else {
          this.setState({
            tipo: TipoPessoa.Fisica,
            isentoICMS: true,
            contribuinte: false,
          });
        }
      } else {
        this.setState({
          tipo: TipoPessoa.Juridica,
          isentoICMS: false,
          contribuinte: true,
        });
      }
    }
  };

  loadDiasFechamento = tipoPrazo => {
    let lista = [];
    switch (Number(tipoPrazo)) {
      case TiposPrazos.Semanal:
        lista = getWeekDays();
        this.setState({
          listaDiasFechamento: lista,
          diasFechamento: !this.state.diasFechamento
            ? lista[0].value
            : this.state.diasFechamento
        });
        break;
      case TiposPrazos.Quinzenal:
        lista = getQuinzenas();
        this.setState({
          listaDiasFechamento: lista,
          diasFechamento: !this.state.diasFechamento
            ? lista[0].value
            : this.state.diasFechamento
        });
        break;
      case TiposPrazos.Dezenal:
        lista = getDezenas();
        this.setState({
          listaDiasFechamento: lista,
          diasFechamento: !this.state.diasFechamento
            ? lista[0].value
            : this.state.diasFechamento
        });
        break;
      default:
        this.setState({
          listaDiasFechamento: [
            {
              label: 'Selecione primeiro um tipo de prazo',
              value: ''
            }
          ]
        });
        break;
    }
  };

  isVerifying = () => {
    return this.state.tipo === null;
  }; // true se clicou no botão "+" e está digitando campo(s) chave
  isEditing = () => {
    return !this.isVerifying() && !!this.state.rowVersion;
  }; // (!! para garantir false ) true se  clicou em um item da list
  isInserting = () => {
    return !this.isVerifying() && !this.state.rowVersion;
  }; // true se clicou no botão "+" e já digitou campo(s)
  isRestoring = () => {
    return !this.isVerifying() && !!this.state.excluido;
  };
  isPJ = () => {
    return this.state.tipo === TipoPessoa.Juridica;
  };
  isPF = () => {
    return this.state.tipo === TipoPessoa.Fisica;
  };
  isRural = () => {
    return (
      !this.isVerifying() &&
      this.state.isCliente &&
      Number(this.state.categoriaCliente) === Categoria.ProdutorRural
    );
  };
  isContador = () => {
    return (
      !this.isVerifying() &&
      this.state.isFornecedor &&
      Number(this.state.categoriaFornecedor) === Categoria.Contador
    );
  };

  wasContador = () => {
    // para saber se originalmente era contador
    return sameInt(this.state.categoriaFornecedorOriginal, Categoria.Contador);
  };

  needIE = () => {
    return !this.state.isentoICMS;
  };

  podeDesativar = async (message, field) => {
    const { data: bombas } = await getBombas(getCnpj(), false);
    const { codigo } = this.state;
    let result = true;

    bombas.result.forEach(b => {
      if (sameInt(codigo, b.codigoFabricante)) {
        showError(
          this,
          titulo,
          message + 'existe bomba vinculada ao fabricante',
          field
        );
        result = false;
        return;
      }
    });
    return result;
  };

  isNomeValid = nome => {
    const regex = /[<>&"'“”‘’]/;
    return !regex.test(nome);
  };

  render() {
    const {
      codigo,
      cnpjCpf,
      inscricaoEstadual,
      inscricaoMunicipal,

      nome,
      apelido,
      isentoICMS,
      contribuinte,
      dataNascimento,
      identidade,
      orgaoEmissor,
      emiteNFe,
      ativo,
      tipo,

      isCliente,
      categoriaCliente,

      isFornecedor,
      categoriaFornecedor,
      crc,
      nomeContador,

      isFabricante,
      categoriaFabricante,

      isTransportadora,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      contatoEMail,

      site,
      excluido,
      // dataExclusao,
      // rowVersion,

      categoriasClientes,
      categoriasFornecedores,
      categoriasFabricantes,

      utilizaFatura,
      obrigaAutorizado,
      tipoPrazo,
      diasFechamento,
      saldoDisponivel,
      creditoUtilizado,
      limiteCredito,
      carencia,
      clienteBloqueado,
      clienteEmitirNFe,
      clienteEmailFaturamento,
      formaCalculoDataGeracao
    } = this.state;

    function ifTipo(Fisica, Juridica, Ambos) {
      switch (tipo) {
        case TipoPessoa.Fisica:
          return Fisica;
        case TipoPessoa.Juridica:
          return Juridica;
        default:
          return Ambos;
      }
    }

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent
              title={`${this.isVerifying()
                  ? 'Dados básicos para a inclusão de uma nova pessoa'
                  : ifTipo(
                    'Dados cadastrais da pessoa física',
                    'Dados cadastrais da pessoa jurídica',
                    'Dados cadastrais da pessoa'
                  )
                }`}
            >
              <div className={ifShow(false, 'row')}>
                <div className="col-md-1">
                  <InputText
                    disabled
                    value={apelido ? apelido : ''}
                    name="apelido"
                    label={
                      'debug> ' +
                      ' | identidade: ' +
                      identidade +
                      ' | orgaoEmissor: ' +
                      orgaoEmissor +
                      ' | isentoICMS: ' +
                      isentoICMS +
                      ' | isCliente: ' +
                      isCliente +
                      ' | categoriaCliente: ' +
                      categoriaCliente +
                      ' | isFornecedor: ' +
                      isFornecedor +
                      ' | categoriaFornecedor: ' +
                      categoriaFornecedor +
                      ' | codigo: ' +
                      codigo +
                      ' | excluido: ' +
                      excluido +
                      ' | isVerifying(): ' +
                      this.isVerifying() +
                      ' | isRestoring(): ' +
                      this.isRestoring() +
                      ' | isEditing(): ' +
                      this.isEditing() +
                      ' | isInserting(): ' +
                      this.isInserting() +
                      ' | isPF(): ' +
                      this.isPF() +
                      ' | isPJ(): ' +
                      this.isPJ() +
                      ' | isRural(): ' +
                      this.isRural() +
                      ' | isContador(): ' +
                      this.isContador() +
                      ' | row: ' +
                      this.state.rowVersion +
                      ' | isCli:' +
                      this.state.isCliente +
                      ' | catCli: ' +
                      this.state.categoriaCliente +
                      ' | r: ' +
                      Categoria.ProdutorRural +
                      ' | '
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <InputText
                    value={cnpjCpf}
                    name="cnpjCpf"
                    label={ifTipo('CPF:', 'CNPJ:', 'CPF/CNPJ:')}
                    type="number"
                    icone={`${this.isVerifying() && validaCpfOrCnpj(cnpjCpf)
                        ? 'icon-lx-search'
                        : ''
                      }`}
                    onIconeClick={this.cnpjCpfIconeClick}
                    onKeyDown={event => {
                      if (event.keyCode === 13 && validaCpfOrCnpj(cnpjCpf))
                        this.cnpjCpfIconeClick();
                      else if (event.keyCode === 27)
                        this.setState({ cnpjCpf: '' });
                    }}
                    format={maskCpfCnpj(cnpjCpf)}
                    onChange={this.handleInputChange}
                    disabled={!this.isVerifying()}
                    help={`${this.isVerifying()
                        ? String(cnpjCpf).length === 11
                          ? validaCPF(cnpjCpf)
                            ? 'Clique na "lupa" para verificar CPF'
                            : 'Não é um CPF válido'
                          : String(cnpjCpf).length === 14
                            ? validaCNPJ(cnpjCpf)
                              ? 'Clique na "lupa" para verificar CNPJ'
                              : 'Não é um CNPJ válido'
                            : 'CPF/CNPJ (somente números)'
                        : ''
                      }`}
                  />
                </div>
                <div className={ifShow(!this.isVerifying(), 'col-md-3')}>
                  <Select
                    value={tipo}
                    name="tipo"
                    label={'Tipo da pessoa:'}
                    options={[
                      //{ value: none, label: 'Não definida' },
                      { value: 0, label: 'Pessoa física' },
                      { value: 1, label: 'Pessoa jurídica' }
                    ]}
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isEditing(), 'col-md-3')}>
                  <InputText
                    value={codigo}
                    name="codigo"
                    label="Código:"
                    onChange={this.handleInputChange}
                    disabled
                  />
                </div>
                <div className={ifShow(this.isEditing(), 'col-md-3')}>
                  <Checkbox label="Situação:">
                    <Checkitem
                      checked={ativo}
                      name="ativo"
                      label="Ativo"
                      onChange={async event => {
                        const ativo = event.target.checked;
                        if (isFabricante) {
                          if (
                            await this.podeDesativar(
                              'Não é possível a inativação porque ',
                              'ativo'
                            )
                          ) {
                            this.setState({
                              ativo
                            });
                          }
                        } else
                          this.setState({
                            ativo
                          });
                      }}
                      disabled={this.isInserting()}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-md-3">
                  <Checkbox>
                    <Checkitem
                      checked={isCliente}
                      name="isCliente"
                      label="Cliente"
                      //onChange={this.handleInputChange}
                      onChange={event => {
                        const isentoICMS = this.isPF()
                          ? true
                          : this.state.isentoICMS;
                        this.setState({
                          isCliente: event.target.checked,
                          categoriaCliente: none,
                          isentoICMS
                        });
                      }}
                    />
                  </Checkbox>
                </div>
                <div className="col-md-3">
                  <Checkbox>
                    <Checkitem
                      checked={isFornecedor}
                      name="isFornecedor"
                      label="Fornecedor"
                      // onChange={this.handleInputChange}
                      onChange={event => {
                        this.setState({
                          isFornecedor: event.target.checked,
                          categoriaFornecedor: none
                        });
                      }}
                    />
                  </Checkbox>
                </div>
                <div className="col-md-3">
                  <Checkbox>
                    <Checkitem
                      checked={isFabricante}
                      name="isFabricante"
                      label="Fabricante"
                      // onChange={this.handleInputChange}
                      onChange={event => {
                        this.setState({
                          isFabricante: event.target.checked,
                          categoriaFabricante: none
                        });
                      }}
                    />
                  </Checkbox>
                </div>
                <div className="col-md-3">
                  <Checkbox>
                    <Checkitem
                      checked={isTransportadora}
                      name="isTransportadora"
                      label="Transportadora"
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>

              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className={ifShow(this.isPF() && isCliente, 'col-md-3')}>
                  <Select
                    value={categoriaCliente}
                    name="categoriaCliente"
                    label="Tipo do cliente:"
                    options={categoriasClientes}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(isFornecedor, 'col-md-3')}>
                  <Select
                    value={categoriaFornecedor}
                    name="categoriaFornecedor"
                    label="Tipo do fornecedor:"
                    options={categoriasFornecedores}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(isFabricante, 'col-md-3')}>
                  <Select
                    value={categoriaFabricante}
                    name="categoriaFabricante"
                    label="Tipo do fabricante:"
                    options={categoriasFabricantes}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-md-6">
                  <InputText
                    value={nome}
                    name="nome"
                    label={ifTipo(
                      'Nome:',
                      'Razão social:',
                      'Nome/Razão social:'
                    )}
                    maxlength={65}
                    help="Obrigatório"
                    onChange={event => {
                      const value = event.target.value;
                      if (this.isNomeValid(value)) {
                        this.setState({ nome: value });
                      }
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <InputText
                    value={apelido}
                    name="apelido"
                    label={ifTipo(
                      'Apelido:',
                      'Nome fantasia:',
                      'Apelido/Nome fantasia:'
                    )}
                    maxlength={30}
                    onChange={event => {
                      const value = event.target.value;
                      if (this.isNomeValid(value)) {
                        this.setState({ apelido: value });
                      }
                    }}
                  />
                </div>
                <div className="col-md-3">
                  <InputDate
                    value={dataNascimento}
                    name="dataNascimento"
                    label={ifTipo(
                      'Data de nascimento:',
                      'Data de fundação:',
                      'Data de nascimento/fundação:'
                    )}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className={ifShow(this.isPF(), 'col-md-3')}>
                  <InputText
                    value={identidade}
                    label="Identidade:"
                    name="identidade"
                    help="Obrigatório"
                    maxlength={15}
                    visible={tipo !== TipoPessoa.Juridica}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isPF(), 'col-md-3')}>
                  <InputText
                    value={orgaoEmissor}
                    name="orgaoEmissor"
                    label="Órgão emissor:"
                    maxlength={18}
                    visible={tipo !== TipoPessoa.Juridica}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className='col-md-3'>
                  <Checkbox label="Contribuinte:">
                    <Checkitem
                      checked={contribuinte}
                      name="contribuinte"
                      label="Contribuinte"
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>

                <div
                  className={ifShow(this.isPJ() || this.isRural(), 'col-md-3')}
                >
                  <Checkbox label="ICMS:">
                    <Checkitem
                      checked={isentoICMS}
                      name="isentoICMS"
                      label="Isento"
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
                <div className={ifShow(this.needIE(), 'col-md-3')}>
                  <InputText
                    value={inscricaoEstadual}
                    label="Inscrição estadual:"
                    name="inscricaoEstadual"
                    maxlength={20}
                    help="Obrigatório"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isPJ(), 'col-md-3')}>
                  <InputText
                    value={inscricaoMunicipal}
                    label="Inscrição municipal:"
                    name="inscricaoMunicipal"
                    maxlength={15}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className={ifShow(false && isCliente, 'col-md-3')}>
                  <Checkbox label="Faturamento:">
                    <Checkitem
                      checked={emiteNFe}
                      label="Emitir NF-e"
                      name="emiteNFe"
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
                <div className={ifShow(this.isContador(), 'col-md-3')}>
                  <InputText
                    value={nomeContador}
                    label="Nome do(a) contador(a):"
                    name="nomeContador"
                    maxlength={50}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isContador(), 'col-md-3')}>
                  <InputText
                    value={crc}
                    label="CRC:"
                    name="crc"
                    maxlength={15}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent title="Endereço" visible={!this.isVerifying()}>
              <div className="row">
                <div className="col-md-2">
                  <InputText
                    value={cep}
                    label="CEP:"
                    name="cep"
                    type="number"
                    format="#####-###"
                    icone={`${String(cep).length === 8 ? 'icon-lx-search' : ''
                      }`}
                    onIconeClick={this.searchCep}
                    onChange={this.handleInputChange}
                    help={`${String(cep).length === 8
                        ? 'Clique na "lupa" para buscar o CEP'
                        : ''
                      }`}
                  //onBlur={this.searchCep}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <InputText
                    value={endereco}
                    label="Endereço:"
                    name="endereco"
                    maxlength={65}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <InputText
                    value={numero}
                    label="Número:"
                    name="numero"
                    type="number"
                    decimalScale={0}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <InputText
                    value={complemento}
                    label="Complemento:"
                    name="complemento"
                    maxlength={45}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <InputText
                    value={referencia}
                    label="Referência:"
                    name="referencia"
                    maxlength={45}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputText
                    value={bairro}
                    label="Bairro:"
                    name="bairro"
                    maxlength={45}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <Select
                    value={codigoUF}
                    name="codigoUF"
                    label="UF:"
                    options={this.state.estados}
                    onChange={this.changeEstado}
                  />
                </div>
                <div className="col-md-3">
                  <Select
                    value={codigoMunicipio}
                    name="codigoMunicipio"
                    label="Cidade:"
                    options={this.state.cidades}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <InputText
                    value={contatoNome}
                    label="Contato:"
                    name="contatoNome"
                    maxlength={50}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <InputText
                    value={contatoDescricao}
                    label="Descrição:"
                    name="contatoDescricao"
                    maxlength={50}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <InputText
                    value={contatoDDD}
                    label="DDD:"
                    name="contatoDDD"
                    type="number"
                    decimalScale={0}
                    maxlength={2}
                    onChange={this.handleInputChange}
                    help="Apenas números"
                  />
                </div>
                <div className="col-md-3">
                  <InputText
                    value={contatoNumero}
                    label="Telefone:"
                    name="contatoNumero"
                    type="number"
                    maxlength={15}
                    decimalScale={0}
                    onChange={this.handleInputChange}
                    help="Apenas números"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <InputText
                    value={contatoEMail}
                    label="E-mail:"
                    name="contatoEMail"
                    maxlength={65}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <InputText
                    value={site}
                    label="Endereço Internet:"
                    name="site"
                    maxlength={50}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title="Faturamento e crédito"
              visible={!this.isVerifying() && this.state.isCliente}
            >
              <div className="row">
                <div className="col-md-3">
                  <Checkbox>
                    <Checkitem
                      checked={utilizaFatura}
                      name="utilizaFatura"
                      label="Utiliza fatura"
                      //onChange={this.handleInputChange}
                      onChange={event => {
                        const utilizaFatura = event.target.checked;
                        let {
                          tipoPrazo,
                          carencia,
                          creditoUtilizado
                        } = this.state;
                        if (!utilizaFatura) {
                          if (!sameFloat(creditoUtilizado, 0)) {
                            showError(
                              this,
                              titulo,
                              'Não é possível remover a utilização de fatura.' +
                              '\nO cliente possui R$ ' +
                              creditoUtilizado +
                              ' de crédito utilizado.'
                            );
                            return;
                          }
                          tipoPrazo = null;
                          this.loadDiasFechamento(tipoPrazo);
                          carencia = null;
                        }
                        this.setState({
                          utilizaFatura,
                          tipoPrazo,
                          carencia
                        });
                      }}
                    />
                  </Checkbox>
                </div>

                <div className="col-md-3">
                  <Select
                    value={tipoPrazo}
                    name="tipoPrazo"
                    label="Tipo de prazo:"
                    options={this.state.listaTiposPrazos}
                    onChange={event => {
                      const tipoPrazo = event.target.value;
                      this.loadDiasFechamento(tipoPrazo);
                      this.setState({ tipoPrazo });
                    }}
                    disabled={!utilizaFatura || formaCalculoDataGeracao}
                  />
                </div>
                <div className="col-md-3">
                  {Number(tipoPrazo) === TiposPrazos.Mensal ? (
                    <InputText
                      value={diasFechamento}
                      label="Dia(s) de fechamento:"
                      name="diasFechamento"
                      type="number"
                      decimalScale={0}
                      maxlength={2}
                      onChange={this.handleInputChange}
                      allowNegative={false}
                      disabled={
                        !utilizaFatura ||
                        !tipoPrazo ||
                        formaCalculoDataGeracao ||
                        Number(tipoPrazo) === TiposPrazos.PrazoFixo
                      }
                    />
                  ) : (
                      <Select
                        value={diasFechamento}
                        name="diasFechamento"
                        label="Dia(s) de fechamento:"
                        options={this.state.listaDiasFechamento}
                        onChange={this.handleInputChange}
                        disabled={
                          !utilizaFatura ||
                          !tipoPrazo ||
                          formaCalculoDataGeracao ||
                          Number(tipoPrazo) === TiposPrazos.PrazoFixo
                        }
                      />
                    )}
                </div>
                <div className="col-md-3">
                  <InputText
                    value={carencia}
                    label="Carência para dia de vencimento:"
                    name="carencia"
                    type="number"
                    decimalScale={0}
                    maxlength={2}
                    onChange={this.handleInputChange}
                    allowNegative={false}
                    disabled={!utilizaFatura}
                  />
                </div>
              </div>
              {utilizaFatura ? (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <InputText
                        value={limiteCredito}
                        label="Limite de crédito (R$):"
                        name="limiteCredito"
                        type="number"
                        decimalScale={2}
                        allowNegative={false}
                        //pattern="^-?[0-9]\d*\.?\d*$"
                        onChange={event => {
                          const lc = event.floatValue;
                          const { creditoUtilizado = 0 } = this.state;
                          const saldoDisponivel = toFloatFormattedDisplay(
                            String(
                              !lc ? 0 - creditoUtilizado : lc - creditoUtilizado
                            )
                          );
                          this.setState({
                            saldoDisponivel,
                            limiteCredito: lc
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <InputText
                        value={creditoUtilizado}
                        label="Crédito utilizado (R$):"
                        name="creditoUtilizado"
                        type="text"
                        maxlength={12}
                        onChange={this.handleInputChange}
                        disabled
                      />
                    </div>
                    <div className="col-md-3">
                      <InputText
                        value={saldoDisponivel}
                        label="Saldo disponível (R$):"
                        name="saldoDisponivel"
                        type="text"
                        maxlength={12}
                        onChange={this.handleInputChange}
                        disabled
                      />
                    </div>
                    <div className="col-md-3">
                      <Checkbox>
                        <Checkitem
                          checked={clienteBloqueado}
                          name="clienteBloqueado"
                          label="Bloqueado"
                          onChange={this.handleInputChange}
                        />
                      </Checkbox>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <Checkbox>
                        <Checkitem
                          checked={clienteEmitirNFe}
                          name="clienteEmitirNFe"
                          label="Emitir NF-e"
                          onChange={this.handleInputChange}
                        />
                      </Checkbox>
                    </div>
                    <div className="col-md-3">
                      <Checkbox>
                        <Checkitem
                          checked={clienteEmailFaturamento}
                          name="clienteEmailFaturamento"
                          label="E-mail faturamento"
                          onChange={this.handleInputChange}
                        />
                      </Checkbox>
                    </div>
                  </div>
                </>
              ) : (
                  <></>
                )}
            </SectionContent>
            <SectionContent
              title="Autorizados"
              visible={!this.isVerifying() && this.state.isCliente}
            >
              <div className="row">
                <div className="col-md-6">
                  <Checkbox>
                    <Checkitem
                      checked={obrigaAutorizado}
                      name="obrigaAutorizado"
                      label="Obrigar a informação do autorizado"
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="table-ctn">
                <ReactTable
                  visible={!this.isVerifying() && this.state.isCliente}
                  defaultPageSize={5}
                  data={this.state.autorizados}
                  columns={[
                    {
                      accessor: 'codigo',
                      Header: 'Código',
                      show: false
                    },
                    {
                      accessor: 'placa',
                      Header: 'Placa',
                      filterable: false,
                      Cell: props => {
                        return (
                          <InputText
                            type="text"
                            className="input"
                            name="row.placa"
                            value={props.row.placa}
                            maxlength={7}
                            placeholder="Digite a placa"
                            autocomplete="off"
                            pattern={ALPHANUMERIC_PATTERN}
                            onKeyDown={makeInputUpperCase}
                            onKeyUp={makeInputUpperCase}
                            onKeyPress={makeInputUpperCase}
                            onChange={event => {
                              const val = event.target.value;
                              if (event.target.validity.valid)
                                changeInList(
                                  this,
                                  'autorizados',
                                  'placa',
                                  event.target.value,
                                  props.row._index
                                );
                              else if (val === '' || val === '-')
                                changeInList(
                                  this,
                                  'autorizados',
                                  'placa',
                                  val,
                                  props.row._index
                                );
                            }}
                          />
                        );
                      }
                    },
                    {
                      accessor: 'nome',
                      Header: 'Nome',
                      filterable: false,
                      Cell: props => {
                        return (
                          <InputText
                            type="text"
                            className="input"
                            name="row.nome"
                            value={props.row.nome}
                            maxlength={35}
                            autocomplete="off"
                            pattern="[a-zA-Z0-9 ]+"
                            onKeyDown={makeInputUpperCase}
                            onKeyUp={makeInputUpperCase}
                            onKeyPress={makeInputUpperCase}
                            onChange={event =>
                              changeInList(
                                this,
                                'autorizados',
                                'nome',
                                event.target.value,
                                props.row._index
                              )
                            }
                          />
                        );
                      }
                    },
                    {
                      accessor: 'documento',
                      Header: 'Documento',
                      filterable: false,
                      Cell: props => {
                        return (
                          <InputText
                            type="text"
                            className="input"
                            name="row.documento"
                            value={props.row.documento}
                            maxlength={20}
                            autocomplete="off"
                            onChange={event =>
                              changeInList(
                                this,
                                'autorizados',
                                'documento',
                                event.target.value,
                                props.row._index
                              )
                            }
                          />
                        );
                      }
                    },
                    {
                      accessor: 'setor',
                      Header: 'Setor',
                      filterable: false,
                      Cell: props => {
                        return (
                          <InputText
                            type="text"
                            className="input"
                            name="row.setor"
                            maxlength={50}
                            value={props.row.setor}
                            autocomplete="off"
                            onChange={event =>
                              changeInList(
                                this,
                                'autorizados',
                                'setor',
                                event.target.value,
                                props.row._index
                              )
                            }
                          />
                        );
                      }
                    },
                    {
                      filterable: false,
                      sortable: false,
                      show:
                        menuPermiteExclusao(PATH_MENU) ||
                        menuPermiteInclusao(PATH_MENU),
                      Cell: props => {
                        return (
                          <>
                            {menuPermiteExclusao(PATH_MENU) ? (
                              <Botao
                                className={props.row.codigo}
                                icon={'icon-lx-trash'}
                                tooltip="Remover linha"
                                ic
                                onClick={() => {
                                  deleteInList(
                                    this,
                                    'autorizados',
                                    props.row._index
                                  );
                                }}
                              />
                            ) : (
                                <></>
                              )}
                            {menuPermiteInclusao(PATH_MENU) &&
                              props.row._index ===
                              this.state.autorizados.length - 1 ? (
                                <Botao
                                  icon={'icon-lx-plus'}
                                  ic
                                  secondary
                                  tooltip="Adicionar linha"
                                  onClick={() => {
                                    appendInList(this, 'autorizados', {
                                      codigo: null,
                                      placa: '',
                                      nome: '',
                                      documento: '',
                                      setor: ''
                                    });
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                          </>
                        );
                      }
                    }
                  ]}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando registros..."
                  noDataText="Sem registros para exibir"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                />
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
        <Footer
          saveAction={
            this.isInserting()
              ? this.handleSalvar
              : !this.isInserting() && !this.isEditing()
                ? null
                : menuPermiteAlteracao(PATH_MENU)
                  ? this.handleSalvar
                  : null
          }
          deleteAction={
            !this.isEditing()
              ? null
              : this.isEditing() && this.isRestoring()
                ? null
                : menuPermiteExclusao(PATH_MENU)
                  ? this.handleExcluir
                  : null
          }
          cancelAction={this.handleCancelar}
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

export default class Pessoas extends Component {
  state = {
    pessoas: [],
    pessoaSel: {},
    searchText: '',
    lastSearch: null,
    listas: []
  };

  loadPessoas = async searchText => {
    this.setState({ searchText})
    const pessoas = [];
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto a pesquisa é realizada'
    );
    try {
      const { data } = await getPessoas({ search: searchText });
      data.result.forEach(pessoa => {
        pessoas.push(apiToState(pessoa));
      });
      this.setState({ lastSearch: searchText, pessoas });
    } finally {
      hideWait(this);
    }
  };

  limparCadastro = () => {
    this.setState({ pessoaSel: {} });
  }

  setarValorCampoBusca = (texto) => {
    this.setState({ searchText: texto });
  }

  componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      if (this.state.lastSearch) {
        this.setState({ searchText: this.state.lastSearch });
      }
      this.loadPessoas(this.state.lastSearch);
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      this.setState({ pessoaSel: rowInfo.original });
      this.props.history.push('/cadastro/pessoas/new');
    }
  };

  render() {
    const { edit } = this.props,
      { pessoaSel, searchText } = this.state;
    this.handleInputChange = handleInputChange.bind(this);

    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <Form pessoa={pessoaSel} pessoasExistentes={this.state.pessoas} />
          ) : (
              <>
                <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                  <>
                    <SectionHeader
                      title=""
                      right={
                        <div className={ifShow(!edit, 'button-container')}>
                          <ScreenHeader search={true} pathMenu={PATH_MENU} newButton={true} texto="Novo Cadastro" newURL={"/cadastro/pessoas/new"} searchText={searchText} load={this.loadPessoas} limparCadastro={this.limparCadastro} setarValorCampoBusca={this.setarValorCampoBusca} />
                        </div>
                      }
                    />
                  </>
                  <List
                    onClick={this.handleTableClick}
                    defaultPageSize={10}
                    noDataText={`${!this.state.lastSearch
                        ? 'Inicie fazendo uma busca ou incluindo uma nova pessoa.'
                        : 'Sua pesquisa "' +
                        this.state.lastSearch +
                        '" não encontrou nenhuma pessoa correspondente.'
                      }`}
                    cols={[
                      {
                        accessor: 'nome',
                        Header: 'Nome/Razão Social',
                        width: 400
                      },
                      {
                        accessor: 'cnpjCpf',
                        Header: 'CPF/CNPJ',
                        width: 160,
                        Cell: props => <div>{maskedCnpjCpf(props.value)}</div>
                      },
                      {
                        accessor: 'apelido',
                        Header: 'Apelido/Nome Fantasia',
                        width: 320
                      },
                      {
                        accessor: 'codigo',
                        Header: 'Código',
                        width: 100
                      }
                    ]}
                    rows={this.state.pessoas}
                  />
                </LinxPostos>
                {tagAlert(this)}
              </>
            )}
        </section>
      </main>
    );
  }
}

function ifShow(condition, divName) {
  return condition ? divName : 'content-hidden';
}

Pessoas = withRouter(Pessoas);
export { Pessoas };
