import React from "react";
import { Button } from "react-bootstrap";

export const CustomButton = ({
  variant = "primary",
  type = "button",
  title,
  children,
  icon,
  iconPosition = "left",
  duplicateIcon = false,
  className,
  onClick,
  disabled = false
}) => {

  const renderIcon = () => {
    if (!icon) return null;
    return <i className={`dx-icon-${icon}`} aria-hidden="true"></i>;
  };

  const renderClass = () => {
    if (className) {
      return `d-flex align-items-center ${className}`;
    }

    return `d-flex align-items-center`;
  }

  return (
    <>
      <Button
        className={renderClass()}
        variant={variant}
        type={type}
        aria-label={title}
        title={title}
        onClick={onClick}
        disabled={disabled}
        style={{ gap: 8 }}
      >
        {iconPosition === "left" && renderIcon()}
        {duplicateIcon && iconPosition === "left" && renderIcon()}
        <span>{children}</span>
        {iconPosition === "right" && renderIcon()}
        {duplicateIcon && iconPosition === "right" && renderIcon()}
      </Button>
    </>
  );
};
