import { CharToInt } from './utils';
export function CheckIEPR(ie) {
  if (ie.length !== 10) return false;
  var nro = new Array(10);
  for (var i = 0; i <= 9; i++) nro[i] = CharToInt(ie.charAt(i));
  var b = 3;
  var soma = 0;
  for (i = 0; i <= 7; i++) {
    soma += nro[i] * b;
    b--;
    if (b === 1) b = 7;
  }
  i = soma % 11;
  if (i <= 1) var dig = 0;
  else dig = 11 - i;
  var resultado = dig === nro[8];
  if (!resultado) return false;
  b = 4;
  soma = 0;
  for (i = 0; i <= 8; i++) {
    soma += nro[i] * b;
    b--;
    if (b === 1) b = 7;
  }
  i = soma % 11;
  if (i <= 1) dig = 0;
  else dig = 11 - i;
  return dig === nro[9];
} //pr
