import React, { Component} from 'react';

import {
  getUrl,
  getDadosSellerPop,
  getNomePagina
} from '../../../../shared/utils/Utils';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import SellerPopFrame from '../../../../components/SellerPopFrame/SellerPopFrame';
import { menus } from '../../../../shared/constants/MenuConstants';

//Verificar com Josimar porque estava usando esse código
//const PATH_MENU = menus.COD_80580;
const PATH_MENU = menus.COD_16430;

export class ResultadoOperacional extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <SellerPopFrame
          url={getUrl() + '/ResultadoOperacional.aspx?' + getDadosSellerPop(PATH_MENU)}          
        />
      </LinxPostos>
    );
  }
}

