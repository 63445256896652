import { Button } from 'devextreme-react';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import EditarPagamento from '../editar-pagamento/EditarPagamento';
import TurnoViewItem from './TurnoViewItem';
import { TurnoPedidoService } from '../../../../../../services/turno-pedido/TurnoPedido.service';
import { alert } from 'devextreme/ui/dialog';
import TurnoViewItemSummary from './TurnoViewItemSummary';
import moment from 'moment';

export const TurnoView = props => {
  const { onBusy, onUnBusy, onGoBack } = props;
  const [gridPagamento, setGridPagamento] = useState(null);
  const [turnoAtual, setTurnoAtual] = useState(null);

  useEffect(() => {
    if (!turnoAtual && !props.turno) return;
    if (!turnoAtual && props.turno) {
      setTurnoAtual({ ...props.turno });
      return;
    }
    if (turnoAtual && !props.turno) {
      setTurnoAtual(null);
      return;
    }
  }, [props.turno, turnoAtual]);

  const handlerEditCancel = useCallback(e => {
    setGridPagamento(null);
  }, []);
  const handlerOnItemChange = useCallback(
    it => {
      if (!it) return;
      const index = turnoAtual.valores.findIndex(x => x.turnoId === it.turnoId && x.codigoFormaPagamento === it.codigoFormaPagamento);
      const novoTurno = { ...turnoAtual };
      novoTurno.valores[index] = it;
      setTurnoAtual(novoTurno);
    },
    [turnoAtual],
  );
  const handlerSave = useCallback(
    async it => {
      if (!it) return;
      try {
        if (onBusy) onBusy('Salvando valores do turno...');
        const novoTurno = await TurnoPedidoService.getInstance().salvaValores(turnoAtual.valores);
        setTurnoAtual(novoTurno);
        if (onUnBusy) onUnBusy();
        alert('Turno Salvo com sucesso', 'Sucesso!');
      } catch (error) {
        if (onUnBusy) onUnBusy();
        alert(error.message, 'OPS!');
      }
    },
    [turnoAtual, onBusy, onUnBusy],
  );
  const handlerConferirFacil = useCallback(
    async it => {
      if (!it) return;
      try {
        if (onBusy) onBusy('Fechando o turno...');
        const novoTurno = await TurnoPedidoService.getInstance().confereTurnoFacil(turnoAtual.valores);
        setTurnoAtual(novoTurno);
        if (onUnBusy) onUnBusy();
        alert('Turno conferido com sucesso', 'Sucesso!');
      } catch (error) {
        if (onUnBusy) onUnBusy();
        alert(error.message, 'OPS!');
      }
    },
    [turnoAtual, onBusy, onUnBusy],
  );
  const handlerConferir = useCallback(
    async it => {
      if (!it) return;
      try {
        if (onBusy) onBusy('Fechando o turno...');
        const novoTurno = await TurnoPedidoService.getInstance().confereTurno(turnoAtual.valores);
        setTurnoAtual(novoTurno);
        if (onUnBusy) onUnBusy();
        alert('Turno conferido com sucesso', 'Sucesso!');
      } catch (error) {
        if (onUnBusy) onUnBusy();
        alert(error.message, 'OPS!');
      }
    },
    [turnoAtual, onBusy, onUnBusy],
  );
  const handlerReabrir = useCallback(
    async it => {
      if (!it) return;
      try {
        if (onBusy) onBusy('Reabrindo o turno...');
        const novoTurno = await TurnoPedidoService.getInstance().reabrirTurno(turnoAtual.turnoId);
        setTurnoAtual(novoTurno);
        if (onUnBusy) onUnBusy();
        alert('Turno reaberto com sucesso', 'Sucesso!');
      } catch (error) {
        if (onUnBusy) onUnBusy();
        alert(error.message, 'OPS!');
      }
    },
    [turnoAtual, onBusy, onUnBusy],
  );
  const handlerGoBack = useCallback(
    async it => {
      if (onGoBack) onGoBack();
    },
    [onGoBack],
  );

    const handleAfterSavePagamento = useCallback(
    async it => {
      console.log("entrou no AfterSave de TurnoView para recarregar o turno de id", it);
      if (onBusy) onBusy('Recarregando turno...');
      const novoTurno = await TurnoPedidoService.getInstance().recuperarTurnoPedidoCompleto(it);
      setTurnoAtual(novoTurno);

      if (onUnBusy) onUnBusy();
      return novoTurno;
    },
    [onBusy, onUnBusy],
  );



  if (!turnoAtual) return null;
  return (
    <>
      {gridPagamento !== null && <EditarPagamento onCancel={handlerEditCancel} turno={turnoAtual} pagamento={gridPagamento}></EditarPagamento>}
      {gridPagamento === null && (
        <Card>
          <Card.Header>
            <h5 className="card-title">Detalhes do Turno</h5>
            <Row className="m-0 p-0">
              <Col className="m-0 p-0">
                <span className="m-0 p-0">
                  <small>Operador:</small> <strong id="operadorNome">{turnoAtual.nomeOperador}</strong>
                </span>
              </Col>
              <Col className="m-0 p-0 text-right">
                <span className="mb-0">
                  <small>Data de Abertura:</small> <strong id="dataAbertura">{moment(turnoAtual.dataAbertura).format('DD/MM/YYYY HH:mm')}</strong>
                </span>
              </Col>
            </Row>
            {turnoAtual.turnoConferido && (
              <Row className="m-0 p-0">
                <Col className="m-0 p-0">
                  <span className="m-0 p-0">
                    <small>Conferido por:</small> <strong id="conferenteNome">{turnoAtual.nomeConferente}</strong>
                  </span>
                </Col>
                <Col className="m-0 p-0 text-right">
                  <span className="m-0 p-0">
                    <small>Data de Fechamento:</small> <strong id="dataFechamento">{moment(turnoAtual.dataFechamento).format('DD/MM/YYYY HH:mm')}</strong>
                  </span>
                </Col>
              </Row>
            )}
            <Row className="m-0 mt-3 p-0">
              <Col className="m-0 p-0">
                <span className={!turnoAtual.turnoConferido ? 'badge badge-dark ml-3' : 'badge badge-success ml-3'}>
                  Turno: <small>{turnoAtual.turnoConferido ? 'CONFERIDO' : 'PENDENTE'}</small>
                </span>
              </Col>
              <Col className="m-0 p-0 text-right">
                <span className="mb-0">
                  <small>Data Atual:</small> <strong id="dataAbertura">{moment().format('DD/MM/YYYY HH:mm')}</strong>
                </span>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {turnoAtual.turnoConferido && (
              <Button
                variant="secondary"
                className="btn-secondary"
                style={{ top: '16px', right: '16px' }}
                onClick={handlerReabrir}
              >
                <small>
                  <i className="fas fa-edit"></i> REABRIR TURNO
                </small>
              </Button>
            )}
            <Button
              variant="primary"
              className="btn-primary"
              style={{ top: '16px', right: '16px' }}
              onClick={handlerConferirFacil}
              disabled={turnoAtual.turnoConferido}
            >
              <small>
                <i className="fas fa-edit"></i> CONFERÊNCIA FÁCIL
              </small>
            </Button>
            <Button
              variant="primary"
              className="btn-primary"
              style={{ top: '16px', right: '16px' }}
              onClick={handlerConferir}
              disabled={turnoAtual.turnoConferido}
            >
              <small>
                <i className="fas fa-list"></i> CONFERIR
              </small>
            </Button>
            <Button variant="primary" className="btn-primary" style={{ top: '16px', right: '16px' }} onClick={handlerSave} disabled={turnoAtual.turnoConferido}>
              <small>
                <i className="fas fa-save"></i> SALVAR CONFERÊNCIA
              </small>
            </Button>
            <Button variant="primary" className="btn-secondary" style={{ top: '16px', right: '16px', float: 'right' }} onClick={handlerGoBack}>
              <small>
                <i className="fas fa-arrow-left"></i> VOLTAR
              </small>
            </Button>
          </Card.Body>
          <Card.Body>
            <Accordion>
              {_.sortBy(turnoAtual.valores || [], x => x.descricaoFormaPagamento).map((pgt, index) => (
                <TurnoViewItem onChange={handlerOnItemChange} key={index} turno={turnoAtual} item={pgt} idx={index} onBusy={onBusy} onUnBusy={onUnBusy} afterSavePagamento={handleAfterSavePagamento}></TurnoViewItem>
              ))}
            </Accordion>
            <TurnoViewItemSummary
              valorRecebido={_.sumBy(turnoAtual.valores, x => x.valorRecebido)}
              valorConferido={_.sumBy(turnoAtual.valores, x => x.valorConferido)}
              valorSangrado={_.sumBy(turnoAtual.valores, x => x.valorSangrado)}
              valorSuprido={_.sumBy(turnoAtual.valores, x => x.valorSuprido)}
              valorDiferenca={_.sumBy(
                turnoAtual.valores,
                x => ((x.valorRecebido || 0) * -1) - (x.valorSuprido || 0) + (x.valorSangrado || 0) + (x.valorConferido || 0),
              )}
            ></TurnoViewItemSummary>
          </Card.Body>
          <Card.Footer>
            <div>
              {/* Seu conteúdo */}
              <button
                onClick={() => {
                  window.print();
                }}
              >
                Imprimir
              </button>
            </div>
          </Card.Footer>
        </Card>
      )}
    </>
  );
};
