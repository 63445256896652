import { CharToInt, IntToChar } from './utils';
export function CheckIEAC(ie) {
  if (ie.length !== 13) return false;
  var b = 4,
    soma = 0;

  var i = 0;

  for (i = 0; i <= 10; i++) {
    soma += CharToInt(ie.charAt(i)) * b;
    --b;
    if (b === 1) {
      b = 9;
    }
  }
  var dig = 11 - (soma % 11);
  if (dig >= 10) {
    dig = 0;
  }
  var resultado = IntToChar(dig) === ie.charAt(11);
  if (!resultado) {
    return false;
  }

  b = 5;
  soma = 0;
  i = 0;
  for (i = 0; i <= 11; i++) {
    soma += CharToInt(ie.charAt(i)) * b;
    --b;
    if (b === 1) {
      b = 9;
    }
  }
  dig = 11 - (soma % 11);
  if (dig >= 10) {
    dig = 0;
  }
  if (IntToChar(dig) === ie.charAt(12)) {
    return true;
  } else {
    return false;
  }
}
