import { Badge } from 'react-bootstrap';
import React from 'react';
import styles from './Component.module.scss';
import stylesOs from './../OSsConComodato.module.scss';

export function CellStatus({ text }) {
  let cssStatus = 'emAberto';
  var slg = '';
  switch (text.toUpperCase()) {
    case 'EM ABERTO':
      cssStatus = 'bg-em-aberto';
      slg = 'AB';
      break;
    case 'RECOLHIDO PARCIAL':
      cssStatus = 'bg-recolhido-parcial';
      slg = 'RP';
      break;
    case 'CONFIRMADO':
      cssStatus = `bg-${text.toLowerCase()}`;
      slg = 'CF';
      break;
    case 'CARREGADO':
      cssStatus = `bg-${text.toLowerCase()}`;
      slg = 'CR';
      break;
    case 'ENTREGUE':
      cssStatus = `bg-${text.toLowerCase()}`;
      slg = 'ET';
      break;
    case 'RECOLHIDO':
      cssStatus = `bg-${text.toLowerCase()}`;
      slg = 'RC';
      break;
    case 'CANCELADO':
      cssStatus = `bg-${text.toLowerCase()}`;
      slg = 'CC';
      break;
    case 'DECHADO':
      cssStatus = `bg-${text.toLowerCase()}`;
      slg = 'FC';
      break;
    default:
      cssStatus = `bg-${text.toLowerCase()}`;
      slg = text.toLowerCase().substring(0, 2);
      break;
  }
  return (
    <Badge bg={cssStatus.replace('bg-', '')} className={`w-100 p-0 ${stylesOs[cssStatus]}`}>
      <p className={`${styles.longText} ${styles.smallText} pt-2 pb-1 w-100 pl-1 pr-1 m-0`}>{slg}</p>
    </Badge>
  );
}
