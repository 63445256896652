import axios from "axios";
import { getToken } from "../../../../../authentication/Auth";
import { BASE_URL_GATEWAY } from "../../../../../shared/constants/GatewayUrlConstant";

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const getBanks = async () => {
  try {
    const response = await urlLinxCore.get("/Stone/Banks");

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const getTransfers = async (filter = "") => {
  const queryString = "?" + Object.entries(filter)
  .map(([key, value]) => `${key}=${value}`)
  .join("&");

  try {
    const response = await urlLinxCore.get(`Stone/Transferencias${queryString}`);
    
    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const postInternalTransfer = async (payload) => {
  try {
    const response = await urlLinxCore.post("/Stone/CriarTransferenciaInterna", payload);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const postExternalTransfer = async (payload) => {
  try {
    const response = await urlLinxCore.post("/Stone/CriarTransferenciaExterna", payload);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

export const TransferenciasService = {
  getBanks,
  getTransfers,
  postInternalTransfer,
  postExternalTransfer,
};