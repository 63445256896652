import React, { useCallback, useEffect, useState } from 'react';
import ListaTurnoFiltro from '../lista-turno-filtro/ListaTurnoFiltro';
import { TurnoView } from '../turno-view/TurnoView';

const TurnoNavigation = props => {
  const [turno, setTurno] = useState(null);
  const [filter, setFilter] = useState(null);
  const [turnos, setTurnos] = useState(null);

  const resetFilter = useCallback(f => {
    setFilter({
      dataInicio: new Date(new Date().setHours(0, 0, 0, 0)),
      dataFim: new Date(new Date().setHours(0, 0, 0, 0)),
      op: [],
      situacao: null,
      pedido: null,
    });
  }, []);

  useEffect(() => {
    if (filter) return;
    resetFilter();
  }, [filter, resetFilter]);

  const handlerOnBusy = useCallback(
    msg => {
      if (props.onBusy) props.onBusy(msg);
    },
    [props],
  );
  const handlerOnUnBusy = useCallback(() => {
    if (props.onUnBusy) props.onUnBusy();
  }, [props]);

  const handlerOnGoBack = useCallback(() => {
    setTurno(null);
  }, []);

  return (
    <>
      {!turno && (
        <ListaTurnoFiltro
          onBusy={handlerOnBusy}
          onUnBusy={handlerOnUnBusy}
          turnos={turnos}
          filter={filter}
          filterFound={t => {
            setTurnos(t);
          }}
          selectedTurno={t => {
            setTurno(t);
          }}
          filterChange={p => {
            setFilter({ ...(filter || {}), ...p });
          }}
          onCleaned={() => {
            setTurnos(null);
            resetFilter();
          }}
        ></ListaTurnoFiltro>
      )}
      {turno && <TurnoView onBusy={handlerOnBusy} onUnBusy={handlerOnUnBusy} onGoBack={handlerOnGoBack} turno={turno}></TurnoView>}
    </>
  );
};

export default TurnoNavigation;
