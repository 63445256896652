import { ApiListarFuncionariosService } from './ApiListarFuncionarios.service';
import { ApiRecuperarAcessosPorMenuIdService } from './ApiRecuperarAcessosPorMenuId.service';
import { ApiRecuperarAcessosPorMenuPaiIdService } from './ApiRecuperarAcessosPorMenuPaiId.service';

export class FuncionarioService {
  static instance = null;
  static getInstance() {
    if (!FuncionarioService.instance) {
      FuncionarioService.instance = new FuncionarioService();
    }
    return FuncionarioService.instance;
  }
  apiRecuperarAcessosPorMenuIdService = new ApiRecuperarAcessosPorMenuIdService();
  apiRecuperarAcessosPorMenuPaiIdService = new ApiRecuperarAcessosPorMenuPaiIdService();
  apiListarFuncionariosService = new ApiListarFuncionariosService();

  recuperarAcessosPorMenuId = async menuId => {
    return this.apiRecuperarAcessosPorMenuIdService.execute(menuId);
  };
  recuperarAcessosPorMenuPaiId = async menuPaiId => {
    return this.apiRecuperarAcessosPorMenuPaiIdService.execute(menuPaiId);
  };

  listar = async (useCache = false) => {
    return this.apiListarFuncionariosService.execute(useCache);
  };
}
