import React, { useState } from 'react';
import { TreeView } from 'devextreme-react';

export default function LinxTreeView(props) {
  const [treeView, setTreeView] = useState(null);

  const dataSource = props.dataSource || null,
    noDataText = props.noDataText
      ? props.noDataText
      : 'Sem registros para exibir.',
    property = props.property || null,
    method = props.method ? props.method : () => {},
    selectionMode = props.selectionMode ? props.selectionMode : 'multiple',
    showCheckBoxesMode = props.showCheckBoxesMode
      ? props.showCheckBoxesMode
      : 'selectAll',
    displayExpr = props.displayExpr ? props.displayExpr : 'descricao',
    keyExpr = props.keyExpr ? props.keyExpr : 'codigo';

  return (
    <TreeView
      dataSource={dataSource}
      noDataText={noDataText}
      ref={ref => setTreeView(ref)}
      dataStructure="plain"
      keyExpr={keyExpr}
      selectionMode={selectionMode}
      showCheckBoxesMode={showCheckBoxesMode}
      searchEnabled={true}
      selectNodesRecursive={false}
      displayExpr={displayExpr}
      selectByClick={true}
      onContentReady={e => {
        syncTreeViewSelection(e, property, method, treeView);
      }}
      onItemSelectionChanged={e => {
        method(e.component.getSelectedNodesKeys());
        props.onItemSelectionChanged(e);
      }}
      onSelectAllValueChanged={e => {
        method(e.component.getSelectedNodesKeys());
      }}
    />
  );
}

export const syncTreeViewSelection = (e, property, method, treeView) => {
  if (e === undefined) {
    let tree = treeView && treeView.instance;

    tree.unselectAll();
  } else {
    let tree =
      (e.component.selectItem && e.component) ||
      (treeView && treeView.instance);

    if (tree) {
      if (e.value === null || e === undefined) {
        tree.unselectAll();
      } else {
        let values = e.value || property;
        values &&
          values.forEach(function(value) {
            tree.selectItem(value);
          });
      }
    }
    if (e.value !== undefined && method) {
      method(e.value);
    }
  }
};
