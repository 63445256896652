import { Button } from 'devextreme-react';
import React from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

function CustomToggle({ children, eventKey, openAccordion, setOpenAccordion }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    if (openAccordion === eventKey) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(eventKey);
    }
  });

  return (
    <Button
      variant="light"
      className="p-2 ml-2 btn-light"
      onClick={decoratedOnClick}
      style={{ with: '20px', backgroundColor: 'white', position: 'absolute', top: '0px', right: '0px', height: '100%' }}
    >
      {children}
    </Button>
  );
}

export default CustomToggle;
