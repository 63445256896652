import React, { useCallback, useState } from 'react';
import { Card, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { InputCurrency } from '../../../../../../components/inputCurrency/inputCurrency';

function EditarPagamento(props) {
  const [pagamento, setPagamento] = useState(null);

  useState(() => {
    setPagamento({ ...props.pagamento });
  }, [props]);
  const handlerCancel = useCallback(
    e => {
      if (props.onCancel) props.onCancel();
    },
    [props],
  );
  const handlerSaved = useCallback(
    e => {
      if (props.onSaved) props.onSaved();
    },
    [props],
  );
  if (!pagamento) return <></>;
  return (
    <Card>
      <Card.Header>Alteração de Forma de Pagamento</Card.Header>
      <Card.Body>
        <Form>
          <Form.Group as={Row}>
            <Col sm="6">
              <Form.Label>Forma de Pagamento</Form.Label>
              <Form.Control as="select" value={pagamento.formaPagamento}>
                {/* Adicione suas opções de forma de pagamento aqui */}
                <option value="CRÉDITO">CRÉDITO</option>
                {/* ... */}
              </Form.Control>
            </Col>
            <Col sm="6">
              <Form.Label>Valor do Pagamento</Form.Label>
              <InputGroup className="mb-3">
                <InputCurrency
                  value={pagamento.valor || 0}
                  onChange={(_, money) => {
                    setPagamento({ ...pagamento, valor: money });
                  }}
                  className="form-control text-right"
                  name="valorPagamento"
                  placeholder="R$ 0,00"
                ></InputCurrency>
                <Button variant="outline-primary" id="btnAddPagamento">
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </InputGroup>
            </Col>
          </Form.Group>
        </Form>

        {/* Aqui você pode renderizar as informações de pagamento e documento, similar ao exemplo acima. */}

        <Button variant="danger" className="mr-2" onClick={handlerCancel}>
          <FontAwesomeIcon icon={faTrash} /> CANCELAR
        </Button>
        <Button variant="primary" onClick={handlerSaved}>
          <FontAwesomeIcon icon={faSave} /> SALVAR
        </Button>
      </Card.Body>
    </Card>
  );
}

export default EditarPagamento;
