import React from 'react';
import styles from './TextToolTip.module.scss'

export default function TextToolTip(props) {
    return (
        <div className={`${styles.pill} ${styles.disabled}`}>
            <span>
                <i></i>
                {props.text}
            </span>
        </div>
    );
}