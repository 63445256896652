import React from 'react';
import { Popup } from 'devextreme-react/popup';
import styles from './ModalParcela.module.scss';

export function ModalParcela(props) {
  const { idParcela, codigoRecorrencia, numeroParcelas, valorParcelas } = props;


  const contentRender = () => {
    return (
      <>
          <div className={`${styles.textoTitulo} row`}>
            {idParcela !== codigoRecorrencia
              ? 'Existe mais de uma parcela. Deseja deletar apenas a parcela ' +
                'atual ou todas as próximas?'
              : 'Esta é a primeira parcela de uma recorrência.\n ' +
                'Caso confirmado, as próximas parcelas também serão excluídas.\n ' +
                'Deseja realmente excluir?'}
          </div>
          <div>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <input
                    type="input"
                    className="input"
                    name="numeroParcelas"
                    value={numeroParcelas}
                    disabled
                  />
                  <label className="input-label">Número de parcelas:</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <input
                    type="input"
                    className="input"
                    name="valorParcelas"
                    value={'R$ ' + valorParcelas}
                    disabled
                  />
                  <label className="input-label">Valor total:</label>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.footer}`}>
            {idParcela !== codigoRecorrencia ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  props.handleCloseModal(1);
                }}
              >
                Apenas parcela atual
              </button>
            ) : (
              <></>
            )}
            <button
              className="btn btn-primary"
              onClick={() => {
                props.handleCloseModal(2);
              }}
            >
              {idParcela !== codigoRecorrencia ? 'Todas as parcelas' : 'Sim'}
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                props.handleCloseModal(0);
              }}
            >
              {idParcela !== codigoRecorrencia ? 'Cancelar' : 'Não'}
            </button>
          </div>
      </>
    );
  }

  return (
    props.active && (
      <Popup
        visible={props.active}
        onHidden={() => props.handleCloseModal(false)}
        dragEnabled={false}
        closeOnOutsideClick={false}
        showTitle={false}
        width={800}
        height={350}
        contentRender={contentRender}
      />
    )
  );    
}