import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFiltroSolRes, useFiltroSolRes } from '../../../../redux/slices/solicitacaoReserva/filtroSolSlice'; // Certifique-se de que o caminho está correto
import { ToggleButton } from './ToggleButton/ToggleButton';

export function CheckboxStatus({ typeName, className, label, statusId }) {
  const dispatch = useDispatch();
  const { changeValue } = useFiltroSolRes();
  const { values } = useSelector(selectFiltroSolRes);
  const [checked, setChecked] = React.useState(false);

  // Atualiza o estado do checkbox baseado nos valores do Redux
  useEffect(() => {
    if (!values.status) return;
    setChecked(values.status.includes(statusId));
  }, [statusId, values.status]);

  // Função para manipular a mudança de estado do checkbox
  const onChange = useCallback(
    (e) => {
      const isChecked = e;
      let updatedStatus = [...(values.status || [])];

      if (isChecked) {
        if (!updatedStatus.includes(statusId)) {
          updatedStatus.push(statusId);
        }
      } else {
        updatedStatus = updatedStatus.filter(id => id !== statusId);
      }

      setChecked(isChecked);
      dispatch(changeValue({ fieldName: 'status', value: updatedStatus }));
    },
    [dispatch, changeValue, statusId, values.status],
  );

  return (
    <ToggleButton
      className={className}
      value={checked}
      typeName={typeName}
      label={label}
      onCheckChange={onChange}
      currentCheckedStatus={checked}
    />
  );
}
