import React, { Component } from 'react';
import {
  InputText,
  InputDate,
  Select,
  handleInputChange,
  // FormOptions,
  Checkbox,
  Checkitem,
  AutoComplete
} from '../../../../components/formulario/Formulario';
import {
  SectionContainer,
  SectionContent
} from '../../../../components/section/Content';
import {
  tagAlert,
  showQuestion,
  hideAlert,
  showError,
  showInfo,
  setFocus
} from '../../../../components/alert/Alert';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
  noneItem,
  isAlphaNumericOnly,
  getLabelByValue,
  emptyText,
  getMaxFinalSmallDateString,
  compareStrings,
  validaDadosLogin,
  menuPermiteAlteracao,
  getNomePagina,
  toFloatFormattedDisplayDecimals,
  sameInt
} from '../../../../shared/utils/Utils';
import { getCentrosMonetarios } from '../../../../services/CentrosMonetarios.service';
import {
  montaComboTiposLancamentos,
  montaComboTiposOcorrencias,
  montaComboTiposDocumentos,
  montaComboOperacoes,
  alteraContaPagarReceber,
  incluiContaPagarReceber,
  incluiTransferencia,
  incluiDiversas,
  getTitulo,
  montaComboEstruturaCartoes
} from '../GerenciamentoFinanceiro.service';
import {
  TiposLancamentos,
  defaultSearchText
} from '../GerenciamentoFinanceiro';
import { getPessoas } from '../../../cadastro/pessoas/Pessoas.service';
import { montarComboPlanoContaFinanceiro } from '../../../configuracoes/contas/Contas.service';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '../../../../components/modal/Modal';
import Botao from '../../../../components/botao/Botao';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
  getBancosByQuery,
  getBanco
} from '../../../../services/Bancos.service';
import { ALPHANUMERIC_PATTERN, LAST_SAFE_SMALLDATE, NUMERIC_PATTERN } from '../../../../shared/constants/Const';
import Footer from '../../../../components/core/footer/Footer';
import { menus } from '../../../../shared/constants/MenuConstants';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { lancamentoService } from './FormLancamento.service';
import EstruturaCartoesSelect from '../../../../components/business/EstruturaCartoes/Select/EstruturaCartoesSelect';


const title = 'Gerenciamento financeiro';

const PATH_MENU = menus.COD_60690;

const autoComplete = {
  pessoa: {
    min: 4,
    text: '',
    item: 'pessoa'
  },
  banco: {
    min: 3,
    text: '',
    item: 'banco'
  },
  estruturaCartoes: {
    min: 3,
    text: '',
    item: 'estrutura de cartões'
  }
};

export const Operacoes = {
  None: '',
  LancamentoReceita: '521',
  LancamentoDespesa: '520'
};

export const TiposOcorrencias = {
  None: '',
  Unica: '1',
  Parcelada: '2',
  Diaria: '3',
  Semanal: '4',
  Quinzenal: '5',
  Mensal: '6',
  Bimestral: '7',
  Trimestral: '8',
  Semestral: '9',
  Anual: '10'
};

export const TiposDocumentos = {
  None: '',
  Boleto: '12',
  Diversos: '4',
  NFBoleto: '2',
  NFCompra: '11',
  NFFatura: '1',
  Promissoria: '3',
  VendaCartao: '7',
  VendaCheque: '10',
  VendaCupom: '6'
};

class FormLancamento extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    const {
      id = null,
      tipoLancamento = '',
      valor = null,
      descricao = '',
      dataVencimento = moment(),
      dataEmissao = moment(),
      dataPrevisao = undefined,
      dataPagamento = undefined,
      pago = false,
      conciliado = false,
      conta = '',
      centroMonetarioOrigem = '',
      centroMonetarioDestino = '',
      tipoOcorrencia = TiposOcorrencias.Unica,
      diaVencimento = 1,
      numeroParcelas = 1,
      pessoa = {},
      numeroDocumento = '',
      tipoDocumento = '',
      banco = {},
      agencia = '',
      contaBanco = '',
      estruturaCartoes = noneItem(),
      historico = '',
      operacao = '',
      indicadorVenda = false,
      isEditing = false,
      numeroAutorizacao,
      nsu
    } = this.props.titulo;

    const {
      tiposLancamentos = [],
      contas = [],
      centrosMonetarios = [],
      tiposOcorrencias = [],
      tiposDocumentos = [],
      operacoes = [],
      disableControls = false
    } = this.props;

    this.state = {
      codigo: id,
      tipoLancamento,
      valor,
      valorOriginal: valor,
      descricao,
      dataVencimento,
      dataVencimentoOriginal:dataVencimento,
      dataOperacao: moment(),
      dataEmissao,
      dataPrevisao,
      dataPagamento,
      pago,
      conciliado,
      conta,
      centroMonetarioOrigem,
      centroMonetarioOriginal: centroMonetarioOrigem,
      centroMonetarioDestino,
      centroMonetarioOrigemOriginal: centroMonetarioOrigem,
      centroMonetarioDestinoOriginal: centroMonetarioDestino,
      tipoOcorrencia,
      tipoOcorrenciaOriginal: tipoOcorrencia,
      diaVencimento,
      numeroParcelas,
      pessoa,
      pessoas: [],
      campoPessoa: '',
      numeroDocumento,
      tipoDocumento,
      banco,
      campoBanco: '',
      contaBanco,
      agencia,
      estruturaCartoes,
      isEditing,
      tiposLancamentos,
      contas,
      centrosMonetarios,
      tiposOcorrencias,
      tiposDocumentos,
      operacao,
      indicadorVenda,
      operacoes,
      historico,
      title: 'Lançamento financeiro',
      showModalClienteFornecedor: false,
      clientesFornecedores: [],
      showModalBanco: false,
      listaPesquisaBancos: [],
      disableControls,
      listaEstruturaCartoes: [],
      salvando: false,
      percentualTaxaCartao: 0,
      valorTaxaCartao: 0,
      numeroAutorizacao,
      nsu,
      isPDV: Boolean(props.titulo.numeroPDV) 
    };
  }

  fillArrayForCombo(dataList, listToPush, nullItem) {
    if (nullItem) {
      listToPush[0] = { label: nullItem, value: '' };
    }
    if (dataList)
      dataList.forEach(item => {
        listToPush.push({ label: item.descricao, value: String(item.codigo) });
      });
  }

  async componentDidMount() {
    const {
      tiposLancamentos,
      tipoLancamento,
      contas,
      centrosMonetarios,
      tiposOcorrencias,
      tiposDocumentos,
      operacoes,
      codigo
    } = this.state;

    if (tiposLancamentos.length === 0) {
      const { data: dataLancamentos } = await montaComboTiposLancamentos();
      this.fillArrayForCombo(
        dataLancamentos,
        tiposLancamentos,
        'Selecione um tipo de lançamento'
      );
    }

    if (contas.length === 0) {
      if (tipoLancamento) {
        const { data: dataContas } = await montarComboPlanoContaFinanceiro(
          tipoLancamento
        );
        this.fillArrayForCombo(dataContas, contas, 'Selecione uma conta');
      } else {
        this.fillArrayForCombo(
          null,
          contas,
          'Selecione primeiro um tipo de lançamento'
        );
      }
    }

    if (tiposOcorrencias.length === 0) {
      const { data: dataOcorrencias } = await montaComboTiposOcorrencias();
      this.fillArrayForCombo(dataOcorrencias, tiposOcorrencias, null);
    }

    if (centrosMonetarios.length === 0) {
      const { data: dataCM } = await getCentrosMonetarios();
      dataCM.result.forEach(cm => {
        centrosMonetarios.push({
          label: cm.descricao,
          value: cm.codigoCentroMonetario
        });
      });
      centrosMonetarios.sort((a, b) => {
        return compareStrings(a.label, b.label);
      });
      centrosMonetarios.unshift({
        label: 'Selecione um centro monetário',
        value: ''
      });
    }

    if (tiposDocumentos.length === 0) {
      if (tipoLancamento) {
        const { data: dataDocumentos } = await montaComboTiposDocumentos(
          tipoLancamento
        );
        this.fillArrayForCombo(
          dataDocumentos,
          tiposDocumentos,
          'Selecione um tipo de documento'
        );
      } else {
        this.fillArrayForCombo(
          null,
          tiposDocumentos,
          'Selecione um tipo de documento'
        );
      }
    }

    if (operacoes.length === 0) {
      const { data: dataOperacoes } = await montaComboOperacoes();
      this.fillArrayForCombo(
        dataOperacoes,
        operacoes,
        'Selecione uma operação'
      );
    }

    if (codigo && tipoLancamento) {
      const { data: titulo } = await getTitulo(codigo, tipoLancamento);
      var codigoEstruturaCartao = titulo.cartao ? titulo.cartao.codigoEstruturaCartao : 0;
      this.setState({
        pago: titulo && titulo.hasOwnProperty('pago') ? titulo.pago : false,
        conciliado:
          titulo && titulo.hasOwnProperty('conciliado')
            ? titulo.conciliado
            : false,
        conta: titulo.conta ? String(titulo.conta.codigo) : '',
        centroMonetarioOrigem: titulo.centroMonetario
          ? String(titulo.centroMonetario.codigo)
          : '',
        centroMonetarioDestino: titulo.centroMonetarioDestino
          ? String(titulo.centroMonetarioDestino.codigo)
          : '',
        tipoOcorrencia:
          titulo.recorrencia && titulo.recorrencia.tipoRecorrencia
            ? String(titulo.recorrencia.tipoRecorrencia.codigo)
            : this.state.tipoOcorrencia,
        tipoOcorrenciaOriginal:
          titulo.recorrencia && titulo.recorrencia.tipoRecorrencia
            ? String(titulo.recorrencia.tipoRecorrencia.codigo)
            : this.state.tipoOcorrencia,
        diaVencimento: titulo.recorrencia
          ? titulo.recorrencia.diaVencimento
          : this.state.diaVencimento,
        numeroParcelas: titulo.recorrencia
          ? titulo.recorrencia.numeroParcelas
          : this.state.numeroParcelas,
        pessoa: titulo.pessoa ? titulo.pessoa : this.state.pessoa,
        campoPessoa: titulo.pessoa
          ? titulo.pessoa.descricao
          : this.state.campoPessoa,
        numeroDocumento: titulo.numeroDocumento
          ? titulo.numeroDocumento
          : this.state.numeroDocumento,
        tipoDocumento: titulo.tipoDocumento
          ? String(titulo.tipoDocumento.codigo)
          : this.state.tipoDocumento,
        operacao: titulo.tipoOperacao
          ? String(titulo.tipoOperacao.codigo)
          : this.state.operacao,
        historico: titulo.historico
          ? titulo.historico
          : titulo.centroMonetario && titulo.centroMonetarioDestino
          ? this.getHistorico(
              titulo.centroMonetario.descricao,
              titulo.centroMonetarioDestino.descricao
            )
          : this.state.historico,
        banco:
          titulo.cheque && titulo.cheque.banco
            ? titulo.cheque.banco
            : this.state.banco,
        campoBanco:
          titulo.cheque && titulo.cheque.banco
            ? titulo.cheque.banco.descricao
            : this.state.campoBanco,
        agencia: titulo.cheque ? titulo.cheque.agencia : this.state.agencia,
        contaBanco: titulo.cheque ? titulo.cheque.conta : this.state.contaBanco,
        dataPagamento: titulo.dataPagamento,
        numeroParcela: titulo.parcela,
        indicadorVenda: titulo.indicadorVenda,
        dataEmissao: titulo.dataEmissao,
        dataPrevisao: titulo.dataPrevisao,
        dataOperacao: titulo.dataOperacao,
        dataOperacaoOriginal: titulo.dataOperacao,
        numeroCheque: titulo.cheque ? titulo.cheque.numeroCheque : "",
        percentualTaxaCartao: titulo.cartao ? titulo.cartao.percentualTaxaAdmCartao: 0,
        valorTaxaCartao: titulo.cartao ? titulo.cartao.valorTaxaAdmCartao : 0,
      });
    }

    const listaEstruturaCartoes = await montaComboEstruturaCartoes();

    let tempEstruturaCartoes = listaEstruturaCartoes.filter(
      l => sameInt(l.value, codigoEstruturaCartao)
    );

    this.setState({
      tiposLancamentos,
      contas,
      centrosMonetarios,
      tiposOcorrencias,
      tiposDocumentos,
      operacoes,
      listaEstruturaCartoes,
      estruturaCartoes: tempEstruturaCartoes
    });
  }

  getHistorico(de, para) {
    return 'DE: ' + de + ' - PARA: ' + para;
  }

  isEditing() {
    return !!this.state.isEditing;
  }

  autoCompleteMessage = ctrl => {
    return (
      'Não foi encontrado(a) nenhum(a) ' +
      ctrl.item +
      ' que contenha o texto pesquisado'
    );
  };

  isFormValid = () => {
    const {
      tipoLancamento,
      descricao,
      valor,
      dataVencimento,
      dataEmissao,
      dataPrevisao,
      dataPagamento,
      centroMonetarioDestino,
      centroMonetarioOrigem,
      tipoOcorrencia,
      numeroParcelas,
      tipoDocumento,
      numeroDocumento,
      numeroCheque,
      agencia,
      contaBanco,
      nsu,
      conta,
      operacao,
      pago
    } = this.state;

    let error = null;
    const dV = moment(dataVencimento);
    const dP = dataPrevisao !== undefined ? moment(dataPrevisao) : undefined;
    const dPag = dataPagamento !== undefined ? moment(dataPagamento) : undefined;
    const dE = moment(dataEmissao);
    const fifty = moment()
      .startOf('day')
      .add(50, 'years');
    const smallDate = moment(LAST_SAFE_SMALLDATE).startOf('day');

    if (tipoLancamento === TiposLancamentos.None) {
      error = {
        msg: 'Informe o tipo do lançamento',
        field: 'tipoLancamento'
      };
    } else if (tipoLancamento === TiposLancamentos.Transferencias) {
      if (!valor) {
        error = {
          msg: 'Informe o valor da transferência',
          field: 'valor'
        };
      } else if (!dataVencimento || !dV.isValid()) {
        error = {
          msg: 'Informe uma data de operação válida',
          field: 'dataVencimento'
        };
      } else if (dV.isAfter(fifty) || dV.isAfter(smallDate)) {
        error = {
          msg:
            'Informe uma data de operação anterior a ' +
            getMaxFinalSmallDateString(),
          field: 'dataVencimento'
        };
      } else if (!centroMonetarioOrigem) {
        error = {
          msg: 'Informe o centro monetário de origem',
          field: 'centroMonetarioOrigem'
        };
      } else if (!centroMonetarioDestino) {
        error = {
          msg: 'Informe o centro monetário de destino',
          field: 'centroMonetarioDestino'
        };
      } else if (centroMonetarioOrigem === centroMonetarioDestino) {
        error = {
          msg: 'Centro monetário de origem e de destino não podem ser iguais',
          field: 'centroMonetarioOrigem'
        };
      } else if (numeroDocumento && !isAlphaNumericOnly(numeroDocumento)) {
        error = {
          msg: 'O número do documento não pode conter caracteres especiais',
          field: 'numeroDocumento'
        };
      }
    } else if (this.checkConditions('diversas')) {
      if (!valor) {
        error = {
          msg: 'Informe o valor',
          field: 'valor'
        };
      } else if (!descricao) {
        error = {
          msg: 'Preencha a descrição',
          field: 'descricao'
        };
      } else if (!dataVencimento || !dV.isValid()) {
        error = {
          msg: 'Informe uma data de operação válida',
          field: 'dataVencimento'
        };
      } else if (dV.isAfter(fifty) || dV.isAfter(smallDate)) {
        error = {
          msg:
            'Informe uma data de operação anterior a ' +
            getMaxFinalSmallDateString(),
          field: 'dataVencimento'
        };
      } else if (!conta) {
        error = {
          msg: 'Informe a conta',
          field: 'conta'
        };
      } else if (!operacao) {
        error = {
          msg: 'Informe a operação',
          field: 'operacao'
        };
      } else if (!centroMonetarioOrigem) {
        error = {
          msg: 'Informe o centro monetário',
          field: 'centroMonetarioOrigem'
        };
      }
    } else if (this.checkConditions('pagarReceber')) {
      if (!valor) {
        error = {
          msg: 'Informe o valor do lançamento',
          field: 'valor'
        };
      } else if (!descricao) {
        error = {
          msg: 'Preencha a descrição',
          field: 'descricao'
        };
      } else if (!dataVencimento || !dV.isValid()) {
        error = {
          msg: 'Informe uma data de vencimento válida',
          field: 'dataVencimento'
        };
      } else if (!dataEmissao || !dE.isValid()) {
        error = {
          msg: 'Informe uma data de emissão válida',
          field: 'dataEmissao'
        };
      } else if (dP !== undefined && !dP.isValid()) {
        error = {
          msg: 'Informe uma data de previsão válida',
          field: 'dataPrevisao'
        };
      } else if (pago && dPag === undefined) {
        error = {
          msg: 'Informe uma data de pagamento válida',
          field: 'dataPagamento'
        };
      } else if (pago && dPag !== undefined && !dPag.isValid()) {
        error = {
          msg: 'Informe uma data de pagamento válida',
          field: 'dataPagamento'
        };
      } else if (dV.isAfter(fifty) || dV.isAfter(smallDate)) {
        error = {
          msg:
            'Informe uma data de vencimento anterior a ' +
            getMaxFinalSmallDateString(),
          field: 'dataVencimento'
        };
      } else if (
        moment(dV).format('YYYY-MM-DD') < moment(dE).format('YYYY-MM-DD')
      ) {
        error = {
          msg:
            'Informe uma data de vencimento posterior ou igual a data de emissão',
          field: 'dataVencimento'
        };
      } else if (
        dP !== undefined &&
        moment(dP).format('YYYY-MM-DD') < moment(dE).format('YYYY-MM-DD')
      ) {
        error = {
          msg:
            'Informe uma data de previsão de pagamento posterior ou igual a data de emissão',
          field: 'dataPrevisao'
        };
      } else if (
        dPag !== undefined &&
        moment(dPag).format('YYYY-MM-DD') < moment(dE).format('YYYY-MM-DD')
      ) {
        error = {
          msg:
            'Informe uma data de pagamento posterior ou igual a data de emissão',
          field: 'dataPagamento'
        };
      } else if (!conta) {
        error = {
          msg: 'Informe a conta',
          field: 'conta'
        };
      } else if (!centroMonetarioOrigem) {
        error = {
          msg: 'Informe o centro monetário',
          field: 'centroMonetarioOrigem'
        };
      } else if (!tipoOcorrencia) {
        error = {
          msg: 'Informe o tipo de ocorrência',
          field: 'tipoOcorrencia'
        };
      } else if (!numeroParcelas) {
        error = {
          msg: 'Informe o número de parcelas',
          field: 'numeroParcelas'
        };
      }

      if (tipoOcorrencia !== TiposOcorrencias.Unica) {
        if (
          tipoOcorrencia === TiposOcorrencias.Bimestral ||
          tipoOcorrencia === TiposOcorrencias.Trimestral ||
          tipoOcorrencia === TiposOcorrencias.Semestral ||
          tipoOcorrencia === TiposOcorrencias.Anual
        ) {
          if (Number(numeroParcelas) > 50) {
            error = {
              msg:
                'O número máximo de parcelas permitidas para este tipo ' +
                'de ocorrência é 50',
              field: 'numeroParcelas'
            };
          }
        } else if (Number(numeroParcelas) > 300) {
          error = {
            msg:
              'O número máximo de parcelas permitidas para este tipo ' +
              'de ocorrência é 300',
            field: 'numeroParcelas'
          };
        }
      }
    } else if (tipoLancamento === TiposLancamentos.AReceber) {
      if (tipoDocumento === TiposDocumentos.VendaCheque) {
        if (numeroCheque && !isAlphaNumericOnly(numeroCheque)) {
          error = {
            msg: 'O número do cheque não pode conter caracteres especiais',
            field: 'numeroCheque'
          };
        } else if (agencia && !isAlphaNumericOnly(agencia)) {
          error = {
            msg: 'A agência do banco não pode conter caracteres especiais',
            field: 'agencia'
          };
        } else if (contaBanco && !isAlphaNumericOnly(contaBanco)) {
          error = {
            msg: 'A conta do banco não pode conter caracteres especiais',
            field: 'contaBanco'
          };
        }
      } else if (tipoDocumento === TiposDocumentos.VendaCartao) {
        if (nsu && !isAlphaNumericOnly(nsu)) {
          error = {
            msg: 'NSU não pode conter caracteres especiais',
            field: 'nsu'
          };
        }
      }
    }

    if (error) {
      showError(this, this.state.title, error.msg, error.field);
      return false;
    }
    return true;
  };

  calculateRecorrencia(tipo) {
    const { dataVencimento, diaVencimento, numeroParcelas } = this.state;
    const recorrencia = {};
    switch (tipo) {
      case TiposOcorrencias.Unica:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento).get('date');
        recorrencia.numeroParcelas = 1;
        break;
      case TiposOcorrencias.Parcelada:
        //recorrencia.diaVencimento = moment(dataVencimento).get('date');
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = diaVencimento;
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Diaria:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .add(numeroParcelas, 'days')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Semanal:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .add(7, 'days')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Quinzenal:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .add(15, 'days')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Mensal:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .endOf('month')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Bimestral:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .add(2, 'months')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Trimestral:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .add(3, 'months')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Semestral:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .add(6, 'months')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      case TiposOcorrencias.Anual:
        recorrencia.tipoRecorrencia = { codigo: tipo };
        recorrencia.diaVencimento = moment(dataVencimento)
          .add(1, 'year')
          .get('date');
        recorrencia.numeroParcelas = numeroParcelas;
        break;
      default:
        break;
    }
    return recorrencia;
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    if (!this.isFormValid()) {
      return;
    }

    let {
      codigo,
      tipoLancamento,
      valor,
      valorOriginal,
      descricao,
      dataVencimento,
      dataVencimentoOriginal,
      dataOperacao,
      dataOperacaoOriginal,
      dataPrevisao,
      dataEmissao,
      pago,
      conciliado,
      conta,
      centroMonetarioOrigem,
      centroMonetarioOriginal,
      centroMonetarioDestino,
      centroMonetarioOrigemOriginal,
      centroMonetarioDestinoOriginal,
      centrosMonetarios,
      pessoa,
      campoPessoa,
      numeroDocumento,
      numeroCheque,
      nsu,
      numeroAutorizacao,
      tipoDocumento,
      banco,
      contaBanco,
      agencia,
      estruturaCartoes,
      operacao,
      historico,
      dataPagamento,
      numeroParcela,
      tipoOcorrencia
    } = this.state;

    const recorrencia = this.calculateRecorrencia(tipoOcorrencia);

    const codigoPessoa = campoPessoa && pessoa ? pessoa.codigo : null;
    //const codigoTipoPessoa = campoPessoa && pessoa && pessoa.tipo ?
    //  pessoa.tipo.codigo : null;

    const codigoTipoPessoa =
      campoPessoa && pessoa && pessoa.classificacoes
        ? pessoa.classificacoes[0].codigo
        : null;

    const codigoBanco =
      banco && banco.hasOwnProperty('codigo') ? banco.codigo : null;
    dataOperacao = moment(dataOperacao).format('YYYY-MM-DD');
    dataVencimentoOriginal = moment(dataVencimentoOriginal).format('YYYY-MM-DD');
    const dataVencimentoTratada = moment(dataVencimento).format('YYYY-MM-DD');
    const dataEmissaoTratada = moment(dataEmissao).format('YYYY-MM-DD');
    const dataPrevisaoTratada = dataPrevisao
      ? moment(dataPrevisao).format('YYYY-MM-DD')
      : dataVencimentoTratada;

    dataPagamento =
      !dataPagamento && pago ? moment().format('YYYY-MM-DD') : dataPagamento;

    historico = historico
      ? historico
      : this.getHistorico(
          getLabelByValue(centrosMonetarios, Number(centroMonetarioOrigem)),
          getLabelByValue(centrosMonetarios, Number(centroMonetarioDestino))
        );

    let [action, params] = ['', []];
    if (this.isAPagar() || this.isAReceber()) {
      [action, params] = !!this.isEditing()
        ? [
            alteraContaPagarReceber,
            [
              codigo,
              tipoLancamento,
              descricao,
              valor,
              dataVencimentoTratada,
              dataEmissaoTratada,
              dataPrevisaoTratada,
              pago,
              centroMonetarioOrigem,
              recorrencia,
              codigoTipoPessoa,
              codigoPessoa,
              numeroDocumento,
              numeroCheque,
              numeroAutorizacao,
              nsu,
              tipoDocumento,
              conta,
              codigoBanco,
              contaBanco,
              agencia,
              estruturaCartoes && estruturaCartoes[0]
                ? estruturaCartoes[0].value
                : null, // na alteração quando já tem estrutura, vem um array. Não pode adicionar estrutura quando está em edição
              dataPagamento,
              numeroParcela
            ]
          ]
        : [
            incluiContaPagarReceber,
            [
              tipoLancamento,
              descricao,
              valor,
              dataVencimentoTratada,
              dataEmissaoTratada,
              dataPrevisaoTratada,
              pago,
              centroMonetarioOrigem,
              recorrencia,
              codigoTipoPessoa,
              codigoPessoa,
              numeroDocumento,
              numeroCheque,
              numeroAutorizacao,
              nsu,
              tipoDocumento,
              conta,
              codigoBanco,
              contaBanco,
              agencia,
              estruturaCartoes ? estruturaCartoes.value : null, // na inserção é um objeto com label e value apenas
              dataPagamento
            ]
          ];
    } else if (this.isTransferencia()) {
      [action, params] = this.isEditing()
        ? [
            lancamentoService.alterarTransferencia,
            [
              codigo,
              dataOperacao,
              dataOperacaoOriginal,
              valor,
              valorOriginal,  
              centroMonetarioOrigemOriginal,
              centroMonetarioDestinoOriginal,
              centroMonetarioOrigem,
              centroMonetarioDestino,
              historico,
              conciliado,
              numeroDocumento
            ]
          ]
        : [
            incluiTransferencia,
            [
              dataOperacao,
              valor,
              centroMonetarioOrigem,
              centroMonetarioDestino,
              historico,
              conciliado,
              numeroDocumento,
              tipoDocumento
            ]
          ];
    } else if (this.isDiversas()) {
      [action, params] = this.isEditing()
        ? [
            lancamentoService.alterarLancamento,
            [
              codigo,
              tipoLancamento,
              dataOperacao,
              dataVencimentoOriginal,
              valor,
              valorOriginal,
              centroMonetarioOrigem,
              centroMonetarioOriginal,
              operacao,
              conta,
              descricao,
              conciliado,
              numeroDocumento
            ]
          ]
        : [
            incluiDiversas,
            [
              tipoLancamento,
              dataOperacao,
              valor,
              centroMonetarioOrigem,
              operacao,
              conta,
              descricao,
              conciliado,
              numeroDocumento
            ]
          ];
    }

    this.setState({ salvando: true });
    try {
      await action(...params);
      showInfo(
        this,
        this.state.title,
        `Lançamento ${
          this.isEditing() ? 'alterado' : 'cadastrado'
        } com sucesso!`,
        null,
        () => {
          if (this.props.returnMethod) this.props.returnMethod(false);
          if (this.props.getStateMethod) this.props.getStateMethod(this.state);
          hideAlert(this, this.props.history, this.props.goBackTo);
        }
      );
    } catch (err) {
      let msg =
        err && err.response && err.response.data
          ? 'Mensagem do servidor: ' + err.response.data.message
          : 'Ocorreu um erro';
      showError(this, this.state.title, msg, null, () => {
        hideAlert(this);
      });
    } finally {
      this.setState({ salvando: false });
    }
  };

  doHideAlert = goBack => {
    goBack ? hideAlert(this, this.props.history, goBack) : hideAlert(this);
  };

  handleCancelar = () => {
    showQuestion(
      this,
      title,
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (this.props.returnMethod) this.props.returnMethod(false);
        if (this.props.getStateMethod) this.props.getStateMethod(null);
        this.doHideAlert(resp ? this.props.goBackTo : null);
      }
    );
  };

  getPessoaLabel = () => {
    // return this.isAPagar() ? 'Fornecedor' : 'Cliente ou fornecedor';
    return 'Cliente/Fornecedor';
  };

  isAPagar = () => {
    return this.state.tipoLancamento === TiposLancamentos.APagar;
  };

  isAReceber = () => {
    return this.state.tipoLancamento === TiposLancamentos.AReceber;
  };

  isTransferencia = () => {
    return this.state.tipoLancamento === TiposLancamentos.Transferencias;
  };

  isDiversas = () => {
    return (
      this.state.tipoLancamento === TiposLancamentos.ReceitasDiversas ||
      this.state.tipoLancamento === TiposLancamentos.DespesasDiversas
    );
  };

  checkConditions = item => {
    const { tipoOcorrencia, tipoLancamento, tipoDocumento } = this.state;

    if (item === 'vencimento') {
      return tipoOcorrencia === TiposOcorrencias.Parcelada;
    } else if (item === 'parcelas') {
      return (
        tipoOcorrencia !== TiposOcorrencias.None &&
        tipoOcorrencia !== TiposOcorrencias.Unica
      );
    } else if (item === 'pagarReceber') {
      return (
        tipoLancamento === TiposLancamentos.APagar ||
        tipoLancamento === TiposLancamentos.AReceber
      );
    } else if (item === 'vendaCheque') {
      return (
        tipoLancamento === TiposLancamentos.AReceber &&
        tipoDocumento === TiposDocumentos.VendaCheque
      );
    } else if (item === 'estruturaCartoes') {
      return (
        tipoLancamento === TiposLancamentos.AReceber &&
        tipoDocumento === TiposDocumentos.VendaCartao
      );
    } else if (item === 'transferencia') {
      return tipoLancamento === TiposLancamentos.Transferencias;
    } else if (item === 'diversas') {
      return (
        tipoLancamento === TiposLancamentos.ReceitasDiversas ||
        tipoLancamento === TiposLancamentos.DespesasDiversas
      );
    } else if (item === 'lancamentoNull') {
      return tipoLancamento === TiposLancamentos.None;
    } else return false;
  };

  handleLancamentoChange = async event => {
    const tipoLancamento = event.target.value;
    const tiposDocumentos = [];
    const { data: dataDocumentos } = await montaComboTiposDocumentos(
      tipoLancamento
    );
    this.fillArrayForCombo(
      dataDocumentos,
      tiposDocumentos,
      'Selecione um tipo de documento'
    );

    let contas = [];
    if (tipoLancamento !== TiposLancamentos.Transferencias) {
      const { data: dataContas } = await montarComboPlanoContaFinanceiro(
        tipoLancamento
      );
      this.fillArrayForCombo(dataContas, contas, 'Selecione uma conta');
    } else {
      contas = this.state.contas;
    }

    this.setState({
      tipoLancamento,
      tiposDocumentos,
      contas
    });
  };

  isObjectSelected = object => {
    if (!object) return false;
    return object && object.hasOwnProperty('isSelected') && object.isSelected;
  };

  hasBancoSelected = () => {
    return this.isObjectSelected(this.state.banco);
  };

  hasPessoaSelected = () => {
    return this.isObjectSelected(this.state.pessoa);
  };

  hasCampoBanco = () => {
    return (
      !this.hasBancoSelected() && !emptyText(String(this.state.campoBanco))
    );
  };

  hasCampoPessoa = () => {
    return (
      !this.hasPessoaSelected() && !emptyText(String(this.state.campoPessoa))
    );
  };

  clearBanco = () => {
    this.setState({
      banco: {},
      campoBanco: ''
    });
  };

  clearPessoa = () => {
    this.setState({
      pessoa: {},
      campoPessoa: ''
    });
  };

  handlePesquisarBanco = async pesquisa => {
    if (pesquisa) {
      const codigoBanco = parseInt(pesquisa);
      if (Number.isNaN(codigoBanco)) {
        const { data: dataBancos } = await getBancosByQuery(
          null,
          pesquisa,
          null
        );
        if (
          !dataBancos ||
          !dataBancos.result ||
          dataBancos.result.length === 0
        ) {
          showError(
            this,
            this.state.title,
            'Nenhum banco encontrado com a pesquisa ' + pesquisa + ' informada',
            'campoBanco',
            null
          );
        } else if (dataBancos.result.length === 1) {
          //itaú
          const banco = dataBancos.result[0];
          banco.isSelected = true;
          this.setState({
            banco,
            campoBanco: banco.nome
          });
        } else {
          this.setState({
            showModalBanco: true,
            listaPesquisaBancos: dataBancos.result
          });
        }
      } else {
        try {
          const { data } = await getBanco(codigoBanco);
          if (data) {
            const banco = { ...data };
            banco.isSelected = true;
            this.setState({
              banco,
              campoBanco: banco.nome
            });
          } else {
            showError(
              this,
              this.state.title,
              'Nenhum banco encontrado com a pesquisa ' +
                pesquisa +
                ' informada',
              'campoBanco',
              null
            );
          }
        } catch (e) {
          /* showServerError(this, this.state.title, e, 'Mensagem do servidor: ',
            'Nenhum banco encontrado com a pesquisa '' + pesquisa +
            '' informada', 'campoBanco'); */
          showError(
            this,
            this.state.title,
            'Nenhum banco encontrado com a pesquisa ' + pesquisa + ' informada',
            'campoBanco',
            null
          );
        }
      }
    } else {
      this.setState({
        showModalBanco: false,
        listaPesquisaBancos: []
      });
    }
  };

  handlePesquisarPessoa = async search => {
    const consulta = {
      classificacao: [1, 2], // cliente e fornecedor
      search
    };
    if (search) {
      const { data: dataPessoas } = await getPessoas(consulta);
      if (
        !dataPessoas ||
        !dataPessoas.result ||
        dataPessoas.result.length === 0
      ) {
        showError(
          this,
          this.state.title,
          'Nenhum cliente ou fornecedor encontrado com a pesquisa ' +
            search +
            ' informada',
          'campoPessoa',
          null
        );
      } else if (dataPessoas.result.length === 1) {
        //ASSOCIACAO CATOLICA RAINHA DAS VIRGENS
        const pessoa = dataPessoas.result[0];
        pessoa.isSelected = true;
        this.setState({
          pessoa,
          campoPessoa: pessoa.nome
        });
      } else {
        this.setState({
          showModalClienteFornecedor: true,
          clientesFornecedores: dataPessoas.result
        });
      }
    } else {
      this.setState({
        showModalClienteFornecedor: false,
        clientesFornecedores: []
      });
    }
  };

  handleCloseModal = pessoa => {
    const campoPessoa = pessoa ? pessoa.nome : this.state.campoPessoa;
    const p = pessoa ? pessoa : this.state.pessoa; // se vier null, não pode apagar uma pesquisa prévia
    this.setState({
      pessoa: p,
      campoPessoa,
      showModalClienteFornecedor: false
    });
  };

  handleCloseModalBanco = banco => {
    const campoBanco = banco ? banco.nome : this.state.campoBanco;
    const b = banco ? banco : this.state.banco;
    this.setState({
      banco: b,
      campoBanco,
      showModalBanco: false
    });
  };

  render() {
    const {
      tipoLancamento,
      tiposLancamentos,
      valor,
      descricao,
      dataVencimento,
      dataEmissao,
      dataPrevisao,
      pago,
      conciliado,
      conta,
      contas,
      centroMonetarioOrigem,
      centroMonetarioDestino,
      centrosMonetarios,
      tipoOcorrencia,
      tiposOcorrencias,
      numeroParcelas,
      campoPessoa,
      numeroDocumento,
      numeroCheque,
      tipoDocumento,
      tiposDocumentos,
      campoBanco,
      agencia,
      contaBanco,
      estruturaCartoes,
      historico,
      operacao,
      operacoes,
      disableControls,
      dataOperacao,
      percentualTaxaCartao,
      valorTaxaCartao,
      numeroAutorizacao, 
      nsu,
      isPDV,
      dataPagamento
    } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={`${getNomePagina(PATH_MENU)} Detalhes`}>
          <SectionContainer>
            <SectionContent title="Lançamento financeiro">
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Tipo de lançamento"
                    name="tipoLancamento"
                    value={tipoLancamento}
                    onChange={this.handleLancamentoChange}
                    options={tiposLancamentos}
                    disabled={this.isEditing()}
                    help="Obrigatório"
                  />
                </div>
                <div
                  className={ifShow(
                    tipoLancamento !== TiposLancamentos.None,
                    'col-6'
                  )}
                >
                  <InputText
                    label="Valor (R$)"
                    name="valor"
                    value={valor}
                    onChange={(e)=> {
                      var taxaCartao = this.state.percentualTaxaCartao;
                      var valorFormatado = parseFloat(e.value);
                      this.setState({valor: valorFormatado, valorTaxaCartao: taxaCartao/100 * valorFormatado})
                    }}
                    type="number"
                    maxlength={10}
                    decimalScale={2}
                    allowNegative={false}
                    disabled={disableControls}
                    help="Obrigatório"
                  />
                </div>
              </div>
              {this.checkConditions('transferencia') ? (
                <></>
              ) : (
                <div
                  className={ifShow(
                    tipoLancamento !== TiposLancamentos.None,
                    'row'
                  )}
                >
                  <div className="col-12">
                    <InputText
                      label="Descrição"
                      name="descricao"
                      value={descricao}
                      maxlength={100}
                      onChange={this.handleInputChange}
                      disabled={disableControls}
                      help="Obrigatório"
                    />
                  </div>
                </div>
              )}
              <div
                className={ifShow(
                  tipoLancamento !== TiposLancamentos.None,
                  'row'
                )}
              >
                {this.checkConditions('pagarReceber') ? (
                  <div className="col-2">
                    <InputDate
                      value={dataEmissao}
                      name="dataEmissao"
                      label="Data emissão"
                      onChange={this.handleInputChange}
                      disabled={disableControls}
                      help="Obrigatório"
                    />
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-2">
                  <InputDate
                    value={this.checkConditions('pagarReceber')
                    ? dataVencimento
                    : dataOperacao}
                    name={this.checkConditions('pagarReceber')
                    ? 'dataVencimento'
                    : 'dataOperacao'}
                    label={
                      this.checkConditions('pagarReceber')
                        ? 'Data vencimento'
                        : 'Data da operação'
                    }
                    onChange={this.handleInputChange}
                    disabled={disableControls}
                    help="Obrigatório"
                  />
                </div>
                {this.checkConditions('pagarReceber') ? (
                  <div className="col-2">
                    <InputDate
                      value={dataPrevisao}
                      name="dataPrevisao"
                      label="Previsão de pagamento"
                      onChange={this.handleInputChange}
                      disabled={disableControls}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {this.isAPagar() || this.isAReceber() ? (
                  <>
                  <div className="col-2">
                    <Checkbox>
                      <Checkitem
                        label="Pago"
                        name="pago"
                        checked={pago}
                        onChange={this.handleInputChange}
                        disabled={disableControls}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-2">
                    <InputDate
                      value={dataPagamento}
                      name={'dataPagamento'}
                      label={'Data de pagamento'}
                      onChange={this.handleInputChange}
                      disabled={disableControls || !pago}
                      help={pago ? "Obrigatório" : ""}
                    />
                  </div>
                  </>
                ) : (
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Conciliado"
                        name="conciliado"
                        checked={conciliado}
                        onChange={this.handleInputChange}
                        disabled={disableControls}
                      />
                    </Checkbox>
                  </div>
                )}
              </div>
              {this.checkConditions('pagarReceber') ||
              this.checkConditions('lancamentoNull') ? (
                <>
                  <div
                    className={ifShow(
                      tipoLancamento !== TiposLancamentos.None,
                      'row'
                    )}
                  >
                    <div className="col-6">
                      <Select
                        label="Conta"
                        name="conta"
                        value={conta}
                        onChange={this.handleInputChange}
                        options={contas}
                        disabled={disableControls}
                        help="Obrigatório"
                      />
                    </div>
                    <div className="col-6">
                      <Select
                        label="Centro monetário"
                        name="centroMonetarioOrigem"
                        value={centroMonetarioOrigem}
                        onChange={this.handleInputChange}
                        options={centrosMonetarios}
                        disabled={disableControls}
                        help="Obrigatório"
                      />
                    </div>
                  </div>
                  <div
                    className={ifShow(
                      tipoLancamento !== TiposLancamentos.None,
                      'row'
                    )}
                  >
                    <div className="col-6">
                      <Select
                        label="Tipo de ocorrência"
                        name="tipoOcorrencia"
                        value={tipoOcorrencia}
                        onChange={this.handleInputChange}
                        options={tiposOcorrencias}
                        disabled={disableControls || this.isEditing()}
                        help="Obrigatório"
                      />
                    </div>
                    <div
                      className={ifShow(
                        this.checkConditions('parcelas'),
                        'col-4'
                      )}
                    >
                      <InputText
                        label="Nº de parcelas"
                        name="numeroParcelas"
                        value={numeroParcelas}
                        onChange={this.handleInputChange}
                        type="number"
                        maxlength={3}
                        allowNegative={false}
                        disabled={disableControls || this.isEditing()}
                        help="Obrigatório"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
              {this.checkConditions('transferencia') ? (
                <div
                  className={ifShow(
                    tipoLancamento !== TiposLancamentos.None,
                    'row'
                  )}
                >
                  <div className="col-6">
                    <Select
                      label="Centro monetário de origem"
                      name="centroMonetarioOrigem"
                      value={centroMonetarioOrigem}
                      onChange={this.handleInputChange}
                      options={centrosMonetarios}
                      disabled={disableControls}
                      help="Obrigatório"
                    />
                  </div>
                  <div className="col-6">
                    <Select
                      label="Centro monetário de destino"
                      name="centroMonetarioDestino"
                      value={centroMonetarioDestino}
                      onChange={this.handleInputChange}
                      options={centrosMonetarios}
                      disabled={disableControls}
                      help="Obrigatório"
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {this.checkConditions('diversas') ? (
                <>
                  <div
                    className={ifShow(
                      tipoLancamento !== TiposLancamentos.None,
                      'row'
                    )}
                  >
                    <div className="col-6">
                      <Select
                        label="Conta"
                        name="conta"
                        value={conta}
                        onChange={this.handleInputChange}
                        options={contas}
                        disabled={disableControls}
                        help="Obrigatório"
                      />
                    </div>
                    <div className="col-6">
                      <Select
                        label="Operação"
                        name="operacao"
                        value={operacao}
                        onChange={this.handleInputChange}
                        options={operacoes}
                        disabled={disableControls}
                        help="Obrigatório"
                      />
                    </div>
                  </div>
                  <div
                    className={ifShow(
                      tipoLancamento !== TiposLancamentos.None,
                      'row'
                    )}
                  >
                    <div className="col-6">
                      <Select
                        label="Centro monetário"
                        name="centroMonetarioOrigem"
                        value={centroMonetarioOrigem}
                        onChange={this.handleInputChange}
                        options={centrosMonetarios}
                        disabled={disableControls}
                        help="Obrigatório"
                      />
                    </div>
                    <div className="col-6">
                      <InputText
                        label="Número do documento"
                        name="numeroDocumento"
                        value={numeroDocumento}
                        type="text"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        maxlength={17}
                        onChange={e => {
                          if (e.target.validity.valid)
                            this.setState({ numeroDocumento: e.target.value });
                        }}
                        disabled={disableControls}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </SectionContent>
            {this.checkConditions('diversas') ||
            tipoLancamento === TiposLancamentos.None ? (
              <></>
            ) : (
              <SectionContent
                title="Mais opções"
                accordionDefaultDisplay={false}
                accordion
              >
                {this.isAPagar() || this.isAReceber() ? (
                  <>
                    <ModalClienteFornecedor
                      active={this.state.showModalClienteFornecedor}
                      handleCloseModal={this.handleCloseModal}
                      lista={this.state.clientesFornecedores}
                    />
                    <div className="row">
                      <div className="col-12">
                        <InputText
                          value={campoPessoa}
                          name="campoPessoa"
                          label={this.getPessoaLabel()}
                          placeholder={defaultSearchText}
                          maxlength={40}
                          onChange={this.handleInputChange}
                          icone={
                            disableControls
                              ? null
                              : this.hasPessoaSelected()
                              ? 'icon-lx-close'
                              : this.hasCampoPessoa()
                              ? 'icon-lx-search'
                              : null
                          }
                          help={
                            disableControls
                              ? null
                              : this.hasPessoaSelected()
                              ? "Clique no 'x' para limpar o campo"
                              : this.hasCampoPessoa()
                              ? "Clique na 'lupa' para pesquisar"
                              : null
                          }
                          onIconeClick={() => {
                            if (this.hasPessoaSelected()) {
                              this.clearPessoa();
                              setFocus('campoPessoa');
                            } else this.handlePesquisarPessoa(campoPessoa);
                          }}
                          onKeyDown={
                            campoPessoa
                              ? event => {
                                  if (event.keyCode === 13)
                                    this.handlePesquisarPessoa(campoPessoa);
                                  else if (event.keyCode === 27)
                                    this.clearPessoa();
                                }
                              : null
                          }
                          disabled={disableControls}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {this.checkConditions('pagarReceber') ? (
                  <div className="row">
                    <div className="col-6">
                      <Select
                        label="Tipo de documento"
                        name="tipoDocumento"
                        value={tipoDocumento}
                        onChange={this.handleInputChange}
                        options={tiposDocumentos}
                        disabled={disableControls}
                      />
                    </div>
                    {this.checkConditions('vendaCheque') ? (
                    <div className="col-6">
                      <InputText
                        label={'Número do cheque'}
                        name="numeroCheque"
                        value={numeroCheque}
                        type="text"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        maxlength={17}
                        onChange={e => {
                          if (e.target.validity.valid)
                            this.setState({ numeroCheque: e.target.value });
                        }}
                        disabled={disableControls || isPDV}
                      />
                    </div>
                    ) : (                      
                        <div className="col-6">
                          <InputText
                            label={'Número do documento'}
                            name="numeroDocumento"
                            value={numeroDocumento}
                            type="text"
                            pattern="^-?[0-9]\d*\.?\d*$"
                            maxlength={17}
                            onChange={e => {
                              if (e.target.validity.valid)
                                this.setState({ numeroDocumento: e.target.value });
                            }}
                            disabled={disableControls}
                          />
                        </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {this.checkConditions('transferencia') ? (
                  <div className="row">
                    <div className="col-12">
                      <InputText
                        label={'Número do documento'}
                        name="numeroDocumento"
                        value={numeroDocumento}
                        type="text"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        onChange={e => {
                          if (e.target.validity.valid)
                            this.setState({ numeroDocumento: e.target.value });
                        }}
                        maxlength={17}
                        disabled={disableControls}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={ifShow(
                    this.checkConditions('estruturaCartoes'),
                    'row'
                  )}
                >
                  <div className="col-12">
                    <EstruturaCartoesSelect
                      layoutAntigo={true}
                      selectedEstruturaCartoes={estruturaCartoes}
                      selectTaxaEstruturaCartoes={(taxa) => {
                        var valorTitulo = this.state.valor;
                        this.setState({percentualTaxaCartao: taxa, valorTaxaCartao: taxa/100 * valorTitulo})
                      }}
                      selectEstruturaCartoes={(label, value) => {
                        this.setState({estruturaCartoes: { label: label, value: value }
                        });
                      }}
                      disabled={disableControls || isPDV}
                    />
                  </div>
                </div>
                <div
                  className={ifShow(
                    this.checkConditions('estruturaCartoes'),
                    'row'
                  )}
                >
                    <div className="col-6">
                      <InputText
                        label="Autorização"
                        name="numeroAutorizacao"
                        type="text"
                        onChange={e => {
                          this.setState({ numeroAutorizacao: e.target.value });
                        }}
                        pattern={NUMERIC_PATTERN}
                        value={numeroAutorizacao}
                        disabled={disableControls || isPDV}
                      />
                    </div>
                    <div className="col-6">
                      <InputText
                        label={'NSU'}
                        name="nsu"
                        value={nsu}
                        type="text"
                        pattern={ALPHANUMERIC_PATTERN}
                        maxlength={12}
                        onChange={e => {
                          if (e.target.validity.valid)
                            this.setState({ nsu: e.target.value });
                        }}
                        disabled={disableControls || isPDV}
                      />
                    </div>
                  </div>
                  <div
                  className={ifShow(
                    this.checkConditions('estruturaCartoes'),
                    'row'
                  )}
                  >
                  <div className="col-6">
                    <InputText
                      label="% Taxa cartão"
                      name="percentualTaxaCartao"
                      value={toFloatFormattedDisplayDecimals(percentualTaxaCartao,2)}
                      disabled={true}
                    />
                  </div>
                  <div className="col-6">
                    <InputText
                      label="Valor taxa cartão (R$)"
                      name="valorTaxaCartao"
                      value={toFloatFormattedDisplayDecimals(valorTaxaCartao,2)}
                      disabled={true}
                    />
                  </div>
                </div>
                <div
                  className={ifShow(this.checkConditions('vendaCheque'), 'row')}
                >
                  <div
                    className={ifShow(
                      this.checkConditions('vendaCheque'),
                      'col-6'
                    )}
                  >
                    <ModalBanco
                      active={this.state.showModalBanco}
                      handleCloseModal={this.handleCloseModalBanco}
                      lista={this.state.listaPesquisaBancos}
                    />
                    <InputText
                      value={campoBanco}
                      name="campoBanco"
                      label="Banco"
                      placeholder="Pesquise pelo código ou nome do banco"
                      maxlength={40}
                      onChange={this.handleInputChange}
                      icone={
                        this.hasBancoSelected()
                          ? 'icon-lx-close'
                          : this.hasCampoBanco()
                          ? 'icon-lx-search'
                          : null
                      }
                      help={
                        this.hasBancoSelected()
                          ? "Clique no 'x' para limpar o campo"
                          : this.hasCampoBanco()
                          ? "Clique na 'lupa' para pesquisar"
                          : null
                      }
                      onIconeClick={() => {
                        if (this.hasBancoSelected()) {
                          this.clearBanco();
                          setFocus('campoBanco');
                        } else this.handlePesquisarBanco(campoBanco);
                      }}
                      onKeyDown={
                        campoBanco
                          ? event => {
                              if (event.keyCode === 13)
                                this.handlePesquisarBanco(campoBanco);
                              else if (event.keyCode === 27) this.clearBanco();
                            }
                          : null
                      }
                      disabled={disableControls}
                    />
                  </div>
                  <div
                    className={ifShow(
                      this.checkConditions('vendaCheque'),
                      'col-4'
                    )}
                  >
                    <InputText
                      label="Agência"
                      name="agencia"
                      value={agencia}
                      //onChange={this.handleInputChange}
                      type="text"
                      maxlength={20}
                      disabled={disableControls}
                      pattern={ALPHANUMERIC_PATTERN}
                      onChange={e => {
                        if (e.target.validity.valid)
                          this.setState({ agencia: e.target.value });
                      }}
                    />
                  </div>
                  <div
                    className={ifShow(
                      this.checkConditions('vendaCheque'),
                      'col-4'
                    )}
                  >
                    <InputText
                      label="Conta"
                      name="contaBanco"
                      value={contaBanco}
                      //onChange={this.handleInputChange}
                      type="text"
                      maxlength={20}
                      disabled={disableControls}
                      pattern={ALPHANUMERIC_PATTERN}
                      onChange={e => {
                        if (e.target.validity.valid)
                          this.setState({ contaBanco: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div
                  className={ifShow(
                    this.checkConditions('transferencia'),
                    'row'
                  )}
                >
                  <div
                    className={ifShow(
                      this.checkConditions('transferencia'),
                      'col-12'
                    )}
                  >
                    <InputText
                      label="Histórico"
                      name="historico"
                      value={historico}
                      onChange={this.handleInputChange}
                      type="text"
                      maxlength={50}
                      disabled={disableControls}
                    />
                  </div>
                </div>
              </SectionContent>
            )}
          </SectionContainer>
        </LinxPostos>

        <Footer
          saveAction={
            this.isEditing() && !menuPermiteAlteracao(PATH_MENU)
              ? null
              : this.handleSalvar
          }
          cancelAction={this.handleCancelar}
          disabled={this.state.salvando}
        />
        {tagAlert(this)}
      </>
    );
  }
}

function ifShow(condition, divName) {
  return condition ? divName : 'content-hidden';
}

FormLancamento = withRouter(FormLancamento);
export { FormLancamento };

export class ModalClienteFornecedor extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo && rowInfo.original) {
      rowInfo.original.isSelected = true;
      this.props.handleCloseModal(rowInfo.original);
    }
  };

  render() {
    const { active, handleCloseModal, lista } = this.props;

    return (
      <div>
        <Modal
          className={`${active ? 'active' : null} modal-filter`}
          onClose={() => handleCloseModal(null)}
        >
          <ModalHeader
            title={
              this.props.headerTitle
                ? this.props.headerTitle
                : 'Pesquisar Cliente/Fornecedor'
            }
            subtitle=""
          />

          <ModalBody>
            <SectionContent
              title={
                this.props.bodyTitle
                  ? this.props.bodyTitle
                  : 'Selecione um cliente ou fornecedor'
              }
            >
              <ReactTable
                defaultPageSize={5}
                onClick={this.handleTableClick}
                data={lista}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
                columns={[
                  {
                    accessor: 'codigo',
                    Header: 'Código'
                  },
                  {
                    accessor: 'nome',
                    Header: 'Nome',
                    //style: { "white-space": "unset" },
                    filterable: false
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      this.handleTableClick(
                        state,
                        rowInfo,
                        column,
                        instance,
                        e
                      );
                    }
                  };
                }}
              />
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal(null);
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export class ModalBanco extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo && rowInfo.original) {
      rowInfo.original.isSelected = true;
      this.props.handleCloseModal(rowInfo.original);
    }
  };

  render() {
    const { active, handleCloseModal, lista } = this.props;

    return (
      <div>
        <Modal
          className={`${active ? 'active' : null} modal-filter`}
          onClose={() => handleCloseModal(null)}
        >
          <ModalHeader title="Pesquisar Banco" subtitle="" />

          <ModalBody>
            <SectionContent title="Selecione um banco">
              <ReactTable
                defaultPageSize={5}
                onClick={this.handleTableClick}
                data={lista}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
                columns={[
                  {
                    accessor: 'codigo',
                    Header: 'Código'
                  },
                  {
                    accessor: 'nome',
                    Header: 'Nome',
                    //style: { "white-space": "unset" },
                    filterable: false
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      this.handleTableClick(
                        state,
                        rowInfo,
                        column,
                        instance,
                        e
                      );
                    }
                  };
                }}
              />
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal(null);
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export class ModalEstruturaCartoes extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo && rowInfo.original) {
      rowInfo.original.isSelected = true;
      this.props.handleCloseModal(rowInfo.original);
    }
  };

  render() {
    const { active, handleCloseModal, lista } = this.props;

    return (
      <div>
        <Modal
          className={`${active ? 'active' : null} modal-filter`}
          onClose={() => handleCloseModal(null)}
        >
          <ModalHeader title="Pesquisar Estrutura de cartões" subtitle="" />

          <ModalBody>
            <SectionContent title="Selecione uma estrutura de cartões">
              <ReactTable
                defaultPageSize={5}
                onClick={this.handleTableClick}
                data={lista}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
                columns={[
                  {
                    accessor: 'codigo',
                    Header: 'Código'
                  },
                  {
                    accessor: 'rede',
                    Header: 'Rede'
                  },
                  {
                    accessor: 'bandeira',
                    Header: 'Bandeira'
                  },
                  {
                    accessor: 'formaPagamento',
                    Header: 'Forma Pgto'
                  },
                  {
                    accessor: 'produtoRede',
                    Header: 'Produto'
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      this.handleTableClick(
                        state,
                        rowInfo,
                        column,
                        instance,
                        e
                      );
                    }
                  };
                }}
              />
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal(null);
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
