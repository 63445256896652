import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';

import styles from './DragAndDropFiles.module.scss';

export default function DragAndDropFiles({
  dragAndDropText,
  buttonText,
  acceptedFilesTypesText,
  acceptedFilesTypes,
  maxFiles,
  setFiles
}) {
  const fileInputRef = useRef();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [isDragEnter, setIsDragEnter] = useState(false);

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find(item => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  useEffect(() => {
    if (validFiles.length > 0) {
      setFiles(validFiles);
    }
  }, [validFiles]);

  //#region Eventos padrões drag-and-drop

  const preventDefault = e => {
    e.preventDefault();
  };

  const dragOver = e => {
    setIsDragEnter(true);
    preventDefault(e);
  };

  const dragEnter = e => {
    setIsDragEnter(true);
    preventDefault(e);
  };

  const dragLeave = e => {
    setIsDragEnter(false);
    preventDefault(e);
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  //#endregion

  const fileDrop = e => {
    setIsDragEnter(false);
    preventDefault(e);
    const files = e.dataTransfer.files;

    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = files => {
    for (let i = 0; i < maxFiles; i++) {
      if (typeof files[i] === 'undefined') {
        return;
      }
      if (validateFile(files[i])) {
        if (
          validFiles.length < maxFiles &&
          validFiles.findIndex(e => e.name === files[i].name) === -1
        ) {
          setSelectedFiles(prevArray => [...prevArray, files[i]]);
        }
      } else {
        notify(
          `Arquivo "${files[i].name}" não permitido! Favor enviar um arquivo válido.`,
          'warning',
          2000,
          'top'
        );
      }
    }
  };

  const validateFile = file => {
    return acceptedFilesTypes.indexOf(file.type) !== -1;
  };

  const fileSize = size => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const fileType = fileName => {
    return (
      fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
      fileName
    );
  };

  const removeFile = name => {
    const validFileIndex = validFiles.findIndex(e => e.name === name);
    validFiles.splice(validFileIndex, 1);

    setValidFiles([...validFiles]);

    const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);
    setSelectedFiles([...selectedFiles]);
  };

  return (
    <div className={styles.container}>
      {validFiles.length < maxFiles && (
        <div
          className={`${styles.fileUploadContainer} ${isDragEnter &&
            styles.fileUploadContainerDragEnter}`}
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
        >
          {isDragEnter ? (
            <h1> Solte os arquivos aqui </h1>
          ) : (
            <>
              {dragAndDropText ? (
                <span>{dragAndDropText}</span>
              ) : (
                <span>Arraste e solte aqui o(s) arquivo(s)</span>
              )}
              <span>ou</span>
              <div className={styles.fileUploadButton}>
                <Button variant="primary" onClick={fileInputClicked}>
                  <i className="icon icon-lx-upload" />
                  {buttonText}
                </Button>
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  hidden
                  onChange={filesSelected}
                />
                <span>{acceptedFilesTypesText}</span>
              </div>
            </>
          )}
        </div>
      )}
      <div className={styles.fileDisplayContainer}>
        {validFiles.map((data, i) => (
          <div className={styles.fileStatusBar} key={data.name}>
            <div>
              <div className={styles.fileTypeLogo}></div>
              <div className={styles.fileType}>{fileType(data.name)}</div>
              <span
                className={`${styles.fileName} ${
                  data.invalid ? styles.fileError : ''
                }`}
              >
                {data.name}
              </span>
              <span className={styles.fileSize}>({fileSize(data.size)})</span>
            </div>
            <div
              className={styles.fileRemove}
              onClick={() => removeFile(data.name)}
            >
              X
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
