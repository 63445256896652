import React from 'react';
import {
  sameString,
  toFloatFormattedDisplayDecimals
} from '../../../shared/utils/Utils';
import styles from './LinxMoneyLabel.module.scss';

export const LinxMoneyLabel = props => {
  const title = props.title || 'Valor';
  const value = props.value || 0;

  const getColor = () => {
    if (sameString(props.color, 'red')) {
      return `colorRed`;
    } else if (
      sameString(props.color, 'purple') ||
      sameString(props.color, 'linx')
    ) {
      return `colorPurple`;
    } else {
      return `colorBlack`;
    }
  };

  return (
    <div className={`linxMoneyLabel`}>
      <div>
        <h3>{title}</h3>
      </div>
      <div className={getColor()}>
        <h1>
          {props.showMoneySymbol ? 'R$' : null}{' '}
          {toFloatFormattedDisplayDecimals(String(value), 2)}
        </h1>
      </div>
    </div>
  );
};
