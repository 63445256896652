import { UrlLinxCoreService } from "../../../../services/core/urlLinxCore.service";

export class ApiBuscarTransportadoraService {
  static instance = null;
  static getInstance() {
    if (!ApiBuscarTransportadoraService.instance) {
      ApiBuscarTransportadoraService.instance = new ApiBuscarTransportadoraService();
    }
    return ApiBuscarTransportadoraService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async (arg) => {
    try {
      const response = await this.urlLinxCore.get(
        `/Transportadora/Buscar?args=${arg || ''}&limit=10`
      );
      return response.data.data;
    } catch (error) {
      return error.response.data;
    }
  }
}
