import React from 'react';
import BotaoCarregamento from '../../botao/BotaoCarregamento/BotaoCarregamento';

import styles from './Footer.module.scss';

export default function Footer(props) {
  return (
    <footer className={`${styles.footer} fixed-bottom`}>
      {props.children}

      {props.returnAction && (
        <button className='btn btn-light' onClick={props.returnAction}>
          <i className='icon icon-lx-angle-left'></i>
          Voltar
        </button>
      )}

      {props.clearAction && (
        <button className='btn btn-link' onClick={props.clearAction}>
          Limpar
        </button>
      )}

      {props.cancelAction && (
        <button className='btn btn-light' onClick={props.cancelAction}>
          Cancelar
        </button>
      )}

      {props.deleteAction && (
        <button
          className={`${styles.saveButton} btn btn-secondary btn-sm send`}
          onClick={props.deleteAction}
        >
          <i className='icon icon-lx-close'></i>
          Excluir
        </button>
      )}

      {props.customList &&
        props.customList.map(item => (
          <button
            key={item.name}
            className={`${styles.saveButton} btn ${item.class ? item.class : 'btn-primary'
              } btn-sm send`}
            onClick={item.action}
          >
            {item.icon ? <i className={`icon ${item.icon}`}></i> : ''}
            {item.name}
          </button>
        ))}

      {props.saveAction && (
        <button
          className={`${styles.saveButton} btn btn-primary btn-sm send`}
          onClick={props.saveAction}
          disabled={props.disabled}
        >
          <i className='icon icon-lx-check'></i>
          Salvar
        </button>
      )}

      {props.anonimizarAction && (
        <button
          className={`${styles.anonimizarButton} btn btn-success btn-sm send`}
          onClick={props.anonimizarAction}
        >
          <i className='icon icon-lx-vision'></i>
          Anonimizar
        </button>
      )}

      {props.printAction && (
        <button
          className={`${styles.printButton} btn btn-primary btn-sm send`}
          onClick={props.printAction}
        >
          <i className='icon icon-lx-receipt'></i>
          Imprimir relatório de uso de dados
        </button>
      )}

      {props.eliminarAction && (
        <button
          className={`${styles.eliminarButton} btn btn-primary btn-sm send`}
          onClick={props.eliminarAction}
        >
          <i className='icon icon-lx-trash'></i>
          Eliminar Dados
        </button>
      )}

      {props.concluirAction && (
        <button
          className={`${styles.concluirButton} btn btn-success btn-sm send`}
          onClick={props.concluirAction}
        >
          <i className='icon icon-lx-check'></i>
          Concluir solicitação LGPD
        </button>
      )}

      {props.quitarAction && (
        <BotaoCarregamento click={props.quitarAction} disabled={props.disabled} loading={props.paying} carregando='Quitando...' carregar='Quitar' icon='icon icon-lx-payments'/>
      )}

      {props.cancelQuitacaoAction && (
        <BotaoCarregamento click={props.cancelQuitacaoAction} disabled={props.disabled} loading={props.canceling} carregando='Cancelando...' carregar='Confirmar cancelamento' icon='icon icon-lx-check'/>
      )}
    </footer>
  );
}
