import React, { Component } from "react";
import Botao from "../../../../components/botao/Botao";
import {
  InputText,
  Select,
  // FormOptions,
  handleInputChange,
  Checkbox,
  Checkitem,
  InputDate
} from "../../../../components/formulario/Formulario";
import List from "../../../../components/formulario/List";
import SectionHeader from "../../../../components/section/Header";
import {
  SectionContainer,
  SectionContent
} from "../../../../components/section/Content";
import {
  // Alert,
  tagAlert,
  showWait,
  hideWait,
  // showErrorr,
  showInfo,
  showError,
  showQuestion,
  hideAlert,
  showSaveError,
  showDeleteError
} from "../../../../components/alert/Alert";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  getCnpj,
  menuPermiteInclusao,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  validaDadosLogin,
  getNomePagina,
} from "../../../../shared/utils/Utils";

import {
  getBombas,
  incluirBomba,
  alterarBomba,
  excluirBomba,
  montaComboPDV,
  montaComboFabricantes
} from "./Bombas.service";
import { ALPHANUMERIC_WITH_HYPHEN_PATTERN } from "../../../../shared/constants/Const";
import LinxPostos from "../../../../components/core/linxPostos/LinxPostos";
import { MAPA_PATH } from "../MapaDePista";
import Footer from "../../../../components/core/footer/Footer";

import { menus } from '../../../../shared/constants/MenuConstants';


const PATH_MENU = menus.COD_60530;
const NEW_PATH = "/cadastro/tanques-bombas-bicos/bomba/new";
const titulo = "Bombas";

class FormCadastroBomba extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      cnpjEstabelecimento = getCnpj(),
      codigoBomba = null,
      codigoMaquinaPdv = "'",
      maquinaPdv = "",
      numeroBomba = "",
      numeroSerie = "",
      codigoFabricante = "",
      fabricante = "",
      modelo = "",
      //Itens com valor default
      codigoTipo = 1,
      tipo = "Eletrônica",
      casasDecimais = 3,
      possuiBicosVinculados = false,
      inativo = false,
      excluido = false,
      rowVersion = null,

      lacres = [],

      salvando
    } = this.props.bomba;

    this.state = {
      cnpjEstabelecimento,
      codigoBomba,
      codigoMaquinaPdv,
      maquinaPdv,
      numeroBomba,
      numeroSerie,
      codigoFabricante,
      fabricante,
      modelo,
      codigoTipo,
      tipo,
      casasDecimais,
      possuiBicosVinculados,
      ativo: !inativo,
      excluido,
      rowVersion,

      //lacres
      lacres,

      //Alerts
      alertActive: false,
      alertType: "",
      alertTitle: "",
      alertSubtitle: "",

      bombasExistentes: [],

      salvando
    };
  }

  //Para o combo fabricantes (pessoa do tipo fabricante)
  async componentDidMount() {
    showWait(
      this,
      titulo,
      "Por favor, aguarde enquanto o formulário é carregado."
    );

    const { data: fabricantes } = await montaComboFabricantes(getCnpj());
    this.setState({
      fabricantes: fabricantes.map(fabricante => {
        return {
          label: fabricante.descricao,
          value: fabricante.codigo
        }
      })
    });

    let primeiroFab = fabricantes.codigo;
    let descricaoFab = fabricantes.descricao;
    if (!this.props.bomba.rowVersion) {
      for (let i = 0; i < fabricantes.length; i++) {
        const fab = fabricantes[i];
        primeiroFab = fab.codigo;
        descricaoFab = fab.descricao;
        break;
      }
      this.setState({ codigoFabricante: primeiroFab, fabricante: descricaoFab });
    }

    const { data: pdvs } = await montaComboPDV(getCnpj());
    this.setState({
      pdvs: pdvs.map(pdv => {
        return {
          label: pdv.descricao,
          value: pdv.codigo
        }
      })
    });

    let primeiroPdv = pdvs.codigo;
    let descricaoPdv = pdvs.descricao;
    if (!this.props.bomba.rowVersion) {
      for (let i = 0; i < pdvs.length; i++) {
        const pdv = pdvs[i];
        primeiroPdv = pdv.codigo;
        descricaoFab = pdv.descricao;
        break;
      }
      this.setState({ codigoMaquinaPdv: primeiroPdv, maquinaPdv: descricaoPdv });
    }

    //Tentativa de preencher vazio, para mostrar edits no grid na inserção
    if (this.state.lacres.length === 0) {
      let lacs = [];
      let lacre = {
        numeroLacre: "",
        dataAplicacao: ""
      }

      lacs.push(lacre);
      this.setState({ lacres: lacs });
    }

    hideWait(this);
  }

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }
    const {
      cnpjEstabelecimento,
      possuiBicosVinculados,
      codigoBomba
    } = this.state;

    showQuestion(
      this,
      titulo,
      "Deseja realmente excluir?",
      null,
      async resp => {
        if (resp) {
          try {
            if (possuiBicosVinculados) {
              showError(this, titulo,
                "Bomba não pode ser excluída, pois possui bicos vinculados ativos");
            }
            else {
              showWait(this, titulo,
                "Por favor, aguarde enquanto o registro é excluído.");
              await excluirBomba(codigoBomba, cnpjEstabelecimento);
              hideWait(this);
              showInfo(this, titulo, "Bomba excluída com sucesso!",
                null, () => {
                  this.props.history.push(MAPA_PATH);
                });
            }
          } catch (err) {
            showDeleteError(this, titulo, err);
          }
        } else {
          hideAlert(this);
        }
      }
    );
  };

  handleCancelar = () => {
    showQuestion(this, titulo, "Deseja realmente cancelar?", null, resp => {
      if (resp) {
        hideAlert(this);
        this.props.history.push(MAPA_PATH);
      } else {
        hideAlert(this);
      }
    });
  };

  incluiLacres = (numLacre, dataAp) => {
    const lacs = this.state.lacres;
    let d = moment().format("YYYY-MM-DD").endOf(dataAp);
    let lacre = {
      codigoBomba: this.state.codigoBomba,
      numeroLacre: numLacre,
      dataAplicacao: d
    }

    lacs.push(lacre);
    this.setState({ lacres: lacs });
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }

    this.setState({ salvando: true });

    const {
      cnpjEstabelecimento,
      codigoBomba,
      codigoMaquinaPdv,
      numeroBomba,
      numeroSerie,
      fabricante,
      modelo,
      codigoTipo,
      codigoFabricante,
      casasDecimais,
      // pdvs,
      // fabricantes,

      //Lacres
      lacres,

      ativo,
      rowVersion
    } = this.state,
      inativo = !ativo,
      lacre = [];


    for (let i = 0; i < lacres.length; i++) {
      const element = lacres[i];
      const objLacre = {
        codigoBomba: null, //verificar
        codigoLacre: "",
        numeroLacre: "",
        dataAplicacao: moment()
      }
      objLacre.codigoBomba = this.state.codigoBomba;
      objLacre.codigoLacre = element.codigoLacre;
      objLacre.numeroLacre = element.numeroLacre;
      objLacre.dataAplicacao = moment(element.dataAplicacao).utc().hours(
        0).minutes(0).seconds(0).milliseconds(0);
      lacre.push(objLacre);
    }

    const [action, params] =
      codigoBomba !== null
        ? [
          alterarBomba,
          [
            cnpjEstabelecimento,
            codigoBomba,
            codigoMaquinaPdv,
            numeroBomba,
            numeroSerie,
            fabricante,
            modelo,
            codigoTipo,
            codigoFabricante,
            casasDecimais,
            lacre,
            inativo,
            rowVersion
          ]
        ]
        : [
          incluirBomba,
          [
            cnpjEstabelecimento,
            codigoMaquinaPdv,
            numeroBomba,
            numeroSerie,
            fabricante,
            modelo,
            codigoTipo,
            codigoFabricante,
            casasDecimais,
            lacre
          ]
        ];

    let temErros = this.verificaBomba();

    if ((!temErros) && (action === incluirBomba)) {
      temErros = this.verificaBombaExistente(this.state.numeroBomba,
        this.state.numeroLacre);
    }

    if (!temErros) {
      try {
        showWait(this, titulo, "Por favor, aguarde enquanto o registro é salvo.");
        await action(...params);
        hideWait(this);
        const msg = codigoBomba === null ?
          "Bomba cadastrada com sucesso!" : "Bomba alterada com sucesso!";
        showInfo(this, titulo, msg, null, () => {
          this.props.history.push(MAPA_PATH);
        });
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
    this.setState({ salvando: false });
  };


  verificaBomba = () => {
    //verifica preenchimento dos campos
    if (this.state.numeroBomba === "") {
      showError(this, titulo, "É obrigatório informar o número da bomba");
      return true;
    }
    if (this.state.numeroBomba < 1 || this.state.numeroBomba >= 99) {
      showError(this, titulo, "O número da bomba deve ser entre 1 e 99");
      return true;
    }
    if (parseInt(this.state.codigoMaquinaPdv) === 0) {
      showError(this, titulo, "É obrigatório informar o PDV");
      return true;
    }
    if (parseInt(this.state.codigoFabricante) === 0) {
      showError(this, titulo, "É obrigatório informar o fabricante");
      return true;
    }
    if (this.state.modelo === "") {
      showError(this, titulo, "É obrigatório informar o modelo da bomba");
      return true;
    }
    if (this.state.numeroSerie === "") {
      showError(this, titulo, "É obrigatório informar o número de série");
      return true;
    }
    if (parseInt(this.state.codigoTipo) < 0) {
      showError(this, titulo, "É obrigatório informar o tipo");
      return true;
    }
    if (parseInt(this.state.casasDecimais) === 0) {
      showError(this, titulo, "É obrigatório informar as casas decimais");
      return true;
    }

    // Lacres
    if (this.state.lacres.length > 0) {
      let list = this.state.lacres;
      for (let i = 0; i < list.length; i++) {
        const lacre = list[i];

        if (lacre.numeroLacre === "") {
          showError(this, titulo, "É obrigatório informar o número do lacre");
          return true;
        }
        if (lacre.dataAplicacao === "") {
          showError(this, titulo, "É obrigatório informar a data de aplicação");
          return true;
        }
        if (!moment(lacre.dataAplicacao, "YYYY-MM-DD").isValid()) {
          showError(this, titulo, "Data de aplicação inválida");
          return true;
        }
        if (moment(lacre.dataAplicacao).format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
          showError(this, titulo, "Data de aplicação não deve ser superior à data atual.");
          return true;
        }
        let listCopia = this.state.lacres;
        for (let j = 1; j < listCopia.length; j++) {
          const lacreCopia = listCopia[j];
          if (lacreCopia.numeroLacre === lacre.numeroLacre) {
            showError(this, titulo, "Já existe lacre cadastrado com os valores informados nesta bomba");
            return true;
          }
          break;
        }
        break;
      }
    }
    //Verifica se tem algum lacre inserido
    if (this.state.lacres.length <= 0) {
      showError(this, titulo, "Deve ser informada ao menos um lacre");
      return true;
    }
    return false;
  }

  verificaBombaExistente = (numBomba) => {
    let existeBomba
    // existe = false;
    let lista = this.state.bombasExistentes;
    for (let i = 0; i < lista.length; i++) {
      const bomba = lista[i];
      if (parseInt(bomba.numeroBomba) === numBomba) {
        existeBomba = true;
      }
    }
    if (existeBomba) {
      showError(this, titulo, "Já existe bomba cadastrada com os valores informados");
    }
    return existeBomba;
  }

  handleAdicionar = () => {
    const { lacres, idNovo = 0 } = this.state;

    this.setState({
      lacres: [...lacres, { codigoLacre: idNovo, codigoBomba: this.state.codigoBomba, numeroLacre: "", dataAplicacao: "" }],
      idNovo: idNovo - 1
    });
  };

  handleChangeGrid = (value, name, id) => {
    const lacre = this.state.lacres.map(l => {
      if (l.codigoLacre === id) {
        return { ...l, [name]: value };
      } else {
        return l;
      }
    });
    this.setState({ lacres: lacre });
  }

  handleInputChangeRegexGrid = (event, autocomplete, id) => {
    if (event.target.validity.valid) {
      this.handleChangeGrid(event.target.value, autocomplete, id);
    }
  }

  eliminarLacre = (e) => {
    var listaLacreAtual = this.state.lacres;
    var listaLacreNovo = [];
    var nome = e.currentTarget.className;

    for (let i = 0; i < listaLacreAtual.length; i++) {
      const element = listaLacreAtual[i];
      if ((nome.indexOf(element.codigoLacre) === -1)) {
        listaLacreNovo.push(element);
      }
    }
    this.setState({ lacres: listaLacreNovo })
  }

  changeSelect = (event) => {
    let value, name;
    let descFab = "";

    value = event.target.value;
    name = event.name;

    // let codFab = value;

    const list = this.state.fabricantes;
    for (let i = 0; i < list.length; i++) {
      const fab = list[i];
      if (parseInt(fab.value) === parseInt(value)) {
        descFab = fab.label;
      }
    }
    this.setState({ codigoFabricante: value, fabricante: descFab });
    this.setState({ [name]: value });
  }

  render() {
    const {
      codigoBomba,
      // codigoEstabelecimento,
      numeroBomba,
      // pdv,
      codigoFabricante,
      // descricaoFabricante,
      modelo,
      numeroSerie,
      codigoTipo,
      // tipo,
      casasDecimais,
      codigoMaquinaPdv,
      ativo,
      fabricantes,
      pdvs,
      // lacres,
      // numeroLacre,
      // dataAplicacao,

      // alertActive,
      // alertType,
      // alertTitle,
      // alertSubtitle,
      rowVersion
    } = this.state;

    // let qtdeLacre10 = this.state.lacres.length <= 10;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="Cadastro de bombas" accordion>
              <div className="row">
                <div className="col-5">
                  <InputText
                    label="Número da bomba"
                    value={numeroBomba}
                    name="numeroBomba"
                    type="number"
                    maxlength="2"
                    required
                    onChange={this.handleInputChange}
                    disabled={codigoBomba !== null}
                    allowNegative={false}
                    tabindex={1}
                  />
                </div>
                <div className="col-5">
                  <Select
                    label="PDV"
                    name="codigoMaquinaPdv"
                    value={codigoMaquinaPdv}
                    onChange={this.handleInputChange}
                    options={pdvs}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <Select
                    label="Fabricante"
                    name="codigoFabricante"
                    value={codigoFabricante}
                    onChange={this.changeSelect}
                    options={fabricantes}
                    required
                  />
                </div>
                <div className="col-5">
                  <InputText
                    label="Modelo da bomba"
                    value={modelo}
                    name="modelo"
                    maxlength="20"
                    required
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <InputText
                    label="Número de série"
                    value={numeroSerie}
                    name="numeroSerie"
                    maxlength="20"
                    required
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-5">
                  <Select
                    label="Tipo"
                    name="codigoTipo"
                    value={codigoTipo}
                    onChange={this.handleInputChange}
                    options={
                      [
                        { value: "1", label: "Eletrônica" },
                        { value: "0", label: "Mecânica" }
                      ]
                    }
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <Select
                    label="Casas decimais"
                    name="casasDecimais"
                    value={casasDecimais}
                    onChange={this.handleInputChange}
                    options={
                      [
                        { value: "2", label: "02 casas" },
                        { value: "3", label: "03 casas" }
                      ]
                    }
                    required
                  />
                </div>
                <div className="col-5">
                  <Checkbox label="Ativo:">
                    <Checkitem
                      label=""
                      name="ativo"
                      checked={ativo}
                      onChange={this.handleInputChange}
                      disabled={codigoBomba === null}
                    />
                  </Checkbox>
                </div>
              </div>
            </SectionContent>
            <SectionContent title="Lacres de bombas" accordion accordionDefaultDisplay={false} >
              <>
                <div className="button-container" >
                  <Botao
                    title="Adicionar"
                    icon="icon-lx-plus"
                    onClick={this.handleAdicionar}
                  />
                </div>
                <List
                  defaultPageSize={10}//qtdeLacre10 ? this.state.lacres.length : 10}
                  onClick={this.handleTableClick}
                  rows={this.state.lacres}
                  cols={[
                    {
                      accessor: "numeroLacre",
                      Header: "Número do lacre",
                      Cell: (props) => {
                        return (
                          <InputText
                            className="input"
                            value={props.row.numeroLacre}
                            name="numeroLacre"
                            maxlength="20"
                            pattern={ALPHANUMERIC_WITH_HYPHEN_PATTERN}
                            onChange={(e) => this.handleInputChangeRegexGrid(e, "numeroLacre", props.original.codigoLacre)}
                          />
                        );
                      }
                    },
                    {
                      accessor: "dataAplicacao",
                      Header: "Data de aplicação",
                      Cell: (props) => {
                        return (
                          <InputDate
                            name="dataAplicacao"
                            value={props.row.dataAplicacao}
                            onChange={({ date }) => this.handleChangeGrid(date, "dataAplicacao", props.original.codigoLacre)}
                          />
                        );
                      }
                    },
                    {
                      accessor: "codigoLacre",
                      Header: "CodigoLacre",
                      style: { 'white-space': 'unset' },
                      filterable: false,
                      show: false,
                      Cell: (props) => {
                        return (props.original.codigoLacre)
                      }

                    },
                    {
                      accessor: "acao",
                      Header: "Excluir",
                      width: 75,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <Botao
                            className={row.codigoLacre}
                            icon={"icon-lx-trash"}
                            onClick={this.eliminarLacre}
                          />
                        );
                      }
                    }
                  ]}
                />
              </>
            </SectionContent>
          </SectionContainer>

        </LinxPostos>
        <Footer
         saveAction={              
          !rowVersion
          ? !this.state.salvando
            ? this.handleSalvar
            : null
          : menuPermiteAlteracao(PATH_MENU)
          ? !this.state.salvando
            ? this.handleSalvar
            : null
          : null
        }
          cancelAction={this.handleCancelar}
          deleteAction={
            ((codigoBomba === null)) ? null : (
              menuPermiteExclusao(PATH_MENU) ? this.handleExcluir : null
            )}
        />
        {tagAlert(this)}
      </>
    );
  }
}

FormCadastroBomba = withRouter(FormCadastroBomba);

class Bombas extends Component {
  state = {
    bombas: [],
    bombaSel: {}
  };

  async doMount() {
    showWait(
      this,
      titulo,
      "Por favor, aguarde enquanto os dados são carregados."
    );
    try {
      const { data: b } = await getBombas(getCnpj(), true);
      const bombaArray = b.result;
      const bombs = bombaArray.map(bo => {
        return {
          codigoBomba: bo.codigoBomba,
          codigoMaquinaPdv: bo.codigoMaquinaPdv,
          maquinaPdv: bo.maquinaPdv,
          numeroBomba: bo.numeroBomba,
          numeroSerie: bo.numeroSerie,
          fabricante: bo.fabricante,
          modelo: bo.modelo,
          codigoTipo: bo.codigoTipo,
          tipo: bo.tipo,
          codigoFabricante: bo.codigoFabricante,
          casasDecimais: bo.casasDecimais,
          rowVersion: bo.rowVersion,
          lacres: bo.lacres,
          possuiBicosVinculados: bo.possuiBicosVinculados,
          inativo: bo.inativo,
          casas: bo.casasDecimais + " casas"
        }
      }
      )

      const bbMoOrd = bombs.sort(function (a, b) {
        if (parseInt(a.numeroBomba) < parseInt(b.numeroBomba)) return -1;
        if (parseInt(a.numeroBomba) > parseInt(b.numeroBomba)) return 1;
        return 0;
      });

      this.setState({ bombas: bbMoOrd });
    } finally {
      hideWait(this);
    }
  }

  async componentDidMount() {
    this.doMount();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.doMount();
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.setState({ bombaSel: rowInfo.original });
      this.props.history.push(NEW_PATH, {
        bombaSel: rowInfo.original,
        bombasExistentes: this.state.bomba
      });
    }
  }

  renderHelper = (edit, showTitle) => {
    return (
      <>
        <SectionHeader
          title={showTitle ? "Bombas" : ""}
          subtitle=""
          right={
            <div className="button-container">
              {edit ? (
                <></>
              ) : (
                  menuPermiteInclusao(PATH_MENU) ? (
                    <Botao ic icon="icon-lx-plus" onClick={() => {
                      this.setState({ bombaSel: {} });
                      this.props.history.push(
                        NEW_PATH,
                        {
                          bombaSel: {},
                          bombasExistentes: this.state.bomba
                        });
                    }} />
                  ) : (
                      <></>
                    )
                )}
            </div>
          }
        />
        <List
          defaultPageSize={5}
          onClick={this.handleTableClick}
          cols={[
            {
              accessor: "numeroBomba",
              Header: "Nº da bomba",
              width: 50,
              filterable: false
            },
            {
              accessor: "maquinaPdv",
              Header: "PDV",
              width: 110,
              filterable: false
            },
            {
              accessor: "fabricante",
              Header: "Fabricante",
              width: 200,
              filterable: false
            },
            {
              accessor: "modelo",
              Header: "Modelo",
              width: 150,
              filterable: false
            },
            {
              accessor: "numeroSerie",
              Header: "Número de série",
              width: 150,
              filterable: false
            },
            {
              accessor: "tipo",
              Header: "Tipo",
              width: 100,
              filterable: false
            },
            {
              accessor: "casas",
              Header: "Casas decimais",
              width: 100,
              filterable: false
            },
            {
              accessor: "inativo",
              Header: "Ativo",
              width: 100,
              filterable: false,
              Cell: ({ row }) => {
                return (
                  <Botao
                    secondary={row.inativo}
                    ic
                    icon={row.inativo ? "icon-lx-close" : "icon-lx-check"}
                  />
                );
              }
            }
          ]}
          rows={this.state.bombas}
        />
      </>
    );
  };

  render() {
    const { edit } = this.props;
    let bombaSel = {};
    if (edit) {
      // this.props.location.state por padrao eh undefined
      if (this.props.location.state) {
        bombaSel = this.props.location.state.bombaSel;
      }
    }
    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <FormCadastroBomba
              bomba={bombaSel}
              bombasExistentes={this.state.bomba}
              lacres={this.state.lacres}
            />
          ) : (
              <>
                {
                  this.props.noLinxPostos ? this.renderHelper(edit, true) :
                    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                      {this.renderHelper(edit, false)}
                    </LinxPostos>
                }
              </>
            )}
        </section>
      </main>
    );
  }
}

Bombas = withRouter(Bombas);
export { Bombas };
