import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

export class ApiRemoverPagamentoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(body) {
    try {
      await this.urlLinxCore.delete(`/ConComodato/RemoverPagamento/${body.osId}/${body.pedidoId}/${body.uid}`);
    } catch (error) {
      console.error(error);
      await new AxiosErrorService().execute(error);
    }
  }
}
