import axios from "axios";
import { getToken } from "./Auth";

import { BASE_URL_GATEWAY } from '../shared/constants/GatewayUrlConstant';

const urlLogin = axios.create({
  baseURL: BASE_URL_GATEWAY
});

urlLogin.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default urlLogin;
