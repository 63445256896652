import React from 'react';

import { locale } from 'devextreme/localization';
import moment from 'moment';
import { PrintStyle } from './PrintStyles';

export function ReciboConsignado({ pedido }) {
  moment.locale('pt-br');
  locale('pt-br');
  const sty = new PrintStyle();
  return (
    <>
      <h5>CONSIGNADO</h5>
      <table className="w-100" style={sty.borderSolid}>
        <thead>
          <tr style={sty.borderSolid}>
            <th width="50%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Código - Descricao do Produto
            </th>
            <th width="5%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              UN
            </th>
            <th className="text-center pl-1 pr-1" style={sty.tableTh}>
              Quantidade
            </th>
            <th width="10%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Consumido
            </th>
            <th width="20%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Retorno
            </th>
          </tr>
        </thead>
        <tbody>
          {((pedido || {}).itens || []).map((item, iIdx) => (
            <tr key={iIdx}>
              <td className="text-left" style={sty.borderSolid}>
                <p className="ml-3 pr-1" style={sty.tdP}>{`${item.produto.codigo} - ${item.produto.descricao}`}</p>
              </td>
              <td className="text-right" style={sty.borderSolid}>
                <p className="pr-1" style={sty.tdP}>
                  {item.produto.estoque.unidadeMedidaVenda.sigla.toUpperCase()}
                </p>
              </td>
              <td className="text-right" style={sty.borderSolid}>
                <p className="pr-1" style={sty.tdP}>{`${item.quantidade}`}</p>
              </td>
              <td className="text-right" style={sty.borderSolid}></td>
              <td className="text-right" style={sty.borderSolid}></td>
            </tr>
          ))}
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '20px' }}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '20px' }}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
