import { CriarNovoItemService } from './../../../pages/operacoes/consignado-comodato-nova-os/usercases/criarNovoItem.service';

export class AdicionarNovoItemService {
  execute = (state, { payload }) => {
    const { item } = payload;
    let index = state.values.pedidos.findIndex(it => it.tipoPedido.id === item.tipoPedido.id && !it.faturado);

    const pedido = new CriarNovoItemService().execute(state.values, item);
    let nextValue = null;
    if (index === -1) {
      nextValue = {
        ...state.values,
        pedidos: [...state.values.pedidos, pedido],
      };
    } else {
      var d = { ...state.values };
      d.pedidos[index] = pedido;
      nextValue = { ...d };
    }
    state.values = { ...state.values, ...nextValue };
  };
}
