import { CharToInt } from './utils';
export function CheckIESE(ie) {
  if (ie.length !== 9) return false;
  var nro = new Array(9);
  for (var i = 0; i <= 8; i++) nro[i] = CharToInt(ie.charAt(i));
  var b = 9;
  var soma = 0;
  for (i = 0; i <= 7; i++) {
    soma += nro[i] * b;
    b--;
  }
  var dig = 11 - (soma % 11);
  if (dig >= 10) dig = 0;
  return dig === nro[8];
} //se
