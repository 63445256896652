import { DropDownBox, DataGrid } from 'devextreme-react';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { DropDownImage } from '../../../../consignado-comodato-nova-os/components/drop-down-image';
import { Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';

export const SelectOperadores = forwardRef((props, ref) => {
  const operadores = useSelector(selectConsignadoComodatoSharedData).operadores;
  const [opened, setOpened] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  useEffect(() => {
    // if ((props.value || []).join('_') === (selectedItemKeys || []).join('_')) return;
    setSelectedItemKeys(props.value);
  }, [props.value, selectedItemKeys]);

  useImperativeHandle(ref, () => ({
    getValue: () => data,
  }));

  const onSelectedItemKeysChange = useCallback(
    args => {
      const ops = operadores.filter(x => args.selectedRowKeys.includes(x.id));
      setData(ops);
      if (typeof props.onChanged === 'function') {
        props.onChanged(ops);
      }
      setSelectedItemKeys(args.selectedRowKeys);
    },
    [operadores, props],
  );

  return (
    <DropDownBox
      showClearButton={true}
      placeholder="Escolha as operadores"
      onValueChanged={e => {
        if (!e.event) return;
        if (((e.event.target || {}).className || '').includes('clear')) {
          setSelectedItemKeys([]);
          setData([]);
          if (typeof props.onChanged === 'function') {
            props.onChanged([]);
          }
        }
      }}
      valueExpr="id"
      displayExpr="nome"
      showDropDownButton={true}
      dropDownButtonRender={DropDownImage}
      dataSource={operadores}
      openOnFieldClick={true}
      value={selectedItemKeys}
      onOpenedChange={e => {
        setOpened(e);
      }}
      opened={opened}
      onKeyUp={e => {
        if (opened) e.preventDefault();
        if (
          e.event.originalEvent.code === 'ArrowDown' ||
          e.event.originalEvent.key === 'ArrowDown' ||
          e.event.originalEvent.code === 'Enter' ||
          e.event.originalEvent.key === 'Enter' ||
          e.event.originalEvent.code === 'Space' ||
          e.event.originalEvent.key === 'Space'
        ) {
          setOpened(true);
        }
      }}
      contentRender={() => {
        return (
          <DataGrid dataSource={operadores} keyExpr="id" showBorders={false} selectedRowKeys={selectedItemKeys} onSelectionChanged={onSelectedItemKeysChange}>
            <Selection mode="multiple" selectAllMode={'allPages'} showCheckBoxesMode={'always'} />
            <FilterRow visible={true} />
            <Scrolling mode="virtual" />
            <Column dataField="nome" caption="Nome" />
          </DataGrid>
        );
      }}
    />
  );
});
