import axios from 'axios';
import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { getToken } from '../../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';

export class ComprasDinamicoService {
  urlLinxCore = axios.create({
    baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
  });

  constructor() {
    this.urlLinxCore.interceptors.request.use(async config => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }
  async find(filter) {
    try {
      if (!filter) {
        throw new Error('Filtro não informado');
      }
      let params = new URLSearchParams(filter);
      const response = await this.urlLinxCore.get(`/Compra/RelatorioRoyalties?${params}`);
      if (response.data.notificationLevel !== 1) {
        throw new Error(response.data.message);
      }
      return response.data.data;
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
