import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

/**
 * Um serviço para conferir um turno de pedidos usando a API.
 */
export class ApiFecharTurnoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute() {
    try {
      await this.urlLinxCore.post(`/TurnoPedido/FecharTurnoAtual`, {});
      return;
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
