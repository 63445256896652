import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

export class ApiRecuperarAcessosPorMenuIdService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(menuId) {
    try {
      const dadosLogin = getDadosLogin();
      const cacheKey = `ApiRecuperarAcessosPorMenuIdService${dadosLogin.cnpj}/${menuId}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        return cachedData;
      } else {
        const response = await this.urlLinxCore.get(`/Funcionario/Acessos/${menuId}`);
        var data = response.data.itens;
        memoryCache.put(cacheKey, data, 1000 * 60 * 3);
        return data;
      }
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
