import React, { Component } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import {
  getUrl,
  getNomePagina,
  getDadosSellerPop
} from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_16105;
const PATH_MENU_MATRIZ = menus.COD_22300;

export class VendasRealizadasConsulta extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <div className='row'>
          <Iframe
            url={
              getUrl() + '/VendasRealizadasConsulta.aspx?' + getDadosSellerPop(PATH_MENU)
            }
            className='iframePop'
          />
        </div>
      </LinxPostos>
    );
  }
}

export class MatrizVendasRealizadasConsulta extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU_MATRIZ)}>
        <div className='row'>
          <Iframe
            url={
              getUrl() + '/VendasRealizadasConsulta.aspx?consolidado=true&' + getDadosSellerPop(PATH_MENU_MATRIZ)
            }
            className='iframePop'
          />
        </div>
      </LinxPostos>
    );
  }
}