import React from 'react';
import {
  InputText,
  InputDate,
  Select,
  handleInputChange,
  // FormOptions,
  Checkbox,
  Checkitem
} from '../../../../components/formulario/Formulario';
import { SectionContainer, SectionContent } from '../../../../components/section/Content';
import {
  tagAlert,
  showQuestion,
  hideAlert,
  showError,
  showInfo
} from '../../../../components/alert/Alert';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import { getCentrosMonetarios } from '../../../../services/CentrosMonetarios.service';

import { montaComboTiposFormasPgto } from '../../conferencia-caixa/ConferenciaTurno.service';
import {
  quitaParcial,
  quitaTotal,
  getTitulo
} from './../GerenciamentoFinanceiro.service';
import {
  toFloatFormattedDisplay,
  validaDadosLogin,
  getNomePagina
} from '../../../../shared/utils/Utils';
import Footer from '../../../../components/core/footer/Footer';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60690;

class FormQuitacao extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    const {
      id = null,
      valor = null,
      descricao = '',
      dataVencimento = moment(),
      dataQuitacao = moment(),
      conciliado = false,
      formaPagamento = '',
      saldo = null,
      tipoLancamento = '',
      acrescimos,
      descontos,
    } = this.props.titulo;

    const {
      centrosMonetarios = [],
      formasPagamento = [],
      parcial = false
    } = this.props;

    this.state = {
      codigo: id,
      valor,
      valorOriginal: valor,
      descricao,
      dataVencimento,
      dataQuitacao,
      conciliado,
      formaPagamento,
      valorPago: valor,
      descontos,
      acrescimos,
      centroMonetario: '',
      centrosMonetarios,
      formasPagamento,
      saldo,
      parcial,
      tipoLancamento,
      title: parcial ? 'Quitação parcial' : 'Quitação',
      salvando: false
    };
  }

  fillArrayForCombo(dataList, listToPush, nullItem) {
    if (nullItem) {
      listToPush[0] = { label: nullItem, value: '' };
    }
    if (dataList)
      dataList.forEach(item => {
        listToPush.push({ label: item.descricao, value: item.codigo });
      });
  }

  async componentDidMount() {
    const {
      centrosMonetarios,
      formasPagamento,
      parcial,
      codigo,
      tipoLancamento
    } = this.state;
    if (centrosMonetarios.length === 0) {
      const { data: dataCM } = await getCentrosMonetarios();
      centrosMonetarios.push({
        label: 'Selecione um centro monetário',
        value: ''
      });
      dataCM.result.forEach(cm => {
        centrosMonetarios.push({
          label: cm.descricao,
          value: cm.codigoCentroMonetario
        });
      });
      centrosMonetarios.sort(function (a, b) {
        const compareA = a.label.toUpperCase();
        const compareB = b.label.toUpperCase();
        if (compareA < compareB) {
          return -1;
        }
        if (compareA > compareB) {
          return 1;
        }
        return 0;
      });
      centrosMonetarios.unshift({
        label: 'Selecione um centro monetário',
        value: ''
      });
    }

    if (formasPagamento.length === 0) {
      const { data: dataFormasPagamento } = await montaComboTiposFormasPgto(true, false);
      this.fillArrayForCombo(
        dataFormasPagamento,
        formasPagamento,
        'Selecione uma forma de pagamento'
      );
    }

    let s = this.state.saldo;
    if (parcial && !s) {
      s = this.state.valorOriginal - this.state.valorPago;
    }

    if (codigo && tipoLancamento) {
      const { data: titulo } = await getTitulo(codigo, tipoLancamento);
      this.setState({
        centroMonetario: titulo.centroMonetario
          ? String(titulo.centroMonetario.codigo)
          : this.state.centroMonetario,
        conciliado: titulo.conciliado,
        acrescimos: titulo.acrescimos,
        descontos: titulo.descontos
      });
    }

    this.setState({
      centrosMonetarios,
      formasPagamento,
      saldo: s
    });
  }

  isFormValid() {
    const {
      descricao,
      dataQuitacao,
      dataVencimento,
      valor,
      centroMonetario,
      parcial,
      descontos,
      valorPago,
      valorOriginal
    } = this.state;
    let error = null;

    if (!descricao) {
      error = {
        msg: 'Informe a descrição da quitação',
        field: 'descricao'
      };
    } else if (!moment(dataQuitacao).isValid()) {
      error = {
        msg: 'Informe uma data válida para a quitação',
        field: 'dataQuitacao'
      };
    } else if (moment(dataQuitacao).isAfter(moment(), 'day')) {
      error = {
        msg: 'A data de quitação deve ser igual ou anterior a hoje',
        field: 'dataQuitacao'
      };
    } else if (
      parcial &&
      moment(dataVencimento).isBefore(moment(dataQuitacao), 'day')
    ) {
      error = {
        msg: 'A data de vencimento deve ser igual ou posterior a de quitação',
        field: 'dataVencimento'
      };
    } else if (!valor) {
      error = {
        msg: 'Informe o valor da quitação',
        field: 'valor'
      };
    } else if (parcial && valorPago > valorOriginal) {
      error = {
        msg:
          'Valor pago (R$ ' +
          toFloatFormattedDisplay(String(valorPago)) +
          ') deve ser inferior ao valor do lançamento (R$ ' +
          toFloatFormattedDisplay(String(valorOriginal)) +
          ').',
        field: 'valorPago'
      };
    } else if (!centroMonetario) {
      error = {
        msg: 'Informe o centro monetário',
        field: 'centroMonetario'
      };
    } else if (descontos && descontos > valor) {
      error = {
        msg: 'Valor de desconto deve ser inferior ao valor do lançamento',
        field: 'descontos'
      };
    }

    if (error) {
      showError(this, this.state.title, error.msg, error.field);
      return false;
    }
    return true;
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    if (!this.isFormValid()) {
      return;
    }

    const {
      codigo,
      descricao,
      dataVencimento,
      dataQuitacao,
      conciliado,
      formaPagamento,
      descontos,
      acrescimos,
      valorPago,
      centroMonetario,
      saldo,
      parcial,
      tipoLancamento
    } = this.state;

    const [action, params] = parcial
      ? [
        quitaParcial,
        [
          codigo,
          descricao,
          dataQuitacao,
          centroMonetario,
          conciliado ? 1 : 0,
          tipoLancamento,
          formaPagamento,
          valorPago,
          acrescimos,
          descontos,
          saldo,
          dataVencimento
        ]
      ]
      : [
        quitaTotal,
        [
          codigo,
          descricao,
          dataQuitacao,
          centroMonetario,
          conciliado ? 1 : 0,
          tipoLancamento,
          formaPagamento,
          valorPago,
          acrescimos,
          descontos
        ]
      ];

      this.setState({ salvando: true });
      try {
        await action(...params);
        showInfo(
          this,
          this.state.title,
          'Quitação realizada com sucesso',
          null,
          () => {
            if (this.props.returnMethod) this.props.returnMethod(false);
            hideAlert(this, this.props.history, this.props.goBackTo);
          }
        );
      } catch (err) {
        let msg =
          err && err.response && err.response.data
            ? 'Mensagem do servidor: ' + err.response.data.message
            : 'Ocorreu um erro';
        showError(this, this.state.title, msg, null, () => {
          hideAlert(this);
        });
      } finally {
        this.setState({ salvando: false });
      }
  };

  doHideAlert = goBack => {
    goBack ? hideAlert(this, this.props.history, goBack) : hideAlert(this);
  };

  handleCancelar = () => {
    showQuestion(
      this,
      this.state.title,
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (this.props.returnMethod) this.props.returnMethod(false);
        this.doHideAlert(resp ? this.props.goBackTo : null);
      }
    );
  };

  handleDescontosChange = event => {
    /* 
    Valor deve ser inferior ao valor do lançamento.    
    Caso o usuário preencha o 'valor pago' inferior ao valor do lançamento, 
    o sistema deve apresentar o valor da diferença no campo descontos. */
    let descontos = 0;
    if (event.floatValue) {
      descontos =
        event.floatValue > this.state.valor
          ? this.state.descontos
          : event.floatValue;
    }
    const valorPago = this.state.valor - descontos + this.state.acrescimos;
    this.setState({
      descontos,
      valorPago
    });
  };

  handleAcrescimosChange = event => {
    /* 
    Caso o usuário preencha o 'valor pago' superior ao valor do lançamento, 
    o sistema deve apresentar o valor da diferença no campo acréscimos. */
    const acrescimos = event.floatValue ? event.floatValue : 0;
    const valorPago = this.state.valor - this.state.descontos + acrescimos;
    this.setState({
      acrescimos,
      valorPago
    });
  };

  handleValorChange = event => {
    const valor = event.floatValue ? event.floatValue : 0;
    const valorPago = valor - this.state.descontos + this.state.acrescimos;
    this.setState({
      valor,
      valorPago
    });
  };

  handleValorPagoParcialChange = event => {
    const valorPago = event.floatValue;
    const saldo = this.state.valorOriginal - valorPago;
    this.setState({
      valorPago,
      saldo
    });
  };

  render() {
    const {
      valor,
      valorPago,
      saldo,
      descontos,
      acrescimos,
      descricao,
      dataVencimento,
      dataQuitacao,
      conciliado,
      centroMonetario,
      centrosMonetarios,
      formaPagamento,
      formasPagamento
    } = this.state;

    const { parcial } = this.props;
    return (
      <>
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <SectionContainer>
          <SectionContent title={this.state.title}>
            <div className="row">
              <div className="col-12">
                <InputText
                  label="Descrição:"
                  name="descricao"
                  value={descricao}
                  maxlength={100}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            {parcial ? (
              <>
                <div className="row">
                  <div className="col-6">
                    <InputText
                      label="Valor pago(R$):"
                      name="valorPago"
                      value={valorPago}
                      //onChange={this.handleInputChange}
                      onChange={this.handleValorPagoParcialChange}
                      type="number"
                      maxlength={10}
                      decimalScale={2}
                      allowNegative={false}
                    />
                  </div>
                  <div className="col-6">
                    <InputText
                      label="Saldo(R$):"
                      name="saldo"
                      value={saldo}
                      onChange={this.handleInputChange}
                      type="number"
                      maxlength={10}
                      decimalScale={2}
                      allowNegative={true}
                      disabled
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputDate
                      value={dataQuitacao}
                      name="dataQuitacao"
                      label="Data da quitação:"
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-6">
                    <InputDate
                      value={dataVencimento}
                      name="dataVencimento"
                      label="Data de vencimento do saldo:"
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </>
            ) : (
                <>
                  <div className="row">
                    <div className="col-6">
                      <InputDate
                        value={dataQuitacao}
                        name="dataQuitacao"
                        label="Data da quitação:"
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col-6">
                      <InputText
                        label="Valor (R$):"
                        name="valor"
                        value={valor}
                        //onChange={this.handleInputChange}
                        onChange={this.handleValorChange}
                        type="number"
                        maxlength={10}
                        decimalScale={2}
                        allowNegative={false}
                      />
                    </div>
                  </div>
                </>
              )}
            <div className="row">
              <div className="col-6">
                <Select
                  label="Centro monetário"
                  name="centroMonetario"
                  value={centroMonetario}
                  onChange={this.handleInputChange}
                  options={centrosMonetarios}
                />
              </div>
              <div className="col-2">
                <Checkbox>
                  <Checkitem
                    label="Conciliado"
                    name="conciliado"
                    checked={conciliado}
                    onChange={this.handleInputChange}
                  />
                </Checkbox>
              </div>
            </div>
          </SectionContent>

          <SectionContent
            title="Mais opções"
            accordionDefaultDisplay={false}
            accordion
          >
            <div className="row">
              <div className="col-5">
                <Select
                  label="Forma de pagamento:"
                  name="formaPagamento"
                  value={formaPagamento}
                  onChange={this.handleInputChange}
                  options={formasPagamento}
                />
              </div>
            </div>
            {parcial ? (
              <></>
            ) : (
                <>
                  <div className="row">
                    <div className="col-3">
                      <InputText
                        label="Descontos (R$):"
                        name="descontos"
                        value={descontos}
                        //onChange={this.handleInputChange}
                        onChange={this.handleDescontosChange}
                        type="number"
                        maxlength={10}
                        decimalScale={2}
                        allowNegative={false}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Acréscimos (R$):"
                        name="acrescimos"
                        value={acrescimos}
                        //onChange={this.handleInputChange}
                        onChange={this.handleAcrescimosChange}
                        type="number"
                        maxlength={10}
                        decimalScale={2}
                        allowNegative={false}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Valor pago (R$):"
                        name="valorPago"
                        value={valorPago}
                        onChange={this.handleInputChange}
                        //onChange={this.handleValorPagoChange}
                        type="number"
                        maxlength={10}
                        decimalScale={2}
                        allowNegative={false}
                        disabled
                      />
                    </div>
                  </div>
                </>
              )}
          </SectionContent>
        </SectionContainer>
        </LinxPostos>
        <Footer saveAction={this.handleSalvar} cancelAction={this.handleCancelar} disabled={this.state.salvando}/>
        {tagAlert(this)}
      </>
    );
  }
}

// function ifShow(condition, divName) {
//   return condition ? divName : "content-hidden";
// }

FormQuitacao = withRouter(FormQuitacao);
export { FormQuitacao };
