import * as _ from 'lodash';

import { v4 } from 'uuid';

export class CriarNovoItemService {
  execute = (values, item) => {
    /* Busca por um tipo de pedido existente com valor diferente de -1 */
    var index = values.pedidos.findIndex(it => it.tipoPedido.id === item.tipoPedido.id && !it.faturado);
    let pedido = null;
    if (index === -1) {
      // Pedido Novo
      pedido = this.novoPedido(values, item);
    } else {
      // Pedido existente
      pedido = { ...values.pedidos[index] };
    }

    //verificar se o item como produto.id já existe dentro do pedido itens e se já existir apenas incrementar os valores
    const vrAtivo = [3, 8].includes(item.tipoPedido.id) ? item.valorAtivo : 0;
    const vrProduto = [3, 8].includes(item.tipoPedido.id) ? 0 : item.valorAtivo;
    const itemExistente = pedido.itens.find(it => it.produto.id === item.produto.id && it.valorAtivo === vrAtivo && it.valorProduto === vrProduto);

    if (itemExistente) {
      itemExistente.quantidade += item.quantidade;
      itemExistente.totalProdutos = itemExistente.quantidade * itemExistente.valorProduto;
      itemExistente.totalAtivos = itemExistente.quantidade * itemExistente.valorAtivo;
      this.calc(pedido);
      return { ...pedido };
    } else {
      pedido.itens.push(this.criarItem(values.estaCod, item, pedido));
      this.calc(pedido);
      return { ...pedido };
    }
  };
  calc = pedido => {
    pedido.totalProdutos = Math.round(_.sumBy(pedido.itens, 'totalProdutos') * 100) / 100;
    pedido.totalAtivos = Math.round(_.sumBy(pedido.itens, 'totalAtivos') * 100) / 100;
  };
  criarItem = (estaCod, item, pedido) => {
    const it = {
      id: -1,
      estaCod,
      numeroItem: pedido.itens.reduce((p, v) => (p > v.numeroItem ? p : v.numeroItem), 0) + 1,
      pedidoId: pedido.pedidoId,
      quantidade: item.quantidade,
      valorAtivo: [3, 8].includes(pedido.tipoPedido.id) ? item.valorAtivo : 0,
      valorProduto: ![3, 8].includes(pedido.tipoPedido.id) ? item.valorAtivo : 0,
      desconto: item.desconto || 0,
      acrescimo: item.acrescimo || 0,
      produto: item.produto,
      cancelado: false,
      quantidadeCancelada: 0,
      quantidadeTotal: item.quantidade,
      unidCod: item.produto.estoque.unidadeMedidaVenda.id,
    };
    if ([3, 8].includes(pedido.tipoPedido.id)) {
      const total = it.valorAtivo - it.desconto + it.acrescimo;
      it.totalAtivos = Math.round(it.quantidade * total * 100) / 100;
      it.totalProdutos = 0;
    } else {
      const total = it.valorProduto - it.desconto + it.acrescimo;
      it.totalProdutos = Math.round(it.quantidade * total * 100) / 100;
      it.totalAtivos = 0;
    }
    return it;
  };
  novoPedido = (values, item) => {
    return {
      id: -1,
      pedidoId: v4(),
      faturado: false,
      estaCod: values.estaCod,
      osId: values.osId,
      dataPedido: new Date().toISOString(),
      dataCadastro: new Date().toISOString(),
      dataUltimaAtualizacao: new Date().toISOString(),
      itens: [],
      tipoPedido: { ...item.tipoPedido },
    };
  };
}
