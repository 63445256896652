import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';
import dateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import { maskedCnpjCpf } from '../../../../shared/utils/Utils';

export const DocumentosDinamicoService = {
  find,
  GetColumnsByPagamento,
  GetColumnsByProduto
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {
  /*
   * GO HORSE GERADO por conta de ter sido configurado         [JsonConverter(typeof(UtcAsIso8601))] no MovimentoBaseFilter
   * que acaba adicionando 3 hora nos filtros da tela, com isso filtros padrões que devolvem a data de hoje por exemplo, enviam hoje e amanhã
   * já que o horário fim é definido lá como 23:59
   **/
  appliedFilters.dataInicio = appliedFilters.dataInicio.split('T')[0];
  appliedFilters.dataFim = appliedFilters.dataFim.split('T')[0];

  const response = await urlLinxCore.post('/DocumentoFiscal/NotaDetalhada', appliedFilters)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

const formatDocument = data => {
  return maskedCnpjCpf(data.value);
};

function GetColumnsDefault(matriz) {
  let colunas = [
    {
      dataField: 'empresaCnpj',
      caption: 'CNPJ da empresa',
      width: '130',
      visible: true,
    },
    {
      dataField: 'empresaNomeFantasia',
      caption: 'Nome fantasia',
      width: '200',
      visible: true,
    },
    {
      dataField: 'empresaRazaoSocial',
      caption: 'Razão social',
      width: '200',
      visible: false,
    },
    {
      dataField: 'documentoDataEmissao',
      caption: 'Emissão',
      dataType: 'date',
      width: '130',
      visible: true,
    },
    {
      dataField: 'documentoHoraEmissao',
      caption: 'Horário emissão',
      visible: false,
    },
    {
      dataField: 'documentoCodigoDescricaoDiaSemana',
      caption: 'Dia semana emissão',
      visible: false,
    },

    {
      dataField: 'documentoDataMovimento',
      caption: 'Movimento',
      dataType: 'date',
      width: '130',
      visible: false,
    },
    {
      dataField: 'documentoHoraMovimento',
      caption: 'Horário movimento',
      visible: false,
    },
    {
      dataField: 'documentoCodigoDescricaoDiaSemanaMovimento',
      caption: 'Dia semana movimento',
      visible: false,
    },



    {
      dataField: 'operacaoEntradaSaida',
      caption: 'Entrada / Saída',
      width: '100',
      visible: true,
    },
    {
      dataField: 'operacaoDescricao',
      caption: 'Operação',
      visible: false,
    },
    {
      dataField: 'operacaoRetaguarda',
      caption: 'Tipo operação',
      visible: true,
    },
    {
      dataField: 'documentoTipoDescricao',
      caption: 'Tipo documento',
      width: '130',
      visible: true,
    },
    {
      dataField: 'documentoSituacao',
      caption: 'Situação',
      visible: false,
    },
    {
      dataField: 'documentoNumero',
      caption: 'Nº documento',
      width: '130',
      visible: true,
    },
    {
      dataField: 'documentoSerie',
      caption: 'Nº Serie',
      visible: false,
    },
    {
      dataField: 'documentoSubSerie',
      caption: 'Nº SubSerie',
      visible: false,
    },
    {
      dataField: 'documentoValorBruto',
      caption: 'Documento bruto R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorFrete',
      caption: 'Documento frete R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorOutros',
      caption: 'Documento outros R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorDesconto',
      caption: 'Documento desconto R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorAcrescimo',
      caption: 'Documento acréscimo R$',
      format: { type: 'currency', precision: 2 },
      visible: false,
      totalize: false,
    },
    {
      dataField: 'documentoValorLiquido',
      caption: 'Documento líquido R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: true,
      totalize: false,
    },
    {
      dataField: 'clienteFornecedorCnpjCpf',
      caption: 'Cliente/Fornecedor CNPJ/CPF',
      visible: false,
    },
    {
      dataField: 'clienteFornecedorNome',
      caption: 'Cliente/Fornecedor',
      visible: true,
    },
    {
      dataField: 'operadorNome',
      caption: 'Operador',
      visible: false,
    },
    {
      dataField: 'origemDescricao',
      caption: 'Origem',
      visible: false,
    },
    {
      dataField: 'pedidoNumero',
      caption: 'Nº pedido',
      visible: false,
      width: '130',
    }
  ];

  if (matriz)
    colunas[0].groupIndex = 1;

  return colunas;
}

function GetColumnsByPagamento(matriz) {

  let colunas = GetColumnsDefault(matriz);

  colunas.push(
    {
      dataField: 'pagamentoRecebimentoDescricao',
      caption: 'Forma de pagamento',
      width: '130',
      visible: true,
    },
    {
      dataField: 'pagamentoRecebimentoParcela',
      caption: 'Nº parcela',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoCartaoRede',
      caption: 'Rede cartão',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoCartaoProduto',
      caption: 'Produto cartão',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoCartaoBandeira',
      caption: 'Bandeira cartão',
      width: '130',
      visible: false,
    },
    {
      dataField: 'pagamentoRecebimentoValor',
      caption: 'Pagamento / Recebimento R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: true,
      totalize: true
    },
    {
      dataField: 'pagamentoDataVencimento',
      caption: 'Data de Vencimento',
      dataType: 'date',
      width: '130',
      visible: false,
    },
  );

  return colunas;
}


function GetColumnsByProduto(matriz) {

  let colunas = GetColumnsDefault(matriz);

  colunas.push(
    {
      dataField: 'itemCodigoEan',
      caption: 'Código produto',
      width: '130',
      visible: true,
    },
    {
      dataField: 'itemDescricao',
      caption: 'Produto',
      width: '130',
      visible: true,
    },
    {
      dataField: 'itemNumero',
      caption: 'Item Nº',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemUnidade',
      caption: 'Unidade de Medida',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemQuantidade',
      caption: 'Item Quantidade',
      format: { precision: 2 },
      width: '130',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'itemValorUnitario',
      caption: 'Item unitário R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'itemValorBruto',
      caption: 'Item bruto R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorDesconto',
      caption: 'Item desconto R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorDescontoRateado',
      caption: 'Item desconto rateado R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorDescontoTotal',
      caption: 'Item desconto total R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorAcrescimo',
      caption: 'Item acréscimo R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorAcrescimoRateado',
      caption: 'Item acréscimo rateado R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorAcrescimoTotal',
      caption: 'Item acréscimo total R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorFrete',
      caption: 'Item frete R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorFreteRateado',
      caption: 'Item frete rateado R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorFreteTotal',
      caption: 'Item frete total R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemValorLiquido',
      caption: 'Item líquido R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: true,
      totalize: true,
    },
    {
      dataField: 'itemValorLiquidoComFrete',
      caption: 'Item líquido + Item frete total R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: true,
      totalize: true,
    },
    /*{
      dataField: 'itemValorLiquidoCalculado',
      caption: 'Item líquido (calculado))R$',
      width: '130',
      visible: false,
      totalize: true,
    }, */
    {
      dataField: 'itemGorjetaValor',
      caption: 'Gorjeta R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemGorjetaCpf',
      caption: 'CPF gorjeta',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemGorjetaNome',
      caption: 'Atendente gorjeta',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemComissaotipo',
      caption: 'Tipo comissão',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemComissaoPercentual',
      caption: 'Comissão %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemComisaoValor',
      caption: 'Comissão R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemCfop',
      caption: 'CFOP',
      width: '130',
      visible: true,
    },
    {
      dataField: 'itemImpostoIcmsTipo',
      caption: 'Tipo ICMS',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIcmsCst',
      caption: 'CST ICMS',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIcmsBase',
      caption: 'Base ICMS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIcmsAliquota',
      caption: 'Aliquota ICMS %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIcmsValor',
      caption: 'ICMS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemImpostoIcmsBaseSubstituicao',
      caption: 'Base ICMS substituição R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIcmsAliquotaSubstituicao',
      caption: 'Aliquota ICMS substituição %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIcmsValorSubstituicao',
      caption: 'ICMS substituição R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },

    {
      dataField: 'itemImpostoIcmsFcpAliquota',
      caption: 'Aliquota ICMS-FCP %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIcmsFcpValor',
      caption: 'ICMS-FCP R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemImpostoIcmsDescricao',
      caption: 'Descrição ICMS',
      width: '130',
      visible: true,
    },
    {
      dataField: 'itemImpostoIpiTipo',
      caption: 'Tipo IPI',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIpiCst',
      caption: 'CST IPI',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIpiBase',
      caption: 'Base IPI R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIpiAliquota',
      caption: 'Aliquota IPI %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoIpiValor',
      caption: 'IPI R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemImpostoIpiDescricao',
      caption: 'Descrição IPI',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoPisTipo',
      caption: 'Tipo PIS',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoPisCst',
      caption: 'CST PIS',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoPisBase',
      caption: 'Base PIS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoPisAliquota',
      caption: 'Aliquota PIS %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoPisReducao',
      caption: 'Redução PIS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoPisValor',
      caption: 'PIS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemImpostoPisDescricao',
      caption: 'Descrição PIS',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoCofinsTipo',
      caption: 'Tipo COFINS',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoCofinsCst',
      caption: 'CST COFINS',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoCofinsBase',
      caption: 'Base COFINS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoCofinsAliquota',
      caption: 'Aliquota COFINS %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoCofinsReducao',
      caption: 'Redução COFINS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoCofinsValor',
      caption: 'COFINS R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemImpostoCofinsDescricao',
      caption: 'Descrição COFINS',
      width: '130',
      visible: false,
    },

    {
      dataField: 'itemImpostoDilmaTipo',
      caption: 'Tipo lei da transparência',
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoDilmaBase',
      caption: 'Base lei da transparência R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoDilmaAliquota',
      caption: 'Aliquota lei da transparência %',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoDilmaReducao',
      caption: 'Redução lei da transparência R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
    },
    {
      dataField: 'itemImpostoDilmaValor',
      caption: 'Lei da transparência R$',
      format: { type: 'currency', precision: 2 },
      width: '130',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'itemImpostoDilmaDescricao',
      caption: 'Descrição lei da transparência',
      width: '130',
      visible: false,
    }
  );

  return colunas;
}

export default urlLinxCore;
