import { DataGrid, DropDownBox } from 'devextreme-react';
import { Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFiltroOs, useFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { DropDownImage } from '../../consignado-comodato-nova-os/components/drop-down-image';
import styles from './Component.module.scss';
import { arraysEqual } from '../../../../shared/utils/Utils';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';

export function DropboxTiposDeFrete({ statusCss, className, label, onChanged }) {
  const [opened, setOpened] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const { values } = useSelector(selectFiltroOs);
  const tiposFrete = useSelector(selectConsignadoComodatoSharedData).tiposFrete;
  const dispatch = useDispatch();
  const { changeValue } = useFiltroOs();

  useEffect(() => {
    try {
      if (!values.tiposFrete) {
        return;
      }
      const src = (values.tiposFrete || '').split('_').map(x => parseInt(x, 10));
      const its = (tiposFrete || []).filter(x => src.includes(x.id)).map(x => x.id);
      if (!arraysEqual(its, selectedItemKeys)) {
        setSelectedItemKeys(its);
      }
      setSelectedItemKeys(its);
    } catch (error) {
      console.error(error);
    }
  }, [values.tiposFrete, tiposFrete, selectedItemKeys]);

  const onSelectedItemKeysChange = useCallback(
    args => {
      if ([args.selectedRowKeys].length === 0) {
        dispatch(changeValue({ fieldName: 'tiposFrete', value: '' }));
        return;
      }
      const its = (tiposFrete || []).filter(x => args.selectedRowKeys.includes(x.id));
      if (typeof onChanged === 'function') {
        onChanged(its);
      }
      console.log('TESTE=>', its.map(x => x.id).join('_'), values.tiposFrete || '');
      if (its.map(x => x.id).join('_') === (values.tiposFrete || '')) return;
      const ids = [];
      for (const it of its) {
        ids.push(it.id);
      }
      dispatch(changeValue({ fieldName: 'tiposFrete', value: ids.join('_') }));
      setSelectedItemKeys(args.selectedRowKeys);
    },
    [dispatch, changeValue, tiposFrete, onChanged, values.tiposFrete],
  );
  return (
    <div className={`${className} mt-1`}>
      <DropDownBox
        showClearButton={false}
        className={`${styles.bottonBorder} ml-1`}
        placeholder="Tipos de Frete"
        valueExpr="id"
        displayExpr="nome"
        showDropDownButton={true}
        dropDownButtonRender={DropDownImage}
        dataSource={tiposFrete}
        openOnFieldClick={true}
        value={selectedItemKeys}
        onOpenedChange={e => {
          setOpened(e);
        }}
        opened={opened}
        onKeyUp={e => {
          if (opened) e.preventDefault();
          if (
            e.event.originalEvent.code === 'ArrowDown' ||
            e.event.originalEvent.key === 'ArrowDown' ||
            e.event.originalEvent.code === 'Enter' ||
            e.event.originalEvent.key === 'Enter' ||
            e.event.originalEvent.code === 'Space' ||
            e.event.originalEvent.key === 'Space'
          ) {
            setOpened(true);
          }
        }}
        contentRender={() => {
          return (
            <DataGrid dataSource={tiposFrete} keyExpr="id" showBorders={false} selectedRowKeys={selectedItemKeys} onSelectionChanged={onSelectedItemKeysChange}>
              <Selection mode="multiple" selectAllMode={'allPages'} showCheckBoxesMode={'always'} />
              <FilterRow visible={true} />
              <Scrolling mode="normal" />
              <Column dataField="nome" caption="Nome" />
            </DataGrid>
          );
        }}
      />
      <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    </div>
  );
}
