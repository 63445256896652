import React, { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { LoadIndicator } from "devextreme-react/load-indicator";

import LinxPostos from "../../../components/core/linxPostos/LinxPostos";
import { getCnpj, getNomePagina } from "../../../shared/utils/Utils";

import { menus } from '../../../shared/constants/MenuConstants';

import { LateralContaDigitalIntegrada } from "./components/LateralContaDigitalIntegrada";
import { Extrato } from "./components/Extrato/Extrato";
import { Pagamentos } from "./components/Pagamentos/Pagamentos";
import { Cobrancas } from "./components/Cobrancas/Cobrancas";
import { Transferencias } from "./components/Transferencias/Transferencias";
import { DDA } from "./components/DDA/DDA";
import { AplicativoAprovador } from "./components/AplicativoAprovador/AplicativoAprovador";
import { Configuracoes } from "./components/Configuracoes/Configuracoes";

import { MensagemErro } from "./SharedComponents";

import { getDadosLogin } from "../../../shared/utils/Utils";
import { ContaDigitalIntegradaService } from "./ContaDigitalIntegrada.service";

import styles from "./ContaDigitalIntegrada.module.scss";

export function ContaDigitalIntegrada() {
  const [isLoading, setIsisLoading] = useState(true);
  const [redirect, setRedirect] = useState(null);
  const [statusAccount, setStatusAccount] = useState(0);
  const [isIframe, setIsIframe] = useState(false);
  const [errorAccount, setErrorAccount] = useState(null);
  const [funcao, setFuncao] = useState("extrato");

  const PATH_MENU = menus.COD_5700;

  const handleCallback = funcaoMenu => {
    setFuncao(funcaoMenu);
  };

  const OpenAccountValidator = async () => {
    const dadosLogin = getDadosLogin();

    const response = await ContaDigitalIntegradaService.openAccount(getCnpj(), dadosLogin.codigoEstabelecimento);

    if (response && response.result) {
      const { iframe, status } = response.result;

      setStatusAccount(parseInt(status));

      if (status !== 5) {
        setIsIframe(true);
        setRedirect(iframe);
      }

      setIsisLoading(false);
    } else {
      setErrorAccount(response.message);
      setIsisLoading(false);
    }
  }

  useEffect(() => {
    OpenAccountValidator();
  }, []);

  return (
    <>
      <LinxPostos className={styles.ContainerContaDigitalIntegrada} breadcrumb={getNomePagina(PATH_MENU)} id="page">
        {!isLoading && statusAccount === 5 ?
          <Card>
            <Card.Body className="container-fluid">
              <Row>
                <Col xs={2} className={styles.colPainelEsquerda}>
                  <LateralContaDigitalIntegrada onSelectFuncao={handleCallback}></LateralContaDigitalIntegrada>
                </Col>
                <Col xs={10}>
                  {(() => {
                    if (funcao === "extrato") {
                      return (
                        <Extrato></Extrato>
                      )
                    }
                    else if (funcao === "pagamentos") {
                      return (
                        <Pagamentos></Pagamentos>
                      )
                    }
                    else if (funcao === "cobrancas") {
                      return (
                        <Cobrancas></Cobrancas>
                      )
                    }
                    else if (funcao === "transferencias") {
                      return (
                        <Transferencias></Transferencias>
                      )
                    }
                    else if (funcao === "dda") {
                      return (
                        <DDA></DDA>
                      )
                    }
                    else if (funcao === "aplicativo") {
                      return (
                        <AplicativoAprovador></AplicativoAprovador>
                      )
                    }
                    else if (funcao === "configuracoes") {
                      return (
                        <Configuracoes></Configuracoes>
                      )
                    }
                    return null;
                  })()}
                </Col>
              </Row>
            </Card.Body>
          </Card>
          :
          <div className="d-flex flex-column justify-content-center align-items-center h-100 min-vh-100">
            {!isIframe &&
              <LoadIndicator id="large-indicator" height={60} width={60} />
            }
            <p>
              {!redirect ?
                <span>Aguarde estamos carregando a sua página da Conta Digital Integrada...</span>
                :
                <>
                  {!isIframe ?
                    <span className="d-flex text-center">
                      Identificamos que você ainda não possui uma Conta Digital Integrada. <br />
                      Vamos redirecionar você para a abertura da conta. <br />
                      Por favor aguarde mais alguns instantes enquanto fazemos o redirecionamento.
                    </span>
                    :
                    <iframe src={redirect} frameborder="0" width="100%" height="100%" style={{ overflow: "hidden", width: "94vw", height: "94vh" }}></iframe>
                  }
                </>
              }
            </p>
            <MensagemErro
              isShow={errorAccount}
              titulo="A Conta Digital Integrada não pôde ser carregada no momento. Por favor, tente novamente mais tarde."
              mensagem={errorAccount}
            />
          </div>
        }
      </LinxPostos>
    </>
  );
}

export default withRouter(ContaDigitalIntegrada);
