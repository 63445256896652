import React from 'react';
import { Link } from 'react-router-dom';
import styles from './DicaLinxOptions.module.scss';
import 'rc-tooltip/assets/bootstrap_white.css';

const dicaLinxOptions = props => {

    return (
        <div className={`${styles.dicaLinxOptions} dicaLinxOptions`}>
            <li className={`${styles.navItem} navItem dropdown d-none d-sm-block`}>
                <span
                    className={`${styles.navLink} lx-icon dropdown-toggle pt-0 pb-0 text-uppercase`}
                    //onClick={props.toggleDicaLinxOptions}
                >
                    <i className={`${styles.profile} icon icon-lx-hash`} />
                    <a 
                        className="help-link"
                        target="_blank"
                        rel="noopener noreferrer"                    
                        href = {`${props.urlLinxShare}`}>
                    #DicaLinx
                    </a>
                </span>

                {props.showDicaLinxOptions 
                //&& 
                //(
                //    <div className="show dropdown-menu">
                //        <a 
                //            className="dropdown-item" 
                //            href="https://share.linx.com.br/pages/viewpage.action?pageId=210536436"
                //            target="_blank"
                //            rel="noreferrer noopener"
                //        >
                //            <i className={`${styles.profile} icon icon-lx-file-pdf`} />
                //            Teste Dicalinx 1
                //        </a>
                //        <a 
                //            className="dropdown-item" 
                //            href="https://share.linx.com.br/pages/viewpage.action?pageId=210536436"
                //           target="_blank"
                //            rel="noreferrer noopener"
                //        >
                //            <i className={`${styles.profile} icon icon-lx-file-pdf`} />
                //</li>            Teste Dicalinx 2
                //        </a>
                //        <a 
                //            className="dropdown-item" 
                //            href="https://share.linx.com.br/pages/viewpage.action?pageId=210536436"
                //            target="_blank"
                //            rel="noreferrer noopener"
                //        >
                //            <i className={`${styles.profile} icon icon-lx-file-pdf`} />
                //            Teste Dicalinx 3
                //        </a>
                //    </div>
                //)
                }
                {props.showDicaLinxOptions && (
                    <div
                        className={styles.dropdownOverlay}
                      //  onClick={props.toggleDicaLinxOptions}
                    ></div>
                )}
            </li>
        </div>
    );
};

export default dicaLinxOptions;
