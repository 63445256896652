import React from 'react';
import styles from './YouTubeButton.module.scss';

export default function YouTubeButton() {
  return (
    <div className={styles.YouTubeButton}>
      <a
        href="https://www.youtube.com/user/linxretail"
        target="_blank"
        rel="noopener noreferrer"
        ><i className={`icon icon-lx-youtube`}></i></a>
    </div>
  );
}
