import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

export const InputText = forwardRef((props, ref) => {
  const { onChange } = props;
  const [value, setValue] = useState(props.value || '');
  const input = useRef();

  var onFocus = useCallback(e => {
    e.target.select();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      setFocus: () => {
        if (input.current) {
          input.current.focus();
        }
      },
      getValue: () => {
        return value;
      },
      setNewValueAndSelect: v => {
        if (input.current) {
          input.current.value = v;
          if (onChange) {
            onChange(v);
          }
          input.current.select();
        }
      },
    }),
    [onChange, value],
  );

  useEffect(() => {
    setValue(props.value || '');
  }, [props.value]);

  const handleChange = useCallback(
    event => {
      if (!event.target.value) {
        if (onChange) {
          event.persist();
          onChange(event);
        }
        setValue('');
        return;
      }
      setValue(event.target.value);

      if (onChange) {
        event.persist();
        onChange(event.target.value);
      }
    },
    [onChange],
  );

  return (
    <input
      type="text"
      id={props.id}
      ref={input}
      placeholder={props.placeholder}
      className={props.className}
      disabled={props.disabled}
      onFocus={onFocus}
      value={value}
      onChange={handleChange}
    />
  );
});
