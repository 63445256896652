import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './FortesFiscal.module.scss';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, { Selection, Paging, Grouping, ColumnFixing, Pager, ColumnChooser, GroupPanel, SearchPanel, Export } from 'devextreme-react/data-grid';
import { DropDownBox, DateBox } from 'devextreme-react';
import ptMessages from '../../../../assets/js/pt.json';
import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../shared/utils/Utils';
import { fortesFiscalService } from './FortesFiscal.service';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';

export function FortesFiscal(props) {

    let today = new Date();
    const PATH_MENU = menus.COD_80610;
    const [showSearch] = useState(false);
    const [searching, setSearching] = useState(false);

    const [gerando, setGerando] = useState(false);

    const [versoes, setVersoes] = useState([]);
    const [gridBoxVersoesValue, setGridBoxVersoesValue] = useState([]);

    const [arquivosGerados, setArquivosGerados] = useState([]);

    const [startDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0));

    const [appliedFilters, setAppliedFilters] = useState({
        versao: 0,
        dataInicial: startDate,
        dataFinal: endDate,
    });

    const getTiposArquivos = async () => {
        const response = await fortesFiscalService.getTipoArquivo();
        if (response.length) setVersoes(response[0].versoes)


    };

    const onDateValueChanged = ((event) => {
        if (event.element.id === 'dataInicial' && !moment(event.value).isValid()) {
            notify('Data inicial inválida.', 'error', 2000, 'top');
        } else if (event.element.id === 'dataFinal' && !moment(event.value).isValid()) {
            notify('Data final inválida.', 'error', 2000, 'top');
        } else {
            setAppliedFilters(filters => {
                return {
                    ...filters,
                    [event.element.id]: event.element.id === ('dataInicial' || 'dataFinal') && event.value !== null ? new Date(event.value).toISOString() : event.value
                }
            });
        }

    });

    const isDownloadAvailable = e => {
        let file = e.row ? e.row.data : {};
        return file.download && file.download.length > 0
    }

    const download = async e => {
        let file = e.row ? e.row.data : {};

        if (file.download && file.download.length > 0) {
            window.open(file.download);
        }
    }

    const columns = [
        { dataField: 'tipoArquivo', caption: 'Tipo', width: '180' },
        { dataField: 'numeroVersao', caption: 'Versão', width: '90' },
        { dataField: 'dataSolicitacao', caption: 'Data Solicitação', dataType: 'date', format: "dd/MM/yyyy HH:mm", width: '150' },
        { dataField: 'dataAtualizacao', caption: 'Data Atualização', dataType: 'date', format: "dd/MM/yyyy HH:mm", width: '150' },
        { dataField: 'mensagem', caption: 'Mensagem' },
        { dataField: 'parametrosDescricao', caption: 'Filtros' },
        {
            type: 'buttons', caption: 'Download',
            buttons: [{ hint: 'Download', icon: 'download', visible: isDownloadAvailable, onClick: download }]
        }
    ];

    useEffect(() => {
        getTiposArquivos();
        getUltimos();
        loadMessages(ptMessages);
        locale(navigator.language);
    }, []);

    const getUltimos = async () => {
        setSearching(true);

        const response = await fortesFiscalService.getUltimos();

        if (response.notificationLevel === 1) {
            setArquivosGerados(response.data);
            setSearching(false);
        } else {
            notify(response.message, 'error', 2000, 'top');
            setSearching(false);
        }
    };

    const gerar = async () => {
        setGerando(true);

        if (!appliedFilters) {
            notify('Os campos devem ser informados para gerar o arquivo.', 'error', 2000, 'top');
            setGerando(false);
        } else {

            if (!moment(appliedFilters.dataInicial).isValid() || new Date(appliedFilters.dataInicial).getFullYear() === 0) {
                notify('Data inicial inválida.', 'error', 2000, 'top');
                setGerando(false);
                return;
            }

            if (!moment(appliedFilters.dataFinal).isValid() || new Date(appliedFilters.dataFinal).getFullYear() === 0) {
                notify('Data final inválida.', 'error', 2000, 'top');
                setGerando(false);
                return;
            }

            if (!appliedFilters.versao === 0) {
                notify('A versão do arquivo deve ser selecionada.', 'error', 2000, 'top');
                setGerando(false);
                return;
            }

            const response = await fortesFiscalService.gerar({
                versao: appliedFilters.versao,
                dataInicial: appliedFilters.dataInicial,
                dataFinal: appliedFilters.dataFinal
            });
            if (response.notificationLevel === 1) {
                if (response.data) {
                    notify('Solicitação gerada com sucesso.', 'info', 2000, 'top');
                    setGerando(false);
                } else {
                    notify(`Não foi possível solicitar a geração do arquivo. ${response.message}`, 'error', 2000, 'top');
                    setGerando(false);
                }
            } else {
                notify(response.message, 'error', 2000, 'top');
                setGerando(false);
            }

            await getUltimos();
        }
    };

    const clearVersoes = () => {
        setGridBoxVersoesValue([]);

        setAppliedFilters(filters => {
            return {
                ...filters,
                versao: 0
            }
        });
    };

    const syncDataGridVersoesSelection = (e) => {
        setGridBoxVersoesValue(e.value || []);
        clearVersoes();
    };

    const dataGridVersoes_onSelectionChanged = (e) => {
        let ids = [];
        e.selectedRowKeys.forEach(x => {
            ids.push(x.id);
        });
        setGridBoxVersoesValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);

        setAppliedFilters(filters => {
            return {
                ...filters,
                versao: ids[0]
            }
        });
    };

    const dataGridVersoesRender = () => {
        return (
            <DataGrid
                dataSource={versoes}
                columns={[{ dataField: 'numeroVersao', caption: 'Versão' }]}
                hoverStateEnabled={true}
                showBorders={true}
                showSearch={true}
                height={400}
                selectedRowKeys={gridBoxVersoesValue}
                onSelectionChanged={dataGridVersoes_onSelectionChanged}>
                <Selection mode="single" />
                <Paging enabled={false} />
            </DataGrid>
        );
    };

    return (
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={false} />
            <div className={`${styles.fortesFiscal} mt-3`}>
                <div className="shadow-sm bg-white p-3">
                    <div className={`${showSearch ? styles.search : ''}`}>
                        <div className="container">
                            <div className={`${styles.filtersrWrapper} row`}>
                                <div className="col-md-12">
                                    <h2>EXPORTAÇÃO FORTES FISCAL</h2>
                                    <hr />
                                    <div className={`${showSearch ? styles.fadeIn : ''} ${styles.searchWrapper}`}>
                                        <div className="row">
                                            <div className={`${styles.dropDownBox} col-md-3 form-group mb-1`}>
                                                <span>Versão</span>
                                                <DropDownBox
                                                    value={gridBoxVersoesValue.map(item => item.numeroVersao)}
                                                    valueExpr="id"
                                                    deferRendering={false}
                                                    displayExpr="numeroVersao"
                                                    placeholder="Selecione a versão..."
                                                    disabled={gerando}
                                                    dataSource={versoes}
                                                    onValueChanged={syncDataGridVersoesSelection}
                                                    contentRender={dataGridVersoesRender}
                                                />
                                            </div>

                                            <div className={`${styles.datebox} col-md-3 form-group mb-1`}>
                                                <span>Data Inicial</span>
                                                <DateBox defaultValue={startDate}
                                                    id="dataInicial"
                                                    type="date"
                                                    onValueChanged={onDateValueChanged}
                                                    disabled={gerando}
                                                    useMaskBehavior={true}
                                                    value={appliedFilters.dataInicial} />

                                            </div>
                                            <div className={`${styles.datebox} col-md-3 form-group mb-1`}>
                                                <span>Data Final</span>
                                                <DateBox defaultValue={endDate}
                                                    type="date"
                                                    id="dataFinal"
                                                    onValueChanged={onDateValueChanged}
                                                    disabled={gerando}
                                                    useMaskBehavior={true}
                                                    value={appliedFilters.dataFinal} />

                                            </div>
                                        </div>

                                        <footer className="text-right mt-4">
                                            <button className="btn btn-primary" onClick={() => gerar()} disabled={gerando}>
                                                {' '}
                                                {gerando ? (
                                                    <>
                                                        <svg className={styles.spinner} viewBox="0 0 50 50">
                                                            <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                            </circle>
                                                        </svg> GERANDO...</>) : (`GERAR`)}
                                            </button>
                                        </footer>
                                    </div>

                                </div>
                            </div>

                            <div className={`${styles.fortesResult} mt-4`}>
                                <div className="row">
                                    <div className="col-md-12">

                                        <h2>ÚLTIMOS ARQUIVOS GERADOS</h2>


                                        <button className="btn btn-primary" onClick={() => getUltimos()} disabled={searching}>
                                            {' '}
                                            {searching ? (
                                                <>
                                                    <svg className={styles.spinner} viewBox="0 0 50 50">
                                                        <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                        </circle>
                                                    </svg> ATUALIZANDO...</>) : (`ATUALIZAR LISTA`)}
                                        </button>

                                        <DataGrid dataSource={arquivosGerados} defaultColumns={columns} showBorders={true} allowColumnResizing={true}
                                            allowColumnReordering={true} columnResizingMode={'widget'} columnMinWidth={80} disabled={searching} rowAlternationEnabled={true}>
                                            <GroupPanel visible={true} />
                                            <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                            <Export enabled={true} fileName="Exportações" />
                                            <SearchPanel visible={true} highlightCaseSensitive={true} />
                                            <Grouping autoExpandAll={true} />
                                            <ColumnFixing enabled={true} />
                                            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
                                            <Paging defaultPageSize={15} />
                                        </DataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LinxPostos >
    );
}

export default withRouter(FortesFiscal);