import React from 'react';
import DataGrid, { Scrolling, Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import styles from './RaioXDashboard.module.scss';
import RaioXDashboardLoading from './RaioXDashboardLoading';
import { SectionContainer } from '../../../../components/section/Content';

export default function RaioXDashboardGrid({title, dados, isLoading}) {

  const headerCellTemplate = data => {
    return <div className={styles.gridColumnHeader}>
            {data.column.caption}
          </div>;
  } 

  return (
    <div className={styles.dataGridContainer}>
      { isLoading
      ? (
        <SectionContainer>
          <div className={styles.gridLoadingContainer}>
            <RaioXDashboardLoading />
          </div>
        </SectionContainer>
      )
      : (
        <DataGrid
          dataSource={dados}
          showBorders={true}
          className="bg-white shadow-sm p-3"
          noDataText="Sem dados"
          rowAlternationEnabled={true}
          style={{height: '100%'}}
        >
          <Column
            dataField="nome"
            caption={title}
            alignment="left"
            headerCellRender={headerCellTemplate}
          />
          <Column
            dataField="valorTotal"
            caption="Valor R$"
            alignment="right"
            headerCellRender={headerCellTemplate}
          />
          <Scrolling mode="virtual" />
        </DataGrid>
        )}
    </div>
  )
}
