export const passarTipoOperacaoSelecionadosParaTexto = statusArr => {
  let ret = '';
  for (const it of statusArr) {
    switch (it) {
      default:
      case 2:
        ret += `${ret.length ? ', ' : ''}Consignado`;
        break;
      case 3:
        ret += `${ret.length ? ', ' : ''}Comodato`;
        break;
    }
  }
  return `${ret}`;
};
