import axios from "axios";

import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";
import { getUrl, getCnpj } from "../../../shared/utils/Utils";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getPerfis(consulta) {
  consulta.cnpjEstabelecimento = getCnpj();
  consulta.cadastroCompleto = true;

  return axios.post(`LxApi/v1/PerfilAcesso/Listar/`, {
    query: consulta
  });
}

export function getPerfil(codigoPerfil) {
  return axios.get(`LxApi/v1/PerfilAcesso/Selecionar/`, {
    params: { codigoPerfil }
  });
}

export function getEstruturaPermissaoPadrao() {
  return axios.get(`LxApi/v1/PerfilAcesso/SelecionarEstruturaPermissaoPadrao/`);
}

export function alterarPerfil(perfil) {
  return axios.put(`LxApi/v1/PerfilAcesso/Alterar/`, perfil);
}

export function incluirPerfil(perfil) {
  return axios.post(`LxApi/v1/PerfilAcesso/Incluir/`, perfil);
}

export function montaComboPerfisAcesso() {
  return axios.get(`LxApi/v1/PerfilAcesso/MontarComboPerfisAcesso`);
}

export function excluirPerfil(codigoPerfil) {
  return axios.delete(`LxApi/v1/PerfilAcesso/Excluir`, {
    params: { codigoPerfil }
  });
}
