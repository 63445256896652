import memoryCache from 'memory-cache';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiListarTiposPedidosService {
  static instance = null;
  static getInstance() {
    if (!ApiListarTiposPedidosService.instance) {
      ApiListarTiposPedidosService.instance = new ApiListarTiposPedidosService();
    }
    return ApiListarTiposPedidosService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (useCache = true) => {
    let cacheKey = '';
    if (useCache) {
      const dadosLogin = getDadosLogin();
      cacheKey = `ApiListarTiposPedidosService_${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);
      if (cachedData) {
        return cachedData;
      }
    }
    try {
      const response = await this.urlLinxCore.get('/ConComodato/listar-tipos-pedidos');
      const tiposPedidos = response.data.data || [];
      tiposPedidos.unshift({ id: -1, nome: 'SELECIONE' });
      if (useCache) {
        memoryCache.put(cacheKey, tiposPedidos, 30 * 60 * 1000); // cache de 30 minutos
      }
      return tiposPedidos;
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  }
}
