import memoryCache from 'memory-cache';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';

export class ApiListarStatusService {
  static instance = null;
  static getInstance() {
    if (!ApiListarStatusService.instance) {
      ApiListarStatusService.instance = new ApiListarStatusService();
    }
    return ApiListarStatusService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async () => {
    try {
      const dadosLogin = getDadosLogin();
      const cacheKey = `ApiListarStatusService_${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        return cachedData;
      }
      const response = await this.urlLinxCore.get('/ConComodato/listar-status');
      const status = response.data.itens || [];
      memoryCache.put(cacheKey, status, 30 * 60 * 1000); // cache de 30 minutos
      return status;
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  }
}
