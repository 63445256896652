import { SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFiltroOs, useFiltroOs } from '../../../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { BuscarProdutoService } from '../../../../../../services/produtos/BuscarProdutos.service';
import { DropDownImage } from '../../../../consignado-comodato-nova-os/components/drop-down-image';

export const BuscarProdutoFiltro = memo(
  forwardRef(({ onNextFocus, tipoPedidoId }, ref) => {
    const { changeValue } = useFiltroOs();
    const { values } = useSelector(selectFiltroOs);
    const dispatch = useDispatch();
    const select = useRef();

    const store = new DataSource({
      key: 'id',
      load: async function(arg) {
        if (arg.searchOperation !== 'contains') {
          return [];
        }
        const produtos = await new BuscarProdutoService().execute(arg.searchValue, [3, 8].includes(tipoPedidoId) ? 9 : 1);
        return produtos;
      },
      byKey: async function(key) {
        if (values.produto && values.produto.id === key) return values.produto;
        else return null;
      },
    });

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          select.current.instance.focus();
          select.current.instance.reset();
        },
      };
    });

    const onSelectionChanged = useCallback(
      e => {
        console.log('onSelectionChanged ProdutoFiltro');
        if (!e.selectedItem) {
          dispatch(changeValue({ fieldName: 'produto', value: null }));
          return;
        }
        const data = { ...e.selectedItem };
        if (values.produto && data.codigo === values.produto.codigo) {
          return;
        }
        dispatch(changeValue({ fieldName: 'produto', value: data }));
        if (onNextFocus !== undefined) {
          onNextFocus();
        }
      },
      [dispatch, changeValue, onNextFocus, values.produto],
    );

    return (
      <>
        <SelectBox
          name="produtoFiltro"
          id="produtoFiltro"
          ref={select}
          dropDownButtonRender={DropDownImage}
          style={{ borderTop: '0px', borderRight: '0px', borderLeft: '0px' }}
          searchEnabled={true}
          hint="Digite a descrição, EAN ou o codigo rápido do produto"
          placeholder="Digite a descrição, EAN ou o codigo rápido do produto"
          displayExpr="descricao"
          height={40}
          valueExpr="id"
          dataSource={store}
          selectedItem={values.produto}
          searchMode="contains"
          showClearButton={true}
          onSelectionChanged={onSelectionChanged}
          searchTimeoutOption={500}
        ></SelectBox>
      </>
    );
  }),
);
