import { AtualizarClienteService } from './AtualizarClienteService';
import { ApiBuscarMunicipioService } from './BuscarMunicipio.service';
import { ApiBuscarUfsService } from './BuscarUfs.service';
import { EditarEnderecoService } from './EditarEndereco.service';
import { InserirEnderecoAdicionalService } from './InserirEnderecoAdicional.service';

export class ClienteService {
  static instance = null;
  static getInstance() {
    if (!ClienteService.instance) {
      ClienteService.instance = new ClienteService();
    }
    return ClienteService.instance;
  }

  listarUfService = new ApiBuscarUfsService();
  listarMunicipioService = new ApiBuscarMunicipioService();
  inserirEnderecoAdicionalService = new InserirEnderecoAdicionalService();
  editarEnderecoService = new EditarEnderecoService();

  atualizar = async cliente => {
    await AtualizarClienteService.getInstance().execute(cliente);
  };
  listarUfs = async uf => {
    return await this.listarUfService.execute(uf);
  };
  listarMunicipios = async (ufId, municipio) => {
    return await this.listarMunicipioService.execute(ufId, municipio);
  };
  inserirEnderecoAdicional(data) {
    return this.inserirEnderecoAdicionalService.execute(data);
  }
  editarEndereco(data) {
    return this.editarEnderecoService.execute(data);
  }
}
const clienteService = new ClienteService();
export default clienteService;
