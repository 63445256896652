// Link documentação
// https://www.sefaz.ba.gov.br/inspetoria-eletronica/icms/cadastro/calculo-dv/#:~:text=O%20n%C3%BAmero%20de%20inscri%C3%A7%C3%A3o%20estadual,9%20para%20o%201%C2%BA%20d%C3%ADgito).

export function CheckIEBA(ie) {
  if (ie.length !== 9) return false;
  let die = ie.substring(0, 9);
  const ieSemDigito = die.substring(0, 7);
  const digito = die.substring(die.length - 2);
  console.log('ieSemDigito', ieSemDigito, digito);
  let calculatedDigits = '';
  if (die[1] === '6' || die[1] === '7' || die[1] === '9') {
    calculatedDigits = calcularDigitosVerificadoresMod11(ieSemDigito);

  } else {
    calculatedDigits = calcularDigitosVerificadoresMod10(ieSemDigito);
  }
  return digito === calculatedDigits;
} //ba

function calcularDigitosVerificadoresMod10(inscricaoEstadual) {
  if (inscricaoEstadual.length !== 7) {
    throw new Error('A inscrição estadual deve ter exatamente 7 dígitos.');
  }
  // Cálculo do 1º dígito verificador
  let pesosSegundoDigito = [8, 7, 6, 5, 4, 3, 2];
  let somaSegundoDigito = 0;

  for (let i = 0; i < 7; i++) {
    somaSegundoDigito += parseInt(inscricaoEstadual[i], 10) * pesosSegundoDigito[i];
  }

  let restoSegundoDigito = somaSegundoDigito % 10;
  let segundoDigito = 10 - restoSegundoDigito;
  if (restoSegundoDigito === 0) {
    segundoDigito = 0;
  }

  // Cálculo do 1º dígito verificador (com o 2º dígito já calculado)
  let pesosPrimeiroDigito = [9, 8, 7, 6, 5, 4, 3, 2];
  let inscricaoComSegundoDigito = inscricaoEstadual + segundoDigito;
  let somaPrimeiroDigito = 0;

  for (let i = 0; i < 8; i++) {
    somaPrimeiroDigito += parseInt(inscricaoComSegundoDigito[i], 10) * pesosPrimeiroDigito[i];
  }
  let restoPrimeiroDigito = somaPrimeiroDigito % 10;
  let primeiroDigito = 10 - restoPrimeiroDigito;

  if (restoPrimeiroDigito === 0) {
    primeiroDigito = 0;
  }

  return `${primeiroDigito}${segundoDigito}`;
}

function calcularDigitosVerificadoresMod11(inscricaoEstadual) {
  if (inscricaoEstadual.length !== 7) {
    throw new Error('A inscrição estadual deve ter exatamente 7 dígitos.');
  }
  const mod = 11;
  // Cálculo do 1º dígito verificador
  let pesosSegundoDigito = [8, 7, 6, 5, 4, 3, 2];
  let somaSegundoDigito = 0;

  for (let i = 0; i < 7; i++) {
    somaSegundoDigito += parseInt(inscricaoEstadual[i], 10) * pesosSegundoDigito[i];
  }

  let restoSegundoDigito = somaSegundoDigito % mod;
  let segundoDigito = mod - restoSegundoDigito;

  if (restoSegundoDigito === 0 || restoSegundoDigito === 1) {
    segundoDigito = 0;
  }

  // Cálculo do 1º dígito verificador (com o 2º dígito já calculado)
  let pesosPrimeiroDigito = [9, 8, 7, 6, 5, 4, 3, 2];
  let inscricaoComSegundoDigito = inscricaoEstadual + segundoDigito;
  let somaPrimeiroDigito = 0;

  for (let i = 0; i < 8; i++) {
    somaPrimeiroDigito += parseInt(inscricaoComSegundoDigito[i], 10) * pesosPrimeiroDigito[i];
  }
  let restoPrimeiroDigito = somaPrimeiroDigito % mod;
  let primeiroDigito = mod - restoPrimeiroDigito;
  if (primeiroDigito === 0 || primeiroDigito === 1) {
    primeiroDigito = 0;
  }

  if (restoPrimeiroDigito === 0 || restoPrimeiroDigito === 1) {
    primeiroDigito = 0;
  }

  return `${primeiroDigito}${segundoDigito}`;
}
