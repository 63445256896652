import { DateBox } from 'devextreme-react';
import React from 'react';

const dateTimeBox = props => {
  return (
    <DateBox
      defaultValue={props.date}
      id={props.id}
      type={props.type}
      onValueChanged={props.onValueChanged}
      disabled={props.disabled}
      useMaskBehavior={true}
      value={props.value}
      dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
      placeholder={props.placeholder}
      showClearButton={true}
      useMaskBehavior={true}
      displayFormat={props.displayFormat}
      height={props.height}
      min={props.min}
      dateOutOfRangeMessage={props.dateOutOfRangeMessage}
    />
  );
};

export default dateTimeBox;
