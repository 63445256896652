import { MD5 } from 'crypto-js';
import memoryCache from 'memory-cache';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import { getDadosLogin } from '../../shared/utils/Utils';
export class BuscarProdutoService {
  static instance = null;
  static getInstance() {
    if (!BuscarProdutoService.instance) {
      BuscarProdutoService.instance = new BuscarProdutoService();
    }
    return BuscarProdutoService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async (arg, tipoProduto = null, byId = false) => {
    try {
      if (!arg) {
        return [];
      }
      const dadosLogin = getDadosLogin();
      const urlArg = `/Produto/Buscar?args=${arg}&limit=10${tipoProduto ? `&tipoProduto=${tipoProduto}` : ''}&byId=${byId}`;
      const urlArgMd5 = MD5(urlArg);
      const cacheKey = `api_buscar_produto_${urlArgMd5}_${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        return cachedData;
      }
      const response = await this.urlLinxCore.get(urlArg);
      const result = response.data.data;
      memoryCache.put(cacheKey, result, 5 * 60 * 1000);
      return result;
    } catch (error) {
      return error.response.data;
    }
  };
}
