import React, { memo, useCallback } from 'react';

import { DropDownImage } from '../drop-down-image';
import { Form } from 'react-bootstrap';
import { SelectBox } from 'devextreme-react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';


const DropBoxCentroMonetarios = forwardRef(({ title, onSelected, isBoleto }, ref) => {
  const centrosMonetarios = useSelector(selectConsignadoComodatoSharedData).centrosMonetarios;
  const centosMonetariosCadastradosManualmente = useSelector(selectConsignadoComodatoSharedData).centroMonetarioCadastradosManualmente;
  const [centro_monetario, setCentroMonetario] = useState(null);
  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem) {
        if (centro_monetario) return;
        setCentroMonetario(null);
        return;
      }
      const data = { ...e.selectedItem };
      if (data.id === (centro_monetario || {}).id) return;
      setCentroMonetario(data);
    },
    [centro_monetario, setCentroMonetario],
  );

  const onValueChanged = useCallback(
    data => {
      if (!data.event) return;
      if (onSelected) onSelected(data.value);
    },
    [onSelected],
  );

  useImperativeHandle(
    ref,
    () => ({
      getCentroMonetario: () => {
        return centro_monetario;
      },
      clear: () => {
        setCentroMonetario(null);
        if (onSelected) onSelected(null);
      }
    }),
    [centro_monetario, onSelected],
  );

  return (
    <>
      <Form.Label htmlFor="centro_monetario" className="text-primary">
        {title ? title : 'Centro Monetário'}
        <span className="text-danger">*</span>
      </Form.Label>
      <SelectBox
        dropDownButtonRender={DropDownImage}
        showDropDownButton={true}
        searchEnabled={true}
        hint="Selecione o centro monetário"
        placeholder="Selecione o centro monetário"
        displayExpr="nome"
        acceptCustomValue={false} // Não permite que o valor digitado seja aceito
        height={40}
        valueExpr="id"
        value={centro_monetario}
        onSelectionChanged={onSelectionChanged}
        onValueChanged={onValueChanged}
        dataSource={isBoleto ? centrosMonetarios : centosMonetariosCadastradosManualmente}
        selectedItem={centro_monetario || {}}
        name="centro_monetario"
      />
    </>
  );
});

export default memo(DropBoxCentroMonetarios);
