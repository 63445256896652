import React, { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { NotificationLevel } from '../../../../shared/constants/Const';
import RaioXModalAdesaoCampos from './RaioXModalAdesaoCampos';
import { ReactComponent as RaioxLogo } from '../../../../../assets/images/linx/logo_raiox.svg';
import icoSuccess from '../../../../../assets/images/postopop/modal-alerta-ic-sucesso.png';
import notify from 'devextreme/ui/notify';
import { raioxService } from '../RaioX.service';
import styles from './RaioXModal.module.scss';
import { alert } from 'devextreme/ui/dialog';
import { useCallback } from 'react';
import { Popup, ScrollView } from 'devextreme-react';

export default function RaioXModalAdesao({ setShowModalAdesao, setIsAdesaoConcluidaViaModal }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAdesaoConcluida, setIsAdesaoConcluida] = useState(false);
  const [empresa, setEmpresa] = useState();

  // Carrega dados da empresa logada no sistema.
  useEffect(() => {
    async function getEmpresa() {
      try {
        console.log('useEffect 1');
        setIsLoading(true);
        console.log('useEffect 2');
        const result = await raioxService.GetEmpresa();
        console.log('useEffect 3');
        if (result.notificationLevel === NotificationLevel.Success) {
          console.log('useEffect 4');
          const empresaSelecionada = result.data;

          if (!empresaSelecionada) {
            console.log('Não foi possível recuperar os dados da empresa!');
            notify('Não foi possível recuperar os dados da empresa!', 'error', 2000, 'top');
            setEmpresaEmpty();
          } else {
            console.log('foi possível recuperar os dados da empresa!');
            setEmpresa(empresaSelecionada);
          }
        } else {
          console.log('useEffect -1');
          notify(result.message, 'error', 2000, 'top');
          console.log('useEffect -2');
          setEmpresaEmpty();
          console.log('useEffect -3');
        }
        setIsLoading(false);
        console.log('useEffect FIM');
      } catch (error) {
        alert(error.message, 'OPS!!');
        setIsLoading(false);
      }
    }
    getEmpresa();
  }, []);

  // Altera status conforme adesão concluída
  useEffect(() => {
    if (isAdesaoConcluida) {
      setTimeout(function() {
        setIsAdesaoConcluida(false);
        setIsAdesaoConcluidaViaModal(true);
        setIsLoading(false);
        setShowModalAdesao(false);
      }, 3000);
    }
  }, [isAdesaoConcluida, setIsAdesaoConcluidaViaModal, setShowModalAdesao]);

  function setEmpresaEmpty() {
    setEmpresa({ endereco: { localidade: {}, unidadeFederativa: {} }, operadores: [{ nome: null, email: null, telefone: null }] });
  }

  async function aderirRaiox() {
    if (raioxService.existeCampoObrigatorioVazio(empresa)) {
      notify('Revise os campos obrigatórios que não foram preenchidos', 'warning', 2000, 'top');
      return;
    }

    // setIsLoading(true);

    // setTimeout(
    //   function() {
    //     setIsAdesaoConcluida(true);
    //   },
    //   2000
    // );

    setIsLoading(true);
    const result = await raioxService.Aderir();

    if (result.notificationLevel === NotificationLevel.Success) {
      setIsAdesaoConcluida(true);
    } else {
      notify(result.message, 'error', 2000, 'top');
      setIsLoading(false);
    }
  }

  function Loading() {
    return (
      <svg className={styles.spinner} viewBox="0 0 50 50">
        <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
    );
  }

  const closeModal = useCallback(() => {
    setShowModalAdesao(false);
  }, [setShowModalAdesao]);

  function CarregamentoAdesao() {
    const title = isAdesaoConcluida ? 'Adesão ao Raio-X realizada com sucesso!' : 'Realizando a adesão ao Raio-X';
    const subTitle = isAdesaoConcluida ? 'Obrigado por aguardar!' : 'Estamos preparando tudo para você!';

    return (
      <div className={styles.adesaoLoading}>
        {isAdesaoConcluida ? <img src={icoSuccess} alt="sucesso" style={{ width: '4.5rem', height: '4.5rem' }} /> : <Loading />}
        <div className={styles.adesaoLoadingText}>
          <h1>{title}</h1>
          <p>{subTitle}</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Popup id="popup" className={`${styles.noPadding}`} height={600} visible={true} onHiding={closeModal} hideOnOutsideClick={false} showTitle={false}>
        <div className="w-100 p-3 m-0" style={{ backgroundColor: '#5b2e90' }}>
          <RaioxLogo />
        </div>
        <ScrollView height={400} width="100%">
          <div className="mt-3">
            {isLoading && typeof empresa !== 'undefined' ? (
              <CarregamentoAdesao />
            ) : typeof empresa === 'undefined' ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                {' '}
                <Loading />{' '}
              </div>
            ) : (
              <RaioXModalAdesaoCampos empresa={empresa} />
            )}
          </div>
        </ScrollView>
        {!isLoading && (
          <div className="mt-3 w-100" style={{ justifyContent: 'center' }}>
            <hr/>
            <Button variant="light" onClick={closeModal}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={aderirRaiox}>
              Aderir ao Raio-X
            </Button>
          </div>
        )}
      </Popup>
    </>
  );
}
