import styles from './SelecionaEmpresa.module.scss';
import React, { useEffect, useState } from 'react';
import { Popup } from 'devextreme-react/popup';
import { getDadosLogin, getCnpj } from '../../../../shared/utils/Utils';
import EmpresaCard from './EmpresaCard/EmpresaCard';

const SelecionaEmpresa = props => {
  const [empresas, setEmpresas] = useState([]);
  const [selected, setSelected] = useState({});

  useEffect(() => {
    const dadosLogin = getDadosLogin();
    setEmpresas(dadosLogin.empresas);
    const empresaAtual = dadosLogin.empresas.find(x => x.cnpj === getCnpj());
    setSelected(empresaAtual);
  }, []);

  const selectHandle = empresa => {
    props.selectEmpresaHandle(empresa);
    onHiddenHandle();
  };

  const content = () => {
    return (
      <div className={styles.SelecionaEmpresa}>
        <div className={styles.ContainerEmpresas}>
          {empresas.map(x => (
            <EmpresaCard
              key={x.cnpj}
              cnpj={x.cnpj}
              nome={x.nomeFantasia}
              selected={x.cnpj === selected.cnpj}
              select={() => selectHandle(x)}
            />
          ))}
        </div>

        <button className="btn btn-light" onClick={onHiddenHandle}>
          CANCELAR
        </button>
      </div>
    );
  };

  const onHiddenHandle = () => {
    if (props.handleCloseModal) {
      props.handleCloseModal();
    }
  };

  return (
    props.active && (
      <Popup
        visible={props.active}
        onHidden={onHiddenHandle}
        dragEnabled={false}
        closeOnOutsideClick={false}
        showTitle={true}
        title="Seleção de empresa"
        maxWidth={500}
        maxHeight={400}
        contentRender={content}
      />
    )
  );
};

export default SelecionaEmpresa;
