import axios from 'axios';
import {
  getToken
} from '../../../../authentication/Auth';

import {
  BASE_URL_GATEWAY
} from '../../../../shared/constants/GatewayUrlConstant';

export const estruturaCartoesTreeViewService = {
  getEstruturaCartoes
};

const urlLinxCore = axios.create({
 baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function getEstruturaCartoes() {
  const response = await urlLinxCore.get('/EstruturaCartoes/ListarSimples');
  return response.data.data;
}

