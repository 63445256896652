import axios from 'axios';
import {
  getToken
} from '../../../authentication/Auth';
import {
  TOKEN_PASS_AXIOS
} from '../../../shared/constants/Const';
import {
  getUrl,
  getCnpj
} from '../../../shared/utils/Utils';
import {
  BASE_URL_GATEWAY
} from '../../../shared/constants/GatewayUrlConstant';
import {
  TIPO_SITE
} from '../../../shared/constants/TipoSiteConstant';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


export function incluiFuncionario(
  cpf,
  nome,
  apelido,
  dataNascimento,
  identidade,
  orgaoEmissor,
  dataAdmissao,
  identfID,
  email,
  codigoPerfilAcesso,
  valorMinimoGeracaoVale,
  observacao,
  endereco,
  contatos
) {
  return axios.post(`LxApi/v1/Funcionario/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    cpf,
    nome,
    apelido,
    dataNascimento,
    identidade,
    orgaoEmissor,
    dataAdmissao,
    identfID,
    email,
    codigoPerfilAcesso,
    valorMinimoGeracaoVale,
    observacao,
    endereco,
    contatos,
    tipoSite: TIPO_SITE
  });
}

export function alteraFuncionario(
  codigoPessoa,
  cpf,
  nome,
  apelido,
  dataNascimento,
  identidade,
  orgaoEmissor,
  dataAdmissao,
  dataCadastro,
  identfID,
  codigoUsuario,
  email,
  codigoPerfilAcesso,
  valorMinimoGeracaoVale,
  observacao,
  inativo,
  rowVersion,
  endereco,
  contatos
) {
  return axios.put(`LxApi/v1/Funcionario/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    tipoSite: TIPO_SITE,
    codigoPessoa,
    cpf,
    nome,
    apelido,
    dataNascimento,
    identidade,
    orgaoEmissor,
    dataAdmissao,
    dataCadastro,
    identfID,
    codigoUsuario,
    email,
    codigoPerfilAcesso,
    valorMinimoGeracaoVale,
    observacao,
    inativo,
    rowVersion,
    endereco,
    contatos
  });
}

export function excluiFuncionario(codigoPessoa) {
  const response = urlLinxCore.delete(`/Funcionario?id=${codigoPessoa}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

export function getFuncionarios(consulta) {
  consulta.cnpjEstabelecimento = getCnpj();
  consulta.cadastroCompleto = true;

  return axios.post(`LxApi/v1/Funcionario/Listar/`, {
    query: consulta
  });
}

export function verifyFuncionario(cpf) {
  return axios.get(`LxApi/v1/Funcionario/Verificar/`, {
    params: {
      cpf,
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function montarComboFuncionarios() {
  return axios.get(`LxApi/v1/Pessoa/MontarCombo`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      classificacaoPessoa: 3
    }
  });
}
