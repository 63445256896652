import React, { useEffect, useRef, useState } from 'react';
import notify from 'devextreme/ui/notify';
import DataGrid, { Scrolling, Selection, Grouping, SearchPanel, Paging, Pager, Column } from 'devextreme-react/data-grid';
import { Button, CheckBox, LoadPanel } from 'devextreme-react';
import { setupFacilService } from '../../SetupFacil.service.js';

import styles from './SetupFacilSelecaoProdutos.module.scss';

export default function SetupFacilSelecaoProdutos({ ...props }) {
  const [autoExpandAll, setAutoExpandAll] = useState(true);
  const [allowSelectAll, setAllowSelectAll] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [produtos, setProdutos] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const dataGridRef = useRef();

  useEffect(() => {
    props.handleNextStep(() => () => getSelecionados());
    props.awaitIsCompletedStepUpdate(true);
    props.messageStepNotCompletedUpdate('Escolha pelo menos um produto para continuar.');

    if (props.numberPreviousStep === 3) {
      if (props.dados.fonteDados === 1) {
        const grupos = getGrupos(props.dados.selecaoProdutos.produtos);
        setGrupos(grupos);
        setProdutos(props.dados.selecaoProdutos.produtos);
      } else if (props.dados.fonteDados === 2) {
        props.previousStep();
      }
      setIsLoading(false);
      return;
    }

    if (props.dados.fonteDados === 1) {
      getDados();
    } else if (props.dados.fonteDados === 2) {
      props.dadosUpdate(prevDados => {
        return {
          ...prevDados,
          selecaoProdutos: {
            produtosSelecionados: props.dados.selecaoDados,
            produtos: props.dados.selecaoDados,
          },
        };
      });
      props.isCompletedStepUpdate(true);
      setIsLoading(false);
    } else if (props.dados.fonteDados === 3) {
      props.dadosUpdate(prevDados => {
        return {
          ...prevDados,
          selecaoProdutos: {
            produtosSelecionados: props.dados.selecaoDados,
            produtos: props.dados.selecaoDados,
          },
        };
      });
      props.isCompletedStepUpdate(true);
      setIsLoading(false);
    }

    async function getDados() {
      setIsLoading(true);
      const resp = await setupFacilService.GetSelecaoProdutos(props.dados.selecaoDados);

      if (resp.notifications !== null) {
        const dados = resp.result;
        const grupos = getGrupos(dados);
        console.log('Retorno Seleção Produtos');
        console.log(dados);
        setGrupos(grupos);
        setProdutos(dados);
      } else {
        notify(resp.message, 'error', 2000, 'top');
      }

      setIsLoading(false);
    }

    async function getSelecionados() {
      const produtosSelecionados = dataGridRef.current.instance.getSelectedRowsData();
      if (produtosSelecionados.length > 0) {
        if (produtosSelecionados.length > 2000) {
          notify('Devem ser selecionados até 2000 produtos', 'warning', 2000, 'top');
        } else {
          props.dadosUpdate(prevDados => {
            return {
              ...prevDados,
              selecaoProdutos: {
                produtosSelecionados: produtosSelecionados,
                produtos: dataGridRef.current.props.dataSource,
              },
            };
          });
          props.isCompletedStepUpdate(true);
        }
      } else {
        props.showAlertUpdate();
      }
    }

  }, [props]);

  useEffect(() => {
    if (selectedItems && selectedItems.length > 0 && typeof dataGridRef.current !== 'undefined') {
      dataGridRef.current.instance.selectRows(selectedItems, false);
    } else if (props.dados && props.dados.selecaoProdutos && props.dados.selecaoProdutos.produtosSelecionados) {
      dataGridRef.current.instance.selectRows(props.dados.selecaoProdutos.produtosSelecionados, false);
      setSelectedItems(props.dados.selecaoProdutos.produtosSelecionados);
    }
  }, [props.dados, selectedItems]);

  useEffect(() => {
    if (typeof grupos !== 'undefined' && grupos.length > 0) {
      setIsLoading(false);
    }
  }, [grupos]);

  function getGrupos(dados) {
    const gruposAll = dados.map(item => item.estruturaMercadologicaDescricao);
    const gruposUnion = Array.from(new Set(gruposAll));
    const gruposObject = gruposUnion.map(grupo => ({ grupo: grupo }));

    var grupos = [];
    gruposObject.forEach(grupo => {
      const res = dados.filter(item => item.estruturaMercadologicaDescricao === grupo.grupo);
      grupos = grupos.concat({
        ...grupo,
        itensCount: res.length,
        isChecked: false,
      });
    });
    return grupos;
  }

  const handleGroupCheckChange = e => {
    var grupo = grupos.filter(grupo => grupo.grupo === e.element.id);
    grupo[0].isChecked = e.value;

    if (e.value) {
      var produtosSelecionados = produtos.filter(produto => produto.estruturaMercadologicaDescricao === e.element.id);

      if (selectedItems.length > 0) {
        var selectedItemsPrev = [...selectedItems];
        var selectedItemsConcat = selectedItemsPrev.concat(produtosSelecionados);
        setSelectedItems(selectedItemsConcat);
      } else {
        setSelectedItems(produtosSelecionados);
      }
    } else {
      setSelectedItems(selectedItems.filter(item => item.estruturaMercadologicaDescricao !== e.element.id));
    }
  };

  const handleOnSelectionChanged = data => {

    var selectedItemsPrev = [];
    var selectedItemsConcat = [];
    var dadosGrupo = [];
    var itensSelecionadosGrupo = [];

    // Validação do checkbox para selecionar todos itens
    if (data.currentSelectedRowKeys.length > 0 && produtos.length === data.currentSelectedRowKeys.length + selectedItems.length) {
      grupos.map(grupo => (grupo.isChecked = true));

      if (selectedItems.length > 0) {
        selectedItemsPrev = [...selectedItems];
        selectedItemsConcat = selectedItemsPrev.concat(data.currentSelectedRowKeys);
        setSelectedItems(selectedItemsConcat);
      } else {
        setSelectedItems(data.currentSelectedRowKeys);
      }

    } else if (produtos.length === data.currentDeselectedRowKeys.length) {
      grupos.map(grupo => (grupo.isChecked = false));
      setSelectedItems([]);
    }

    // Item selecionado
    if (data.currentSelectedRowKeys.length === 1) {
      dadosGrupo = grupos.filter(grupo => grupo.grupo === data.currentSelectedRowKeys[0].estruturaMercadologicaDescricao);
      selectedItemsPrev = [...selectedItems];
      selectedItemsConcat = selectedItemsPrev.concat(data.currentSelectedRowKeys[0]);
      // Validação se é o último do grupo a ser selecionado
      if (dadosGrupo[0].itensCount === 1) {
        if (!dadosGrupo[0].isChecked) {
          dadosGrupo[0].isChecked = true;
          setSelectedItems(selectedItemsConcat);
        }
      } else {
        itensSelecionadosGrupo = selectedItems.filter(
          item => item.estruturaMercadologicaDescricao === data.currentSelectedRowKeys[0].estruturaMercadologicaDescricao,
        );

        if (dadosGrupo[0].itensCount === itensSelecionadosGrupo.length + 1 && !dadosGrupo[0].isChecked) {
          dadosGrupo[0].isChecked = true;
        }
        setSelectedItems(selectedItemsConcat);
      }
    } else if (data.currentDeselectedRowKeys.length === 1) {
      dadosGrupo = grupos.filter(grupo => grupo.grupo === data.currentDeselectedRowKeys[0].estruturaMercadologicaDescricao);
      // Se o grupo tiver apenas 1 item e isChecked == true, muda o checked pra false
      if (dadosGrupo[0].itensCount === 1 && dadosGrupo[0].isChecked) {
        dadosGrupo[0].isChecked = false;
      } else {
        // Se tiver apenas 1 item no grupo selecionado, então ele deve ser o ultimo,
        // por isso deve mudar o checked pra false
        itensSelecionadosGrupo = selectedItems.filter(
          item => item.estruturaMercadologicaDescricao === data.currentDeselectedRowKeys[0].estruturaMercadologicaDescricao,
        );
        if (itensSelecionadosGrupo.length === 1 && dadosGrupo[0].isChecked) {
          dadosGrupo[0].isChecked = false;
        }
      }
      // Remove item da lista de selecionados
      setSelectedItems(selectedItems.filter(item => item !== data.currentDeselectedRowKeys[0]));
    }
  };

  const groupCellTemplate = data => {
    var grupo = grupos.filter(grupo => grupo.grupo === data.value);

    return (
      <>
        {isLoading ? (
          <div>{data.value}</div>
        ) : (
          <CheckBox text={data.value} id={data.value} {...(grupo[0].isChecked ? { value: true } : { value: false })} onValueChanged={handleGroupCheckChange} />
        )}
      </>
    );
  };

  const headerCellTemplate = data => {
    return <div className={styles.gridColumnHeader}>{data.column.caption}</div>;
  };

  const handleOnOptionChanged = e => {
    if (e.fullName === 'searchPanel.text') {
      if (allowSelectAll && e.value) {
        setAllowSelectAll(false);
      } else {
        setAllowSelectAll(true);
      }
    }
  };

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={isLoading}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div className={styles.container}>
        <h1>Selecione os produtos que deseja importar</h1>

        <div className={styles.dataGridResult}>
          <div className={styles.gridOptions}>
            <Button
              hint="Expandir/Recolher agrupamentos"
              disabled={false}
              className={styles.expandButton}
              icon={autoExpandAll ? 'collapse' : 'expand'}
              onClick={() => setAutoExpandAll(!autoExpandAll)}
            />
          </div>

          <DataGrid
            dataSource={produtos}
            ref={dataGridRef}
            hoverStateEnabled={true}
            showBorders={true}
            rowAlternationEnabled={true}
            onSelectionChanged={handleOnSelectionChanged}
            onOptionChanged={handleOnOptionChanged}
          >
            <Column dataField="codigo" caption="Código" alignment="left" headerCellRender={headerCellTemplate} />
            <Column dataField="descricao" caption="Produto" alignment="left" headerCellRender={headerCellTemplate} />
            <Column dataField="setor" caption="Setor" alignment="left" headerCellRender={headerCellTemplate} />
            <Column dataField="estruturaMercadologicaDescricao" caption="Grupo" groupIndex={0} groupCellRender={groupCellTemplate} />
            <Grouping autoExpandAll={autoExpandAll} />
            <SearchPanel visible={true} highlightCaseSensitive={true} width={194} placeholder={'Pesquisar no resultado...'} />
            <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
            <Paging defaultPageSize={15} />
            <Scrolling showScrollbar={'always'} />
            <Selection mode="multiple" showCheckBoxesMode="always" allowSelectAll={allowSelectAll} />
          </DataGrid>
        </div>
      </div>
    </>
  );
}
