import axios from 'axios';
import { getUrl, getCnpj } from '../../../shared/utils/Utils';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function incluirMovimentacaoEstoque(operacaoEstoque, movimentos) {
  return axios.post(`LxApi/v1/Estoque/Movimentacao/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    operacaoEstoque,
    movimentos
  });
}

export function montarComboOperacaoEstoque() {
  return axios.get(`LxApi/v1/Estoque/Movimentacao/OperacaoEstoque`, {});
}

export function montarComboJustificativa(operacaoEstoque) {
  return axios.get(`LxApi/v1/Estoque/Movimentacao/Justificativa`, {
    params: { operacaoEstoque }
  });
}
