import 'devextreme/dist/css/dx.light.css';

import { Popup, ToolbarItem } from 'devextreme-react/popup';
import React, { useEffect, useState } from 'react';
import { getCnpj, getSystemInfoByTipoSiteLogged, isObjectEmpty } from '../../../shared/utils/Utils';

import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';
import Header from '../header/Header';
import { Helmet } from 'react-helmet';
import HorizontalMenu from '../horizontalMenu/HorizontalMenu';
import Main from '../main/Main';
import Sidenav from '../sidenav/Sidenav';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import axios from 'axios';
import { getToken } from '../../../authentication/Auth';
import { lais } from '../../../services/Lais.service';
import notify from 'devextreme/ui/notify';
import styles from './LinxPostos.module.scss';

//import Lais from '../../Lais/Lais';

//o TIMER é definido em milissegundos
const TIMER_INTERVAL_MENSAGEM_BUSCAR_INICIAL = 3000;
const TIMER_INTERVAL_MENSAGEM_BUSCAR_PROXIMA = 1200000;
const TIMER_INTERVAL_MENSAGEM_BUSCAR_ERROR = 9990000;

export default function LinxPostos(props) {
  const [showMobileSidenav, setshowMobileSidenav] = useState(false);
  const [showLais, setshowLais] = useState(false);
  const [user, setUser] = useState('');
  const [CNPJ, setCNPJ] = useState('');
  const [name, setName] = useState('');
  const systemInfo = getSystemInfoByTipoSiteLogged();

  const [popup_visible, setPopup_visible] = useState(false);
  const [popupBuscando, setPopupBuscando] = useState(false);
  const [popupInterval, setPopupInterval] = useState(TIMER_INTERVAL_MENSAGEM_BUSCAR_INICIAL);
  const [mensagemSistema, setMensagemSistema] = useState({});

  const toggleMobileSidenav = () => {
    setshowMobileSidenav(!showMobileSidenav);
  };

  const toggleLaisHandler = () => {
    console.log('toggleLaisHandler - showLais = ' + showLais);
    setshowLais(!showLais);
  };

  const [favoriteCount, setFavoriteCount] = useState(0);
  const [existeMenuFavoritos, setExisteMenuFavoritos] = useState(false);

  const buttonOptions = {
    text: 'Ler mais tarde...',
    onClick: function () {
      setPopup_visible(false);

      //Sinaliza para a tela filha saber que deixou de mostrar uma mensagem modal
      //console.log(props.onHidePopUp);
      if (props.onHidePopUp) props.onHidePopUp();
    }
  };

  const popup_content = () => {
    return (
      <div dangerouslySetInnerHTML={{ __html: mensagemSistema.descricao }} />
    );
  };

  const urlLinxCore = axios.create({
    baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
  });

  urlLinxCore.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const mensagemSistemaBuscar = async () => {
    //console.log("mensagemSistemaBuscar inicio");

    setPopupBuscando(true);
    const response = await urlLinxCore
      .get('/MensagemSistema', {
        params: {
          tipo: 7,
        },
      })
      .then(response => {
        //console.log("mensagemSistemaBuscar - then - response...");
        //console.log(response);

        return response.data;
      })
      .catch(error => {
        console.log('mensagemSistemaBuscar - error = ' + error);
        return { success: false, message: error.message };
      });

    //console.log("mensagemSistemaBuscar depois await");
    //console.log("mensagemSistemaBuscar - response...");
    //console.log(response);

    setPopupBuscando(false);

    try {
      //console.log("response.success = " + response.success);
      if (response.success) {
        //console.log("response.success");
        const consultaMensagemSistema = response.data;

        //Agenda próxima busca
        setPopupInterval(TIMER_INTERVAL_MENSAGEM_BUSCAR_PROXIMA);
        return consultaMensagemSistema;
      } else {
        notify('Não foi possível realizar a consulta das mensagens do sistema!', 'error', 5000);
      }
    } catch (err) {
      notify('Erro ao tratar o retorno das mensagens do sistema!', 'error', 5000);
    }

    //Se deu erro agenda próxima busca para bem mais tarde
    setPopupInterval(TIMER_INTERVAL_MENSAGEM_BUSCAR_ERROR);
    return null;
  };

  const mensagemSistemaMarcarComoVisualizadoOperador = async id => {
    console.log('mensagemSistemaMarcarComoVisualizadoOperador inicio - id = ' + id);

    const response = await urlLinxCore
      .put('/MensagemSistema/' + id)
      .then(response => {
        //console.log("mensagemSistemaMarcarComoVisualizadoOperador - then - response...");
        //console.log(response);
        return response.data;
      })
      .catch(error => {
        console.log('mensagemSistemaMarcarComoVisualizadoOperador - error = ' + error);
        return { success: false, message: error.message };
      });

    //console.log("mensagemSistemaMarcarComoVisualizadoOperador depois await");
    //console.log("mensagemSistemaMarcarComoVisualizadoOperador - response...");
    //console.log(response);

    try {
      //console.log("response.success = " + response.success);
      if (!response.success) {
        //Se deu erro agenda próxima busca para bem mais tarde
        setPopupInterval(TIMER_INTERVAL_MENSAGEM_BUSCAR_ERROR);
        notify('Não foi possível marcar a mensagem como visualizada!', 'error', 5000);
      }
    } catch (err) {
      //Se deu erro agenda próxima busca para bem mais tarde
      setPopupInterval(TIMER_INTERVAL_MENSAGEM_BUSCAR_ERROR);
      notify('Erro ao tratar o retorno das mensagens do sistema!', 'error', 5000);
    }
    return null;
  };

  useEffect(() => {
    //console.log("************* useEffect sessionStorage start *********************** ");

    let email = sessionStorage
      .getItem('user')
      .replace('"', '')
      .replace('"', '');

    let name = email.split('@')[0].replace('"', '');

    let cnpj = getCnpj();

    setUser(email);
    setCNPJ(cnpj);
    setName(name);

    //console.log("useEffect sessionStorage end");
  }, []);

  useEffect(() => {
    //console.log("useEffect showLais start - showLais = " + showLais);

    //console.log("user = " + user);
    //console.log("CNPJ = " + CNPJ);
    //console.log("name = " + name);

    if (showLais) {
      lais.Iniciar({ user: user, cnpj: CNPJ, name: name });
    } else {
      lais.Finalizar();
    }
  }, [showLais, user, CNPJ, name]);

  useEffect(() => {
    //Ignorar POP-UP se não estiver na tela de dashboard
    if (!props.dashboard) return;

    var timeoutHandle = null;
    console.log('popupInterval = ' + popupInterval);

    timeoutHandle = setInterval(async () => {
      //console.log("setInterval  timer Menu");
      // console.log("popup_visible = " + popup_visible);
      // console.log("popupBuscando = " + popupBuscando);

      //Se ainda não tem uma mensagem vísivel
      if (!popup_visible) {
        //console.log("setInterval  mensagemSistemaBuscar  antes");

        //Se ainda estiver buscando
        if (popupBuscando) return;

        var msg = await mensagemSistemaBuscar();

        //console.log("setInterval  mensagemSistemaBuscar  depois");
        //console.log(msg);

        if (msg != null && !isObjectEmpty(msg) && msg.descricao != null) {
          //console.log("setMensagemSistema");
          setMensagemSistema(msg);
          setPopup_visible(true);

          //Sinaliza para a tela filha saber que está mostrando uma mensagem modal
          //console.log(props.onShowPopUp);
          if (props.onShowPopUp) props.onShowPopUp();
        }
      }
    }, popupInterval); //900000
    //console.log("useEffect - new Timeout (timeoutHandle = " + timeoutHandle + ")");

    // Especifica como limpar depois desse efeito:
    return function cleanup() {
      console.log('useEffect cleanup - clearTimeout (timeoutHandle = ' + timeoutHandle + ')');
      clearTimeout(timeoutHandle);
    };
  }, [popup_visible, popupBuscando, popupInterval]);

  const onHiding = async () => {
    //console.log("-------------------------------------------------------------");
    //console.log("onHiding Id = " + mensagemSistema.id);
    setPopup_visible(false);
    //Marca a mensagem como visualizada no banco de dados
    await mensagemSistemaMarcarComoVisualizadoOperador(mensagemSistema.id);

    //Sinaliza para a tela filha saber que deixou de mostrar uma mensagem modal
    //console.log(props.onHidePopUp);
    if (props.onHidePopUp) props.onHidePopUp();

    //Se configurado para forçar o RELOAD (NoCache) do navegador (caso seja suportado)
    //console.log(props.onHidePopUp);
    // console.log("forceReloadNoCache = " + mensagemSistema.forceReloadNoCache);
    if (mensagemSistema.forceReloadNoCache) window.location.reload(true);
    //console.log("-------------------------------------------------------------");
  };

  const Area_Popup = () => {
    return (
      <Popup
        visible={popup_visible}
        onHiding={onHiding}
        dragEnabled={true}
        closeOnOutsideClick={false}
        showCloseButton={true}
        showTitle={mensagemSistema.titulo.length > 0}
        title={mensagemSistema.titulo}
        minWidth={mensagemSistema.areaLargura ? mensagemSistema.areaLargura + 40 : 800}
        minHeight={mensagemSistema.areaAltura ? mensagemSistema.areaAltura + 60 : 600}
        maxWidth="95vw"
        maxHeight="95vh"
        width={mensagemSistema.areaLargura ? mensagemSistema.areaLargura + 40 : 800}
        height={mensagemSistema.areaAltura ? mensagemSistema.areaAltura + 60 : 600}
        position="center"
        contentRender={popup_content}
        resizeEnabled={true}
      >
        <ToolbarItem
          options={buttonOptions}
          widget="dxButton"
          location="after"
        />
      </Popup>
    );
  };

  return (
    <div className={styles.linxPostos}>
      <Helmet>
        <title>{systemInfo.nomeLower}</title>
        <meta name="description" content={systemInfo.message} />
      </Helmet>

      <Header
        breadcrumb={props.breadcrumb}
        toggleMobileSidenav={toggleMobileSidenav}
        toggleLais={toggleLaisHandler}
        toggleFavorito={toggleLaisHandler}
        setFavoriteCount={setFavoriteCount}
        favoriteCount={favoriteCount}
        existeMenuFavoritos={existeMenuFavoritos}
      />
      {TIPO_SITE === 'RetaguardaSellerWeb' ? (
        <HorizontalMenu
          favoriteCount={favoriteCount}
          setExisteMenuFavoritos={setExisteMenuFavoritos} />
      ) : (
        <Sidenav
          toggleMobileSidenav={toggleMobileSidenav}
          showMobileSidenav={showMobileSidenav}
          favoriteCount={favoriteCount}
          setExisteMenuFavoritos={setExisteMenuFavoritos}
        />
      )}
      <Main className={props.className} noScroll={props.noScroll}>
        {props.children}
      </Main>

      {popup_visible ? <Area_Popup /> : <></>}
    </div>
  );
}
