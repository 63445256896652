export function passarStringArraySelecionadosParaTexto(str, itens) {
  if (!str) return '';
  const arr = str.split('_');
  if (!arr.length) return '';
  let ret = '';
  for (const it of arr) {
    const o = (itens || []).find(item => parseInt(item.id) === parseInt(it, 10));
    if (o === null || o === undefined) return '';
    ret += `${ret.length ? ', ' : ''}${(o.nome || o.descricao || o).toUpperCase()}`;
  }
  return `${ret}`;
}
