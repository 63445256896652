import React, { useEffect, useState } from 'react';
import moment from 'moment';
import notify from 'devextreme/ui/notify';
import { LoadPanel, TabPanel } from 'devextreme-react';
import { locale } from 'devextreme/localization';
import config from 'devextreme/core/config';
import { Button } from 'react-bootstrap';
import { NotificationLevel } from '../../../../shared/constants/Const';
import _ from 'lodash';

import RaioXDashboardFilter from './RaioXDashboardFilter';
import RaioXDashboardTotalCard from './RaioXDashboardTotalCard';
import RaioXDashboardGrid from './RaioXDashboardGrid';
import RaioXDashboardChart from './RaioXDashboardChart';

import { raioxService } from '../RaioX.service';

import styles from './RaioXDashboard.module.scss';

function DashboardTitle() {
  return (
    <div className={styles.headerTitle}>
      <h1>Dashboard Raio-X | Minhas vendas</h1>
      <div className={styles.headerSubtitle}>
        <span>
          Confira os detalhes sobre suas vendas com a integração ao Raio-x. Para
          mais detalhes,
        </span>
        <a href="https://portal.raiox.app/" target="_blank">
          clique aqui para acessar o portal do Raio-X.
        </a>
      </div>
    </div>
  );
}

export default function RaioXDashboard() {
  const dashboardRelatorios = [
    {
      index: 0,
      agrupamento: 1,
      nome: 'Maquininha',
      chartTitulo: 'Vendas por maquininha'
    },
    {
      index: 1,
      agrupamento: 2,
      nome: 'Bandeira',
      chartTitulo: 'Vendas por bandeira'
    }
  ];

  const [dashboardDados, setDashboardDados] = useState([]);

  const [tabPanelIndex, setTabPanelIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    console.log('useEffect ---- CHEGOU AtÉ AQUI');
    locale(navigator.language);

    // Validação para executar função somente se a tela não foi carregada ainda
    if (!isLoaded && !isSearching) {
      const filters = {
        dataInicio: moment(new Date().toISOString())
          .subtract(6, 'days')
          .format('YYYY-MM-DD'),
        dataFim: moment(new Date().toISOString()).format('YYYY-MM-DD')
      };

      setIsLoading(true);
      search(filters);
    }
  }, []);

  useEffect(() => {
    if (dashboardRelatorios.length === dashboardDados.length) {
      if (dashboardRelatorios[0].index !== dashboardDados[0].index) {
        setDashboardDados(_.sortBy(dashboardDados, 'index'));
      }
      setIsLoading(false);
      setIsSearching(false);
      setIsLoaded(true);
    }
  }, [dashboardDados]);

  function search(filters) {
    // Se a tela já estiver carregada, significa que o usuário executou essa função pelos filtros
    if (isLoaded) {
      setIsSearching(true);
    }
    setDashboardDados([]);
    dashboardRelatorios.forEach(item => {
      getDados(filters, item);
    });
  }

  async function getDados(filters, dashboardItem) {
    // var dados = dashboardItem.agrupamento === 1 ? data.default : dataBandeira.default;
    if (
      moment(moment(filters.dataFim).format('YYYY-MM-DDT23:59:59')).isBefore(
        filters.dataInicio
      )
    ) {
      notify(
        'Período inválido! A data de início deve ser menor que a data de fim.',
        'warning',
        2000,
        'top'
      );
      return;
    }

    const appliedFilters = {
      dataInicio: filters.dataInicio,
      dataFim: filters.dataFim,
      agrupamento: dashboardItem.agrupamento
    };

    var dadosItem = {};

    dadosItem.index = dashboardItem.index;
    dadosItem.agrupamento = dashboardItem.agrupamento;
    dadosItem.nome = dashboardItem.nome;
    dadosItem.chartTitulo = dashboardItem.chartTitulo;

    const result = await raioxService.GetDadosDashboard(appliedFilters);

    if (result.notificationLevel === NotificationLevel.Success) {
      if (result.data.length > 0) {
        dadosItem.valorTotal = raioxService.convertResponseToValorTotal(
          result.data
        );
        dadosItem.quantidadeTotal = raioxService.convertResponseToQuantidadeTotal(
          result.data
        );
        dadosItem.gridDados = raioxService.convertResponseToGridData(
          result.data
        );

        const [
          chartDadosCoresRef,
          chartDados
        ] = raioxService.convertResponseToChartData(result.data);
        dadosItem.chartDados = chartDados;
        dadosItem.chartDadosCoresRef = chartDadosCoresRef;
      } else {
        notify(
          'Nenhum registro encontrado no período informado.',
          'info',
          2000,
          'top'
        );

        dadosItem.chartDados = {};
        dadosItem.chartDadosCoresRef = {};
        dadosItem.gridDados = {};
        dadosItem.valorTotal = 0;
        dadosItem.quantidadeTotal = 0;
      }
    } else {
      notify(result.message, 'error', 2000, 'top');

      dadosItem.chartDados = {};
      dadosItem.chartDadosCoresRef = {};
      dadosItem.gridDados = {};
      dadosItem.valorTotal = 0;
      dadosItem.quantidadeTotal = 0;
    }

    setDashboardDados(prevDados => {
      return [...prevDados, dadosItem];
    });
  }

  function panelDataSource() {
    return dashboardRelatorios.map(item => ({
      index: item.index,
      title: item.nome
    }));
  }

  function panelTitleRender(e) {
    return <h1 className={styles.panelTitle}>{e.title.toUpperCase()}</h1>;
  }

  function getValueUndefined(item, campo, itemTipo) {
    if (typeof item[tabPanelIndex] === 'undefined') {
      if (itemTipo === 'object') {
        return {};
      } else if (itemTipo === 'number') {
        return 0;
      } else {
        return '';
      }
    } else {
      return item[tabPanelIndex][campo];
    }
  }

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={isLoading}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div className={styles.dashboardContainer}>
        {/* <MensagemCadastroIncompleto /> */}
        <div className={styles.bodyContainerLeft}>
          <DashboardTitle />
          <br />
          <div className={`shadow-sm p-1 bg-white ${styles.chartContainer}`}>
            <div className={`container-fluid ${styles.chartContent}`}>
              <TabPanel
                dataSource={panelDataSource()}
                width="100%"
                height="100%"
                animationEnabled={true}
                itemTitleRender={x => panelTitleRender(x)}
                onTitleClick={x => setTabPanelIndex(x.itemIndex)}
                itemRender={x => {
                  return (
                    <RaioXDashboardChart
                      title={getValueUndefined(
                        dashboardDados,
                        'chartTitulo',
                        'string'
                      )}
                      dados={getValueUndefined(
                        dashboardDados,
                        'chartDados',
                        'object'
                      )}
                      coresRef={getValueUndefined(
                        dashboardDados,
                        'chartDadosCoresRef',
                        'object'
                      )}
                      isLoading={isLoading || isSearching}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.bodyContainerRight}>
          <RaioXDashboardFilter onSearch={search} />
          <br />
          <RaioXDashboardTotalCard
            title="Total de vendas"
            value={getValueUndefined(dashboardDados, 'valorTotal', 'number')}
            isLoading={isLoading || isSearching}
          />
          <RaioXDashboardTotalCard
            title="Transações"
            value={getValueUndefined(
              dashboardDados,
              'quantidadeTotal',
              'number'
            )}
            isLoading={isLoading || isSearching}
          />
          <RaioXDashboardGrid
            title={getValueUndefined(dashboardDados, 'nome', 'object')}
            dados={getValueUndefined(dashboardDados, 'gridDados', 'string')}
            isLoading={isLoading || isSearching}
          />
        </div>
      </div>
    </>
  );
}
