import { SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { ProdutoService } from '../../../../../services/produtos/Produto.service';

export const SelectProduto = forwardRef(({ tipoProduto = 1 }, ref) => {
  const [produto, setProduto] = useState(null);
  const select = useRef();

  const store = new DataSource({
    key: 'id',
    byKey: key => {
      return [produto];
    },
    load: async function(arg) {
      if (arg.searchOperation !== 'contains') {
        return [];
      }
      const produtos = await ProdutoService.getInstance().buscarProduto(arg.searchValue, tipoProduto);
      return produtos;
    },
  });

  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem) {
        return;
      }
      const data = { ...e.selectedItem };
      if (produto && data.codigo === produto.codigo) {
        return;
      }
      setProduto(data);
    },
    [produto],
  );

  useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        select.current.instance.focus();
        select.current.instance.reset();
      },
      limpar: () => {
        setProduto(null);
      },
      selectedItem() {
        return produto;
      },
    }),
    [select, produto],
  );

  return (
    <div className="form-group text-left">
      <label htmlFor="buscarProduto" className="text-primary">
        Produto
      </label>
      <SelectBox
        name="buscarProduto"
        ref={select}
        dropDownButtonRender={() => <i className="dx-icon-chevrondown"></i>}
        searchEnabled={true}
        hint="Digite a descrição, EAN ou o codigo rápido do produto"
        placeholder="Digite a descrição, EAN ou o codigo rápido do produto"
        displayExpr="descricao"
        valueExpr="id"
        dataSource={store}
        selectedItem={produto}
        searchMode="contains"
        onSelectionChanged={onSelectionChanged}
        searchTimeoutOption={500}
      ></SelectBox>
    </ div>
  );
});
