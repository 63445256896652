import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import { maskedCnpjCpf } from '../../../../shared/utils/Utils';

export const vendasCombustivelPremmiaService = {
  find,
  GetColumns,
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {
  const response = await urlLinxCore
    .post('/Vendas/VendasCombustivelPremmia', { query: appliedFilters })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

const formatDocument = data => {
  return maskedCnpjCpf(data.value);
};

function GetColumns() {
  let colunas = [
    {
      dataField: 'cnpj',
      caption: 'CNPJ da empresa',
      width: '130',
      customizeText: formatDocument,
    },
    {
      dataField: 'dataVenda',
      caption: 'Data da Venda',
      dataType: 'date',
      width: '90',
    },
    {
      dataField: 'horaEmissao',
      caption: 'Hora',
      width: '80'
    },
    {
      dataField: 'dataInicioTurno',
      caption: 'Início Turno',
      dataType: 'date',
      width: '90',
    },
    {
      dataField: 'codigoAbastecimento',
      caption: 'Código de Abastecimento',
      width: '120',
      alignment: 'left',
    },
    {
      dataField: 'nomeCombustivel',
      caption: 'Nome do Combustível',
      width: '200',
    },
    {
      dataField: 'idPremmia',
      caption: 'Código Premmia',
      width: '100',
    },
    {
      dataField: 'descricaoPagamentoPremmia',
      caption: 'Descrição Forma de Pagamento',
      width: '200',
    },
    {
      dataField: 'valorPagamentoPremmia',
      caption: 'Valor do Pagamento Premmia',
      width: '200',
      format: { type: 'currency', precision: 2 },
    },
    {
      dataField: 'precoUnitario',
      caption: 'Valor da Venda',
      width: '100',
      format: { type: 'currency', precision: 2 },
    },
    {
      dataField: 'desconto',
      caption: 'Valor Desconto',
      width: '100',
      format: { type: 'currency', precision: 2 },
    },
  ];

  return colunas;
}

export default urlLinxCore;
