export const passarStatusSelecionadosParaTexto = statusArr => {
  let ret = '';
  for (const it of statusArr) {
    switch (it) {
      default:
      case 1:
        ret += `${ret.length ? ', ' : ''}Em Aberto`;
        break;
      case 2:
        ret += `${ret.length ? ', ' : ''}Enviado`;
        break;
      case 3:
        ret += `${ret.length ? ', ' : ''}Cancelado`;
        break;
      case 3:
        ret += `${ret.length ? ', ' : ''}Fechado`;
        break;
    }
  }
  return `${ret}`;
};
