import React from 'react';

import { locale } from 'devextreme/localization';
import moment from 'moment';
import { PrintStyle } from './PrintStyles';

export function ReciboExtra() {
  moment.locale('pt-br');
  locale('pt-br');
  const sty = new PrintStyle();
  return (
    <>
      <h5>ITENS EXTRAS</h5>
      <table className="w-100" style={sty.borderSolid}>
        <thead>
          <tr style={sty.borderSolid}>
            <th width="50%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Código - Descricao do Produto
            </th>
            <th width="10%" className="text-center pl-1 pr-1" style={sty.tableTh}></th>
            <th width="20%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Quantidade
            </th>
            <th width="20%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Retorno
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
            <td className="text-right" style={sty.borderSolid}></td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
