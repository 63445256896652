import { DataGrid } from 'devextreme-react';
import { Column, Grouping, GroupItem, Summary, TotalItem } from 'devextreme-react/data-grid';
import Popup from 'devextreme-react/popup';
import React, { useCallback, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

export function ModalPagamentoAnalitico({ currentItem, turno }) {
  const [popupVisible, setPopupVisible] = useState(false);

  const show = useCallback(
    e => {
      if (popupVisible) return;
      setPopupVisible(true);
    },
    [popupVisible],
  );
  const hide = useCallback(
    e => {
      if (!popupVisible) return;
      setPopupVisible(false);
    },
    [popupVisible],
  );
  return (
    <>
      <Button title='Detalhes analíticos do pagamento.' className="float-right" style={{ border: 'none', position: 'relative' }} variant="outline-dark" onClick={show}>
        <i className="fa fa-chart-line fa-2x"></i>
      </Button>
      <Popup width="70vw" height="80vh" onHiding={hide} visible={popupVisible} title={currentItem.descricaoFormaPagamento} showTitle={true} hideOnOutsideClick={false}>
        <Card style={{ display: 'flex', flexDirection: 'column', height: 'calc(80vh - 90px)' }}>
          <Card.Header className="" style={{ height: '80px' }}>
            <h1>Informações detalhadas do meio de pagamento</h1>
          </Card.Header>
          <Card.Body className="mt-0 pt-0 mb-3" style={{ flex: 1, overflowY: 'auto' }}>
            {/* <pre>{JSON.stringify(turno, null, 2)}</pre> */}
            <DataGrid className='mt-3'
              dataSource={(turno.pagamentos || []).filter(x => x.codigoFormaPagamento === currentItem.codigoFormaPagamento)}
              paging={{
                enabled: true, // Ativa a paginação
                pageSize: 25 // Define o número de registros por página
              }}
              pager={{
                showPageSizeSelector: true, // Permite ao usuário alterar o tamanho da página
                allowedPageSizes: [5, 10, 20, 50, 75, 100], // Opções de tamanhos de página disponíveis para o usuário
                showInfo: true, // Mostra informações da página atual
                showNavigationButtons: true, // Mostra botões de navegação de página
              }}
              groupPanel={{
                visible: true,
                emptyPanelText: 'Arraste um cabeçalho de coluna aqui para agrupar por essa coluna',
              }} // Habilita o painel de agrupamento
              defaultGrouping={[{ selector: 'descricaoRedeCartao', groupInterval: null }]}
              grouping={{ autoExpandAll: true }} // Expande todos os grupos automaticamente
            >
              <Grouping contextMenuEnabled={true} />
              <GroupItem
                column="descricaoRedeCartao"
                summaryType="count"
                displayFormat="{0} itens" // Mostra a quantidade de itens por grupo
              />
              <Column dataField="descricaoRedeCartao" className="w-100" caption="Rede" groupIndex={0}></Column>
              <Column dataField="descricaoBandeiraCartao" caption="Bandeira"></Column>
              <Column dataField="descricaoProdutoCartao" caption="Produto"></Column>
              <Column dataField="codigoNsu" caption="NSU"></Column>
              <Column dataField="numeroPedido" caption="Nº Pedido"></Column>
              <Column dataField="dataVencimento" dataType="date" caption="Vencimento" format={'dd/MM/yyyy HH:mm'}></Column>
              <Column dataField="valor" caption={'Total'} width={250} format="R$ #,##0.00" />
              <Summary
                type="sum"
                format="R$ #,##0.00"
                showInGroupFooter={true} // Mostra a soma no rodapé do grupo
              />
              <Summary recalculateWhileEditing={true}>
                <TotalItem column="valor" summaryType="sum" displayFormat="Total: R$ {0}" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
              </Summary>
            </DataGrid>
          </Card.Body>
          <Card.Footer className="border" style={{ height: '70px' }}>
            <Row>
              <Col md={12}>
                <div className="float-right">
                  <button className="btn btn-secondary ml-3" onClick={hide}>Fechar</button>
                </div>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Popup>
    </>
  );
}
