import { Button } from 'devextreme-react';
import { Export } from 'devextreme-react/bar-gauge';
import DataGrid, { ColumnChooser, ColumnFixing, FilterPanel, FilterRow, Grouping, GroupPanel, HeaderFilter, Pager, Paging, Scrolling, SearchPanel } from 'devextreme-react/data-grid';
import config from 'devextreme/core/config';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import styles from './DataGridResult.module.scss';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { getDadosLogin, maskedCnpj } from '../../../shared/utils/Utils';
import moment from 'moment';

const DataGridResult = props => {

  const [autoExpandAll, setAutoExpandAll] = useState(false);
  const [columns, setColumns] = useState([]);

  useEffect(() => {

    if (props.gridResultRef) {
      const dataGrid = props.gridResultRef.current.instance;
      dataGrid.clearGrouping();
      dataGrid.clearSorting();
      dataGrid.clearFilter();

      setColumns(props.columns);
      dataGrid.refresh(false);
    }

  }, [props.columns])

  config({
    defaultCurrency: 'BRL'
  });

  const exportGrid = () => {
    const dadosLogin = getDadosLogin();
    const empresa = dadosLogin.empresas.find(x => x.cnpj === dadosLogin.cnpj);
    
    let doc = new jsPDF({
      orientation: 'landscape'
    });
    let fs = doc.getFontSize();
    let pageWidth = doc.internal.pageSize.width;
    doc.page = 1; 
    // HEADER
    doc.setFontSize(18);
    doc.text(props.reportTitle, pageWidth / 2, 10, {align: 'center'});
    //Subtitle
    doc.setFontSize(14);
    doc.text(`CNPJ: ${maskedCnpj(empresa.cnpj)} - ${empresa.nomeFantasia}`, pageWidth / 2, 17, {align: 'center'});
    //Filters
    doc.text(props.reportFilters, pageWidth / 2, 24, {align: 'center'});
    const pdfDoc = doc;
    doc.setFontSize(fs);
    
    const dataGrid = props.gridResultRef.current.instance;

    exportDataGridToPdf({
      jsPDFDocument: pdfDoc,
      component: dataGrid,
      margin: { top: 35, left: 5, right: 5 }

    }).then(() => {
      let pages = doc.internal.getNumberOfPages();
      for (let j = 1; j < pages + 1; j++) {
        pdfDoc.setPage(j);
        doc.setFontSize(10);
        pdfDoc.text('Página ' + j + ' de ' + pages, pageWidth - 15, 10, { align: 'right' });
      }

      pdfDoc.save(`${props.fileName}.pdf`);
    });
  };

  const onExcelExporting = e => {
    const dadosLogin = getDadosLogin();
    const empresa = dadosLogin.empresas.find(x => x.cnpj === dadosLogin.cnpj);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(props.reportTitle);

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      topLeftCell: {
        row: 4,
        column: 1
      }
    })
      .then(cellRange => {
        worksheet.mergeCells(1, 1, 1, worksheet.columnCount);
        worksheet.mergeCells(2, 1, 2, worksheet.columnCount);
        worksheet.mergeCells(3, 1, 3, worksheet.columnCount);

        //Header
        const headerRow = worksheet.getRow(1, 2);
        headerRow.getCell(2).value = props.reportTitle;
        headerRow.height = 25;
        headerRow.getCell(2).font = {
          size: 18
        };
        headerRow.getCell(2).alignment = {
          horizontal: 'center'
        };

        //Subtitle
        const headerSubRow = worksheet.getRow(2, 2);
        headerSubRow.height = 25;
        headerSubRow.getCell(2).value = `CNPJ: ${maskedCnpj(empresa.cnpj)} - ${empresa.nomeFantasia
          }`;
        headerSubRow.getCell(2).font = {
          size: 14
        };
        headerSubRow.getCell(2).alignment = {
          horizontal: 'center'
        };

        //Filters
        const filtersRow = worksheet.getRow(3, 2);
        filtersRow.height = 25;
        filtersRow.getCell(2).value = props.reportFilters;
        filtersRow.getCell(2).font = {
          size: 14
        };
        filtersRow.getCell(2).alignment = {
          horizontal: 'center'
        };

        // footer Time
        const footerRow = cellRange.to.row + 2;

        const footerTimeRow = worksheet.getRow(footerRow, 1);
        footerTimeRow.getCell(1).value = moment(new Date()).format(
          'DD/MM/YYYY HH:mm'
        );
        footerTimeRow.getCell(1).font = {
          italic: true
        };

        // footer URL
        const footerUrlRow = worksheet.getRow(footerRow, 1);
        worksheet.mergeCells(
          footerRow,
          2,
          footerRow,
          worksheet.columnCount - 1
        );
        footerUrlRow.getCell(2).value = dadosLogin.tipoSiteUrl;
        footerUrlRow.getCell(2).font = {
          italic: true
        };
        footerUrlRow.getCell(2).alignment = {
          horizontal: 'center'
        };

        // footer User
        const footerUserRow = worksheet.getRow(footerRow, 1);
        footerUserRow.getCell(worksheet.columnCount).value = dadosLogin.nome;
        footerUserRow.getCell(worksheet.columnCount).font = {
          italic: true
        };

        worksheet.autoFilter = {
          from: {
            row: 4,
            column: 1
          },
          to: {
            row: cellRange.to.row,
            column: worksheet.columnCount
          }
        };
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(
            new Blob([buffer], {
              type: 'application/octet-stream'
            }),
            `${props.fileName}.xlsx`
          );
        });
      });
    e.cancel = true;
  };

  return (
    <div className={styles.dataGridResult}>
      <div className={styles.gridOptions}>
        {props.export ? (

            <Button
            hint="Exportar para PDF"
              disabled={props.disabled}
              icon="exportpdf"
              className={`${styles.pdfButton}`}
              onClick={exportGrid}
            />

        ) : null}
        {props.allowExpand ? (
          <Button
            hint="Expandir/Recolher agrupamentos"
            disabled={props.disabled}
            className={styles.expandButton}
            icon={autoExpandAll ? 'collapse' : 'expand'}
            onClick={() => setAutoExpandAll(!autoExpandAll)}
          />
        ) : null}
      </div>
      <DataGrid
        dataSource={props.dataSource}
        columns={columns}
        showBorders={true}
        ref={props.gridResultRef}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={'widget'}
        columnMinWidth={80}
        onExporting={onExcelExporting}
        rowAlternationEnabled={true}
        wordWrapEnabled={true}
        //loadPanel={{ enabled: true }}
      >
        <Scrolling showScrollbar={'always'} />
        <GroupPanel visible={props.filters} />
        <FilterRow visible={props.filters} />
        <FilterPanel visible={props.filters} />
        <HeaderFilter visible={props.filters} />
        {props.summary()}
        <ColumnChooser enabled={props.columnChooser} mode={'select'} allowSearch={true} height={350} />
        <Export enabled={props.export} fileName={props.fileName} />
        <SearchPanel visible={props.searchPanel} highlightCaseSensitive={true} width={194} placeholder={'Pesquisar no resultado...'} />
        <Grouping autoExpandAll={autoExpandAll} />
        <ColumnFixing enabled={true} />
        <Pager showPageSizeSelector={props.showPageSizeSelector} allowedPageSizes={props.allowedPageSizes} showInfo={true} />
        <Paging defaultPageSize={props.defaultPageSize} />
      </DataGrid>
    </div>
  );
};

export default DataGridResult;
