import { Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DateBox } from 'devextreme-react';
import styles from './../components.module.scss';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { removeDiasDaData } from '../../../../../shared/utils/Utils';

function DataEvento() {
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const dispatch = useDispatch();
  const [minDate, setMinDate] = useState(removeDiasDaData(30));

  useEffect(() => {
    if(values.id !== -1 && values.dataCadastro !== null) {
      setMinDate(removeDiasDaData(30, new Date(values.dataCadastro)));
      return;
    }
    setMinDate(removeDiasDaData(30));
  }, [values.dataCadastro, values.id]);
  return (
    <Row className={`${styles.row}`}>
      <Col lg={4} className={`form-group`}>
        <Form.Label htmlFor="dataEvento" className="text-primary">
          Data do Evento<span className="text-danger">*</span>
        </Form.Label>
        <DateBox
          type="date"
          displayFormat="dd/MM/yyyy"
          min={minDate}
          name="dataEvento"
          id="dataEvento"
          onValueChange={e => {
            dispatch(
              changeValue({
                fieldName: 'dataEvento',
                value: new Date(e).toISOString(),
              }),
            );
            dispatch(changeBlur({ fieldName: 'dataEvento' }));
          }}
          defaultValue={minDate}
          value={new Date(values.dataEvento)}
        />
        {(touched || {}).dataEvento && (errors || {}).dataEvento && (
          <div className="text-danger validationError">
            {(errors || {}).dataEvento || ''}
          </div>
        )}
      </Col>
      <Col lg={3} className={`form-group`}>
        <Form.Label htmlFor="dataEventoHora" className="text-primary">
          Hora<span className="text-danger">*</span>
        </Form.Label>
        <DateBox
          type="time"
          pickerType="native"
          name="dataEventoHora"
          min={minDate}
          onValueChange={e => {
            dispatch(
              changeValue({
                fieldName: 'dataEvento',
                value: new Date(e).toISOString(),
              }),
            );
            dispatch(changeBlur({ fieldName: 'dataEvento' }));
          }}
          displayFormat="HH:mm"
          value={new Date(values.dataEvento)}
        />
      </Col>
    </Row>
  );
}

export default DataEvento;
