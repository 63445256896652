import { BuscarProdutoService } from './BuscarProdutos.service';
export class ProdutoService {
  static instance = null;
  static getInstance() {
    if (!ProdutoService.instance) {
      ProdutoService.instance = new ProdutoService();
    }
    return ProdutoService.instance;
  }

  buscarProduto = async (arg, tipoProduto = null) => {
    return await BuscarProdutoService.getInstance().execute(arg, tipoProduto);
  };

  buscarProdutoId = async (id, tipoProduto = null) => {
    return await BuscarProdutoService.getInstance().execute(id, tipoProduto, true);
  };
}
