import React, { memo, useCallback } from 'react';

import { SelectBox } from 'devextreme-react';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { DropDownImage } from '../drop-down-image';

const DropBoxPagamentos = forwardRef(({ title, onSelected }, ref) => {
  const pagamentos = useSelector(selectConsignadoComodatoSharedData).pagamentos;
  const [payment, setPayment] = useState(null);
  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem) {
        if (payment) return;
        setPayment(null);
        return;
      }
      const data = { ...e.selectedItem };
      if (data.id === (payment || {}).id) return;
      setPayment(data);
    },
    [payment, setPayment],
  );

  const onValueChanged = useCallback(
    data => {
      if (!data.event) return;
      if (onSelected) onSelected((data || {}).value);
    },
    [onSelected],
  );

  useImperativeHandle(
    ref,
    () => ({
      getPayment: () => {
        return payment;
      },
      clear: () => {
        setPayment(null);
        if (onSelected) onSelected(null);
      }
    }),
    [payment, onSelected],
  );

  return (
    <>
      <Form.Label htmlFor="pagamento" className="text-primary">
        {title ? title : 'Forma de Pagamento'}
        <span className="text-danger">*</span>
      </Form.Label>
      <SelectBox
        dropDownButtonRender={DropDownImage}
        showDropDownButton={true}
        searchEnabled={true}
        hint="Selecione a forma de pagamento"
        placeholder="Selecione a forma de pagamento"
        displayExpr="descricao"
        acceptCustomValue={false} // Não permite que o valor digitado seja aceito
        height={40}
        valueExpr="id"
        value={payment}
        onSelectionChanged={onSelectionChanged}
        onValueChanged={onValueChanged}
        dataSource={pagamentos}
        selectedItem={payment || {}}
        name="pagamento"
      />
    </>
  );
});

export default memo(DropBoxPagamentos);
