import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import styles from './Main.module.scss';

export default function Main(props) {
  return (
    <main className={`${TIPO_SITE === 'RetaguardaSellerWeb' ? styles.mainSeller : styles.main}  ${styles.fadeIn}`}>
      {props.noScroll ? (
        <div className={`${styles.container} container-fluid`}>{props.children}</div>
      ) : (
        <PerfectScrollbar className={props.className}>
          <div className={`${styles.container} container-fluid`}>{props.children}</div>
        </PerfectScrollbar>
      )}
    </main>
  );
}
