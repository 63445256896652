import {
  getUrlLinxCore,
  getUrlLinxReports
} from '../../../../services/core/Auth.service';

const conferenciaCaixa = {
  listaTurnos,
  listaMaquinas,
  listaSituacoesTurnos,
  ListarFechamentoCaixa,
  DocumentosPorTurno,
  SalvarConferencia,
  RealizarConferencia,
  RealizarConferenciaFacil,
  RealizarConferenciaOffline,
  Reabrir,
  ConsultarVale,
  VerificaSePossuiVale,
  DiaPossuiVendaCombustivel,
  Situacoes
};

function Situacoes() {
  return {
    NaoConferido: 0,
    Conferido: 1,
    ConferidoParcialmente: 2,
    AbertoNoPDV: 3
  };
}

async function ConsultarVale(turno, operador) {
  const response = await getUrlLinxReports()
    .get(`turno/v1/Imprimir?turno=${turno}&operador=${operador}`, {
      responseType: 'blob'
    })
    .then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });

      const fileURL = URL.createObjectURL(file);
      return fileURL;
    })
    .catch(error => {
      throw error;
    });
  return response;
}

async function Reabrir(codigoTurno, turnoOperadorId) {
  const response = await getUrlLinxCore()
    .post(`/Turno/Reabrir?turno=${codigoTurno}&turnoOperadorId=${turnoOperadorId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function RealizarConferenciaFacil(body) {
  const response = await getUrlLinxCore()
    .post('/Turno/RealizarConferenciaFacil', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function RealizarConferenciaOffline(body) {
  const response = await getUrlLinxCore()
    .post('/Turno/RealizarConferenciaOffline', body)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function RealizarConferencia(body) {
  const response = await getUrlLinxCore()
    .post('/Turno/RealizarConferencia', [body])
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function SalvarConferencia(body) {
  const response = await getUrlLinxCore()
    .post('/Turno/SalvarConferencia', [body])
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function listaTurnos(consulta) {
  const response = await getUrlLinxCore().post('/Turno/ListarTurnos', {
    query: consulta
  });
  return response.data.data;
}

async function listaMaquinas() {
  const response = await getUrlLinxCore().get('/Maquina/Listar');
  return response.data.data;
}

async function listaSituacoesTurnos() {
  const response = await getUrlLinxCore().get('/Turno/ListarSituacoesTurnos');
  return response.data.data;
}

async function ListarFechamentoCaixa(codigoTurno, turnoOperador) {
  const response = await getUrlLinxCore().get(
    `/Turno/ListarFechamentoCaixa?Id=${codigoTurno}&turnoOperadorId=${turnoOperador}`
  );
  return response.data.data;
}

async function DocumentosPorTurno(consulta) {
  const response = await getUrlLinxCore().post(
    '/DocumentoFiscal/DocumentosPorTurno',
    {
      query: {
        operadores: consulta.operadores,
        turno: consulta.turno,
        formaPagamento: consulta.formaPagamento
      }
    }
  );
  return response.data.data;
}

async function VerificaSePossuiVale(codigoTurno, turnoOperador) {
  const response = await getUrlLinxCore()
    .get(`Turno/VerificaSePossuiVale?turno=${codigoTurno}&turnoOperadorId=${turnoOperador}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function DiaPossuiVendaCombustivel(dataInicioTurno) {
  const response = await getUrlLinxCore()
    .get(`Turno/DiaPossuiVendaCombustivel?dataInicioTurno=${dataInicioTurno}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

export default conferenciaCaixa;
