import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

export class ApiListarFuncionariosService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(useCache = false) {
    try {
      const dadosLogin = getDadosLogin();
      const cacheKey = `ApiListarFuncionariosService${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = useCache ? memoryCache.get(cacheKey) : null;

      if (cachedData) {
        return cachedData;
      } else {
        const response = await this.urlLinxCore.post(`/Funcionario/ListarTodos`, {});
        var r = response.data;
        var data = r.itens;
        if (useCache) memoryCache.put(cacheKey, data, 1000 * 60 * 3);
        return data;
      }
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
