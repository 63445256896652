import { CharToInt } from './utils';
export function CheckIESP(ie) {
  var nro = [];
  var i = 0;
  if (ie.substring(0, 1).toUpperCase() === 'P') {
    var s = ie.substring(1, 9);
    nro = new Array(12);
    for (i = 0; i <= 7; i++) nro[i] = CharToInt(s[i]);
    var soma = nro[0] * 1 + nro[1] * 3 + nro[2] * 4 + nro[3] * 5 + nro[4] * 6 + nro[5] * 7 + nro[6] * 8 + nro[7] * 10;
    var dig = soma % 11;
    if (dig >= 10) dig = 0;
    var resultado = dig === nro[8];
    if (!resultado) return false;
  } else {
    if (ie.length < 12) return false;
    nro = new Array(12);
    for (i = 0; i <= 11; i++) nro[i] = CharToInt(ie.charAt(i));
    soma = nro[0] * 1 + nro[1] * 3 + nro[2] * 4 + nro[3] * 5 + nro[4] * 6 + nro[5] * 7 + nro[6] * 8 + nro[7] * 10;
    dig = soma % 11;
    if (dig >= 10) dig = 0;
    resultado = dig === nro[8];
    if (!resultado) return false;
    soma = nro[0] * 3 + nro[1] * 2 + nro[2] * 10 + nro[3] * 9 + nro[4] * 8 + nro[5] * 7 + nro[6] * 6 + nro[7] * 5 + nro[8] * 4 + nro[9] * 3 + nro[10] * 2;
    dig = soma % 11;
    if (dig >= 10) dig = 0;
    return dig === nro[11];
  }
} //sp
