import axios from 'axios';
import { getToken } from '../../../authentication/Auth';
import dateTimeBox from '../../../components/DateTimeBox/DateTimeBox';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';
import { maskedCnpjCpf } from '../../../shared/utils/Utils';

export const ConsignadoDinamicoService = {
  find,
  GetColumns
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {

  appliedFilters.dataInicio = appliedFilters.dataInicio.split('T')[0];
  appliedFilters.dataFim = appliedFilters.dataFim.split('T')[0];

  const response = await urlLinxCore.post('/ConComodato/ListarRelatorio', appliedFilters)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

const formatDocument = data => {
  return maskedCnpjCpf(data.value);
};

function GetColumns(matriz) {
  let colunas = [
    { 
      dataField: 'empresaCnpj', 
      caption: 'CNPJ da Empresa', 
      width: '130', 
      visible: matriz
    },
    {
      dataField: 'empresaRazaoSocial',
      caption: 'Razao Social',
      width: '200',
      visible: false
    },
    {
      dataField: 'empresaNomeFantasia',
      caption: 'Nome Fantasia',
      width: '200',
      visible: false
    },
    { 
      dataField: 'pedidoNumero', 
      caption: 'Nº Pedido', 
      width: '130', 
      visible: true,
      groupIndex: 2
    },
    {
      dataField: 'pedidoStatus',
      caption: 'Status do Pedido',
      width: '200',
      visible: false,
    },
    {
      dataField: 'notaData',
      caption: 'Emissão',
      dataType: 'date',
      width: '150',
    },
    {
      dataField: 'notaDiaSemana',
      caption: 'Dia da Semana',
      width: '120',
      visible: false
    },
    {
      dataField: 'notaNumero',
      caption: 'Nº Documento',
      width: '130',
      alignment: 'left',
      visible: false,
    },    
    {
      dataField: 'clienteRazaoNome',
      caption: 'Cliente',
      width: '250',
    },
    {
      dataField: 'itemDescricao',
      caption: 'Produto',
      minWidth: '130',
    },
    {
      dataField: 'itemCodigoEan',
      caption: 'Cód. Produto',
      width: '120',
      visible: false
    },
    {
      dataField: 'itemNumeroSerie',
      caption: 'Nº Série do Item',
      width: '120',
      visible: false
    },
    {
      dataField: 'pedidoTipo',
      caption: 'Operação',
      width: '270',
      alignment: 'left',
    },
    {
      dataField: 'itemValor',
      caption: 'Valor R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false
    },
    {
      dataField: 'itemValorDesconto',
      caption: 'Desconto R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false
    },
    {
      dataField: 'itemValorAcrescimo',
      caption: 'Acréscimo R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false
    },
    {
      dataField: 'itemValorTotal',
      caption: 'Líquido R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false
    },
    {
      dataField: 'itemQuantidadeTotal',
      caption: 'Quantidade',
      width: '150',
    },
 
  ];
 
  if(matriz)
    colunas[0].groupIndex = 1;

  return colunas;
}

export default urlLinxCore;
