import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styles from './CadastroEstabelecimento.module.scss';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  InputText,
  Select,
  InputDate
} from '../../../components/formulario/Formulario';
import {
  montarComboUfs,
  montarComboMunicipios
} from './CadastroEstabelecimento.service';
import Footer from '../../../components/core/footer/Footer';
import {
  showError,
  showInfo,
  tagAlert,
  showWait,
  hideWait,
  hideAlert
} from '../../../components/alert/Alert';
import moment from 'moment';
import { validaCNPJ, validaCPF, isValidIE } from '../../../shared/utils/Utils';
import { incluirEstabelecimento } from './CadastroEstabelecimento.service';

const windowTitle = 'Cadastro Inicial do Estabelecimento';

class CadastroEstabelecimento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      empresa: {
        cnpj: '',
        razaoSocial: '',
        nomeFantasia: '',
        inscricaoEstadual: '',
        inscricaoMunicipal: '',
        dataConstituicao: '',
        registroJunta: '',
        dataRegistroJunta: '',
        ddd: '',
        telefone: '',
        fax: '',
        email: '',
        enderecoInternet: '',
        endereco: {
          tipoLogradouro: '',
          logradouro: '',
          numero: '',
          complemento: '',
          referencia: '',
          bairro: '',
          uf: 0,
          ufStr: '',
          municipio: 0,
          municipioStr: '',
          cep: ''
        },
        representanteLegal: {
          nome: '',
          cpf: ''
        }
      },
      listaUFs: [],
      listaMunicipios: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUFChange = this.handleUFChange.bind(this);
    this.handleMunicipioChange = this.handleMunicipioChange.bind(this);
  }

  async componentDidMount() {
    let lista = montarComboUfs();

    this.setState({
      listaUFs: lista
    });

    this.carregaComboMunicipios();
  }

  verificaCNPJ = () => {
    if (
      this.state.empresa.cnpj !== '' &&
      !validaCNPJ(this.state.empresa.cnpj)
    ) {
      showError(this, windowTitle, 'CNPJ não é válido');
      return false;
    }

    return true;
  };

  verificaCPF = () => {
    if (
      this.state.empresa.representanteLegal.cpf !== '' &&
      !validaCPF(this.state.empresa.representanteLegal.cpf)
    ) {
      showError(this, windowTitle, 'CPF do Representante legal não é válido');
    }

    return true;
  };

  verificaIE = () => {
    if (
      this.state.empresa.inscricaoEstadual !== '' &&
      !isValidIE(
        this.state.empresa.inscricaoEstadual,
        this.state.empresa.endereco.uf
      )
    ) {
      showError(
        this,
        windowTitle,
        'Inscrição estadual não é válida para a UF selecionada.'
      );
      return false;
    } else return true;
  };

  validaTeclaDigitada = e => {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }

    if (e.key === 'ç') {
      e.preventDefault();
    }
  };

  validaTextoColado = event => {
    var textoNovo = '';

    var textoOriginal = '';

    var tamMax = 0;
    var tamTexto = 0;

    tamMax = event.target.maxLength;

    var caracterEspecial = false;

    var campo = event.target.name;

    textoOriginal = event.clipboardData.getData('Text');

    for (let i = 0; i < textoOriginal.length; i++) {
      const re = /[0-9a-zA-Z]+/g;
      const c = textoOriginal[i];
      tamTexto = textoNovo.length;
      caracterEspecial = false;
      if (!re.test(c)) {
        caracterEspecial = true;
      }

      if (c === 'ç') {
        caracterEspecial = true;
      }

      if (tamTexto < tamMax) if (!caracterEspecial) textoNovo += c;
    }

    if (caracterEspecial) event.preventDefault();

    this.setState({
      [campo]: textoNovo
    });
  };

  validaDataEscolhida = date => {
    var dataValida = false;
    dataValida = moment(date, 'YYYY-MM-DD').isValid();
    return dataValida;
  };

  validaDadosEstabelecimento = () => {
    if (
      !this.state.empresa.nomeFantasia ||
      this.state.empresa.nomeFantasia.trim() === ''
    ) {
      showError(this, windowTitle, 'É necessário informar o nome fantasia.');
      return false;
    }

    if (
      !this.state.empresa.razaoSocial ||
      this.state.empresa.razaoSocial.trim() === ''
    ) {
      showError(this, windowTitle, 'É necessário informar a razão social.');
      return false;
    }

    if (!this.validaDataEscolhida(this.state.empresa.dataRegistroJunta)) {
      showError(
        this,
        windowTitle,
        'Campo Data de Registro na Junta deve ser uma data válida'
      );
      return false;
    }

    if (!this.validaDataEscolhida(this.state.empresa.dataConstituicao)) {
      showError(
        this,
        windowTitle,
        'Campo Data Constituição deve ser uma data válida'
      );
      return false;
    }

    if (!this.state.empresa.cnpj || this.state.empresa.cnpj.trim() === '') {
      showError(this, windowTitle, 'É necessário informar o CNPJ.');
      return false;
    } else if (!this.verificaCNPJ()) {
      return false;
    }

    if (
      !this.state.empresa.inscricaoEstadual ||
      this.state.empresa.inscricaoEstadual.trim() === ''
    ) {
      showError(
        this,
        windowTitle,
        'É necessário informar a inscrição estadual.'
      );
      return false;
    } else if (!this.verificaIE()) {
      return false;
    }

    if (!this.state.empresa.ddd || this.state.empresa.ddd.trim() === '') {
      showError(this, windowTitle, 'É necessário informar o DDD.');
      return false;
    }

    if (
      !this.state.empresa.telefone ||
      this.state.empresa.telefone.trim() === ''
    ) {
      showError(this, windowTitle, 'É necessário informar o telefone.');
      return false;
    }

    return true;
  };

  validaEndereco = () => {
    if (
      !this.state.empresa.endereco.tipoLogradouro ||
      this.state.empresa.endereco.tipoLogradouro.trim() === ''
    ) {
      showError(this, windowTitle, 'É necessário informar o logradouro.');
      return false;
    }

    if (
      !this.state.empresa.endereco.logradouro ||
      this.state.empresa.endereco.logradouro.trim() === ''
    ) {
      showError(this, windowTitle, 'É necessário informar o endereço.');
      return false;
    }

    if (
      !this.state.empresa.endereco.bairro ||
      this.state.empresa.endereco.bairro.trim() === ''
    ) {
      showError(this, windowTitle, 'É necessário informar o bairro.');
      return false;
    }

    if (
      !this.state.empresa.endereco.uf ||
      this.state.empresa.endereco.uf <= 0
    ) {
      showError(this, windowTitle, 'É necessário informar o estado.');
      return false;
    }

    if (
      !this.state.empresa.endereco.municipio ||
      this.state.empresa.endereco.municipio <= 0
    ) {
      showError(this, windowTitle, 'É necessário informar o município.');
      return false;
    }

    if (
      !this.state.empresa.endereco.cep ||
      this.state.empresa.endereco.cep <= 0
    ) {
      showError(this, windowTitle, 'É necessário informar o CEP.');
      return false;
    }

    return true;
  };

  validaRepresentanteLegal = () => {
    if (
      !this.state.empresa.representanteLegal.nome ||
      this.state.empresa.representanteLegal.nome.trim() === ''
    ) {
      showError(
        this,
        windowTitle,
        'É necessário informar o nome do representante legal.'
      );
      return false;
    }

    if (
      !this.state.empresa.representanteLegal.cpf ||
      this.state.empresa.representanteLegal.cpf.trim() === ''
    ) {
      showError(
        this,
        windowTitle,
        'É necessário informar o CPF do representante legal.'
      );
      return false;
    } else {
      return validaCPF(this.state.empresa.representanteLegal.cpf);
    }
  };

  isFormValid = () => {
    if (!this.validaDadosEstabelecimento()) {
      return false;
    }

    if (!this.validaEndereco()) {
      return false;
    }

    if (!this.validaRepresentanteLegal()) {
      return false;
    }

    return true;
  };

  handleSalvar = async () => {
    if (!this.isFormValid()) {
      return;
    }

    showWait(
      this,
      windowTitle,
      'Por favor, aguarde enquanto os dados são salvos.'
    );
    incluirEstabelecimento(this.state.empresa, this.props.baseUrl).then(
      response => {
        hideWait(this);
        if (response) {
          showInfo(
            this,
            windowTitle,
            'Estabelecimento salvo com sucesso!',
            null,
            () => {
              hideAlert(this);
              this.props.handleEmpresaCadastrada(true);
            }
          );
        } else {
          showError(this, windowTitle, 'Erro ao salvar estabelecimento!');
        }
      }
    );
  };

  handleUFChange(event) {
    let value,
      valueStr = '';
    value = event.target.value;

    const isUF = uf => uf.value === parseInt(value);
    var item = this.state.listaUFs.filter(isUF).map(cid => {
      return { label: cid.label, value: cid.value };
    });
    valueStr = item[0].label;
    let changed = value !== this.state.empresa.endereco.uf;

    let empresaEdit = this.state.empresa;
    empresaEdit.endereco.uf = parseInt(value);
    empresaEdit.endereco.ufStr = valueStr;
    if (changed)
    {
      empresaEdit.endereco.municipio = null;
      empresaEdit.endereco.municipioStr = '';
    }
    this.setState({ empresa: empresaEdit });
    this.carregaComboMunicipios(empresaEdit.endereco.uf);
  }

  handleMunicipioChange(event) {
    let value,
      valueStr = '';
    value = event.target.value;

    const isMunicipio = municipio => municipio.value === parseInt(value);
    var item = this.state.listaMunicipios.filter(isMunicipio).map(cid => {
      return { label: cid.label, value: cid.value };
    });
    valueStr = item[0].label;

    let empresaEdit = this.state.empresa;
    empresaEdit.endereco.municipio = parseInt(value);
    empresaEdit.endereco.municipioStr = valueStr;
    this.setState({ empresa: empresaEdit });
  }

  carregaComboMunicipios = async codigoUFAtual => {
    if (!codigoUFAtual) codigoUFAtual = this.state.empresa.endereco.uf;
    let lista = montarComboMunicipios(codigoUFAtual);

    this.setState({ listaMunicipios: lista });

    if (lista && lista.length > 0) {
      let empresaEdit = this.state.empresa;
      empresaEdit.endereco.municipio = lista[0].value;
      empresaEdit.endereco.municipioStr = lista[0].label;
      this.setState({ empresa: empresaEdit });
    } 
  };

  handleChange(event) {
    let value, name;
    if (event.hasOwnProperty('target')) {
      // inputs comuns
      const target = event.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    } else if (event.hasOwnProperty('floatValue')) {
      // input react-number-format
      value = event.format === null ? event.floatValue : event.value;
      name = event.name;
    } else if (event.hasOwnProperty('date')) {
      // react-date...
      value = event.date ? event.date.format('YYYY-MM-DD') : null;
      name = event.name;
    } else {
      // multi-select, TimeField...
      value = event.hasOwnProperty('selected') ? event.selected : event.value;
      name = event.name;
    }

    var values = this.state;
    var currentProperty = null;
    var currentPropertyStr = '';

    while (name.indexOf('.') > 0) {
      currentPropertyStr = name.substring(0, name.indexOf('.'));
      currentProperty = !currentProperty
        ? values[currentPropertyStr]
        : currentProperty[currentPropertyStr];
      name = name.substring(name.indexOf('.') + 1);
      if (name.indexOf('.') <= 0) currentProperty[name] = value;
    }

    this.setState(values);
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    return (
      <>
        <main className={`${styles.main} ${styles.fadeIn}`}>
          <PerfectScrollbar>
            <div className={`${styles.container} container-fluid`}>
              <form onSubmit={this.handleSubmit}>
                <SectionContainer>
                  <div className="row">
                    <h1>{windowTitle}</h1>
                  </div>
                  <SectionContent title="Endereço" accordion>
                    {/* Logradouro, Endereço e Número */}
                    <div className="row">
                      <div className="col-2">
                        <InputText
                          label="Logradouro"
                          value={this.state.empresa.endereco.tipoLogradouro}
                          name="empresa.endereco.tipoLogradouro"
                          onChange={this.handleChange}
                          tabindex={1}
                          maxlength="10"
                        />
                      </div>
                      <div className="col-8">
                        <InputText
                          label="Endereço"
                          value={this.state.empresa.endereco.logradouro}
                          name="empresa.endereco.logradouro"
                          onChange={this.handleChange}
                          tabindex={2}
                          maxlength="65"
                          required={true}
                        />
                      </div>
                      <div className="col-2">
                        <InputText
                          label="Número"
                          value={this.state.empresa.endereco.numero}
                          name="empresa.endereco.numero"
                          onChange={this.handleChange}
                          tabindex={3}
                          maxlength="10"
                          type="number"
                          decimalScale={0}
                        />
                      </div>
                    </div>
                    {/* Complemento, Referência e Bairro */}
                    <div className="row">
                      <div className="col-4">
                        <InputText
                          label="Complemento"
                          value={this.state.empresa.endereco.complemento}
                          name="empresa.endereco.complemento"
                          onChange={this.handleChange}
                          tabindex={4}
                          maxlength="45"
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="Referência"
                          value={this.state.empresa.endereco.referencia}
                          name="empresa.endereco.referencia"
                          onChange={this.handleChange}
                          tabindex={5}
                          maxlength="45"
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="Bairro"
                          value={this.state.empresa.endereco.bairro}
                          name="empresa.endereco.bairro"
                          onChange={this.handleChange}
                          tabindex={6}
                          maxlength="45"
                          required={true}
                        />
                      </div>
                    </div>
                    {/* UF, Município e CEP */}
                    <div className="row">
                      <div className="col-1">
                        <Select
                          label="UF"
                          name="empresa.endereco.uf"
                          value={this.state.empresa.endereco.uf}
                          onChange={this.handleUFChange}
                          options={this.state.listaUFs}
                          required
                          autofocus="true"
                          tabindex={7}
                        />
                      </div>
                      <div className="col-4">
                        <Select
                          label="Município"
                          name="empresa.endereco.municipio"
                          value={this.state.empresa.endereco.municipio}
                          onChange={this.handleMunicipioChange}
                          options={this.state.listaMunicipios}
                          required
                          autofocus="true"
                          tabindex={8}
                        />
                      </div>
                      <div className="col-2">
                        <InputText
                          label="CEP"
                          value={this.state.empresa.endereco.cep}
                          name="empresa.endereco.cep"
                          onChange={this.handleChange}
                          tabindex={9}
                          required={true}
                          type="number"
                          decimalScale={0}
                          format="#####-###"
                        />
                      </div>
                    </div>
                  </SectionContent>
                  <div className="content-divider" />
                  <SectionContent title="Dados do estabelecimento" accordion>
                    {/* Nome fantasia e Razão Social */}
                    <div className="row">
                      <div className="col-5">
                        <InputText
                          label="Nome Fantasia"
                          value={this.state.empresa.nomeFantasia}
                          name="empresa.nomeFantasia"
                          onChange={this.handleChange}
                          tabindex={10}
                          maxlength="30"
                          required={true}
                        />
                      </div>
                      <div className="col-7">
                        <InputText
                          label="Razão Social"
                          value={this.state.empresa.razaoSocial}
                          name="empresa.razaoSocial"
                          onChange={this.handleChange}
                          tabindex={11}
                          maxlength="65"
                          required={true}
                        />
                      </div>
                    </div>
                    {/* Data de Fundação, Numero e Data de Inscrição de Inscrição Junta */}
                    <div className="row">
                      <div className="col-2">
                        <InputDate
                          label="Data Constituição"
                          value={this.state.empresa.dataConstituicao}
                          name="empresa.dataConstituicao"
                          onChange={this.handleChange}
                          tabindex={12}
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="Registro na junta sob o nº"
                          value={this.state.empresa.registroJunta}
                          name="empresa.registroJunta"
                          onChange={this.handleChange}
                          tabindex={13}
                          maxlength="15"
                          type="number"
                        />
                      </div>
                      <div className="col-2">
                        <InputDate
                          label="Data Registro Junta"
                          value={this.state.empresa.dataRegistroJunta}
                          name="empresa.dataRegistroJunta"
                          onChange={this.handleChange}
                          required={true}
                          tabindex={14}
                        />
                      </div>
                    </div>
                    {/* CNPJ, Inscrição Estadual e Inscrição Municipal */}
                    <div className="row">
                      <div className="col-4">
                        <InputText
                          label="CNPJ"
                          value={this.state.empresa.cnpj}
                          name="empresa.cnpj"
                          onChange={this.handleChange}
                          tabindex={15}
                          maxlength="20"
                          required={true}
                          format="##.###.###/####-##"
                          onBlur={this.verificaCNPJ}
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="Inscrição Estadual"
                          value={this.state.empresa.inscricaoEstadual}
                          name="empresa.inscricaoEstadual"
                          onChange={this.handleChange}
                          onBlur={this.verificaIE}
                          onKeyDown={this.validaTeclaDigitada}
                          onPaste={this.validaTextoColado}
                          tabindex={16}
                          maxlength="20"
                          required={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="Inscrição Municipal"
                          value={this.state.empresa.inscricaoMunicipal}
                          name="empresa.inscricaoMunicipal"
                          onChange={this.handleChange}
                          tabindex={17}
                          maxlength="20"
                        />
                      </div>
                    </div>
                    {/* E-mail e Endereço Internet */}
                    <div className="row">
                      <div className="col-6">
                        <InputText
                          label="E-mail"
                          value={this.state.empresa.email}
                          name="empresa.email"
                          onChange={this.handleChange}
                          tabindex={18}
                          maxlength="200"
                        />
                      </div>
                      <div className="col-6">
                        <InputText
                          label="Endereço Internet"
                          value={this.state.empresa.enderecoInternet}
                          name="empresa.enderecoInternet"
                          onChange={this.handleChange}
                          tabindex={19}
                          maxlength="200"
                        />
                      </div>
                    </div>
                    {/* DDD, Telefone e Fax */}
                    <div className="row">
                      <div className="col-2">
                        <InputText
                          label="DDD"
                          value={this.state.empresa.ddd}
                          name="empresa.ddd"
                          onChange={this.handleChange}
                          tabindex={20}
                          maxlength="2"
                          required={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="Telefone"
                          value={this.state.empresa.telefone}
                          name="empresa.telefone"
                          onChange={this.handleChange}
                          tabindex={21}
                          maxlength="9"
                          required={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="Fax"
                          value={this.state.empresa.fax}
                          name="empresa.fax"
                          onChange={this.handleChange}
                          tabindex={13}
                          maxlength="9"
                        />
                      </div>
                    </div>
                  </SectionContent>
                  <div className="content-divider" />
                  <SectionContent title="Representante Legal" accordion>
                    {/* Nome e CPF */}
                    <div className="row">
                      <div className="col-8">
                        <InputText
                          label="Nome"
                          value={this.state.empresa.representanteLegal.nome}
                          name="empresa.representanteLegal.nome"
                          onChange={this.handleChange}
                          tabindex={23}
                          maxlength="50"
                          required={true}
                        />
                      </div>
                      <div className="col-4">
                        <InputText
                          label="CPF"
                          value={this.state.empresa.representanteLegal.cpf}
                          name="empresa.representanteLegal.cpf"
                          onChange={this.handleChange}
                          tabindex={24}
                          required={true}
                          type="number"
                          onBlur={this.verificaCPF}
                          format="###.###.###-##"
                        />
                      </div>
                    </div>
                  </SectionContent>
                </SectionContainer>
                <Footer saveAction={this.handleSalvar} cancelAction={null} />
                {tagAlert(this)}
              </form>
            </div>
          </PerfectScrollbar>
        </main>
      </>
    );
  }
}

export {CadastroEstabelecimento};
