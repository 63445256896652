import React from 'react';
import moment from 'moment';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from '../../../../components/modal/Modal';
import {
  InputText,
  handleInputChange
} from '../../../../components/formulario/Formulario';
import Botao from '../../../../components/botao/Botao';
import {
  showError,
  tagAlert,
  setFocus
} from '../../../../components/alert/Alert';
import {
  emptyText,
  maskedCnpjCpf,
  labelCpfCnpj,
  formatNumber
} from '../../../../shared/utils/Utils';
import { getRecibo, alteraRecibo } from '../GerenciamentoFinanceiro.service';
import { searchCliente, tagSearchPessoa } from '../SearchPessoa';
import { Classificacao } from '../../../cadastro/pessoas/Pessoas.jsx';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

const titulo = 'Recibo';

class ReciboModal extends React.Component {
  constructor(props) {
    // selectedRow:{id}, onClose
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      razaoSocial: null,
      nomeRecebido: null,
      cpfcnpjRecebido: null,
      valor: null,
      valorPorExtenso: null,
      descricao: null,
      observacao: null,
      cidade: null,
      data: null,
      codigoTipoPessoa: null,
      codigoPessoa: null,
      saveCodigoPessoa: null,
      previewing: false
    };
  }

  apiToState = data => {
    return {
      razaoSocial: data ? data.razaoSocial : null,
      nomeRecebido: data ? data.nomeRecebido : null,
      cpfcnpjRecebido: data ? maskedCnpjCpf(data.cpfcnpjRecebido) : null,
      valor: data ? formatNumber(data.valor) : null,
      valorPorExtenso: data ? data.valorPorExtenso : null,
      descricao: data ? data.descricao : null,
      observacao: data ? data.observacao : null,
      cidade: data ? data.cidade : null,
      data: moment().format('D[ de ]MMMM[ de ]YYYY'),
      codigoTipoPessoa: data ? data.codigoTipoPessoa : null,
      codigoPessoa: data ? data.codigoPessoa : null,
      saveCodigoPessoa: data ? data.codigoPessoa : null
    };
  };

  loadRecibo = async id => {
    try {
      const { data } = await getRecibo(id);
      this.setState(this.apiToState(data));
    } catch (e) {
      this.setState(this.apiToState(null));
    }
  };

  validate = () => {
    let message = '';
    let focus = '';
    if (!this.hasPessoa()) {
      if (this.hasRecebido())
        message = 'Conclua a pesquisa do cliente clicando na "lupa".';
      else message = 'Informe o cliente e clique na "lupa".';
      focus = 'nomeRecebido';
    }
    if (!message) return true;
    showError(this, titulo, message, focus);
    return false;
  };

  saveRecibo = () => {
    if (this.state.codigoPessoa !== this.state.saveCodigoPessoa)
      alteraRecibo(
        this.props.selectedRow.id,
        this.state.codigoTipoPessoa,
        this.state.codigoPessoa
      );
  };

  componentDidMount = async () => {
    await this.loadRecibo(this.props.selectedRow.id);
  };

  hasPessoa = () => {
    return !!this.state.codigoPessoa;
  };

  hasRecebido = () => {
    return !this.hasPessoa() && !emptyText(String(this.state.nomeRecebido));
  };

  clearRecebido = () => {
    this.setState({
      codigoPessoa: null,
      codigoTipoPessoa: null,
      nomeRecebido: '',
      cpfcnpjRecebido: ''
    });
  };

  searchRecebido = () => {
    searchCliente(this, this.state.nomeRecebido, (pessoa, message) => {
      if (pessoa)
        this.setState({
          codigoPessoa: pessoa.codigo,
          codigoTipoPessoa: Classificacao.Cliente, // TODO: pessoa.tipo.codigo, // Não é tipo (PF/PJ), é classificação (o que não faz sentido)
          nomeRecebido: pessoa.nome,
          cpfcnpjRecebido: maskedCnpjCpf(pessoa.cnpjCpf)
        });
      else if (message) showError(this, titulo, message, 'nomeRecebido');
    });
  };

  preview = () => {
    if (this.validate()) {
      this.setState({ previewing: true }, () => {
        this.saveRecibo();
        this.setState({ previewing: false });
        this.props.onClose(true);
      });
    }
  };

  render() {
    return (
      <div>
        <Modal
          className={`${this.props.active ? 'active' : null} modal-filter`}
          onClose={() => this.props.onClose(false)}
        >
          <ModalHeader title={titulo} subtitle={'Emissão do recibo'} />
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <InputText
                  value={this.state.razaoSocial}
                  name="razaoSocial"
                  label="Razão social:"
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <InputText
                  value={this.state.nomeRecebido}
                  name="nomeRecebido"
                  label="Recebemos de:"
                  maxlength={40}
                  icone={
                    this.hasPessoa()
                      ? 'icon-lx-close'
                      : this.hasRecebido()
                      ? 'icon-lx-search'
                      : null
                  }
                  help={
                    this.hasPessoa()
                      ? 'Clique no "x" para limpar o campo'
                      : this.hasRecebido()
                      ? 'Clique na "lupa" para pesquisar'
                      : null
                  }
                  placeholder="Digite o nome parcial ou o CPF/CNPJ do cliente."
                  onIconeClick={() => {
                    if (this.hasPessoa()) {
                      this.clearRecebido();
                      setFocus('nomeRecebido');
                    } else this.searchRecebido();
                  }}
                  onBlur={() => {}}
                  onKeyDown={event => {
                    if (
                      event.keyCode === 13 &&
                      !this.hasPessoa() &&
                      !emptyText(this.state.nomeRecebido)
                    )
                      this.searchRecebido();
                    else if (event.keyCode === 27) this.clearRecebido();
                  }}
                  onChange={!this.hasPessoa() && this.handleInputChange}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <InputText
                  value={this.state.cpfcnpjRecebido}
                  name="cpfcnpjRecebido"
                  label="CPF / CNPJ:"
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-4">
                <InputText
                  value={this.state.valor}
                  name="valor"
                  label="A importância de:"
                  disabled
                />
              </div>
              <div className="col-8">
                <InputText
                  value={this.state.valorPorExtenso}
                  name="valorPorExtenso"
                  label="(Valor por extenso):"
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <InputText
                  value={this.state.descricao}
                  name="descricao"
                  label="Referente a:"
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <InputText
                  value={this.state.observacao}
                  name="observacao"
                  label="Observação:"
                  onChange={this.handleInputChange}
                  placeholder="Digite a observação a ser impressa no recibo."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <InputText
                  value={this.state.cidade}
                  name="cidade"
                  label="Cidade:"
                  disabled
                />
              </div>
              <div className="col-6">
                <InputText
                  value={this.state.data}
                  name="data"
                  label="Data:"
                  disabled
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <ReactToPrint content={() => this.componentRef}>
                <PrintContextConsumer>
                  {({ handlePrint }) => (
                    <Botao
                      title="Imprimir"
                      icon="icon-lx-print"
                      onClick={handlePrint}
                    />
                  )}
                </PrintContextConsumer>
              </ReactToPrint>
              <Preview
                ref={el => (this.componentRef = el)}
                state={this.state}
              />
            </div>
            <Botao
              title="Cancelar"
              secondary
              icon="icon-lx-close"
              onClick={() => {
                this.props.onClose(false);
              }}
            />
          </ModalFooter>
        </Modal>
        {tagAlert(this)}
        {tagSearchPessoa(this)}
      </div>
    );
  }
}

class Preview extends React.Component {
  render() {
    return (
      <table className="table-venda print">
        <tbody>
          <tr>
            <td className="td-center title table-space">
              {this.props.state.razaoSocial}
            </td>
          </tr>
          <tr>
            <td className="td-center table-space">
              <center>
                Recebemos de {this.props.state.nomeRecebido},{' '}
                {labelCpfCnpj(this.props.state.cpfcnpjRecebido)}{' '}
                {this.props.state.cpfcnpjRecebido}, a importância de R${' '}
                {this.props.state.valor} - {this.props.state.valorPorExtenso},
                referente a {this.props.state.descricao}.
              </center>
            </td>
          </tr>
          <tr>
            <td className="td-center table-space">
              {this.props.state.observacao
                ? 'Observação: ' + this.props.state.observacao
                : null}
            </td>
          </tr>
          <tr>
            <td className="td-center table-space">
              <center>Sendo assim, firmo o presente recibo de quitação.</center>
            </td>
          </tr>
          <tr>
            <td className="table-space td-center">
              <table className="table-assinatura ">
                <tbody>
                  <tr>
                    <td>
                      <center>
                        {this.props.state.cidade}, {this.props.state.data}
                      </center>
                    </td>
                  </tr>
                  <tr>
                    <td className="assinatura" />
                  </tr>
                  <tr>
                    <td>
                      <center>Assinatura</center>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

export const showRecibo = (state, onClose) => {
  if (state && state.emitindoRecibo)
    return (
      <ReciboModal
        active={true}
        selectedRow={state.reciboRow}
        onClose={onClose}
      />
    );
};
