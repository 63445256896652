import React, { useState } from "react";
import {
  DataGrid,
  Selection,
  Column,
  Pager,
  Paging,
  Sorting,
  SearchPanel,
  ColumnChooser,
  FilterRow,
  Summary,
  TotalItem
} from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";

import { exportDataGrid } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";

import { jsPDF } from "jspdf";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import { CustomButton } from "../CustomButton/CustomButton";
import { MensagemErro } from "../MensagemErro/MensagemErro";

export const TitulosFinanceiros = ({
  isLoading,
  allInvoice,
  error,
  onFinish
}) => {
  const [totalSum, setTotalSum] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);

  const calculateCustomSummary = (options) => {
    if (options.name === "selectedSum") {
      switch (options.summaryProcess) {
        case "start":
          options.totalValue = 0;
          break;
        case "calculate":
          if (options.component.isRowSelected(options.value.invoiceCode)) {
            options.totalValue += options.value.valor;
          }
          break;
      }
    }
  };

  const exportToPDF = (e) => {
    const doc = new jsPDF();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    doc.text("Títulos financeiros", 15, 10);

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
      autoTableOptions: {
        styles: { fillColor: [255, 255, 255] },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        headerStyles: { textColor: [0, 0, 0], fontStyle: "normal" },
        bodyStyles: { textColor: [0, 0, 0] },
        margin: { top: 20, right: 10, bottom: 10, left: 10 }
      }
    }).then(() => {
      doc.save("titulos_financeiros.pdf");
    });

    e.cancel = true;
  }

  const exportToExcel = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Planilha1");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), "cobrancas_realizadas.xlsx");
      });
    });

  }

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "exportpdf",
          onClick: () => exportToPDF(e),
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "xlsxfile",
          onClick: () => exportToExcel(e),
        }
      },
    );
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    setSelectedRows(selectedRowsData);
    const total = selectedRowsData.reduce((sum, row) => sum + row.valor, 0);
    setTotalSum(total.toFixed(2).replace(".", ","));
  };

  const setLink = () => {
    notify("Título vínculado com sucesso", "success", 2000);

    const selectedCode = selectedRows.map((item) => item.codigo);

    onFinish(selectedCode);
  };

  return (
    <>
      {isLoading &&

        <div className="d-flex justify-content-center align-items-center h-100">
          <LoadIndicator id="large-indicator" height={60} width={60} />
        </div>
      }
      {!isLoading && allInvoice && allInvoice.length > 0 && !error &&
        <div className="d-flex flex-column justify-content-start py-3" style={{ maxHeight: "calc(100% - 100px)" }}>
          <h3>Selecione o título abaixo que deseja vincular</h3>
          <DataGrid
            dataSource={allInvoice}
            key="codigo"
            onSelectionChanged={onSelectionChanged}
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            showBorders={true}
            width="100%"
            height="100%"
            onToolbarPreparing={onToolbarPreparing}
            onCellPrepared={(e) => {
              if (e.rowType === "filter") {
                e.cellElement.style.backgroundColor = "white";
              }
            }}
          >
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <Selection mode="multiple" showCheckBoxesMode="always" />
            <Column dataField="numero" caption="Número do Título" />
            <Column dataField="cliente" caption="Cliente do Título" />
            <Column dataField="status" caption="Status do Título" />
            <Column dataField="valor" caption="Valor do Título" format="R$ #,##0.00" />
            <ColumnChooser enabled={true} mode={"select"} allowSearch={true} title="Escolher colunas" />
            <Paging defaultPageSize={10} />
            <Pager
              visible={true}
              displayMode="full"
            />
            <Sorting mode="multiple" />
            <Summary calculateCustomSummary={calculateCustomSummary}>
              <TotalItem
                name="selectedSum"
                showInColumn="valor"
                displayFormat={`Total: R$ ${totalSum}`}
              />
            </Summary>
          </DataGrid>
          <div className="d-flex justify-content-between">
            <CustomButton
              className="ml-auto"
              icon="chevronnext"
              iconPosition="right"
              duplicateIcon={true}
              onClick={setLink}
              disabled={selectedRows.length === 0}
            >
              Vincular título (s)
            </CustomButton>
          </div>
        </div>
      }
      <MensagemErro
        isShow={error}
        titulo="Os títulos financeiros não puderam ser carregados no momento. Por favor, tente acessá-los novamente mais tarde." mensagem={error}
      />
    </>
  )
}
