import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiltroLista } from './FiltroLista/FiltroLista';
import './GridReservaMercadoria.css';
import styles from './../SolicitacaoReserva.module.scss';
import { setResult } from '../../../../redux/slices/solicitacaoReserva/filtroSolSlice';

export function GridReservaMercadoria() {
  const solicitacoes = useSelector(state => state.filtroSolRes.values.itens || []); // Garante que solicitacoes é sempre um array
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const dispatch = useDispatch();

  const setSearching = (searching) => setIsLoading(searching);
  const setSearchingMessage = (message) => console.log(message); // Adapte para mostrar mensagens ao usuário se necessário

  //Função para alternar a expansão das linhas
  const toggleRow = (index) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter(id => id !== index)
        : [...prevExpandedRows, index]
    );
  };
  return (
    <div className='d-flex flex-column'>
      <div style={{ marginTop: '20px' }} className={`d-flex row ${styles.header}`}>
        <FiltroLista
          setSolicitacoes={(result) => {
            
            dispatch(setResult({ itens: result }));
          }}
          setSearching={setSearching}
          setSearchingMessage={setSearchingMessage}
        />
      </div>
      <div>
        {isLoading && <p>Carregando...</p>}
        {error && <p>{error}</p>}
        <table className="tableSolicitacaoGrid">
          <thead>
            <tr>
              <th></th>
              <th>Status</th>
              <th>Solicitação</th>
              <th>Descrição Item</th>
              <th>Quantidade Item (Barril)</th>
              <th>Quantidade Item (Litros)</th>
              <th>Incluído Por</th>
              <th>Data Inclusão</th>
              <th>Alterado Por</th>
              <th>Data Alteração</th>
            </tr>
          </thead>
          <tbody>
          {solicitacoes.length > 0 ? (
    solicitacoes.map((sol, index) => {
      const totalQuantidade = sol.itens.reduce((acc, item) => acc + item.quantidade, 0);
      const totalLitros = sol.itens.reduce((acc, item) => acc + item.quantidade * item.prodLitros, 0);
      
      return (
       
        <React.Fragment key={index}>
          <tr>
            <td>
            <button onClick={() => toggleRow(index)}>
              {expandedRows.includes(index) ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="26px"
                  height="26px"
                >
                  <path d="M8.71 11.29a1 1 0 0 0 1.42 0L12 9.41l1.88 1.88a1 1 0 1 0 1.42-1.42l-2.59-2.59a1 1 0 0 0-1.42 0L8.71 9.87a1 1 0 0 0 0 1.42z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  width="26px"
                  height="26px"
                >
                  <path d="M9.29 8.71a1 1 0 0 0 0 1.42L12.17 12l-2.88 2.88a1 1 0 0 0 1.42 1.42l3.59-3.59a1 1 0 0 0 0-1.42L10.71 8.71a1 1 0 0 0-1.42 0z" />
                </svg>
              )}
              </button>
            </td>
            <td>{sol.solicitacaoStatusDesc}</td>
            <td>{sol.numeroPedido}</td>
            <td></td>
            <td>{totalQuantidade}</td>
            <td>{totalLitros}</td>
            <td></td>
            <td>{new Date(sol.dataCadastro).toLocaleDateString('pt-BR')}</td>
            <td></td>
            <td>{new Date(sol.dataCadastro).toLocaleDateString('pt-BR')}</td>
           
          </tr>
                  {expandedRows.includes(index) && (
                    sol.itens.map((item, itemIndex) => (
                      <tr key={`${index}-${itemIndex}`}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{item.prodDescricao}</td>
                        <td>{item.quantidade}</td>
                        <td>{item.quantidade * item.prodLitros}</td>
                        <td>{item.criadoPor}</td>
                        <td>{new Date(sol.dataCadastro).toLocaleDateString('pt-BR')}</td>
                        <td>{item.alteradoPor}</td>
                        <td>{new Date(sol.dataCadastro).toLocaleDateString('pt-BR')}</td>
                      </tr>
                     ))
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
  <td colSpan="10" style={{ textAlign: 'center' }}>Sem Dados</td>
</tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
