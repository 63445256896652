import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import { AxiosErrorService } from '../core/axiosError.service';

export class ApiSincronizaTurnoPedidoPagamentoService {
  static instance = null;
  static getInstance() {
    if (!ApiSincronizaTurnoPedidoPagamentoService.instance) {
      ApiSincronizaTurnoPedidoPagamentoService.instance = new ApiSincronizaTurnoPedidoPagamentoService();
    }
    return ApiSincronizaTurnoPedidoPagamentoService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  /**
 * Executa uma solicitação para sincronizar o pagamento de um turno de pedidos.
 * @param {number} pagamentoId O ID do pagamento a ser sincronizado.
 * @returns {Promise<string>} Uma promessa que resolve para a mensagem de resposta do servidor.
 * @throws {AxiosError} Se a solicitação falhar, um erro será lançado.
 */
  execute = async (pagamentoId) => {
    try {
      const response = await this.urlLinxCore.post('/ConComodato/sincroniza-turno-pedido-pagamento', {pagamentoId});
      const msg = response.data;
      return msg;
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  }
}
