import React from 'react';
import styles from './HelpShareButton.module.scss';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { Link } from 'react-router-dom';

const helpShareButton = props => {
  return (
    <div className={`${styles.HelpShareButton} HelpShareButton`}>
      <Tooltip placement="bottomRight" overlay={<span>{props.tooltip}</span>}>
        <Link
          to={props.shareLink}
          type="button"
          className={`${styles.helpButton} helpButton shadow-sm`}
          target="_blank"
          onClick={event => {
            event.preventDefault();
            window.open(props.shareLink);
          }}
        >
          <i className={`${styles.help} help icon icon-lx-question`}></i>
        </Link>
      </Tooltip>
    </div>
  );
};

export default helpShareButton;
