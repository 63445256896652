import { LoadPanel } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { locale } from 'devextreme/localization';
import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import Swal from 'sweetalert2';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { fetchSharedDataInit, selectConsignadoComodatoSharedData, useConsignadoComodatoSharedData } from '../../../redux/slices/consignadoComodato/SharedData.slice';
import { TurnoPedidoService } from '../../../services/turno-pedido/TurnoPedido.service';
import { menus } from '../../../shared/constants/MenuConstants';
import { getDadosLogin, getNomePagina } from '../../../shared/utils/Utils';
import { SessionManager } from '../../../shared/utils/sessionManager';
import { fetchFiltroOsInit, selectFiltroOs, useFiltroOs } from './../../../redux/slices/consignadoComodato/filtroOsSlice';
import { VALUES_OS } from './../consignado-comodato-nova-os/ValuesOsKey';
import styles from './OSsConComodato.module.scss';
import { FiltroLista } from './components/FiltroLista/FiltroLista';
import { HeaderList } from './components/HeaderList';
import { ListaItensOs } from './components/ListaItensOs';
import { ListaOs } from './components/ListaOs';
import { ApiListarOsService } from './usercases/ApiListarOs.service';

export function OSsConComodato() {
  locale('pt-BR');
  const [activeKey, setActiveKey] = useState('comodato');
  const [turnoInicializado, setTurnoInicializado] = useState(false);
  const [osVerificada, setOsVerificada] = useState(false);
  const PATH_MENU = menus.COD_17800;
  const [searching, setSearching] = useState(true);
  const [searchingMessage, setSearchingMessage] = useState('Carregando Pedidos...');
  const { changeValue } = useFiltroOs();
  const { values, initialized: filterInitialized } = useSelector(selectFiltroOs);
  const { updateState } = useConsignadoComodatoSharedData();
  const { initialized: dadosInicializados, loading: sharedLoading, error: sharedError, turnoAtual } = useSelector(selectConsignadoComodatoSharedData);

  const dispatch = useDispatch();
  const history = useHistory();

  const headerFilter = React.createRef(null);

  /**
   * useEffect se existir uma OS simplesmente navegar para outra página
   */
  useEffect(() => {
    if (!values) return;
    const os = SessionManager.getInstance().getEncryptedData(VALUES_OS);
    if (os) {
      history.push('/operacoes/pedidos/novo');
      return true;
    }
    setOsVerificada(true);
  }, [history, values]);
  /**
   * useEffect carrega dados iniciais como origens e meios de pagamento
   */
  useEffect(() => {
    if (!values) return;
    if (!osVerificada) return;
    if (!dadosInicializados && !sharedError && !sharedLoading) {
      dispatch(fetchSharedDataInit());
      setSearching(sharedLoading);
    }
  }, [values, osVerificada, dadosInicializados, sharedError, sharedLoading, dispatch]);

  /**
   * useEffect carrega dados iniciais do turno
   */
  useEffect(() => {
    if (!values) return;
    if (!osVerificada) return;
    if (!dadosInicializados) return;
    if (turnoInicializado) return;
    const carregarDadosIniciaisDoTurno = async () => {
      try {
        const dadosLogin = getDadosLogin();
        if(dadosLogin.login === 'Config'){
          setSearching(false);
          return;
        }
        setSearching(true);
        setSearchingMessage('Carregando dados do turno');
        var turno = await TurnoPedidoService.getInstance().turnoAtual();
        dispatch(updateState({ name: 'turnoAtual', data: turno }));
        setTurnoInicializado(true);
      } catch (error) {
        setSearching(false);
        var resp = await Swal.fire({
          title: 'Falha Carregando dados do turno!',
          html: `${error.message}<br />Deseja tentar carregar novamente?`,
          icon: 'error',
          confirmButtonText: 'Tentar Novamente',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
        });
        if (resp.isConfirmed) {
          await carregarDadosIniciaisDoTurno();
          return;
        } else {
          history.push('/');
          return;
        }
      }
    };
    carregarDadosIniciaisDoTurno();
  }, [values, dadosInicializados, osVerificada, dispatch, turnoInicializado, updateState, history]);

  /**
   * useEffect carrega dados iniciais da lista
   */
  useEffect(() => {
    if (!values) return;
    if (!osVerificada) return;
    if (!dadosInicializados) return;
    if (!turnoInicializado) return;

    var carregarDadosIniciaisDaLista = async () => {
      if (!filterInitialized) dispatch(fetchFiltroOsInit());
      if (values.itens && values.itens.length) {
        setSearching(false);
        return;
      }
      if (values.itens) {
        setSearching(false);
        return;
      }
      try {
        const response = await new ApiListarOsService().execute();
        dispatch(changeValue({ fieldName: 'itens', value: response.itens }));
        dispatch(changeValue({ fieldName: 'paginas', value: response.paginas }));
        setSearching(false);
      } catch (error) {
        setSearching(false);
        await Swal.fire({
          title: 'Falha carregando dados iniciais da lista!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    };
    return carregarDadosIniciaisDaLista();
  }, [osVerificada, values, changeValue, dispatch, dadosInicializados, turnoInicializado, filterInitialized]); // Revise as dependências conforme necessário

  const onChangeGrid = useCallback(() => {
    if (activeKey === 'comodato') setActiveKey('itens');
    else setActiveKey('comodato');
  }, [activeKey]);

  return (
    <LinxPostos className={styles.container} breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={searchingMessage} showPane={true} />
      {values && (
        <>
          <HeaderList headerFilter={headerFilter} setSearchingMessage={setSearchingMessage} setSearching={setSearching} />
          <Card>
            {turnoAtual && (
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <span className="mr-2" title='Identificação do Turno'><i className="fas fa-id-card" title='Identificação do Turno'></i> {turnoAtual.turnoId}</span>
                    <span className="mr-2" title='Hora de abertura do turno'><i className="fas fa-clock"></i> {new Date(turnoAtual.dataAbertura).toLocaleString()}</span>
                    <span className='text-primary'><i className="fas fa-user" title='Usuário que esta operando o turno'></i> {turnoAtual.nomeOperador}</span>
                  </div>
                  <span className="text-success" title='Status do Turno'>TURNO ABERTO</span>
                </div>
              </Card.Header>
            )}
            <Card.Body>
              <FiltroLista ref={headerFilter} activeKey={activeKey} setSearching={setSearching} setSearchingMessage={setSearchingMessage} />
            </Card.Body>
            <Card.Body>
              <button onClick={onChangeGrid} className="btn btn-primary" style={{ position: 'relative', top: '-30px', zIndex: 99999 }}>
                <i className="icon icon-lx-doc"></i> {activeKey === 'comodato' ? 'IR PARA MODO ITENS DO PEDIDO' : 'VOLTAR PARA O MODO PEDIDOS'}
              </button>
              {activeKey === 'comodato' ? (
                <ListaOs history={history} headerFilter={headerFilter} setSearchingMessage={setSearchingMessage} setSearching={setSearching} />
              ) : (
                <ListaItensOs history={history} headerFilter={headerFilter} setSearchingMessage={setSearchingMessage} setSearching={setSearching} />
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </LinxPostos>
  );
}

export default withRouter(OSsConComodato);
