import axios from "axios";

import { TOKEN_PASS_AXIOS } from '../../../../shared/constants/Const';
import { getUrl } from '../../../../shared/utils/Utils';
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getConsultaVendas(
  cnpjEstabelecimento,
  codigosProduto,
  data,
  codigosSetor,
  codigosAlmoxarifado,
  consultaDashBoard = false
) {
  return axios.post(`LxApi/v1/VendasRealizadas/Consulta`, {
    query: {
      cnpjEstabelecimento,
      codigosProduto,
      data,
      codigosSetor,
      codigosAlmoxarifado,
      consultaDashBoard
    }
  });
}

export function getTotaisConsultaVenda(
  cnpjEstabelecimento,
  codigosProduto,
  data,
  codigosSetor,
  codigosAlmoxarifado
) {
  return axios.post(`LxApi/v1/VendasRealizadas/ConsultaTotais`, {
    query: {
      cnpjEstabelecimento,
      codigosProduto,
      data,
      codigosSetor,
      codigosAlmoxarifado
    }
  });
}
