import Tooltip from 'rc-tooltip';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './HelpOptions.module.scss';
import 'rc-tooltip/assets/bootstrap_white.css';

const helpOptions = () => (
  <div className={styles.helpOptions}>
    <Link to="/ajuda">
      <li className={`${styles.navItem} d-none d-sm-block`}>
        <Tooltip placement="bottom" overlay={<span>Ajuda</span>}>
          <span className={`${styles.navLink} active pt-0 pb-0 text-uppercase`}>
            <i className="icon icon-lx-question"></i> Ajuda
          </span>
        </Tooltip>
      </li>
    </Link>
  </div>
);

export default helpOptions;
