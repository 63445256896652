import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import TextToolTip from '../../../components/textToolTip/TextToolTip'
import styles from './RequisicaoLGPD.module.scss';
import { LGPDService } from './RequisicaoLGPD.service';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import ListToolTip from '../../../components/listToolTip/ListToolTip';
import { DateBox, RadioGroup, TextBox } from 'devextreme-react';
import { locale, loadMessages } from 'devextreme/localization';
import ptMessages from '../../../../assets/js/pt.json';
import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina, getNomePaginaAmigavel } from '../../../shared/utils/Utils';
import DateToolTip from '../../../components/dateTooltip/DateToolTip';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import DropDownBoxRender from '../../../components/dropDownBox/DropDownBoxRender';
import ResultPanel from '../../../components/Report/ResultPanel/ResultPanel';
import { confirm } from 'devextreme/ui/dialog';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';

export function RequisicaoLGPD(props) {

    const PATH_MENU = menus.COD_80620;
    let validationErrorMessage = '';
    const [showSearch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [disabledSearch, setDisabledSearch] = useState(false);
    const [resultData, setResultData] = useState([]);
    const [syncLists, setSyncLists] = useState({});
    const [resultFilters, setResultFilters] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState(LGPDService.getFiltros());
    const [tipoFiltro, setTipoFiltro] = useState([]);
    const [tipoSolicitacao, setTipoSolicitacao] = useState([]);
    const [situacoes, setSituacoes] = useState([]);

    const getTipoFiltro = async () => {
        const response = await LGPDService.getTipoFiltro();
        if (response.length) setTipoFiltro(response);
    };

    const getTipoSolicitacao = async () => {
        const response = await LGPDService.getTipoSolicitacao();
        if (response.length) setTipoSolicitacao(response);
    };

    const getSituacoes = async () => {
        const response = await LGPDService.getSituacao();
        if (response.length) setSituacoes(response);
    };

    useEffect(() => {
        getTipoFiltro();
        getTipoSolicitacao();
        getSituacoes();
        clearSyncLists();
        loadMessages(ptMessages);
        locale(navigator.language);
    }, [])


    const clearfilter = (event) => {
        let prop = event.target.id;

        setAppliedFilters(filters => {
            return {
                ...filters,
                [prop]: LGPDService.getFiltros()[prop]
            }
        });

        setSyncLists(lists => {
            return {
                ...lists,
                [prop]: LGPDService.getFiltros()[prop]
            }
        });
    };

    const clearFilters = () => {
        setAppliedFilters(LGPDService.getFiltros());
        clearSyncLists();
    };

    const clearSyncLists = () => {
        let retorno = {};
        for (let prop in LGPDService.getFiltros()) {
            retorno[prop] = LGPDService.getFiltros()[prop];
        }

        setSyncLists(retorno);
    };

    const isValidFilters = (filters) => {
        validationErrorMessage = '';

        if (!filters) {
            validationErrorMessage = 'Informe ao menos um filtro para pesquisa.';
            return false;
        }

        if (moment(filters.dataInicio).isAfter(filters.dataFim)) {
            validationErrorMessage = 'A data final deve ser maior que a data inicial.';
            return false;
        }

        return true;
    }

    const find = async () => {
        setSearching(true);

        if (!isValidFilters(appliedFilters)) {
            notify(validationErrorMessage, 'error', 2000, 'top');
            setSearching(false);
            return;
        }

        setResultFilters({
            dataInicio: appliedFilters.dataInicio,
            dataFim: appliedFilters.dataFim,
            tipoFiltro: appliedFilters.tipoFiltro,
            tipoSolicitacao: appliedFilters.tipoSolicitacao,
            situacao: appliedFilters.situacao,
            filtro: appliedFilters.filtro
        });

        const response = await LGPDService.find(appliedFilters);
        if (response.notificationLevel === 1) {
            if (response.data.length) {
                if (!response.data.length) {
                    notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
                }
                setResultData(response.data);
            } else {
                notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
            }
        } else {
            notify("Informe um periodo válido e tente novamente. " + response.message, 'error', 2000, 'top');
        }
        setSearching(false);
    };

    const editarRequisicao = e => {

        let solicitacao = e.row ? e.row.data : {};

        if (solicitacao.id) {
            props.history.push({
                pathname: '/cadastro/requisicao-LGPD/detalhes',
                state: {
                    data: solicitacao
                }
            });
        }
    }

    const gerarRelatorio = async e => {

        setSearching(true);

        let requisicao = e.row ? e.row.data : {};

        try {
            const response = await LGPDService.Relatorio(requisicao.id);
            setSearching(false);
            window.open(response);
        } catch (e) {
            setSearching(false);
            notify('Erro ao solicitar a impressão do uso de dados. ' + e.message, 'error', 5000);
        }
    }

    const enableReport = e => {
        let req = e.row ? e.row.data : {};
        return req.situacao === 1 || req.situacao === 2
    }

    const enableDelete = e => {
        let req = e.row ? e.row.data : {};
        return req.situacao === 0;
    }

    const enableEdit = e => {
        let req = e.row ? e.row.data : {};
        return req.situacao === 0 || req.situacao === 1;
    }

    const enableConcluir = e => {
        let req = e.row ? e.row.data : {};
        return req.situacao === 1;
    }

    const enableVisualizar = e => {
        let req = e.row ? e.row.data : {};
        return req.situacao === 2 || req.situacao === 3;
    }

    const deletarRequisicao = async e => {

        let result = await confirm(
            `Deseja realmente excluir a requisição?`,
            'Requisição LGPD'
        );

        if (!result) {
            return;
        }

        setSearching(true);
        let solicitacao = e.row ? e.row.data : {};

        if (solicitacao.id) {
            const response = await LGPDService.deletar(solicitacao.id)
            if (response.notificationLevel === 1) {
                find();
                notify('Requisição excluída com sucesso.', 'info', 2000, 'top');
            } else {
                setSearching(false);
                notify('Não foi possível excluir a requisição.', 'error', 2000, 'top');
            }
        }
    }

    const concluirRequisicao = async e => {

        let result = await confirm(
            `Deseja realmente concluir a requisição?`,
            'Requisição LGPD'
        );

        if (!result) {
            return;
        }

        setSearching(true);
        let solicitacao = e.row ? e.row.data : {};

        if (solicitacao.id) {
            const response = await LGPDService.concluir(solicitacao.id)
            if (response.notificationLevel === 1) {
                find();
                notify('Requisição concluída com sucesso.', 'info', 2000, 'top');
            } else {
                setSearching(false);
                notify('Não foi possível concluir a requisição.', 'error', 2000, 'top');
            }
        }
    }

    const columns = [
        { dataField: 'dataSolicitacao', caption: 'Data Requisição', dataType: 'date', width: '130' },
        { dataField: 'dataConclusao', caption: 'Data Conclusão', dataType: 'date', width: '130' },
        { dataField: 'tipoRequerenteDescricao', caption: 'Tipo Requerente', width: '130' },
        { dataField: 'nomeRequerente', caption: 'Nome Requerente' },
        { dataField: 'tipoSolicitacao.descricao', caption: 'Tipo Requisição' },
        { dataField: 'situacaoDescricao', caption: 'Situação', width: '130' },
        { dataField: 'nomeTitular', caption: 'Nome Titular' },
        { dataField: 'documentoTitular', caption: 'CPF Titular' },
        { dataField: 'emailResposta', caption: 'E-mail para resposta' },
        {
            type: 'buttons', caption: 'Ações', fixed: false, width: '90',
            buttons: [
                { hint: 'Visualizar', icon: 'card', visible: enableVisualizar, onClick: editarRequisicao },
                { hint: 'Editar', icon: 'edit', visible: enableEdit, onClick: editarRequisicao },
                { hint: 'Concluir', icon: 'check', visible: enableConcluir, onClick: concluirRequisicao },
                { hint: 'Excluir', icon: 'trash', visible: enableDelete, onClick: deletarRequisicao },
                { hint: 'Imprimir relatório de uso de dados', icon: 'export', visible: enableReport, onClick: gerarRelatorio }
            ]
        }
    ];

    const onValueChanged = ((event) => {
        if (event.element.id === 'dataInicio' && !moment(event.value).isValid()) {
            setDisabledSearch(true);
            notify('Data inicial inválida.', 'error', 2000, 'top');
        } else if (event.element.id === 'dataFim' && !moment(event.value).isValid()) {
            setDisabledSearch(true);
            notify('Data final inválida.', 'error', 2000, 'top');
        } else {
            setAppliedFilters(filters => {
                return {
                    ...filters,
                    [event.element.id]: event.element.id === ('dataInicio' || 'dataFim') && event.value !== null ? new Date(event.value).toISOString() : event.value
                }
            });
            setDisabledSearch(false);
        }

    });

    const resultGrid = () => {

        return (
            <ResultPanel dataSource={resultData}
                columns={columns}
                fileName="RequisiçõesLGPD"
                disabled={searching}
                reportTitle={getNomePaginaAmigavel(PATH_MENU)}
                panelTitle="RESULTADOS">
                <DateToolTip inicial={resultFilters.dataInicio} final={resultFilters.dataFim} />
                <ListToolTip data={resultFilters.tipoSolicitacao} message={'Solicitações informadas'} />
                <ListToolTip data={resultFilters.situacao} message={'Situações informadas'} />
                {resultFilters.tipoFiltro !== null ?
                    <TextToolTip text={`Filtro: ${(resultFilters.tipoFiltro === 0 ? 'Titular' : 'Requerente')}`} />
                    :
                    <>
                    </>
                }
            </ResultPanel>
        )
    }

    const syncSelection = (e, prop) => {
        setSyncLists(lists => {
            return {
                ...lists,
                [prop]: e.value || LGPDService.getFiltros()[prop]
            }
        });

        clearfilter({ target: { id: prop } });
    };

    const onSyncSelection = (e, prop, id) => {
        setSyncLists(lists => {
            return {
                ...lists,
                [prop]: e || LGPDService.getFiltros()[prop]
            }
        });

        setAppliedFilters(filters => {
            return {
                ...filters,
                [prop]: Array.isArray(e) ? e.map(item => { return item[id]; }) : e
            }
        });
    };

    const onSyncTipoSelection = (e) => {
        setSyncLists(lists => {
            return {
                ...lists,
                tipoFiltro: e === 'Titular' ? 0 : 1
            }
        });

        setAppliedFilters(filters => {
            return {
                ...filters,
                tipoFiltro: e === 'Titular' ? 0 : 1
            }
        });
    };

    return (
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <div className={`${styles.requisicoesLGPDFilters} mt-3`}>
                <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={true} texto={"NOVA REQUISIÇÃO"} newURL={"/cadastro/requisicao-LGPD/detalhes"}/>

                <div className="shadow-sm bg-white p-3">
                    <div className={`${showSearch ? styles.search : ''}`}>
                        <div className="container-fluid">
                            <div className={`${styles.filtersrWrapper} row`}>
                                <div className="col-md-12">
                                    <h2>REQUISIÇÕES LGPD</h2>
                                    <hr />

                                    <div className={`${showSearch ? styles.fadeIn : ''} ${styles.searchWrapper}`}>
                                        <div className="row">
                                            <div className={`col-md-2 form-group mb-1`}>
                                                <div className={styles.dateWrapper}>
                                                    <div className={styles.datebox}>
                                                        <span>De</span>
                                                        <DateBox id="dataInicio" type="date"
                                                            onValueChanged={onValueChanged}
                                                            disabled={searching}
                                                            useMaskBehavior={true}
                                                            value={appliedFilters.dataInicio} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-md-2 form-group mb-1`}>
                                                <div className={styles.dateWrapper}>
                                                    <div className={styles.datebox}>
                                                        <span>Até</span>
                                                        <DateBox type="date"
                                                            id="dataFim"
                                                            onValueChanged={onValueChanged}
                                                            disabled={searching}
                                                            useMaskBehavior={true}
                                                            value={appliedFilters.dataFim} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-4 form-group mb-1`}>
                                                <DropDownBoxRender
                                                    title='Tipo de requisição'
                                                    value={syncLists.tipoSolicitacao}
                                                    displayExpr='descricao'
                                                    placeholder="Selecione..."
                                                    dataSource={tipoSolicitacao}
                                                    disabled={searching}
                                                    onValueChanged={e => syncSelection(e, 'tipoSolicitacao')}
                                                    methodThatSets={e => onSyncSelection(e, 'tipoSolicitacao', 'id')} />
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-4 form-group mb-1`}>
                                                <DropDownBoxRender
                                                    title='Situação'
                                                    value={syncLists.situacao}
                                                    displayExpr='descricao'
                                                    placeholder="Selecione..."
                                                    dataSource={situacoes}
                                                    disabled={searching}
                                                    onValueChanged={e => syncSelection(e, 'situacao')}
                                                    methodThatSets={e => onSyncSelection(e, 'situacao', 'codigo')} />
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-2 form-group mb-2`}>
                                                <span>Pesquisar por</span>
                                                <RadioGroup
                                                    items={tipoFiltro}
                                                    defaultValue={tipoFiltro[0]}
                                                    layout="horizontal"
                                                    disabled={searching}
                                                    onValueChanged={e => onSyncTipoSelection(e.value)} />
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-10 form-group mb-2`}>
                                                <span>Nome ou Documento</span>
                                                <TextBox
                                                    value={syncLists.filtro}
                                                    showClearButton={true}
                                                    disabled={(appliedFilters.tipoFiltro !== 0 && appliedFilters.tipoFiltro !== 1) || searching}
                                                    valueChangeEvent="change keypress"
                                                    maxLength={100}
                                                    onValueChanged={event => {
                                                        onSyncSelection(event.value, 'filtro');
                                                    }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <footer className="text-right mt-4">
                                        {/* <button className="btn btn-default"> Salvar relatório </button> */}
                                        <button className="btn btn-light" onClick={clearFilters} disabled={searching}> Limpar filtros </button>
                                        <button className="btn btn-primary" onClick={find} disabled={disabledSearch || searching}>
                                            {' '}
                                            {searching ? (
                                                <>
                                                    <svg className={styles.spinner} viewBox="0 0 50 50">
                                                        <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                        </circle>
                                                    </svg>
              PESQUISANDO...
            </>
                                            ) : (
                                                    `PESQUISAR`
                                                )}
                                        </button>
                                    </footer>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                resultData.length > 0 ? (resultGrid()) : <></>
            }
        </LinxPostos >
    );
}

export default withRouter(RequisicaoLGPD);
