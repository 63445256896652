import Tooltip from 'rc-tooltip';
import React from 'react';
import imgLais from '../../../../../assets/images/linx/LAISONOFF.png';
import styles from './Lais.module.scss';
import 'rc-tooltip/assets/bootstrap_white.css';

const lais = props => {
  return props.show ? (
    <div className={styles.lais}>
      <li className={`${styles.navItem} d-none d-sm-block`}>
        <div className={styles.Lais} onClick={props.onClick}>
          <Tooltip placement="bottom" overlay={<span>On/Off LAIS</span>}>
            <img src={imgLais} alt="Lais On/Off" />
          </Tooltip>
        </div>
      </li>
    </div>
  ) : null;
};

export default lais;
