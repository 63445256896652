import axios from "axios";
import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";
import { getUrl, getCnpj } from "../../../shared/utils/Utils";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getTrocos(somenteAtivos) {
  return axios.post(`LxApi/v1/Troco/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      somenteAtivos
    }
  });
}

export function getTroco(codigoFormaPagamento, codigoTroco, tipoLimite) {
  return axios.get(`LxApi/v1/Troco/Selecionar/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoFormaPagamento,
      codigoTroco,
      tipoLimite
    }
  });
}

export function incluiTroco(
  codigo,
  codigoAntigo,
  codigoFormaPagamento,
  tipoLimite,
  limite,
  inativo
) {
  return axios.post(`LxApi/v1/Troco/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    codigo,
    codigoAntigo,
    codigoFormaPagamento,
    tipoLimite,
    limite,
    inativo
  });
}

export function alteraTroco(
  codigo,
  codigoAntigo,
  codigoFormaPagamento,
  tipoLimite,
  limite,
  inativo,
  rowVersion
) {
  return axios.put(`LxApi/v2/Troco/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigo,
    codigoAntigo,
    codigoFormaPagamento,
    tipoLimite,
    limite,
    inativo,
    rowVersion
  });
}

export function excluiTroco(codigoFormaPagamento, codigoTipoLimite, codigo) {
  return axios.delete(`LxApi/v1/Troco/Excluir/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoFormaPagamento,
      codigoTipoLimite,
      codigo
    }
  });
}

export function montaComboTipoFormaPagamento() {
  return axios.get(`LxApi/v1/Troco/MontarComboTipoFormaPagamento/`, {
    params: {
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function montaComboFormaPagamento() {
  return axios.get(`LxApi/v1/Troco/MontarComboFormaPagamento/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoTipoFormaPagamento: 1
    }
  });
}

export function montaComboTipoLimite() {
  return axios.get(`LxApi/v1/Troco/MontarComboTipoLimite/`);
}
