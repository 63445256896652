import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './POSMFeDetails.module.scss';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, { Selection } from 'devextreme-react/data-grid';
import { DropDownBox, TextBox } from 'devextreme-react';
import { Validator} from 'devextreme-react/validator';
import { ALPHANUMERIC_WITH_SPACE_PATTERN, NUMERIC_PATTERN } from '../../../../shared/constants/Const';
import ptMessages from '../../../../../assets/js/pt.json';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../../shared/utils/Utils';
import { posMFeService } from '../POSMFe.service';
import notify from 'devextreme/ui/notify';
import Footer from "../../../../components/core/footer/Footer";
import validationEngine from 'devextreme/ui/validation_engine';
import { confirm } from 'devextreme/ui/dialog';


export function POSMFeDetails(props) {

    const PATH_MENU = menus.COD_80540;
    const [showSearch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [loadedEquipamentos, setLoadedEquipamentos] = useState(false);
    const [loadedAdquirentes, setLoadedAdquirentes] = useState(false);

    const [descricao, setDescricao] = useState(null);
    const [modelo, setModelo] = useState(null);
    const [numeroSerie, setNumeroSerie] = useState(null);
    const [codEstabelecimento, setCodEstabelecimento] = useState(null);

    const [equipamentos, setEquipamentos] = useState([]);
    const [selectedEquipamento, setSelectedEquipamento] = useState([]);
    const [gridBoxEquipamentoValue, setGridBoxEquipamentoValue] = useState([]);

    const [adquirentes, setAdquirentes] = useState([]);
    const [selectedAdquirente, setSelectedAdquirente] = useState([]);
    const [gridBoxAdquirentesValue, setGridBoxAdquirentesValue] = useState([]);
   
    const [receivedData, setReceivedData] = useState([]);

    const getEquipamentos = async () => {
        const response = await posMFeService.getEquipamentos();
        if (response.length) setEquipamentos(response);
        setLoadedEquipamentos(true);
    };

    const getAdquirentes = async () => {
        const response = await posMFeService.getAdquirentes();
        if (response.length) setAdquirentes(response);
        setLoadedAdquirentes(true);
    };

    useEffect(() => {
        getEquipamentos();
        getAdquirentes();
    }, [])

    useEffect(() => {
        loadMessages(ptMessages);
        locale(navigator.language);
        if (props.location.state) {
            setReceivedData(props.location.state.data);
        }
    }, [props, receivedData]);

    useEffect(() => {
        if (loadedEquipamentos === true && loadedAdquirentes === true) {
            loadContent();
        }

        setTimeout(() => validationEngine.validateGroup());
    }, [loadedEquipamentos, loadedAdquirentes])


    const backToScreen = () => {
        props.history.goBack();
    };

    const cancel = async () => {

        let result = await confirm(
            '<h1>Deseja realmente cancelar a operação?</h1>',
            'Detalhes POS MFE'
        );

        if (result) {
            backToScreen();
        }
    }

    const loadContent = () => {

        if (receivedData && receivedData.id) {
            setDescricao(receivedData.descricao);
            setModelo(receivedData.modelo);
            setNumeroSerie(receivedData.numeroSerie);
            setCodEstabelecimento(receivedData.codigoEstabelecimento);
            setSelectedAdquirente(receivedData.adquirente.cnpjCpf);
            setGridBoxAdquirentesValue([receivedData.adquirente]);
            setSelectedEquipamento(receivedData.equipamento.id);
            setGridBoxEquipamentoValue([receivedData.equipamento]);
        }
    }

    const save = async () => {

        if (!validationEngine.validateGroup().isValid) {
            notify('Os campos obrigatórios devem ser informados.', 'error', 2000, 'top');
        } else {

            if (receivedData && receivedData.id > 0) {

                const response = await posMFeService.update({
                    id: receivedData.id,
                    descricao: descricao,
                    numeroSerie: numeroSerie,
                    modelo: modelo,
                    codigoEstabelecimento: codEstabelecimento,
                    equipamento: { id: selectedEquipamento },
                    adquirente: { CnpjCpf: selectedAdquirente },
                    excluido: false,
                    inativo: false
                });
                if (response.notificationLevel === 1) {
                    if (response.data) {
                        notify('Cadastro alterado com sucesso.', 'info', 2000, 'top');
                        props.history.goBack();
                    } else {
                        notify(response.message, 'error', 2000, 'top');
                        setSearching(false);
                    }
                } else {
                    notify(response.message, 'error', 2000, 'top');
                    setSearching(false);
                }


            } else {

                const response = await posMFeService.insert({
                    descricao: descricao,
                    numeroSerie: numeroSerie,
                    modelo: modelo,
                    codigoEstabelecimento: codEstabelecimento,
                    equipamento: { id: selectedEquipamento },
                    adquirente: { CnpjCpf: selectedAdquirente },
                    excluido: false,
                    inativo: false
                });
                if (response.notificationLevel === 1) {
                    if (response.data) {
                        notify('Cadastro inserido com sucesso.', 'info', 2000, 'top');
                        props.history.goBack();
                    } else {
                        notify(response.message, 'error', 2000, 'top');
                        setSearching(false);
                    }
                } else {
                    notify(response.message, 'error', 2000, 'top');
                    setSearching(false);
                }
            }
        }



    }

    const syncDataGridEquipamentoSelection = (e) => {
        setGridBoxEquipamentoValue(e.value || []);
    };

    const dataGridEquipamento_onSelectionChanged = (e) => {
        // let ids = [];
        // e.selectedRowKeys.forEach(x => {
        //     ids.push(x.id);
        // });

        setGridBoxEquipamentoValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
        setSelectedEquipamento(e.selectedRowKeys[0].id);
    };

    const dataGridEquipamentoRender = () => {
        return (
            <DataGrid
                dataSource={equipamentos}
                columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
                hoverStateEnabled={true}
                showBorders={true}
                selectedRowKeys={gridBoxEquipamentoValue}
                onSelectionChanged={dataGridEquipamento_onSelectionChanged}>
                <Selection mode="single" />
            </DataGrid>
        );
    };

    const syncDataGridAdquirenteSelection = (e) => {
        setGridBoxAdquirentesValue(e.value || []);
    };

    const dataGridAdquirentes_onSelectionChanged = (e) => {
        // let cnpj = [];
        // e.selectedRowKeys.forEach(x => {
        //     cnpj.push(x.cnpjCpf);
        // });

        setGridBoxAdquirentesValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
        setSelectedAdquirente(e.selectedRowKeys[0].cnpjCpf);
    };

    const handleValueChange = (e) => {
        let key = e.event.key;

        let regex = new RegExp(ALPHANUMERIC_WITH_SPACE_PATTERN);
        if (!regex.test(key)) {
            e.event.preventDefault();
            return false;
        }
    }

    const handleValueNumberChange = (e) => {
        let key = e.event.key;

        let regex = new RegExp(NUMERIC_PATTERN);
        if (!regex.test(key)) {
            e.event.preventDefault();
            return false;
        }
    }

    const dataGridAdquirentesRender = () => {
        return (
            <DataGrid
                dataSource={adquirentes}
                columns={[{ dataField: 'nome', caption: 'Descrição' }]}
                hoverStateEnabled={true}
                showBorders={true}
                showSearch={true}
                height={400}
                selectedRowKeys={gridBoxAdquirentesValue}
                onSelectionChanged={dataGridAdquirentes_onSelectionChanged}>
                <Selection mode="single" />
            </DataGrid>
        );
    };

    return (

        <LinxPostos breadcrumb={getNomePagina(PATH_MENU, 'Detalhes')}>
            <div className={`${styles.posMFeDetails} mt-3`}>
                <div className="shadow-sm bg-white p-3">
                    <div className={`${showSearch ? styles.search : ''}`}>
                        <div className="container-fluid">
                            <div className={`${styles.filtersrWrapper} row`}>
                                <div className="col-md-12">
                                    <h2>DETALHES POS MFE</h2>
                                    <hr />

                                    <div className={`${showSearch ? styles.fadeIn : ''} ${styles.searchWrapper}`}>

                                        <div className="row">
                                            <div className={`${styles.dropDownBox} col-md-6 form-group mb-1`}>
                                                <span>Descrição</span>
                                                <TextBox placeholder="Informe a descrição do equipamento"
                                                    showClearButton={true}
                                                    onValueChanged={(e) => setDescricao(e.value.toUpperCase())}
                                                    value={descricao}
                                                    maxLength={50}
                                                    onKeyPress={(e) => handleValueChange(e)}
                                                    disabled={searching} >
                                                    <Validator validationRules={[{ type: 'required' }]}/>
                                                </TextBox>
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-3 form-group mb-1`}>
                                                <span>Equipamento</span>
                                                <DropDownBox
                                                    value={gridBoxEquipamentoValue.map(item => item.descricao)}
                                                    valueExpr="id"
                                                    deferRendering={false}
                                                    displayExpr="descricao"
                                                    placeholder="Selecione..."
                                                    disabled={searching}
                                                    dataSource={equipamentos}
                                                    onValueChanged={syncDataGridEquipamentoSelection}
                                                    contentRender={dataGridEquipamentoRender}>
                                                    <Validator validationRules={[{ type: 'required' }]} />
                                                </DropDownBox>
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-3 form-group mb-1`}>
                                                <span>Modelo</span>
                                                <TextBox placeholder="Informe o modelo do equipamento"
                                                    showClearButton={true}
                                                    onValueChanged={(e) => setModelo(e.value.toUpperCase())}
                                                    value={modelo}
                                                    maxLength={65}
                                                    onKeyPress={(e) => handleValueChange(e)}
                                                    disabled={searching} >
                                                    <Validator validationRules={[{ type: 'required' }]} />
                                                </TextBox>

                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-4 form-group mb-2 mt-2`}>
                                                <span>Número de Série</span>
                                                <TextBox placeholder="Informe o Número de Série"
                                                    showClearButton={true}
                                                    onValueChanged={(e) => setNumeroSerie(e.value.toUpperCase())}
                                                    value={numeroSerie}
                                                    maxLength={12}
                                                    disabled={searching}>
                                                    <Validator validationRules={[{ type: 'required' }]} />
                                                </TextBox>

                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-4 form-group mb-2 mt-2`}>
                                                <span>Código do estabelecimento</span>
                                                <TextBox placeholder="Informe o Código do estabelecimento"
                                                    showClearButton={true}
                                                    onValueChanged={(e) => setCodEstabelecimento(e.value)}
                                                    value={codEstabelecimento}
                                                    maxLength={65}
                                                    onKeyPress={(e) => handleValueNumberChange(e)}
                                                    disabled={searching} >
                                                    <Validator validationRules={[{ type: 'required' }]} />
                                                </TextBox>
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-4 form-group mb-2 mt-2`}>
                                                <span>Adquirente</span>
                                                <DropDownBox
                                                    value={gridBoxAdquirentesValue.map(item => item.nome)}
                                                    valueExpr="adquirente"
                                                    deferRendering={false}
                                                    displayExpr="nome"
                                                    placeholder="Selecione..."
                                                    disabled={searching}
                                                    dataSource={adquirentes}
                                                    onValueChanged={syncDataGridAdquirenteSelection}
                                                    contentRender={dataGridAdquirentesRender}>
                                                    <Validator validationRules={[{ type: 'required' }]} />
                                                </DropDownBox>

                                            </div>
                                        </div>

                                        <Footer saveAction={save} cancelAction={cancel} />
                                    </div>

                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>
        </LinxPostos >
    );
}

export default withRouter(POSMFeDetails);