import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'react-bootstrap';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';

function DropBoxOrigens({isVTex}) {
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const origens = useSelector(selectConsignadoComodatoSharedData).origens;
  const dispatch = useDispatch();

  const handlerOrigem = useCallback(
    e => {
      const it = origens.find(x => x.id === parseInt(e.target.value));
      dispatch(changeValue({ fieldName: 'origem', value: it }));
    },
    [dispatch, changeValue, origens],
  );

  return (
    <>
      <Form.Label htmlFor="origem" className="text-primary">
        {'Origem'}
        <span className="text-danger">*</span>
      </Form.Label>
      <select
        onBlur={e => dispatch(changeBlur({ fieldName: 'origem' }))}
        disabled={isVTex}
        onChange={handlerOrigem}
        value={((values || {}).origem || {}).id || ''}
        id="origem"
        name="origem"
        className="form-control"
      >
        {(origens || []).map((origem, index) => (
          <option key={index} value={origem.id}>
            {origem.nome}
          </option>
        ))}
      </select>
      {(touched || {}).origem && (errors || {}).origem && <div className="text-danger validationError">{(errors || {}).origem || ''}</div>}
    </>
  );
}

export default DropBoxOrigens;
