import axios from "axios";
import { getUrl, getCnpj } from "../../../shared/utils/Utils";
import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";

axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getListAlmoxarifado() {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v2/Almoxarifado/Listar/`, {
    params: { cnpjEstabelecimento: getCnpj() }
  });
}

export function getAlmoxarifado(codigoAlmoxarifado) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v2/Almoxarifado/Selecionar`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoAlmoxarifado
    }
  });
}

export function incluirAlmoxarifado(
  numeroAlmoxarifado,
  descricao,
  tipo,
  inativo
) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v2/Almoxarifado/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    numeroAlmoxarifado,
    descricao,
    tipo,
    inativo
  });
}

export function alterarAlmoxarifado(
  codigoAlmoxarifado,
  numeroAlmoxarifado,
  descricao,
  tipo,
  inativo,
  rowVersion
) {
  axios.defaults.baseURL = getUrl();
  return axios.put(`LxApi/v2/Almoxarifado/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigoAlmoxarifado,
    numeroAlmoxarifado,
    descricao,
    tipo,
    inativo,
    rowVersion
  });
}

export function excluirAlmoxarifado(codigoAlmoxarifado) {
  axios.defaults.baseURL = getUrl();
  return axios.delete(`LxApi/v2/Almoxarifado/Excluir`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoAlmoxarifado
    }
  });
}

export function restaurarAlmoxarifado(codigoAlmoxarifado) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v2/Almoxarifado/Restaurar`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoAlmoxarifado
    }
  });
}

export function montaComboAlmoxarifado(cnpjEstabelecimento, tipos) {
  return axios.get(`LxApi/v2/Almoxarifado/MontarCombo`, {
    params: { cnpjEstabelecimento, tipos }
  });
}
