import axios from 'axios';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';
import {
  TIPO_SITE
} from '../../../shared/constants/TipoSiteConstant';

import { getUrl } from '../../../shared/utils/Utils';
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function alteraSenha(email, senhaAtual, novaSenha) {
  axios.defaults.baseURL = BASE_URL_GATEWAY;
  try {
    return axios.put('usuarios/api/Usuario/change-password', {
      email,
      senhaAtual,
      novaSenha
    });
  } finally {
    axios.defaults.baseURL = getUrl();
  }
}

export function getEmailUsuarioRetaguarda(usuario, urlOrigem) {
  axios.defaults.baseURL = urlOrigem;
  try {
    return axios.get(`/LxApi/v1/Funcionarios/ObtemEmail/` + usuario)
    .then(resp => { 
      if(resp.data.result) 
          return { response: true, value: resp.data.notifications[0].message};
        else
          return { response: false, value: resp.data.notifications[0].message};
     })
  }
  catch(e)
  {
    return { response: false, value: 'Não foi possível obter informações do e-mail do usuário'};
  }
  finally {
    axios.defaults.baseURL = getUrl();
  }
}

export async function solicitaTrocaSenha(usuario, url, stateUrlOrigem) {
 
  try 
  {
    let emailResponse = await getEmailUsuarioRetaguarda(usuario, stateUrlOrigem);

    if(!emailResponse.response)
      return emailResponse;

    axios.defaults.baseURL = BASE_URL_GATEWAY;
    return axios.put(`usuarios/api/Usuario/request-reset`, {
      email: emailResponse.value,
      usuario, 
      url,
      tipoSite: TIPO_SITE,
      URLOrigem: stateUrlOrigem 
    }).then(resp => {
      return {response: resp.data.success, value: resp.data.message }
    } );
  } finally {
    axios.defaults.baseURL = getUrl();
  }
}

export function ativaUsuario(chave, senha) {
  axios.defaults.baseURL = BASE_URL_GATEWAY;
  try {
    return axios.put('usuarios/api/Usuario/activate', {
      chave,
      senha
    });
  } finally {
    axios.defaults.baseURL = getUrl();
  }
}

export function ativacaoCompletaUsuario(email, senha, chave, urlBase = '') {
  const currentAxiosBaseURL = axios.defaults.baseURL;
  axios.defaults.baseURL = urlBase;
  try {
    return axios.put('LxApi/v2/Usuario/Ativar', { email, senha, chave });
  } finally {
    axios.defaults.baseURL = currentAxiosBaseURL;
  }
}

export function executaTrocaSenha(chave, novaSenha) {
  axios.defaults.baseURL = BASE_URL_GATEWAY;
  try {
    return axios.put('usuarios/api/Usuario/reset-password', {
      chave,
      senha: novaSenha
    });
  } finally {
    axios.defaults.baseURL = getUrl();
  }
}

export function reenviaEmail(email, url, acao) {
  axios.defaults.baseURL = BASE_URL_GATEWAY;
  //axios.defaults.baseURL = 'http://localhost:61164';

  //Ações -> Ativar ou Redefinir
  //precisa começar com letra maiúscula!

  try {
    return axios.put(`usuarios/api/Usuario/resend`, {
      email,
      url,
      acao,
      tipoSite: TIPO_SITE
    });
  } finally {
    axios.defaults.baseURL = getUrl();
  }
}

export function salvaSenhaPostoPop(email, senha, url) {
  const baseUrl = axios.defaults.baseURL;
  axios.defaults.baseURL = url;
  try {
    return axios.put(`LxApi/v1/Usuario/SalvarSenha`, {
      email,
      senha,
      jaCriptografada: false
    });
  } finally {
    axios.defaults.baseURL = baseUrl;
  }
}

export function marcaUsuarioComoAtivado(email, url) {
  const baseUrl = axios.defaults.baseURL;
  axios.defaults.baseURL = url;
  try {
    return axios.put(`LxApi/v1/Usuario/Ativar`, {
      email
    });
  } finally {
    axios.defaults.baseURL = baseUrl;
  }
}

export function buscaUrl(cnpj) {
  var tipo = TIPO_SITE;

  axios.defaults.baseURL = BASE_URL_GATEWAY + '/empresas/api/empresa/';

  return axios.get(`${cnpj}/url/${tipo}`, {
    cnpj,
    tipo
  });
}

export function buscaListaUrl(listaCnpj) {
  axios.defaults.baseURL = BASE_URL_GATEWAY + '/empresas/api/empresa/';
  return axios.post('', listaCnpj);
}
