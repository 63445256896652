import { BlipChat } from 'blip-chat-widget';
import { APP_KEY } from '../shared/constants/LaisConstant';
import { getSystemInfoByTipoSiteLogged  } from '../shared/utils/Utils';

let blipChat = null;
const systemInfo = getSystemInfoByTipoSiteLogged();

export const lais = {
  Iniciar,
  Finalizar
};

function Iniciar({ user, cnpj, name }) {
  blipChat = new BlipChat()
    .withAppKey(APP_KEY)
    .withAuth({
      authType: BlipChat.DEV_AUTH,
      userIdentity: `${user}-${cnpj}`, // Email + cnpj
      userPassword: 'posto-pop'
    })
    .withAccount({
      fullName: name, //Nome do usuario logado
      email: user, //email do usuario logado
      extras: {
        platform: 'web',
        auth: 'true',
        document: cnpj, //CNPJ do cliente após logar
        produto: systemInfo.nome, // Nome do Produto
        //dinamico, passar qualquer coisa
      }
    })
    .withButton({
      color: 'transparent'
    });

  blipChat.build();
}

function Finalizar() {
  if (blipChat) {
    try {
      blipChat.destroy();
    } catch {}
  }
}
