import React, { Component } from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { withRouter } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from '../../../components/modal/Modal';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  InputText,
  InputDate,
  Select,
  handleInputChange
} from '../../../components/formulario/Formulario';
import Botao from '../../../components/botao/Botao';
import {
  showInfo,
  showWarning,
  showQuestion,
  showError,
  hideAlert,
  tagAlert,
  showWait,
  hideWait,
  showWaitCancel
} from '../../../components/alert/Alert';
import {
  getCombustiveisPeriodo,
  getParametrosLMC,
  getLMC,
  getLMCRecebidos,
  updateLMCRecebidos,
  getTanqueList,
  viewLMC
} from './LMC.service';
import {
  emptyText,
  emptyDate,
  emptySelect,
  makeList,
  none,
  sameString,
  formatNumber,
  validaCNPJ,
  maskedCnpj,
  validaCPF,
  maskedCpf,
  getNomePagina,
  validaDadosLogin
} from '../../../shared/utils/Utils';
import { changeInList } from '../../../shared/utils/TableUtils';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import Footer from '../../../components/core/footer/Footer';

import { menus } from '../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60740;

const ViewMode = {
  None: 0,
  Termos: 1,
  Movimento: 2,
  All: 3
};

const ScreenMode = {
  Parametrizing: 0,
  Generating: 1,
  Viewing: 2,
  Changing: 3
};
// eslint-disable-next-line
const ScreenModeCaption = [
  'Parâmetros para geração',
  'Aguarde, gerando o LMC...',
  'Movimento de combustíveis',
  'Ajustes do movimento de combustíveis recebidos'
];

let lmcCount = 0;

const screenUrl = '/lmc/';
const titulo = 'LMC';

class CombustivelModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      idLMC: this.props.idLMC,
      recebidos: [],
      observacao: null,
      lists: { tanques: [] },
      possuiDados: false
    };
  }

  stateToApi = state => {
    let recebidos = [];
    state.recebidos.forEach((r, i) => {
      if (!(i === state.recebidos.length - 1 && emptySelect(r.codigoTanque)))
        recebidos.push({
          idLMCRecebido: r.idLMCRecebido,
          data: r.data,
          codigoTanque: r.codigoTanque,
          capacidade: r.capacidade,
          volume: r.volume,
          numeroNota: r.numeroNota
        });
    });

    return {
      idLMC: this.state.idLMC,
      observacao: this.state.observacao === '' ? null : this.state.observacao,
      recebidos
    };
  };

  apiToState = data => {
    let idLMC = this.props.selectedRow.idLMC,
      observacao = data ? data.observacao : '',
      recebidos = data ? data.recebidos : [];

    if (recebidos.length === 0) {
      this.setState({ possuiDados: false });

      recebidos.push({
        idLMCRecebido: null,
        data: this.props.selectedRow.data,
        codigoTanque: none,
        capacidade: null,
        volume: null,
        numeroNota: null
      });
    } else {
      this.setState({ possuiDados: true });
    }

    return {
      idLMC,
      observacao,
      recebidos
    };
  };

  loadRecebidos = async idLMC => {
    try {
      const { data } = await getLMCRecebidos(idLMC);
      this.setState(this.apiToState(data));
    } catch (e) {
      this.setState(this.apiToState(null));
    }
  };

  loadTanques = async (dataMovimento, codigoCombustivel) => {
    const { data } = await getTanqueList(dataMovimento, codigoCombustivel);
    const tanques = [];
    data.forEach(t => {
      tanques.push({
        value: t.codigo,
        label: t.descricao,
        capacidade: t.capacidade
      });
    });
    tanques.unshift({
      value: none,
      label:
        tanques.length === 0
          ? 'Não há nenhum tanque disponível para esse combustível'
          : 'Selecione um tanque',
      capacidade: null
    });
    this.setState({ lists: { ...this.state.lists, tanques } });
  };

  validate = () => {
    let message = '';
    this.state.recebidos.forEach((r, i, a) => {
      if (emptySelect(r.codigoTanque)) {
        if (a.length > 1)
          message = 'Ao inserir um movimento selecione o tanque.';
      } else if (emptyText(r.volume)) {
        message = 'Ao inserir um tanque, insira o volume.';
      } else if (parseFloat(r.volume) > parseFloat(r.capacidade)) {
        message =
          'O volume informado não pode ser superior a capacidade do tanque.';
      } else if (emptyText(r.numeroNota)) {
        message = 'Ao inserir um tanque, insira o número da nota.';
      }
    });
    if (!message) return true;
    showError(this, 'LMC', message);
    return false;
  };

  saveRecebidos = () => {
    updateLMCRecebidos(this.stateToApi(this.state));
  };

  componentDidMount = async () => {
    await this.loadTanques(
      moment(this.props.selectedRow.data).format('YYYY-MM-DD'),
      this.props.selectedRow.produto.value
    );
    await this.loadRecebidos(this.props.selectedRow.idLMC);
  };

  render() {
    return (
      <div>
        <Modal
          className={`${this.props.active ? 'active' : null} modal-filter`}
          onClose={() => this.props.onClose(false)}
        >
          <ModalHeader
            title={
              'Detalhes do combustível ' +
              this.props.selectedRow.combustivel +
              ' em ' +
              moment(this.props.selectedRow.data).format('DD/MM/YYYY')
            }
          />
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <InputText
                  value={this.state.observacao}
                  name="observacao"
                  label="Observações do LMC:"
                  maxlength={1000}
                  help={String(this.state.observacao).length + ' de 1000'}
                  onChange={this.handleInputChange}
                />
              </div>
            </div>
            {this.state.possuiDados ? (
              <ReactTable
                data={this.state.recebidos}
                showPagination={true}
                defaultPageSize={5}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
                columns={[
                  {
                    accessor: 'codigoTanque',
                    Header: 'Tanque',
                    sortable: false,
                    Cell: props => {
                      return (
                        <Select
                          value={props.row.codigoTanque}
                          name="row.codigoTanque"
                          options={this.state.lists.tanques}
                          // help={"◄" + props.row.codigoTanque + "►"}
                          disabled
                          onChange={event => {
                            const capacidade = this.state.lists.tanques.find(
                                t => sameString(t.value, event.target.value)
                              ).capacidade,
                              volume = emptySelect(event.target.value)
                                ? null
                                : props.row.volume,
                              numeroNota = emptySelect(event.target.value)
                                ? null
                                : props.row.numeroNota;
                            changeInList(
                              this,
                              'recebidos',
                              'codigoTanque',
                              event.target.value,
                              props.row._index,
                              { capacidade, volume, numeroNota }
                            );
                          }}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'capacidade',
                    Header: 'Capacidade',
                    sortable: false,
                    width: 120,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.capacidade}
                          name="row.capacidade"
                          type="number"
                          decimalScale={2}
                          disabled
                          onChange={this.handleInputChange}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'volume',
                    Header: 'Volume',
                    sortable: false,
                    width: 120,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.volume}
                          name="row.volume"
                          type="number"
                          decimalScale={2}
                          disabled
                          onChange={event => {
                            if (
                              event.floatValue >
                              parseFloat(props.row.capacidade)
                            )
                              showError(
                                this,
                                'LMC',
                                'O volume não pode ser maior que a capacidade do tanque.'
                              );
                            else
                              changeInList(
                                this,
                                'recebidos',
                                'volume',
                                event.floatValue,
                                props.row._index
                              );
                          }}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'numeroNota',
                    Header: 'Nº Nota fiscal',
                    sortable: false,
                    width: 120,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.numeroNota}
                          name="row.numeroNota"
                          type="number"
                          decimalScale={2}
                          disabled
                          onChange={event =>
                            changeInList(
                              this,
                              'recebidos',
                              'numeroNota',
                              event.floatValue,
                              props.row._index
                            )
                          }
                        />
                      );
                    }
                  }
                  /* Implementação futura irá redirecionar para outra tela
                  ,
                  {
                    width: 75,
                    filterable: false,
                    sortable: false,
                    Cell: props => {
                      if (props.row._index < this.state.recebidos.length - 1)
                        return (
                          <>
                            <Botao
                              ic
                              icon={"icon-lx-trash"}
                              onClick={() => {
                                deleteInList(this, "recebidos", props.row._index);
                              }}
                            />
                          </>
                        );
                      else
                        return (
                          <>
                            <Botao
                              ic
                              icon={"icon-lx-plus"}
                              secondary
                              onClick={() => {
                                appendInList(this, "recebidos", {
                                  idLMCRecebido: null,
                                  data: this.props.row && this.props.row.data,
                                  codigoTanque: none,
                                  capacidade: null,
                                  volume: null,
                                  numeroNota: null
                                });
                              }}
                            />
                          </>
                        );
                    }
                  }*/
                ]}
              />
            ) : (
              <></>
            )}
          </ModalBody>
          <ModalFooter>
            <Botao
              title="Salvar"
              icon="icon-lx-check"
              onClick={() => {
                if (this.validate())
                  showQuestion(
                    this,
                    'LMC',
                    'Deseja salvar as alterações?',
                    null,
                    resp => {
                      hideAlert(this);
                      if (resp) {
                        this.saveRecebidos();
                        let totalRecebido = 0;
                        this.state.recebidos.forEach((r, i, a) => {
                          totalRecebido = totalRecebido + r.volume;
                        });
                        this.props.onClose(true, totalRecebido);
                      }
                    }
                  );
              }}
            />
            <Botao
              title="Cancelar"
              secondary
              icon="icon-lx-close"
              onClick={() =>
                showQuestion(
                  this,
                  'LMC',
                  'Deseja cancelar as alterações?',
                  null,
                  resp => {
                    hideAlert(this);
                    if (resp) this.props.onClose(false);
                  }
                )
              }
            />
          </ModalFooter>
        </Modal>
        {tagAlert(this)}
      </div>
    );
  }
}

export class LMC extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    let state = {
      screenMode: ScreenMode.Parametrizing,
      dataInicial: null,
      dataFinal: null,
      combustiveis: [],
      distribuidora: null,
      numeroOrdem: null,
      movimento: [],
      lists: {
        tanques: [],
        combustiveis: []
      },
      selectedRow: null,
      lmc: null,
      previewMode: ViewMode.None,

      termo: {}
    };
    this.state = { ...state };
  }

  isParametrizing = () => {
    return this.state.screenMode === ScreenMode.Parametrizing;
  };

  isGenerating = () => {
    return this.state.screenMode === ScreenMode.Generating;
  };

  isViewing = () => {
    return this.state.screenMode === ScreenMode.Viewing;
  };

  isChanging = () => {
    return this.state.screenMode === ScreenMode.Changing;
  };

  componentDidMount = () => {
    this.loadParametros();
  };

  loadParametros = async () => {
    const { data: parametros } = await getParametrosLMC();
    const combustiveis = [],
      dataFinal = moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD');
    const dataInicial = moment(dataFinal)
      .startOf('month')
      .format('YYYY-MM-DD');
    this.loadCombustiveis(dataInicial, dataFinal);
    this.setState({
      dataInicial,
      dataFinal,
      combustiveis,
      distribuidora: parametros.distribuidora,
      numeroOrdem: parametros.numeroOrdem,
      movimento: []
    });
  };

  loadCombustiveis = async (dataInicial, dataFinal) => {
    this.setState({ combustiveis: [], objComb: [] });
    this.forceUpdate();
    let lists = this.state.lists;
    dataInicial = moment(dataInicial).format('YYYY-MM-DD 00:00:00');
    dataFinal = moment(dataFinal).format('YYYY-MM-DD 23:59:59');

    const { data } = await getCombustiveisPeriodo(dataInicial, dataFinal);
    lists.combustiveis = makeList(data, null, false);
    this.setState({ lists });
  };

  clearCombustiveis = () => {
    let lists = this.state.lists;
    lists.combustiveis = [];
    this.setState({ lists });
  };

  mountCombustiveis = (dataInicial, dataFinal) => {
    if (
      dataInicial &&
      dataFinal &&
      moment(dataInicial).isSameOrBefore(moment(dataFinal))
    )
      this.loadCombustiveis(dataInicial, dataFinal);
    else this.clearCombustiveis();
  };

  validate = rebuild => {
    let message = '',
      focus = '';
    if (emptyDate(this.state.dataInicial)) {
      message = 'Informe a data inicial.';
      focus = 'dataInicial';
    } else if (
      moment(this.state.dataInicial).isSameOrAfter(moment().startOf('day'))
    ) {
      message = 'A data inicial deve ser inferior a hoje.';
      focus = 'dataInicial';
    } else if (emptyDate(this.state.dataFinal)) {
      message = 'Informe a data final.';
      focus = 'dataFinal';
    } else if (
      moment(this.state.dataFinal).isSameOrAfter(moment().startOf('day'))
    ) {
      message = 'A data final deve ser inferior a hoje.';
      focus = 'dataFinal';
    } else if (
      moment(this.state.dataFinal).isBefore(moment(this.state.dataInicial))
    ) {
      message = 'A data final não pode ser inferior a data inicial.';
      focus = 'dataFinal';
    } else if (
      moment(this.state.dataFinal).get('month') !==
      moment(this.state.dataInicial).get('month')
    ) {
      message = 'A data final final deve estar no mesmo mês da data inicial.';
      focus = 'dataFinal';
    } else if (this.state.lists.combustiveis.length === 0) {
      message = 'Não há nenhum movimento de combustíveis no período informado.';
      focus = 'dataFinal';
    } else if (emptySelect(this.state.combustiveis)) {
      message = 'Selecione os combustíveis.';
      focus = 'combustiveis';
    } else if (emptyText(this.state.distribuidora)) {
      message = 'Informe a distribuidora';
      focus = 'distribuidora';
    } else if (rebuild && emptyText(this.state.numeroOrdem)) {
      message = 'Informe o número de ordem.';
      focus = 'numeroOrdem';
    } else if (rebuild && parseInt(this.state.numeroOrdem) === 0) {
      message = 'O número de ordem não pode ser zero.';
      focus = 'numeroOrdem';
    } else if (parseInt(this.state.numeroOrdem) > 999999999999) {
      message = 'O número de ordem não pode ser superior a 999.999.999.999';
      focus = 'numeroOrdem';
    }
    if (message) {
      showError(this, 'LMC', message, focus);
      return false;
    }
    return true;
    // if (moment(this.state.dataFinal).diff(moment(this.state.dataInicial), "days") <= 90)
    //   return true;
    // showQuestion(this, "LMC", "O período selecionado representa mais de 90 dias. Deseja realmente gerar o LMC?", null,
    // resp => {
    //   if (resp)
    // }
  };

  getLMC = async rebuild => {
    let thisCount = ++lmcCount;
    this.setState({
      screenMode: ScreenMode.Generating,
      movimento: [],
      lmcCount
    });
    showWaitCancel(
      this,
      titulo,
      `Por favor, aguarde enquanto o LMC é ${rebuild ? 'gerado' : 'aberto'}.`,
      null,
      async () => {
        this.cancelGeneration();
        hideWait(this);
      }
    );
    try {
      const api = await getLMC(
        rebuild,
        this.state.dataInicial,
        this.state.dataFinal,
        this.state.combustiveis,
        this.state.distribuidora,
        this.state.numeroOrdem
      );
      if (
        thisCount !== lmcCount ||
        this.state.screenMode !== ScreenMode.Generating
      ) {
        hideWait(this);
        return;
      }

      const movimento = api.data.produtos.map(p => {
        return {
          screenMode: ScreenMode.Viewing,
          idLMC: p.idLMC,
          data: p.data,
          produto: { value: p.produto.codigo, label: p.produto.descricao },
          combustivel: p.produto.codigo + ' - ' + p.produto.descricao,
          estoqueAbertura: p.valores.estoqueAbertura,
          totalRecebido: p.valores.totalRecebido,
          vendaDia: p.valores.vendaDia,
          estoqueEscritural: p.valores.estoqueEscritural,
          estoqueFechamento: p.valores.estoqueFechamento,
          perdaSobra: p.valores.perdaSobra
        };
      });

      this.setState({ screenMode: ScreenMode.Viewing, movimento });

      if (this.state.movimento.length === 0) {
        showInfo(
          this,
          titulo,
          'Não existem registros de LMC para o período informado.',
          null,
          () => {
            hideAlert(this, this.props.history, screenUrl);
          }
        );
      }

      hideWait(this);
    } catch (e) {
      if (e && e.response && e.response.data && e.response.data.message)
        showError(
          this,
          'LMC',
          'Mensagem do servidor: ' + e.response.data.message
        );
      else showError(this, 'LMC', 'Ocorreu um erro na geração do LMC.');
      this.setState({ screenMode: ScreenMode.Parametrizing, movimento: [] });
    }
  };

  geraLmcClick = () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    if (this.validate(true)) {
      this.getLMC(true);
    }
  };

  abreLmcClick = () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    if (this.validate(false)) {
      this.getLMC(false);
    }
  };

  cancelGeneration = () => {
    this.setState({ screenMode: ScreenMode.Parametrizing, movimento: [] });
  };

  backParams = () => {
    this.setState({ screenMode: ScreenMode.Parametrizing, movimento: [] });
    // showQuestion(this, "LMC", "Deseja realmente voltar aos parâmetros?",
    //   null, resp => {
    //     if (resp)
    //       this.setState({ screenMode: ScreenMode.Parametrizing, movimento: [] });
    //     hideAlert(this);
    //   });
  };

  adjustLMC = lmc => {
    const result = lmc;
    if (validaCNPJ(lmc.empresa.cnpj))
      lmc.empresa.cnpj = maskedCnpj(lmc.empresa.cnpj);
    if (lmc.empresa.representante && validaCPF(lmc.empresa.representante.cpf))
      lmc.empresa.representante.cpf = maskedCpf(lmc.empresa.representante.cpf);

    result.produtos.forEach(p => {
      for (let a = p.estoqueAbertura.length; a < 6; a++) {
        p.estoqueAbertura.push({
          numeroTanque: '',
          volume: ''
        });
      }
      for (let v = p.vendasDia.length; v < 13; v++) {
        p.vendasDia.push({
          numeroTanque: '',
          numeroBico: '',
          valorFechamento: '',
          valorAbertura: '',
          valorAfericao: '',
          valorVendaBico: ''
        });
      }
      for (let r = p.recebimentos.length; r < 5; r++) {
        p.recebimentos.push({
          documento: '',
          numeroTanque: '',
          volume: ''
        });
      }
      for (let c = p.conciliacoes.length; c < 6; c++) {
        p.conciliacoes.push({
          numeroTanque: '',
          volume: null
        });
      }
    });
    return result;
  };

  previewLMC = async previewMode => {
    try {
      showWait(this, titulo, `Por favor, aguarde enquanto a visualização é gerada.`);
      const { data } = await viewLMC(
        previewMode,
        this.state.dataInicial,
        this.state.dataFinal,
        this.state.combustiveis,
        this.state.distribuidora,
        this.state.numeroOrdem
      );
      hideWait(this);
      this.setState(
        {
          lmc: this.adjustLMC(data),
          previewMode
        },
        () => {
          window.print();
          this.setState({ previewMode: ViewMode.None });
        }
      );
    }
    catch(e) {
      if (e && e.response && e.response.data && e.response.data.message)
        showError(this,'LMC', e.response.data.message);
      else 
        showError(this, 'LMC', 'Ocorreu um erro na geração do LMC.');
    }
  };

  hasTermo = () => {
    return (
      this.state.previewMode === ViewMode.Termos ||
      this.state.previewMode === ViewMode.All
    );
  };

  hasMovimento = () => {
    return (
      this.state.previewMode === ViewMode.Movimento ||
      this.state.previewMode === ViewMode.All
    );
  };

  openCombustivelModal = selectedRow => {
    this.setState({
      screenMode: ScreenMode.Changing,
      selectedRow
    });
  };

  closeCombustivelModal = (resp, totalRecebido) => {
    const selectedRow = this.state.selectedRow;
    if (resp) selectedRow.totalRecebido = totalRecebido;
    this.setState({ screenMode: ScreenMode.Viewing, selectedRow });
    if (resp) showWarning(this, 'LMC', 'Dados atualizados');
  };

  handleTableClick = (state, rowInfo, column, instance, e) => {
    alert('handleTableClick');
    if (rowInfo) this.openCombustivelModal(rowInfo.original.idLMC);
  };

  render() {
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <main className="main">
            <section className="section-container">
              <SectionContainer>
                <SectionContent
                  title={
                    'Período para geração / ' +
                    ScreenModeCaption[this.state.screenMode]
                  }
                >
                  <div className="row">
                    <div className="col-4">
                      <InputDate
                        value={this.state.dataInicial}
                        name={'dataInicial'}
                        label="Data inicial:"
                        disabled={!this.isParametrizing()}
                        onChange={event => {
                          this.handleInputChange(event);
                          this.loadCombustiveis(
                            event.date ? event.date.format('YYYY-MM-DD') : null,
                            this.state.dataFinal
                          );
                        }}
                        onBlur={this.combustiveisBlur}
                      />
                    </div>
                    <div className="col-4">
                      <InputDate
                        value={this.state.dataFinal}
                        name="dataFinal"
                        label="Data final:"
                        disabled={!this.isParametrizing()}
                        onChange={event => {
                          this.handleInputChange(event);
                          this.loadCombustiveis(
                            this.state.dataInicial,
                            event.date ? event.date.format('YYYY-MM-DD') : null
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Select
                        multi
                        value={this.state.objComb}
                        originalValue="objComb"
                        name="combustiveis"
                        label={
                          'Produtos com movimento no período - ' +
                          'selecione os combustíveis para geração'
                        }
                        disabled={!this.isParametrizing()}
                        options={this.state.lists.combustiveis}
                        //onChange={this.handleInputChange}
                        sender={this}
                      />
                    </div>
                  </div>
                </SectionContent>
                <SectionContent
                  title="Totais recebidos por dia e por combustível"
                  accordion
                  visible={this.isViewing() || this.isChanging()}
                >
                  <ReactTable
                    data={this.state.movimento}
                    showPagination={true}
                    defaultPageSize={5}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando registros..."
                    noDataText="Sem registros para exibir"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    getTdProps={(state, rowInfo, column, instance) => {
                      return {
                        onClick: (e, handleOriginal) => {
                          //if (this.props.onClick)
                          //this.props.onClick(state, rowInfo, column, instance, e);
                          if (rowInfo)
                            this.openCombustivelModal(rowInfo.original);

                          //this.handleTableClick(state, rowInfo, column, instance, e);
                          if (handleOriginal) handleOriginal();
                        }
                      };
                    }}
                    columns={[
                      {
                        accessor: 'data',
                        Header: 'Data',
                        sortable: true,
                        width: 150,
                        Cell: props => (
                          <div>{moment(props.value).format('DD/MM/YYYY')}</div>
                        )
                      },
                      {
                        accessor: 'combustivel',
                        Header: 'Combustível',
                        sortable: true
                      },
                      {
                        accessor: 'estoqueAbertura',
                        Header: 'Estoque abertura',
                        sortable: false,
                        width: 150
                      },
                      {
                        accessor: 'totalRecebido',
                        Header: 'Total recebido',
                        sortable: false,
                        width: 150
                      },
                      {
                        accessor: 'vendaDia',
                        Header: 'Vendas dia',
                        sortable: false,
                        width: 150
                      },
                      {
                        accessor: 'estoqueEscritural',
                        Header: 'Estoque escritural',
                        sortable: false,
                        width: 150
                      },
                      {
                        accessor: 'estoqueFechamento',
                        Header: 'Estoque fechamento',
                        sortable: false,
                        width: 150
                      },
                      {
                        accessor: 'perdaSobra',
                        Header: 'Perdas/Sobras',
                        sortable: false,
                        width: 150,
                        getProps: (state, rowInfo, column) => {
                          let destacarValor =
                            rowInfo &&
                            (Math.abs(rowInfo.row.perdaSobra) /
                              Math.abs(rowInfo.row.estoqueEscritural)) *
                              100 >
                              0.6;
                          return {
                            style: {
                              color: destacarValor ? 'red' : null,
                              fontWeight: destacarValor ? 'bold' : null
                            }
                          };
                        }
                      }
                    ]}
                  />
                </SectionContent>
                <SectionContent
                  title="Parâmetros para termos de abertura e fechamento do LMC"
                  accordion
                >
                  <div className="row">
                    <div className="col-4">
                      <InputText
                        value={this.state.distribuidora}
                        name="distribuidora"
                        label="Distribuidora:"
                        // help={"combustíveis ◄" + this.state.combustiveis + "►   movimentos ◄" + this.state.movimento.length + "►   screenMode: ◄" + this.state.screenMode + "►"}
                        disabled={!this.isParametrizing()}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div className="col-4">
                      <InputText
                        value={this.state.numeroOrdem}
                        name="numeroOrdem"
                        label="Número de ordem:"
                        type="number"
                        decimalScale={0}
                        maxlength={12}
                        disabled={!this.isParametrizing()}
                        onChange={this.handleInputChange}
                      />
                    </div>
                  </div>
                </SectionContent>
              </SectionContainer>
            </section>
          </main>

          {this.hasTermo() && this.state.lmc.termos.map(termo => {
            return (
              <>
                <TermoLMC
                  abertura
                  termo={termo}
                  empresa={this.state.lmc.empresa}
                  endereco={this.state.lmc.empresa.endereco}
                  representante={this.state.lmc.empresa.representante}
                />
                {this.hasMovimento() && (
                  <Movimentos
                    paginas={this.state.lmc.produtos.filter(p => p.codigoTermo === termo.codigoTermo )}
                    empresa={this.state.lmc.empresa}
                    endereco={this.state.lmc.empresa.endereco}
                  />
                )}
                <TermoLMC
                  termo={termo}
                  empresa={this.state.lmc.empresa}
                  endereco={this.state.lmc.empresa.endereco}
                  representante={this.state.lmc.empresa.representante}
                />
              </>
            );
          })}
          {!this.hasTermo() && this.hasMovimento() && (
            <Movimentos
              paginas={this.state.lmc.produtos}
              empresa={this.state.lmc.empresa}
              endereco={this.state.lmc.empresa.endereco}
            />
          )}
          {this.isChanging() && (
            <CombustivelModal
              active={this.isChanging()}
              selectedRow={this.state.selectedRow}
              onClose={this.closeCombustivelModal}
            />
          )}
        </LinxPostos>
        <Footer customList={this.tagButtons()} />

        {tagAlert(this)}
      </>
    );
  }

  tagButtons = () => {
    const customList = [];

    const btnGerar = {
      name: 'Gerar o LMC',
      icon: 'icon-lx-new',
      class: '',
      action: this.geraLmcClick
    };

    const btnAbrir = {
      name: 'Abrir o LMC',
      icon: 'icon-lx-search',
      class: '',
      action: this.abreLmcClick
    };

    const btnResetar = {
      name: 'Resetar os parâmetros',
      icon: 'icon-lx-close',
      class: 'secondary',
      action: this.loadParametros
    };

    const btnVisualizarCompleto = {
      name: 'Visualizar Completo',
      icon: 'icon-lx-print',
      class: 'secondary',
      action: () => this.previewLMC(ViewMode.All)
    };

    const btnVisualizarMovimentacao = {
      name: 'Visualizar Movimentação',
      icon: 'icon-lx-print',
      class: 'secondary',
      action: () => this.previewLMC(ViewMode.Movimento)
    };

    const btnVisualizarTermos = {
      name: 'Visualizar Termos',
      icon: 'icon-lx-print',
      class: 'secondary',
      action: () => this.previewLMC(ViewMode.Termos)
    };

    const btnVoltarParametros = {
      name: 'Voltar aos Parâmetros',
      icon: 'icon-lx-close',
      class: 'secondary',
      action: this.backParams
    };

    switch (this.state.screenMode) {
      case ScreenMode.Parametrizing:
        customList.push(btnGerar, btnAbrir, btnResetar);
        return customList;
      case ScreenMode.Viewing:
        customList.push(
          btnVisualizarCompleto,
          btnVisualizarMovimentacao,
          btnVisualizarTermos,
          btnVoltarParametros
        );
        return customList;
      default:
        return null;
    }
  };
}

const f3 = value => {
  return formatNumber(value, 3);
};
const dt = value => {
  return moment(value).format('DD/MM/YYYY');
};

const Movimentos = ({ paginas, empresa, endereco }) =>
  paginas.map((p, index) => (
    <table key={index} className='print'>
      <tbody>
        <tr>
          <td colSpan="2">LIVRO DE MOVIMENTAÇÃO DE COMBUSTÍVEL (LMC)</td>
          <td>fl. nº. {p.numeroFolha}</td>
        </tr>
        <tr>
          <td colSpan="2">CNPJ: {empresa.cnpj}</td>
          <td>Inscrição Estadual: {empresa.inscricaoEstadual}</td>
        </tr>
        <tr>
          <td colSpan="3"> Razão social: {empresa.razaoSocial}</td>
        </tr>
        <tr>
          <td colSpan="2">
            Endereço: {endereco.endereco + ' ' + endereco.numero}
          </td>
          <td>UF: {endereco.estado}</td>
        </tr>
        <tr>
          <td colSpan="2">1) Produto: {p.produto.descricao}</td>
          <td>2) Data: {dt(p.data)}</td>
        </tr>
        <tr>
          <td colSpan="2">3) Estoque de Abertura (Medição no Início do Dia)</td>
          <td />
        </tr>
        <tr>
          <td colSpan='2' rowSpan='2' className='nopadding'>
            <table>
              <tbody>
                <tr>
                  {p.estoqueAbertura.map((e, index) => (
                    <td key={index}>TQ) {e.numeroTanque}</td>
                  ))}
                </tr>
                <tr>
                  {p.estoqueAbertura.map((e,index) => (
                    <td key={index}>{f3(e.volume)}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </td>
          <td>3.1) Estoque de Abertura</td>
        </tr>
        <tr>
          <td align="right">{f3(p.valores.estoqueAbertura)}</td>
        </tr>
        <tr>
          <td>4) Volume Recebido no Dia (em litros)</td>
          <td>4.1) Nº TQ Descarga</td>
          <td>4.2) Volume Recebido</td>
        </tr>
        <tr>
          <td>{p.recebimentos[0].documento}</td>
          <td align="right">{p.recebimentos[0].numeroTanque}</td>
          <td align="right">{f3(p.recebimentos[0].volume)}</td>
        </tr>
        <tr>
          <td>{p.recebimentos[1].documento}</td>
          <td align="right">{p.recebimentos[1].numeroTanque}</td>
          <td align="right">{f3(p.recebimentos[1].volume)}</td>
        </tr>
        <tr>
          <td>{p.recebimentos[2].documento}</td>
          <td align="right">{p.recebimentos[2].numeroTanque}</td>
          <td align="right">{f3(p.recebimentos[2].volume)}</td>
        </tr>
        <tr>
          <td>{p.recebimentos[3].documento}</td>
          <td align="right">{p.recebimentos[3].numeroTanque}</td>
          <td align="right">{f3(p.recebimentos[3].volume)}</td>
        </tr>
        <tr>
          <td>{p.recebimentos[4].documento}</td>
          <td align="right">{p.recebimentos[4].numeroTanque}</td>
          <td align="right">{f3(p.recebimentos[4].volume)}</td>
        </tr>
        <tr>
          <td />
          <td>4.3) Total Recebido</td>
          <td align="right">{f3(p.valores.totalRecebido)}</td>
        </tr>
        <tr>
          <td>5) Volume Vendido no Dia (em litros)</td>
          <td>4.4) Vol. Disponível (3.1 + 4.3)</td>
          <td align="right">{f3(p.valores.volumeDispoivel)}</td>
        </tr>
        <tr>
          <td colSpan='3' className='nopadding'>
            <table>
              <tbody>
                <tr>
                  <td>5.1) TQ </td>
                  <td>5.2) Bico </td>
                  <td>5.3) + Fechamento</td>
                  <td>5.4) - Abertura</td>
                  <td>5.5) - Aferições</td>
                  <td>5.6) = Vendas Bico</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[0].numeroTanque}</td>
                  <td align="center">{p.vendasDia[0].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[0].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[0].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[0].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[0].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[1].numeroTanque}</td>
                  <td align="center">{p.vendasDia[1].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[1].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[1].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[1].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[1].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[2].numeroTanque}</td>
                  <td align="center">{p.vendasDia[2].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[2].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[2].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[2].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[2].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[3].numeroTanque}</td>
                  <td align="center">{p.vendasDia[3].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[3].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[3].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[3].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[3].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[4].numeroTanque}</td>
                  <td align="center">{p.vendasDia[4].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[4].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[4].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[4].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[4].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[5].numeroTanque}</td>
                  <td align="center">{p.vendasDia[5].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[5].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[5].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[5].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[5].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[6].numeroTanque}</td>
                  <td align="center">{p.vendasDia[6].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[6].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[6].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[6].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[6].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[7].numeroTanque}</td>
                  <td align="center">{p.vendasDia[7].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[7].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[7].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[7].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[7].valorVendaBico)}</td>
                </tr>                
                <tr>
                  <td align="center">{p.vendasDia[8].numeroTanque}</td>
                  <td align="center">{p.vendasDia[8].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[8].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[8].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[8].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[8].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[9].numeroTanque}</td>
                  <td align="center">{p.vendasDia[9].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[9].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[9].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[9].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[9].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[10].numeroTanque}</td>
                  <td align="center">{p.vendasDia[10].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[10].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[10].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[10].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[10].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[11].numeroTanque}</td>
                  <td align="center">{p.vendasDia[11].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[11].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[11].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[11].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[11].valorVendaBico)}</td>
                </tr>
                <tr>
                  <td align="center">{p.vendasDia[12].numeroTanque}</td>
                  <td align="center">{p.vendasDia[12].numeroBico}</td>
                  <td align="right">{f3(p.vendasDia[12].valorFechamento)}</td>
                  <td align="right">{f3(p.vendasDia[12].valorAbertura)}</td>
                  <td align="right">{f3(p.vendasDia[12].valorAfericao)}</td>
                  <td align="right">{f3(p.vendasDia[12].valorVendaBico)}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td rowSpan='3' className='nopadding'>
            <table>
              <tbody>
                <tr>
                  <td colSpan="2">10) Valor das vendas (R$)</td>
                </tr>
                <tr>
                  <td>10.1) Valor Vendas Dia (5.7 x Preço Bomba)</td>
                  <td align="right">{f3(p.valores.valorVendaDia)}</td>
                </tr>
                <tr>
                  <td>10.2) Valor Acumulado Mês</td>
                  <td align="right">{f3(p.valores.valorVendaAcumuladoMes)}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>5.7) Vendas no Dia</td>
          <td align="right">{f3(p.valores.vendaDia)}</td>
        </tr>
        <tr>
          <td>6) Estoque Escritural (4.4 - 5.7)</td>
          <td align="right">{f3(p.valores.estoqueEscritural)}</td>
        </tr>
        <tr>
          <td>7) Estoque de Fechamento (9.1)</td>
          <td align="right">{f3(p.valores.estoqueFechamento)}</td>
        </tr>
        <tr>
          <td>11) Para uso do Revendedor</td>
          <td>8) Perdas + Ganhos (*)</td>
          <td align="right">{f3(p.valores.perdaSobra)}</td>
        </tr>
        <tr>
          <td rowSpan='3' style={{ width: '50%' }}>
            13) Observações: {p.observacao}
          </td>
          <td colSpan="2">12) Destinado à Fiscalização</td>
        </tr>
        <tr>
          <td colSpan="2">ANP</td>
        </tr>
        <tr>
          <td colSpan="2">Outros Orgãos Fiscais</td>
        </tr>
        <tr>
          <td colSpan="3">Conciliação dos Estoques</td>
        </tr>
        <tr>
          <td colSpan='3' className='nopadding'>
            <table>
              <tbody>
                <tr>
                  <td />
                  <td>TQ) {p.conciliacoes[0].numeroTanque}</td>
                  <td>TQ) {p.conciliacoes[1].numeroTanque}</td>
                  <td>TQ) {p.conciliacoes[2].numeroTanque}</td>
                  <td>TQ) {p.conciliacoes[3].numeroTanque}</td>
                  <td>TQ) {p.conciliacoes[4].numeroTanque}</td>
                  <td>TQ) {p.conciliacoes[5].numeroTanque}</td>
                  <td>9.1) Total</td>
                </tr>
                <tr>
                  <td>9) Fechamento Físico</td>
                  <td align="right">{p.conciliacoes[0].volume}</td>
                  <td align="right">{p.conciliacoes[1].volume}</td>
                  <td align="right">{p.conciliacoes[2].volume}</td>
                  <td align="right">{p.conciliacoes[3].volume}</td>
                  <td align="right">{p.conciliacoes[4].volume}</td>
                  <td align="right">{p.conciliacoes[5].volume}</td>
                  <td align="right">
                    {p.conciliacoes[0].volume +
                      p.conciliacoes[1].volume +
                      p.conciliacoes[2].volume +
                      p.conciliacoes[3].volume +
                      p.conciliacoes[4].volume +
                      p.conciliacoes[5].volume}
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
            (*) ATENÇÃO: SE O RESULTADO FOR NEGATIVO, PODE ESTAR HAVENDO
            VAZAMENTO DE PRODUTO PARA O MEIO AMBIENTE.
          </td>
        </tr>
      </tbody>
    </table>
  ));

const TermoLMC = ({ abertura, termo, empresa, endereco, representante }) => (
  <table className="table-venda print">
    <tbody>
      <tr>
        <td className='td-center title table-space'>
          LIVRO DE MOVIMENTAÇÃO DE COMBUSTÍVEL (LMC)
        </td>
      </tr>
      <tr>
        <td className="td-center table-space">{termo.portaria}</td>
      </tr>
      <tr>
        <td className="td-center table-space">
          Nº DE ORDEM {termo.numeroOrdem}
        </td>
      </tr>
      <tr>
        <td className="td-center table-space">
          <b>TERMO DE {abertura ? 'ABERTURA' : 'FECHAMENTO'}</b>
        </td>
      </tr>
      <tr>
        <td className="td-center table-space">
          Contém este Livro {termo.numeroPaginas} ({termo.numeroPaginasExtenso})
          folhas numeradas do nº 1 ao nº {termo.numeroPaginas} e serviu para o
          lançamento das operações do estabelecimento abaixo qualificado:
        </td>
      </tr>
      <tr>
        <td className="table-space">
          <table className="table-conteudo">
            <tbody>
              <tr>
                <td>Estabelecimento:</td>
                <td colSpan="5">{empresa.razaoSocial}</td>
              </tr>
              <tr>
                <td>Endereço:</td>
                <td colSpan="5">
                  {endereco.endereco} {endereco.numero}
                </td>
              </tr>
              <tr>
                <td>Bairro:</td>
                <td>{endereco.bairro}</td>
              </tr>
              <tr>
                <td>Município:</td>
                <td>
                  {endereco.cidade}/{endereco.estado}
                </td>
              </tr>
              <tr>
                <td>CNPJ:</td>
                <td>{empresa.cnpj}</td>
              </tr>
              <tr>
                <td>Inscrição Estadual:</td>
                <td>{empresa.inscricaoEstadual}</td>
              </tr>
              <tr>
                <td>Distribuidora:</td>
                <td colSpan="5">{termo.distribuidora}</td>
              </tr>
              <tr>
                <td>Capacidade de Armazenamento:</td>
                <td colSpan="5">{f3(termo.capacidadeArmazenamento)}</td>
              </tr>
              <tr>
                <td>Data de Constituição:</td>
                <td colSpan="5">{dt(empresa.dataConstituicao)}</td>
              </tr>
              <tr>
                <td>Data de Abertura:</td>
                <td colSpan="5">{dt(termo.dataInicialApuracao)}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <tr>
        <td className="table-space td-center">
          <table className="table-assinatura ">
            <tbody>
              <tr>
                <td>{termo.dataCompleta}</td>
              </tr>
              <tr>
                <td className="assinatura" />
              </tr>
              <tr>
                {representante ? (
                  <td>
                    ({representante.nome}
                    {representante.cpf && ', '}
                    {representante.cpf}
                    {representante.funcaoCargo && ', '}
                    {representante.funcaoCargo})
                  </td>
                ) : (
                  <></>
                )}
              </tr>
              <tr>
                <td />
              </tr>
              <tr>
                <td>Registro no C.R.C nº {empresa.crcContador}</td>
              </tr>
              <tr />
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

LMC = withRouter(LMC);
