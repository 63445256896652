import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import { maskedCnpjCpf } from '../../../../shared/utils/Utils';
import { TIPO_SITE } from '../../../../shared/constants/TipoSiteConstant';

export const detalhadoProdutoService = {
  find,
  getCombustiveis,
  searchProdutos,
  GetColumns,
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {
  const response = await urlLinxCore
    .post('/DocumentoFiscal/VendaDetalhadaPorProduto', { query: appliedFilters })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function getCombustiveis() {
  const response = await urlLinxCore.post('/produto/listarComFiltro', { query: { subtipos: [1] } });
  return response.data.data;
}

async function searchProdutos(searchTerm) {
  const response = await urlLinxCore.post('/produto/listarComFiltro', { query: { descricao: searchTerm } });
  return response.data.data;
}

const formatDocument = data => {
  return maskedCnpjCpf(data.value);
};

function GetColumns() {
  let colunas = [
    { dataField: 'empresa_CNPJ', caption: 'CNPJ da empresa', width: '130' },
    {
      dataField: 'empresa_RazaoSocial',
      caption: 'Razao social',
      width: '200',
      visible: false,
    },
    {
      dataField: 'empresa_NomeFantasia',
      caption: 'Nome fantasia',
      width: '200',
      visible: false,
    },
    {
      dataField: 'origemVenda',
      caption: 'Origem venda',
      width: '130',
    },
    {
      dataField: 'dataPedido',
      caption: 'Data do Pedido',
      dataType: 'date',
      width: '90',
    },
    {
      dataField: 'dataEmissao',
      caption: 'Emissão',
      dataType: 'date',
      width: '90',
    },
    { dataField: 'horaEmissao', caption: 'Hora', width: '80' },
    {
      dataField: 'produto.codigo',
      caption: 'Código',
      width: '150',
    },
    {
      dataField: 'produto.descricao',
      caption: 'Produto',
      minWidth: '200',
    },
    { dataField: 'unidadeMedida.descricao', caption: 'Unidade' },
    {
      dataField: 'quantidade',
      caption: 'Quantidade',
      width: '100',
      totalize: true,
    },

    { dataField: 'estruturaMercadologica.grupo', caption: 'Grupo', visible: false },
    { dataField: 'estruturaMercadologica.subGrupo', caption: 'Subgrupo', visible: false },
    { dataField: 'estruturaMercadologica.artigo', caption: 'Artigo', visible: false },
    {
      dataField: 'intervalo',
      caption: 'Intervalo',
      width: '100',
      visible: false,
    },
    {
      dataField: 'modeloDocumento',
      caption: 'Modelo',
      width: '80',
      visible: false,
    },
    { dataField: 'serie', caption: 'Série', width: '100', visible: false },
    {
      dataField: 'documento',
      caption: 'Nº Documento',
      width: '120',
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'numeroPedido',
      caption: 'Nº Pedido',
      width: '120',
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'numeroIntegracao',
      caption: 'Nº Integração',
      width: '120',
      alignment: 'left',
      visible: false,
    },
    {
      dataField: 'chave',
      caption: 'Chave de acesso',
      width: '320',
      visible: false,
    },
    { dataField: 'statusNotaFiscal.descricao', caption: 'Situação', visible: false },
    {
      dataField: 'codigoDescricaoDiaSemana',
      caption: 'Dia da semana',
      visible: false,
    },
    {
      dataField: 'operacao',
      caption: 'Operação',
      visible: false,
    },

    {
      dataField: 'operador',
      caption: 'Operador',
      width: '150',
      visible: false,
    },
    { dataField: 'maquina', caption: 'Máquina', width: '100', visible: false },
    {
      dataField: 'cliente.nome',
      caption: 'Cliente',
      width: '150',
      visible: false,
    },
    {
      dataField: 'cliente.documento',
      caption: 'CPF/CNPJ',
      width: '120',
      visible: false,
      customizeText: formatDocument,
    },
    {
      dataField: 'cliente.nomeAutorizado',
      caption: 'Nome motorista',
      visible: false,
    },
    {
      dataField: 'cliente.documentoAutorizado',
      caption: 'Documento motorista',
      visible: false,
      customizeText: formatDocument,
    },
    { dataField: 'cliente.placa', caption: 'Placa', visible: false },
    { dataField: 'itemCombustivel.tanque', caption: 'Tanque', visible: false },
    { dataField: 'itemCombustivel.bomba', caption: 'Bomba', visible: false },
    { dataField: 'itemCombustivel.bico', caption: 'Bico', visible: false },
    {
      dataField: 'itemCombustivel.frentista',
      caption: 'Frentista',
      visible: false,
    },
    {
      dataField: 'itemCombustivel.encerranteInicial',
      caption: 'Encerrante inicial',
      format: { type: 'decimal', precision: 2 },
      visible: false,
    },
    {
      dataField: 'itemCombustivel.encerranteFinal',
      caption: 'Encerrante final',
      format: { type: 'decimal', precision: 2 },
      visible: false,
    },
    {
      dataField: 'numeroItem',
      caption: 'Nº item',
      visible: false,
    },
    {
      dataField: 'statusItem',
      caption: 'Situação item',
      visible: false,
    },
    { dataField: 'setor.descricao', caption: 'Setor', visible: false },
    {
      dataField: 'almoxarifado.descricao',
      caption: 'Almoxarifado',
      visible: false,
    },
    {
      dataField: 'precoUnitario',
      caption: 'Preço unitário',
      format: { type: 'currency', precision: 2 },
      width: '180',
      visible: false,
    },
    {
      dataField: 'custoUnitario',
      caption: 'Custo ICMS unitário',
      format: { type: 'currency', precision: 4 },
      width: '180',
      visible: false,
    },
    {
      dataField: 'vendaBruta',
      caption: 'Bruto R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      totalize: true,
    },
    {
      dataField: 'vendaCancelada',
      caption: 'Cancelado R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      totalize: true,
    },
    {
      dataField: 'valorFrete',
      caption: 'Frete R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      totalize: true,
    },
    {
      dataField: 'desconto',
      caption: 'Desconto R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      totalize: true,
    },
    {
      dataField: 'acrescimo',
      caption: 'Acréscimo R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'icms.valorST',
      caption: 'Icms ST R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'vendaLiquida',
      caption: 'Líquido R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      totalize: true,
    },
    {
      dataField: 'vendaLiquidaComFrete',
      caption: 'Líquido + Frete R$',
      format: { type: 'currency', precision: 2 },
      width: '110',
      totalize: true,
    },
    {
      dataField: 'custo',
      caption: 'Custo',
      format: { type: 'currency', precision: 4 },
      width: '110',
      visible: false,
      totalize: true,
    },
    {
      dataField: 'vendedor',
      caption: 'Vendedor',
      width: '150',
      visible: false,
    },
    {
      dataField: 'percentualComissao',
      caption: 'Comissão %',
      format: { precision: 2 },
      width: '180',
      visible: false,
    },
    {
      dataField: 'valorComissao',
      caption: 'Comissão R$',
      format: { type: 'currency', precision: 2 },
      width: '180',
      visible: false,
    },

    { dataField: 'periodoTurno', caption: 'Turno', visible: false },
    { dataField: 'icms.cst', caption: 'CST ICMS', visible: false },
    { dataField: 'icms.aliquota', caption: 'ICMS %', visible: false },
    {
      dataField: 'icms.valor',
      caption: 'ICMS R$',
      visible: false,
      format: { type: 'currency', precision: 2 },
      totalize: true,
    },

    { dataField: 'pis.cst', caption: 'CST PIS/COFINS', visible: false },
    { dataField: 'pis.aliquota', caption: 'PIS %', visible: false },
    {
      dataField: 'pis.valor',
      caption: 'PIS R$',
      visible: false,
      format: { type: 'currency', precision: 2 },
      totalize: true,
    },
    {
      dataField: 'cofins.aliquota',
      caption: 'COFINS %',
      visible: false,
    },
    {
      dataField: 'cofins.valor',
      caption: 'COFINS R$',
      visible: false,
      format: { type: 'currency', precision: 2 },
      totalize: true,
    },
    {
      dataField: 'margemValor',
      caption: 'Margem R$',
      format: { type: 'currency', precision: 4 },
      width: '100',
      totalize: true,
    },
    {
      dataField: 'margem',
      caption: 'Margem %',
      format: { precision: 2 },
      width: '100',
      //summaryType: "custom",
      //nameItem: "margemFooter",
      //nameGroup: "margemPerc",
      //totalize: true, 
    },
    {
      dataField: 'gorjetaTaxaServico',
      caption: 'Gorjeta/taxa de serviço',
      visible: false,
      format: { type: 'currency', precision: 2 },
    },
    {
      dataField: 'numPedidoPDV',
      caption: 'Nº Pedido PDV',
      visible: false,
      width: '60',
    },
    {
      dataField: 'codigoRapido',
      caption: 'Codigo Rapido',
      visible: false,
    },
    {
      dataField: 'icms.fcp',
      caption: 'FCP R$',
      visible: false,
      format: { type: 'currency', precision: 2 },
    },
    {
      dataField: 'icms.porcentagem',
      caption: 'FCP %',
      visible: false,
    },
    {
      dataField: 'hectolitros',
      caption: 'Hectolitros',
      format: { type: 'fixedPoint', precision: 3 },
      visible: false,
      totalize: true,
    },
    {
      dataField: 'parceiro',
      caption: 'Parceiro',
      width: '150',
      visible: false,
    },
  ];

  if (TIPO_SITE === 'RetaguardaEmporioPro') colunas = colunas.filter(coluna => !coluna.dataField.includes('itemCombustivel'));

  return colunas;
}

export default urlLinxCore;
