import React, { useState, useCallback, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Popup } from 'devextreme-react';
import Switch from 'devextreme-react/switch';
import { DataGrid, Column, Pager, Paging, Sorting, SearchPanel, FilterRow, ColumnChooser } from 'devextreme-react/data-grid';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

import { jsPDF } from 'jspdf';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import { CustomButton } from '../../../../SharedComponents';
import { RealizarPagamento } from '../RealizarPagamento';

import { DDAService } from '../../../DDA/DDA.service';
import { getDadosLogin } from "../../../../../../../shared/utils/Utils"

export function RealizarPagamentoDDA() {
  const [isShowDDA, setIsShowDDA] = useState(false);
  const [value, setValue] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [listingData, setListingData] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const fetchSubscription = async () => {
      setIsLoading(true);
      const dadosLogin = getDadosLogin();
      try {
        const response = await DDAService.consultSubscription(dadosLogin.codigoEstabelecimento);
        if(response.result.status === 'registered')
        {
          setData(response.result);
          const listTitles = await DDAService.listDDATitles(dadosLogin.codigoEstabelecimento);
          var list = [];

          if(listTitles.result)
          {
            for (var i = 0; i < listTitles.result.length; i++) {
              console.log(listTitles.result[i]);
              var obj = {};
              obj.id = listTitles.result[i].id;
              obj.vencimento = listTitles.result[i].paymentLimitDate;
              obj.fornecedor = listTitles.result[i].client;
              obj.valor = listTitles.result[i].value;
              obj.barras = listTitles.result[i].barcode;
              obj.status = listTitles.result[i].status;
              list.push(obj);
          }

          setListingData(list);
        }
      }

      } catch (error) {
        console.error('Erro ao consultar inscrição DDA', error);
        // Aqui você pode definir um estado para guardar mensagens de erro e mostrá-las na UI, se necessário
      } finally {
        setIsLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  const openModal = () => {
    setIsShowDDA(!isShowDDA);
  };
  const valueChanged = useCallback(e => {
    setValue(e.value);

  }, []);

  const exportToPDF = e => {
    const doc = new jsPDF();

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(18);

    doc.text('Pagamentos realizados', 15, 10);

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
      autoTableOptions: {
        styles: { fillColor: [255, 255, 255] },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        headerStyles: { textColor: [0, 0, 0], fontStyle: 'normal' },
        bodyStyles: { textColor: [0, 0, 0] },
        margin: { top: 20, right: 10, bottom: 10, left: 10 },
      },
    }).then(() => {
      doc.save('pagamentos_realizados.pdf');
    });

    e.cancel = true;
  };

  const exportToExcel = e => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Planilha1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'transferencias_realizadas.xlsx');
      });
    });
  };

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'exportpdf',
          onClick: () => exportToPDF(e),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'xlsxfile',
          onClick: () => exportToExcel(e),
        },
      },
    );
  };

  const pay = (barCode) => {
    setBarcode(barCode);
    setIsPay(!isPay);
  };

  const renderActionButtons = (barCode) => {
    return (
      <div>
        <CustomButton className="rounded" variant="primary" icon="money" title="Realizar pagamento" onClick={() => pay(barCode)}>
          Pagar
        </CustomButton>
      </div>
    );
  };

  return (
    <Col>
      <Container fluid>
        <Row>
          {!isLoading ? (
            <>
              {(
                <div className="d-flex mh-100">
                  <DataGrid
                    key="id"
                    dataSource={listingData}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    width="100%"
                    height="100%"
                    onToolbarPreparing={onToolbarPreparing}
                    onCellPrepared={e => {
                      if (e.rowType === 'filter') {
                        e.cellElement.style.backgroundColor = 'white';
                      }
                    }}
                  >
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <FilterRow visible={true} applyFilter="auto" />
                    <Column dataField="vencimento" caption="Vencimento" dataType="date" format="dd/MM/yyyy" />
                    <Column dataField="fornecedor" caption="Fornecedor" />
                    <Column dataField="valor" caption="Valor do boleto" format="R$ #,##0.00" />
                    <Column dataField="barras" caption="Código de barras" />
                    <Column dataField="status" caption="Status" />
                    <Column cellRender={({ data }) => renderActionButtons(data.barras)} caption="Ações" width="auto" />
                    <ColumnChooser enabled={true} mode={'select'} allowSearch={true} title="Escolher colunas" />
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager visible={true} displayMode="full" />
                    <Sorting mode="multiple" />
                  </DataGrid>
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <LoadIndicator id="large-indicator" height={60} width={60} />
            </div>
          )}
        </Row>
        <Popup
          visible={isPay}
          dragEnabled={false}
          showTitle={true}
          title="Realizar pagamento"
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={pay}
          width="80%"
          height="auto"
          minHeight="471px"
        >
          <RealizarPagamento isShow={isPay} barcode={barcode} />
        </Popup>
      </Container>
    </Col>
  );
}
