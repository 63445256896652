import React, { useRef } from 'react';
import { Popup } from 'devextreme-react/popup';
import styles from './ModalCancelamentoQuitacaoLote.module.scss';
import DataGridResult from '../../../../../components/Report/DataGridResult/DataGridResult';
import { ScrollView } from 'devextreme-react';
import { confirm } from 'devextreme/ui/dialog';
import Footer from '../../../../../components/core/footer/Footer';

export function ModalCancelamentoQuitacaoLote(props) {

  const gridResultRef = useRef(null);

  const columns = [
    { dataField: 'codigoMovimento', caption: 'Lote'},
    { dataField: 'dataPagamento', caption: 'Pagamento', dataType: 'date' },
    { dataField: 'qtdeTitulos', caption: 'Total de títulos' },
    { dataField: 'valorPago', caption: 'Total pago', format: { type: 'currency', precision: 2 } }
  ];

  function updateWidth() {
    let widthTotal = window.innerWidth;
    let min = window.innerWidth < 700 ? window.innerWidth : 700;
    let widthPercent = widthTotal < 1400 ? widthTotal * 0.55 : widthTotal * 0.2;
    return widthPercent < min ? min : widthPercent;
  }

  function updateHeight() {
    let heightTotal = window.innerHeight;
    let min = 400;
    let max = 500;
    let heightPercent = heightTotal * 0.18 * props.lotes.length;
    return heightPercent < min ? min : heightPercent > max ? max : heightPercent
  }

  const confirmarCancelamentoQuitacaoLote = async () => {
    let result = await confirm(
      '<h1>A quitação de todos os títulos do lote será cancelada. Deseja continuar?</h1>',
      'Gerenciamento financeiro'
    );

    if (result) {
      props.handleCloseModal(1);
    }
  }

  const contentRender = () => {
    return (
      <ScrollView
        id="scrollview"
        scrollByContent={true}
        showScrollbar={'onScroll'}
        scrollByThumb={true}
      >
        <div className={`${styles.textoTitulo} row`}>
          {'Foi(ram) selecionado(s) título(s) quitado(s) em lote.'}
        </div>
        <div className={`${styles.textoTitulo} row`}>
          {
            'O cancelamento da quitação só pode ser realizada ao cancelar a quitação do(s) lote(s).'
          }
        </div>
        <div>
         <DataGridResult dataSource={props.lotes} columns={columns} groupPanel= {false} columnChooser = {false} searchPanel={false} 
         columnMinWidth={50} allowedPageSizes={[5]} defaultPageSize={5} showPageSizeSelector={false} summary={() => {}} export={false}
         gridResultRef={gridResultRef} />
        </div>

        <Footer cancelQuitacaoAction={() => { confirmarCancelamentoQuitacaoLote(); }} returnAction={() => { props.handleCloseModal(0); }} canceling={props.canceling} disabled={props.canceling}/>

      </ScrollView>
    );
  };

  return (
    props.active && (
      <Popup
        visible={props.active}
        onHidden={() => props.handleCloseModal(0)}
        dragEnabled={false}
        closeOnOutsideClick={false}
        title="Confirmação de cancelamento"
        showTitle={true}
        width={updateWidth()}
        contentRender={contentRender}
        height={updateHeight()} />
    )
  );
}
