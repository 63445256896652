import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';

export const lancamentoService = {
  alterarLancamento,
  alterarTransferencia,
  excluirLancamento,
  excluirTransferencia
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function alterarLancamento(
  id,
  codigoLancamento,
  dataOperacao,
  dataOperacaoOriginal,
  valor,
  valorOriginal,
  codigoCentroMonetario,
  centroMonetarioOriginal,
  codigoOperacao,
  codigoConta,
  descricao,
  conciliado,
  numeroDocumento
) {
  const response = await urlLinxCore
    .post('/financeiro/AlterarLancamento', {
      codigoMovimento: id,
      codigoLancamento,
      dataOperacao,
      dataOperacaoOriginal,
      valor,
      valorOriginal,
      centroMonetario: {
        id: codigoCentroMonetario
      },
      centroMonetarioOriginal: {
        id: centroMonetarioOriginal
      },
      operacao: {
        id: codigoOperacao
      },
      conta: {
        id: codigoConta
      },
      historico: descricao,
      indicadorConciliado: conciliado,
      numeroDocumento
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function alterarTransferencia(
  id,
  dataOperacao,
  dataOperacaoOriginal,
  valor,
  valorOriginal,
  centroMonetarioOrigemOriginal,
  centroMonetarioDestinoOriginal,
  codigoCentroMonetarioOrigem,
  codigoCentroMonetarioDestino,
  historico,
  conciliado,
  numeroDocumento
) {
  const response = await urlLinxCore
    .post('/financeiro/AlterarTransferencia', {
      id,
      dataOperacao,
      dataOperacaoOriginal,
      valor,
      valorOriginal,
      centroMonetarioOrigemOriginal,
      centroMonetarioDestinoOriginal,
      centroMonetarioOrigem: {
        id: codigoCentroMonetarioOrigem
      },
      centroMonetarioDestino: {
        id: codigoCentroMonetarioDestino
      },
      historico,
      conciliado,
      numeroDocumento: String(numeroDocumento)
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function excluirLancamento(
  id,
  codigoTipoLancamento,
  dataOperacao,
  valor,
  centroMonetario
) {
  const response = await urlLinxCore
    .post('/financeiro/ExcluirLancamento', {
      codigoMovimento: id,
      codigoTipoLancamento,
      dataOperacao,
      valor,
      centroMonetario
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function excluirTransferencia(
  id,
  dataOperacao,
  valor,
  centroMonetarioOrigem,
  centroMonetarioDestino
) {
  const response = await urlLinxCore
    .post('/financeiro/ExcluirTransferencia', {
      id,
      dataOperacao,
      valor,
      centroMonetarioOrigem,
      centroMonetarioDestino
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}
