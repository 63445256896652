import moment from 'moment';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
  hideAlert, hideWait, setFocus, showDeleteError, showError, showInfo, showLoadError, showQuestion, showSaveError, showWait, tagAlert
} from '../../../components/alert/Alert';
import Botao from '../../../components/botao/Botao';
import Footer from '../../../components/core/footer/Footer';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import {
  AutoComplete, Checkbox,
  Checkitem, handleInputChange,
  handleInputChangeRegex, InputDate, InputText, Select
} from '../../../components/formulario/Formulario';
import List from '../../../components/formulario/List';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';
import { getEstruturasMercadologicas } from '../../../services/EstruturaMercadologica.service';
import { getEstados } from '../../../services/Localidades.service';
import { getSetores } from '../../../services/Setores.service';
import {
  ALPHANUMERIC_WITH_SPACE_PATTERN,
  NUMERIC_PATTERN
} from '../../../shared/constants/Const';
import { menus } from '../../../shared/constants/MenuConstants';
import {
  appendInList, changeInList,
  deleteInList
} from '../../../shared/utils/TableUtils';
import {
  emptyAutoComplete, emptySelect, emptyText, formatNumber, getCnpj, getNomePagina, heOrShe,
  ifShow, isValidGTIN, makeItem,
  makeList, menuPermiteAlteracao,
  menuPermiteExclusao, none, noneItem, oneOrMore, sameFloat, sameFloatWithFixed, sameInt, sameString, sortList, stringToFloatWithFixed, sumNumbersInArray, toFloatFormattedDisplay, unionArray, validaCNPJ, validaDadosLogin
} from '../../../shared/utils/Utils';
import { getTanques } from '../tanques-bombas-bicos/tanques/Tanques.service';
import {
  SectionContainer, SectionContent
} from './../../../components/section/Content';
import SectionHeader from './../../../components/section/Header';
import { ComposicaoProduto } from './ComposicaoProduto';
import {
  alterarProduto,
  excluirProduto, getAlmoxarifados, getANP, getCEST, getContasSPED, getFabricantesProdutos, getFormasReajustePreco, getFornecedoresProdutos, getGruposImpostos, getNCM, getProdutoComposicao, getProdutoEstoque,
  getProdutoFornecedores, getProdutoInformacoesFiscais, getProdutoNiveisPreco, getProdutoPrecosMargens, getProdutos, getSelecionarProduto, getSituacoes, getUnidadeMedida, incluirProduto, verifyProduto
} from './Produtos.service';
import {
  searchEstruturaMercadologica,
  tagSearchEstruturaMercadologica
} from './SearchEstruturaMercadologica';

const PATH_MENU = menus.COD_60510;

const autoComplete = {
  ncm: {
    min: 5,
    list: 'NCM',
    item: 'NCM',
    isHe: false,
    text: ''
  },
  anp: {
    min: 3,
    list: 'ANP',
    item: 'código da ANP',
    isHe: true,
    text: ''
  },
  cest: {
    min: 3,
    list: 'CEST',
    item: 'código da CEST',
    isHe: true,
    text: ''
  },
  fornecedorProduto: {
    min: 3,
    list: 'fornecedores',
    item: 'fornecedor',
    isHe: true,
    text: ''
  },
  fabricante: {
    min: 1,
    list: 'fabricantes',
    item: 'fabricante',
    isHe: true,
    text: ''
  }
};

const codigoInternGLP = 327;
const codigoInternKg = 6;

export const Situacao = {
  None: none,
  Ativo: 1,
  InativoEmOperacao: 2,
  InativoForaOperacao: 3
};

export const Tipo = {
  None: none,
  AtivoImobilizado: '9',
  MaterialUsoConsumo: '8',
  MercadoriaRevenda: '1',
  OutrosInsumos: '11'
};

export const Tipos = [
  { value: Tipo.None, label: 'Selecione o tipo do produto' },
  { value: Tipo.AtivoImobilizado, label: 'Ativo imobilizado' },
  { value: Tipo.MaterialUsoConsumo, label: 'Material de uso e consumo' },
  { value: Tipo.MercadoriaRevenda, label: 'Mercadoria para revenda' },
  { value: Tipo.OutrosInsumos, label: 'Outros insumos' }
];

export const Subtipo = {
  None: none,

  AtivoImobilizado: '10',

  MaterialUsoConsumo: '9',

  Combustivel: '1',
  Composto: '3',
  ElementoFiltrante: '8',
  Embalagem: '5',
  Filtro: '7',
  Fluidos: '13',
  Kit: '4',
  Lubrificante: '6',
  Simples: '2',

  OutrosInsumos: '12'
};

export const Subtipos = {
  None: {
    default: Subtipo.None,
    enabled: false,
    list: [{ value: none, label: 'Selecione primeiro o tipo do produto' }]
  },
  AtivoImobilizado: {
    default: Subtipo.AtivoImobilizado,
    enabled: false,
    list: [
      {
        value: Subtipo.None,
        label: 'Selecione o subtipo do ativo imobilizado'
      },
      { value: Subtipo.AtivoImobilizado, label: 'Ativo imobilizado' }
    ]
  },
  MaterialUsoConsumo: {
    default: Subtipo.MaterialUsoConsumo,
    enabled: false,
    list: [
      {
        value: Subtipo.None,
        label: 'Selecione o subtipo do material de uso e consumo'
      },
      { value: Subtipo.MaterialUsoConsumo, label: 'Material de uso e consumo' }
    ]
  },
  MercadoriaRevenda: {
    default: Subtipo.None,
    enabled: true,
    list: [
      {
        value: Subtipo.None,
        label: 'Selecione o subtipo da mercadoria para revenda'
      },
      { value: Subtipo.Combustivel, label: 'Combustível' },
      { value: Subtipo.Composto, label: 'Composto' },
      { value: Subtipo.ElementoFiltrante, label: 'Elemento filtrante' },
      { value: Subtipo.Embalagem, label: 'Embalagem' },
      { value: Subtipo.Filtro, label: 'Filtro' },
      { value: Subtipo.Fluidos, label: 'Fluídos' },
      { value: Subtipo.Kit, label: 'Kit' },
      { value: Subtipo.Lubrificante, label: 'Lubrificante' },
      { value: Subtipo.Simples, label: 'Simples' }
    ]
  },
  OutrosInsumos: {
    default: Subtipo.OutrosInsumos,
    enabled: false,
    list: [
      { value: Subtipo.None, label: 'Selecione o subtipo de outros insumos' },
      { value: Subtipo.OutrosInsumos, label: 'Outros insumos' }
    ]
  }
};

export const Setor = {
  Loja: 2,
  Pista: 3
};

export const TipoAlmoxarifado = {
  AreaVendas: '1',
  Deposito: '2'
};

export const FormaReajuste = {
  MargemBruta: '1',
  MargemMarkup: '2'
};

const Url = '/cadastro/produtos/';
const titulo = 'Produtos';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleInputChangeRegex = handleInputChangeRegex.bind(this);
    this.state = {
      cnpjEstabelecimento: null,
      id: null,
      situacao: Situacao.Ativo,
      favorito: null,
      codigo: null,
      gtin: null,
      codigoRapido: null,
      descricao: null,
      descricaoResumida: null,
      marca: null,
      tipo: none,
      subtipo: none,
      ncm: noneItem(),
      cest: noneItem(),
      anp: noneItem(),
      unidadeVenda: null,
      unidadeVendaTributada: null,
      fatorConversaoTributada: null,
      unidadeCompra: null,
      fatorConversao: null,
      percentualGLP: null,
      percentualGasNacional: null,
      percentualGasImportado: null,
      totalPercGLP: null,
      estruturaMercadologicaId: null,
      estruturaMercadologicaDescricao: '',
      fabricante: noneItem(),
      setor: null,
      naoPermiteVenda: null,
      controlaEstoque: null,
      baixaEstoqueRecebimento: null,

      // precosMargens
      precosMargensLoaded: false,
      precoVenda: null,
      precoVendaVigente: null,
      precoCompra: null,
      custoSemICMS: null,
      custoComICMS: null,
      formaReajuste: null,
      percentualReajuste: null,
      desconto: null,
      comissao: null,

      // niveisPrecos
      niveisPrecosLoaded: false,
      niveisPrecos: [
        {
          nivel: 1,
          valor: null
        }
      ],

      // fiscal
      fiscalLoaded: false,
      aliquotaMVAOriginal: null,
      codigoSped: null,
      contaSpedCredito: null,
      contaSpedDebito: null,
      escalaNaoRelevante: null,
      cnpjEscala: null,
      razaoEscala: null,
      codigoBeneficio: null,
      tributacoes: [
        {
          codigoGrupo: null,
          descricaoGrupo: null, //??
          impostos: [
            {
              codigo: null,
              descricaoImposto: null, //??
              situacaoTributaria: null,
              tipoAliquota: null,
              descricaoAliquota: null, //??
              percentualValor: null,
              percentualValorReduzido: null,
              quantidade: null
            }
          ],
          fcp: null
        }
      ],
      tributacoesEstadoDestino: [
        {
          estadoId: null,
          aliquotaMVA: null,
          aliquotaICMS: null,
          aliquotaFCP: null
        }
      ],
      // estoque
      estoqueLoaded: false,
      quantidadeMinima: null,
      quantidadeMaxima: null,
      custoMedio: null,
      dataCompra: null,
      dataEncomenda: null,
      dataVenda: null,
      almoxarifados: [
        {
          codigo: none,
          descricao: null,
          tipo: null,
          saldo: null
        }
      ],

      // fornecedores
      fornecedoresLoaded: false,
      fornecedores: [
        {
          fornecedor: noneItem(),
          principal: true,
          referencia: null,
          unidadeCompra: null,
          fatorConversao: null
        }
      ],

      // composicoes
      composicoesLoaded: false,
      itensComposicao: [],

      excluido: null,
      dataExclusao: null,
      rowVersion: null,

      sections: {
        identificacao: true,
        classificacaoFiscal: false,
        medidasQuantidades: false,
        outrasInformacoes: false,
        precosMargens: false,
        niveisPrecos: false,
        fiscal: false,
        estoque: false,
        composicoes: false,
        fornecedores: false
      },

      salvando: false
    };
  }

  getEstruturaMercadologicaDescricao = estrutura => {
    const listaEstruturas = this.props.lists.estruturas;
    const fullDescription = node => {
      let result = '';
      if (node.codigoPai) {
        if (listaEstruturas && listaEstruturas.length) {
          listaEstruturas.map(item => {
            if (sameInt(item.codigo, node.codigoPai)) {
              result = fullDescription(item) + ' - ' + node.descricao;
            }
            return result;
          });
        }
      } else {
        result = node.descricao;
      }
      return result;
    };

    let result = '';
    let node = null;
    if (listaEstruturas) {
      listaEstruturas.forEach(element => {
        if (sameInt(element.codigo, estrutura.codigo)) {
          node = { ...element };
          return;
        }
      });
    }
    if (node) {
      result = fullDescription(node);
    }
    return result;
  };

  apiToState = data => {
    let setor = data.setor ? data.setor.codigo : null;
    if (data.subtipo && sameString(data.subtipo.codigo, Subtipo.Combustivel))
      setor = Setor.Pista;
    return {
      cnpjEstabelecimento: data.cnpjEstabelecimento,
      id: data.id,
      situacao: data.situacao.codigo + 1,
      favorito: data.favorito,
      codigo: data.codigo,
      gtin: data.gtin,
      codigoRapido: data.codigoRapido,
      descricao: data.descricao,
      descricaoResumida: data.descricaoResumida,
      marca: data.marca,
      tipo: data.tipo ? String(data.tipo.codigo) : none,
      subtipo: data.subtipo ? String(data.subtipo.codigo) : none,
      ncm: makeItem(data.ncm, true),
      cest: makeItem(data.cest, true),
      //anp: makeItem(data.anp, true),
      anp: !!data.anp
        ? {
            value: data.anp.codigo,
            label: data.anp.codigoExtenso + ' - ' + data.anp.descricao
          }
        : noneItem(),
      unidadeVenda: data.unidadeVenda ? data.unidadeVenda.codigo : null,
      unidadeVendaTributada: data.unidadeVendaTributada ? data.unidadeVendaTributada.codigo: null,
      fatorConversaoTributada: data.fatorConversaoTributada,
      unidadeCompra: data.unidadeCompra ? data.unidadeCompra.codigo : null,
      fatorConversao: data.fatorConversao,
      estruturaMercadologicaId: data.estruturaMercadologica.codigo,
      percentualGLP: data.percentualGLP,
      percentualGasNacional: data.percentualGasNacional,
      percentualGasImportado: data.percentualGasImportado,
      totalPercGLP: sumNumbersInArray([
        data.percentualGLP,
        data.percentualGasNacional,
        data.percentualGasImportado
      ]),
      //estruturaMercadologicaDescricao: data.estruturaMercadologica.descricao,
      estruturaMercadologicaDescricao: this.getEstruturaMercadologicaDescricao(
        data.estruturaMercadologica
      ),
      fabricante: makeItem(data.fabricante),
      setor,
      naoPermiteVenda: data.naoPermiteVenda,
      controlaEstoque: data.controlaEstoque,
      baixaEstoqueRecebimento: data.baixaEstoqueRecebimento,

      // precosMargens
      precosMargensLoaded: false,
      precoVenda: null,
      precoVendaVigente: null,
      precoCompra: null,
      custoSemICMS: null,
      custoComICMS: null,
      formaReajuste: null,
      percentualReajuste: null,
      desconto: null,
      comissao: null,

      // niveisPrecos
      niveisPrecosLoaded: false,
      niveisPrecos: [],

      // fiscal
      fiscalLoaded: false,
      aliquotaMVAOriginal: null,
      codigoSped: null,
      contaSpedCredito: null,
      contaSpedDebito: null,
      escalaNaoRelevante: null,
      cnpjEscala: null,
      razaoEscala: null,
      codigoBeneficio: null,
      tributacoes: [],
      tributacoesEstadoDestino: [],

      // estoque
      estoqueLoaded: false,
      quantidadeMinima: null,
      quantidadeMaxima: null,
      custoMedio: null,
      dataCompra: null,
      dataEncomenda: null,
      dataVenda: null,
      almoxarifados: [],

      // fornecedores
      fornecedoresLoaded: false,
      fornecedores: [],

      // composicoes
      composicoesLoaded: false,
      itensComposicao: [],

      excluido: data.excluido,
      dataExclusao: data.dataExclusao,
      rowVersion: data.rowVersion,

      sections: {
        identificacao: true,
        composicoes: false,
        classificacaoFiscal: false,
        medidasQuantidades: false,
        outrasInformacoes: false,
        precosMargens: false,
        niveisPrecos: false,
        fiscal: false,
        estoque: false,
        fornecedores: false
      }
    };
  };

  loadProduto = async id => {
    if (this.props.id) {
      try {
        const { data } = await getSelecionarProduto(this.props.id);
        const newState = this.apiToState(data);
        this.setState(newState);
        await this.loadAllLazies(this.props.id);
      } catch (e) {
        showLoadError(this, titulo, e, null, () => {
          hideAlert(this, this.props.history, Url);
        });
      }
    }
  };

  loadAllLazies = async id => {
    //if (this.state.sections.precosMargens)
    await this.loadPrecosMargens(id);
    //if (this.state.sections.niveisPrecos)
    await this.loadNiveisPrecos(id);
    //if (this.state.sections.fiscal)
    await this.loadFiscal(id);
    //if (this.state.sections.estoque)
    await this.loadEstoque(id);
    //if (this.state.sections.fornecedores)
    await this.loadFornecedores(id);
    //if (this.state.sections.composicoes)
    await this.carregarComposicao(id);
  };

  apiPrecosMargensToState = data => {
    const precoVenda = data ? data.precoVenda : null,
      precoVendaVigente = data ? data.precoVendaVigente : null,
      precoCompra = data ? data.precoCompra : null,
      custoSemICMS = data ? data.custoSemICMS : null,
      custoComICMS = data ? data.custoComICMS : null,
      formaReajuste = data ? data.formaReajuste : null,
      percentualReajuste = data ? data.valorAjuste : null,
      desconto = data ? data.desconto : null,
      comissao = data ? data.comissao : null;
    return {
      precoVenda,
      precoVendaVigente,
      precoCompra,
      custoSemICMS,
      custoComICMS,
      formaReajuste,
      percentualReajuste,
      desconto,
      comissao
    };
  };

  loadPrecosMargens = async id => {
    if (!this.state.precosMargensLoaded) {
      const { data } = await getProdutoPrecosMargens(id);
      this.setState({
        ...this.apiPrecosMargensToState(data),
        precosMargensLoaded: true
      });
    }
  };

  apiNiveisPrecosToState = data => {
    let niveisPrecos = data.map(item => {
      return {
        codigo: item.codigo,
        nivel: item.nivel,
        valor: item.valor
      };
    });
    if (!niveisPrecos || niveisPrecos.length === 0)
      niveisPrecos = [
        {
          codigo: 1,
          nivel: 1,
          valor: null
        }
      ];
    return { niveisPrecos };
  };

  loadNiveisPrecos = async id => {
    if (!this.state.niveisPrecosLoaded) {
      const { data } = await getProdutoNiveisPreco(id);
      this.setState({
        ...this.apiNiveisPrecosToState(data),
        niveisPrecosLoaded: true
      });
    }
  };

  apiFiscalToState = data => {
    const codigoSped = data ? data.informacoesFiscais.codigoSped : null,
      aliquotaMVAOriginal =  data ? data.informacoesFiscais.aliquotaMVAOriginal : null,
      contaSpedCredito = data
        ? data.informacoesFiscais.planoContaSpedCredito
        : null,
      contaSpedDebito = data
        ? data.informacoesFiscais.planoContaSpedDebito
        : null,
      escalaNaoRelevante = data ? data.escalaNaoRelevante.indicador : null,
      cnpjEscala = data ? data.escalaNaoRelevante.cnpj : null,
      razaoEscala = data ? data.escalaNaoRelevante.razaoSocial : null,
      codigoBeneficio = data ? data.escalaNaoRelevante.codigoBeneficio : null,
      tributacoesEstadoDestino = !data
        ?  [] : (
        !data.tributacoesEstadoDestino ? []
        : data.tributacoesEstadoDestino.map(t => {
          return {
            estadoId: t.estadoId,
            aliquotaMVA: t.aliquotaMVA,
            aliquotaICMS: t.aliquotaICMS,
            aliquotaFCP: t.aliquotaFCP
          };
        })
        ),
      tributacoes = !data
        ? []
        : data.tributacoes.map(t => {
            return {
              codigoGrupo: t.codigoGrupo,
              descricaoGrupo: t.descricaoGrupo,

              fcp: t.fcp
            };
          });


    if (tributacoes.length === 0)
      tributacoes.push({
        codigoGrupo: null,
        descricaoGrupo: null,
        // impostos: [],
        fcp: null
      });

    if (tributacoesEstadoDestino.length === 0)
    tributacoesEstadoDestino.push({
      estadoId: null,
      aliquotaMVA: null,
      aliquotaICMS: null,
      aliquotaFCP: null
    });

    return {
      aliquotaMVAOriginal,
      codigoSped,
      contaSpedCredito,
      contaSpedDebito,
      escalaNaoRelevante,
      cnpjEscala,
      razaoEscala,
      codigoBeneficio,
      tributacoes,
      tributacoesEstadoDestino
    };
  };

  loadFiscal = async id => {
    if (!this.state.fiscalLoaded) {
      if (!sameString(this.state.subtipo, Subtipo.Kit)) {
        if (id) {
          const { data } = await getProdutoInformacoesFiscais(id);
          this.setState({
            ...this.apiFiscalToState(data)
          });
        }
        this.setState({
          fiscalLoaded: true
        });
      }
    }
  };

  apiEstoqueToState = data => {
    const quantidadeMinima = data ? data.quantidadeMinima : null,
      quantidadeMaxima = data ? data.quantidadeMaxima : null,
      custoMedio = data ? data.custoMedio : null,
      dataCompra = data ? data.dataCompra : null,
      dataEncomenda = data ? data.dataEncomenda : null,
      dataVenda =
        data && data.dataVenda
          ? moment(data.dataVenda).format('DD/MM/YYYY')
          : null,
      almoxarifados =
        !data || !data.almoxarifados
          ? []
          : data.almoxarifados.map(a => {
              return {
                codigo: a.codigoAlmoxarifado,
                descricao: a.descricao,
                tipo: a.tipo + 1,
                saldo: a.saldo
              };
            });
    if (almoxarifados.length === 0)
      almoxarifados.push({
        codigo: none,
        descricao: null,
        tipo: null,
        saldo: null
      });
    return {
      quantidadeMinima,
      quantidadeMaxima,
      custoMedio,
      dataCompra,
      dataEncomenda,
      dataVenda,
      almoxarifados
    };
  };

  loadEstoque = async id => {
    if (!this.state.estoqueLoaded) {
      const { data } = await getProdutoEstoque(id);
      this.setState({
        ...this.apiEstoqueToState(data),
        estoqueLoaded: true
      });
    }
  };

  apiFornecedoresToState = data => {
    const fornecedores = data.map(f => {
      return {
        fornecedor: makeItem(f),
        principal: f.principal,
        referencia: f.referencia,
        unidadeCompra: f.codigoUnidadeCompra,
        fatorConversao: f.fatorConversao
      };
    });
    if (fornecedores.length === 0)
      fornecedores.push({
        fornecedor: noneItem(),
        principal: true,
        referencia: null,
        unidadeCompra: none,
        fatorConversao: null
      });
    return { fornecedores };
  };

  loadFornecedores = async id => {
    if (!this.state.fornecedoresLoaded) {
      const { data } = await getProdutoFornecedores(id);
      this.setState({
        ...this.apiFornecedoresToState(data),
        fornecedoresLoaded: true
      });
    }
  };

  stateToApiComposicao = itensComposicao => {
    var listaComposicao = [];
    for (let i = 0; i < itensComposicao.length; i++) {
      const e = itensComposicao[i];
      const objComposicao = {};
      objComposicao.id = i + 1;
      objComposicao.produto = {};
      objComposicao.produto.id = e.id;
      objComposicao.produto.codigo = e.codigoProduto;
      objComposicao.produto.descricao = e.descricaoProduto;

      objComposicao.unidadeVenda = { codigo: e.unidadeVenda };
      objComposicao.quantidade = e.qtdeProduto.toString().replace(',', '.');
      /*V - valor unitario
      T - total
      F - fixo
      P - percentual*/
      switch (parseInt(e.tipoDesconto)) {
        case 1:
          objComposicao.tipoDesconto = 'V';
          break;

        case 2:
          objComposicao.tipoDesconto = 'T';
          break;

        case 3:
          objComposicao.tipoDesconto = 'F';
          break;

        case 4:
          objComposicao.tipoDesconto = 'P';
          break;

        default:
          break;
      }
      objComposicao.valor = 0;
      if (sameInt(this.state.subtipo, Subtipo.Kit)) {
        objComposicao.valor = e.desconto
          ? e.desconto.toString().replace(',', '.')
          : 0;
        //valor desconto
      }
      objComposicao.inativo = !e.ativo;
      objComposicao.excluido = false;
      objComposicao.dataAlteracao = moment();
      listaComposicao.push(objComposicao);
    }

    return listaComposicao;
  };

  stateToApi = () => {
    const state = this.state;
    let result = {};
    if (state.id) result.id = state.id;
    // identificacao
    result = {
      ...result,
      cnpjEstabelecimento: state.cnpjEstabelecimento,
      situacao: { codigo: state.situacao - 1 },
      favorito: state.favorito,
      codigo: state.codigo,
      gtin: state.gtin,
      codigoRapido: state.codigoRapido ? state.codigoRapido : null,
      descricao: state.descricao,
      descricaoResumida: state.descricaoResumida,
      marca: state.marca,
      tipo: { codigo: state.tipo },
      subtipo: { codigo: state.subtipo },
      ncm: { codigo: state.ncm.value },
      cest: { codigo: state.cest.value },
      anp: { codigo: state.anp.value },
      unidadeVenda: { codigo: state.unidadeVenda },
      unidadeVendaTributada: { codigo: state.unidadeVendaTributada },
      fatorConversaoTributada: this.hasFatorConversaoTributada() ? state.fatorConversaoTributada : 1,
      unidadeCompra: { codigo: state.unidadeCompra },
      fatorConversao: this.hasFatorConversao() ? state.fatorConversao : 1, // 1 é neutro na multiplicação e divisão
      percentualGLP: state.percentualGLP,
      percentualGasNacional: state.percentualGasNacional,
      percentualGasImportado: state.percentualGasImportado,
      estruturaMercadologica: { codigo: state.estruturaMercadologicaId },
      fabricante: { codigo: state.fabricante.value },
      setor: { codigo: state.setor },
      naoPermiteVenda: state.naoPermiteVenda,
      controlaEstoque: state.controlaEstoque,
      baixaEstoqueRecebimento: state.baixaEstoqueRecebimento
    };

    // precosMargens
    if (state.precosMargensLoaded || this.isInserting()) {
      result = {
        ...result,
        precosMargens: {
          precoVenda: state.precoVenda
            ? state.precoVenda.toString().replace(',', '.')
            : 0,
          formaReajuste: !this.isKit() ? state.formaReajuste : null,
          valorAjuste: !this.isKit() ? state.percentualReajuste : null,
          desconto: state.desconto,
          comissao: state.comissao
        }
      };
      if (!this.isInserting())
        result = {
          ...result,
          alterouPrecosMargens: true
        };
    }

    // niveisPrecos
    if (
      this.isCombustivel() &&
      (state.niveisPrecosLoaded || this.isInserting())
    ) {
      const niveisPreco = [];
      state.niveisPrecos.forEach(n => {
        if (n.nivel && n.valor)
          niveisPreco.push({
            codigo: n.codigo, // TODO: na inclusão não usa e na alteração usa ??? :\
            nivel: n.nivel,
            valor: n.valor
          });
      });
      result = {
        ...result,
        niveisPreco
      };
      if (!this.isInserting())
        result = {
          ...result,
          alterouNiveisPrecos: true
        };
    }

    // fiscal
    if (state.fiscalLoaded || this.isInserting()) {
      const tributacoes = [];
      const tributacoesEstadoDestino = [];

      state.tributacoes.forEach(t => {
        if (t.codigoGrupo) {
          tributacoes.push({
            codigoGrupo: t.codigoGrupo,
            fcp: t.fcp
          });
        }
      });

      state.tributacoesEstadoDestino.forEach(t => {
        if (t.estadoId) {
          tributacoesEstadoDestino.push({
            estadoId: t.estadoId,
            aliquotaMVA: t.aliquotaMVA,
            aliquotaICMS: t.aliquotaICMS,
            aliquotaFCP: t.aliquotaFCP,
          });
        }
      });

      result = {
        ...result,
        fiscal: {
          informacoesFiscais: {
            codigoSPED: state.codigoSped,
            planoContaSpedCredito: state.contaSpedCredito,
            planoContaSpedDebito: state.contaSpedDebito,
            aliquotaMVAOriginal: state.aliquotaMVAOriginal
          },
          escalaNaoRelevante: {
            indicador: state.escalaNaoRelevante,
            cnpj: state.cnpjEscala,
            razaoSocial: state.razaoEscala,
            codigoBeneficio: state.codigoBeneficio
          },
          tributacoes,
          tributacoesEstadoDestino
        }
      };
      if (!this.isInserting())
        result = {
          ...result,
          alterouFiscal: true
        };
    }

    // estoque
    if (state.estoqueLoaded || this.isInserting()) {
      const almoxarifados = [];
      state.almoxarifados.forEach(a => {
        if (a.codigo)
          almoxarifados.push({
            codigoAlmoxarifado: a.codigo
          });
      });
      result = {
        ...result,
        estoque: {
          quantidadeMinima: state.quantidadeMinima,
          quantidadeMaxima: state.quantidadeMaxima,
          almoxarifados
        }
      };
      if (!this.isInserting())
        result = {
          ...result,
          alterouEstoque: true
        };
    }

    // fornecedores
    if (state.fornecedoresLoaded || this.isInserting()) {
      const fornecedores = [];
      state.fornecedores.forEach(f => {
        if (f.fornecedor && !sameString(f.fornecedor.value, none))
          fornecedores.push({
            codigo: f.fornecedor.value,
            principal: f.principal,
            referencia: f.referencia ? f.referencia : '',
            codigoUnidadeCompra: f.unidadeCompra,
            fatorConversao: f.fatorConversao
          });
      });
      result = {
        ...result,
        fornecedores
      };
      if (!this.isInserting())
        result = {
          ...result,
          alterouFornecedores: true
        };
    }

    // ◄composicoes►
    if (
      (state.composicoesLoaded || this.isInserting()) &&
      (state.subtipo === Subtipo.Kit ||
        state.subtipo === Subtipo.Composto ||
        state.subtipo === Subtipo.Embalagem)
    ) {
      result = {
        ...result,
        composicao: {
          itens: this.stateToApiComposicao(state.itensComposicao)
        }
      };
      if (!this.isInserting())
        result = {
          ...result,
          alterouComposicao: true
        };
    }

    if (state.rowVersion)
      result = {
        ...result,
        rowVersion: state.rowVersion
      };

    return result;
  };

  loadNCM = inputText => {
    this.forceUpdate();
    autoComplete.ncm.text = inputText;
    if (String(inputText).length >= autoComplete.ncm.min)
      return getNCM(inputText).then(response =>
        response.data.result.map(item => {
          return {
            value: item.codigo,
            label: item.codigo + ' - ' + item.descricao,
            obrigaCest: item.obrigaCest
          };
        })
      );
    return Promise.resolve();
  };

  loadANP = inputText => {
    this.forceUpdate();
    autoComplete.anp.text = inputText;
    if (String(inputText).length >= autoComplete.anp.min)
      return getANP(null, inputText).then(response =>
        response.data.result.map(item => {
          return {
            value: item.codigo,
            label: item.codigoExtenso + ' - ' + item.descricao
          };
        })
      );
    return Promise.resolve();
  };

  loadCEST = inputText => {
    this.forceUpdate();
    autoComplete.cest.text = inputText;
    if (String(inputText).length >= autoComplete.cest.min)
      return getCEST(inputText).then(response =>
        response.data.result.map(item => {
          return {
            value: item.codigo,
            label: item.codigo + ' - ' + item.descricao
          };
        })
      );
    return Promise.resolve();
  };

  loadFornecedoresProdutos = inputText => {
    //this.forceUpdate();
    autoComplete.fornecedorProduto.text = inputText;
    if (String(inputText).length >= autoComplete.fornecedorProduto.min)
      return getFornecedoresProdutos(inputText).then(response =>
        response.data.result.map(item => {
          return {
            value: item.codigo,
            label: item.codigo + ' - ' + item.nome
          };
        })
      );
    return Promise.resolve();
  };

  loadFabricantes = inputText => {
    this.forceUpdate();
    autoComplete.fabricante.text = inputText;
    if (String(inputText).length >= autoComplete.fabricante.min)
      return getFabricantesProdutos(inputText).then(response =>
        response.data.result.map(item => {
          return {
            value: item.codigo,
            label: item.codigo + ' - ' + item.nome
          };
        })
      );
    return Promise.resolve();
  };

  autoCompleteMessage = ctrl => {
    if (ctrl.text.length < ctrl.min)
      return (
        'Inicie digitando pelo menos ' +
        ctrl.min +
        ' ' +
        oneOrMore(ctrl.min, 'caracter', 'caracteres') +
        ' para obter uma lista de ' +
        ctrl.list
      );

    return (
      'Não foi ' +
      heOrShe(ctrl.isHe, 'encontrado nenhum', 'encontrada nenhuma') +
      ' ' +
      ctrl.item +
      ' que contenha "' +
      ctrl.text +
      '"'
    );
  };

  async componentDidMount() {
    if (this.props.id) {
      this.loadProduto(this.props.id);
    } else {
      setFocus('codigo');
    }
  }

  validateData = async () => {
    let section = null,
      field = null,
      message = null;
    const state = this.state;

    const validateIdentificacao = () => {
      if (this.hasSectionIdentificacao()) {
        section = 'identificacao';
        if (!emptyText(state.codigoRapido) && sameInt(state.codigoRapido, 0)) {
          field = 'codigoRapido';
          message = 'Código rápido não deve ser zero.';
        } else if (sameInt(state.codigoRapido, state.codigo)) {
          field = 'codigoRapido';
          message = 'Código rápido não deve ser igual ao código.';
        } else if (emptySelect(state.situacao)) {
          field = 'situacao';
          message = 'A situação do produto deve ser informada.';
        } else if (emptyText(state.descricao)) {
          field = 'descricao';
          message = 'A descrição do produto deve ser informada.';
        } else if (emptyText(state.descricaoResumida)) {
          field = 'descricaoResumida';
          message = 'A descrição resumida do produto deve ser informada.';
        } else if (emptySelect(state.tipo)) {
          field = 'tipo';
          message = 'O tipo do produto deve ser selecionado.';
        } else if (emptySelect(state.subtipo)) {
          field = 'subtipo';
          message = 'O subtipo do produto deve ser selecionado.';
        } else if (emptyText(state.estruturaMercadologicaId)) {
          field = 'estruturaMercadologicaDescricao';
          message = 'A estrutura mercadológica deve ser selecionada.';
        }
      }
      return !message;
    };

    const validateClassificacaoFiscal = async () => {
      const isCestObrigatorio = async stateNcm => {
        if (
          stateNcm &&
          stateNcm.hasOwnProperty('obrigaCest') &&
          stateNcm.obrigaCest
        ) {
          return true;
        }
        const { data: dataNcm } = await getNCM(stateNcm.value);

        let result = false;
        dataNcm.result.forEach(n => {
          if (sameInt(n.codigo, stateNcm.value) && n.obrigaCest) {
            result = true;
            return;
          }
        });
        return result;
      };
      if (this.hasSectionClassificacaoFiscal()) {
        section = 'classificacaoFiscal';

        if (emptyAutoComplete(state.ncm)) {
          field = 'ncm';
          message = 'A NCM deve ser selecionada.';
        } else if (
          emptyAutoComplete(state.cest) &&
          (await isCestObrigatorio(state.ncm))
        ) {
          field = 'cest';
          message = 'O código CEST deve ser selecionado.';
        } // CEST apenas quando o NCM obrigar
        else if (this.isCombustivel() && emptyAutoComplete(state.anp)) {
          field = 'anp';
          message =
            'Quando o subtipo for combustíveis, o código da ANP deve ser selecionado.';
        }
      }
      return !message;
    };

    const validateMedidasQuantidades = () => {
      if (this.hasSectionMedidasQuantidades()) {
        section = 'medidasQuantidades';
        if (emptySelect(state.unidadeCompra)) {
          field = 'unidadeCompra';
          message = 'A unidade de compra do produto deve ser selecionada.';
        } else if (emptySelect(state.unidadeVenda)) {
          field = 'unidadeVenda';
          message = 'A unidade comercial de venda do produto deve ser selecionada.';
        } else if(emptySelect(state.unidadeVendaTributada)) {
          field = 'unidadeVendaTributada';
          message = 'A unidade tributável de venda do produto deve ser selecionada.';
        } else if (this.hasFatorConversao() && !this.state.fatorConversao) {
          field = 'fatorConversao';
          message = 'Para unidades de compra e de venda comercial distintas, informe o fator de conversão.';
        } else if(this.hasFatorConversaoTributada() && !this.state.fatorConversaoTributada) {
          field = 'fatorConversaoTributada';
          message = 'Para unidades de venda comercial e tributável distintas, informe o fator de conversão.';
        } else if (state.fatorConversao && state.fatorConversao > 9999999) {
          field = 'fatorConversao';
          message = 'O fator de conversão de compra deve ser no máximo 9.999.999.';
        } else if(state.fatorConversaoTributada && state.fatorConversaoTributada > 9999999) {
          field = 'fatorConversaoTributada';
          message = 'O fator de conversão de venda deve ser no máximo 9.999.999.';
        } else if(state.anp.value === codigoInternGLP) {
          if(state.percentualGLP && state.percentualGLP > 100) {
            field = 'percentualGLP';
            message = 'O percentual de Gás derivado de petróleo não deve ser superior a 100.';
          } else if(state.percentualGasNacional && state.percentualGasNacional > 100) {
            field = 'percentualGasNacional';
            message = 'O percentual de Gás natural nacional não deve ser superior a 100.';
          } else if(state.percentualGasImportado && state.percentualGasImportado > 100) {
            field = 'percentualGasImportado';
            message = 'O percentual de Gás natural importado não deve ser superior a 100.';
          } else if(state.totalPercGLP && state.totalPercGLP > 100) {
            field = 'percentualGLP';
            message = 'A composição total de GLP não deve ser superior a 100';
          }
        }
      }
      return !message;
    };

    const validateOutrasInformacoes = () => {
      if (this.hasSectionOutrasInformacoes()) {
        section = 'outrasInformacoes';
        if (emptySelect(state.setor)) {
          field = 'setor';
          message = 'O setor deve ser informado.';
        }
      }
      return !message;
    };

    const validatePrecosMargens = () => {
      if (this.hasSectionPrecosMargens()) {
        section = 'precosMargens'; // precisa aceitar preço de venda zerado - bug 6725

        /* if (
          !this.isKit() &&
          !state.naoPermiteVenda &&
          emptyText(state.precoVenda)
        ) {
          field = "precoVenda";
          message = "O preço de venda deve ser informado.";
        } else */ if (
          !this.isKit() &&
          !state.naoPermiteVenda &&
          emptySelect(state.formaReajuste)
        ) {
          field = 'formaReajuste';
          message = 'A forma de reajuste deve ser selecionada.';
        } else if (
          !this.isKit() &&
          !state.naoPermiteVenda &&
          emptyText(state.percentualReajuste)
        ) {
          field = 'percentualReajuste';
          message = 'O percentual de reajuste deve ser informado.';
        } else if (!this.isKit() && parseFloat(state.percentualReajuste) <= 0) {
          field = 'percentualReajuste';
          message = 'O percentual de reajuste deve ser superior a 0%.';
        } else if (
          !this.isKit() &&
          this.isMarkup() &&
          parseFloat(state.percentualReajuste) >= 1000
        ) {
          field = 'percentualReajuste';
          message =
            'Quando a forma de reajuste é margem markup, o percentual de reajuste deve ser inferior a 1000%.';
        } else if (
          !this.isKit() &&
          !this.isMarkup() &&
          parseFloat(state.percentualReajuste) > 99.99
        ) {
          field = 'percentualReajuste';
          message = 'O percentual de reajuste não pode ser superior a 99,99%.';
        } else if (!!state.desconto && parseFloat(state.desconto) > 99.99) {
          field = 'desconto';
          message =
            'Quando informado, o percentual de desconto não pode ser superior a 99,99%.';
        } else if (!!state.comissao && parseFloat(state.comissao) > 100) {
          field = 'comissao';
          message =
            'Quando informado, o percentual de comissão não pode ser superior a 100%.';
        }
      }
      return !message;
    };

    const validateNiveis = () => {
      if (this.hasSectionNiveisPrecos()) {
        section = 'niveisPrecos';
        for (let i = 0; i < state.niveisPrecos.length; i++) {
          if (state.niveisPrecos[i].value) {
            if (i === 0 && state.niveisPrecos.length === 1) {
              message =
                'É necessário informar pelo menos um nível de preço deste combustível.';
              break;
            } else if (i < state.niveisPrecos.length - 1) {
              message =
                'Se incluir um novo nível de preço é necessário informar o seu valor.';
              break;
            }
          }
          if (i > 0) {
            for (let j = 0; j < i; j++)
              if (
                sameFloat(
                  state.niveisPrecos[i].valor,
                  state.niveisPrecos[j].valor
                )
              ) {
                message = 'Não adicione o mesmo valor em mais de um nível.';
                break;
              }
            if (message) break;
          }
        }
      }
      return !message;
    };

    const validateFiscal = () => {
      if (this.hasSectionFiscal()) {
        if (!sameString(this.state.subtipo, Subtipo.Kit)) {
          section = 'fiscal';
          if (state.escalaNaoRelevante && emptyText(state.cnpjEscala)) {
            field = 'cnpjEscala';
            message =
              'Quando o produto for produzido em escala não relevante, informe o CNPJ do fabricante.';
          } else if (
            state.escalaNaoRelevante &&
            !validaCNPJ(state.cnpjEscala)
          ) {
            field = 'cnpjEscala';
            message =
              'CNPJ inválido para o fabricante de um produto produzido em escala não relevante.';
          } else if (state.escalaNaoRelevante && emptyText(state.razaoEscala)) {
            field = 'razaoEscala';
            message =
              'Quando o produto for produzido em escala não relevante, informe a razão social do fabricante.';
          }
        }
      }
      return !message;
    };

    const validateTributacoes = () => {
      if (this.hasSectionFiscal()) {
        for (let i = 0; i < state.tributacoes.length; i++) {
          if (!state.tributacoes[i].codigoGrupo) {
            if (i === 0 && state.tributacoes.length === 1) {
              message = 'É necessário informar pelo menos uma tributação.';
              break;
            } else if (i < state.tributacoes.length - 1) {
              message =
                'Se incluir uma nova tributação é necessário selecionar o grupo de impostos.';
              break;
            }
          } else if (state.tributacoes[i].fcp > 100) {
            message = 'O percentual do FCP não pode ser superior a 100%.';
            break;
          }
          if (i > 0)
            for (let j = 0; j < i; j++)
              if (
                sameString(
                  state.tributacoes[j].codigoGrupo,
                  state.tributacoes[i].codigoGrupo
                )
              ) {
                message =
                  'Não adicione o mesmo grupo de impostos mais de uma vez.';
                break;
              }
          if (message) break;
        }
      }
      return !message;
    };

    const validateTributacoesEstadoDestino = () => {
      if (this.hasSectionFiscal()) {
        for (let i = 0; i < state.tributacoesEstadoDestino.length; i++) {

            if (state.tributacoesEstadoDestino[i].aliquotaMVA > 1000) {
              message = 'O percentual do FCP não pode ser superior a 1000%.';
              break;
            }
            else if (state.tributacoesEstadoDestino[i].aliquotaICMS > 100) {
              message = 'O percentual do ICMS não pode ser superior a 100%.';
              break;
            }
            else if (state.tributacoesEstadoDestino[i].aliquotaFCP > 100) {
              message = 'O percentual do FCP não pode ser superior a 100%.';
              break;
            }

            if (i > 0)
              for (let j = 0; j < i; j++)
                if (
                  sameString(
                    state.tributacoesEstadoDestino[j].estadoId,
                    state.tributacoesEstadoDestino[i].estadoId
                  )
                ) {
                  message =
                    'Não adicione o mesmo estado mais de uma vez.';
                  break;
                }

          if (message) break;
        }
      }

      return !message;
    };

    const validateEstoque = () => {
      if (this.hasSectionEstoque()) {
        section = 'estoque';
        if (
          state.quantidadeMinima &&
          state.quantidadeMaxima &&
          parseFloat(state.quantidadeMaxima) <
            parseFloat(state.quantidadeMinima)
        ) {
          field = 'quantidadeMaxima';
          message =
            'A quantidade máxima não pode ser menor que a quantidade mínima.';
        }
      }
      return !message;
    };

    const validateAlmoxarifados = () => {
      if (this.hasSectionEstoque() && this.hasSubsectionAlmoxarifados()) {
        const list = state.almoxarifados;
        if (
          list.length === 0 ||
          (list.length === 1 && emptySelect(list[0].codigo))
        )
          message = 'É necessario informar ao menos um almoxarifado.';
        else
          for (let i = 0; i < list.length; i++) {
            if (sameString(list[i].codigo, none)) {
              message = 'Se incluir um novo almoxarifado selecione-o na lista.';
              break;
            }
            if (i > 0) {
              for (let j = 0; j < i; j++) {
                if (sameString(list[j].codigo, list[i].codigo)) {
                  message =
                    'Não adicione o mesmo almoxarifado mais de uma vez.';
                  break;
                }
                if (message) break;
              }
            }
          }
      }
      return !message;
    };

    const validateTipoAlmoxarifado = () => {
      if (this.hasSectionEstoque() && this.hasSubsectionAlmoxarifados()) {
        if (sameString(state.tipo, Tipo.MercadoriaRevenda)) {
          message = 'Informe ao menos um almoxarifado do tipo área de vendas.';
          for (let i = 0; i < state.almoxarifados.length; i++) {
            if (
              sameString(
                state.almoxarifados[i].tipo,
                TipoAlmoxarifado.AreaVendas
              )
            ) {
              message = '';
              break;
            }
          }
        }
      }
      return !message;
    };

    const validateFornecedores = () => {
      if (this.hasSectionFornecedores()) {
        section = 'fornecedores';
        for (let i = 0; i < state.fornecedores.length; i++) {
          if (state.fornecedores[i].fatorConversao > 9999) {
            message = 'O fator de conversão não pode ser superior a 9999.';
            break;
          }
          if (i > 0)
            for (let j = 0; j < i; j++) {
              if (
                state.fornecedores[j].fornecedor.value ===
                  state.fornecedores[i].fornecedor.value &&
                state.fornecedores[j].referencia ===
                  state.fornecedores[i].referencia
              ) {
                message =
                  'Não adicione o mesmo fornecedor com a mesma referência mais de uma vez.';
                break;
              }
              if (message) break;
            }
        }
      }
      return !message;
    };

    const validateSituacao = async () => {
      if (sameString(this.state.situacao, Situacao.InativoForaOperacao)) {
        message = await this.validaSituacaoEstoque();
      }

      if (message && message.length > 0) {
        showError(
          this,
          titulo,
          'Não é possível alterar a situação do produto para inativo fora de operação porque ' +
            unionArray(message) +
            '.',
          'situacao'
        );
      }
      if (message && message.length > 0) return false;
      else return true;
    };

    if (
      validateIdentificacao() &&
      (await validateClassificacaoFiscal()) &&
      validateMedidasQuantidades() &&
      validateOutrasInformacoes() &&
      validatePrecosMargens() &&
      validateNiveis() &&
      validateFiscal() &&
      validateTributacoes() &&
      validateTributacoesEstadoDestino() &&
      validateEstoque() &&
      validateAlmoxarifados() &&
      validateTipoAlmoxarifado() &&
      validateFornecedores() &&
      (await validateSituacao())
    )
      return true;

    if (!state.sections[section]) {
      const sections = state.sections;
      sections[section] = true;
      this.setState({ sections });
    }
    this.setState({ salvando: false });
    showError(this, titulo, message, field);
    return false;
  };

  getPrecoVendaCalculado = () => {
    const ccicms = this.state.custoComICMS ? this.state.custoComICMS : 0;
    const fixed = this.isCombustivel() ? 3 : 2;
    const custoComICMS = stringToFloatWithFixed(String(ccicms), fixed);
    const { percentualReajuste } = this.state;
    let result = 0,
      pr = 0;
    if (this.isMarkup()) {
      pr =
        percentualReajuste && percentualReajuste > 0 ? percentualReajuste : 99;
      result = custoComICMS * (1 + pr / 100);
      return parseFloat(result.toFixed(fixed));
    }

    if (this.isMargemBruta()) {
      pr =
        percentualReajuste && percentualReajuste > 0 ? percentualReajuste : 0;
      result = custoComICMS / ((100 - pr) / 100);
      return parseFloat(result.toFixed(fixed));
    }

    return result;
  };

  doSave = async () => {
    try {
      const [action] = this.isInserting() ? [incluirProduto] : [alterarProduto];
      await action(this.stateToApi());
      showInfo(
        this,
        titulo,
        `Produto ${
          this.isInserting()
            ? 'cadastrado'
            : this.isRestoring()
            ? 'restaurado'
            : 'alterado'
        } com sucesso!`,
        null,
        () => {
          hideAlert(this, this.props.history, Url);
        }
      );
    } catch (e) {
      showSaveError(this, titulo, e);
    }
    this.setState({ salvando: false });
  };

  validatePrecosEComposicoes = () => {
    const hideAndSave = () => {
      hideAlert(this);
      this.doSave();
    };

    const checkQuantidade = () => {
      const itensProd = this.state.itensComposicao;
      if (itensProd) {
        let result = true;
        itensProd.forEach(value => {
          const qtd = parseFloat(String(value.qtdeProduto).replace(',', '.'));
          if (!qtd || qtd < 0.001 || qtd > 99999.999) {
            result = false;
            return;
          }
          if (!result) {
            return; // finaliza o forEach, não precisa verificar os demais itens se um já está errado
          }
        });
        return result;
      }
      return true;
    };
    if (this.hasSectionComposicoes()) {
      //section = "composicoes";
      switch (this.state.subtipo) {
        case Subtipo.Composto:
          //#region Subtipo.Composto
          if (!checkQuantidade()) {
            showError(
              this,
              titulo,
              'Quantidade dos itens da composição do produto deve ser ' +
                'entre 0,001 e 99.999,999'
            );
            return;
          }
          const itensProd = this.state.itensComposicao;
          let pvd = this.state.precoVenda
            ? String(this.state.precoVenda)
            : itensProd.precoVendaDigitado
            ? itensProd.precoVendaDigitado
            : '0';
          let vs = itensProd.valorSugestao ? itensProd.valorSugestao : '0';
          if (!sameFloatWithFixed(pvd, vs, 2)) {
            showQuestion(
              this,
              titulo,
              /* "Valor do preço de venda está " +
              "diferente do valor de sugestão. Deseja manter assim?" */
              'Valor do preço de venda informado ' +
                '(R$ ' +
                toFloatFormattedDisplay(String(pvd).replace(',', '.')) +
                ') está diferente do valor de sugestão ' +
                '(R$ ' +
                toFloatFormattedDisplay(String(vs).replace(',', '.')) +
                '). \nDeseja alterar para o valor sugerido?',
              null,
              resp => {
                if (resp) {
                  itensProd.precoVendaDigitado = itensProd.valorSugestao
                    ? itensProd.valorSugestao
                    : '0';
                  const precoVenda = itensProd.precoVendaDigitado;
                  this.setState(
                    {
                      itensComposicao: itensProd,
                      precoVenda
                    },
                    hideAndSave
                  );
                } else {
                  const sections = this.state.sections;
                  sections['composicoes'] = true;
                  this.setState({ sections }, hideAndSave);
                }
              }
            );
          } else {
            this.doSave();
          }
          break;
        //#endregion
        case Subtipo.Kit:
          if (!checkQuantidade()) {
            showError(
              this,
              titulo,
              'Quantidade dos itens da composição do produto deve ser ' +
                'entre 0,001 e 99.999,999'
            );
            return;
          }
          const itensComposicao = this.state.itensComposicao;
          const v1 = stringToFloatWithFixed(itensComposicao.precoVenda, 2);
          const v2 = stringToFloatWithFixed(
            itensComposicao.precoVendaDigitado,
            2
          );
          if (parseFloat(v1) < 0 || parseFloat(v2) < 0) {
            showError(
              this,
              titulo,
              'Preço de venda do kit não pode ser negativo.'
            );
          } else {
            this.doSave();
          }
          break;
        case Subtipo.Embalagem:
          if (!checkQuantidade()) {
            showError(
              this,
              titulo,
              'Quantidade do item na embalagem deve ser ' +
                'entre 0,001 e 99.999,999'
            );
            return;
          } else {
            this.doSave();
          }
          break;

        default:
          this.doSave();
          break;
      }
    } else {
      //this.doSave();

      let action = null,
        message = '',
        field = '';

      if (
        sameInt(this.state.custoComICMS, 0) &&
        sameInt(this.state.precoVenda, 0)
      ) {
        message =
          'Não foi informado preço de venda.\n' +
          'Não será possível efetuar a venda do produto no PDV.\n' +
          'Deseja continuar?';

        field = 'precoVenda';

        action = resp => {
          if (resp) {
            hideAlert(this);
            this.doSave();
            return;
          } else {
            hideAlert(this);
          }
        };
      } else {
        const precoVendaAux = this.state.precoVenda ? this.state.precoVenda : 0;
        const precoVendaCalculadoAux = this.getPrecoVendaCalculado();
        const fixed = this.isCombustivel() ? 3 : 2;
        const precoVenda = parseFloat(precoVendaAux.toFixed(fixed));
        const precoVendaCalculado = parseFloat(
          precoVendaCalculadoAux.toFixed(fixed)
        );

        if (
          precoVendaCalculado > 0 &&
          !sameFloat(precoVendaCalculado, precoVenda)
        ) {
          message =
            'Preço de venda informado ' +
            '(R$ ' +
            toFloatFormattedDisplay(String(precoVenda)) +
            ') ' +
            'está diferente do calculado de acordo com o ' +
            'percentual de reajuste definido para o produto ' +
            '(R$ ' +
            toFloatFormattedDisplay(String(precoVendaCalculado)) +
            ').\nDeseja alterar o preço de venda para o valor calculado?';
          field = 'precoVenda';
          action = resp => {
            if (resp) {
              this.setState(
                {
                  precoVenda: precoVendaCalculado
                },
                () => {
                  hideAlert(this);
                  this.doSave();
                }
              );
            } else {
              this.doSave();
            }
            hideAlert(this);
          };
        }
      }

      if (action) {
        showQuestion(this, titulo, message, field, action);
      } else {
        this.doSave();
      }
    }

    this.setState({ salvando: false });
  };

  validateGLPbeforeSave = () => {
    if(this.state.anp.value === codigoInternGLP) {

      const checkTotaisGLP = () => {
        if(!this.state.totalPercGLP || (this.state.totalPercGLP !== 100)) {
            showQuestion(
              this,
              titulo,
              'Quando informado uma composição total de GLP inferior a 100%, as notas fiscais emitidas para esse produto podem ser recusadas.'
              + ' Deseja continuar mesmo assim?',
              null,
              resp => {
                if (resp) {
                  this.validatePrecosEComposicoes();
                } else {
                  hideAlert(this);
                  setFocus('percentualGLP');
                }
              }
            );
        } else {
          this.validatePrecosEComposicoes();
        }
      };

      if(this.state.unidadeVendaTributada !== codigoInternKg) {
        showQuestion(
          this,
          titulo,
          'Quando informada uma unidade de medida tributável diferente de KILOGRAMA, as notas fiscais emitidas para esse produto podem ser recusadas.'
          + ' Deseja continuar mesmo assim?',
          null,
          resp => {
            if (resp) {
              checkTotaisGLP();
            } else {
              hideAlert(this);
              setFocus('unidadeVendaTributada');
            }
          }
        );
      } else {
        checkTotaisGLP();
      }
    } else {
      this.validatePrecosEComposicoes();
    }

    this.setState({ salvando: false });
  };

  handleSalvar = async () => {
    this.setState({ salvando: true });

    showWait(this, titulo, 'Por favor, aguarde enquanto o registro é salvo.');

    if (!validaDadosLogin()) {
      this.setState({ salvando: false });
      hideWait(this);
      this.props.history.push('/');
    }

    const resultValidate = await this.validateData();

    if (resultValidate) {
      this.validateGLPbeforeSave();
    }
  };

  canDelete = () => {
    let message = '';
    const messages = [];
    if (this.state.almoxarifados)
      this.state.almoxarifados.forEach(a => {
        if (!sameFloat(a.saldo, 0))
          messages.push(
            'o almoxarifado ' +
              a.descricao +
              ' tem saldo ' +
              toFloatFormattedDisplay(String(a.saldo))
          );
      });
    if (messages.length > 0)
      message =
        'Não é possível excluir esse produto porque ' +
        unionArray(messages) +
        '.';

    if (!message) return true;
    showError(this, titulo, message);
    return false;
  };

  handleExcluir = () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    if (this.canDelete()) {
      showQuestion(
        this,
        titulo,
        'Deseja realmente excluir esse produto?',
        null,
        async resp => {
          if (resp) {
            try {
              await excluirProduto(this.state.id);
              showInfo(
                this,
                titulo,
                'Produto excluído com sucesso!',
                null,
                () => {
                  hideAlert(this, this.props.history, Url);
                }
              );
            } catch (e) {
              showDeleteError(this, titulo, e, null, () => {
                hideAlert(this);
              });
            }
          } else {
            hideAlert(this);
          }
        }
      );
    }
  };

  handleCancelar = async () => {
    if (this.isVerifying()) {
      this.props.history.push(Url);
    } else {
      showQuestion(
        this,
        titulo,
        'Deseja realmente cancelar a operação?',
        null,
        resp => {
          if (resp) hideAlert(this, this.props.history, Url);
          else hideAlert(this);
        }
      );
    }
  };

  codigoIconeClick = async () => {
    const { codigo } = this.state;
    try {
      const { data: response } = await verifyProduto(codigo);
      if (!!response.excluido) {
        showQuestion(
          this,
          titulo,
          'O produto com o código "' +
            codigo +
            '" foi excluído do sistema. Deseja restaurá-lo?',
          null,
          resp => {
            if (resp) {
              hideAlert(this);
              this.setState(this.apiToState(response));
              this.loadAllLazies(response.id);
            } else {
              hideAlert(this);
            }
          }
        );
      } else {
        showError(
          this,
          titulo,
          'O produto com o código "' + codigo + '" já está cadastrado!',
          'codigo'
        );
      }
    } catch (e) {
      this.setState({ gtin: isValidGTIN(codigo) });
    }
  };

  isVerifying = () => {
    return this.state.gtin === null;
  }; // true se clicou no botão "+" e está digitando campo(s) chave

  isEditing = () => {
    return !this.isVerifying() && !!this.state.rowVersion;
  }; // (!! para garantir false ) true se  clicou em um item da list

  isInserting = () => {
    return !this.isVerifying() && !this.state.rowVersion;
  }; // true se clicou no botão "+" e já digitou campo(s)

  isRestoring = () => {
    return !this.isVerifying() && !!this.state.excluido;
  };

  isCombustivel = () => {
    return sameString(this.state.subtipo, Subtipo.Combustivel);
  };

  stateSalvar = salvando => {
    this.setState({ salvando });
  };

  decimalScale = (
    isCombustivel = this.isCombustivel(),
    combustivelScale = 3,
    othersScale = 2
  ) => {
    return isCombustivel ? combustivelScale : othersScale;
  };

  isKit = () => {
    return sameString(this.state.subtipo, Subtipo.Kit);
  };

  isMarkup = () => {
    return sameString(this.state.formaReajuste, FormaReajuste.MargemMarkup);
  };

  isMargemBruta = () => {
    return sameString(this.state.formaReajuste, FormaReajuste.MargemBruta);
  };

  hasFatorConversao = () => {
    return (
      !emptySelect(this.state.unidadeCompra) &&
      !emptySelect(this.state.unidadeVenda) &&
      Number(this.state.unidadeCompra) !== Number(this.state.unidadeVenda)
    );
  };

  hasFatorConversaoTributada = () => {
    return (
      !emptySelect(this.state.unidadeVenda) &&
      !emptySelect(this.state.unidadeVendaTributada) &&
      Number(this.state.unidadeVenda) !== Number(this.state.unidadeVendaTributada)
    );
  };

  enableNaoPermitirVenda = () => {
    return (
      sameString(this.state.tipo, Tipo.MercadoriaRevenda) &&
      !sameString(this.state.subtipo, '')
    );
  };

  getNaoPermitirVenda = tipo => {
    return (
      sameString(tipo, Tipo.AtivoImobilizado) ||
      sameString(tipo, Tipo.MaterialUsoConsumo) ||
      sameString(tipo, Tipo.OutrosInsumos)
    );
  };

  enableBaixarEstoqueRecebimento = () => {
    return sameString(this.state.subtipo, Subtipo.Composto);
  };

  enableControlarEstoque = () => {
    switch (String(this.state.subtipo)) {
      case Subtipo.Composto:
        return (
          this.enableBaixarEstoqueRecebimento() &&
          !!this.state.baixaEstoqueRecebimento
        );
      case Subtipo.Kit:
      case Subtipo.None:
        return false;
      default:
        return true;
    }
  };

  getControlarEstoque = (tipo, subtipo) => {
    return !subtipo
      ? sameString(tipo, Tipo.AtivoImobilizado) ||
          sameString(tipo, Tipo.MaterialUsoConsumo) ||
          sameString(tipo, Tipo.OutrosInsumos)
      : !(
          sameString(subtipo, Subtipo.None) ||
          sameString(subtipo, Subtipo.Composto) ||
          sameString(subtipo, Subtipo.Kit)
        );
  };

  getSubtipo = tipo => {
    switch (String(tipo)) {
      case Tipo.AtivoImobilizado:
        return Subtipos.AtivoImobilizado;
      case Tipo.MaterialUsoConsumo:
        return Subtipos.MaterialUsoConsumo;
      case Tipo.MercadoriaRevenda:
        return Subtipos.MercadoriaRevenda;
      case Tipo.OutrosInsumos:
        return Subtipos.OutrosInsumos;
      default:
        return Subtipos.None;
    }
  };

  hasSectionIdentificacao = () => {
    return true;
  };

  hasSectionComposicoes = () => {
    return (
      this.state.subtipo === Subtipo.Kit ||
      this.state.subtipo === Subtipo.Composto ||
      this.state.subtipo === Subtipo.Embalagem
    );
  };
  hasSectionClassificacaoFiscal = () => {
    return !this.isVerifying() && !sameString(this.state.subtipo, Subtipo.Kit);
  };

  hasSectionMedidasQuantidades = () => {
    return !this.isVerifying();
  };

  hasSectionOutrasInformacoes = () => {
    return !this.isVerifying();
  };

  hasSectionPrecosMargens = () => {
    return !this.isVerifying();
  };

  hasSectionNiveisPrecos = () => {
    return !this.isVerifying() && this.isCombustivel();
  };

  hasSectionFiscal = () => {
    return !this.isVerifying() && !sameString(this.state.subtipo, Subtipo.Kit);
  };

  hasSectionEstoque = () => {
    return !this.isVerifying();
  };

  hasSubsectionAlmoxarifados = () => {
    return (
      this.hasSectionEstoque() &&
      !sameString(this.state.subtipo, Subtipo.Combustivel)
    );
  };

  hasSectionFornecedores = () => {
    return !this.isVerifying();
  };

  clearEstruturaMercadologica = () => {
    this.setState({
      estruturaMercadologicaId: null,
      estruturaMercadologicaDescricao: ''
    });
  };

  handleItensProdutos = itensComposicao => {
    this.setState({ itensComposicao });

    if (itensComposicao.precoVendaDigitado)
      this.setState({ precoVenda: itensComposicao.precoVendaDigitado });
  };

  podeMudarTipo = async (errorMessage, field) => {
    let message = [];
    message = await this.validaSituacaoEstoque();
    if (message.length > 0) {
      showError(this, titulo, errorMessage + unionArray(message) + '.', field);
      return false;
    }
    if (this.isCombustivel()) {
      const { id } = this.state;
      const { data: dataTanques } = await getTanques(getCnpj());
      let result = true;
      dataTanques.result.forEach(t => {
        if (sameInt(id, t.codigoProduto)) {
          showError(
            this,
            titulo,
            errorMessage +
              'o combustível está vinculado ao tanque ' +
              t.numeroTanque +
              '.',
            field
          );
          result = false;
          return;
        }
      });
      return result;
    }

    return true;
  };

  carregarComposicao = async () => {
    //const { data: responseComposicao } = await getProdutoComposicao(4883);]
    let tempItensProdutoComposicao = [];
    tempItensProdutoComposicao.precoVendaDigitado = 0;
    if (!this.isInserting()) {
      const { data: responseComposicao } = await getProdutoComposicao(
        this.props.id
      );
      var icp = responseComposicao.itens;

      var precoVendaTotal = 0;
      var valorCustoTotal = 0;

      for (let i = 0; i < icp.length; i++) {
        const e = icp[i];
        var tipoDescontoAjustado = 0;
        var precoVendaKit = 0;

        var precoVendaAtual = e.produto.precosMargens
          ? e.quantidade * e.produto.precosMargens.precoVenda
          : 0;

        if (!e.inativo) {
          valorCustoTotal += e.produto.precosMargens
            ? e.produto.precosMargens.custoComICMS * e.quantidade
            : 0;
        }

        switch (e.tipoDesconto) {
          case 'V':
            tipoDescontoAjustado = 1;
            precoVendaKit = precoVendaAtual - e.quantidade * e.valor;
            break;

          case 'T':
            tipoDescontoAjustado = 2;
            precoVendaKit = precoVendaAtual - e.valor;
            break;

          case 'F':
            tipoDescontoAjustado = 3;
            precoVendaKit = e.valor;
            break;

          case 'P':
            tipoDescontoAjustado = 4;
            precoVendaKit = precoVendaAtual - (precoVendaAtual * e.valor) / 100;
            break;

          default:
            break;
        }

        const objProd = {
          ativo: !e.inativo,
          id: e.produto.id,
          unidadeVenda: e.unidadeVenda ? e.unidadeVenda.codigo : null,
          codigoProduto: e.produto.codigo,
          codigoRapido: e.produto.codigoRapido,
          custoAtual: e.produto.precosMargens
            ? e.produto.precosMargens.custoComICMS
            : 0,
          desconto: e.valor,
          descricaoProduto: e.produto.descricao,
          precoVenda: e.produto.precosMargens
            ? e.produto.precosMargens.precoVenda
            : 0,
          qtdeProduto: e.quantidade,
          tipoDesconto: tipoDescontoAjustado,
          valorFinal: e.produto.precosMargens
            ? e.quantidade * e.produto.precosMargens.precoVenda
            : 0,
          valorVendaAtualKit: e.produto.precosMargens
            ? e.quantidade * e.produto.precosMargens.precoVenda
            : 0,
          valorVendaAtualComposto: e.produto.precosMargens
            ? parseFloat(
                e.quantidade * e.produto.precosMargens.precoVenda
              ).toFixed(2)
            : 0,
          valorFinalKitCalculado: precoVendaKit.toFixed(2),
          valorFinalCompostoCalculado: e.produto.precosMargens
            ? parseInt(this.state.formaReajuste) === 1
              ? (e.produto.precosMargens.custoComICMS /
                  (1 - this.state.percentualReajuste / 100)) *
                e.quantidade
              : (1 + this.state.percentualReajuste / 100) *
                e.produto.precosMargens.custoComICMS *
                e.quantidade
            : 0
        };

        if (this.state.subtipo === Subtipo.Composto)
          objProd.custoAtual = objProd.custoAtual
            ? objProd.custoAtual.toString().replace('.', ',')
            : 0;

        objProd.valorFinalCompostoCalculado = parseFloat(
          objProd.valorFinalCompostoCalculado
        ).toFixed(2);

        objProd.valorVendaAtualKit = parseFloat(
          objProd.valorVendaAtualKit
        ).toFixed(2);
        objProd.valorVendaAtualComposto = parseFloat(
          objProd.valorVendaAtualComposto
        ).toFixed(2);

        objProd.valorVendaAtualKit = objProd.valorVendaAtualKit
          .toString()
          .replace('.', ',');
        objProd.valorVendaAtualComposto = objProd.valorVendaAtualComposto
          .toString()
          .replace('.', ',');

        objProd.valorFinalKitCalculado = objProd.valorFinalKitCalculado.replace(
          '.',
          ','
        );
        objProd.valorFinalCompostoCalculado = objProd.valorFinalCompostoCalculado.replace(
          '.',
          ','
        );

        objProd.desconto = objProd.desconto
          ? objProd.desconto.toString().replace('.', ',')
          : 0;

        tempItensProdutoComposicao.push(objProd);
        tempItensProdutoComposicao.precoVendaDigitado += parseFloat(
          objProd.valorFinalKitCalculado.replace(',', '.')
        );
      }

      if (this.state.formaReajuste === 1)
        precoVendaTotal =
          valorCustoTotal / (1 - this.state.percentualReajuste / 100);
      else if (this.state.formaReajuste === 2)
        precoVendaTotal =
          valorCustoTotal * (1 + this.state.percentualReajuste / 100);

      precoVendaTotal = parseFloat(precoVendaTotal).toFixed(2);
      valorCustoTotal = parseFloat(valorCustoTotal).toFixed(4);
      tempItensProdutoComposicao.precoVendaDigitado = parseFloat(
        tempItensProdutoComposicao.precoVendaDigitado
      ).toFixed(2);

      if (sameString(this.state.subtipo, Subtipo.Composto)) {
        tempItensProdutoComposicao.valorCustoTotal = valorCustoTotal.replace(
          '.',
          ','
        );
        tempItensProdutoComposicao.valorSugestao = precoVendaTotal.replace(
          '.',
          ','
        );
        tempItensProdutoComposicao.precoVendaDigitado = this.state.precoVenda;
      }

      if (tempItensProdutoComposicao.precoVendaDigitado)
        tempItensProdutoComposicao.precoVendaDigitado = tempItensProdutoComposicao.precoVendaDigitado
          .toString()
          .replace('.', ',');
    }
    this.setState({
      composicoesLoaded: true,
      itensComposicao: tempItensProdutoComposicao
    });
    return true;
  };

  getTipoAlmoxarifado = value => {
    const list = this.props.lists.almoxarifados;
    let result = null;
    for (let i = 0; i < list.length; i++) {
      if (sameString(list[i].value, value)) {
        result = list[i].tipo;
        break;
      }
    }
    return result;
  };

  closeEstruturaMercadologica = (estruturaMercadologica, message) => {
    let state = this.state;
    if (state.searchEM && state.searchEM.active) {
      state.searchEM.active = false;
    }
    if (estruturaMercadologica) {
      this.setState({
        ...state,
        estruturaMercadologicaId: estruturaMercadologica.id,
        estruturaMercadologicaDescricao: estruturaMercadologica.descricao
      });
    } else {
      this.setState(state);
      if (message)
        showError(this, titulo, message, 'estruturaMercadologicaDescricao');
    }
  };

  searchEstruturaMercadologicaClick = () => {
    searchEstruturaMercadologica(
      this,
      this.state.estruturaMercadologicaDescricao,
      this.closeEstruturaMercadologica
    );
  };

  validaSituacaoEstoque = async () => {
    let message = [];
    let almoxarifados = this.state.almoxarifados;
    //em caso de falha para carregar os almoxarifados....
    if (almoxarifados && almoxarifados.length === 0)
      await this.loadEstoque(this.state.id);

    almoxarifados = this.state.almoxarifados;

    if (almoxarifados)
      almoxarifados.forEach(a => {
        if (!sameFloat(a.saldo, 0))
          message.push(
            'o almoxarifado ' +
              a.descricao +
              ' tem saldo ' +
              toFloatFormattedDisplay(String(a.saldo))
          );
      });
    return message;
  };

  render() {
    const {
      situacao,
      favorito,
      codigo,
      gtin,
      descricao,
      descricaoResumida,
      marca,
      tipo,
      subtipo,
      ncm,
      cest,
      anp,
      unidadeVenda,
      unidadeVendaTributada,
      fatorConversaoTributada,
      unidadeCompra,
      fatorConversao,
      percentualGLP,
      percentualGasNacional,
      percentualGasImportado,
      fabricante,
      setor,
      naoPermiteVenda,
      controlaEstoque,
      baixaEstoqueRecebimento,

      // PREÇOS E MARGENS //
      precoVenda,
      precoVendaVigente,
      precoCompra,
      custoSemICMS,
      custoComICMS,
      formaReajuste,
      percentualReajuste,
      comissao,
      niveisPrecos,
      aliquotaMVAOriginal,
      codigoSped,
      contaSpedCredito,
      contaSpedDebito,
      escalaNaoRelevante,
      cnpjEscala,
      razaoEscala,
      codigoBeneficio,
      //estoque,
      quantidadeMinima,
      quantidadeMaxima,
      custoMedio,
      dataCompra,
      dataVenda,
      sections,
      tributacoes
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent
              // identificacao
              title={`${
                this.isVerifying()
                  ? 'Identificação do novo produto'
                  : 'Identificação do produto'
              }`}
              visible={this.hasSectionIdentificacao()}
              //accordion
              accordionDisplay={sections.identificacao}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    identificacao: !sections.identificacao
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-3">
                  <InputText
                    value={codigo}
                    name="codigo"
                    label="Código:"
                    type="number"
                    icone={`${
                      this.isVerifying() && Number(codigo)
                        ? 'icon-lx-search'
                        : ''
                    }`}
                    format="##############"
                    help={`${
                      isValidGTIN(codigo)
                        ? 'GTIN-' + String(codigo).length
                        : this.isVerifying()
                        ? 'Informe um código númerico (ou GTIN)'
                        : ''
                    }`}
                    onIconeClick={this.codigoIconeClick}
                    onKeyDown={
                      this.isVerifying() && Number(codigo)
                        ? event => {
                            if (event.keyCode === 13) {
                              this.codigoIconeClick();
                            } else if (event.keyCode === 27) {
                              this.setState({ codigo: '' });
                            }
                          }
                        : null
                    }
                    disabled={!this.isVerifying()}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(!this.isVerifying() && false, 'col-3')}>
                  <Checkbox label="GTIN:">
                    <Checkitem
                      checked={gtin}
                      name="gtin"
                      label="Válido"
                      disabled
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
                <div className={ifShow(!this.isVerifying(), 'col-3')}>
                  <InputText
                    value={this.state.codigoRapido}
                    name="codigoRapido"
                    label="Código rápido:"
                    type="number"
                    format="####"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div
                  className={ifShow(
                    !this.isVerifying() && !this.isInserting(),
                    'col-3'
                  )}
                >
                  <Select
                    value={situacao}
                    name="situacao"
                    label="Situação:"
                    options={this.props.lists.situacoes}
                    onChange={async e => {
                      let message = [];
                      let valorSituacao = e.target.value;
                      if (
                        sameString(e.target.value, Situacao.InativoForaOperacao)
                      ) {
                        message = await this.validaSituacaoEstoque();
                      }

                      if (message.length > 0) {
                        showError(
                          this,
                          titulo,
                          'Não é possível alterar a situação do produto para inativo fora de operação porque ' +
                            unionArray(message) +
                            '.',
                          'situacao'
                        );
                      } else {
                        this.setState({ situacao: valorSituacao });
                      }
                    }}
                  />
                </div>
                <div className={ifShow(!this.isVerifying(), 'col-3')}>
                  <Select
                    value={favorito}
                    name="favorito"
                    label="Favorito:"
                    onChange={this.handleInputChange}
                    options={[
                      { value: 0, label: 'Nenhuma estrela' },
                      { value: 1, label: '1 estrela' },
                      { value: 2, label: '2 estrelas' },
                      { value: 3, label: '3 estrelas' },
                      { value: 4, label: '4 estrelas' },
                      { value: 5, label: '5 estrelas' }
                    ]}
                  />
                </div>
              </div>
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-12">
                  <InputText
                    value={descricao}
                    name="descricao"
                    label="Descrição:"
                    maxlength={100}
                    onChange={event => {
                      this.setState({
                        descricao: event.target.value,
                        descricaoResumida:
                          String(this.state.descricao).substr(0, 26) ===
                            String(this.state.descricaoResumida).substr(
                              0,
                              26
                            ) || !this.state.descricaoResumida
                            ? String(event.target.value).substr(0, 26)
                            : this.state.descricaoResumida
                      });
                    }}
                  />
                </div>
              </div>
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-6">
                  <InputText
                    value={descricaoResumida}
                    name="descricaoResumida"
                    label="Descrição resumida:"
                    maxlength={26}
                    icone={
                      String(descricao).substr(0, 26) !==
                      String(descricaoResumida).substr(0, 26)
                        ? 'icon-lx-clipboard'
                        : ''
                    }
                    onIconeClick={() => {
                      this.setState({
                        descricaoResumida: String(descricao).substr(0, 26)
                      });
                    }}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-6">
                  <InputText
                    value={marca}
                    name="marca"
                    label="Marca:"
                    maxlength={20}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-6">
                  <Select
                    value={tipo}
                    name="tipo"
                    label={'Tipo:'}
                    options={Tipos}
                    onChange={async event => {
                      const tipo = event.target.value;
                      if (
                        await this.podeMudarTipo(
                          'Não é possível alterar o tipo do produto porque ',
                          'tipo'
                        )
                      ) {
                        this.setState({
                          tipo,
                          subtipo: this.getSubtipo(tipo).default,
                          naoPermiteVenda: this.getNaoPermitirVenda(tipo),
                          controlaEstoque: this.getControlarEstoque(tipo)
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-6">
                  <Select
                    value={subtipo}
                    name="subtipo"
                    label="Subtipo:"
                    options={this.getSubtipo(this.state.tipo).list}
                    onChange={async event => {
                      const subtipo = event.target.value;
                      if (
                        await this.podeMudarTipo(
                          'Não é possível alterar o subtipo do produto porque ',
                          'tipo'
                        )
                      ) {
                        let setor = this.state.setor;
                        if (sameString(subtipo, Subtipo.Combustivel)) {
                          setor = Setor.Pista;
                        }
                        if (!sameString(subtipo, Subtipo.Kit)) {
                          if (tributacoes.length === 0)
                            tributacoes.push({
                              codigoGrupo: null,
                              descricaoGrupo: null,
                              fcp: null
                            });
                        }
                        this.setState({
                          subtipo,
                          setor,
                          naoPermiteVenda: this.getNaoPermitirVenda(
                            tipo,
                            subtipo
                          ),
                          controlaEstoque: this.getControlarEstoque(
                            tipo,
                            subtipo
                          )
                        });
                      }
                    }}
                    disabled={!this.getSubtipo(tipo).enabled}
                  />
                </div>
              </div>
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-12">
                  <InputText
                    value={this.state.estruturaMercadologicaDescricao}
                    name="estruturaMercadologicaDescricao"
                    label="Estrutura mercadológica:"
                    maxlength={65}
                    icone={
                      this.state.estruturaMercadologicaId
                        ? 'icon-lx-close'
                        : 'icon-lx-search'
                    }
                    help={
                      this.state.estruturaMercadologicaId
                        ? 'Clique no "x" para limpar o campo'
                        : 'Clique na "lupa" para pesquisar'
                    }
                    placeholder="Digite a descrição parcial da estrutura mercadológica."
                    onIconeClick={() => {
                      if (this.state.estruturaMercadologicaId) {
                        this.clearEstruturaMercadologica();
                        setFocus('estruturaMercadologicaDescricao');
                      } else this.searchEstruturaMercadologicaClick();
                    }}
                    onKeyDown={event => {
                      if (
                        event.keyCode === 13 &&
                        !this.state.estruturaMercadologicaId
                      )
                        this.searchEstruturaMercadologicaClick();
                      else if (event.keyCode === 27)
                        this.clearEstruturaMercadologica();
                    }}
                    onChange={
                      !this.state.estruturaMercadologicaId &&
                      this.handleInputChange
                    }
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              // classificacaoFiscal
              title="Classificação fiscal"
              visible={this.hasSectionClassificacaoFiscal()}
              //visible={!this.isVerifying() && !sameString(subtipo, Subtipo.Kit)}
              accordion
              accordionDisplay={sections.classificacaoFiscal}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    classificacaoFiscal: !sections.classificacaoFiscal
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-12">
                  <AutoComplete
                    value={ncm}
                    name="ncm"
                    label="NCM:"
                    loadOptions={this.loadNCM}
                    noOptionsMessage={this.autoCompleteMessage(
                      autoComplete.ncm
                    )}
                    onChange={this.handleInputChange}
                    onFocus={() => {
                      autoComplete.ncm.text = '';
                      this.forceUpdate();
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <AutoComplete
                    value={cest}
                    name="cest"
                    label="CEST:"
                    loadOptions={this.loadCEST}
                    noOptionsMessage={this.autoCompleteMessage(
                      autoComplete.cest
                    )}
                    onChange={this.handleInputChange}
                    onFocus={() => {
                      autoComplete.cest.text = '';
                      this.forceUpdate();
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <AutoComplete
                    value={anp}
                    name="anp"
                    label="Código ANP:"
                    loadOptions={this.loadANP}
                    noOptionsMessage={this.autoCompleteMessage(
                      autoComplete.anp
                    )}
                    onChange={this.handleInputChange}
                    onFocus={() => {
                      autoComplete.anp.text = '';
                      this.forceUpdate();
                    }}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              // medidasQuantidades
              title="Medidas e quantidades"
              visible={this.hasSectionMedidasQuantidades()}
              //visible={!this.isVerifying()}
              accordion
              accordionDisplay={sections.medidasQuantidades}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    medidasQuantidades: !sections.medidasQuantidades
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-4">
                  <span>Venda</span>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Select
                    value={unidadeVenda}
                    name="unidadeVenda"
                    label="Unidade comercial (embalagem):"
                    options={this.props.lists.unidadesVenda}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <Select
                    value={unidadeVendaTributada}
                    name="unidadeVendaTributada"
                    label="Unidade tributável (itens da embalagem):"
                    options={this.props.lists.unidadesVenda}
                    onChange={this.handleInputChange}
                  />
                </div>
                {this.hasFatorConversaoTributada() &&
                  <div className="col-4">
                    <InputText
                      value={fatorConversaoTributada}
                      name="fatorConversaoTributada"
                      label="Fator de conversão venda:"
                      type="number"
                      decimalScale={3}
                      onChange={this.handleInputChange}
                    />
                  </div>
                }
              </div>
              <div className="row">
                <div className="col-4">
                  <span>Compra</span>
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Select
                    value={unidadeCompra}
                    name="unidadeCompra"
                    label="Unidade:"
                    options={this.props.lists.unidadesCompra}
                    onChange={this.handleInputChange}
                  />
                </div>
                {this.hasFatorConversao() &&
                  <div className="col-4">
                    <InputText
                      value={fatorConversao}
                      name="fatorConversao"
                      label="Fator de conversão:"
                      type="number"
                      decimalScale={3}
                      onChange={this.handleInputChange}
                    />
                  </div>
                }
              </div>
              {anp.value === codigoInternGLP && (
                <>
                <div className="row">
                  <div className="col-4">
                    <span>Composição GLP</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <InputText
                      value={percentualGLP}
                      name="percentualGLP"
                      label="% Gás derivado de petróleo:"
                      type="number"
                      maxlength={3}
                      decimalScale={2}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-3">
                    <InputText
                      value={percentualGasNacional}
                      name="percentualGasNacional"
                      label="% Gás natural nacional:"
                      type="number"
                      maxlength={3}
                      decimalScale={2}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-3">
                    <InputText
                      value={percentualGasImportado}
                      name="percentualGasImportado"
                      label="% Gás importado:"
                      type="number"
                      maxlength={3}
                      decimalScale={2}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className="col-3">
                    <InputText
                      value={sumNumbersInArray([percentualGLP, percentualGasNacional, percentualGasImportado])}
                      name="totalPercGLP"
                      label="Total:"
                      type="number"
                      decimalScale={2}
                      disabled
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>
              </>
              )}
            </SectionContent>
            <SectionContent
              // outrasInformacoes
              title="Outras informações"
              visible={this.hasSectionOutrasInformacoes()}
              // visible={!this.isVerifying()}
              accordion
              accordionDisplay={sections.outrasInformacoes}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    outrasInformacoes: !sections.outrasInformacoes
                  }
                });
              }}
            >
              <div className="row">
                <div className="col-6">
                  <AutoComplete
                    value={fabricante}
                    name="fabricante"
                    label="Fabricante:"
                    loadOptions={this.loadFabricantes}
                    noOptionsMessage={this.autoCompleteMessage(
                      autoComplete.fabricante
                    )}
                    onChange={this.handleInputChange}
                    onFocus={() => {
                      autoComplete.fabricante.text = '';
                      this.forceUpdate();
                    }}
                  />
                </div>

                <div className="col-6">
                  <Select
                    value={setor}
                    name="setor"
                    label="Setor:"
                    disabled={sameString(subtipo, Subtipo.Combustivel)}
                    options={this.props.lists.setores}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <Checkbox>
                    <Checkitem
                      checked={naoPermiteVenda}
                      name="naoPermiteVenda"
                      label="Não permitir venda"
                      disabled={!this.enableNaoPermitirVenda()}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
                <div
                  className={ifShow(
                    this.enableBaixarEstoqueRecebimento(),
                    'col-4'
                  )}
                >
                  <Checkbox>
                    <Checkitem
                      checked={baixaEstoqueRecebimento}
                      name="baixaEstoqueRecebimento"
                      label="Baixar estoque no recebimento"
                      onChange={event => {
                        this.setState({
                          baixaEstoqueRecebimento: event.target.checked,
                          controlaEstoque: event.target.checked
                            ? this.state.baixaEstoqueRecebimento
                            : false
                        });
                      }}
                    />
                  </Checkbox>
                </div>
                <div className="col-3">
                  <Checkbox>
                    <Checkitem
                      checked={controlaEstoque}
                      name="controlaEstoque"
                      label="Controlar estoque"
                      disabled={!this.enableControlarEstoque()}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
            </SectionContent>
            <SectionContent
              // precosMargens
              title="Preços e margens"
              visible={this.hasSectionPrecosMargens()}
              //visible={!this.isVerifying()}
              accordion
              accordionDisplay={sections.precosMargens}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    precosMargens: !sections.precosMargens
                  }
                });
                this.loadPrecosMargens();
              }}
            >
              <div className="row">
                <div className="col-4">
                  <InputText
                    value={precoVenda}
                    name="precoVenda"
                    label="Preço de venda:"
                    type="number"
                    decimalScale={this.decimalScale()}
                    disabled={this.isKit()}
                    help={`${
                      this.isCombustivel() && parseFloat(precoVenda) >= 10
                        ? 'ATENÇÃO: VALOR SUPERIOR A R$ 9,999'
                        : ''
                    }`}
                    onChange={event => {
                      if (this.isCombustivel()) {
                        if (
                          this.state.niveisPrecos &&
                          this.state.niveisPrecos.length > 0
                        ) {
                          const niveisPrecos = this.state.niveisPrecos;
                          niveisPrecos[0].valor = event.floatValue;
                          this.setState({
                            niveisPrecos,
                            precoVenda: event.floatValue
                          });
                        } else this.handleInputChange(event);
                      } else this.handleInputChange(event);
                    }}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={precoVendaVigente}
                    name="precoVendaVigente"
                    label="Preço de venda vigente:"
                    type="number"
                    decimalScale={this.decimalScale()}
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <InputText
                    value={precoCompra}
                    name="precoCompra"
                    label="Preço de compra:"
                    type="number"
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={custoComICMS}
                    name="custoComICMS"
                    label="Custo com ICMS:"
                    type="number"
                    decimalScale={4}
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={custoSemICMS}
                    name="custoSemICMS"
                    label="Custo sem ICMS:"
                    type="number"
                    decimalScale={4}
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className={ifShow(!this.isKit(), 'col-4')}>
                  <Select
                    value={formaReajuste}
                    name="formaReajuste"
                    label="Forma de reajuste:"
                    options={this.props.lists.formasReajuste}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(!this.isKit(), 'col-4')}>
                  <InputText
                    value={percentualReajuste}
                    name="percentualReajuste"
                    label="Percentual de reajuste:"
                    type="number"
                    decimalScale={2}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={comissao}
                    name="comissao"
                    label="Comissão (%):"
                    type="number"
                    decimalScale={2}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              // niveisPrecos
              title="Níveis de preços"
              visible={this.hasSectionNiveisPrecos()}
              //visible={!this.isVerifying() && (this.state.subtipo === Subtipo.Combustivel)}
              accordion
              accordionDisplay={sections.niveisPrecos}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    niveisPrecos: !sections.niveisPrecos
                  }
                });
                this.loadNiveisPrecos();
              }}
            >
              <ReactTable
                data={niveisPrecos}
                showPagination={false}
                defaultPageSize={5}
                columns={[
                  {
                    accessor: 'nivel',
                    Header: 'Nível',
                    sortable: false,
                    width: 80,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.nivel}
                          name="nivel"
                          disabled
                        />
                      );
                    }
                  },
                  {
                    accessor: 'valor',
                    Header: 'Valor',
                    width: 280,
                    sortable: false,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.valor}
                          name="valor"
                          type="number"
                          decimalScale={3}
                          placeholder="Digite o valor"
                          maxlength={8}
                          help={`${
                            this.isCombustivel() &&
                            parseFloat(props.row.valor) >= 10
                              ? 'ATENÇÃO: VALOR SUPERIOR A R$ 9,999'
                              : ''
                          }`}
                          onChange={event => {
                            changeInList(
                              this,
                              'niveisPrecos',
                              'valor',
                              event.floatValue,
                              props.row._index
                            );
                            if (props.row._index === 0)
                              this.setState({ precoVenda: event.floatValue });
                          }}
                        />
                      );
                    }
                  },
                  {
                    width: 75,
                    filterable: false,
                    sortable: false,
                    Cell: props => {
                      if (props.row._index < this.state.niveisPrecos.length - 1)
                        return (
                          <>
                            <Botao
                              ic
                              icon={'icon-lx-trash'}
                              onClick={() => {
                                deleteInList(
                                  this,
                                  'niveisPrecos',
                                  props.row._index,
                                  'nivel'
                                );
                              }}
                            />
                          </>
                        );
                      else if (this.state.niveisPrecos.length < 5)
                        return (
                          <>
                            <Botao
                              ic
                              icon={'icon-lx-plus'}
                              secondary
                              onClick={() => {
                                appendInList(this, 'niveisPrecos', {
                                  nivel: niveisPrecos.length + 1,
                                  valor: ''
                                });
                              }}
                            />
                          </>
                        );
                    }
                  }
                ]}
              />
            </SectionContent>
            <SectionContent
              // fiscal
              title="Fiscal"
              visible={this.hasSectionFiscal()}
              //visible={!this.isVerifying() && !sameString(subtipo, Subtipo.Kit)}
              accordion
              accordionDisplay={sections.fiscal}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    fiscal: !sections.fiscal
                  }
                });
                this.loadFiscal();
              }}
            >
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <label className="input-label">Tributação para venda</label>
                      <div style={{paddingBottom: "10px"}}>
                        <Botao
                          ic
                          tooltip="Adicionar tributação"
                          icon={'icon-lx-plus'}
                          onClick={() => {
                            appendInList(this, 'tributacoes', {
                              codigoGrupo: '',
                              descricaoGrupo: '',
                              impostos: [
                                {
                                  codigo: '',
                                  descricaoImposto: '',
                                  situacaoTributaria: '',
                                  tipoAliquota: '',
                                  descricaoAliquota: '',
                                  percentualValor: '',
                                  percentualValorReduzido: '',
                                  quantidade: ''
                                }
                              ],
                              fcp: ''
                            });
                          }}
                        />
                      </div>
                      <div className="table-ctn">
                        <ReactTable
                          data={this.state.tributacoes}
                          columns={[
                            {
                              accessor: 'codigoGrupo',
                              Header: 'Tributação (Obrigatório)',
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <Select
                                    value={props.row.codigoGrupo}
                                    name="row.codigoGrupo"
                                    options={this.props.lists.gruposImpostos}
                                    onChange={event =>
                                      changeInList(
                                        this,
                                        'tributacoes',
                                        'codigoGrupo',
                                        event.target.value,
                                        props.row._index
                                      )
                                    }
                                  />
                                );
                              }
                            },
                            {
                              accessor: 'fcp',
                              Header: 'FCP',
                              width: 150,
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <InputText
                                    value={props.row.fcp}
                                    name="row.fcp"
                                    type="number"
                                    decimalScale={2}
                                    onChange={event =>
                                      changeInList(
                                        this,
                                        'tributacoes',
                                        'fcp',
                                        event.floatValue,
                                        props.row._index
                                      )
                                    }
                                  />
                                );
                              }
                            },
                            {
                              width: 75,
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <>
                                    <Botao
                                      ic
                                      icon={'icon-lx-trash'}
                                      onClick={() => {
                                        deleteInList(
                                          this,
                                          'tributacoes',
                                          props.row._index
                                        );
                                      }}
                                    />
                                  </>
                                );
                              }
                            }
                          ]}
                          defaultPageSize={5}
                          previousText="Anterior"
                          nextText="Próximo"
                          loadingText="Carregando registros..."
                          noDataText="Sem registros para exibir"
                          pageText="Página"
                          ofText="de"
                          rowsText="registros"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                      <div className="col-12">
                        <InputText
                            value={aliquotaMVAOriginal}
                            name="aliquotaMVAOriginal"
                            label="MVA / IVA original (%):"
                            type="number"
                            decimalScale={2}
                            maxlength="6"
                            onChange={this.handleInputChange}
                          />
                      </ div>
                  </div>
                </div>
                <div className="col-6">
                    <label className="input-label">Tributação para estado de destino</label>
                    <div className="col-12">
                      <div style={{paddingBottom: "10px"}}>
                        <Botao
                          ic
                          tooltip="Adicionar tributação estado de destino"
                          icon={'icon-lx-plus'}
                          onClick={() => {
                            appendInList(this, 'tributacoesEstadoDestino', {
                              estadoId: 0,
                              descricaoEstado: '',
                              aliquotaMVA: 0,
                              aliquotaICMS: 0,
                              aliquotaFCP: 0
                            });
                          }}
                        />
                      </div>
                      <div className="table-ctn">
                        <ReactTable
                          data={this.state.tributacoesEstadoDestino}
                          columns={[
                            {
                              accessor: 'estadoId',
                              Header: 'Estado',
                              width: 180,
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <Select
                                    value={props.row.estadoId}
                                    name="row.estadoId"
                                    options={this.props.lists.estados}
                                    onChange={event =>
                                      changeInList(
                                        this,
                                        'tributacoesEstadoDestino',
                                        'estadoId',
                                        event.target.value,
                                        props.row._index
                                      )
                                    }
                                  />
                                );
                              }
                            },
                            {
                              accessor: 'aliquotaMVA',
                              Header: 'MVA / IVA (%)',
                              width: 150,
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <InputText
                                    value={props.row.aliquotaMVA}
                                    name="row.aliquotaMVA"
                                    type="number"
                                    decimalScale={2}
                                    maxlength="6"
                                    onChange={event =>
                                      changeInList(
                                        this,
                                        'tributacoesEstadoDestino',
                                        'aliquotaMVA',
                                        event.floatValue,
                                        props.row._index
                                      )
                                    }
                                  />
                                );
                              }
                            },
                            {
                              accessor: 'aliquotaICMS',
                              Header: 'ICMS (%)',
                              width: 150,
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <InputText
                                    value={props.row.aliquotaICMS}
                                    name="row.aliquotaICMS"
                                    type="number"
                                    decimalScale={2}
                                    maxlength="5"
                                    onChange={event =>
                                      changeInList(
                                        this,
                                        'tributacoesEstadoDestino',
                                        'aliquotaICMS',
                                        event.floatValue,
                                        props.row._index
                                      )
                                    }
                                  />
                                );
                              }
                            },
                            {
                              accessor: 'aliquotaFCP',
                              Header: 'FCP (%)',
                              width: 150,
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <InputText
                                    value={props.row.aliquotaFCP}
                                    name="row.aliquotaFCP"
                                    type="number"
                                    decimalScale={2}
                                    maxlength="5"
                                    onChange={event =>
                                      changeInList(
                                        this,
                                        'tributacoesEstadoDestino',
                                        'aliquotaFCP',
                                        event.floatValue,
                                        props.row._index
                                      )
                                    }
                                  />
                                );
                              }
                            },
                            {
                              width: 75,
                              filterable: false,
                              sortable: false,
                              Cell: props => {
                                return (
                                  <>
                                    <Botao
                                      ic
                                      icon={'icon-lx-trash'}
                                      onClick={() => {
                                        deleteInList(
                                          this,
                                          'tributacoesEstadoDestino',
                                          props.row._index
                                        );
                                      }}
                                    />
                                  </>
                                );
                              }
                            }
                          ]}
                          defaultPageSize={5}
                          previousText="Anterior"
                          nextText="Próximo"
                          loadingText="Carregando registros..."
                          noDataText="Sem registros para exibir"
                          pageText="Página"
                          ofText="de"
                          rowsText="registros"
                        />
                      </div>
                  </div>
                </ div>
              </div>
              <div className="row">
                <div className="col-3">
                  <InputText
                    value={codigoSped}
                    name="codigoSped"
                    label="Código do produto no SPED:"
                    maxlength={20}
                    pattern={NUMERIC_PATTERN}
                    onChange={this.handleInputChangeRegex}
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={codigoBeneficio}
                    name="codigoBeneficio"
                    label="Código do benefício fiscal:"
                    maxlength={10}
                    pattern={ALPHANUMERIC_WITH_SPACE_PATTERN}
                    onChange={this.handleInputChangeRegex}
                  />
                </div>
                <div className="col-3">
                  <Select
                    value={contaSpedCredito}
                    name="contaSpedCredito"
                    label="Conta contábil de crédito (SPED):"
                    options={this.props.lists.contasCreditoSPED}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-3">
                  <Select
                    value={contaSpedDebito}
                    name="contaSpedDebito"
                    label="Conta contábil de débito:"
                    options={this.props.lists.contasDebitoSPED}
                    onChange={this.handleInputChange}
                  />
                </div>

              </div>
              <div className="row">
                <div className="col-3">
                  <Checkbox label="Indicador:">
                    <Checkitem
                      checked={escalaNaoRelevante}
                      name="escalaNaoRelevante"
                      label="Escala não relevante"
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
                <div className={ifShow(escalaNaoRelevante, 'col-3')}>
                  <InputText
                    value={cnpjEscala}
                    name="cnpjEscala"
                    label="CNPJ do fabricante:"
                    help={`${
                      String(cnpjEscala).length !== 14
                        ? 'CNPJ (somente números)'
                        : validaCNPJ(cnpjEscala)
                        ? 'CNPJ válido'
                        : 'CNPJ inválido'
                    }`}
                    type="number"
                    format="##.###.###/####-##"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(escalaNaoRelevante, 'col-6')}>
                  <InputText
                    value={razaoEscala}
                    name="razaoEscala"
                    label="Razão social do fabricante:"
                    maxlength={60}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>

            </SectionContent>
            <SectionContent
              // estoque
              title="Estoque"
              visible={this.hasSectionEstoque()}
              //visible={!this.isVerifying()}
              accordion
              accordionDisplay={sections.estoque}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    estoque: !sections.estoque
                  }
                });
                this.loadEstoque();
              }}
            >
              {!this.hasSubsectionAlmoxarifados() ? (
                <></>
              ) : (
                <>
                <div style={{paddingBottom: "10px"}}>
                  <Botao
                    ic
                    icon={'icon-lx-plus'}
                    tooltip="Adicionar almoxarifado"
                    onClick={() => {
                      appendInList(this, 'almoxarifados', {
                        codigo: none,
                        descricao: null,
                        saldo: null
                      });
                    }}
                  />
                </div>
                <div className="table-ctn">
                  <ReactTable
                    data={this.state.almoxarifados}
                    columns={[
                      {
                        accessor: 'codigo',
                        Header: 'Almoxarifado (Obrigatório)',
                        filterable: false,
                        sortable: false,
                        Cell: props => {
                          return (
                            <Select
                              value={props.row.codigo}
                              name="codigo"
                              options={this.props.lists.almoxarifados}
                              disabled={!sameFloat(props.row.saldo, 0)}
                              onChange={event => {
                                changeInList(
                                  this,
                                  'almoxarifados',
                                  'codigo',
                                  event.target.value,
                                  props.row._index,
                                  {
                                    tipo: this.getTipoAlmoxarifado(
                                      event.target.value
                                    ),
                                    saldo: null
                                  }
                                );
                              }}
                            />
                          );
                        }
                      },
                      {
                        accessor: 'tipo',
                        Header: 'Tipo',
                        filterable: false,
                        sortable: false,
                        width: 200,
                        Cell: props => {
                          return (
                            <Select
                              value={props.row.tipo}
                              name="tipo"
                              options={this.props.lists.tiposAlmoxarifados}
                              disabled
                            />
                          );
                        }
                      },
                      {
                        accessor: 'saldo',
                        Header: 'Saldo',
                        filterable: false,
                        sortable: false,
                        width: 150,
                        Cell: props => {
                          return (
                            <InputText
                              value={props.row.saldo}
                              name="saldo"
                              type="number"
                              onChange={this.handleInputChange}
                              allowNegative
                              disabled
                            />
                          );
                        }
                      },
                      {
                        width: 75,
                        filterable: false,
                        sortable: false,
                        Cell: props => {
                          if (sameFloat(props.row.saldo, 0)) {
                            return (
                              <>
                                <Botao
                                  ic
                                  icon={'icon-lx-trash'}
                                  onClick={() => {
                                    deleteInList(
                                      this,
                                      'almoxarifados',
                                      props.row._index
                                    );
                                  }}
                                />
                              </>
                            );
                          }
                        }
                      }
                    ]}
                    defaultPageSize={5}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando registros..."
                    noDataText="Sem registros para exibir"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                  />
                </div>
                </>
              )}
              <div className="row">
                <div className="col-4">
                  <InputText
                    value={quantidadeMinima}
                    name="quantidadeMinima"
                    label="Quantidade mínima:"
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={quantidadeMaxima}
                    name="quantidadeMaxima"
                    label="Quantidade máxima:"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <InputText
                    value={custoMedio}
                    name="custoMedio"
                    label="Custo médio:"
                    type="number"
                    decimalScale={4}
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={custoComICMS}
                    name="custoComICMS"
                    label="Custo com ICMS:"
                    type="number"
                    decimalScale={4}
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={custoSemICMS}
                    name="custoSemICMS"
                    label="Custo sem ICMS:"
                    type="number"
                    decimalScale={4}
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <InputDate
                    value={dataCompra}
                    name="dataCompra"
                    label="Data da última compra:"
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    value={dataVenda}
                    name="dataVenda"
                    label="Data da última venda:"
                    disabled
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              // composicoes
              title="Composição do produto"
              visible={this.hasSectionComposicoes()}
              //visible={(this.state.subtipo === Subtipo.Kit || this.state.subtipo === Subtipo.Composto)}
              accordion
              accordionDisplay={sections.composicoes}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    composicoes: !sections.composicoes
                  }
                });
                if (!this.state.composicoesLoaded) {
                  this.carregarComposicao();
                }
              }}
            >
              <ComposicaoProduto
                kit={this.state.subtipo === Subtipo.Kit}
                composto={this.state.subtipo === Subtipo.Composto}
                embalagem={this.state.subtipo === Subtipo.Embalagem}
                handleItensProdutos={this.handleItensProdutos}
                precoVendaProduto={this.state.precoVenda}
                formaReajuste={this.state.formaReajuste}
                percentualReajuste={this.state.percentualReajuste}
                itensProduto={this.state.itensComposicao}
                almoxarifadosProduto={this.state.almoxarifados}
                unidadesMedida={this.props.lists.unidadesMedida}
                idBase={this.state.id}
              />
            </SectionContent>
            <SectionContent
              // fornecedores
              title="Fornecedores"
              visible={this.hasSectionFornecedores()}
              //visible={!this.isVerifying()}
              accordion
              accordionDisplay={sections.fornecedores}
              onChange={() => {
                this.setState({
                  sections: {
                    ...sections,
                    fornecedores: !sections.fornecedores
                  }
                });
                this.loadFornecedores();
              }}
            >
              <div style={{paddingBottom: "10px"}}>
                <Botao
                    ic
                    tooltip="Adicionar fornecedor"
                    icon={'icon-lx-plus'}
                    onClick={() => {
                      appendInList(this, 'fornecedores', {
                        fornecedor: noneItem(),
                        referencia: '',
                        unidadeCompra: none,
                        fatorConversao: ''
                      });
                    }}
                  />
              </div>
              <div className="table-ctn">
                <ReactTable
                  data={this.state.fornecedores}
                  columns={[
                    {
                      accessor: 'principal',
                      Header: 'Principal',
                      width: 75,
                      sortable: false,
                      Cell: props => {
                        return (
                          <Checkbox>
                            <Checkitem
                              name={props.row.subclasse}
                              checked={props.row.principal}
                              onChange={event => {
                                const fornecedores = [];
                                for (
                                  let i = 0;
                                  i < this.state.fornecedores.length;
                                  i++
                                )
                                  fornecedores.push({
                                    ...this.state.fornecedores,
                                    principal: i === props.row._index
                                  });
                                this.setState({ fornecedores });
                              }}
                            />
                          </Checkbox>
                        );
                      }
                      // boolean -> mostrar check
                    },
                    {
                      accessor: 'fornecedor',
                      Header: 'Fornecedor',
                      sortable: false,
                      // codigo - descrição em AutoComplete
                      Cell: props => {
                        return (
                          <AutoComplete
                            value={props.row.fornecedor}
                            name={props.row.fornecedor}
                            loadOptions={this.loadFornecedoresProdutos}
                            noOptionsMessage={this.autoCompleteMessage(
                              autoComplete.fornecedorProduto
                            )}
                            onChange={event => {
                              changeInList(
                                this,
                                'fornecedores',
                                'fornecedor',
                                { value: event.value, label: event.label },
                                props.row._index
                              );
                            }}
                          />
                        );
                      }
                    },
                    {
                      accessor: 'referencia',
                      Header: 'Referência',
                      width: 200,
                      sortable: false,
                      Cell: props => {
                        return (
                          <InputText
                            value={props.row.referencia}
                            name={props.row.referencia}
                            maxlength={30}
                            onChange={event =>
                              changeInList(
                                this,
                                'fornecedores',
                                'referencia',
                                event.target.value,
                                props.row._index
                              )
                            }
                          />
                        );
                      }
                    },
                    {
                      accessor: 'unidadeCompra',
                      Header: 'Unidade de compra',
                      width: 200,
                      sortable: false,
                      Cell: props => {
                        return (
                          <Select
                            value={props.row.unidadeCompra}
                            name={props.row.unidadeCompra}
                            options={this.props.lists.unidadesCompra}
                            onChange={event =>
                              changeInList(
                                this,
                                'fornecedores',
                                'unidadeCompra',
                                event.target.value,
                                props.row._index
                              )
                            }
                          />
                        );
                      }
                    },
                    {
                      accessor: 'fatorConversao',
                      Header: 'Fator de conversão',
                      width: 200,
                      sortable: false,
                      Cell: props => {
                        return (
                          <InputText
                            name={props.row.fatorConversao}
                            value={props.row.fatorConversao}
                            type="number"
                            decimalScale={3}
                            onChange={event =>
                              changeInList(
                                this,
                                'fornecedores',
                                'fatorConversao',
                                event.floatValue,
                                props.row._index
                              )
                            }
                          />
                        );
                      }
                    },
                    {
                      width: 75,
                      filterable: false,
                      sortable: false,
                      Cell: props => {
                        return (
                          <>
                            <Botao
                              ic
                              icon={'icon-lx-trash'}
                              onClick={() => {
                                deleteInList(
                                  this,
                                  'fornecedores',
                                  props.row._index
                                );
                              }}
                            />
                          </>
                        );
                      }
                    }
                  ]}
                  defaultPageSize={5}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando registros..."
                  noDataText="Sem registros para exibir"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                />
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
        <Footer
          saveAction={
            this.isInserting() && !this.state.salvando
              ? this.handleSalvar
              : !this.isInserting() && !this.isEditing()
              ? null
              : menuPermiteAlteracao(PATH_MENU) && !this.state.salvando
              ? this.handleSalvar
              : null
          }
          deleteAction={
            !this.isEditing()
              ? null
              : this.isEditing() && this.isRestoring()
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
          cancelAction={this.handleCancelar}
        />
        {tagAlert(this)}
        {tagSearchEstruturaMercadologica(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class Produtos extends Component {
  state = {
    produtos: [],
    id: null,
    searchText: '',
    lastSearch: null,
    lists: {
      situacoes: [],
      unidadesCompra: [],
      unidadesVenda: [],
      setores: [],
      formasReajuste: [],
      gruposImpostos: [],
      contasCreditoSPED: [],
      contasDebitoSPED: [],
      hasSearched: false,
      estados: [],
    }
  };

  doLoadProdutos = async searchText => {
    this.setState({searchText});
    const { data } = await getProdutos({ search: searchText });
    if (!data || !data.result || data.result.length === 0)
      showError(
        this,
        titulo,
        'Sua pesquisa "' +
          searchText +
          '" não encontrou nenhum produto correspondente.'
      );
    this.setState({
      lastSearch: searchText,
      produtos: data.result,
      hasSearched: true
    });
  };

  loadProdutos = async text => {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto a pesquisa é realizada.'
    );
    await this.doLoadProdutos(text);
    hideWait(this);
  };

  async componentDidMount() {
    const situacoes = await getSituacoes();
    const unidades = await getUnidadeMedida();
    const setores = await getSetores();
    const formasReajuste = await getFormasReajustePreco();
    const gruposImpostos = await getGruposImpostos();
    const estados = await getEstados();
    const contasCreditoSPED = await getContasSPED('C');
    const contasDebitoSPED = await getContasSPED('D');
    const almoxarifados = await getAlmoxarifados();
    const { data: dataEstruturas } = await getEstruturasMercadologicas();

    situacoes.data = situacoes.data.map(it => {
      return {
        codigo: it.codigo + 1,
        descricao: it.descricao
      };
    });
    const lists = {
      estruturas:
        dataEstruturas && dataEstruturas.result ? dataEstruturas.result : [],
      situacoes: makeList(situacoes.data, 'Selecione a situação', false),
      unidadesMedida: unidades.data,
      unidadesCompra: makeList(
        unidades.data,
        'Selecione uma unidade de compra',
        false
      ),
      unidadesVenda: makeList(
        unidades.data,
        'Selecione uma unidade de venda',
        false
      ),
      setores: makeList(setores.data, 'Selecione um setor', true),
      formasReajuste: makeList(
        formasReajuste.data,
        'Selecione uma forma de reajuste',
        false
      ),
      gruposImpostos: makeList(
        sortList(gruposImpostos.data, 'descricao'),
        'Selecione um grupo de impostos',
        false
      ),
      estados: makeList(estados.data,
      'Selecione um estado',
      false
      ),
      contasCreditoSPED: makeList(
        contasCreditoSPED.data,
        'Selecione uma conta contábil de crédito para o SPED',
        false
      ),
      contasDebitoSPED: makeList(
        contasDebitoSPED.data,
        'Selecione uma conta contábil de débito para o SPED',
        false
      ),
      almoxarifados: !almoxarifados.data
        ? [
            {
              value: none,
              label: 'Não foi encontrado nenhum almoxarifado',
              tipo: null
            }
          ]
        : [
            {
              value: none,
              label: 'Selecione um almoxarifado',
              tipo: none,
              saldo: null
            }
          ].concat(
            sortList(almoxarifados.data, 'descricao').map(a => {
              return {
                value: a.codigo,
                label: a.descricao,
                tipo: a.tipo + 1
              };
            })
          ),
      tiposAlmoxarifados: [
        noneItem(''),
        { value: '1', label: 'Área de venda' },
        { value: '2', label: 'Depósito' }
      ]
    };
    this.setState({ lists });
  }

  componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      if (this.state.hasSearched) {
        this.setState({ searchText: this.state.lastSearch });
        this.loadProdutos(this.state.lastSearch);
      }
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      this.setState({ id: rowInfo.original.id });
      this.props.history.push('/cadastro/produtos/new');
    }
  };

  limparCadastro = () => {
    this.setState({ id: null });
  }

  setarValorCampoBusca = (texto) => {
    this.setState({ searchText: texto });
  }

  render() {
    const { edit } = this.props,
      { searchText } = this.state;
    this.handleInputChange = handleInputChange.bind(this);

    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <Form id={this.state.id} lists={this.state.lists} />
          ) : (
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
              <>
                <SectionHeader
                  title=""
                  right={
                    <div className={ifShow(!edit, 'button-container')}>
                      <ScreenHeader search={true} pathMenu={PATH_MENU} newButton={true} texto="Novo Cadastro" newURL={"/cadastro/produtos/new"} searchText={searchText} load={this.doLoadProdutos} limparCadastro={this.limparCadastro}  setarValorCampoBusca={this.setarValorCampoBusca} />
                    </div>
                  }
                />
                <List
                  onClick={this.handleTableClick}
                  defaultPageSize={10}
                  noDataText={`${
                    !this.state.lastSearch
                      ? 'Inicie fazendo uma busca ou incluindo um novo produto.'
                      : 'Sua pesquisa "' +
                        this.state.lastSearch +
                        '" não encontrou nenhum produto correspondente.'
                  }`}
                  cols={[
                    {
                      accessor: 'descricao',
                      Header: 'Descrição',
                      width: 300
                    },
                    {
                      accessor: 'codigo',
                      Header: 'Código',
                      width: 150
                    },
                    {
                      accessor: 'codigoRapido',
                      Header: 'Código rápido',
                      width: 150
                    },
                    {
                      accessor: 'subtipo',
                      Header: 'Subtipo',
                      width: 200
                    },
                    {
                      accessor: 'precoVenda',
                      Header: 'Preço de venda',
                      width: 150,
                      Cell: props => (
                        <div>
                          {formatNumber(
                            props.value,
                            props.row.subtipo === 'Combustíveis' ? 3 : 2
                          )}
                        </div>
                      )
                    },
                    {
                      accessor: 'situacao',
                      Header: 'Situação',
                      width: 200
                    }
                  ]}
                  rows={this.state.produtos}
                />
                {tagAlert(this)}
              </>
            </LinxPostos>
          )}
        </section>
      </main>
    );
  }
}

export { Produtos };
Produtos = withRouter(Produtos);
