export const passarStatusSelecionadosParaTexto = statusArr => {
  let ret = '';
  for (const it of statusArr) {
    switch (it) {
      default:
      case 1:
        ret += `${ret.length ? ', ' : ''}Em Aberto`;
        break;
      case 2:
        ret += `${ret.length ? ', ' : ''}Confirmado`;
        break;
      case 3:
        ret += `${ret.length ? ', ' : ''}Carregado`;
        break;
      case 4:
        ret += `${ret.length ? ', ' : ''}Entregue`;
        break;
      case 5:
        ret += `${ret.length ? ', ' : ''}Recolhido`;
        break;
      case 6:
        ret += `${ret.length ? ', ' : ''}Cancelado`;
        break;
      case 7:
        ret += `${ret.length ? ', ' : ''}Fechado`;
        break;
      case 8:
        ret += `${ret.length ? ', ' : ''}Recolhido Parcial`;
        break;
    }
  }
  return `${ret}`;
};
