import { getUrlLinxCore } from '../../../services/core/Auth.service';

const funcionario = {
  ListarComFiltro,
};

async function ListarComFiltro(query) {
  const response = await getUrlLinxCore().post('/Funcionario/ListarComFiltro', {
    query: query
  });
  return response.data.data;
}

export default funcionario;
