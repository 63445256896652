import { CheckIEROantigo } from './CheckIEROantigo';
import { CharToInt } from './utils';
export function CheckIERO(ie) {
  return CheckIERONovo(ie) || CheckIEROantigo(ie);
}
function CheckIERONovo(ie) {
  if (ie.length !== 14) {
    return false;
  }

  var nro = new Array(14);
  var b = 6;
  var soma = 0;
  var i = 0;
  for (i = 0; i <= 4; i++) {
    nro[i] = CharToInt(ie.charAt(i));

    soma = soma + nro[i] * b;
    b--;
  }

  b = 9;
  for (i = 5; i <= 13; i++) {
    nro[i] = CharToInt(ie.charAt(i));

    if (i !== 13) {
      soma = soma + nro[i] * b;
      b--;
    }
  }

  var dig = 11 - (soma % 11);

  if (dig >= 10) dig = dig - 10;

  return dig === nro[13];
} //ro nova
