import React, { useState, useEffect, useRef } from 'react';
import { LoadPanel } from 'devextreme-react';
import { SearchPanel } from "devextreme-react/data-grid";
import { locale, loadMessages } from 'devextreme/localization';

import {
  FilterRow
} from "devextreme-react/data-grid";

import notify from 'devextreme/ui/notify';
import moment from 'moment';
import config from 'devextreme/core/config';

import 'jspdf-autotable';

import ResultPanel from '../../../../../components/Report/ResultPanel/ResultPanel';
import DateToolTip from '../../../../../components/dateTooltip/DateToolTip';
import ListToolTip from '../../../../../components/listToolTip/ListToolTip';
import DateTimeBox from '../../../../../components/DateTimeBox/DateTimeBox';
import FilterPanel from '../../../../../components/Report/FilterPanel/FilterPanel';

import { NotificationLevel } from '../../../../../shared/constants/Const';
import { TIPO_SITE } from '../../../../../shared/constants/TipoSiteConstant';

import { ExtratoService } from './Extrato.service';
import { reportMangerService } from '../../../../../services/ReportManager.service';

import styles from '../../ContaDigitalIntegrada.module.scss';
import ptMessages from '../../../../../../assets/js/pt.json';


export function Extrato() {

  const CodigoFuncionalidade = 5710;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({
    dataInicio: startDate,
    dataFim: endDate
  });

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, startDate, endDate]);

  useEffect(() => {
    const colunas = ExtratoService.GetColumns(true);
    setColumns(colunas);

    async function GetReports() {
      const result = await reportMangerService.Get(CodigoFuncionalidade);

      if (result.notificationLevel === NotificationLevel.Success) {
        let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

        setServerReports(reports);
      }
    }

    GetReports();
  }, []);

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }

    setFilters({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim
    });
    await request({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim
    });

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    setSearching(true);

    const response = await ExtratoService.listStatement(model);

    if (response && response.result) {
      if (response.result.length === 0) {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          2000,
          'top'
        );
        setResultData([])
      } else {
        setResultData(response.result)
      }
    } else {
      notify(response.message, 'info', 2000, 'top');
    }

    setShowResult(true);
    setSearching(false);
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);

    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    refreshColumns(report.data.colunas);

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim
    };

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const refreshColumns = newColumns => {
    let refreshedColuns = reportMangerService.RefreshColumns(
      columns,
      newColumns
    );
    setColumns(refreshedColuns);
  };

  const reportHandle = report => {
    setChosenReport(report);

    if (report.tipo === 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59'
              : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00'
        };
      });
      setDisabledSearch(false);
    }
  };


  const clearFilters = () => {
    setAppliedFilters({
      dataInicio: new Date().toISOString(),
      dataFim: new Date().toISOString()
    });
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const updateReportInfo = report => {
    setTitle(`EXTRATO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
      refreshColumns(ExtratoService.GetColumns(true));
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
      if (TIPO_SITE === 'RetaguardaEmporioPro')
        reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="Extrato"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        disabled={searching}
        panelTitle={title}
        reportTitle='Extrato'
        filters= { true }
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <ListToolTip />
      </ResultPanel>
    );
  };


  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <FilterPanel
        onSearch={find}
        clearFilter={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-3`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-3`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </>
  );
}
