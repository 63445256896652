import axios from "axios";
import { TOKEN_PASS_AXIOS } from "../shared/constants/Const";
import { getUrl, getCnpj } from "../shared/utils/Utils";
import { Classificacao } from "../pages/cadastro/pessoas/Pessoas.jsx";


axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function buscarGrupoClientes(cadastroCompleto) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v2/AgrupadorCliente/Listar`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      cadastroCompleto
    }
  });
}

export function selecionarGrupoClientes(codigoAgrupador) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v2/AgrupadorCliente/Selecionar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      codigoAgrupador
    }
  });
}

export function incluirGrupoClientes(descricao, mostraAgrupado, values) {
  axios.defaults.baseURL = getUrl();
  const clientes = values.map(cli => {
    return { codigoCliente: cli };
  });
  return axios.post(`LxApi/v2/AgrupadorCliente/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    descricao,
    mostraAgrupado,
    clientes
  });
}

export function alterarGrupoClientes(
  id,
  mostraAgrupado,
  descricao,
  rowVersion,
  values
) {
  axios.defaults.baseURL = getUrl();
  const clientes = values.map(cli => {
    return { codigoCliente: cli };
  });
  return axios.put(`LxApi/v2/AgrupadorCliente/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    id,
    mostraAgrupado,
    descricao,
    rowVersion,
    clientes
  });
}

export function excluirGrupoClientes(codigoAgrupador) {
  axios.defaults.baseURL = getUrl();
  return axios.delete(`LxApi/v2/AgrupadorCliente/Excluir`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoAgrupador
    }
  });
}

export function montarComboClientes() {
  var classificacaoPessoa = 1;
  var cnpjEstabelecimento = getCnpj();
  return axios.get(`LxApi/v1/Pessoa/MontarCombo`, {
    params: { cnpjEstabelecimento, classificacaoPessoa }
  });
}

export function montaComboGrupoClientes() {
  return axios.get(`LxApi/v1/AgrupadorCliente/MontarCombo`, {
    params: {
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function getClientesGrupo(search) {
  return axios.post(`LxApi/v1/Pessoa/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      classificacao: [Classificacao.Cliente],
      somenteAtivos: false,
      search
    }
  });
}
