
export class AtualizarNumeroSerieService {
  execute = (state, { payload }) => {
    const { series, tipoPedidoId, numeroItem } = payload;
    let index = state.values.pedidos.findIndex(it => it.tipoPedido.id === tipoPedidoId && !it.faturado);
    const pedido = state.values.pedidos[index];

    let idx = pedido.itens.findIndex(it => it.numeroItem === numeroItem);
    pedido.itens[idx].series = series;
    let nextValue = null;
    var d = { ...state.values };
    d.pedidos[index] = pedido;
    nextValue = { ...d };

    state.values = { ...state.values, ...nextValue };
  };
}
