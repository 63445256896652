import React, { Component } from "react";
import Botao from "../../../components/botao/Botao";
import {
  InputText,
  InputDate,
  // Checkbox,
  Checkitem,
  Select,
  handleInputChange,
  // AutoComplete
} from "../../../components/formulario/Formulario";
import ReactTable from "react-table";
import { SectionContainer, SectionContent } from "../../../components/section/Content";
import {
  tagAlert,
  showWait,
  hideWait,
  showError,
  showQuestion,
  showExecuteError,
  hideAlert
} from "../../../components/alert/Alert";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  getNomePagina,
  emptyText,
  isObjectSelected,
  toFloatFormattedDisplay,
  compareStrings,
  maskedCnpjCpf,
  formatNumber,
  getNomeEmpresa,
  toFloatFormattedDisplayDecimals,
  getMenu
} from "../../../shared/utils/Utils";
import {
  getPessoa,
  getPessoas,
  montaComboTiposPrazos
} from "../../cadastro/pessoas/Pessoas.service";
import { ModalClienteFornecedor } from "../gerenciamento-financeiro/FormLancamento/FormLancamento";

import { montaComboGrupoClientes } from "../../../services/GrupoClientes.service";
import { ModalDocumentosFiscaisFechamento } from "../../../components/modal/ModalDocumentosFiscaisFechamento";
import { Colors } from "../fluxo-caixa/Cashflow";
import {
  getContasCorrentes,
  getCupons,
  geraFaturaCliente,
  geraNotaFiscalFaturas,
  getFaturados,
  getCuponsFaturados,
  cancelaFaturaCliente,
  montaComboSituacoesFatura
} from "../../../services/ContaCorrente.service";
import { ModalReenviaEmail } from "../../../components/modal/ModalReenviaEmail";
import { HistoricoFaturados } from "./HistoricoFaturados";
import { ModalVisualizadorRelatorioFaturado } from "../../../components/modal/ModalVisualizadorRelatorioFaturado";
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { confirm, alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { menus } from '../../../shared/constants/MenuConstants';


const PATH_MENU = menus.COD_60980;

const titulo = "Relatório para faturamento de vendas a prazo";
// const processing = "Em processamento";

/* const TiposDePrazos = {
  None: 0,
  Semanal: 1,
  Quinzenal: 2,
  Mensal: 3
}; */

export const SituacoesFatura = {
  None: 0,
  NaoFaturados: 1,
  Faturados: 2
};

class RelatorioFaturamentoVendaPrazo extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      listaPrazos: [],
      tipoPrazo: "",
      listaGruposClientes: [],
      grupoClientes: "",
      campoPessoa: "",
      cliente: null,
      dataDe: moment().startOf("month"),
      dataAte: moment().endOf("month"),
      showModalCliente: false,
      listaClientes: [],
      resultadosPesquisa: [],
      showModalDocumentosFiscaisFechamento: false,
      showModalReenviaEmail: false,
      codigoClienteReenvioEmail: null,
      codigoTituloReenvioEmail: null,
      codigoDocumentosFiscaisFechamento: null,
      printTotals: false,
      printView: false,
      listDocs: [],
      situacao: SituacoesFatura.NaoFaturados,
      listaSituacoes: [],
      nomeFantasia: "",
      selectAll: false,
      //showModalHistoricoFaturamento: false,
      dadosHistoricoFaturamento: [],
      prazoConsultado: 0,
      showModalVisualizadorRelatorio: false,
      dadosView: [],
      menuGrupoPessoasVisible: false
    };
  }

  loadReportFilters = async () => {
    showWait(
      this,
      titulo,
      "Por favor, aguarde enquanto os filtros são carregados."
    );
    try {
      const { data: dataTiposPrazos } = await montaComboTiposPrazos();
      const listaPrazos = [];
      listaPrazos.push({ label: "Todos", value: 0 });
      dataTiposPrazos.forEach(item => {
        listaPrazos.push({ label: item.descricao, value: item.codigo });
      });
      const listaGruposClientes = [];
      const { data: dataGruposClientes } = await montaComboGrupoClientes();

      if (dataGruposClientes && dataGruposClientes.length > 0) {
        dataGruposClientes.forEach(item => {
          listaGruposClientes.push({
            label: item.descricao,
            value: item.codigo
          });
        });
        listaGruposClientes.sort((a, b) => compareStrings(a.label, b.label));
        listaGruposClientes.unshift({ label: "Todos", value: 0 });
      } else {
        listaGruposClientes.push({
          label: "Não existem grupos para seleção",
          value: 0
        });
      }

      //const { data: dataSituacoes } = await montaComboSituacoesFatura();
      const dataSituacoes = montaComboSituacoesFatura(); // TODO
      const listaSituacoes = [];
      dataSituacoes.forEach(s => {
        listaSituacoes.push({ label: s.descricao, value: s.codigo });
      });

      const nomeFantasia = await getNomeEmpresa();

      this.setState({
        listaPrazos,
        listaGruposClientes,
        listaSituacoes,
        nomeFantasia
      });
    } finally {
      hideWait(this);
    }
  };

  /* async componentDidUpdate(prevProps) {
    if (this.props.historico !== prevProps.historico && !this.props.historico) {
      await this.doMount();
    }
  } */

  async componentDidMount() {
    await this.loadReportFilters();
    this.getMenuGrupoPessoas();
  }

  getMenuGrupoPessoas = () => {
    const response = getMenu(60470);
    this.setState({ menuGrupoPessoasVisible: response });
  }

  isFormValid = () => {
    const { dataDe, dataAte } = this.state;

    if (!dataDe) {
      showError(this, titulo, "Escolha uma data inicial válida", "dataDe");
      return false;
    }

    if (!dataAte) {
      showError(this, titulo, "Escolha uma data final válida", "dataAte");
      return false;
    }

    if (moment(dataDe).isAfter(moment(dataAte))) {
      showError(
        this,
        titulo,
        "A data inicial não pode ser posterior a data final",
        "dataDe"
      );
      return false;
    }

    return true;
  };

  apiToResult = (apiList, resultList, situacao) => {
    if (parseInt(situacao) === parseInt(SituacoesFatura.NaoFaturados)) {
      apiList.forEach(async item => {
        resultList.push({
          codigo: item.codigoCliente,
          nome: item.nomeCliente,
          tipoPrazo: item.tipoPrazo.descricao,
          saldoDevedor: item.saldoDevedor,
          vendasAPrazoOriginal: item.saldoNaoFaturado,
          faturasEmAbertoOriginal: item.saldoFaturadoEmAberto,
          vendasAPrazo: toFloatFormattedDisplayDecimals(
            item.saldoNaoFaturado,
            2
          ),
          faturadasEmAberto: toFloatFormattedDisplayDecimals(
            item.saldoFaturadoEmAberto,
            2
          ),
          creditoIlimitado: item.creditoIlimitado,
          limite: item.creditoIlimitado
            ? "Ilimitado"
            : toFloatFormattedDisplayDecimals(item.limiteCredito, 2),
          saldo: item.creditoIlimitado
            ? "Ilimitado"
            : toFloatFormattedDisplayDecimals(item.saldoDisponivel, 2),
          selected: false,
          hasDocs: true // todo: exibir lupa apenas quando tiver documentos?
          //hasDocs: item.hasDocs
        });
      });
    } else if (parseInt(situacao) === parseInt(SituacoesFatura.Faturados)) {
      apiList.forEach(item => {
        resultList.push({
          codigo: item.codigoCliente,
          nome: item.nome,
          numeroFatura: item.numeroTitulo,
          numeroNfeOriginal: item.numeroNfe,
          numeroNfe: item.numeroNfe ? String(item.numeroNfe) : "",
          dataVencimento: moment(item.dataVencimento).format("DD/MM/YYYY"),
          dataFechamento: moment(item.dataEmissao).format("DD/MM/YYYY"),
          valor: "R$ " + toFloatFormattedDisplayDecimals(item.valorLiquido, 2),
          situacao: item.situacao,
          selected: false,
          codigoTitulo: item.codigoTitulo,
          rowVersion: item.rowVersion
        });
      });
    }
  };

  pesquisa = async () => {
    if (!this.isFormValid()) {
      return;
    }

    const {
      tipoPrazo,
      grupoClientes,
      cliente,
      dataDe,
      dataAte,
      situacao
    } = this.state;

    let codigoTipoPrazo = tipoPrazo ? tipoPrazo : 0;
    let codigoAgrupadorClientes = grupoClientes ? grupoClientes : 0;
    let codigoCliente = this.hasPessoaSelected() ? cliente.codigo : 0;
    showWait(this, titulo, "Por favor, aguarde a geração do relatório.");
    let pesquisa = [];
    if (parseInt(situacao) === parseInt(SituacoesFatura.NaoFaturados)) {
      const { data: dataPesquisa } = await getContasCorrentes(
        moment(dataDe).format("YYYY-MM-DD"),
        moment(dataAte).format("YYYY-MM-DD"),
        codigoCliente,
        codigoTipoPrazo,
        codigoAgrupadorClientes
      );
      pesquisa = dataPesquisa;
      /*for (const item of pesquisa) {
        if (item) {
          const { data: dataCupons } = await getCupons(
            moment(dataDe).format("YYYY-MM-DD"),
            moment(dataAte).format("YYYY-MM-DD"),
            item.codigoCliente
          );
          item.hasDocs = dataCupons && dataCupons.length > 0;
        }
      } */
    } else if (parseInt(situacao) === parseInt(SituacoesFatura.Faturados)) {
      const { data: dataPesquisa } = await getFaturados(
        moment(dataDe).format("YYYY-MM-DD"),
        moment(dataAte).format("YYYY-MM-DD"),
        codigoCliente,
        codigoTipoPrazo,
        codigoAgrupadorClientes
      );
      pesquisa = dataPesquisa;
    }
    if (!pesquisa || pesquisa.length === 0) {
      showError(
        this,
        titulo,
        "Nenhum resultado obtido para os filtros informados",
        "campoPessoa"
      );
      return;
    }
    const resultadosPesquisa = [];
    this.apiToResult(pesquisa, resultadosPesquisa, situacao);
    if (parseInt(situacao) === parseInt(SituacoesFatura.NaoFaturados)) {
      this.calculateTotals(resultadosPesquisa);
    }
    hideWait(this);
    this.setState({
      resultadosPesquisa,
      selectAll: false,
      situacaoConsultada: situacao,
      prazoConsultado: codigoTipoPrazo
    });
  };

  calculateTotals = list => {
    if (!list || list.length === 0) {
      list.totalNaoFaturados = "0";
      list.totalFaturadosEmAberto = "0";
      return;
    }
    let totalNaoFaturados = 0,
      totalFaturadosEmAberto = 0;
    list.forEach(item => {
      totalNaoFaturados += item.vendasAPrazoOriginal;
      totalFaturadosEmAberto += item.faturasEmAbertoOriginal;
    });

    list.totalNaoFaturados = toFloatFormattedDisplay(String(totalNaoFaturados));
    list.totalFaturadosEmAberto = toFloatFormattedDisplay(
      String(totalFaturadosEmAberto)
    );
  };

  hasPessoaSelected = () => {
    return isObjectSelected(this.state.cliente);
  };

  hasCampoPessoa = () => {
    return (
      !this.hasPessoaSelected() && !emptyText(String(this.state.campoPessoa))
    );
  };

  clearPessoa = () => {
    this.setState({
      cliente: null,
      campoPessoa: ""
    });
  };

  handlePesquisarPessoa = async search => {
    const consulta = {
      classificacao: [1],
      search
    };
    showWait(
      this,
      titulo,
      "Por favor, aguarde enquanto a consulta é realizada."
    );
    const { data: dataPessoas } = await getPessoas(consulta);
    if (
      !dataPessoas ||
      !dataPessoas.result ||
      dataPessoas.result.length === 0
    ) {
      hideWait(this);
      showError(
        this,
        titulo,
        'Nenhum cliente encontrado com a pesquisa "' + search + '" informada',
        "campoPessoa"
      );
    } else if (dataPessoas.result.length === 1) {
      //ASSOCIACAO CATOLICA RAINHA DAS VIRGENS
      const { data: dataPessoa } = await getPessoa(
        dataPessoas.result[0].codigo
      );
      //const cliente = dataPessoas.result[0];
      const cliente = { ...dataPessoa };
      cliente.isSelected = true;
      hideWait(this);
      this.setState({
        cliente,
        campoPessoa: cliente.nome
      });
    } else {
      hideWait(this);
      this.setState({
        showModalCliente: true,
        listaClientes: dataPessoas.result
      });
    }
  };

  handleCloseModalCliente = async cliente => {
    const campoPessoa = cliente ? cliente.nome : this.state.campoPessoa;

    let c = this.state.cliente;
    let { tipoPrazo, grupoClientes } = this.state;
    // se vier null, não pode apagar uma pesquisa prévia
    if (cliente) {
      const { data: dataPessoa } = await getPessoa(cliente.codigo);
      c = { ...dataPessoa };
      c.isSelected = true;
      tipoPrazo = "";
      grupoClientes = "";
    }

    this.setState({
      cliente: c,
      campoPessoa,
      showModalCliente: false,
      tipoPrazo,
      grupoClientes
    });
  };

  handleVisualizarButtonClick = rowInfo => {
    this.setState({
      showModalDocumentosFiscaisFechamento: true,
      codigoDocumentosFiscaisFechamento: rowInfo.row.codigo
    });
  };

  handleReenviaEmailButtonClick = (rowInfo, caller) => {
    if (caller) {
      caller.setState({
        showModalReenviaEmail: true,
        codigoClienteReenvioEmail: rowInfo.row.codigo,
        codigoTituloReenvioEmail: rowInfo.row.codigoTitulo
      });
    }
  };

  handleHistoricoButtonClick = rowInfo => {
    this.setState({
      //showModalHistoricoFaturamento: true,
      dadosHistoricoFaturamento: rowInfo.row
    });
    this.props.history.push("/financeiro/faturamento-vendas-prazo/historico");
  };

  handleGerarNfeButtonClick = async (
    codigoTitulo,
    codigoCliente,
    numeroTitulo,
    user
  ) => {
    await this.geraNFe(codigoTitulo, codigoCliente, numeroTitulo, user);
  };

  getAllResultsSelectedByCodigoTitulo = codigoTitulo => {
    const r = this.state.resultadosPesquisa;
    r.forEach(rP => {
      if (rP) {
        rP.selected = false;

        if (rP.codigoTitulo === codigoTitulo) {
          rP.selected = true;
        }
      }
    });

    return r;
  };

  getListDocs = async codigo => {
    const tempListDocs = await doGetCuponsFaturados(codigo);
    const listDocs = [];
    listDocs.push(tempListDocs);
    return listDocs;
  };

  handleImprimirButtonClick = async codigoTitulo => {
    const listDocs = await this.getListDocs(codigoTitulo);

    if (listDocs.length === 0) {
      showError(
        this,
        titulo,
        "Este faturamento não contém dados para imprimir."
      );
      return false;
    }

    this.setState({
      resultadosPesquisa: this.getAllResultsSelectedByCodigoTitulo(
        codigoTitulo
      ),
      printTotals: false,
      printView: true,
      listDocs
    });
    this.forceUpdate(() => {
      window.print();
    });
  };

  handleVisualizarFaturaClick = async codigoTitulo => {
    const listDocs = await this.getListDocs(codigoTitulo);

    if (listDocs.length === 0) {
      showError(
        this,
        titulo,
        "Este faturamento não contém dados para imprimir."
      );
      return false;
    }
    const dadosView = [];
    const r = this.state.resultadosPesquisa;

    r.forEach(rP => {
      if (rP) {
        if (rP.codigoTitulo === codigoTitulo) {
          dadosView.push({ ...rP });
          return;
        }
      }
    });

    this.setState({
      dadosView,
      listDocs,
      showModalVisualizadorRelatorio: true
    });
  };

  handleCancelarNfe = async (rowInfo, listaDados) => {
    if (rowInfo.row.numeroNfe) {

      await alert(`Para excluí-lo, é necessário efetuar o cancelamento da NF-e: ${rowInfo.row.numeroNfe} do cliente ${rowInfo.row.nome} pelo menu de monitoramento da NF-e`, titulo);

    } else {

      let result = await confirm(`Deseja cancelar a fatura: ${rowInfo.row.numeroFatura} do cliente ${rowInfo.row.nome}?`, titulo);

      if (result) {
        try {
          showWait(
            this,
            titulo,
            "Por favor, aguarde enquanto a fatura é cancelada."
          );
          await cancelaFaturaCliente(
            rowInfo.row.codigoTitulo,
            rowInfo.row.rowVersion
          );
          notify('Fatura cancelada com sucesso.', 'info', 2000, 'top');

          if (listaDados) {
            let index = listaDados.indexOf(rowInfo.row._original);
            if (index !== -1) {
              listaDados.splice(index, 1);
            }
          }
          await this.pesquisa();
        } catch (error) {
          showExecuteError(this, titulo, error);
        }
      }
    }
  };

  getCuponsFromSelected = async (dataInicio, dataFim, list) => {
    const result = await Promise.all(
      list.map(async value => {
        if (value.selected) {
          const { data: dataDocumentos } = await getCupons(
            moment(dataInicio).format("YYYY-MM-DD"),
            moment(dataFim).format("YYYY-MM-DD"),
            value.codigo
          );
          return dataDocumentos;
        }
        return null;
      })
    );
    return result ? result.filter(c => !!c) : [];
  };

  imprimirDocumentos = async listDocs => {
    if (listDocs && listDocs.length > 0) {
      const pessoasResponseArray = await Promise.all(
        listDocs.map(async value => {
          const reply = await Promise.all(
            value.map(async v => {
              const { data: dataPessoa } = await getPessoa(v.codigoCliente);
              return dataPessoa;
            })
          );
          return reply;
        })
      );
      listDocs.forEach(itemD => {
        itemD.forEach(d => {
          d.cnpjCpf = "";
          pessoasResponseArray.forEach(itemP => {
            if (d.cnpjCpf) {
              return;
            }

            itemP.forEach(p => {
              if (d.codigoCliente === p.codigo) {
                d.cnpjCpf = p.cnpjCpf;
                return;
              }
            });
          });
        });
      });
    }

    this.setState({
      printTotals: false,
      printView: true,
      listDocs
    });
    this.forceUpdate(() => {
      window.print();
    });
  };

  handleViewClick = async resultadosPesquisa => {
    const { dataDe, dataAte } = this.state;
    const listDocs = await this.getCuponsFromSelected(
      dataDe,
      dataAte,
      resultadosPesquisa
    );
    this.imprimirDocumentos(listDocs);
  };

  geraNFe = async (codigoTitulo, codigoCliente, numeroTitulo, user) => {
    const faturas = [];
    faturas.push({
      codigoCliente: codigoCliente,
      codigoTitulo: codigoTitulo,
      numeroTitulo: numeroTitulo
    });
    try {
      showWait(user ? user : this, titulo, "Por favor, aguarde enquanto gera NF-e");
      await geraNotaFiscalFaturas(faturas);
      if (user) {
        const historico = [];
        const pesquisa = await user.getFaturados(codigoCliente);
        user.apiToHistorico(pesquisa, historico);
        user.setState({ historico });
      }
      await this.pesquisa();
      hideWait(user ? user : this);
    } catch (e) {
      showExecuteError(this, titulo, e);
    }
  };

  geraFatura = async () => {
    const { resultadosPesquisa, dataDe, dataAte } = this.state;
    if (!resultadosPesquisa || resultadosPesquisa.length === 0) {
      return false;
    }

    const listDocs = await this.getCuponsFromSelected(
      dataDe,
      dataAte,
      resultadosPesquisa
    );

    if (!listDocs || listDocs.length === 0) {
      return false;
    }

    this.setState({ listDocs });

    return await this.doGeraFatura(
      resultadosPesquisa,
      listDocs,
      dataDe,
      dataAte
    );
  };

  doGeraFatura = async (rs, listDocs, dataDe, dataAte) => {
    const selec = rs.filter(c => c.selected);

    const dadosClientes = [];
    selec.forEach(cliente => {
      let cupons = listDocs.map(doc => {
        return doc.map(d => {
          if (d.codigoCliente === cliente.codigo) {
            return d.codigoTitulo;
          }
          return doc;
        });
      });
      cupons = cupons.map(listaInterna => {
        const result = listaInterna.filter(cupom => !!cupom);
        return result && result.length > 0 ? result : null;
      });
      dadosClientes.push({
        codigoCliente: cliente.codigo,
        cupons: cupons && cupons.length > 0 ? cupons.find(c => !!c) : []
      });
    });

    try {
      showWait(this, titulo, "Por favor, aguarde enquanto gera fatura");
      const { data: resultadoFatura } = await geraFaturaCliente(
        dadosClientes,
        moment(dataDe).format("YYYY-MM-DD"),
        moment(dataAte).format("YYYY-MM-DD")
      );
      await this.pesquisa();
      listDocs = resultadoFatura[0].documentosFiscais;
      this.imprimirDocumentos(listDocs.filter(c => !!c));
      this.setState({ resultadoFatura });
      hideWait(this);
    } catch (e) {
      showExecuteError(this, titulo, e);
      return false;
    }

    return true;
  };

  getSelectedCount = () => {
    let count = 0;
    this.state.resultadosPesquisa.forEach(value => {
      if (value.selected) {
        count++; // total de selecionados
      }
    });
    return count;
  };

  tagButtons = () => {
    const { resultadosPesquisa, situacaoConsultada } = this.state;
    if (!resultadosPesquisa) {
      return <></>;
    }
    if (
      resultadosPesquisa.length === 0 ||
      Number(situacaoConsultada) === SituacoesFatura.Faturados
    ) {
      return <></>;
    }

    let totalSelected = 0;
    resultadosPesquisa.forEach(item => {
      if (item && item.selected) {
        totalSelected++;
      }
    });

    if (totalSelected > 0) {
      return (
        <>
          <Botao
            title="Gerar fatura e NF-e"
            secondary
            icon="icon-lx-files"
            onClick={async () => {
              const count = this.getSelectedCount();
              showQuestion(
                this,
                titulo,
                count > 1
                  ? "Foram selecionados " +
                  String(count) +
                  " clientes para geração de fatura e NF-e. Deseja continuar?"
                  : "Deseja gerar fatura e NF-e?",
                null,
                async resp => {
                  if (resp) {
                    let gerouFatura = false;
                    gerouFatura = await this.geraFatura();
                    if (gerouFatura) {
                      const { resultadoFatura } = this.state;

                      await this.geraNFe(
                        resultadoFatura.codigoTitulo,
                        resultadoFatura.codigoCliente,
                        resultadoFatura.numeroTitulo,
                        null
                      );
                    }
                  } else {
                    hideAlert(this);
                  }
                }
              );
            }}
          />
          <Botao
            title="Gerar apenas fatura"
            icon="icon-lx-invoice"
            onClick={async () => {
              const count = this.getSelectedCount();
              showQuestion(
                this,
                titulo,
                count > 1
                  ? "Foram selecionados " +
                  String(count) +
                  " clientes para geração de fatura. Deseja continuar?"
                  : "Deseja gerar apenas fatura?",
                null,
                async resp => {
                  if (resp) {
                    await this.geraFatura();
                  } else {
                    hideAlert(this);
                  }
                }
              );
            }}
          />
          <Botao
            title="Visualizar"
            icon="icon-lx-vision"
            onClick={async () => {
              showWait(
                this,
                titulo,
                "Por favor, aguarde a geração do relatório."
              );
              this.handleViewClick(resultadosPesquisa);
              hideWait(this);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <Botao
            title="Imprimir totais"
            icon="icon-lx-print"
            onClick={() => {
              this.setState({
                printTotals: true,
                printView: false
              });
              this.forceUpdate(() => {
                window.print();
              });
              // this.forceUpdate();
              // window.print();
            }}
          />
        </>
      );
    }
  };


  tagPrint = situacao => {
    const { resultadosPesquisa, listDocs } = this.state;
    return (
      <Report
        sender={this}
        dados={resultadosPesquisa}
        headers={this.getTableHeaders()}
        isPrint={true}
        printTotals={this.state.printTotals}
        printView={this.state.printView}
        docs={listDocs}
        situacao={situacao}
        selectAll={this.state.selectAll}
      />
    );
  };

  getTableHeaders = () => {
    const headers = [];
    headers.push("Nome");
    headers.push("Não faturadas (R$)");
    headers.push("Faturadas em aberto (R$)");
    headers.push("Limite (R$)");
    headers.push("Saldo disponível (R$)");
    return headers;
  };

  renderGrupoClientes = () => {
    if (this.state.menuGrupoPessoasVisible) {
      return (<>
        <div className="col-2">
          <Select
            label="Tipo de prazo:"
            name="tipoPrazo"
            value={this.state.tipoPrazo}
            onChange={this.handleInputChange}
            options={this.state.listaPrazos}
            disabled={this.hasPessoaSelected()}
          />
        </div>
        <div className="col-3">
          <Select
            label="Grupo de clientes:"
            name="grupoClientes"
            value={this.state.grupoClientes}
            onChange={this.handleInputChange}
            options={this.state.listaGruposClientes}
            disabled={
              this.hasPessoaSelected() ||
              this.state.listaGruposClientes.length === 1
            }
          />
        </div>
      </>);
    } else {
      return <div className="col-5">
        <Select
          label="Tipo de prazo:"
          name="tipoPrazo"
          value={this.state.tipoPrazo}
          onChange={this.handleInputChange}
          options={this.state.listaPrazos}
          disabled={this.hasPessoaSelected()}
        />
      </div>;
    }
  };

  render() {
    const {
      campoPessoa,
      dataDe,
      dataAte,
      situacao,
      listaSituacoes,
      resultadosPesquisa,
      listDocs,
      situacaoConsultada
    } = this.state;

    const { historico } = this.props;

    return (
      <>
        {historico ? (
          <HistoricoFaturados
            sender={this}
            dados={this.state.dadosHistoricoFaturamento}
            prazo={{
              lista: this.state.listaPrazos
            }}
            datas={{
              dataDe: this.state.dataDe,
              dataAte: this.state.dataAte
            }}
            grupoClientes={{
              codigo: this.state.grupoClientes,
              lista: this.state.listaGruposClientes,
              nome: this.state.listaGruposClientes[this.state.grupoClientes]
            }}
          />
        ) : (
            <>
              <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                <SectionContainer>
                  <SectionContent title="Filtros">
                    <div className="row">
                      <div className="col-6">
                        <ModalClienteFornecedor
                          active={this.state.showModalCliente}
                          handleCloseModal={this.handleCloseModalCliente}
                          lista={this.state.listaClientes}
                          headerTitle="Pesquisar Cliente"
                          bodyTitle="Selecione um cliente"
                        />
                        <InputText
                          value={campoPessoa}
                          name="campoPessoa"
                          label="Cliente:"
                          placeholder={
                            "Pesquise o cliente por nome, razão social, " +
                            "apelido, nome fantasia, CPF ou CNPJ"
                          }
                          maxlength={40}
                          onChange={this.handleInputChange}
                          icone={
                            this.hasPessoaSelected()
                              ? "icon-lx-close"
                              : "icon-lx-search"
                          }
                          help={
                            this.hasPessoaSelected()
                              ? 'Clique no "x" para limpar o campo'
                              : this.hasCampoPessoa()
                                ? 'Clique na "lupa" para pesquisar'
                                : null
                          }
                          onIconeClick={() => {
                            if (this.hasPessoaSelected()) {
                              this.clearPessoa();
                            } else this.handlePesquisarPessoa(campoPessoa);
                          }}
                          onKeyDown={
                            campoPessoa
                              ? event => {
                                if (event.keyCode === 13)
                                  this.handlePesquisarPessoa(campoPessoa);
                                else if (event.keyCode === 27)
                                  this.clearPessoa();
                              }
                              : null
                          }
                        /* onBlur={() => {
                      if (!this.hasPessoaSelected())
                        this.handlePesquisarPessoa(campoPessoa);
                    }} */
                        />
                      </div>
                      {this.renderGrupoClientes()}
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <InputDate
                          value={dataDe}
                          name="dataDe"
                          label={
                            Number(situacao) === 1
                              ? "Data de emissão inicial:"
                              : "Data de fechamento inicial:"
                          }
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-3">
                        <InputDate
                          value={dataAte}
                          name="dataAte"
                          label={
                            Number(situacao) === 1
                              ? "Data de emissão final:"
                              : "Data de fechamento final:"
                          }
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-2">
                        <Select
                          label="Situação:"
                          name="situacao"
                          value={situacao}
                          onChange={this.handleInputChange}
                          options={listaSituacoes}
                        />
                      </div>
                      <div className="col-1">
                        <div className="button-wrapper">
                          <Botao
                            icon="icon-lx-search"
                            title={"Consultar"}
                            onClick={this.pesquisa}
                          />
                        </div>
                      </div>
                    </div>
                  </SectionContent>
                  {resultadosPesquisa && resultadosPesquisa.length > 0 ? (
                    <>
                      <Report
                        sender={this}
                        dados={resultadosPesquisa}
                        headers={this.getTableHeaders()}
                        isPrint={false}
                        printTotals={false}
                        printView={false}
                        docs={listDocs}
                        situacao={situacaoConsultada}
                        selectAll={this.state.selectAll}
                      />
                      <ModalDocumentosFiscaisFechamento
                        active={this.state.showModalDocumentosFiscaisFechamento}
                        codigo={this.state.codigoDocumentosFiscaisFechamento}
                        dataInicio={this.state.dataDe}
                        dataFim={this.state.dataAte}
                        handleCloseModal={async (
                          imprimirDocumentos = false,
                          listDocs
                        ) => {
                          let tempCodigo = this.state
                            .codigoDocumentosFiscaisFechamento;
                          this.setState({
                            showModalDocumentosFiscaisFechamento: false,
                            codigoDocumentosFiscaisFechamento: null
                          });
                          if (imprimirDocumentos) {
                            resultadosPesquisa.forEach(rP => {
                              if (rP && rP.codigo === tempCodigo) {
                                rP.selected = true;
                              }
                            });
                            this.setState({ resultadosPesquisa });
                            await this.imprimirDocumentos(listDocs);
                            await this.pesquisa();
                          }
                        }}
                      />
                      <ModalReenviaEmail
                        active={this.state.showModalReenviaEmail}
                        codigoCliente={this.state.codigoClienteReenvioEmail}
                        codigoTitulo={this.state.codigoTituloReenvioEmail}
                        handleCloseModal={() => {
                          this.setState({
                            showModalReenviaEmail: false,
                            codigoClienteReenvioEmail: null,
                            codigoTituloReenvioEmail: null
                          });
                        }}
                      />
                      <ModalVisualizadorRelatorioFaturado
                        titulo={titulo}
                        active={this.state.showModalVisualizadorRelatorio}
                        handleCloseModal={() => {
                          this.setState({
                            showModalVisualizadorRelatorio: false
                          });
                        }}
                        visualizarProps={{
                          dados: this.state.dadosView,
                          docs: listDocs,
                          nomeFantasia: this.state.nomeFantasia
                        }}
                      />
                    </>
                  ) : (
                      <></>
                    )}
                  {this.tagPrint(situacaoConsultada)}
                  <div className="section-footer">{this.tagButtons()}</div>
                </SectionContainer>
              </LinxPostos>
              {tagAlert(this)}
            </>
          )}
      </>
    );
  }
}

export const Visualizar = dados =>
  !dados ||
    !dados.dados ||
    dados.dados.length === 0 ||
    !dados.docs ||
    dados.docs.length === 0 ? (
      <></>
    ) : (
      <>
        {dados.dados.map(d => {
          let doc = null;
          let listaProdutosVendidos = [];
          dados.docs.forEach(item => {
            if (doc) return;
            doc = item.find(element => {
              return Number(element.codigoCliente) === Number(d.codigo);
            });
            item.forEach(doc => {
              doc.produtosVendidos.forEach(itemPV => {
                let prod = listaProdutosVendidos.find(
                  lpv =>
                    Number(lpv.produto) === Number(itemPV.produto) &&
                    Number(lpv.valorUnitario) === Number(itemPV.valorUnitario) &&
                    Number(lpv.codigoCliente) === Number(doc.codigoCliente)
                );
                if (prod) {
                  prod.valorTotal += itemPV.valorTotal;
                  prod.quantidade += itemPV.quantidade;
                } else {
                  const tempItem = {
                    ...itemPV
                  };
                  tempItem.codigoCliente = doc.codigoCliente;
                  listaProdutosVendidos.push(tempItem);
                }
              });
            });
          });

          if (!doc) {
            doc = {};
          }
          return (
            <>
              <table className={dados.show ? "do-print" : "print-nota-prazo"}>
                <h3 className="centro">Relatório de Vendas a Prazo</h3>
                <h5 className="centro">{dados.nomeFantasia}</h5>
                <table className="table-venda relatorio-width">
                  <thead>
                    <tr>
                      <th colspan={2} className="left-pad">
                        Nome: <span>{d.nome}</span>
                      </th>
                      <th colspan={2} className="left-pad">
                        CPF/CNPJ: <span>{maskedCnpjCpf(doc.cnpjCpf)}</span>
                      </th>
                    </tr>

                    <tr>
                      <th className="left-pad">
                        Data Fechamento:{" "}
                        {moment(doc.dataEmissao).format("DD/MM/YYYY")}
                      </th>
                      <th className="left-pad">
                        Data Vencimento:{" "}
                        {moment(doc.dataVencimento).format("DD/MM/YYYY")}
                      </th>
                      <th className="left-pad">
                        Número da Fatura: {doc.numeroTitulo}
                      </th>
                    </tr>
                  </thead>

                  <h3>Documentos Fiscais</h3>
                  <thead>
                    <tr className="titulo-lista">
                      <th className="left-pad">Data Emissão</th>
                      <th className="left-pad">Modelo</th>
                      <th className="left-pad">Nº Documento</th>
                      <th className="left-pad">Autorizado</th>
                      <th className="left-pad">Placa</th>
                      <th className="left-pad">KM</th>
                      <th className="left-pad">Valor</th>
                    </tr>
                  </thead>
                  {dados.docs.map(item => {
                    return item.map(documentoFiscal => {
                      if (
                        Number(documentoFiscal.codigoCliente) === Number(d.codigo)
                      ) {
                        return (
                          <>
                            <tr>
                              <td>
                                {moment(documentoFiscal.dataEmissao).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>{documentoFiscal.modelo}</td>
                              <td>{documentoFiscal.numeroCupom}</td>
                              <td className="pad">{documentoFiscal.autorizado}</td>
                              <td className="pad">{documentoFiscal.placa}</td>
                              <td className="pad">{documentoFiscal.odometro}</td>
                              <td>
                                {"R$" +
                                  formatNumber(documentoFiscal.valorLiquido, 2)}
                              </td>
                            </tr>
                          </>
                        );
                      }
                      return <></>;
                    });
                  })}

                  {dados.docs.map(item => {
                    let valorTotal = 0,
                      showTotal = false;
                    item.forEach(documentoFiscal => {
                      if (
                        Number(documentoFiscal.codigoCliente) === Number(d.codigo)
                      ) {
                        valorTotal += documentoFiscal.valorLiquido;
                        showTotal = true;
                      }
                    });
                    if (showTotal)
                      return (
                        <>
                          <tr>
                            <td />
                            <td />
                            <td>
                              <strong>Total:</strong>
                            </td>
                            <td>
                              <strong>
                                {"R$" + formatNumber(valorTotal, 2)}
                              </strong>
                            </td>
                          </tr>
                        </>
                      );
                    return dados.doc;
                  })}
                </table>

                <table className="table-venda relatorio-width">
                  <h3>Produtos Vendidos</h3>
                  <tr className="titulo-lista">
                    <th className="left">Produto</th>
                    <th className="left">Quantidade</th>
                    <th className="left">Valor Unitário</th>
                    <th className="left">Valor Total</th>
                  </tr>
                  {listaProdutosVendidos.map(lpv => {
                    if (Number(lpv.codigoCliente) === Number(d.codigo)) {
                      return (
                        <>
                          <tr>
                            <td>{lpv.produto}</td>
                            <td>{formatNumber(lpv.quantidade, 3)}</td>
                            <td>{"R$" + formatNumber(lpv.valorUnitario, 3)}</td>
                            <td>{"R$" + formatNumber(lpv.valorTotal, 2)}</td>
                          </tr>
                        </>
                      );
                    } else return <></>;
                  })}
                </table>
              </table>
            </>
          );
        })}
      </>
    );

const Report = ({
  sender,
  dados,
  headers,
  isPrint,
  printTotals,
  printView,
  docs,
  situacao,
  selectAll
}) =>
  dados && dados.length > 0 ? (
    <>
      {printTotals ? (
        <table className="print-nota-prazo">
          <thead>
            <tr>
              <td align="center" colspan={headers.length}>
                Relatório de vendas a prazo
              </td>
            </tr>
            <tr>
              {headers.map(h => {
                return <th align="left">{h}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {dados.map((i, index) => {
              const bg =
                index & 1
                  ? Colors.OddRows.background
                  : Colors.EvenRows.background;

              return (
                <tr bgcolor={bg}>
                  <td>{i.nome}</td>
                  <td>{i.vendasAPrazo}</td>
                  <td>{i.faturadasEmAberto}</td>
                  <td>{!!i.creditoIlimitado ? i.limite : i.limite}</td>
                  <td>{!!i.creditoIlimitado ? i.saldo : i.saldo}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr bgcolor={"blue"}>
              <td>
                <label>Total</label>
              </td>
              <td>
                <label>{dados.totalNaoFaturados}</label>
              </td>
              <td>
                <label>{dados.totalFaturadosEmAberto}</label>
              </td>
              <td />
              <td />
            </tr>
          </tfoot>
        </table>
      ) : printView ? (
        <Visualizar
          dados={dados.filter(d => d.selected)}
          docs={docs}
          nomeFantasia={sender.state.nomeFantasia}
        />
      ) : null}

      {isPrint ? (
        <></>
      ) : (
          <div className="table-ctn table-select-all-column0 table-buttons-last-column">
            <ReactTable
              defaultPageSize={10}
              //onClick={this.handleTableClick}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando registros..."
              pageText="Página"
              ofText="de"
              rowsText="registros"
              data={dados}
              columns={createColumns(sender, situacao, dados, selectAll, true)}

            /* getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  this.handleTableClick(state, rowInfo,
                    column, instance, e);
                }
              };
            }} */
            />
          </div>
        )}
    </>
  ) : null;

RelatorioFaturamentoVendaPrazo = withRouter(
  RelatorioFaturamentoVendaPrazo
);
export { RelatorioFaturamentoVendaPrazo };

export function createColumns(
  sender,
  situacao,
  dados,
  selectAll,
  showName,
  user
) {
  const c = [];
  c.push({
    accessor: "codigo",
    show: false
  });
  c.push({
    accessor: "codigoTitulo",
    show: false
  });
  c.push({
    accessor: "rowVersion",
    show: false
  });
  if (parseInt(situacao) === SituacoesFatura.NaoFaturados) {
    c.push({
      accessor: "selected",
      width: 50,
      sortable: false,
      filterable: false,
      Header: props => {
        return (
          <Checkitem
            name="selectAll"
            title={selectAll ? "Desmarcar todos" : "Marcar todos"}
            checked={selectAll}
            onChange={event => {
              dados.forEach(item => {
                if (item) {
                  item.selected = event.target.checked;
                }
              });
              sender.setState({
                resultadosPesquisa: dados,
                selectAll: event.target.checked
              });
            }}
          />
        );
      },
      Cell: props => {
        return (
          <>
            <Checkitem
              label=""
              name="selected"
              checked={props.row.selected}
              onChange={event => {
                dados.forEach(item => {
                  if (
                    item &&
                    Number(item.codigo) === Number(props.row.codigo)
                  ) {
                    item.selected = event.target.checked;
                    return;
                  }
                });
                props.row.selected = event.target.checked;
                sender.setState({ resultadosPesquisa: dados });
              }}
            />
          </>
        );
      }
    });
  }

  if (parseInt(situacao) === SituacoesFatura.NaoFaturados) {
    if (showName) {
      c.push({
        accessor: "nome",
        Header: "Nome"
      });
    }
    c.push({
      accessor: "tipoPrazo",
      Header: "Tipo de prazo",
      Footer: <div className="rt-td">Total</div>
    });
    c.push({
      accessor: "vendasAPrazo",
      Header: "Não faturadas (R$)",
      Footer: <div className="rt-td">{dados.totalNaoFaturados}</div>
    });
    c.push({
      accessor: "faturadasEmAberto",
      Header: "Faturadas em aberto (R$)",
      Footer: <div className="rt-td">{dados.totalFaturadosEmAberto}</div>
    });
    c.push({
      accessor: "creditoIlimitado",
      show: false
    });
    c.push({
      accessor: "limite",
      Header: "Limite (R$)"
    });
    c.push({
      accessor: "saldo",
      Header: "Saldo disponível (R$)"
    });
    c.push({
      accessor: "hasDocs",
      show: false
    });
    c.push({
      sortable: false,
      filterable: false,
      Cell: rowInfo => {
        return (
          <>
            {rowInfo.row.hasDocs ? (
              <Botao
                tooltip="Visualizar documentos"
                ic
                icon="icon-lx-search"
                onClick={event => {
                  sender.handleVisualizarButtonClick(rowInfo);
                }}
              />
            ) : (
                <></>
              )}

            <Botao
              tooltip="Histórico de faturamentos"
              ic
              icon="icon-lx-files"
              onClick={event => {
                sender.handleHistoricoButtonClick(rowInfo);
              }}
            />
          </>
        );
      }
    });
  } else if (parseInt(situacao) === SituacoesFatura.Faturados) {
    c.push({
      accessor: "nome",
      Header: "Nome",
      sortable: false,
      filterable: false,
      show: showName
    });
    c.push({
      accessor: "numeroFatura",
      Header: "Nº fatura"
    });
    c.push({
      accessor: "numeroNfe",
      Header: "Nº NF-e"
    });
    c.push({
      accessor: "numeroNfeOriginal",
      show: false
    });
    c.push({
      accessor: "dataFechamento",
      Header: "Data fechamento"
    });
    c.push({
      accessor: "dataVencimento",
      Header: "Data vencimento"
    });
    c.push({
      accessor: "valor",
      Header: "Valor"
    });
    c.push({
      accessor: "situacao",
      Header: "Pago/Não pago"
    });
    c.push({
      sortable: false,
      filterable: false,
      width: 190,
      Header: "Opções",
      Cell: rowInfo => {
        return (
          <>
            {rowInfo.row.situacao === "Não pago" ? (
              <>
                <Botao
                  tooltip="Reenviar e-mail"
                  ic
                  icon="icon-lx-envelope"
                  onClick={event => {
                    sender.handleReenviaEmailButtonClick(
                      rowInfo,
                      user ? user : sender
                    );
                  }}
                />
                {rowInfo.row.numeroNfeOriginal === 0 ? (
                  <Botao
                    tooltip="Emitir NF-e"
                    ic
                    icon="icon-lx-report"
                    onClick={event => {
                      sender.handleGerarNfeButtonClick(
                        rowInfo.row.codigoTitulo,
                        rowInfo.row.codigo,
                        rowInfo.row.numeroFatura,
                        user
                      );
                    }}
                  />
                ) : (
                    <></>
                  )}
              </>
            ) : (
                <></>
              )}
            <Botao
              tooltip="Imprimir"
              ic
              icon="icon-lx-print"
              onClick={event => {
                if (user && user.handleImprimirButtonClick) {
                  user.handleImprimirButtonClick(rowInfo.row.codigoTitulo);
                } else {
                  sender.handleImprimirButtonClick(rowInfo.row.codigoTitulo);
                }
              }}
            />
            <Botao
              tooltip="Visualizar fatura"
              ic
              icon="icon-lx-vision"
              onClick={event => {
                if (user && user.handleVisualizarFaturaClick) {
                  user.handleVisualizarFaturaClick(rowInfo.row.codigoTitulo);
                } else {
                  sender.handleVisualizarFaturaClick(rowInfo.row.codigoTitulo);
                }
              }}
            />
            {rowInfo.row.situacao === "Não pago" ? (
              <Botao
                tooltip="Cancelar faturamento"
                ic
                icon="icon-lx-remove-circle"
                onClick={event => {
                  sender.handleCancelarNfe(rowInfo, dados);
                }}
              />
            ) : (
                <></>
              )}
          </>
        );
      }
    });
  }
  return c;
}

export const getCuponsSituacaoFaturado = async codigoTitulo => {
  const { data: dataDocumentos } = await getCuponsFaturados(codigoTitulo);

  return dataDocumentos;
};

export const doGetCuponsFaturados = async codigoTitulo => {
  const result = await getCuponsSituacaoFaturado(codigoTitulo);

  if (result && result.length > 0) {
    const pessoasResponseArray = await Promise.all(
      result.map(async d => {
        const { data: dataPessoa } = await getPessoa(d.codigoCliente);
        return dataPessoa;
      })
    );
    result.forEach(itemD => {
      itemD.cnpjCpf = "";
      pessoasResponseArray.forEach(itemP => {
        if (itemD.cnpjCpf) return;
        if (itemD.codigoCliente === itemP.codigo) {
          itemD.cnpjCpf = itemP.cnpjCpf;
          return;
        }
      });
    });
  }
  return result;
};
