import { CharToInt } from './utils';
export function CheckIERR(ie) {
  if (ie.length !== 9) return false;
  if (ie.substring(0, 2) !== '24') return false;
  var nro = new Array(9);
  for (var i = 0; i <= 8; i++) nro[i] = CharToInt(ie.charAt(i));
  var soma = 0;
  var n = 0;
  for (i = 0; i <= 7; i++) soma += nro[i] * ++n;
  var dig = soma % 9;
  return dig === nro[8];
} //rr
