import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Footer from '../core/footer/Footer';

import styles from './StepWizard.module.scss';
import StepWizardFinished from './StepWizardFinished';
import { confirm, alert } from 'devextreme/ui/dialog';

import { locale } from 'devextreme/localization';

export default function StepWizard({ steps }) {

  locale('pt-BR');
  
  const [currentStep, setCurrentStep] = useState(0);
  const [isCompletedStep, setIsCompletedStep] = useState(false);
  const [awaitIsCompletedStep, setAwaitIsCompletedStep] = useState(false);
  const [messageStepNotCompleted, setMessageStepNotCompleted] = useState(
    'Salve as alterações para continuar.'
  );
  const [isFinished, setIsFinished] = useState(false);
  const [dados, setDados] = useState({});
  const [handleNextStep, setHandleNextStep] = useState(null);
  const [numberPreviousStep, setNumberPreviousStep] = useState({});
  const [confirming, setConfirming] = useState(false);
  
  const history = useHistory();

  const props = {
    dados: dados,
    dadosUpdate: setDados,
    isCompletedStepUpdate: setIsCompletedStep,
    awaitIsCompletedStepUpdate: setAwaitIsCompletedStep, // Função para indicar se deve esperar o isCompletedStep para executar o nextStep
    messageStepNotCompletedUpdate: setMessageStepNotCompleted,
    handleNextStep: setHandleNextStep,
    showAlertUpdate: setShowAlert,
    numberPreviousStep: numberPreviousStep,
    previousStep: previousStep,
    setCurrentStep: setCurrentStep,
    setConfirming: setConfirming
  };

  const footerOptionsAll = [
    { name: 'VOLTAR', class: 'btn-light', action: previousStep },
    {
      name: 'CANCELAR',
      class: 'btn-light',
      action: cancel
    },
    { name: 'AVANÇAR', action: onNextStep }
  ];

  const footerOptionsStep4 = [
    { name: 'VOLTAR', class: 'btn-light', action: previousStep },
    {
      name: 'CANCELAR',
      class: 'btn-light',
      action: cancel
    },
    { name: 'VALIDAR', action: onNextStep }
  ];

  const footerOptionsFirst = [
    { name: 'VOLTAR', class: 'btn-light', action: previousStep },
    { name: 'AVANÇAR', action: onNextStep }
  ];

  const footerOptionsLast = [
    { name: 'VOLTAR', class: 'btn-light', action: previousStep },
    {
      name: 'CANCELAR',
      class: 'btn-light',
      action: cancel
    },
    { name: 'CONFIRMAR', confirming: confirming, action: onNextStep }
  ];

  useEffect(() => {
    if (awaitIsCompletedStep) {
      nextStep();
    }
  }, [isCompletedStep]);

  function onNextStep() {
    if (handleNextStep !== null && handleNextStep !== 'undefined') {
      handleNextStep();
    }

    if (!awaitIsCompletedStep) {
      nextStep();
    }
  }

  function nextStep() {
    if (isCompletedStep) {
      if (steps.length === currentStep + 1) {
        setNumberPreviousStep(currentStep);
        resetStates();
        setIsFinished(true);
      } else {
        setNumberPreviousStep(currentStep);
        setCurrentStep(currentStep + 1);
        resetStates();
      }
    } else {
      setShowAlert();
    }
  }

  function resetStates() {
    setHandleNextStep(null);
    setIsCompletedStep(false);
    setAwaitIsCompletedStep(false);
    setMessageStepNotCompleted('Salve as alterações para continuar.');
  }

  function previousStep() {
    if (currentStep > 0) {
      setNumberPreviousStep(currentStep);
      setCurrentStep(currentStep - 1);
      resetStates();

      delete dados[steps[currentStep].propertyName];
    } else {
      history.push('/cadastro/produtos');
    }
  }

  function setShowAlert(message)
  {
    if (typeof message !== 'undefined') 
      alert(message, "Importação Produto");
    else
      alert(messageStepNotCompleted, "Importação Produto");
  }

  async function cancel() {

    var result = await confirm("Deseja realmente cancelar a operação?", "Importação de produtos");

    if (result)
    {
      resetStates();

      steps.forEach(step => {
        delete dados[step.propertyName];
      });

      setCurrentStep(0);
    }
  }

  return (
    <>
      <div>
        <div className={styles.wizardHeader}>
          <div className={`${styles.wizardHeaderItem}`}>
            <span className={styles.wizardHeaderItemLineFirst} />
            <div className={styles.wizardHeaderItemActive}>
              <h1>{steps[0].index + 1}</h1>
              <p>{steps[0].text}</p>
            </div>
          </div>

          {steps.map(item => {
            if (item.index > 0 && item.index < steps.length - 1) {
              return (
                <div className={styles.wizardHeaderItem} key={item.text}>
                  <span className={styles.wizardHeaderItemLine} />
                  <div
                    className={
                      currentStep >= item.index
                        ? styles.wizardHeaderItemActive
                        : ''
                    }
                  >
                    <h1>{item.index + 1}</h1>
                    <p>{item.text}</p>
                  </div>
                </div>
              );
            }
          })}

          <div className={styles.wizardHeaderItem}>
            <span className={styles.wizardHeaderItemLineLast} />
            <div
              className={
                currentStep >= steps.length - 1
                  ? styles.wizardHeaderItemActive
                  : ''
              }
            >
              <h1>{steps[steps.length - 1].index + 1}</h1>
              <p>{steps[steps.length - 1].text}</p>
            </div>
          </div>
        </div>

        <div className={styles.wizardBodyComponent}>
          {typeof steps[currentStep].component === 'undefined' ? (
            <span>Não foi possível carregar esta etapa</span>
          ) : isFinished ? (
            <StepWizardFinished />
          ) : (
            React.createElement(steps[currentStep].component, props)
          )}
        </div>
      </div>
      <Footer
        customList={
          currentStep === 0
            ? footerOptionsFirst
            : currentStep === 3
            ? footerOptionsStep4
            : currentStep === steps.length - 1
            ? footerOptionsLast
            : footerOptionsAll
        }
      />
    </>
  );
}
