import React, { useState, useEffect } from 'react';
import DataGrid, { Scrolling, Sorting, Paging, Pager, Column } from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';

import styles from './SetupFacilConfirmacao.module.scss';

export default function SetupFacilConfirmacaoGridProdutosMerge({ dados, camposDiferentes, gridRef, unidadesMedida, gridListas }) {
  const [dadosGrid, setDadosGrid] = useState([]);
  const [, setState] = useState();

  useEffect(() => {
    if (dados.length > 0) setDadosGrid(dados);
  }, [dados]);

  const headerCellTemplate = data => {
    return <div className={styles.gridColumnHeader}>{data.column.caption}</div>;
  };

  const atualizaRowsSelecionadas = (registro, valor) => {
    dadosGrid.find(d => d.codigo === registro.codigo && d.possuiCadastro === registro.possuiCadastro).isSelected = valor;

    dadosGrid.find(d => d.codigo === registro.codigo && d.possuiCadastro === !registro.possuiCadastro).isSelected = !valor;
    setDadosGrid(dadosGrid);
    setState({});
  };

  const cellTeplateRadio = data => {
    return (
      <>
        <CheckBox
          defaultValue={false}
          onValueChanged={e => {
            if (e.event) atualizaRowsSelecionadas(data.key, e.value);
          }}
          value={data.key.isSelected}
        />
      </>
    );
  };

  const cellTemplateCamposDiferentes = data => {

    const item = camposDiferentes.filter(item => item.codigo === data.key.codigo);

    const campo = item[0].campos.find(campo => campo.toUpperCase() === data.column.dataField.toUpperCase());

    const isBold = typeof campo !== 'undefined';

    if (data.column.dataField === 'formaReajuste') {
      const result = gridListas.formaReajuste.filter(f => f.value === data.value);
      data.value = (result.length === 0 ? data.value : result[0].label);
    }

    if (data.column.dataField === 'permiteVenda' || data.column.dataField === 'controlaEstoque' || data.column.dataField === 'usaBalanca') {
      const result = gridListas.boolean.filter(f => f.value === data.value);
      data.value = (result.length === 0 ? data.value : result[0].label);
    }

    if (data.column.dataField === 'unidadeCompra' || data.column.dataField === 'unidadeVenda') {
      const result = unidadesMedida.filter(f => f.sigla === data.value);
      data.value = (result.length === 0 ? data.value : result[0].descricao);
    }

    return <>{isBold ? <span style={{ fontWeight: 'bold', color: 'red', fontSize: '12px' }}>{data.value}</span> : <span style={{ fontSize: '12px' }}>{data.value}</span>}</>;
  };

  return (
    <div>
      <div className={`${styles.gridTitle} ${styles.bgBlue}`}>
        <p>Produtos Pendentes</p>
      </div>
      <span className={`${styles.subTitle}`}>
        Selecione a opção para atualizar o cadastro do produto. Os campos: Setor, Estrutura Mercadológica, Preços, Custos e Estoque atual não poderão ser
        atualizadas devido ao histórico no sistema e movimentação de estoque.
      </span>
      <DataGrid
        dataSource={dadosGrid}
        ref={gridRef}
        showBorders={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={'widget'}
        columnMinWidth={80}
        columnAutoWidth={true}
        noDataText="Sem itens para mostrar"
        // onRowPrepared={handleOnRowPrepared}
      >
        <Column dataField="codigo" caption="Código" groupIndex={0} />
        <Column dataField="isSelected" caption="" minWidth={50} headerCellRender={headerCellTemplate} cellRender={cellTeplateRadio} />
        <Column dataField="fonte" caption="Fonte" minWidth={120} headerCellRender={headerCellTemplate} />
        <Column dataField="codigoRapido" caption="Código Rápido" minWidth={120} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="descricao" caption="Descrição" minWidth={250} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="descricaoResumida" caption="Descrição Resumida" minWidth={250} headerCellRender={headerCellTemplate}cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="marca" caption="Marca" minWidth={250} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="tipo" caption="Tipo" minWidth={200} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="subTipo" caption="Subtipo" minWidth={200} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="grupo" caption="Grupo" minWidth={200} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="subGrupo" caption="SubGrupo" minWidth={200} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="artigo" caption="Artigo" minWidth={200} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="setor" caption="Setor" minWidth={200} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="codigoNcm" caption="Código NCM" minWidth={100} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="codigoCest" caption="Código CEST" minWidth={100} headerCellRender={headerCellTemplate} />
        <Column dataField="unidadeCompra" caption="Unidade de Compra" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="unidadeVenda" caption="Unidade de Venda" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="permiteVenda" caption="Permite Venda" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="controlaEstoque" caption="Controla Estoque" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column dataField="usaBalanca" caption="Usa Balança" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column
          dataField="fatorConversao"
          caption="Fator de Conversão"
          alignment="right"
          minWidth={120}
          format={{
            type: 'fixedPoint',
            precision: 3,
          }}
          headerCellRender={headerCellTemplate}
          cellRender={cellTemplateCamposDiferentes} 
        />
        <Column
          dataField="precoVenda"
          caption="Preço de Venda R$"
          alignment="right"
          minWidth={120}
          format={{
            type: 'currency',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
          cellRender={cellTemplateCamposDiferentes}
        />
        <Column 
          dataField="formaReajuste" 
          caption="Forma de Reajuste" 
          minWidth={150} 
          headerCellRender={headerCellTemplate} 
          cellRender={cellTemplateCamposDiferentes}>
        </Column>
        <Column
          dataField="percentualReajuste"
          caption="Percentual de Reajuste"
          alignment="right"
          minWidth={150}
          format={{
            type: 'fixedPoint',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
          cellRender={cellTemplateCamposDiferentes} 
        />
        <Column dataField="cstIcmsVenda" caption="Situação Tributária Venda - ICMS" width={200} headerCellRender={headerCellTemplate} cellRender={cellTemplateCamposDiferentes} />
        <Column
          dataField="aliquotaTributacaoIcms"
          caption="Alíquota Tributação - ICMS"
          alignment="right"
          minWidth={80}
          format={{
            type: 'fixedPoint',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
          cellRender={cellTemplateCamposDiferentes} 
        />
        <Column
          dataField="aliquotaEfeitvaIcms"
          caption="Alíquota Efetiva %"
          alignment="right"
          minWidth={80}
          format={{
            type: 'fixedPoint',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
          cellRender={cellTemplateCamposDiferentes} 
        />
        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
        <Sorting mode="none" />
        <Paging defaultPageSize={15} />
        <Scrolling showScrollbar={'always'} columnRenderingMode="virtual" />
      </DataGrid>
    </div>
  );
}
