// filtroSolSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchFiltroSolResInit = createAsyncThunk('filtroSolRes/init', async () => {
  return {};
});

const newFilter = () => {
  var firstTime = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7);
  var currentTime = new Date();
  currentTime.setHours(23, 59, 59, 999);
  firstTime.setHours(0, 0, 0, 0);

  return {
    itens: [],
    itensPedido: [],
    status: [],
    statusType: [],
    saldoPendente: [],
    consignadoPendente: [],
    comodatoPendente: [],
    locacaoPendente: [],
    filtrosAvancados: false,
  };
};

const initialState = {
  values: newFilter(),
  errors: {},
  touched: {},
  result: [],
  initialized: false,
};

const slice = createSlice({
  name: 'filtroSolRes',
  initialState,
  reducers: {
    changeValue: (state, { payload }) => {
      state.values[payload.fieldName] = payload.value;
      state.errors = {};
      return state;
    },

    setResult: (state, { payload }) => {
      state.values.itens = payload.itens;
      if (!state.values.pagination) {
        state.values.pagination = { posStart: 1, posEnd: 1, paginas: 0, currentPage: 1, itensPorPagina: 10 };
      }
      state.values.pagination.currentPage = payload.pagina;
      state.values.pagination.paginas = payload.paginas;
      state.values.pagination.itensPorPagina = payload.itensPorPagina;
      return state;
    },

    setItensResult: (state, { payload }) => {
      state.values.itensPedido = payload.itens;
      if (!state.values.paginationItens) {
        state.values.paginationItens = { posStart: 1, posEnd: 1, paginas: 0, currentPage: 1, itensPorPagina: 10 };
      }
      state.values.paginationItens.currentPage = payload.pagina;
      state.values.paginationItens.paginas = payload.paginas;
      state.values.paginationItens.itensPorPagina = payload.itensPorPagina;
      return state;
    },

    changeBlur: (state, { payload }) => {
      state.touched = { ...state.touched, [payload.fieldName]: true };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFiltroSolResInit.fulfilled, (state) => {
      state.values = newFilter();
      state.errors = {};
      state.initialized = true;
      state.touched = {};
      state.result = [];
      return state;
    });
  },
});

// Export the reducer as a named export
export const { changeValue, setResult, setItensResult, changeBlur } = slice.actions;
export const selectFiltroSolRes = (state) => state.filtroSolRes;
export const useFiltroSolRes = () => slice.actions;

// Export the reducer as a named export
export default slice.reducer;
