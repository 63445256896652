import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Routes from './Routes';
import './scss/App.scss';
import { updateTema } from './shared/utils/Utils';
import { fetchSharedDataReset } from './redux/slices/consignadoComodato/SharedData.slice';
import 'devextreme/dist/css/dx.light.css';
import 'sweetalert2/dist/sweetalert2.min.css';

const App = () => {
  const dispatch = store.dispatch;
  useEffect(() => {
    updateTema();
    const event = e => {
      dispatch(fetchSharedDataReset());
    };
    window.addEventListener('login', event);
    window.addEventListener('logout', event);

    return () => {
      window.removeEventListener('login', event);
      window.removeEventListener('logout', event);
    };
  });

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default App;
