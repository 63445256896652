import memoryCache from 'memory-cache';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';

export class ApiRelatorioControleAtivosService {
  static instance = null;
  static getInstance() {
    if (!ApiRelatorioControleAtivosService.instance) {
      ApiRelatorioControleAtivosService.instance = new ApiRelatorioControleAtivosService();
    }
    return ApiRelatorioControleAtivosService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (prodCod, serie, statusPedido, dtEntrada, dtSaida) => {
    try {
      const dadosLogin = getDadosLogin();
      let query = '?';
      if (prodCod) {
        query += `prodCod=${prodCod}&`;
      }

      if (serie) {
        query += `serie=${serie}&`;
      }

      if (statusPedido) {
        query += `statusPedido=${statusPedido}&`;
      }

      if (dtEntrada) {
        query += `dtEntrada=${dtEntrada}&`;
      }

      if (dtSaida) {
        query += `dtSaida=${dtSaida}&`;
      }
      if (query.endsWith('&')) {
        query = query.slice(0, -1);
      }
      const url = `/ConComodato/controle-ativos${query}`;
      const cacheKey = `ApiRelatorioControleAtivosService_${dadosLogin.cnpj}_${url}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        return cachedData;
      }
      const response = await this.urlLinxCore.get(url);
      const data = response.data.itens || [];
      console.log('url', data);
      memoryCache.put(cacheKey, data, 5 * 60 * 1000); // cache de 30 minutos
      return data;
    } catch (er) {
      console.log('er', er);
      await new AxiosErrorService().execute(er);
    }
  };
}
