import React, { Component } from 'react';
import {
  Select,
  handleInputChange
} from '../../../components/formulario/Formulario';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  showInfo,
  showError,
  hideAlert,
  tagAlert,
  showSaveError,
  showWait,
  hideWait
} from '../../../components/alert/Alert';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ConfiguracaoGeralItem from './ConfiguracaoGeralItem/ConfiguracaoGeralItem';
import {
  getConfiguracoesGerais,
  atualizaConfiguracaoGeral,
  testaConfiguracaoEmail
} from './ConfiguracoesGerais.service';
import {
  menuPermiteAlteracao,
  getNomePagina,
  isValidUrl,
  isValidEmail,
  validaDadosLogin
} from '../../../shared/utils/Utils';
import {
  ALPHANUMERIC_WITH_SPACE_PATTERN,
  NUMERIC_PATTERN
} from '../../../shared/constants/Const';
import Footer from '../../../components/core/footer/Footer';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../shared/constants/MenuConstants';
import { Link } from 'react-router-dom';
import styles from './ConfiguracoesGerais.module.scss';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import {
  CheckBox,
  NumberBox,
  TabPanel,
  TextBox
} from 'devextreme-react';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';

export const Params = {
  CartaoIdentificacao: 148,
  PercentualCustoMedio: 222,

  DataInicioHorarioVerao: 34,
  DataFimHorarioVerao: 35,

  DiscriminacaoCheque: 1,
  AceitaChequeTerceiro: 2,
  CapturaDadosChequePdv: 3,
  NumeroViasComprovante: 17,
  TipoComissionamento: 19,
  UtilizaMesmoAtendente: 21,
  ObrigaSelecaoAtendente: 125,

  ServidorSmtp: 342,
  PortaSmtp: 343,
  AutenticacaoSegura: 344,
  EnderecoEmail: 345,
  NomeExibicao: 346,
  UsuarioEmail: 347,
  SenhaEmail: 348,
  SerieUtilizarNotaFiscalFatura: 185,

  TipoPaginacaoLMC: 54,
  ValorMinimoGeracaoVale: 27,
  FormaCalculoDataVencimentoFatura: 76,
  NaoConsideraSuprimentoDiferenca: 183,
  GerarTitulosReceberVendasDinheiro: 1001,
  ConferenciaPorOperador: 1002
};

const TipoComissionamento = {
  Nenhum: '0',
  Produto: '1',
  Faixa: '3'
};

const TiposComissionamentos = [
  { value: TipoComissionamento.Nenhum, label: 'Nenhum' },
  { value: TipoComissionamento.Produto, label: 'Produto' },
  { value: TipoComissionamento.Faixa, label: 'Faixa' }
];

const QtdViasComprovante = [0, 1, 2, 3, 4];

const TipoPaginacaoLMC = {
  Nenhum: '0',
  UmParaCada: '1',
  UmParaTodos: '2'
  //1 - Ordenar por combustível e dia (Um livro para cada combustível);
  //2 - Ordenar por dia e combustível (Um livro para todos os combustíveis)
};

const TiposPaginacoesLMC = [
  {
    value: TipoPaginacaoLMC.UmParaCada,
    label: 'Um livro para cada combustível'
  },
  {
    value: TipoPaginacaoLMC.UmParaTodos,
    label: 'Um livro para todos os combustíveis'
  }
];

export const FormaCalculoDataVencimento = {
  Nenhum: '0',
  ConfigCliente: '1',
  DataGeracao: '2'
};

const titulo = 'Configurações Gerais';

const PATH_MENU = menus.COD_60580;

class ConfiguracoesGerais extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    this.state = {
      percentualCustoMedio: 0,
      dataInicioHorarioVerao: null,
      dataFimHorarioVerao: null,
      qtdViasComprovante: null,
      discriminacaoCheque: null,
      aceitaChequeTerceiro: null,
      capturaDadosChequePdv: null,
      tipoComissionamento: null,
      utilizaMesmoAtendente: null,
      obrigaSelecaoAtendente: null,

      servidorSmtp: null,
      portaSmtp: null,
      autenticacaoSegura: false,
      enderecoEmail: null,
      nomeExibicao: null,
      usuarioEmail: null,
      senhaEmail: null,
      serieUtilizarNotaFiscalFatura: null,
      tipoPaginacaoLMC: null,
      valorMinimoGeracaoVale: null,
      formaCalculoDataVencimentoFatura: null,
      naoConsideraSuprimentoDiferenca: null,
      gridBoxLMC: [],
      conferenciaPorOperador: false
    };
  }

  async componentDidMount() {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto os dados são carregados.'
    );
    try {
      await this.loadParams();
    } finally {
      hideWait(this);
    }
  }

  loadParams = async () => {
    const { data } = await getConfiguracoesGerais();

    const getParam = (id, padrao) => {
      const param = data.data.find((c, i, a) => {
        return c.id === id;
      });
      return param && param.valor ? param.valor : padrao;
    };

    const state = {
      percentualCustoMedio: isNaN(getParam(Params.PercentualCustoMedio, '0'))
        ? '0'
        : getParam(Params.PercentualCustoMedio, '0'),
      qtdViasComprovante: getParam(Params.NumeroViasComprovante, '2'),
      discriminacaoCheque: getParam(Params.DiscriminacaoCheque, false) === '1',
      aceitaChequeTerceiro:
        getParam(Params.AceitaChequeTerceiro, false) === '1',
      capturaDadosChequePdv:
        getParam(Params.CapturaDadosChequePdv, false) === '1',
      tipoComissionamento: getParam(Params.TipoComissionamento, 0),
      utilizaMesmoAtendente:
        getParam(Params.UtilizaMesmoAtendente, false) === '1',
      obrigaSelecaoAtendente:
        getParam(Params.ObrigaSelecaoAtendente, false) === '1',
      serieUtilizarNotaFiscalFatura: getParam(
        Params.SerieUtilizarNotaFiscalFatura,
        '0'
      ),
      servidorSmtp: getParam(Params.ServidorSmtp, ''),
      portaSmtp: getParam(Params.PortaSmtp, null),
      autenticacaoSegura: getParam(Params.AutenticacaoSegura, false) === '1',
      enderecoEmail: getParam(Params.EnderecoEmail, ''),
      nomeExibicao: getParam(Params.NomeExibicao, ''),
      usuarioEmail: getParam(Params.UsuarioEmail, ''),
      senhaEmail: getParam(Params.SenhaEmail, ''),
      tipoPaginacaoLMC: getParam(
        Params.TipoPaginacaoLMC,
        TipoPaginacaoLMC.UmParaTodos
      ),
      gridBoxLMC: [
        getParam(Params.TipoPaginacaoLMC, TipoPaginacaoLMC.UmParaTodos)
      ],
      valorMinimoGeracaoVale: getParam(Params.ValorMinimoGeracaoVale, 0),
      formaCalculoDataVencimentoFatura: getParam(
        Params.FormaCalculoDataVencimentoFatura,
        1
      ),
      naoConsideraSuprimentoDiferenca:
        getParam(Params.NaoConsideraSuprimentoDiferenca, false) === '1',
      conferenciaPorOperador:
        getParam(Params.ConferenciaPorOperador, false) === '1',

      gerarTitulosReceberVendasDinheiro:
        getParam(Params.GerarTitulosReceberVendasDinheiro, false) === '1'
    };

    this.setState(state);
  };

  saveParams = () => {
    const montaParam = (id, valor, isCheckBox = false) => {
      return {
        id,
        valor: isCheckBox ? (valor ? '1' : '0') : String(valor)
      };
    };

    if (this.isFormValid()) {
      atualizaConfiguracaoGeral([
        montaParam(
          Params.PercentualCustoMedio,
          this.state.percentualCustoMedio || 0
        ),
        montaParam(
          Params.DiscriminacaoCheque,
          this.state.discriminacaoCheque,
          true
        ),
        montaParam(
          Params.AceitaChequeTerceiro,
          this.state.aceitaChequeTerceiro,
          true
        ),
        montaParam(
          Params.CapturaDadosChequePdv,
          this.state.capturaDadosChequePdv,
          true
        ),
        montaParam(Params.TipoComissionamento, this.state.tipoComissionamento),
        montaParam(Params.NumeroViasComprovante, this.state.qtdViasComprovante),
        montaParam(
          Params.UtilizaMesmoAtendente,
          this.state.utilizaMesmoAtendente,
          true
        ),
        montaParam(
          Params.ObrigaSelecaoAtendente,
          this.state.obrigaSelecaoAtendente,
          true
        ),
        montaParam(
          Params.SerieUtilizarNotaFiscalFatura,
          this.state.serieUtilizarNotaFiscalFatura
        ),
        montaParam(Params.ServidorSmtp, this.state.servidorSmtp),
        montaParam(Params.PortaSmtp, this.state.portaSmtp),
        montaParam(
          Params.AutenticacaoSegura,
          this.state.autenticacaoSegura,
          true
        ),
        montaParam(Params.EnderecoEmail, this.state.enderecoEmail),
        montaParam(Params.NomeExibicao, this.state.nomeExibicao),
        montaParam(Params.UsuarioEmail, this.state.usuarioEmail),
        montaParam(Params.SenhaEmail, this.state.senhaEmail),
        montaParam(Params.TipoPaginacaoLMC, this.state.tipoPaginacaoLMC),
        montaParam(
          Params.ValorMinimoGeracaoVale,
          this.state.valorMinimoGeracaoVale
        ),
        montaParam(
          Params.FormaCalculoDataVencimentoFatura,
          this.state.formaCalculoDataVencimentoFatura
        ),
        montaParam(
          Params.NaoConsideraSuprimentoDiferenca,
          this.state.naoConsideraSuprimentoDiferenca,
          true
        ),
        montaParam(
          Params.GerarTitulosReceberVendasDinheiro,
          this.state.gerarTitulosReceberVendasDinheiro,
          true
        ),
        montaParam(
          Params.ConferenciaPorOperador,
          this.state.conferenciaPorOperador,
          true
        )
      ]);
    }
  };

  testEmailConfig = async () => {
    if (!this.isEmailConfigValid()) {
      return;
    }

    const configEmail = {
      smtp: this.state.servidorSmtp,
      porta: Number(this.state.portaSmtp),
      autenticacaoSegura: this.state.autenticacaoSegura,
      email: this.state.enderecoEmail,
      nome: this.state.nomeExibicao,
      usuario: this.state.usuarioEmail,
      senha: this.state.senhaEmail
    };
    showWait(this, titulo);
    try {
      const { data: dataEmail } = await testaConfiguracaoEmail(configEmail);
      if (!dataEmail) {
        showInfo(this, titulo, 'Configurações de e-mail ok.');
      } else {
        hideWait(this);
      }
    } catch (e) {
      showError(
        this,
        titulo,
        'Erro ao enviar e-mail. Verifique as configurações.\n' +
          'Mensagem do servidor: ' +
          e.message,
        'servidorSmtp'
      );
    }
  };

  isEmailConfigValid = () => {
    if (this.state.servidorSmtp) {
      if (!isValidUrl(this.state.servidorSmtp)) {
        showError(
          this,
          titulo,
          'Informe um servidor SMTP válido',
          'servidorSmtp'
        );
        return false;
      }

      if (!this.state.portaSmtp) {
        showError(this, titulo, 'Informe a porta SMTP', 'portaSmtp');
        return false;
      }

      if (!isValidEmail(this.state.enderecoEmail)) {
        showError(
          this,
          titulo,
          'Informe um endereço de e-mail válido',
          'enderecoEmail'
        );
        return false;
      }

      if (this.state.autenticacaoSegura) {
        if (!this.state.usuarioEmail) {
          showError(
            this,
            titulo,
            'Informe o usuário das configurações de e-mail',
            'usuarioEmail'
          );
          return false;
        }

        if (!this.state.senhaEmail) {
          showError(
            this,
            titulo,
            'Informe a senha do usuário das configurações de e-mail',
            'senhaEmail'
          );
          return false;
        }
      }
    }
    return true;
  };

  isFormValid = () => {
    const { dataInicioHorarioVerao, dataFimHorarioVerao } = this.state;
    if (this.state.percentualCustoMedio > 100) {
      showError(
        this,
        titulo,
        'Percentual de tolerância para variação do ' +
          'custo médio do produto não deve ultrapassar 100%',
        'percentualCustoMedio'
      );
      return false;
    }

    if (
      moment(dataFimHorarioVerao).format('YYYY-MM-DD') <
      moment(dataInicioHorarioVerao).format('YYYY-MM-DD')
    ) {
      showError(
        this,
        titulo,
        'Data de fim do horário de verão não pode ' +
          'ser menor que data início do horário de verão',
        'dataFimHorarioVerao'
      );
      return false;
    }

    if (!this.isEmailConfigValid()) {
      return false;
    }

    return true;
  };

  handleSalvar = async () => {
    if (this.isFormValid()) {
      if (!validaDadosLogin()) {
        this.props.history.push('/');
      }
      try {
        showWait(
          this,
          titulo,
          'Por favor, aguarde enquanto as configurações são salvas'
        );
        this.saveParams();
        this.forceUpdate();
        hideAlert(this);
        showInfo(this, titulo, 'Configurações gerais alteradas com sucesso!');
      } catch (e) {
        showSaveError(this, titulo, e);
      }
    }
  };

  changeAceitaCheque = event => {
    let value, name;

    value = event.target.checked;
    name = event.name;

    if (value === false) {
      this.setState({ capturaDadosChequePdv: false });
    }

    this.setState({ aceitaChequeTerceiro: value });
    this.setState({ [name]: value });
  };

  changeTipoCom = event => {
    let value, name;

    value = event.target.value;
    name = event.name;

    if (value === TipoComissionamento.Nenhum) {
      this.setState({ obrigaSelecaoAtendente: false });
    }

    this.setState({ tipoComissionamento: value });
    this.setState({ [name]: value });
  };

  changeNumeroViasComprovante = event => {
    let value, name;

    value = event.target.value;
    name = event.name;

    this.setState({ qtdViasComprovante: value });
    this.setState({ [name]: value });
  };

  changeLMC = e => {
    // e.stopPropagation();
    e.preventDefault();

    this.setState({
      tipoPaginacaoLMC: e.target.value
    });
  };

  handleValueChange = e => {
    let key = e.event.key;

    let regex = new RegExp(NUMERIC_PATTERN);
    if (!regex.test(key)) {
      e.event.preventDefault();
      return false;
    }
  };

  handleValueChangeAlphaNumeric = e => {
    let key = e.event.key;

    let regex = new RegExp(ALPHANUMERIC_WITH_SPACE_PATTERN);
    if (!regex.test(key)) {
      e.event.preventDefault();
      return false;
    }
  };

  panelTitleRender = e => {
    return <h1 className={`${styles.pannelTitle} pannelTitle`}>{e.title}</h1>;
  };

  panelDataSource = () => {
    return [
      { index: 0, title: 'Gerais' },
      { index: 1, title: 'PDV' },
      { index: 2, title: 'E-mail' },
      { index: 3, title: 'LMC' }
    ];
  };

  renderGerais = () => {
    return (
      <div className={styles.tabContent}>
        <ConfiguracaoGeralItem
          titulo="Percentual de tolerância para variação do custo médio do produto"
          size="col-5"
          sizeValue="col-5"
        >
          <TextBox
            name="percentualCustoMedio"
            onValueChanged={e =>
              this.setState({
                percentualCustoMedio: e.value
              })
            }
            value={this.state.percentualCustoMedio}
            maxLength={3}
            onKeyPress={this.handleValueChange}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Série Nota Fiscal Fatura"
          size="col-5"
          sizeValue="col-5"
        >
          <TextBox
            name="serieUtilizarNotaFiscalFatura"
            onValueChanged={e =>
              this.setState({
                serieUtilizarNotaFiscalFatura: e.value
              })
            }
            value={this.state.serieUtilizarNotaFiscalFatura}
            maxLength={3}
            onKeyPress={this.handleValueChange}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Valor mínimo aceitável para perdas e sobras de caixa"
          size="col-5"
          sizeValue="col-5"
        >
          <NumberBox
            name="valorMinimoGeracaoVale"
            showClearButton={false}
            format={{
              type: 'currency',
              precision: 2
            }}
            value={this.state.valorMinimoGeracaoVale}
            onValueChanged={e => {
              this.setState({
                valorMinimoGeracaoVale: e.value
              });
            }}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Não considerar suprimento nas transações financeiras e diferenças da conferência de caixa"
          size="col-5"
          sizeValue="col-5"
        >
          <CheckBox
            name="naoConsideraSuprimentoDiferenca"
            value={this.state.naoConsideraSuprimentoDiferenca}
            onValueChanged={e =>
              this.setState({
                naoConsideraSuprimentoDiferenca: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>
        <ConfiguracaoGeralItem
          titulo="Gerar títulos a receber para vendas em dinheiro na conferência de turno"
          size="col-5"
          sizeValue="col-5"
        >
          <CheckBox
            name="gerarTitulosReceberVendasDinheiro"
            value={this.state.gerarTitulosReceberVendasDinheiro}
            onValueChanged={e =>
              this.setState({
                gerarTitulosReceberVendasDinheiro: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Habilitar conferência de caixa por operador"
          size="col-5"
          sizeValue="col-5"
        >
          <CheckBox
            name="conferenciaPorOperador"
            value={this.state.conferenciaPorOperador}
            onValueChanged={e => {
              this.setState({
                conferenciaPorOperador: e.value
              });
              if (e.value) {
                showInfo(
                  this,
                  titulo,
                  'Para realizar a conferência por operador, no PDV deve estar selecionado o parâmetro: "Agrupar movimentação do caixa por operador" em Configurações > Caixa. Dessa forma, em todos os lançamentos será solicitada a identificação do operador.'
                );
              }
            }}
          />
        </ConfiguracaoGeralItem>
      </div>
    );
  };

  renderPDV = () => {
    return (
      <div className={styles.tabContent}>
        <ConfiguracaoGeralItem
          titulo="Solicitar discriminação dos cheques"
          size="col-5"
          sizeValue="col-1"
        >
          <CheckBox
            name="discriminacaoCheque"
            value={this.state.discriminacaoCheque}
            onValueChanged={e =>
              this.setState({
                discriminacaoCheque: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Aceitar cheques de terceiros"
          size="col-5"
          sizeValue="col-1"
        >
          <CheckBox
            name="aceitaChequeTerceiro"
            value={this.state.aceitaChequeTerceiro}
            onValueChanged={e =>
              this.setState({
                aceitaChequeTerceiro: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Obrigar captura dos dados do emitente do cheque no PDV"
          size="col-5"
          sizeValue="col-1"
        >
          <CheckBox
            name="capturaDadosChequePdv"
            value={this.state.capturaDadosChequePdv}
            disabled={this.state.aceitaChequeTerceiro === false}
            onValueChanged={e =>
              this.setState({
                capturaDadosChequePdv: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Tipo de comissionamento"
          size="col-5"
          sizeValue="col-5"
        >
          <Select
            label=""
            name="tipoComissionamento"
            value={this.state.tipoComissionamento}
            onChange={this.changeTipoCom}
            options={TiposComissionamentos}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Utilizar mesmo 'Atendente' para itens comissionados no mesmo CF"
          size="col-5"
          sizeValue="col-1"
        >
          <CheckBox
            name="utilizaMesmoAtendente"
            value={this.state.utilizaMesmoAtendente}
            onValueChanged={e =>
              this.setState({
                utilizaMesmoAtendente: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Obrigar a seleção de um atendente comissionado da lista"
          size="col-5"
          sizeValue="col-1"
        >
          <CheckBox
            name="obrigaSelecaoAtendente"
            disabled={
              this.state.tipoComissionamento === TipoComissionamento.Nenhum
            }
            value={this.state.obrigaSelecaoAtendente}
            onValueChanged={e =>
              this.setState({
                obrigaSelecaoAtendente: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Número de vias de comprovante para vendas a faturar"
          size="col-5"
          sizeValue="col-1"
        >
          <Select
            label=""
            name="qtdViasComprovante"
            value={this.state.qtdViasComprovante}
            onChange={this.changeNumeroViasComprovante}
            options={QtdViasComprovante}
          />
        </ConfiguracaoGeralItem>
      </div>
    );
  };

  renderEmail = () => {
    return (
      <div className={styles.tabContent}>
        <ConfiguracaoGeralItem
          titulo="Servidor SMTP"
          size="col-5"
          sizeValue="col-5"
        >
          <TextBox
            value={this.state.servidorSmtp}
            name="servidorSmtp"
            maxlength={100}
            onValueChanged={e => this.setState({ servidorSmtp: e.value })}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Porta SMTP"
          size="col-5"
          sizeValue="col-5"
        >
          <TextBox
            value={this.state.portaSmtp}
            name="portaSmtp"
            onKeyPress={this.handleValueChange}
            maxLength={3}
            onValueChanged={e => this.setState({ portaSmtp: e.value })}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Autenticação segura"
          size="col-5"
          sizeValue="col-1"
        >
          <CheckBox
            name="autenticacaoSegura"
            value={this.state.autenticacaoSegura}
            onValueChanged={e =>
              this.setState({
                autenticacaoSegura: e.value
              })
            }
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Endereço de e-mail"
          size="col-5"
          sizeValue="col-5"
        >
          <TextBox
            value={this.state.enderecoEmail}
            maxlength={200}
            onValueChanged={e => this.setState({ enderecoEmail: e.value })}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem
          titulo="Nome de exibição"
          size="col-5"
          sizeValue="col-5"
        >
          <TextBox
            value={this.state.nomeExibicao}
            name="nomeExibicao"
            maxlength={250}
            onValueChanged={e => this.setState({ nomeExibicao: e.value })}
            onKeyPress={this.handleValueChangeAlphaNumeric}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem titulo="Usuário" size="col-5" sizeValue="col-5">
          <TextBox
            value={this.state.usuarioEmail}
            inputAttr={{ autoComplete: 'new-password' }}
            maxlength={200}
            onValueChanged={e => this.setState({ usuarioEmail: e.value })}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem titulo="Senha" size="col-5" sizeValue="col-5">
          <TextBox
            value={this.state.senhaEmail}
            inputAttr={{ autoComplete: 'new-password' }}
            mode="password"
            maxlength={200}
            onValueChanged={e => this.setState({ senhaEmail: e.value })}
          />
        </ConfiguracaoGeralItem>

        <ConfiguracaoGeralItem size="col-5" sizeValue="col-5">
          <div className="row align-items-center justify-content-start">
            <div className="col-2">
              <button
                className={`${styles.test} btn btn-primary`}
                onClick={this.testEmailConfig}
              >
                Testar
              </button>
            </div>
            <div className="col-1" />
            <div className="col-2">
              <Tooltip
                placement="bottom"
                overlay={<span>Como configurar o SMTP no Pop?</span>}
              >
                <Link
                  to="https://share.linx.com.br/pages/viewpage.action?pageId=182111828"
                  target="_blank"
                  onClick={event => {
                    event.preventDefault();
                    window.open(
                      'https://share.linx.com.br/pages/viewpage.action?pageId=182111828'
                    );
                  }}
                >
                  <li className={`d-none d-sm-block`}>
                    <i className={`${styles.help} icon icon-lx-question`}></i>
                  </li>
                </Link>
              </Tooltip>
            </div>
          </div>
        </ConfiguracaoGeralItem>

        {/* <div className="row align-items-center col-5">
          <button
            className={`${styles.test} btn btn-primary`}
            onClick={this.testEmailConfig}
          >
            Testar
          </button>
        </div> */}
      </div>
    );
  };

  renderLMC = () => {
    return (
      <div className={styles.tabContent}>
        <ConfiguracaoGeralItem
          titulo="Tipo de paginação"
          size="col-5"
          sizeValue="col-5"
        >
          <Select
            label=""
            name="tipoPaginacaoLMC"
            value={this.state.tipoPaginacaoLMC}
            onChange={this.changeLMC}
            options={TiposPaginacoesLMC}
          />
        </ConfiguracaoGeralItem>
      </div>
    );
  };

  render() {
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <main className={`${styles.ConfiguracoesGerais} ConfiguracoesGerais main`}>
            <section className="section-container">
              <ScreenHeader
                search={false}
                pathMenu={PATH_MENU}
                newButton={false}
              />
              <SectionContainer>
                <SectionContent>
                  <TabPanel
                    dataSource={this.panelDataSource()}
                    width="100%"
                    animationEnabled={true}
                    itemTitleRender={x => this.panelTitleRender(x)}
                    itemRender={x => {
                      if (x.index === 0) {
                        return this.renderGerais();
                      } else if (x.index === 1) {
                        return this.renderPDV();
                      } else if (x.index === 2) {
                        return this.renderEmail();
                      } else {
                        return this.renderLMC();
                      }
                    }}
                  />
                </SectionContent>
              </SectionContainer>

              <Footer
                saveAction={
                  menuPermiteAlteracao(PATH_MENU) ? this.handleSalvar : null
                }
              />
            </section>
          </main>
        </LinxPostos>
        {tagAlert(this)}
      </>
    );
  }
}

ConfiguracoesGerais = withRouter(ConfiguracoesGerais);
export { ConfiguracoesGerais };
