import { getUrlLinxCore } from '../../../services/core/Auth.service';

const cliente = {
  listaClientes
};

async function listaClientes() {
  const response = await getUrlLinxCore().get('/Cliente/Listar');
  return response.data.data;
}

export default cliente;
