import React, { useRef, useState, useEffect, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react/load-indicator";

import { getDadosLogin } from "../../../../../../shared/utils/Utils";

import { CustomButton } from "../../../SharedComponents/";
import { Formulario } from "./Formulario/Formulario";
import { VincularTitulos } from "../../../SharedComponents/";
import { Sucesso } from "./Sucesso/Sucesso";

import { CobrancasService } from "../Cobrancas.service";

export const GerarBoletos = ({
  isShow,
}) => {
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [barCode, setBarCode] = useState(null);
  const [billId, setBillId] = useState(null);
  const [formData, setFormData] = useState();
  const [selectedFinancial, setSelectedFinancial] = useState([]);

  const formRef = useRef(null);

  const addDaysToDate = (date, days) => {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const resetStatus = useCallback(() => {
    setStep(0);

    setFormData({
      billingType: "",
      billingValue: "",
      expireDate: addDaysToDate(new Date(), 4),
      limitDate: addDaysToDate(new Date(), 4),
      clientId: "",
      payName: "",
      documentPay: "",
      discountPerc: 0,
      disctounExpireDate: "",
      assessmentPerc: 0,
      startAssessmentDate: "",
    });

    setIsSuccess(false);
 }, []);

  const handleSubmit = async () => {
    const formInstance = formRef.current.instance;
    const validationResult = formInstance.validate();

    if (validationResult.isValid) {
      const dadosLogin = getDadosLogin();

      const data = formInstance.option("formData");
      const payloadData = {
        ...data,
        titleCod: Array.isArray(selectedFinancial) ? selectedFinancial : [],
        estaCod: dadosLogin.codigoEstabelecimento,
      };

      setFormData(payloadData);
      setIsLoading(true);
      const response = await CobrancasService.postGenerateInvoice(payloadData);

      if(response != null && response.notifications != null && response.notifications.length > 0){

        var qtdNotify = response.notifications.length;
        var msg = '';
        for(var i = 0; i< qtdNotify; i++){
          msg += response.notifications[i].message + '\n';
        }

        notify(msg, "error", 5000);
      }else{

        if (response && response.result) {
          const { id, metadata: { barcode_number } } = response.result;

          setBillId(id);
          setBarCode(barcode_number);
          setIsSuccess(!isSuccess);

          notify("Boleto gerado com sucesso", "success", 2000);

        } else {
          notify(response.message, "error", 5000);
        }
      }



      setIsLoading(false);

    }
  };

  const onFinish = (selectedRows = []) => {
    setSelectedFinancial(selectedRows);
    setStep(1);
  }

  useEffect(() => {
    if (!isShow) {
      resetStatus();
    }
  }, [isShow, resetStatus])

  return (
    <>
      {isShow &&
        <div className="d-flex flex-column h-100">
          <VincularTitulos
            message="Deseja vincular o boleto a um título do financeiro?"
            isShow={step === 0 && !isLoading}
            onFinish={onFinish}
            typeTitle="R"
          />
          <Formulario
            isShow={step === 1 && !isLoading && !isSuccess}
            formRef={formRef}
            formData={formData}
            setFormData={setFormData}
          />
          <Sucesso
            isShow={isSuccess && !isLoading}
            barCode={barCode}
            billId={billId}
          />
          {step === 1 && !isLoading && !isSuccess && formData.payName !== "" &&
            <div className="d-flex justify-content-between ml-auto my-3">
              <CustomButton className="rounded" icon="chevronnext" iconPosition="right" duplicateIcon={true} onClick={handleSubmit}>
                Gerar boleto
              </CustomButton>
            </div>
          }
          {isLoading &&
            <div className="d-flex justify-content-center align-items-center">
              <LoadIndicator id="large-indicator" height={60} width={60} />
            </div>
          }
        </div>
      }
    </>
  );
};
