import React, { useState } from "react";
import { Popup } from "devextreme-react";
import { Col, Row, Container } from "react-bootstrap";

import { CustomButton } from "../../SharedComponents/CustomButton/CustomButton";
import { RealizarPagamento } from "./RealizarPagamento/RealizarPagamento";
import { RealizarPagamentoDDA } from "./RealizarPagamento/DDA/RealizarPagamentoDDA";
import { Realizados } from "./Realizados/Realizados";

export function Pagamentos() {
  const [isShow, setIsShow] = useState(false);
  const [isShowDDA, setIsShowDDA] = useState(false);

  const optionsButton = [
    {
      id: 1,
      icon: "money",
      title: "Realizar pagamento",
    },
    {
      id: 2,
      icon: "money",
      title: "Realizar pagamento DDA",
    },
  ];

  const openModal = () => {
    setIsShow(!isShow);
  }
  const openModalDDA = () => {
    setIsShowDDA(!isShowDDA);
  }


  return (
    <Col>
      <Container fluid>
        <Row as="header">
          <h2>Pagamentos</h2>
        </Row>
        <Row style={{gap: 8}}>
          {optionsButton.map(({ id, icon, title,  }) => (
            <CustomButton className="rounded" key={id} icon={icon} onClick={(id == 1 )? openModal : openModalDDA }>{ title }</CustomButton>
          ))}
        </Row>
        <Row>
          <Realizados />
        </Row>
        <Popup
          visible={isShow}
          dragEnabled={false}
          showTitle={true}
          title="Realizar pagamento"
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={openModal}
          width="80%"
          height="auto"
          minHeight="471px"
        >
          <RealizarPagamento isShow={isShow} />
        </Popup>
        <Popup
          visible={isShowDDA}
          dragEnabled={false}
          showTitle={true}
          title="Realizar pagamento"
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={openModalDDA}
          width="80%"
          height="auto"
          minHeight="471px"
        >
          <RealizarPagamentoDDA isShow={isShowDDA} />
        </Popup>
      </Container>
    </Col>
  );
}
