import React from 'react';
import styles from './FacebookButton.module.scss';

export default function FacebookButton() {
  return (
    <div className={styles.FacebookButton}>
      <a
        href="https://www.facebook.com/linxretail"
        target="_blank"
        rel="noopener noreferrer"
      ><i className={`icon icon-lx-facebook`}></i></a>
    </div>
  );
}
