import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import InputAutocomplete from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select/';
import TimeField from './TimeField';

import Botao from '../botao/Botao';
import { AsYouType } from 'libphonenumber-js';
import 'react-dates/initialize';
import SingleDatePicker from './react-dates/SingleDatePicker';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import 'moment/locale/pt-br';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import isEqual from 'lodash/isEqual';
import ReactStars from 'react-stars';
import { FileSelector } from './FileSelector';
import { ReactMultiEmail } from 'react-multi-email';
import Footer from '../core/footer/Footer';
moment.locale('pt-br');

export function handleInputChange(event, autocomplete, sender) {
  if (autocomplete) {
    return this.setState({
      [event.name]: { label: event.label, value: event.value }
    });
  }

  let value, name;
  if (event.hasOwnProperty('target')) {
    // inputs comuns
    const target = event.target;
    value = target.type === 'checkbox' ? target.checked : target.value;
    name = target.name;
  } else if (event.hasOwnProperty('floatValue')) {
    // input react-number-format
    value = event.format === null ? event.floatValue : event.value;
    name = event.name;
  } else if (event.hasOwnProperty('date')) {
    // react-date...
    value = event.date ? event.date.format('YYYY-MM-DD') : null;
    name = event.name;
  } else {
    // multi-select, TimeField...
    value = event.hasOwnProperty('selected') ? event.selected : event.value;
    name = event.name;
  }
  if (sender) {
    sender.setState({
      [name]: value
    });
  } else {
    this.setState({
      [name]: value
    });
  }
}

export function handleInputChangeRegex(event, autocomplete, sender) {
  if (event.target.validity.valid) {
    handleInputChange(event, autocomplete, sender ? sender : this);
  }
}

export function handleInputChangeForceUpper(event, autocomplete, sender) {
  event.target.value = String(event.target.value).toUpperCase();
  handleInputChange(event, autocomplete, sender ? sender : this);
}

export function handleInputChangeRegexForceUpper(event, autocomplete) {
  event.target.value = String(event.target.value).toUpperCase();
  handleInputChangeRegex(event, autocomplete, this);
}

export function formatPhoneNumber(number) {
  return new AsYouType('BR').input(number);
}

export const makeInputUpperCase = event => {
  const input = event.target;
  const start = input.selectionStart;
  const end = input.selectionEnd;

  input.value = String(input.value).toUpperCase();
  input.setSelectionRange(start, end);
};

export class InputText extends Component {
  render() {
    const {
      label,
      placeholder,
      icone,
      onIconeClick,
      help,
      type = 'text',
      name,
      value,
      onChange,
      disabled = false,
      required = false,
      decimalScale = 2,
      allowNegative = false,
      format = null,
      mask = ' ',
      // autofocus,
      maxlength,
      // tabindex,
      onBlur,
      onKeyDown,
      onPaste,
      inputRef,
      id,
      pattern,
      autocomplete = 'on',
      onClick,
      onKeyUp,
      onKeyPress,
      isAllowed = null,
      max
    } = this.props;

    const handleIntegerChange = (event) => {
      const value = event.target.value;
      let integer = parseInt(value, 10);
      if (!isNaN(integer)) {
        if (integer > max)
          integer = max;

        const newEvent = {
          ...event,
          target: {
            ...event.target,
            value: integer,
          },
        };
        onChange(newEvent, integer);
      }
    }

    return (
      <div className={`form-input ${icone ? 'has-bt' : null}`}>
        {
          /*o if apenas foi reordenado para ter o type integer, que é similar ao type number (não sei se funciona), mas sem decimais */
          type == 'integer' ? (
            <input
              type="text"
              className="input"
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              name={name}
              value={value ? value : ''}
              onChange={handleIntegerChange}
              onBlur={onBlur}
              maxLength={maxlength}
              onKeyDown={onKeyDown}
              onKeyUp={onKeyUp}
              onKeyPress={onKeyPress}
              onPaste={onPaste}
              ref={inputRef}
              id={id || name}
              pattern={pattern}
              autoComplete={autocomplete}
              onClick={onClick}
              step="1"
            />
          ) : type == 'number' ? (
              <NumberFormat
                decimalSeparator=","
                decimalScale={decimalScale}
                className="input"
                placeholder={placeholder}
                allowNegative={allowNegative}
                disabled={disabled}
                required={required}
                name={name}
                value={value}
                format={format}
                mask={mask}
                maxLength={maxlength}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onValueChange={values => onChange({ ...values, name, format })}
                inputref={inputRef}
                id={id || name}
                autoComplete={autocomplete}
                onClick={onClick}
                isAllowed={
                  isAllowed
                    ? isAllowed
                    : () => {
                      return true;
                    }
                }
              />
            ) : (
                <input
                type={type}
                className="input"
                placeholder={placeholder}
                disabled={disabled}
                required={required}
                name={name}
                value={value ? value : ''}
                onChange={onChange}
                onBlur={onBlur}
                maxLength={maxlength}
                onKeyDown={onKeyDown}
                onKeyUp={onKeyUp}
                onKeyPress={onKeyPress}
                onPaste={onPaste}
                ref={inputRef}
                id={id || name}
                pattern={pattern}
                autoComplete={autocomplete}
                onClick={onClick}
              />
        )}

        {label && <span className="input-label">{label}</span>}

        {icone && (
          <div
            className="input-bt"
            {...(onIconeClick ? { onClick: onIconeClick } : {})}
          >
            <div className={`icon ${icone}`} />
          </div>
        )}

        {help && <span className="help">{help}</span>}
      </div>
    );
  }
}

// const customFilter = (options, filter) => {
//   const optionIncludesText = option => {
//     const label = option.label || "";
//     return label.toLowerCase().includes(filter.toLowerCase());
//   };

//   return options.filter(optionIncludesText);
// };

export class Select extends Component {
  render() {
    const {
      label,
      icone,
      onIconeClick,
      help,
      name,
      value,
      options = [],
      onChange,
      multi = false,
      // autoFocus,
      // tabindex,
      disabled,
      onBlur,
      sender = null
    } = this.props;

    /* const op = [];
    if (options.length > 0) {
      op.push({ label: "Selecionar todos(as)", value: "*" });
      options.forEach(o => {
        op.push({ ...o });
      });
    } */

    return (
      <div className={icone ? 'form-input has-bt' : 'form-input'}>
        {!multi ? (
          <select
            className="input"
            name={name}
            value={value ? value : ''}
            onChange={onChange}
            disabled={disabled}
            onBlur={onBlur}
          >
            {options.map((option, index) => {
              const [v, label] = option.hasOwnProperty('value')
                ? [option.value, option.label]
                : [option, option];
              return (
                <option value={v} title={option.hint} key={index}>
                  {label}
                </option>
              );
            })}
          </select>
        ) : (
          <>
            <ReactSelect
              //className="input"
              //className="basic-multi-select"
              //classNamePrefix="select"
              name={name}
              sender={sender}
              options={options}
              value={value}
              onChange={(value, action) => {
                /* let v2 = [];
                  if (value && value.find(v => v.value === "*")) {
                    v2 = this.props.options.filter(item => item.value !== "*");
                  }
                  else {
                    v2 = value;
                  } */
                if (this.props.sender)
                  this.props.sender.setState({
                    [this.props.name]: value ? value.map(v => v.value) : [],
                    [this.props.originalValue]: value
                  });

                this.setState({ value });
              }}
              //onChange={onChange}
              isMulti
              isSearchable
              isDisabled={disabled || options.length === 0}
              placeholder={
                options.length === 0 ? 'Nenhuma opção disponível' : 'Selecione'
              }
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
            />
            {/* <MultiSelect
            {...this.props}
            selected={value}
            onSelectedChanged={(selected) => onChange({ selected, name })}
            filterOptions={customFilter}
            overrideStrings={{
              selectSomeItems: "Selecionar...",
              allItemsAreSelected: "Todos os items",
              selectAll: "Selecionar todos",
              search: "Pesquisar",
            }}
            disabled={disabled}
          /> */}
          </>
        )}
        {label && <span className="input-label">{label}</span>}

        {icone && (
          <div
            className="input-bt"
            {...(onIconeClick ? { onClick: onIconeClick } : {})}
          >
            <div className={`icon ${icone}`} />
          </div>
        )}

        {help && <span className="help">{help}</span>}
      </div>
    );
  }
}

export class InputDate extends Component {
  state = { date: null, focused: false };

  render() {
    const {
      label,
      icone,
      onIconeClick,
      help,
      value,
      name,
      onChange,
      onFocus,
      withPortal,
      disabled,
      inputRef
    } = this.props;
    return (
      <div className={`form-input ${icone ? 'has-bt' : null}`}>
        <SingleDatePicker
          isOutsideRange={() => false}
          date={value ? moment(value) : null} // momentPropTypes.momentObj or null
          onDateChange={date => {
            onChange({ date, name });
          }} // PropTypes.func.isRequired
          focused={this.state.focused} // PropTypes.bool
          disabled={disabled}
          onFocusChange={({ focused }) => {
            this.setState({ focused });
            if (onFocus != null) {
              onFocus(focused);
            }
          }} // PropTypes.func.isRequired
          numberOfMonths={1}
          id={name} // PropTypes.string.isRequired,
          withPortal={withPortal}
          ref={inputRef}
        />

        {label && <span className="input-label">{label}</span>}

        {icone && (
          <div
            className="input-bt"
            {...(onIconeClick ? { onClick: onIconeClick } : {})}
          >
            <div className={`icon ${icone}`} />
          </div>
        )}

        {help && <span className="help">{help}</span>}
      </div>
    );
  }
}

export class InputTime extends Component {
  render() {
    const {
      label,
      placeholder,
      icone,
      onIconeClick,
      help,
      name,
      value,
      onChange,
      showSeconds = false,
      inputRef,
      id
    } = this.props;

    return (
      <div className={`form-input ${icone ? 'has-bt' : null}`}>
        <TimeField
          className="input"
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          style={{ width: '100%' }}
          showSeconds={showSeconds}
          ref={inputRef}
          id={id || name}
        />
        {label && <span className="input-label">{label}</span>}

        {icone && (
          <div
            className="input-bt"
            {...(onIconeClick ? { onClick: onIconeClick } : {})}
          >
            <div className={`icon ${icone}`} />
          </div>
        )}

        {help && <span className="help">{help}</span>}
      </div>
    );
  }
}

export class Checkbox extends Component {
  render() {
    const { label = String.fromCharCode(8203) } = this.props;

    return (
      <div className="form-input">
        <div className="input hascheckbox">{this.props.children}</div>

        {label && <label className="input-label">{label}</label>}
      </div>
    );
  }
}

export class Checkitem extends Component {
  render() {
    const {
      label,
      name,
      checked,
      onChange,
      disabled = false,
      title = ''
    } = this.props;

    return (
      <label className="checkbox-label">
        <input
          type="checkbox"
          className="checkboxinput"
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          title={title}
        />
        <div className="checkbox" />
        <span className="label">{label}</span>
      </label>
    );
  }
}

export class CheckTree extends Component {
  constructor(props) {
    super(props);
    const {
      label,
      dados,
      placeHolder = 'Selecione...',
      onChange,
      onNodeToggle,
      onAction,
      onBlur,
      onFocus,
      disabled = false
    } = this.props;

    this.state = {
      label,
      dados,
      placeHolder,
      onChange,
      onNodeToggle,
      onAction,
      onBlur,
      onFocus,
      disabled
    };
  }

  shouldComponentUpdate = nextProps => {
    return !isEqual(nextProps.data, this.state.data);
  };

  render() {
    const {
      label,
      dados,
      placeHolder,
      onChange,
      onNodeToggle,
      onAction,
      onBlur,
      onFocus,
      disabled
    } = this.state;

    return (
      <div className={`form-input`}>
        <DropdownTreeSelect
          data={dados}
          onChange={onChange}
          onAction={onAction}
          onNodeToggle={onNodeToggle}
          placeholderText={placeHolder}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        />

        {label && <span className="input-label">{label}</span>}
      </div>
    );
  }
}

export class Favoritos extends Component {
  render() {
    const {
      label,
      name,
      color1 = '#ebebeb',
      color2 = '#ff9200',
      value,
      onChange,
      numberOfStars
    } = this.props;

    return (
      <div className={`form-input`}>
        <ReactStars
          count={numberOfStars}
          onChange={value => onChange({ name, value })}
          value={value}
          size={26}
          color1={color1}
          color2={color2}
          half={false}
        />

        {label && <span className="input-label">{label}</span>}
      </div>
    );
  }
}

export class FormOptions extends Component {
  /* Rodape de um form contendo botões para salvar, cancelar, excluir... */
  render() {
    const {
      sender,
      handleSalvar,
      handleAdicionar,
      handleExcluir,
      handleCancelar,
      handleVoltar
    } = this.props;
    // const footerOptions =
    //   sender && sender.state && sender.state.footerOptions
    //     ? sender.state.footerOptions
    //     : {};
    const _saving = 'saving',
      // _adding = "adding",
      _deleting = 'deleting';
    // _cancelling = "cancelling",
    // _backing = "backing";

    const executing = command => {
      return (
        sender &&
        sender.state &&
        sender.state.footerOptions &&
        sender.state.footerOptions[command]
      );
    };

    const caption = (command, action, message) => {
      if (executing(command)) return message;
      return action;
    };

    const doExecute = async method => {
      return await method();
    };

    const setOption = (command, value) => {
      sender.setState((prevState, props) => {
        const footerOptions = prevState.footerOptions || {};
        footerOptions[command] = value;
        return { footerOptions };
      });
      sender.forceUpdate();
    };

    const onExecute = (command, method) => {
      if (!sender || !sender.state) return method;
      if (executing(command)) return null;
      return () => {
        setOption(command, true);
        doExecute(method).then(() => setOption(command, false));
      };
    };

    return (
      <Footer>
        <div className="section-footer">
          {handleSalvar && (
            <Botao
              secondary
              icon="icon-lx-check"
              title={caption(_saving, 'Salvar', 'Salvando...')}
              onClick={onExecute(_saving, handleSalvar)}
            />
          )}
          {handleAdicionar && (
            <Botao
              className="right"
              icon="icon-lx-plus"
              title={'Adicionar'}
              onClick={handleAdicionar}
            />
          )}
          {handleExcluir && (
            <Botao
              icon="icon-lx-trash"
              title={'Excluir'}
              onClick={onExecute(_deleting, handleExcluir)}
            />
          )}
          {handleCancelar && (
            <Botao
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={handleCancelar}
            />
          )}
          {handleVoltar && (
            <Botao
              icon="icon-lx-arrow-left"
              title={'Voltar'}
              onClick={handleVoltar}
            />
          )}
        </div>
      </Footer>
    );
  }
}

export class MessageInLine extends Component {
  render() {
    const { message = '', visible = true } = this.props;

    return (
      <>
        {visible ? (
          <div className="row">{message}</div>
        ) : (
          <div className="content-hidden">{message}</div>
        )}
      </>
    );
  }
}

export class AutoComplete extends Component {
  onChange = event => {
    const { name, onChange } = this.props;
    onChange({ ...event, name }, true);
  };

  render() {
    const {
      options = [],
      name,
      value,
      loadOptions,
      loadingMessage = 'Carregando...',
      noOptionsMessage = 'Não há opções',
      onBlur,
      onFocus,
      disabled = false,
      placeholder = ''
    } = this.props;
    return (
      <div className="form-input">
        {loadOptions ? (
          <AsyncSelect
            value={value}
            onChange={this.onChange}
            loadOptions={loadOptions}
            loadingMessage={() => loadingMessage}
            noOptionsMessage={() => noOptionsMessage}
            onBlur={onBlur}
            onFocus={onFocus}
            name={name}
            placeholder={placeholder}
            className="input autocomplete"
            isClearable
            defaultOptions
            disabled={disabled}
          />
        ) : (
          <InputAutocomplete
            value={value}
            onChange={this.onChange}
            options={options}
            noOptionsMessage={() => noOptionsMessage}
            onBlur={onBlur}
            name={name}
            placeholder={placeholder}
            className="input autocomplete"
            isClearable
            getOptionValue={option => option['value']}
            isDisabled={disabled}
          />
        )}
        <span className="input-label">{this.props.label}</span>
      </div>
    );
  }
}

export class FileInput extends Component {
  render() {
    const {
      name,
      label,
      icone,
      help,
      onIconeClick,
      sender = null,
      accept = '*.*'
    } = this.props;

    return (
      <div className={`form-input ${icone ? 'has-bt' : null}`}>
        <FileSelector sender={sender} name={name} accept={accept} />

        {label && <span className="input-label">{label}</span>}

        {icone && (
          <div
            className="input-bt"
            {...(onIconeClick ? { onClick: onIconeClick } : {})}
          >
            <div className={`icon ${icone}`} />
          </div>
        )}

        {help && <span className="help">{help}</span>}
      </div>
    );
  }
}

export class EmailInput extends Component {
  render() {
    const { emails, label, placeholder, sender } = this.props;

    return (
      <div className="form-input">
        <ReactMultiEmail
          emails={emails}
          placeholder={placeholder}
          onChange={emails => {
            if (sender) {
              sender.setState({ emails });
            }
            this.setState({ emails });
          }}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                {email}
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  &nbsp;×
                </span>
              </div>
            );
          }}
        />

        {label && <span className="input-label">{label}</span>}
      </div>
    );
  }
}
