import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './GerenciamentoFinanceiroFilters.module.scss';

import { DateBox, SelectBox } from 'devextreme-react';
import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, { Selection, Paging } from 'devextreme-react/data-grid';
import { DropDownBox, TextBox } from 'devextreme-react';
import ptMessages from '../../../../assets/js/pt.json';

import { sameInt, sortList } from '../../../shared/utils/Utils';
import { gerenciamentoFinanceiroService } from './GerenciamentoFinanceiroFilters.service';
import LinxListAsContent from '../../linx/ListAsContent/LinxListAsContent';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { EstruturaCartoesTreeView } from '../EstruturaCartoes/TreeView/EstruturaCartoesTreeView';
import BotaoCarregamento from '../../botao/BotaoCarregamento/BotaoCarregamento';

export function GerenciamentoFinanceiroFilters(props) {
  let today = new Date();
  const treeViewRef = useRef();

  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [endDate] = useState(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  );

  const [operacoes, setOperacoes] = useState([]);
  const [selectedOperacoes, setSelectedOperacoes] = useState([]);
  const [gridBoxOperacaoValue, setGridBoxOperacaoValue] = useState([]);
  const [tipoOperacoes, setTipoOperacoes] = useState([]);
  const [tiposPeriodo, setTiposPeriodo] = useState([]);
  const [selectedTipoData, setSelectedTipoData] = useState(1);
  const [selectedTipoDataDesc, setSelectedTipoDataDesc] = useState(
    'Data de vencimento'
  );
  const [selectedTipoOperacoes, setSelectedTipoOperacoes] = useState([]);
  const [gridBoxTipoOperacaoValue, setGridBoxTipoOperacaoValue] = useState([]);
  const [situacoes, setSituacoes] = useState([]);
  const [selectedSituacoes, setSelectedSituacoes] = useState([]);
  const [gridBoxSituacoesValue, setGridBoxSituacoesValue] = useState([]);
  const [contas, setContas] = useState([]);
  const [selectedContas, setSelectedContas] = useState([]);
  const [gridBoxContasValue, setGridBoxContasValue] = useState([]);
  const [centroMonetario, setCentroMonetario] = useState([]);
  const [selectedCentroMonetario, setSelectedCentroMonetario] = useState([]);
  const [
    gridBoxCentroMonetarioValue,
    setGridBoxCentroMonetarioValue
  ] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [pessoaValue, setPessoaValue] = useState([]);
  const [descricaoValue, setDescricaoValue] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({
    dataInicio: startDate,
    dataFim: endDate,
    tipoOperacao: [],
    situacao: [],
    pessoa: [],
    centroMonetario: [],
    conta: [],
    operacaoFinanceira: [],
    descricao: ''
  });
  const [selectedEstruturaCartoes, setSelectedEstruturaCartoes] = useState([]);

  useEffect(() => {
    if (tiposPeriodo.length > 0) {
      let tipoDataDesc = tiposPeriodo.find(tp =>
        sameInt(tp.valor, selectedTipoData)
      ).descricao;
      setSelectedTipoDataDesc(tipoDataDesc);
    }
  }, [tiposPeriodo, selectedTipoData]);

  const dataGridEstruturaCartoes_onSelectionChanged = e => {
    let ids = e.component.getSelectedNodesKeys();
    setSelectedEstruturaCartoes(ids);
  };

  const getOperacoes = async () => {
    const response = await gerenciamentoFinanceiroService.getOperacaoFinanceira();
    if (response.length) setOperacoes(response);
  };

  const getTipoOperacoes = async () => {
    const response = await gerenciamentoFinanceiroService.getTiposOperacao();
    if (response.length) setTipoOperacoes(response);
  };

  const getSituacoes = async () => {
    const response = await gerenciamentoFinanceiroService.getSituacoes();
    if (response.length) setSituacoes(response);
  };

  const getContas = async () => {
    const response = await gerenciamentoFinanceiroService.getContas();
    if (response.length) setContas(response);
  };

  const getCentroMonetario = async () => {
    const response = await gerenciamentoFinanceiroService.getCentroMonetario();
    if (response.length) setCentroMonetario(response);
  };

  const getPessoas = async () => {
    const response = await gerenciamentoFinanceiroService.getPessoas();
    if (response.length) setPessoas(sortList(response, 'nome'));
  };

  const getTiposPeriodo = async () => {
    const response = await gerenciamentoFinanceiroService.getTiposPeriodo();
    if (response.length) setTiposPeriodo(response);
  };

  const clearFilters = () => {
    setGridBoxSituacoesValue([]);
    setSelectedSituacoes([]);

    setGridBoxCentroMonetarioValue([]);
    setSelectedCentroMonetario([]);

    setGridBoxContasValue([]);
    setSelectedContas([]);

    setGridBoxOperacaoValue([]);
    setSelectedOperacoes([]);

    setGridBoxTipoOperacaoValue([]);
    setSelectedTipoOperacoes([]);

    setPessoaValue([]);
    setDescricaoValue('');

    treeViewRef.current.instance.unselectAll();

    setSelectedTipoData(1);

    let today = new Date();

    setAppliedFilters({
      dataInicio: new Date(new Date(today.getFullYear(), today.getMonth(), 1)),
      dataFim: new Date(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
      tipoOperacao: [],
      situacao: [],
      pessoa: [],
      centroMonetario: [],
      conta: [],
      operacaoFinanceira: [],
      descricao: '',
      estruturas: []
    });
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const onValueChanged = event => {
    if (event.element.id === 'dataInicio' && !moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inicial inválida.', 'error', 2000, 'top');
    } else if (
      event.element.id === 'dataFim' &&
      !moment(event.value).isValid()
    ) {
      setDisabledSearch(true);
      notify('Data final inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === ('dataInicio' || 'dataFim') &&
            event.value !== null
              ? new Date(event.value).toISOString()
              : event.value
        };
      });
      setDisabledSearch(false);
    }
  };

  useEffect(() => {
    getOperacoes();
    getTipoOperacoes();
    getSituacoes();
    getContas();
    getCentroMonetario();
    getPessoas();
    getTiposPeriodo();
    setAppliedFilters(filters => {
      return {
        ...filters,
        dataInicio: startDate,
        dataFim: endDate
      };
    });
    loadMessages(ptMessages);
    locale(navigator.language);
  }, []);

  const recuperarCodigos = () => 
  {
      let estruturasCodigosParaFiltrar = selectedEstruturaCartoes.map((e)=> {
          let arrEstruturas = e.split('.');
          return arrEstruturas;
      });
      let listaEstruturaCartaoFilter = [];
      estruturasCodigosParaFiltrar.forEach(itens => {
          let estruturaCartaoFilter = {};
          itens.forEach(item => {
              switch (item[0])
              {
                  case 'R':
                      estruturaCartaoFilter.Rede = parseInt(item.substring(1));
                      break;
                  case 'B':
                      estruturaCartaoFilter.Bandeira = parseInt(item.substring(1));
                      break;
                  case 'F':
                      estruturaCartaoFilter.TipoOperacaoCartao = parseInt(item.substring(1));
                      break;
                  case 'P':
                      estruturaCartaoFilter.ProdutoRede = parseInt(item.substring(1));
                      break;
                  default:
                      break;
              }
          });
          listaEstruturaCartaoFilter.push(estruturaCartaoFilter);
      });            
     
      return listaEstruturaCartaoFilter;
  }

  const find = async () => {
    setSearching(true);

    if (!appliedFilters)
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');

    const response = await gerenciamentoFinanceiroService.find(props.operacao, {
      tipoOperacao: [...selectedTipoOperacoes],
      situacao: [...selectedSituacoes],
      pessoa: getSelectedPessoas(),
      centroMonetario: [...selectedCentroMonetario],
      conta: [...selectedContas],
      operacaoFinanceira: [...selectedOperacoes],
      descricao: descricaoValue,
      tipoData: selectedTipoData,
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      estruturaCartaoFilter: recuperarCodigos()
    });
    if (response.notificationLevel === 1) {
      if (response.data.length) {
        if (!response.data.length) {
          notify(
            'Não foram encontrados registros para esta pesquisa.',
            'info',
            2000,
            'top'
          );
        }

        props.handleResultado(response.data, {
          tipoOperacao: [...selectedTipoOperacoes],
          situacao: [...selectedSituacoes],
          pessoa: getSelectedPessoas(),
          centroMonetario: [...selectedCentroMonetario],
          conta: [...selectedContas],
          operacaoFinanceira: [...selectedOperacoes],
          descricao: descricaoValue,
          tipoData: selectedTipoData,
          tipoDataDesc: selectedTipoDataDesc,
          dataInicio: appliedFilters.dataInicio,
          dataFim: appliedFilters.dataFim,
          estruturas: selectedEstruturaCartoes
        });
      } else {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          2000,
          'top'
        );
      }
    } else {
      notify(
        response.message + ' Refaça a pesquisa informando um período menor.',
        'info',
        2000,
        'top'
      );
    }
    setSearching(false);
  };

  const clearOperacoes = () => {
    setGridBoxOperacaoValue([]);
    setSelectedOperacoes([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        operacaoFinanceira: []
      };
    });
  };

  const clearSituacoes = () => {
    setGridBoxSituacoesValue([]);
    setSelectedSituacoes([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        situacao: []
      };
    });
  };

  const clearConta = () => {
    setGridBoxContasValue([]);
    setSelectedContas([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        conta: []
      };
    });
  };

  const clearCentroMonetario = () => {
    setGridBoxCentroMonetarioValue([]);
    setSelectedCentroMonetario([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        centroMonetario: []
      };
    });
  };

  const clearTipoOperacao = () => {
    setGridBoxTipoOperacaoValue([]);
    setSelectedTipoOperacoes([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        tipoOperacao: []
      };
    });
  };

  const syncDataGridOperacoesSelection = e => {
    setGridBoxOperacaoValue(e.value || []);
    clearOperacoes();
  };

  const dataGridOperacoes_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(operacao => {
      ids.push(operacao.id);
    });
    setGridBoxOperacaoValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedOperacoes(ids);
  };

  const dataGridOperacoesRender = () => {
    return (
      <DataGrid
        dataSource={operacoes}
        columns={[
          { dataField: 'descricao', caption: 'Descrição' },
          {
            dataField: 'debitoCreditoDescricao',
            caption: 'Tipo',
            groupIndex: 0
          }
        ]}
        hoverStateEnabled={true}
        showBorders={true}
        showSearch={true}
        height={325}
        selectedRowKeys={gridBoxOperacaoValue}
        onSelectionChanged={dataGridOperacoes_onSelectionChanged}
      >
        <Selection mode="multiple" />
        <Paging enabled={false} />
      </DataGrid>
    );
  };

  const syncDataGridTipoOperacaoSelection = e => {
    setGridBoxTipoOperacaoValue(e.value || []);
    clearTipoOperacao();
  };

  const dataGridTipoOperacao_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(tipo => {
      ids.push(tipo.valor);
    });
    setGridBoxTipoOperacaoValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedTipoOperacoes(ids);
  };

  const dataGridTipoOperacaoRender = () => {
    return (
      <DataGrid
        dataSource={tipoOperacoes}
        columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={gridBoxTipoOperacaoValue}
        onSelectionChanged={dataGridTipoOperacao_onSelectionChanged}
      >
        <Selection mode="multiple" />
      </DataGrid>
    );
  };

  const syncDataGridSituacaoSelection = e => {
    setGridBoxSituacoesValue(e.value || []);
    clearSituacoes();
  };

  const dataGridSituacao_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(situacao => {
      ids.push(situacao.valor);
    });
    setGridBoxSituacoesValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedSituacoes(ids);
  };

  const dataGridSituacaoRender = () => {
    return (
      <DataGrid
        dataSource={situacoes}
        columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={gridBoxSituacoesValue}
        onSelectionChanged={dataGridSituacao_onSelectionChanged}
      >
        <Selection mode="multiple" />
      </DataGrid>
    );
  };

  const syncDataGridContasSelection = e => {
    setGridBoxContasValue(e.value || []);
    clearConta();
  };

  const dataGridContas_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(conta => {
      ids.push(conta.id);
      if(sameInt(conta.id, 4))
        ids.push(6501);
    });
    setGridBoxContasValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedContas(ids);
  };

  const dataGridContasRender = () => {
    return (
      <DataGrid
        dataSource={contas}
        columns={[
          { dataField: 'descricao', caption: 'Descrição' },
          {
            dataField: 'debitoCreditoDescricao',
            caption: 'Tipo',
            groupIndex: 0
          }
        ]}
        hoverStateEnabled={true}
        showBorders={true}
        showSearch={true}
        height={325}
        selectedRowKeys={gridBoxContasValue}
        onSelectionChanged={dataGridContas_onSelectionChanged}
      >
        <Selection mode="multiple" />
        <Paging enabled={false} />
      </DataGrid>
    );
  };

  const syncDataGridCentroMonetarioSelection = e => {
    setGridBoxCentroMonetarioValue(e.value || []);
    clearCentroMonetario();
  };

  const dataGridCentroMonetario_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(centro => {
      ids.push(centro.id);
    });
    setGridBoxCentroMonetarioValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedCentroMonetario(ids);
  };

  const dataGridCentroMonetarioRender = () => {
    return (
      <DataGrid
        dataSource={centroMonetario}
        columns={[{ dataField: 'nome', caption: 'Descrição' }]}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={gridBoxCentroMonetarioValue}
        onSelectionChanged={dataGridCentroMonetario_onSelectionChanged}
      >
        <Selection mode="multiple" />
      </DataGrid>
    );
  };

  const syncListSelection = (e, method) => {
    if (e) {
      if (e.value) {
        if (Array.isArray(e.value)) {
          method([...e.value]);
        } else {
          method(e.value);
        }
      } else {
        method([]);
      }
    }
  };

  const getSelectedPessoas = () => {
    let pessoas = [];
    if (pessoaValue && pessoaValue.length > 0) {
      pessoaValue.forEach(p => {
        pessoas.push(p.id);
      });
    }
    return pessoas;
  };

  return (
    <div className={`${styles.gerenciamentoFinanceiroFilter} gerenciamentoFinanceiroFilter`}>
      <div className={props.operacao ? "shadow-sm bg-white p-3" : ""}>
        <div className={`${styles.filters} filters ${styles.search}`}>
          <div className="container-fluid">
            {props.operacao ? <h2>GERENCIAMENTO FINANCEIRO</h2> : <></>}
            <hr className={`${styles.fadeIn}`} />
            <div className={`${styles.fadeIn} ${styles.searchWrapper} searchWrapper`}>
              <div className="row">
                <div className={`col-md-2 form-group mb-1`}>
                  <div className={styles.dateWrapper}>
                    <div className={styles.datebox}>
                      <span>De</span>
                      <DateBox
                        defaultValue={startDate}
                        id="dataInicio"
                        type="date"
                        onValueChanged={onValueChanged}
                        disabled={searching}
                        useMaskBehavior={true}
                        value={appliedFilters.dataInicio}
                        displayFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className={`col-md-2 form-group mb-1`}>
                  <div className={styles.dateWrapper}>
                    <div className={styles.datebox}>
                      <span>Até</span>
                      <DateBox
                        defaultValue={endDate}
                        type="date"
                        id="dataFim"
                        onValueChanged={onValueChanged}
                        disabled={searching}
                        useMaskBehavior={true}
                        value={appliedFilters.dataFim}
                        displayFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-2 form-group mb-1`}
                >
                  <span>Tipo de período</span>
                  <SelectBox
                    dataSource={tiposPeriodo}
                    displayExpr="descricao"
                    valueExpr="valor"
                    defaultValue={1}
                    value={selectedTipoData}
                    onValueChanged={e => setSelectedTipoData(e.value)}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-4 form-group mb-1`}
                >
                  <span>Tipo de lançamento</span>
                  <DropDownBox
                    value={gridBoxTipoOperacaoValue.map(item => item.descricao)}
                    valueExpr="marca"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={tipoOperacoes}
                    onValueChanged={syncDataGridTipoOperacaoSelection}
                    contentRender={dataGridTipoOperacaoRender}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-2 form-group mb-1`}
                >
                  <span>Situação</span>
                  <DropDownBox
                    value={gridBoxSituacoesValue.map(item => item.descricao)}
                    valueExpr="marca"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={situacoes}
                    onValueChanged={syncDataGridSituacaoSelection}
                    contentRender={dataGridSituacaoRender}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-6 form-group mb-2 mt-2`}
                >
                  <span>Pessoa</span>
                  <DropDownBox
                    value={pessoaValue}
                    valueExpr={item => {
                      return item;
                    }}
                    displayExpr={item => {
                      return item.nome;
                    }}
                    placeholder="Selecione..."
                    showClearButton={true}
                    disabled={searching}
                    dataSource={pessoas}
                    onValueChanged={e => syncListSelection(e, setPessoaValue)}
                    contentRender={() => {
                      return (
                        <LinxListAsContent
                          dataSource={pessoas}
                          selectedItems={pessoaValue}
                          methodThatSets={setPessoaValue}
                          keyExpr={item => {
                            return item.id;
                          }}
                          displayExpr={item => {
                            return item.nome;
                          }}
                          searchExpr={item => {
                            return item.nome;
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div
                  className={`${styles.input} input col-md-6 form-group mb-2 mt-2`}
                >
                  <span>Descrição do lançamento ou número do documento</span>
                  <TextBox
                    placeholder="Informe uma descrição"
                    showClearButton={true}
                    disabled={searching}
                    value={descricaoValue}
                    valueChangeEvent="change keyPress"
                    onValueChanged={e => {
                      setDescricaoValue(e.value);
                    }}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-4 form-group mb-2 mt-2`}
                >
                  <span>Centro monetário</span>
                  <DropDownBox
                    value={gridBoxCentroMonetarioValue.map(item => item.nome)}
                    valueExpr="id"
                    deferRendering={false}
                    displayExpr="nome"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={centroMonetario}
                    onValueChanged={syncDataGridCentroMonetarioSelection}
                    contentRender={dataGridCentroMonetarioRender}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-4 form-group mb-2 mt-2`}
                >
                  <span>Conta</span>
                  <DropDownBox
                    value={gridBoxContasValue.map(item => item.descricao)}
                    valueExpr="marca"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={contas}
                    onValueChanged={syncDataGridContasSelection}
                    contentRender={dataGridContasRender}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-4 form-group mb-2 mt-2`}
                >
                  <span>Operação</span>
                  <DropDownBox
                    value={gridBoxOperacaoValue.map(item => item.descricao)}
                    valueExpr="marca"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={operacoes}
                    onValueChanged={syncDataGridOperacoesSelection}
                    contentRender={dataGridOperacoesRender}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-6 form-group mb-2 mt-2`}
                >
                  <span>Estrutura de cartões</span>
                  <EstruturaCartoesTreeView
                    selectNodesRecursive={true}
                    ref={treeViewRef}
                    onSelectionChanged={
                      dataGridEstruturaCartoes_onSelectionChanged
                    }
                  />
                </div>
              </div>

              <footer className="text-right">
                {/* <button className="btn btn-default"> Salvar relatório </button> */}
                <button
                  className="btn btn-light"
                  onClick={clearFilters}
                  disabled={searching}
                >
                  {' '}
                  Limpar filtros{' '}
                </button>
                <BotaoCarregamento click={find} disabled={disabledSearch || searching} loading={searching} carregando='PESQUISANDO...' carregar='PESQUISAR' />
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(GerenciamentoFinanceiroFilters);
