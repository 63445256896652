import React, { Component } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import {
  getUrl,
  getNomePagina,
  getDadosSellerPop,
  getNomePaginaAmigavel
} from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';
import styles from '../dashboard/contas-receber/ContasReceber.module.scss';

const PATH_MENU = menus.COD_13105;

export class ManterFunFuncionario extends Component {
  state = {
    loading: true
  };

  hideSpinner = () => {
    this.setState({
      loading: false
    });
  };

  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <div className="row">
          <Iframe
            url={
              getUrl() +
              '/ManterFunFuncionario.aspx?' +
              getDadosSellerPop(PATH_MENU)
            }
            className="iframePop"
            onLoad={this.hideSpinner}
          />
          {this.state.loading ? (
            <div className={styles.loader}>
              <h1>Carregando {getNomePaginaAmigavel(PATH_MENU)}</h1>
              <svg className={`${styles.spinner} spinner`} viewBox="0 0 50 50">
                <circle
                  className={`${styles.path} path`}
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          ) : null}
        </div>
      </LinxPostos>
    );
  }
}
