import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './MovimentacaoEstoque.module.scss';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';

import { DateBox } from 'devextreme-react';
import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, { Selection, Column, SearchPanel, Pager, Paging, Grouping, ColumnFixing, GroupPanel, ColumnChooser, Summary, TotalItem } from 'devextreme-react/data-grid';
import { DropDownBox } from 'devextreme-react';
import ptMessages from '../../../../../assets/js/pt.json';

import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina, sortList } from '../../../../shared/utils/Utils';
import { movimentacaoEstoqueService } from './MovimentacaoEstoque.service';
import LinxListAsContent from '../../../../components/linx/ListAsContent/LinxListAsContent';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import _ from 'lodash';
import { Export } from 'devextreme-react/bar-gauge';
import { EstruturaMercadologicaTreeView } from '../../../../components/business/EstruturaMercadologica/TreeView/EstruturaMercadologicaTreeView';

export function MovimentacaoEstoqueReport(props) {

    let today = new Date();
    const treeViewRef = useRef();
    const PATH_MENU = menus.COD_80560;
    const [showPreReports, setShowPreReports] = useState(false);
    const [reports, setReports] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [disabledSearch, setDisabledSearch] = useState(false);
    const [chosenReport, setChosenReport] = useState([]);
    const [preSearching, setPreSearching] = useState(false);
    const [searching, setSearching] = useState(false);
    const [startDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0));

    const [filters, setFilters] = useState([]);

    const [situacoesProduto, setSituacoesProduto] = useState([]);
    const [selectedSituacoesProduto, setSelectedSituacoesProduto] = useState([]);
    const [gridBoxSituacoesProdutoValue, setGridBoxSituacoesProdutoValue] = useState([]);

    const [selectedEstruturaMercadologica, setSelectedEstruturaMercadologica] = useState([]);
    
    const [posicaoEstoque, setPosicaoEstoque] = useState([]);
    const [selectedPosicoes, setSelectedPosicaoEstoque] = useState([]);
    const [gridBoxPosicaoEstoqueValue, setGridBoxPosicaoEstoqueValue] = useState([]);

    const [operacaoEstoque, setOperacaoEstoque] = useState([]);
    const [selectedOperacaoEstoque, setSelectedOperacaoEstoque] = useState([]);

    const [searchedProdutos, setSearchedProdutos] = useState([]);
    const [searchingProdutos, setSearchingProdutos] = useState(false);
    const [selectedProdutos, setSelectedProdutos] = useState([]);
    const [noResults, setNoResults] = useState(true);
    const [searchInput, setSearchInput] = useState('');

    const [resultData, setResultData] = useState([]);

    const [pessoas, setPessoas] = useState([]);
    const [pessoaValue, setPessoaValue] = useState([]);

    const [preDays, setPreDays] = useState(0);

    const [porPosicaoEstoque, setPorPosicaoEstoque] = useState('');
    const [appliedFilters, setAppliedFilters] = useState({
        dataInicial: '',
        dataFinal: '',
        operacoesEstoque: [],
        situacoes: [],
        pessoas: [],
        posicoesEstoque: [],
        produtos: [],
        estruturasMercadologicas: []
    });
   
    const resultColumns = [
        { dataField: 'produto.codigo', caption: 'Código', width: '130' },
        { dataField: 'produto.codigoRapido', caption: 'Código Rápido', width: '130' },
        { dataField: 'produto.descricao', caption: 'Produto', width: '130' },
        { dataField: 'setor.descricao', caption: 'Setor', width: '130' },
        { dataField: 'almoxarifado.descricao', caption: 'Almoxarifado', width: '130' },
        { dataField: 'estruturaMercadologica.grupo', caption: 'Grupo', width: '130' },
        { dataField: 'estruturaMercadologica.subGrupo', caption: 'Subgrupo', width: '130' },
        { dataField: 'estruturaMercadologica.artigo', caption: 'Artigo', width: '130' },
        { dataField: 'produto.subTipo.descricao', caption: 'Subtipo', width: '130' },
        { dataField: 'produto.estoque.unidadeMedidaVenda.descricao', caption: 'Unidade', width: '60' },
        { dataField: 'dataMovimento', caption: 'Data Movimento', dataType: 'datetime', width: '130' },
        { dataField: 'saldoInicial', caption: 'Estoque Inicial', format: { type: 'fixedPoint', precision: 2 }, width: '110' },
        { dataField: 'operacao.descricao', caption: 'Operação', width: '150' },
        { dataField: 'quantidade', caption: 'Quantidade', width: '90' },
        { dataField: 'observacao', caption: 'Justificativa', width: '150' },
        { dataField: 'saldo', caption: 'Estoque Final', format: { type: 'fixedPoint', precision: 2 }, width: '110' },
        { dataField: 'custoMedio', caption: 'Custo Médio', format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'estoqueCustoMedio', caption: 'Estoque Final R$', format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'precoMedio', caption: 'Preço Médio', visible: false, format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'valorVenda', caption: 'Total de Venda', visible: false, format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'margem', caption: 'Margem R$', visible: false, format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'margemPercentual', caption: 'Margem %', visible: false, format: { type: 'fixedPoint', precision: 2 }, width: '100' },
        { dataField: 'produto.quantidadeMinima', caption: 'Quantidade Minima', visible: false, width: '90' },
        { dataField: 'produto.quantidadeMaxima', caption: 'Quantidade Máxima', visible: false, width: '90' },
        { dataField: 'produto.situacaoDescricao', caption: 'Situação', visible: false, width: '150' },
        { dataField: 'documentoFiscal.numeroCOO', caption: 'Documento Fiscal', visible: false, width: '130' },
        { dataField: 'pessoa.nome', caption: 'Pessoa', visible: false, width: '150' },
    ];

    const resultColumnsPosicaoEstoque = [
        { dataField: 'produto.codigo', caption: 'Código', width: '130'},
        { dataField: 'produto.codigoRapido', caption: 'Código Rápido', visible: false, width: '130' },
        { dataField: 'produto.descricao', caption: 'Produto', width: '130' },
        { dataField: 'setor.descricao', caption: 'Setor', width: '130' },
        { dataField: 'almoxarifado.descricao', caption: 'Almoxarifado', width: '130' },
        { dataField: 'estruturaMercadologica.grupo', caption: 'Grupo', width: '130' },
        { dataField: 'estruturaMercadologica.subGrupo', caption: 'Subgrupo', width: '130' },
        { dataField: 'estruturaMercadologica.artigo', caption: 'Artigo', width: '130' },
        { dataField: 'produto.subTipo.descricao', caption: 'Subtipo', width: '130' },
        { dataField: 'produto.estoque.unidadeMedidaVenda.descricao', caption: 'Unidade', width: '60' },
        { dataField: 'saldo', caption: 'Quantidade', width: '90' },
        { dataField: 'produto.precoVenda', caption: 'Preço Venda', format: { type: 'currency', precision: 2 }, width: '100' },        
        { dataField: 'estoquePrecoVenda', caption: 'Estoque Preço Venda', format: { type: 'currency', precision: 2 }, width: '100' },        
        { dataField: 'precoCompra', caption: 'Preço Compra', format: { type: 'currency', precision: 2 }, width: '100' },        
        { dataField: 'estoquePrecoCompra', caption: 'Estoque Preço Compra', format: { type: 'currency', precision: 2 }, width: '100' },        
        { dataField: 'custoMedio', caption: 'Custo Médio', format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'estoqueCustoMedio', caption: 'Estoque Custo médio', format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'margem', caption: 'Margem (R$)', visible: false, format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'margemPercentual', caption: 'Margem (%)', visible: false, format: { type: 'fixedPoint', precision: 2 }, width: '100' },
        { dataField: 'dataUltimaCompra', caption: 'Ultima Compra', visible: false, dataType: 'date', width: '90' },
        { dataField: 'produto.quantidadeMinima', caption: 'Quantidade Minima', vis0ible: false, width: '90' },
        { dataField: 'produto.quantidadeMaxima', caption: 'Quantidade Máxima', visible: false, width: '90' },
        { dataField: 'produto.situacaoDescricao', caption: 'Situação', width: '150' },        
    ];

    const getReports = async () => {
        const response = await movimentacaoEstoqueService.getReports();

        if (!response.length) {
            toggleSearch();
        } else {
            setReports(response);
        }
    };

    const getReport = async (report) => {
        setResultData([]);
        setPorPosicaoEstoque('');
        setAppliedFilters(filters => {
            return {
                ...filters,
                dataInicial: startDate,
                dataFinal: endDate,
            }
        })

        setShowSearch(false);
        if (chosenReport.id === report.id) {
            togglePreReports();
        } else {
            setChosenReport([]);
            const response = await movimentacaoEstoqueService.getReport().find(x => x.id === report.id);
            setChosenReport(response);
            togglePreReports();
        }
    };

    const getPosicaoEstoque = async () => {
        const response = await movimentacaoEstoqueService.getPosicaoEstoque();
        if (response.length) setPosicaoEstoque(response);
    };

    const getOperacaoEstoque = async () => {
        const response = await movimentacaoEstoqueService.getOperacaoEstoque();
        if (response.length) setOperacaoEstoque(sortList(response, 'descricao'));
    };

    const getSituacoesProduto = async () => {
        const response = await movimentacaoEstoqueService.getSituacoesProduto();
        if (response.length) setSituacoesProduto(response);
    };

    const getPessoas = async () => {
        const response = await movimentacaoEstoqueService.getPessoas();
        if (response.length) setPessoas(sortList(response, 'nome'));
    };
   
    const togglePreReports = () => {
        setShowPreReports(true);
    };

    const clearFilters = () => {

        setGridBoxSituacoesProdutoValue([]);
        setSelectedSituacoesProduto([]);

        setSelectedOperacaoEstoque([]);

        setGridBoxPosicaoEstoqueValue([]);
        setSelectedPosicaoEstoque([]);

        setSelectedEstruturaMercadologica([]);

        clearProdutos([]);
        setSelectedProdutos([]);
        setSearchInput('');

        setPessoaValue([]);

        treeViewRef.current.instance.unselectAll();

        let today = new Date();

        setAppliedFilters(
            {
                dataInicial: new Date(new Date(today.getFullYear(), today.getMonth(), 1)),
                dataFinal: new Date(new Date(today.getFullYear(), today.getMonth() + 1, 0)),
                operacoesEstoque: [],
                situacoes: [],
                pessoas: [],
                posicoesEstoque: [],
                produtos: [],
                estruturasMercadologicas: []
            }
        );
        notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
    };

    useEffect(() => {
        getReports();
        loadMessages(ptMessages);
        locale(navigator.language);
    }, [selectedProdutos]);

    const getPreReport = async (report) => {
        setSearching(true);
        setPreDays(report.dias);
        setPreSearching(true);

        setAppliedFilters(filters => {
            return {
                ...filters,
                dataInicial: report.dataInicial,
                dataFinal: report.dataFinal,             
            }
        });


        setPorPosicaoEstoque(() => {
            switch (report.posicaoEstoque) {
              case 0:
                return 'ESTOQUE POSITIVO';
              case 1:
                return 'ESTOQUE NEGATIVO';
              case 2:
                return 'ESTOQUE ZERADO';
              default:
                return 'HOJE';
            }
          });
          

        if (!appliedFilters) notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
        const response = await movimentacaoEstoqueService.listarResultadoPosicaoEstoque({
            posicaoEstoque: report.posicaoEstoque,
            dateTime: report.dateTime,
        });
        if (response.notificationLevel === 1) {
            if (response.data.length) {
                setResultData(response.data);
                setPreSearching(false);
                setSearching(false);
            } else {
                notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
                setPreSearching(false);
                setSearching(false);
            }
        } else {
            notify(response.message + ' Refaça a pesquisa informando um período menor.', 'info', 2000, 'top');
            setPreSearching(false);
            setSearching(false);
        }
    }

    const onValueChanged = ((event) => {
        if (event.element.id === 'dataInicial' && !moment(event.value).isValid()) {
            setDisabledSearch(true);
            notify('Data inicial inválida.', 'error', 2000, 'top');
        } else if (event.element.id === 'dataFinal' && !moment(event.value).isValid()) {
            setDisabledSearch(true);
            notify('Data final inválida.', 'error', 2000, 'top');
        } else {
            setAppliedFilters(filters => {
                return {
                    ...filters,
                    [event.element.id]: event.element.id === ('dataInicial' || 'dataFinal') && event.value !== null ? new Date(event.value).toISOString() : event.value
                }
            });
            setDisabledSearch(false);
        }

    });

    const toggleSearch = () => {
        setResultData([]);
        setPorPosicaoEstoque('');
        getPosicaoEstoque();
        getOperacaoEstoque();
        getSituacoesProduto();
        getPessoas();
        setAppliedFilters(filters => {
            return {
                ...filters,
                dataInicial: startDate,
                dataFinal: endDate,
            }
        });
        setShowPreReports(false);
        setShowSearch(true);
    };

    const find = async () => {
        setSearching(true);

        if (!appliedFilters) notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');

        setFilters({
            operacoesEstoque: getSelectedOperacoesEstoque(),
            situacoes: [...selectedSituacoesProduto],
            pessoas: getSelectedPessoas(),
            posicoesEstoque: [...selectedPosicoes],
            produtos: [...selectedProdutos],
            estruturasMercadologicas: [...selectedEstruturaMercadologica],
            dataInicial: appliedFilters.dataInicial,
            dataFinal: appliedFilters.dataFinal
        });

        const response = await movimentacaoEstoqueService.find({
            operacoesEstoque: getSelectedOperacoesEstoque(),
            situacoes: [...selectedSituacoesProduto],
            pessoas: getSelectedPessoas(),
            posicoesEstoque: [...selectedPosicoes],
            produtos: [...selectedProdutos],
            estruturasMercadologicas: [...selectedEstruturaMercadologica],
            dataInicial: appliedFilters.dataInicial,
            dataFinal: appliedFilters.dataFinal
        });

        if (response.notificationLevel === 1) {
            if (!response.data.length) {
                notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
            }
            setResultData(response.data);
        } else {
            notify(response.message + ' Refaça a pesquisa informando um período menor.', 'info', 2000, 'top');
        }
        setSearching(false);
    };

    const clearPosicaoEstoque = () => {
        setGridBoxPosicaoEstoqueValue([]);
        setSelectedPosicaoEstoque([]);
        setAppliedFilters(filters => {
            return {
                ...filters,
                posicoesEstoque: [],
            }
        });
    };

    const clearSituacoesProduto = () => {
        setGridBoxSituacoesProdutoValue([]);
        setSelectedSituacoesProduto([]);
        setAppliedFilters(filters => {
            return {
                ...filters,
                situacoes: []
            }
        });
    };

    const syncDataGridPosicaoEstoqueSelection = (e) => {
        setGridBoxPosicaoEstoqueValue(e.value || []);
        clearPosicaoEstoque();
    };

    const dataGridPosicaoEstoque_onSelectionChanged = (e) => {
        let ids = [];
        e.selectedRowKeys.forEach(operacao => {
            ids.push(operacao.valor);
        });
        setGridBoxPosicaoEstoqueValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);

        setSelectedPosicaoEstoque(ids);
    };

    const dataGridPosicaoEstoqueRender = () => {
        return (
            <DataGrid
                dataSource={posicaoEstoque}
                columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
                hoverStateEnabled={true}
                showBorders={true}
                selectedRowKeys={gridBoxPosicaoEstoqueValue}
                onSelectionChanged={dataGridPosicaoEstoque_onSelectionChanged}>
                <Selection mode="multiple" />
            </DataGrid>
        );
    };
 

    const syncDataGridSituacaoProdutoSelection = (e) => {
        setGridBoxSituacoesProdutoValue(e.value || []);
        clearSituacoesProduto();
    };

    const dataGridSituacaoProduto_onSelectionChanged = (e) => {
        let ids = [];
        e.selectedRowKeys.forEach(situacao => {
            ids.push(situacao.valor);
        });
        setGridBoxSituacoesProdutoValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);

        setSelectedSituacoesProduto(ids);
    };

    const dataGridSituacaoProdutosRender = () => {
        return (
            <DataGrid
                dataSource={situacoesProduto}
                columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
                hoverStateEnabled={true}
                showBorders={true}
                selectedRowKeys={gridBoxSituacoesProdutoValue}
                onSelectionChanged={dataGridSituacaoProduto_onSelectionChanged}>
                <Selection mode="multiple" />
            </DataGrid>
        );
    };

    const dataGridEstruturaMercadologica_onSelectionChanged = (e) => {
        let ids = e.component.getSelectedNodesKeys();
        setSelectedEstruturaMercadologica(ids);
    }; 
   
    const getSelectedPessoas = () => {
        let pessoas = [];
        if (pessoaValue && pessoaValue.length > 0) {
            pessoaValue.forEach(p => {
                pessoas.push(p.id);
            });
        }
        return pessoas;
    };

    const getSelectedOperacoesEstoque = () => {
        let operacoesEstoque = [];
        if (selectedOperacaoEstoque && selectedOperacaoEstoque.length > 0) {
            selectedOperacaoEstoque.forEach(p => {
                operacoesEstoque.push(p.id);
            });
        }
        return operacoesEstoque;
    };

    const onSearchProdutos = (e) => {
        setSearchedProdutos([]);

        if (e.target.value.length > 2) {
            setSearchingProdutos(true)
            searchProdutos(e.target.value);
            setNoResults(false);
        } else {
            setSearchedProdutos([]);
            setSearchingProdutos(false);
        }
    };

    const searchProdutos = _.debounce(async (e) => {
        const response = await movimentacaoEstoqueService.searchProdutos(e);
        if (!response.length) setNoResults(true);
        setSearchedProdutos(response);
    }, 1200);

    const onSelectionChanged = ({ selectedRowsData }) => {
        let ids = [];

        selectedRowsData.forEach(produto => {
            if (!appliedFilters.produtos.includes(produto.id)) {
                ids.push(produto.id);
            };
        });

        setSelectedProdutos(ids);
    };

    const closeSearchingProdutos = () => {
        setSearchingProdutos(false);
    };

    const clearProdutos = () => {
        setSelectedProdutos([]);
    };

    const syncListSelection = (e, method) => {
        if (e) {
            if (e.value) {
                if (Array.isArray(e.value)) {
                    method([...e.value]);
                } else {
                    method(e.value);
                }
            } else {
                method([]);
            }
        }
    };



    ///Tooltips
    const ReturnToolTipOperacoes = () => {
        return (filters.operacoesEstoque.length ?
            <div className={`${styles.pill} ${styles.disabled}`}>
                <span>
                    <i></i>
                    {`Operações selecionadas: ${filters.operacoesEstoque.length}`}
                </span>
            </div>
            :
            <>
            </>);
    }
    const ReturnToolTipSituacoes = () => {
        return (filters.situacoes.length ?
            <div className={`${styles.pill} ${styles.disabled}`}>
                <span>
                    <i></i>
                    {`Situações selecionadas: ${filters.situacoes.length}`}
                </span>
            </div>
            :
            <>
            </>);
    }
    const ReturnToolTipPosicoes = () => {
        return (
            filters.posicoesEstoque.length ?
                <div className={`${styles.pill} ${styles.disabled}`}>
                    <span>
                        <i></i>
                        {`Posições selecionadas: ${filters.posicoesEstoque.length}`}
                    </span>
                </div>
                :
                <>
                </>
        );
    }
    const ReturnToolTipPessoas = () => {
        return (filters.pessoas.length ?
            <div className={`${styles.pill} ${styles.disabled}`}>
                <span>
                    <i></i>
                    {`Pessoas selecionadas: ${filters.pessoas.length}`}
                </span>
            </div>
            :
            <>
            </>);
    }
    const ReturnToolTipProdutos = () => {
        return (filters.produtos.length ?
            <div className={`${styles.pill} ${styles.disabled}`}>
                <span>
                    <i></i>
                    {`Produtos selecionados: ${filters.produtos.length}`}
                </span>
            </div>
            :
            <>
            </>);
    }
    const ReturnToolTipEstruturaMercadologica = () => {
        return (filters.estruturasMercadologicas.length ?
            <div className={`${styles.pill} ${styles.disabled}`}>
                <span>
                    <i></i>
                    {`Estruturas Mercadológicas selecionadas: ${filters.estruturasMercadologicas.length}`}
                </span>
            </div>
            :
            <>
            </>);
    }
    const ReturnToolTipDatas = () => {
        return (<div className={`${styles.pill} ${styles.disabled}`}>
            <span>
                <i className="icon icon-lx-calendar"></i>
                {`${moment(filters.dataInicial).format("DD/MM/YYYY")} a ${moment(filters.dataFinal).format("DD/MM/YYYY")}`}
            </span>
        </div>);
    }

    const ReturnToolTipPosicaoEscolhida = () => {
        return (
            <div className={`${styles.pill} ${styles.disabled}`}>
                <span>
                    <i></i>
                    {`Posição de Estoque: ${porPosicaoEstoque}`}
                </span>
            </div>
        );
    }

    const getResultGrid = () => {
        if (porPosicaoEstoque === ''){
            return ((resultGrid()))
        } else {
            return (resultGridPosicaoEstoque()) 
        } 
    }

    const resultGrid = () => {

        return (
            <div className={`${styles.movimentacaoEstoqueResult} mt-3`}>
                <div className={`${styles.results} shadow-sm p-3 bg-white`}>
                    <h2>RESULTADOS</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${styles.appliedFilters}`}>
                                {ReturnToolTipDatas()}
                                {ReturnToolTipProdutos()}
                                {ReturnToolTipPessoas()}
                                {ReturnToolTipEstruturaMercadologica()}
                                {ReturnToolTipPosicoes()}
                                {ReturnToolTipSituacoes()}
                                {ReturnToolTipOperacoes()}
                            </div>
                            <hr />
                            <DataGrid dataSource={resultData} defaultColumns={resultColumns} showBorders={true} allowColumnResizing={true}
                                allowColumnReordering={true} columnResizingMode={'widget'} columnMinWidth={80} rowAlternationEnabled={true}>
                                <GroupPanel visible={true} />
                                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                <Export enabled={true} fileName="MovimentacaoEstoque" />
                                <SearchPanel visible={true} highlightCaseSensitive={true} />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={true} />
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
                                <Paging defaultPageSize={20} />
                            </DataGrid>
                            <br />
                        </div>
                    </div>
                </div>
            </div >

        )
    }

    const resultGridPosicaoEstoque = () => {

        return (
            <div className={`${styles.movimentacaoEstoqueResult} mt-3`}>
                <div className={`${styles.results} shadow-sm p-3 bg-white`}>
                    <h2>RESULTADOS</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${styles.appliedFilters}`}>
                                {ReturnToolTipPosicaoEscolhida()}
                            </div>
                            <hr />
                            <DataGrid dataSource={resultData} defaultColumns={resultColumnsPosicaoEstoque} showBorders={true} allowColumnResizing={true}
                                allowColumnReordering={true} columnResizingMode={'widget'} columnMinWidth={80} rowAlternationEnabled={true}>
                                <GroupPanel visible={true} />
                                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                <Export enabled={true} fileName="PosicaoEstoque" />
                                <SearchPanel visible={true} highlightCaseSensitive={true} />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={true} />
                                <Summary>
                                    <TotalItem column="saldo" summaryType="sum" valueFormat={{ precision: 2 }} />
                                    <TotalItem column="estoquePrecoVenda" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                    <TotalItem column="estoquePrecoCompra" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                    <TotalItem column="estoqueCustoMedio" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />                                  
                               </Summary>
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
                                <Paging defaultPageSize={20} />
                            </DataGrid>
                            <br />
                        </div>
                    </div>
                </div>
            </div >

        )
    }

    return (
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <div className={`${styles.movimentacaoEstoqueReport} mt-3`}>
                <div className="shadow-sm bg-white p-3">
                    <div className={`${styles.filters} ${showPreReports ? styles.expanded : ''} ${showSearch ? styles.search : ''}`}>
                        <div className="container-fluid">
                            <div className={`${styles.filtersrWrapper} row`}>
                                <div className="col-md-12">
                                    <h2>RELATÓRIO DE ESTOQUE</h2>
                                    {reports.length ? <hr /> : <></>}
                                    <div className={styles.buttonWrapper}>
                                        {reports.map(report => (
                                            <div className={styles.reportButton} key={report.id}>
                                                <button className={`${report.type === 1 ? 'btn-secondary' : ''} ${report.type === 2 ? 'btn-light' : ''} btn btn-primary`}
                                                    onClick={report.type === 2 ? toggleSearch : () => getReport(report)}
                                                    disabled={searching}
                                                >
                                                    <i className={report.icon}></i>
                                                </button>
                                                <p>{report.title}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {showPreReports &&
                                <div className={`${styles.preReportsWrapper} ${showPreReports ? styles.fadeIn : ''}`}>
                                    {chosenReport.type === 1 ?
                                        <h3>{chosenReport.title}</h3>
                                        :
                                        <h3>RELATÓRIOS SUGERIDOS DE {chosenReport.title}</h3>
                                    }
                                    <hr />
                                    {chosenReport.title &&
                                        <div className={`${styles.optionButtonWrapper} ${showPreReports ? styles.fadeIn : ''}`}>
                                            {chosenReport.preReports.map(preReport => (
                                                <div className={styles.reportButton} key={preReport.id}>
                                                    <button className={`${preReport.type === 1 ? 'btn-secondary' : ''} ${preReport.type === 2 ? 'btn-light' : ''} btn btn-primary`}
                                                        onClick={() => getPreReport(preReport)} disabled={preSearching}>
                                                        {' '}
                                                        {preSearching && preReport.days === preDays ? (
                                                            <>
                                                                <svg className={styles.spinner} viewBox="0 0 50 50">
                                                                    <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                                    </circle>
                                                                </svg>
                                                            </>
                                                        ) : (
                                                                <>
                                                                    {preReport.icon ?
                                                                        <i className={preReport.icon}></i>
                                                                        :
                                                                        <span>{preReport.text}</span>
                                                                    }
                                                                </>
                                                            )}

                                                    </button>
                                                    {preSearching && preReport.days === preDays ?
                                                        <p>PESQUISANDO...</p>
                                                        :
                                                        <p>{preReport.title}</p>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            }
                            {showSearch &&
                                <>
                                    <h3>FILTROS</h3>
                                    <hr className={`${showSearch ? styles.fadeIn : ''}`} />
                                    <div className={`${showSearch ? styles.fadeIn : ''} ${styles.searchWrapper}`}>
                                        <div className="row">
                                            <div className={`col-md-2 form-group mb-1`}>
                                                <div className={styles.dateWrapper}>
                                                    <div className={styles.datebox}>
                                                        <span>De</span>
                                                        <DateBox defaultValue={startDate} id="dataInicial" type="date"
                                                            onValueChanged={onValueChanged} disabled={searching} useMaskBehavior={true} value={appliedFilters.dataInicial} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`col-md-2 form-group mb-1`}>
                                                <div className={styles.dateWrapper}>
                                                    <div className={styles.datebox}>
                                                        <span>Até</span>
                                                        <DateBox defaultValue={endDate} type="date" id="dataFinal" onValueChanged={onValueChanged}
                                                            disabled={searching} useMaskBehavior={true} value={appliedFilters.dataFinal} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${styles.input} col-md-8 form-group mb-1`}>
                                                <span>Produto</span>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">
                                                            <i className="icon icon-lx-search"></i>
                                                        </span>
                                                    </div>
                                                    <input type="text" className="form-control"
                                                        placeholder="Busque produtos por código ou descrição - informe ao menos 3 caracteres" value={searchInput} onFocus={(e) => { e.persist(); onSearchProdutos(e) }} onChange={(e) => { setSearchInput(e.value); e.persist(); onSearchProdutos(e) }} />
                                                </div>
                                                {searchingProdutos ?
                                                    <>
                                                        <div className={`${styles.inputResults} bg-white`}>
                                                            {!searchedProdutos.length && !noResults ?
                                                                <div className={styles.searchingWrapper}>
                                                                    <svg className={styles.spinner} viewBox="0 0 50 50">
                                                                        <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                                        </circle>
                                                                    </svg>
                            PESQUISANDO PRODUTOS...
                          </div>
                                                                : searchedProdutos.length ?
                                                                    <DataGrid
                                                                        className={styles.gridProdutos}
                                                                        id="grid-container"
                                                                        dataSource={searchedProdutos}
                                                                        keyExpr="id"
                                                                        onSelectionChanged={onSelectionChanged}
                                                                        selectedRowKeys={selectedProdutos}
                                                                        showBorders={true}
                                                                        hoverStateEnabled={true}
                                                                    >
                                                                        <Selection mode="multiple" />
                                                                        <Column dataField="codigo" caption="Código" width={150} />
                                                                        <Column dataField="descricao" caption="Descrição" />
                                                                    </DataGrid>
                                                                    :
                                                                    <>
                                                                    </>
                                                            }
                                                            {noResults &&
                                                                <div className={styles.noResults}>A BUSCA NÃO ENCONTROU NENHUM PRODUTO CORRESPONDENTE</div>
                                                            }
                                                        </div>
                                                        <div className={styles.produtosOverlay} onClick={closeSearchingProdutos}></div>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </div>

                                            <div className={`${styles.dropDownBox} col-md-6 form-group mb-2 mt-2`}>
                                                <span>Estrutura Mercadológica</span>
                                                <EstruturaMercadologicaTreeView
                                                    selectNodesRecursive={true}
                                                    ref={treeViewRef}
                                                    onSelectionChanged={dataGridEstruturaMercadologica_onSelectionChanged}
                                                />

                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}>
                                                <span>Situação</span>
                                                <DropDownBox
                                                    value={gridBoxSituacoesProdutoValue.map(item => item.descricao)}
                                                    valueExpr="descricao"
                                                    deferRendering={false}
                                                    displayExpr="descricao"
                                                    placeholder="Selecione..."
                                                    showClearButton={true}
                                                    dataSource={situacoesProduto}
                                                    onValueChanged={syncDataGridSituacaoProdutoSelection}
                                                    contentRender={dataGridSituacaoProdutosRender}
                                                />
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}>
                                                <span>Posição de Estoque</span>
                                                <DropDownBox
                                                    value={gridBoxPosicaoEstoqueValue.map(item => item.descricao)}
                                                    valueExpr="marca"
                                                    deferRendering={false}
                                                    displayExpr="descricao"
                                                    placeholder="Selecione..."
                                                    showClearButton={true}
                                                    dataSource={posicaoEstoque}
                                                    onValueChanged={syncDataGridPosicaoEstoqueSelection}
                                                    contentRender={dataGridPosicaoEstoqueRender}
                                                />
                                            </div>
                                            
                                            <div className={`${styles.dropDownBox} col-md-6 form-group mb-3`}>
                                                <span>Operações</span>
                                                <DropDownBox
                                                    value={selectedOperacaoEstoque}
                                                    valueExpr={item => { return item; }}
                                                    displayExpr={item => { return item.descricao; }}
                                                    placeholder="Selecione..."
                                                    showClearButton={true}
                                                    disabled={searching}
                                                    dataSource={operacaoEstoque}
                                                    onValueChanged={e => syncListSelection(e, setSelectedOperacaoEstoque)}
                                                    contentRender={() => {
                                                        return (
                                                            <LinxListAsContent
                                                                dataSource={operacaoEstoque}
                                                                selectedItems={selectedOperacaoEstoque}
                                                                methodThatSets={setSelectedOperacaoEstoque}
                                                                keyExpr={item => { return item.id; }}
                                                                displayExpr={item => { return item.descricao; }}
                                                                searchExpr={item => { return item.descricao; }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-6 form-group mb-3`}>
                                                <span>Pessoa (Emitente ou Destinatário)</span>
                                                <DropDownBox
                                                    value={pessoaValue}
                                                    valueExpr={item => { return item; }}
                                                    displayExpr={item => { return item.nome; }}
                                                    placeholder="Selecione..."
                                                    showClearButton={true}
                                                    disabled={searching}
                                                    dataSource={pessoas}
                                                    onValueChanged={e => syncListSelection(e, setPessoaValue)}
                                                    contentRender={() => {
                                                        return (
                                                            <LinxListAsContent
                                                                dataSource={pessoas}
                                                                selectedItems={pessoaValue}
                                                                methodThatSets={setPessoaValue}
                                                                keyExpr={item => { return item.id; }}
                                                                displayExpr={item => { return item.nome; }}
                                                                searchExpr={item => { return item.nome; }}
                                                            />
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <footer className="text-right">
                                            {/* <button className="btn btn-default"> Salvar relatório </button> */}
                                            <button className="btn btn-light" onClick={clearFilters} disabled={searching}> Limpar filtros </button>
                                            <button className="btn btn-primary" onClick={find} disabled={disabledSearch || searching}>
                                                {' '}
                                                {searching ? (
                                                    <>
                                                        <svg className={styles.spinner} viewBox="0 0 50 50">
                                                            <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                            </circle>
                                                        </svg>
                                                        PESQUISANDO...
                                                    </>
                                                ) : (
                                                        `PESQUISAR`
                                                    )}
                                            </button>
                                        </footer>
                                    </div>
                                </>
                            }

                        </div>
                    </div>
                </div>
            </div>


            {
                resultData.length > 0 ? (getResultGrid()) : <></>
            }


        </LinxPostos>
    );

}

export default withRouter(MovimentacaoEstoqueReport);