import _ from 'lodash';
import valorFreteNaLocacao from '../totais/valorFreteNaLocacao';
import valorFreteNaVenda from '../totais/valorFreteNaVenda';
import valorFreteVendaDoConsignado from '../totais/valorFreteVendaDoConsignado';
export class CalcularValorPendentePedidoService {
  static instance;
  static getInstance() {
    if (!CalcularValorPendentePedidoService.instance) {
      CalcularValorPendentePedidoService.instance = new CalcularValorPendentePedidoService();
    }
    return CalcularValorPendentePedidoService.instance;
  }

  execute(pedido, values) {
    let valorFrete = 0;
    if (pedido.tipoPedido.id === 1) {
      valorFrete = valorFreteNaVenda(values);
    } else if (pedido.tipoPedido.id === 8) {
      valorFrete = valorFreteNaLocacao(values);
    } else if (pedido.tipoPedido.id === 6) {
      valorFrete = valorFreteVendaDoConsignado(values);
    }
    let pendente = 0;
    const valorPedido = (pedido.totalProdutos || 0) + (pedido.totalAtivos || 0) - (pedido.desconto || 0) + (pedido.acrescimo || 0) + (valorFrete || 0);
    const valorRegistrado = _.sumBy(pedido.pagamentos || [], x => x.valorPagamento || 0);
    pendente = valorPedido - valorRegistrado;
    return pendente;
  }
}
