import axios from 'axios';

import { BASE_URL_GATEWAY } from '../../shared/constants/GatewayUrlConstant';

const empresasAxios = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/empresas/api`
});

export const empresasRequestService = {
  GetByCNPJ,
  GetCodigoLojaByCNPJ
}

async function GetByCNPJ(data) {
  const response = await empresasAxios.post('empresa', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function GetCodigoLojaByCNPJ(cnpj, url) {
  const http = axios.create({baseURL: url});
  
  return http.get(`LxApi/v1/empresa/estacod/`+cnpj)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    });
}

export default empresasRequestService;
