import React, { Component } from "react";
import Botao from "../../../../components/botao/Botao";
import {
  InputText,
  InputDate,
  Select,
  handleInputChange
} from "../../../../components/formulario/Formulario";
import { SectionContainer, SectionContent } from "../../../../components/section/Content";
import { showError, tagAlert } from "../../../../components/alert/Alert";
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  getTotaisConsultaFinanc,
  getConsultaFinanceiro
} from "./ConsultaFinanceiro.service";
import { getCnpj, getNomePagina, validaDadosLogin } from "../../../../shared/utils/Utils";
//Combos
import { montaComboSetor } from "../../../../services/Setores.service";
import { montaComboAlmoxarifado } from "../../../estoque/depositos/Deposito.service";
import { getPessoasFiltradas } from "../../../cadastro/pessoas/Pessoas.service";
import { montaComboTiposFormasPgto } from "../../../financeiro/conferencia-caixa/ConferenciaTurno.service";
import { montaComboMaquinas } from "../../../cadastro/maquinas-perifericos/Maquinas.service";
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../shared/constants/MenuConstants';
import Footer from "../../../../components/core/footer/Footer";
moment.locale('pt-br');

const PATH_MENU = menus.COD_60710;
const titulo = "Consulta Financeira";

class ConsultaFinanceiro extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    const now = moment().format("YYYY-MM-DD");
    this.state = {
      //cnpjEstabelecimento,
      cnpjEstabelecimento: getCnpj(),
      dataInicial: now,
      dataFinal: now,
      consultaDashBoard: false,
      codigoAlmoxarifado: [],
      codigoSetor: [],
      codigoFormaPagamento: [],
      codigoMaquina: [],
      codigoOperador: [],

      setores: [],
      almoxarifados: [],
      formasPagamento: [],
      maquinas: [],
      operadores: [],

      totalDinheiro: "",
      totalCredito: "",
      totalDebito: "",
      totalCheque: "",
      totalTicket: "",
      totalVale: "",
      totalCTF: "",

      alertActive: false,
      alertType: "",
      alertTitle: "",
      alertSubtitle: "",

      edit: false,
      financeiros: []
    };

  }

  async componentDidMount() {
    //Setores
    const { data } = await montaComboSetor(getCnpj());
    const setores = data.map(setor => {
      return {
        value: setor.codigo,
        label: setor.descricao
      }
    });
    this.setState({ setores });

    //Almoxarifado
    const { data: itens } = await montaComboAlmoxarifado(getCnpj(), "");
    const almoxarifados = itens.map(almoxarifado => {
      return {
        value: almoxarifado.codigo,
        label: almoxarifado.descricao
      }
    });
    this.setState({ almoxarifados });

    //Forma pagamento
    const { data: formas } = await montaComboTiposFormasPgto(false, false);
    const formasPagamento = formas.map(f => {
      return {
        value: f.codigo,
        label: f.descricao
      }
    });
    this.setState({ formasPagamento });

    //Maquinas
    const { data: maquina } = await montaComboMaquinas();
    const maquinas = maquina.map(m => {
      return {
        value: m.codigo,
        label: m.descricao
      }
    });
    this.setState({ maquinas });

    //Operadores
    const { data: operador } = await getPessoasFiltradas(3, 0);
    const operadores = operador.map(op => {
      return {
        value: op.codigo,
        label: op.descricao
      }
    });
    this.setState({ operadores });
  }

  limpaFiltros = () => {
    this.setState({
      cnpjEstabelecimento: getCnpj(),
      dataInicial: moment().format("YYYY-MM-DD"),
      dataFinal: moment().add(1, "months"),
      codigoAlmoxarifado: [],
      codigoSetor: [],
      formaPagamento: [],
      maquina: [],
      operador: [],
    });
  }

  verificaFiltro = () => {
    if (this.state.dataInicial === "") {
      showError(this, titulo, "É obrigatório informar a data inicial");
      return true;
    }
    if (!moment(this.state.dataInicial, "YYYY-MM-DD").isValid()) {
      showError(this, titulo, "Data inicial inválida");
      return true;
    }
    if (this.state.dataFinal === "") {
      showError(this, titulo, "É obrigatório informar a data final");
      return true;
    }
    if (!moment(this.state.dataFinal, "YYYY-MM-DD").isValid()) {
      showError(this, titulo, "Data final inválida");
      return true;
    }
    return false;
  }

  handleCancelar = () => {
    this.setState({ edit: false });
    this.limpaFiltros();
    this.props.history.push("/relatorios/financeiro");
  }

  geraRelatorio = async () => {

    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }

    const { data: financ } = await getConsultaFinanceiro(
      getCnpj(),
      this.state.dataInicial,
      this.state.dataFinal,
      this.state.codigoSetor,
      this.state.codigoAlmoxarifado,
      this.state.codigoMaquina,
      this.state.codigoOperador,
      this.state.codigoFormaPagamento,
      this.state.consultaDashBoard
    );
    const financeiro = financ.result;

    if (financeiro.length > 0) {
      const fin = financeiro.map(f => {
        return {
          data: moment(f.data).format("DD/MM/YYYY"),
          formaPagamento: f.formaPagamento,
          bandeira: f.bandeira,
          valor: "R$ " + f.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
        }
      }
      )

      const finMoOrd = fin.sort(function (a, b) {
        if (parseInt(a.data) < parseInt(b.data)) return -1;
        if (parseInt(a.data) > parseInt(b.data)) return 1;
        return 0;
      });

      this.setState({ financeiros: finMoOrd });

      const { data: totalFinanceiro } = await getTotaisConsultaFinanc(
        getCnpj(),
        this.state.dataInicial,
        this.state.dataFinal,
        this.state.codigoSetor,
        this.state.codigoAlmoxarifado,
        this.state.codigoMaquina,
        this.state.codigoOperador,
        this.state.codigoFormaPagamento
      );
      this.setState({
        totalDinheiro: "R$ " +  totalFinanceiro.totalDinheiro.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
        totalCredito: "R$ " +  totalFinanceiro.totalCredito.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
        totalDebito: "R$ " + totalFinanceiro.totalDebito.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
        totalCheque: totalFinanceiro.totalCheque > 0 ?
          "R$ " + totalFinanceiro.totalCheque.toLocaleString('pt-BR', { minimumFractionDigits: 2 }) : "R$ 0,00",
        totalTicket: "R$ " + totalFinanceiro.totalTicket.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
        totalVale: "R$ " + totalFinanceiro.totalVale.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
        totalCTF: "R$ " + totalFinanceiro.totalCTF.toLocaleString('pt-BR', { minimumFractionDigits: 2 }),
        totalFaturar: "R$ " + totalFinanceiro.totalFaturar.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
      });

      this.setState({ edit: true });
      this.props.history.push("/relatorios/financeiro/relatorio");
    }
    else {
      this.limpaFiltros();
      showError(this, titulo, "Não existem dados com esses filtros");
    }
  }

  changeAlmoxarifado = (event) => {
    let value, name;

    value = event.selected;
    name = event.name;

    this.setState({ codigoAlmoxarifado: value });
    this.setState({ [name]: value });
  }

  changeSetor = (event) => {
    let value, name;

    value = event.selected;
    name = event.name;

    this.setState({ codigoSetor: value });
    this.setState({ [name]: value });
  }

  changePagamento = (event) => {
    let value, name;

    value = event.selected;
    name = event.name;

    this.setState({ codigoFormaPagamento: value });
    this.setState({ [name]: value });
  }

  changeMaquina = (event) => {
    let value, name;

    value = event.selected;
    name = event.name;

    this.setState({ codigoMaquina: value });
    this.setState({ [name]: value });
  }

  changeOperador = (event) => {
    let value, name;

    value = event.selected;
    name = event.name;

    this.setState({ codigoOperador: value });
    this.setState({ [name]: value });
  }

  render() {
    const {
      edit,
      dataInicial,
      dataFinal,
      // codigoAlmoxarifado,
      // codigoSetor,
      // codigoFormaPagamento,
      // codigoMaquina,
      // codigoOperador,

      setores,
      almoxarifados,
      formasPagamento,
      maquinas,
      operadores,

      totalDinheiro,
      totalCredito,
      totalDebito,
      totalCheque,
      totalTicket,
      totalVale,
      totalCTF,
      totalFaturar
    } = this.state;
    return (
      <>
        {edit ? (
          <>
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU, 'Relatorio')}>
              <SectionContainer>
                <SectionContent title="Totais" accordion>
                  <div className="row">
                    <div className="col-3">
                      <InputText
                        label="Total em dinheiro"
                        value={totalDinheiro}
                        name="totalDinheiro"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total em crédito"
                        value={totalCredito}
                        name="totalCredito"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total em débito"
                        value={totalDebito}
                        name="totalDebito"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total em cheque"
                        value={totalCheque}
                        name="totalCheque"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <InputText
                        label="Total em ticket"
                        value={totalTicket}
                        name="totalTicket"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total em vale"
                        value={totalVale}
                        name="totalVale"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total em CTF"
                        value={totalCTF}
                        name="totalCTF"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total em Nota Prazo"
                        value={totalFaturar}
                        name="totalFaturar"
                        disabled={true}
                      />
                    </div>
                  </div>
                </SectionContent>
                <SectionContent title="Relatório financeiro">
                  <ReactTable
                    data={this.state.financeiros}
                    defaultPageSize={20}
                    columns={[
                      {
                        accessor: "data",
                        Header: "Data",
                        //width: 50,
                        filterable: false
                      },
                      {
                        accessor: "formaPagamento",
                        Header: "Forma ",
                        //width: 110,
                        filterable: false
                      },
                      {
                        accessor: "bandeira",
                        Header: "Bandeira",
                        //width: 100,
                        filterable: false
                      },
                      {
                        accessor: "valor",
                        Header: "Valor",
                        //width: 150,
                        filterable: false
                      }
                    ]}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando registros..."
                    noDataText="Sem registros para exibir"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                  />
                </SectionContent>
              </SectionContainer>
            </LinxPostos>
            <Footer>
              <div className="section-footer">
                <Botao
                  title="Voltar"
                  icon="icon-lx-close"
                  onClick={this.handleCancelar}
                />
              </div>
            </Footer>
          </>
        ) : (
            <>
              <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                <SectionContainer>
                  <SectionContent title="">
                    <div className="row">
                      <div className="col-3">
                        <InputDate
                          label="De"
                          name="dataInicial"
                          value={(dataInicial ? moment(dataInicial) : moment())}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-3">
                        <InputDate
                          label="Até"
                          name="dataFinal"
                          value={(dataFinal ? moment(dataFinal) : moment())}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-6">
                        <Select multi
                          label="Forma de pagamento"
                          name="codigoFormaPagamento"
                          //value={codigoFormaPagamento}
                          onChange={this.changePagamento}
                          options={formasPagamento}
                          sender={this}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <Select multi
                          label="Setor"
                          name="codigoSetor"
                          //value={codigoSetor}
                          onChange={this.changeSetor}
                          options={setores}
                          sender={this}
                        />
                      </div>
                      <div className="col-6">
                        <Select multi
                          label="Almoxarifado"
                          name="codigoAlmoxarifado"
                          //value={codigoAlmoxarifado}
                          onChange={this.changeAlmoxarifado}
                          options={almoxarifados}
                          sender={this}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Select multi
                          label="Máquina"
                          name="codigoMaquina"
                          // value={codigoMaquina}
                          onChange={this.changeMaquina}
                          options={maquinas}
                          sender={this}
                        />
                      </div>
                      <div className="col-5">
                        <Select multi
                          label="Operador"
                          name="codigoOperador"
                          //value={codigoOperador}
                          onChange={this.changeOperador}
                          options={operadores}
                          sender={this}
                        />
                      </div>
                      <div className="col-3">
                        <div className="button-wrapper">
                          <Botao
                            title="Visualizar"
                            icon="icon-lx-search"
                            onClick={this.geraRelatorio}
                          />
                        </div>
                      </div>
                    </div>
                  </SectionContent>
                </SectionContainer>
              </LinxPostos>
            </>
          )}
        {tagAlert(this)}
      </>
    );
  }
}

ConsultaFinanceiro = withRouter(ConsultaFinanceiro);
export { ConsultaFinanceiro };
