import 'devextreme/dist/css/dx.light.css';
import 'jspdf-autotable';

import { Col, Row } from 'react-bootstrap';

import { Pagamento } from './pagamento';
import React from 'react';
import { useSelector } from 'react-redux';

export function Pagamentos({ setSearching, setMessageLoadin }) {
  const selector = useSelector(state => state.novaOs);
  return (
    <>
      <Row xs={1} lg={2}>
        {(selector.values.pedidos || [])
          .filter(x => [1, 6, 8].includes((x.tipoPedido || {}).id || 1))
          .map((pedido, index) => (
            <Col xs={12} lg={6} key={index}>
              <Pagamento pedido={pedido} pedidos={selector.values.pedidos} idx={index} setSearching={setSearching} setMessageLoadin={setMessageLoadin} />
            </Col>
          ))}
      </Row>
    </>
  );
}
