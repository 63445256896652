import React, { Component } from "react";
import { InputText, handleInputChange } from "../../../components/formulario/Formulario";
import {
  tagAlert,
  // showQuestion,
  // hideAlert,
  showError,
  // showInfo
} from "../../../components/alert/Alert";
import { getEstruturasMercadologicas } from "../../../services/EstruturaMercadologica.service";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../../components/modal/Modal";
import Botao from "../../../components/botao/Botao";

import "rc-tree/assets/index.css";
// import PropTypes from "prop-types";
import Tree, { TreeNode } from "rc-tree";
// import Trigger from "rc-trigger";
import { 
  // compareNumbers, 
  sameInt} from "../../../shared/utils/Utils";

const propName = "searchEM";
const Nivel = {
  Grupo: 1,
  Subgrupo: 2,
  Artigo: 3
};

const nivelName = nivel => {
  switch (nivel) {
    case Nivel.Artigo:
      return "artigo";
    case Nivel.Subgrupo:
      return "subgrupo";
    case Nivel.Grupo:
      return "grupo";
    default:
      return "";
  }
};

function filterEstruturaMercadologica(text, search) {
  return search && text.toLowerCase().indexOf(search.toLowerCase()) > -1;
}

class SearchEstruturaMercadologicaForm extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      searchText: this.props.sender.state[propName].searchText,
      selectedKeys: [],
      expandedKeys: []
    };
    if (this.props.sender.state[propName].searchText) {
      this.filterKeys = [];
    }
  }

  onSelect = (selectedKeys, info) => {
    if (info.node.props.level === Nivel.Artigo)
      this.props.sender.state[propName].onClose({
        id: info.node.props.eventKey,
        //descricao: info.node.props.title
        descricao: info.node.props.fullDescription
      });
    else
      showError(
        this,
        this.props.sender.state[propName].title,
        `Esse é um ${nivelName(
          info.node.props.level
        )}. Selecione um ${nivelName(Nivel.Artigo)} (o ${
        Nivel.Artigo
        }º nível da estrutura mercadológica).`
      );
  };

  onExpand = (expandedKeys, e) => {
    this.filterKeys = undefined;
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  filterTreeNode = treeNode => {
    return this.filterFn(treeNode.props.title);
  };

  filterFn = key => {
    return filterEstruturaMercadologica(key, this.state.searchText);
  };

  componentDidMount = () => {
    // const { sender } = this.props;
    this.filterKeys = [];
  };

  render() {
    const speedSearchOnBottom = true;
    const loop = data => {
      return (
        data &&
        data.map(item => {
          if (this.filterKeys && this.filterFn(item.title))
            this.filterKeys.push(item.key);
          if (item.children)
            return (
              <TreeNode key={item.key} title={item.title} level={item.level} fullDescription={item.fullDescription}>
                {loop(item.children)}
              </TreeNode>
            );
          return (
            <TreeNode key={item.key} title={item.title} level={item.level} fullDescription={item.fullDescription} />
          );
        })
      );
    };

    const speedSearch = slim => (
      <InputText
        value={this.state.searchText}
        name="searchText"
        label={slim ? null : "Estrutura mercadológica:"}
        placeholder="Digite o texto a ser pesquisado"
        icone={slim ? null : this.state.searchText && "icon-lx-close"}
        onIconeClick={
          slim
            ? null
            : this.state.searchText
              ? () => {
                this.filterKeys = [];
                this.setState({ searchText: "" });
              }
              : null
        }
        onChange={e => {
          this.filterKeys = [];
          this.setState({ searchText: e.target.value });
        }}
        onKeyDown={
          this.state.searchText
            ? e => {
              if (e.keyCode === 27) {
                this.filterKeys = [];
                this.setState({ searchText: "" });
              }
            }
            : null
        }
      />
    );

    let expandedKeys = this.state.expandedKeys;
    let autoExpandParent = this.state.autoExpandParent;
    if (this.filterKeys) {
      expandedKeys = this.filterKeys;
      autoExpandParent = true;
    }

    return (
      <div>
        <Modal
          className={"active modal-filter"}
          onClose={() => this.props.sender.state[propName].onClose(null, null)}
        >
          <ModalHeader
            title={this.props.sender.state[propName].title}
            subtitle={this.props.sender.state[propName].subtitle}
          />
          <ModalBody>
            {!speedSearchOnBottom && speedSearch(false)}
            <Tree
              defaultExpandAll={true}
              showLine
              onSelect={this.onSelect}
              selectedKeys={this.state.selectedKeys}
              onExpand={this.onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              showIcon={true}
              filterTreeNode={this.filterTreeNode}
            >
              {loop(this.props.sender.state[propName].tree)}
            </Tree>
          </ModalBody>
          <ModalFooter>
            {speedSearchOnBottom && speedSearch(true)}
            <Botao
              secondary
              icon="icon-lx-close"
              title={"Cancelar"}
              onClick={() => {
                this.props.sender.state[propName].onClose(null, null);
              }}
            />
          </ModalFooter>
        </Modal>
        {tagAlert(this)}
      </div>
    );
  }
}

function findEstruturaMercadologica(data, key) {
  const fullDescription = node => {
    let result = "";
    if (node.parentKey) {
      data.map(item => {
        if (sameInt(item.key, node.parentKey)) {
          result = fullDescription(item) + " - " + node.title;
        }
        return result;
      });
    } else {
      result = node.title;
    }
    return result;
  };

  let item = null;
  let count = 0;
  if (key)
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].level === Nivel.Artigo &&
        filterEstruturaMercadologica(data[i].title, key)
      ) {
        count++;
        item = {
          id: data[i].key,
          //descricao: data[i].title 
          descricao: data[i].fullDescription ?
            data[i].fullDescription : fullDescription(data[i])
        };
      }
    }

  if (count === 1) {
    return item;
  }
  return null;
}

async function getData() {
  const { data } = await getEstruturasMercadologicas();
  let result = [];
  if (data && data.result)
    result = data.result.map(i => {
      return {
        key: i.codigo,
        parentKey: i.codigoPai,
        title: i.descricao,
        level: i.nivel
      };
    });
  return result;
}

function mountTree(data) {
  const tree = [];

  const node = item => {
    return {
      key: item.key,
      title: item.title,
      level: item.level,
      children: children(item.key),
      fullDescription: fullDescription(item)
    };
  };

  const children = parentKey => {
    const result = [];
    data.map(item => {
      if (item.parentKey === parentKey) {
        result.push(node(item));
      }
      return result;
    });
    return result;
  };

  const fullDescription = node => {
    let result = "";
    if (node.parentKey) {
      data.map(item => {
        if (sameInt(item.key, node.parentKey)) {
          result = fullDescription(item) + " - " + node.title;
        }
        return result;
      });
    } else {
      result = node.title;
    }
    return result;
  };

  data.map(item => {
    if (!item.parentKey) {
      tree.push(node(item));
    }
    return tree;
  });

  return tree;
}

export async function searchEstruturaMercadologica(
  sender,
  searchText,
  onClose,
  title = "Pesquisa Estrutura Mercadológica",
  subtitle = `Selecione um ${nivelName(
    Nivel.Artigo
  )} clicando nele (${nivelName(Nivel.Artigo)} é o ${
    Nivel.Artigo
    }º nível da estrutura mercadológica)`
) {
  const state = sender.state;
  if (!state.hasOwnProperty(propName)) state[propName] = {};
  state[propName] = {
    ...state[propName],
    sender,
    searchText: String(searchText),
    onClose,
    title,
    subtitle
  };
  if (!state[propName].data) {
    state[propName].data = await getData();
    state[propName].tree = mountTree(state[propName].data);
  }

  const item = findEstruturaMercadologica(state[propName].data, searchText);
  state[propName].active = !item;
  sender.setState(state);
  if (item) {
    onClose(item, null);
  }
}

export const tagSearchEstruturaMercadologica = sender => {
  if (sender.state[propName] && sender.state[propName].active)
    return <SearchEstruturaMercadologicaForm active={true} sender={sender} />;
};
