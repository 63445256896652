import axios from 'axios';
import {
  getCnpj
} from '../../../shared/utils/Utils';
import {
  BASE_URL_GATEWAY
} from '../../../shared/constants/GatewayUrlConstant';
import {
  TOKEN_KEY
} from '../../../authentication/Auth';

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
  //baseURL: 'http://localhost:1460/api/v1'
});


urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export function getOpcoesAntecipacao(startDate, endDate) {

  let ret = urlLinxCore.post('/dashboardcartoes/OpcoesAntecipacao', {
    query: {
      cnpj: getCnpj(),
      dataInicio: startDate,
      dataFim: endDate
    }
  });

  return ret;
}

export function getAgrupadas(startDate, endDate, tipoConsulta) {
  return urlLinxCore.post('/dashboardcartoes/TransacoesAgrupadas', {
    query: {
      cnpj: getCnpj(),
      //cnpj: '31408963000152', //Somente para testar o dashboard
      dataInicio: startDate,
      dataFim: endDate,
      tipoConsulta
    }
  });
}
