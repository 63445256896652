import axios from 'axios';
import { TOKEN_PASS_AXIOS } from '../../../../shared/constants/Const';
import { getUrl, getCnpj } from '../../../../shared/utils/Utils';
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function getTanques(cnpjEstabelecimento, ativo = false) {
  return axios.post(`LxApi/v1/Tanque/Listar`, {
    query: { cnpjEstabelecimento, ativo }
  });
}

export function getTanque(codigoTanque, cnpjEstabelecimento) {
  return axios.get(`LxApi/v1/Tanque/Selecionar`, {
    params: {
      codigoTanque,
      cnpjEstabelecimento
    }
  });
}

export function incluirTanque(
  cnpjEstabelecimento,
  numeroTanque,
  codigoTipoAlmoxarifado,
  codigoProduto,
  capacidade,
  percentualErroMedicao,
  dataAtivacao
) {
  return axios.post(`LxApi/v1/Tanque/Incluir`, {
    cnpjEstabelecimento,
    numeroTanque,
    codigoTipoAlmoxarifado,
    codigoProduto,
    capacidade,
    percentualErroMedicao,
    dataAtivacao
  });
}

export function alterarTanque(
  cnpjEstabelecimento,
  codigoTanque,
  numeroTanque,
  codigoProduto,
  capacidade,
  percentualErroMedicao,
  dataAtivacao,
  motivoAlteracao,
  inativo,
  rowVersion,
  codigoTipoAlmoxarifado
) {
  return axios.put(`LxApi/v1/Tanque/Alterar`, {
    cnpjEstabelecimento,
    codigoTanque,
    numeroTanque,
    codigoTipoAlmoxarifado,
    codigoProduto,
    capacidade,
    percentualErroMedicao,
    dataAtivacao,
    motivoAlteracao,
    inativo,
    rowVersion
  });
}

export function excluirTanque(codigoTanque, cnpjEstabelecimento) {
  return axios.delete(`LxApi/v1/Tanque/Excluir`, {
    params: {
      codigoTanque,
      cnpjEstabelecimento
    }
  });
}

export function getTanqueByData(codigoTanque, dataSaldoEstoque) {
  return axios.put('LxApi/v2/Tanque/Estoque', {
    cnpjEstabelecimento: getCnpj(),
    codigoTanque,
    dataSaldoEstoque
  });
}
