import { Col, Form, Row } from 'react-bootstrap';
import { DateBox, TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './../components.module.scss';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { removeDiasDaData } from '../../../../../shared/utils/Utils';

function DataEntregaRetirada() {
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const dispatch = useDispatch();

  const [minDate, setMinDate] = useState(removeDiasDaData(30));

  useEffect(() => {
    if (values.id !== -1 && values.dataCadastro !== null) {
      setMinDate(removeDiasDaData(30, new Date(values.dataCadastro)));
      return;
    }
    setMinDate(removeDiasDaData(30));
  }, [values.dataCadastro, values.id]);


  function diferencaEmDias(data1, data2) {
    // Um dia tem 24 * 60 * 60 * 1000 = 86400000 milissegundos
    const UM_DIA = 86400000;

    // Convertendo as datas para milissegundos
    const data1Milissegundos = data1.getTime();
    const data2Milissegundos = data2.getTime();

    // Encontrando a diferença em milissegundos
    const diferencaMilissegundos = Math.abs(data1Milissegundos - data2Milissegundos);

    // Convertendo a diferença para dias
    const diferencaDias = diferencaMilissegundos / UM_DIA;

    return Math.round(diferencaDias);
  }

  return (
    <Row className={`${styles.row}`}>
      <Col lg={4} className={`form-group`}>
        <Form.Label htmlFor="dataEntregaRetirada" className="text-primary">
          Entrega/Retirada<span className="text-danger">*</span>
        </Form.Label>
        <DateBox
          type="date"
          name="dataEntregaRetirada"
          id="dataEntregaRetirada"
          height={40}
          displayFormat="dd/MM/yyyy"
          min={minDate}
          onValueChange={e => {
            const dt = new Date(e);
            dt.setHours(0, 0, 0, 0);
            dispatch(
              changeValue({
                fieldName: 'dataEntregaRetirada',
                value: dt.toISOString(),
              }),
            );
            dispatch(changeBlur({ fieldName: 'dataEntregaRetirada' }));
            try {
              dispatch(changeValue({ fieldName: 'recolhaDevolucaoDiasCalc', value: diferencaEmDias(new Date(values.dataRecolhaDevolucao), dt) || 1 }));
            } catch (error) {}
          }}
          defaultValue={minDate}
          value={new Date(values.dataEntregaRetirada)}
        />
        {(touched || {}).dataEntregaRetirada && (errors || {}).dataEntregaRetirada && (
          <div className="text-danger validationError">{(errors || {}).dataEntregaRetirada || ''}</div>
        )}
      </Col>
      <Col lg={3} className={`form-group`}>
        <Form.Label htmlFor="entregaRetiradaHoraDe" className="text-primary">
          Hora de<span className="text-danger"></span>
        </Form.Label>
        <TextBox
          type="time"
          name="entregaRetiradaHoraDe"
          id="entregaRetiradaHoraDe"
          height={40}
          mode="text"
          maskRules={{ H: /[0-9]:/ }}
          mask="00:00"
          useMaskedValue={true}
          onValueChange={e => {
            if (e && e > '23:59') {
              e = '23:59';
            }
            dispatch(
              changeValue({
                fieldName: 'entregaRetiradaHoraDe',
                value: e,
              }),
            );
            dispatch(changeBlur({ fieldName: 'entregaRetiradaHoraDe' }));
          }}
          value={(values || {}).entregaRetiradaHoraDe || ''}
        />
      </Col>
      <Col lg={3} className={`form-group `}>
        <Form.Label htmlFor="entregaRetiradaHoraAte" className="text-primary">
          Até<span className="text-danger"></span>
        </Form.Label>
        <TextBox
          type="time"
          name="entregaRetiradaHoraAte"
          height={40}
          maskRules={{ H: /[0-9]:/ }}
          mask="00:00"
          mode="text"
          useMaskedValue={true}
          onValueChange={e => {
            if (e && e > '23:59') {
              e = '23:59';
            }
            dispatch(
              changeValue({
                fieldName: 'entregaRetiradaHoraAte',
                value: e,
              }),
            );
            dispatch(changeBlur({ fieldName: 'entregaRetiradaHoraAte' }));
          }}
          min={minDate}
          value={(values || {}).entregaRetiradaHoraAte || ''}
        />
        {(touched || {}).entregaRetiradaHoraAte && (errors || {}).entregaRetiradaHoraAte && (
          <div className="text-danger validationError">{(errors || {}).entregaRetiradaHoraAte || ''}</div>
        )}
      </Col>
      <Col className="form-group col-lg-2"></Col>
    </Row>
  );
}

export default DataEntregaRetirada;
