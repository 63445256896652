import { CheckBox, Lookup, TextBox } from 'devextreme-react';
import { DropDownOptions } from 'devextreme-react/lookup';
import React, { useState } from 'react';
import ListToolTip from '../../../../listToolTip/ListToolTip';
import styles from './ReportManager.module.scss';
import notify from 'devextreme/ui/notify';

import { useEffect } from 'react';

const ReportManager = props => {
  const [tipo, setTipo] = useState(0);
  const [periodo, setPeriodo] = useState(0);
  const [dias, setDias] = useState(0);
  const [titulo, setTitulo] = useState('');
  const [publico, setPublico] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [proprietario, setProprietario] = useState('');

  const periodos = [
    { id: 0, descricao: 'Dia atual' },
    { id: 1, descricao: 'Últimos x dias' },
    { id: 2, descricao: 'Mês atual' },
    { id: 3, descricao: 'Mês anterior' },
    { id: 4, descricao: 'Semana atual' },
    { id: 5, descricao: 'Semana anterior' }
  ];

  const periodoDia = [7, 15, 30];

  const data = [
    { id: 0, descricao: 'Dia atual' },
    { id: 1, descricao: 'Dia anterior' },
    { id: 2, descricao: 'Primeiro dia do mês' },
    { id: 3, descricao: 'Último dia do Mês' }
  ];

  useEffect(() => {
    if (props.chosenSubReport) {
      setPeriodo(props.chosenSubReport.data.periodo.periodo || 0);
      setDias(props.chosenSubReport.data.periodo.dias || 7);

      setTitulo(
        props.chosenSubReport.copia
          ? `Cópia - ${props.chosenSubReport.titulo}`
          : props.chosenSubReport.titulo
      );

      if (!props.chosenSubReport.interno) {
        setPublico(props.chosenSubReport.publico);

        if (
          props.chosenSubReport.proprietario &&
          props.chosenSubReport.proprietario.length > 0
        ) {
          setProprietario(props.chosenSubReport.proprietario);
        }
      }
    }

    setTipo(props.chosenSubReport.data.periodo.tipo);
  }, [props]);

  const save = async () => {
    if(periodo == 1 && dias == '')
    {
      notify(
        'Informe a quantidade de dias.',
        'info',
        2000,
        'top'
      );
        return;
    }
    setProcessing(true);

    let report = props.chosenSubReport;

    report.titulo = titulo;
    report.publico = publico;
    report.filtros = props.filtroOutros;
    report.colunas = props.columnsSelected;
    report.codigoRetaguarda = props.codigoRetaguarda;
    report.periodo = {
      tipo: props.chosenSubReport.data.periodo.tipo,
      periodo: periodo,
      dias: dias
    };

    setProcessing(false);

    props.save(report);
  };

  function valueChangedDias(data) {
    if(data != undefined && data !='')
    {
      data += ''; /*numérico não tem replace*/
      let temp = data.replace(/[^0-9]+/g, '');
      if(temp == undefined || temp =='' || Number.parseInt(temp) < 1)
        temp = 1;
      else
        if(Number.parseInt(temp) > 92)
          temp = 92;
      
      setDias(temp);
    }
    else
      setDias('');
  };

  const renderPeriodo = () => {
    if (tipo === 0) {
      return (
        <>
          <div className={`${styles.dropDownBox} col-6`}>
            <span>Periodo</span>
            <Lookup
              dataSource={periodos}
              valueExpr="id"
              displayExpr="descricao"
              disabled={processing}
              searchEnabled={false}
              value={periodo}
              showCancelButton={false}
              onValueChanged= {e => setPeriodo(e.value)}
            >
              <DropDownOptions closeOnOutsideClick={true} showTitle={false} />
            </Lookup>
          </div>
          {periodo === 1 ? (
            <div className={`${styles.dropDownBox} col-6`}>
              <span>Dias</span>
                <TextBox
                placeholder="Informe a quantidade de dias"
                showClearButton={true}
                type="number"
                min="1"
                max="92"
                step="1"
                maxLength={2}
                value={dias}
                showClearButton={false}
                valueChangeEvent="keyup"
                onValueChanged={e => valueChangedDias(e.value)}
              />
            </div>
          ) : null}
        </>
      );
    } else {
      return (
        <div className={`${styles.input} col-6`}>
          <span>Data</span>
          <Lookup
            dataSource={data}
            valueExpr="id"
            value={periodo}
            disabled={processing}
            displayExpr="descricao"
            showCancelButton={false}
            onValueChanged={e => setPeriodo(e.value)}
          >
            <DropDownOptions closeOnOutsideClick={true} showTitle={false} />
          </Lookup>
        </div>
      );
    }
  };

  const renderTooltips = () => {
    if (
      typeof props.filtroOutros !== 'undefined' &&
      props.filtroOutros.length > 0
    ) {
      return props.filtroOutros
        .filter(({ valor, titulo }) => {
          return (valor.length > 0 && titulo != undefined);
        })
        .map(({ titulo, valor }) => {
          return (
            <div className="col" key={titulo}>
              <ListToolTip data={valor} message={titulo} />
            </div>
          );
        });
    } else {
      return null;
    }
  };

  return (
    <div className={styles.ReportManager}>
      <div>
        {proprietario.length > 0 ? (
          <div className="row">
            <div className={`${styles.input} col-12`}>
              <span>Criado por: {proprietario}</span>
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className={`${styles.input} col-10`}>
            <span>Titulo</span>
            <TextBox
              placeholder="Informe o titulo"
              showClearButton={true}
              maxLength={50}
              value={titulo}
              showClearButton={false}
              onValueChanged={e => setTitulo(e.value)}
            />
          </div>
          <div className={`${styles.check} col-2`}>
            <CheckBox
              text="Publico"
              value={publico}
              onValueChanged={e => setPublico(e.value)}
            />
          </div>
        </div>
        <div className="row">{renderPeriodo()}</div>
        <div className="row">{renderTooltips()}</div>
        <footer className="text-right mt-4">
          <button
            disabled={processing}
            className="btn btn-light"
            onClick={props.cancel}
          >
            Cancelar
          </button>
          <button
            disabled={processing}
            className="btn btn-primary"
            onClick={save}
          >
            Salvar
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ReportManager;
