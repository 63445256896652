import axios from "axios";

import { TOKEN_PASS_AXIOS } from '../shared/constants/Const';
import {
  TIPO_SITE
} from "../shared/constants/TipoSiteConstant";
import { getUrl, getCnpj } from '../shared/utils/Utils';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function incluiFuncionario(
  cpf,
  nome,
  apelido,
  dataNascimento,
  identidade,
  orgaoEmissor,
  dataAdmissao,
  identfID,
  email,
  senha,
  codigoPerfilAcesso,
  valorMinimoGeracaoVale,
  observacao,
  endereco,
  contatos
) {
  return axios.post(`LxApi/v1/Funcionario/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    cpf,
    nome,
    apelido,
    dataNascimento,
    identidade,
    orgaoEmissor,
    dataAdmissao,
    identfID,
    email,
    senha,
    codigoPerfilAcesso,
    valorMinimoGeracaoVale,
    observacao,
    endereco,
    contatos,
    tipoSite: TIPO_SITE,
  });
}

export function alteraFuncionario(
  codigoPessoa,
  cpf,
  nome,
  apelido,
  dataNascimento,
  identidade,
  orgaoEmissor,
  dataAdmissao,
  dataCadastro,
  identfID,
  codigoUsuario,
  email,
  senha,
  codigoPerfilAcesso,
  valorMinimoGeracaoVale,
  observacao,
  inativo,
  rowVersion,
  endereco,
  contatos
) {
  return axios.put(`LxApi/v1/Funcionario/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    tipoSite: TIPO_SITE,
    codigoPessoa,
    cpf,
    nome,
    apelido,
    dataNascimento,
    identidade,
    orgaoEmissor,
    dataAdmissao,
    dataCadastro,
    identfID,
    codigoUsuario,
    email,
    senha,
    codigoPerfilAcesso,
    valorMinimoGeracaoVale,
    observacao,
    inativo,
    rowVersion,
    endereco,
    contatos
  });
}

export function excluiFuncionario(codigoPessoa) {
  return axios.delete(`LxApi/v1/Funcionario/Excluir/`, {
    params: {
      codigoPessoa,
      cnpjEstabelecimento: getCnpj(),
        tipoSite: TIPO_SITE
    }
  });
}

export function getFuncionarios(consulta) {
  consulta.cnpjEstabelecimento = getCnpj();
  consulta.cadastroCompleto = true;

  return axios.post(`LxApi/v1/Funcionario/Listar/`, {
    query: consulta
  });
}

export function verifyFuncionario(cpf) {
  return axios.get(`LxApi/v1/Funcionario/Verificar/`, {
    params: {
      cpf,
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function montarComboFuncionarios() {
  return axios.get(`LxApi/v1/Pessoa/MontarCombo`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      classificacaoPessoa: 3
    }
  });
}