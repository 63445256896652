import axios from "axios";

import { TOKEN_PASS_AXIOS } from "../../../../shared/constants/Const";
import { getUrl, getCnpj } from "../../../../shared/utils/Utils";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getBicos() {
  return axios.post(`LxApi/v1/Bico/Listar`, {
    query: { cnpjEstabelecimento: getCnpj() }
  });
}

export function incluirBico(
  codigoTanque,
  codigoBomba,
  numeroSequencial,
  numeroBicoNaBomba,
  codigoNivelPreco,
  permiteAbastecimentoManual,
  numeroHexa,
  dataAtivacao
) {
  return axios.post(`LxApi/v1/Bico/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    codigoTanque,
    codigoBomba,
    numeroSequencial,
    numeroBicoNaBomba,
    codigoNivelPreco,
    permiteAbastecimentoManual,
    numeroHexa,
    dataAtivacao
  });
}

export function alterarBico(
  codigoBico,
  codigoTanque,
  codigoBomba,
  numeroSequencial,
  numeroBicoNaBomba,
  codigoNivelPreco,
  permiteAbastecimentoManual,
  numeroHexa,
  dataAtivacao,
  inativo,
  rowVersion
) {
  return axios.put(`LxApi/v1/Bico/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigoBico,
    codigoTanque,
    codigoBomba,
    numeroSequencial,
    numeroBicoNaBomba,
    codigoNivelPreco,
    permiteAbastecimentoManual,
    numeroHexa,
    dataAtivacao,
    inativo,
    rowVersion
  });
}

export function excluirBico(codigoBico) {
  return axios.delete(`LxApi/v1/Bico/Excluir`, {
    params: { codigoBico, cnpjEstabelecimento: getCnpj() }
  });
}

export function montarComboTanque() {
  return axios.get(`LxApi/v1/Tanque/MontarCombo`, {
    params: { cnpjEstabelecimento: getCnpj() }
  });
}

export function montarComboBomba() {
  return axios.get(`LxApi/v1/Bomba/MontarCombo`, {
    params: { cnpjEstabelecimento: getCnpj() }
  });
}

export function montarComboListaPrecos(codigoTanque) {
  return axios.get(`LxApi/v1/Bico/MontarComboNiveisPreco`, {
    params: { codigoTanque, cnpjEstabelecimento: getCnpj() }
  });
}
