import { EMPORIO, EMPORIOPOP, EMPORIOPRO, POSTOPOP, SELLERWEB } from '../constants/TipoSiteSystemInfo';

import aesjs from 'aes-js';
import moment from 'moment';
import { TOKEN_KEY, login } from '../../authentication/Auth';
import { getDadosEmpresa } from '../../pages/configuracoes/empresas/Empresas.service';
import { empresasRequestService } from '../../services/RequestServices/EmpresasRequest.service';
import { BASE_URL_GATEWAY } from '../../shared/constants/GatewayUrlConstant';
import { LAST_SAFE_SMALLDATE } from '../constants/Const';
import { menus } from '../constants/MenuConstants';
import { TIPO_SITE } from '../constants/TipoSiteConstant';

export const DADOS_LOGIN_KEY = 'postopop_dadosLogin';
const DADOS_PERMISSOES_MENU_KEY = 'postopop_menuPermissoes';
export const CNPJ_DEFAULT = '54517628000198';
//export const BASE_URL_AXIOS = 'http://pop-finlandia-dbcliente.linxpostopop.com.br';
export const BASE_URL_AXIOS = 'http://localhost:56996';
const MENUS_ATUAIS = 'postopop_menusAtuais';
const MENUS_INCLUSAO = 'postopop_menusInclusao';
const MENUS_ALTERACAO = 'postopop_menusAlteracao';
const MENUS_EXCLUSAO = 'postopop_menusExclusao';
const MENUS_CONSULTA = 'postopop_menusConsulta';
const MENUS_ACESSO = 'postopop_menusAcesso';
const TOKEN_SELLER_POP = 'postopop_token';
const DADOS_USUARIO = 'postopop_dadosUsuario';
const CURRENT_MENU = 'Menu_atual';
const PERFIL_USUARIO = 'postopop_codigoPerfil';
const USER_KEY = 'user';
const MENUS_DOCS = 'postopop_menusDocumentacao';

export function getUser() {
  const storedItem = sessionStorage.getItem(USER_KEY);
  return storedItem ? storedItem : null;
}

export function getSystemInfoByTipoSite() {
  return tipoSiteConfig(TIPO_SITE);
}

export function getSystemInfoByTipoSiteLogged() {
  const { tipoSite } = getDadosLogin();
  return tipoSiteConfig(tipoSite);
}

export function MaskedPhoneNumber(value) {
  value = value.replace(/\D/g, ''); //Remove tudo o que não é dígito
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
  value = value.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
  return value;
}

const tipoSiteConfig = tipo => {
  switch (tipo) {
    case TipoSiteOption.RetaguardaPostoPop:
      return POSTOPOP;
    case TipoSiteOption.RetaguardaEmporioPop:
      return EMPORIOPOP;
    case TipoSiteOption.RetaguardaSellerWeb:
      return SELLERWEB;
    case TipoSiteOption.RetaguardaEmporioPro:
      return EMPORIOPRO;
    case TipoSiteOption.RetaguardaEmporio:
      return EMPORIO;
    default:
      return {
        nome: 'SISTEMA NAO RECONHECIDO',
        message: 'SISTEMA NAO RECONHECIDO',
        logo: null,
        showLais: false,
      };
  }
};

export const TipoSiteOption = {
  RetaguardaPostoPop: 'RetaguardaPostoPop',
  RetaguardaEmporioPop: 'RetaguardaEmporioPop',
  RetaguardaSellerWeb: 'RetaguardaSellerWeb',
  RetaguardaEmporioPro: 'RetaguardaEmporioPro',
  RetaguardaEmporio: 'RetaguardaEmporio',
};

export function getDadosLogin() {
  let result = null;
  const storedItem = sessionStorage.getItem(DADOS_LOGIN_KEY);
  if (storedItem) result = JSON.parse(storedItem);
  if (result && result.cnpj && result.url && result.codigoEstabelecimento) return result;
  return {
    cnpj: CNPJ_DEFAULT,
    url: BASE_URL_AXIOS,
  };
}

export function setDadosAutenticacao(token, codigoPerfil) {
  sessionStorage.setItem(TOKEN_SELLER_POP, token);
  sessionStorage.setItem(PERFIL_USUARIO, codigoPerfil);
}

export function setDadosLogin(cnpj, url, codigoEstabelecimento, logo, logoSmall, tipoSiteUrl, nome, login, empresas, tipoSite, templateTema, share) {
  
  
  sessionStorage.setItem(
    DADOS_LOGIN_KEY,
    JSON.stringify({
      cnpj,
      url,
      codigoEstabelecimento,
      logo,
      logoSmall,
      tipoSiteUrl,
      empresas,
      nome,
      login,
      tipoSite,
      templateTema,
      share,
    }),
  );
}

export function updateInfoEmpresaStorage(razaoSocial, nomeFantasia, cnpj) {
  let dados = getDadosLogin();

  let empresaAtualizar = dados.empresas.find(x => x.cnpj === cnpj);
  empresaAtualizar.razaoSocial = razaoSocial;
  empresaAtualizar.nomeFantasia = nomeFantasia;

  sessionStorage.setItem(
    DADOS_LOGIN_KEY,
    JSON.stringify({
      cnpj: dados.cnpj,
      url: dados.url,
      codigoEstabelecimento: dados.codigoEstabelecimento,
      logo: dados.logo,
      logoSmall: dados.logoSmall,
      tipoSiteUrl: dados.tipoSiteUrl,
      empresas: dados.empresas,
      nome: dados.nome,
      login: dados.login,
      tipoSite: dados.tipoSite,
      templateTema: dados.templateTema,
      share: dados.share,
    }),
  );
}

function setEmpresaSelecionada(empresa, codigoEstabelecimento) {
  const dados = getDadosLogin();

  const empresaSelecionada = dados.empresas.find(x => x.cnpj === empresa.cnpj);
  const url = empresaSelecionada.urLs[0];
  const novosDados = {
    ...dados,
    cnpj: empresaSelecionada.cnpj,
    url: url.url,
    codigoEstabelecimento: codigoEstabelecimento,
    tipoSite: url.tipoSite,
    templateTema: url.templateTema,
    share: url.share,
  };
  console.log('SET SESSION');

  sessionStorage.setItem(DADOS_LOGIN_KEY, JSON.stringify(novosDados));
  login(empresaSelecionada.token);
}

export function updateSelectedEmpresa(empresa) {
  setEmpresaSelecionada(empresa, getCodigoEstabelecimento());
  empresasRequestService.GetCodigoLojaByCNPJ(empresa.cnpj, getUrl()).then(response => {
    setEmpresaSelecionada(empresa, parseInt(response));
  });
}

export function updateUserName(userName) {
  let dados = getDadosLogin();
  console.log('SET SESSION');
  sessionStorage.setItem(
    DADOS_LOGIN_KEY,
    JSON.stringify({
      cnpj: dados.cnpj,
      url: dados.url,
      codigoEstabelecimento: dados.codigoEstabelecimento,
      logo: dados.logo,
      logoSmall: dados.logoSmall,
      tipoSiteUrl: dados.tipoSiteUrl,
      empresas: dados.empresas,
      nome: userName,
      login: dados.login,
      tipoSite: dados.tipoSite,
    }),
  );
}

export function updateTema() {
  const classList = document.body.classList;
  while (classList.length > 0) {
    classList.remove(classList.item(0));
  }

  const tema = getTema();
  document.body.classList.add(tema);
}

export function encriptaCNPJ() {
  let cnpjOriginal = getCnpj();

  let bs = 16;

  let key = new Int16Array(new Buffer([17, 5, 4, 1, 3, 17, 5, 4, 1, 3, 17, 5, 4, 1, 3, 17]));
  let iv = new Int16Array(new Buffer([17, 5, 4, 1, 3, 17, 5, 4, 1, 3, 17, 5, 4, 1, 3, 17]));

  let clearBytes = aesjs.utils.utf8.toBytes(cnpjOriginal);

  let fillCount = bs - (clearBytes.length % bs);
  let char = String.fromCharCode(fillCount);

  for (let i = 0; i < fillCount; i++) {
    cnpjOriginal += char;
  }

  clearBytes = aesjs.utils.utf8.toBytes(cnpjOriginal);

  var aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);

  var encryptedBytes = aesCbc.encrypt(clearBytes);

  let textoFinal = new Buffer(encryptedBytes).toString('base64');

  textoFinal = encodeURIComponent(textoFinal);

  return textoFinal;
}

export function removeDadosLogin() {
  sessionStorage.removeItem(DADOS_LOGIN_KEY);
  sessionStorage.removeItem(DADOS_PERMISSOES_MENU_KEY);
  sessionStorage.removeItem(MENUS_ATUAIS);
  sessionStorage.removeItem(MENUS_INCLUSAO);
  sessionStorage.removeItem(MENUS_ALTERACAO);
  sessionStorage.removeItem(MENUS_EXCLUSAO);
  sessionStorage.removeItem(MENUS_CONSULTA);
  sessionStorage.removeItem(MENUS_ACESSO);
  sessionStorage.removeItem(TOKEN_SELLER_POP);
  sessionStorage.removeItem(DADOS_USUARIO);
  sessionStorage.removeItem(PERFIL_USUARIO);
  sessionStorage.removeItem(MENUS_DOCS);
}
export function getCnpj() {
  return getDadosLogin().cnpj;
}

export function getTema() {
  const tema = getDadosLogin().templateTema;

  if (tema) {
    return tema;
  } else {
    return 'default-theme';
  }
}

export function getUrl() {
  return getDadosLogin().url;
}

export function getCodigoEstabelecimento() {
  return getDadosLogin().codigoEstabelecimento;
}

export function getCodigoUsuario() {
  return getDadosUsuario().codigo;
}
export function getBearerToken() {
  return sessionStorage.getItem(TOKEN_KEY);
}

export const getNomeEmpresa = async () => {
  const { data: dataEmpresa } = await getDadosEmpresa();
  return dataEmpresa.nomeFantasia;
};

export function toFloatFormattedDisplay(numberString) {
  if (numberString === null) {
    return null;
  }

  let number = parseFloat(numberString);
  let s = number.toLocaleString('pt-br');
  return number % 1 === 0 ? s + ',00' : s;
}

export function toFloatFormattedDisplayDecimals(numberString, minimumFractionDigits) {
  let number = parseFloat(numberString);
  let s = number.toLocaleString('pt-br', {
    minimumFractionDigits,
    maximumFractionDigits: minimumFractionDigits,
  });
  return s;
}

export function validaCPF(value) {
  let c = String(value);
  const b = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

  if ((c = c.replace(/[^\d]/g, '')).length !== 11) return false;

  if (
    /0{11}/.test(c) ||
    /1{11}/.test(c) ||
    /2{11}/.test(c) ||
    /3{11}/.test(c) ||
    /4{11}/.test(c) ||
    /5{11}/.test(c) ||
    /6{11}/.test(c) ||
    /7{11}/.test(c) ||
    /8{11}/.test(c) ||
    /9{11}/.test(c)
  )
    return false;

  for (var i = 0, n = 0; i < 9; n += c[i] * b[++i]);
  if (!sameInt(c[9], (n %= 11) < 2 ? 0 : 11 - n)) return false;

  for (var x = 0, y = 0; x <= 9; y += c[x] * b[x++]);
  if (!sameInt(c[10], (y %= 11) < 2 ? 0 : 11 - y)) return false;

  return true;
}

export function validaCNPJ(value) {
  // http://araujo.cc/blog/funcao-javascript-para-validar-cnpj.html
  let c = String(value);
  const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  if ((c = c.replace(/[^\d]/g, '')).length !== 14) return false;

  if (/0{14}/.test(c)) return false;

  for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]);
  if (!sameInt(c[12], (n %= 11) < 2 ? 0 : 11 - n)) return false;

  for (var x = 0, y = 0; x <= 12; y += c[x] * b[x++]);
  if (!sameInt(c[13], (y %= 11) < 2 ? 0 : 11 - y)) return false;

  return true;
}

export function validaCpfOrCnpj(value) {
  return validaCPF(value) || validaCNPJ(value);
}

export function maskedCpf(value) {
  // retorna value formatado com a máscara de CPF "999.999.999-99"
  let str = String(value);
  return str.substring(0, 3) + '.' + str.substring(3, 6) + '.' + str.substring(6, 9) + '-' + str.substring(9);
}

export function maskedCnpj(value) {
  // retorna value formatado com a máscara do CNPJ "99.999.999/9999-99"
  let str = String(value).padStart(14, '0');
  return str.substring(0, 2) + '.' + str.substring(2, 5) + '.' + str.substring(5, 8) + '/' + str.substring(8, 12) + '-' + str.substring(12);
}

export function maskedCnpjCpf(value) {
  // retorna value formatado como CPF ou CNPJ se for válido em um desses
  if (validaCPF(value)) {
    return maskedCpf(value);
  }
  if (validaCNPJ(value)) {
    return maskedCnpj(value);
  }
  return value;
}

export function maskCpfCnpj(value) {
  // se value for um CPF válido retorna a máscara do CPF mais um #
  // se for um CNPJ válido retorna a máscara do CNPJ
  // senão retorna 14 #
  if (validaCPF(value)) {
    return '###.###.###-###'; // o último é para poder continuar digitando
  }
  if (validaCNPJ(value)) {
    return '##.###.###/####-##';
  }
  return '##############';
}

export function getMeses() {
  const meses = [];
  meses.push({
    label: 'Janeiro',
    value: 0,
  });
  meses.push({
    label: 'Fevereiro',
    value: 1,
  });
  meses.push({
    label: 'Março',
    value: 2,
  });
  meses.push({
    label: 'Abril',
    value: 3,
  });
  meses.push({
    label: 'Maio',
    value: 4,
  });
  meses.push({
    label: 'Junho',
    value: 5,
  });
  meses.push({
    label: 'Julho',
    value: 6,
  });
  meses.push({
    label: 'Agosto',
    value: 7,
  });
  meses.push({
    label: 'Setembro',
    value: 8,
  });
  meses.push({
    label: 'Outubro',
    value: 9,
  });
  meses.push({
    label: 'Novembro',
    value: 10,
  });
  meses.push({
    label: 'Dezembro',
    value: 11,
  });

  return meses;
}

export function getWeekDays(fromIndex = 0) {
  /* const m = moment().locale("pt-br");
  if (!fromIndex)
    return m.weekdays();

  const result = [];
  for (let i = fromIndex; i < 7; i++) {
    result.push(m.weekdays(i));
  }
  for (let j = 0; j < fromIndex; j++) {
    result.push(m.weekdays(j));
  }
  return result; */
  const weekDays = [];
  weekDays.push({
    label: 'Segunda-feira',
    value: 1,
  });
  weekDays.push({
    label: 'Terça-feira',
    value: 2,
  });
  weekDays.push({
    label: 'Quarta-feira',
    value: 3,
  });
  weekDays.push({
    label: 'Quinta-feira',
    value: 4,
  });
  weekDays.push({
    label: 'Sexta-feira',
    value: 5,
  });
  weekDays.push({
    label: 'Sábado',
    value: 6,
  });
  weekDays.push({
    label: 'Domingo',
    value: 7,
  });
  return weekDays;
}

export function getQuinzenas() {
  const quinzenas = [];
  let j = 0;
  for (let i = 1; i < 16; i++) {
    j = i + 15;
    quinzenas.push({
      label: String(i) + '-' + String(j),
      value: i,
    });
  }
  return quinzenas;
}

export function getDezenas() {
  const quinzenas = [];
  for (let i = 1; i <= 10; i++) {
    quinzenas.push({
      label: `${i}-${i + 10}-${i + 20}`,
      value: i,
    });
  }
  return quinzenas;
}

export function getDateIndex(month, year) {
  return Number(String(year) + String(month));
}

export function isValidEmail(email) {
  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function isValidUrl(url) {
  const re = /^[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
  return re.test(url);
}

export function isValidHour(hour) {
  // https://stackoverflow.com/questions/38147443/24-hour-clock-regex-for-javascript
  // relógio com hora e minuto apenas (08:35, por exemplo)
  const re = /^([01]\d|2[0-3]):([0-5]\d)$/;
  return re.test(hour);
}

export function ibgeToUf(ibge) {
  switch (ibge) {
    case 12:
      return 'AC';
    case 27:
      return 'AL';
    case 13:
      return 'AM';
    case 16:
      return 'AP';
    case 29:
      return 'BA';
    case 23:
      return 'CE';
    case 53:
      return 'DF';
    case 32:
      return 'ES';
    case 99:
      return 'EX';
    case 52:
      return 'GO';
    case 21:
      return 'MA';
    case 31:
      return 'MG';
    case 50:
      return 'MS';
    case 51:
      return 'MT';
    case 15:
      return 'PA';
    case 25:
      return 'PB';
    case 26:
      return 'PE';
    case 22:
      return 'PI';
    case 41:
      return 'PR';
    case 33:
      return 'RJ';
    case 24:
      return 'RN';
    case 11:
      return 'RO';
    case 14:
      return 'RR';
    case 43:
      return 'RS';
    case 42:
      return 'SC';
    case 28:
      return 'SE';
    case 35:
      return 'SP';
    case 17:
      return 'TO';
    default:
      return '';
  }
}

export function isValidIE(ie, uf) {
  let estado = ibgeToUf(uf);
  let dig = 0;
  let nro = 0;
  let resultado = 0;
  let b = 0;
  let soma = 0;
  let die = 0;
  let s = 0;
  let nresto = 0;

  ie = ie.replace(/\./g, '');
  ie = ie.replace(/\\/g, '');
  ie = ie.replace(/\-/g, '');
  ie = ie.replace(/\//g, '');

  if (ie === 'ISENTO') return true;

  switch (estado) {
    case 'AC':
      if (ie.length !== 13) return false;
      b = 4;
      soma = 0;
      for (let i = 0; i <= 10; i++) {
        soma += parseInt(ie.charAt(i)) * b;
        --b;
        if (b === 1) b = 9;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = 0;
      if (dig !== parseInt(ie.charAt(11))) return false;
      b = 5;
      soma = 0;
      for (let i = 0; i <= 11; i++) {
        soma += parseInt(ie.charAt(i)) * b;
        --b;
        if (b === 1) b = 9;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = 0;
      return dig === parseInt(ie.charAt(12));

    case 'AL':
      if (ie.length !== 9) return false;
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += parseInt(ie.charAt(i)) * b;
        --b;
      }
      soma *= 10;
      dig = soma - Math.floor(soma / 11) * 11;
      if (dig === 10) dig = 0;
      return dig === parseInt(ie.charAt(8));

    case 'AM':
      if (ie.length !== 9) return false;
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += parseInt(ie.charAt(i)) * b;
        b--;
      }
      if (soma < 11) {
        dig = 11 - soma;
      } else {
        let g = soma % 11;
        if (g <= 1) {
          dig = 0;
        } else {
          dig = 11 - g;
        }
      }
      return dig === parseInt(ie.charAt(8));

    case 'AP':
      if (ie.length !== 9) return false;
      if (ie.substring(0, 2) !== '03') return false;
      let p = 0,
        d = 0,
        i = ie.substring(1, 8);
      if (i >= 3000001 && i <= 3017000) {
        p = 5;
        d = 0;
      } else if (i >= 3017001 && i <= 3019022) {
        p = 9;
        d = 1;
      }
      b = 9;
      soma = p;
      for (let i = 0; i <= 7; i++) {
        soma += parseInt(ie.charAt(i)) * b;
        b--;
      }
      dig = 11 - (soma % 11);
      if (dig === 10) {
        dig = 0;
      } else if (dig === 11) {
        dig = d;
      }
      return dig === parseInt(ie.charAt(8));

    case 'BA':
      if (ie.length === 8) {
        let die = ie.substring(0, 8);
        nro = new Array(8);
        let dig = -1;
        for (let i = 0; i <= 7; i++) nro[i] = parseInt(die.charAt(i));
        let NumMod = 0;
        if (String(nro[0]).match(/[0123458]/)) {
          NumMod = 10;
        } else {
          NumMod = 11;
        }
        b = 7;
        soma = 0;
        for (let j = 0; j <= 5; j++) {
          soma += nro[j] * b;
          b--;
        }
        let g = soma % NumMod;
        if (NumMod === 10) {
          if (g === 0) {
            dig = 0;
          } else {
            dig = NumMod - g;
          }
        } else {
          if (g <= 1) {
            dig = 0;
          } else {
            dig = NumMod - g;
          }
        }
        resultado = dig === nro[7];
        if (!resultado) return false;
        b = 8;
        soma = 0;
        for (let i = 0; i <= 5; i++) {
          soma += nro[i] * b;
          b--;
        }
        soma += nro[7] * 2;
        let h = soma % NumMod;
        if (NumMod === 10) {
          if (h === 0) {
            dig = 0;
          } else {
            dig = NumMod - h;
          }
        } else {
          if (h <= 1) {
            dig = 0;
          } else {
            dig = NumMod - h;
          }
        }
        return dig === nro[6];
      }

      if (ie.length === 9) {
        die = ie.substring(0, 9);
        nro = new Array(9);
        dig = -1;
        for (let i = 0; i <= 8; i++) nro[i] = parseInt(die.charAt(i));
        let NumMod = 0;
        if (String(nro[1]).match(/[0123458]/)) {
          NumMod = 10;
        } else {
          NumMod = 11;
        }
        b = 8;
        soma = 0;
        for (let i = 0; i <= 6; i++) {
          soma += nro[i] * b;
          b--;
        }
        let h = soma % NumMod;
        if (NumMod === 10) {
          if (h === 0) {
            dig = 0;
          } else {
            dig = NumMod - h;
          }
        } else {
          if (h <= 1) {
            dig = 0;
          } else {
            dig = NumMod - h;
          }
        }
        resultado = dig === nro[8];

        if (!resultado) return false;
        b = 9;
        soma = 0;
        for (let i = 0; i <= 6; i++) {
          soma += nro[i] * b;
          b--;
        }
        soma += nro[8] * b;
        h = soma % NumMod;
        if (NumMod === 10) {
          if (h === 0) {
            dig = 0;
          } else {
            dig = NumMod - h;
          }
        } else {
          if (h <= 1) {
            dig = 0;
          } else {
            dig = NumMod - h;
          }
        }
        return dig === nro[7];
      }

      return false;

    case 'CE':
      if (ie.length !== 9) return false;
      die = ie;
      nro = Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(die[i]);
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = 0;
      return dig === nro[8];

    case 'DF':
      if (ie.length !== 13) return false;
      nro = new Array(13);
      for (let i = 0; i <= 12; i++) nro[i] = parseInt(ie.charAt(i));
      b = 4;
      soma = 0;
      for (let i = 0; i <= 10; i++) {
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 9;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = 0;
      if (dig !== nro[11]) return false;
      b = 5;
      soma = 0;
      for (let i = 0; i <= 11; i++) {
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 9;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = 0;
      return dig === nro[12];

    case 'ES':
      if (ie.length !== 9) return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      let g = soma % 11;
      if (g < 2) {
        dig = 0;
      } else {
        dig = 11 - g;
      }
      return dig === nro[8];

    case 'GO':
      if (ie.length !== 9) return false;
      s = ie.substring(0, 2);
      if (s === '10' || s === '11' || s === '15') {
        nro = new Array(9);
        for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
        //n = Math.floor(ie / 10);
        let n = parseInt(ie.substring(0, 7));
        if ((n = 11094402)) {
          if (nro[8] === 0 || nro[8] === 1) return true;
        }
        b = 9;
        soma = 0;
        for (let i = 0; i <= 7; i++) {
          soma += nro[i] * b;
          b--;
        }
        let g = soma % 11;
        if (g === 0) dig = 0;
        else {
          if (g === 1) {
            if (n >= 10103105 && n <= 10119997) dig = 1;
            else dig = 0;
          } else dig = 11 - g;
        }
        return dig === nro[8];
      }
      return false;

    case 'MA':
      if (ie.length !== 9) return false;
      if (ie.substring(0, 2) !== '12') return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      g = soma % 11;
      if (g <= 1) {
        dig = 0;
      } else {
        dig = 11 - g;
      }
      return dig === nro[8];

    case 'MG':
      if (ie.length !== 13) return false;
      let dig1 = ie.substring(11, 12);
      let dig2 = ie.substring(12, 13);
      let inscC = ie.substring(0, 3) + '0' + ie.substring(3, 11);
      let insc = inscC.split('');
      let npos = 11;
      let y = 1;
      let ptotal = 0;
      let psoma = 0;
      while (npos >= 0) {
        y++;
        psoma = parseInt(insc[npos]) * y;
        if (psoma >= 10) psoma -= 9;
        ptotal += psoma;
        if (y === 2) y = 0;
        npos--;
      }
      nresto = ptotal % 10;
      if (nresto === 0) nresto = 10;
      nresto = 10 - nresto;
      if (nresto !== parseInt(dig1)) return false;
      npos = 11;
      let z = 1;
      ptotal = 0;
      let is = ie.split('');
      while (npos >= 0) {
        z++;
        if (z === 12) z = 2;
        ptotal += parseInt(is[npos]) * z;
        npos--;
      }
      nresto = ptotal % 11;
      if (nresto === 0 || nresto === 1) nresto = 11;
      nresto = 11 - nresto;
      return nresto === parseInt(dig2);

    case 'MS':
      if (ie.length !== 9) return false;
      if (ie.substring(0, 2) !== '28') return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      let w = soma % 11;
      if (w <= 1) {
        dig = 0;
      } else {
        dig = 11 - w;
      }
      return dig === nro[8];

    case 'MT':
      ie = ('00000000000' + ie).slice(-11);

      if (ie.length !== 11) return false;
      die = ie;
      nro = new Array(11);
      for (let i = 0; i <= 10; i++) nro[i] = parseInt(die[i]);
      b = 3;
      soma = 0;
      for (let i = 0; i <= 9; i++) {
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 9;
      }
      let q = soma % 11;
      if (q <= 1) {
        dig = 0;
      } else {
        dig = 11 - q;
      }
      return dig === nro[10];

    case 'PA':
      if (ie.length !== 9) return false;
      if (ie.substring(0, 2) !== '15') return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      let c = soma % 11;
      if (c <= 1) dig = 0;
      else dig = 11 - c;
      return dig === nro[8];

    case 'PB':
      if (ie.length !== 9) return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      let pb = soma % 11;
      if (pb <= 1) dig = 0;
      else dig = 11 - pb;
      return dig === nro[8];

    case 'PE':
      // IE antiga com 14 digitos
      if (ie.length === 14) {
        nro = new Array(14);
        for (let i = 0; i <= 13; i++) nro[i] = parseInt(ie.charAt(i));
        b = 5;
        soma = 0;
        for (let i = 0; i <= 12; i++) {
          soma += nro[i] * b;
          b--;
          if (b === 0) b = 9;
        }
        dig = 11 - (soma % 11);
        if (dig > 9) dig = dig - 10;
        return dig === nro[13];
      }

      // IE nova com 9 digitos
      if (ie.length === 9) {
        nro = new Array(9);
        for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
        b = 8;
        soma = 0;
        for (let i = 0; i <= 6; i++) {
          soma += nro[i] * b;
          b--;
        }
        let e = soma % 11;
        if (e <= 1) {
          dig = 0;
        } else {
          dig = 11 - e;
        }
        if (dig !== nro[7]) return false;
        b = 9;
        soma = 0;
        for (let i = 0; i <= 7; i++) {
          soma += nro[i] * b;
          b--;
        }
        e = soma % 11;
        if (e <= 1) {
          dig = 0;
        } else {
          dig = 11 - e;
        }
        return dig === nro[8];
      }

      return false;

    case 'PI':
      if (ie.length !== 9) return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      let pi = soma % 11;
      if (pi <= 1) {
        dig = 0;
      } else {
        dig = 11 - pi;
      }
      return dig === nro[8];

    case 'PR':
      if (ie.length !== 10) return false;
      nro = new Array(10);
      for (let i = 0; i <= 9; i++) nro[i] = parseInt(ie.charAt(i));
      b = 3;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 7;
      }
      let pr = soma % 11;
      if (pr <= 1) dig = 0;
      else dig = 11 - pr;
      if (dig !== nro[8]) return false;
      b = 4;
      soma = 0;
      for (let i = 0; i <= 8; i++) {
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 7;
      }
      pr = soma % 11;
      if (pr <= 1) dig = 0;
      else dig = 11 - pr;
      return dig === nro[9];

    case 'RJ':
      if (ie.length !== 8) return false;
      nro = new Array(8);
      for (let i = 0; i <= 7; i++) nro[i] = parseInt(ie.charAt(i));
      b = 2;
      soma = 0;
      for (let i = 0; i <= 6; i++) {
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 7;
      }
      let rj = soma % 11;
      if (rj <= 1) {
        dig = 0;
      } else {
        dig = 11 - rj;
      }
      return dig === nro[7];

    case 'RN':
      if (ie.substring(0, 2) !== '20') return false;

      // IE com 9 digitos
      if (ie.length === 9) {
        nro = new Array(9);
        for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
        b = 9;
        soma = 0;
        for (let i = 0; i <= 7; i++) {
          soma += nro[i] * b;
          b--;
        }
        soma *= 10;
        dig = soma % 11;
        if (dig === 10) dig = 0;
        return dig === nro[8];
      }

      // IE com 10 digitos
      if (ie.length === 10) {
        nro = new Array(10);
        for (let i = 0; i <= 9; i++) nro[i] = parseInt(ie.charAt(i));
        b = 10;
        soma = 0;
        for (let i = 0; i <= 8; i++) {
          soma += nro[i] * b;
          b--;
        }
        soma *= 10;
        dig = soma % 11;
        if (dig === 10) dig = 0;
        return dig === nro[9];
      }

      return false;

    case 'RO':
      if (ie.length !== 14) return false;
      nro = new Array(14);
      b = 6;
      soma = 0;
      for (let i = 0; i <= 12; i++) {
        nro[i] = parseInt(ie.charAt(i));
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 9;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = dig - 10;
      return dig === parseInt(ie.charAt(13));

    case 'RR':
      if (ie.length !== 9) return false;
      if (ie.substring(0, 2) !== '24') return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      soma = 0;
      let n = 0;
      for (let i = 0; i <= 7; i++) soma += nro[i] * ++n;
      dig = soma % 9;
      return dig === nro[8];

    case 'RS':
      if (ie.length !== 10) return false;
      nro = new Array(10);
      for (let i = 0; i <= 9; i++) nro[i] = parseInt(ie.charAt(i));
      b = 2;
      soma = 0;
      for (let i = 0; i <= 8; i++) {
        soma += nro[i] * b;
        b--;
        if (b === 1) b = 9;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = 0;
      return dig === nro[9];

    case 'SC':
      if (ie.length !== 9) return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      let sc = soma % 11;
      if (sc <= 1) dig = 0;
      else dig = 11 - sc;
      return dig === nro[8];

    case 'SE':
      if (ie.length !== 9) return false;
      nro = new Array(9);
      for (let i = 0; i <= 8; i++) nro[i] = parseInt(ie.charAt(i));
      b = 9;
      soma = 0;
      for (let i = 0; i <= 7; i++) {
        soma += nro[i] * b;
        b--;
      }
      dig = 11 - (soma % 11);
      if (dig >= 10) dig = 0;
      return dig === nro[8];

    case 'SP':
      if (ie.length !== 12) return false;
      nro = new Array(12);
      for (let i = 0; i <= 11; i++) nro[i] = parseInt(ie.charAt(i));
      soma = nro[0] * 1 + nro[1] * 3 + nro[2] * 4 + nro[3] * 5 + nro[4] * 6 + nro[5] * 7 + nro[6] * 8 + nro[7] * 10;
      dig = soma % 11;
      if (dig >= 10) dig = 0;
      if (dig !== nro[8]) return false;
      soma = nro[0] * 3 + nro[1] * 2 + nro[2] * 10 + nro[3] * 9 + nro[4] * 8 + nro[5] * 7 + nro[6] * 6 + nro[7] * 5 + nro[8] * 4 + nro[9] * 3 + nro[10] * 2;
      dig = soma % 11;
      if (dig >= 10) dig = 0;
      return dig === nro[11];

    case 'TO':
      if (ie.length === 11 || ie.length === 9) {
        if (ie.length === 9) {
          nro = new Array(9);
        } else {
          s = ie.substring(2, 4);
          if (s !== '01' && s !== '02' && s !== '03' && s !== '99') return false;
          nro = new Array(11);
        }

        b = 9;
        soma = 0;

        if (ie.length === 9) {
          for (let i = 0; i <= 7; i++) {
            nro[i] = parseInt(ie.charAt(i));
            soma += nro[i] * b;
            b--;
          }
        } else {
          for (let i = 0; i <= 9; i++) {
            nro[i] = parseInt(ie.charAt(i));
            if (i !== 2 && i !== 3) {
              soma += nro[i] * b;
              b--;
            }
          }
        }

        let resto = soma % 11;
        if (resto < 2) {
          dig = 0;
        } else {
          dig = 11 - resto;
        }

        if (ie.length === 9) return dig === parseInt(ie.charAt(8));
        else return dig === parseInt(ie.charAt(10));
      } else return false;

    default:
      return false;
  }
}

export function isValidGTIN(value) {
  if (!value || !parseInt(value)) {
    return false;
  }
  // https://github.com/TheGeekExplorer/GTIN-Validation/blob/master/JS/GTINCheckDigit.js
  const gtin = String(value);
  // Check length of barcode for validity
  if (!checkBasics(gtin)) {
    return false;
  }

  // Define fixed variables
  let CheckDigitArray = [];
  let gtinMaths = [3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3, 1, 3];
  let modifier = 17 - (gtin.length - 1); // Gets the position to place first digit in array
  // let gtinCheckDigit = gtin.slice(-1); // Get provided check digit
  let BarcodeArray = gtin.split(''); // Split barcode at each digit into array
  let gtinLength = gtin.length;
  let tmpCheckDigit = 0;
  let tmpCheckSum = 0;
  // let tmpMath = 0;

  // Run through and put digits into multiplication table
  for (let i = 0; i < gtinLength - 1; i++) {
    CheckDigitArray[modifier + i] = BarcodeArray[i]; // Add barcode digits to Multiplication Table
  }

  // Calculate "Sum" of barcode digits
  for (let i = modifier; i < 17; i++) {
    tmpCheckSum += CheckDigitArray[i] * gtinMaths[i];
  }

  // Difference from Rounded-Up-To-Nearest-10 - Fianl Check Digit Calculation
  tmpCheckDigit = Math.ceil(tmpCheckSum / 10) * 10 - parseInt(tmpCheckSum);

  // Check if last digit is same as calculated check digit
  if (gtin.slice(-1) === tmpCheckDigit) return true;
}

// Checks the validity of the input - is it
// the right length (8, 12, 13, 14), and is
// a numeric value
function checkBasics(value) {
  const gtin = String(value);
  // Check length is ok
  if (gtin.length !== 8 && gtin.length !== 12 && gtin.length !== 13 && gtin.length !== 14) return false;

  // Check whether is a number
  var m = gtin.match(/\d+/);
  if (isNaN(m[0])) return false;

  // Is valid, return true
  return true;
}

export function isValidIdentfid(s) {
  const re = /\b[0-9A-F]{1,16}\b/gi;
  return re.test(s);
}

export function isAlphaNumericOnly(s) {
  const re = /[a-zA-Z0-9]/;
  return re.test(s);
}

export function maskCpf(value) {
  // se value for um CPF válido retorna a máscara do CPF
  // senão retorna 11 #
  if (validaCPF(value)) {
    return '###.###.###-##'; // o último é para poder continuar digitando
  }
  return '#'.repeat(11);
}

export function getPercentualDiff(max, amount, decimalNumbers) {
  const dN = Number.isNaN(decimalNumbers) ? 3 : decimalNumbers;
  return max ? Math.abs(100 - (amount / max) * 100).toFixed(dN) : 0;
}

export const none = '';
// constante usada no lugar de null para listas utilizadas no Select
// se usar null em value buga

export function noneItem(noneText = '') {
  // retorna um item none para a lista utilizada no Select
  return {
    value: none,
    label: noneText,
  };
}

export function makeItem(obj, showValue = false, value = 'codigo', label = 'descricao') {
  // retorna um objeto {value, label} a ser utilizado na lista do Select
  // baseando-se em um objeto no formato default {codigo, descricao}
  if (obj)
    return {
      value: obj[value],
      label: `${showValue ? obj[value] + ' - ' : ''}` + obj[label],
    };
  return noneItem();
}

export function isItem(obj) {
  return !!obj && obj.hasOwnProperty('value') && obj.hasOwnProperty('label');
}

export function makeList(source, noneText, showValue = false, value = 'codigo', label = 'descricao') {
  // retorna o mapeamento de um array para ser utilizada no Select
  let result = [];
  if (source && source.length > 0)
    result = source.map(item => {
      return makeItem(item, showValue, value, label);
    });
  if (noneText) {
    result.unshift(noneItem(noneText));
  }
  return result;
}

export function sortList(list, property = 'label') {
  return list.sort((objectA, objectB) => {
    let a = String(objectA[property]).toUpperCase();
    let b = String(objectB[property]).toUpperCase();
    return a < b ? -1 : b > a ? +1 : 0;
  });
}

export function oneOrMore(value, one, more) {
  // retorna one se value for um ou more se for diferente
  if (value === 1) return one;
  return more;
}

export function heOrShe(isHe, he, she) {
  // retorna he se isHe for true ou she se for diferente
  if (isHe) return he;
  return she;
}

export function ifShow(condition, divName) {
  // retorna divName se condition for true ou "content-hidden" se for
  return condition ? divName : 'content-hidden';
}

export function sameInt(number1, number2) {
  return parseInt(number1 ? number1 : 0) === parseInt(number2 ? number2 : 0);
}

export function sameFloat(float1, float2) {
  return parseFloat(float1 ? float1 : 0) === parseFloat(float2 ? float2 : 0);
}

export function stringToFloatWithFixed(numberAsString, fixed = 0) {
  let f = numberAsString ? numberAsString : 0;
  f = String(f).replace(',', '.'); // 1,23 vira 1.23
  const v = !fixed ? f : parseFloat(f).toFixed(fixed);
  return v;
}

export function sameFloatWithFixed(float1, float2, fixed = 0) {
  const v1 = stringToFloatWithFixed(float1, fixed);
  const v2 = stringToFloatWithFixed(float2, fixed);
  return parseFloat(v1) === parseFloat(v2);
}

export function sameString(string1, string2) {
  return String(string1) === String(string2);
}

export function emptyText(inputText) {
  return typeof inputText === 'undefined' || inputText === '' || inputText === null;
}

export function emptyDate(inputDate) {
  return emptyText(inputDate);
}

export function emptySelect(value) {
  // retorna se em um campo Select nada foi selecionado
  return value === none || value === null || typeof value === 'undefined' || (Array.isArray(value) && value.length === 0);
}

export function emptyAutoComplete(item) {
  // verifica se em um campo AutoComplete nada foi selecionado
  return !isItem(item) || emptySelect(item.value);
}

export function unionArray(list, logical = 'e', comma = ',') {
  let result = '';
  for (let i = 0; i < list.length; i++) {
    result = result + (i === 0 ? '' : i < list.length - 1 ? comma + ' ' : ' ' + logical + ' ') + list[i];
  }
  return result;
}

export function formatNumber(value, minimumFractionDigits = 2) {
  let result = parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits,
  });
  if (result === 'NaN') return '';
  return result;
}

export function isValidIP(ip) {
  // https://stackoverflow.com/questions/23483855/javascript-regex-to-validate-ipv4-and-ipv6-address-no-hostnames
  const IP_REGEXP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$|^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
  return IP_REGEXP.test(ip);
}

export function removeAccents(s) {
  // https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463
  return String(s)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

export function compareStrings(a, b) {
  const A = removeAccents(a).toUpperCase();
  const B = removeAccents(b).toUpperCase();
  if (A < B) return -1;
  if (A > B) return 1;
  return 0;
}

export function compareNumbers(a, b) {
  const A = Number(a);
  const B = Number(b);
  if (A < B) return -1;
  if (A > B) return 1;
  return 0;
}

export function setPermissoesMenu(permissoes) {
  var menusAtuais = [];
  var screenInclusao = [];
  var screenAlteracao = [];
  var screenExclusao = [];
  var screenConsulta = [];
  var screenAcesso = [];
  var linksDocumentacao = [];
  permissoes.forEach(itMenu => {
    menusAtuais.push({
      codigoMenu: itMenu.codigoMenu,
      codigoMenuPai: itMenu.codigoMenuPai,
      descricao: itMenu.descricao,
      caminhoImagem: itMenu.caminhoImagem,
      caminhoMenu: itMenu.caminhoMenu,
    });

    linksDocumentacao.push({
      codigoMenu: itMenu.codigoMenu,
      linkDocumentacao: itMenu.linkDocumentacao,
      caminhoMenu: itMenu.caminhoMenu,
    });

    itMenu.permissoes.forEach(itPermissao => {
      if (itPermissao.codigoOperacao === 10 && itPermissao.temPermissao && itMenu.caminhoMenu) screenInclusao.push(itMenu.caminhoMenu);
      if (itPermissao.codigoOperacao === 20 && itPermissao.temPermissao && itMenu.caminhoMenu) screenAlteracao.push(itMenu.caminhoMenu);
      if (itPermissao.codigoOperacao === 30 && itPermissao.temPermissao && itMenu.caminhoMenu) screenExclusao.push(itMenu.caminhoMenu);
      if (itPermissao.codigoOperacao === 40 && itPermissao.temPermissao && itMenu.caminhoMenu) screenConsulta.push(itMenu.caminhoMenu);
      if (itPermissao.codigoOperacao === 50 && itPermissao.temPermissao) {
        if (itMenu.caminhoMenu !== '') screenAcesso.push(itMenu.caminhoMenu);
        else {
          screenAcesso.push(itMenu.codigoMenu);
        }
      }
    });
  });
  sessionStorage.setItem(
    DADOS_PERMISSOES_MENU_KEY,
    JSON.stringify({
      permissoes,
    }),
  );
  sessionStorage.setItem(
    MENUS_ATUAIS,
    JSON.stringify({
      menusAtuais,
    }),
  );
  sessionStorage.setItem(
    MENUS_INCLUSAO,
    JSON.stringify({
      screenInclusao,
    }),
  );
  sessionStorage.setItem(
    MENUS_ALTERACAO,
    JSON.stringify({
      screenAlteracao,
    }),
  );
  sessionStorage.setItem(
    MENUS_EXCLUSAO,
    JSON.stringify({
      screenExclusao,
    }),
  );
  sessionStorage.setItem(
    MENUS_CONSULTA,
    JSON.stringify({
      screenConsulta,
    }),
  );
  sessionStorage.setItem(
    MENUS_ACESSO,
    JSON.stringify({
      screenAcesso,
    }),
  );
  sessionStorage.setItem(
    MENUS_DOCS,
    JSON.stringify({
      linksDocumentacao,
    }),
  );
}

export function getMenusAtuais() {
  let result = null;
  const storedItem = sessionStorage.getItem(MENUS_ATUAIS);
  if (storedItem) result = JSON.parse(storedItem);
  if (result) return result.menusAtuais;
  else return [];
}

export function menuPermiteInclusao(caminhoMenu) {
  let result = null;
  if (caminhoMenu === menus.COD_80620 || caminhoMenu === menus.COD_80540)
    //exceções
    return true;
  const storedItem = sessionStorage.getItem(MENUS_INCLUSAO);
  if (storedItem) result = JSON.parse(storedItem).screenInclusao;
  if (result) return result.filter(s => s === caminhoMenu).length > 0;
  else return false;
}

export function menuPermiteAlteracao(caminhoMenu) {
  let result = null;
  const storedItem = sessionStorage.getItem(MENUS_ALTERACAO);
  if (storedItem) result = JSON.parse(storedItem).screenAlteracao;
  if (result) return result.filter(s => s === caminhoMenu).length > 0;
  else return false;
}

export function menuPermiteExclusao(caminhoMenu) {
  let result = null;
  const storedItem = sessionStorage.getItem(MENUS_EXCLUSAO);
  if (storedItem) result = JSON.parse(storedItem).screenExclusao;
  if (result) return result.filter(s => s === caminhoMenu).length > 0;
  else return false;
}

export function menuPermiteConsulta(caminhoMenu) {
  let result = null;
  const storedItem = sessionStorage.getItem(MENUS_CONSULTA);
  if (storedItem) result = JSON.parse(storedItem).screenConsulta;
  if (result) return result.filter(s => s === caminhoMenu).length > 0;
  else return false;
}

export function menuPermiteAcesso(caminhoMenu) {
  let result = null;
  const storedItem = sessionStorage.getItem(MENUS_ACESSO);
  if (storedItem) result = JSON.parse(storedItem).screenAcesso;
  if (result) return result.filter(s => s === caminhoMenu).length > 0;
  else return false;
}

export function operacaoAcesso(codigoMenu) {
  let result = null;
  const storedItem = sessionStorage.getItem(MENUS_ACESSO);
  if (storedItem) result = JSON.parse(storedItem).screenAcesso;
  if (result) return result.filter(s => s === codigoMenu).length > 0;
  else return false;
}

export function getLabelByValue(list, value) {
  if (!list || !value) return '';

  let label = '';
  list.forEach(item => {
    if (item.value === value) {
      label = item.label;
      return;
    }
  });
  return label;
}

export function setToken(token) {
  sessionStorage.setItem(TOKEN_SELLER_POP, token);
}

export function setDadosUsuario(dadosUsuario, email) {
  var codigo = dadosUsuario.codigo;
  var codigoPerfilAcesso = dadosUsuario.codigoPerfilAcesso;
  var nome = dadosUsuario.nome;
  var cpf = dadosUsuario.cpf;
  var dataCadastro = dadosUsuario.dataCadastro;

  sessionStorage.setItem(
    DADOS_USUARIO,
    JSON.stringify({
      codigo,
      codigoPerfilAcesso,
      nome,
      email,
      cpf,
      dataCadastro,
    }),
  );
}

export function getDadosUsuario() {
  const storedItem = sessionStorage.getItem(DADOS_USUARIO);
  if (storedItem);
  return JSON.parse(storedItem);
}

export function setMenuClicado(menuClicado) {
  sessionStorage.setItem(CURRENT_MENU, menuClicado);
}

export function getToken() {
  const storedItem = sessionStorage.getItem(TOKEN_SELLER_POP);
  if (storedItem);
  return storedItem;
}

export function getCodigoPerfil() {
  const storedItem = sessionStorage.getItem(PERFIL_USUARIO);
  if (storedItem) return storedItem;
}

export function getDadosSellerPop(caminhoMenu, sendCredentials) {
  let params =
    'ID=' +
    getToken() +
    '&PERFIL=' +
    getCodigoPerfil() +
    '&SISTEMA=' +
    getCodigoMenu(caminhoMenu) +
    '&LOJA=' +
    getCodigoEstabelecimento() +
    '&POSTOESCOLA=N&NaoTranca=true' +
    '&Token=' +
    getBearerToken() +
    '&Gateway=' +
    BASE_URL_GATEWAY;

  if (sendCredentials) params += '&UserId=' + getDadosUsuario().codigo;

  return params;
}

export function getMaxFinalSmallDateString() {
  const fiftyYearsFromNow = moment()
    .startOf('day')
    .add(50, 'years');
  const smallDateMoment = moment(LAST_SAFE_SMALLDATE).startOf('day');
  return fiftyYearsFromNow.isAfter(smallDateMoment) ? smallDateMoment.format('DD/MM/YYYY') : fiftyYearsFromNow.format('DD/MM/YYYY');
}

export function getCodigoMenu(caminhoMenu) {
  let result = '';
  let menuBuscado = {};
  const storedItem = sessionStorage.getItem(MENUS_ATUAIS);
  if (storedItem) var parseado = JSON.parse(storedItem);
  if (parseado) menuBuscado = parseado.menusAtuais.filter(s => s.caminhoMenu === caminhoMenu);
  if (menuBuscado.length > 0) result = menuBuscado[0].codigoMenu;
  return result;
}

export function getNomePagina(caminhoMenu, detail) {
  let result = [];
  let menuPai = [];
  let menuBuscado = [];
  let menuFavorito = [];
  let caminhoParsed = caminhoMenu.split('/')[1];
  const storedItem = sessionStorage.getItem(MENUS_ATUAIS);
  if (storedItem) var parseado = JSON.parse(storedItem);
  if (parseado) {
    menuPai = parseado.menusAtuais.filter(
      x =>
        x.caminhoImagem &&
        x.descricao
          .toLowerCase()
          .replace('ç', 'c')
          .replace('õ', 'o')
          .replace('ó', 'o')
          .replace('-', ' ') === caminhoParsed.replace('-', ' '),
    );
    menuBuscado = parseado.menusAtuais.filter(s => s.caminhoMenu === caminhoMenu);
  } else {
    result = 'Breadcrumb-não-encontrado-no-sessionStorage';
  }

  if (menuBuscado.length > 0) {
    menuFavorito = menuBuscado.filter(s => s.codigoMenuPai === 100000);

    if (caminhoMenu.split('/').length > 3) {
      if (menuPai.length > 0)
        result = `${menuPai[0].caminhoImagem} ${menuPai[0].descricao.replace(/ /g, '#')} ${caminhoMenu
          .split('/')[2]
          .replace(/^./, caminhoMenu.split('/')[2][0].toUpperCase())
          .replace('cao', 'ção')}${caminhoMenu.split('/').length > 4 ? ' ...' : ''} ${menuBuscado[0].descricao.replace(/ /g, '#')}`;
      if (detail) {
        result = `${result} ${detail}`;
      }
    } else if (caminhoMenu.split('/').length === 3) {
      if (menuPai.length > 0) result = `${menuPai[0].caminhoImagem} ${menuPai[0].descricao} ${menuBuscado[0].descricao.replace(/ /g, '#')}`;
      if (detail) {
        result = `${result} ${detail}`;
      }
    } else {
      if (menuPai.length > 0) result = `${menuPai[0].caminhoImagem} ${menuPai[0].descricao}`;
      if (detail) {
        result = `${result} ${detail}`;
      }
    }
  } else {
    result = 'Menu-não-encontrado-no-menusAtuais';
  }

  let resultObjeto = {
    caminhoSeparado: result,
    caminhoMenu: caminhoMenu,
    ehFavorito: menuFavorito.length > 0,
  };

  return resultObjeto;
}

export function getNomePaginaAmigavel(caminhoMenu, detail) {
  let result = [];
  let menuPai = [];
  let menuBuscado = {};
  let caminhoParsed = caminhoMenu.split('/')[1];
  const storedItem = sessionStorage.getItem(MENUS_ATUAIS);
  if (storedItem) var parseado = JSON.parse(storedItem);
  if (parseado) {
    menuPai = parseado.menusAtuais.filter(
      x =>
        x.caminhoImagem &&
        x.descricao
          .toLowerCase()
          .replace('ç', 'c')
          .replace('õ', 'o')
          .replace('ó', 'o') === caminhoParsed,
    );
    menuBuscado = parseado.menusAtuais.filter(s => s.caminhoMenu === caminhoMenu);
  } else {
    result = 'Breadcrumb-não-encontrado-no-sessionStorage';
  }
  if (caminhoMenu.split('/').length > 3) {
    if (menuPai.length > 0)
      result = `${menuPai[0].descricao} ${caminhoMenu.split('/')[2].replace(/^./, caminhoMenu.split('/')[2][0].toUpperCase())} - ${menuBuscado[0].descricao.replace(/ /g, '-')}`;
    if (detail) {
      result = `${result} - ${detail}`;
    }
  } else if (caminhoMenu.split('/').length === 3) {
    if (menuPai.length > 0) result = `${menuPai[0].descricao} - ${menuBuscado[0].descricao.replace(/ /g, '-')}`;
    if (detail) {
      result = `${result} - ${detail}`;
    }
  } else {
    if (menuPai.length > 0) result = `${menuPai[0].descricao}`;
    if (detail) {
      result = `${result} - ${detail}`;
    }
  }
  return result;
}

export function getMenu(codigoMenu) {
  const storedItem = sessionStorage.getItem(MENUS_ATUAIS);
  if (storedItem) var parseado = JSON.parse(storedItem);
  if (parseado) {
    let menu = parseado.menusAtuais.filter(x => x.codigoMenu === codigoMenu);
    if (menu.length > 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function labelCpfCnpj(value) {
  if (validaCPF(value)) return 'CPF';
  if (validaCNPJ(value)) return 'CNPJ';
  return 'CPF/CNPJ';
}

export function isObject(obj) {
  return sameString(typeof obj, 'object');
}

export function isObjectEmpty(obj) {
  if (isObject(obj)) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }
  return false;
}

export function isObjectSelected(object) {
  if (!object) return false;
  return object && object.hasOwnProperty('isSelected') && object.isSelected;
}

export function validaDadosLogin() {
  const storedItem = sessionStorage.getItem(DADOS_LOGIN_KEY);
  return storedItem !== null;
}

export function filledArray(array) {
  if (!array) {
    return false;
  }

  if (Array.isArray(array)) {
    return array.length > 0;
  }

  return false;
}

export function stringToUpperOrEmpty(s) {
  return s ? String(s).toUpperCase() : '';
}

export function stringToFloatHelper(s) {
  return s ? parseFloat(String(s).replace(',', '.')) : 0;
}

export function sleep(milliseconds = 0) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function getErrorMessage(error) {
  if (error && error.response && error.response.data && error.response.data.message) {
    return error.response.data.message;
  }
  return error.message;
}

export function isArrayFilledWithEmpty(array) {
  let result = false;
  if (Array.isArray(array)) {
    array.forEach(value => {
      if (value) {
        if (Array.isArray(value)) {
          result = isArrayFilledWithEmpty(value);
        } else if (isObject(value)) {
          result = isObjectEmpty(value);
        } else {
          result = false;
          return;
        }
      }
    });
  }
  return result;
}

export function scrollToRef(ref) {
  document.querySelector('.scrollbar-container').scrollTo({
    left: 0,
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
}

export function sumNumbersInArray(numbers) {
  if (!Array.isArray(numbers)) return 0;
  return numbers.reduce((acc, num) => {
    return acc + (isNaN(parseFloat(num)) ? 0 : parseFloat(num));
  }, 0);
}

export function getLinkMenu(caminhoMenu) {
  let itemParsed = null;
  const storedItem = sessionStorage.getItem(MENUS_DOCS);
  if (storedItem) itemParsed = JSON.parse(storedItem);
  if (itemParsed && itemParsed.linksDocumentacao) {
    const item = itemParsed.linksDocumentacao.find(r => r.caminhoMenu === caminhoMenu);
    if (item && item.linkDocumentacao) return item.linkDocumentacao;
    else return '';
  }
}

export function formatPosDtefSerie(value) {
  // retorna value formatado para "999-999-999"
  let str = String(value);
  if (str.length < 1) return '';

  str = str.replace(/-/g, '');
  str = str.substr(0, 3) + '-' + str.substr(3, 3) + '-' + str.substr(6, 3);

  return str;
}

export function baseURL(url) {
  var urlBase = '';

  if (url) {
    var pathArray = url.split('/');

    if (pathArray.length > 0) {
      var protocol = pathArray[0];
      var host = pathArray[2];
      urlBase = protocol + '//' + host;
    }
  }
  return urlBase;
}
export function formatStringToLowerWithoutSpecialCharacters(value) {
  var retorno = removeAccents(value);
  retorno = retorno.replace(/\s/g, '');
  return retorno.toLowerCase();
}
export function formatStringToCurrency(value) {
  if (!value) {
    value = 0;
  }
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
  return formatter.format(value);
}

export function removeDiasDaData(dias, date = null) {
  const data = date || new Date();
  data.setDate(data.getDate() - dias);
  return new Date(data.setHours(0, 0, 0, 0));
}

export function menuFavoritoTratar(caminhoMenu, favoritar) {
  let menuBuscado = [];
  let indexFavoritado = -1;
  let codigoMenu = 0;

  const menusLocalStorage = sessionStorage.getItem(MENUS_ATUAIS);
  if (menusLocalStorage) var parseado = JSON.parse(menusLocalStorage);
  if (parseado) {
    menuBuscado = parseado.menusAtuais.filter((valor, indice) => {
      if (valor.caminhoMenu === caminhoMenu) {
        codigoMenu = valor.codigoMenu;
        //100000 = Favoritos
        if (valor.codigoMenuPai === 100000) {
          indexFavoritado = indice; //Deve retornar o indice do favorito apenas (se existir)
          return false; //Deve retornar apenas o menu normal
        }
        return true;
      }
      return false;
    });
  }

  if (menuBuscado.length > 0) {
    if (favoritar) {
      if (indexFavoritado < 0) {
        //Troca o código pai para indicar que agora é um Favorito
        let menuFavorito = { ...menuBuscado[0] };
        //100000 = Favoritos
        menuFavorito.codigoMenuPai = 100000;
        //Adiciona o favorito no array que será guardado no LocalStorage
        parseado.menusAtuais.push(menuFavorito);
      }
      //Chamada da API para remover registro de favorito da tabela do banco
    } else {
      if (indexFavoritado >= 0) {
        //Remove favorito do array que será guardado no LocalStorage
        parseado.menusAtuais.splice(indexFavoritado, 1);
      }
      //Chamada da API para remover registro de favorito da tabela do banco
    }
  }

  //Grava no sessionStorage com o objeto modificado
  sessionStorage.setItem(MENUS_ATUAIS, JSON.stringify({ menusAtuais: parseado.menusAtuais }));

  return codigoMenu;
}
export function menuFavoritoRecarregar(menusFavoritos) {
  //Busca menus atuais do LocalStorage
  const menusLocalStorage = sessionStorage.getItem(MENUS_ATUAIS);
  if (menusLocalStorage) var parseado = JSON.parse(menusLocalStorage);
  if (parseado) {
    //Cria array sem os menus Favoritos (100000)
    let menus = parseado.menusAtuais.filter(x => x.codigoMenuPai !== 100000);

    //Merge do Menu Favoritos com os demais Menus
    menusFavoritos.forEach(itMenu => {
      //Adiciona os favoritos no array que será guardado no LocalStorage
      menus.push({
        codigoMenu: itMenu.codigoMenu,
        codigoMenuPai: itMenu.codigoMenuPai,
        descricao: itMenu.descricao,
        caminhoImagem: itMenu.caminhoImagem,
        caminhoMenu: itMenu.caminhoMenu,
      });
    });

    //Grava no sessionStorage com o objeto modificado
    sessionStorage.setItem(MENUS_ATUAIS, JSON.stringify({ menusAtuais: menus }));
  }
}
export function arraysEqual(a, b) {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
}

export function normalizeToBoolean(value) {
  if (typeof value === 'string') {
    const normalizedValue = value.trim().toLowerCase();
    if (normalizedValue === '1' || normalizedValue === 'true') {
      return true;
    } else if (normalizedValue === '0' || normalizedValue === 'false') {
      return false;
    }
  }
  // Opcional: lançar um erro ou retornar um valor padrão para casos inesperados
  throw new Error(`Valor não suportado: ${value}`);
  // ou return null; // caso prefira retornar null para valores não suportados
}
