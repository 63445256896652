import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { URL_ARQUIVO_MODELO_PRODUTO, URL_ARQUIVO_MODELO_PRODUTO_CARGA_RAPIDA } from '../../../../../shared/constants/Const';

import styles from './SetupFacilFonteDados.module.scss';
import { locale } from 'devextreme/localization';

export default function SetupFacilFonteDados({ ...props }) {

  locale('pt-BR');
  
  const [selectedItem, setSelectedItem] = useState(0);
  // selectedItem:
  // 0 - Item não selecionado
  // 1 - Base de dados
  // 2 - Planilha
  // 3 - Planilha Rápida

  useEffect(() => {
    if (selectedItem === 0) {
      props.messageStepNotCompletedUpdate('Selecione uma das opções para continuar');
    } else {
      props.dadosUpdate({ fonteDados: selectedItem });
    }
  }, [selectedItem]);

  const onClickOption = e => {
    props.isCompletedStepUpdate(true);

    switch (e.currentTarget.id) {
      case 'baseDados':
        setSelectedItem(1);
        break;
      case 'planilha':
        setSelectedItem(2);
        break;
      case 'planilhaRapida':
        setSelectedItem(3);
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.fonteDados}>
      <h1>Selecione a fonte de dados para importação</h1>

{ 
      
      /*<div id="baseDados" className={`${styles.fonteDadosOption} ${selectedItem === 1 ? styles.fonteDadosOptionChecked : ''}`} onClick={onClickOption}>
        <input type="radio" id="baseDados" name="fonte" value="baseDados" checked={selectedItem === 1} onChange={onClickOption} />
        <label htmlFor="baseDados">BASE DE DADOS MODELO</label>
        <p>Você poderá realizar a importação de cadastros de produtos de nossa base de dados modelo, realizando ajustes conforme suas especificações.</p>
      </div>

      {selectedItem === 1 && <span> Para continuar, clique em AVANÇAR </span>}*/
      
}
      <div id="planilha" className={`${styles.fonteDadosOption} ${selectedItem === 2 ? styles.fonteDadosOptionChecked : ''}`} onClick={onClickOption}>
        <input type="radio" id="planilha" name="fonte" value="planilha" checked={selectedItem === 2} onChange={onClickOption} />
        <label htmlFor="planilha">CARGA INICIAL VIA PLANILHA TEMPLATE</label>
        <p>
          Você poderá realizar o download da nossa planilha que possui um template de cadastro de produtos para carga inicial. Basta você preencher os dados dos
          seus produtos, voltar aqui para o upload da planilha para realizar a importação.
        </p>
      </div>

      {selectedItem === 2 && (
        <div className={styles.fonteDadosOptionDownloadTemplate}>
          {console.log('URL_ARQUIVO_MODELO_PRODUTO   >>>> ' + URL_ARQUIVO_MODELO_PRODUTO)}
          <a href={URL_ARQUIVO_MODELO_PRODUTO} download="template_carga_produtos.xlxs">
            <Button variant="light">
              <i className="icon icon-lx-download" />
              DOWNLOAD PLANILHA TEMPLATE
            </Button>
          </a>
          <span> Se você já preencheu a planilha, clique em AVANÇAR </span>
        </div>
      )}

      <div id="planilhaRapida" className={`${styles.fonteDadosOption} ${selectedItem === 3 ? styles.fonteDadosOptionChecked : ''}`} onClick={onClickOption}>
        <input type="radio" id="planilhaRapida" name="fonte" value="planilhaRapida" checked={selectedItem === 3} onChange={onClickOption} />
        <label htmlFor="planilhaRapida">CARGA INICIAL VIA PLANILHA RÁPIDA</label>
        <p>
          Você poderá realizar o download da nossa planilha que possui um template de cadastro de produtos para carga inicial. Basta você preencher o código do
          EAN e o Preço de Venda, voltar aqui para o upload da planilha para realizar a importação.
        </p>
      </div>

      {selectedItem === 3 && (
        <div className={styles.fonteDadosOptionDownloadTemplate}>
          <a href={URL_ARQUIVO_MODELO_PRODUTO_CARGA_RAPIDA} download="template_importacao_via_carga_rapida.xlsx">
            <Button variant="light">
              <i className="icon icon-lx-download" />
              DOWNLOAD PLANILHA RÁPIDA TEMPLATE
            </Button>
          </a>
          <span> Se você já preencheu a planilha, clique em AVANÇAR </span>
        </div>
      )}
    </div>
  );
}
