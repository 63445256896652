import { Column, DataGrid, Export, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import 'devextreme/dist/css/dx.light.css';
import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { selectFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { ExportarParaExcelService } from '../../../relatorios/auditoria/compras-dinamico/services/ExportarParaExcell.service';
import { ExportarParaPdfService } from '../../../relatorios/auditoria/compras-dinamico/services/ExportarParaPdf.service';
import styles from './Component.module.scss';

export function ListaItensOs({ headerFilter }) {
  const { values } = useSelector(selectFiltroOs);

  const onExporting = useCallback(
    async e => {
      if (e.format === 'xlsx') {
        await new ExportarParaExcelService().execute(
          e,
          'Relatório de Itens do Pedido',
          headerFilter.current.filtroString(),
          'Relatorio_Itens_Pedido_Comodato',
        );
      } else {
        await new ExportarParaPdfService().execute(
          e,
          'Relatório de Itens do Pedido',
          headerFilter.current.filtroString(),
          'Relatorio_Itens_Pedido_Comodato',
          [30, 80, 20]
        );
      }

      e.cancel = true;
    },
    [headerFilter],
  );

  return (
    <>
      <div className={`${styles.listaOs} content-wrapper`} style={{position: 'relative', top: '-65px'}}>
        <DataGrid
          rowAlternationEnabled={true}
          dataSource={(values || {}).itensPedido}
          noDataText="Sem dados"
          keyExpr="id"
          allowColumnReordering={true}
          remoteOperations={true}
          onExporting={onExporting}
          className={`${styles.middle}`}
        >
          <Column dataField="id" width={90} caption="EAN"></Column>
          <Column dataField="descricao" minWidth={200} caption={'Descrição'}></Column>
          <Column dataField="quantidade" caption={'Qtd'} format="##0.000" />
          <Column dataField="valorAtivo" caption={'Valor Ativo'} format="R$ #,##0.00" />
          <Column dataField="valorProduto" caption={'Valor Produto'} format="R$ #,##0.00" />
          <Column dataField="totalAtivos" caption={'Total Ativos'} format="R$ #,##0.00" />
          <Column dataField="totalProdutos" caption={'Total Produtos'} format="R$ #,##0.00" />
          <Summary>
            <TotalItem column="totalAtivos" displayFormat={'R$ {0}'} summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
            <TotalItem column="totalProdutos" displayFormat={'R$ {0}'} summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
          </Summary>
          <Export
            enabled={true}
            texts={{ exportAll: 'Exporte tudo para {0}', exportSelectedRows: 'Exporte a seleção para {0}', exportTo: 'Exporte para {0}' }}
            formats={['pdf', 'xlsx']}
            fileName={'Ralatório de Itens Pedido Comodato'}
          />
          <Paging defaultPageSize={20} defaultPageIndex={0} /> {/* Shows the second page */}
        </DataGrid>
      </div>
    </>
  );
}
