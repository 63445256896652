import { LoadPanel } from 'devextreme-react';
import Popup from 'devextreme-react/popup';
import { alert } from 'devextreme/ui/dialog';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ClienteService } from '../../../../../services/cliente/Cliente.service';
import { maskedCnpjCpf } from '../../../../../shared/utils/Utils';
import { CheckIE } from '../../../../../shared/utils/ValidadorIe/checkIe';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';

export function EditCliente({ onUpdated }) {
  const [popupVisible, setPopupVisible] = useState(false);
  const [messageLoadin, setMessageLoadin] = useState('');
  const [searching, setSearching] = useState(false);
  const { values } = useSelector(state => state.novaOs);
  const [cliente, setCliente] = useState(null);
  const [uf, setUf] = useState(null);
  const [nome, setNome] = useState('');
  const [ie, setIe] = useState('');
  const dispatch = useDispatch();
  const { changeValue } = useNovaOs();

  useEffect(() => {
    if (!popupVisible) return;
    if (!cliente && values.cliente) {
      setUf(values.cliente.endereco.unidadeFederativa.sigla);
      setCliente({ ...values.cliente });
      setNome(values.cliente.nome || '');
      setIe(values.cliente.inscricaoEstadual || '');
    }
  }, [popupVisible, cliente, values.cliente]);

  const show = useCallback(() => {
    if (popupVisible) return;
    setPopupVisible(true);
  }, [popupVisible]);
  const hide = useCallback(() => {
    if (!popupVisible) return;
    setPopupVisible(false);
  }, [popupVisible]);

  const onSave = useCallback(async () => {
    try {
      if (!CheckIE(ie, uf)) {
        alert(`Informe uma IE válida para o estado de ${uf}`, 'Atenção!');
        return;
      }
      if (!nome) {
        alert(`Informe uma nome válida para o cliente`, 'Atenção!');
        return;
      }
      if (nome.length < 3) {
        alert(`Informe uma nome com mais de 3 caracteres`, 'Atenção!');
        return;
      }
      if (/^[a-zA-Z0-9]+$/.test(nome)) {
        alert(`Informe uma nome com caracteres válidos sem simbolos`, 'Atenção!');
        return;
      }
      setMessageLoadin('Atualizando cliente');
      setSearching(true);
      const data = {
        id: cliente.id,
        nome,
        ie,
      };
      await ClienteService.getInstance().atualizar(data);
      var newCliente = {...values.cliente, nome, inscricaoEstadual: ie};

      dispatch(changeValue({ fieldName: 'cliente', value: newCliente }));
      setSearching(false);
      if (typeof onUpdated === 'function') {
        onUpdated(data);
      }
      alert('Cliente atualizado com sucesso', 'Sucesso!');
    } catch (e) {
      setSearching(false);
      alert('Erro ao atualizar cliente' + e.message, 'Erro!');
    }
  }, [cliente, ie, nome, onUpdated, uf, dispatch, changeValue, values]);

  return (
    <>
      <button className="btn btn-secondary" onClick={show} disabled={!values.cliente}>
        <i className="fa fa-edit"></i>
      </button>
      {cliente && (
        <Popup
          width="500px"
          height="500px"
          onHiding={hide}
          visible={popupVisible}
          title={`CLIENTE ${maskedCnpjCpf(cliente.cnpjCpf)}- ${cliente.nome}`}
          showTitle={true}
          hideOnOutsideClick={false}
        >
          <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={messageLoadin} showPane={true} />
          <Card style={{ display: 'flex', flexDirection: 'column', height: 'calc(500px - 90px)' }}>
            <Card.Header className="" style={{ height: '80px' }}>
              <h1>Atualize os dados do cliente e clique em salvar</h1>
            </Card.Header>
            <Card.Body className="mt-0 pt-0" style={{ flex: 1, overflowY: 'auto' }}>
              <Row className="mt-3">
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="nome">Nome</label>
                    <input
                      style={{ textTransform: 'uppercase' }}
                      maxLength={100}
                      type="text"
                      className="form-control"
                      id="nome"
                      value={nome}
                      onChange={e => {
                        setNome((e.target.value || '').toUpperCase());
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="p-0">
                <Col md={12}>
                  <div className="form-group">
                    <label htmlFor="ie">Inscrição Estadual (Deixar vazio para isento)</label>
                    <input
                      type="number"
                      maxLength={20}
                      className="form-control"
                      id="ie"
                      value={ie}
                      onChange={e => {
                        setIe(e.target.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="border" style={{ height: '70px' }}>
              <Row>
                <Col md={12}>
                  <div className="float-right">
                    <button className="btn btn-primary" onClick={onSave}>
                      Salvar
                    </button>
                    <button className="btn btn-secondary ml-3" onClick={hide}>
                      Cancelar
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </Popup>
      )}
    </>
  );
}
