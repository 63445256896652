export class PrintStyle {
  h1 = {
    fontFamily: 'Calibri, sans-serif',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '15pt',
    margin: '0',
  };

  h1Small = {
    fontFamily: 'Calibri, sans-serif',
    color: '#585858',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    fontSize: '15pt',
  };

  h1Strong = {
    fontFamily: 'Calibri, sans-serif',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16pt',
  };
  section = {
    margin: '0 auto',
  };
  divHeader = {
    width: '100%',
    padding: '5px',
    margin: '0',
    border: '1px solid #000',
  };

  h2 = {
    fontFamily: 'Calibri, sans-serif',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    fontSize: '14pt',
    margin: '0',
  };
  h3 = {
    fontFamily: 'Calibri, sans-serif',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: '13pt',
  };
  p = {
    fontFamily: 'Calibri, sans-serif',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    fontSize: '12pt',
    margin: '0',
    textAlign: 'justify',
    textJustify: 'inter-word',
  };
  tdP = {
    fontFamily: 'Calibri, sans-serif',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    fontSize: '14pt',
    margin: '0',
  };
  borderSolid = {
    border: '1px solid #000',
  };
  tableTh = {
    border: '1px solid #000',
    fontFamily: 'Calibri, sans-serif',
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 'normal',
    textDecoration: 'none',
    fontSize: '14pt',
    padding: '10px',
  };
  tableTd = {
    border: '0px solid #000',
    fontSize: '14pt',
  };
}
