import React, { useEffect } from 'react';
import _ from 'lodash';
import { formatStringToCurrency, maskedCnpjCpf } from '../../../../../shared/utils/Utils';
import { selectNovaOs, useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { useDispatch, useSelector } from 'react-redux';

import { PrintStyle } from './PrintStyles';
import { Row } from 'react-bootstrap';
import moment from 'moment';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';

export function PaginaContratoComodato({ styles }) {
  const [pedido, setPedido] = React.useState({});
  const { values } = useSelector(selectNovaOs);
  const empresa = useSelector(selectConsignadoComodatoSharedData).empresa;
  const { showContrato } = useNovaOs();
  const dispatch = useDispatch();

  const sty = new PrintStyle();

  useEffect(() => {
    if (!values.pedidos) return;
    const pedidoComodato = (values.pedidos || []).find(x => x.tipoPedido.id === 3);
    if (!pedidoComodato) {
      dispatch(showContrato(false));
      return;
    }
    setPedido(pedidoComodato);
  }, [dispatch, showContrato, values.pedidos]);

  const ns = item => {
    let str = (item.series || []).map(x => x.serie).join(', ');
    if (str.length > 0) str = ` (${str})`;
    return str || '';
  };
  return (
    <>
      {values.cliente && empresa && (
        <div id="impressaoContrato">
          <section className={`${styles['sheet']} padding-5mm`}>
            <h1 style={sty.h1}>
              <b>CONTRATO DE COMODATO</b>
            </h1>
            <div>
              <h2 style={sty.h2}>
                <b>N. FISCAL/COMODATO:</b> {(pedido.numeroNF || 0).toString().padStart(9, '0')} <b>VENCIMENTO:&nbsp;</b>{' '}
                {new Date(values.dataRecolhaDevolucao)
                  .getDate()
                  .toString()
                  .padStart(2, '0')}
                /{(new Date(values.dataRecolhaDevolucao).getMonth() + 1).toString().padStart(2, '0')}/{new Date(values.dataRecolhaDevolucao).getFullYear()}
              </h2>
            </div>
            <div>
              <p style={sty.p}>
                {empresa.razaoSocial}, estabelecida na Cidade de {empresa.endereco.localidade.descricao}, Estado de {empresa.endereco.unidadeFederativa.sigla}
                ,&nbsp; na {empresa.endereco.tipoLogradouro ? `${empresa.endereco.tipoLogradouro} ` : ''}
                {empresa.endereco.logradouro}, {empresa.endereco.numero}, inscrita no CNPJ sob o No, {maskedCnpjCpf(empresa.cnpj)},&nbsp; neste ato devidamente
                representada, na forma de seus Estatutos Sociais, doravante simplesmente denominada COMODANTE, e {values.cliente.nome},&nbsp; estabelecido na
                Cidade de {values.cliente.endereco.localidade.descricao} , Estado de {values.cliente.endereco.unidadeFederativa.sigla}, na&nbsp;
                {values.cliente.endereco.tipoLogradouro} {values.cliente.endereco.logradouro}, {values.cliente.endereco.numero}, no bairro&nbsp;
                {values.cliente.endereco.bairro}, inscrito no {values.cliente.pessoaJuridica === 0 ? 'CPF' : 'CNPJ'} {maskedCnpjCpf(values.cliente.cnpjCpf)} ,
                neste&nbsp; ato devidamente representada doravante simplesmente denominada COMODATARIO, ajustam entre si, através do presente
                instrumento,comodato de bens&nbsp; mediante as clausulas e condições adiante descritas:
              </p>
              <p style={sty.p}>
                <b>1{')'} Constitui objeto do presente</b> instrumento o empréstimo gratuito, por parte da COMODANTE a COMODATARIA, dos bens e materiais
                abaixo&nbsp; especificados, todos de sua propriedade, conforme as Notas Fiscais de Remessa para Comodato acima especificada, de emissão da
                COMODANTE:
              </p>
              <table className={`table ${styles.table}`}>
                <thead>
                  <tr>
                    <th>Cod</th>
                    <th>Descrição</th>
                    <th>UN</th>
                    <th className="text-right">Qtde.</th>
                    <th className="text-right">Valor Uni.</th>
                    <th className="text-right">Valor Total</th>
                  </tr>
                </thead>
                <tbody>
                  {(pedido.itens || []).map((it, idx) => {
                    const serieStr = ns(pedido.itens[idx]);
                    return (
                      <tr key={idx}>
                        <td style={sty.tableTd}>{it.produto.codigo}</td>
                        <td style={sty.tableTd}>
                          {it.produto.descricao}
                          {serieStr && <br />}
                          {serieStr}
                        </td>
                        <td style={sty.tableTd}>{it.produto.estoque.unidadeMedidaVenda.sigla}</td>
                        <td className="text-right" style={sty.tableTd}>
                          {it.quantidade}
                        </td>
                        <td className="text-right" style={sty.tableTd}>
                          {formatStringToCurrency(it.valorAtivo)}
                        </td>
                        <td className="text-right" style={sty.tableTd}>
                          {formatStringToCurrency(it.totalAtivos)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <th colSpan={5} className="text-right pr-3">
                      {' '}
                      {formatStringToCurrency(_.sumBy(pedido.itens, 'totalAtivos'))}
                    </th>
                  </tr>
                </tfoot>
              </table>
              <p style={sty.p}></p>
              <p style={sty.p}>
                <b>2) Constituem obrigações da COMODATÁRIA:</b> a) Manter os materiais ora comodatados em perfeito estado de conservação, utilização e&nbsp;
                higienização, assim como os recebeu, ressalvado o desgaste natural decorrente do uso a que se destinam, os quais não poderão ser
                estruturalmente&nbsp; modificados, gravados, locados, onerados ou alienados, seja a que titulo for, nem ter os seus números, plaquetas de
                identificação, cores e&nbsp; apresentações visuais alterados; b) Indenizar a COMODANTE, pelo valor de reposição dos bens ora comodatados na data
                da observância do evento, na&nbsp; ocorrência de fatores contribuintes para diminuição do valor venal dos bens, ou pelos danos a eles causados
                em função do mau uso, guarda ou&nbsp; conservação, ou ainda na hipótese de não devolução dos bens quando exigida nos termos deste
                instrumento,&nbsp;
                <b> sem prejuízo da multa prevista no item 4.2;</b> c) Não mudar e/ou instalar os bens ora comodatados em local diverso do seu endereço&nbsp;
                especificado no preâmbulo do presente sem a previa e expressa anuência da COMODANTE. Nas hipóteses de venda, transferência ou qualquer tipo
                de&nbsp; cessão total ou parcial do seu negocio, estabelecimento ou instalações, deverá a COMODATÁRIA consultar previamente a COMODANTE da sua
                intenção&nbsp; ou não na continuidade do presente comodato; d) Utilizar os bens ora comodatados única e exclusivamente para acomodação,
                conservação ou&nbsp; comercialização de produtos fornecidos pela COMODANTE.
              </p>
              <p style={sty.p}>
                3) <b>Observado o disposto</b> no item 2 e seguintes, a indenização por extravio perda, destruição ou qualquer outro fator que torne os&nbsp;
                equipamentos imprestáveis ao uso a que se destinam, corresponderá ao valor discriminado no item 1, sobre o qual incidirão juros moratórios de
                1%&nbsp; (um por cento) ao mês e correção monetária pelo INPC ou índice que o substituir a contar da data especificada no campo&nbsp;
                <b>VENCIMENTO, sem prejuízo da multa prevista no item 4.2.</b>
              </p>
              <p style={sty.p}>
                4) <b>O prazo de vigência do presente</b> contrato termina na data especificada no campo <b>VENCIMENTO</b>, com término independentemente
                de&nbsp; qualquer aviso, notificação ou interpelação. 4.1) Caso se estabeleça a avença por prazo indeterminado, qualquer das partes poderá
                resilir de&nbsp; pleno direito o presente instrumento, a qualquer tempo, mediante a comunicação prévia e expressa nesse sentido com no mínimo,
                10 (dez) dias de&nbsp; antecedência. 4.2) Observado o disposto no item 5 e seguintes, o atraso na restituição de qualquer um dos equipamentos
                acima especificados&nbsp; importa na aplicação, de pleno direito, de multa no valor de <b>R$ 100,00 (cem reais)</b> por dia de atraso,
                independentemente de perdas e danos&nbsp; se houver, até que ocorra a restituição do bem ou o pagamento da indenização prevista no item 3. 4.3)
                A restituição dos equipamentos deverá&nbsp; ocorrer no prazo estipulado no caput <b>independentemente do consumo do produto nele inserto.</b>{' '}
                4.4) Poderá a COMODATÁRIA, antes da&nbsp; restituição,
                <b>
                  esvaziar o vasilhame conforme lhe aprouver, não havendo qualquer responsabilidade pela COMODANTE pelo produto eventualmente não consumido.
                </b>
              </p>
              <p style={sty.p}>
                <b>5) A restituição</b> dos equipamentos, observado o prazo estipulado no item 4, caput, ocorrerá por meio de recolhimento realizado a cargo
                da&nbsp; COMODANTE no local estabelecido no preâmbulo e, caso a entrega tenha ocorrido no estabelecimento da COMODANTE, ficará a cargo da
                COMODATÁRIA a&nbsp; restituição neste local. 5.1) Se por duas tentativas não forem por qualquer motivo restituídos os equipamentos quando o
                recolhimento ficar a&nbsp; cargo da COMODANTE, incidirá de pleno direito a multa estipulada no item 4.2, <b>sem prejuízo dos itens 2</b> e{' '}
                <b>3.</b> 5.2) Se o COMODATÁRIA&nbsp; não restituir os equipamentos no prazo previsto no item 3, quando ficar a seu cargo a entrega nos termos
                do caput, incidirá de pleno direito a&nbsp; multa estipulada no item 4.2, <b>sem prejuízo dos itens 2</b> e <b>3.</b>
              </p>
              <p style={sty.p}>
                <b>6) Dar-se-á também</b> por rescindido de pleno direito este contrato, independente de qualquer aviso, notificação ou interpelação judicial
                ou&nbsp; extrajudicial, nas seguintes hipóteses, sem prejuízo das penalidades cabíveis e as aqui previstas,alem de eventuais perdas e danos
                causados: a)&nbsp; Inobservância, por qualquer uma das partes, de qualquer clausula ou disposição do presente instrumento; b) Caso a COMODATÁRIA
                venha a contrair&nbsp; débitos de qualquer natureza junto a COMODANTE; c) Caso a COMODATÁRIA venha a falir, requerer concordata, entrar em
                liquidação judicial ou&nbsp; extrajudicial,ou na ocorrência de legitimo protesto de titulo sacado contra a COMODATÁRIA.
              </p>
              <p style={sty.p}>
                <b>7) Em ocorrendo</b> qualquer uma das hipóteses de término, resilição ou rescisão do presente instrumento, deverá a COMODATÁRIA proceder
                a&nbsp; imediata devolução dos bens comodatados a COMODANTE, em perfeito estado de uso e conservação, no estabelecimento desta ultima
                especificado no&nbsp; preâmbulo do presente instrumento, <b>observado o disposto no item 5.1</b> e{' '}
                <b>sem prejuízo do disposto nos itens 2, 3</b> e <b>4.2.</b>
              </p>
              <p style={sty.p}>
                <b>8) A tolerância</b> das partes não significará renúncia, perdão, novação ou alteração do que foi aqui contratado.
              </p>
              <p style={sty.p}>
                <b>9) Declara a COMODATÁRIA:</b> a) ter recebido os equipamentos em perfeito estado de conservação; b) ter constatado o seu pleno e
                adequado&nbsp; funcionamento no momento da entrega; c)
                <b>
                  ter plena ciência do prazo de validade do produto expresso no vasilhame, não sendo responsabilidade da COMODANTE os efeitos do consumo
                  após&nbsp; tal data.
                </b>
                d) ter plena ciência de que os equipamentos serão entregues, quando a cargo da COMODANTE, no endereço indicado no preâmbulo,&nbsp;
                <b>
                  a sua pessoa ou a que, à sua ordem, apresentar-se para o recebimento, sem prejuízo de sua plena responsabilidade pelas obrigações dispostas
                  no&nbsp; presente contrato.
                </b>
              </p>
              <p style={sty.p}>
                <b>10) A constatação de danos</b>, avarias e alterações nos equipamentos, para fins do disposto no item 2 e seguintes, será realizada no
                momento&nbsp; da restituição em termo próprio.
              </p>
              <p style={sty.p}>
                <b>11) As partes elegem</b>, de comum acordo, o foro que integra a Comarca do Município onde se localiza a COMODANTE, como competente para&nbsp;
                dirimir quaisquer questões do presente contrato,ou da sua execução, com renuncia expressa a qualquer outro, por mais privilegiado que
                seja.E,&nbsp; por estarem assim, justas e contratadas, assinam as partes o presente contrato em 02 (duas)vias de igual teor e forma, na presença
                das 02(duas)&nbsp; testemunhas infra-assinadas, para que produzam os seus devidos e legais efeitos.
              </p>
              <br />
              <p style={{ marginTop: '10px', marginBottom: '25px' }}>
                {' '}
                {empresa.endereco.localidade.descricao}, {new Date(values.dataPedido).getDate()} de{' '}
                {moment(new Date(values.dataPedido))
                  .startOf('month')
                  .format('MMMM')}{' '}
                de {new Date(values.dataPedido).getFullYear()}
              </p>
              <br />
              <br />
              <Row>
                <div className="col col-4">
                  <hr />
                  <p style={{ marginBottom: '0px' }}>{empresa.razaoSocial} </p>
                  <p style={{ marginBottom: '0px' }}>{maskedCnpjCpf(empresa.cnpj)}</p>
                </div>
                <div className="col col-4">
                  <hr />
                  <p style={{ marginBottom: '0px' }}>{values.cliente.nome}</p>
                  <p style={{ marginBottom: '0px' }}>{maskedCnpjCpf(values.cliente.cnpjCpf)} </p>
                </div>
                <div className="co col-4">
                  <hr />
                  <p style={{ marginBottom: '0px' }}>À ORDEM</p>
                  <p style={{ marginBottom: '0px' }}>CPF:</p>
                </div>
              </Row>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
