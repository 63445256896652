import _ from 'lodash';
import valorFreteNaLocacao from '../totais/valorFreteNaLocacao';
import valorFreteNaVenda from '../totais/valorFreteNaVenda';
import valorFreteVendaDoConsignado from '../totais/valorFreteVendaDoConsignado';

export class RecalcularTotaisService {
  static instance = null;
  static getInstance() {
    if (!RecalcularTotaisService.instance) {
      RecalcularTotaisService.instance = new RecalcularTotaisService();
    }
    return RecalcularTotaisService.instance;
  }
  execute(values) {
    for (const pedido of values.pedidos) {
      pedido.totalProdutos = _.sumBy(pedido.itens, x => x.totalProdutos);
      pedido.totalAtivos = _.sumBy(pedido.itens, x => x.totalAtivos);
    }

    const total =
      Math.round(
        _.sumBy(
          values.pedidos.filter(x => [1, 6].includes(x.tipoPedido.id)),
          'totalProdutos',
        ) * 100,
      ) / 100;
    const outros =
      Math.round(
        (_.sumBy(
          (values.pedidos || []).filter(x => [2, 8].includes(x.tipoPedido.id)),
          x => (x.totalProdutos || 0) + (x.totalAtivos || 0),
        ) -
          _.sumBy(values.pedidos || [], x => ((x.tipoPedido || {}).id === 4 ? x.totalProdutos : 0))) *
          100,
      ) / 100;
    let totalRecebido = 0;
    let totalAReceber = 0;

    for (const pedido of values.pedidos) {
      const recebido = _.sumBy(
        (pedido.pagamentos || []).filter(x => x.vencimento !== 'A RECEBER'),
        'valorPagamento',
      );
      let aReceber = 0;
      if ([1, 8, 6, 2].includes(pedido.tipoPedido.id)) {
        if (pedido.tipoPedido.id === 2) {
          aReceber = 0;
        } else {
          aReceber = (pedido.totalProdutos || 0) + (pedido.totalAtivos || 0) - (pedido.desconto || 0) + (pedido.acrescimo || 0);
        }
        if (pedido.tipoPedido.id === 1) {
          aReceber += valorFreteNaVenda(values);
        }
        if (pedido.tipoPedido.id === 8) {
          aReceber += valorFreteNaLocacao(values);
        }
        if (pedido.tipoPedido.id === 6) {
          aReceber += valorFreteVendaDoConsignado(values);
        }
      }
      aReceber -= recebido;

      totalRecebido += recebido;
      totalAReceber += aReceber;
    }
    totalRecebido = Math.round(totalRecebido * 100) / 100;
    totalAReceber = Math.round(totalAReceber * 100) / 100;
    let acrescimo = _.sumBy(values.pedidos, 'acrescimo') || 0;
    let desconto = _.sumBy(values.pedidos, 'desconto') || 0;
    acrescimo = Math.round(acrescimo * 100) / 100;
    desconto = Math.round(desconto * 100) / 100;
    const totalGeral = Math.round((total + acrescimo - desconto + values.valorFrete) * 100) / 100;

    // Utilizando destructuring assignment para definir os valores
    values.total = total;
    values.acrescimo = acrescimo;
    values.desconto = desconto;
    values.totalGeral = totalGeral + outros;
    values.recebido = totalRecebido;
    values.aReceber = totalAReceber;
  }
}
