import React, { useState, useEffect } from 'react';
import DataGrid, { Scrolling, Grouping, SearchPanel, Paging, Pager, Column, Editing, NumericRule, Lookup } from 'devextreme-react/data-grid';
import { Button, NumberBox, SelectBox } from 'devextreme-react';
import Tooltip from 'rc-tooltip';
import { ajusteProdutosUtils } from './SetupFacilAjusteProdutosUtils';
import { exportExcelModelo } from '../../../../../services/Produto.service';

import styles from './SetupFacilAjusteProdutos.module.scss';

function ErrorMessage({ errors }) {
  if (errors.length <= 0) return;

  return (
    <>
      {errors.map((error, index) => {
        return (
          <div key={error.mensagem}>
            <span style={{ fontSize: '12px' }}>{`${index + 1} - ${error.mensagem}`}</span>
          </div>
        );
      })}
    </>
  );
}

export default function SetupFacilAjusteProdutosDataGrid({ dataSource, setores, unidadesMedida, validation, dataGridRef, simplesNacional, gridListas }) {
  const [autoExpandAll, setAutoExpandAll] = useState(true);
  const numericRuleText = 'Este campo aceita somente números';
  
  const columnEditorOptions = {
    codigo: {
      maxLength: 14,
    },
    descricao: {
      maxLength: 100,
    },
    descricaoResumida: {
      maxLength: 100,
    },
    marca: {
      maxLength: 20,
    },
    estruturaMercadologica: {
      maxLength: 65,
    },
    ncm: {
      maxLength: 8,
    },
    cest: {
      maxLength: 7,
    },
  };
  
  const headerCellTemplate = data => {
    return <div className={styles.gridColumnHeader}>{data.column.caption}</div>;
  };

  const comboEditorRender = cell => {
    return (
      <SelectBox
        searchEnabled={true}
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={e => {
          cell.setValue(e.value);
        }}
      />
    );
  };

  const numberEditorRender = cell => {
    const maxValue = ajusteProdutosUtils.getFieldMaxValue(cell.column.dataField, cell.key);

    return (
      <NumberBox
        defaultValue={cell.value}
        onValueChanged={e => {
          if (typeof e.value === 'undefined' || e.value === null) {
            cell.setValue(0);
          } else {
            cell.setValue(e.value);
          }
        }}
        {...(maxValue > 0 ? { max: maxValue } : {})}
        min={0}
      />
    );
  };

  // Validação: Pinta linha de vermelho
  const handleOnRowPrepared = e => {
    if (typeof e.key === 'undefined') return;
    if (typeof validation === 'undefined') return;

    if (validation.length > 0)
    {
        const produtoErro = validation.filter(produto => produto.id === e.key.id);

        if (produtoErro.length > 0) {
          e.rowElement.style.backgroundColor = '#f5b3a9';
          e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
        }
     }
  };

  // Validação: Mostra tooltip com mensagens de erro
  const handleCellTemplate = e => {
    const errors = ajusteProdutosUtils.getFieldErros(e.column.dataField, e.key.id, validation);
    const showWarning = errors.length > 0;

    return (
      <Tooltip
        placement="bottomLeft"
        overlay={() => {
          return <ErrorMessage errors={errors} />;
        }}
        {...(!showWarning ? { visible: false } : {})}
      >
        <span style={{ fontSize: '12px' }}>
          {showWarning && <i className="dx-icon dx-icon-warning" style={{ paddingRight: '5px', fontSize: '14px' }} />}
          {e.text}
        </span>
      </Tooltip>
    );
  };

  return (
    <div className={styles.dataGridResult}>
      <div className={styles.gridOptions}>
        <Button
          hint="Exportar para excel"
          className={styles.exportButton}
          text="EXPORTAR"
          onClick={() => exportExcelModelo(ajusteProdutosUtils.tratarProdutosPlanilha(dataGridRef.current.props.dataSource, gridListas, unidadesMedida)
                                            , gridListas, setores, unidadesMedida, simplesNacional)}
        />
        <Button
          hint="Expandir/Recolher agrupamentos"
          className={styles.expandButton}
          icon={autoExpandAll ? 'collapse' : 'expand'}
          onClick={() => setAutoExpandAll(!autoExpandAll)}
        />
      </div>

      <DataGrid
        dataSource={dataSource}
        ref={dataGridRef}
        hoverStateEnabled={true}
        showBorders={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={'widget'}
        columnMinWidth={80}
        columnAutoWidth={true}
        onRowPrepared={handleOnRowPrepared}>
        <Column dataField="estruturaMercadologicaDescricao" caption="Grupo" groupIndex={0} />
        <Column
          dataField="codigo"
          caption="Código"
          minWidth={120}
          allowEditing={false}
          editorOptions={columnEditorOptions.codigo}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="codigoRapido"
          caption="Código Rápido"
          minWidth={120}
          editorOptions={columnEditorOptions.codigo}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="descricao"
          caption="Descrição"
          minWidth={250}
          editorOptions={columnEditorOptions.descricao}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        ></Column>
        <Column
          dataField="descricaoResumida"
          caption="Descrição Resumida"
          minWidth={250}
          editorOptions={columnEditorOptions.descricaoResumida}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        ></Column>        
        <Column
          dataField="marca"
          caption="Marca"
          minWidth={250}
          editorOptions={columnEditorOptions.marca}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        ></Column>
        <Column
          dataField="tipo"
          caption="Tipo"
          minWidth={200}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={gridListas.tipo} displayExpr="label" valueExpr="label" />
        </Column>
        <Column
          dataField="subTipo"
          caption="SubTipo"
          minWidth={200}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={gridListas.subTipo} displayExpr="label" valueExpr="label" />
        </Column>
        <Column
          dataField="grupo"
          caption="Grupo"
          minWidth={200}
          editorOptions={columnEditorOptions.estruturaMercadologica}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
        </Column>
        <Column
          dataField="subGrupo"
          caption="SubGrupo"
          minWidth={200}
          editorOptions={columnEditorOptions.estruturaMercadologica}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
        </Column>
        <Column
          dataField="artigo"
          caption="Artigo"
          minWidth={200}
          editorOptions={columnEditorOptions.estruturaMercadologica}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
        </Column>
        <Column
          dataField="setor"
          caption="Setor"
          minWidth={200}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={setores} displayExpr="descricao" valueExpr="descricao" />
        </Column>
        <Column
          dataField="codigoNcm"
          caption="Código NCM"
          minWidth={100}
          editorOptions={columnEditorOptions.ncm}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="codigoCest"
          caption="Código CEST"
          minWidth={100}
          editorOptions={columnEditorOptions.cest}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="unidadeCompra"
          caption="Unidade de Compra"
          minWidth={150}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={unidadesMedida} displayExpr="descricao" valueExpr="sigla" />
        </Column>
        <Column
          dataField="unidadeVenda"
          caption="Unidade de Venda"
          minWidth={150}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={unidadesMedida} displayExpr="descricao" valueExpr="sigla" />
        </Column>
        <Column
          dataField="permiteVenda"
          caption="Permite Venda"
          minWidth={150}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={gridListas.boolean} displayExpr="label" valueExpr="value" />
        </Column>
        <Column
          dataField="controlaEstoque"
          caption="Controla Estoque"
          minWidth={150}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={gridListas.boolean} displayExpr="label" valueExpr="value" />
        </Column>
        <Column
          dataField="usaBalanca"
          caption="Usa Balança"
          minWidth={150}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={gridListas.boolean} displayExpr="label" valueExpr="value" />
        </Column>
        <Column
          dataField="fatorConversao"
          caption="Fator de Conversão"
          alignment="right"
          minWidth={120}
          format={ajusteProdutosUtils.getFormat('decimalScale_3')}
          headerCellRender={headerCellTemplate}
          editCellRender={numberEditorRender}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="precoVenda"
          caption="Preço de Venda R$"
          alignment="right"
          minWidth={120}
          format={ajusteProdutosUtils.getFormat('currency')}
          headerCellRender={headerCellTemplate}
          editCellRender={numberEditorRender}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="formaReajuste"
          caption="Forma de Reajuste"
          minWidth={150}
          headerCellRender={headerCellTemplate}
          editCellRender={comboEditorRender}
          cellRender={handleCellTemplate}
        >
          <Lookup dataSource={gridListas.formaReajuste} displayExpr="label" valueExpr="value" />
        </Column>
        <Column
          dataField="percentualReajuste"
          caption="Percentual de Reajuste"
          alignment="right"
          minWidth={150}
          format={ajusteProdutosUtils.getFormat('percent')}
          headerCellRender={headerCellTemplate}
          editCellRender={numberEditorRender}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="cstIcmsVenda"
          caption="Situação Tributária Venda - ICMS"
          alignment="right"
          width={200}
          format={ajusteProdutosUtils.getFormat('number')}
          headerCellRender={headerCellTemplate}
          cellRender={handleCellTemplate}
        >
        </Column>
        <Column
          dataField="aliquotaTributacaoIcms"
          caption="Alíquota Tributação - ICMS"
          alignment="right"
          minWidth={80}
          format={ajusteProdutosUtils.getFormat('percent')}
          headerCellRender={headerCellTemplate}
          editCellRender={numberEditorRender}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Column
          dataField="aliquotaEfeitvaIcms"
          caption="Alíquota Efetiva %"
          alignment="right"
          minWidth={80}
          format={ajusteProdutosUtils.getFormat('percent')}
          headerCellRender={headerCellTemplate}
          editCellRender={numberEditorRender}
          cellRender={handleCellTemplate}
        >
          <NumericRule message={numericRuleText} />
        </Column>
        <Editing mode="cell" allowUpdating={true} />
        <Grouping autoExpandAll={autoExpandAll} />
        <SearchPanel visible={true} highlightCaseSensitive={true} width={194} placeholder={'Pesquisar no resultado...'} />
        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
        <Paging defaultPageSize={15} />
        <Scrolling showScrollbar={'always'} columnRenderingMode="virtual" />
      </DataGrid>
    </div>
  );
}
