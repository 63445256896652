// create a toogle button react component

import React, { useCallback, useEffect, useState } from 'react';

import styles from './ToggleButton.module.scss';

export function ToggleButton({ className, onCheckChange, label, typeName, value = false, buttonClass = '' }) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = useCallback(
    event => {
      setChecked(!checked);
      if (onCheckChange) onCheckChange(!checked);
    },
    [checked, onCheckChange],
  );

  return (
    <button
      className={`${className} p-0 pl-1 pr-1 ${buttonClass ? styles['btn-fs-9'] : ''} ${styles.btn} ${checked ? styles[`btn-${typeName}`] : ''} ${
        checked ? styles['btn-active'] : ''
      }`}
      onClick={handleChange}
    >
      {label}
    </button>
  );
}
