import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'react-bootstrap';
import { useNovaOsItem } from '../../../../../redux/slices/consignadoComodato/novaOsItemSlice';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
function DropBoxTipoPedido({ onNextFocus }) {
  const { changeValue, changeBlur } = useNovaOsItem();
  const { values, tipos } = useSelector(state => state.novaOsItem);
  const parametro528 = useSelector(selectConsignadoComodatoSharedData).parametro528;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!values.tipoPedido) {
      dispatch(
        changeValue({
          fieldName: 'tipoPedido',
          value: { id: 1, nome: 'VENDA' },
        }),
      );
    }
  }, [dispatch, changeValue, values.tipoPedido]);

  const handlerTipos = useCallback(
    e => {
      let tps = [...tipos];
      if (!parametro528) {
        tps = tps.filter(x => x.id < 4);
      }
      const it = tps.find(x => x.id === parseInt(e.target.value));
      dispatch(changeValue({ fieldName: 'tipoPedido', value: it }));
    },
    [dispatch, changeValue, tipos, parametro528],
  );

  return (
    <>
      <Form.Label htmlFor="tipoPedido" className="text-primary">
        Tabela de Preço<span className="text-danger">*</span>
      </Form.Label>
      <select
        autoFocus={true}
        onKeyPress={e => {
          if (!onNextFocus) return;
          if (e.key === 'Enter' || e.code === 'Enter') onNextFocus();
        }}
        onBlur={e => dispatch(changeBlur({ fieldName: 'tipoPedido' }))}
        onChange={handlerTipos}
        value={((values || {}).tipoPedido || {}).id || '1'}
        id="tipoPedido"
        name="tipoPedido"
        className="form-control"
      >
        {(tipos || []).map((tipo, index) => {
          if (!parametro528 && tipo.id > 3) {
            return false;
          }
          return (
            <option key={index} value={tipo.id}>
              {tipo.nome}
            </option>
          );
        })}
      </select>
    </>
  );
}

export { DropBoxTipoPedido };
