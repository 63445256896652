import { CheckBox, LoadPanel, NumberBox } from 'devextreme-react';
import Popup from 'devextreme-react/popup';
import { alert } from 'devextreme/ui/dialog';
import * as _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectNovaOs, useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';
import { formatStringToCurrency } from '../../../../shared/utils/Utils';
import { ApiRetornarPedidoComodato } from '../usercases/apiRetornarPedidoComodato.service';

export function RetornoComodato({ pedido, onUpdated }) {
  const { values } = useSelector(selectNovaOs);
  const { changeValue } = useNovaOs();
  const dispatch = useDispatch();
  const [popupVisible, setPopupVisible] = useState(false);
  const [messageLoadin, setMessageLoadin] = useState('');
  const [searching, setSearching] = useState(false);
  const [itens, setItens] = useState([]);
  const [focusedElement, setFocusedElement] = useState(pedido.itens.map(item => ({ quantidade: item.quantidade })));

  useEffect(() => {
    if (itens.length > 0 && !popupVisible) {
      setItens([]);
    }
    if (!popupVisible) return;
    if (itens.length > 0) return;
    setItens(
      pedido.itens.map(item => {
        const todosItens = _.flatMap(
          values.pedidos.filter(pedido => pedido.tipoPedido.id === 5),
          pedido => pedido.itens,
        );
        const itensMesmoProduto = todosItens.filter(x => x.produto.id === item.produto.id && item.valorAtivo === x.valorAtivo);
        const todasAsSeriesDevolvidas = _.flatMap(itensMesmoProduto, x => x.series || []);
        const qantRet = _.sumBy(itensMesmoProduto, x => x.quantidade) || 0;
        const it = {
          ...item,
          codigoProduto: `${item.produto.codigo} - ${item.produto.descricao}`,
          totalProdutos: 0,
          quantidadePendente: item.quantidade - qantRet,
          quantidadeRetorno: (item.series || []).length ? 0 : item.quantidade - qantRet,
          valorAtivo: item.valorAtivo,
          quantidadeDevolvida: qantRet,
        };
        // Mapeie it.series para um novo array com as séries atualizadas
        const seriesAtualizadas = it.series.map(serie => {
          // Verifique se a série atual deve ser marcada como retornada
          const deveMarcarComoRetornada = todasAsSeriesDevolvidas.some(x => x.serie === serie.serie);

          // Se sim, retorne uma cópia atualizada da série, senão retorne a série original
          return deveMarcarComoRetornada ? { ...serie, retornada: true } : serie;
        });
        // Atualize o objeto it com o novo array de séries atualizadas
        const itAtualizado = {
          ...it,
          series: seriesAtualizadas,
        };
        return itAtualizado;
      }),
    );
  }, [itens, pedido.itens, values.pedidos, popupVisible]);

  const show = useCallback(
    e => {
      if (popupVisible) return;
      setPopupVisible(true);
    },
    [popupVisible],
  );
  const hide = useCallback(
    e => {
      if (!popupVisible) return;
      setPopupVisible(false);
    },
    [popupVisible],
  );

  const onFocusIn = useCallback(e => {
    setFocusedElement(e);
  }, []);

  const onInputChange = useCallback(
    e => {
      const idx = focusedElement.element.id.split('_')[1];
      const value = parseFloat(e || '0', 10);
      if (value < 0) return alert('Valor informado não pode ser menor que zero', 'Ops!');
      const data = [...itens];
      if (value > data[idx]['quantidadePendente']) return alert('Valor informado excede o valor do pedido original', 'Ops!');

      // data[idx]['quantidadePendente'] =
      //   data[idx]['quantidadeDevolvida'] > 0 ? data[idx]['totalAtivos'] - data[idx]['quantidadeDevolvida'] - value : data[idx]['totalAtivos'] - value;
      data[idx]['quantidadeRetorno'] = value;
      setItens(data);
    },
    [itens, focusedElement],
  );

  const retornarPedido = useCallback(
    async e => {
      try {
        setSearching(true);
        setMessageLoadin('Realizando o retorno do pedido...');

        const pedidoAnterioresRetornoComodato = values.pedidos.filter(pedido => pedido.tipoPedido.id === 5);

        if (pedidoAnterioresRetornoComodato) {
          const pedidoComodato = values.pedidos.find(pedido => pedido.tipoPedido.id === 3);

          const totalItensDevolvidos = pedidoAnterioresRetornoComodato.filter(pedido => pedido.itens).reduce((total, item) => total + item.quantidade, 0);

          const totalItensDevolvendo = itens.reduce((total, item) => total + item.quantidadeRetorno, 0);

          const totalItensComodatados = pedidoComodato.itens.reduce((total, item) => total + item.quantidade, 0);

          //valida se total devolvido é maior que o total de itens do consignado
          if (totalItensDevolvidos + totalItensDevolvendo > totalItensComodatados) {
            setSearching(false);
            return alert('Total de itens devolvidos é maior que o total de itens do consignado. Por favor, valide os retornos de consignados.', 'Retorno de Comodato');
          }
        }

        const envioNps =  sessionStorage.getItem('envioNps') == 'true' ? true : false;

        var data = {
          itens: itens.map((it, idx) => ({
            id: it.id,
            pedidoId: pedido.pedidoId,
            numeroItem: idx + 1,
            quantidade: it.quantidadeRetorno,
            quantidadeRetornada: it.quantidadeRetorno,
            valorAtivo: it.valorAtivo,
            valorProduto: 0,
            totalAtivos: Math.round(it.valorAtivo * it.quantidadeRetorno * 100) / 100,
            totalProdutos: 0,
            acrescimo: 0,
            desconto: 0,
            produto: it.produto,
            series: it.series,
            cancelado: false,
            quantidadeCancelada: 0,
            quantidadeTotal: it.quantidadeRetorno,
            dataCadastro: new Date().toISOString(),
            dataUltimaAtualizacao: new Date().toISOString(),
          })),
          tipoPedido: {
            id: 5,
          },
          totalProdutos: _.sumBy(itens, x => x.totalAtivos),
          pedidoId: pedido.pedidoId,
          nps: envioNps
        };

        const response = await new ApiRetornarPedidoComodato().execute(data);
        if (response) {
          dispatch(changeValue({ fieldName: 'pedidos', value: response.pedidos }));
        }

        setSearching(false);
        alert('Retorno de comodato realizado com sucesso!!', 'Retorno de Comodato');
        if (typeof onUpdated === 'function') {
          // onUpdated é uma função e pode ser chamada
          onUpdated();
        }
        setPopupVisible(false);
      } catch (error) {
        alert(error.message, 'Falha no retorno do pedido.');
        setSearching(false);
        if (typeof onUpdated === 'function') {
          // onUpdated é uma função e pode ser chamada
          onUpdated();
        }
        setPopupVisible(false);
      }
    },
    [pedido, itens, changeValue, dispatch, values.pedidos, onUpdated],
  );

  const formatQuantidade = produto => {
    if (!produto || !produto.estoque || !produto.estoque.unidadeMedidaVenda) {
      return '0';
    }

    if (produto.estoque.unidadeMedidaVenda.casasDecimais) {
      return `#,##0.${'0'.repeat(produto.estoque.unidadeMedidaVenda.casasDecimais)} ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
    }
    return `#,##0 ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
  };
  const formatarQuantidade = (qnt, produto) => {
    if (!qnt) qnt = 0;
    if (!produto || !produto.estoque || !produto.estoque.unidadeMedidaVenda) {
      return `${qnt.toString()} UN`;
    }

    if (produto.estoque.unidadeMedidaVenda.casasDecimais) {
      return `${qnt.toFixed(produto.estoque.unidadeMedidaVenda.casasDecimais)} ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
    }
    return `${qnt} ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
  };

  const onChange = useCallback(
    args => {
      const newItens = [...itens];
      newItens[args.idx] = {
        ...newItens[args.idx],
        series: newItens[args.idx].series.map((serie, idxSerie) => (idxSerie === args.idxSerie ? { ...serie, retornar: args.checked } : serie)),
      };
      newItens[args.idx] = {
        ...newItens[args.idx],
        quantidadeRetorno: newItens[args.idx].series.filter(x => x.retornar).length,
      };
      setItens([...newItens]);
    },
    [itens],
  );

  return (
    <>
      <button className="btn btn-primary ml-3" onClick={show}>
        Retornar Comodato
      </button>
      <Popup width="70vw" height="80vh" onHiding={hide} visible={popupVisible} title={`RETORNO ${pedido.tipoPedido.nome}`} showTitle={true} hideOnOutsideClick={false}>
        <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={messageLoadin} showPane={true} />
        <Card style={{ display: 'flex', flexDirection: 'column', height: 'calc(80vh - 90px)' }}>
          <Card.Header className="" style={{ height: '80px' }}>
            <h1>
              Selecione os itens que deseja retornar do pedido {pedido.numeroNF} - {(values.cliente || {}).nome}
            </h1>
          </Card.Header>
          <Card.Body className="mt-0 pt-0" style={{ flex: 1, overflowY: 'auto' }}>
            <ul>
              {itens.map((item, idx) => {
                return (
                  <li className="list-unstyled m-0 p-0" key={idx} style={{ borderBottom: '1px' }}>
                    <Row className="p-0 m-0">
                      <Col md={6} className="m-0 p-0">
                        <span>
                          <small>Código - Descricão do Produto:</small>
                        </span>
                        <p className="mt-0 pt-0 text-primary">
                          <b>{item.codigoProduto}</b>
                        </p>
                        {(item.series || []).length > 0 && (
                          <ul>
                            <li className="list-unstyled m-0 p-0">
                              <span>
                                <small>Numeros de séries:</small>
                              </span>
                            </li>
                            {(item.series || []).map((serie, idxSerie) => {
                              return (
                                <li key={`${idxSerie}_${idx}`} className="list-unstyled m-0 p-0">
                                  <div className="" style={{ width: '200px' }}>
                                    <span className="text-info" style={{ width: '100px', display: 'inline-block' }}>
                                      <small>
                                        <b>{serie.serie}</b>
                                      </small>
                                    </span>
                                    <span
                                      className={`${serie.retornar ? 'text-warning' : serie.retornada ? 'text-muted' : 'text-success'} mr-1`}
                                      style={{ width: '70px', display: 'inline-block' }}
                                    >
                                      <small>{serie.retornar ? 'RECOLHENDO' : serie.retornada ? 'RECOLHIDO' : 'NO CLIENTE'}</small>
                                    </span>

                                    <CheckBox
                                      defaultValue={true}
                                      disabled={serie.retornada}
                                      style={{ fonstSize: '5px !important' }}
                                      value={itens[idx].series[idxSerie].retornar || false}
                                      id={`serie_${idxSerie}_${idx}`}
                                      size="xs"
                                      onValueChanged={e => onChange({ checked: e.value, idxSerie, idx })}
                                    />
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </Col>
                      <Col md={6} className="d-flex justify-content-end">
                        <div className="align-self-end">
                          <div className="clearfix"></div>
                          <div className="mt-0 pt-0  text-right" style={{ width: '220px' }}>
                            <span className="text-right" style={{ width: '100px', display: 'inline-block' }}>
                              <small>Recolher:</small>
                            </span>
                            <NumberBox
                              disabled={item.quantidadePendente === 0 || item.series.length > 0}
                              className="curreny mb-1 float-right"
                              format={formatQuantidade(pedido.itens[idx].produto)}
                              style={{ borderTop: '0px', borderLeft: '0px', borderRight: '0px', position: 'relative', top: '-5px' }}
                              width={100}
                              valueChangeEvent="keyup"
                              onValueChange={onInputChange}
                              onFocusIn={onFocusIn}
                              defaultValue={0}
                              id={`quantidade_${idx}`}
                              value={item.quantidadeRetorno}
                            ></NumberBox>
                          </div>
                          <div className="clearfix"></div>
                          <div className="mt-0 pt-0  text-right" style={{ width: '220px' }}>
                            <span className="">
                              <small>TOTAL:</small>
                            </span>
                            <span className="text-right" style={{ width: '60px', display: 'inline-block' }}>
                              <small>{formatarQuantidade(item.quantidade, item.produto)}</small>
                            </span>
                            <span style={{ width: '80px', display: 'inline-block' }} className="text-right">
                              <b>
                                <small>{formatStringToCurrency(item.totalAtivos)}</small>
                              </b>
                            </span>
                          </div>
                          <div className="clearfix"></div>
                          <div className="mt-0 pt-0  text-right" style={{ width: '220px' }}>
                            <span className="text-muted">
                              <small>RECOLHIDO:</small>
                            </span>
                            <span style={{ width: '60px', display: 'inline-block' }} className="text-right">
                              <small>{formatarQuantidade(item.quantidadeDevolvida || 0, item.produto)}</small>
                            </span>
                            <span style={{ width: '80px', display: 'inline-block' }} className="text-right">
                              <small>
                                <b>{formatStringToCurrency((item.quantidadeDevolvida || 0) * item.valorAtivo)}</b>
                              </small>
                            </span>
                          </div>
                          <div className="mt-0 pt-0  text-right" style={{ width: '220px' }}>
                            <span className="text-warning">
                              <small>RECOLHENDO:</small>
                            </span>
                            <span style={{ width: '60px', display: 'inline-block' }} className="text-right">
                              <small>{formatarQuantidade(item.quantidadeRetorno, item.produto)}</small>
                            </span>
                            <span style={{ width: '80px', display: 'inline-block' }} className="text-right">
                              <small>
                                <b>{formatStringToCurrency(item.quantidadeRetorno * item.valorAtivo)}</b>
                              </small>
                            </span>
                          </div>
                          <div className="clearfix"></div>
                          <div className="mt-0 pt-0  text-right" style={{ width: '220px' }}>
                            <span className="text-success">
                              <small>PENDENTE:</small>
                            </span>
                            <span style={{ width: '60px', display: 'inline-block' }} className="text-right">
                              <small>{formatarQuantidade(item.quantidadePendente, item.produto)}</small>
                            </span>
                            <span style={{ width: '80px', display: 'inline-block' }} className="text-right">
                              <b>
                                <small>{formatStringToCurrency(item.quantidadePendente * item.valorAtivo - item.quantidadeRetorno * item.valorAtivo)}</small>
                              </b>
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <hr></hr>
                  </li>
                );
              })}
            </ul>
          </Card.Body>
          <Card.Footer className="border" style={{ height: '70px' }}>
            <Row>
              <Col md={12}>
                <div className="float-right">
                  <button className="btn btn-primary" onClick={retornarPedido}>
                    Retornar
                  </button>
                  <button className="btn btn-secondary ml-3" onClick={hide}>
                    Cancelar
                  </button>
                </div>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Popup>
    </>
  );
}
