import React, { useState, useEffect } from 'react';
import DragAndDropFiles from '../../../../../components/DragAndDropFiles/DragAndDropFiles';
import { importCsv } from '../../../../../services/Produto.service';
import { LoadPanel } from 'devextreme-react';

import styles from './SetupFacilSelecaoDados.module.scss';

export default function SetupFacilSelecaoDadosPlanilha({ props }) {
  const [file, setFile] = useState([]);
  const [isGetFileData, setIsGetFileData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const acceptedFilesTypes = [
    'text/comma-separated-values',
    'text/csv',
    'application/csv',
    'application/vnd.ms-excel'
  ];

  useEffect(() => {
    props.handleNextStep(() => () => setIsGetFileData(true));
    props.awaitIsCompletedStepUpdate(true);
    props.messageStepNotCompletedUpdate(
      'Adicione um arquivo válido para prosseguir.'
    );
  }, []);

  useEffect(() => {

    if (isGetFileData) {
      getDadosPlanilha();
      setIsGetFileData(false);
    }

    async function getDadosPlanilha() {
      setIsLoading(true);
      
      if (file.length > 0) {

        const dados = await importCsv(file);
  
        let textAlert = '';
        if (typeof dados.result.listaSucess !== 'undefined' && dados.result.listaSucess.length > 0) {
          textAlert = 'Produtos Cadastrados com Sucesso!\n';
        }

        if (typeof dados.result.listaError !== 'undefined' && dados.result.listaError.length > 0) {
          props.dadosUpdate(prevDados => {
            return {
              ...prevDados,
              selecaoDados: dados.result.listaError
            };
          });
          props.isCompletedStepUpdate(true);
        } 

        if (textAlert !== '')  props.showAlertUpdate(textAlert);

      } else {
        props.showAlertUpdate();
      }

      setIsLoading(false);
    }

  }, [isGetFileData]);

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={isLoading}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div className={styles.planilha}>
        <h3>
          Selecione o arquivo com a planilha template preenchida para o upload
        </h3>

        <DragAndDropFiles
          dragAndDropText="Arraste e solte aqui a planilha"
          buttonText="SELECIONE O ARQUIVO PARA UPLOAD"
          acceptedFilesTypesText="O arquivo deve estar no formato .csv"
          acceptedFilesTypes={acceptedFilesTypes}
          maxFiles={1}
          setFiles={setFile}
        />
      </div>
    </>
  );
}
