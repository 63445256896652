import React, { useCallback, useEffect } from 'react';
import { selectFiltroOs, useFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButton } from './ToggleButton/ToggleButton';

export function CheckboxStatus({ typeName, className, label, statusId }) {
  const { changeValue } = useFiltroOs();
  const { values } = useSelector(selectFiltroOs);
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (!values.status) return;
    if (values.status.find(x => x === statusId)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [statusId, values.status]);

  const onChange = useCallback(
    e => {
      var status = values.status ? [...values.status] : [];
      if (e) {
        const idx = status.indexOf(statusId);
        if (idx === -1) {
          status.push(statusId);
        }
      } else {
        const idx = status.indexOf(statusId);
        if (idx !== -1) {
          status.splice(idx, 1);
        }
      }
      setChecked(e);
      dispatch(changeValue({ fieldName: 'status', value: status }));
    },
    [dispatch, changeValue, statusId, values.status],
  );
  return (
    // <div className={className}>
    //   <input type="checkbox" checked={checked} onChange={onChange} />
    //   <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    // </div>
    <ToggleButton className={className} value={checked} typeName={typeName} label={`${label}`} onCheckChange={onChange} currentCheckedStatus={checked}></ToggleButton>
    // <button className={`${className} btn ${styles[`bg-${statusCss.replace('color-', '')}-${'unchecked'}`]}`} onClick={onChange} checked={checked}>
    //   <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    // </button>
  );
}
