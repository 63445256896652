import React from 'react';
import Iframe from 'react-iframe';

import styles from './SellerPopFrame.module.scss';

const sellerPopFrame = (props) => (
    <Iframe
        url={props.url}
        width="100%"
        height="100%"
        className={styles.SellerPopFrame}
        display="initial"
  />
);

export default sellerPopFrame;
