import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import Form, { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react/load-indicator";

import { validaCPF, validaCNPJ, maskedCnpjCpf } from "../../../../../../shared/utils/Utils";
import { CustomButton } from "../../../SharedComponents";
import { ContaDigitalIntegradaService } from "../../../ContaDigitalIntegrada.service";
import { TransferenciasService } from "../Transferencias.service";

export const Externas = forwardRef(({
  onSuccess,
}, ref) => {
  const [isLoading, setIsLoading] = useState(true);
  const [bankList, setBankList] = useState([]);
  const [step, setStep] = useState(0);
  const [documentType, setDocumentType] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const [formData, setFormData] = useState({
    walletType: "",
    bank: "",
    agency: "",
    wallet: "",
    valueTranster: 0,
    transferDate: new Date(),
    documentType: "",
    owner: "",
    document: "",
  });

  const formRef = useRef(null);

  const cpfValidationRule = {
    type: "custom",
    validationCallback: (e) => {
      return validaCPF(e.value);
    },
    message: "CPF inválido"
  };

  const cnpjValidationRule = {
    type: "custom",
    validationCallback: (e) => {
      return validaCNPJ(e.value);
    },
    message: "CNPJ inválido"
  };

  const getButtonTitle = () => step === 0 ? "Confirmar transferência" : "Realizar transferência";

  const formatDate = (date) => {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    return `${day}/${month}/${year}`;
  }

  const formatTransferDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  }

  const handleSubmit = async () => {
    if (step === 0) {
      const formInstance = formRef.current.instance;
      const validationResult = formInstance.validate();

      if (validationResult.isValid) {
        setFormData(formInstance.option("formData"));
        setStep(1);
      }
    } else if (step === 1) {
      setDisabled(true);
      notify("Realizando a transferência. Aguarde...", "info", 2000);

      const updatedFormData = {
        ...formData,
        transferDate: formatTransferDate(formData.transferDate)
      };

      const transfer = await TransferenciasService.postExternalTransfer(updatedFormData);
      setDisabled(false);

      if (transfer) {

        if (transfer.notifications && Array.isArray(transfer.notifications) && transfer.notifications.length > 0) {
          notify(transfer.notifications[0].message, "error", 5000);
        } else {

        if(transfer.result){
          const { status } = transfer.result;

          notify("Para concluir a operação, aprove a solicitação no app Aprovador Linx.", "success", 2000);
          setFormData({
            walletType: "",
            bank: "",
            agency: "",
            wallet: "",
            valueTranster: 0,
            transferDate: new Date(),
            documentType: "",
            owner: "",
            document: "",
          });

          if (onSuccess) {

            var campoSaldo = document.getElementById('saldoContaDigital');

            if (campoSaldo) {
              const response = await ContaDigitalIntegradaService.getBalance();

              campoSaldo.textContent = response.result;
            }

            onSuccess();
          }

        }else{
          notify("Não foi possível criar a transferência externa", "error", 5000);
        }

        }

      } else {
        notify(transfer.message, "error", 5000);
      }
    }
  };

  const goBack = () => {
    setStep(0);
  };

  const getTypes = () => {
    return [
      {
        label: "Conta Corrente",
        value: "CC"
      },
      {
        label: "Conta de Depósito",
        value: "CD"
      },
      {
        label: "Conta Garantida",
        value: "CG"
      },
      {
        label: "Conta de Investimento",
        value: "CI"
      },
      {
        label: "Conta de Pagamento",
        value: "PG"
      },
      {
        label: "Poupança",
        value: "PP"
      },
    ];
  }

  const getDocumentType = () => {
    return [
      {
        label: "Pessoa física",
        value: "cpf"
      },
      {
        label: "Pessoa jurídica",
        value: "cnpj"
      },
    ]
  }

  const onValueChange = (e) => {
    setDocumentType(e.value);
  }

  const fetchBankData = async () => {
    const banks = await TransferenciasService.getBanks();

    if (banks && banks.result) {
      setBankList(banks.result);
    } else {
      notify(banks.message, "error", 5000);

      if (onSuccess) {
        onSuccess();
      }
    }

    setIsLoading(false);
  }


  useImperativeHandle(ref, () => ({
    resetStep() {
      setFormData({
        walletType: "",
        bank: "",
        agency: "",
        wallet: "",
        valueTranster: 0,
        transferDate: new Date(),
        documentType: "",
        owner: "",
        document: "",
      });

      setDocumentType(null);
      setStep(0);
    }
  }));

  useEffect(() => {
    fetchBankData();

  }, []);

  return (
    <>
      {!isLoading ?
        <div className="d-flex flex-column justify-content-between h-100">
          {step === 0 &&
            <Form ref={formRef} formData={formData}>
              <SimpleItem
                dataField="walletType"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: getTypes(),
                  displayExpr: "label",
                  valueExpr: "value",
                  searchEnabled: true // Enable search functionality
                }}
                label={{ text: "Tipo de conta" }}
                isRequired
              />

              <SimpleItem
                dataField="bank"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: bankList,
                  displayExpr: "name",
                  valueExpr: "code",
                  searchEnabled: true // Enable search functionality
                }}
                label={{ text: "Banco" }}
                isRequired
              />

              <SimpleItem dataField="agency" editorType="dxTextBox" label={{ text: "Agência" }} isRequired />
              <SimpleItem dataField="wallet" editorType="dxTextBox" label={{ text: "Conta" }} isRequired />

              <SimpleItem
                dataField="valueTranster"
                editorType="dxNumberBox"
                editorOptions={{ format: "R$ #,##0.00", showSpinButtons: false, min: 0 }}
                label={{ text: "Valor da transferência" }}
                isRequired
              />
              <SimpleItem
                dataField="transferDate"
                editorType="dxDateBox"
                editorOptions={{ defaultValue: new Date(), type: "date", displayFormat: "dd/MM/yyyy" }}
                label={{ text: "Data da Transferência" }}
              />
              <SimpleItem
                dataField="documentType"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: getDocumentType(),
                  displayExpr: "label",
                  valueExpr: "value",
                  onValueChanged: onValueChange,
                  searchEnabled: true // Enable search functionality
                }}
                label={{ text: "Tipo de documento" }}
                isRequired
              />
              <SimpleItem dataField="owner" editorType="dxTextBox" label={{ text: "Nome destino" }} isRequired />
              {documentType === "cpf" &&
                <SimpleItem
                  dataField="document"
                  editorType="dxTextBox"
                  editorOptions={{
                    mask: "999.999.999-99",
                    maskRules: { "9": /[0-9]/ },
                    showMaskMode: "onFocus"
                  }}
                  label={{ text: "CPF" }}
                  isRequired
                  validationRules={[cpfValidationRule]}
                />
              }
              {documentType === "cnpj" &&
                <SimpleItem
                  dataField="document"
                  editorType="dxTextBox"
                  editorOptions={{
                    mask: "99.999.999/9999-99",
                    maskRules: { "9": /[0-9]/ },
                    showMaskMode: "onFocus"
                  }}
                  label={{ text: "CNPJ" }}
                  isRequired
                  validationRules={[cnpjValidationRule]}
                />
              }
            </Form>
          }
          {step === 1 &&
            <div className="card mb-2">
              <div className="card-body">
                <h3>Revise os dados da transferência</h3>
                <p>Tipo de Conta: <strong>{formData.walletType}</strong></p>
                <p>Banco: <strong>{formData.bank}</strong></p>
                <p>Agência: <strong>{formData.agency}</strong></p>
                <p>Conta: <strong>{formData.wallet}</strong></p>
                <p>Valor da transferência: <strong>R$ {formData.valueTranster.toFixed(2).replace(".", ",")}</strong></p>
                <p>Data da Transferência: <strong>{formatDate(formData.transferDate)}</strong></p>
                <h3>Informações do dono da conta destino</h3>
                <p>Nome destinatário: <strong>{formData.owner}</strong></p>
                <p className="mb-0">{formData.documentType.toLocaleUpperCase()} destinatário: <strong>{maskedCnpjCpf(formData.document)}</strong></p>
              </div>
            </div>
          }
          <div className="d-flex justify-content-between">
            {step === 1 &&
              <CustomButton
                variant="outline-primary"
                icon="chevronprev"
                iconPosition="left"
                onClick={goBack}
                disabled={disabled}
              >
                Voltar
              </CustomButton>
            }
            <CustomButton
              className="ml-auto"
              icon="chevronnext"
              iconPosition="right"
              duplicateIcon={true} onClick={handleSubmit}
              disabled={disabled}
            >
              {getButtonTitle()}
            </CustomButton>
          </div>
        </div>
        :
        <div className="d-flex justify-content-center align-items-center h-100">
          <LoadIndicator id="large-indicator" height={60} width={60} />
        </div>
      }
    </>
  );
});
