import React from 'react';
import SetupFacilSelecaoDadosBase from './SetupFacilSelecaoDadosBase';
import SetupFacilSelecaoDadosPlanilha from './SetupFacilSelecaoDadosPlanilha';
import SetupFacilSelecaoDadosCargaRapida from './SetupFacilSelecaoDadosCargaRapida';

export default function SetupFacilSelecaoDados({ ...props }) {
  if (props.dados.fonteDados === 1) {
    return <SetupFacilSelecaoDadosBase props={props} />;
  } else if (props.dados.fonteDados === 2) {
    return <SetupFacilSelecaoDadosPlanilha props={props} />;
  } else {
    return <SetupFacilSelecaoDadosCargaRapida props={props} />;
  }
}
