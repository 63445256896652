import { ApiAbrirTurnoService } from './ApiAbrirTurno.service';
import { ApiFecharTurnoService } from './ApiFecharTurno.service';
import { ApiConfereTurnoPedidoService } from './ApiConfereTurno.service';
import { ApiConfereTurnoPedidoFacilService } from './ApiConfereTurnoFacil.service';
import { ApiReabreTurnoPedidoService } from './ApiReabreTurno.service';
import { ApiRecuperarTurnoAtualService } from './ApiRecuperarTurnoAtual.service';
import { ApiRetornaTurnoPedidoCompletoService } from './ApiRetornaTurnoPedidoCompleto.service';
import { ApiTurnoFiltroService } from './ApiTurnoFiltro.service';
import { ApiTurnoPedidoSalvaValoresService } from './ApiTurnoPedidoSalvaValores.service';
import { ApiAdicionarSangriaService } from './ApiAdicionarSangria.service';
import { ApiRecuperarSangriasService } from './ApiRecuperarSangrias.service';
import { ApiAdicionarSuprimentoService } from './ApiAdicionarSuprimento.service';
import { ApiRecuperarSuprimentosService } from './ApiRecuperarSuprimento.service';

/**
 * Um serviço para gerenciar turnos de pedidos.
 */
export class TurnoPedidoService {
  /**
   * Cria uma nova instância da classe TurnoPedidoService.
   * @returns {TurnoPedidoService} A nova instância da classe TurnoPedidoService.
   */
  static instance = null;
  /**
   * retorna um instância da classe TurnoPedidoService.
   * @returns {TurnoPedidoService} A nova instância da classe TurnoPedidoService.
   */
  static getInstance() {
    if (!TurnoPedidoService.instance) {
      TurnoPedidoService.instance = new TurnoPedidoService();
    }
    return TurnoPedidoService.instance;
  }
  /**
   * O serviço da API para filtrar turnos.
   * @type {ApiTurnoFiltroService}
   */
  apiTurnoFiltroService = new ApiTurnoFiltroService();
  apiRetornaTurnoPedidoCompletoService = new ApiRetornaTurnoPedidoCompletoService();
  apiConfereTurnoPedidoFacilService = new ApiConfereTurnoPedidoFacilService();
  apiConfereTurnoPedidoService = new ApiConfereTurnoPedidoService();
  apiTurnoPedidoSalvaValoresService = new ApiTurnoPedidoSalvaValoresService();
  apiReabreTurnoPedidoService = new ApiReabreTurnoPedidoService();
  apiAbrirTurnoService = new ApiAbrirTurnoService();
  apiFecharTurnoService = new ApiFecharTurnoService();
  apiRecuperarTurnoAtualService = new ApiRecuperarTurnoAtualService();
  apiAdicionarSangriaService = new ApiAdicionarSangriaService();
  apiAdicionarSuprimentoService = new ApiAdicionarSuprimentoService();
  apiRecuperarSangriasService = new ApiRecuperarSangriasService();
  apiRecuperarSuprimentosService = new ApiRecuperarSuprimentosService();

  /**
   * Recupera uma lista de pedidos de turno que correspondem aos critérios especificados.
   * @param {string} dtDe A data de início da pesquisa.
   * @param {string} dtAte A data de término da pesquisa.
   * @param {string} op O operador para a pesquisa.
   * @param {string} situacao A situação para a pesquisa.
   * @param {string} maquina A máquina para a pesquisa.
   * @param {string} pedido O número do Pedido para a pesquisa.
   * @param {boolean} useCache Se deve usar dados em cache, se disponíveis.
   * @returns {Promise<Array>} Uma promessa que resolve para uma matriz de pedidos de turno.
   */
  listar = async (dtDe, dtAte, op, situacao, pedido, useCache = false) => {
    return await this.apiTurnoFiltroService.execute(dtDe, dtAte, op, situacao, pedido, useCache);
  };

  /**
   * Conferir um turno de pedidos.
   * @param {{
  *   turnoId: number,
  *   codigoEstabelecimento: number,
  *   codigoOperador: number,
  *   nomeOperador: string,
  *   dataAbertura: Date,
  *   dataFechamento: Date?,
  *   turnoConferido: boolean,
  *   codigoConferente: number?,
  *   nomeConferente: string,
  *   codigoFormaPagamento: number,
  *   descricaoFormaPagamento: string,
  *   valorRecebido: number,
  *   valorSuprido: number,
  *   valorSangrado: number,
  *   valorConferido: number,
  * }[]} valores Os valores a serem salvos para o turno de pedidos.
  * @returns {Promise<{
  *   turnoId: number,
  *   codigoEstabelecimento: number,
  *   codigoOperador: number,
  *   nomeOperador: string,
  *   dataAbertura: Date,
  *   dataFechamento: Date?,
  *   turnoConferido: boolean,
  *   codigoConferente: number?,
  *   nomeConferente: string,
  *   codigoFormaPagamento: number,
  *   descricaoFormaPagamento: string,
  *   valorRecebido: number,
  *   valorSuprido: number,
  *   valorSangrado: number,
  *   valorConferido: number,
  *   pagamentos: Array<{
  *     turnoId: long,
  *     codigoEstabelecimento: short,
  *     codigoFormaPagamento: short,
  *     descricaoFormaPagamento: string,
  *     numeroPedido: long,
  *     valor: decimal,
  *     dataHora: DateTime,
  *     codigoRedeCartao: short,
  *     descricaoRedeCartao: string,
  *     codigoProdutoCartao: short,
  *     descricaoProdutoCartao: string,
  *     codigoBandeiraCartao: short,
  *     descricaoBandeiraCartao: string,
  *     nsuCartao: string,
  *   }>,
  *   sangrias: Array<{
  *     sangriaId: long,
  *     turnoId: long,
  *     codigoFormaPagamento: short,
  *     descricaoFormaPagamento: string,
  *     valorSangrado: decimal,
  *     dataSangria: DateTime,
  *     observacao: string,
  *   }>,
  *   suprimentos: Array<{
  *     suprimentoId: long,
  *     turnoId: long,
  *     codigoFormaPagamento: short,
  *     descricaoFormaPagamento: string,
  *     valorSuprido: decimal,
  *     dataSuprimento: DateTime,
  *     observacao: string,
  *   }>
  * }>}
   */
  confereTurnoFacil = async valores => {
    await this.apiConfereTurnoPedidoFacilService.execute(valores);
    return await this.recuperarTurnoPedidoCompleto(valores[0].turnoId, false);
  };
  /**
   * Conferir um turno de pedidos.
   * @param {{
  *   turnoId: number,
  *   codigoEstabelecimento: number,
  *   codigoOperador: number,
  *   nomeOperador: string,
  *   dataAbertura: Date,
  *   dataFechamento: Date?,
  *   turnoConferido: boolean,
  *   codigoConferente: number?,
  *   nomeConferente: string,
  *   codigoFormaPagamento: number,
  *   descricaoFormaPagamento: string,
  *   valorRecebido: number,
  *   valorSuprido: number,
  *   valorSangrado: number,
  *   valorConferido: number,
  * }[]} valores Os valores a serem salvos para o turno de pedidos.
  * @returns {Promise<{
  *   turnoId: number,
  *   codigoEstabelecimento: number,
  *   codigoOperador: number,
  *   nomeOperador: string,
  *   dataAbertura: Date,
  *   dataFechamento: Date?,
  *   turnoConferido: boolean,
  *   codigoConferente: number?,
  *   nomeConferente: string,
  *   codigoFormaPagamento: number,
  *   descricaoFormaPagamento: string,
  *   valorRecebido: number,
  *   valorSuprido: number,
  *   valorSangrado: number,
  *   valorConferido: number,
  *   pagamentos: Array<{
  *     turnoId: long,
  *     codigoEstabelecimento: short,
  *     codigoFormaPagamento: short,
  *     descricaoFormaPagamento: string,
  *     numeroPedido: long,
  *     valor: decimal,
  *     dataHora: DateTime,
  *     codigoRedeCartao: short,
  *     descricaoRedeCartao: string,
  *     codigoProdutoCartao: short,
  *     descricaoProdutoCartao: string,
  *     codigoBandeiraCartao: short,
  *     descricaoBandeiraCartao: string,
  *     nsuCartao: string,
  *   }>,
  *   sangrias: Array<{
  *     sangriaId: long,
  *     turnoId: long,
  *     codigoFormaPagamento: short,
  *     descricaoFormaPagamento: string,
  *     valorSangrado: decimal,
  *     dataSangria: DateTime,
  *     observacao: string,
  *   }>,
  *   suprimentos: Array<{
  *     suprimentoId: long,
  *     turnoId: long,
  *     codigoFormaPagamento: short,
  *     descricaoFormaPagamento: string,
  *     valorSuprido: decimal,
  *     dataSuprimento: DateTime,
  *     observacao: string,
  *   }>
  * }>}
   */
  confereTurno = async valores => {
    await this.apiConfereTurnoPedidoService.execute(valores);
    return await this.recuperarTurnoPedidoCompleto(valores[0].turnoId, false);
  };

  /**
   * Recupera um turno de pedidos completo usando o ID do turno especificado.
   * @param {string} turnoId O ID do turno a ser recuperado.
   * @param {boolean} useCache Se deve usar dados em cache, se disponíveis.
   * @returns {Promise<{
   *   turnoId: number,
   *   codigoEstabelecimento: number,
   *   codigoOperador: number,
   *   nomeOperador: string,
   *   dataAbertura: Date,
   *   dataFechamento: Date?,
   *   turnoConferido: boolean,
   *   codigoConferente: number?,
   *   nomeConferente: string,
   *   codigoFormaPagamento: number,
   *   descricaoFormaPagamento: string,
   *   valorRecebido: number,
   *   valorSuprido: number,
   *   valorSangrado: number,
   *   valorConferido: number,
   *   valores: Array<{
   *  turnoId: number,
   *   codigoEstabelecimento: number,
   *   codigoOperador: number,
   *   nomeOperador: string,
   *   dataAbertura: Date,
   *   dataFechamento: Date?,
   *   turnoConferido: boolean,
   *   codigoConferente: number?,
   *   nomeConferente: string,
   *   codigoFormaPagamento: number,
   *   descricaoFormaPagamento: string,
   *   valorRecebido: number,
   *   valorSuprido: number,
   *   valorSangrado: number,
   *   valorConferido: number,
   *   }>
   *   pagamentos: Array<{
   *     turnoId: long,
   *     codigoEstabelecimento: short,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     numeroPedido: long,
   *     valor: decimal,
   *     dataHora: DateTime,
   *     codigoRedeCartao: short,
   *     descricaoRedeCartao: string,
   *     codigoProdutoCartao: short,
   *     descricaoProdutoCartao: string,
   *     codigoBandeiraCartao: short,
   *     descricaoBandeiraCartao: string,
   *     nsuCartao: string,
   *   }>,
   *   sangrias: Array<{
   *     sangriaId: long,
   *     turnoId: long,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     valorSangrado: decimal,
   *     dataSangria: DateTime,
   *     observacao: string,
   *   }>,
   *   suprimentos: Array<{
   *     suprimentoId: long,
   *     turnoId: long,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     valorSuprido: decimal,
   *     dataSuprimento: DateTime,
   *     observacao: string,
   *   }>
   * }>} Uma promessa que resolve para os dados do turno de pedidos completo.
   */
  recuperarTurnoPedidoCompleto = async (turnoId, useCache = false) => {
    return await this.apiRetornaTurnoPedidoCompletoService.execute(turnoId, useCache);
  };

  /**
   * Salva os valores de um turno de pedidos.
   * @param {{
   *   turnoId: number,
   *   codigoEstabelecimento: number,
   *   codigoOperador: number,
   *   nomeOperador: string,
   *   dataAbertura: Date,
   *   dataFechamento: Date?,
   *   turnoConferido: boolean,
   *   codigoConferente: number?,
   *   nomeConferente: string,
   *   codigoFormaPagamento: number,
   *   descricaoFormaPagamento: string,
   *   valorRecebido: number,
   *   valorSuprido: number,
   *   valorSangrado: number,
   *   valorConferido: number,
   * }[]} valores Os valores a serem salvos para o turno de pedidos.
   * @returns {Promise<{
   *   turnoId: number,
   *   codigoEstabelecimento: number,
   *   codigoOperador: number,
   *   nomeOperador: string,
   *   dataAbertura: Date,
   *   dataFechamento: Date?,
   *   turnoConferido: boolean,
   *   codigoConferente: number?,
   *   nomeConferente: string,
   *   codigoFormaPagamento: number,
   *   descricaoFormaPagamento: string,
   *   valorRecebido: number,
   *   valorSuprido: number,
   *   valorSangrado: number,
   *   valorConferido: number,
   *   pagamentos: Array<{
   *     turnoId: long,
   *     codigoEstabelecimento: short,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     numeroPedido: long,
   *     valor: decimal,
   *     dataHora: DateTime,
   *     codigoRedeCartao: short,
   *     descricaoRedeCartao: string,
   *     codigoProdutoCartao: short,
   *     descricaoProdutoCartao: string,
   *     codigoBandeiraCartao: short,
   *     descricaoBandeiraCartao: string,
   *     nsuCartao: string,
   *   }>,
   *   sangrias: Array<{
   *     sangriaId: long,
   *     turnoId: long,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     valorSangrado: decimal,
   *     dataSangria: DateTime,
   *     observacao: string,
   *   }>,
   *   suprimentos: Array<{
   *     suprimentoId: long,
   *     turnoId: long,
   *     codigoFormaPagamento: short,
   *     descricaoFormaPagamento: string,
   *     valorSuprido: decimal,
   *     dataSuprimento: DateTime,
   *     observacao: string,
   *   }>
   * }>} Uma promessa que resolve para os dados do turno de pedidos completo.
   */
  salvaValores = async valores => {
    if(valores.length === 0) throw new Error('Não há valores para salvar.')
    await this.apiTurnoPedidoSalvaValoresService.execute(valores);
    return await this.recuperarTurnoPedidoCompleto(valores[0].turnoId, false);
  };
  /**
    * Reabre o turno de pedidos.
    * @param turnoId: number O ID do turno a ser reaberto.
    * @returns {Promise<{
    *   turnoId: number,
    *   codigoEstabelecimento: number,
    *   codigoOperador: number,
    *   nomeOperador: string,
    *   dataAbertura: Date,
    *   dataFechamento: Date?,
    *   turnoConferido: boolean,
    *   codigoConferente: number?,
    *   nomeConferente: string,
    *   codigoFormaPagamento: number,
    *   descricaoFormaPagamento: string,
    *   valorRecebido: number,
    *   valorSuprido: number,
    *   valorSangrado: number,
    *   valorConferido: number,
    *   pagamentos: Array<{
    *     turnoId: long,
    *     codigoEstabelecimento: short,
    *     codigoFormaPagamento: short,
    *     descricaoFormaPagamento: string,
    *     numeroPedido: long,
    *     valor: decimal,
    *     dataHora: DateTime,
    *     codigoRedeCartao: short,
    *     descricaoRedeCartao: string,
    *     codigoProdutoCartao: short,
    *     descricaoProdutoCartao: string,
    *     codigoBandeiraCartao: short,
    *     descricaoBandeiraCartao: string,
    *     nsuCartao: string,
    *   }>,
    *   sangrias: Array<{
    *     sangriaId: long,
    *     turnoId: long,
    *     codigoFormaPagamento: short,
    *     descricaoFormaPagamento: string,
    *     valorSangrado: decimal,
    *     dataSangria: DateTime,
    *     observacao: string,
    *   }>,
    *   suprimentos: Array<{
    *     suprimentoId: long,
    *     turnoId: long,
    *     codigoFormaPagamento: short,
    *     descricaoFormaPagamento: string,
    *     valorSuprido: decimal,
    *     dataSuprimento: DateTime,
    *     observacao: string,
    *   }>
    * }>} Uma promessa que resolve para os dados do turno de pedidos completo.
  */
  reabrirTurno = async turnoId => {
    if(!turnoId) throw new Error('Identificação do turno inválida.')
    await this.apiReabreTurnoPedidoService.execute(turnoId);
    return await this.recuperarTurnoPedidoCompleto(turnoId, false);
  };
  abrirTurno = async () => {
    return await this.apiAbrirTurnoService.execute();
  };
  fecharTurno = async () => {
    return await this.apiFecharTurnoService.execute();
  };
  turnoAtual = async () => {
    return await this.apiRecuperarTurnoAtualService.execute();
  };
  adicionarSangria = async (sangria) => {
    return await this.apiAdicionarSangriaService.execute(sangria);
  };
  adicionarSuprimento = async (suprimento) => {
    return await this.apiAdicionarSuprimentoService.execute(suprimento);
  };
  recuperarSangria = async (turnoId) => {
    return await this.apiRecuperarSangriasService.execute(turnoId);
  };
  recuperarSuprimento = async (turnoId) => {
    return await this.apiRecuperarSuprimentosService.execute(turnoId);
  };
}
