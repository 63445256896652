export class AxiosErrorService {
  async execute(er) {
    if (er.isAxiosError) {
      if (er.message === 'Network Error') {
        throw new Error('Não foi possível conectar ao servidor. Verifique sua conexão com a internet.');
      }
      let data = er.response.data;
      // Verifica se a resposta é um blob
      if (data instanceof Blob) {
        try {
          // Converte o blob em texto
          data = await this.readBlobAsText(data);
          console.log(data);
          data = JSON.parse(data); // Tenta converter o texto para JSON
        } catch (e) {
          throw new Error('Erro ao processar a mensagem de erro do servidor.');
        }
      }
      if (!data) {
        data = {
          message: er.message || 'Erro desconhecido. Tente novamente mais tarde.',
        };
      }
      if (data.constructor.name === 'String') {
        throw new Error(data);
      }
      if (data.message) {
        throw new Error(data.message);
      }
      if (data.errors) {
        let msg = '';
        for (const key in data.errors) {
          if (Object.hasOwn(data.errors, key)) {
            const element = data.errors[key];
            msg = `${msg}${msg.length > 0 ? ' - ' : ''}${element}`;
          }
        }
        throw new Error(msg);
      }
      throw new Error((data || {}).message || 'Sem mensagem de erro');
    }
    throw new Error(er.message || 'Sem mensagem de erro');
  }
   // Método auxiliar para ler o Blob como texto
   async readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsText(blob);
    });
  }
}
const axiosErrorService = new AxiosErrorService();
export default axiosErrorService;
