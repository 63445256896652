import axios from "axios";

import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";
import {
  getCnpj, getUrl
} from "../../../shared/utils/Utils";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getContas(somenteAtivos, cadastroCompleto) {
  return axios.post(`LxApi/v1/Contas/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      somenteAtivos,
      cadastroCompleto
    }
  });
}

export function getConta(codigoConta) {
  return axios.get(`LxApi/v1/Contas/Selecionar/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoConta
    }
  });
}

export function getSubConta(codigoSubConta) {
  return axios.get(`LxApi/v1/Contas/SelecionarSubConta/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoSubConta
    }
  });
}

export function incluiConta(
  descricao,
  pagarReceber,
  tipo,
  inativo,
  rateio,
  rowVersion
) {
  return axios.post(`LxApi/v1/Contas/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    descricao,
    pagarReceber,
    tipo,
    inativo,
    rateio,
    rowVersion
  });
}

export function incluiSubConta(
  codigoPai,
  descricao,
  conta,
  contaReduzida,
  pagarReceber,
  tipoConta,
  redeAutorizadora,
  inativo,
  restrito,
  exibeConta,
  tipoReceitaDespesa,
  sped,
  rateio
) {
  return axios.post(`LxApi/v1/Contas/IncluirSubConta`, {
    cnpjEstabelecimento: getCnpj(),
    codigoPai,
    descricao,
    conta,
    contaReduzida,
    pagarReceber,
    tipoConta,
    redeAutorizadora,
    inativo,
    restrito,
    exibeConta,
    tipoReceitaDespesa,
    sped,
    rateio
  });
}

export function alteraConta(
  codigo,
  descricao,
  pagarReceber,
  tipo,
  inativo,
  rateio,
  rowVersion
) {
  return axios.put(`LxApi/v1/Contas/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigo,
    descricao,
    pagarReceber,
    tipo,
    inativo,
    rateio,
    rowVersion
  });
}

export function alteraSubConta(
  codigo,
  codigoPai,
  descricao,
  conta,
  contaReduzida,
  pagarReceber,
  tipoConta,
  redeAutorizadora,
  inativo,
  restrito,
  exibeConta,
  tipoReceitaDespesa,
  sped,
  rateio,
  rowVersion
) {
  return axios.put(`LxApi/v1/Contas/AlterarSubConta`, {
    cnpjEstabelecimento: getCnpj(),
    codigo,
    codigoPai,
    descricao,
    conta,
    contaReduzida,
    pagarReceber,
    tipoConta,
    redeAutorizadora,
    inativo,
    restrito,
    exibeConta,
    tipoReceitaDespesa,
    sped,
    rateio,
    rowVersion
  });
}

export function excluiConta(codigoConta) {
  return axios.delete(`LxApi/v1/Contas/Excluir/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoConta
    }
  });
}

export function excluiSubConta(codigoSubConta) {
  return axios.delete(`LxApi/v1/Contas/ExcluirSubConta/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoSubConta
    }
  });
}

export function montaComboPagarReceber() {
  return axios.get(`LxApi/v1/Contas/MontarComboPagarReceber/`);
}

export function montaComboGrupo() {
  return axios.get(`LxApi/v1/Contas/MontarComboGrupo/`);
}

export function montaComboTipoConta() {
  return axios.get(`LxApi/v1/Contas/MontarComboTipoConta/`);
}

export function montaComboReceitaDespesa() {
  return axios.get(`LxApi/v1/Contas/MontarComboReceitaDespesa/`);
}

export function montaComboNaturezaSped() {
  return axios.get(`LxApi/v1/Contas/MontarComboNaturezaSped/`);
}

export function montarComboIndicadorConta() {
  return axios.get(`LxApi/v1/Contas/MontarComboIndicadorConta/`);
}

export function montarComboPlanoContaFinanceiro(codigoTipoLancamento) {
  return axios.get(`LxApi/v1/Contas/MontarComboPlanoContaFinanceiro/`, {
    params: {
      codigoTipoLancamento
    }
  });
}
