import React from 'react';
import { formatStringToCurrency } from '../../../../shared/utils/Utils';
// import styles from './Component.module.scss';

export function CellSaldo({ data }) {
  const { saldo = 0 } = data;

  const style = {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    color: saldo > 0 ? 'red' : 'black',
    top: '-3px',
  };

  return (
    <p title={formatStringToCurrency(saldo)} className={`pt-2 pb-1 w-100 pl-1 pr-1 m-0 float-right`} style={style}>
      {formatStringToCurrency(saldo) || '---'}
    </p>
  );
}
