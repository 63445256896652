import React, { Component } from "react";

import modalclose from "../../../assets/images/postopop/modal-close.png";

export class Modal extends Component {
  render() {
    const { className, onClose, children } = this.props;

    return (
      <div className={`modal-bg ${className}`}>
        <div className={`modal ${className}`}>
          <div className="modal-container">
            {onClose && (
              <img src={modalclose} alt="Fechar modal" className="modal-close" onClick={onClose} />
            )}
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export class ModalHeader extends Component {
  render() {
    const { title, subtitle, children } = this.props;

    return (
      <div className="modal-header">
        {title && <h3 className="title">{title}</h3>}
        {subtitle && <span className="subtitle">{subtitle}</span>}
        {children}
      </div>
    );
  }
}

export class ModalFooter extends Component {
  render() {
    return <div className="modal-footer">{this.props.children}</div>;
  }
}

export class ModalBody extends Component {
  render() {
    return <div className="modal-body">{this.props.children}</div>;
  }
}

export class ModalBodyDashboard extends Component {
  render() {
    return <div className="modal-body-dashboard">{this.props.children}</div>;
  }
}

export class ModalBodyTree extends Component {
  render() {
    return <div className="modal-body-tree">{this.props.children}</div>;
  }
}
