import React, { Component } from "react";
import Botao from "../../../../components/botao/Botao";
import {
  AutoComplete,
  InputText,
  Select,
  handleInputChange
} from "../../../../components/formulario/Formulario";
import { SectionContainer, SectionContent } from "../../../../components/section/Content";
import { tagAlert, showError } from "../../../../components/alert/Alert";
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  getCnpj, oneOrMore, getNomePagina,
  validaDadosLogin
} from "../../../../shared/utils/Utils";

import {
  getConsultaVendas,
  getTotaisConsultaVenda
} from "./ConsultaVendas.service";

import { montaComboSetor } from "../../../../services/Setores.service";
import { montaComboAlmoxarifado } from "../../../estoque/depositos/Deposito.service";
import { getProdutoConsultaEstoque } from "../../../cadastro/produtos/Produtos.service";

import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../shared/constants/MenuConstants';
import Footer from "../../../../components/core/footer/Footer";

const titulo = "Consulta de Vendas";
const PATH_MENU = menus.COD_60700;

const autoComplete = {
  produto: {
    min: 3,
    list: "Produtos",
    item: "Produto",
    isHe: true,
    text: ""
  }
}

class ConsultaVendas extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    this.state = {
      cnpjEstabelecimento: getCnpj(),
      meses: this.getMeses(),
      anos: [],
      codigoAlmoxarifado: [],
      codigoSetor: [],
      produto: [],

      setores: [],
      almoxarifados: [],

      totalVendas: "",
      totalDocsFiscais: "",
      totalTicketMedio: "",
      totalIcms: "",
      //totalIss: "",
      //totalPisCofins: "",            

      produtos: [],

      alertActive: false,
      alertType: "",
      alertTitle: "",
      alertSubtitle: "",

      edit: false,
      vendas: [],

      vigenciaMes: moment().format("MM"),
      vigenciaAno: String(moment().format("YYYY")),
      vigenciaAtual: moment().format("YYYY-MM-01")
    };

  }

  async componentDidMount() {
    const { data } = await montaComboSetor(getCnpj());
    const setores = data.map(setor => {
      return {
        value: setor.codigo,
        label: setor.descricao
      }
    });
    this.setState({ setores });

    const { data: itens } = await montaComboAlmoxarifado(getCnpj(), "");
    const almoxarifados = itens.map(almoxarifado => {
      return {
        value: almoxarifado.codigo,
        label: almoxarifado.descricao
      }
    });
    this.setState({ almoxarifados });

    let { vigenciaAno } = this.state;
    const ano = [];
    for (let i = 0; i > -6; i--) {
      ano.push({
        label: String(Number(vigenciaAno) + i),
        value: Number(vigenciaAno) + i
      });
    };
    this.setState({ anos: ano });
  }

  limpaFiltros = () => {
    this.setState({
      cnpjEstabelecimento: getCnpj(),
      vigenciaMes: String(moment().format("MM")),
      vigenciaAno: String(moment().format("YYYY")),
      vigenciaAtual: moment().format("YYYY-MM-01"),
      codigoAlmoxarifado: [],
      codigoSetor: [],
      produto: []
    });
  }

  handleCancelar = () => {
    this.setState({ edit: false });
    this.limpaFiltros();
    this.props.history.push("/relatorios/financeiro/consulta-vendas/relatorio");
  }

  getMeses = () => {
    const meses = [];
    meses.push({ label: "Janeiro", value: "01" });
    meses.push({ label: "Fevereiro", value: "02" });
    meses.push({ label: "Março", value: "03" });
    meses.push({ label: "Abril", value: "04" });
    meses.push({ label: "Maio", value: "05" });
    meses.push({ label: "Junho", value: "06" });
    meses.push({ label: "Julho", value: "07" });
    meses.push({ label: "Agosto", value: "08" });
    meses.push({ label: "Setembro", value: "09" });
    meses.push({ label: "Outubro", value: "10" });
    meses.push({ label: "Novembro", value: "11" });
    meses.push({ label: "Dezembro", value: "12" });

    return meses;
  }

  geraRelatorio = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }

    const { data: consVendas } = await getConsultaVendas(
      getCnpj(),
      [this.state.produto.value],
      this.state.vigenciaAtual,
      this.state.codigoSetor,
      this.state.codigoAlmoxarifado
    );
    const consultaVendas = consVendas.result;

    if (consultaVendas.length > 0) {
      const venda = consultaVendas.map(v => {
        return {
          data: moment(v.data).format("DD ddd"),
          qtdeDocsFiscais: v.totalDocumento.toLocaleString('pt-BR'),
          vendaBruta: v.vendasBrutas.toLocaleString('pt-BR'),
          cancelados: v.vendasLiquidasCanceladas.toLocaleString('pt-BR'),
          descontos: v.descontos.toLocaleString('pt-BR'),
          acrescimos: v.acrescimos.toLocaleString('pt-BR'),
          vendaLiquida: v.vendasLiquidas.toLocaleString('pt-BR'),
          ticketMedio: v.ticketMedio.toLocaleString('pt-BR')
        }
      }
      )

      const venMoOrd = venda.sort(function (a, b) {
        if (parseInt(a.data) < parseInt(b.data)) return -1;
        if (parseInt(a.data) > parseInt(b.data)) return 1;
        return 0;
      });

      this.setState({ vendas: venMoOrd });

      const { data: totalVenda } = await getTotaisConsultaVenda(
        getCnpj(),
        [this.state.produto.value],
        this.state.vigenciaAtual,
        this.state.codigoSetor,
        this.state.codigoAlmoxarifado
      );
      this.setState({
        totalVendas: totalVenda.totalVendas.toLocaleString('pt-BR'),
        totalDocsFiscais: totalVenda.totalDocumentosFiscais.toLocaleString('pt-BR'),
        totalTicketMedio: totalVenda.ticketMedio.toLocaleString('pt-BR'),
        totalIcms: totalVenda.totalICMS.toLocaleString('pt-BR')
      });

      this.setState({ edit: true });
      this.props.history.push("/relatorios/financeiro/consulta-vendas/relatorio");
    }
    else {
      this.limpaFiltros();
      showError(this, titulo, "Não existem dados com esses filtros");
    }
  }

  changeAlmoxarifado = (event) => {
    let value, name;

    value = event.selected;
    name = event.name;

    this.setState({ codigoAlmoxarifado: value });
    this.setState({ [name]: value });
  }

  changeSetor = (event) => {
    let value, name;

    value = event.selected;
    name = event.name;

    this.setState({ codigoSetor: value });
    this.setState({ [name]: value });
  }

  changeMes = (event) => {
    const vigenciaMes = event.target.value;
    const { vigenciaAno } = this.state;
    const vigenciaAtual = String(vigenciaAno + "-" + vigenciaMes + "-01");
    this.setState({ vigenciaMes, vigenciaAtual });
  }

  changeAno = (event) => {
    const vigenciaAno = event.target.value;
    const { vigenciaMes } = this.state;
    const vigenciaAtual = String(vigenciaAno + "-" + vigenciaMes + "-01");
    this.setState({ vigenciaAno, vigenciaAtual });
  }

  autoCompleteMessage = (ctrl) => {
    if (ctrl.text.length < ctrl.min)
      return "Inicie digitando pelo menos " + ctrl.min + " " +
        oneOrMore(ctrl.min, "caracter", "caracteres") +
        " para obter uma lista de " + ctrl.list

    return "Não foi encontrado nenhum " + ctrl.item +
      ' que contenha "' + ctrl.text + '"'
  }

  loadProduto = inputText => {
    this.forceUpdate();
    autoComplete.produto.text = inputText
    if (String(inputText).length >= autoComplete.produto.min)
      return getProdutoConsultaEstoque(getCnpj(), inputText)
        .then(response => response.data.map(item => {
          return {
            value: item.codigo,
            label: item.descricao
          }
        }));
    return Promise.resolve()
  }

  render() {
    const {
      edit,
      meses,
      anos,
      produto,
      // codigoAlmoxarifado,
      // codigoSetor,

      setores,
      almoxarifados,

      totalVendas,
      totalDocsFiscais,
      totalTicketMedio,
      totalIcms,

      vigenciaMes,
      vigenciaAno

    } = this.state;
    return (
      <>
        {edit ? (
          <>
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
              <SectionContainer>
                <SectionContent title="Totais" accordion>
                  <div className="row">
                    <div className="col-3">
                      <InputText
                        label="Total de vendas"
                        value={totalVendas}
                        name="totalVendas"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total de Documentos Fiscais"
                        value={totalDocsFiscais}
                        name="totalDocsFiscais"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total Ticket Médio"
                        value={totalTicketMedio}
                        name="totalTicketMedio"
                        disabled={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Total de ICMS"
                        value={totalIcms}
                        name="totalIcms"
                        disabled={true}
                      />
                    </div>
                  </div>
                </SectionContent>
                <SectionContent title="Relatório consulta de vendas">
                  <ReactTable
                    data={this.state.vendas}
                    defaultPageSize={20}
                    columns={[
                      {
                        accessor: "data",
                        Header: "Data",
                        //width: 50,
                        filterable: false
                      },
                      {
                        accessor: "qtdeDocsFiscais",
                        Header: "Qtd Docs Fiscais",
                        //width: 110,
                        filterable: false
                      },
                      {
                        accessor: "vendaBruta",
                        Header: "Venda Bruta",
                        //width: 100,
                        filterable: false
                      },
                      {
                        accessor: "cancelados",
                        Header: "Cancelados",
                        //width: 150,
                        filterable: false
                      },
                      {
                        accessor: "descontos",
                        Header: "Descontos",
                        //width: 150,
                        filterable: false
                      },
                      {
                        accessor: "acrescimos",
                        Header: "Acréscimos",
                        //width: 100,
                        filterable: false
                      },
                      {
                        accessor: "vendaLiquida",
                        Header: "Venda Líquida",
                        //width: 100,
                        filterable: false
                      },
                      {
                        accessor: "ticketMedio",
                        Header: "Ticket Médio",
                        //width: 100,
                        filterable: false
                      }
                    ]}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando registros..."
                    noDataText="Sem registros para exibir"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                  />
                </SectionContent>
              </SectionContainer>
            </LinxPostos>
            <Footer>
              <div className="section-footer">
                <Botao
                  title="Voltar"
                  icon="icon-lx-close"
                  onClick={this.handleCancelar}
                />
              </div>
            </Footer>
          </>
        ) : (
            <>
              <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                <SectionContainer>
                  <SectionContent title="">
                    <div className="row">
                      <div className="col-6">
                        <AutoComplete
                          value={produto}
                          name="produto"
                          label="Produto"
                          loadOptions={this.loadProduto}
                          noOptionsMessage={this.autoCompleteMessage(autoComplete.produto)}
                          onChange={this.handleInputChange}
                          onFocus={() => {
                            autoComplete.produto.text = ""
                            this.forceUpdate()
                          }}
                        />
                      </div>
                      <div className="col-3">
                        <Select
                          label="Mês"
                          name="vigenciaMes"
                          value={vigenciaMes}
                          onChange={this.changeMes}
                          options={meses}
                        />
                      </div>
                      <div className="col-3">
                        <Select
                          label="Ano"
                          name="vigenciaAno"
                          value={vigenciaAno}
                          onChange={this.changeAno}
                          options={anos}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <Select multi
                          label="Setor"
                          name="codigoSetor"
                          // value={codigoSetor}
                          onChange={this.changeSetor}
                          options={setores}
                          sender={this}
                        />
                      </div>
                      <div className="col-4">
                        <Select multi
                          label="Almoxarifado"
                          name="codigoAlmoxarifado"
                          // value={codigoAlmoxarifado}
                          onChange={this.changeAlmoxarifado}
                          options={almoxarifados}
                        />
                      </div>
                      <div className="col-4">
                        <div className="button-wrapper">
                          <Botao
                            title="Visualizar"
                            icon="icon-lx-search"
                            onClick={this.geraRelatorio}
                          />
                        </div>
                      </div>
                    </div>
                  </SectionContent>
                </SectionContainer>
              </LinxPostos >
            </>
          )}
        {tagAlert(this)}
      </>
    );
  }
}
ConsultaVendas = withRouter(ConsultaVendas);
export { ConsultaVendas };
