import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './FormQuitacaoLote.module.scss';

import { CheckBox, DateBox, SelectBox } from 'devextreme-react';
import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, { Paging, ColumnChooser, GroupPanel, SearchPanel, Grouping, ColumnFixing, Pager, Editing, Column, Lookup, Summary, TotalItem } from 'devextreme-react/data-grid';
import { TextBox } from 'devextreme-react';
import ptMessages from '../../../../../assets/js/pt.json';
import { menus } from '../../../../shared/constants/MenuConstants';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { compareStrings, getNomePagina, sameInt } from '../../../../shared/utils/Utils';
import notify from 'devextreme/ui/notify';
import { Export } from 'devextreme-react/bar-gauge';
import { confirm } from 'devextreme/ui/dialog';
import { useHistory } from 'react-router-dom';
import { getCentrosMonetarios } from '../../../../services/CentrosMonetarios.service';
import { quitacaoService } from './Quitacao.service';
import { montaComboTiposFormasPgto } from '../../conferencia-caixa/ConferenciaTurno.service';
import { FormasPagamento } from '../../conferencia-caixa/conferencia-caixa-totais/ConferenciaCaixaTotais';
import _ from 'lodash';
import Footer from '../../../../components/core/footer/Footer';
import { Validator} from 'devextreme-react/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import moment from 'moment';

export function FormQuitacaoLote(props) {
  let today = new Date();
  let history = useHistory();


  const [selectedCentroMonetario, setSelectedCentroMonetario] = useState(null);
  const [listaCentroMonetario, setListaCentroMonetario] = useState([]);
  const [paying, setPaying] = useState(false);
  const [quitarAgrupado, setQuitarAgrupado] = useState(false);
  const [dataInformada, setDataInformada] = useState(today);
  const [numeroCheque, setNumeroCheque] = useState(0);
  const [showSearch] = useState(false);
  const [tiposDataPagamento, setTiposDataPagamento] = useState([]);
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [tipoDataPagamentoSelecionada, setTipoDataPagamentoSelecionada] = useState(0);
  const [formaPagamentoSelecionada, setFormaPagamentoSelecionada] = useState(null);
  const [lotesPagamento, setLotesPagamento] = useState([]);
  const [alteracoesGrid, setAlteracoesGrid] = useState(0);
  const [titulosQuitarClone, setTitulosQuitarClone] = useState(props.titulos);
  const [, setState] = useState();

  const PATH_MENU = menus.COD_60690;

  const TipoDataPgto = {
    InformarData: 0,
    Previsao: 1,
    Vencimento: 2
  };

  useEffect(() => {
    setTimeout(() => validationEngine.validateGroup());
  });

  useEffect(() => {
    const realizaConsulta = async () => {
      const centrosMonetarios = [];
      if (listaCentroMonetario.length === 0) {
        const { data: dataCM } = await getCentrosMonetarios();
        dataCM.result.forEach(cm => {
          centrosMonetarios.push({
            label: cm.descricao,
            value: cm.codigoCentroMonetario
          });
        });
        centrosMonetarios.sort((a, b) => {
          return compareStrings(a.label, b.label);
        });
        setListaCentroMonetario(centrosMonetarios);
      }
    }
    realizaConsulta();
  });

  useEffect(()=> {
    let lotes = [];
    let dataPagamento = dataInformada;
    let titulosAgrupados = _.groupBy(titulosQuitarClone, 'estruturaCartao');
    let i = 0;

    if (sameInt(tipoDataPagamentoSelecionada, TipoDataPgto.InformarData)){
      for (var [estrutura, titulos] of Object.entries(titulosAgrupados)) {
        let titulosAgrupadosCM = _.groupBy(titulos, 'centroMonetario.id');
        for (var [centroMonetarioID, titulosCM] of Object.entries(titulosAgrupadosCM)) {
            let somas = titulosCM.reduce((a, b) => ({
              valor: a.valor + b.valor,
              acrescimo: a.acrescimo + b.acrescimo,
              desconto: a.desconto + b.desconto,
              valorTaxaCartao: a.valorTaxaCartao + b.valorTaxaCartao,
              valorLiquido: a.valorLiquido + b.valorLiquido
            }));
            i += 1;
            lotes.push({
              id: i,
              lotePagamento: estrutura !== 'undefined' ? estrutura : '',
              dataPagamento: dataPagamento,
              valor: somas.valor,
              acrescimo: somas.acrescimo,
              desconto: somas.desconto,
              valorTaxaCartao: somas.valorTaxaCartao,
              valorLiquido: somas.valorLiquido,
              centroMonetario: getCentroMonetarioNomeById(centroMonetarioID),
              titulos: titulosCM,
              acrescimoLote: 0,
              descontoLote: 0
            })
          }
        }
    } else {
      for (var [estrutura, titulos] of Object.entries(titulosAgrupados)) {
        let titulosAgrupadosData = _.groupBy(titulos, sameInt(tipoDataPagamentoSelecionada, TipoDataPgto.Previsao) ? 'dataPrevisao' : 'dataVencimento');
        for (var [dataPagamentoAgrupamento, titulosData] of Object.entries(titulosAgrupadosData)) {
          let titulosAgrupadosCM = _.groupBy(titulosData, 'centroMonetario.id');
          for (var [centroMonetarioID, titulosCM] of Object.entries(titulosAgrupadosCM)) {
              let somas = titulosCM.reduce((a, b) => ({
                valor: a.valor + b.valor,
                acrescimo: a.acrescimo + b.acrescimo,
                desconto: a.desconto + b.desconto,
                valorTaxaCartao: a.valorTaxaCartao + b.valorTaxaCartao,
                valorLiquido: a.valorLiquido + b.valorLiquido
              }));
              i += 1;
              lotes.push({
                id: i,
                lotePagamento: estrutura !== 'undefined' ? estrutura : '',
                dataPagamento: dataPagamentoAgrupamento,
                valor: somas.valor,
                acrescimo: somas.acrescimo,
                desconto: somas.desconto,
                valorTaxaCartao: somas.valorTaxaCartao,
                valorLiquido: somas.valorLiquido,
                centroMonetario: getCentroMonetarioNomeById(centroMonetarioID),
                titulos: titulosCM,
                acrescimoLote: 0,
                descontoLote: 0
              })
          }
        }
      }
    }

    setLotesPagamento(lotes);
  },[titulosQuitarClone, alteracoesGrid, dataInformada, tipoDataPagamentoSelecionada, listaCentroMonetario]);

  useEffect(()=> {
    const realizaConsulta = async () => {
      const { data: dataFormasPagamento } = await montaComboTiposFormasPgto(true, false);
      setFormasPagamento(dataFormasPagamento)
    }
    realizaConsulta();
  },[props.titulos]);

  useEffect(()=> {
    const realizaConsulta = async () => {
      const response = await quitacaoService.getTiposDataQuitacaoLote();
      if (response.length) setTiposDataPagamento(response);
    }
    realizaConsulta();
  },[props.titulos]);

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, []);

  const getCentroMonetarioNomeById = (id) => {
    let centroMonetarioPesquisado = listaCentroMonetario.find(cm=> sameInt(cm.value, id));
    if (centroMonetarioPesquisado){
      return centroMonetarioPesquisado.label
    } else {
      return ''
    }
  }

  const validarQuitacao = () =>
  {
    if(titulosQuitarClone.find(t=> t.centroMonetario === undefined)) {
      notify('Informe o centro monetário para quitação', 'error');
      return false;
    }

    if(sameInt(tipoDataPagamentoSelecionada, TipoDataPgto.InformarData) && titulosQuitarClone.find(t=> new Date(t.dataEmissao) > new Date(dataInformada))) {
      notify('A data de pagamento deve ser igual ou posterior à data de emissão', 'error');
      return false;
    }

    if (quitarAgrupado && !selectedCentroMonetario) {
      notify('Informe o centro monetário para quitação', 'error');
      return false;
    }

    return true;
  }

  const prepararLotesQuitacao = () =>
  {
    let lotesApi = lotesPagamento;

    lotesApi.forEach(lote => {
      lote.titulos.forEach(element => {
        let lote = lotesPagamento.find(x=> x.titulos.find(y=>sameInt(y.codigoTitulo, element.codigoTitulo)));
        element.acrescimo += lote.acrescimoLote;
        element.desconto += lote.descontoLote;
        element.valorLiquido = element.valor + element.acrescimo - element.desconto - element.valorTaxaCartao;
      });

      lote.titulos = lote.titulos.map(t => (
        {
          codigoTitulo: t.codigoTitulo,
          descricao: t.descricao,
          numeroDocumento: t.numeroDocumento,
          dataQuitacao: sameInt(tipoDataPagamentoSelecionada, TipoDataPgto.InformarData) ? dataInformada : sameInt(tipoDataPagamentoSelecionada, TipoDataPgto.Previsao) ? t.dataPrevisao : t.dataVencimento,
          dataVencimento: t.dataVencimento,
          dataPrevisao: t.dataPrevisao,
          centroMonetario: t.centroMonetario ?
          {
            id: t.centroMonetario.id
          }: null,
          conciliado: false,
          tipoOperacaoFinanceira: t.tipoOperacao,
          valorPago: t.valorLiquido,
          valorAcrescimo: t.acrescimo,
          valorDesconto: t.desconto,
          dataVencimentoSaldo: t.DataVencimento,
          saldo: 0,
          conta: t.conta ?
          {
            id: t.conta.id
          } : null,
          operacao: t.operacao ?
          {
              id: t.operacao.id,
              DebitoCredito: t.operacao.debitoCredito
          } : null,
          pessoa: t.pessoa
      }));
    });

    return lotesApi;
  }

  const pay = async () => {

    if(validarQuitacao())
    {
      setPaying(true);
      try {
        const data =
          {
            formaQuitacao: quitarAgrupado ? 0 : 1,
            centroMonetario: quitarAgrupado ? {
                id: selectedCentroMonetario
            } : null,
            tipoDataQuitacaoLote: tipoDataPagamentoSelecionada,
            dataPagamentoEspecificada: dataInformada,
            codigoFormaPagamento: formaPagamentoSelecionada,
            numeroCheque: parseFloat(numeroCheque),
            lotesQuitacao: prepararLotesQuitacao(),
            TipoOperacaoFinanceira: titulosQuitarClone[0].tipoOperacao
          };

        const response = await quitacaoService.realizarQuitacaoLote(data);

        if (response.notificationLevel !== 1) {
          notify(response.message, 'error', 5000, 'top');
        } else {
          notify(
            'Títulos quitados com sucesso!',
            'success',
            5000
          );
          if (props.returnMethod) props.returnMethod(false);
          history.push(props.goBackTo);
        }
      } finally {
        setPaying(false);
      }
    }

  };

  const cancel = async () => {
    let resp = await confirm (
        '<h1>Deseja realmente cancelar a operação?</h1>',
        'Quitação em lote'
      );
      if (resp) {
        history.push(props.goBackTo);
        props.recuperarSelecionados();
      }
  };

  const atualizaCloneTitulosQuitar = (titulo, campo, novoValor) => {
    switch (campo) {
      case 'acrescimo':
        titulo.acrescimo = parseFloat(novoValor.replace(',', '.'));
        break;

      case 'desconto':
        titulo.desconto = parseFloat(novoValor.replace(',', '.'));
        break;

      case 'centroMonetario':
        if(titulo.centroMonetario)
          titulo.centroMonetario.id = novoValor;
        else
          titulo.centroMonetario = {
            id: novoValor
          }
        break;

      default:
        break;
    }
    for (let i = 0; i < titulosQuitarClone.length; i++) {
      if(sameInt(titulosQuitarClone[i].codigoTitulo, titulo.codigoTitulo))
      {
        titulosQuitarClone[i] = {
          ...titulo,
          valorLiquido: titulo.valor + titulo.acrescimo - titulo.desconto - titulo.valorTaxaCartao,
          centroMonetario: {
            id: titulo.centroMonetario.id
          }
        }
      }
    }
    setTitulosQuitarClone(titulosQuitarClone);
    setAlteracoesGrid(alteracoesGrid+1);
  }

  const atualizaLote = (lote, campo, novoValor) => {

    lotesPagamento.forEach(element => {
      if (sameInt(lote.id, element.id)) {
        switch (campo) {
          case 'acrescimoAdicional':
            element.acrescimoLote = parseFloat(novoValor.replace(',', '.'));
            if(isNaN(element.descontoLote))
              element.descontoLote = parseFloat(element.descontoLote.replace(',', '.'));
            break;

          case 'descontoAdicional':
            if(isNaN(element.acrescimoLote))
              element.acrescimoLote = parseFloat(element.acrescimoLote.replace(',', '.'));
            element.descontoLote = parseFloat(novoValor.replace(',', '.'));
            break;

          default:
            break;
        }
        element.valorLiquido = element.valor + element.acrescimo - element.desconto - element.valorTaxaCartao + element.acrescimoLote - element.descontoLote;
      }
    });

    setLotesPagamento(lotesPagamento);
  }

  const cmEditorRender = (cell) => {
    return <SelectBox
      defaultValue={cell.value}
      {...cell.column.lookup}
      onValueChanged={(e) => {
            cell.setValue(e.value);
            atualizaCloneTitulosQuitar(cell.key, 'centroMonetario', e.value);
        }}
    />;
  }

  const acrescimoEditorRender = (cell) => {
    return <TextBox
      defaultValue={cell.value}
      onValueChanged={(e) => {
            if(e.value === '') e.value = '0';
            cell.setValue(e.value);
            atualizaCloneTitulosQuitar(cell.key, 'acrescimo', e.value);
        }}
    />;
  }

  const descontoEditorRender = (cell) => {
    return <TextBox
      defaultValue={cell.value}
      onValueChanged={(e) => {
            if(e.value === '') e.value = '0';
            if(parseFloat(e.value.replace(',','.')) > (cell.key.valor + cell.key.acrescimo - cell.key.valorTaxaCartao)){
              notify('Valor do desconto deve ser igual ou inferior ao resultado de Valor + Acréscimo - Valor taxa cartão (R$)', 'error');
            } else {
              cell.setValue(e.value);
              atualizaCloneTitulosQuitar(cell.key, 'desconto', e.value);
            }
        }}
    />;
  }

  const acrescimoLoteEditorRender = (cell) => {
    return <TextBox
      defaultValue={cell.value}
      onValueChanged={(e) => {
            if(e.value === '') e.value = '0';
            atualizaLote(cell.key, 'acrescimoAdicional', e.value);
        }}
    />;
  }


  const descontoLoteEditorRender = (cell) => {
    return <TextBox
      defaultValue={cell.value}
      onValueChanged={(e) => {
            if(e.value === '') e.value = '0';
            if(parseFloat(e.value.replace(',','.')) > (cell.key.valor + cell.key.acrescimo - cell.key.valorTaxaCartao)){
              notify('Valor do desconto deve ser igual ou inferior ao resultado de Valor + Acréscimo - Valor taxa cartão (R$)', 'error');
            } else {
              atualizaLote(cell.key, 'descontoAdicional', e.value);
            }
        }}
    />;
  }

  const format = {
    type: 'currency',
    precision: 2
  };

  const handleCentroMonetarioAgrupado = (centroMonetarioId) => {
    titulosQuitarClone.forEach(titulo => {
      if (titulo.centroMonetario)
        titulo.centroMonetario.id = centroMonetarioId;
      else
        titulo.centroMonetario = {
          id: centroMonetarioId
      }
    });
    setTitulosQuitarClone(titulosQuitarClone);
    setState({});
    setAlteracoesGrid(alteracoesGrid+1);
  }

  const resultGrid = () => {
    return (
        <>
            <hr />
            <div className="row">
                <div className={` ${styles.sectionTitle}`}>
                <h3>Títulos selecionados</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <DataGrid
                    dataSource={titulosQuitarClone}
                    showBorders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnResizingMode={'widget'}
                    columnMinWidth={80}
                    disabled={paying}
                    rowAlternationEnabled={true}
                    onCellPrepared= {(info) =>
                      {
                        if(info.rowType === 'data' && info.column.dataField === "centroMonetario.id" && info.data.centroMonetario === undefined) {
                              info.cellElement.style.backgroundColor = "red"
                        }
                      }
                    }
                    >
                    <Editing
                        mode= "cell"
                        allowUpdating= {true}
                    />
                    <Column dataField= 'estruturaCartao' caption= 'Estrutura cartão' width= '130' groupIndex={0} visible= {false} />
                    <Column dataField= 'dataEmissao' caption= 'Emissão' dataType= 'date' width= '90' allowEditing= {false}  />
                    <Column dataField= 'dataPrevisao' caption= 'Previsão' dataType= 'date' width= '90' allowEditing= {false}  />
                    <Column dataField= 'dataVencimento' caption= 'Vencimento' dataType= 'date' width= '90' allowEditing= {false} />
                    <Column dataField= 'pessoa.nome' caption= 'Pessoa' width= '270' allowEditing= {false} />
                    <Column dataField= 'descricao' caption= 'Descrição' width= '350' allowEditing= {false} />
                    <Column dataField= 'numeroDocumento' caption= 'N° documento' width= '250' allowEditing= {false} />
                    <Column dataField= 'nsu' caption= 'NSU' width= '70' allowEditing= {false} />
                    <Column dataField= 'valor' caption= 'Valor bruto' format={format} width= '100' allowEditing= {false} />
                    <Column dataField= 'acrescimo' caption= 'Acréscimo' format={format} width= '100' editCellRender={acrescimoEditorRender}/>
                    <Column dataField= 'desconto' caption= 'Desconto' format={format} width= '100' editCellRender={descontoEditorRender} />
                    <Column dataField= 'percentualTaxaCartao' caption= '% Taxa cartão' width= '110' format= {{precision: 2}} allowEditing= {false}/>
                    <Column dataField= 'valorTaxaCartao' caption= 'Valor taxa cartão' format={format} width= '150' allowEditing= {false} />
                    <Column dataField= 'valorLiquido' caption= 'Valor líquido' format={format} width= '110' allowEditing= {false} />
                    <Column dataField="centroMonetario.id" caption= 'Centro monetário' width={200} editCellRender={cmEditorRender} allowEditing= {!quitarAgrupado} >
                      <Lookup dataSource={listaCentroMonetario} displayExpr="label" valueExpr="value" />
                    </Column>
                    <Column dataField= 'rede_Descricao' caption= 'Rede' width= '100' allowEditing= {false} />
                    <Column dataField= 'bandeira_Descricao' caption= 'Bandeira' width= '150' allowEditing= {false} />
                    <Column dataField= 'produtoCartao_Descricao' caption= 'Produto cartão' width= '150' allowEditing= {false} />
                    <GroupPanel visible={true} />
                    <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                    <Export enabled={true} fileName="TitulosQuitacao" />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 15, 20, 50]}
                        showInfo={true}
                    />
                    <Paging defaultPageSize={15} />
                    </DataGrid>
                    <br />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <DataGrid
                    dataSource={lotesPagamento}
                    showBorders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnResizingMode={'widget'}
                    columnMinWidth={80}
                    disabled={paying}
                    rowAlternationEnabled={true}
                    >
                    <Editing
                        mode= "cell"
                        allowUpdating= {true}
                    />
                    <Column dataField= 'lotePagamento' caption= 'Lotes de pagamento' width= '200' visible= {true} allowEditing= {false} />
                    <Column dataField= 'dataPagamento' caption= 'Data pagamento' dataType= 'date' width= '150' allowEditing= {false} />
                    <Column dataField= 'valor' caption= 'Valor' format={format} width= '100' allowEditing= {false} />
                    <Column dataField= 'acrescimo' caption= 'Acréscimo' format={format} width= '100' allowEditing= {false} />
                    <Column dataField= 'desconto' caption= 'Desconto' format={format} width= '100' allowEditing= {false} />
                    <Column dataField= 'acrescimoLote' caption= 'Acréscimo lote' format={format} width= '150' editCellRender={acrescimoLoteEditorRender} />
                    <Column dataField= 'descontoLote' caption= 'Desconto lote' format={format} width= '150' editCellRender={descontoLoteEditorRender} />
                    <Column dataField= 'valorTaxaCartao' caption= 'Valor taxa cartão' format={format} width= '150' allowEditing= {false} />
                    <Column dataField= 'valorLiquido' caption= 'Valor líquido' format={format} width= '110' allowEditing= {false} />
                    <Column dataField="centroMonetario" caption= 'Centro monetário' width={200} allowEditing= {false} />
                    <Summary>
                      <TotalItem column="valor" valueFormat={{ type: 'currency', precision: 2 }} summaryType="sum" />
                      <TotalItem column="acrescimo" valueFormat={{ type: 'currency', precision: 2 }} summaryType="sum" />
                      <TotalItem column="desconto" valueFormat={{ type: 'currency', precision: 2 }} summaryType="sum" />
                      <TotalItem column="acrescimoLote" valueFormat={{ type: 'currency', precision: 2 }} summaryType="sum" />
                      <TotalItem column="descontoLote" valueFormat={{ type: 'currency', precision: 2 }} summaryType="sum" />
                      <TotalItem column="valorTaxaCartao" valueFormat={{ type: 'currency', precision: 2 }} summaryType="sum" />
                      <TotalItem column="valorLiquido" valueFormat={{ type: 'currency', precision: 2 }} summaryType="sum" />
                    </Summary>
                    <GroupPanel visible={false} />
                    <ColumnChooser enabled={false} allowSearch={true}/>
                    <Export enabled={false}/>
                    <SearchPanel visible={false} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[5, 15, 20, 50]}
                        showInfo={true}
                    />
                    <Paging defaultPageSize={15} />
                    </DataGrid>
                    <br />
                </div>
            </div>
        </>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU, 'Quitação#em#lote')}>
      <div className={`${styles.formQuitacaoLote} mt-3`}>
        <div className="shadow-sm bg-white p-3">
          <div className={`${showSearch ? styles.search : ''}`}>
            <div className="container-fluid">
              <div className={`${styles.filtersrWrapper} row`}>
                <div className="col-md-12">
                  <div
                    className={`${showSearch ? styles.fadeIn : ''} ${
                      styles.searchWrapper
                    }`}
                  >
                    <div className="row">
                      <div className={` ${styles.sectionTitle}`}>
                        <h3>Opções de pagamento</h3>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <div className={`${styles.tooltip}`}>
                          <span>Forma de quitação</span>
                          <i className="dx-icon-help"></i>
                          <span className={`${styles.tooltiptext}`}>Quando selecionado, realizará a quitação de todos os títulos em um centro monetário específico, de forma agrupada. Quando não selecionado,
                          realizará a quitação dos títulos nos centros monetários informados no lançamento, de forma individual.</span>
                        </div>
                        <div>
                          <CheckBox
                            text="Quitar agrupado, em um único centro monetário"
                            value={quitarAgrupado}
                            onValueChanged={value =>
                              setQuitarAgrupado(value.value)
                            }
                          />
                        </div>
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-9 form-group mb-1`}
                      >
                        <span>Centro monetário para quitação</span>
                        <SelectBox
                          items={listaCentroMonetario}
                          displayExpr="label"
                          valueExpr="value"
                          value={selectedCentroMonetario}
                          showClearButton={false}
                          onValueChanged={e => {
                            setSelectedCentroMonetario(e.value);
                            handleCentroMonetarioAgrupado(e.value);
                          }}
                          disabled={!quitarAgrupado}
                          >
                          <Validator validationRules={quitarAgrupado ? [{ type: 'required' }] : []} />
                        </SelectBox>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <span>Tipo de data de pagamento</span>
                        <SelectBox
                          items={tiposDataPagamento}
                          displayExpr="descricao"
                          valueExpr="valor"
                          value={tipoDataPagamentoSelecionada}
                          showClearButton={false}
                          onValueChanged={e => {
                            setTipoDataPagamentoSelecionada(e.value);
                          }}
                        />
                      </div>
                      <div className={` ${styles.datebox} col-md-3 form-group mb-1`}>
                        <span>Data de pagamento especificada</span>
                        <DateBox
                          defaultValue={today}
                          id="dataInformada"
                          type="date"
                          onValueChanged={e => {
                              if (!moment(e.value).isValid()) {
                                notify('Data de pagamento inválida.', 'error', 2000, 'top');
                              } else {
                                setDataInformada(e.value);
                              }
                          }}
                          useMaskBehavior={true}
                          value={dataInformada}
                          disabled={paying || tipoDataPagamentoSelecionada !== TipoDataPgto.InformarData}
                          displayFormat="dd/MM/yyyy"
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <span>Forma de pagamento</span>
                        <SelectBox
                          items={formasPagamento}
                          placeholder="Selecione..."
                          displayExpr="descricao"
                          valueExpr="codigo"
                          value={formaPagamentoSelecionada}
                          showClearButton={false}
                          onValueChanged={e => {
                            setFormaPagamentoSelecionada(e.value);
                          }}
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <span>Número do cheque</span>
                        <TextBox
                          placeholder="Número do cheque"
                          showClearButton={true}
                          value={numeroCheque}
                          valueChangeEvent="change keypress"
                          onValueChanged={event => {
                            const value = event.value;
                            if (String(value).length <= 10) {
                              setNumeroCheque(value);
                            }
                          }}
                          onKeyPress={e => {
                            const event = e.event,
                              str = String.fromCharCode(event.keyCode);
                            if (!/[0-9]/.test(str)) {
                              event.preventDefault();
                            }
                          }}
                          disabled={paying || !sameInt(formaPagamentoSelecionada, FormasPagamento.Cheque)}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    {props.titulos.length > 0 ? resultGrid() : <></>}

                    <Footer quitarAction={pay} cancelAction={cancel} paying={paying} disabled={paying}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LinxPostos>
  );
}

export default withRouter(FormQuitacaoLote);
