import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { alert } from 'devextreme/ui/dialog';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectSolicitacaoReservaSharedData } from '../../../../../redux/slices/solicitacaoReserva/SharedData.slice';
import { fetchFiltroSolResInit, selectFiltroSolRes, useFiltroSolRes, setResult } from '../../../../../redux/slices/solicitacaoReserva/filtroSolSlice';
import { ApiBuscarSolicitacoesPorFiltrosService } from '../../usercases/ApiBuscarSolicitacoesPorFiltros.service';
import { ButtonOpType } from '../ButtonOpType';
import { CheckboxStatus } from '../CheckboxStatus';
import { DropboxOperador } from '../DropboxOperador';
import './FiltroLista.css';

import { FloatDateInput } from '../FloatDateInput';
import { FloatLabelInput } from '../FloatLabelInput';
import { fabricarFiltroString } from './FabricarFiltroString';
import moment from 'moment';


export const FiltroLista = forwardRef(({ setSearching, setSearchingMessage, activeKey }, ref) => {
  const { changeValue } = useFiltroSolRes();
  const { values } = useSelector(selectFiltroSolRes);
  const dispatch = useDispatch();
  const origens = useSelector(selectSolicitacaoReservaSharedData).origens;
  const [resolution, setResolution] = useState(window.innerWidth);

  useEffect(() => {
    const updateColumns = () => {
      setResolution(window.innerWidth);
    };
    window.addEventListener('resize', updateColumns);
    return () => window.removeEventListener('resize', updateColumns);
  }, []);

  const onLimparFiltro = useCallback(() => {
    dispatch(fetchFiltroSolResInit());
  }, [dispatch]);



  const onAplicarFiltro = useCallback(async () => {
    const service = new ApiBuscarSolicitacoesPorFiltrosService();
    try {
      setSearching(true);
      setSearchingMessage('Carregando Solicitações...');

      // Criar o objeto de filtros

      console.log('valores dos status', values.status);


      const filtros = {
        dataInicial: values.dataPedidoDe ? new Date(values.dataPedidoDe).toISOString() : new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000).toISOString(),
        dataFinal: values.dataPedidoAte ? new Date(values.dataPedidoAte).toISOString() : new Date().toISOString(),
        numeroPedido: values.numeroPedido ? values.numeroPedido.trim() || null : null, // Verificação condicional
        status: values.status, // Converte array em string separada por vírgulas
      };



      const response = await service.execute(filtros);

      // Atualizar o Redux com os dados retornados
      dispatch(setResult({ itens: response }));

      setSearching(false);
    } catch (error) {
      setSearching(false);
      alert(error.message, 'Erro');
    }
  }, [dispatch, values, setSearching, setSearchingMessage]);

  useImperativeHandle(
    ref,
    () => ({
      executeAplicarFiltro() {
        onAplicarFiltro();
      },
      filtroString() {
        return fabricarFiltroString(values, origens);
      },
    }),
    [onAplicarFiltro, values, origens],
  );

  const validarData = (dataDe, dataAte, fieldName) => {
    if (!dataDe || !dataAte) {
      throw new Error(`Informe a ${fieldName} de => até`);
    }
    if (dataDe > dataAte) {
      throw new Error(`A ${fieldName} "de" deve ser menor que a ${fieldName} "até"`);
    }
  };

  return (
    <>
     
{values && (
        <Card className="w-100"style={{ padding: '10px' }}>
          <Card.Body className="border pl-0">
            <Row className="m-0 p-0">

              <Col md={resolution > 1150 ? 2 : 4}>
                <Row className="m-0 p-0">
                  <Col md={12} className="m-0 p-0">
                    <FloatLabelInput type="text" value={values.numeroPedido} onChange={changeValue} id="numeroPedido" label="Nº Solicitação" />
                  </Col>
                </Row>
              </Col>

              <Col md={resolution > 1150 ? 3 : 4} className="m-0 p-0">
                <Row className="m-0 p-0">
                  <Col md={6} className="pl-0 pr-1">
                    <FloatDateInput
                      displayFormat="dd/MM/yyyy"
                      placeholder="de:"
                      label="Data da Reserva"
                      value={values.dataPedidoDe}
                      id="dataPedidoDe"
                      onChange={changeValue}
                    />
                  </Col>
                  <Col md={6} className="pl-1 pr-0">
                    <FloatDateInput
                      displayFormat="dd/MM/yyyy"
                      label=""
                      placeholder="até:"
                      value={values.dataPedidoAte}
                      id="dataPedidoAte"
                      onChange={changeValue}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={resolution > 1100 ? 4 : 3}>
                <Row className="m-0 p-0">
                  <Col md={resolution > 1100 ? 4 : 4} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="em-aberto" statusCss="color-em-aberto" label="Em Aberto" statusId={1} />
                  </Col>
                  <Col md={resolution > 1100 ? 4 : 4} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="enviado" statusId={2} label="Enviado" />
                  </Col>

                  <Col md={resolution > 1100 ? 4 : 4} className="m-0 p-1">
                    <CheckboxStatus className="w-100" typeName="cancelado" statusId={3} label="Cancelado" />
                  </Col>
                </Row>
              </Col>
              
              <Col md={resolution > 1100 ? 3 : 5} className="mr-0 pr-0">
                <Row className="m-0 p-0 justify-content-end">
                  <Col md={4} className="m-0 p-1">
                    <button onClick={onAplicarFiltro} className="btn btn-primary w-100">
                      <i className="icon icon-lx-search"></i> APLICAR FILTRO
                    </button>
                  </Col>

                  <Col md={4} className="m-0 p-1">
                    <button onClick={onLimparFiltro} className="btn btn-secondary w-100 h-100">
                      <i className="icon lg icon-lx-close"></i> LIMPAR
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Body>
          <span className="text-center" style={{ margin: '0 auto', fontSize: '12px', color: 'gray', marginTop: '10px', marginBottom: '10px' }}>
            {fabricarFiltroString(values, origens)}
          </span>
        </Card>
      )}


    </>
  );
});
