export const passarOrigensSelecionadasParaTexto = (origstr, origens) => {
  if (!origstr) return '';
  const arr = origstr.split('_');
  if (!arr.length) return '';
  let ret = '';
  for (const it of arr) {
    const o = origens.find(item => item.id === parseInt(it));
    ret += `${ret.length ? ', ' : ''}${o.nome}`;
  }
  return `${ret}`;
};
