import React, { Component } from 'react';

import {
  getUrl,
  getNomePagina,
  getDadosSellerPop
} from '../../../shared/utils/Utils';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import SellerPopFrame from '../../../components/SellerPopFrame/SellerPopFrame';

import { menus } from '../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60650;

export class Contagem extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <div className='row'>
          <SellerPopFrame
            url={
              getUrl() + '/ManterContagem.aspx?' + getDadosSellerPop(PATH_MENU)
            }
          />
        </div>
      </LinxPostos>
    );
  }
}
