import axios from 'axios';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import UFList from '../../../../assets/data/uf.json';
import MunicipioList from '../../../../assets/data/municipios.json';
import { getUrl } from '../../../shared/utils/Utils';

axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const httpRetaguarda = axios.create({
  baseURL: getUrl() + '/LxApi/v1'
});

export async function incluirEstabelecimento(empresa, baseUrl) {
  let http = httpRetaguarda;
  if (baseUrl)
    http = axios.create({
      baseURL: baseUrl + '/LxApi/v1'
    });

  let retaguardaRequestObject = {
    codigo: 0,
    razaoSocial: empresa.razaoSocial,
    inativo: false,
    nomeFantasia: empresa.nomeFantasia,
    codigoANP: '',
    dataConstituicao: empresa.dataConstituicao,
    registroJunta: empresa.registroJunta,
    dataRegistroJunta: empresa.dataRegistroJunta,
    cnpj: empresa.cnpj.replace('.', '').replace('.', '').replace('/', '').replace('-', ''),
    inscricaoEstadual: empresa.inscricaoEstadual,
    inscricaoMunicipal: empresa.inscricaoMunicipal,
    email: empresa.email,
    enderecoInternet: empresa.enderecoInternet,
    ddd: empresa.ddd,
    telefone: empresa.telefone,
    fax: empresa.fax,
    chave: '',
    endereco: {
      codigoUF: empresa.endereco.uf,
      codigoMunicipio: empresa.endereco.municipio,
      endereco: empresa.endereco.logradouro,
      numero: empresa.endereco.numero,
      complemento: empresa.endereco.complemento,
      referencia: empresa.endereco.referencia,
      bairro: empresa.endereco.bairro,
      cep: empresa.endereco.cep,
      municipio: empresa.endereco.municipioStr,
      estado: empresa.endereco.ufStr,
      tipoLogradouro: empresa.endereco.tipoLogradouro
    },
    representante: {
      nome: empresa.representanteLegal.nome,
      cpf: empresa.representanteLegal.cpf,
      funcaoCargo: ''
    }
  };

  return await http.post('/empresa', retaguardaRequestObject)
    .then(response => {
      return (response.status === 200);
    })
    .catch((reason) => {
      return false;
    });
}

export function montarComboUfs() {
  var listaUf = UFList;
  return listaUf;
}

export function montarComboMunicipios(codigoUF) {
  const isUF = municipio => municipio.codigo_uf === codigoUF;
  var lista = MunicipioList.filter(isUF).map(cid => { return { label: cid.nome, value: cid.codigo_ibge } });
  return lista;
}
