import { TextBox } from 'devextreme-react';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

export const InputText = forwardRef(({ id, label, placeholder, displayFormat = 'dd/MM/yyyy', isMinTime = true }, ref) => {
  const [value, setValue] = useState('');
  const onChange = useCallback(
    e => {
      setValue(e);
    },
    [],
  );

  useImperativeHandle(
    ref,
    () => ({
      getValue() {
        return value;
      },
      setValue(data) {
        setValue(data);
      },
    }),
    [value],
  );

  return (
    <div className="form-group text-left">
      <label htmlFor="selectEstabelecimentos" className="text-primary">
        {label}
      </label>
      <TextBox
        id={id}
        placeholder={placeholder}
        value={value}
        onValueChange={onChange}
      />
    </div>
  );
});
