import React from 'react';
import styles from './NovoLancamento.module.scss'
import { useHistory } from 'react-router-dom';

export default function NovoLancamento(props) {
    
    let history = useHistory();

    return <div>
    <button
      onClick={() => {
        if (props.limparCadastro) props.limparCadastro();
        history.push(props.newURL);
      }}
      className={`${styles.newLancamento} newLancamento btn shadow-sm`}
    >
      {props.texto}
    </button>
  </div>
}

