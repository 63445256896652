import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "./Modal";
import Botao from "../botao/Botao";
import { reenviarEmail } from "../../services/ContaCorrente.service";
import {
  tagAlert,
  showWait,
  // hideWait,
  // showError,
  showInfo,
  showQuestion,
  showExecuteError,
  hideAlert
} from "../alert/Alert";
import { EmailInput } from "../formulario/Formulario";
import { getPessoa } from "../../pages/cadastro/pessoas/Pessoas.service";

// const styles = {
//   fontFamily: "sans-serif",
//   width: "500px",
//   border: "1px solid #eee",
//   background: "#f3f3f3",
//   padding: "25px",
//   margin: "20px"
// };

const titulo = "Reenviar E-mail";

export class ModalReenviaEmail extends Component {
  state = {
    emails: [],
    pesquisado: false
  };

  getEmailCliente = async () => {
    const codigoCliente = this.props.codigoCliente;

    if (codigoCliente) {
      const { data: dataCliente } = await getPessoa(codigoCliente);

      const emails = [];

      if (dataCliente && dataCliente.contatos) {
        emails.push(dataCliente.contatos[0].email);
      }

      this.setState({ emails, pesquisado: true });
    }
  };

  async componentDidUpdate() {
    if (!this.state.pesquisado) {
      await this.getEmailCliente();
    }
  }

  doClose = () => {
    this.setState({ pesquisado: false });
    if (this.props.handleCloseModal) {
      this.props.handleCloseModal();
    }
  };

  renderCancelButton = () => {
    return (
      <Botao
        title="Voltar"
        icon="icon-lx-arrow-right"
        onClick={() => this.doClose()}
      />
    );
  };

  render() {
    const { emails } = this.state;
    const { active } = this.props;

    return (
      <div>
        <Modal
          className={active ? "active modal-filter" : null}
          onClose={() => this.doClose()}
        >
          <ModalHeader title={titulo} />
          <ModalBody>
            <div className="row">
              <div className="col-12">
                <EmailInput
                  sender={this}
                  emails={emails}
                  label="E-mails:"
                  placeholder="Pressione 'Enter' ou 'Tab' para validar"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {emails.length > 0 ? (
              <Botao
                title="Enviar"
                secondary
                icon="icon-lx-check"
                onClick={async () =>
                  showQuestion(
                    this,
                    titulo,
                    "Deseja reenviar o e-mail da fatura?",
                    null,
                    async resp => {
                      if (resp) {
                        try {
                          showWait(
                            this,
                            titulo,
                            "Por favor, aguarde o reenvio do e-mail."
                          );
                          await reenviarEmail(
                            this.props.codigoTitulo,
                            emails.join(", ")
                          );
                          showInfo(
                            this,
                            titulo,
                            "E-mail(s) reenviados com sucesso."
                          );
                        } catch (error) {
                          showExecuteError(this, titulo, error);
                        }
                      } else {
                        hideAlert(this);
                      }
                    }
                  )
                }
              />
            ) : (
                <></>
              )}

            {this.renderCancelButton()}
          </ModalFooter>
        </Modal>
        {tagAlert(this)}
      </div>
    );
  }
}
