import { useEffect, useState } from 'react';
import { useHistory } from "react-router";

export default () => {

  const contolarCtrlClick = false;

  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const handleKeyDown = e => {
    if (e.ctrlKey) {
      setCtrlKeyPressed(true);
    }
  };

  const handleKeyUp = e => {
    if (e.keyCode === 17) {
      setCtrlKeyPressed(false);
    }
  };

  const handleFocusout = e => {
    setCtrlKeyPressed(false);
  };

  const history = useHistory();

  let ctrlHistory = Object.assign({}, history, {
    push: (value) => {
      if (ctrlKeyPressed) {
        setCtrlKeyPressed(false);
        window.open(value, "", "status=0,menubar=0,scrollbars=0,resizable=1,location=0");
      } else {
        history.push(value);
      }
    }
  });

  useEffect(() => {
    if (contolarCtrlClick) {
      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('keyup', handleKeyUp);
      document.addEventListener('focusout', handleFocusout);
    }
    return () => {
      if (contolarCtrlClick) {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('keyup', handleKeyUp);
        document.removeEventListener('focusout', handleFocusout);
      }
    };
  }, []);

  return ctrlHistory;
};
