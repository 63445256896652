export class AtualizarPagamentoService {
  execute = (state, { payload }) => {
    let { payment, idx } = payload;
    idx--;

    const pedidos = JSON.parse(JSON.stringify(state.values.pedidos));
    const pedido = pedidos[idx];
    if (!pedido) return;
    const itIdx = pedido.pagamentos.findIndex(it => it.id === payment.id);

    if (itIdx === -1) return;
    pedidos[idx].pagamentos[itIdx] = payment;
    state.values = { ...state.values, pedidos };
  };
}
