import React from 'react';
import { Modal, Button, Table, Spinner } from 'react-bootstrap';
import styles from './faturamentoLoteModal.module.scss';

export function FaturamentoLoteModal({ show, onClose, modalStatus, isProcessing }) {

  // Função para determinar a classe CSS da label com base no status
  const getStatusLabelClass = (status) => {
    switch (status) {
      case 'Sucesso':
        return styles.labelSuccess;  // Verde
      case 'Erro':
        return styles.labelError;  // Vermelho
      case 'Processando':
        return styles.labelProcessing;  // Amarelo
      default:
        return styles.labelDefault;  // Cor padrão, evitar "undefined"
    }
  };

  return (
    <Modal show={show} onHide={isProcessing ? null : onClose} centered dialogClassName={styles.customModal}> {/* Custom modal class */}
      <Modal.Header closeButton={!isProcessing}>
        <Modal.Title>{isProcessing ? 'Processando...' : 'Status do Processamento'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isProcessing ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Processando...</span>
            </Spinner>
            <p>Faturando os pedidos selecionados. Por favor, aguarde...</p>
          </div>
        ) : (
          <div className={styles.tableWrapper}> {/* Adicionando um wrapper para a tabela com scroll se necessário */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Tipo do Faturamento</th>
                  <th>Número da Nota</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {modalStatus.map((status, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{status.tipoPedido && status.tipoPedido.nome ? status.tipoPedido.nome : 'N/A'}</td> {/* Tipo do Faturamento */}
                    <td>{status.nfe && status.nfe.numeroNfe ? status.nfe.numeroNfe : '---'}</td> {/* Número da Nota Fiscal */}
                    <td>
                      <span className={`${styles.label} ${getStatusLabelClass(status.status)}`}>
                        {status.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!isProcessing && (
          <Button variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}
