import React from 'react';
import moment from 'moment';
import 'react-table/react-table.css';
import { withRouter } from 'react-router-dom';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  InputText,
  InputDate,
  Select,
  handleInputChange
} from '../../../components/formulario/Formulario';
import Botao from '../../../components/botao/Botao';
import {
  // showInfo,
  // showWarning,
  // showQuestion,
  showError,
  // hideAlert,
  tagAlert,
  // setFocus,
  showLoadError
} from '../../../components/alert/Alert';
import { getTiposLancamentos, getCashflow } from './Cashflow.service';
import {
  // emptyText,
  emptyDate,
  emptySelect,
  makeList,
  // none,
  sameString,
  formatNumber,
  // validaCNPJ,
  // maskedCnpj,
  // validaCPF,
  // maskedCpf,
  ifShow,
  compareStrings,
  getNomePagina,
  menuPermiteConsulta,
  menuPermiteInclusao
} from '../../../shared/utils/Utils';
// import {
//   changeInList,
//   deleteInList,
//   appendInList
// } from "../../../shared/utils/TableUtils";
// import { XMLNS_1_0 } from "xmlchars";
import { ModalLancamentoFinanceiro } from '../../../components/modal/ModalLancamentoFinanceiro';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../shared/constants/MenuConstants';


const ScreenMode = {
  Parametrizing: 0,
  Generating: 1,
  Viewing: 2,
  Previewing: 3,
  Changing: 4
};

const TipoLancamento = {
  Despesa: 0,
  Receita: 1
};

const TipoConsulta = {
  Mensal: '1',
  Periodo: '2'
};

const TiposConsulta = [
  { value: TipoConsulta.Mensal, label: 'Mensal' },
  { value: TipoConsulta.Periodo, label: 'Período' }
];

const Monthes = [
  { value: '01', label: 'Janeiro' },
  { value: '02', label: 'Fevereiro' },
  { value: '03', label: 'Março' },
  { value: '04', label: 'Abril' },
  { value: '05', label: 'Maio' },
  { value: '06', label: 'Junho' },
  { value: '07', label: 'Julho' },
  { value: '08', label: 'Agosto' },
  { value: '09', label: 'Setembro' },
  { value: '10', label: 'Outubro' },
  { value: '11', label: 'Novembro' },
  { value: '12', label: 'Dezembro' }
];

let exeCount = 0;
const titulo = 'Fluxo de Caixa';
// const screenUrl = "/fluxodecaixa/";


const PATH_MENU = menus.COD_60940;
const RowType = {
  SaldoInicial: 11,
  SaldoInicialSeparator: 12,

  ReceitasTitle: 21,
  ReceitasData: 22,
  ReceitasTotal: 23,
  ReceitasSeparator: 24,

  DespesasTitle: 31,
  DespesasData: 32,
  DespesasTotal: 33,
  DespesasSeparator: 34,

  ResultadoDiario: 41,
  ResultadoSeparator: 42,

  SaldoPeriodo: 51
};

export const Colors = {
  OpeningBalance: { background: 'white', text: 'black' },
  PartialBalance: { background: 'white', text: 'black' },
  FinalBalance: { background: 'white', text: 'black' },
  PositiveValues: { background: 'seagreen', text: 'white' },
  NegativeValues: { background: '#ff3313', text: 'white' },
  EvenRows: { background: 'white', text: 'black' },
  OddRows: { background: '#e1e2e3', text: 'black' }
};

export class Cashflow extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      ...this.initParameters(moment()),
      lists: { tiposLancamentos: [] },
      showModalLancamento: false,
      lancamento: null
    };
  }

  loadTiposLancamentos = async () => {
    const { data } = await getTiposLancamentos();
    const lists = this.state.lists;
    lists.tiposLancamentos = makeList(data);
    this.setState({ lists });
  };

  cellName = date => {
    return 'valor' + moment(date).format('YYYY_MM_DD');
  };

  de = () => {
    if (this.isPeriod()) return this.state.dataInicial;
    return moment({
      year: this.state.ano,
      month: this.state.mes - 1,
      day: 1
    }).format('YYYY-MM-DD');
  };

  ate = () => {
    if (this.isPeriod()) return this.state.dataFinal;
    return moment(this.de())
      .endOf('month')
      .format('YYYY-MM-DD');
  };

  datas = () => {
    const result = [];
    for (
      let d = this.de();
      moment(d).isSameOrBefore(this.ate());
      d = moment(d).add(1, 'days')
    )
      result.push(d);
    return result;
  };

  headers = () => {
    const result = [];
    for (
      let d = this.de();
      moment(d).isSameOrBefore(this.ate());
      d = moment(d).add(1, 'days')
    )
      result.push(moment(d).format('DD/MM'));
    return result;
  };

  loadLancamentos = async () => {
    let dados = [];
    const zeros = {};
    let hasReceitas = false;
    let hasDespesas = false;
    for (
      let d = this.de();
      moment(d).isSameOrBefore(this.ate());
      d = moment(d)
        .add(1, 'days')
        .format('YYYY-MM-DD')
    )
      zeros[this.cellName(d)] = 0;

    const addRow = (
      rowType,
      tipoConta,
      codigoConta,
      descricaoConta,
      data,
      valor
    ) => {
      const hasValue = data && valor !== null && typeof valor !== 'undefined';
      let index = -1;
      for (let i = 0; i < dados.length; i++) {
        if (
          dados[i].rowType === rowType &&
          sameString(dados[i].tipoConta, tipoConta) &&
          sameString(dados[i].codigoConta, codigoConta)
        ) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        let newRow = {
          rowType,
          tipoConta,
          codigoConta,
          descricaoConta
        };
        if (hasValue) newRow = { ...newRow, ...zeros };
        index = dados.push(newRow) - 1;
      }
      if (hasValue) {
        let currentValue = dados[index][this.cellName(data)] || 0;
        dados[index][this.cellName(data)] = currentValue + valor;
      }
      return index;
    };

    const addLancamento = (tipo, conta, data, valor) => {
      let index = -1;
      switch (tipo) {
        case TipoLancamento.Receita:
          hasReceitas = true;
          index = addRow(
            RowType.ReceitasData,
            tipo,
            conta.codigo,
            conta.descricao,
            data,
            valor
          );
          addRow(RowType.ReceitasTotal, null, null, null, data, valor);
          break;
        case TipoLancamento.Despesa:
          hasDespesas = true;
          index = addRow(
            RowType.DespesasData,
            tipo,
            conta.codigo,
            conta.descricao,
            data,
            valor
          );
          addRow(RowType.DespesasTotal, null, null, null, data, valor);
          break;
        default:
          alert('tipo inválido: ' + tipo);
          break;
      }
      return index;
    };

    const { data } = await getCashflow(
      this.de(),
      this.ate(),
      this.state.tiposLancamentos
    );
    if (data && data.dias) {
      for (
        let d = this.de();
        moment(d).isSameOrBefore(this.ate());
        d = moment(d)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      ) {
        addRow(
          RowType.SaldoInicial,
          null,
          null,
          'Saldo Inicial',
          d,
          data.saldoInicial
        );
        // addRow(RowType.SaldoInicialSeparator);
        addRow(RowType.ResultadoDiario, null, null, 'Resultado Diário', d, 0);
        addRow(
          RowType.SaldoPeriodo,
          null,
          null,
          'Saldo do Período',
          d,
          data.saldoInicial
        );
      }
      const dias = data.dias;
      const indexSaldoInicial = addRow(
        RowType.SaldoInicial,
        null,
        null,
        'Saldo Inicial',
        this.de(),
        0
      );
      const indexReceitasTotal = addRow(
        RowType.ReceitasTotal,
        null,
        null,
        'Total Receitas',
        this.de(),
        0
      );
      const indexDespesasTotal = addRow(
        RowType.DespesasTotal,
        null,
        null,
        'Total Despesas',
        this.de(),
        0
      );
      const indexResultadoDiario = addRow(
        RowType.ResultadoDiario,
        null,
        null,
        'Resultado Diário',
        this.de(),
        0
      );
      const indexSaldoPeriodo = addRow(
        RowType.SaldoPeriodo,
        null,
        null,
        'Saldo Período',
        this.de(),
        0
      );

      // adiciona os lançamentos em dados
      for (let i = 0; i < dias.length; i++)
        addLancamento(
          dias[i].tipoConta,
          dias[i].conta,
          dias[i].data,
          dias[i].valor
        );

      if (hasReceitas) addRow(RowType.ReceitasTitle, null, null, 'Receitas');
      if (hasDespesas) addRow(RowType.DespesasTitle, null, null, 'Despesas');
      // totais
      for (
        let d = this.de();
        moment(d).isSameOrBefore(this.ate());
        d = moment(d)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      ) {
        dados[indexResultadoDiario][this.cellName(d)] =
          dados[indexReceitasTotal][this.cellName(d)] -
          dados[indexDespesasTotal][this.cellName(d)];
        dados[indexSaldoPeriodo][this.cellName(d)] =
          dados[indexSaldoInicial][this.cellName(d)] +
          dados[indexResultadoDiario][this.cellName(d)];
        let tomorrow =
          moment(d).isBefore(this.ate()) &&
          moment(d)
            .add(1, 'days')
            .format('YYYY-MM-DD');
        if (tomorrow)
          dados[indexSaldoInicial][this.cellName(tomorrow)] =
            dados[indexSaldoPeriodo][this.cellName(d)];
      }

      // ordena dados
      dados = dados.sort((a, b) => {
        if (a.rowType < b.rowType) return -1;
        if (a.rowType > b.rowType) return 1;
        if (a.tipoConta < b.tipoConta) return -1;
        if (a.tipoConta > b.tipoConta) return 1;
        return compareStrings(a.descricaoConta, b.descricaoConta);
      });
    }
    this.setState({ dados });
  };

  loadCashflow = async () => {
    let thisCount = ++exeCount;
    this.setState({
      screenMode: ScreenMode.Generating,
      dados: [],
      exeCount,
      showModalLancamento: false
    });
    try {
      await this.loadLancamentos();
      if (
        thisCount !== exeCount ||
        this.state.screenMode !== ScreenMode.Generating
      )
        return;
      this.setState({ screenMode: ScreenMode.Viewing });
    } catch (e) {
      showLoadError(this, titulo, e);
      this.setState({ screenMode: ScreenMode.Parametrizing, dados: [] });
    }
  };

  validate = () => {
    let message = '';
    let focus = '';
    if (this.isMonth() && !this.state.mes) {
      message = 'Selecione o mês.';
      focus = 'mes';
    } else if (this.isMonth() && !this.state.ano) {
      message = 'Selecione o ano.';
      focus = 'ano';
    } else if (this.isPeriod() && emptyDate(this.state.dataInicial)) {
      message = 'Informe a data inicial.';
      focus = 'dataInicial';
    } else if (this.isPeriod() && emptyDate(this.state.dataFinal)) {
      message = 'Informe a data final.';
      focus = 'dataFinal';
    } else if (
      this.isPeriod() &&
      moment(this.state.dataInicial).isAfter(this.state.dataFinal)
    ) {
      message = 'A data final deve ser superior ou igual a data inicial.';
      focus = 'dataFinal';
    } else if (emptySelect(this.state.tiposLancamentos)) {
      message = 'Selecione o(s) tipo(s) de lançamentos.';
      focus = 'tipoLancamento';
    }
    if (message) showError(this, titulo, message, focus);
    return !message;
  };

  loadCashflowClick = () => {
    if (this.validate()) this.loadCashflow();
  };

  cancelGeneration = () => {
    this.setState({ screenMode: ScreenMode.Parametrizing, dados: [] });
  };

  backParams = () => {
    this.setState({ screenMode: ScreenMode.Parametrizing, dados: [] });
  };

  initParameters = (referenceDate = moment()) => {
    return {
      screenMode: ScreenMode.Parametrizing,
      tipoConsulta: TipoConsulta.Mensal,
      dataInicial: moment(referenceDate).startOf('month'),
      dataFinal: moment(referenceDate).endOf('month'),
      mes: moment(referenceDate).format('MM'),
      ano: moment(referenceDate).format('YYYY'),
      tiposLancamentos: [0, 1],
      colunas: [],
      dados: []
    };
  };

  initParametersClick = () => {
    this.setState(this.initParameters());
  };

  componentDidMount = () => {
    this.loadTiposLancamentos();
  };

  isParametrizing = () => {
    return this.state.screenMode === ScreenMode.Parametrizing;
  };

  isGenerating = () => {
    return this.state.screenMode === ScreenMode.Generating;
  };

  isViewing = () => {
    return this.state.screenMode === ScreenMode.Viewing;
  };

  isChanging = () => {
    return this.state.screenMode === ScreenMode.Changing;
  };

  isMonth = () => {
    return sameString(this.state.tipoConsulta, TipoConsulta.Mensal);
  };

  isPeriod = () => {
    return sameString(this.state.tipoConsulta, TipoConsulta.Periodo);
  };

  preview = async () => {
    this.setState({}, () => {
      window.print();
      this.setState({});
    });
  };

  render() {
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="Filtros">
              <div className="row">
                <div className="col-3">
                  <Select
                    value={this.state.tipoConsulta}
                    name="tipoConsulta"
                    label="Tipo de consulta:"
                    disabled={!this.isParametrizing()}
                    options={TiposConsulta}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isMonth(), 'col-3')}>
                  <Select
                    value={this.state.mes}
                    name="mes"
                    label="Mês:"
                    disabled={!this.isParametrizing()}
                    options={Monthes}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isMonth(), 'col-3')}>
                  <InputText
                    value={this.state.ano}
                    name="ano"
                    label="Ano:"
                    type="number"
                    maxlength={4}
                    disabled={!this.isParametrizing()}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isPeriod(), 'col-3')}>
                  <InputDate
                    value={this.state.dataInicial}
                    name={'dataInicial'}
                    label="Data inicial:"
                    disabled={!this.isParametrizing()}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className={ifShow(this.isPeriod(), 'col-3')}>
                  <InputDate
                    value={this.state.dataFinal}
                    name={'dataFinal'}
                    label="Data final:"
                    disabled={!this.isParametrizing()}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-3">
                  <Select
                    multi
                    sender={this}
                    //value={this.state.tiposLancamentos}
                    name="tiposLancamentos"
                    label="Tipos de lançamentos:"
                    disabled={!this.isParametrizing()}
                    options={this.state.lists.tiposLancamentos}
                    //onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title="Fluxo de caixa"
              accordion
              accordionDefaultDisplay={true}
            >
              <div className="table-content-bd">
                <Report
                  dados={this.state.dados}
                  datas={this.datas()}
                  headers={this.headers()}
                  cellName={this.cellName}
                  isPrint={false}
                />
              </div>
            </SectionContent>
          </SectionContainer>
          <div className="section-footer">{this.tagButtons()}</div>
        </LinxPostos>
        <Report
          dados={this.state.dados}
          datas={this.datas()}
          headers={this.headers()}
          cellName={this.cellName}
          isPrint={true}
        />
        {tagAlert(this)}
        {this.state.showModalLancamento ? (
          <>
            <ModalLancamentoFinanceiro
              active={this.state.showModalLancamento}
              handleCloseModal={lancamento => {
                if (lancamento) this.loadCashflow();
                else
                  this.setState({
                    showModalLancamento: false
                  });
              }}
            />
          </>
        ) : (
          <></>
        )}
      </>
    );
  }

  tagButtons = () => {
    switch (this.state.screenMode) {
      case ScreenMode.Parametrizing:
        return (
          <>
            {menuPermiteConsulta(PATH_MENU) ? (
              <Botao
                title="Abrir o Fluxo de Caixa"
                icon="icon-lx-new"
                onClick={this.loadCashflowClick}
              />
            ) : (
              <></>
            )}
            <Botao
              title="Resetar os Parâmetros"
              secondary
              icon="icon-lx-close"
              onClick={this.initParametersClick}
            />
          </>
        );
      case ScreenMode.Generating:
        return (
          <>
            <Botao
              title="Cancelar a Listagem"
              secondary
              icon="icon-lx-close"
              onClick={this.cancelGeneration}
            />
          </>
        );
      case ScreenMode.Viewing:
        return (
          <>
            <Botao
              title="Visualizar"
              icon="icon-lx-print"
              onClick={() => this.preview()}
            />
            <Botao
              title="Voltar aos Parâmetros"
              secondary
              icon="icon-lx-close"
              onClick={this.backParams}
            />
            {menuPermiteInclusao(PATH_MENU) ? (
              <Botao
                title="Incluir Lançamento"
                icon="icon-lx-plus"
                onClick={() => {
                  this.setState({ showModalLancamento: true });
                }}
              />
            ) : (
              <></>
            )}
          </>
        );
      default:
        return null;
    }
  };
}

const Report = ({ dados, datas, headers, cellName, isPrint }) =>
  dados && dados.length > 0 ? (
    <table className={isPrint ? 'print' : null}>
      <thead>
        <tr>
          <th />
          {headers.map(h => {
            return <th style={{ textAlign: "right" }} align="right">{h}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {dados.map((i, index) => {
          const bg =
            i.rowType === RowType.ReceitasTitle
              ? Colors.PositiveValues.background
              : i.rowType === RowType.DespesasTitle
              ? Colors.NegativeValues.background
              : i.rowType === RowType.saldoInicial
              ? Colors.OpeningBalance.background
              : i.rowType === RowType.ResultadoDiario
              ? Colors.PartialBalance.background
              : i.rowType === RowType.SaldoPeriodo
              ? Colors.FinalBalance.background
              : index & 1
              ? Colors.OddRows.background
              : Colors.EvenRows.background;
          return (
            <tr bgcolor={bg}>
              <td>{i.descricaoConta}</td>
              {datas.map(d => {
                return <td align="right">{formatNumber(i[cellName(d)])}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : null;

Cashflow = withRouter(Cashflow);
