import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  InputText,
  Select,
  handleInputChange
} from '../../../components/formulario/Formulario';
import SectionHeader from "../../../components/section/Header";
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import Botao from '../../../components/botao/Botao';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import ReactTable from "react-table";
import {
  getTrocos,
  incluiTroco,
  alteraTroco,
  excluiTroco,
  montaComboTipoFormaPagamento,
  montaComboTipoLimite
} from "./RegrasTroco.service";

import {
  toFloatFormattedDisplay,
  menuPermiteInclusao,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  sameInt
} from '../../../shared/utils/Utils';
import {
  getFormaPagamento,
  getFormasPagamento
} from "../formas-pagamento/FormasPagamento.service";
import {
  tagAlert,
  showError,
  showInfo,
  showQuestion,
  hideAlert,
  showWait,
  showDeleteError,
  showSaveError,
  hideWait
} from "../../../components/alert/Alert";
import Footer from "../../../components/core/footer/Footer";
import { menus } from "../../../shared/constants/MenuConstants";
import { isAllowed } from "../../../shared/utils/TableUtils";

const Formas = {
  Cheque: -2,
  Dinheiro: -1,
  Ticket: -3,
  Vale: -5
};

const FormasAceitas = {
  Dinheiro: -1,
  Vale: -5
};

const Tipos = {
  Percentual: 0,
  Valor: 1
};

const PATH_MENU = menus.COD_60520;
const MAIN_PATH = "/cadastro/regra-troco";
const titulo = "Regras de troco";

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    // const { codigo } = this.props.troco.codigo ?
    //     Math.abs(this.props.troco.codigo) : Formas.Cheque;                

    let {
      codigo = Formas.Cheque,
      codigoFormaPagamento = FormasAceitas.Dinheiro,
      codigoAntigo = 0,
      tipoLimite = Tipos.Percentual,
      limite,
      inativo = false,
      rowVersion = null
    } = this.props.troco;

    const {
      trocosExistentes = [],
      shouldRender = false,
      isEditing = false
    } = this.props;

    codigoAntigo = isEditing ? codigo : codigoAntigo;

    this.state = {
      codigo,
      codigoAntigo,
      formas: [],
      ativo: !inativo,
      codigoFormaPagamento,
      formasAceitas: [],
      tipoLimite,
      tipos: [],
      limite,
      rowVersion,
      trocosExistentes,
      trocoSel: [],
      shouldRender,
      isEditing
    };

  }

  async componentDidMount() {
    const formasAceitas = [];
    const { data: dinheiro } = await getFormaPagamento(FormasAceitas.Dinheiro);
    if (!dinheiro.inativo) {
      formasAceitas.push({
        label: dinheiro.descricao,
        value: dinheiro.codigo
      });
    }

    const { data: vale } = await getFormaPagamento(FormasAceitas.Vale);
    if (!vale.inativo) {
      formasAceitas.push({
        label: vale.descricao,
        value: vale.codigo
      });
    }

    if (formasAceitas.length === 0) {
      showError(this.props.sender, titulo,
        "As formas de pagamento aceitas para troco (dinheiro e vale) não " +
        "estão ativas no cadastro de formas de pagamento");
    }

    const { data: dataTipoForma } = await montaComboTipoFormaPagamento();
    const formas = [];
    dataTipoForma.forEach((fp) => {
      formas.push({
        label: fp.descricao, value: fp.codigo > 0 ?
          fp.codigo * (-1) : fp.codigo
      });
    })
    // formas.push({ label: "Cheque", value: Formas.Cheque });
    // formas.push({ label: "Dinheiro", value: Formas.Dinheiro });
    // formas.push({ label: "Ticket", value: Formas.Ticket });
    // formas.push({ label: "Vale", value: Formas.Vale });

    const { data: dataTipoLimite } = await montaComboTipoLimite();
    const tipos = [];
    dataTipoLimite.forEach((l) => {
      tipos.push({ label: l.descricao, value: l.codigo });
    });
    // tipos.push({ label: "Percentual", value: Tipos.Percentual });
    // tipos.push({ label: "Valor", value: Tipos.Valor });

    this.setState({ formas, formasAceitas, tipos });
  }

  handleCodigoChange = (event) => {
    let value = event.target.value;
    let codigo = "0";
    switch (Number(value)) {
      case Formas.Cheque:
        codigo = String(Formas.Cheque);
        break;
      case Formas.Dinheiro:
        codigo = String(Formas.Dinheiro);
        break;
      case Formas.Ticket:
        codigo = String(Formas.Ticket);
        break;
      case Formas.Vale:
        codigo = String(Formas.Vale);
        break;
      default:
        break;
    }
    this.setState({ codigo });
  }

  handleAceitaEmChange = (event) => {
    let value = event.target.value;
    let codigoFormaPagamento = Number(value) === FormasAceitas.Dinheiro ?
      String(FormasAceitas.Dinheiro) : String(FormasAceitas.Vale);
    this.setState({ codigoFormaPagamento });
  }

  handleTipoLimiteChange = (event) => {
    let value = event.target.value;
    let limite = this.state.limite;
    if (value !== this.state.tipoLimite)
      limite = 0;
    let tipoLimite = Number(value) === Tipos.Percentual ?
      String(Tipos.Percentual) : String(Tipos.Valor);
    this.setState({ tipoLimite, limite });
  }

  trocoExists = (forma, aceitaEm) => {
    if (this.state.isEditing) // em edição existe, mas não tem problema
      return false;

    let lista = this.state.trocosExistentes;

    for (let i = 0; i < lista.length; i++) {
      const troco = lista[i];
      if (Number(forma) === Number(troco.codigo) &&
        Number(aceitaEm) === Number(troco.codigoFormaPagamento)) {
        showError(this.props.sender, titulo, "Já existe um cadastro deste tipo de troco " +
          "para a forma de pagamento informada");
        return true;
      }
    }
    return false;
  }

  getIsAllowedMethod = () => {
    const { tipoLimite } = this.state;

    const method = (values) => {
      return isAllowed(values,
        sameInt(tipoLimite, Tipos.Valor) ? 999999 : 100);
    };

    return method;
  }

  isFormValid() {
    const {
      tipoLimite,
      limite,
    } = this.state;

    if (!limite) {
      showError(this.props.sender, titulo, "Informe o limite do troco");
      return false;
    }

    if (Number(tipoLimite) === Tipos.Valor &&
      (limite < 0 || limite > 999999)) {
      showError(this.props.sender, titulo, "O valor limite de troco deve estar entre" +
        " 0 e 999.999");
      return false;
    }

    if (Number(tipoLimite) === Tipos.Percentual &&
      (limite < 0.01 || limite > 100)) {
      showError(this.props.sender, titulo, "O percentual limite de troco deve estar" +
        " entre 0,01 e 100");
      return false;
    }

    /* Percentual	Aceitar valores entre 0,01 e 100,00.
    Valor	Aceitar valores entre 0,00 e 999999,00. */

    return true;
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }
    let {
      isEditing,
      codigo,
      codigoAntigo,
      codigoFormaPagamento,
      tipoLimite,
      limite,
      ativo,
      rowVersion
    } = this.state;

    let inativo = !ativo;

    if (!this.isFormValid() || this.trocoExists(codigo,
      codigoFormaPagamento)) {
      return;
    }

    const [action, params] =
      isEditing
        ? [
          alteraTroco,
          [
            codigo,
            codigoAntigo,
            codigoFormaPagamento,
            tipoLimite,
            limite,
            inativo,
            rowVersion
          ]
        ]
        : [
          incluiTroco,
          [
            codigo,
            codigoAntigo,
            codigoFormaPagamento,
            tipoLimite,
            limite,
            inativo
          ]
        ];

    try {
      showWait(this.props.sender, titulo,
        "Por favor, aguarde enquanto a regra de troco é salva.");
      await action(...params);
      const msg = rowVersion ?
        "Regra de troco alterada com sucesso!" :
        "Regra de troco incluída com sucesso!";
      showInfo(
        this.props.sender,
        titulo,
        msg,
        null,
        () => {
          hideAlert(this.props.sender);
          this.props.updateMethod();
          this.dismiss();
        }
      );
    } catch (e) {
      showSaveError(this.props.sender, titulo, e);
    }
  };

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }
    const {
      codigoFormaPagamento,
      tipoLimite,
      codigo
    } = this.state;

    showQuestion(
      this.props.sender,
      titulo,
      "Deseja excluir a regra de troco?",
      null,
      async (resp) => {
        if (resp) {
          try {
            showWait(this.props.sender, titulo,
              "Por favor, aguarde enquanto a regra de troco é excluída.");
            await excluiTroco(codigoFormaPagamento, tipoLimite, codigo);
            showInfo(
              this.props.sender,
              titulo,
              "Regra de troco excluída com sucesso!",
              null,
              () => {
                this.dismiss();
                hideAlert(this.props.sender);
              }
            );
          } catch (err) {
            showDeleteError(this.props.sender, titulo, err);
          }
        } else {
          hideAlert(this.props.sender);
        }
      }
    );
  };


  handleCancelar = () => {
    showQuestion(
      this.props.sender,
      titulo,
      "Deseja realmente cancelar a operação?",
      null,
      (resp) => {
        if (resp) {
          hideAlert(this.props.sender);
          this.props.history.push(MAIN_PATH);
          this.dismiss();
        } else {
          hideAlert(this.props.sender);
        }
      }
    );
  };

  dismiss() {
    this.props.onClose();
    this.props.updateMethod();
  }

  getLimiteMaxLength = () => {
    return (Number(this.state.tipoLimite) === Tipos.Percentual ? 6 : 9);
  }

  render() {
    const {
      codigo,
      codigoFormaPagamento,
      tipoLimite,
      formas,
      formasAceitas,
      tipos,
      limite,
      shouldRender,
      isEditing
    } = this.state;

    if (!shouldRender) {
      return (<></>);
    }

    const { sender } = this.props;

    return (
      <>
        <SectionContent
          title="Nova configuração de troco">
          <div className="row">
            <div className="col-5">
              <Select
                label="Forma de pagamento:"
                name="codigo"
                value={codigo}
                onChange={this.handleCodigoChange}
                options={formas}
                disabled={isEditing}
              />
            </div>
            <div className="col-5">
              <Select
                label="Aceitar troco em:"
                name="codigoFormaPagamento"
                value={codigoFormaPagamento}
                onChange={this.handleAceitaEmChange}
                options={formasAceitas}
                disabled={isEditing}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <Select
                label="Tipo de limite para troco:"
                name="tipoLimite"
                value={tipoLimite}
                onChange={this.handleTipoLimiteChange}
                options={tipos}
              />
            </div>
            <div className="col-5">
              <InputText
                label={sameInt(tipoLimite, Tipos.Percentual) ?
                  "Limite (%):" : "Limite (R$):"}
                value={limite}
                name="limite"
                type="number"
                maxlength={this.getLimiteMaxLength()}
                decimalScale={2}
                allowNegative={false}
                onChange={this.handleInputChange}
                isAllowed={this.getIsAllowedMethod()}
              />
            </div>
          </div>
        </SectionContent>
        <Footer
          saveAction={
            !isEditing ? this.handleSalvar : (
              menuPermiteAlteracao(PATH_MENU) ? this.handleSalvar : null)}
          deleteAction={!isEditing ? null : (
            menuPermiteExclusao(PATH_MENU) ? this.handleExcluir : null
          )}
          cancelAction={this.handleCancelar}
        />
        {tagAlert(sender)}
      </>
    );
  }
}

Form = withRouter(Form);

class RegrasTroco extends Component {
  state = {
    trocos: [],
    trocoSel: {},
    telaDisponivel: false,
    codigo: 0,
    showForm: false,
    isEditing: false
  };

  closeForm = () => {
    const shouldRender = false;
    const showForm = false;
    const trocoSel = {};
    this.setState({ shouldRender, showForm, trocoSel });
  }

  doMount = async () => {
    showWait(this, titulo,
      "Por favor, aguarde enquanto os dados são carregados.");
    const { data: dataTrocos } = await getTrocos(false);
    const trocos = dataTrocos.result;

    const { data: dataFormas } = await getFormasPagamento(false, true);

    const formas = dataFormas.result;
    trocos.forEach((t) => {
      formas.forEach((f) => {
        if (Number(t.codigo) === Number(f.codigo)) {
          t.formaPagamento = f.descricao;
          return;
        }
      });

      formas.forEach((f) => {
        if (Number(t.codigoFormaPagamento) === Number(f.codigo)) {
          t.aceitaTrocoEm = f.descricao;
          return;
        }
      });

      t.descricaoLimite = Number(t.tipoLimite) === Tipos.Percentual ?
        "Percentual" : "Valor";
    });

    this.setState({
      trocos,
      telaDisponivel: true
    });
    hideWait(this);
  }

  componentDidMount() {
    this.doMount();
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    try {
      if (this.state.telaDisponivel && rowInfo) {
        this.setState({
          trocoSel: rowInfo.original,
          showForm: true,
          isEditing: true
        });
        //this.props.history.push("/troco");
      }
    } catch (error) {
      //console.log(error.message);
    }
  }

  render() {
    const {
      trocos,
      trocoSel,
      showForm,
      isEditing
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionHeader
            right={
              <div className="button-container">
                {menuPermiteInclusao(PATH_MENU) ? (
                  <Botao ic icon="icon-lx-plus" onClick={() => {
                    this.setState({
                      formaSel: {},
                      showForm: true,
                      isEditing: false
                    });
                    //this.props.history.push("/troco/new")
                  }} />
                ) : <></>}
              </div>
            }
          />
          <SectionContainer>
            <SectionContent
              title="Configurações de Troco"
              accordion>
              <div className="table-ctn">
                <ReactTable
                  data={trocos}
                  columns={[
                    {
                      accessor: "formaPagamento",
                      Header: "Forma de Pagamento",
                      width: 250,
                      filterable: false
                    },
                    {
                      accessor: "aceitaTrocoEm",
                      Header: "Aceitar troco em",
                      width: 250,
                      filterable: false
                    },
                    {
                      accessor: "descricaoLimite",
                      Header: "Tipo de limite para troco",
                      width: 250,
                      filterable: false
                    },
                    {
                      accessor: "limite",
                      Header: "Limite",
                      width: 150,
                      filterable: false,
                      Cell: props => <div>
                        {toFloatFormattedDisplay(props.value)}
                      </div>
                    },
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        this.handleTableClick(state, rowInfo,
                          column, instance, e);
                      }
                    };
                  }}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando registros..."
                  noDataText="Sem registros para exibir"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  defaultPageSize={5}
                />
              </div>
            </SectionContent>
            {showForm ? (
              <Form
                troco={trocoSel ? trocoSel : null}
                trocosExistentes={trocos ? trocos : null}
                shouldRender={showForm}
                isEditing={isEditing}
                onClose={this.closeForm}
                updateMethod={this.doMount}
                sender={this}
              />) : (<></>)
            }
          </SectionContainer>
        </LinxPostos>
        {tagAlert(this)}
      </>
    );
  }
}

RegrasTroco = withRouter(RegrasTroco);
export { RegrasTroco };
