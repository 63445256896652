import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiBuscarTransportadoraService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/ApiBuscaTransportadora.service';
import { ApiListarCentrosMonetariosService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/ApiListarCentroMonetarios.service';
import { ComodatoService } from '../../../services/con-comodato/Comodato.service';
import { EmpresaService } from '../../../services/empresa/Empresa.service';
import { FuncionarioService } from '../../../services/funcionarios/Funcionario.service';
import { SessionManager } from '../../../shared/utils/sessionManager';
import { ConfiguracoesGeraisService } from '../../../services/configuracoesGerais/ConfiguracoesGerais.service';
import conferenciaCaixa from '../../../pages/financeiro/conferencia-caixa/service/ConferenciaCaixa.service';
import { getCentrosMonetariosCadastradosManualmente } from '../../../services/CentrosMonetarios.service';


const VALUES_OS_DATA = 'values_os_data';
const initialState = {
  initialized: false,
  loading: false,
  error: null,
  origens: [],
  tiposPedidos: [],
  status: [],
  pagamentos: [],
  operadores: null,
  acessos: [],
  centrosMonetarios: [],
  transportadoras: [],
  parametros: [],
  turnoAtual: null,
  maquinas: null,
  tiposFrete: [
    { id: 2, nome: 'ENTREGA TERCEIROS' },
    { id: 3, nome: 'ENTREGA DA LOJA' },
    { id: 4, nome: 'CLIENTE RETIRA' },
    { id: 9, nome: 'SEM TRANSPORTE' },
  ],
};

export const fetchSharedDataInit = createAsyncThunk('solicitacaoReservaSharedData/init', async () => {
  const dados = sessionManager.getEncryptedData(VALUES_OS_DATA) || {};
  if (!dados.redes) {
    dados.redes = await ComodatoService.getInstance().listarRedes();
  }
  if (!dados.operadores) {
    dados.operadores = await FuncionarioService.getInstance().listar();
  }
  if (!dados.maquinas) {
    dados.maquinas = await conferenciaCaixa.listaMaquinas();
  }
  if (!dados.bandeiras) {
    dados.bandeiras = await ComodatoService.getInstance().listarBandeiras();
  }
  if (dados.origens) return dados;
  // const dados = {};
  try {
    dados.origens = await ComodatoService.getInstance().listarOrigens();
    dados.tiposPedidos = await ComodatoService.getInstance().listarTiposDePedidos();
    dados.status = await ComodatoService.getInstance().listarStatus();
    dados.empresa = await EmpresaService.getInstance().recuperar();
    dados.pagamentos = await ComodatoService.getInstance().listarFormasPagamento();
    dados.bandeiras = await ComodatoService.getInstance().listarBandeiras();
    dados.redes = await ComodatoService.getInstance().listarRedes();
    dados.centrosMonetarios = await ApiListarCentrosMonetariosService.getInstance().execute();
    dados.centroMonetarioCadastradosManualmente = await getCentrosMonetariosCadastradosManualmente();

    const acessos = await FuncionarioService.getInstance().recuperarAcessosPorMenuId(17810);
    (acessos || []).forEach(acesso => {
      dados[`acessos_${acesso.menuId}_${acesso.codigoOperacao}`] = acesso;
    });
    dados.transportadoras = await ApiBuscarTransportadoraService.getInstance().execute('');
    dados.parametro528 = await ConfiguracoesGeraisService.getInstance().byId(528);
    dados.parametro522 = await ConfiguracoesGeraisService.getInstance().byId(522);
    dados.parametro542 = await ConfiguracoesGeraisService.getInstance().byId(542);
    dados.parametro575 = await ConfiguracoesGeraisService.getInstance().byId(575);
    return dados;
  } catch (error) {
    throw new Error(`Falha carregando os dados: ${error.message}`);
  }
});

export const fetchSharedDataReset = createAsyncThunk('solicitacaoReservaSharedData/reset', async () => {
  SessionManager.getInstance().remove(VALUES_OS_DATA);
});


const sessionManager = new SessionManager();
const slice = createSlice({
  name: 'solicitacaoReservaSharedData',
  initialState,
  reducers: {
    updateState: (state, { payload }) => {
      const { data, name } = payload;
      console.log('updateState', name, data);
      state[name] = data;
      sessionManager.saveEncryptedData(VALUES_OS_DATA, state);
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSharedDataInit.pending, state => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchSharedDataInit.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      Object.assign(state, { ...action.payload, initialized: true });
      sessionManager.saveEncryptedData(VALUES_OS_DATA, state);
    });
    builder.addCase(fetchSharedDataInit.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      SessionManager.getInstance().remove(VALUES_OS_DATA);
    });
    builder.addCase(fetchSharedDataReset.fulfilled, state => {
      Object.assign(state, { ...initialState });
    });
  },
});

export const useSolicitacaoReservaSharedData = () => slice.actions;
export const selectSolicitacaoReservaSharedData = state => state.solicitacaoReservaSharedData;
export default slice.reducer;
