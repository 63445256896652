import axios from "axios";

import { TOKEN_PASS_AXIOS } from "../../../../shared/constants/Const";
import { getUrl } from "../../../../shared/utils/Utils";
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getEstoqueProdutos(
  cnpjEstabelecimento,
  data,
  codigosSetor,
  codigosAlmoxarifado,
  codigosProduto,
  consultaDashBoard = false
) {
  return axios.post(`LxApi/v1/Estoque/Consulta`, {
    query: {
      cnpjEstabelecimento,
      data,
      codigosSetor,
      codigosAlmoxarifado,
      codigosProduto,
      consultaDashBoard
    }
  });
}

export function getTotaisEstoqueProdutos(
  cnpjEstabelecimento,
  data,
  codigosSetor,
  codigosAlmoxarifado,
  codigosProduto
) {
  return axios.post(`/LxApi/v1/Estoque/ConsultaTotais`, {
    query: {
      cnpjEstabelecimento,
      data,
      codigosSetor,
      codigosAlmoxarifado,
      codigosProduto
    }
  });
}
