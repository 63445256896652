import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { handleInputChange } from '../../../components/formulario/Formulario';

import {
  executaTrocaSenha,
  salvaSenhaPostoPop
} from '../boas-vindas/BoasVindas.service';

import PerfectScrollbar from 'react-perfect-scrollbar';

import styles from '../login/Login.module.scss';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import logoSeller from '../../../../assets/images/linx/logo-seller-new.png';
import logoEmporio from '../../../../assets/images/linx/logo-emporio-new.png';
import urlLogin from '../../../authentication/Auth.interceptor';
import urlEmpresas from '../../../authentication/AuthEmpresas';
import { MAX_LENGTH_CHAVE, MAX_LENGTH_PASSWORD } from '../../../shared/constants/Const';
import notify from 'devextreme/ui/notify';
import { getSystemInfoByTipoSite, TipoSiteOption, baseURL } from '../../../shared/utils/Utils';
import queryString from 'query-string';
let logoSite;
if (TIPO_SITE == 'RetaguardaSellerWeb'){
  logoSite = logoSeller;
}else{
  logoSite  = logoEmporio;
}

function searchingFor(searchFilter) {
  return function(x) {
    return (
      x.razaoSocial.toLowerCase().includes(searchFilter.toLowerCase()) ||
      x.cnpj.includes(searchFilter) ||
      !searchFilter
    );
  };
}

class EsqueceuSenha extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const { nome } = this.props;

    this.state = {
      nome,
      systemInfo: {}
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    var chave = params.chave;
    var email = params.email;
    const systemInfo = getSystemInfoByTipoSite();

    this.setState({ chave, email, systemInfo: systemInfo });

    var urlOrigem = '';
    var tipoSite = '';
    const values = queryString.parse(this.props.location.search);
    urlOrigem = baseURL(values.urlOrigem);
    tipoSite = values.tipoSite;
    this.setState({ urlOrigem: urlOrigem, tipoSite: tipoSite });


  }

  efetivarTrocaSenha = async () => {
    try {
      const respSalvaSenha = await salvaSenhaPostoPop(
        this.state.email,
        this.state.senha,
        this.state.selectedEmpresa
          ? this.state.selectedEmpresa.urLs.find(
              u =>
                u.tipoSite === 'RetaguardaPostoPop' ||
                u.tipoSite === 'RetaguardaEmporioPop'
            ).url
          : this.state.authorizedCNPJs[0].urLs.find(
              u =>
                u.tipoSite === 'RetaguardaPostoPop' ||
                u.tipoSite === 'RetaguardaEmporioPop'
            ).url
      );
      if (respSalvaSenha) {
        this.setState({ loggingIn: false });
        notify('Senha alterada com sucesso', 'info', 2000, 'top');
        this.props.history.push('/');
      } else {
        this.setState({ loggingIn: false });
        notify('Erro na alteração da senha', 'error', 2000, 'top');
      }
    } catch (err) {
      this.setState({ loggingIn: false });
      notify('Erro na alteração da senha', 'error', 2000, 'top');
    }
  };

  trocarSenha = async e => {
    e.preventDefault();
    this.setState({ redefinindo: true });
    try {
      //validar senha e confirmacao
      const { chaveRecuperacao, senha, confirmeSenha } = this.state;
      if (senha !== confirmeSenha) {
        this.setState({ redefinindo: false });
        notify('As senhas não conferem.', 'error', 2000, 'top');
      } else {
        if(!chaveRecuperacao || 
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(chaveRecuperacao) == false)
        {
          this.setState({ redefinindo: false });
          notify('A chave informada não é válida.', 'error', 2000, 'top');
        }
        else
        {
          try {
            const resp = await executaTrocaSenha(chaveRecuperacao, senha);
            if (resp.data.success) {
              notify("Senha redefinida com sucesso!", 'info', 2000, 'top');
              this.setState({ redefinindo: false });
              this.props.history.push('/login/?urlOrigem='+ this.state.urlOrigem +'&tipoSite=' + this.state.tipoSite);

            } else {
              this.setState({ loggingIn: false });
              notify(resp.data.message, 'error', 2000, 'top');
            }
          } catch (err) {
            this.setState({
              error: 'Houve um problema ao redefinir a sua senha.', redefinindo: false 
            });
          }
        }
      }
    }catch (err) {
      this.setState({
        error: 'Houve um problema ao redefinir a sua senha.', redefinindo: false
      });
    }
  }

  render() {
    const { email, chaveRecuperacao, senha, confirmeSenha } = this.state;

    return (
      <div className={styles.Login}>
        <div className={`container-fluid ${styles.pageLogin}`}>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div
                  className={`col-xs-3 col-md-3 col-sm-12 ${
                    TIPO_SITE === TipoSiteOption.RetaguardaPostoPop
                      ? styles.bgPrimary
                      : styles.bgPrimaryEmporio
                  } ${styles.columnHeight}
                  ${styles.columnHeightA} d-flex align-items-center`}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                          <center className="message">
                            {this.state.systemInfo.message}
                          </center>
                        <h2
                          className={`${styles.textBrandScreen} text-uppercase text-truncate`}
                        >
                          REDEFINIÇÃO DE SENHA
                        </h2>
                      </div>
                      <p className={styles.copyright}>
                        {' '}
                        &copy; Linx - Todos direitos reservados
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className={`${styles.columnHeight} ${styles.columnHeightB} d-flex align-items-center`}
                  >
                    <div className="row">
                      <div className="col-12">
                        <picture className="text-center logotipo center-align">
                          <source media="(max-width: 768px)" srcSet={logoSite} />
                          <source srcSet={logoSite} />
                          <img srcet={logoSite} alt="Logo Linx" />
                        </picture>
                      </div>
                      {this.state.showEmpresas ? (
                        <div className={styles.authorizedCNPJs}>
                          <div className={`${styles.searchInput} input-group`}>
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="icon icon-lx-search"></i>
                              </span>
                            </div>
                            <input
                              onChange={this.filterCNPJs}
                              type="text"
                              className="form-control"
                              placeholder="Busque e/ou selecione uma empresa abaixo"
                            />
                          </div>
                          <div className={styles.empresas}>
                            <PerfectScrollbar>
                              <table
                                id="empresasAutorizadas"
                                className="table table-hover table-sm table-visualization"
                              >
                                <tbody className="table-visualization__body">
                                  {this.state.authorizedCNPJs
                                    .filter(
                                      searchingFor(this.state.searchFilter)
                                    )
                                    .map(empresa => (
                                      <tr
                                        key={empresa.chave}
                                        onClick={() =>
                                          this.selectEmpresa(empresa)
                                        }
                                      >
                                        <td> {empresa.cnpj} </td>
                                        <td> {empresa.razaoSocial} </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </PerfectScrollbar>
                          </div>

                          <div className={styles.buttons}>
                            <button
                              type="submit"
                              className="btn btn-light"
                              disabled={this.state.redefinindo}
                              onClick={this.returnToLogin}
                            >
                              VOLTAR AO LOGIN
                            </button>
                            <button
                              className="btn btn-primary"
                              disabled={
                                !this.state.selectedEmpresa ||
                                this.state.redefinindo
                              }
                              onClick={this.efetivarTrocaSenha}
                            >
                              {this.state.redefinindo ? (
                                <>
                                  <svg
                                    className={styles.spinner}
                                    viewBox="0 0 50 50"
                                  >
                                    <circle
                                      className={styles.path}
                                      cx="25"
                                      cy="25"
                                      r="20"
                                      fill="none"
                                      strokeWidth="5"
                                    ></circle>
                                  </svg>
                                  REDEFININDO SENHA
                                </>
                              ) : (
                                'REDEFINIR'
                              )}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <form
                          className={styles.formLogin}
                          autoComplete="off"
                          onSubmit={e => this.trocarSenha(e)}
                        >
                          <div className="form-group">
                            <label htmlFor="chaveRecuperacao">Chave de recuperação</label>
                            <input
                              type="text"
                              id="chaveRecuperacao"
                              name="chaveRecuperacao"
                              value={chaveRecuperacao}
                              onChange={e =>
                                this.setState({ chaveRecuperacao: e.target.value })
                              }
                              className="form-control"
                              placeholder="Chave"
                              required
                              disabled={
                                this.state.loggingIn || this.state.connecting
                              }
                              maxLength={MAX_LENGTH_CHAVE}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="password">Senha</label>
                            <input
                              type="password"
                              id="senha"
                              name="senha"
                              value={senha}
                              onChange={e =>
                                this.setState({ senha: e.target.value })
                              }
                              className="form-control"
                              placeholder="Senha"
                              required
                              disabled={
                                this.state.loggingIn || this.state.connecting
                              }
                              maxLength={MAX_LENGTH_PASSWORD}
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="password">Confirme senha</label>
                            <input
                              type="password"
                              id="confirmeSenha"
                              name="confirmeSenha"
                              value={confirmeSenha}
                              onChange={e =>
                                this.setState({ confirmeSenha: e.target.value })
                              }
                              className="form-control"
                              placeholder="Senha"
                              required
                              disabled={
                                this.state.loggingIn || this.state.connecting
                              }
                              maxLength={MAX_LENGTH_PASSWORD}
                            />
                          </div>
                          <button
                            type="submit"
                            className="btn btn-secondary btn-block"
                            disabled={this.state.loggingIn}
                          >
                            {this.state.redefinindo ? (
                              <>
                                <svg
                                  className={styles.spinner}
                                  viewBox="0 0 50 50"
                                >
                                  <circle
                                    className={styles.path}
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    strokeWidth="5"
                                  ></circle>
                                </svg>
                                REDEFININDO SENHA
                              </>
                            ) : (
                              'REDEFINIR'
                            )}
                          </button>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EsqueceuSenha = withRouter(EsqueceuSenha);
export { EsqueceuSenha };
