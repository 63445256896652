import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './ConferenciaCaixaFiltro.module.scss';
import { DateBox, DropDownBox, TreeView, TextBox } from 'devextreme-react';
import moment from 'moment';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina, sortList } from '../../../../shared/utils/Utils';
import notify from 'devextreme/ui/notify';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import {
  SectionContainer,
  SectionContent
} from '../../../../components/section/Content';
import ptMessages from '../../../../../assets/js/pt.json';
import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, { Selection } from 'devextreme-react/data-grid';
import ConferenciaCaixaResult from '../conferencia-caixa-result/ConferenciaCaixaResult';
import LinxListAsContent from '../../../../components/linx/ListAsContent/LinxListAsContent';
import config from 'devextreme/core/config';
import conferenciaCaixa from '../service/ConferenciaCaixa.service';
import cliente from '../../../cadastro/pessoas/Cliente.service';
import funcionario from '../../../cadastro/pessoas/Funcionario.service';
import ScreenHeader from '../../../../components/header/ScreenHeader/ScreenHeader';
import SectionHeader from '../../../../components/section/Header';

function ConferenciaCaixaFiltros(props) {
  const PATH_MENU = menus.COD_60680;
  const [treeView, setTreeView] = useState(null);

  const [operadores, setOperadores] = useState([]);
  const [operadorValue, setOperadorValue] = useState([]);

  const [situacoes, setSituacoes] = useState([]);
  const [situacaoValue, setSituacaoValue] = useState([]);
  const [selectedSituacoes, setSelectedSituacoes] = useState([]);

  const [maquinas, setMaquinas] = useState([]);
  const [maquinaValue, setMaquinaValue] = useState([]);
  const [selectedMaquinas, setSelectedMaquinas] = useState([]);

  const [clientes, setClientes] = useState([]);
  const [clienteValue, setClienteValue] = useState([]);

  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf('month')
      .format('YYYY-MM-DD') + 'T00:00:00'
    //new Date(2019, 8, 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [documentoFiscal, setDocumentoFiscal] = useState('');
  const [searching, setSearching] = useState(false);
  const [dadosConferencia, setDadosConferencia] = useState(null);
  const [dadosFiltros, setDadosFiltros] = useState(null);

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);

    config({
      defaultCurrency: 'BRL'
    });
  }, []);

  useEffect(() => {
    const loadSituacoes = async () => {
      const situacoes = await conferenciaCaixa.listaSituacoesTurnos();
      setSituacoes(situacoes);
    };

    const loadClientes = async () => {
      const response = await cliente.listaClientes();

      // let retiraClientesInternos = response.filter(
      //   item => item.restricao !== true
      // );

      // let clientesOrdenados = sortList(retiraClientesInternos, 'nome');

      let clientesOrdenados = sortList(response, 'nome');

      setClientes(clientesOrdenados);
    };
    loadSituacoes();
    loadClientes();
  }, []);

  useEffect(() => {
    const DataInicioFim = {
      datainicio: moment(startDate).format('YYYY-MM-DD') + 'T00:00:00',
      datafim: moment(endDate).format('YYYY-MM-DD') + 'T23:59:59'
    };

    const loadOperadores = async () => {
      const response = await funcionario.ListarComFiltro(DataInicioFim);

      setOperadores(response);
    };

    loadOperadores();
  }, [startDate, endDate]);

  useEffect(() => {
    const loadMaquinas = async () => {
      const response = await conferenciaCaixa.listaMaquinas();

      let maquinasOrdenados = sortList(response, 'descricao');

      setMaquinas(maquinasOrdenados);
    };

    loadMaquinas();
  }, []);

  const onValueChanged = event => {
    if (event.element.id === 'dataInicio') {
      if (!moment(event.value).isValid()) {
        notify('Data inicial inválida.', 'error', 2000, 'top');
      } else {
        setStartDate(moment(event.value).format('YYYY-MM-DD') + 'T00:00:00');
      }
    } else if (event.element.id === 'dataFim') {
      if (!moment(event.value).isValid()) {
        notify('Data final inválida.', 'error', 2000, 'top');
      } else {
        setEndDate(moment(event.value).format('YYYY-MM-DD') + 'T23:59:59');
      }
    }
  };

  const syncListSelection = (e, method) => {
    if (e) {
      if (e.value) {
        if (Array.isArray(e.value)) {
          method([...e.value]);
        } else {
          method(e.value);
        }
      } else {
        method([]);
      }
    }
  };

  const dataGridSituacaoRender = () => {
    return (
      <DataGrid
        dataSource={situacoes}
        columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={situacaoValue}
        onSelectionChanged={dataGridSituacao_onSelectionChanged}
      >
        <Selection mode="multiple" />
      </DataGrid>
    );
  };

  const dataGridSituacao_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(situacao => {
      ids.push(situacao.valor);
    });
    setSituacaoValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);

    setSelectedSituacoes(ids);
  };

  const syncDataGridSituacaoSelection = e => {
    setSituacaoValue(e.value || []);
    setSituacaoValue([]);
    setSelectedSituacoes([]);
  };

  const dataGridMaquinaRender = () => {
    return (
      <DataGrid
        dataSource={maquinas}
        columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={maquinaValue}
        onSelectionChanged={dataGridMaquina_onSelectionChanged}
      >
        <Selection mode="multiple" />
      </DataGrid>
    );
  };

  const dataGridMaquina_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(maquina => {
      ids.push(maquina.id);
    });
    setMaquinaValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);

    setSelectedMaquinas(ids);
  };

  const syncDataGridMaquinaSelection = e => {
    setMaquinaValue(e.value || []);
    setMaquinaValue([]);
    setSelectedMaquinas([]);
  };

  const syncTreeViewSelection = (e, property, method) => {
    if (e === undefined) {
      let tree = treeView && treeView.instance;
      tree.unselectAll();
    } else {
      let tree =
        (e.component.selectItem && e.component) ||
        (treeView && treeView.instance);

      if (tree) {
        if (e.value === null || e === undefined) {
          tree.unselectAll();
        } else {
          let values = e.value || property;
          values &&
            values.forEach(function(value) {
              tree.selectItem(value);
            });
        }
      }
      if (e.value !== undefined && method) {
        method(e.value);
      }
    }
  };

  const treeViewRender = (
    dataSource,
    property,
    method,
    noDataText,
    keyExpr = 'codigo',
    displayExpr = 'descricao'
  ) => {
    return (
      <TreeView
        dataSource={dataSource}
        noDataText={noDataText}
        ref={ref => setTreeView(ref)}
        dataStructure="plain"
        keyExpr={keyExpr}
        selectionMode="multiple"
        showCheckBoxesMode="selectAll"
        searchEnabled={true}
        selectNodesRecursive={false}
        displayExpr={displayExpr}
        selectByClick={true}
        onContentReady={e => syncTreeViewSelection(e, property, method)}
        onItemSelectionChanged={e => {
          method(e.component.getSelectedNodesKeys());
        }}
        onSelectAllValueChanged={e => {
          method(e.component.getSelectedNodesKeys());
        }}
      />
    );
  };

  const isFormValid = () => {
    if (moment(startDate).isAfter(moment(endDate))) {
      notify(
        'A data final deve ser igual ou posterior a data inicial.',
        'error',
        3000,
        'top'
      );
      return false;
    }
    return true;
  };

  const clearFilters = () => {
    setOperadorValue([]);
    setClienteValue([]);
    setStartDate(
      moment(new Date())
        .startOf('month')
        .format('YYYY-MM-DD') + 'T00:00:00'
    );
    setEndDate(new Date());
    setDocumentoFiscal('');
    setMaquinaValue([]);
    setSituacaoValue([]);
    setSelectedSituacoes([]);
    setSelectedMaquinas([]);
  };

  const doSearch = async () => {
    if (!isFormValid()) {
      return;
    }
    const clientes = [];
    if (clienteValue) {
      clienteValue.forEach(c => {
        clientes.push(c.id);
      });
    }
    const consulta = {
      periodoDe: startDate || '',
      periodoAte: endDate || '',
      operadores: operadorValue || [],
      situacoes: selectedSituacoes || [],
      maquinas: selectedMaquinas || [],
      clientes,
      documentoFiscal:
        String(documentoFiscal) === 'null' ? '' : String(documentoFiscal)
    };
    setSearching(true);
    try {
      const response = await conferenciaCaixa.listaTurnos(consulta);

      if (response && response.length) {
        preparaFiltros(consulta);
        setDadosConferencia(response);
      } else {
        notify('Não existem turnos para os filtros informados.', 'error', 5000);
      }
    } finally {
      setSearching(false);
    }
  };

  const preparaFiltros = consulta => {
    setDadosFiltros({
      ...consulta,
      operadores: operadorValue,
      situacoes: situacaoValue,
      maquinas: maquinaValue,
      clientes: clienteValue || []
    });
  };

  const backButtonHandle = () => {
    setDadosConferencia(null);
    setDadosFiltros(null);
  };

  if (dadosConferencia && dadosFiltros) {
    return (
      <ConferenciaCaixaResult
        dadosConferenciaResult={dadosConferencia}
        appliedFilters={dadosFiltros}
        backButtonHandle={backButtonHandle}
      />
    );
  } else {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <div className={styles.conferenciaFiltro}>
          <SectionHeader
              title=""
              right={
                <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={false} />
              }
          />
          <SectionContainer>
            <SectionContent>
              <div className="row">
                <div className="col-3">
                  <div className={styles.dateWrapper}>
                    <div className={styles.datebox}>
                      <span>De</span>
                      <DateBox
                        value={startDate}
                        id="dataInicio"
                        type="date"
                        useMaskBehavior={true}
                        onValueChanged={onValueChanged}
                        disabled={searching}
                        displayFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-3">
                  <div className={styles.dateWrapper}>
                    <div className={styles.datebox}>
                      <span>Até</span>
                      <DateBox
                        value={endDate}
                        id="dataFim"
                        type="date"
                        useMaskBehavior={true}
                        onValueChanged={onValueChanged}
                        disabled={searching}
                        displayFormat="dd/MM/yyyy"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-3 form-group mb-1`}
                >
                  <span>Operador</span>
                  <DropDownBox
                    value={operadorValue}
                    valueExpr="id"
                    displayExpr="nome"
                    placeholder="Selecione..."
                    showClearButton={true}
                    disabled={searching}
                    dataSource={operadores}
                    onValueChanged={e =>
                      syncTreeViewSelection(e, operadorValue, setOperadorValue)
                    }
                    contentRender={() => {
                      return treeViewRender(
                        operadores,
                        operadorValue,
                        setOperadorValue,
                        'Nenhum operador disponível no período indicado',
                        'id',
                        'nome'
                      );
                    }}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-3 form-group mb-1`}
                >
                  <span>Situação</span>
                  <DropDownBox
                    value={situacaoValue.map(item => item.descricao)}
                    valueExpr="valor"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={situacoes}
                    onValueChanged={syncDataGridSituacaoSelection}
                    contentRender={dataGridSituacaoRender}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={`${styles.dropDownBox} col-md-3 form-group mb-1`}
                >
                  <span>Máquina</span>
                  <DropDownBox
                    value={maquinaValue.map(item => item.descricao)}
                    valueExpr="id"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={situacoes}
                    onValueChanged={syncDataGridMaquinaSelection}
                    contentRender={dataGridMaquinaRender}
                  />
                </div>
                <div
                  className={`${styles.dropDownBox} col-md-3 form-group mb-1`}
                >
                  <span>Cliente</span>
                  <DropDownBox
                    value={clienteValue}
                    valueExpr={item => {
                      return item;
                    }}
                    displayExpr={item => {
                      return item.nome;
                    }}
                    placeholder="Selecione..."
                    showClearButton={true}
                    disabled={searching}
                    dataSource={clientes}
                    /* onValueChanged={e =>
                      syncTreeViewSelection(e, clienteValue, setClienteValue)
                    }
                    contentRender={() => {
                      return treeViewRender(
                        clientes,
                        clienteValue,
                        setClienteValue,
                        'Nenhum cliente disponível no período indicado'
                      );
                    } */
                    onValueChanged={e => syncListSelection(e, setClienteValue)}
                    contentRender={() => {
                      return (
                        <LinxListAsContent
                          dataSource={clientes}
                          selectedItems={clienteValue}
                          methodThatSets={setClienteValue}
                          keyExpr={item => {
                            return item.id;
                          }}
                          displayExpr={item => {
                            return item.nome;
                          }}
                          searchExpr={item => {
                            return item.nome;
                          }}
                        />
                      );
                    }}
                  />
                </div>
                <div className={`${styles.input} col-md-3 form-group mb-1`}>
                  <span>Documento fiscal</span>
                  <TextBox
                    placeholder="Informe o número do documento fiscal"
                    showClearButton={true}
                    disabled={searching}
                    onKeyPress={e => {
                      const str = String.fromCharCode(e.event.keyCode);
                      if (!/[0-9]/.test(str)) {
                        e.event.preventDefault();
                      }
                      if (e.event.target.value.length >= 10) {
                        e.event.preventDefault();
                      }
                    }}
                    value={documentoFiscal}
                    valueChangeEvent="change keyPress"
                    onValueChanged={e => {
                      if (e.value >= 0) {
                        setDocumentoFiscal(e.value);
                      }
                    }}
                  />
                </div>
              </div>

              <footer className="text-right">
                <button className="btn btn-light" onClick={clearFilters} disabled={searching}> Limpar filtros </button>
                <button className="btn btn-primary" onClick={doSearch} disabled={searching}>
                    {' '}
                    {searching ? (
                        <>
                            <svg className={styles.spinner} viewBox="0 0 50 50">
                                <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                </circle>
                            </svg>
                            PESQUISANDO...
                        </>
                    ) : (
                        `PESQUISAR`
                    )}
                </button>
              </footer>
            </SectionContent>
          </SectionContainer>
          </div>
      </LinxPostos>
    );
  }
}
export default withRouter(ConferenciaCaixaFiltros);
