import { LoadPanel } from 'devextreme-react';
import { GroupItem, Summary, TotalItem } from 'devextreme-react/data-grid';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ptMessages from '../../../../../assets/js/pt.json';
import DateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import FilterPanel from '../../../../components/Report/FilterPanel/FilterPanel';
import ResultPanel from '../../../../components/Report/ResultPanel/ResultPanel';
import SearchProdutos from '../../../../components/business/SearchProdutos/SearchProdutos';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import DateToolTip from '../../../../components/dateTooltip/DateToolTip';
import ListToolTip from '../../../../components/listToolTip/ListToolTip';
import { renderSummarySumDefault, renderTotalItemSumDefault, renderGroupItemSumDefault, reportMangerService } from '../../../../services/ReportManager.service';
import { NotificationLevel } from '../../../../shared/constants/Const';
import { menus } from '../../../../shared/constants/MenuConstants';
import { TIPO_SITE } from '../../../../shared/constants/TipoSiteConstant';
import {
  getDadosLogin,
  getNomePagina,
  getNomePaginaAmigavel
} from '../../../../shared/utils/Utils';
import styles from './DetalhadoProduto.module.scss';
import { detalhadoProdutoService } from './DetalhadoProduto.service';

var valorVendaLinhas = 0;
var valorMargemLinhas = 0;
var valorVendaFoot = 0;
var valorMargemFoot = 0;
const calculateCustomSummary = (options) => {
  console.log("options calculate", options)

  if (options.name === "margemPerc") {
    switch (options.summaryProcess) {
      case "start":
        valorVendaLinhas = 0;
        valorMargemLinhas = 0;
        options.totalValue = 0;
        break;
      case "calculate":
        valorVendaLinhas += parseFloat(options.value.vendaLiquida.toFixed(4));
        valorMargemLinhas += parseFloat(options.value.margemValor.toFixed(4));
        break;
      case "finalize":
        options.totalValue = ((valorMargemLinhas / (valorVendaLinhas == 0 ? 1 : valorVendaLinhas)) * 100).toFixed(2);
        break;
      default:

        break
    }
  }
  else {
    if (options.name === "margemFooter") {
      switch (options.summaryProcess) {
        case "start":
          valorVendaFoot = 0;
          valorMargemFoot = 0;
          options.totalValue = 0;
          break;
        case "calculate":
          valorVendaFoot += parseFloat(options.value.vendaLiquida.toFixed(4));
          valorMargemFoot += parseFloat(options.value.margemValor.toFixed(4));
          break;
        case "finalize":
          options.totalValue = ((valorMargemFoot / (valorVendaFoot == 0 ? 1 : valorVendaFoot)) * 100).toFixed(2);
          break;
        default:

          break
      }
    }
  }
}

export function DetalhadoProduto() {
  const PATH_MENU = menus.COD_16190;
  const CodigoFuncionalidade = 80510;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [selectedProdutos, setSelectedProdutos] = useState([]);
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({
    produtos: [],
    estruturaMercadologica: [],
    subtipo: [],
    setor: [],
    deposito: [],
    dataInicio: startDate,
    dataFim: endDate,
    chaveDocumento: '',
    numeroDocumento: '',
    empresas: [],
    MultiEmpresa: false,
  });

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, selectedProdutos, startDate, endDate]);

  useEffect(() => {
    const colunas = detalhadoProdutoService.GetColumns();
    setColumns(colunas);

    async function GetReports() {
      const result = await reportMangerService.Get(CodigoFuncionalidade);

      if (result.notificationLevel === NotificationLevel.Success) {
        let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

        setServerReports(reports);
      }
    }

    GetReports();
  }, []);

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }

    setFilters({
      produtos: [...selectedProdutos],
      estruturaMercadologica: [],
      setor: [],
      deposito: [],
      subtipo: [],
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      combustiveis: [],
      chave: appliedFilters.chaveDocumento,
      numeroDocumento: appliedFilters.numeroDocumento,
      multiEmpresa: false,
    });
    await request({
      produtos: [...selectedProdutos],
      estruturaMercadologica: [],
      setor: [],
      deposito: [],
      subtipo: [],
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      chave: appliedFilters.chaveDocumento,
      numeroDocumento: appliedFilters.numeroDocumento,
      multiEmpresa: false,
    });

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    setSearching(true);

    const response = await detalhadoProdutoService.find(model);

    if (response.notificationLevel === 1) {
      if (!response.data.length) {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          2000,
          'top'
        );
      }
      setResultData(response.data);
    } else {
      notify(
        (response.message ? response.message : '')  + ' Refaça a pesquisa informando um período menor.',
        'info',
        2000,
        'top'
      );
    }
    setShowResult(true);
    setSearching(false);
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);

    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    refreshColumns(report.data.colunas);

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim
    };

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const refreshColumns = newColumns => {
    let refreshedColuns = reportMangerService.RefreshColumns(
      columns,
      newColumns
    );
    setColumns(refreshedColuns);
  };

  const reportHandle = report => {
    setChosenReport(report);

    if (report.tipo === 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59'
              : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00'
        };
      });
      setDisabledSearch(false);
    }
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    let ids = [];

    selectedRowsData.forEach(produto => {
      if (!appliedFilters.produtos.includes(produto.id)) {
        ids.push(produto.id);
      }
    });

    setSelectedProdutos(ids);
  };

  const clearFilters = () => {
    setAppliedFilters({
      produtos: [],
      estruturaMercadologica: [],
      subtipo: [],
      setor: [],
      deposito: [],
      dataInicio: new Date().toISOString(),
      dataFim: new Date().toISOString(),
      chaveDocumento: '',
      numeroDocumento: '',
      multiEmpresa: false,
    });

    clearProdutos([]);
    setSelectedProdutos([]);
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const clearProdutos = () => {
    setSelectedProdutos([]);
  };

  const updateReportInfo = report => {
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
      refreshColumns(detalhadoProdutoService.GetColumns());
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="vendas"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        filtroOutros={[
          {
            chave: 1,
            titulo: 'SubTipo',
            valor: filters.subTipo || [],
            campo: 'subTipo'
          },
          {
            chave: 2,
            titulo: 'Produto',
            valor: filters.produtos || [],
            campo: 'produtos'
          },
        ]}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        summary={() =>
          <Summary calculateCustomSummary={calculateCustomSummary}>
            {renderTotalItemSumDefault(columns)}
            {renderGroupItemSumDefault(columns)}
            <TotalItem
              name="margemFooter"
              showInColumn="margem"
              summaryType="custom"
              valueFormat={{ type: 'currency', precision: 2 }}
            />
            <GroupItem
              name="margemPerc"
              showInColumn="margem"
              summaryType="custom"
              alignByColumn={true}
            />
          </Summary>
          }
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <ListToolTip
          data={filters.produtos}
          message={'Produtos selecionados'}
        />
        <ListToolTip
          data={filters.combustiveis}
          message={'Combustíveis selecionados'}
        />
        <ListToolTip data={filters.subTipo} message={'SubTipos selecionados'} />
      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
      />
      <FilterPanel
        onSearch={find}
        clearFilters={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.input} col-8`}>
            <span>Produtos</span>
            <SearchProdutos
              searching={searching}
              selectedProdutos={selectedProdutos}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className={`${styles.input} col-12`}>
            <Select multi
              label="Empresas"
              name="empresasSelecionadas"
              options={listaEmpresas}
              sender={this}
              onBlur={setSelectedEmpresas()}
            />
          </div>
        </div> */}
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}

export function MatrizDetalhadoProduto(props) {
  const PATH_MENU = menus.COD_22600;
  const CodigoFuncionalidade = 80510;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [selectedProdutos, setSelectedProdutos] = useState([]);
  const [listaEmpresas, setListaEmpresas] = useState(getDadosLogin().empresas.map(item => {
    return {value: item.cnpj, label: item.nomeFantasia}
  }));
  const [selectedEmpresas, setSelectedEmpresas] = useState([]);
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({
    produtos: [],
    estruturaMercadologica: [],
    subtipo: [],
    setor: [],
    deposito: [],
    dataInicio: startDate,
    dataFim: endDate,
    chaveDocumento: '',
    numeroDocumento: '',
    empresas: [],
    multiEmpresa: true,
  });

  config({
    defaultCurrency: 'BRL'
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, selectedProdutos, startDate, endDate]);

  useEffect(() => {
    const colunas = detalhadoProdutoService.GetColumns();
    setColumns(colunas);

    async function GetReports() {
      const result = await reportMangerService.Get(CodigoFuncionalidade);

      if (result.notificationLevel === NotificationLevel.Success) {
        let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

        setServerReports(reports);
      }
    }

    GetReports();
  }, []);

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }

    setFilters({
      produtos: [...selectedProdutos],
      estruturaMercadologica: [],
      setor: [],
      deposito: [],
      subtipo: [],
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      combustiveis: [],
      chave: appliedFilters.chaveDocumento,
      numeroDocumento: appliedFilters.numeroDocumento,
      multiEmpresa: true,
    });
    await request({
      produtos: [...selectedProdutos],
      estruturaMercadologica: [],
      setor: [],
      deposito: [],
      subtipo: [],
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      chave: appliedFilters.chaveDocumento,
      numeroDocumento: appliedFilters.numeroDocumento,
      multiEmpresa: true,
    });

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    setSearching(true);

    const response = await detalhadoProdutoService.find(model);

    if (response.notificationLevel === 1) {
      if (!response.data.length) {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          2000,
          'top'
        );
      }
      setResultData(response.data);
    } else {
      notify(
        (response.message ? response.message : '')  + ' Refaça a pesquisa informando um período menor.',
        'info',
        2000,
        'top'
      );
    }
    setShowResult(true);
    setSearching(false);
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);

    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    refreshColumns(report.data.colunas);

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim,
      multiEmpresa: true,
    };

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const refreshColumns = newColumns => {
    let refreshedColuns = reportMangerService.RefreshColumns(
      columns,
      newColumns
    );
    setColumns(refreshedColuns);
  };

  const reportHandle = report => {
    setChosenReport(report);

    if (report.tipo === 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59'
              : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00'
        };
      });
      setDisabledSearch(false);
    }
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    let ids = [];

    selectedRowsData.forEach(produto => {
      if (!appliedFilters.produtos.includes(produto.id)) {
        ids.push(produto.id);
      }
    });

    setSelectedProdutos(ids);
  };

  const clearFilters = () => {
    setAppliedFilters({
      produtos: [],
      estruturaMercadologica: [],
      subtipo: [],
      setor: [],
      deposito: [],
      dataInicio: new Date().toISOString(),
      dataFim: new Date().toISOString(),
      chaveDocumento: '',
      numeroDocumento: '',
      multiEmpresa: true,
    });

    clearProdutos([]);
    setSelectedProdutos([]);
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const clearProdutos = () => {
    setSelectedProdutos([]);
  };

  const updateReportInfo = report => {
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
      refreshColumns(detalhadoProdutoService.GetColumns());
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        //setSubServerReports(report[0].subReport);

        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="vendas"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        filtroOutros={[
          {
            chave: 1,
            titulo: 'SubTipo',
            valor: filters.subTipo || [],
            campo: 'subTipo'
          },
          {
            chave: 2,
            titulo: 'Produto',
            valor: filters.produtos || [],
            campo: 'produtos'
          },
        ]}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        summary={() =>
          <Summary calculateCustomSummary={calculateCustomSummary}>
            {renderTotalItemSumDefault(columns)}
            {renderGroupItemSumDefault(columns)}
            <TotalItem
              name="margemFooter"
              showInColumn="margem"
              summaryType="custom"
              valueFormat={{ type: 'currency', precision: 2 }}
            />
            <GroupItem
              name="margemPerc"
              showInColumn="margem"
              summaryType="custom"
              alignByColumn={true}
            />
          </Summary>
        }
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <ListToolTip
          data={filters.produtos}
          message={'Produtos selecionados'}
        />
        <ListToolTip
          data={filters.combustiveis}
          message={'Combustíveis selecionados'}
        />
        <ListToolTip data={filters.subTipo} message={'SubTipos selecionados'} />
      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <FilterPanel
        onSearch={find}
        clearFilters={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`${styles.input} col-8`}>
            <span>Produtos</span>
            <SearchProdutos
              searching={searching}
              selectedProdutos={selectedProdutos}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className={`${styles.input} col-12`}>
            <Select multi
              label="Empresas"
              name="empresasSelecionadas"
              options={listaEmpresas}
              sender={this}
              onBlur={setSelectedEmpresas()}
            />
          </div>
        </div> */}
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}




export default withRouter(DetalhadoProduto);
