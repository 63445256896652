import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  InputDate,
  handleInputChange,
  Select
} from '../../../components/formulario/Formulario';
import ReactTable from 'react-table';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  AlertType,
  tagAlert,
  showQuestion,
  hideAlert,
  showError,
  showSaveError,
  showInfo,
  showDeleteError,
  showWait,
  hideWait
} from '../../../components/alert/Alert';
import moment from 'moment';
import {
  isValidHour,
  getCnpj,
  toFloatFormattedDisplay,
  getPercentualDiff,
  menuPermiteInclusao,
  menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  menuPermiteAlteracao
} from '../../../shared/utils/Utils';
import {
  salvaMedicoes,
  getMedicoes,
  excluirMedicao
} from './MedicaoTanques.service';
import {
  getTanques,
  getTanqueByData
} from '../../cadastro/tanques-bombas-bicos/tanques/Tanques.service';
import { getLMC, getParametrosLMC } from '../../fiscal/LMC/LMC.service';
import { deleteInList } from '../../../shared/utils/TableUtils';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import Footer from '../../../components/core/footer/Footer';

import { menus } from '../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60660;

const titulo = 'Medição de Tanques e GNV';

export const Tanques = {
  None: ''
};

export default class MedicaoTanques extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const now = moment();

    this.state = {
      data: now,
      //data: moment("2018-02-08"),
      hora: now.format('HH:mm'),
      tanques: [],
      medicoes: [],
      tanquesParaExibir: [],
      alertActive: false,
      alertType: AlertType.None,
      alertTitle: '',
      alertSubtitle: '',
      alertFocus: null,
      alertAction: null
    };
  }

  async componentDidMount() {
    const { data: dataTanques } = await getTanques(getCnpj(), true);
    const tanques = [];
    dataTanques.result.forEach(t => {
      tanques.push(t);
    });
    this.setState({ tanques });
    this.updateMedicoes(this.state.data);
  }

  tanqueToTanque(medicao) {
    const t = {};
    if (medicao && medicao.tanque) {
      t.codigo = medicao.tanque.codigoTanque;
      t.descricao =
        medicao && medicao.tanque ? medicao.tanque.descricaoTanque : '';
      t.combustivel = medicao.tanque.produto;
      t.unidade =
        medicao && medicao.tanque ? medicao.tanque.unidadeMedida.trim() : 'L';
      let cap = medicao.tanque.capacidade;
      t.capacidade = cap;
      let esc =
        medicao && medicao.tanque && medicao.tanque.volumeAtual
          ? medicao.tanque.volumeAtual
          : 0;
      let vol = medicao && medicao.quantidade ? medicao.quantidade : 0;
      t.volume = vol;
      t.escritural = esc;
      t.diferencaVolume = vol - esc;
      t.diferencaPercentual = getPercentualDiff(esc, vol);
      t.hora = medicao
        ? moment(medicao.dataHoraMedicao).format('HH:mm')
        : '00:00';
      t.segundos = medicao
        ? moment(medicao.dataHoraMedicao).format('ss')
        : '00';
      t.tanqueOriginal = { ...medicao.tanque };
      t.lockTanque = true;
    }
    return t;
  }

  async updateMedicoes(dataComparacao) {
    const { data: dataMedicoes } = await getMedicoes(dataComparacao);
    const medicoesDoDia = [];
    if (dataMedicoes.result) {
      dataMedicoes.result.forEach(m => {
        medicoesDoDia.push(m);
      });
    }

    const medicoes = [];

    medicoesDoDia.forEach(m => {
      let t = m.tanque;
      let dataAtivacao = moment(t.dataAtivacao);
      let dataInativacao = moment(t.dataInativacao);
      if (
        dataAtivacao.isSameOrBefore(dataComparacao) &&
        (!t.inativo || dataInativacao.isAfter(dataComparacao))
      ) {
        medicoes.push(this.tanqueToTanque(m));
      }
    });

    const tanquesParaExibir = [];
    tanquesParaExibir.push({
      label: 'Selecione um tanque',
      value: Tanques.None
    });
    this.state.tanques.forEach(t => {
      let dataAtivacao = moment(t.dataAtivacao);
      let dataInativacao = moment(t.dataInativacao);
      if (
        dataAtivacao.isSameOrBefore(dataComparacao) &&
        (!t.inativo || dataInativacao.isAfter(dataComparacao))
      ) {
        let desc = t.descricaoTanque
          ? t.descricaoTanque
          : 'Tanque ' + t.numeroTanque;
        t.descricaoTanque = desc;
        tanquesParaExibir.push({
          label: desc + ' - ' + t.produto,
          value: t.codigoTanque
        });
      }
    });

    this.setState({ medicoes, tanquesParaExibir, medicoesDoDia });
  }

  validateHour = () => {
    const hour = this.state.hora;
    if (!!hour && !isValidHour(hour)) {
      showError(this, titulo, 'Hora informada não é válida.', 'hora');
      return;
    }
  };

  isFormValid() {
    let erro = null;

    const { medicoes, data } = this.state;

    if (!data)
      erro = {
        field: 'data',
        message: 'Escolha uma data para as medições.'
      };
    else if (data.isAfter(moment(), 'day')) {
      erro = {
        field: 'data',
        message: 'Data escolhida não pode ser maior que a de hoje.'
      };
    }

    if (this.state.medicoes.length == 0)
    {
      erro = {
        field: 'medicoes',
        message: 'Adicione ao menos uma medição para salvar.'
      };
    }

    let end = false;
    medicoes.forEach((m, index) => {
      if (!m.tanqueOriginal.descricaoTanque) {
        if(m.tanqueOriginal.numeroTanque) {
          m.tanqueOriginal.descricaoTanque = 'Tanque ' + m.tanqueOriginal.numeroTanque;
        } else {
          m.tanqueOriginal.descricaoTanque = 'Tanque.';
        }
      }
      if (!m.codigo) {
        erro = {
          field: 'descricao',
          message: 'Todas as linhas devem ter um tanque selecionado'
        };
        return;
      } else if (!m.hora) {
        erro = {
          field: 'hora',
          message:
            'Informe a hora da medição do ' + m.tanqueOriginal.descricaoTanque
        };
        return;
      } else if (!isValidHour(m.hora)) {
        erro = {
          field: 'hora',
          message:
            'Informe uma hora válida para a medição do ' +
            m.tanqueOriginal.descricaoTanque
        };
        return;
      }

      for (let i = index + 1; i < medicoes.length; i++) {
        if (
          Number(m.codigo) === Number(medicoes[i].codigo) &&
          String(m.hora) === String(medicoes[i].hora)
        ) {
          erro = {
            field: 'hora',
            message:
              'Não pode haver mais de uma medição no ' +
              'mesmo horário para o ' +
              m.tanqueOriginal.descricaoTanque
          };
          end = true;
          return;
        }
      }
      if (end) return;
    });

    if (erro) {
      showError(this, titulo, erro.message, erro.field);
      return false;
    } else return true;
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    const { data, medicoes } = this.state;

    if (this.isFormValid()) {
      const medicoesToSend = [];
      const codigosProdutoLMC = [];

      medicoes.forEach(m => {
        let dataHoraMedicao = data;

        dataHoraMedicao = dataHoraMedicao.utcOffset(0).startOf('date');
        dataHoraMedicao.add(Number(String(m.hora[0] + m.hora[1])), 'h');
        dataHoraMedicao.add(Number(String(m.hora[3] + m.hora[4])), 'm');

        codigosProdutoLMC.push(m.tanqueOriginal.codigoProduto);

        medicoesToSend.push({
          tanque: m.tanqueOriginal,
          quantidade: m.volume,
          dataHoraMedicao: dataHoraMedicao.toISOString()
        });
      });
      try {
        showWait(
          this,
          titulo,
          'Por favor, aguarde enquanto as medições são salvas'
        );
        const response = await salvaMedicoes(medicoesToSend);
        this.forceUpdate();
        hideAlert(this);
        if (response.data === '')
          showInfo(this, titulo, 'Medições salvas com sucesso!');
        else
          showQuestion(
            this,
            titulo,
            'Medições salvas com sucesso!\n' +
              response.data +
              '\nDeseja regerá-lo agora?',
            null,
            resp => {
              if (resp) {
                this.gerarLMC(codigosProdutoLMC);
              } else {
                hideAlert(this);
              }
            }
          );

        medicoes.forEach(m => {
          m.lockTanque = true;
        });
        this.setState({ medicoes });
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
  };

  handleDateChange = event => {
    const newDate = event.date;

    if (newDate) {
      if (!moment(newDate).isValid()) return;
      const { data: oldDate } = this.state;
      let data = null;
      if (newDate.isAfter(moment(), 'day')) {
        data = oldDate;
        showError(
          this,
          titulo,
          'Data escolhida não pode ser maior que a de hoje.',
          'data'
        );
        return;
      } else {
        data = newDate;
        this.updateMedicoes(data);
      }
      this.setState({ data });
    } else {
      this.setState({ data: null });
    }
  };

  handleCancelar = async () => {
    showQuestion(
      this,
      titulo,
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (resp) {
          hideAlert(this);
          this.updateMedicoes(this.state.data);
        } else {
          hideAlert(this);
        }
      }
    );
  };

  handleVolumeChange = (
    value,
    name,
    index,
    capacidade,
    escritural,
    volumeAnterior
  ) => {
    let v = value;
    if (capacidade && value > capacidade) {
      showError(
        this,
        titulo,
        'Volume não pode ser maior que a capacidade',
        'volume'
      );
      v = volumeAnterior ? volumeAnterior : 0;
    }

    const medicoes = [];
    for (let i = 0; i < this.state.medicoes.length; i++) {
      if (i === index)
        medicoes.push({
          ...this.state.medicoes[i],
          [name]: v,
          diferencaVolume: v ? v - escritural : -escritural,
          diferencaPercentual: getPercentualDiff(escritural, v ? v : 0)
        });
      else medicoes.push(this.state.medicoes[i]);
    }

    this.setState({ medicoes });
  };

  adicionaMedicao = () => {
    const { medicoes } = this.state;
    medicoes.push({
      codigo: Tanques.None,
      descricao: 'Selecione um tanque',
      combustivel: '',
      unidade: 'L',
      capacidade: 0,
      volume: 0,
      escritural: 0,
      diferencaVolume: 0,
      diferencaPercentual: 0,
      hora: null,
      segundos: '00',
      tanqueOriginal: {},
      lockTanque: false
    });
    this.setState({ medicoes });
  };

  changedList = (list, name, value, index) => {
    const result = [];
    for (let i = 0; i < list.length; i++) {
      if (i === index) result.push({ ...list[i], [name]: value });
      else result.push(list[i]);
    }
    return result;
  };

  changeInList = (listName, name, value, index) => {
    this.setState({
      [listName]: this.changedList(this.state[listName], name, value, index)
    });
  };

  checkLoadTanque = async (listName, name, value, index) => {
    
    this.changeInList(listName, name, value, index);

    let codigo = name === 'codigo' ? value : this.state[listName][index].codigo;
    let hora = name === 'hora' ? value : this.state[listName][index].hora;

    if (codigo && codigo > 0 && hora && isValidHour(hora)) {
      await this.loadTanqueInfo(codigo, hora, index, true);
    } else if (name === 'codigo' && codigo && codigo > 0 && (!hora || !isValidHour(hora))) {
      await this.loadTanqueInfo(codigo, '00:00', index, false); //atualiza apenas unidade e capacidade do tanque
    }
  };

  loadTanqueInfo = async (codigo, hora, index, fullUpdate) => {
    const medicoesNovas = [];

    const medicoesVelhas = this.state.medicoes;

    for (let i = 0; i < medicoesVelhas.length; i++) {
      if (i === index) {
        showWait(
          this,
          titulo,
          'Por favor, aguarde enquanto os dados do tanque são carregados.'
        );
        try {
          let dataEnviar = (
            moment(this.state.data).utcOffset(0).format('YYYY-MM-DD') 
            + 'T' 
            + moment(hora.replace(':',''), 'hmm').format('HH:mm')
          );

          const { data: tanque } = await getTanqueByData(codigo, dataEnviar);
          
          if (fullUpdate) {
            medicoesNovas.push({
              ...medicoesVelhas[i],
              tanqueOriginal: { ...tanque },
              codigo: codigo,
              unidade:
                tanque && tanque.unidadeMedida
                  ? tanque.unidadeMedida.trim()
                  : 'L',
              capacidade: tanque ? tanque.capacidade : 0,
              hora: hora,
              escritural: tanque ? tanque.volumeAtual : 0,
              diferencaVolume: tanque 
                ? (medicoesVelhas[i].volume ? (medicoesVelhas[i].volume - tanque.volumeAtual) : -tanque.volumeAtual) 
                : 0,
              diferencaPercentual: tanque
                ? getPercentualDiff(tanque.volumeAtual, (medicoesVelhas[i].volume ? medicoesVelhas[i].volume : 0))
                : 0
            });
          } else {
            medicoesNovas.push({
              ...medicoesVelhas[i],
              tanqueOriginal: { ...tanque },
              codigo: codigo,
              unidade:
                tanque && tanque.unidadeMedida
                  ? tanque.unidadeMedida.trim()
                  : 'L',
              capacidade: tanque ? tanque.capacidade : 0,
              escritural: 0,
              diferencaVolume: 0,
              diferencaPercentual: 0
            });
          }
        } finally {
          hideWait(this);
        }
      } else {
        medicoesNovas.push(medicoesVelhas[i]);
      }
    }

    this.setState({ medicoes: medicoesNovas });
  };

  excluiMedicao = async (
    codigoTanque,
    codigoProduto,
    codigoAlmoxarifado,
    dataMedicao,
    listaMedicaoAtual
  ) => {
    var listaMedicoesNovo = [];
    try {
      showWait(
        this,
        titulo,
        'Por favor, aguarde enquanto a medição é excluída.'
      );
      const resp = await excluirMedicao(
        codigoTanque,
        codigoProduto,
        codigoAlmoxarifado,
        dataMedicao
      );
      if (resp.status === 200) {
        if (resp.statusText === 'OK') {
          showQuestion(
            this,
            titulo,
            'Necessário realizar geração do LMC. Deseja fazer agora?',
            null,
            resp => {
              if (resp) {
                this.gerarLMC([codigoProduto]);
              } else {
                hideAlert(this);
              }
            }
          );
        }

        for (let i = 0; i < listaMedicaoAtual.length; i++) {
          const element = listaMedicaoAtual[i];
          if (parseInt(codigoTanque) !== parseInt(element.codigo))
            listaMedicoesNovo.push(element);
        }

        this.setState({ medicoes: listaMedicoesNovo });
      }
    } catch (err) {
      // falha
      showDeleteError(this, titulo, err);
    }
  };

  gerarLMC = async idsCombustiveis => {
    const { data: parametros } = await getParametrosLMC();

    var dataLMC = this.state.data;

    try {
      await getLMC(
        true,
        moment(dataLMC).format('YYYY-MM-DD '),
        moment(dataLMC).format('YYYY-MM-DD '),
        idsCombustiveis,
        parametros.distribuidora,
        parametros.numeroOrdem
      );

      showInfo(this, 'LMC gerado com sucesso!');
    } catch (e) {
      showSaveError(this, 'LMC', e);
    }
  };

  eliminarMedicao = async e => {
    var dataMedicao = this.state.data;
    var horaMedicao = null;
    var listaMedicaoAtual = this.state.medicoes;
    var nome = e.currentTarget.className;

    var codigoTanque = 0;
    var codigoProduto = 0;
    var codigoAlmoxarifado = 0;

    var codigo = nome.substring(7, nome.indexOf(null) - 1);

    var objMedicao = listaMedicaoAtual.filter(
      m => parseInt(m.codigo) === parseInt(codigo)
    );

    if (objMedicao.length > 0) {
      codigoTanque = objMedicao[0].tanqueOriginal.codigoTanque;
      codigoProduto = objMedicao[0].tanqueOriginal.codigoProduto;
      codigoAlmoxarifado = objMedicao[0].tanqueOriginal.codigoAlmoxarifado;
      horaMedicao = objMedicao[0].hora + ':' + objMedicao[0].segundos;
      showQuestion(
        this,
        titulo,
        'Deseja realmente excluir a medição?',
        null,
        async resp => {
          if (resp) {
            await this.excluiMedicao(
              codigoTanque,
              codigoProduto,
              codigoAlmoxarifado,
              moment(dataMedicao).format('YYYY-MM-DD ') + horaMedicao,
              listaMedicaoAtual
            );
          } else {
            hideAlert(this);
          }
        }
      );
    }
  };

  render() {
    const { data } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent>
              <div className="row">
                <div className="col-4">
                  <InputDate
                    value={data}
                    name="data"
                    label="Data da medição:"
                    onChange={this.handleDateChange}
                  />
                </div>
                {menuPermiteInclusao(PATH_MENU) ? (
                  <div className="col-5">
                    <div className="button-wrapper">
                      <Botao
                        ic
                        icon="icon-lx-plus"
                        //title={"Adicionar medição"}
                        onClick={this.adicionaMedicao}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </SectionContent>
            <div className="table-ctn">
              <ReactTable
                title="Medições"
                defaultPageSize={5}
                // pageSize={this.state.medicoes ? this.state.medicoes.length : 1}
                data={this.state.medicoes}
                columns={[
                  {
                    accessor: 'codigo',
                    Header: 'Código',
                    show: false
                  },
                  {
                    accessor: 'lockTanque',
                    show: false
                  },
                  {
                    accessor: 'descricao',
                    Header: 'Tanque',
                    filterable: false,
                    width: 400,
                    Cell: props => {
                      return (
                        <Select
                          disabled={!menuPermiteAlteracao(PATH_MENU) || props.row.lockTanque}
                          value={props.row.codigo}
                          name="row.codigo"
                          options={this.state.tanquesParaExibir}
                          onChange={async event => {
                            this.checkLoadTanque(
                              'medicoes',
                              'codigo',
                              event.target.value,
                              props.row._index
                            );
                          }}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'combustivel',
                    Header: 'Combustível',
                    width: 180,
                    show: false
                  },
                  {
                    accessor: 'unidade',
                    Header: 'Unidade',
                    filterable: false,
                    width: 50
                  },
                  {
                    accessor: 'capacidade',
                    Header: 'Capacidade',
                    filterable: false,
                    width: 105
                  },
                  {
                    accessor: 'hora',
                    Header: 'Hora',                    
                    width: 100,
                    filterable: false,
                    Cell: props => {
                      return (
                        <InputText
                          disabled= {props.row.lockTanque}
                          type="number"
                          value={props.row.hora}
                          name="hora"
                          format={'##:##'}
                          onChange={event =>
                            this.checkLoadTanque(
                              'medicoes',
                              'hora',
                              event.formattedValue,
                              props.row._index
                            )
                          }
                        />
                      );
                    }
                  },
                  {
                    accessor: 'escritural',
                    Header: 'Escritural',
                    width: 100,
                    filterable: false,
                    Cell: props => (
                      <div>{toFloatFormattedDisplay(props.value)}</div>
                    )
                  },
                  {
                    accessor: 'volume',
                    Header: 'Volume',
                    width: 150,
                    filterable: false,
                    Cell: props => {
                      return (
                        <InputText
                          type="number"
                          className="input"
                          name="volume"
                          value={props.row.volume}
                          maxlength={11}
                          allowNegative={false}
                          decimalScale={3}
                          autocomplete="off"
                          disabled={!menuPermiteAlteracao(PATH_MENU)}
                          onChange={event =>
                            this.handleVolumeChange(
                              event.floatValue,
                              'volume',
                              props.row._index,
                              props.row.capacidade,
                              props.row.escritural,
                              props.row.volume
                            )
                          }
                        />
                      );
                    }
                  },
                  {
                    accessor: 'diferencaVolume',
                    Header: 'Diferença',
                    width: 100,
                    filterable: false,
                    Cell: props => (
                      <div>{toFloatFormattedDisplay(props.value)}</div>
                    )
                  },
                  {
                    accessor: 'diferencaPercentual',
                    Header: 'Diferença %',
                    width: 100,
                    filterable: false,
                    Cell: props => (
                      <div>{toFloatFormattedDisplay(props.value)}</div>
                    )
                  },
                  {
                    accessor: 'segundos', // segundos salvos no banco
                    show: false
                  },
                  {
                    accessor: 'acao',
                    Header: 'Excluir',
                    width: 75,
                    filterable: false,
                    show: menuPermiteExclusao(PATH_MENU),
                    Cell: ({ row }) => {
                      return (
                        <Botao
                          className={row.codigo}
                          icon={'icon-lx-trash'}
                          onClick={
                            row.lockTanque
                              ? this.eliminarMedicao
                              : () => {
                                  showQuestion(
                                    this,
                                    titulo,
                                    'Deseja realmente excluir a medição?',
                                    null,
                                    resp => {
                                      if (resp) {
                                        deleteInList(
                                          this,
                                          'medicoes',
                                          row._index
                                        );
                                        hideAlert(this);
                                      } else {
                                        hideAlert(this);
                                      }
                                    }
                                  );
                                }
                          }
                        />
                      );
                    }
                  }
                ]}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
              />
            </div>
          </SectionContainer>

          <Footer
            saveAction={this.handleSalvar}
            cancelAction={this.handleCancelar}
          />
        </LinxPostos>
        {tagAlert(this)}
      </>
    );
  }
}
