import React, { Component } from "react";

export default class Accordion extends Component {
  constructor(props) {
    super(props);
    const { defaultDisplay = true, display } = this.props;

    let initDisplay = "block";
    if (display !== undefined) {
      initDisplay = display ? "block" : "none";
    } else {
      initDisplay = defaultDisplay ? "block" : "none";
    }

    this.state = {
      display: initDisplay
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    // caso foi informado a prop display, entao o gerenciamento se está aberto ou fechado deve ser feito
    // pelo componente pai e o onChange deve ser passado para alterar o state do pai para abrir/fechar
    if (this.props.display !== undefined) {
      this.props.onChange(this.props.display);
    } else {
      const newDisplay = this.state.display === "block" ? "none" : "block";
      this.setState({
        display: newDisplay
      });

      if (this.props.onChange) {
        this.props.onChange(newDisplay === "block");
      }
    }
  }

  render() {
    const display =
      this.props.display !== undefined
        ? this.props.display
          ? "block"
          : "none"
        : this.state.display;
    return (
      <>
        <div className="content content-accordeon">
          <div className={`heading ${display} `} onClick={this.toggle}>
            {this.props.accordionTitle}
            <div className="section-header-right">{this.props.right}</div>
          </div>
          <div className="body-content" style={{ display: display }}>
            {this.props.children}
          </div>
        </div>
      </>
    );
  }
}
