import { CheckBox, TextArea, TextBox } from 'devextreme-react';
import { Validator } from 'devextreme-react/validator';
import { loadMessages, locale } from 'devextreme/localization';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import validationEngine from 'devextreme/ui/validation_engine';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ptMessages from '../../../../../assets/js/pt.json';
import Footer from '../../../../components/core/footer/Footer';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import {
  ALPHANUMERIC_WITH_SPACE_PATTERN
} from '../../../../shared/constants/Const';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina, sameInt } from '../../../../shared/utils/Utils';
import dadosAdicionaisDocumentoFiscal from '../service/DadosAdicionaisDocumentoFiscal.service';
import styles from './DadosAdicionaisCadastro.module.scss';

export function DadosAdicionaisCadastro(props) {
  const PATH_MENU = menus.COD_60430;
  const [showSearch] = useState(false);
  const [searching, setSearching] = useState(false);

  const [descricaoResumida, setDescricaoResumida] = useState('');
  const [descricaoCompleta, setDescricaoCompleta] = useState('');
  const [entrada, setEntrada] = useState(false);
  const [saida, setSaida] = useState(false);
  const [interesseContribuinte, setInteresseContribuinte] = useState(false);
  const [interesseFisco, setInteresseFisco] = useState(false);
  const [aceitaConteudoVariavel, setAceitaConteudoVariavel] = useState(false);
  const [operacoes, setOperacoes] = useState([]);
  const [operacoesFiltradas, setOperacoesFiltradas] = useState([]);
  const [operacoesSelecionadas, setOperacoesSelecionadas] = useState([]);
  const [camposPredefinidos, setCamposPredefinidos] = useState([]);
  const [loadedContent, setLoadedContent] = useState(false);
  const [receivedData, setReceivedData] = useState([]);
  const [, setState] = useState();

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
    if (props.location.state) {
      setReceivedData(props.location.state.data);
    }
  }, [props, receivedData]);

  useEffect(() => {
    if (!loadedContent) {
      loadContent();
    }

    setTimeout(() => validationEngine.validateGroup());
  });

  useEffect(() => {
    getOperacoes();
  });

  useEffect(() => {
    trataOperacoesFiltradas();
  }, [entrada, saida, operacoes]);

  const backToScreen = () => {
    props.history.goBack();
  };

  const getTags = async codigoDadoAdicionalDocumentoFiscal => {
    const response = await dadosAdicionaisDocumentoFiscal.listaTagsDadoAdicional(
      codigoDadoAdicionalDocumentoFiscal
    );
    if (response.data.length) setCamposPredefinidos(response.data);
  };

  const getOperacoes = async () => {
    if (operacoes.length === 0) {
      if (!searching) {
        setSearching(true);
        const response = await dadosAdicionaisDocumentoFiscal.listaOperacoes();
        if (response.data.length) {
          setOperacoes(response.data);
          setSearching(false);
        }
      }
    }
  };

  const trataOperacoesFiltradas = () => {
    let todasOperacoes = operacoes;
    if (todasOperacoes.length > 0) {
      let operacoesFiltradas = [];
      if (entrada && saida) operacoesFiltradas = todasOperacoes;
      else if (entrada) {
        operacoesFiltradas = todasOperacoes.filter(op =>
          sameInt(op.tipoOperacao, 0)
        );
      } else if (saida) {
        operacoesFiltradas = todasOperacoes.filter(op =>
          sameInt(op.tipoOperacao, 1)
        );
      }
      if (operacoesSelecionadas) {
        operacoesFiltradas.forEach(op => {
          op.selecionado = false;
          operacoesSelecionadas.forEach(opSelec => {
            if (sameInt(op.id, opSelec.codigoOperacao)) {
              op.selecionado = true;
            }
          });
        });
      } else {
        operacoesFiltradas.forEach(op => {
          op.selecionado = false;
        });
      }
      setOperacoesFiltradas(operacoesFiltradas);
    }
  };

  const cancel = async () => {
    let result = await confirm(
      '<h1>Deseja realmente cancelar a operação?</h1>',
      'Dados adicionais da Nota FIscal'
    );

    if (result) {
      backToScreen();
    }
  };

  const handlePredefinidoClick = async e => {
    const codigoCampoPredefinido = e.currentTarget.id;
    const tempDescricaoCompleta = descricaoCompleta;
    camposPredefinidos.forEach(cp => {
      if (sameInt(cp.id, codigoCampoPredefinido)) {
        setDescricaoCompleta(tempDescricaoCompleta + cp.tag);
      }
    });
  };

  const loadContent = () => {
    if (receivedData && receivedData.id) {
      setDescricaoResumida(receivedData.descricaoResumida);
      setDescricaoCompleta(receivedData.descricaoCompleta);
      setEntrada(receivedData.entrada);
      setSaida(receivedData.saida);
      setInteresseContribuinte(receivedData.contribuinte);
      setInteresseFisco(receivedData.fisco);
      setAceitaConteudoVariavel(receivedData.aceitaConteudoVariavel);
      setOperacoesSelecionadas(
        receivedData.operacoes.map(op => {
          return {
            codigoOperacao: op.operacao.id
          };
        })
      );
      getTags(receivedData.id);
      setLoadedContent(true);
    }
  };

  const save = async () => {
    if (!validationEngine.validateGroup().isValid) {
      notify(
        'Os campos obrigatórios devem ser informados.',
        'error',
        2000,
        'top'
      );
    } else if (!(entrada || saida)) {
      notify(
        'Deve ser selecionado ao menos um tipo de operação',
        'error',
        2000,
        'top'
      );
    } else if (!(interesseFisco || interesseContribuinte)) {
      notify(
        'Deve ser selecionado ao menos um tipo de dado adicional',
        'error',
        2000,
        'top'
      );
    } else {
      if (receivedData && !sameInt(receivedData.id, 0)) {
        notify({
          message: 'Aguarde enquanto as alterações são efetivadas',
          type: 'info',
          displayTime: 100,
          position: {
            my: 'bottom center',
            at: 'bottom center',
            of: null,
            offset: '0 -' + 10
          }
        });
        const response = await dadosAdicionaisDocumentoFiscal.alterar({
          id: receivedData.id,
          descricaoCompleta: descricaoCompleta,
          descricaoResumida: descricaoResumida,
          entrada: entrada,
          saida: saida,
          fisco: interesseFisco,
          contribuinte: interesseContribuinte,
          aceitaConteudoVariavel: aceitaConteudoVariavel,
          operacoes: operacoesSelecionadas.map(op => {
            return {
              operacao: {
                id: op.codigoOperacao
              }
            };
          }),
          codigoTipoDadoAdicional: 1
        });
        if (response.notificationLevel === 1) {
          if (response.data) {
            notify({
              message: 'Cadastro alterado com sucesso.',
              type: 'info',
              displayTime: 500,
              position: {
                my: 'bottom center',
                at: 'bottom center',
                of: null,
                offset: '0 -' + 100
              }
            });
            props.history.goBack();
          } else {
            notify(response.message, 'error', 2000);
            setSearching(false);
          }
        } else {
          notify(response.message, 'error', 2000);
          setSearching(false);
        }
      } else {
        notify({
          message: 'Aguarde enquanto a inserção é efetivada',
          type: 'info',
          displayTime: 50,
          position: {
            my: 'bottom center',
            at: 'bottom center',
            of: null,
            offset: '0 -' + 10
          }
        });
        const response = await dadosAdicionaisDocumentoFiscal.inserir({
          descricaoCompleta: descricaoCompleta,
          descricaoResumida: descricaoResumida,
          entrada: entrada,
          saida: saida,
          fisco: interesseFisco,
          contribuinte: interesseContribuinte,
          aceitaConteudoVariavel: aceitaConteudoVariavel,
          operacoes: operacoesSelecionadas.map(op => {
            return {
              operacao: {
                id: op.codigoOperacao
              }
            };
          }),
          codigoTipoDadoAdicional: 1
        });
        if (response.notificationLevel === 1) {
          if (response.data) {
            notify({
              message: 'Cadastro inserido com sucesso.',
              type: 'info',
              displayTime: 500,
              position: {
                my: 'bottom center',
                at: 'bottom center',
                of: null,
                offset: '0 -' + 100
              }
            });
            props.history.goBack();
          } else {
            notify(response.message, 'error', 2000);
            setSearching(false);
          }
        } else {
          notify(response.message, 'error', 2000);
          setSearching(false);
        }
      }
    }
  };

  const handleValueChange = e => {
    let key = e.event.key;
    let regex = new RegExp(ALPHANUMERIC_WITH_SPACE_PATTERN);
    if (!regex.test(key)) {
      e.event.preventDefault();
      return false;
    }
  };

  const handleOperacaoClick = e => {
    const codigoOperacao = e.element.id;
    const tempOperacoesSelecionadas = operacoesSelecionadas;
    if (e.event) {
      if (e.value) {
        tempOperacoesSelecionadas.push({
          codigoOperacao: parseInt(codigoOperacao)
        });
      } else {
        for (var i = 0; i < tempOperacoesSelecionadas.length; i++) {
          if (
            sameInt(tempOperacoesSelecionadas[i].codigoOperacao, codigoOperacao)
          ) {
            tempOperacoesSelecionadas.splice(i, 1);
          }
        }
      }
      setOperacoesSelecionadas(tempOperacoesSelecionadas);
    } else {
      operacoesFiltradas.forEach(op => {
        op.selecionado = false;
        tempOperacoesSelecionadas.forEach(opSelec => {
          if (sameInt(op.id, opSelec.codigoOperacao)) {
            op.selecionado = true;
          }
        });
      });
      setOperacoes(operacoes);
      setState({});
    }
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU, 'Detalhes')}>
      <div className={`${styles.dadosAdicionaisCadastro} dadosAdicionaisCadastro mt-3`}>
        <div className="shadow-sm bg-white p-3">
          <div className={`${showSearch ? styles.search : ''}`}>
            <div
              className={`container-fluid ${styles.cadastroDadosAdicionais}`}
            >
              <div className={`${styles.filtersrWrapper} row`}>
                <div className="col-md-12">
                  <h2>DADOS ADICIONAIS DA NOTA FISCAL</h2>
                  <hr />

                  <div
                    className={`${showSearch ? styles.fadeIn : ''} ${
                      styles.searchWrapper
                    } searchWrapper`}
                  >
                    <div className="row">
                      <div
                        className={`${styles.box} col-md-12 form-group mb-1`}
                      >
                        <span>Descrição resumida</span>
                        <TextBox
                          placeholder="Informe a descrição resumida"
                          showClearButton={true}
                          onValueChanged={e => setDescricaoResumida(e.value)}
                          value={descricaoResumida}
                          maxLength={100}
                          onKeyPress={e => handleValueChange(e)}
                          disabled={searching}
                        >
                          <Validator validationRules={[{ type: 'required' }]} />
                        </TextBox>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`${styles.box} ${
                          camposPredefinidos.length > 0
                            ? 'col-md-6'
                            : 'col-md-12'
                        } form-group mb-1`}
                      >
                        <span>Descrição completa</span>
                        <TextArea
                          height={90}
                          placeholder="Informe a descrição completa"
                          showClearButton={true}
                          onValueChanged={e => setDescricaoCompleta(e.value)}
                          value={descricaoCompleta}
                          maxLength={500}
                          onKeyPress={e => handleValueChange(e)}
                          disabled={searching}
                        >
                          <Validator validationRules={[{ type: 'required' }]} />
                        </TextArea>
                      </div>
                      {camposPredefinidos.length > 0 ? (
                        <>
                          <div
                            className={`${styles.box} col-md-6 form-group mb-1`}
                          >
                            <span>
                              Campos predefinidos (Clique na tag para ser
                              incluída na descrição completa)
                            </span>
                            <div className={`${styles.gridCamposPredefinidos} gridCamposPredefinidos`}>
                              {camposPredefinidos.map(cp => {
                                return (
                                  <div
                                    className={`${styles.predefinido}`}
                                    onClick={handlePredefinidoClick}
                                    id={cp.id}
                                  >
                                    <span>{cp.descricao}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="row">
                      <div
                        className={`${styles.input} ${styles.gridCheckBox} col-sm-4`}
                      >
                        <ul className={`${styles.ul}`}>
                          <li className={`${styles.li} ${styles.box}`}>
                            <span>Tipo de operação</span>
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Entrada"
                              value={entrada}
                              onValueChanged={e => setEntrada(e.value)}
                            />
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Saída"
                              value={saida}
                              onValueChanged={e => setSaida(e.value)}
                            />
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${styles.input} ${styles.gridCheckBox} col-sm-4`}
                      >
                        <ul className={`${styles.ul}`}>
                          <li className={`${styles.li} ${styles.box}`}>
                            <span>Tipo de dado adicional</span>
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Interesse do contribuinte"
                              value={interesseContribuinte}
                              onValueChanged={e =>
                                setInteresseContribuinte(e.value)
                              }
                            />
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Interesse do fisco"
                              value={interesseFisco}
                              onValueChanged={e => setInteresseFisco(e.value)}
                            />
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${styles.input} ${styles.gridCheckBox} col-sm-4`}
                      >
                        <ul className={`${styles.ul}`}>
                          <li className={`${styles.li} ${styles.box}`}>
                            <span>Conteúdo</span>
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Aceita conteúdo variável"
                              value={aceitaConteudoVariavel}
                              onValueChanged={e =>
                                setAceitaConteudoVariavel(e.value)
                              }
                            />
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className={`${styles.box} col-sm-12`}>
                      <div className="row">
                        <span>
                          Selecione as operações para tornar o dado adicional
                          automático
                        </span>
                        <div className={`${styles.gridOperacoes} gridOperacoes col-sm-12`}>
                          <ul className={`${styles.ul}`}>
                            {operacoesFiltradas.map(op => {
                              return (
                                <li className={`${styles.li}`}>
                                  <CheckBox
                                    text={op.descricao}
                                    value={op.selecionado}
                                    id={op.id}
                                    onValueChanged={e => handleOperacaoClick(e)}
                                  />
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <Footer saveAction={save} cancelAction={cancel} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LinxPostos>
  );
}

export default withRouter(DadosAdicionaisCadastro);
