import axios from "axios";
import { getToken } from "../../../../../authentication/Auth";
import { BASE_URL_GATEWAY } from "../../../../../shared/constants/GatewayUrlConstant";

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const getPayments = async (filter = "") => {
  const queryString = "?" + Object.entries(filter)
  .map(([key, value]) => `${key}=${value}`)
  .join("&");

  console.log(queryString);

  try {
    const response = await urlLinxCore.get(`Stone/RecuperaBoletoPagos${queryString}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "Ocorreu um erro desconhecido",
      }
    }
  }
}

const decodeSlip = async (slipData) => {
  try {
    const response = await urlLinxCore.post("Stone/DecodificarBoleto", slipData);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "Ocorreu um erro desconhecido",
      }
    }
  }

}


const paySlip = async (slipData) => {
  try {
    const response = await urlLinxCore.post("Stone/PagarBoleto", slipData);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "Ocorreu um erro desconhecido",
      }
    }
  }

}

const getPdfDownload = async (id) => {
  try {
    const response = await urlLinxCore.get(`/Stone/ObterComprovantePDF?id=${id}`, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${getFormattedReciptName()}.pdf`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error during PDF download", error);
  }
};

const getFormattedReciptName = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hour = now.getHours().toString().padStart(2, "0");
  const minute = now.getMinutes().toString().padStart(2, "0");
  const second = now.getSeconds().toString().padStart(2, "0");

  return `comprovante_${year}${month}${day}${hour}${minute}${second}`;
}

export const PagamentosService = {
  getPayments,
  decodeSlip,
  paySlip,
  getPdfDownload
};
