import axios from 'axios';

import {
  setPermissoesMenu,
  setDadosAutenticacao,
  removeDadosLogin,
  setDadosLogin,
  setDadosUsuario,
  getDadosLogin
} from '../shared/utils/Utils';

import { getMontagemMenu } from '../services/MenuPermissao.service';

export const TOKEN_KEY = '@LinxPostos-Token';
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);
export const login = token => {
  sessionStorage.setItem(TOKEN_KEY, token);
  window.dispatchEvent(new CustomEvent('login', { detail: { action: 'login', token } }));
};
export const logout = () => {
  sessionStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem('user');
  const d = getDadosLogin();
  removeDadosLogin();
  window.dispatchEvent(new CustomEvent('logout', { detail: { action: 'logout', dadosLogin: d } }));
};

const getCodigoUsuario = async (Url, Email, Senha, Cnpj) => {
  axios.defaults.baseURL = Url;
  return axios.post(`LxApi/v1/auth`, {
    username: Email,
    password: Senha,
    cnpjEstabelecimento: Cnpj
  });
};

export const getEmpresaCadastrada = async (url) => {
  axios.defaults.baseURL = url;
  return axios.get('LxApi/v1/empresa/estabelecimento-cadastrado');
}

export const permissionamento = async (
  Cnpj,
  Url,
  Email,
  Senha,
  logo,
  logoSmall,
  tipoSiteUrl,
  empresas,
  tipoSite,
  templateTema,
  share
) => {
  const { data: respDadosAcesso } = await getCodigoUsuario(
    Url,
    Email,
    Senha,
    Cnpj
  );

  const codigoUsuario = respDadosAcesso.codigoUsuario;
  
  setDadosLogin(
    Cnpj,
    Url,
    respDadosAcesso.codigoEstabelecimento,
    logo,
    logoSmall,
    tipoSiteUrl,
    respDadosAcesso.nome,
    respDadosAcesso.login,
    empresas,
    tipoSite,
    templateTema,
    share
  );
  const dadosUsuario = {
    codigo: codigoUsuario,
    codigoPerfilAcesso: respDadosAcesso.codigoPerfil,
    nome: respDadosAcesso.nome,
    cpf: '',
    dataCadastro: ''
  }
  setDadosUsuario(dadosUsuario, Email)


  setDadosAutenticacao(respDadosAcesso.token, respDadosAcesso.codigoPerfil);

  const { data: respPermissoes } = await getMontagemMenu(codigoUsuario);
  if (respPermissoes) {
    setPermissoesMenu(respPermissoes);
  }
};
