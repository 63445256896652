import memoryCache from 'memory-cache';
import _ from 'lodash';
import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiRecuperarProdutoRedeService {
  static instance = null;
  static getInstance() {
    if (!ApiRecuperarProdutoRedeService.instance) {
      ApiRecuperarProdutoRedeService.instance = new ApiRecuperarProdutoRedeService();
    }
    return ApiRecuperarProdutoRedeService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (redeCod,  useCache = false) => {
    let cacheKey = '';
    if (useCache) {
      const dadosLogin = getDadosLogin();
      cacheKey = `ApiRecuperarProdutoRedeService${dadosLogin.cnpj}-${redeCod}`;
      const cachedData = memoryCache.get(cacheKey);
      if (cachedData) {
        return cachedData;
      }
    }
    try {
      const response = await this.urlLinxCore.get(`/financeiro/produtos-rede/${redeCod}`);
      let data = response.data.itens || [];
      data = _.sortBy(data, 'descricao', 'asc');
      if (useCache) {
        memoryCache.put(cacheKey, data, 30 * 60 * 1000); // cache de 30 minutos
      }
      return data;
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  };
}
