import axiosErrorService from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class EditarEnderecoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  async execute(data) {
    try {
      var response = await this.urlLinxCore.post(`/Cliente/editar-endereco`, data);
      return response.data.item;
    } catch (error) {
      axiosErrorService.execute(error);
    }
  }
}
