import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Select,
  // FormOptions,
  handleInputChange,
  Checkbox,
  Checkitem,
  handleInputChangeRegexForceUpper
} from '../../../components/formulario/Formulario';
import List from '../../../components/formulario/List';
import styles from './Maquinas.module.scss';
import {
  SectionContent,
  SectionContainer
} from './../../../components/section/Content';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
  getCnpj,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  sameFloat,
  none,
  emptySelect,
  sameString,
  sameInt,
  makeList,
  sortList
} from '../../../shared/utils/Utils';
import {
  showInfo,
  showError,
  showQuestion,
  showSaveError,
  showDeleteError,
  hideAlert,
  tagAlert,
  hideWait,
  showWait
} from '../../../components/alert/Alert';
import {
  getMaquinasPerifericos,
  montaComboEquipamento,
  montaComboTipoEquipamento,
  alterarMaquinaPeriferico,
  incluirMaquinaPeriferico,
  excluirMaquinaPeriferico
} from './Maquinas.service';

import { montaComboAlmoxarifado } from '../../estoque/depositos/Deposito.service';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from '../../../components/modal/Modal';
import {
  deleteInList,
  appendInList,
  changeInList
} from '../../../shared/utils/TableUtils';
import { ALPHANUMERIC_WITH_HYPHEN_UNDERSCORE_PATTERN } from '../../../shared/constants/Const';
import { montarComboFuncionarios } from '../../../services/Funcionarios.service';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import Footer from '../../../components/core/footer/Footer';
import { menus } from '../../../shared/constants/MenuConstants';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';

const screenUrl = '/cadastro/maquinas-perifericos/';
const NEW_PATH = '/cadastro/maquinas-perifericos/new';
const titulo = 'Máquinas e Periféricos';

const TipoEquipamento = {
  ConcentradorBomba: '5',
  ImpressoraFiscal: '1',
  ImpressoraNaoFiscal: '10',
  Tef: '4',
  Gaveteiro: '9',
  Sat: '11'
};

const PATH_MENU = menus.COD_60490;

class Form extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleInputChangeRegexForceUpper = handleInputChangeRegexForceUpper.bind(
      this
    );

    const listaPerifericos =
      this.props.maquina && this.props.maquina.perifericos
        ? this.props.maquina.perifericos
        : [];

    const {
      cnpjEstabelecimento = getCnpj(),
      //Maquina
      codigoMaquina = null,
      //almoxarifados
      almoxarifado = [],
      nome = '',
      descricao = '',
      inativo = false,
      comunicaConcentrador = false,
      vendeCombustivel = false,
      //Configuração
      valorAvisoSangria = '',
      valorMaxCaixa = '',
      tempoAviso = '',
      sangriaCega = false,
      imprimeValoresComprovSaida = false,
      valorInicialCaixa = '',
      permiteAlterarValor = false,
      viasComprovante = '',
      solicitaClienteInicioVenda = false,
      numSerieNFe = '',
      quantidadeLimiteVenda = '',
      imprimeLeituraX = '',
      integraDPos = false,
      abreFechaTurnoAuto = false,
      responsavelTurnoAuto = null,
      viasSangria = '',
      versaoPDV,
      dataAtualizacaoPDV,
      //Perifericos
      indicadorSmartPos,
      responsavelTurnoSmartPos,
      indicadorTurnoAutomaticamente,
      indicadorLogin,
      indicadorLancaProdutos,
      indicadorLancaCombustivel,
      indicadorValidaEstoqueCarrinho,
      indicadorDinheiro,
      indicadorNotaPrazo,
      indicadorTicket,
      indicadorQRLinx,
      posdtefIndicadorDinheiro,
      posdtefIndicadorTicket,

      rowVersion = null
    } = this.props.maquina;

    this.state = {
      //Modal
      showModal: false,
      operation: null,

      codigoTipoEquipamento: 0,

      //Maquina
      cnpjEstabelecimento,
      codigoMaquina,
      nome,
      descricao,
      ativo: !inativo,
      //almoxarifados
      almoxarifado:
        almoxarifado.length > 0
          ? almoxarifado
          : [
              {
                codigo: null,
                descricao: null
              }
            ],
      comunicaConcentrador,
      vendeCombustivel,
      descricaoEquipamento: '',

      //Configuração
      valorAvisoSangria,
      valorMaxCaixa,
      tempoAviso,
      sangriaCega,
      imprimeValoresComprovSaida,
      valorInicialCaixa,
      permiteAlterarValor,
      viasComprovante,
      solicitaClienteInicioVenda,
      numSerieNFe,
      quantidadeLimiteVenda,
      imprimeLeituraX,
      viasSangria,
      rowVersion,

      //Perifericos
      listaPerifericos,

      maquinasExistentes: [],

      almoxarifados: [],
      tipoEquipamentos: [],
      equipamentos: [],
      objTipoEquipamento: {},
      integraDPos,
      abreFechaTurnoAuto,
      responsavelTurnoAuto,
      listaResponsaveis: [],
      versaoPDV,
      dataAtualizacaoPDV,
      indicadorSmartPos,
      responsavelTurnoSmartPos,
      indicadorTurnoAutomaticamente,
      indicadorLogin,
      indicadorLancaProdutos,
      indicadorLancaCombustivel,
      indicadorValidaEstoqueCarrinho,
      indicadorDinheiro,
      indicadorNotaPrazo,
      indicadorTicket,
      indicadorQRLinx,
      posdtefIndicadorDinheiro,
      posdtefIndicadorTicket
    };
  }

  validateAlmoxarifados = () => {
    const list = this.state.almoxarifado;
    let temErro = false;
    if (
      list.length === 0 ||
      (list.length === 1 && emptySelect(list[0].codigo))
    ) {
      temErro = true;
      showError(
        this,
        titulo,
        'É necessário informar ao menos um almoxarifado.'
      );
    } else
      for (let i = 0; i < list.length; i++) {
        if (sameString(list[i].codigo, 0)) {
          temErro = true;
          showError(
            this,
            titulo,
            'Se incluir um novo almoxarifado selecione-o na lista.'
          );
          break;
        }
        if (i > 0) {
          for (let j = 0; j < i; j++) {
            if (sameString(list[j].codigo, list[i].codigo)) {
              temErro = true;
              showError(
                this,
                titulo,
                'Não adicione o mesmo almoxarifado mais de uma vez.'
              );
              break;
            }
          }
        }
      }
    return temErro;
  };

  async componentDidMount() {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto os dados são carregados.'
    );
    try {
      this.setState({ maquinasExistentes: this.props.maquinasExistentes });

      let almoxarifado = this.state.almoxarifado;

      const { data: almoxarifados } = await montaComboAlmoxarifado(
        getCnpj(),
        0
      );

      const almoxarifadosCompleto = almoxarifados.map(almoxarifado => {
        return {
          label: almoxarifado.descricao,
          value: almoxarifado.codigo
        };
      });

      almoxarifadosCompleto.unshift({
        value: 0,
        label: 'Selecione um Almoxarifado'
      });

      almoxarifado = almoxarifado.map(a => {
        let almo = almoxarifados.find(as => as.codigo === a.codigoAlmoxarifado);
        return {
          codigo: a.codigoAlmoxarifado,
          descricao: almo ? almo.descricao : ''
        };
      });

      this.setState({
        almoxarifados: almoxarifadosCompleto,
        almoxarifado
      });

      ////// TIPO EQUIPAMENTO ///////
      const { data: tipoEquipamento } = await montaComboTipoEquipamento();

      const tipoEquipamentos = tipoEquipamento.map(equips => {
        return {
          label: equips.descricao,
          value: equips.codigo
        };
      });

      tipoEquipamentos.unshift({
        value: 0,
        label: 'Selecione um Tipo de Equipamento'
      });
      this.setState({ tipoEquipamentos });

      let codigoTipoEquipamento = tipoEquipamentos.codigo;
      let descricaoTipoEquipamento = tipoEquipamentos.descricao;
      if (!this.props.rowVersion) {
        for (let i = 0; i < tipoEquipamentos.length; i++) {
          const te = tipoEquipamentos[i];
          codigoTipoEquipamento = te.codigo;
          descricaoTipoEquipamento = te.descricao;
          break;
        }
        this.setState({
          tipoEquipamento: codigoTipoEquipamento,
          descricaoEquipamento: descricaoTipoEquipamento
        });
      }

      const listaCompleta = [];
      if (this.state.listaPerifericos.length > 0) {
        for (let i = 0; i < this.state.listaPerifericos.length; i++) {
          const lista = this.state.listaPerifericos[i];

          lista.descricaoTipoEquipamento = this.getDescTipoEquip(
            lista.codigoTipoEquipamento
          );
          lista.marca = lista.descricaoPeriferico;

          listaCompleta.push(lista);
        }
        this.setState({ listaPerifericos: listaCompleta });
      }

      let listaResponsaveis = [];
      const { data: dataResp } = await montarComboFuncionarios();
      listaResponsaveis = makeList(
        sortList(dataResp, 'descricao'),
        'Selecione um responsável',
        false
      );

      this.setState({ listaResponsaveis });
    } finally {
      hideWait(this);
    }
  }

  validacaoGrid = () => {
    if (this.state.nome === '' || this.state.nome.trim() === '') {
      showError(
        this,
        titulo,
        'É obrigatório informar o Nome da Máquina',
        'nome'
      );
      return false;
    }
    if (this.state.descricao === '' || this.state.descricao.trim() === '') {
      showError(
        this,
        titulo,
        'É obrigatório informar a Descrição da Máquina',
        'descricao'
      );
      return false;
    }
    if (this.state.almoxarifado.length === 0) {
      showError(
        this,
        titulo,
        'É obrigatório informar o Almoxarifado',
        'almoxarifado'
      );
      return false;
    }
    if (this.state.tipoEquipamento === 0) {
      showError(
        this,
        titulo,
        'É obrigatório informar o Tipo de Equipamento',
        'tipoEquipamento'
      );
      return false;
    }
    //if (this.state.periferico.length === 0) {
    //  showError(this, titulo, "É obrigatório selecionar um Tipo de Equipamento", "periferico");
    //  return false
    //}

    if (this.state.listaPerifericos.length > 0) {
      let list = this.state.listaPerifericos;
      for (let i = 0; i < list.length; i++) {
        if (
          parseInt(list[i].codigoTipoEquipamento) ===
          parseInt(this.state.tipoEquipamento)
        ) {
          showError(
            this,
            titulo,
            'A Máquina não deve conter Equipamentos de mesmo Tipo',
            'tipoEquipamento'
          );
          return false;
        }
      }
    }
    return true;
  };

  // Modal
  handleOpenModal = codigoTipoEquipamento => {
    let validou = this.validacaoGrid();
    //this.setState({ operation: null });

    if (codigoTipoEquipamento === undefined || codigoTipoEquipamento === '0') {
      this.validaTipoEquipamento(codigoTipoEquipamento);
    } else if (validou) {
      let tipoEquipamento = this.state.tipoEquipamentos.filter(
        e => e.value === parseInt(codigoTipoEquipamento)
      );

      // const { periferico } = this.state;

      let descricaoEquipamento = tipoEquipamento[0].label;

      this.updateComboEquip(this.state.tipoEquipamento);

      const objTipoEquipamento = {
        codigoTipoEquipamento: codigoTipoEquipamento,
        descricaoTipoEquipamento: descricaoEquipamento
      };

      //Altera o setState para True e Abre o Modal
      this.setState({
        showModal: true,
        operation: null,
        objTipoEquipamento
      });
    }
  };

  handleEditModal = (objTipoEquipamento, operation) => {
    this.updateComboEquip(parseInt(objTipoEquipamento.codigoTipoEquipamento));

    this.setState({
      showModal: true,
      operation,
      objTipoEquipamento
    });
  };

  validaPerifericos = objPeriferico => {
    const CodigoEquipamento = {
      ImpressoraNaoFiscal: '10',
      TEF: '4',
      ConcentradorBomba: '5',
      Gaveteiro: '9',
      SAT: '11',
      BalancaPDV: '2',
      DisplayCliente: '7',
      SmartPos: '14'
    };

    if (
      objPeriferico.codigoEquipamento === '' ||
      objPeriferico.codigoEquipamento === undefined ||
      objPeriferico.marca === ''
    ) {
      showError(
        this,
        titulo,
        'Por favor. Selecione um Equipamento válido.',
        'codigoEquipamento'
      );
      return true;
    }

    if (
      objPeriferico.codigoTipoEquipamento ===
      CodigoEquipamento.ImpressoraNaoFiscal
    ) {
      if (
        objPeriferico.portaSerial === '' ||
        objPeriferico.portaSerial === undefined
      ) {
        showError(
          this,
          titulo,
          'É obrigatório informar a Porta Serial',
          'portaSerial'
        );
        return true;
      }
      if (
        parseInt(objPeriferico.portaSerial) < 1 ||
        parseInt(objPeriferico.portaSerial) > 9
      ) {
        showError(
          this,
          titulo,
          'A Porta Serial deve ser entre 1 e 9',
          'portaSerial'
        );
        return true;
      }
      if (
        objPeriferico.numSerieNFCe === '' ||
        objPeriferico.numSerieNFCe === undefined
      ) {
        showError(
          this,
          titulo,
          'É obrigatório informar o Número de Série NFC-e',
          'portaSerial'
        );
        return true;
      }
      if (
        parseInt(objPeriferico.numSerieNFCe) < 1 ||
        parseInt(objPeriferico.numSerieNFCe) > 890
      ) {
        showError(
          this,
          titulo,
          'O número de série NFC-e deve ser entre 1 e 890',
          'numSerieNFCe'
        );
        return true;
      }
    }

    if (
      objPeriferico.codigoTipoEquipamento ===
      CodigoEquipamento.ConcentradorBomba
    ) {
      if (
        parseInt(objPeriferico.numNiveisPreco) < 1 ||
        parseInt(objPeriferico.numNiveisPreco) > 5
      ) {
        showError(
          this,
          titulo,
          'Os números de Níveis de Preço deve ser entre 1 e 5',
          'numNiveisPreco'
        );
        return true;
      }
      if (!this.state.comunicaConcentrador) {
        showError(
          this,
          titulo,
          "Para adicionar um concentrador é necessário marcar a opção 'Comunica com o concentrador'"
        );
        return true;
      }
    }

    if (objPeriferico.codigoTipoEquipamento === CodigoEquipamento.SAT) {
      if (
        objPeriferico.numSerieFabricante === '' ||
        objPeriferico.numSerieFabricante === undefined
      ) {
        showError(
          this,
          titulo,
          'O número de série do Fabricante não pode estar vazio.',
          'numSerieFabricante'
        );
        return true;
      }

      if (objPeriferico.numSerieFabricante.length > 47) {
        showError(
          this,
          titulo,
          'O número de série do Fabricante não pode ter mais de 47 carateres.',
          'numSerieFabricante'
        );
        return true;
      }

      if (objPeriferico.ativo === '' || objPeriferico.ativo === undefined) {
        showError(
          this,
          titulo,
          'O código de ativação não pode estar vazio.',
          'ativo'
        );
        return true;
      }

      if (
        objPeriferico.caixaSat === '' ||
        objPeriferico.caixaSat === undefined
      ) {
        showError(
          this,
          titulo,
          'O valor de Caixa SAT não pode estar vazio.',
          'caixaSat'
        );
        return true;
      }

      if (
        parseInt(objPeriferico.caixaSat) < 1 ||
        parseInt(objPeriferico.caixaSat) > 890
      ) {
        showError(
          this,
          titulo,
          'A caixa SAT deve ser entre 1 e 890',
          'caixaSat'
        );
        return true;
      }
    }
  };

  handleAlteraObjEquipamento = (name, value) => {
    const CodigoEquipamento = {
      ImpressoraNaoFiscal: '10',
      TEF: '4',
      ConcentradorBomba: '5',
      Gaveteiro: '9',
      SAT: '11',
      BalancaPDV: '2',
      DisplayCliente: '7',
      SmartPos: '14'
    };

    let tempObjTipoEquipamento = this.state.objTipoEquipamento;

    switch (name) {
      case 'codigoEquipamento':
        tempObjTipoEquipamento.codigoEquipamento = value;
        break;

      case 'marca':
        tempObjTipoEquipamento.marca = value;
        break;

      case 'ativo':
        tempObjTipoEquipamento.ativo = value;
        break;

      case 'usadaPraNFCe':
        tempObjTipoEquipamento.usadaPraNFCe = value;
        break;

      case 'ipMaquinaMIDe':
        tempObjTipoEquipamento.ipMaquinaMIDe =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.ImpressoraNaoFiscal)
            ? value
            : null;
        break;

      case 'portaSerial':
        tempObjTipoEquipamento.portaSerial =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.ImpressoraNaoFiscal)
            ? value
            : null;
        break;

      case 'numSerieNFCe':
        tempObjTipoEquipamento.numSerieNFCe =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
            parseInt(CodigoEquipamento.ImpressoraNaoFiscal)
            ? value
            : null;
        break;

      /*case "ipMideClient":
        tempObjTipoEquipamento.ipMideClient =
          tempObjTipoEquipamento.codigoTipoEquipamento ===
          CodigoEquipamento.ImpressoraNaoFiscal
            ? value
            : null;
        break;*/

      case 'numNiveisPreco':
        tempObjTipoEquipamento.numNiveisPreco =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.ConcentradorBomba)
            ? value
            : null;
        break;

      case 'usaCasadoMilhao':
        tempObjTipoEquipamento.usaCasadoMilhao =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.ConcentradorBomba)
            ? value
            : false;
        break;

      case 'off':
        tempObjTipoEquipamento.off =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.Gaveteiro)
            ? value
            : false;
        break;

      case 'numSerieFabricante':
        tempObjTipoEquipamento.numSerieFabricante =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.SAT) || 
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
            parseInt(CodigoEquipamento.SmartPos)
            ? value
            : null;
        break;

      case 'codigoAtivacao':
        tempObjTipoEquipamento.codigoAtivacao =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.SAT)
            ? value
            : null;
        break;

      case 'caixaSat':
        tempObjTipoEquipamento.caixaSat =
          parseInt(tempObjTipoEquipamento.codigoTipoEquipamento) ===
          parseInt(CodigoEquipamento.SAT)
            ? value
            : null;
        break;

      default:
        break;
    }

    this.setState({ objTipoEquipamento: tempObjTipoEquipamento });
  };

  validaTipoEquipamento = tipoEquipamento => {
    if (tipoEquipamento === '0' || tipoEquipamento === undefined) {
      showError(
        this,
        titulo,
        'É obrigatório informar um Tipo de Equipamento',
        'tipoEquipamento'
      );
      return false;
    }
    this.setState({ showModal: false });
  };

  handleCloseModal = save => {
    if (save) {
      // let listaPerifericos = this.state.listaPerifericos;

      let objPeriferico = this.state.objTipoEquipamento;

      //listaPerifericos.push(objPeriferico);

      this.setState({ operation: null });
      if (this.validaPerifericos(objPeriferico)) {
        // && close !== true
        //alert("POSSUI ERROS.");
        return this.setState({
          showModal: true
        });
      } else {
        //alert("NÃO POSSUI ERRO");

        let operation = this.state.operation;

        if (operation === 'edit') {
          const listaPerifericos = this.state.listaPerifericos;

          for (let i = 0; i < listaPerifericos.length; i++) {
            //const element = listaPerifericos[i];

            if (
              objPeriferico.codigoEquipamento ===
              listaPerifericos[i].codigoEquipamento
            ) {
              listaPerifericos[i] = objPeriferico;
            }
          }

          return this.setState({
            showModal: false,
            operation: null,
            listaPerifericos
          });
        }

        if (objPeriferico === false) {
          this.setState({ showModal: false });
        } else {
          let listaPerifericos = this.state.listaPerifericos;

          listaPerifericos.push(objPeriferico);

          this.setState({ showModal: false, listaPerifericos });
        }
      }
    } else {
      this.setState({ showModal: false });
    }
  };

  handleChangeGrid = (value, name, id) => {
    const listaPerifericos = this.state.listaPerifericos.map(periferico => {
      if (listaPerifericos.tipoEquipamento === id) {
        return { ...listaPerifericos, [name]: value };
      } else {
        return periferico;
      }
    });
    this.setState({ listaPerifericos: listaPerifericos });
  };

  handleCancelar = async () => {
    showQuestion(
      this,
      titulo,
      'Deseja Realmente Cancelar a Operação?',
      null,
      resp => {
        if (resp) hideAlert(this, this.props.history, screenUrl);
        else hideAlert(this);
      }
    );
  };

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    showQuestion(
      this,
      titulo,
      'Deseja realmente excluir essa máquina?',
      null,
      async resp => {
        if (resp) {
          try {
            showWait(
              this,
              titulo,
              'Por favor, aguarde enquanto a máquina é excluída.'
            );
            await excluirMaquinaPeriferico(this.state.codigoMaquina);
            showInfo(
              this,
              titulo,
              'Máquina excluída com sucesso!',
              null,
              () => {
                hideAlert(this, this.props.history, screenUrl);
              }
            );
          } catch (e) {
            showDeleteError(this, titulo, e, null, () => {
              hideAlert(this, this.props.history, screenUrl);
            });
          }
        } else {
          hideAlert(this);
        }
      }
    );
  };

  updateComboEquip = async codigoTipoEquipamento => {
    const { data } = await montaComboEquipamento(
      parseInt(codigoTipoEquipamento)
    );

    const opcoesEquipamento = data.map(list => {
      return {
        label: list.descricao,
        value: list.codigo
      };
    });

    //if (opcoesEquipamento.length > 1) // Se os valores do combo forem menores que 1. não acrescenta um label informando para ele selecionar
    opcoesEquipamento.unshift({ label: 'Selecione', value: '' });
    this.setState({ opcoesEquipamento });
  };

  changeTipoEquip = event => {
    let value, name;
    let desc = '';

    value = event.target.value;
    name = event.name;

    const list = this.state.tipoEquipamentos;
    for (let i = 0; i < list.length; i++) {
      const te = list[i];
      if (parseInt(te.value) === parseInt(value)) {
        desc = te.label;
      }
    }

    this.setState({ tipoEquipamento: value, descricaoEquipamento: desc });
    //this.updateComboEquip(value);
    this.setState({ [name]: value });
  };

  isFormValid = async () => {
    let {
      integraDPos,
      abreFechaTurnoAuto,
      responsavelTurnoAuto,
      indicadorSmartPos,
      indicadorTurnoAutomaticamente,
      responsavelTurnoSmartPos
    } = this.state;

    if (integraDPos) {
      if (abreFechaTurnoAuto && !responsavelTurnoAuto) {
        showError(
          this,
          titulo,
          'Selecione o responsável pelo turno do POS TEF',
          'responsavelTurnoAuto'
        );
        return false;
      }
    } else {
      this.setState({
        abreFechaTurnoAuto: false,
        responsavelTurnoAuto: null
      });
    }

    if (indicadorSmartPos) {
      if (indicadorTurnoAutomaticamente && !responsavelTurnoSmartPos) {
        showError(
          this,
          titulo,
          'Selecione o responsável pelo turno do Smart POS',
          'responsavelTurnoAuto'
        );
        return false;
      }
    } else {
      this.setState({
        indicadorTurnoAutomaticamente: false,
        responsavelTurnoSmartPos: null
      });
    }

    return true;
  };

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    if (!(await this.isFormValid())) {
      return;
    }

    let {
        //Maquina
        cnpjEstabelecimento,
        codigoMaquina,
        nome,
        descricao,
        ativo,
        almoxarifado,
        comunicaConcentrador,
        vendeCombustivel,

        //Configuração
        valorAvisoSangria,
        valorMaxCaixa,
        tempoAviso,
        sangriaCega,
        imprimeValoresComprovSaida,
        valorInicialCaixa,
        permiteAlterarValor,
        viasComprovante,
        solicitaClienteInicioVenda,
        numSerieNFe,
        quantidadeLimiteVenda,
        imprimeLeituraX,
        rowVersion,
        integraDPos,
        abreFechaTurnoAuto,
        responsavelTurnoAuto,
        viasSangria,
        indicadorSmartPos,
        responsavelTurnoSmartPos,
        indicadorTurnoAutomaticamente,
        indicadorLogin,
        indicadorLancaProdutos,
        indicadorLancaCombustivel,
        indicadorValidaEstoqueCarrinho,
        indicadorDinheiro,
        indicadorNotaPrazo,
        indicadorTicket,
        indicadorQRLinx,
        posdtefIndicadorDinheiro,
        posdtefIndicadorTicket
      } = this.state,
      //lista de perifericos
      perifericos = [],
      //lista de almoxarifados -> para montar a ordem
      almoxs = [];

    let listaPerifericos = this.state.listaPerifericos;
    for (let i = 0; i < listaPerifericos.length; i++) {
      let element = listaPerifericos[i];

      let objPeriferico = {
        codigoTipoEquipamento: this.state.tipoEquipamento,
        codigoPeriferico: null,
        descricaoPeriferico: this.state.descricaoEquipamento,
        codigoEquipamento: null,
        equipamento: this.state.equipamento,
        usadaPraNFCe: null,
        numSerieNFCe: null,
        ipMaquinaMIDe: null,
        ipMideClient: null,
        portaSerial: null,
        usaCasadoMilhao: null,
        numNiveisPreco: null,
        off: null,
        numSerieFabricante: null,
        codigoAtivacao: null,
        caixaSat: null,
        rowVersion: null
      };

      objPeriferico.codigoPeriferico = parseInt(element.codigoPeriferico);
      objPeriferico.descricaoTipoEquipamento = element.descricaoTipoEquipamento;
      objPeriferico.codigoTipoEquipamento = parseInt(
        element.codigoTipoEquipamento
      );
      objPeriferico.descricaoPeriferico = element.marca;
      objPeriferico.codigoEquipamento = parseInt(element.codigoEquipamento);
      objPeriferico.usadaPraNFCe = element.usadaPraNFCe;
      objPeriferico.numSerieNFCe = element.numSerieNFCe;
      objPeriferico.ipMaquinaMIDe = element.ipMaquinaMIDe;
      objPeriferico.ipMideClient = element.ipMideClient;
      objPeriferico.portaSerial = element.portaSerial;
      objPeriferico.usaCasadoMilhao = element.usaCasadoMilhao;
      objPeriferico.numNiveisPreco = element.numNiveisPreco;
      objPeriferico.off = element.off;
      objPeriferico.numeroFabricante = element.numSerieFabricante;
      objPeriferico.codigoAtivacao = element.ativo;
      objPeriferico.caixaSat = element.caixaSat;
      objPeriferico.rowVersion = element.rowVersion;

      perifericos.push(objPeriferico);
    }

    //Monta objeto almoxarifado com a ordem
    let contador = 0;
    for (let i = 0; i < almoxarifado.length; i++) {
      contador++;
      let objAlmoxarifado = {
        codigoAlmoxarifado: almoxarifado[i].codigo,
        numeroOrdem: contador
      };
      //let almox = montaAlmoxarifado(almoxarifado[i], );

      almoxs.push(objAlmoxarifado);
    }

    const [action, params] =
      codigoMaquina !== null
        ? [
            alterarMaquinaPeriferico,
            [
              codigoMaquina,
              cnpjEstabelecimento,
              nome,
              descricao,
              !ativo,
              almoxs,
              comunicaConcentrador,
              vendeCombustivel,

              //Configuração
              valorAvisoSangria,
              valorMaxCaixa,
              tempoAviso,
              sangriaCega,
              imprimeValoresComprovSaida,
              valorInicialCaixa,
              permiteAlterarValor,
              viasComprovante,
              solicitaClienteInicioVenda,
              numSerieNFe,
              quantidadeLimiteVenda,
              imprimeLeituraX,
              //Perifericos
              perifericos,
              integraDPos,
              abreFechaTurnoAuto,
              responsavelTurnoAuto,
              viasSangria,
              rowVersion,
              indicadorSmartPos,
              responsavelTurnoSmartPos,
              indicadorTurnoAutomaticamente,
              indicadorLogin,
              indicadorLancaProdutos,
              indicadorLancaCombustivel,
              indicadorValidaEstoqueCarrinho,
              indicadorDinheiro,
              indicadorNotaPrazo,
              indicadorTicket,
              indicadorQRLinx,
              posdtefIndicadorDinheiro,
              posdtefIndicadorTicket
            ]
          ]
        : [
            incluirMaquinaPeriferico,
            [
              cnpjEstabelecimento,
              nome,
              descricao,
              !ativo,
              almoxs,
              comunicaConcentrador,
              vendeCombustivel,

              //Configuração
              valorAvisoSangria,
              valorMaxCaixa,
              tempoAviso,
              sangriaCega,
              imprimeValoresComprovSaida,
              valorInicialCaixa,
              permiteAlterarValor,
              viasComprovante,
              solicitaClienteInicioVenda,
              numSerieNFe,
              quantidadeLimiteVenda,
              imprimeLeituraX,
              //Perifericos
              perifericos,
              integraDPos,
              abreFechaTurnoAuto,
              responsavelTurnoAuto,
              viasSangria,
              indicadorSmartPos,
              responsavelTurnoSmartPos,
              indicadorTurnoAutomaticamente,
              indicadorLogin,
              indicadorLancaProdutos,
              indicadorLancaCombustivel,
              indicadorValidaEstoqueCarrinho,
              indicadorDinheiro,
              indicadorNotaPrazo,
              indicadorTicket,
              indicadorQRLinx,
              posdtefIndicadorDinheiro,
              posdtefIndicadorTicket
            ]
          ];

    let temErros = this.verificaMaquinas(action);

    if (!temErros && action === incluirMaquinaPeriferico) {
      temErros = this.verificaExistente(this.state.codigoMaquina);
    }

    if (!temErros) {
      temErros = this.validateAlmoxarifados();
    }
    if (!temErros) {
      try {
        showWait(
          this,
          titulo,
          'Por favor, aguarde enquanto a máquina é salva.'
        );
        await action(...params);
        showInfo(
          this,
          titulo,
          `Máquina  ${
            codigoMaquina === null ? 'cadastrada' : 'alterada'
          } com sucesso!`,
          null,
          () => {
            hideAlert(this, this.props.history, screenUrl);
          }
        );
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
  };

  verificaMaquinas = action => {
    let listaMaquinasExistentes = this.state.maquinasExistentes;
    let codigoMaquinaSelecionada = this.state.codigoMaquina;
    let nomeMaquinaConcentrador = '';
    let descricaoMaquinaConcentrador = '';

    if (this.state.comunicaConcentrador) {
      for (let i = 0; i < listaMaquinasExistentes.length; i++) {
        if (listaMaquinasExistentes[i].comunicaConcentrador) {
          nomeMaquinaConcentrador = listaMaquinasExistentes[i].nome;
          descricaoMaquinaConcentrador = listaMaquinasExistentes[i].descricao;
          let codigoMaquinaConcentrador =
            listaMaquinasExistentes[i].codigoMaquina;

          if (codigoMaquinaConcentrador === codigoMaquinaSelecionada) {
            return false;
          } else {
            showError(
              this,
              titulo,
              'A Máquina ' +
                nomeMaquinaConcentrador +
                ' / ' +
                descricaoMaquinaConcentrador +
                ' já está se comunicando com o Concentrador.'
            );
            return true;
          }
        }
      }
    }

    if (this.state.integraDPos) {
      let ok = true,
        nome = '',
        desc = '';
      if (listaMaquinasExistentes && listaMaquinasExistentes.length > 0) {
        listaMaquinasExistentes.forEach(m => {
          if (
            m.integraDPos &&
            !sameInt(m.codigoMaquina, this.state.codigoMaquina)
          ) {
            ok = false;
            nome = m.nome;
            desc = m.descricao;
            return;
          }
        });
      }

      if (!ok) {
        showError(
          this,
          titulo,
          'A Máquina ' +
            nome +
            ' / ' +
            desc +
            ' já está integrada com o POS D-TEF.'
        );
        return true;
      }
    }

    if (this.state.nome === '' || this.state.nome.trim() === '') {
      showError(
        this,
        titulo,
        'É obrigatório informar o nome da máquina',
        'nome'
      );
      return true;
    }
    if (this.state.descricao === '' || this.state.descricao.trim() === '') {
      showError(
        this,
        titulo,
        'É obrigatório informar a descrição da máquina',
        'descricao'
      );
      return true;
    }
    if (this.state.almoxarifado.length === 0) {
      showError(
        this,
        titulo,
        'É obrigatório informar o almoxarifado',
        'almoxarifado'
      );
      return true;
    }

    if (this.state.listaPerifericos.length === 0) {
      showError(
        this,
        titulo,
        'É obrigatório informar ao menos um periférico',
        'tipoEquipamento'
      );
      return true;
    }

    if (this.state.listaPerifericos.length > 0) {
      let list = this.state.listaPerifericos;
      for (let i = 0; i < list.length; i++) {
        const listaPerifericos = list[i];

        if (listaPerifericos.codigoTipoEquipamento === '') {
          showError(
            this,
            titulo,
            'É obrigatório informar o tipo de equipamento',
            'tipoEquipamento'
          );
          return true;
        }
        if (
          sameInt(
            listaPerifericos.codigoTipoEquipamento,
            TipoEquipamento.ImpressoraNaoFiscal
          )
        ) {
          //Se for uma impressora nao fiscal
          if (listaPerifericos.equipamento === '') {
            showError(
              this,
              titulo,
              'É obrigatório informar o equipamento',
              'equipamento'
            );
            return true;
          }
          if (listaPerifericos.numSerieNFCe === '') {
            showError(
              this,
              titulo,
              'É obrigatório informar o número de série NFC-e',
              'numSerieNFCe'
            );
            return true;
          }
          if (
            listaPerifericos.numSerieNFCe < 1 ||
            listaPerifericos.percentualValor > 890
          ) {
            showError(
              this,
              titulo,
              'O número de série NFC-e deve ser entre 1 e 890',
              'numSerieNFCe'
            );
            return true;
          }
          if (listaPerifericos.portaSerial === '') {
            showError(
              this,
              titulo,
              'É obrigatório informar a porta serial',
              'portaSerial'
            );
            return true;
          }
          if (
            listaPerifericos.portaSerial < 1 ||
            listaPerifericos.portaSerial > 9
          ) {
            showError(
              this,
              titulo,
              'A porta serial deve ser entre 1 e 9',
              'portaSerial'
            );
            return true;
          }
        }

        /*
        let listCopia = this.state.listaPerifericos;
        //if (action === incluirMaquinaPeriferico)
        for (let j = 1; j < listCopia.length; j++) {
          const perifericoCopia = listCopia[j];
          if (perifericoCopia.numSerieNFe === listaPerifericos.numSerieNFe) {
            showError(
              this,
              titulo,
              "Já existe um periférico registrado com o mesmo número de série"
            );
            return true;
          }
          if (
            perifericoCopia.tipoEquipamento === listaPerifericos.tipoEquipamento
          ) {
            showError(
              this,
              titulo,
              "Já existe um periférico registrado com o mesmo tipo de equipamento"
            );
            return true;
          }
          break;
        }
        */
      }
    }

    return false;
  };

  verificaExistente = nomeMaquina => {
    let existe = false;
    let cod = '';
    let lista = this.state.maquinasExistentes;
    for (let i = 0; i < lista.length; i++) {
      const maq = lista[i];
      if (parseInt(maq.nome) === nomeMaquina) {
        cod = maq.codigoMaquina;
        existe = true;
      }
    }
    if (existe) {
      showError(
        this,
        titulo,
        'Já existe uma máquina com o mesmo nome, com código: ' + cod
      );
    }
    return existe;
  };

  excluirEquipamento = objEquipamento => {
    let listaPerifericos = [];

    listaPerifericos = this.state.listaPerifericos;

    let index = listaPerifericos.indexOf(objEquipamento);

    //Com base no objeto do parametro. deleta o obj da lista.
    if (index > -1) {
      listaPerifericos.splice(index, 1);
    }

    this.setState({ periferico: listaPerifericos });
  };

  getDescTipoEquip = cod => {
    let descricao = '';
    let list = this.state.tipoEquipamentos;
    for (let i = 0; i < list.length; i++) {
      const eq = list[i];
      if (parseInt(eq.value) === parseInt(cod)) {
        descricao = eq.label;
        break;
      }
    }
    return descricao;
  };

  panelTitleRender = e => {
    return <h1 className={styles.pannelTitle}>{e.title}</h1>;
  };

  panelDataSource = () => {
    return [
      { index: 0, title: 'Gerais' },
      { index: 1, title: 'Sangria' },
      { index: 2, title: 'Suprimento' },
      { index: 3, title: 'Smart POS' },
      { index: 4, title: 'POS TEF' }
    ];
  };

  changeSangriaCega = event => {
    let value, name;

    value = event.target.checked;
    name = event.name;

    if (value === false) {
      this.setState({ imprimeValoresComprovSaida: false });
    }

    this.setState({ sangriaCega: value });
    this.setState({ [name]: value });
  };

  render() {
    const {
      showModal,
      // codigoTipoEquipamento,
      opcoesEquipamento,
      codigoMaquina,
      nome,
      descricao,
      ativo,
      // almoxarifado,
      comunicaConcentrador,
      vendeCombustivel,
      tipoEquipamento,
      // descricaoEquipamento,

      //Perifericos
      // periferico,
      //dentro do objeto periferico
      // marca,

      //Configuração
      valorAvisoSangria,
      valorMaxCaixa,
      tempoAviso,
      sangriaCega,
      imprimeValoresComprovSaida,
      valorInicialCaixa,
      permiteAlterarValor,
      viasComprovante,
      solicitaClienteInicioVenda,
      numSerieNFe,
      quantidadeLimiteVenda,
      imprimeLeituraX,
      viasSangria,
      almoxarifados,
      tipoEquipamentos,
      versaoPDV,
      dataAtualizacaoPDV,
      indicadorSmartPos,
      responsavelTurnoSmartPos,
      indicadorTurnoAutomaticamente,
      indicadorLogin,
      indicadorLancaProdutos,
      indicadorLancaCombustivel,
      indicadorValidaEstoqueCarrinho,
      indicadorDinheiro,
      indicadorNotaPrazo,
      indicadorTicket,
      indicadorQRLinx,
      posdtefIndicadorDinheiro,
      posdtefIndicadorTicket
      // equipamentos
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <div className={styles.Maquinas}>
            <SectionContainer>
              <SectionContent title="Dados da Máquina" accordion="true">
                <div className="row">
                  <div className="col-6">
                    <InputText
                      label="Nome"
                      value={nome}
                      name="nome"
                      maxlength="20"
                      //onChange={this.handleInputChange}
                      pattern={ALPHANUMERIC_WITH_HYPHEN_UNDERSCORE_PATTERN}
                      onChange={this.handleInputChangeRegexForceUpper}
                      help="Obrigatório"
                    />
                  </div>
                  <div className="col-6">
                    <InputText
                      label="Descrição"
                      value={descricao}
                      name="descricao"
                      maxlength="30"
                      onChange={this.handleInputChange}
                      help="Obrigatório"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-2">
                    <Checkbox>
                      <Checkitem
                        label="Vende Combustível"
                        name="vendeCombustivel"
                        checked={vendeCombustivel}
                        onChange={this.handleInputChange}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-2">
                    <Checkbox>
                      <Checkitem
                        label="Comunica com Concentrador"
                        name="comunicaConcentrador"
                        checked={comunicaConcentrador}
                        onChange={this.handleInputChange}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-2">
                    <Checkbox>
                      <Checkitem
                        label="Ativo"
                        name="ativo"
                        checked={ativo}
                        onChange={this.handleInputChange}
                        //disabled={codigoMaquina === null}
                      />
                    </Checkbox>
                  </div>
                  {versaoPDV && versaoPDV.length ? (
                    <div className="col-3">
                      <InputText
                        disabled={true}
                        label="Versão do PDV"
                        value={versaoPDV}
                      />
                    </div>
                  ) : null}
                  {dataAtualizacaoPDV && dataAtualizacaoPDV.length ? (
                    <div className="col-3">
                      <InputText
                        disabled={true}
                        label="Data da última atualização"
                        value={
                          dataAtualizacaoPDV
                            ? moment(dataAtualizacaoPDV).format(
                                'DD/MM/YYYY HH:mm'
                              )
                            : ''
                        }
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-6">
                    <div className="table-ctn">
                      <ReactTable
                        data={this.state.almoxarifado}
                        columns={[
                          {
                            accessor: 'codigo',
                            Header: 'Almoxarifado (Obrigatório)',
                            filterable: false,
                            sortable: false,
                            Cell: props => {
                              return (
                                <Select
                                  value={props.row.codigo}
                                  name="codigo"
                                  options={almoxarifados}
                                  disabled={!sameFloat(props.row.saldo, 0)}
                                  onChange={event => {
                                    changeInList(
                                      this,
                                      'almoxarifado',
                                      'codigo',
                                      parseInt(event.target.value),
                                      props.row._index
                                    );
                                  }}
                                />
                              );
                            }
                          },
                          {
                            width: 75,
                            filterable: false,
                            sortable: false,
                            Cell: props => {
                              if (
                                props.row._index <
                                this.state.almoxarifado.length - 1
                              ) {
                                if (sameFloat(props.row.saldo, 0))
                                  return (
                                    <>
                                      <Botao
                                        ic
                                        icon={'icon-lx-trash'}
                                        onClick={() => {
                                          deleteInList(
                                            this,
                                            'almoxarifado',
                                            props.row._index
                                          );
                                        }}
                                      />
                                    </>
                                  );
                              } else
                                return (
                                  <>
                                    <Botao
                                      ic
                                      icon={'icon-lx-plus'}
                                      secondary
                                      onClick={() => {
                                        appendInList(this, 'almoxarifado', {
                                          codigo: none,
                                          descricao: null
                                        });
                                      }}
                                    />
                                  </>
                                );
                            }
                          }
                        ]}
                        defaultPageSize={5}
                        previousText="Anterior"
                        nextText="Próximo"
                        loadingText="Carregando registros..."
                        noDataText="Sem registros para exibir"
                        pageText="Página"
                        ofText="de"
                        rowsText="registros"
                      />
                    </div>
                  </div>
                </div>
              </SectionContent>
              <SectionContent title="Periféricos" accordion="false">
                <div className="row">
                  <div className="col-5">
                    <Select
                      label="Tipo de Equipamento"
                      name="tipoEquipamento"
                      value={tipoEquipamento}
                      onChange={this.changeTipoEquip}
                      options={tipoEquipamentos}
                      help="Obrigatório"
                    />
                  </div>
                  <div className="col-2">
                    <div className="button-wrapper">
                      <Botao
                        ic
                        icon="icon-lx-plus"
                        onClick={() => this.handleOpenModal(tipoEquipamento)}
                      />
                    </div>
                  </div>
                </div>
                <ReactTable
                  defaultPageSize={5}
                  onClick={this.handleTableClick}
                  data={this.state.listaPerifericos}
                  columns={[
                    {
                      accessor: 'tipoEquipamento',
                      show: false
                    },
                    {
                      accessor: 'descricaoTipoEquipamento',
                      Header: 'Nome',
                      style: { whiteSpace: 'unset' },
                      width: 250,
                      filterable: false,
                      sortable: false
                    },
                    {
                      accessor: 'marca',
                      Header: 'Marca',
                      sortable: false,
                      width: 150
                    },
                    {
                      accessor: 'portaSerial',
                      Header: 'Porta Serial',
                      sortable: false,
                      width: 200
                    },
                    {
                      accessor: 'numSerieNFCe',
                      Header: 'Núm. Série',
                      sortable: false,
                      width: 200
                    },
                    {
                      accessor: 'acao',
                      Header: 'Excluir',
                      sortable: false,
                      width: 85,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <Botao
                            className={row.tipoEquipamento}
                            icon={'icon-lx-trash'}
                            onClick={() =>
                              this.excluirEquipamento(row._original)
                            }
                          />
                        );
                      }
                    },
                    {
                      accessor: 'editar',
                      Header: 'Editar',
                      width: 85,
                      filterable: false,
                      sortable: false,
                      show: menuPermiteAlteracao(PATH_MENU),
                      Cell: ({ row }) => {
                        return (
                          <Botao
                            className={row.tipoEquipamento}
                            icon={'icon-lx-edit'}
                            onClick={() =>
                              this.handleEditModal(row._original, 'edit')
                            }
                          />
                        );
                      }
                    }
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (this.props.onClick) {
                          this.props.onClick(
                            state,
                            rowInfo,
                            column,
                            instance,
                            e
                          );
                        }

                        if (handleOriginal) {
                          handleOriginal();
                        }
                      }
                    };
                  }}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando registros..."
                  noDataText="Sem registros para exibir"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                />
              </SectionContent>
              <SectionContent title="Configurações" accordion="true">
                <div className="row">
                  <label>Sangria</label>
                </div>
                <div className="row">
                  <div className="col-5">
                    <InputText
                      label="Valor para Aviso"
                      value={valorAvisoSangria}
                      name="valorAvisoSangria"
                      type="number"
                      maxlength="8"
                      decimalScale={2}
                      onChange={this.handleInputChange}
                      allowNegative={false}
                      help="Apenas Números"
                    />
                  </div>
                  <div className="col-5">
                    <InputText
                      label="Tempo para Aviso"
                      value={tempoAviso}
                      name="tempoAviso"
                      type="number"
                      maxlength="5"
                      onChange={this.handleInputChange}
                      allowNegative={false}
                      help="Apenas Números"
                    />
                  </div>
                  <div className="col-5">
                    <Checkbox>
                      <Checkitem
                        label="Sangria cega?"
                        name="sangriaCega"
                        checked={sangriaCega}
                        onChange={this.changeSangriaCega}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-5">
                    <Select
                      label="Vias de sangria"
                      name="viasSangria"
                      value={viasSangria}
                      onChange={this.handleInputChange}
                      options={[
                        { value: '', label: 'Selecione' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' }
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputText
                      label="Valor Máx. em Caixa"
                      value={valorMaxCaixa}
                      name="valorMaxCaixa"
                      type="number"
                      maxlength="8"
                      decimalScale={2}
                      onChange={this.handleInputChange}
                      allowNegative={false}
                      help="Apenas Números"
                    />
                  </div>
                  <div className="col-6">
                    <Checkbox>
                      <Checkitem
                        label="Imprime valores no comprovante de saída?"
                        name="imprimeValoresComprovSaida"
                        checked={imprimeValoresComprovSaida}
                        onChange={this.handleInputChange}
                        disabled={this.state.sangriaCega === false}
                      />
                    </Checkbox>
                  </div>
                </div>
                <div className="row">
                  <label>Suprimento</label>
                </div>
                <div className="row">
                  <div className="col-6">
                    <InputText
                      label="Valor Inicial Caixa"
                      value={valorInicialCaixa}
                      name="valorInicialCaixa"
                      type="number"
                      maxlength="8"
                      decimalScale={2}
                      onChange={this.handleInputChange}
                      allowNegative={false}
                      help="Apenas Números"
                    />
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Permite alterar valor?"
                        name="permiteAlterarValor"
                        checked={permiteAlterarValor}
                        onChange={this.handleInputChange}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Select
                      label="Vias de suprimento"
                      name="viasComprovante"
                      value={viasComprovante}
                      onChange={this.handleInputChange}
                      options={[
                        { value: '', label: 'Selecione' },
                        { value: '1', label: '1' },
                        { value: '2', label: '2' }
                      ]}
                    />
                  </div>
                </div>
                <div className="row" />
                <div className="row">
                  <div className="col-6">
                    <Checkbox>
                      <Checkitem
                        label="Solicita cliente no início da venda?"
                        name="solicitaClienteInicioVenda"
                        checked={solicitaClienteInicioVenda}
                        onChange={this.handleInputChange}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-6">
                    <Select
                      label="Imprime Leitura X (Entrada/Saída)"
                      name="imprimeLeituraX"
                      value={imprimeLeituraX}
                      onChange={this.handleInputChange}
                      options={[
                        { value: '0', label: 'Nenhum' },
                        { value: '1', label: 'Todas' },
                        { value: '2', label: 'Entrada' },
                        { value: '3', label: 'Saída' }
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <InputText
                      label="Num. Série NF-e"
                      value={numSerieNFe}
                      name="numSerieNFe"
                      type="number"
                      maxlength="3"
                      onChange={this.handleInputChange}
                      allowNegative={false}
                      help="Apenas Números"
                    />
                  </div>
                  <div className="col-3">
                    <InputText
                      label="Qtd. Limite para Venda"
                      value={quantidadeLimiteVenda}
                      name="quantidadeLimiteVenda"
                      type="number"
                      maxlength="4"
                      onChange={this.handleInputChange}
                      allowNegative={false}
                      help="Apenas Números"
                    />
                  </div>
                </div>

                <div className="row">
                  <label>Smart POS</label>
                </div>
                <div className="row">
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Integrado com Smart POS"
                        name="indicadorSmartPos"
                        checked={indicadorSmartPos}
                        onChange={this.handleInputChange}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Abrir e fechar turno automaticamente"
                        name="indicadorTurnoAutomaticamente"
                        checked={indicadorTurnoAutomaticamente}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-6">
                    <Select
                      disabled={!this.state.indicadorSmartPos}
                      label="Responsável padrão"
                      name="responsavelTurnoSmartPos"
                      value={responsavelTurnoSmartPos}
                      onChange={this.handleInputChange}
                      options={this.state.listaResponsaveis}
                      help={
                        this.state.indicadorTurnoAutomaticamente
                          ? 'Obrigatório'
                          : ''
                      }
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Solicitar usuário"
                        name="indicadorLogin"
                        checked={indicadorLogin}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Permitir lançar produtos"
                        name="indicadorLancaProdutos"
                        checked={indicadorLancaProdutos}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>

                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Permitir lançar combustíveis"
                        name="indicadorLancaCombustivel"
                        checked={indicadorLancaCombustivel}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>

                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Validar estoque ao adicionar o item no carrinho"
                        name="indicadorValidaEstoqueCarrinho"
                        checked={indicadorValidaEstoqueCarrinho}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>
                </div>
                <div className={`row ${styles.rowSection}`}>
                  <div className="col-3">
                    <label>Formas de pagamento</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Dinheiro"
                        name="indicadorDinheiro"
                        checked={indicadorDinheiro}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Nota Prazo"
                        name="indicadorNotaPrazo"
                        checked={indicadorNotaPrazo}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="QR LINX"
                        name="indicadorQRLinx"
                        checked={indicadorQRLinx}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Ticket"
                        name="indicadorTicket"
                        checked={indicadorTicket}
                        onChange={this.handleInputChange}
                        disabled={!this.state.indicadorSmartPos}
                      />
                    </Checkbox>
                  </div>
                </div>

                <div className="row">
                  <label>POS TEF</label>
                </div>
                <div className="row">
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Integrado com POS D-TEF"
                        name="integraDPos"
                        checked={this.state.integraDPos}
                        onChange={this.handleInputChange}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Abre/Fecha turno automaticamente"
                        name="abreFechaTurnoAuto"
                        checked={this.state.abreFechaTurnoAuto}
                        onChange={this.handleInputChange}
                        disabled={!this.state.integraDPos}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-6">
                    <Select
                      disabled={!this.state.integraDPos}
                      label="Responsável turno"
                      name="responsavelTurnoAuto"
                      value={this.state.responsavelTurnoAuto}
                      onChange={this.handleInputChange}
                      options={this.state.listaResponsaveis}
                      help={this.state.abreFechaTurnoAuto ? 'Obrigatório' : ''}
                    />
                  </div>
                </div>
                <div className={`row ${styles.rowSection}`}>
                  <div className="col-3">
                    <span>Formas de pagamento</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Dinheiro"
                        name="posdtefIndicadorDinheiro"
                        checked={posdtefIndicadorDinheiro}
                        onChange={this.handleInputChange}
                        disabled={!this.state.integraDPos}
                      />
                    </Checkbox>
                  </div>
                  <div className="col-3">
                    <Checkbox>
                      <Checkitem
                        label="Voucher"
                        name="posdtefIndicadorTicket"
                        checked={posdtefIndicadorTicket}
                        onChange={this.handleInputChange}
                        disabled={!this.state.integraDPos}
                      />
                    </Checkbox>
                  </div>
                </div>
              </SectionContent>

              <ModalPerifericos
                active={showModal}
                opcoesEquipamento={opcoesEquipamento}
                objTipoEquipamento={this.state.objTipoEquipamento}
                handleCloseModal={this.handleCloseModal}
                handleAlteraObjEquipamento={this.handleAlteraObjEquipamento}
              />
            </SectionContainer>
          </div>
        </LinxPostos>
        <Footer
          saveAction={
            menuPermiteAlteracao(PATH_MENU) ? this.handleSalvar : null
          }
          cancelAction={this.handleCancelar}
          deleteAction={
            codigoMaquina === null
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class Maquinas extends Component {
  state = {
    showModal: false,
    codigoTipoEquipamento: 0,
    maquinasPerifericos: [],
    maquinasPerifericoSel: {}
  };

  async doMount() {
    const { data } = await getMaquinasPerifericos();
    const maqArray = data.result;

    const maqs = maqArray.map(item => {
      return {
        codigoMaquina: item.codigoMaquina,
        nome: item.nome,
        descricao: item.descricao,
        inativo: item.inativo,
        //lista almoxarifado
        almoxarifado: item.almoxarifado,
        comunicaConcentrador: item.comunicaConcentrador,
        vendeCombustivel: item.vendeCombustivel,
        //configurações
        valorAvisoSangria: parseInt(item.valorAvisoSangria),
        valorMaxCaixa: item.valorMaxCaixa,
        tempoAviso: item.tempoAviso,
        sangriaCega: item.sangriaCega,
        imprimeValoresComprovSaida: item.imprimeValoresComprovSaida,
        valorInicialCaixa: item.valorInicialCaixa,
        permiteAlterarValor: item.permiteAlterarValor,
        viasComprovante: item.viasComprovante,
        solicitaClienteInicioVenda: item.solicitaClienteInicioVenda,
        numSerieNFe: item.numSerieNFe,
        quantidadeLimiteVenda: item.quantidadeLimiteVenda,
        imprimeLeituraX: item.imprimeLeituraX,
        versaoPDV: item.versaoPDV,
        dataAtualizacaoPDV: item.dataAtualizacaoPDV,
        sistemaOperacionalPDV: item.sistemaOperacionalPDV,
        //lista almoxarifado
        perifericos: item.perifericos.map(element => {
          return {
            numSerieFabricante: element.numeroFabricante,
            ativo: element.codigoAtivacao,
            caixaSat: element.caixaSAT,
            ...element
          };
        }),
        rowVersion: item.rowVersion,
        integraDPos: item.integraDPos,
        abreFechaTurnoAuto: item.abreFechaTurnoAuto,
        responsavelTurnoAuto: item.responsavelTurnoAuto,
        viasSangria: item.viasSangria,
        indicadorSmartPos: item.indicadorSmartPos,
        responsavelTurnoSmartPos: item.responsavelTurnoSmartPos,
        indicadorTurnoAutomaticamente: item.indicadorTurnoAutomaticamente,
        indicadorLogin: item.indicadorLogin,
        indicadorLancaProdutos: item.indicadorLancaProdutos,
        indicadorLancaCombustivel: item.indicadorLancaCombustivel,
        indicadorValidaEstoqueCarrinho: item.indicadorValidaEstoqueCarrinho,
        indicadorDinheiro: item.indicadorDinheiro,
        indicadorNotaPrazo: item.indicadorNotaPrazo,
        indicadorTicket: item.indicadorTicket,
        indicadorQRLinx: item.indicadorQRLinx,
        posdtefIndicadorDinheiro: item.posdtefIndicadorDinheiro,
        posdtefIndicadorTicket: item.posdtefIndicadorTicket
      };
    });

    const maMoOrd = maqs.sort(function(a, b) {
      if (a.descricaoGrupoImposto < b.descricaoGrupoImposto) return -1;
      if (a.descricaoGrupoImposto > b.descricaoGrupoImposto) return 1;
      return 0;
    });

    this.setState({
      maquinasPerifericos: maMoOrd
    });
  }

  async componentDidMount() {
    this.doMount();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.doMount();
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.setState({ maquinasPerifericoSel: rowInfo.original });

      this.props.history.push(NEW_PATH, {
        maquinasPerifericoSel: rowInfo.original,
        maquinasPerifericos: this.state.maquina
      });
    }
  };

  limparCadastro = () => {
    this.setState({ maquinasPerifericoSel: {} });
  };

  render() {
    const { edit } = this.props;
    let maquinasPerifericoSel = {};
    // let maquinasExistentes = [];

    if (edit) {
      // this.props.location.state por padrao eh undefined
      if (this.props.location.state) {
        maquinasPerifericoSel = this.props.location.state.maquinasPerifericoSel;
        // maquinasExistentes = this.props.location.state.maquinasExistentes;
      }
    }
    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <Form
              maquina={maquinasPerifericoSel}
              maquinasExistentes={this.state.maquinasPerifericos}
              periferico={this.state.perifericos}
            />
          ) : (
            <>
              <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                <ScreenHeader
                  search={false}
                  pathMenu={PATH_MENU}
                  newButton={true}
                  texto={'Novo cadastro'}
                  newURL={NEW_PATH}
                />
                <List
                  title="Máquinas"
                  onClick={this.handleTableClick}
                  rows={this.state.maquinasPerifericos}
                  cols={[
                    {
                      accessor: 'nome',
                      Header: 'Nome',
                      width: 200,
                      filterable: false
                    },
                    {
                      accessor: 'descricao',
                      Header: 'Descrição',
                      width: 460,
                      filterable: false
                    },
                    {
                      accessor: 'inativo',
                      Header: 'Ativo',
                      width: 100,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <Botao
                            secondary={!row.inativo}
                            ic
                            icon={
                              row.inativo ? 'icon-lx-remove' : 'icon-lx-check'
                            }
                          />
                        );
                      }
                    }
                  ]}
                  defaultPageSize={5}
                />
              </LinxPostos>
            </>
          )}
        </section>
      </main>
    );
  }
}

Maquinas = withRouter(Maquinas);
export { Maquinas };

/* 
  Classe contendo o Modal de Periféricos.
*/
export class ModalPerifericos extends Component {
  // constructor(props) {
  //   super(props);
  //this.handleInputChange = handleInputChange.bind(this);
  // const { handleAlteraObjEquipamento } = this.props;
  // }

  pegarDescricaoCombo(codigoEquipamento) {
    let equipamentos = this.props.opcoesEquipamento.filter(
      e => e.value === parseInt(codigoEquipamento)
    );
    let descricaoEquipamento = '';

    if (equipamentos.length > 0) descricaoEquipamento = equipamentos[0].label;

    return descricaoEquipamento;
  }

  handleInputChange = event => {
    let value, name;
    if (event.hasOwnProperty('target')) {
      // inputs comuns
      const target = event.target;
      value = target.type === 'checkbox' ? target.checked : target.value;
      name = target.name;
    } else if (event.hasOwnProperty('floatValue')) {
      // input react-number-format
      value = event.format === null ? event.floatValue : event.value;
      name = event.name;
    }
    this.props.handleAlteraObjEquipamento(name, value);
  };

  render() {
    const {
      active,
      handleCloseModal,
      // nome,
      opcoesEquipamento
      // objTipoEquipamento
    } = this.props;

    const {
      // codigoPeriferico = 0,
      // descricaoPeriferico,
      codigoTipoEquipamento,
      descricaoTipoEquipamento,
      codigoEquipamento = 0,
      // marca,
      // usadaPraNFCe,
      numSerieNFCe,
      ipMaquinaMIDe,
      portaSerial,
      usaCasadoMilhao,
      numNiveisPreco,
      off,
      // ipMideClient,
      ativo,
      numSerieFabricante,
      caixaSat
    } = this.props.objTipoEquipamento;

    const CodigoEquipamento = {
      ImpressoraNaoFiscal: '10',
      TEF: '4',
      ConcentradorBomba: '5',
      Gaveteiro: '9',
      SAT: '11',
      BalancaPDV: '2',
      DisplayCliente: '7',
      SmartPos: '14'
    };

    let content = '';
    content = (
      <SectionContent title={descricaoTipoEquipamento}>
        <div className="row">
          <div className="col-12">
            <div className="form-input">
              <Select
                options={opcoesEquipamento}
                value={codigoEquipamento}
                name="codigoEquipamento"
                onChange={this.handleInputChange}
                help="Obrigatório"
              />
              <label className="input-label">Equipamento:</label>
            </div>
          </div>
        </div>

        {parseInt(codigoTipoEquipamento) ===
        parseInt(CodigoEquipamento.SmartPos) ? (
          <div className="row">
            <div className="col-12">
              <div className="form-input">
              <InputText
                    type="input"
                    className="input"
                    name="numSerieFabricante"
                    value={numSerieFabricante}
                    onChange={this.handleInputChange}
                    maxlength="20"
                    help="Obrigatório"
                    required
                  />
                <label className="input-label">N° de série do fabricante:</label>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {parseInt(codigoTipoEquipamento) ===
        parseInt(CodigoEquipamento.ConcentradorBomba) ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <InputText
                    className="input"
                    name="numNiveisPreco"
                    value={numNiveisPreco}
                    onChange={this.handleInputChange}
                    maxlength="1"
                    decimalScale={2}
                    type="number"
                    allowNegative={false}
                    required
                  />
                  <label className="input-label">Níveis de Preço:</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <Checkbox>
                    <Checkitem
                      label="Utiliza Casa do milhão?"
                      name="usaCasadoMilhao"
                      checked={usaCasadoMilhao}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {parseInt(codigoTipoEquipamento) ===
        parseInt(CodigoEquipamento.Gaveteiro) ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <Checkbox label="Off?">
                    <Checkitem
                      label=""
                      name="off"
                      checked={off}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {parseInt(codigoTipoEquipamento) ===
        parseInt(CodigoEquipamento.ImpressoraNaoFiscal) ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <InputText
                    type="number"
                    className="input"
                    name="portaSerial"
                    value={portaSerial}
                    onChange={this.handleInputChange}
                    maxlength="1"
                    help="Obrigatório"
                    required
                  />
                  <label className="input-label">Porta Serial:</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <InputText
                    type="number"
                    className="input"
                    name="numSerieNFCe"
                    value={numSerieNFCe}
                    onChange={this.handleInputChange}
                    maxlength="3"
                    help="Obrigatório"
                    required
                  />
                  <label className="input-label">Número de Série:</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <InputText
                    className="input"
                    name="ipMaquinaMIDe"
                    value={ipMaquinaMIDe}
                    onChange={this.handleInputChange}
                    maxlength="50"
                    required
                  />
                  <label className="input-label">IP Máquina MID-e:</label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {parseInt(codigoTipoEquipamento) === parseInt(CodigoEquipamento.SAT) ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <InputText
                    type="input"
                    className="input"
                    name="numSerieFabricante"
                    value={numSerieFabricante}
                    onChange={this.handleInputChange}
                    maxlength="47"
                    help="Obrigatório"
                    required
                  />
                  <label className="input-label">N° de série do fabricante:</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <InputText
                    type="input"
                    className="input"
                    name="ativo"
                    value={ativo}
                    onChange={this.handleInputChange}
                    maxlength="32"
                    help="Obrigatório"
                    required
                  />
                  <label className="input-label">Código de Ativação:</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-input">
                  <InputText
                    type="number"
                    className="input"
                    name="caixaSat"
                    value={caixaSat}
                    onChange={this.handleInputChange}
                    maxlength="3"
                    help="Obrigatório"
                    required
                  />
                  <label className="input-label">Caixa SAT:</label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </SectionContent>
    );

    return (
      <div>
        <Modal
          className={`${active ? 'active' : null} modal-filter`}
          onClose={() => handleCloseModal(false)}
        >
          <ModalHeader title="Tipo de Equipamento" subtitle="" />
          <ModalBody>{content}</ModalBody>
          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal(false);
              }}
            />
            <Botao
              icon="icon-lx-check"
              title={'Salvar'}
              onClick={() => {
                this.props.handleAlteraObjEquipamento(
                  'marca',
                  this.pegarDescricaoCombo(codigoEquipamento)
                );
                handleCloseModal(true);
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
