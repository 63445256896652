import React, { memo, useCallback, useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import { DropDownImage } from '../drop-down-image';
import { Form } from 'react-bootstrap';
import { SelectBox } from 'devextreme-react';
import { ComodatoService } from '../../../../../services/con-comodato/Comodato.service';
import { alert } from 'devextreme/ui/dialog';

const DropBoxProdutoRede = forwardRef(({ title, onSelected, redeCod }, ref) => {
  // Troque para o seletor apropriado que retorna a lista de ProdutoRede
  const [produtosRede, setProdutosRede] = useState([]);
  const [produtoRede, setProdutoRede] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (redeCod || redeCod === 0) {
          setLoading(true);
          // Garanta que o redeCod seja válido
          const data = await ComodatoService.getInstance().listarProdutoRede(redeCod);
          setProdutosRede(data);
          setLoading(false);
        } else {
          setProdutosRede([]); // Resetar os produtos se redeCod for inválido
        }
      } catch (error) {
        setLoading(false);
        alert('Erro', 'Erro ao consultar ProdutosRede', 'error');
        console.error('Consulta ProdutosRede => ', (error || {}).message);
      }
    };

    fetchData();
  }, [redeCod]);


  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem) {
        if (produtoRede) return;
        setProdutoRede(null);
        return;
      }
      const data = { ...e.selectedItem };
      if (data.id === (produtoRede || {}).id) return;
      setProdutoRede(data);
      if (typeof onSelected === 'function') {
        onSelected(data.id);
      }
    },
    [produtoRede,onSelected],
  );

  const onValueChanged = useCallback(
    data => {
      if (!data.event) return;
      if (onSelected) onSelected(data.value);
    },
    [onSelected],
  );

  useImperativeHandle(
    ref,
    () => ({
      getProdutoRede: () => {
        return produtoRede;
      },
      clear: () => {
        setProdutoRede(null);
        if (onSelected) onSelected(null);
      },
    }),
    [produtoRede, onSelected],
  );

  return (
    <>
      <Form.Label htmlFor="produtoRede" className="text-primary">
        {title ? title : 'Produto Rede'}
        <span className="text-danger">*</span>
      </Form.Label>
      <SelectBox
        dropDownButtonRender={DropDownImage}
        showDropDownButton={true}
        searchEnabled={true}
        hint="Selecione o Produto Rede"
        placeholder={loading ? 'CARREGANDO...' : 'Selecione o Produto Rede'}
        displayExpr="descricao"
        acceptCustomValue={false}
        height={40}
        valueExpr="id"
        value={produtoRede}
        onSelectionChanged={onSelectionChanged}
        onValueChanged={onValueChanged}
        dataSource={produtosRede}
        selectedItem={produtoRede || {}}
        name="produtoRede"
      />
    </>
  );
});

export default memo(DropBoxProdutoRede);
