import React, {
  useEffect,
  useState,
  useCallback
} from "react";
import { SelectBox } from "devextreme-react/select-box";
import {
  DataGrid,
  Column,
  Pager,
  Paging,
  Sorting,
  SearchPanel,
  FilterRow,
  ColumnChooser,
} from "devextreme-react/data-grid";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react/load-indicator";

import { exportDataGrid } from "devextreme/excel_exporter";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";

import { jsPDF } from "jspdf";
import { Popup } from "devextreme-react";

import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

import { CustomButton } from "../../../SharedComponents/CustomButton/CustomButton";
import { Cancelar } from "../../../SharedComponents/Cancelar/Cancelar";

import { PagamentosService } from "../Pagamentos.service";

export const Realizados = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [paymentListing, setPaymentListing] = useState();
  const [selectedValue, setSelectedValue] = useState(7);
  const [isShow, setIsShow] = useState(false);

  const dateRangeOptions = [
    { label: "Últimos 7 dias", value: 7 },
    { label: "Últimos 15 dias", value: 15 },
    { label: "Últimos 30 dias", value: 30 }
  ];

  const exportToPDF = (e) => {
    const doc = new jsPDF();

    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    doc.text("Pagamentos realizados", 15, 10);

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
      autoTableOptions: {
        styles: { fillColor: [255, 255, 255] },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        headerStyles: { textColor: [0, 0, 0], fontStyle: "normal" },
        bodyStyles: { textColor: [0, 0, 0] },
        margin: { top: 20, right: 10, bottom: 10, left: 10 }
      }
    }).then(() => {
      doc.save("pagamentos_realizados.pdf");
    });

    e.cancel = true;
  }

  const exportToExcel = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("Planilha1");

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), "transferencias_realizadas.xlsx");
      });
    });
  }

  const onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift(
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "exportpdf",
          onClick: () => exportToPDF(e),
        }
      },
      {
        location: "after",
        widget: "dxButton",
        options: {
          icon: "xlsxfile",
          onClick: () => exportToExcel(e),
        }
      },
    );
  };

  const renderActionButtons = (cellData) => {
    const { status, id } = cellData.row.data;

    if (status === "Aprovado") {
      return (
        <div>
          <CustomButton className="rounded" variant="primary" icon="download" title="Baixar comprovante" onClick={() => download(id)}>
          </CustomButton>
        </div>
      );
    }
  };

  const handleValueChange = (e) => {
    setSelectedValue(e.value);
  };

  const download = async (id) => {
    notify("Gerando download do comprovante. Aguarde...", "info", 2000);

    await PagamentosService.getPdfDownload(id);

  }

  const openModal = () => {
    setIsShow(!isShow);
  }

  const onConfirm = () => {
    notify("Cancelado com sucesso", "success", 2000);
    openModal();
  }

  const featchPaymentData = useCallback(async () => {
    setIsLoading(true);
    const transfers = await PagamentosService.getPayments({
      "ultimosDias": selectedValue
    });

    if (transfers && transfers.result) {

      console.log(transfers);

      const formattedData = transfers.result.map((item) => ({
        id: item.id,
        dataGeracao: item.create,
        cliente: item.client,
        valorBoleto: item.value,
        tituloFinanceiro: item.description,
        status: item.status,
      }));

      setPaymentListing(formattedData);

    } else {
      notify(transfers.message, "error", 5000);
    }

    setIsLoading(false);
  }, [selectedValue])

  useEffect(() => {
    featchPaymentData();
  }, [featchPaymentData])

  return (
    <div className="d-flex flex-column w-100">
      <div className="dx-field">
        <div className="dx-field-label">Filtro</div>
        <div style={{ display: "flex", width: "100%" }}>
          <SelectBox
            dataSource={dateRangeOptions}
            displayExpr="label"
            valueExpr="value"
            value={selectedValue}
            onValueChanged={handleValueChange}
          />
        </div>
      </div>
      {!isLoading ?
        <div className="d-flex mh-100">
          <DataGrid
            key="id"
            dataSource={paymentListing}
            allowColumnReordering={true}
            allowColumnResizing={true}
            rowAlternationEnabled={true}
            showBorders={true}
            width="100%"
            height="100%"
            onToolbarPreparing={onToolbarPreparing}
            onCellPrepared={(e) => {
              if (e.rowType === "filter") {
                e.cellElement.style.backgroundColor = "white";
              }
            }}
          >
            <SearchPanel
              visible={true}
              highlightCaseSensitive={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <Column dataField="dataGeracao" caption="Data da geração:" />
            <Column dataField="cliente" caption="Cliente" />
            <Column dataField="valorBoleto" caption="Valor do boleto" format="R$ #,##0.00" />
            <Column dataField="tituloFinanceiro" caption="Título financeiro" />
            <Column dataField="status" caption="Status" />
            <Column cellRender={renderActionButtons} caption="Ações" width="auto" />
            <ColumnChooser enabled={true} mode={"select"} allowSearch={true} title="Escolher colunas" />
            <Paging enabled={true} defaultPageSize={10} />
            <Pager
              visible={true}
              displayMode="full"
            />
            <Sorting mode="multiple" />
          </DataGrid>
        </div>
        :
        <div className="d-flex justify-content-center align-items-center h-100">
          <LoadIndicator id="large-indicator" height={60} width={60} />
        </div>
      }
      <Popup
        visible={isShow}
        dragEnabled={false}
        showTitle={true}
        title="Confirmar Cancelamento"
        showCloseButton={true}
        hideOnOutsideClick={true}
        onHiding={openModal}
        width={300}
        height={300}
      >
        <Cancelar
          onCancel={openModal}
          onConfirm={onConfirm}
        />
      </Popup>
    </div>
  );
};
