import * as _ from 'lodash';
import React, { useCallback, useState } from 'react';

import { LoadPanel, NumberBox } from 'devextreme-react';
import Popup from 'devextreme-react/popup';
import { alert } from 'devextreme/ui/dialog';
import { Button } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useDispatch, useSelector } from 'react-redux';
import { selectNovaOs, useNovaOs } from '../../../../redux/slices/consignadoComodato/novaOsSlice';
import { ApiRetornarPedidoLocacao } from '../usercases/ApiRetornarPedidoLocacao.service';

export function RetornoLocacao({ pedido }) {
  const { values } = useSelector(selectNovaOs);
  const { changeValue } = useNovaOs();
  const dispatch = useDispatch();
  const [popupVisible, setPopupVisible] = useState(false);
  const [messageLoadin, setMessageLoadin] = useState('');
  const [searching, setSearching] = useState(false);

  const [itens, setItens] = useState(
    pedido.itens.map(item => ({
      id: item.id,
      pedidoId: item.pedidoId,
      produto: item.produto,
      codigoProduto: `${item.produto.codigo} - ${item.produto.descricao}`,
      totalAtivos: item.quantidade,
      series: item.series,
      quantidadePendente: values.pedidos.find(pedido => pedido.tipoPedido.id === 9)
        ? item.quantidade -
          values.pedidos
            .filter(pedido => pedido.tipoPedido.id === 9)
            .filter(pedido => pedido.itens)
            .reduce((total, itemPedido) => {
              itemPedido.itens.forEach(produto => {
                if (produto.produto.id === item.produto.id) {
                  return (total += produto.quantidade);
                } else {
                  return total;
                }
              });
              return total;
            }, 0)
        : item.quantidade,
      quantidadeRetorno: values.pedidos.find(pedido => pedido.tipoPedido.id === 9)
        ? item.quantidade -
          values.pedidos
            .filter(pedido => pedido.tipoPedido.id === 9)
            .filter(pedido => pedido.itens)
            .reduce((total, itemPedido) => {
              itemPedido.itens.forEach(produto => {
                if (produto.produto.id === item.produto.id) {
                  return (total += produto.quantidade);
                } else {
                  return total;
                }
              });
              return total;
            }, 0)
        : item.quantidade,
      valorAtivo: item.valorAtivo,
      quantidadeDevolvida: values.pedidos.find(pedido => pedido.tipoPedido.id === 9)
        ? values.pedidos
            .filter(pedido => pedido.tipoPedido.id === 9)
            .filter(pedido => pedido.itens)
            .reduce((total, itemPedido) => {
              itemPedido.itens.forEach(produto => {
                if (produto.produto.id === item.produto.id) {
                  return (total += produto.quantidade);
                } else {
                  return total;
                }
              });
              return total;
            }, 0)
        : 0,
    })),
  );
  const [focusedElement, setFocusedElement] = useState(pedido.itens.map(item => ({ quantidade: item.quantidade })));

  const show = useCallback(
    e => {
      if (popupVisible) return;
      setPopupVisible(true);
    },
    [popupVisible],
  );
  const hide = useCallback(
    e => {
      if (!popupVisible) return;
      setPopupVisible(false);
    },
    [popupVisible],
  );

  const onFocusIn = useCallback(e => {
    setFocusedElement(e);
  }, []);

  const onInputChange = useCallback(
    e => {
      const idx = focusedElement.element.id.split('_')[1];
      const value = parseFloat(e || '0', 10);
      const data = [...itens];

      if (value + data[idx]['quantidadeDevolvida'] > data[idx]['totalAtivos']) return alert('Valor informado excede o valor do pedido original', 'Ops!');

      data[idx]['quantidadePendente'] =
        data[idx]['quantidadeDevolvida'] > 0 ? data[idx]['totalAtivos'] - data[idx]['quantidadeDevolvida'] - value : data[idx]['totalAtivos'] - value;
      data[idx]['quantidadeRetorno'] = value;
      setItens(data);
    },
    [itens, focusedElement],
  );

  const retornarPedido = useCallback(
    async e => {
      try {
        debugger;
        setSearching(true);
        setMessageLoadin('Realizando o retorno do pedido...');

        const pedidoAnterioresRetornoLocacao = values.pedidos.filter(pedido => pedido.tipoPedido.id === 9);

        if (pedidoAnterioresRetornoLocacao) {
          const pedidoLocacao = values.pedidos.find(pedido => pedido.tipoPedido.id === 8);

          const totalItensDevolvidos = pedidoAnterioresRetornoLocacao.filter(pedido => pedido.itens).reduce((total, item) => total + item.quantidade, 0);

          const totalItensDevolvendo = itens.reduce((total, item) => total + item.quantidadeRetorno, 0);

          const totalItensLocacao = pedidoLocacao.itens.reduce((total, item) => total + item.quantidade, 0);

          //valida se total devolvido é maior que o total de itens do consignado
          if (totalItensDevolvidos + totalItensDevolvendo > totalItensLocacao) {
            setSearching(false);
            return alert(
              'Total de itens devolvidos é maior que o total de itens do consignado. Por favor, valide os retornos de consignados.',
              'Retorno de Locacao',
            );
          }
        }

        const envioNps =  sessionStorage.getItem('envioNps') == 'true' ? true : false;

        var data = {
          itens: itens.map((it, idx) => ({
            id: it.id,
            pedidoId: pedido.pedidoId,
            numeroItem: idx + 1,
            quantidade: it.quantidadeRetorno,
            valorAtivo: it.valorAtivo,
            valorProduto: 0,
            totalAtivos: Math.round(it.valorAtivo * it.quantidadeRetorno * 100) / 100,
            totalProdutos: 0,
            acrescimo: 0,
            desconto: 0,
            produto: it.produto,
            cancelado: false,
            quantidadeCancelada: 0,
            quantidadeTotal: it.quantidadeRetorno,
            dataCadastro: new Date().toISOString(),
            dataUltimaAtualizacao: new Date().toISOString(),
          })),
          tipoPedido: {
            id: 9,
          },
          totalProdutos: _.sumBy(itens, x => x.totalAtivos),
          pedidoId: pedido.pedidoId,
          nps: envioNps,
        };

        const response = await ApiRetornarPedidoLocacao.getInstance().execute(data);
        if (response) {
          dispatch(changeValue({ fieldName: 'pedidos', value: response.pedidos }));
        }

        setSearching(false);
        alert('Retorno de Locacao realizado com sucesso!!', 'Retorno de Locacao');
        setPopupVisible(false);
      } catch (error) {
        alert(error.message, 'Falha no retorno do pedido.');
        setSearching(false);
      }
    },
    [pedido, itens, changeValue, dispatch, values.pedidos],
  );

  const formatQuantidade = produto => {
    if (produto.estoque.unidadeMedidaVenda.casasDecimais) {
      return `#,##0.${'0'.repeat(produto.estoque.unidadeMedidaVenda.casasDecimais)} ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
    }
    return `#,##0 ${(produto.estoque.unidadeMedidaVenda.sigla || 'UN').trim().toUpperCase()}`;
  };

  const ns = item => {
    let str = (item.series || []).filter(x => x.serie && x.serie !== '-1').map(x => x.serie).join(', ');
    if (str.length > 0) str = ` (${str})`;
    return str || '';
  };

  return (
    <>
      <button className="btn btn-primary ml-3" onClick={show}>
        Retornar Locacao
      </button>
      <Popup
        width="80vw"
        height={350}
        onHiding={hide}
        visible={popupVisible}
        title={`RETORNO ${pedido.tipoPedido.nome}`}
        showTitle={true}
        hideOnOutsideClick={false}
      >
        <LoadPanel
          shadingColor="rgba(0,0,0,0.4)"
          position={{ of: '#page' }}
          visible={searching}
          showIndicator={true}
          shading={true}
          message={messageLoadin}
          showPane={true}
          closeOnOutsideClick={false}
        />
        <PerfectScrollbar>
          <table className="table">
            <thead>
              <tr>
                <th>CODIGO/PRODUTO</th>
                <th>TOTAL ATIVOS</th>
                <th>QUANTIDADE PENDENTE</th>
                <th>QUANTIDADE A RETORNAR</th>
                {/* <th>TOTAL PENDENTE</th>
              <th>TOTAL RETORNO</th> */}
              </tr>
            </thead>
            <tbody>
              {itens.map((item, idx) => {
                const serieStr = ns(item);
                return (
                  <tr key={idx}>
                    <td>
                      {item.codigoProduto}
                      {serieStr && <br />}
                      {serieStr}
                    </td>
                    <td>{item.totalAtivos}</td>
                    <td>{item.quantidadePendente}</td>
                    <td>
                      <NumberBox
                        disabled={item.totalAtivos - item.quantidadeDevolvida === 0}
                        className="curreny"
                        format={formatQuantidade(pedido.itens[idx].produto)}
                        height={40}
                        valueChangeEvent="keyup"
                        onValueChange={onInputChange}
                        onFocusIn={onFocusIn}
                        defaultValue={0}
                        id={`quantidade_${idx}`}
                        value={item.quantidadeRetorno}
                      ></NumberBox>
                    </td>
                    {/* <td>{formatStringToCurrency(item.totalPendente)}</td>
                  <td>{formatStringToCurrency(item.totalRetorno)}</td> */}
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" className="text-right">
                  <Button onClick={retornarPedido}>Retornar</Button>
                </td>
                {/* <td colSpan="8" className="text-right">
                <Button onClick={retornarPedido}>Retornar Todos</Button>
              </td> */}
              </tr>
            </tfoot>
          </table>
        </PerfectScrollbar>
      </Popup>
    </>
  );
}
