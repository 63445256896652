import React from 'react';
import styles from './EmpresaCard.module.scss';
import { maskedCnpj } from './../../../../../shared/utils/Utils';

const empresaCard = props => {
  return (
    <>
      <div
        className={`${styles.EmpresaCard} EmpresaCard ${
          props.selected ? 'EmpresaCardSelected' : ''
        }`}
        onClick={props.select}
      >
        <div className={styles.CardItem}>
          <span>{props.nome}</span>
          <span>{maskedCnpj(props.cnpj)}</span>
        </div>
      </div>
    </>
  );
};

export default empresaCard;
