export default function valorFreteVendaDoConsignado(os) {
  const hasPedidoVendaConsignada = os.pedidos.find(pedido => pedido.tipoPedido.id === 6);
  if(!hasPedidoVendaConsignada) return 0;
  const hasPedidoVenda = os.pedidos.find(pedido => pedido.tipoPedido.id === 1);
  const hasPedidolocacao = os.pedidos.find(pedido => pedido.tipoPedido.id === 8);
  let freteVendaConsignada = Math.round(os.valorFrete * 100) / 100;
  if (hasPedidolocacao && !hasPedidoVenda) {
  freteVendaConsignada = 0; // não precisa cobrar frete pois será recebido na locação
  }
  if (hasPedidoVenda) {
    freteVendaConsignada = 0; // não precisa cobrar frete pois será recebido na venda
  }
  return freteVendaConsignada;
}
