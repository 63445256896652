import React, { Component } from 'react';
import SellerPopFrame from '../../../components/SellerPopFrame/SellerPopFrame';
import {
  getUrl,
  getDadosSellerPop,
  getNomePagina,
} from '../../../shared/utils/Utils';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../shared/constants/MenuConstants';


const PATH_MENU = menus.COD_60480;

export class ListaPreco extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <SellerPopFrame
          url={
            getUrl() +
            '/ManterListaPreco.aspx?' +
            getDadosSellerPop(PATH_MENU)
          }
        />
      </LinxPostos>      
    );
  }
}
