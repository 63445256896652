import React, { useState, useEffect } from 'react';
import { Popup } from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react';
import { sameString, getUser } from '../../shared/utils/Utils';
import styles from './ModalTrocaSenha.module.scss';
import notify from 'devextreme/ui/notify';
import { alert as dxAlert } from 'devextreme/ui/dialog';
import { alteraSenha } from '../../pages/core/boas-vindas/BoasVindas.service';
import { MAX_LENGTH_PASSWORD } from '../../shared/constants/Const';

const title = 'Alterar senha';

export default function ModalTrocaSenha(props) {
  const [senhaAtual, setSenhaAtual] = useState(null);
  const [senhaNova, setSenhaNova] = useState(null);
  const [senhaNovaConfirma, setSenhaNovaConfirma] = useState(null);
  const [validationStatus, setValidationStatus] = useState('valid');
  const [senhasDiferentes, setSenhasDiferentes] = useState(false);
  const [changing, setChanging] = useState(false);
  const [email, setEmail] = useState(null);

  const onHiddenHandle = () => {
    if (props.handleCloseModal) {
      props.handleCloseModal();
    }
  };

  useEffect(() => {
    const e = getUser();
    if (e) {
      setEmail(e.replace(/['"]+/g, '')); // remove aspas
    }
  }, []);

  useEffect(() => {
    if (senhaNova && senhaNovaConfirma) {
      const iguais = sameString(senhaNova, senhaNovaConfirma);
      setValidationStatus(iguais ? 'valid' : 'invalid');
      setSenhasDiferentes(!iguais);
    }
  }, [senhaNova, senhaNovaConfirma]);

  const isFormValid = () => {
    if (!senhaAtual) {
      notify('Digite a senha atual', 'error', 5000);
      return false;
    }
    if (!senhaNova) {
      notify('Digite a nova senha', 'error', 5000);
      return false;
    }
    if (!senhaNovaConfirma) {
      notify('Digite a confirmação da nova senha', 'error', 5000);
      return false;
    }
    if (!sameString(senhaNovaConfirma, senhaNova)) {
      notify(
        'Nova senha e confirmação da nova senha não estão iguais.',
        'error',
        5000
      );
      return false;
    }
    return true;
  };

  const changePasswordHandle = async () => {
    const trataErro = async mensagem => {
      await dxAlert(
        '<div>Erro ao alterar a senha: ' + mensagem + '</div>',
        title
      );
    };
    setChanging(true);
    try {
      if (isFormValid()) {
        try {
          const { data: result } = await alteraSenha(
            email,
            senhaAtual,
            senhaNova
          );

          if (result.success) {
            notify('Senha alterada com sucesso', 'success', 5000);
            clearFields();
            onHiddenHandle();
          } else {
            await trataErro(result.message);
          }
        } catch (e) {
          await trataErro(e.message);
        }
      }
    } finally {
      setChanging(false);
    }
  };

  const cancelClickHandle = () => {
    clearFields();
    onHiddenHandle();
  };

  const clearFields = () => {
    setSenhaAtual(null);
    setSenhaNova(null);
    setSenhaNovaConfirma(null);
    setValidationStatus('valid');
    setSenhasDiferentes(false);
    setChanging(false);
  };

  const contentRender = () => {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <h2>Usuário: {props.user}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Senha atual:</label>
            <TextBox
              showClearButton={true}
              value={senhaAtual}
              valueChangeEvent="change keyup"
              onValueChanged={event => {
                setSenhaAtual(event.value);
              }}
              mode="password"
              maxLength={MAX_LENGTH_PASSWORD}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Nova senha:</label>
            <TextBox
              showClearButton={true}
              value={senhaNova}
              valueChangeEvent="change keyup"
              onValueChanged={event => {
                setSenhaNova(event.value);
              }}
              mode="password"
              maxLength={MAX_LENGTH_PASSWORD}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label>Confirme a nova senha:</label>
            <TextBox
              showClearButton={true}
              value={senhaNovaConfirma}
              valueChangeEvent="change keyup"
              onValueChanged={event => {
                setSenhaNovaConfirma(event.value);
              }}
              mode="password"
              validationStatus={validationStatus}
              maxLength={MAX_LENGTH_PASSWORD}
            />
            {senhasDiferentes && (
              <span className={styles.help}>
                Senha de confirmação diferente da nova senha
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="section-footer">
            <div className="button-wrapper">
              {!changing && (<button className="btn btn-light" onClick={cancelClickHandle}> CANCELAR </button>)}
              {' '}
              <button className="btn btn-primary" onClick={changePasswordHandle} disabled={changing}>
                {' '}
                {changing ? (
                  <>
                    <svg className={styles.spinner} viewBox="0 0 50 50">
                      <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                      </circle>
                    </svg>
                      ALTERANDO...
                    </>
                ) : (
                    `ALTERAR SENHA`
                  )}
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (


    props.active && (
      <Popup
        visible={props.active}
        onHidden={onHiddenHandle}
        dragEnabled={false}
        closeOnOutsideClick={false}
        showTitle={true}
        title={title}
        maxWidth={300}
        maxHeight={460}
        contentRender={contentRender} />
    )

  );
}
