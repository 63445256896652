import { v4 } from 'uuid';

export class AdicionarPagamentoService {
  execute = (state, { payload }) => {
    const { payment, pedidoId, centro_monetario, uid } = payload;
    console.log('AdicionarPagamentoService', payload);
    const idx = state.values.pedidos.findIndex((p) => p.pedidoId === pedidoId);

    var data = {
      id: -1,
      uid: uid || v4(),
      estaCod: state.values.estaCod,
      pedidoId: pedidoId,
      valorPagamento: payment.valorPagamento,
      pagamento: payment.pagamento,
      dataCadastro: new Date().toISOString(),
      dataUltimaAtualizacao: new Date().toISOString(),
      vencimento: 'A RECEBER',
      dataVencimento: payment.dataVencimento,
      ceMoCod: (centro_monetario || {}).id,
      info: payment.info || null,
      ceMoValOutrasCobrancas: centro_monetario != null ? centro_monetario.outrasCobrancas : 0.00,
      juros: payment.juros || 0.00,
      numeroParcelas: payment.numeroParcelas || 0,
      regraParcelamento: payment.regraParcelamento,
      parcelas: payment.parcelas || [],
    };

    if (!state.values.pedidos[idx].pagamentos) {
      state.values.pedidos[idx].pagamentos = [];
    }
    state.values.pedidos[idx].pagamentos.push(data);
  };
}
