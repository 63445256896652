import { getDadosLogin, maskedCnpj } from '../../../../../shared/utils/Utils';
import { jsPDF } from 'jspdf';
import { exportDataGrid } from 'devextreme/pdf_exporter';
import 'jspdf-autotable';

export class ExportarParaPdfService {
  async execute(e, title, filters, fileName, columnWidths = 'auto') {
    let doc = new jsPDF({
      unit: 'mm',
      format: 'a4',
      orientation: 'landscape',
    });
    this.drawHeader(doc, title, filters);

    await exportDataGrid({
      jsPDFDocument: doc,
      component: e.component,
      columnWidths: columnWidths,
      topLeft: { x: -4, y: 10 },
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    let pages = doc.internal.getNumberOfPages();
    for (let j = 1; j < pages + 1; j++) {
      doc.setPage(j);
      doc.setFontSize(10);
      doc.text('Página ' + j + ' de ' + pages, pageWidth - 15, 10, { align: 'right' });
    }
    return doc.save(`${fileName}.pdf`);
  }
  drawHeader(doc, title, filter) {
    let fs = doc.getFontSize();
    const dadosLogin = getDadosLogin();
    const empresa = dadosLogin.empresas.find(x => x.cnpj === dadosLogin.cnpj);
    let pageWidth = doc.internal.pageSize.width;
    doc.page = 1;
    // HEADER
    doc.setFontSize(10);
    doc.text(title, pageWidth / 2, 10, { align: 'center' });
    // Subtitle
    doc.setFontSize(8);
    doc.text(`CNPJ: ${maskedCnpj(empresa.cnpj)} - ${empresa.nomeFantasia}`, pageWidth / 2, 14, { align: 'center' });
    // Filters
    doc.setFontSize(7);
    // const filterLines = doc.splitTextToSize(filter, pageWidth - 2 * marginHorizontal);
    //for (let i = 0; i < filterLines.length; i++) {
    doc.text(filter, pageWidth / 2, 18, { align: 'center', maxWidth: pageWidth - 25 });
    //}
    doc.setFontSize(fs);
  }
}
