import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import { AxiosErrorService } from '../core/axiosError.service';

export class ApiMarcarClienteAlternativoComoResolvidoService {
  static instance = null;
  static getInstance() {
    if (!ApiMarcarClienteAlternativoComoResolvidoService.instance) {
      ApiMarcarClienteAlternativoComoResolvidoService.instance = new ApiMarcarClienteAlternativoComoResolvidoService();
    }
    return ApiMarcarClienteAlternativoComoResolvidoService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  execute = async (id) => {
    try {
      await this.urlLinxCore.get(`/ConComodato/marcar-cliente-alternativo-como-resolvido/${id}`);
      return true;
    } catch (er) {
      await new AxiosErrorService().execute(er);
    }
  }
}
