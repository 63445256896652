import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';

import Table from 'react-dt';
import Paper from '@material-ui/core/Paper';

import {
  toFloatFormattedDisplay,
  getNomePagina,
  sameInt
} from '../../../shared/utils/Utils';

import { SectionContent } from '../../../components/section/Content';

import moment from 'moment';

import Box from 'react-styled-box';

import { withRouter } from 'react-router-dom';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';

import {
  Modal,
  ModalBodyDashboard,
  ModalHeader,
  ModalFooter
} from '../../../components/modal/Modal';

import {
  showError,
  hideAlert,
  tagAlert,
  showWait,
  hideWait
} from '../../../components/alert/Alert';

import { Select, InputDate } from '../../../components/formulario/Formulario';

import { getAgrupadas, getOpcoesAntecipacao } from './DashboardPayHub';

import { menus } from '../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60400;

export const TipoConsultaPayHub = {
  Vendas: 1,
  Recebimentos: 2
};

export const TipoTransacaoPayHub = {
  AVista: 1,
  Parcelado: 2
};


const colsVendasBrutoLiquido = [
  { prop: 'data', label: 'Data' },
  { prop: 'valorBruto', label: 'Valor Bruto' },
  { prop: 'valorLiquido', label: 'Valor Liquido' }
];

const colsVendasTicketDiario = [
  { prop: 'data', label: 'Data' },
  { prop: 'qtde', label: 'Qtde' },
  { prop: 'ticketMedio', label: 'Ticket médio' }
];

const titulo = 'Dashboard - PayHub';

class DashboardPayHub extends Component {
  state = {
    vendasTotais: {},
    vendasBL: [],
    vendasTMD: [],
    recebimentosTotais: [],
    colsBandeira: [],
    listaPorBandeirasTratada: [],
    colsAdquirente: [],
    listaPorAdquirenteTratada: [],
    antecipacaoTotais: [],
    listaPorBandeirasAntecip: [],
    modalPeriodo: false,
    dataInicioRecebimentos: moment().startOf('day'),
    dataFimRecebimentos: moment(),
    dataInicioVendas: moment().startOf('day'),
    dataFimVendas: moment()
  };

  abrirModalPeriodo = (oP, tituloWidgetEscolhido) => {
    this.setState({
      modalPeriodo: true,
      origemPeriodo: oP,
      tituloPeriodo: tituloWidgetEscolhido
    });
  };

  handleCloseModalPeriodo = (periodoDe, periodoAte, origem) => {
    this.setState({ modalPeriodo: false });
    if (!periodoDe) {
      return;
    }
    if (!periodoAte) {
      return;
    }
    if (origem === 1) {
      this.setState({ dataInicioVendas: periodoDe, dataFimVendas: periodoAte });
      this.atualizaVendas(periodoDe, periodoAte);
    } else if (origem === 2) {
      this.setState({
        dataInicioRecebimentos: periodoDe,
        dataFimRecebimentos: periodoAte
      });
      this.atualizaRecebimentos(periodoDe, periodoAte);
    } else if (origem === 3) {
      periodoAte = moment(periodoAte).endOf('day');
      this.setState({
        dataInicioAntecip: periodoDe,
        dataFimAntecip: periodoAte
      });
      this.atualizaAntecipacao(periodoDe, periodoAte);
    }
  };

  async doMount() {
    this.setState({ diaAtual: moment().format('DD/MM/YYYY') });
    const momentNow = moment();

    await this.atualizaVendas(moment(momentNow).startOf('day'), momentNow);
    await this.atualizaRecebimentos(
      moment(momentNow).startOf('day'),
      momentNow
    );
    //await this.atualizaAntecipacao(momentNow, momentNow);
  }

  async componentDidMount() {
    await this.doMount();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      await this.doMount();
    }
  }

  atualizaAntecipacao = async (dataInicio, dataFim) => {
    const totais = {
      totalBruto: 0,
      valorBruto: '',
      totalLiquido: 0,
      valorLiquido: '',
      antecipacoes: []
    };
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto as antecipações são atualizadas.'
    );
    try {
      try {
        const { data: resultAntecipacao } = await this.getAntecipacoes(
          dataInicio,
          dataFim
        );

        if (resultAntecipacao && resultAntecipacao.length) {
          resultAntecipacao.forEach(element => {
            totais.totalBruto += parseFloat(element.valorBruto.replace(",","."));
            totais.totalLiquido += parseFloat(element.valorLiquido.replace(",","."));
          });
          totais.antecipacoes.push(...resultAntecipacao);
          totais.valorBruto = toFloatFormattedDisplay(totais.totalBruto);
          totais.valorLiquido = toFloatFormattedDisplay(totais.totalLiquido);
        } else {
          this.setState({
            antecipacaoTotais: [],
            creditoAVista: '',
            creditoParcelado: '',
            listaPorBandeirasAntecip: []
          });
          return;
        }
      } catch (error) {
        return;
      }

      const listaPorBandeirasAntecip = [];
      let listaBandeiraExistente = false,
        totalAVista = 0,
        totalParcelado = 0;

      totais.antecipacoes.forEach(antecipacao => {
        if (listaPorBandeirasAntecip.length > 0) {
          listaBandeiraExistente =
            listaPorBandeirasAntecip.filter(
              item => item.bandeira === antecipacao.bandeira
            ).length > 0;
        }

        if (!listaBandeiraExistente) {
          const itemListaBandeira = {
            bandeira: antecipacao.bandeira,
            valorBruto: 0,
            antecipacoes: []
          };
          const listaFiltradaBandeira = totais.antecipacoes.filter(
            rec => rec.bandeira === antecipacao.bandeira
          );
          itemListaBandeira.antecipacoes = listaFiltradaBandeira;
          listaPorBandeirasAntecip.push(itemListaBandeira);
        }
        const valorAAntecipar = parseFloat(antecipacao.valorBruto.replace(",","."));
        if (sameInt(antecipacao.tipoTransacao, TipoTransacaoPayHub.AVista)) {
          totalAVista += valorAAntecipar;
        } else {
          totalParcelado += valorAAntecipar;
        }
      });

      if (listaPorBandeirasAntecip && listaPorBandeirasAntecip.length) {
        listaPorBandeirasAntecip.forEach(bandeira => {
          bandeira.antecipacoes.forEach(element => {
            bandeira.valorBruto += parseFloat(element.valorBruto.replace(",","."));
          });
        });
      }
      const creditoAVista = 'R$ ' + toFloatFormattedDisplay(totalAVista);
      const creditoParcelado = 'R$ ' + toFloatFormattedDisplay(totalParcelado);
      const antecipacaoTotais = totais.antecipacoes;
      antecipacaoTotais.valorBruto = totais.valorBruto;
      antecipacaoTotais.valorLiquido = totais.valorLiquido;
      this.setState({
        antecipacaoTotais,
        creditoAVista,
        creditoParcelado,
        listaPorBandeirasAntecip
      });
      hideAlert(this);
    } finally {
      hideWait(this);
    }
  };

  atualizaRecebimentos = async (dataInicio, dataFim) => {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto os recebimentos são atualizados.'
    );
    try {
      const totais = {
        valorLiquido: 0,
        valorBruto: 0,
        recebimentos: []
      };

      try {
        let { data: resultRecebimentos } = await this.getTransacoes(
          dataInicio,
          dataFim,
          TipoConsultaPayHub.Recebimentos
        );
        //let resultRecebimentos = transacoes;
        if (resultRecebimentos && resultRecebimentos.length) {
          resultRecebimentos.forEach(element => {
            totais.valorBruto += element.valorBruto;
            totais.valorLiquido += element.valorLiquido;
          });
          totais.recebimentos.push(...resultRecebimentos);
        } else {
          this.setState({
            recebimentosTotais: [],
            listaPorBandeirasTratada: [],
            listaPorAdquirenteTratada: [],
            colsBandeira: [],
            colsAdquirente: []
          });
          return;
        }
      } catch (error) {
        return;
      }
      totais.recebimentos = this.getTranscoesOrdenadasPorData(
        totais.recebimentos,
        dataInicio,
        dataFim
      );

      totais.valorBruto = totais.valorBruto.toFixed(2);
      totais.valorLiquido = totais.valorLiquido.toFixed(2);
      totais.valorBruto = toFloatFormattedDisplay(totais.valorBruto);
      totais.valorLiquido = toFloatFormattedDisplay(totais.valorLiquido);

      const listaPorBandeiras = [];
      const listaPorAdquirente = [];

      let listaBandeiraExistente = false;
      let listaAdquirenteExistente = false;
      const colsBandeira = [{ prop: 'bandeira', label: 'Bandeira' }];
      const colsAdquirente = [{ prop: 'adquirente', label: 'Adquirente' }];

      totais.recebimentos.forEach(itemDia => {
        itemDia.data = moment(itemDia.data).format('DD/MM/YYYY');
        //cada dia é um elemento
        let somaTotalDia = 0;
        if (itemDia.length) {
          itemDia.forEach((recebimento, index) => {
            somaTotalDia += recebimento.valorBruto;
            const dataFormatada = moment(recebimento.data).format('DD/MM/YYYY');
            if (listaPorBandeiras.length > 0) {
              listaBandeiraExistente =
                listaPorBandeiras.filter(
                  i =>
                    i.bandeira === recebimento.bandeira.nome &&
                    i.data === dataFormatada
                ).length > 0;
            }

            if (!listaBandeiraExistente) {
              const itemListaBandeira = {
                bandeira: recebimento.bandeira.nome,
                data: dataFormatada,
                recebimentos: []
              };

              const listaFiltradaBandeira = itemDia.filter(
                rec => rec.bandeira.nome === recebimento.bandeira.nome
              );
              itemListaBandeira.recebimentos = listaFiltradaBandeira;
              listaPorBandeiras.push(itemListaBandeira);
            }

            if (index === itemDia.length - 1) {
              somaTotalDia = somaTotalDia.toFixed(2);
              const itemBandeira = {
                bandeira: 'Total'
              };
              itemBandeira[
                'valorBruto' + itemDia.data.substring(0, 5).replace('/', '')
              ] = 'R$ ' + toFloatFormattedDisplay(somaTotalDia);
              listaPorBandeiras.push(itemBandeira);
            }

            if (listaPorAdquirente.length > 0) {
              listaAdquirenteExistente =
                listaPorAdquirente.filter(
                  i =>
                    i.adquirente === recebimento.adquirente &&
                    i.data === dataFormatada
                ).length > 0;
            }

            if (!listaAdquirenteExistente) {
              const itemListaAdquirente = {
                adquirente: recebimento.adquirente,
                data: dataFormatada,
                recebimentos: []
              };
              const listaFiltradaAdquirente = itemDia.filter(
                rec => rec.adquirente === recebimento.adquirente
              );
              itemListaAdquirente.recebimentos = listaFiltradaAdquirente;
              listaPorAdquirente.push(itemListaAdquirente);
            }

            if (index === itemDia.length - 1) {
              //somaTotalDia = somaTotalDia.toFixed(2);
              const itemAdquirente = {
                adquirente: 'Total'
              };
              itemAdquirente[
                'valorBruto' + itemDia.data.substring(0, 5).replace('/', '')
              ] = 'R$ ' + toFloatFormattedDisplay(somaTotalDia);
              listaPorAdquirente.push(itemAdquirente);
            }
          });
        }

        const itemCol = {
          prop: 'valorBruto' + itemDia.data.substring(0, 5).replace('/', ''),
          label: itemDia.data
        };
        colsBandeira.push(itemCol);
        colsAdquirente.push(itemCol);
      });

      const setTotalBruto = (list, property) => {
        if (list && list.length) {
          let dataCalculando = '',
            valorBrutoData = 0;
          list.forEach(item => {
            if (item[property] === 'Total') {
              item[
                'valorBruto' + dataCalculando.substring(0, 5).replace('/', '')
              ] = 'R$ ' + toFloatFormattedDisplay(valorBrutoData);
            } else {
              if (item.data !== dataCalculando) {
                dataCalculando = item.data;
                valorBrutoData = 0;
              }
              item.valorBruto = 0;
              item.recebimentos.forEach(element => {
                item.valorBruto += element.valorBruto;
              });
              item[
                'valorBruto' + dataCalculando.substring(0, 5).replace('/', '')
              ] = 'R$ ' + toFloatFormattedDisplay(item.valorBruto);
              valorBrutoData += item.valorBruto;
            }
          });
        }
      };

      setTotalBruto(listaPorBandeiras, 'bandeira');
      setTotalBruto(listaPorAdquirente, 'adquirente');

      const trataLista = (list, property) => {
        const newList = [];
        let comparisonPropExists = false;
        if (list && list.length) {
          list.forEach(item => {
            comparisonPropExists = false;
            if (newList.length) {
              newList.forEach((newItem, index) => {
                if (newItem[property] === item[property]) {
                  comparisonPropExists = true;
                  Object.assign(newList[index], item);
                }
              });
            }
            if (!comparisonPropExists) {
              newList.push(item);
            }
          });
        }
        return newList;
      };

      const listaPorBandeirasTratada = trataLista(
        listaPorBandeiras,
        'bandeira'
      );
      const listaPorAdquirenteTratada = trataLista(
        listaPorAdquirente,
        'adquirente'
      );

      const setTotalValueOnCorrectIndex = (list, property) => {
        if (list && list.length > 0) {
          const lastIndex = list.length - 1;
          const lastItem = list[lastIndex];
          if (lastItem[property] !== 'Total') {
            const localTotal = list.findIndex(i => i[property] === 'Total');
            const tmp = list[lastIndex];
            list[lastIndex] = list[localTotal];
            list[localTotal] = tmp;
          }
        }
      };
      setTotalValueOnCorrectIndex(listaPorBandeirasTratada, 'bandeira');
      setTotalValueOnCorrectIndex(listaPorAdquirenteTratada, 'adquirente');

      for (
        let d1 = moment(dataInicio);
        moment(d1).isSameOrBefore(moment(dataFim), 'days');
        d1 = moment(d1).add(1, 'days')
      ) {
        const valorBrutoProp = 'valorBruto' + d1.format('DDMM');
        listaPorBandeirasTratada.forEach(item => {
          if (!item[valorBrutoProp]) {
            item[valorBrutoProp] = 'R$ 0,00';
          }
        });
        listaPorAdquirenteTratada.forEach(item => {
          if (!item[valorBrutoProp]) {
            item[valorBrutoProp] = 'R$ 0,00';
          }
        });
      }

      this.setState({
        recebimentosTotais: totais,
        listaPorBandeirasTratada,
        listaPorAdquirenteTratada,
        colsBandeira,
        colsAdquirente
      });
    } finally {
      hideWait(this);
    }
  };

  getTransacoes = async (dataInicio, dataFim, tipoConsulta) => {
    const startDate = moment(dataInicio).utcOffset(0),
      endDate = moment(dataFim).utcOffset(0);
    try {
      const { data: resultTransacoes } = await getAgrupadas(
        startDate,
        endDate,
        tipoConsulta
      );
      return resultTransacoes;
    } catch (error) {
      return null;
    }
  };

  getAntecipacoes = async (dataInicio, dataFim) => {
    const startDate = moment(dataInicio).utcOffset(0),
      endDate = moment(dataFim).utcOffset(0);
    try {
      const { data: resultAntecipacoes } = await getOpcoesAntecipacao(
        startDate,
        endDate
      );
      return resultAntecipacoes;
    } catch (error) {
      return null;
    }
  };

  getTranscoesOrdenadasPorData = (transactions, startDate, endDate) => {
    let result = [];
    if (!transactions.length) {
      return;
    }

    transactions.forEach(t => {
      let data = moment(t.data).format('YYYY-MM-DD');

      if (!result.find(item => item.data === data)) {
        const transacoesDeUmaData = transactions.filter(
          t1 => moment(t1.data).format('YYYY-MM-DD') === data
        );

        if (transacoesDeUmaData.length > 0) {
          transacoesDeUmaData.data = data;
          result.push(transacoesDeUmaData);
        }
      }
    });

    if (result.length !== moment(startDate).diff(moment(endDate), 'days') - 1) {
      for (
        let m = moment(startDate);
        moment(m).isSameOrBefore(moment(endDate), 'days');
        m = moment(m).add(1, 'days')
      ) {
        let dataAsString = moment(m).format('YYYY-MM-DD');
        if (!result.find(item => item.data === dataAsString)) {
          const transacaoArrayVazio = [];
          transacaoArrayVazio.data = dataAsString;
          result.push(transacaoArrayVazio);
        }
      }
      result = result.sort((a, b) => {
        const data1 = moment(a.data).format('DD/MM/YYYY');
        const data2 = moment(b.data).format('DD/MM/YYYY');
        if (parseFloat(data1) < parseFloat(data2)) return -1;
        if (parseFloat(data1) > parseFloat(data2)) return 1;
        return 0;
      });
    }
    return result;
  };

  atualizaVendas = async (dataInicio, dataFim) => {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto as vendas são atualizadas.'
    );
    try {
      const totais = {
        vendas: [],
        valorBruto: 0,
        valorLiquido: 0,
        qtdeVendasRealizadas: 0
      };
      try {
        const { data: resultVendas } = await this.getTransacoes(
          dataInicio,
          dataFim,
          TipoConsultaPayHub.Vendas
        );
        //let resultVendas = transacoes;

        if (resultVendas && resultVendas.length) {
          resultVendas.forEach(element => {
            totais.valorBruto += element.valorBruto;
            totais.valorLiquido += element.valorLiquido;
          });
          totais.vendas.push(...resultVendas);
          totais.qtdeVendasRealizadas = resultVendas.length;
        } else {
          this.setState({ vendasTotais: [], vendasBL: [], vendasTMD: [] });
          return;
        }
      } catch (e) {
        return;
      }

      totais.ticketMedio = totais.qtdeVendasRealizadas
        ? totais.valorBruto / totais.qtdeVendasRealizadas
        : 0;

      totais.ticketMedio = totais.ticketMedio.toFixed(2);

      totais.ticketMedio = toFloatFormattedDisplay(totais.ticketMedio);
      totais.valorBruto = toFloatFormattedDisplay(totais.valorBruto);
      totais.valorLiquido = toFloatFormattedDisplay(totais.valorLiquido);

      let vendasRealizadas = this.getTranscoesOrdenadasPorData(
        totais.vendas,
        dataInicio,
        dataFim
      );
      let vendasBL = []; //BrutoxLiquido
      let vendasTMD = []; //TicketMedioDiario

      vendasRealizadas.forEach(vr => {
        let totalVendas = {};
        if (vr && vr.length) {
          totalVendas = vr.reduce((a, b) => ({
            valorBruto: a.valorBruto + b.valorBruto,
            valorLiquido: a.valorLiquido + b.valorLiquido
          }));
          totalVendas.qtdeVendas = vr.length;
          totalVendas.ticketMedio =
            totalVendas.valorBruto / totalVendas.qtdeVendas;
        } else {
          totalVendas = {
            valorBruto: 0,
            valorLiquido: 0,
            qtdeVendas: 0,
            ticketMedio: 0
          };
        }

        vendasBL.push({
          data: moment(vr.data).format('DD/MM/YYYY'),
          valorBruto: 'R$' + toFloatFormattedDisplay(totalVendas.valorBruto),
          valorLiquido: 'R$' + toFloatFormattedDisplay(totalVendas.valorLiquido)
        });

        totalVendas.ticketMedio = totalVendas.ticketMedio.toFixed(2);

        vendasTMD.push({
          data: moment(vr.data).format('DD/MM/YYYY'),
          qtde: totalVendas.qtdeVendas,
          ticketMedio: 'R$' + toFloatFormattedDisplay(totalVendas.ticketMedio)
        });
      });

      this.setState({ vendasTotais: totais, vendasBL, vendasTMD });
    } finally {
      hideWait(this);
    }
  };

  sortTableValuesByDate = (a, b) => {
    if (parseFloat(a.data) < parseFloat(b.data)) return -1;
    if (parseFloat(a.data) > parseFloat(b.data)) return 1;
    return 0;
  };

  handlePeriodoVendaChange = e => {
    const escolhido = e.target.value;
    if (sameInt(escolhido, 3)) {
      this.abrirModalPeriodo(
        1,
        'Selecione um período de no máximo 30 dias para consulta inferior a data atual.'
      );
      this.setState({ periodoVendas: escolhido });
      return;
    }

    let dataInicio = moment();
    let dataFim = moment();
    if (escolhido === '2') {
      dataInicio = moment()
        .subtract(6, 'days')
        .startOf('day');
    }

    this.setState({
      dataInicioVendas: dataInicio,
      dataFimVendas: dataFim,
      periodoVendas: escolhido
    });
    this.atualizaVendas(dataInicio, dataFim);
  };

  handlePeriodoRecChange = e => {
    let dataInicio = moment().startOf('day');
    let dataFim = moment();
    const escolhido = e.target.value; // 1 = hoje
    if (escolhido === '2') {
      //Ultimos 7 dias
      dataInicio = moment().subtract(6, 'days');
    } else if (escolhido === '3') {
      //Ultimos 15 dias
      dataInicio = moment().subtract(14, 'days');
    } else if (escolhido === '4') {
      //Ultimos 30 dias
      dataInicio = moment().subtract(29, 'days');
    } else if (escolhido === '5') {
      //Próximos 7 dias
      dataFim = moment().add(6, 'days');
    } else if (escolhido === '6') {
      //Próximos 15 dias
      dataFim = moment().add(14, 'days');
    } else if (escolhido === '7') {
      //Próximos 30 dias
      dataFim = moment().add(29, 'days');
    } else if (escolhido === '8') {
      this.abrirModalPeriodo(
        2,
        'Selecione um período de no máximo 30 dias para consulta superior ou inferior a data atual.'
      );
      this.setState({ periodoReceb: escolhido });
      return;
    }
    this.setState({
      dataInicioRecebimentos: dataInicio,
      dataFimRecebimentos: dataFim,
      periodoReceb: escolhido
    });

    this.atualizaRecebimentos(dataInicio, dataFim);
  };

  render() {
    const {
      vendasTotais,
      vendasBL,
      vendasTMD,
      recebimentosTotais,
      colsBandeira,
      listaPorBandeirasTratada,
      colsAdquirente,
      listaPorAdquirenteTratada,
      periodoVendas,
      periodoReceb,
      antecipacaoTotais,
      creditoAVista,
      creditoParcelado,
      listaPorBandeirasAntecip
    } = this.state;

    const { modalPeriodo } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <div className="section-content-payhub mt-3">
            <div className="secion-body-payhub">
              <div className="row">
                <div className="col-md-2">
                  <div className="col-md-2">
                    <h3>Vendas</h3>
                  </div>
                </div>

                <div className="col-md-7 payhub-selectDate">
                  {this.state.periodoVendas === '3' ? (
                    <>                    
                        <div className="col-md-3">
                          <InputDate
                            label="Data:"
                            value={this.state.dataInicioVendas}
                            name="dataInicioVendas"
                            disabled
                          />
                        </div>
                        <div className="col-md-3">
                          <InputDate
                            label="Data:"
                            value={this.state.dataFimVendas}
                            name="dataFimVendas"
                            disabled
                          />
                        </div>                        
                        <div className="col-md-2">
                          <br/>
                          <Botao
                            secondary
                            dashboard
                            icon="icon-lx-check"
                            title="Selecionar período"
                            onClick={() => {
                              this.abrirModalPeriodo(
                                1,
                                'Selecione um período de no máximo 30 dias para consulta inferior a data atual.'
                              );
                            }}
                          />
                        </div>
                      
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-md-3">
                  <div className="col-md-12 payhub-controls">
                    <Select
                      label=""
                      name="periodoVendas"
                      value={periodoVendas}
                      onChange={this.handlePeriodoVendaChange}
                      options={[
                        { label: 'Hoje', value: 1 },
                        { label: 'Últimos 7 dias', value: 2 },
                        { label: 'Outro período', value: 3 }
                      ]}
                      tabindex={1}
                    />
                    <Botao
                      className="right"
                      ic
                      icon="icon-lx-refresh"
                      onClick={() => {
                        this.atualizaVendas(
                          this.state.dataInicioVendas,
                          this.state.dataFimVendas
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2">
                  <Box flexDirection="column" width="100%">
                    <div
                      className="col-md-12"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        marginBottom: 10,
                        borderRadius: 5
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                        <div className="payhub">
                          <h5>Bruto</h5>
                          <h1>R$ {vendasTotais.valorBruto}</h1>
                        </div>
                      </Paper>
                    </div>

                    <div
                      className="col-md-12"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        marginBottom: 10,
                        marginTop: 10,
                        borderRadius: 5
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                        <div className="payhub">
                          <h5>Liquido</h5>
                          <h1>R$ {vendasTotais.valorLiquido}</h1>
                        </div>
                      </Paper>
                    </div>

                    <div
                      className="col-md-12"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        marginTop: 10,
                        borderRadius: 5
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                        <div className="payhub">
                          <h5>Ticket médio</h5>
                          <h1>R$ {vendasTotais.ticketMedio}</h1>
                          <h5 className="sub">
                            {vendasTotais.qtdeVendasRealizadas} vendas
                            realizadas
                          </h5>
                        </div>
                      </Paper>
                    </div>
                  </Box>
                </div>
                <div className="col-md-10 dashboard-wrapper">
                  <div
                    className="dashboard col-md-6"
                    style={{
                      boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                      border: '1px solid #ccc',
                      height: 300
                    }}
                  >
                    <Paper
                      square
                      elevation={0}
                      style={{ width: '100%', overflow: 'auto' }}
                    >
                      <header className="section-header-paper">
                        <div className="section-header-content">
                          <h2>Bruto x Liquido</h2>
                        </div>
                      </header>
                      {vendasBL.length > 0 ? (
                        <Table cols={colsVendasBrutoLiquido} rows={vendasBL} />
                      ) : (
                        <center>
                          <h3>
                            Não há informações para exibição no período
                            selecionado
                          </h3>
                        </center>
                      )}
                    </Paper>
                  </div>

                  <div
                    className="dashboard col-md-6"
                    style={{
                      boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                      border: '1px solid #ccc',
                      height: 300
                    }}
                  >
                    <Paper
                      square
                      elevation={0}
                      style={{ width: '100%', overflow: 'auto' }}
                    >
                      <header className="section-header-paper">
                        <div className="section-header-content">
                          <h2>Ticket médio diário</h2>
                        </div>
                      </header>
                      {vendasBL.length > 0 ? (
                        <Table cols={colsVendasTicketDiario} rows={vendasTMD} />
                      ) : (
                        <center>
                          <h3>
                            Não há informações para exibição no período
                            selecionado
                          </h3>
                        </center>
                      )}
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section-content-payhub">
            <div className="secion-body-payhub">
              <div className="row">
                <div className="col-md-2">
                  <div className="col-md-12">
                    <h3>Previsão de recebimentos</h3>
                  </div>
                </div>

                <div className="col-md-7 payhub-selectDate">
                  {this.state.periodoReceb === '8' ? (
                    <>
                      <div className="col-md-3">
                        <InputDate
                          label="Data:"
                          value={this.state.dataInicioRecebimentos}
                          name="dataInicioRecebimentos"
                          disabled
                        />
                      </div>
                      <div className="col-md-3">
                        <InputDate
                          label="Data:"
                          value={this.state.dataFimRecebimentos}
                          name="dataFimRecebimentos"
                          disabled
                        />
                      </div>
                      <div className="col-md-2">
                        <br/>
                        <Botao
                          secondary
                          dashboard
                          icon="icon-lx-check"
                          title="Selecionar período"
                          onClick={() => {
                            this.abrirModalPeriodo(
                              2,
                              'Selecione um período de no máximo 30 dias para consulta superior ou inferior a data atual.'
                            );
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-md-3">
                  <div className="col-md-12 payhub-controls">
                    <Select
                      label=""
                      name="periodoReceb"
                      value={periodoReceb}
                      onChange={this.handlePeriodoRecChange}
                      options={[
                        { label: 'Hoje', value: 1 },
                        { label: 'Últimos 7 dias', value: 2 },
                        { label: 'Últimos 15 dias', value: 3 },
                        { label: 'Últimos 30 dias', value: 4 },
                        { label: 'Próximos 7 dias', value: 5 },
                        { label: 'Próximos 15 dias', value: 6 },
                        { label: 'Próximos 30 dias', value: 7 },
                        { label: 'Outro período', value: 8 }
                      ]}
                      tabindex={1}
                    />
                    <Botao
                      className="right"
                      ic
                      icon="icon-lx-refresh"
                      onClick={() => {
                        this.atualizaRecebimentos(
                          this.state.dataInicioRecebimentos,
                          this.state.dataFimRecebimentos
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2">
                  <Box flexDirection="column" width="100%">
                    <div
                      className="col-md-12"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        marginBottom: 10,
                        borderRadius: 5
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                        <div className="payhub payhub-recebimentos">
                          <h5>Bruto</h5>
                          <h1>R$ {recebimentosTotais.valorBruto}</h1>
                        </div>
                      </Paper>
                    </div>

                    <div
                      className="col-md-12"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        marginTop: 10,
                        borderRadius: 5
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                        <div className="payhub payhub-recebimentos">
                          <h5>Liquido</h5>
                          <h1>R$ {recebimentosTotais.valorLiquido}</h1>
                        </div>
                      </Paper>
                    </div>
                  </Box>
                </div>
                <div />
                <div className="col-md-10 dashboard-wrapper">
                  <div
                    className="dashboard col-md-6"
                    style={{
                      boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                      border: '1px solid #ccc',
                      height: 210
                    }}
                  >
                    <Paper
                      square
                      elevation={0}
                      style={{ width: '100%', overflow: 'auto' }}
                    >
                      <header className="section-header-paper">
                        <div className="section-header-content">
                          <h2>Por bandeira</h2>
                        </div>
                      </header>
                      {listaPorBandeirasTratada.length > 0 ? (
                        <Table
                          cols={colsBandeira}
                          rows={listaPorBandeirasTratada}
                        />
                      ) : (
                        <center>
                          <h3>
                            Não há informações para exibição no período
                            selecionado
                          </h3>
                        </center>
                      )}
                    </Paper>
                  </div>
                  <div
                    className="dashboard col-md-6"
                    style={{
                      boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                      border: '1px solid #ccc',
                      height: 210
                    }}
                  >
                    <Paper
                      square
                      elevation={0}
                      style={{ width: '100%', overflow: 'auto' }}
                    >
                      <header className="section-header-paper">
                        <div className="section-header-content">
                          <h2>Por adquirente</h2>
                        </div>
                      </header>
                      {listaPorAdquirenteTratada.length > 0 ? (
                        <Table
                          cols={colsAdquirente}
                          rows={listaPorAdquirenteTratada}
                        />
                      ) : (
                        <center>
                          <h3>
                            Não há informações para exibição no período
                            selecionado
                          </h3>
                        </center>
                      )}
                    </Paper>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="section-content-payhub"
            style={{ paddingBottom: '36px' }}
          >
            <div className="secion-body-payhub">
              <div className="row">
                <div className="col-md-7">
                  <div className="col-md-12">
                    <h3>Opções de antecipação</h3>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="col-md-12 payhub-controls payhub-antecipacao">
                    <InputDate
                      label="Data:"
                      value={this.state.dataInicioAntecip}
                      name="dataInicioAntecip"
                      disabled
                    />
                    <InputDate
                      label="Data:"
                      value={this.state.dataFimAntecip}
                      name="dataFimAntecip"
                      disabled
                    />
                    <Botao
                      secondary
                      icon="icon-lx-check"
                      title="Selecionar período"
                      onClick={() => {
                        this.abrirModalPeriodo(
                          3,
                          'Selecione um período superior a data atual.'
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-2">
                  <Box flexDirection="column" width="100%">
                    <div
                      className="col-md-12"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        marginBottom: 10,
                        borderRadius: 5
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                        <div className="payhub">
                          <h5>Bruto</h5>
                          <h1>R$ {antecipacaoTotais.valorBruto}</h1>
                        </div>
                      </Paper>
                    </div>

                    <div
                      className="col-md-12"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        marginTop: 10,
                        borderRadius: 5
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                        <div className="payhub">
                          <h5>Liquido</h5>
                          <h1>R$ {antecipacaoTotais.valorLiquido}</h1>
                        </div>
                      </Paper>
                    </div>
                  </Box>
                </div>

                <div className="col-md-10 dashboard-wrapper">

                  <div
                    className="dashboard col-md-6"
                    style={{
                      boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                      border: '0px solid #ccc',
                      height: 210,
                      lineHeight: 0.8
                    }}
                  >
                    <Paper square elevation={0} style={{ width: '100%' }}>
                    <header className="section-header-paper">
                        <div className="section-header-content">
                          <h2>Por adquirente</h2>
                        </div>
                      </header>
                      
                        {listaPorBandeirasAntecip.map(lb => {
                          return (                             
                            <h5 style={{fontSize: '14px',color: '#5e6c84', marginTop: '10px', marginLeft: '15px'}}>
                                {lb.bandeira +
                                  ': R$ ' +
                                  toFloatFormattedDisplay(lb.valorBruto)}
                                  <br/>
                              </h5>                                                          
                          );                          
                        })}                      
                    </Paper>
                  </div>

                  <div className="col-md-6 payhub-antecipacao__operacoes">
                    <div
                      className="dashboard"
                      style={{
                        boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, .1)',
                        border: '0px solid #ccc',
                        height: 210,
                        marginRight: '22px'
                      }}
                    >
                      <Paper square elevation={0} style={{ width: '100%' }}>
                      <header className="section-header-paper">
                        <div className="section-header-content">
                          <h2>Operações</h2>
                        </div>
                      </header>                      
                          
                            <h5 style={{fontSize: '14px',color: '#5e6c84', marginTop: '10px', marginLeft: '15px'}}>
                              Crédito à vista: {creditoAVista}
                            </h5>                            
                            <h5 style={{fontSize: '14px',color: '#5e6c84', marginTop: '10px', marginLeft: '15px'}}>
                              Crédito parcelado: {creditoParcelado}
                            </h5>
                          
                      </Paper>
                    </div>
                    <div>
                      <a
                        rel="noopener noreferrer"
                        href="https://portal-cliente.linxsaas.com.br/login/linx"
                        target="_blank"
                      >
                        <Botao title="Simular no portal PayHub" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ModalPeriodo
            active={modalPeriodo}
            handleCloseModal={this.handleCloseModalPeriodo}
            origem={this.state.origemPeriodo}
            tituloModal={this.state.tituloPeriodo}
          />
        </LinxPostos>
        {tagAlert(this)}
      </>
    );
  }
}

export class ModalPeriodo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      periodoDe: moment(),
      periodoAte: moment()
    };
  }

  handlePeriodoDeChange = e => {
    if (e.date) {
      const periodoDe = e.date.format('YYYY-MM-DD') + 'T00:00:00';

      this.setState({ periodoDe });
    }
  };

  handlePeriodoAteChange = e => {
    if (e.date) {
      const periodoAte = e.date.format('YYYY-MM-DD') + 'T00:00:00';

      this.setState({ periodoAte });
    }
  };

  render() {
    const { active, handleCloseModal, origem, tituloModal } = this.props;

    var erro = false;

    const { periodoDe, periodoAte } = this.state;

    return (
      <div>
        <Modal className={`${active ? 'active' : null} modal-filter`}>
          <ModalHeader title={tituloModal} subtitle="" />

          <ModalBodyDashboard>
            {/* Conteúdo principal */}
            <SectionContent>
              <div className="row">
                <div
                  className="col-md-5"
                  style={{ position: 'relative', zIndex: '11' }}
                >
                  <InputDate
                    label="De:"
                    value={periodoDe}
                    name="periodoDe"
                    onChange={this.handlePeriodoDeChange}
                    tabindex={1}
                  />
                </div>
                <div
                  className="col-md-5"
                  style={{ position: 'relative', zIndex: '11' }}
                >
                  <InputDate
                    label="Até:"
                    value={periodoAte}
                    name="periodoAte"
                    onChange={this.handlePeriodoAteChange}
                    tabindex={2}
                  />
                </div>
              </div>
            </SectionContent>
          </ModalBodyDashboard>

          <ModalFooter>
            <Botao
              icon="icon-lx-check"
              title={'Aplicar'}
              onClick={() => {
                if (moment(periodoDe).isAfter(moment(periodoAte))) {
                  showError(
                    this,
                    'PayHub',
                    'Data de inicio deve ser menor que a data de fim'
                  );
                  erro = true;
                }

                if (origem === 1) {
                  //vendas'
                  //período de 30 dias
                  if (moment(periodoDe).isAfter(moment())) {
                    showError(
                      this,
                      'PayHub',
                      'Data de inicio deve ser menor que a data atual'
                    );
                    erro = true;
                  }
                  if (moment(periodoAte).isAfter(moment())) {
                    showError(
                      this,
                      'PayHub',
                      'Data de fim deve ser menor que a data atual'
                    );
                    erro = true;
                  }
                  if (moment(periodoAte).diff(moment(periodoDe), 'days') > 30) {
                    showError(
                      this,
                      'PayHub',
                      'A diferença entre as datas de inicio e fim deve ser de no máximo 30 dias'
                    );
                    erro = true;
                  }
                } else if (origem === 2) {
                  //recebimentos
                  //período de 30 dias
                  if (moment(periodoAte).diff(moment(periodoDe), 'days') > 30) {
                    showError(
                      this,
                      'PayHub',
                      'A diferença entre as datas de inicio e fim deve ser de no máximo 30 dias'
                    );
                    erro = true;
                  }
                } else if (origem === 3) {
                  //antecipações
                  if (moment().isAfter(moment(periodoDe))) {
                    showError(
                      this,
                      'PayHub',
                      'Data de inicio deve ser maior que a data atual'
                    );
                    erro = true;
                  }
                }
                if (!erro) {
                  handleCloseModal(
                    moment(periodoDe).startOf('day'),
                    moment(periodoAte),
                    origem
                  );
                }
              }}
            />
            <Botao
              secondary
              icon="icon-lx-check"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal();
              }}
            />
          </ModalFooter>
        </Modal>
        {tagAlert(this)}
      </div>
    );
  }
}

DashboardPayHub = withRouter(DashboardPayHub);
export { DashboardPayHub };
