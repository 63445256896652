import React, { Component } from "react";
import { handleInputChange } from "../../../components/formulario/Formulario";
import { getPessoas } from "../../cadastro/pessoas/Pessoas.service";
import { Classificacao } from "../../cadastro/pessoas/Pessoas.jsx";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../../components/modal/Modal";
import Botao from "../../../components/botao/Botao";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { maskedCnpjCpf } from "../../../shared/utils/Utils";

class SearchPessoaForm extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
  }
  render() {
    return (
      <div>
        <Modal
          className={"active modal-filter"}
          onClose={() => this.props.onClose(null, null)}
        >
          <ModalHeader
            title={this.props.title || "Pesquisar Pessoa"}
            subtitle={
              this.props.subtitle || "Selecione uma pessoa clicanco nela"
            }
          />
          <ModalBody>
            <ReactTable
              data={this.props.list}
              showPagination={false}
              defaultPageSize={this.props.list.length}
              columns={[
                {
                  accessor: "codigo",
                  Header: "Código",
                  width: 100,
                  sorteable: true
                },
                { accessor: "nome", Header: "Nome", sorteable: true },
                {
                  accessor: "cnpjCpf",
                  Header: "CPF/CNPJ",
                  width: 180,
                  sorteable: true,
                  Cell: props => <div> {maskedCnpjCpf(props.value)} </div>
                }
              ]}
              defaultSorted={[{ id: "nome", desc: false }]}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    this.props.onClose(rowInfo.original);
                  }
                };
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={"Cancelar"}
              onClick={() => {
                this.props.onClose(null);
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export async function searchPessoa({
  sender,
  searchText,
  onClose,
  classificacoes = [],
  title = "Pesquisa Pessoa",
  subtitle = "Selecione uma pessoa clicando nela"
}) {
  if (!searchText) onClose(null, "Informe um texto a ser pesquisado.");
  else {
    const { data } = await getPessoas({ search: searchText });
    if (!data || !data.result || data.result.length === 0)
      onClose(
        null,
        'Nenhum resultado obtido com a pesquisa "' + searchText + '" informada'
      );
    else if (data.result.length === 1) onClose(data.result[0], null);
    else
      sender.setState({
        searchPessoa: {
          searchText,
          title,
          subtitle,
          list: data.result,
          onClose: (pessoa, message) => {
            if (sender.state.searchPessoa) {
              const newState = sender.state;
              delete newState.searchPessoa;
              sender.setState(newState);
            }
            onClose(pessoa, message);
          }
        }
      });
  }
}

export function searchCliente(
  sender,
  searchText,
  onClose,
  title = "Pesquisa Cliente",
  subtitle = "Selecione um cliente clicando nele"
) {
  return searchPessoa({
    sender,
    searchText,
    onClose,
    classificacoes: [Classificacao.Cliente],
    title,
    subtitle
  });
}

export function searchFornecedor(
  sender,
  searchText,
  onClose,
  title = "Pesquisa Fornecedor",
  subtitle = "Selecione um fornecedor clicando nele"
) {
  return searchPessoa({
    sender,
    searchText,
    onClose,
    classificacoes: [Classificacao.Fornecedor],
    title,
    subtitle
  });
}

export const tagSearchPessoa = sender => {
  if (sender.state.searchPessoa)
    return (
      <SearchPessoaForm
        active={true}
        title={sender.state.searchPessoa.title}
        subtitle={sender.state.searchPessoa.subtitle}
        list={sender.state.searchPessoa.list}
        onClose={sender.state.searchPessoa.onClose}
      />
    );
};
