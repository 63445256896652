import { CharToInt, IntToChar } from './utils';

export function CheckIEAM(ie) {
  if (ie.length !== 9) return false;
  var b = 9,
    soma = 0;
  for (var i = 0; i <= 7; i++) {
    soma += CharToInt(ie.charAt(i)) * b;
    b--;
  }
  var dig = 0;
  if (soma < 11) {
    dig = 11 - soma;
  } else {
    i = soma % 11;
    if (i <= 1) {
      dig = 0;
    } else {
      dig = 11 - i;
    }
  }
  return IntToChar(dig) === ie.charAt(8);
} //am
