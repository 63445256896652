import axios from "axios";
import { getUrl, getCnpj } from "../../../shared/utils/Utils";
import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getFormasPagamento(somenteAtivos, cadastroCompleto) {
  return axios.post(`LxApi/v1/FormasPagamento/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      somenteAtivos,
      cadastroCompleto
    }
  });
}

export function getFormaPagamento(codigoFormaPagamento) {
  return axios.get(`LxApi/v1/FormasPagamento/Selecionar/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoFormaPagamento
    }
  });
}

export function incluiFormaPagamento(
  descricao,
  tipo,
  avisoSangria,
  interna,
  opcoesPagamento,
  parcelamento,
  inativo,
  diaRecebimento
) {
  return axios.post(`LxApi/v1/FormasPagamento/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    descricao,
    tipo,
    avisoSangria,
    interna,
    opcoesPagamento,
    parcelamento,
    inativo,
    diaRecebimento
  });
}

export function alteraFormaPagamento(
  codigo,
  descricao,
  tipo,
  avisoSangria,
  interna,
  opcoesPagamento,
  parcelamento,
  inativo,
  diaRecebimento,
  rowVersion
) {
  return axios.put(`LxApi/v1/FormasPagamento/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigo,
    descricao,
    tipo,
    avisoSangria,
    interna,
    opcoesPagamento,
    parcelamento,
    inativo,
    diaRecebimento,
    rowVersion
  });
}

export function excluiFormaPagamento(codigoFormaPagamento) {
  return axios.delete(`LxApi/v1/FormasPagamento/Excluir/`, {
    params: { cnpjEstabelecimento: getCnpj(), codigoFormaPagamento }
  });
}
