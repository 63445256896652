import React, { useRef, useState, useEffect, useCallback } from 'react';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import { CustomButton } from '../../../SharedComponents/CustomButton/CustomButton';
import { getDadosLogin } from '../../../../../../shared/utils/Utils';

import { PagamentosService } from '../Pagamentos.service';
import { VincularTitulos } from '../../../SharedComponents';
import { Formulario } from './Formulario/Formulario';

export const RealizarPagamento = ({ isShow, barcode}, setIsShow) => {
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [selectedFinancial, setSelectedFinancial] = useState([]);

  const formRef = useRef(null);

  const resetStatus = useCallback(() => {
    setStep(0);
    setFormData({
      barCode: '',
      amount: 0,
      addition: 0,
      expiration: new Date(),
      supplier: '',
      bank: '',
      payDay: new Date(),
    });
  }, []);

  const handleSubmit = async () => {
    const formInstance = formRef.current.instance;
    const validationResult = formInstance.validate();

    if (validationResult.isValid) {
      try {
        const dadosLogin = getDadosLogin();

        const data = formInstance.option('formData');
        const payloadData = {
          ...data,
          titleCod: Array.isArray(selectedFinancial) ? selectedFinancial : [],
          estaCod: dadosLogin.codigoEstabelecimento,
        };

        setFormData(payloadData);

        setIsLoading(true);

        var response = await PagamentosService.paySlip(payloadData);

        if (response.result) {
          notify('Para concluir a operação, aprove a solicitação no app Aprovador Linx.', 'success', 5000);
          resetStatus();
        } else {
          notify(response.notifications[0].message, 'error', 5000);
        }
      } catch (error) {
        notify('Ocorreu um erro ao realizar o pagamento.', 'error', 5000);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onFinish = (selectedRows = []) => {
    // setSelectedFinancial(selectedRows);
    setStep(1);
  };

  useEffect(() => {
    if (!isShow) {
      resetStatus();
    }
  }, [isShow, resetStatus]);

  return (
    <>
      {isShow && (
        <div className="d-flex flex-column h-100">
          <VincularTitulos isShow={step === 0} onFinish={onFinish} typeTitle="P" />
          <Formulario formRef={formRef} formData={formData} isShow={step === 1} setFormData={setFormData} code={barcode} />
          {step === 1 && (
            <div className="d-flex justify-content-between ml-auto my-3">
              <CustomButton className="rounded" icon="chevronnext" iconPosition="right" duplicateIcon={true} onClick={handleSubmit} disabled={formData.barCode === ''}>
                Realizar pagamento
              </CustomButton>
            </div>
          )}
          {isLoading && (
            <div className="d-flex justify-content-center align-items-center">
              <LoadIndicator id="large-indicator" height={60} width={60} />
            </div>
          )}
        </div>
      )}
    </>
  );
};
