import { DataGrid, DropDownBox } from 'devextreme-react';
import { Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { selectFiltroOs, useFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { DropDownImage } from '../../consignado-comodato-nova-os/components/drop-down-image';
import styles from './Component.module.scss';
import { arraysEqual } from '../../../../shared/utils/Utils';

export const DropboxFormasPagamentos = forwardRef(({ onChanged }, ref) => {
  const [opened, setOpened] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const { values } = useSelector(selectFiltroOs);
  const pagamentosData = useSelector(selectConsignadoComodatoSharedData).pagamentos;
  const [pagamentosMap, setPagamentosMap] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { changeValue } = useFiltroOs();

  useEffect(() => {
    if (values.pagamentos === pagamentosData.map(x => x.id).join('_')) return;
    let pos = 1;
    let its = (pagamentosData || []).map(x => {
      const item = { ...x };
      item.id = parseInt(item.id, 10);
      item.key = pos++;
      return item;
    });

    setPagamentosMap(its);
  }, [pagamentosData, values.pagamentos]);

  useEffect(() => {
    try {
      if (!values.pagamentos) {
        return;
      }
      const src = (values.pagamentos || '').split('_').map(x => parseInt(x, 10));
      const its = (pagamentosMap || []).filter(x => src.includes(x.id)).map(x => x.id);
      if (!arraysEqual(its, selectedItemKeys)) {
        setSelectedItemKeys(its);
      }
    } catch (error) {
      console.error(error);
    }
  }, [values.pagamentos, pagamentosMap, pagamentosData, selectedItemKeys]);

  useImperativeHandle(ref, () => ({
    getValue: () => data,
  }));

  const onSelectedItemKeysChange = useCallback(
    args => {
      if ([args.selectedRowKeys].length === 0) {
        dispatch(changeValue({ fieldName: 'pagamentos', value: '' }));
        return;
      }
      const its = (pagamentosMap || []).filter(x => args.selectedRowKeys.includes(x.id));
      setData(its);
      if (typeof onChanged === 'function') {
        onChanged(its);
      }
      console.log('onSelectedItemKeysChange', its.map(x => x.id).join('_'), values.pagamentos || '');
      if (its.map(x => x.id).join('_') === (values.pagamentos || '')) return;
      const ids = [];
      for (const it of its) {
        ids.push(it.id);
      }
      dispatch(changeValue({ fieldName: 'pagamentos', value: ids.join('_') }));
      setSelectedItemKeys(args.selectedRowKeys);
    },
    [dispatch, changeValue, pagamentosMap, onChanged, values.pagamentos],
  );
  return (
    <DropDownBox
      showClearButton={false}
      placeholder="Escolha as formas de pagamento"
      onValueChanged={e => {
        if (!e.event) return;
        if (((e.event.target || {}).className || '').includes('clear')) {
          setSelectedItemKeys([]);
          setData([]);
          if (typeof onChanged === 'function') {
            onChanged([]);
          }
        }
      }}
      className={`${styles.bottonBorder} ml-1`}
      valueExpr="id"
      displayExpr="descricao"
      showDropDownButton={true}
      dropDownButtonRender={DropDownImage}
      dataSource={pagamentosMap}
      openOnFieldClick={true}
      value={selectedItemKeys}
      onOpenedChange={e => {
        setOpened(e);
      }}
      opened={opened}
      onKeyUp={e => {
        if (opened) e.preventDefault();
        if (
          e.event.originalEvent.code === 'ArrowDown' ||
          e.event.originalEvent.key === 'ArrowDown' ||
          e.event.originalEvent.code === 'Enter' ||
          e.event.originalEvent.key === 'Enter' ||
          e.event.originalEvent.code === 'Space' ||
          e.event.originalEvent.key === 'Space'
        ) {
          setOpened(true);
        }
      }}
      contentRender={() => {
        return (
          <DataGrid
            dataSource={pagamentosMap}
            keyExpr="id"
            showBorders={false}
            selectedRowKeys={selectedItemKeys}
            onSelectionChanged={onSelectedItemKeysChange}
          >
            <Selection mode="multiple" selectAllMode={'allPages'} showCheckBoxesMode={'always'} />
            <FilterRow visible={true} />
            <Scrolling mode="normal" />
            <Column dataField="descricao" caption="Nome" />
          </DataGrid>
        );
      }}
    />
  );
});
