import axios from "axios";
import { TOKEN_PASS_AXIOS } from "../../../../shared/constants/Const";
import { getUrl } from "../../../../shared/utils/Utils";
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getBombas(cnpjEstabelecimento, cadastroCompleto) {
  return axios.post(`LxApi/v1/Bomba/Listar`, {
    query: {
      cnpjEstabelecimento,
      cadastroCompleto
    }
  });
}

export function getBomba(codigoBomba, cnpjEstabelecimento) {
  return axios.get(`LxApi/v1/Bomba/Selecionar`, {
    params: {
      codigoBomba,
      cnpjEstabelecimento
    }
  });
}

export function incluirBomba(
  cnpjEstabelecimento,
  codigoMaquinaPdv,
  numeroBomba,
  numeroSerie,
  fabricante,
  modelo,
  codigoTipo,
  codigoFabricante,
  casasDecimais,
  lacres
) {
  return axios.post(`LxApi/v1/Bomba/Incluir`, {
    cnpjEstabelecimento,
    codigoMaquinaPdv,
    numeroBomba,
    numeroSerie,
    fabricante,
    modelo,
    codigoTipo,
    codigoFabricante,
    casasDecimais,
    lacres
  });
}

export function alterarBomba(
  cnpjEstabelecimento,
  codigoBomba,
  codigoMaquinaPdv,
  numeroBomba,
  numeroSerie,
  fabricante,
  modelo,
  codigoTipo,
  codigoFabricante,
  casasDecimais,
  lacres,
  inativo,
  rowVersion
) {
  return axios.put(`LxApi/v1/Bomba/Alterar`, {
    cnpjEstabelecimento,
    codigoBomba,
    codigoMaquinaPdv,
    numeroBomba,
    numeroSerie,
    fabricante,
    modelo,
    codigoTipo,
    codigoFabricante,
    casasDecimais,
    lacres,
    inativo,
    rowVersion
  });
}

export function excluirBomba(codigoBomba, cnpjEstabelecimento) {
  return axios.delete(`/LxApi/v1/Bomba/Excluir`, {
    params: {
      codigoBomba,
      cnpjEstabelecimento
    }
  });
}

export function montaComboPDV(cnpjEstabelecimento) {
  return axios.get(`/LxApi/v1/Bomba/MontarComboPdvs`, {
    params: { cnpjEstabelecimento }
  });
}

export function montaComboFabricantes(cnpjEstabelecimento) {
  return axios.get(`/LxApi/v1/Bomba/MontarComboFabricantes`, {
    params: { cnpjEstabelecimento }
  });
}
