import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

/**
 * Um serviço para filtrar turnos usando a API.
 */
export class ApiTurnoFiltroService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  /**
   * Filtra turnos usando os critérios especificados.
   * @param {string} dtDe A data de início da pesquisa.
   * @param {string} dtAte A data de término da pesquisa.
   * @param {object} op O operador para a pesquisa.
   * @param {string} situacao A situação para a pesquisa.
   * @param {string} nf O número Pedido para a pesquisa.
   * @param {boolean} useCache Se deve usar dados em cache, se disponíveis.
   * @returns {Promise<Array>} Uma promessa que resolve para uma matriz de turnos filtrados.
   */
  async execute(dtDe, dtAte, op, situacao, pedido, useCache = false) {
    try {
      let ops = null
      if(Array.isArray(op) && op.length){
        ops = op.map(x => x.id).join('_');
      }
      const params = new URLSearchParams();
      console.log(dtDe, dtAte);
      params.append('dataInicio', new Date(dtDe).toISOString());
      params.append('dataFim', new Date(dtAte).toISOString());
      if (ops) {
        params.append('operadores', ops);
      }

      if (situacao) {
        params.append('situacao', situacao);
      }

      if (pedido) {
        params.append('pedido', pedido);
      }
      const dadosLogin = getDadosLogin();
      const cacheKey = `ApiTurnoFiltroService${dadosLogin.cnpj}?${params}`; // Chave do cache para este serviço
      const cachedData = useCache ? memoryCache.get(cacheKey) : null;
      if (cachedData) {
        return cachedData;
      } else {
        const response = await this.urlLinxCore.get(`/TurnoPedido/Listar?${params.toString()}`);
        var r = response.data;
        var data = r.itens;
        if (useCache) memoryCache.put(cacheKey, data, 1000 * 60 * 3);
        return data;
      }
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
