import React, { Component } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import { getUrl, getNomePagina, getDadosSellerPop } from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_13490;

export class ManterObservacoesPreparo extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <div className="row">
          <Iframe url={getUrl() + '/ManterObservacoesPreparo.aspx?' + getDadosSellerPop(PATH_MENU)} className="iframePop" />
        </div>
      </LinxPostos>
    );
  }
}
