import React, { Component } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import Botao from '../../../components/botao/Botao';
import {
  handleInputChange,
  FileInput
} from '../../../components/formulario/Formulario';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  toFloatFormattedDisplay,
  getNomePagina
} from '../../../shared/utils/Utils';
import {
  tagAlert,
  showInfo,
  showError,
  showLoadError,
  showWait,
  hideWait,
  showQuestion,
  hideAlert,
  showSaveError
} from '../../../components/alert/Alert';

import {
  validaImportacaoPessoas,
  salvaImportacaoPessoas
} from '../../cadastro/pessoas/Pessoas.service';

import {
  validaImportacaoProdutos,
  salvaImportacaoProdutos
} from '../../cadastro/produtos/Produtos.service';
import { Modo } from './CargaInicialHub';
import { FileError } from '../../../components/errors/Errors';

import icsuccess from '../../../../assets/images/postopop/lx_check2.svg';
import icerro from '../../../../assets/images/postopop/lx_close-2.svg';
import icalerta from '../../../../assets/images/postopop/lx_exclamation-triangle.svg';
import icdownload from '../../../../assets/images/postopop/lx_download.svg';
import styled from 'styled-components';
import './StyleCargaInicial.scss';
import Footer from '../../../components/core/footer/Footer';
import { menus } from '../../../shared/constants/MenuConstants';
import { 
  URL_ARQUIVO_MODELO_PESSOA,
  URL_ARQUIVO_MODELO_PRODUTO 
} from '../../../shared/constants/Const';

const ImportState = {
  None: 0,
  PreImport: 1,
  SaveImport: 2
};

const ImportDataStatus = {
  None: 0,
  Ok: 1,
  Alert: 2,
  Error: 3
};

const ImgStyle = styled.span`
  position: relative;
  top: 11px;
`;

const PATH_MENU = menus.COD_60990;

class CargaInicialImportacao extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    this.state = {
      arquivoCSV: null,
      importState: ImportState.PreImport,
      gridData: [],
      colunas: [],
      importData: []
    };
  }

  componentDidMount = () => {
    if (this.props.modo === Modo.None)
      this.props.history.push('/configuracoes/carga-inicial');
  };

  isProdutos = () => {
    return this.props.modo === Modo.Produtos;
  };

  isPessoas = () => {
    return this.props.modo === Modo.Pessoas;
  };

  getTitulo = () => {
    if (this.isProdutos()) return 'Carga Inicial - Produtos';

    if (this.isPessoas()) return 'Carga Inicial - Pessoas';

    return 'Carga Inicial';
  };

  getArquivoModelo = () => {
    if (this.isProdutos())
      return (URL_ARQUIVO_MODELO_PRODUTO);

    if (this.isPessoas())
      return (URL_ARQUIVO_MODELO_PESSOA);

    return '';
  };

  doImport = async csvFile => {
    if (!csvFile)
      throw new FileError('Falha no arquivo .csv. \n Tente novamente.');

    if (this.isProdutos()) {
      const { data: dataProdutos } = await validaImportacaoProdutos(csvFile);

      this.apiToGrid(dataProdutos);
    } else if (this.isPessoas()) {
      const { data: dataPessoas } = await validaImportacaoPessoas(csvFile);

      this.apiToGrid(dataPessoas);
    } else {
      // teoricamente nunca deveria entrar aqui
      throw new Error(
        'Nenhum tipo de dado (produtos ou pessoas) para importar'
      );
    }
  };

  getStatusIcon = (status, msgList) => {
    let title = '',
      src = '',
      alt = '';

    switch (status) {
      case ImportDataStatus.Ok:
        title =
          'O item não possui registro de erro na validação e será ' +
          'salvo no banco corretamente.';
        src = icsuccess;
        alt = 'Sucesso';
        break;

      case ImportDataStatus.Alert:
        title =
          'O item possui alguma incoerência nos dados importados mas ' +
          'que não bloqueia a inserção do item no banco de dados.';
        if (msgList && msgList.length > 0) {
          msgList.forEach(m => {
            title = title + '\n' + m;
          });
        }
        src = icalerta;
        alt = 'Alerta';
        break;
      case ImportDataStatus.Error:
        title =
          'O item possui erros que bloqueiam a inserção do item ' +
          'no banco de dados.';
        if (msgList && msgList.length > 0) {
          msgList.forEach(m => {
            title = title + '\n' + m;
          });
        }
        src = icerro;
        alt = 'Erro';
        break;
      default:
        break;
    }

    return (
      <div>
        <img title={title} src={src} alt={alt} height='60' width='60' />
      </div>
    );
  };

  getStatusFromAlert = alert => {
    if (!alert) return ImportDataStatus.Ok;

    if (alert.length === 0) return ImportDataStatus.Ok;

    let status = ImportDataStatus.Alert;
    alert.forEach(a => {
      if (a.bloqueiaImportacao) {
        status = ImportDataStatus.Error;
        return;
      }
    });
    return status;
  };

  getMsgFromAlert = alert => {
    const result = [];
    if (alert && alert.length > 0) {
      alert.forEach(a => {
        result.push(a.mensagem);
      });
    }
    return result;
  };

  apiToGrid = apiData => {
    let gridData = [],
      colunas = [],
      importData = [];
    if (this.isProdutos()) {
      apiData.forEach(item => {
        let status = this.getStatusFromAlert(item.alertas);
        // só importa itens sem erro
        if (status !== ImportDataStatus.Error)
          importData.push({ ...item.produto });

        gridData.push({
          mensagens: this.getMsgFromAlert(item.alertas),
          status: status,
          situacao: item.produto.situacao.descricao,
          codigo: item.produto.codigo,
          gtin: !!item.produto.gtin,
          descricao: item.produto.descricao,
          descricaoResumida: item.produto.descricaoResumida,
          subtipo: item.produto.subtipo.descricao,
          grupo: item.produto.grupo,
          subGrupo: item.produto.subGrupo,
          artigo: item.produto.artigo,
          ncm: item.produto.ncm.descricao,
          cest: item.produto.cest.descricao,
          anp: item.produto.anp.descricao,
          unidadeCompra: item.produto.unidadeCompra.descricao,
          unidadeVenda: item.produto.unidadeVenda.descricao,
          fatorConversao: item.produto.fatorConversao,
          setor: item.produto.setor.descricao,
          permiteVenda: !item.produto.naoPermiteVenda,
          controlaEstoque: item.produto.controlaEstoque,
          estoqueAtual: item.produto.estoqueAtual,
          precoVenda: item.produto.precosMargens.precoVenda,
          precoCompra: item.produto.precosMargens.precoCompra,
          custoComICMS: item.produto.precosMargens.custoComICMS,
          custoSemICMS: item.produto.precosMargens.custoSemICMS,
          custoMedio: item.produto.estoque.custoMedio,
          formaReajuste: item.produto.precosMargens.formaReajuste,
          percentualReajuste: item.produto.precosMargens.valorAjuste,
          tributacaoVendaICMS: item.produto.icms.situacaoTributaria,
          aliquotaTributacaoICMS: item.produto.icms.percentualValor,
          percentualFCP: item.produto.fcp ? item.produto.fcp : 0,
          tributacaoPIS: item.produto.pis.situacaoTributaria,
          aliquotaTributacaoPIS: item.produto.pis.percentualValor,
          naturezaReceitaPIS: item.produto.pis.naturezaReceita,
          tributacaoCOFINS: item.produto.cofins.situacaoTributaria,
          aliquotaTributacaoCOFINS: item.produto.cofins.percentualValor,
          naturezaReceitaCOFINS: item.produto.cofins.naturezaReceita,
          almoxarifado: item.produto.estoque.almoxarifados
            ? item.produto.estoque.almoxarifados[0].descricao
            : '',
          escalaNaoRelevante: item.produto.possuiEscalaNaoRelevante,
          codigoBeneficioFiscal: item.produto.codigoBeneficioEscalaNaoRelevante,
          cnpjFabricante: item.produto.cnpjFabricanteEscalaNaoRelevante,
          razaoSocialFabricante:
            item.produto.razaoSocialFabricanteEscalaNaoRelevante,
          quantidadeMinima: item.produto.estoque.quantidadeMinima,
          quantidadeMaxima: item.produto.estoque.quantidadeMaxima
        });
      });
      colunas = [
        {
          accessor: 'mensagens',
          show: false
        },
        {
          accessor: 'status',
          Header: 'Status',
          width: 75,
          Cell: props => {
            return (
              <>
                <div>
                  {this.getStatusIcon(props.row.status, props.row.mensagens)}
                </div>
              </>
            );
          }
        },
        {
          accessor: 'situacao',
          Header: 'Situação',
          sortable: false
        },
        {
          accessor: 'codigo',
          Header: 'Código'
        },
        {
          accessor: 'gtin',
          Header: 'É GTIN?',
          Cell: props => {
            return (
              <>
                <div>{props.row.gtin ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'descricao',
          Header: 'Descrição'
        },
        {
          accessor: 'descricaoResumida',
          Header: 'Descrição resumida'
        },
        {
          accessor: 'subtipo',
          Header: 'Subtipo'
        },
        {
          accessor: 'grupo',
          Header: 'Grupo'
        },
        {
          accessor: 'subGrupo',
          Header: 'SubGrupo'
        },
        {
          accessor: 'artigo',
          Header: 'Artigo'
        },
        {
          accessor: 'ncm',
          Header: 'NCM'
        },
        {
          accessor: 'cest',
          Header: 'CEST'
        },
        {
          accessor: 'anp',
          Header: 'ANP'
        },
        {
          accessor: 'unidadeCompra',
          Header: 'Unidade de compra'
        },
        {
          accessor: 'unidadeVenda',
          Header: 'Unidade de venda'
        },
        {
          accessor: 'fatorConversao',
          Header: 'Fator de conversão',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'setor',
          Header: 'Setor'
        },
        {
          accessor: 'permiteVenda',
          Header: 'Permite venda',
          Cell: props => {
            return (
              <>
                <div>{!!props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'controlaEstoque',
          Header: 'Controla estoque',
          Cell: props => {
            return (
              <>
                <div>{!!props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'estoqueAtual',
          Header: 'Estoque atual'
        },
        {
          accessor: 'precoVenda',
          Header: 'Preço de venda',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'precoCompra',
          Header: 'Preço de compra',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'custoComICMS',
          Header: 'Custo com ICMS',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'custoSemICMS',
          Header: 'Custo sem ICMS',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'custoMedio',
          Header: 'Custo Médio',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'formaReajuste',
          Header: 'Forma de reajuste'
        },
        {
          accessor: 'percentualReajuste',
          Header: 'Percentual Reajuste',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'tributacaoVendaICMS',
          Header: 'Tributação na venda - ICMS'
        },
        {
          accessor: 'aliquotaTributacaoICMS',
          Header: 'Alíquota tributação - ICMS',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'percentualFCP',
          Header: 'FCP (%)',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'tributacaoPIS',
          Header: 'Tributação - PIS'
        },
        {
          accessor: 'aliquotaTributacaoPIS',
          Header: 'Alíquota tributação - PIS',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'naturezaReceitaPIS',
          Header: 'Natureza Receita - PIS',
        },
        {
          accessor: 'tributacaoCOFINS',
          Header: 'Tributação - COFINS'
        },
        {
          accessor: 'aliquotaTributacaoCOFINS',
          Header: 'Alíquota tributação - COFINS',
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'naturezaReceitaCOFINS',
          Header: 'Natureza Receita - COFINS'
        },
        {
          accessor: 'almoxarifado',
          Header: 'Almoxarifado'
        },
        {
          accessor: 'escalaNaoRelevante',
          Header: 'Escala não relevante',
          Cell: props => {
            return (
              <>
                <div>{!!props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'codigoBeneficioFiscal',
          Header: 'Código Benefício Fiscal'
        },
        {
          accessor: 'cnpjFabricante',
          Header: 'CNPJ do fabricante'
        },
        {
          accessor: 'razaoSocialFabricante',
          Header: 'Razão social do fabricante'
        },
        {
          accessor: 'quantidadeMinima',
          Header: 'Quantidade mínima'
        },
        {
          accessor: 'quantidadeMaxima',
          Header: 'Quantidade máxima'
        }
      ];
    } else if (this.isPessoas()) {
      apiData.forEach(item => {
        let status = this.getStatusFromAlert(item.alertas);

        // só importa itens sem erro
        if (status !== ImportDataStatus.Error)
          importData.push({ ...item.pessoa });

        gridData.push({
          mensagens: this.getMsgFromAlert(item.alertas),
          status: status,
          cliente: item.pessoa.cliente,
          tipoCliente: item.pessoa.tipoCliente.descricao,
          fornecedor: item.pessoa.fornecedor,
          tipoFornecedor: item.pessoa.tipoFornecedor.descricao,
          fabricante: item.pessoa.fabricante,
          tipoFabricante: item.pessoa.tipoFabricante.descricao,
          transportadora: item.pessoa.transportadora,
          isentoICMS: item.pessoa.isentoICMS,
          nome: item.pessoa.nome,
          nomeFantasia: item.pessoa.nomeFantasia,
          cnpjCpf: item.pessoa.cnpjCpf,
          identidade: item.pessoa.identidade,
          orgaoEmissor: item.pessoa.orgaoEmissor,
          nomeContador: item.pessoa.nomeContador,
          crcContador: item.pessoa.crcContador,
          inscricaoEstadual: item.pessoa.inscricaoEstadual,
          inscricaoMunicipal: item.pessoa.inscricaoMunicipal,
          dataFundacaoNascimento: item.pessoa.dataNascimento,
          cep: item.pessoa.cep,
          enderecoLogradouro: item.pessoa.endereco,
          enderecoNumero: item.pessoa.numero,
          enderecoComplemento: item.pessoa.complemento,
          enderecoBairro: item.pessoa.bairro,
          enderecoUF: item.pessoa.uf.descricao,
          enderecoCidade: item.pessoa.cidade.descricao,
          contato: item.pessoa.contato,
          ddd: item.pessoa.ddd,
          telefone: item.pessoa.telefone,
          email: item.pessoa.email,
          url: item.pessoa.url,
          situacao: item.pessoa.bloqueadoFaturamento,
          utilizaFatura: item.pessoa.utilizaFatura,
          tipoPrazo: item.pessoa.tipoPrazo.descricao,
          diasFechamento: item.pessoa.diasFechamento,
          limiteCredito: item.pessoa.limiteCredito,
          emitirNfeVendasAPrazo: item.pessoa.emiteNFe,
          enviarEmailGeracaoFatura: item.pessoa.enviaEmailFatura
        });
      });
      colunas = [
        {
          accessor: 'mensagens',
          show: false
        },
        {
          accessor: 'status',
          Header: 'Status',
          width: 75,
          Cell: props => {
            return (
              <>
                <div>
                  {this.getStatusIcon(props.row.status, props.row.mensagens)}
                </div>
              </>
            );
          }
        },
        {
          accessor: 'cliente',
          Header: 'Cliente',
          width: 140,
          Cell: props => {
            return (
              <>
                <div>{!!props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'tipoCliente',
          Header: 'Tipo de Cliente',
          width: 140
        },
        {
          accessor: 'fornecedor',
          Header: 'Fornecedor',
          width: 140,
          Cell: props => {
            return (
              <>
                <div>{!!props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'tipoFornecedor',
          Header: 'Tipo de Fornecedor',
          width: 150
        },
        {
          accessor: 'fabricante',
          Header: 'Fabricante',
          width: 150,
          Cell: props => {
            return (
              <>
                <div>{!!props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'tipoFabricante',
          Header: 'Tipo de Fabricante',
          width: 150
        },
        {
          accessor: 'transportadora',
          Header: 'Transportadora',
          width: 150,
          Cell: props => {
            return (
              <>
                <div>{!!props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'isentoICMS',
          Header: 'Isento ICMS',
          width: 150,
          Cell: props => {
            return (
              <>
                <div>{props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'nome',
          Header: 'Nome/Razão Social',
          width: 200
        },
        {
          accessor: 'nomeFantasia',
          Header: 'Nome Fantasia',
          width: 200
        },
        {
          accessor: 'cnpjCpf',
          Header: 'CNPJ/CPF',
          width: 150
        },
        {
          accessor: 'identidade',
          Header: 'RG',
          width: 150
        },
        {
          accessor: 'orgaoEmissor',
          Header: 'Órgão Emissor',
          width: 150
        },
        {
          accessor: 'nomeContador',
          Header: 'Nome Contador',
          width: 150
        },
        {
          accessor: 'crcContador',
          Header: 'CRC Contador',
          width: 150
        },
        {
          accessor: 'inscricaoEstadual',
          Header: 'Inscrição Estadual',
          width: 150
        },
        {
          accessor: 'inscricaoMunicipal',
          Header: 'Inscrição municipal',
          width: 150
        },
        {
          accessor: 'dataFundacaoNascimento',
          Header: 'Data de Fundação/Nascimento',
          width: 230
        },
        {
          accessor: 'cep',
          Header: 'CEP',
          width: 100
        },
        {
          accessor: 'enderecoLogradouro',
          Header: 'Endereço',
          width: 200
        },
        {
          accessor: 'enderecoNumero',
          Header: 'Número',
          width: 140
        },
        {
          accessor: 'enderecoComplemento',
          Header: 'Complemento',
          width: 150
        },
        {
          accessor: 'enderecoBairro',
          Header: 'Bairro',
          width: 160
        },
        {
          accessor: 'enderecoUF',
          Header: 'UF',
          width: 70
        },
        {
          accessor: 'enderecoCidade',
          Header: 'Cidade',
          width: 170
        },
        {
          accessor: 'contato',
          Header: 'Contato',
          width: 100
        },
        {
          accessor: 'ddd',
          Header: 'DDD',
          width: 70
        },
        {
          accessor: 'telefone',
          Header: 'Telefone',
          width: 150
        },
        {
          accessor: 'email',
          Header: 'E-mail',
          width: 210
        },
        {
          accessor: 'url',
          Header: 'Endereço Internet',
          width: 210
        },
        {
          accessor: 'situacao',
          Header: 'Bloqueado para Faturamento',
          width: 210,
          Cell: props => {
            return (
              <>
                <div>{props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'utilizaFatura',
          Header: 'Utiliza Fatura',
          width: 140,
          Cell: props => {
            return (
              <>
                <div>{props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'tipoPrazo',
          Header: 'Tipo de Prazo',
          width: 140
        },
        {
          accessor: 'diasFechamento',
          Header: 'Dias de Fechamento',
          width: 160
        },
        {
          accessor: 'limiteCredito',
          Header: 'Limite Crédito (R$)',
          width: 160,
          Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
        },
        {
          accessor: 'emitirNfeVendasAPrazo',
          Header: 'Emitir NF-e de Vendas a Prazo',
          width: 220,
          Cell: props => {
            return (
              <>
                <div>{props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        },
        {
          accessor: 'enviarEmailGeracaoFatura',
          Header: 'Enviar E-mail de Geração de Fatura',
          width: 230,
          Cell: props => {
            return (
              <>
                <div>{props.value ? 'Sim' : 'Não'}</div>
              </>
            );
          }
        }
      ];
    }

    this.setState({
      gridData,
      colunas,
      importState: ImportState.SaveImport,
      importData
    });
  };

  tagButtons = () => {
    const { arquivoCSV } = this.state;

    return (
      <>
        {this.state.importState === ImportState.SaveImport ? (
          <Botao
            title='Salvar importação'
            secondary
            icon='icon-lx-save'
            onClick={this.saveImportData}
          />
        ) : arquivoCSV ? (
          <Botao
            title='Importar dados'
            secondary
            icon='icon-lx-files'
            onClick={this.doImportData}
          />
        ) : (
          <></>
        )}

        <Botao
          title='Cancelar'
          icon='icon-lx-close'
          onClick={() => {
            showQuestion(
              this,
              this.getTitulo(),
              'Deseja realmente cancelar a importação?',
              null,
              resp => {
                if (resp) this.doCancel();
                hideAlert(this);
              }
            );
          }}
        />
      </>
    );
  };

  doImportData = async () => {
    const { arquivoCSV } = this.state;
    if (arquivoCSV && arquivoCSV.length > 0 && arquivoCSV[0].size > 0) {
      try {
        showWait(this, this.getTitulo(), 'Aguarde, importando dados');
        await this.doImport(arquivoCSV[0]);
        hideWait(this);
      } catch (e) {
        if (e.name && e.name === 'FileError')
          showError(this, this.getTitulo(), e.message);
        else {
          this.treatMessage(e);
          showLoadError(this, this.getTitulo(), e);
        }
      }
    } else {
      showError(
        this,
        this.getTitulo(),
        'Escolha um arquivo csv válido para importar'
      );
    }
  };

  treatMessage = e => {
    if (!e) return;
    let s = String(e.response.data.message);

    if (s.includes('Colunas não reconhecidas')) {
      const dp = s.indexOf(':'); // dois pontos depois do trecho Colunas não reconhecidas
      s = '\n' + s.substring(0, dp + 1) + '\n' + s.substring(dp + 1);
      const re = /\t/gi;
      s = s.replace(re, '\n');
    }
    if (s.includes('Colunas não encontradas')) {
      const cne = s.indexOf('Colunas não encontradas');
      const dp = s.indexOf(':', cne); // dois pontos depois do trecho Colunas não encontradas
      s =
        '\n' +
        s.substring(0, cne) +
        ' \n' +
        s.substring(cne, dp + 1) +
        '\n' +
        s.substring(dp + 1);
      const re = /,/gi;
      s = s.replace(re, '\n');
    }
    e.response.data.message = s;
  };

  saveImportData = async () => {
    const { importData } = this.state;
    try {
      showWait(
        this,
        this.getTitulo(),
        'Aguarde, salvando importação dos dados'
      );
      let ok = false,
        msg = '';
      if (this.isProdutos()) {
        const { data: dataImportacaoProdutos } = await salvaImportacaoProdutos(
          importData ? importData : []
        );
        if (dataImportacaoProdutos) {
          ok = dataImportacaoProdutos.success;
          if (!ok) {
            msg = 'Mensagem do servidor: \n' + dataImportacaoProdutos.message;
            dataImportacaoProdutos.data.forEach(d => {
              if (d.alertas && d.alertas.length > 0) {
                msg =
                  msg + '\n' + d.produto.codigo + ' - ' + d.produto.descricao;
                d.alertas.forEach(a => {
                  msg = msg + '\n' + a.mensagem;
                });
                msg = msg + '\n';
              }
            });
          }
        }
      }
      if (this.isPessoas()) {
        const { data: dataImportacaoPessoas } = await salvaImportacaoPessoas(
          importData ? importData : []
        );
        if (dataImportacaoPessoas) {
          ok = dataImportacaoPessoas.success;
          if (!ok) {
            msg = 'Mensagem do servidor: \n' + dataImportacaoPessoas.message;
            dataImportacaoPessoas.data.forEach(d => {
              if (d.alertas && d.alertas.length > 0) {
                msg = msg + '\n' + d.pessoa.cnpjCpf + ' - ' + d.pessoa.nome;
                d.alertas.forEach(a => {
                  msg = msg + '\n' + a.mensagem;
                });
                msg = msg + '\n';
              }
            });
          }
        }
      }
      hideWait(this);
      if (ok) {
        showInfo(this, this.getTitulo(), 'Importação realizada com sucesso');
        this.setState({
          importState: ImportState.PreImport
        });
      } else {
        showError(this, this.getTitulo(), msg);
      }
    } catch (e) {
      showSaveError(this, this.getTitulo(), e);
    }
  };

  doCancel = () => {
    this.clearState();
    if (this.state.importState === ImportState.PreImport) {
      this.props.history.push('/configuracoes/carga-inicial');
    }
  };

  clearState = () => {
    this.setState({
      importState: ImportState.PreImport,
      gridData: [],
      colunas: [],
      importData: [],
      arquivoCSV: null
    });
  };

  render() {
    const { importState, gridData, colunas } = this.state;

    return (
      <>
        <LinxPostos
          breadcrumb={
            this.isProdutos()
              ? getNomePagina(PATH_MENU) + ' Produtos'
              : getNomePagina(PATH_MENU) + ' Pessoas'
          }
        >
          <section className='section-container'>
            <SectionContainer>
              <SectionContent title=''>
                <div className='row'>
                  <div className='col-5'>
                    <a
                      className='a'
                      href={this.getArquivoModelo()}
                      download='arquivo_padrão.csv'
                    >
                      {this.isProdutos()
                        ? 'Baixar Arquivo Modelo Produtos'
                        : 'Baixar Arquivo Modelo Pessoas'}
                      <ImgStyle>
                        <img
                          className='style-icon'
                          title='Download arquivo padrão'
                          src={icdownload}
                          alt='Download arquivo padrão'
                          height='35'
                          width='35'
                        />
                      </ImgStyle>
                    </a>
                  </div>
                  <div className='col-5'>
                    {this.state.importState === ImportState.SaveImport ? (
                      <>
                        <div className='Informacao'>
                          Faça a conferência dos dados para prosseguir com a
                          importação.
                        </div>
                      </>
                    ) : (
                      <FileInput
                        name='arquivoCSV'
                        sender={this}
                        label='Selecione o arquivo modelo para importar.'
                        help='Obrigatório'
                        accept='.csv'
                        id='file'
                      />
                    )}
                  </div>
                </div>
              </SectionContent>
              {importState === ImportState.SaveImport ? (
                <SectionContent title='Dados para Conferência'>
                  <div className='table-ctn'>
                    {
                      <ReactTable
                        defaultPageSize={10}
                        previousText='Anterior'
                        nextText='Próximo'
                        loadingText='Carregando registros...'
                        pageText='Página'
                        ofText='de'
                        rowsText='registros'
                        noDataText='Nenhum registro encontrado'
                        data={gridData}
                        columns={colunas}
                      />
                    }
                  </div>
                </SectionContent>
              ) : (
                <></>
              )}
            </SectionContainer>
          </section>
        </LinxPostos>

        <Footer>
          <div className='section-footer'>{this.tagButtons()}</div>
        </Footer>
        {tagAlert(this)}
      </>
    );
  }
}

CargaInicialImportacao = withRouter(CargaInicialImportacao);
export { CargaInicialImportacao };
