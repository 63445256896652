import axios from 'axios';
import { BASE_URL_GATEWAY } from '../../shared/constants/GatewayUrlConstant';
import { getToken } from './../../pages/dashboard/cartoes/DashboardPayHub';

export class UrlLinxCoreService{
    urlLinxCore = null;
    constructor(){
        this.urlLinxCore = axios.create({
            baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
        });

        this.urlLinxCore.interceptors.request.use(async config => {
            const token = getToken();
            if(token){
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        });
    }

    urlBase(arg){
        return this.urlLinxCore
    }
}
