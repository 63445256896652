import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';

export const MovimentacaoTurnoPedidoDinamicoService = {
  find,
  GetColumns
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters, matriz = false) {
  const response = await urlLinxCore.get(`/TurnoPedido/movimentacao-turno-pedido?dataInicio=${new Date(appliedFilters.dataInicio).toISOString()}&dataFim=${appliedFilters.dataFim}&matriz=${matriz}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

function GetColumns(matriz) {
  let colunas = [
    {
      dataField: 'tipoTurno',
      caption: 'Tipo Turno',
      width: '100'
    },
    {
      dataField: 'empresaCnpj',
      caption: 'CNPJ da Empresa',
      width: '130',
      visible: false
    },
    {
      dataField: 'nomeFantasia',
      caption: 'Nome Fantasia',
      width: '200',
    },
    {
      dataField: 'pedidoNumero',
      caption: 'Nº Pedido',
      width: '130',
    },
    {
      dataField: 'operacao',
      caption: 'Operação',
      width: '120',
    },
    {
      dataField: 'dataTurno',
      caption: 'Data do Turno',
      dataType: 'date',
      width: '150'
    },
    {
      dataField: 'dataPedido',
      caption: 'Data do Pedido',
      dataType: 'date',
      width: '150',
    },
    {
      dataField: 'dataEmissao',
      caption: 'Emissão',
      dataType: 'date',
      width: '150',
    },
    {
      dataField: 'criadoPor',
      caption: 'Criado Por',
      width: '150',
    },
    {
      dataField: 'recebidoPor',
      caption: 'Recebido Por',
      width: '150',
    },
    {
      dataField: 'formaPagamento',
      caption: 'Forma de Pagamento',
      minWidth: '130',
    },
    {
      dataField: 'valorPagamento',
      caption: 'Valor R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      totalize: true
    },
    {
      dataField: 'status',
      caption: 'Status',
      width: '150',
    },
    {
      dataField: 'nomeCliente',
      caption: 'Cliente',
      width: '150',
    },
    {
      dataField: 'pagamentoCartaoRede',
      caption: 'Rede cartão',
      width: '130',
      visible: true,
    },
    {
      dataField: 'pagamentoCartaoProduto',
      caption: 'Produto cartão',
      width: '130',
      visible: true,
    },
    {
      dataField: 'pagamentoCartaoBandeira',
      caption: 'Bandeira cartão',
      width: '130',
      visible: true,
    },    
    {
      dataField: 'dataVencimento',
      caption: 'Data do Vencimento',
      dataType: 'date',
      width: '150',
      visible: true,
    },
  ];

  //if(matriz)
  //  colunas[0].groupIndex = 1;

  return colunas;
}

export default urlLinxCore;
