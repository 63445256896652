import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './POSMFe.module.scss';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, {
  Selection,
  Paging,
  GroupPanel,
  ColumnChooser,
  SearchPanel,
  Grouping,
  ColumnFixing,
  Pager
} from 'devextreme-react/data-grid';
import { DropDownBox, TextBox } from 'devextreme-react';
import ptMessages from '../../../../assets/js/pt.json';
import { menus } from '../../../shared/constants/MenuConstants';
import {
  ALPHANUMERIC_WITH_SPACE_PATTERN,
  NUMERIC_PATTERN
} from '../../../shared/constants/Const';
import { getNomePagina, formatPosDtefSerie } from '../../../shared/utils/Utils';
import { posMFeService } from './POSMFe.service';
import notify from 'devextreme/ui/notify';
import { Export } from 'devextreme-react/bar-gauge';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';

export function POSMFe(props) {
  const PATH_MENU = menus.COD_80540;
  const [showSearch] = useState(false);
  const [disabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);

  const [descricao, setDescricao] = useState('');
  const [modelo, setModelo] = useState('');
  const [numeroSerie, setNumeroSerie] = useState('');
  const [codEstabelecimento, setCodEstabelecimento] = useState('');

  const [situacoes, setSituacoes] = useState([]);
  const [selectedSituacoes, setSelectedSituacoes] = useState([]);
  const [gridBoxSituacaoValue, setGridBoxSituacaoValue] = useState([]);

  const [equipamentos, setEquipamentos] = useState([]);
  const [selectedEquipamentos, setSelectedEquipamentos] = useState([]);
  const [gridBoxEquipamentoValue, setGridBoxEquipamentoValue] = useState([]);

  const [adquirentes, setAdquirentes] = useState([]);
  const [selectedAdquirentes, setSelectedAdquirentes] = useState([]);
  const [gridBoxAdquirentesValue, setGridBoxAdquirentesValue] = useState([]);
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState({
    descricao: '',
    numeroSerie: '',
    modelo: '',
    codEstabelecimento: '',
    situacao: [],
    adquirente: [],
    equipamento: []
  });

  const isRestoreVisible = e => {
    let pos = e.row ? e.row.data : {};
    return pos.inativo;
  };

  const isRemoveVisible = e => {
    let pos = e.row ? e.row.data : {};
    return !pos.inativo;
  };

  const editPOSmfe = e => {
    let pos = e.row ? e.row.data : {};

    if (pos.id) {
      props.history.push({
        pathname: '/configuracoes/pos-mfe/detalhes',
        state: {
          data: pos
        }
      });
    }
  };

  const ativarPOS = async e => {
    setSearching(true);
    let pos = e.row ? e.row.data : {};

    if (pos.id) {
      const response = await posMFeService.situacao({
        id: pos.id,
        inativo: false
      });
      if (response.notificationLevel === 1) {
        refreshResult();
        notify('Cadastro ativado com sucesso.', 'info', 2000, 'top');
      } else {
        setSearching(false);
        notify('Não foi possível ativar o cadastro.', 'error', 2000, 'top');
      }
    }
  };

  const inativarPOS = async e => {
    setSearching(true);
    let pos = e.row ? e.row.data : {};

    if (pos.id) {
      const response = await posMFeService.situacao({
        id: pos.id,
        inativo: true
      });
      if (response.notificationLevel === 1) {
        refreshResult();
        notify('Cadastro inativado com sucesso.', 'info', 2000, 'top');
      } else {
        setSearching(false);
        notify('Não foi possível inativar o periférico.', 'error', 2000, 'top');
      }
    }
  };

  const refreshResult = async () => {
    setSearching(true);
    const response = await posMFeService.find(filters);
    if (response.notificationLevel === 1) {
      setResultData(response.data);
    } else {
      notify(response.message, 'error', 2000, 'top');
    }

    setSearching(false);
  };

  const columns = [
    { dataField: 'descricao', caption: 'Descriçãoo' },
    { dataField: 'equipamento.descricao', caption: 'Equipamento' },
    { dataField: 'modelo', caption: 'Modelo' },
    { dataField: 'numeroSerie', caption: 'Número de Série' },
    {
      dataField: 'codigoEstabelecimento',
      caption: 'Código do Estabelecimento'
    },
    { dataField: 'adquirente.nome', caption: 'Adquirente' },
    { dataField: 'situacaoDescricao', caption: 'Situação' },
    {
      type: 'buttons',
      caption: 'Açãoes',
      buttons: [
        {
          hint: 'Alterar informações',
          icon: 'edit',
          visible: true,
          onClick: editPOSmfe
        },
        {
          hint: 'Inativar',
          icon: 'trash',
          visible: isRemoveVisible,
          onClick: inativarPOS
        },
        {
          hint: 'Ativar',
          icon: 'undo',
          visible: isRestoreVisible,
          onClick: ativarPOS
        }
      ]
    }
  ];

  const getEquipamentos = async () => {
    const response = await posMFeService.getEquipamentos();
    if (response.length) setEquipamentos(response);
  };

  const getSituacoes = async () => {
    const response = await posMFeService.getSituacoes();
    if (response.length) setSituacoes(response);
  };

  const getAdquirentes = async () => {
    const response = await posMFeService.getAdquirentes();
    if (response.length) setAdquirentes(response);
  };

  const clearFilters = () => {
    setGridBoxSituacaoValue([]);
    setSelectedSituacoes([]);

    setGridBoxAdquirentesValue([]);
    setSelectedAdquirentes([]);

    setGridBoxSituacaoValue([]);
    setSelectedSituacoes([]);

    setGridBoxEquipamentoValue([]);
    setSelectedEquipamentos([]);

    setDescricao('');
    setModelo('');
    setCodEstabelecimento('');
    setNumeroSerie('');

    setAppliedFilters({
      descricao: '',
      numeroSerie: '',
      modelo: '',
      codEstabelecimento: '',
      situacao: [],
      equipamento: [],
      adquirente: []
    });
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  useEffect(() => {
    getEquipamentos();
    getSituacoes();
    getAdquirentes();
    loadMessages(ptMessages);
    locale(navigator.language);
  }, []);

  const find = async () => {
    setSearching(true);

    if (!appliedFilters)
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');

    let filters = {
      descricao: descricao,
      numeroSerie: formatPosDtefSerie(numeroSerie),
      modelo: modelo,
      codigoEstabelecimento: codEstabelecimento,
      situacoes: [...selectedSituacoes],
      equipamentos: [...selectedEquipamentos],
      adquirentes: [...selectedAdquirentes],
      TipoEquipamentos: [12]
    };
    setFilters(filters);

    const response = await posMFeService.find(filters);
    if (response.notificationLevel === 1) {
      if (response.data.length) {
        if (!response.data.length) {
          notify(
            'Não foram encontrados registros para esta pesquisa.',
            'info',
            2000,
            'top'
          );
        }
        setResultData(response.data);
      } else {
        notify(
          'Não foram encontrados registros para esta pesquisa.',
          'info',
          2000,
          'top'
        );
      }
    } else {
      notify(response.message, 'error', 2000, 'top');
    }
    setSearching(false);
  };

  const clearEquipamentos = () => {
    setGridBoxEquipamentoValue([]);
    setSelectedEquipamentos([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        equipamentos: []
      };
    });
  };

  const clearAdquirentes = () => {
    setGridBoxAdquirentesValue([]);
    setSelectedAdquirentes([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        adquirentes: []
      };
    });
  };

  const clearSituacoes = () => {
    setGridBoxSituacaoValue([]);
    setSelectedSituacoes([]);
    setAppliedFilters(filters => {
      return {
        ...filters,
        situacoes: []
      };
    });
  };

  const syncDataGridSituacoesSelection = e => {
    setGridBoxSituacaoValue(e.value || []);
    clearSituacoes();
  };

  const dataGridSituacoes_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(x => {
      ids.push(x.valor);
    });
    setGridBoxSituacaoValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedSituacoes(ids);
  };

  const dataGridSituacoesRender = () => {
    return (
      <DataGrid
        dataSource={situacoes}

        columns={[{ dataField: 'descricao', caption: 'Situaçõao' }]}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={gridBoxSituacaoValue}
        onSelectionChanged={dataGridSituacoes_onSelectionChanged}
      >
        <Selection mode="multiple" />
      </DataGrid>
    );
  };

  const syncDataGridEquipamentoSelection = e => {
    setGridBoxEquipamentoValue(e.value || []);
    clearEquipamentos();
  };

  const dataGridEquipamento_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(x => {
      ids.push(x.id);
    });
    setGridBoxEquipamentoValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedEquipamentos(ids);
  };

  const dataGridEquipamentoRender = () => {
    return (
      <DataGrid
        dataSource={equipamentos}
        columns={[{ dataField: 'descricao', caption: 'Descriçãoo' }]}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={gridBoxEquipamentoValue}
        onSelectionChanged={dataGridEquipamento_onSelectionChanged}
      >
        <Selection mode="multiple" />
      </DataGrid>
    );
  };

  const syncDataGridAdquirenteSelection = e => {
    setGridBoxAdquirentesValue(e.value || []);
    clearAdquirentes();
  };

  const dataGridAdquirentes_onSelectionChanged = e => {
    let ids = [];
    e.selectedRowKeys.forEach(x => {
      ids.push(x.id);
    });
    setGridBoxAdquirentesValue(
      (e.selectedRowKeys.length && e.selectedRowKeys) || []
    );

    setSelectedAdquirentes(ids);
  };

  const handleValueChange = e => {
    let key = e.event.key;

    let regex = new RegExp(ALPHANUMERIC_WITH_SPACE_PATTERN);
    if (!regex.test(key)) {
      e.event.preventDefault();
      return false;
    }
  };

  const handleValueNumberChange = e => {
    let key = e.event.key;

    let regex = new RegExp(NUMERIC_PATTERN);
    if (!regex.test(key)) {
      e.event.preventDefault();
      return false;
    }
  };

  const dataGridAdquirentesRender = () => {
    return (
      <DataGrid
        dataSource={adquirentes}

        columns={[{ dataField: 'nome', caption: 'Descrição' }]}
        hoverStateEnabled={true}
        showBorders={true}
        showSearch={true}
        height={400}
        selectedRowKeys={gridBoxAdquirentesValue}
        onSelectionChanged={dataGridAdquirentes_onSelectionChanged}
      >
        <Selection mode="multiple" />
        <Paging enabled={false} />
      </DataGrid>
    );
  };

  const resultGrid = () => {
    return (
      <div className={`${styles.gerenciamentoFinanceiroResult} mt-3`}>
        <div className={`${styles.results} shadow-sm p-3 bg-white`}>
          <h2>RESULTADOS</h2>
          <div className="row">
            <div className="col-md-12">
              <hr />
              <DataGrid
                dataSource={resultData}
                defaultColumns={columns}
                showBorders={true}
                allowColumnResizing={true}
                allowColumnReordering={true}
                columnResizingMode={'widget'}
                columnMinWidth={80}
                disabled={searching}
                rowAlternationEnabled={true}
              >
                <GroupPanel visible={true} />
                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                <Export enabled={true} fileName="Perifericos" />
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <Grouping autoExpandAll={true} />
                <ColumnFixing enabled={true} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[5, 15, 20, 50]}
                  showInfo={true}
                />
                <Paging defaultPageSize={15} />
              </DataGrid>
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
      <div className={`${styles.posMFeFilters}`}>
        <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={true} texto="Novo Cadastro" newURL={"/configuracoes/pos-mfe/detalhes"} />

        <div className="shadow-sm bg-white p-3">
          <div className={`${showSearch ? styles.search : ''}`}>
            <div className="container-fluid">
              <div className={`${styles.filtersrWrapper} row`}>
                <div className="col-md-12">
                  <h2>CONFIGURAÇÃO POS MFE</h2>
                  <hr />

                  <div
                    className={`${showSearch ? styles.fadeIn : ''} ${
                      styles.searchWrapper
                    }`}
                  >
                    <div className="row">
                      <div
                        className={`${styles.dropDownBox} col-md-6 form-group mb-1`}
                      >
                        <span>Descrição</span>
                        <TextBox
                          placeholder="Informe a descrição do equipamento para pesquisar"                        
                          showClearButton={true}
                          onValueChanged={e => setDescricao(e.value)}
                          value={descricao}
                          onKeyPress={e => handleValueChange(e)}
                          disabled={searching}
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-1`}
                      >
                        <span>Equipamento</span>
                        <DropDownBox
                          value={gridBoxEquipamentoValue.map(
                            item => item.descricao
                          )}
                          valueExpr="marca"
                          deferRendering={false}
                          displayExpr="descricao"
                          placeholder="Selecione..."
                          showClearButton={true}
                          disabled={searching}
                          dataSource={equipamentos}
                          onValueChanged={syncDataGridEquipamentoSelection}
                          contentRender={dataGridEquipamentoRender}
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-1`}
                      >
                        <span>Modelo</span>
                        <TextBox
                          placeholder="Informe o modelo do equipamento"
                          showClearButton={true}
                          onValueChanged={e => setModelo(e.value)}
                          value={modelo}
                          onKeyPress={e => handleValueChange(e)}
                          disabled={searching}
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <span>Número de Série</span>
                        <TextBox
                          placeholder="Informe o Número de Série"
                          showClearButton={true}
                          onValueChanged={e => setNumeroSerie(e.value)}
                          mask="X00-000-000"
                          maskRules={{ X: /[0-9]/ }}
                          value={numeroSerie}
                          disabled={searching}
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <span>Código do estabelecimento</span>
                        <TextBox
                          placeholder="Informe o Código do estabelecimento"
                          showClearButton={true}
                          onValueChanged={e => setCodEstabelecimento(e.value)}
                          value={codEstabelecimento}
                          onKeyPress={e => handleValueNumberChange(e)}
                          disabled={searching}
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <span>Adquirente</span>
                        <DropDownBox
                          value={gridBoxAdquirentesValue.map(item => item.nome)}
                          valueExpr="arquirente"
                          deferRendering={false}
                          displayExpr="nome"
                          placeholder="Selecione..."
                          showClearButton={true}
                          disabled={searching}
                          dataSource={adquirentes}
                          onValueChanged={syncDataGridAdquirenteSelection}
                          contentRender={dataGridAdquirentesRender}
                        />
                      </div>
                      <div
                        className={`${styles.dropDownBox} col-md-3 form-group mb-2 mt-2`}
                      >
                        <span>Situação</span>
                        <DropDownBox
                          value={gridBoxSituacaoValue.map(
                            item => item.descricao
                          )}
                          valueExpr="marca"
                          deferRendering={false}
                          displayExpr="descricao"
                          placeholder="Selecione..."
                          showClearButton={true}
                          disabled={searching}
                          dataSource={situacoes}
                          onValueChanged={syncDataGridSituacoesSelection}
                          contentRender={dataGridSituacoesRender}
                        />
                      </div>
                    </div>

                    <footer className="text-right mt-4">
                      {/* <button className="btn btn-default"> Salvar relatório </button> */}
                      <button
                        className="btn btn-light"
                        onClick={clearFilters}
                        disabled={searching}
                      >
                        {' '}
                        Limpar filtros{' '}
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={find}
                        disabled={disabledSearch || searching}
                      >
                        {' '}
                        {searching ? (
                          <>
                            <svg className={styles.spinner} viewBox="0 0 50 50">
                              <circle
                                className={styles.path}
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                              ></circle>
                            </svg>
                            PESQUISANDO...
                          </>
                        ) : (
                          `PESQUISAR`
                        )}
                      </button>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {resultData.length > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}

export default withRouter(POSMFe);
