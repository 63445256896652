import { DateBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { ConfiguracoesGeraisService } from '../../../../services/configuracoesGerais/ConfiguracoesGerais.service';

export function PagamentoVencimento({ newPayment, setNewPayment, ignoreParameter = false, allowRetro = false, startWithCurrentDate = false, isBoleto = false }) {
  const [today] = React.useState(new Date());
  const [tomorrow] = React.useState(new Date(new Date().setDate(new Date().getDate() +1)));
  const [parametro518, setParametro518] = useState(0);
  const [parametro515, setParametro515] = useState('7;14;28');


  useEffect(() => {
    if (startWithCurrentDate && newPayment.dataVencimento) {
      return;
    }
    ConfiguracoesGeraisService.getInstance()
      .byId(518)
      .then(data => {
        setParametro518(data.valor);
      })
      .catch(console.error);
    ConfiguracoesGeraisService.getInstance()
      .byId(515)
      .then(data => {
        setParametro515(data.valor);
      })
      .catch(console.error);
    if (startWithCurrentDate) {
      setNewPayment({ ...newPayment, dataVencimento: new Date() });
    }
  }, [newPayment, setNewPayment, startWithCurrentDate, today]);

  const transformDate = days => {
    const date = new Date(new Date().getTime() + days * 24 * 60 * 60 * 1000);
    date.setHours(0, 0, 0, 0);
    return date.toISOString(); //asdasd
  };

  return (
    <>
      <Form.Label className="text-primary" htmlFor="dataVencimento">
        Data Vencimento<span className="text-danger">*</span>
      </Form.Label>
      {(parametro518 || '').toString() === '1' && parametro515 && !ignoreParameter ? (
        <select
          className="form-control"
          onChange={e => {
            if (e.target.value === '-1') {
              setNewPayment({ ...newPayment, dataVencimento: null });
              return;
            }
            setNewPayment({ ...newPayment, dataVencimento: new Date(e.target.value) });
          }}
          value={newPayment.dataVencimento && (newPayment.dataVencimento instanceof Date) ? newPayment.dataVencimento.toISOString() : -1}
        >
          <option value={-1}>Selecione um vencimento</option>
          {parametro515.split(';').map((item, idx) => {
            return (
              <option key={idx} value={transformDate(item)}>
                {item} Dia{item > 1 ? 's' : ''} - {new Date(transformDate(item)).toLocaleDateString()}
              </option>
            );
          })}
        </select>
      ) : (
        <DateBox
          defaultValue={new Date(today.getFullYear(), today.getMonth(), today.getDay()).toISOString()}
          id="dataVencimento"
          type="date"
          min={allowRetro ? null : (isBoleto ? tomorrow.toISOString() : today.toISOString())}
          height={40}
          onValueChange={e => {
            setNewPayment({ ...newPayment, dataVencimento: e });
          }}
          value={newPayment.dataVencimento}
          useMaskBehavior={true}
          displayFormat="dd/MM/yyyy"
        />
      )}
    </>
  );
}
