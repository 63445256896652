import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

export class ApiFaturarVTexService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(id) {
    try {
      const response = await this.urlLinxCore.post(`/vtex/set-invoice/${id}`);
      return response.data;
    } catch (error) {
      console.log('error', error);
      await new AxiosErrorService().execute(error);
    }
  }
}
