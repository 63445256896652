import { LoadPanel } from 'devextreme-react';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { locale } from 'devextreme/localization';
import { withRouter } from 'react-router-dom';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import { useHistory } from 'react-router-dom';
import { HorusBiService } from '../../services/horus-bi/HorusBi.service';
import Swal from 'sweetalert2';
import { FuncionarioService } from '../../services/funcionarios/Funcionario.service';

export function DashboardMain(props) {
  locale('pt-BR');
  const history = useHistory();
  const [searching, setSearching] = useState(true);
  const [searchingMessage, setSearchingMessage] = useState('Carregando Pedidos...');

  useEffect(() => {
    setSearching(true);
    setSearchingMessage('Verificando permissões...');
    const doEffect = async () => {
      try {
        var permissao = await FuncionarioService.getInstance().recuperarAcessosPorMenuId(40001);
        if (!(permissao || []).find(x => x.codigoOperacao === 50)) {
          setSearching(false);
          history.push('/dashboard/vazio');
          return;
        }
        var url = await HorusBiService.getInstance().token();
        setSearching(false);
        if (url) {
          history.push('/dashboard/horusbi');
          return;
        }
        history.push('/dashboard/gerencial');
      } catch (error) {
        setSearching(false);
        await Swal.fire({
          title: 'Erro',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
        history.push('/dashboard/gerencial');
      }
    };
    doEffect().then();
  }, [history]);

  return (
    <LinxPostos breadcrumb={'Dashboard'} id="page">
      <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={searchingMessage} showPane={true} />
      <Card>
        <Card.Body>Carregando...</Card.Body>
      </Card>
    </LinxPostos>
  );
}

export default withRouter(DashboardMain);
