import axios from 'axios';

import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";
import { getDadosLogin, getUrl } from "../../../shared/utils/Utils";
import { UrlLinxCoreService } from '../../../services/core/urlLinxCore.service';
import { AxiosErrorService } from '../../../services/core/axiosError.service';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export const AjudaService = {
  getBackEndVersion,
  getBackEndSalesforceUrl,
  getPortalFinanceiroUrl,
}

async function getBackEndVersion()  {
  const response = await axios.get('/LxApi/v1/version');
  return response.data;
}

async function getBackEndSalesforceUrl(cnpj)  {
  const response = await axios.get('/LxApi/v1/salesforceUrl/',
  {

    params: { cnpj }

  });
  return response.data;
}
async function getPortalFinanceiroUrl()  {
    try {
      //debugger;
      var urlLinxCore = new UrlLinxCoreService().urlBase();
      var dadosLogin = getDadosLogin();
      const response = await urlLinxCore.post(`/Ajuda/url-portal-financeiro`, dadosLogin.empresas);
      return response.data.item;      
    } catch (error) {
      new AxiosErrorService().execute(error);
    }
}