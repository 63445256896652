import React, { useCallback, useEffect } from 'react';
import { selectFiltroOs, useFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButton } from './ToggleButton/ToggleButton';

export function ButtonOpType({ typeName, propertyName = 'status', className, label, statusId, buttonClass = "" }) {
  const { changeValue } = useFiltroOs();
  const { values } = useSelector(selectFiltroOs);
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);

  let customStyle = {
    backgroundColor: '#fff',
  };

  useEffect(() => {
    if (!values[propertyName]) return;
    if (values[propertyName].find(x => x === statusId)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [statusId, values, propertyName]);

  const onChange = useCallback(
    e => {
      var status = values[propertyName] ? [...values[propertyName]] : [];
      if (e) {
        const idx = status.indexOf(statusId);
        if (idx === -1) {
          status.push(statusId);
        }
      } else {
        const idx = status.indexOf(statusId);
        if (idx !== -1) {
          status.splice(idx, 1);
        }
      }
      setChecked(e);
      dispatch(changeValue({ fieldName: propertyName, value: status }));
    },
    [dispatch, changeValue, statusId, propertyName, values],
  );
  return (
    // <div className={className}>
    //   <input type="checkbox" checked={checked} onChange={onChange} />
    //   <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    // </div>
    <ToggleButton
      className={className}
      buttonClass={buttonClass}
      style={customStyle}
      typeName={typeName}
      label={`${label}`}
      value={checked}
      onCheckChange={onChange}
      currentCheckedStatus={checked}
    ></ToggleButton>
    // <button className={`${className} btn ${styles[`bg-${statusCss.replace('color-', '')}-${'unchecked'}`]}`} onClick={onChange} checked={checked}>
    //   <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    // </button>
  );
}
