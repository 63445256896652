import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { handleInputChange } from '../../../components/formulario/Formulario';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import { ativacaoCompletaUsuario } from './BoasVindas.service';
import { buscaUrl } from './BoasVindas.service';
import logoSeller from '../../../../assets/images/linx/logo-seller-new.png';
import logoEmporio from '../../../../assets/images/linx/logo-emporio-new.png';
import SuporteButton from '../../../components/Suporte/SuporteButton/SuporteButton';
import styles from '../login/Login.module.scss';
import notify from 'devextreme/ui/notify';
import { MAX_LENGTH_PASSWORD } from '../../../shared/constants/Const';
import {
  sleep,
  getSystemInfoByTipoSite,
  TipoSiteOption
} from '../../../shared/utils/Utils';
import FacebookButton from '../../../components/botao/FacebookButton/FacebookButton';
import TwitterButton from '../../../components/botao/TwitterButton/TwitterButton';
import LinkedinButton from '../../../components/botao/LinkedinButton/LinkedinButton';
import YouTubeButton from '../../../components/botao/YouTubeButton/YouTubeButton';
import InstagramButton from '../../../components/botao/InstagramButton/InstagramButton';
let logoSite;
if (TIPO_SITE == 'RetaguardaSellerWeb'){
  logoSite = logoSeller;
}else{
  logoSite  = logoEmporio;
}

class BoasVindas extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const { nome } = this.props;

    this.state = {
      nome,
      systemInfo: {}
    };
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    var chave = params.chave;
    var email = params.email;
    var cnpj = params.cnpj;
    const systemInfo = getSystemInfoByTipoSite();

    this.setState({ chave, email, cnpj, systemInfo: systemInfo });
  }

  ativarUsuario = async e => {
    const getErrorMessage = error => {
      let msg = 'Falha ao ativar o usuário.';
      if (error && error.response && error.response.data) {
        let errorMsg = error.response.data.message;
        if (String(errorMsg).includes('Registro não encontrado')) {
          errorMsg = 'Chave inutilizada.';
        }
        msg += ' ' + errorMsg;
      }
      return msg;
    };
    e.preventDefault();

    this.setState({ ativando: true });
    try {
      const { senha, confirmeSenha, email, cnpj, chave } = this.state;
      if (senha === confirmeSenha) {
        let urlBase = '';
        try {
          const respURL = await buscaUrl(cnpj);
          if (respURL) {
            urlBase = respURL.data.data.url;
          }
        } catch (e) {
          notify(getErrorMessage(e), 'error', 5000);
        }
        try {
          const respAtivacao = await ativacaoCompletaUsuario(
            email,
            senha,
            chave,
            urlBase
          );
          if (respAtivacao && respAtivacao.data) {
            notify('Usuário ativado com sucesso', 'info', 5000);
            await sleep(3000);
            this.props.history.push('/');
          } else {
            notify('Falha ao ativar o usuário', 'error', 5000);
          }
        } catch (e) {
          notify(getErrorMessage(e), 'error', 5000);
        }
      } else {
        notify('Senhas não conferem', 'error', 5000);
      }
    } finally {
      this.setState({ ativando: false });
    }
  };

  disablePasswordFields = () => {
    return this.state.loggingIn || this.state.connecting || this.state.ativando;
  };

  render() {
    const { email, senha, confirmeSenha } = this.state;

    return (
      <div className={styles.Login}>
        <div className={`container-fluid ${styles.pageLogin}`}>
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div
                  className={`col-xs-3 col-md-3 col-sm-12 ${
                    TIPO_SITE === TipoSiteOption.RetaguardaPostoPop
                      ? styles.bgPrimary
                      : styles.bgPrimaryEmporio
                  } ${styles.columnHeight}
                  ${styles.columnHeightA} d-flex align-items-center`}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <h1 className={styles.textBrandLinx} title="Linx">
                          Linx
                        </h1>
                        <h2
                          className={`${styles.textBrandProduct} text-uppercase text-truncate`}
                        >
                          {this.state.systemInfo.nome}
                        </h2>
                        <h2
                          className={`${styles.textBrandScreen} text-uppercase text-truncate`}
                        >
                          ATIVAÇÃO DE USUÁRIO
                        </h2>
                      </div>
                      <p className={styles.copyright}>
                        {' '}
                        &copy; Linx - Todos direitos reservados
                      </p>
                    </div>
                    <div className="row">
                      <div
                        className={`col-12 justify-content-center ${styles.midiasSociais}`}
                      >
                        <FacebookButton />
                        <TwitterButton />
                        <LinkedinButton />
                        <YouTubeButton />
                        <InstagramButton />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className={`${styles.columnHeight} ${styles.columnHeightB} d-flex align-items-center`}
                  >
                    <div className="row">
                      <div className="col-12">
                        <picture className="text-center logotipo center-align">
                          <source media="(max-width: 768px)" srcSet={logoSite} />
                          <source srcSet={logoSite} />
                          <img srcet={logoSite} alt="Logo Linx" />
                        </picture>
                      </div>
                      <form
                        className={styles.formLogin}
                        autoComplete="off"
                        onSubmit={e => this.ativarUsuario(e)}
                      >
                        <div className="form-group">
                          <label htmlFor="email">Usuário</label>
                          <input
                            disabled
                            value={email}
                            type="email"
                            id="email"
                            name="email"
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                            className="form-control"
                            placeholder="Usuário"
                            autoFocus
                            required
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Senha</label>
                          <input
                            type="password"
                            id="senha"
                            name="senha"
                            value={senha}
                            onChange={e =>
                              this.setState({ senha: e.target.value })
                            }
                            className="form-control"
                            placeholder="Senha"
                            required
                            disabled={this.disablePasswordFields()}
                            maxLength={MAX_LENGTH_PASSWORD}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="password">Confirme a senha</label>
                          <input
                            type="password"
                            id="confirmeSenha"
                            name="confirmeSenha"
                            value={confirmeSenha}
                            onChange={e =>
                              this.setState({ confirmeSenha: e.target.value })
                            }
                            className="form-control"
                            placeholder="Senha"
                            required
                            disabled={this.disablePasswordFields()}
                            maxLength={MAX_LENGTH_PASSWORD}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-secondary btn-block"
                          disabled={this.state.ativando}
                        >
                          {this.state.ativando ? (
                            <>
                              <svg
                                className={styles.spinner}
                                viewBox="0 0 50 50"
                              >
                                <circle
                                  className={styles.path}
                                  cx="25"
                                  cy="25"
                                  r="20"
                                  fill="none"
                                  strokeWidth="5"
                                ></circle>
                              </svg>
                              ATIVANDO
                            </>
                          ) : (
                            'ATIVAR'
                          )}
                        </button>
                      </form>
                    </div>
                    <SuporteButton />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BoasVindas = withRouter(BoasVindas);
export { BoasVindas };
