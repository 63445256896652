import axios from 'axios';
import { getToken } from '../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

export const movimentacaoEncerrantesSservice = {
    getMovimentacaoEncerrantesPorTurno,
    getAfericoesPorTurno
};

const urlLinxCore = axios.create({
    baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
});

urlLinxCore.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

async function getMovimentacaoEncerrantesPorTurno(dataInicioTurno) {
    const response = await urlLinxCore.post('/TurnoEncerrante/ListarPorTurno', {
        query: { 
            dataInicioTurno: dataInicioTurno
        } 
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return error;
    })
    return response;
}

async function getAfericoesPorTurno(codigoBico, turnoMacro) {
    const response = await urlLinxCore.post('/Turno/ListarAfericoesPorTurno', {
        query: { 
            codigoBico: codigoBico,
            turnoMacro: turnoMacro
        } 
    })
    .then(response => {
        return response.data;
    })
    .catch(error => {
        return error;
    })
    return response;
}