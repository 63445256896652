import { LoadPanel } from 'devextreme-react';
import { GroupItem, Summary, TotalItem } from 'devextreme-react/data-grid';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import ptMessages from '../../../../../assets/js/pt.json';
import DateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import FilterPanel from '../../../../components/Report/FilterPanel/FilterPanel';
import ResultPanel from '../../../../components/Report/ResultPanel/ResultPanel';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import DateToolTip from '../../../../components/dateTooltip/DateToolTip';
import ListToolTip from '../../../../components/listToolTip/ListToolTip';
import { reportMangerService } from '../../../../services/ReportManager.service';
import { NotificationLevel } from '../../../../shared/constants/Const';
import { menus } from '../../../../shared/constants/MenuConstants';
import { TIPO_SITE } from '../../../../shared/constants/TipoSiteConstant';
import {
  getDadosLogin,
  getNomePagina,
  getNomePaginaAmigavel
} from '../../../../shared/utils/Utils';
import styles from './VendasCombustivelPremmia.module.scss';
import { vendasCombustivelPremmiaService } from './VendasCombustivelPremmia.service';

var valorVendaLinhas = 0;
var valorMargemLinhas = 0;
var valorVendaFoot = 0;
var valorMargemFoot = 0;
const calculateCustomSummary = (options) => {
  if(options.name === "margemPerc") {
    switch (options.summaryProcess)
    {
      case "start":
        valorVendaLinhas = 0;
        valorMargemLinhas = 0;
        options.totalValue = 0;
      break;
      case "calculate":
        valorVendaLinhas += parseFloat(options.value.vendaLiquida.toFixed(4));
        valorMargemLinhas += parseFloat(options.value.margemValor.toFixed(4));
      break;
      case "finalize":
        options.totalValue = ((valorMargemLinhas/(valorVendaLinhas == 0 ? 1 : valorVendaLinhas)) * 100).toFixed(2);
      break;
      default:

        break
    }
  }
  else
  {
    if(options.name === "margemFooter")
    {
      switch (options.summaryProcess)
      {
        case "start":
          valorVendaFoot = 0;
          valorMargemFoot = 0;
          options.totalValue = 0;
        break;
        case "calculate":
          valorVendaFoot += parseFloat(options.value.vendaLiquida.toFixed(4));
          valorMargemFoot += parseFloat(options.value.margemValor.toFixed(4));
        break;
        case "finalize":
          options.totalValue = ((valorMargemFoot/(valorVendaFoot == 0 ? 1 : valorVendaFoot)) * 100).toFixed(2);
        break;
        default:

        break
      }
    }
  }
}

export function VendasCombustivelPremmia() {
  const PATH_MENU = menus.COD_16196;
  const CodigoFuncionalidade = 80700;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({
    cnpj: [],
    dataInicio: startDate,
    dataFim: endDate,
  });

  config({
    defaultCurrency: 'BRL'
  });


  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, startDate, endDate]);

  useEffect(() => {
    const colunas = vendasCombustivelPremmiaService.GetColumns();
    setColumns(colunas);

    async function GetReports() {
      const result = await reportMangerService.Get(CodigoFuncionalidade);

      if (result.notificationLevel === NotificationLevel.Success) {
        let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

        setServerReports(reports);
      }
    }

    GetReports();
  }, []);

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }
    var dadosLogin = getDadosLogin();

    setFilters({
      cnpj: dadosLogin.cnpj,
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
    });
    await request({
      cnpj: dadosLogin.cnpj,
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
    });

    updateReportInfo(chosenReport);
  };

  const request = async model => {

    setSearching(true);

    try {
      const response = await vendasCombustivelPremmiaService.find(model);

        if (!response.data.length) {
          notify(
            'Não foram encontrados registros para esta pesquisa.',
            'info',
            2000,
            'top'
          );
          setShowResult(false);

        }
        else{
          setResultData(response.data);
          setShowResult(true);

        }

    } catch (error) {
      notify(
        'Ocorreu um erro intern. Já estamos atuando.',
        'error',
        2000,
        'top'
      );

    }
    finally {
      setSearching(false);
    }
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);

    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    refreshColumns(report.data.colunas);

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim,
      multiEmpresa: true,
    };

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const refreshColumns = newColumns => {
    let refreshedColuns = reportMangerService.RefreshColumns(
      columns,
      newColumns
    );
    setColumns(refreshedColuns);
  };

  const reportHandle = report => {
    setChosenReport(report);

    if (report.tipo === 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59'
              : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00'
        };
      });
      setDisabledSearch(false);
    }
  };

  const onSelectionChanged = ({ selectedRowsData }) => {
    let ids = [];

    selectedRowsData.forEach(produto => {
      if (!appliedFilters.produtos.includes(produto.id)) {
        ids.push(produto.id);
      }
    });
  };

  const clearFilters = () => {
    setAppliedFilters({
      cnpj: [],
      dataInicio: new Date().toISOString(),
      dataFim: new Date().toISOString(),
    });

    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const updateReportInfo = report => {
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
      refreshColumns(vendasCombustivelPremmiaService.GetColumns());
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro')
          reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        //setSubServerReports(report[0].subReport);

        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="vendas"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        summary={() => (
          <Summary calculateCustomSummary={calculateCustomSummary}>
            <TotalItem
              column="valorPagamentoPremmia"
              summaryType="sum"
              valueFormat={{ precision: 2 }}
            />
            <TotalItem
              column="precoUnitario"
              summaryType="sum"
              valueFormat={{ precision: 2 }}
            />
            <TotalItem
              column="desconto"
              summaryType="sum"
              valueFormat={{ precision: 2 }}
            />
            <GroupItem
              column="valorPagamentoPremmia"
              summaryType="sum"
              valueFormat={{ type: 'currency', precision: 2 }}
              showInGroupFooter={false}
              alignByColumn={true}
            />
            <GroupItem
              column="precoUnitario"
              summaryType="sum"
              valueFormat={{ type: 'currency', precision: 2 }}
              showInGroupFooter={false}
              alignByColumn={true}
            />
            <GroupItem
              column="desconto"
              summaryType="sum"
              valueFormat={{ type: 'currency', precision: 2 }}
              showInGroupFooter={false}
              alignByColumn={true}
            />
          </Summary>
        )}
      >
        <DateToolTip inicial={filters.dataInicioTurno} final={filters.dataFimTurno} />
        <ListToolTip/>

      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <FilterPanel
        onSearch={find}
        clearFilters={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox
                  type="date"
                  id="dataFim"
                  date={endDate}
                  disabled={searching}
                  value={appliedFilters.dataFim}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
        </div>
      </FilterPanel>
      {
        showResult > 0 ? resultGrid() : <></>
      }
    </LinxPostos>
  );
}
