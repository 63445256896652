import { Button, DateBox, SelectBox, TextBox } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TurnoPedidoService } from '../../../../../../services/turno-pedido/TurnoPedido.service';
import { getFilterLabel } from './FilterUtils';
import { SelectOperadores } from './SelectOperadores';

/**
 * FiltroTurno props.
 * @typedef {Object} FiltroTurnoProps
 */

/**
 * A componente to filter turn.
 * @param {FiltroTurnoProps} props - Propriedades do componente
 * @returns {React.Component} O componente FiltroTurno.
 */
export const FiltroTurno = forwardRef((props, ref) => {
  const situacoes = ['TODOS', 'PENDENTE', 'CONFERIDO'];
  const dataInicioRef = useRef(null);
  const dataFimRef = useRef(null);
  const operadorRef = useRef(null);
  const situacaoRef = useRef(null);
  const pedidoRef = useRef(null);

  useImperativeHandle(ref, () => ({
    /**
     * Retorna os valores atuais do filtro.
     *
     * @returns {Object} Objeto contendo os valores dos filtros.
     * @property {Date} dataInicio - Data de início selecionada no filtro.
     * @property {Date} dataFim - Data final selecionada no filtro.
     * @property {string} operador - Operador selecionado no filtro.
     * @property {string} situacao - Situação selecionada no filtro.
     * @property {string} maquina - Máquina selecionada no filtro.
     * @property {string} notaFiscal - Nota fiscal inserida no filtro.
     */
    getFilterValues: () => ({
      dataInicio: (props.filter || {}).dataInicio,
      dataFim: (props.filter || {}).dataFim,
      op: (props.filter || {}).op,
      situacao: (props.filter || {}).situacao,
      pedido: (props.filter || {}).pedido,
    }),
  }));

  const handleFilter = useCallback(async () => {
    if (typeof props.onBusy === 'function') props.onBusy('Buscando turnos...');
    try {
      const data = await TurnoPedidoService.getInstance().listar(
        (props.filter || {}).dataInicio,
        (props.filter || {}).dataFim,
        (props.filter || {}).op,
        (props.filter || {}).situacao,
        (props.filter || {}).pedido,
        false,
      );
      if (typeof props.onFilter === 'function') {
        props.onFilter(data);
      }
      if (typeof props.onBusy === 'function') props.onUnBusy();
    } catch (e) {
      if (typeof props.onBusy === 'function') props.onUnBusy();
      alert(`${e.message}`, 'Ops!');
      return;
    }
  }, [props]);

  const handleClear = () => {
    if (props && typeof props.onCleaned === 'function') props.onCleaned();
  };
  if (!props.filter) {
    return null;
  }
  const filterLabel = getFilterLabel(
    (props.filter || {}).dataInicio,
    (props.filter || {}).dataFim,
    ((props.filter || {}).op || []).map(x => x.nome).join(', '),
    (props.filter || {}).situacao,
    (props.filter || {}).pedido,
  );

  return (
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">Filtrar Turnos</h5>
        <Row className="mb-0 pb-0">
          <Col sm={4} xl={2}>
            <div className="form-group">
              <label>De:</label>
              <DateBox
                type="date"
                placeholder="De"
                displayFormat="dd/MM/yyyy"
                ref={dataInicioRef}
                value={(props.filter || {}).dataInicio}
                onValueChanged={e => {
                  if (props && typeof props.onFilterChange === 'function') {
                    props.onFilterChange({ dataInicio: e.value });
                  }
                }}
              />
            </div>
          </Col>
          <Col sm={4} xl={2}>
            <div className="form-group">
              <label>Até:</label>
              <DateBox
                type="date"
                placeholder="Até"
                displayFormat="dd/MM/yyyy"
                ref={dataFimRef}
                value={(props.filter || {}).dataFim}
                onValueChanged={e => {
                  if (props && typeof props.onFilterChange === 'function') {
                    props.onFilterChange({ dataFim: e.value });
                  }
                }}
              />
            </div>
          </Col>
          <Col sm={4} xl={4}>
            <div className="form-group">
              <label>Operador:</label>
              <SelectOperadores
                onChanged={e => {
                  if (props && typeof props.onFilterChange === 'function') {
                    props.onFilterChange({ op: e });
                  }
                }}
                value={((props.filter || {}).op || []).map(x => x.id)}
                ref={operadorRef}
              ></SelectOperadores>
            </div>
          </Col>
          <Col sm={4} xl={2}>
            <div className="form-group">
              <label>Situação:</label>
              <SelectBox
                items={situacoes}
                placeholder="Selecione uma situação"
                ref={situacaoRef}
                value={(props.filter || {}).situacao}
                onValueChanged={e => {
                  if (props && typeof props.onFilterChange === 'function') {
                    props.onFilterChange({ situacao: e.value });
                  }
                }}
              />
            </div>
          </Col>

          <Col sm={4} xl={2}>
            <div className="form-group">
              <label>Nº Pedido:</label>
              <TextBox
                mode="number"
                placeholder="Número do Pedido"
                value={(props.filter || {}).pedido}
                ref={pedidoRef}
                onValueChanged={e => {
                  if (props && typeof props.onFilterChange === 'function') {
                    props.onFilterChange({ pedido: e.value });
                  }
                }}
              />
            </div>
          </Col>
          <Col sm={12} className="mt-0 d-flex justify-content-between align-items-center">
            <h6>{filterLabel}</h6>
            <div>
              <Button variant="secondary" className="p-2" onClick={handleClear}>
                &nbsp;<i className="fas fa-trash"></i>&nbsp;LIMPAR FILTRO&nbsp;&nbsp;
              </Button>
              <Button variant="primary" className="p-2 ml-2 btn-primary" onClick={handleFilter}>
                &nbsp;<i className="fas fa-filter"></i>&nbsp;APLICAR FILTRO&nbsp;&nbsp;
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
});
