import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LinxPostos from "../../../components/core/linxPostos/LinxPostos";

import Botao from "../../../components/botao/Botao";

import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import { menuPermiteAcesso, getNomePagina } from '../../../shared/utils/Utils';
import { menus } from '../../../shared/constants/MenuConstants';
import ScreenHeader from "../../../components/header/ScreenHeader/ScreenHeader";


const PATH_MENU = menus.COD_60990;

export const Modo = {
  None: 0,
  Pessoas: 1,
  Produtos: 2
};

class CargaInicialHub extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={false} />
        <SectionContainer>
          <SectionContent title="">
            <div className="row">
              <div className="col-6">
                {menuPermiteAcesso("/configuracoes/carga-inicial-produtos") ? (
                  <Botao
                    title="Produtos"
                    cargaInicial
                    img="icon-lx-cart"
                    secondary
                    onClick={() => {
                      this.props.history.push(
                        "/configuracoes/carga-inicial/produtos"
                      );
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className="col-6">
                {menuPermiteAcesso("/cargaInicial/pessoas/") ? (
                  <Botao
                    title="Pessoas"
                    cargaInicial
                    img="icon-lx-shirt"
                    onClick={() => {
                      this.props.history.push(
                        "/configuracoes/carga-inicial/pessoas"
                      );
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </SectionContent>
        </SectionContainer>
      </LinxPostos>
    );
  }
}

CargaInicialHub = withRouter(CargaInicialHub);
export { CargaInicialHub };
