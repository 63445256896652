import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Checkbox,
  Checkitem,
  // FormOptions,
  handleInputChange,
  Select
} from '../../../components/formulario/Formulario';
// import Loader from '../../../components/loader/Loader'
// import Header from '../../../components/header/Header';
import Footer from '../../../components/core/footer/Footer';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import List, { sortInt } from '../../../components/formulario/List';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import { Alert } from '../../../components/alert/Alert';

import { withRouter } from 'react-router-dom';
import SectionHeader from '../../../components/section/Header';

import {
  getListAlmoxarifado,
  // getAlmoxarifado,
  incluirAlmoxarifado,
  alterarAlmoxarifado,
  excluirAlmoxarifado,
  restaurarAlmoxarifado
} from './Deposito.service';
import {
  menuPermiteInclusao,
  // menuPermiteAlteracao,
  // menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  menuPermiteAlteracao,
  menuPermiteExclusao
} from '../../../shared/utils/Utils';

import { menus } from '../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60640;

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      codigoAlmoxarifado = null,
      numeroAlmoxarifado = null,
      descricao = '',
      tipo = '0',
      excluido = false,
      inativo = false,
      rowVersion = null
    } = this.props.almoxarifado;

    this.state = {
      codigoAlmoxarifado,
      numeroAlmoxarifado,
      descricao,
      tipo,
      excluido, // exclusao logica
      ativo: !inativo, // indica se esta ativo/inativo
      rowVersion, // TODO: entender melhor funcionamento

      alertActive: false, // se o alert deve ser apresentado
      alertType: '', // tipo de alert (sucesso, erro...)
      alertTitle: '', // titulo do alert
      alertSubtitle: '', // subtitulo/mensagem do alert

      almoxarifadosExistentes: []
    };
  }

  componentDidMount() {
    this.setState({ almoxarifadosExistentes: this.props.existentes });
  }

  handleAlertAction = async resp => {
    const { alertType, codigoAlmoxarifado } = this.state;

    this.setState({
      alertActive: false,
      alertType: '',
      alertTitle: '',
      alertSubtitle: ''
    });

    switch (alertType) {
      case 'success':
        this.props.history.push('/estoque/depositos/');
        break;

      case 'question':
        if (resp) {
          try {
            await excluirAlmoxarifado(codigoAlmoxarifado);
            //sucesso
            this.showInfo('Almoxarifado excluído com sucesso!');
          } catch (err) {
            // falha
            this.showError(err.response.data.message);
          }
        }
        break;

      case 'cancel':
        if (resp) {
          this.props.history.push('/estoque/depositos');
        }
        break;

      case 'recreate':
        if (resp) {
          try {
            await restaurarAlmoxarifado(codigoAlmoxarifado);
            //sucesso
            this.showInfo('Almoxarifado restaurado com sucesso!');
          } catch (err) {
            // falha
            this.showError(err.response.data.message);
          }
        }
        break;

      default:
        break;
    }
  };

  showError = message => {
    this.setState({
      alertActive: true,
      alertType: 'error',
      alertTitle: 'Erro',
      alertSubtitle: message
    });
  };

  showInfo = message => {
    this.setState({
      /* numeroAlmoxarifado: "",
            rowVersion: null,
            descricao: "",
            ativo: false,
            tipo: "1",
            excluido: false,*/

      alertActive: true,
      alertType: 'success',
      alertTitle: 'Almoxarifado',
      alertSubtitle: message
    });
  };

  handleSalvar = async () => {
    const {
        codigoAlmoxarifado,
        numeroAlmoxarifado,
        descricao,
        ativo,
        tipo,
        rowVersion
      } = this.state,
      inativo = !ativo;
    const [action, params] =
      rowVersion !== null
        ? [
            alterarAlmoxarifado,
            [
              codigoAlmoxarifado,
              numeroAlmoxarifado,
              descricao,
              tipo,
              inativo,
              rowVersion
            ]
          ]
        : [incluirAlmoxarifado, [numeroAlmoxarifado, descricao, tipo, inativo]];

    let temErros = this.validaAlmoxarifado();

    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    if (!temErros && action === incluirAlmoxarifado) {
      temErros = this.verificaValorDigitado(this.state.numeroAlmoxarifado);
    }

    if (!temErros) {
      temErros = this.verificaDescricaoDigitada(
        this.state.descricao,
        this.state.numeroAlmoxarifado
      );
    }

    if (!temErros) {
      try {
        await action(...params);
        this.setState({
          alertActive: true,
          alertType: 'success',
          alertTitle: 'Almoxarifado',
          alertSubtitle: `Almoxarifado  ${
            codigoAlmoxarifado === null ? 'cadastrado' : 'alterado'
          } com sucesso!`
        });
      } catch (err) {
        console.log(err.response.status);
        if (err.response.status === 430) {
          this.setState({ codigoAlmoxarifado: err.response.data.message });
          this.handleRestaurar();
        } else
          this.setState({
            alertActive: true,
            alertType: 'error',
            alertTitle: 'Erro',
            alertSubtitle: err.response.data.message
          });
      }
    }
  };

  validaAlmoxarifado = () => {
    //verifica preenchimento dos campos

    if (this.state.descricao.trim() === '') {
      this.showError('É obrigatório informar a descrição do almoxarifado');
      return true;
    }

    return false;
  };

  verificaValorDigitado = valor => {
    let existe = false;
    let lista = this.state.almoxarifadosExistentes;
    for (let i = 0; i < lista.length; i++) {
      const almoxarifados = lista[i];
      if (almoxarifados.numeroAlmoxarifado === valor) {
        existe = true;
      }
    }
    if (existe) {
      this.showError(
        'Já existe um almoxarifado cadastrado com o número informado.'
      );
    }
    return existe;
  };

  verificaDescricaoDigitada = (valor, numeroAlmoxarifado) => {
    let existe = false;
    let lista = this.state.almoxarifadosExistentes;
    for (let i = 0; i < lista.length; i++) {
      const almoxarifados = lista[i];
      if (almoxarifados.numeroAlmoxarifado !== numeroAlmoxarifado)
        if (almoxarifados.descricao.toLowerCase() === valor.toLowerCase()) {
          existe = true;
        }
    }
    if (existe) {
      this.showError(
        'Já existe um almoxarifado cadastrado com a descrição informada.'
      );
    }
    return existe;
  };

  handleExcluir = () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    this.setState({
      alertActive: true,
      alertType: 'question',
      alertTitle: 'Almoxarifado',
      alertSubtitle: 'Deseja excluir?'
    });
  };

  handleRestaurar = () => {
    console.log(this.state.numeroAlmoxarifado);
    this.setState({
      alertActive: true,
      alertType: 'recreate',
      alertTitle: 'Almoxarifado',
      alertSubtitle:
        'O almoxarifado nº' +
        this.state.numeroAlmoxarifado +
        ' está excluído. Deseja restaurar o mesmo?'
    });
  };

  handleCancelar = () => {
    this.setState({
      alertActive: true,
      alertType: 'cancel',
      alertTitle: 'Almoxarifado',
      alertSubtitle: 'Deseja realmente cancelar a operação?'
    });
  };

  render() {
    const {
      descricao,
      ativo,
      tipo,
      // rowVersion,

      alertActive,
      alertType,
      alertTitle,
      alertSubtitle
    } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="">
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="Descrição:"
                    name="descricao"
                    value={descricao}
                    required
                    onChange={this.handleInputChange}
                    tabindex={2}
                    maxlength="100" //Verificar com Alexandre
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <Select
                    label="Tipo:"
                    name="tipo"
                    value={tipo}
                    onChange={this.handleInputChange}
                    options={[
                      { value: '0', label: 'Área de venda' },
                      { value: '1', label: 'Depósito' }
                    ]}
                    required
                    tabindex={3}
                  />
                </div>
                <div className="col-1">
                  <Checkbox label="Ativo:">
                    <Checkitem
                      label=""
                      name="ativo"
                      checked={ativo}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
        <Footer
          saveAction={
            this.state.rowVersion === null
              ? this.handleSalvar
              : menuPermiteAlteracao(PATH_MENU)
              ? this.handleSalvar
              : null
          }
          cancelAction={this.handleCancelar}
          deleteAction={
            this.state.rowVersion === null
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
        />

        <Alert
          active={alertActive}
          type={alertType}
          title={alertTitle}
          subtitle={alertSubtitle}
          handleAction={this.handleAlertAction}
        />
      </>
    );
  }
}

Form = withRouter(Form);

class Deposito extends Component {
  state = { almoxarifados: [], almoxarifadoSel: {} };

  async componentDidMount() {
    const { data: almoxarifados } = await getListAlmoxarifado(1);
    const almOrd = almoxarifados.sort(function(a, b) {
      if (a.numeroAlmoxarifado < b.numeroAlmoxarifado) return -1;
      if (a.numeroAlmoxarifado > b.numeroAlmoxarifado) return 1;
      return 0;
    });
    this.setState({ almoxarifados: almOrd });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      const { data: almoxarifados } = await getListAlmoxarifado(1);
      const almOrd = almoxarifados.sort(function(a, b) {
        if (a.numeroAlmoxarifado < b.numeroAlmoxarifado) return -1;
        if (a.numeroAlmoxarifado > b.numeroAlmoxarifado) return 1;
        return 0;
      });
      this.setState({ almoxarifados: almOrd });
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      this.setState({ almoxarifadoSel: rowInfo.original });
      this.props.history.push('/estoque/deposito/new');
    }
  };

  render() {
    const { edit } = this.props,
      { almoxarifadoSel } = this.state;
    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <Form
              almoxarifado={almoxarifadoSel}
              existentes={this.state.almoxarifados}
            />
          ) : (
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
              <>
                <SectionHeader
                  title=""
                  subtitle=""
                  right={
                    <div className="button-container">
                      {menuPermiteInclusao(PATH_MENU) ? (
                        <Botao
                          ic
                          icon="icon-lx-plus"
                          onClick={() => {
                            this.setState({ almoxarifadoSel: {} });
                            this.props.history.push('/estoque/deposito/new');
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                />
                <List
                  onClick={this.handleTableClick}
                  cols={[
                    {
                      accessor: 'descricao',
                      Header: 'Descrição',
                      width: 500,
                      filterable: false
                    },
                    {
                      accessor: 'tipo',
                      Header: 'Tipo',
                      width: 150,
                      filterable: false,
                      sortMethod: sortInt,
                      Cell: ({ row }) => {
                        if (row.tipo === 0) {
                          return <label>Área de venda</label>;
                        } else {
                          return <label>Depósito</label>;
                        }
                      }
                    },
                    {
                      accessor: 'inativo',
                      Header: 'Ativo',
                      width: 100,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <Botao
                            secondary={row.inativo}
                            ic
                            icon={
                              row.inativo ? 'icon-lx-close' : 'icon-lx-check'
                            }
                          />
                        );
                      }
                    }
                  ]}
                  rows={this.state.almoxarifados}
                />
              </>
            </LinxPostos>
          )}
        </section>
      </main>
    );
  }
}

Deposito = withRouter(Deposito);
export { Deposito };
