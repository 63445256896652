import React, { useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Popup } from "devextreme-react";

import { CustomButton } from "../../SharedComponents";
import { Realizadas } from "./Realizadas/Realizadas";
import { GerarBoletos } from "./GerarBoletos/GerarBoletos";

import { locale } from "devextreme/localization";
locale("br");

export const Cobrancas = () => {
  const [isShow, setIsShow] = useState(false);

  const optionsButton = [
    {
      id: 1,
      icon: "textdocument",
      title: "Gerar boletos",
    },
  ];

  const openModal = () => setIsShow(!isShow);

  return (
    <Col>
      <Container fluid>
        <Row as="header">
          <h2>Cobranças</h2>
        </Row>
        <Row style={{gap: 8}}>
          {optionsButton.map(({ id, icon, title,  }) => (
            <CustomButton className="rounded" icon={icon} onClick={() => openModal()} key={id}>{ title }</CustomButton>
          ))}
        </Row>
        <Row>
          <Realizadas />
        </Row>
        <Popup
          visible={isShow}
          dragEnabled={false}
          showTitle={true}
          title="Gerar boletos"
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={openModal}
          width="80%"
          height="auto"
        >
          <GerarBoletos isShow={isShow} />
        </Popup>
      </Container>
    </Col>
  );
}
