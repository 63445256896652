import { LoadPanel } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import './SolicitacaoReservaForm.css';
import { locale } from 'devextreme/localization';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { selectSolicitacaoReservaSharedData, useSolicitacaoReservaSharedData } from '../../../../redux/slices/solicitacaoReserva/SharedData.slice';
import moment from 'moment';
import Swal from 'sweetalert2';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getDadosLogin, getNomePagina } from '../../../../shared/utils/Utils';
import { SessionManager } from '../../../../shared/utils/sessionManager';
import { ApiSalvarSolResService } from "./../usercases/ApiSalvarSolRes.service";

export function SolicitacaoReservaForm({
    recordOpened, formData, createNewRecord, setFormData, reloadForm
}) {
    locale('pt-BR');
    const [gridOpened, setGridOpened] = useState(false);

    const [statusPedido, setStatusPedido] = useState('');


    useEffect(() => {
        if (!recordOpened) return;
        if (formData) {
            if (formData.solicitacaoStatusId === 1) {
                setStatusPedido('ABERTO');
            }
            else if (formData.solicitacaoStatusId === 2) {
                setStatusPedido('ENVIADO');
            }
            else if (formData.solicitacaoStatusId === 3) {
                setStatusPedido('CANCELADO');
            }
            else {
                setStatusPedido('FECHADO');
            }
        }
    }, [recordOpened, formData]);

    function doCreateNewRecord(ev) {
        ev.preventDefault();
        if (typeof createNewRecord === 'function') {
            createNewRecord();
        }
    }

    async function confirmarEnviarReserva() {
        try {
            let usuario = getDadosLogin();
            let data = {
                ...formData
            };

            if (!formData.itens.some((item) => item.quantidade > 0)) {
                if (formData.solicitacaoStatusId === 1) {
                    return;
                }
            }
            else {
                for (let i = 0; i < data.itens.length; i++) {
                    if (data.itens[i].quantidade > 0) {
                        if (!data.itens[i].criadoPor) {
                            data.itens[i].criadoPor = usuario.nome;
                            data.itens[i].dataInclusao = new Date();
                        }
                        else {
                            data.itens[i].alteradoPor = usuario.nome;
                            data.itens[i].dataUltimaAtualizacao = new Date();
                        }
                    }
                    else {
                        if (formData.solicitacaoStatusId === 3) {
                            data.itens[i].canceladoPor = usuario.nome;
                            data.itens[i].dataCancelamento = new Date();
                        }
                    }
                }
            }

            const response = await new ApiSalvarSolResService().execute(data);

            if (response) {
                //swel
                Swal.fire({
                    icon: 'success',
                    title: 'Reserva enviada com sucesso',
                    text: 'Sua reserva foi enviada com sucesso!'
                });

                if (typeof reloadForm === 'function') {
                    reloadForm();
                }

            }
            else {
                throw new Error('Erro ao enviar reserva');
            }
        }
        catch (e) {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: e.message ? e.message : e
            });
        }
    }


    function getNextWednesday() {
        const now = new Date();
        const dayOfWeek = now.getDay();
        const currentHour = now.getHours();

        // Calcular a diferença de dias até a próxima quarta-feira
        const daysUntilNextWednesday = (3 - dayOfWeek + 7) % 7 || 7;

        // Criar a data para a próxima quarta-feira
        const nextWednesday = new Date(now);
        nextWednesday.setDate(now.getDate() + daysUntilNextWednesday);
        nextWednesday.setHours(18, 0, 0, 0); // Define a hora para 18:00:00

        // Se hoje for quarta-feira e ainda não passou das 18:00
        if (dayOfWeek === 3 && currentHour < 18) {
            return new Date(now.setHours(18, 0, 0, 0));
        }

        return nextWednesday;
    }

    function getDeliveryDates(dataMaxima) {
        // Calcular o número de dias até a próxima segunda-feira
        const daysUntilNextMonday = (1 - dataMaxima.getDay() + 7) % 7 || 7;

        const deliveryStartDate = new Date(dataMaxima);
        deliveryStartDate.setDate(dataMaxima.getDate() + daysUntilNextMonday);

        const deliveryDates = [];
        for (let i = 0; i < 5; i++) {
            const date = new Date(deliveryStartDate);
            date.setDate(deliveryStartDate.getDate() + i);
            deliveryDates.push(date);
        }

        return deliveryDates;
    }


    function getWeekNumber(date) {
        const startOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - startOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 8) / 7);
    }


    let dataMaxima = getNextWednesday().toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    let previsaoDeEntrega = getDeliveryDates(getNextWednesday());
    let previsaoDataInicial = previsaoDeEntrega[0].toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    let previsaoDataFinal = previsaoDeEntrega[4].toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    let previsaoSemana = getWeekNumber(getNextWednesday());

    //let semanaDeEntrega = getWeekNumber(dataMaxima);




    function enviarReserva(ev) {
        try {
            ev.preventDefault();
            if (!formData || !formData.itens || formData.itens.length === 0) {
                throw new Error('Nenhum item para enviar na reserva');
                return;
            }
            let itensSemQuantidade = formData.itens.filter((item) => item.quantidade < 0);
            if (itensSemQuantidade.length > 0) {
                throw new Error('Existem itens com quantidade inválida!');
            }
            Swal.fire({
                title: 'Confirmação',
                text: 'Deseja realmente enviar a reserva?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmarEnviarReserva();

                }
            });
        }
        catch (e) {
            console.error(e);
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: e.message ? e.message : e
            });
        }
    }

    function handleQuantityChange(e, index) {
        let itens = formData.itens;

        itens[index].quantidade = e.target.value;

        /*if (itens[index].criadoPor) {
            itens[index].alteradoPor = getDadosLogin().nome;
            itens[index].dataAlteracao = new Date();
        }
        else
        {
            itens[index].criadoPor = getDadosLogin().nome;
            itens[index].dataInclusao = new Date();
            itens[index].includoNow = true;
        }*/
        setFormData({
            ...formData,
            itens: itens
        });
    }

    return <>
        {!recordOpened &&
            <div style={
                {
                    backgroundColor: '#f7d7da',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    padding: '5px',
                    borderRadius: '5px',
                    textAlign: 'center',
                    color: '#721c24',
                    fontSize: '1.0em',
                    marginBottom: '40px'
                }
            }>
                <p>NÃO HÁ SOLICITAÇÃO DE RESERVA EM ABERTO OU ENVIADA PARA A PRÓXIMA SEMANA.</p>
                <p>INICIE UMA NOVA RESERVA CLICANDO EM <a href="#" onClick={doCreateNewRecord} style={{
                    color: '#6a6a5d',
                    textDecoration: 'underline'
                }}>"NOVA RESERVA"</a></p>
            </div>
        }
        {recordOpened && <Card>
            <Card.Body style={{
                marginBottom: '20px'
            }}>
                <p style={{
                    textAlign: 'center',
                    fontSize: '1.1em'
                }}>INSIRA A QUANTIDADE DE PRODUTOS QUE DESEJA RESERVAR<br /> DATA MÁXIMA DE ENVIO: {dataMaxima.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' })}</p>

                <div>
                    <span style={{
                        fontSize: '0.9em',
                        fontWeight: 'bold'
                    }}>STATUS: </span>
                    <span style={{
                        fontSize: '1.1em',
                        color: '#28a745',
                    }}>{statusPedido}</span>
                    <br />
                    <span style={{
                        fontSize: '0.9em',
                        fontWeight: 'bold'
                    }}>PREVISÃO DE ENTREGA: </span>
                    <span style={{
                        fontSize: '1.1em',
                        color: '#28a745',
                    }}>Entre {previsaoDataInicial} e {previsaoDataFinal} - Semana {previsaoSemana}</span>
                    {formData && formData.numeroPedido && <>
                        <br />
                        <span style={{
                            fontSize: '0.9em',
                            fontWeight: 'bold'
                        }}>RESERVA NÚMERO: </span>
                        <span style={{
                            fontSize: '1.1em',
                            color: '#28a745',
                        }}>{formData.numeroPedido}</span>
                    </>}
                </div>



                <table className="tableSolicitacao">
                    <thead>
                        <tr>
                            <th>Descrição Produto</th>
                            <th>Código EAN</th>
                            <th>Data da Solicitação</th>
                            <th>Unidades Vendidas Últimos 7 Dias</th>
                            <th>Unidades Vendidas Últimos 30 Dias</th>
                            <th>Incluído Por</th>
                            
                            <th>Alterado Por</th>
                            <th>Data Alteração</th>
                            <th>Quantidade Solicitada (BARRIL)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formData && formData.itens && formData.itens.map((item, index) => {
                            console.log("Item:", item);
                            return <tr key={index}>
                                <td>{item.prodDescricao}</td>
                                <td>{item.prodEan}</td>
                                <td>{formData.dataCadastro ? moment(formData.dataCadastro).format('DD/MM/YYYY') : ''}</td>
                                <td>{item.historicoVendas.vendas7Dias}</td>
                                <td>{item.historicoVendas.vendas30Dias}</td>
                                <td>{item.criadoPor}</td>
                                
                                <td>{item.alteradoPor}</td>
                                <td>{item.dataUltimaAtualizacao ? moment(item.dataUltimaAtualizacao).format('DD/MM/YYYY') : ''}</td>
                                <td>
                                    <input type="number" value={item.quantidade} style={{
                                        width: '100%',
                                        border: '1px solid #aaa',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        
                                        fontSize: '1.2em'
                                    }} onChange={(e) => handleQuantityChange(e, index)} />
                                </td>
                            </tr>
                        })}
                    </tbody>
                    <tfoot>
                        {(statusPedido === 'ABERTO' || statusPedido === 'CANCELADO') && (
                            <tr>
                            <td colSpan="10" style={{ textAlign: 'left' }}>
                                <b>VOCÊ PRECISA ENVIAR SUA RESERVA ATÉ A DATA MÁXIMA DE ENVIO {dataMaxima} ÀS 18:00 HORAS</b>
                            </td>
                            </tr>
                        )}
                        </tfoot>
                </table>
                <div className="d-flex justify-content-center">
                    <button className="btn btn-success" onClick={enviarReserva} style={{
                        width: '200px'
                    }}>ENVIAR RESERVA</button>
                </div>

            </Card.Body>
        </Card>}
    </>
}