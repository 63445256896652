import axios from "axios";

import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";
import {
  getCnpj, getUrl, compareStrings, formatNumber 
} from "../../../shared/utils/Utils";

import moment from "moment";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getTitulos(
  codigoSituacao,
  codigoTipoLancamento,
  dataInicial,
  dataFinal,
  descricao,
  codigoPessoa
) {
  return axios.post(`LxApi/v1/Financeiro/ConsultaGerenciamento`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      dataInicial,
      dataFinal,
      codigoSituacao,
      codigoTipoLancamento,
      descricao,
      codigoPessoa
    }
  });
}

export function getTitulo(id, codigoTipoLancamento) {
  return axios.get(`LxApi/v1/Financeiro/SelecionarTitulo`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoTipoLancamento,
      id
    }
  });
}

export function incluiContaPagarReceber(
  codigoTipoLancamento,
  descricao,
  valor,
  dataVencimento,
  dataEmissao,
  dataPrevisao,
  pago,
  codigoCentroMonetario,
  recorrencia,
  codigoTipoPessoa,
  codigoPessoa,
  numeroDocumento,
  numeroCheque,
  numeroAutorizacao,
  nsu,
  codigoTipoDocumento,
  codigoConta,
  codigoBanco,
  contaBanco,
  agencia,
  codigoEstruturaCartao,
  dataPagamento
) {
  return axios.post(`LxApi/v1/Financeiro/ContasPagarReceber/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    codigoTipoLancamento,
    id: 0,
    descricao,
    valor,
    dataVencimento,
    dataEmissao,
    dataPrevisao,
    pago,
    codigoCentroMonetario,
    numeroParcela: 1,
    recorrencia,
    codigoTipoPessoa,
    codigoPessoa,
    numeroDocumento,    
    codigoTipoDocumento,
    codigoConta,
    cheque: {
      numeroCheque: numeroCheque,
      codigoBanco,
      agencia,
      conta: contaBanco
    },
    cartao: {
      codigoEstruturaCartao,
      nsu,
      numeroAutorizacao
    },
    dataPagamento
  });
}

export function alteraContaPagarReceber(
  codigo,
  codigoTipoLancamento,
  descricao,
  valor,
  dataVencimento,
  dataEmissao,
  dataPrevisao,
  pago,
  codigoCentroMonetario,
  recorrencia,
  codigoTipoPessoa,
  codigoPessoa,
  numeroDocumento,
  numeroCheque,
  numeroAutorizacao,
  nsu,
  codigoTipoDocumento,
  codigoConta,
  codigoBanco,
  contaBanco,
  agencia,
  codigoEstruturaCartao,
  dataPagamento,
  numeroParcela
) {
  return axios.put(`LxApi/v1/Financeiro/ContasPagarReceber/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigoTipoLancamento,
    id: codigo,
    descricao,
    valor,
    dataVencimento,
    dataEmissao,
    dataPrevisao,
    pago,
    codigoCentroMonetario,
    numeroParcela,
    recorrencia,
    codigoTipoPessoa,
    codigoPessoa,
    numeroDocumento,
    codigoTipoDocumento,
    codigoConta,
    cheque: {
      numeroCheque: numeroCheque,
      codigoBanco,
      agencia,
      conta: contaBanco
    },
    cartao: {
      codigoEstruturaCartao,
      nsu,
      numeroAutorizacao
    },
    dataPagamento
  });
}

export function montaComboTiposLancamentos() {
  return axios.get("LxApi/v1/Financeiro/MontaComboTipoLancamento");
}

export function montaComboSituacoes() {
  return axios.get("LxApi/v1/Financeiro/MontaComboSituacao/");
}

export function montaComboTiposOcorrencias() {
  return axios.get("LxApi/v1/Financeiro/MontaComboTipoOcorrencia");
}

export function montaComboTiposDocumentos(tipoLancamento) {
  return axios.get(`LxApi/v1/Financeiro/MontaComboTipoDocumento`, {
    params: { CodigoTipoLancamento: tipoLancamento }
  });
}

export function montaComboOperacoes() {
  /* return [
    { descricao: "Lançamento de receita", codigo: Operacoes.LancamentoReceita },
    { descricao: "Lançamento de despesa", codigo: Operacoes.LancamentoDespesa }
  ]; */
  return axios.get("LxApi/v1/Financeiro/MontaComboTipoOperacao");
}

export function excluiParcelaUnica(codigoParcela, codigoTipoLancamento) {
  axios.defaults.baseURL = getUrl();
  var cnpjEstabelecimento = getCnpj();
  return axios.delete(`LxApi/v2/Financeiro/ExcluirParcela`, {
    params: { cnpjEstabelecimento, id: codigoParcela, codigoTipoLancamento }
  });
}

export function excluiParcelaRecorrente(codigoRecorrencia, dataVencimento) {
  axios.defaults.baseURL = getUrl();
  var cnpjEstabelecimento = getCnpj();
  return axios.delete(`LxApi/v1/Financeiro/ExcluirRecorrencia`, {
    params: { cnpjEstabelecimento, codigoRecorrencia, dataVencimento }
  });
}

export function consultaParcela(codigoParcela) {
  axios.defaults.baseURL = getUrl();
  var cnpjEstabelecimento = getCnpj();
  return axios.get(`LxApi/v1/Financeiro/ConsultarParcelas`, {
    params: { cnpjEstabelecimento, id: codigoParcela }
  });
}

export function quitaTotal(
  id,
  descricao,
  dataQuitacao,
  codigoCentroMonetario,
  conciliado,
  codigoTipoLancamento,
  codigoFormaPagamento,
  valorPago,
  valorAcrescimo,
  valorDesconto
) {
  return axios.post(`LxApi/v1/Financeiro/ContasPagarReceber/Quitacao`, {
    cnpjEstabelecimento: getCnpj(),
    id,
    descricao,
    dataQuitacao,
    codigoCentroMonetario,
    conciliado, // aceita 0 ou 1
    codigoTipoLancamento,
    codigoFormaPagamento,
    valorPago,
    valorAcrescimo,
    valorDesconto
  });
}

export function quitaParcial(
  id,
  descricao,
  dataQuitacao,
  codigoCentroMonetario,
  conciliado,
  codigoTipoLancamento,
  codigoFormaPagamento,
  valorPago,
  valorAcrescimo,
  valorDesconto,
  saldo,
  dataVencimentoSaldo
) {
  return axios.post(`LxApi/v1/Financeiro/ContasPagarReceber/Quitacao`, {
    cnpjEstabelecimento: getCnpj(),
    id,
    descricao,
    dataQuitacao,
    codigoCentroMonetario,
    conciliado, // aceita 0 ou 1
    codigoTipoLancamento,
    codigoFormaPagamento,
    valorPago,
    valorAcrescimo,
    valorDesconto,
    saldo,
    dataVencimentoSaldo
  });
}

export function incluiTransferencia(
  dataOperacao,
  valor,
  codigoCentroMonetarioOrigem,
  codigoCentroMonetarioDestino,
  historico,
  conciliado,
  numeroDocumento,
  codigoTipoDocumento
) {
  return axios.post(`LxApi/v1/Financeiro/Transferencia/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    id: 0,
    dataOperacao,
    valor,
    codigoCentroMonetarioOrigem,
    codigoCentroMonetarioDestino,
    historico,
    conciliado,
    numeroDocumento,
    codigoTipoDocumento
  });
}

export function alteraTransferencia(
  id,
  dataOperacao,
  valor,
  codigoCentroMonetarioOrigem,
  codigoCentroMonetarioDestino,
  historico,
  conciliado,
  numeroDocumento,
  codigoTipoDocumento
) {
  return axios.put(`LxApi/v1/Financeiro/Transferencia/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    id,
    dataOperacao,
    valor,
    codigoCentroMonetarioOrigem,
    codigoCentroMonetarioDestino,
    historico,
    conciliado,
    numeroDocumento,
    codigoTipoDocumento
  });
}

export function incluiDiversas(
  codigoTipoLancamento,
  dataOperacao,
  valor,
  codigoCentroMonetario,
  codigoTipoOperacao,
  codigoConta,
  descricao,
  conciliado,
  numeroDocumento
) {
  return axios.post(`LxApi/v1/Financeiro/DespesasDiversas/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    id: 0,
    codigoTipoLancamento,
    dataOperacao,
    valor,
    codigoCentroMonetario,
    codigoTipoOperacao,
    codigoConta,
    descricao,
    conciliado,
    numeroDocumento
  });
}

export function alteraDiversas(
  id,
  codigoTipoLancamento,
  dataOperacao,
  valor,
  codigoCentroMonetario,
  codigoTipoOperacao,
  codigoConta,
  descricao,
  conciliado,
  numeroDocumento
) {
  return axios.put(`LxApi/v1/Financeiro/DespesasDiversas/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    id,
    codigoTipoLancamento,
    dataOperacao,
    valor,
    codigoCentroMonetario,
    codigoTipoOperacao,
    codigoConta,
    descricao,
    conciliado,
    numeroDocumento
  });
}

export function cancelaQuitacao(id, codigoTipoLancamento) {
  return axios.delete(`LxApi/v1/Financeiro/Estorno`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoTipoLancamento,
      id
    }
  });
}

export async function getRecibo(id) {
  return await axios.get(`LxApi/v1/Financeiro/Recibo`, {
    params: { cnpjEstabelecimento: getCnpj(), id }
  });
}

export function alteraRecibo(id, CodigoTipoPessoa, CodigoPessoa) {
  return axios.put(
    `LxApi/v1/Financeiro/Recibo/Alterar?cnpjEstabelecimento=${getCnpj()}&id=${id}&CodigoTipoPessoa=${CodigoTipoPessoa}&CodigoPessoa=${CodigoPessoa}`
  );
}

export async function montaComboEstruturaCartoes(search) {
  // TODO: AGUARDANDO API
  const { data } = await axios.post(`LxApi/v1/EstruturaCartao/Listar`, {
    query: {
      inicioVigenciaDe: "2010-01-01",
      inicioVigenciaAte: "2079-06-06",
      cnpjEstabelecimento: getCnpj()
    }
  });
  let result = [];
  if (data && data.result) {
    const part = (obj, defaultValue) => {
      return !!obj ? obj.descricao + " (" + obj.codigo + ") / " : defaultValue;
    };
    for (let i = 0; i < data.result.length; i++) {
      let v = data.result[i];
      result.push({
        value: v.codigo,
        label:
          part(v.rede, "rede não informada / ") +
          part(v.bandeira, "bandeira não informada / ") +
          part(v.formaPagamento, "forma de pagamento não informada / ") +
          part(v.produtoRede, "produto rede não informado / ") +
          formatNumber(v.taxa, 2) +
          "% / " +
          v.diasResgate +
          " dias / " +
          moment(v.vigencia).format("DD/MM/YYYY"),
        rede: v.rede,
        bandeira: v.bandeira,
        formaPagamento: v.formaPagamento,
        produtoRede: v.produtoRede
      });
    }
  }
  result.sort((a, b) => compareStrings(a.label, b.label));
  return result;
}

