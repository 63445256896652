import React, { useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import notify from "devextreme/ui/notify";

import { CustomButton } from "../../../../SharedComponents";
import { CobrancasService } from "../../Cobrancas.service";

export const Sucesso = ({
  isShow,
  barCode,
  billId,
}) => {

  const textRef = useRef(null);

  const setNotice = () => {
    notify("Código de barras copiado", "info", 2000);
  }

  const download = async () => {
    notify("Gerando download do boleto. Aguarde...", "info", 2000);

    await CobrancasService.getPdfDownload(billId);

  }

  return (
    <>
      {isShow &&
        <div className="card mb-2">
          <div className="card-body">
            <h3>Boleto gerado com sucesso</h3>
            <div className="d-flex align-items-center" style={{ gap: 16 }}>
              <p>
                Código de barras: <strong ref={textRef}>{ barCode }</strong>
              </p>
              <CopyToClipboard text={barCode} style={{ cursor: "pointer" }} onCopy={setNotice}>
                <i className="dx-icon-copy" aria-hidden="true"></i>
              </CopyToClipboard>
            </div>
            <CustomButton className="rounded" icon="download" onClick={download}>Download boleto</CustomButton>
          </div>
        </div>
      }
    </>
  )
}
