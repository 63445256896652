import React, { useEffect, useState, useRef } from 'react';
import DataGridResult from '../DataGridResult/DataGridResult';
import styles from './ResultPanel.module.scss';
import moment from 'moment';
import { DropDownButton, Popup } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import ReportManager from '../DataGridResult/Components/ReportManager/ReportManager';
import { reportMangerService } from '../../../services/ReportManager.service';
import { NotificationLevel } from '../../../shared/constants/Const';

const ResultPanel = props => {
  const gridResultRef = useRef(null);
  const [reportFilter, setReportFilter] = useState('');
  const [options, setOptions] = useState([]);
  const [popupVisible, setPopupVisible] = useState(false);
  const [columnsSelected, setColumnsSelected] = useState(false);
  const [report, setReport] = useState({});
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const filter = props.children
      .map(item =>
        item.props.data && item.props.data.length > 0
          ? `${item.props.message}: ${item.props.data.length}`
          : item.props.inicial
          ? `${moment(item.props.inicial).format('DD/MM/YYYY')} a ${moment(
              item.props.final
            ).format('DD/MM/YYYY')}`
          : item.props.text && item.props.text.length > 0
          ? `Pesquisa: ${item.props.text}`
          : ``
      )
      .join(' ');
    setReportFilter(filter);

    if (props.saveReport) {
      // if (!props.chosenSubReport.id || props.chosenSubReport.interno) {
      if (props.chosenSubReport.tipo === 0) {
        setOptions(['Salvar como']);
      } else {
        let opcoes = [];

        if (props.chosenSubReport.editar) {
          opcoes.push('Salvar');
        }

        opcoes.push('Salvar como');

        if (props.chosenSubReport.editar) {
          opcoes.push('Editar');
        }

        if (props.chosenSubReport.excluir) {
          opcoes.push('Excluir');
        }

        setOptions(opcoes);
      }
    }
  }, [props]);

  const hideInfo = () => {
    setPopupVisible(false);
  };

  const showManager = () => {
    const dataGrid = gridResultRef.current.instance;
    const colums = dataGrid
      .getVisibleColumns()
      .map(
        ({
          caption,
          dataField,
          groupIndex,
          visibleIndex,
          filterValue,
          sortIndex,
          sortOrder
        }) => {
          return {
            caption,
            dataField,
            groupIndex,
            visibleIndex,
            filterValue,
            sortIndex,
            sortOrder,
            text: caption
          };
        }
      );

    setColumnsSelected(colums);
    setPopupVisible(true);
  };

  const onItemClick = e => {
    if (e.itemData === 'Salvar como' && props.chosenSubReport.id > 0) {
      setReport({
        data: props.chosenSubReport.data,
        publico: props.chosenSubReport.publico,
        titulo: props.chosenSubReport.titulo,
        editar: true,
        excluir: true,
        interno: false,
        copia: true
      });
      showManager();
    } else if (e.itemData === 'Salvar como') {
      setReport({
        data: { periodo: { tipo: 0 } },
        publico: false,
        titulo: '',
        editar: true,
        excluir: true,
        interno: false
      });
      showManager();
    } else if (e.itemData === 'Editar') {
      setReport(props.chosenSubReport);
      showManager();
    } else if (e.itemData === 'Excluir') {
      excluir();
    } else if (e.itemData === 'Salvar') {
      let colunas = gridResultRef.current.instance
        .getVisibleColumns()
        .map(
          ({
            caption,
            dataField,
            groupIndex,
            visibleIndex,
            filterValue,
            sortIndex,
            sortOrder
          }) => {
            return {
              caption,
              dataField,
              groupIndex,
              visibleIndex,
              filterValue,
              sortIndex,
              sortOrder,
              text: caption
            };
          }
        );

      const relatorio = {
        id: props.chosenSubReport.id,
        titulo: props.chosenSubReport.titulo,
        publico: props.chosenSubReport.publico,
        filtros: props.chosenSubReport.data.filtros,
        colunas: colunas,
        codigoRetaguarda: props.codigoRetaguarda,
        periodo: props.chosenSubReport.data.periodo
      };

      save(relatorio);
    }
  };

  const excluir = async () => {
    setProcessing(true);

    let result = await reportMangerService.Delete(props.chosenSubReport.id);

    if (result.notificationLevel === NotificationLevel.Success) {
      notify('Relatório excluido com sucesso.', 'success', 2000, 'top');
      props.refreshReports();
    } else {
      notify(result.message, 'error', 2000, 'top');
    }

    setProcessing(false);
  };

  const save = async report => {
    setProcessing(true);

    if (report.id) {
      let result = await reportMangerService.Update(report);

      if (result.notificationLevel === NotificationLevel.Success) {
        notify('Relatório salvo com sucesso.', 'success', 2000, 'top');
        hideInfo();
        props.refreshReports();
      } else {
        notify(result.message, 'error', 2000, 'top');
      }
    } else {
      let result = await reportMangerService.Add(report);

      if (result.notificationLevel === NotificationLevel.Success) {
        notify('Relatório salvo com sucesso.', 'success', 2000, 'top');
        const relatorio = {
          id: result.data,
          titulo: report.titulo,
          codigoRetaguarda: report.codigoRetaguarda,
          editar: true,
          excluir: true,
          interno: false,
          publico: report.publico,
          data: {
            colunas: report.colunas,
            filtros: report.filtros,
            periodo: report.periodo
          }
        };
        props.changeChosenSubReport(relatorio);
        setReport(relatorio);
        hideInfo();
        props.refreshReports();
      } else {
        notify(result.message, 'error', 2000, 'top');
      }
    }

    setProcessing(false);
  };

  const contentRender = () => {
    return (
      <ReportManager
        filtroOutros={props.filtroOutros}
        chosenSubReport={report}
        columnsSelected={columnsSelected}
        codigoRetaguarda={props.codigoRetaguarda}
        cancel={() => setPopupVisible(false)}
        save={save}
      />
    );
  };

  return (
    <div>
      <div className={`${styles.result} result mt-3`}>
        <div className={`${styles.results} shadow-sm p-3 bg-white`}>
          <h2>{props.panelTitle}</h2>
          <div className="row">
            <div className="col-11">
              <div className={`${styles.appliedFilters} appliedFilters`}>{props.children}</div>
            </div>
            {props.saveReport && (
              <div className="col-1">
                <div className={'d-flex flex-row-reverse'}>
                  <DropDownButton
                    icon="menu"
                    text={'Personalizar'}
                    disabled={props.disabled || processing}
                    items={options}
                    onItemClick={onItemClick}
                  />
                </div>
              </div>
            )}
          </div>
          <hr />
          <div className="row">
            <div className="col-md-12">
              <DataGridResult
                dataSource={props.dataSource}
                columns={props.columns}
                saveReport={props.saveReport}
                codigoRetaguarda={props.codigoRetaguarda}
                fileName={props.fileName}
                gridResultRef={gridResultRef}
                disabled={props.disabled || processing}
                chosenSubReport={props.chosenSubReport}
                filters={props.filters}
                filtroOutros={props.filtroOutros}
                reportTitle={props.reportTitle}
                reportFilters={reportFilter}
                allowExpand={props.allowExpand}
                summary={props.summary ? props.summary : () => {}}
                export={true}
                searchPanel={true}
                showPageSizeSelector={true}
                allowedPageSizes={[5, 15, 20, 50]}
                defaultPageSize={20}
                columnChooser={true}
              />
              <br />
            </div>
          </div>
        </div>
      </div>

      <Popup
        visible={popupVisible}
        dragEnabled={false}
        onHiding={hideInfo}
        closeOnOutsideClick={true}
        showTitle={true}
        title={'Relatório Personalizado'}
        width={630}
        height={430}
        contentRender={contentRender}
      />
    </div>
  );
};

export default ResultPanel;
