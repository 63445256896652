import React from 'react'
import { DropDownBox } from 'devextreme-react';
import LinxListAsContent from '../linx/ListAsContent/LinxListAsContent';

const DropDownBoxRender = (props) => {

    return(
        <div>
            <span>{props.title}</span>
            <DropDownBox
                value={props.value}
                valueExpr={props.valueExpr}
                displayExpr={props.displayExpr}
                placeholder={props.placeholder}
                showClearButton={true}
                disabled={props.disabled}
                dataSource={props.dataSource}
                onValueChanged={props.onValueChanged}
                contentRender={() => {
                    return (
                        <LinxListAsContent
                            selectionMode={props.selectionMode}
                            dataSource={props.dataSource}
                            selectedItems={props.value}
                            methodThatSets={props.methodThatSets}
                            displayExpr={props.displayExpr}
                            
                            searchEnabled={false}
                        />
                    );
                }}
            />
        </div>
    )
}

export default DropDownBoxRender;