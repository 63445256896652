import React from 'react';
import styles from './PreReportButton.module.scss';

const PreReportButton = props => {
  return (
    <div className={`${styles.reportButton} reportButton ${props.report.subReport && props.report.subReport.length > 0 ? "dropdown" : ""}`}>
      <div className={`${styles.preReportButton} preReportButton`} onClick={props.report.tipo === 0 ? () => props.onClick(props.report) : null}>
        <i className={props.report.icone}></i>
        <p>{props.report.titulo}</p>
      </div>

      <div className={`${styles.dropMenu} dropMenu show dropdown-menu `}>
        {props.report.subReport.sort(
          (a, b) => {
            if (a.titulo < b.titulo) { return -1; }
            if (a.titulo > b.titulo) { return 1; }
            return 0;
          }
        ).map((x, index) => <span key={index} className={`${styles.dropItem} dropItem dropdown-item `} onClick={() => props.onClick(x)}>{x.titulo.toUpperCase()}</span>)}
      </div>
    </div >
  );
};

export default PreReportButton;
