import axios from "axios";

import { getUrl, getCnpj } from "../shared/utils/Utils";
import { TOKEN_PASS_AXIOS } from "../shared/constants/Const";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getEstruturasMercadologicas() {
  return axios.post(`LxApi/v1/EstruturaMercadologica/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function incluiEstruturaMercadologica(codigoPai, descricao, nivel) {
  return axios.post(`LxApi/v1/EstruturaMercadologica/Incluir`, {
    cnpjEstabelecimento: getCnpj(),
    codigoPai,
    descricao,
    nivel
  });
}

export function alteraEstruturaMercadologica(
  codigo,
  codigoPai,
  descricao,
  nivel,
  rowVersion
) {
  return axios.put(`LxApi/v1/EstruturaMercadologica/Alterar`, {
    cnpjEstabelecimento: getCnpj(),
    codigo,
    codigoPai,
    descricao,
    nivel,
    rowVersion
  });
}

export function excluiEstruturaMercadologica(codigoEstrutura) {
  return axios.delete(`LxApi/v1/EstruturaMercadologica/Excluir/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoEstrutura
    }
  });
}
