export class NovaOSValidateService {
  execute(values) {
    if (!values) {
      return {};
    }
    const errors = { isValid: true };
    if (!values.origem || values.origem.id === -1) {
      errors.origem = 'Por favor, informe uma origem para o pedido';
      errors.isValid = false;
    }
    if (!values.cliente) {
      errors.cliente = 'Por favor, selecione um cliente';
      errors.isValid = false;
    }
    if (!values.dataEntregaRetirada) {
      errors.dataEntregaRetirada = 'Por favor, informe uma data de entrega ou retirada';
      errors.isValid = false;
    }
    if (values.entregaRetiradaHoraAte && values.entregaRetiradaHoraDe && values.entregaRetiradaHoraAte < values.entregaRetiradaHoraDe) {
      errors.entregaRetiradaHoraAte = 'A hora da retirada deve ser maior que a hora da entrega';
      errors.isValid = false;
    }
    if (!values.dataEvento) {
      errors.dataEvento = 'Por favor, informe uma data para o evento';
      errors.isValid = false;
    }
    if (values.dataEvento && values.dataEntregaRetirada && new Date(values.dataEvento) < new Date(values.dataEntregaRetirada)) {
      errors.dataEvento = 'A data do evento deve ser maior que a data de entrega ou retirada';
      errors.isValid = false;
    }
    if (!values.dataRecolhaDevolucao) {
      errors.dataRecolhaDevolucao = 'Por favor, informe a data de recolha';
      errors.isValid = false;
    }

    if (values.dataRecolhaDevolucao && values.dataEntregaRetirada && new Date(values.dataRecolhaDevolucao) < new Date(values.dataEntregaRetirada)) {
      errors.dataRecolhaDevolucao = 'A data de recolha deve ser maior que a data de entrega ou retirada';
      errors.isValid = false;
    }
    if (!values.tipoFreteId || values.tipoFreteId === '-1' || values.tipoFreteId === -1) {
      errors.tipoFreteId = 'Por favor, informe o tipo do frete para o pedido.';
      errors.isValid = false;
    }
    if (!values.transportadora && (values.tipoFreteId === 2 || values.tipoFreteId === 3)) {
      errors.transportadora = 'Por favor, informar uma transportadora.';
      errors.isValid = false;
    }
    if (!values.pedidos || !values.pedidos.length) {
      errors.pedidos = 'Por favor, informe pelo menos um pedido.';
      errors.isValid = false;
    }
    // if (values.pedidos) {
    //   let idxPedido = 0;
    //   for (const pedido of values.pedidos) {
    //     if(pedido.tipoPedido.id !== 1) continue;
    //     if (!pedido.pagamentos || !pedido.pagamentos.length) {
    //       errors[`Pagamento_${idxPedido}`] = 'Por favor, informe pelo menos um pagamento.';
    //       errors.isValid = false;
    //     }
    //   }
    // }
    // console.log('errors', errors);
    return errors;
  }
}
