export function changedList(list, name, value, index, others) {
  const result = [];
  for (let i = 0; i < list.length; i++) {
    if (i === index) result.push({ ...list[i], [name]: value, ...others });
    else result.push(list[i]);
  }
  return result;
}

export function changeInList(sender, listName, name, value, index, others) {
  sender.setState({
    [listName]: changedList(sender.state[listName], name, value, index, others)
  });
}

export function appendedList(list, row) {
  const result = list;
  result.push(row);
  return result;
}

export function appendInList(sender, listName, row) {
  sender.setState({
    [listName]: appendedList(sender.state[listName], row)
  });
}

export function renumberedList(list, fieldToRenumber, start) {
  const result = [];
  let value = start;
  for (let i = 0; i < list.length; i++) {
    result.push({ ...list[i], [fieldToRenumber]: value++ });
  }
  return result;
}

export function deletedList(list, index, fieldToRenumber, start = 1) {
  const result = list;
  result.splice(index, 1);
  if (fieldToRenumber) return renumberedList(result, fieldToRenumber, start);
  else return result;
}

export function deleteInList(
  sender,
  listName,
  index,
  fieldToRenumber,
  start = 1
) {
  sender.setState({
    [listName]: deletedList(
      sender.state[listName],
      index,
      fieldToRenumber,
      start
    )
  });
}

export const isAllowed = (values, maxValue) => {
  const { formattedValue, floatValue } = values;
  return formattedValue === '' || floatValue <= maxValue;
};
