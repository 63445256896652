import { CharToInt, IntToChar } from './utils';

export function CheckIEAL(ie) {
  if (ie.length !== 9) return false;
  var b = 9,
    soma = 0;
  for (var i = 0; i <= 7; i++) {
    soma += CharToInt(ie.charAt(i)) * b;
    --b;
  }
  soma *= 10;
  var dig = soma - Math.floor(soma / 11) * 11;
  if (dig === 10) {
    dig = 0;
  }
  return IntToChar(dig) === ie.charAt(8);
} //AL
