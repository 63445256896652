import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import styles from './GerenciamentoFinanceiro.module.scss';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';

import { locale, loadMessages } from 'devextreme/localization';
import DataGrid, { Paging, GroupPanel, ColumnChooser, SearchPanel, Grouping, ColumnFixing, Summary, TotalItem, GroupItem, Pager } from 'devextreme-react/data-grid';
import ptMessages from '../../../../../assets/js/pt.json';

import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina, emptyText } from '../../../../shared/utils/Utils';
import { gerenciamentoFinanceiroService } from './GerenciamentoFinanceiro.service';
import notify from 'devextreme/ui/notify';
import { Export } from 'devextreme-react/bar-gauge';
import config from 'devextreme/core/config';
import DateToolTip from '../../../../components/dateTooltip/DateToolTip';
import ListToolTip from '../../../../components/listToolTip/ListToolTip';
import TextToolTip from '../../../../components/textToolTip/TextToolTip';
import { GerenciamentoFinanceiroFilters } from '../../../../components/business/GerenciamentoFinanceiroFilters/GerenciamentoFinanceiroFilters';
import ScreenHeader from '../../../../components/header/ScreenHeader/ScreenHeader';

export function GerenciamentoFinanceiroReport(props) {

    let today = new Date();

    const PATH_MENU = menus.COD_80530;
    const [showPreReports, setShowPreReports] = useState(false);
    const [reports, setReports] = useState([]);
    const [showSearch, setShowSearch] = useState(false);
    const [chosenReport, setChosenReport] = useState([]);
    const [preSearching, setPreSearching] = useState(false);
    const [searching, setSearching] = useState(false);
    const [startDate] = useState(new Date(today.getFullYear(), today.getMonth(), 1));
    const [endDate] = useState(new Date(today.getFullYear(), today.getMonth() + 1, 0));
    const [filters, setFilters] = useState([]);
    const [resultData, setResultData] = useState([]);
    const [preDays, setPreDays] = useState(0);
    const [appliedFilters, setAppliedFilters] = useState({
        dataInicio: '',
        dataFim: '',
        tipoOperacao: [],
        situacao: [],
        pessoa: [],
        centroMonetario: [],
        conta: [],
        operacaoFinanceira: [],
        descricao: ''
    });

    config({
        defaultCurrency: 'BRL'
    });

    const columns = [
        { dataField: 'tipoOperacaoDescricao', caption: 'Tipo de Operação', width: '130', groupIndex: 0 },
        { dataField: 'dataEmissao', caption: 'Emissão', dataType: 'date', width: '90' },
        { dataField: 'dataPrevisao', caption: 'Previsão', dataType: 'date', width: '90' },
        { dataField: 'dataVencimento', caption: 'Vencimento', dataType: 'date', width: '90' },
        { dataField: 'dataPagamento', caption: 'Pagamento', dataType: 'date', width: '90' },
        { dataField: 'pessoa.nome', caption: 'Pessoa', width: '170' },
        { dataField: 'pessoa.cnpjCpf', caption: 'CPF/CNPJ', width: '170'},
        { dataField: 'descricao', caption: 'Descrição', width: '90' },
        { dataField: 'autorizado', caption: 'Autorizado', width: '130' },
        { dataField: 'placa', caption: 'Placa', width: '130' },
        { dataField: 'odometro', caption: 'KM', width: '130' },
        { dataField: 'conta.descricao', caption: 'Conta', width: '130' },
        { dataField: 'numeroDocumento', caption: 'N° Documento', width: '110' },
        { dataField: 'operacao.descricao', caption: 'Operação', width: '130' },
        { dataField: 'parcela', caption: 'Parcela', width: '70' },
        { dataField: 'valor', caption: 'Valor Bruto', format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'acrescimo', caption: 'Acréscimo', format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'desconto', caption: 'Desconto', format: { type: 'currency', precision: 2 }, width: '100' },
        { dataField: 'percentualTaxaCartao', caption: '% Taxa cartão', width: '110', format: { precision: 2 },  visible: false },
        { dataField: 'valorTaxaCartao', caption: 'Valor taxa cartão', format: { type: 'currency', precision: 2 }, width: '110'},
        { dataField: 'valorLiquido', caption: 'Valor líquido', format: { type: 'currency', precision: 2 }, width: '110' },
        { dataField: 'centroMonetario.nome', caption: 'Centro Monetário', width: '100' },
        { dataField: 'tipoOcorrenciaDescricao', caption: 'Ocorrência', width: '100', visible: false },
        { dataField: 'tipoDocumento.descricao', caption: 'Tipo de Documento', width: '150', visible: false },
        { dataField: 'formaPagamento.descricao', caption: 'Forma Pagamento', width: '150' },
        { dataField: 'rede_Descricao', caption: 'Rede', width: '70' },
        { dataField: 'bandeira_Descricao', caption: 'Bandeira', width: '70' },
        { dataField: 'produtoCartao_Descricao', caption: 'Produto Cartão', width: '70' },
        { dataField: 'nsu', caption: 'NSU', width: '70' },
        { dataField: 'serie', caption: 'Série', width: '70', visible: false },
        { dataField: 'tipoDocumentoFiscalSigla', caption: 'Modelo', width: '70', visible: false },
        { dataField: 'numeroAutorizacao', caption: 'Número Autorização', width: '70' },
        { dataField: 'situacaoDescricao', caption: 'Situação', width: '70' },
        { dataField: 'conciliado', caption: 'Conciliado', width: '100' },
        { dataField: 'historico', caption: 'Histórico', width: '110', visible: false },
        { dataField: 'empresa.cnpj', caption: 'CNPJ', width: '100', visible: false },
        { dataField: 'empresa.razaoSocial', caption: 'Razão social', width: '200', visible: false },
        { dataField: 'empresa.nomeFantasia', caption: 'Nome fantasia', width: '200', visible: false }
    ];

    const getReports = async () => {
        const response = await gerenciamentoFinanceiroService.getReports();
        if (!response.length) {
            toggleSearch();
        } else {
            setReports(response);
        }
    };

    const getReport = async (report) => {
        setAppliedFilters(filters => {
            return {
                ...filters,
                dataInicio: startDate,
                dataFim: endDate,
            }
        })

        setShowSearch(false);
        if (chosenReport.id === report.id) {
            togglePreReports();
        } else {
            setChosenReport([]);
            const response = await gerenciamentoFinanceiroService.getReport().find(x => x.id === report.id);
            setChosenReport(response);
            togglePreReports();
        }
    };

    const togglePreReports = () => {
        setShowPreReports(true);
    };

    useEffect(() => {
        getReports();
        loadMessages(ptMessages);
        locale(navigator.language);
    }, []);

    const getPreReport = async (report) => {
        setSearching(true);
        setPreDays(report.dias);
        setPreSearching(true);

        setAppliedFilters(filters => {
            return {
                ...filters,
                dataInicio: report.dataInicio,
                dataFim: report.dataFim,
                tipoOperacao: report.tipoOperacao,
                tipoData: report.tipoData,
                situacao: report.situacao
            }
        });

        if (!appliedFilters) notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
        const response = await gerenciamentoFinanceiroService.find({
            tipoOperacao: report.tipoOperacao,
            situacao: report.situacao,
            pessoa: [],
            centroMonetario: [],
            conta: [],
            operacaoFinanceira: [],
            descricao: '',
            tipoData: report.tipoData,
            dataInicio: report.dataInicio,
            dataFim: report.dataFim
        });
        if (response.notificationLevel === 1) {
            if (response.data.length) {
                if (!response.data.length) {
                    notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
                }
                setResultData(response.data);
            } else {
                notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
            }
        } else {
            notify(response.message + ' Refaça a pesquisa informando um período menor.', 'info', 2000, 'top');
        }
        setPreSearching(false);
        setSearching(false);
    }

    const toggleSearch = () => {
        setAppliedFilters(filters => {
            return {
                ...filters,
                dataInicio: startDate,
                dataFim: endDate,
            }
        });
        setShowPreReports(false);
        setShowSearch(true);
    };

    const resultGrid = () => {

        return (
            <div className={`${styles.gerenciamentoFinanceiroResult} mt-3`}>
                <div className={`${styles.results} shadow-sm p-3 bg-white`}>
                    <h2>RESULTADOS</h2>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={`${styles.appliedFilters}`}>
                                <DateToolTip inicial={filters.dataInicio} final={filters.dataFim}/>
                                <ListToolTip data={filters.conta} message={'Contas selecionadas'}/>
                                <ListToolTip data={filters.operacaoFinanceira} message={'Operações selecionadas'}/>
                                <ListToolTip data={filters.centroMonetario} message={'Centros monetários selecionados'}/>
                                <ListToolTip data={filters.situacao} message={'Situações selecionadas'}/>
                                <ListToolTip data={filters.tipoOperacao} message={'Tipos de lançamentos selecionados'}/>
                                <ListToolTip data={filters.pessoa} message={'Pessoas selecionadas'}/>
                                <ListToolTip data={filters.estruturas} message={'Estruturas de cartão selecionadas'}/>
                                {filters.tipoDataDesc ?
                                <TextToolTip text={`Tipo de período selecionado: ${filters.tipoDataDesc}`} />
                                :<></>}
                                
                                {!emptyText(filters.descricao) ?
                                    <TextToolTip text={'Descrição informada'}/>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                            <hr />
                            <DataGrid dataSource={resultData} defaultColumns={columns} showBorders={true} allowColumnResizing={true}
                                allowColumnReordering={true} columnResizingMode={'widget'} columnMinWidth={80} rowAlternationEnabled={true}>
                                <GroupPanel visible={true} />
                                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                <Export enabled={true} fileName="GerenciamentoFinanceiro" />
                                <SearchPanel visible={true} highlightCaseSensitive={true} />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={true} />
                                <Summary>
                                    <TotalItem column="acrescimo" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                    <TotalItem column="desconto" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                    <TotalItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                    <TotalItem column="valorTaxaCartao" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                    <TotalItem column="valorLiquido" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />

                                    <GroupItem column="acrescimo" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                        showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="desconto" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                        showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                        showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="valorTaxaCartao" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                        showInGroupFooter={false} alignByColumn={true} />
                                    <GroupItem column="valorLiquido" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                        showInGroupFooter={false} alignByColumn={true} />
                                </Summary>
                                <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
                                <Paging defaultPageSize={15} />
                            </DataGrid>
                            <br />
                        </div>
                    </div>
                </div>
            </div >

        )
    }


    return (
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={false} />
            <div className={`${styles.gerenciamentoFinanceiroReport} mt-3`}>
                <div className="shadow-sm bg-white p-3">
                    <div className={`${styles.filters} ${showPreReports ? styles.expanded : ''} ${showSearch ? styles.search : ''}`}>
                        <div className="container-fluid">
                            <div className={`${styles.filtersrWrapper} row`}>
                                <div className="col-md-12">
                                    <h2>FILTROS</h2>
                                    {reports.length ? <hr /> : <></>}
                                    <div className={styles.buttonWrapper}>
                                        {reports.map(report => (
                                            <div className={styles.reportButton} key={report.id}>
                                                <button className={`${report.type === 1 ? 'btn-secondary' : ''} ${report.type === 2 ? 'btn-light' : ''} btn btn-primary`}
                                                    onClick={report.type === 2 ? toggleSearch : () => getReport(report)}
                                                    disabled={searching}
                                                >
                                                    <i className={report.icon}></i>
                                                </button>
                                                <p>{report.title}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {showPreReports &&
                                <div className={`${styles.preReportsWrapper} ${showPreReports ? styles.fadeIn : ''}`}>
                                    {chosenReport.type === 1 ?
                                        <h3>{chosenReport.title}</h3>
                                        :
                                        <h3>RELATÓRIOS SUGERIDOS DE {chosenReport.title}</h3>
                                    }
                                    <hr />
                                    {chosenReport.title &&
                                        <div className={`${styles.optionButtonWrapper} ${showPreReports ? styles.fadeIn : ''}`}>
                                            {chosenReport.preReports.map(preReport => (
                                                <div className={styles.reportButton} key={preReport.id}>
                                                    <button className={`${preReport.type === 1 ? 'btn-secondary' : ''} ${preReport.type === 2 ? 'btn-light' : ''} btn btn-primary`}
                                                        onClick={() => getPreReport(preReport)} disabled={preSearching}>
                                                        {' '}
                                                        {preSearching && preReport.days === preDays ? (
                                                            <>
                                                                <svg className={styles.spinner} viewBox="0 0 50 50">
                                                                    <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                                    </circle>
                                                                </svg>
                                                            </>
                                                        ) : (
                                                                <>
                                                                    {preReport.icon ?
                                                                        <i className={preReport.icon}></i>
                                                                        :
                                                                        <span>{preReport.text}</span>
                                                                    }
                                                                </>
                                                            )}

                                                    </button>
                                                    {preSearching && preReport.days === preDays ?
                                                        <p>PESQUISANDO...</p>
                                                        :
                                                        <p>{preReport.title}</p>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            }
                            {showSearch &&
                                <GerenciamentoFinanceiroFilters
                                handleResultado={(valorResultado, filters) => {
                                  setResultData(valorResultado);
                                  setFilters(filters);
                                }}
                              />
                            }

                        </div>
                    </div>
                </div>
            </div>
            {
                resultData.length > 0 ? (resultGrid()) : <></>
            }
        </LinxPostos>
    );

}

export default withRouter(GerenciamentoFinanceiroReport);