import React, { Component } from "react";
import {
  handleInputChange,
  InputText,
  // Select
} from "../../../components/formulario/Formulario";

import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import {
  createColumns,
  SituacoesFatura,
  Visualizar,
  doGetCuponsFaturados
} from "./RelatorioFaturamentoVendaPrazo";

import { getFaturados } from "../../../services/ContaCorrente.service";
import moment from "moment";
import { toFloatFormattedDisplayDecimals, getNomePagina } from "../../../shared/utils/Utils";
import { SectionContainer, SectionContent } from "../../../components/section/Content";
import { tagAlert, showWait, hideWait } from "../../../components/alert/Alert";
import Botao from "../../../components/botao/Botao";
import { ModalReenviaEmail } from "../../../components/modal/ModalReenviaEmail";
import { ModalVisualizadorRelatorioFaturado } from "../../../components/modal/ModalVisualizadorRelatorioFaturado";
import Footer from "../../../components/core/footer/Footer";
import LinxPostos from "../../../components/core/linxPostos/LinxPostos";
import { menus } from "../../../shared/constants/MenuConstants";

const titulo = "Histórico de documentos já faturados";
const PATH_MENU = menus.COD_60980;
class HistoricoFaturados extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      historico: [],
      codigoCliente: null,
      campoPessoa: "",
      listDocs: [],
      clickedInfo: [],
      showModalReenviaEmail: false,
      showModalVisualizadorRelatorio: false,
      codigoClienteReenvioEmail: null,
      codigoTituloReenvioEmail: null
    };
  }

  clearState = () => {
    this.setState({
      codigoCliente: null,
      campoPessoa: "",
      historico: []
    });
  };

  doGoBack = () => {
    this.props.history.push("/financeiro/faturamento-vendas-prazo/");
  };

  componentDidMount = async () => {
    const { dados } = this.props;
    if (dados && dados.length === 0) {
      this.doGoBack();
    }

    let { campoPessoa, codigoCliente } = this.state;
    if (!codigoCliente || codigoCliente !== dados.codigo) {
      showWait(
        this,
        titulo,
        "Por favor, aguarde enquanto o histórico é carregado."
      );
      codigoCliente = dados.codigo;
      campoPessoa = dados.nome;
      const historico = [];
      if (codigoCliente) {
        const pesquisa = await this.getFaturados(codigoCliente);
        this.apiToHistorico(pesquisa, historico);
      }
      this.setState({ codigoCliente, campoPessoa, historico });
      hideWait(this);
    }
  };

  getPrazoByDesc = desc => {
    const { prazo } = this.props;
    let result = 0;
    if (prazo && prazo.lista) {
      prazo.lista.forEach(p => {
        if (String(p.label).toUpperCase() === String(desc).toUpperCase()) {
          result = p.value;
          return;
        }
      });
    }
    return result;
  };

  getFaturados = async codigoCliente => {
    const { datas, grupoClientes, dados } = this.props;
    //let codigoTipoPrazo = prazo && prazo.tipo ? prazo.tipo : 0;
    let codigoTipoPrazo = this.getPrazoByDesc(dados.tipoPrazo);
    let codigoAgrupadorClientes =
      grupoClientes && grupoClientes.codigo ? grupoClientes.codigo : 0;

    const { data: dataPesquisa } = await getFaturados(
      //moment(datas.dataDe).format("YYYY-MM-DD"),
      "1970-01-01",
      moment(datas.dataAte).format("YYYY-MM-DD"),
      codigoCliente,
      codigoTipoPrazo,
      codigoAgrupadorClientes
    );

    return dataPesquisa;
  };

  apiToHistorico = (apiList, localList) => {
    if (apiList && apiList.length > 0) {
      apiList.forEach(item => {
        localList.push({
          codigo: item.codigoCliente,
          nome: item.nome,
          numeroFatura: item.numeroTitulo,
          numeroNfeOriginal: item.numeroNfe,
          numeroNfe: item.numeroNfe ? String(item.numeroNfe) : "",
          dataVencimento: moment(item.dataVencimento).format("DD/MM/YYYY"),
          dataFechamento: moment(item.dataEmissao).format("DD/MM/YYYY"),
          valor: "R$ " + toFloatFormattedDisplayDecimals(item.valorLiquido, 2),
          situacao: item.situacao,
          selected: false,
          codigoTitulo: item.codigoTitulo,
          rowVersion: item.rowVersion
        });
      });
    }
  };

  tagButtons = () => {
    return (
      <Botao
        title="Voltar"
        icon="icon-lx-arrow-left"
        onClick={() => {
          this.clearState();
          this.doGoBack();
        }}
      />
    );
  };

  getListDocs = async codigo => {
    const temp = await doGetCuponsFaturados(codigo);
    const listDocs = [];
    listDocs.push(temp);
    return listDocs;
  };

  handleImprimirButtonClick = async codigoTitulo => {
    const listDocs = await this.getListDocs(codigoTitulo);
    this.setState({
      listDocs
    });

    this.forceUpdate(() => {
      window.print();
    });
  };

  handleVisualizarFaturaClick = async codigoTitulo => {
    const listDocs = await this.getListDocs(codigoTitulo);

    this.setState({
      listDocs,
      showModalVisualizadorRelatorio: true
    });
  };

  render() {
    const { sender, dados } = this.props;
    const { campoPessoa, historico } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <div className="row">
              <div className="col-5">
                <InputText value={campoPessoa} name="campoPessoa" label="Cliente:" onChange={this.handleInputChange}
                  disabled />
              </div>
              <div className="col-4">
                <InputText value={dados.tipoPrazo} name="tipoPrazo" label="Tipo de prazo:"
                  onChange={this.handleInputChange} disabled />
              </div>
            </div>
            <SectionContent title={titulo}>
              <div className="table-ctn table-buttons-last-column">
                <ReactTable defaultPageSize={10} previousText="Anterior" nextText="Próximo"
                  loadingText="Carregando registros..." pageText="Página" ofText="de" rowsText="registros"
                  noDataText="Nenhum registro para exibir" data={historico} columns={createColumns( sender,
                  SituacoesFatura.Faturados, historico, false, false, this )} getTdProps={(state, rowInfo, column,
                  instance)=> {
                  return {
                  onClick: (e, handleOriginal) => {
                  const clickedInfo = [];
                  clickedInfo.push({ ...rowInfo.row });
                  this.setState({ clickedInfo });
                  }
                  };
                  }}
                  />
              </div>
            </SectionContent>
            <Footer>
              <div className="section-footer">{this.tagButtons()}</div>
            </Footer>
            {tagAlert(this)}
            <Visualizar dados={this.state.clickedInfo} docs={this.state.listDocs}
              nomeFantasia={sender.state.nomeFantasia} />
            <ModalVisualizadorRelatorioFaturado titulo={titulo} active={this.state.showModalVisualizadorRelatorio}
              handleCloseModal={()=> {
              this.setState({ showModalVisualizadorRelatorio: false });
              }}
              visualizarProps={{
            dados: this.state.clickedInfo,
            docs: this.state.listDocs,
            nomeFantasia: sender.state.nomeFantasia
          }}
              />
              <ModalReenviaEmail active={this.state.showModalReenviaEmail}
                codigoCliente={this.state.codigoClienteReenvioEmail} codigoTitulo={this.state.codigoTituloReenvioEmail}
                handleCloseModal={()=> {
                this.setState({
                showModalReenviaEmail: false,
                codigoClienteReenvioEmail: null,
                codigoTituloReenvioEmail: null
                });
                }}
                />
          </SectionContainer>
        </LinxPostos>
       
      </>
    );
  }
}

HistoricoFaturados = withRouter(HistoricoFaturados);
export { HistoricoFaturados };
