import { getUrlLinxCore } from '../../../../services/core/Auth.service';

const dadosAdicionaisDocumentoFiscal = {
  listaTiposOperacao,
  listaTiposDadoAdicional,
  filtraDadosAdicionais,
  listaTagsDadoAdicional,
  listaOperacoes,
  recuperar,
  inserir,
  alterar
};

async function listaTiposOperacao() {
  const response = await getUrlLinxCore().get(
    '/dadosAdicionaisDocumentoFiscal/ListarTipoOperacao'
  );
  return response.data;
}

async function listaTiposDadoAdicional() {
  const response = await getUrlLinxCore().get(
    '/dadosAdicionaisDocumentoFiscal/ListarTipoDadoAdicional'
  );
  return response.data;
}

async function listaTagsDadoAdicional(codigoDadoAdicionalDocumentoFiscal) {
  const response = await getUrlLinxCore().get(
    `/dadosAdicionaisDocumentoFiscal/ListarTagsDadoAdicional?codigoDadoAdicionalDocumentoFiscal=${codigoDadoAdicionalDocumentoFiscal}`
  );
  return response.data;
}

async function listaOperacoes() {
  const response = await getUrlLinxCore().get(
    '/dadosAdicionaisDocumentoFiscal/ListarOperacoesDadoAdicional'
  );
  return response.data;
}

async function filtraDadosAdicionais(consulta) {
  const response = await getUrlLinxCore().post(
    '/dadosAdicionaisDocumentoFiscal/ListarComFiltro',
    {
      query: consulta
    }
  );
  return response.data;
}

async function recuperar(codigoDadoAdicionalDocumentoFiscal) {
  const response = await getUrlLinxCore().get(
    `/dadosAdicionaisDocumentoFiscal/Recuperar?codigoDadoAdicionalDocumentoFiscal=${codigoDadoAdicionalDocumentoFiscal}`
  );
  return response.data;
}

async function inserir(entity) {
  const response = await getUrlLinxCore()
    .post('/dadosAdicionaisDocumentoFiscal/Inserir', entity)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function alterar(entity) {
  const response = await getUrlLinxCore()
    .put('/dadosAdicionaisDocumentoFiscal/Editar', entity)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

export default dadosAdicionaisDocumentoFiscal;
