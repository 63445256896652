import React, { useState, useEffect } from 'react';
import styles from './LinxButton.module.scss';

export const LinxButtonConferencia = props => {
  const [disabled, setDisabled] = useState(props.disabled);
  const [labelNormal, setLabelNormal] = useState(props.labelNormal);
  const [labelDisabled, setLabelDisabled] = useState(
    props.labelDisabled ? props.labelDisabled : props.labelNormal
  );
  const [secondary, setSecondary] = useState(props.secondary);

  useEffect(() => {
    setDisabled(props.disabled);
    setLabelNormal(props.labelNormal);
    setLabelDisabled(props.labelDisabled);
    setSecondary(props.secondary);
  }, [props.disabled, props.labelNormal, props.labelDisabled, props.secondary]);

  return (
    <button
      className={
        secondary
          ? 'btn btn-margin btn-secondary'
          : 'btn btn-margin btn-primary'
      }
      onClick={props.onClick}
      disabled={disabled}
    >
      {' '}
      {disabled ? (
        <>
          <svg className={styles.spinner} viewBox="0 0 50 50">
            <circle
              className={styles.path}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
          {labelDisabled}
        </>
      ) : (
        labelNormal
      )}
    </button>
  );
};
