import React from 'react';
import styles from './LinkedinButton.module.scss';

export default function LinkedinButton() {
  return (
    <div className={styles.LinkedinButton}>
      <a
        href="https://www.linkedin.com/company/linxretail"
        target="_blank"
        rel="noopener noreferrer"
        ><i className={`icon icon-lx-linkedin`}></i></a>
    </div>
  );
}
