export const currentDateWithoutTime = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return now;
};

/**
 * Retorna uma mensagem indicando os filtros selecionados.
 *
 * @function
 * @returns {string} Mensagem indicando os filtros selecionados.
 * @example
 * const mensagemFiltro = filtragemPorHoje();
 * console.log(mensagemFiltro);  // "Filtragem baseada nos filtros de hoje."
 */
export const getFilterLabel = (dataInicio, dataFim, operador, situacao, pedido) => {

  const today = currentDateWithoutTime();

  if ((dataInicio || new Date()).getTime() === (today || new Date()).getTime() &&
      (dataFim || new Date()).getTime() === (today || new Date()).getTime() &&
      !operador &&
      !situacao &&
      !pedido) {
    return "Filtrado por Hoje.";
  }

  let filterMessages = [];

  if ((dataInicio || new Date()).getTime() === today.getTime() && (dataFim || new Date()).getTime() === today.getTime()) {
    filterMessages.push("Hoje");
  } else if ((dataInicio || new Date()).getTime() !== (dataFim || new Date()).getTime()) {
    filterMessages.push(`De ${(dataInicio || new Date()).toLocaleDateString()} até ${(dataFim || new Date()).toLocaleDateString()}`);
  }

  if (operador) {
    filterMessages.push(`Operador: ${operador}`);
  }

  if (situacao) {
    filterMessages.push(`Situação: ${situacao}`);
  }

  if (pedido) {
    filterMessages.push(`Nº do Pedido: ${pedido}`);
  }

  return filterMessages.length ? `Filtrado por ${filterMessages.join(', ')}` : "Sem filtro";
};
