
import React, { useEffect, useState } from 'react';
import { maskedCnpjCpf } from '../../../../../shared/utils/Utils';

import { locale } from 'devextreme/localization';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { PrintStyle } from './PrintStyles';

import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { ConfiguracoesGeraisService } from '../../../../../services/configuracoesGerais/ConfiguracoesGerais.service';
import { getConsultaTelefoneEmpresa } from '../../../../relatorios/empresa/ConsultaEmpresa.service';
import { ReciboAssinatura } from './ReciboAssinatura';
import { ReciboComodato } from './ReciboComodato';
import { ReciboConsignado } from './ReciboConsignado';
import { ReciboExtra } from './ReciboExtra';
import { ReciboObs } from './ReciboObs';
import { ReciboLocacao } from './ReciboLocacao';

export function PaginaRecibo({ styles }) {
  moment.locale('pt-br');
  locale('pt-br');
  const { values } = useSelector(selectNovaOs);
  const empresa = useSelector(selectConsignadoComodatoSharedData).empresa;

  const sty = new PrintStyle();

  const [setUsers] = useState([]);
  const [fone, setFone] = useState([]);
  const [urlImage, setUrlImage] = useState('');
  const [pedidoComodato, setPedidoComodato] = useState(null);
  const [pedidoLocacao, setPedidoLocacao] = useState(null);
  const [pedidoConsignado, setPedidoConsignado] = useState(null);

  useEffect(() => {
    ConfiguracoesGeraisService.getInstance()
      .byId(514)
      .then(data => {
        setUrlImage(data.valor.toString());
      });

    getConsultaTelefoneEmpresa().then(function(fon) {
      setFone(fon.data);
    });

    setPedidoComodato(values.pedidos.find(x => x.tipoPedido.id === 3));
    setPedidoLocacao(values.pedidos.find(x => x.tipoPedido.id === 8));
    setPedidoConsignado(values.pedidos.find(x => x.tipoPedido.id === 2));
  }, [values.pedidos, setUrlImage, setUsers, setFone]);

  return (
    <>
      {values.cliente && empresa && (
        <div id="impressaoRecibo">
          <section className={`${styles.sheet}`} style={sty.section}>
            {[1, 2].map((x, i) => (
              <>
                {x === 2 && <><br /><div className="w-100" style={{ borderTop: '1px dashed red' }}></div><br /></>}
                <div key={i} className="text-center">
                  RECIBO DE RECOLHIMENTO DE CONSIGNADO/COMODATO/LOCAÇÃO {x}ªVIA {x === 1 ? `LOJA` : 'CLIENTE'}
                </div>
                <br />
                <div className="row">
                  <div className="col-8">
                    <div style={sty.divHeader}>
                      <h1 className="ml-1 p-0 mb-0" style={sty.h1}>
                        NF COMODATO:{' '}
                        <small style={{ ...sty.h1Small }}>{((values.pedidos || []).find(x => x.tipoPedido.id === 3) || {}).numeroNF || '---'}</small>
                      </h1>
                      <h1 className="ml-1 p-0 mb-0" style={sty.h1}>
                        {' '}
                        NF CONSIGNADO:{' '}
                        <small style={{ ...sty.h1Small }}>{((values.pedidos || []).find(x => x.tipoPedido.id === 2) || {}).numeroNF || '---'}</small>
                      </h1>
                      <h1 className="ml-1 p-0 mb-0" style={sty.h1}>
                        {' '}
                        NF LOCAÇÂO:{' '}
                        <small style={{ ...sty.h1Small }}>{((values.pedidos || []).find(x => x.tipoPedido.id === 8) || {}).numeroNF || '---'}</small>
                      </h1>
                      <h1 className="ml-1 p-0 mb-0 float-right" style={{ ...sty.h1 }}>
                        DATA REC/DEV: <small style={{ ...sty.h1Small }}>{moment(new Date(values.dataRecolhaDevolucao)).format('DD/MM/YYYY')}</small>
                      </h1>
                    </div>
                    <div className="text-center">
                      {empresa.nomeFantasia} - CNPJ: {formatCNPJ(empresa.cnpj)}
                    </div>
                    <div className="text-center">
                      {empresa.endereco.logradouro}, {empresa.endereco.numero} - {empresa.endereco.bairro} - {empresa.endereco.localidade.descricao} -{' '}
                      {empresa.endereco.unidadeFederativa.sigla} - {formatCEP(empresa.endereco.cep)}
                    </div>
                    <div className="text-center">{fone}</div>
                    <h5 className="mt-3 mb-0" style={sty.h3}>
                      <b>DECLARO TER RECEBIDO DO CLIENTE:</b> {values.cliente.nome.toUpperCase()}
                    </h5>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      CPF/CNPJ: {maskedCnpjCpf(values.cliente.cnpjCpf)}{' '}
                      {values.cliente.inscricaoEstadual && <>InscricaoEstadual: {maskedCnpjCpf(values.cliente.inscricaoEstadual)}</>} Celular:{' '}
                      {values.cliente.contato.dddCel} {values.cliente.contato.celular}
                    </p>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      ENDEREÇO: {values.enderecoEntrega.logradouro.toUpperCase()}, {values.enderecoEntrega.numero} {values.enderecoEntrega.complemento} BAIRRO:{' '}
                      {(values.enderecoEntrega.bairro || '').toUpperCase()} MUNICÍPIO: {values.enderecoEntrega.municipio.descricao}
                    </p>
                    {values.enderecoRecolha && (<p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      END. RECOLHA: {values.enderecoRecolha.logradouro.toUpperCase()}, {values.enderecoRecolha.numero} {values.enderecoRecolha.complemento} BAIRRO:{' '}
                      {(values.enderecoRecolha.bairro || '').toUpperCase()} MUNICÍPIO: {values.enderecoRecolha.municipio.descricao}
                    </p>
                    )}
                    <br />
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>OS PRODUTOS CONSTANTES NA COLUNA RETORNO, CONFORME ABAIXO RELACIONADO:</p>
                  </div>
                  <div className="col-4 text-right">{urlImage && <img src={urlImage} alt="Logo" style={{ height: '150px' }} />}</div>
                </div>
                <div className="row">
                  <div className="col-8">
                    {pedidoComodato && <ReciboComodato pedido={pedidoComodato}></ReciboComodato>}
                    {pedidoLocacao && <ReciboLocacao pedido={pedidoLocacao}></ReciboLocacao>}
                    {pedidoConsignado && <ReciboConsignado pedido={pedidoConsignado}></ReciboConsignado>}
                    <ReciboExtra></ReciboExtra>
                  </div>
                  <div className="col-4">
                    <ReciboObs></ReciboObs>
                    <br />
                    <br />
                    <ReciboAssinatura via={x}></ReciboAssinatura>
                  </div>
                </div>
              </>
            ))}
          </section>
        </div>
      )}
    </>
  );
}

function formatCNPJ(data) {
  var result = data.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');

  return result;
}

function formatCEP(data) {
  var cep = data
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');

  return cep;
}
