import Tooltip from 'rc-tooltip';
import React from 'react';
import styles from './PerfilOptions.module.scss';
import 'rc-tooltip/assets/bootstrap_white.css';
import {logout} from '../../../../authentication/Auth';
import { getDadosLogin, setDadosLogin, CNPJ_DEFAULT, updateTema } from '../../../../shared/utils/Utils';

const perfilOptions = props => {
  const getName = () => {
    let positions = props.user.split(' ');

    if (positions.length > 1) {
      return positions[0] + ' ' + positions[positions.length - 1];
    } else {
      return positions[0];
    }
  };

  const getFirstName = () => {
    let positions = props.user.split(' ');

    return positions[0];
  };

  const handleSair = () => {
    const dadosLogin = getDadosLogin();
    logout();
    setDadosLogin(
      CNPJ_DEFAULT,
      dadosLogin.url,
      -999,
      dadosLogin.logo,
      dadosLogin.logoSmall,
      dadosLogin.tipoSiteUrl,
      [],
      '',
      '',
      '',
      'default-theme',
      dadosLogin.share
    );
    updateTema();
    props.history.push('/login');
  }

  return (
    <div className={`${styles.perfilOptions} perfilOptions`}>
      <li className={`${styles.navItem} navItem dropdown d-none d-sm-block`}>
        <Tooltip placement="bottom" overlay={<span>{getName()}</span>}>
          <span
            className={`${styles.navLink} lx-icon dropdown-toggle pt-0 pb-0 text-uppercase`}
            onClick={props.toggleProfileOptions}
          >
            <i className={`${styles.profile} icon icon-lx-user`}></i>
            
            
            
            {` ${getFirstName()}`}
          </span>
        </Tooltip>

        {props.showProfileOptions && (
          <div className="show dropdown-menu">
            <h6 className="dropdown-header"> {getName()} </h6>
            <span className="dropdown-item" onClick={props.trocaSenhaHandle}>
              {' '}
              Alterar senha{' '}
            </span>
            <div className="dropdown-divider"></div>
            <span className="dropdown-item" onClick={handleSair}>Sair</span>
            {/* <Link className="dropdown-item" to="/login">
              Sair
            </Link> */}
          </div>
        )}
        {props.showProfileOptions && (
          <div
            className={styles.dropdownOverlay}
            onClick={props.toggleProfileOptions}
          ></div>
        )}
      </li>
    </div>
  );
};

export default perfilOptions;
