import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styles from './DadosAdicionaisDocumentoFiscalFiltros.module.scss';
import { TextBox, CheckBox } from 'devextreme-react';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../../shared/utils/Utils';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import ptMessages from '../../../../../assets/js/pt.json';
import { locale, loadMessages } from 'devextreme/localization';
import config from 'devextreme/core/config';
import dadosAdicionaisDocumentoFiscal from '../service/DadosAdicionaisDocumentoFiscal.service';
import notify from 'devextreme/ui/notify';
import { ALPHANUMERIC_WITH_SPACE_PATTERN } from '../../../../shared/constants/Const';
import { Link } from 'react-router-dom';

function DadosAdicionaisDocumentoFiscalFiltros(props) {
  const PATH_MENU = menus.COD_60430;

  const [showSearch] = useState(false);
  const [entrada, setEntrada] = useState(false);
  const [saida, setSaida] = useState(false);
  const [interesseContribuinte, setInteresseContribuinte] = useState(false);
  const [interesseFisco, setInteresseFisco] = useState(false);
  const [descricaoDadoAdicional, setDescricaoDadoAdicional] = useState('');
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);

    config({
      defaultCurrency: 'BRL'
    });
  }, []);

  const doSearch = async () => {
    const tiposDadoAdicionalSelecionados = [];
    const tiposOperacaoSelecionados = [];
    if (saida) tiposOperacaoSelecionados.push(1);
    if (entrada) tiposOperacaoSelecionados.push(2);
    if (interesseContribuinte) tiposDadoAdicionalSelecionados.push(1);
    if (interesseFisco) tiposDadoAdicionalSelecionados.push(2);
    const consulta = {
      descricao:
        String(descricaoDadoAdicional) === 'null'
          ? ''
          : String(descricaoDadoAdicional),
      tiposDadoAdicional: tiposDadoAdicionalSelecionados,
      tiposOperacao: tiposOperacaoSelecionados
    };

    const resultadoTratado = [];

    setSearching(true);
    try {
      const response = await dadosAdicionaisDocumentoFiscal.filtraDadosAdicionais(
        consulta
      );
      if (response.notificationLevel === 1) {
        response.data.forEach(element => {
          resultadoTratado.push({
            id: element.id,
            descricaoResumida: element.descricaoResumida,
            entrada: element.entrada ? 'SIM' : 'NÃO',
            saida: element.saida ? 'SIM' : 'NÃO',
            fisco: element.fisco ? 'SIM' : 'NÃO',
            contribuinte: element.contribuinte ? 'SIM' : 'NÃO'
          });
        });
        if (response.data.length) {
          props.history.push({
            pathname: '/fiscal/dados-adicionais-nf/resultado',
            state: {
              data: resultadoTratado,
              appliedfilters: consulta
            }
          });
        } else {
          notify(
            'Não foram encontrados registros para esta pesquisa.',
            'info',
            2000,
            'top'
          );
          setSearching(false);
        }
      } else {
        notify('Não existem dados para os filtros informados.', 'error', 5000);
      }
    } finally {
      setSearching(false);
    }
  };

  const clearFilters = () => {
    setDescricaoDadoAdicional(' ');
    setEntrada(false);
    setSaida(false);
    setInteresseContribuinte(false);
    setInteresseFisco(false);
  };

  const handleValueChange = e => {
    let key = e.event.key;

    let regex = new RegExp(ALPHANUMERIC_WITH_SPACE_PATTERN);
    if (!regex.test(key)) {
      e.event.preventDefault();
      return false;
    }
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
      <div className={`${styles.dadosAdicionaisFilters} mt-3`}>
        <div className="row">
          <div className="col-md-12">
            <Link
              to="/fiscal/dados-adicionais-nf/cadastro"
              type="button"
              className={`${styles.newDadoAdicional} btn bg-white shadow-sm`}
            >
              Novo Cadastro
            </Link>
          </div>
        </div>

        <div className="shadow-sm bg-white p-3">
          <div className={`${showSearch ? styles.search : ''}`}>
            <div className="container">
              <div className={`${styles.filtersrWrapper} row`}>
                <div className="col-md-12">
                <h2>DADOS ADICIONAIS DA NOTA FISCAL</h2>
                  <hr />

                  <div
                    className={`${showSearch ? styles.fadeIn : ''} ${
                      styles.searchWrapper
                    }`}
                  >
                    <div className="row">
                      <div
                        className={`${styles.input} col-md-12 form-group mb-1`}
                      >
                        <span>Descrição</span>
                        <TextBox
                          placeholder="Pesquise no texto da descrição resumida ou descrição completa."
                          showClearButton={true}
                          value={descricaoDadoAdicional}
                          onValueChanged={e =>
                            setDescricaoDadoAdicional(e.value)
                          }
                          onKeyPress={e => handleValueChange(e)}
                          disabled={searching}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={`${styles.input} ${styles.gridCheckBox} col-sm-3`}
                      >
                        <ul className={`${styles.ul}`}>
                          <li className={`${styles.li} ${styles.box}`}>
                            <span>Tipo de operação</span>
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Entrada"
                              value={entrada}
                              onValueChanged={e => setEntrada(e.value)}
                            />
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Saída"
                              value={saida}
                              onValueChanged={e => setSaida(e.value)}
                            />
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${styles.input} ${styles.gridCheckBox} col-sm-3`}
                      >
                        <ul className={`${styles.ul}`}>
                          <li className={`${styles.li} ${styles.box}`}>
                            <span>Tipo de dado adicional</span>
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Interesse do contribuinte"
                              value={interesseContribuinte}
                              onValueChanged={e =>
                                setInteresseContribuinte(e.value)
                              }
                            />
                          </li>
                          <li className={`${styles.li}`}>
                            <CheckBox
                              text="Interesse do fisco"
                              value={interesseFisco}
                              onValueChanged={e => setInteresseFisco(e.value)}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <footer className="text-right mt-4">
                      {/* <button className="btn btn-default"> Salvar relatório </button> */}
                      <button
                        className="btn btn-light"
                        onClick={clearFilters}
                        disabled={searching}
                      >
                        {' '}
                        Limpar filtros{' '}
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={doSearch}
                        disabled={searching}
                      >
                        {' '}
                        {searching ? (
                          <>
                            <svg className={styles.spinner} viewBox="0 0 50 50">
                              <circle
                                className={styles.path}
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                              ></circle>
                            </svg>
                            PESQUISANDO...
                          </>
                        ) : (
                          `PESQUISAR`
                        )}
                      </button>
                    </footer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LinxPostos>
  );
}
export default withRouter(DadosAdicionaisDocumentoFiscalFiltros);
