import React, { useCallback } from 'react';

import { alert } from 'devextreme/ui/dialog';
import { useHistory } from 'react-router';
import { SessionManager } from '../../../../shared/utils/sessionManager';
import { VALUES_OS } from '../../consignado-comodato-nova-os/ValuesOsKey';
import { CriarNovaOsVaziaService } from '../../consignado-comodato-nova-os/usercases/criarNovaOsVazia.service';
import { ApiIntegrarVTexService } from '../usercases/ApiIntegrarVtex.service';
import styles from './../OSsConComodato.module.scss';
import { Button, Dropdown } from 'react-bootstrap';
import { selectConsignadoComodatoSharedData, useConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { useDispatch, useSelector } from 'react-redux';
import { TurnoPedidoService } from '../../../../services/turno-pedido/TurnoPedido.service';
import Swal from 'sweetalert2';
import { AdicionarSangria } from './sangria/adicionarSangria';
import { AdicionarSuprimento } from './suprimento/adicionarSuprimento';

export function HeaderList({ setSearching, setSearchingMessage, headerFilter }) {
  const history = useHistory();
  const { turnoAtual, parametro542 } = useSelector(selectConsignadoComodatoSharedData);
  const { updateState } = useConsignadoComodatoSharedData();
  const dispatch = useDispatch();

  const onClickAddOs = useCallback(
    e => {
      e.preventDefault();
      const newOs = new CriarNovaOsVaziaService().execute();
      new SessionManager().saveEncryptedData(VALUES_OS, newOs);
      history.push('/operacoes/pedidos/novo');
    },
    [history],
  );
  const onIntegrar = useCallback(
    async e => {
      e.preventDefault();
      setSearching(true);
      setSearchingMessage('Buscando pedidos VTEX...');
      try {
        const h = headerFilter.current;
        new ApiIntegrarVTexService()
          .execute()
          .then(() => {
            console.log(headerFilter.current);
            console.log(h);
            h.executeAplicarFiltro();
          })
          .catch(error => {
            setSearching(false);
            alert(error.message, 'Erro');
          });
      } catch (error) {}
    },
    [setSearching, setSearchingMessage, headerFilter],
  );
  const abrirTurnoClick = useCallback(
    async e => {
      e.preventDefault();
      setSearching(true);
      setSearchingMessage('Abrindo turno...');
      try {
        var ret = await TurnoPedidoService.getInstance().abrirTurno();
        dispatch(updateState({ name: 'turnoAtual', data: ret }));
        setSearching(false);
      } catch (error) {
        setSearching(false);
        Swal.fire('Erro', error.message, 'error', 'OK');
      }
    },
    [setSearching, setSearchingMessage, dispatch, updateState],
  );
  const fecharTurnoClick = useCallback(
    async e => {
      e.preventDefault();
      setSearching(true);
      setSearchingMessage('Fechando turno...');
      try {
        await TurnoPedidoService.getInstance().fecharTurno();
        dispatch(updateState({ name: 'turnoAtual', data: null }));
        setSearching(false);
      } catch (error) {
        setSearching(false);
        Swal.fire('Erro', error.message, 'error', 'OK');
      }
    },
    [setSearching, setSearchingMessage, dispatch, updateState],
  );
  return (
    <div className={`d-flex row ${styles.header}`}>
      <div className="col col-12">
        <h2 className="float-left ml-3 mt-3">Módulo Consignado Comodato</h2>
        {turnoAtual && parametro542 && parametro542.valor !== '1' && (
          <Dropdown className={`float-right`}>
            <Dropdown.Toggle variant="info" id="dropdown-basic" style={{ height: '60px', width: '60px' }} title='Ações'>
              AÇÕES
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: '200px' }}>
              <Dropdown.Item className="d-flex justify-content-end p-0">
                <Button className="w-100" style={{ border: 'none' }} variant="outline-dark" onClick={fecharTurnoClick}>
                  FECHAR TURNO
                </Button>
              </Dropdown.Item>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item className="d-flex justify-content-end p-0">
                <AdicionarSangria></AdicionarSangria>
              </Dropdown.Item>
              <Dropdown.Item className="d-flex justify-content-end p-0">
                <AdicionarSuprimento></AdicionarSuprimento>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <button style={{ width: '200px' }} className={`btn btn-secondary float-right h-100`} onClick={onIntegrar}>
          INTEGRAÇÃO VTEX
        </button>
        {!turnoAtual && parametro542 && parametro542.valor !== '1' && (
          <button style={{ width: '200px', borderLeft: '1px solid #FFF' }} className={`btn btn-primary float-right h-100`} onClick={abrirTurnoClick}>
            <i className="dx-icon-plus"></i>
            <span className="ml-1">ABRIR TURNO</span>
          </button>
        )}
        <button style={{ width: '200px' }} className={`btn btn-primary float-right h-100`} onClick={onClickAddOs}>
          <span>NOVO PEDIDO</span>&nbsp;&nbsp;
          <i className="dx-icon-plus"></i>
        </button>
      </div>
    </div>
  );
}
