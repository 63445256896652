import { getDadosLogin } from '../../shared/utils/Utils';
import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import memoryCache from 'memory-cache';

export class ApiListarEmpresasService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute() {
    try {
      const dadosLogin = getDadosLogin();
      const cacheKey = `api_recuperar_empresa_${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        // Retorna o resultado em cache se existir
        return cachedData;
      } else {
        const response = await this.urlLinxCore.get(`/Empresa`);
        const data = response.data.itens;
        memoryCache.put(cacheKey, data, 5 * 60 * 1000);
        return data;
      }
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
