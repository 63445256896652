import React, { useCallback, useState, useEffect } from 'react';
import Form, { SimpleItem } from 'devextreme-react/form';
import List from 'devextreme-react/list';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import { getDadosLogin } from '../../../../../../../shared/utils/Utils';
import { CobrancasService } from '../../Cobrancas.service';

export const Formulario = ({ isShow, formRef, formData, setFormData }) => {
  const [documentPayData, setDocumentPayData] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [billingType, setBillingType] = useState(formData.billingType || null);

  const getTypesList = () => [
    {
      label:
        'Cobrança - Este boleto deve ser usado em uma compra já entregue ao cliente, como carnê/nota a prazo. O contrato já está assinado ou a NF emitida, sendo só para cobrança. Diferente dos outros, este boleto pode ser protestado.',
      value: 3,
    },
    {
      label: 'Depósito - Deve ser utilizado apenas para fins de depósito. Ou seja, transferir dinheiro para a sua conta.',
      value: 2,
    },
    {
      label:
        'Proposta - Este boleto vence na data indicada e não pode ser pago após o vencimento. Não pode ser protestado. Use-o para vendas com pagamento antecipado ou como sinal. Comum em E-commerce, onde a venda é finalizada após a quitação.',
      value: 1,
    },
  ];

  const getCustomers = useCallback(async (searchText = '') => {
    if (searchText.length >= 1) {
      setIsLoading(true);
      const dadosLogin = getDadosLogin();
      const response = await CobrancasService.getCustomerByName(dadosLogin.codigoEstabelecimento, searchText);

      if (response && response.result) {
        setDocumentPayData(response.result);
      } else {
        notify(response.message, 'error', 5000);
      }

      setIsLoading(false);
    }
  }, []);

  const handleSearch = useCallback(
    e => {
      if (e.value === '') {
        setDocumentPayData([]);
        setSelectedCustomer(false);
        setFormData({ ...formData, payName: '' });
      } else {
        getCustomers(e.value);
      }
    },
    [getCustomers, formData, setFormData],
  );

  const cleanForm = useCallback(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      discountPerc: 0,
      disctounExpireDate: '',
      assessmentPerc: 0,
      startAssessmentDate: '',
      interestPerc: 0,
      startInterestDate: '',
    }));
  }, [setFormData]);

  const selectPayer = useCallback(payer => {
    setFormData(prevFormData => ({ ...prevFormData, payName: payer.nome, clientId: payer.clienteId, documentPay: payer.cpfCNPJ }));
    setDocumentPayData([]);
    setSelectedCustomer(true);
  }, [setFormData]);

  const handleBillingTypeChange = useCallback(e => {
    console.log('Changing billing type:', e.value);
    setBillingType(e.value);
    setFormData(prevFormData => ({ ...prevFormData, billingType: e.value }));
    if (e.value !== 3) {
      cleanForm();
    }
  }, [cleanForm, setFormData]);

  const validateDateRange = useCallback(({ value }) => {
    const { expireDate, limitDate } = formData;
    console.log('Validating date range:', value, 'Expire:', expireDate, 'Limit:', limitDate);
    return value > new Date(expireDate) && value <= new Date(limitDate);
  }, [formData]);

  const validateFutureDate = useCallback(({ value }) => {
    const currentDate = new Date();
    return value > currentDate;
  }, []);

  const validateDiscountExpireDate = useCallback(({ value }) => {
    const { expireDate } = formData;
    return value < new Date(expireDate);
  }, [formData]);

  const validateLimitDate = useCallback(({ value }) => {
    const { expireDate } = formData;
    return value >= new Date(expireDate);
  }, [formData]);

  const handleFieldChange = useCallback(e => {
    console.log('Field changed:', e.dataField, 'Value:', e.value);
    if (formData[e.dataField] !== e.value) {
      setFormData(prevFormData => ({
        ...prevFormData,
        [e.dataField]: e.value,
      }));
    }
  }, [formData, setFormData]);

  return (
    <>
      {isShow && (
        <>
          <Form ref={formRef} formData={formData}>
            {!formData.payName && (
              <SimpleItem>
                <p>
                  Para iniciar a geração de uma cobrança, comece buscando o pagador. Digite pelo menos três caracteres para realizar a busca. Uma vez encontrados os resultados,
                  selecione o pagador desejado.
                </p>
              </SimpleItem>
            )}
            <SimpleItem
              dataField="payName"
              editorType="dxTextBox"
              editorOptions={{
                placeholder: 'Busque o nome do pagador...',
                valueChangeEvent: 'keyup',
                onValueChanged: handleSearch,
              }}
              label={{ text: 'Nome do Pagador' }}
              isRequired
            />
            {isLoading && (
              <SimpleItem>
                <div className="d-flex justify-content-center align-items-center h-100">
                  <LoadIndicator id="large-indicator" height={60} width={60} />
                </div>
              </SimpleItem>
            )}
            {documentPayData.length > 0 && (
              <SimpleItem>
                <p>{`${documentPayData.length > 1 ? 'Escolha um dos pagadores listados abaixo' : 'Selecione o pagador na lista abaixo.'}`}</p>
                <div className="bg-white rounded text-secondary w-100 border" style={{ maxHeight: '200px', overflow: 'auto' }}>
                  <List items={documentPayData} itemRender={item => <div>{item.nome}</div>} onItemClick={e => selectPayer(e.itemData)} noDataText="Nenhum pagador para exibir" />
                </div>
              </SimpleItem>
            )}
            <SimpleItem
              dataField="billingType"
              editorType="dxSelectBox"
              editorOptions={{
                dataSource: getTypesList(),
                displayExpr: 'label',
                valueExpr: 'value',
                onValueChanged: handleBillingTypeChange,
              }}
              label={{ text: 'Tipo de boleto' }}
              visible={selectedCustomer}
              isRequired
            />
            <SimpleItem
              dataField="billingValue"
              editorType="dxNumberBox"
              editorOptions={{
                format: 'R$ #,##0.00',
                showSpinButtons: false,
                min: 0,
                onValueChanged: handleFieldChange,
              }}
              label={{ text: 'Valor do boleto' }}
              visible={selectedCustomer}
              isRequired
              validationRules={[
                { type: 'range', min: 0, message: 'O valor não pode ser negativo' }
              ]}
            />
            <SimpleItem
              dataField="expireDate"
              editorType="dxDateBox"
              editorOptions={{
                defaultValue: new Date(),
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                onValueChanged: handleFieldChange,
              }}
              label={{ text: 'Vencimento' }}
              visible={selectedCustomer}
              isRequired
              validationRules={[
                { type: 'custom', validationCallback: validateFutureDate, message: 'A data de vencimento deve ser maior que a data atual' }
              ]}
            />
            <SimpleItem
              dataField="limitDate"
              editorType="dxDateBox"
              editorOptions={{
                defaultValue: new Date(),
                type: 'date',
                displayFormat: 'dd/MM/yyyy',
                onValueChanged: handleFieldChange,
              }}
              label={{ text: 'Data limite' }}
              visible={selectedCustomer}
              isRequired
              validationRules={[
                { type: 'custom', validationCallback: validateFutureDate, message: 'A data limite deve ser maior que a data atual' },
                { type: 'custom', validationCallback: validateLimitDate, message: 'A data limite não pode ser menor que a data de vencimento' }
              ]}
            />

            {billingType === 3 && (
              <SimpleItem
                dataField="discountPerc"
                editorType="dxNumberBox"
                editorOptions={{
                  format: '#,##0.00',
                  showSpinButtons: false,
                  min: 0,
                  onValueChanged: handleFieldChange,
                }}
                visible={selectedCustomer}
                label={{ text: 'Desconto' }}
                validationRules={[{ type: 'range', min: 0, max: 100, message: 'Desconto deve ser entre 0 e 100%', reevaluate: true}]}
              />
            )}
            {billingType === 3 && formData.discountPerc > 0 && (
              <SimpleItem
                dataField="disctounExpireDate"
                editorType="dxDateBox"
                editorOptions={{
                  defaultValue: '',
                  type: 'date',
                  displayFormat: 'dd/MM/yyyy',
                  onValueChanged: handleFieldChange,
                }}
                label={{ text: 'Data vencimento desconto' }}
                visible={selectedCustomer}
                validationRules={[
                  {
                    type: 'required',
                    message: 'Data de vencimento do desconto é obrigatória',
                    reevaluate: true,
                  },
                  {
                    type: 'custom',
                    validationCallback: validateDiscountExpireDate,
                    message: 'A data de vencimento do desconto deve ser menor que a data de vencimento do boleto',
                  }
                ]}
              />
            )}

            {billingType === 3 && (
              <SimpleItem
                dataField="assessmentPerc"
                editorType="dxNumberBox"
                editorOptions={{
                  format: '#,##0.00',
                  min: 0,
                  max: 2.0,
                  onValueChanged: handleFieldChange,
                }}
                label={{ text: 'Multa' }}
                visible={selectedCustomer}
                validationRules={[
                  { type: 'range', min: 0, max: 2.0, message: 'Multa deve ser maior que 0.0 e menor ou igual a 2.0', reevaluate: true }
                ]}
              />
            )}
            {billingType === 3 && formData.assessmentPerc > 0 && (
              <SimpleItem
                dataField="startAssessmentDate"
                editorType="dxDateBox"
                editorOptions={{ defaultValue: '', type: 'date', displayFormat: 'dd/MM/yyyy', onValueChanged: handleFieldChange }}
                label={{ text: 'Data início aplicação multa' }}
                visible={selectedCustomer}
                validationRules={[
                  { type: 'required', message: 'Data de início de aplicação da multa é obrigatória', reevaluate: true },
                  {
                    type: 'custom',
                    validationCallback: validateDateRange,
                    message: 'A data de início da multa deve ser superior à data de vencimento e inferior ou igual à data limite de pagamento',
                  },
                ]}
              />
            )}

            {billingType === 3 && (
              <SimpleItem
                dataField="interestPerc"
                editorType="dxNumberBox"
                editorOptions={{
                  format: '#,##0.00',
                  min: 0,
                  max: 1.0,
                  onValueChanged: handleFieldChange,
                }}
                label={{ text: 'Juros ao mês' }}
                visible={selectedCustomer}
                validationRules={[{ type: 'range', min: 0, max: 1.0, message: 'Juros deve ser maior que 0 e menor ou igual a 1.0', reevaluate: true}]}
              />
            )}
            {billingType === 3 && formData.interestPerc > 0 && (
              <SimpleItem
                dataField="startInterestDate"
                editorType="dxDateBox"
                editorOptions={{ defaultValue: '', type: 'date', displayFormat: 'dd/MM/yyyy', onValueChanged: handleFieldChange }}
                label={{ text: 'Data início aplicação dos juros' }}
                visible={selectedCustomer}
                validationRules={[
                  { type: 'required', message: 'Data de início de aplicação dos juros é obrigatória', reevaluate: true },
                  {
                    type: 'custom',
                    validationCallback: validateDateRange,
                    message: 'A data de início dos juros deve ser superior à data de vencimento e inferior ou igual à data limite de pagamento',
                  },
                ]}
              />
            )}
          </Form>
        </>
      )}
    </>
  );
};
