import React from 'react';
import styles from './Sidenav.module.scss';
import MenuItem from './MenuItem';

const MenuPai = ({ menu, toggleMenu, showMobileSidenav }) => {
  return (
    <ul className={`${styles.menu} menu`} key={menu.codigo}>
      <li className={`${styles.menuHeader} menuHeader ${menu.expanded ? styles.expanded : 'text-light'} pr-0`} key={menu.codigo}>
        <h5 className={`${styles.menuTitle} menuTitle ${showMobileSidenav ? styles.fadeInMenu : styles.fadeOutMenu} mb-0`} onClick={() => toggleMenu(menu)}>
          <i className={`${menu.icon} icon`}></i>
          <span className={`${styles.menuHeader} menuHeader`}>{menu.title}</span>
          <i className={`${styles.arrow} arrow ${menu.expanded ? styles.rotate : ''} icon icon-lx-angle-right float-right`}></i>
        </h5>
          <MenuItem menu={menu} toggleMenu={toggleMenu} showMobileSidenav={showMobileSidenav}></MenuItem>
      </li>
    </ul>
  );
};

export default MenuPai;
