import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';
import buscarCepService from '../../../../../services/cliente/BuscarCep.service';
import clienteService from '../../../../../services/cliente/Cliente.service';
import { DropBoxMunicipio } from '../drop-box-municipio/DropBoxMunicipio';
import { DropBoxUf } from '../drop-box-uf/DropBoxUF';

export function EnderecoAdicional({ onUpdated, cliente, address, action, title }) {
  const [show, setShow] = useState(false);
  const [id, setId] = useState(null);
  const [geneCod, setGeneCod] = useState(0);
  const [geTiCod, setGeTiCod] = useState(0);
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numeroLogradouro, setNumeroLogradouro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [uf, setUf] = useState(null);
  const [municipio, setMunicipio] = useState(null);
  const [tipoEndereco, setTipoEndereco] = useState('Entrega/Retirada');
  const [cnpj, setCnpj] = useState('');
  const [ie, setIe] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [loadingCep, setLoadingCep] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const txtNumeroEndereco = useRef();

  const resetForm = useCallback(() => {
    setCep('');
    setLogradouro('');
    setNumeroLogradouro('');
    setComplemento('');
    setBairro('');
    setMunicipio(null);
    setUf(null);
    setCnpj('');
    setIe('');
    setRazaoSocial('');
  }, []);
  useEffect(() => {
    if (!show) {
      resetForm();
    } else {
      if (action === 'ADD') {
        if (cliente) {
          setUf(cliente.uf);
          setMunicipio(cliente.municipio);
          setGeneCod(cliente.id);
          setGeTiCod(cliente.tipoPessoa);
        }
      } else {
        if (address) {
          setId(address.id || -1);
          setMunicipio(address.municipio);
          setGeneCod(address.geneCod);
          setGeTiCod(address.geTiCod);
          setCep(address.cep);
          setLogradouro(address.logradouro);
          setNumeroLogradouro(address.numero);
          setComplemento(address.complemento);
          setBairro(address.bairro);
          setTipoEndereco(address.tipo);
          setCnpj(address.cnpj);
          setIe(address.ie || '');
          setRazaoSocial(address.razaoSocial || '');
          setUf(address.municipio.unidadeFederativa || null);
        }
      }
    }
  }, [cliente, action, address, show, resetForm]);

  // Função para lidar com o envio do formulário
  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      try {
        setLoadingSave(true);
        var body = {
          id: id || 0,
          geneCod: geneCod || 0,
          geTiCod: geTiCod || 0,
          cep: cep.replace(/\D/g, ''),
          logradouro,
          numero: numeroLogradouro || null,
          complemento,
          bairro,
          tipo: tipoEndereco,
          cnpj,
          ie,
          razaoSocial,
          municipio,
          uf,
        };
        let end = null;
        if(action === 'ADD')
          end = await clienteService.inserirEnderecoAdicional(body);
        else
          end = await clienteService.editarEndereco(body);
        if (onUpdated && typeof onUpdated === 'function') {
          onUpdated(end);
        }
        handleClose();
        setLoadingSave(false);
      } catch (error) {
        setLoadingSave(false);
        Swal.fire({
          title: 'OPS!',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    },
    [ie, cnpj, razaoSocial, tipoEndereco, bairro, complemento, cep, geneCod, geTiCod, logradouro, municipio, numeroLogradouro, uf, onUpdated, id, action],
  );

  const handleClose = async () => {
    setShow(false);
  };
  const handleBuscarCep = async () => {
    try {
      setLoadingCep(true);
      if (!cep) throw new Error('Informe o CEP para buscar o endereço.');
      if (cep.length !== 9) throw new Error(`Informe um CEP válido com #####-### com 9 caracteres. ${cep.length}`);
      const result = await buscarCepService.execute(cep.replace(/\D/g, ''));
      if (!result) throw new Error('CEP não encontrado.');
      setLogradouro(result.logradouro);
      setBairro(result.bairro);
      setUf(result.uf);
      setMunicipio(result.municipio);
      txtNumeroEndereco.current.focus();
      setLoadingCep(false);
    } catch (error) {
      setLoadingCep(false);
      await Swal.fire({
        title: 'OPS!',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };
  const handleShow = () => setShow(true);

  // Função para bloquear caracteres não numéricos
  const handleKeyDown = event => {
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Tab', 'Enter', 'ArrowLeft', 'ArrowRight'];
    if (!validKeys.includes(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      <button className="btn btn-primary" onClick={handleShow} disabled={!cliente || (action === 'EDIT' && !address)} title={title}>
        <i className={action==='EDIT' ? 'fa fa-edit' : 'fa fa-plus'}></i>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>{action === 'EDIT' ? 'Editar' : 'Criar'} Endereço Adicional</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} autoComplete="off" disabled={loadingSave}>
            <Form.Group controlId="cep" className="flex-fill">
              <Form.Label>CEP: {cep}</Form.Label>
              <div className="d-flex">
                <InputMask
                  required={true}
                  className="form-control"
                  onChange={e => setCep(e.target.value)}
                  mask="99999-999"
                  maskChar={null}
                  type="text"
                  value={cep}
                  placeholder="Informe o CEP EX: 99999-000"
                  title="Digite um CEP válido com 8 dígitos numéricos."
                />
                <Button variant="primary" onClick={handleBuscarCep} disabled={loadingCep}>
                  <div className="d-flex justify-content-center align-items-center">
                    {!loadingCep && <i className="fa fa-search mr-2"></i>}
                    <span>{loadingCep ? 'Buscando' : 'Buscar'}</span>
                    {loadingCep && <Spinner className="ml-3" animation="grow" role="status" as="span" size="sm" aria-hidden="true" />}
                  </div>
                </Button>
              </div>
            </Form.Group>
            <div className="d-flex">
              <Form.Group controlId="logradouro" className="flex-fill">
                <Form.Label>Logradouro:</Form.Label>
                <Form.Control value={logradouro} onChange={e => setLogradouro(e.target.value)} type="text" placeholder="Informe o endereço" maxLength={60} />
              </Form.Group>
              <Form.Group controlId="numeroLogradouro">
                <Form.Label>Número:</Form.Label>
                <Form.Control
                  ref={txtNumeroEndereco}
                  onChange={e => setNumeroLogradouro(e.target.value)}
                  value={numeroLogradouro}
                  onKeyDown={handleKeyDown}
                  type="text"
                  placeholder="Nº"
                  maxLength={8}
                />
              </Form.Group>
            </div>
            <Form.Group controlId="complemento" className="">
              <Form.Label>Complemento:</Form.Label>
              <Form.Control type="text" onChange={e => setComplemento(e.target.value)} value={complemento} placeholder="Informe o complemento do endereço" maxLength={60} />
            </Form.Group>
            <Form.Group controlId="bairro" className="">
              <Form.Label>Bairro:</Form.Label>
              <Form.Control type="text" onChange={e => setBairro(e.target.value)} value={bairro} required={true} placeholder="Informe o bairro" maxLength={60} />
            </Form.Group>
            <div className="d-flex">
              <Form.Group controlId="uf">
                <Form.Label>UF:</Form.Label>
                <DropBoxUf
                  onSelected={uf => {
                    setUf(uf);
                  }}
                  value={uf}
                ></DropBoxUf>
              </Form.Group>
              <Form.Group controlId="txt" className="flex-fill">
                <Form.Label>Cidade:</Form.Label>
                <DropBoxMunicipio
                  disabled={!uf}
                  onSelected={mun => {
                    setMunicipio(mun);
                  }}
                  value={municipio}
                  ufValue={uf}
                ></DropBoxMunicipio>
              </Form.Group>
            </div>
            {id !== -1 && (
              <>
                <div className="d-flex">
                  <Form.Group controlId="cnpj" className="flex-fill">
                    <Form.Label>CNPJ:</Form.Label>
                    <InputMask
                      className="form-control"
                      type="text"
                      maskChar={null}
                      mask={'99.999.999/9999-99'}
                      onChange={e => setCnpj(e.target.value)}
                      value={cnpj || ''}
                      placeholder="Informe o CNPJ"
                    />
                  </Form.Group>
                  <Form.Group controlId="ie" className="flex-fill">
                    <Form.Label>IE:</Form.Label>
                    <Form.Control type="text" onChange={e => setIe(e.target.value)} value={ie} placeholder="Informe a inscrição estadual" maxLength={60} />
                  </Form.Group>
                </div>
                <Form.Group controlId="razaoSocial" className="">
                  <Form.Label>Razão Social:</Form.Label>
                  <Form.Control type="text" onChange={e => setRazaoSocial(e.target.value)} value={razaoSocial} placeholder="Informe a razão social" maxLength={60} />
                </Form.Group>
              </>
            )}
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose} disabled={loadingSave}>
                Fechar
              </Button>
              <Button variant="primary" type="submit" disabled={loadingSave}>
                <div className="d-flex justify-content-center align-items-center">
                  {!loadingSave && <i className="fa fa-plus mr-2"></i>}
                  <span>{loadingSave ? 'Salvando' : 'Salvar'}</span>
                  {loadingSave && <Spinner className="ml-3" animation="grow" role="status" as="span" size="sm" aria-hidden="true" />}
                </div>
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
