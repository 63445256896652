import React from 'react';
import styles from './ScreenHeader.module.scss'
import HelpShareButton from '../../Suporte/HelpShareButton/HelpShareButton';
import { getLinkMenu, menuPermiteConsulta, menuPermiteInclusao } from '../../../shared/utils/Utils';
import NovoLancamento from '../../botao/NovoLancamento/NovoLancamento';
import SearchHeader from '../SearchHeader/SearchHeader';

export default function ScreenHeader(props) {
    return <div className={`${styles.actions} actions col-md-12`} >
    {props.search && menuPermiteConsulta(props.pathMenu) ? <SearchHeader searchText={props.searchText} load={props.load} setarValorCampoBusca={props.setarValorCampoBusca}/> : <></>}
    {props.newButton && menuPermiteInclusao(props.pathMenu) ? <NovoLancamento texto={props.texto} newURL={props.newURL} limparCadastro={props.limparCadastro} /> : <></>}
    <HelpShareButton shareLink={getLinkMenu(props.pathMenu)} tooltip={'Documentos e Manuais'}/> 
  </div>
}

