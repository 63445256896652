import { SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import clienteService from '../../../../../services/cliente/Cliente.service';
import { DropDownImage } from './../drop-down-image';

export const DropBoxMunicipio = ({ onSelected, value, ufValue, disabled = false }) => {
  const [municipio, setMunicipio] = useState(null);
  const [uf, setUf] = useState(null);
  const selectBoxRef = useRef(null);

  const dataSource = new DataSource({
    key: 'id',
    cacheRawData: false, // Desativa o cache de dados
    byKey: key => {
      return [municipio || null];
    },
    load: async function(arg) {
      if (arg.searchOperation !== 'contains') {
        return [];
      }
      if (!arg.searchValue) {
        return [];
      }

      const resultMun = await clienteService.listarMunicipios((uf || {id: -1}).id, arg.searchValue);
      return resultMun;
    },
  });

  useEffect(() => {
    setUf(ufValue);
    setMunicipio(value);

    // Limpa o local storage relacionado ao SelectBox
    localStorage.removeItem('dx-selectbox');
  }, [value, ufValue]);

  const onSelectionChanged = useCallback(
    e => {
      if ((municipio || {}).id === (e.selectedItem || {}).id) {
        return;
      }
      setMunicipio(e.selectedItem);
      if (onSelected) {
        onSelected(e.selectedItem);
      }
    },
    [onSelected, municipio],
  );

  useEffect(() => {
    if (selectBoxRef.current) {
      // Acessa o elemento input interno do SelectBox e desativa o preenchimento automático
      const inputElement = selectBoxRef.current.querySelector('input');
      if (inputElement) {
        inputElement.setAttribute('autocomplete', 'off');
      }
    }
  }, []);

  return (
    <div ref={selectBoxRef}>
      <SelectBox
        dropDownButtonRender={DropDownImage}
        searchEnabled={true}
        required={true}
        disabled={disabled}
        displayExpr="descricao"
        height={40}
        valueExpr="id"
        value={municipio || {}}
        acceptCustomValue={false} // Não permite que o valor digitado seja aceito
        dataSource={dataSource}
        selectedItem={municipio || {}}
        onSelectionChanged={onSelectionChanged}
        searchTimeout={500}
      />
    </div>
  );
};
