import axios from "axios";
import { getUrl, getCnpj } from "../../../shared/utils/Utils";
import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function montaComboMaquinas() {
  return axios.get(`LxApi/v1/Maquina/MontarCombo`, {
    params: { cnpjEstabelecimento: getCnpj() }
  });
}

export function getMaquinasPerifericos() {
  return axios.post(`LxApi/v1/Maquina/ListarMaquinasPerifericos`, {
    query: { cnpjEstabelecimento: getCnpj() }
  });
}

export function montaComboEquipamento(codigoTipoEquipamento) {
  return axios.get(`LxApi/v1/Maquina/MontarComboEquipamento`, {
    params: { codigoTipoEquipamento }
  });
}

export function montaComboTipoEquipamento() {
  return axios.get(`LxApi/v1/Maquina/MontarComboTipoEquipamento`, {
    params: { cnpjEstabelecimento: getCnpj() }
  });
}

export function incluirMaquinaPeriferico(
  cnpjEstabelecimento,
  nome,
  descricao,
  ativo,
  almoxarifado,
  comunicaConcentrador,
  vendeCombustivel,
  valorAvisoSangria,
  valorMaxCaixa,
  tempoAviso,
  sangriaCega,
  imprimeValoresComprovSaida,
  valorInicialCaixa,
  permiteAlterarValor,
  viasComprovante,
  solicitaClienteInicioVenda,
  numSerieNFe,
  quantidadeLimiteVenda,
  imprimeLeituraX,
  perifericos,
  integraDPos,
  abreFechaTurnoAuto,
  responsavelTurnoAuto,
  viasSangria,
  indicadorSmartPos,
  responsavelTurnoSmartPos,
  indicadorTurnoAutomaticamente,
  indicadorLogin,
  indicadorLancaProdutos,
  indicadorLancaCombustivel,
  indicadorValidaEstoqueCarrinho,
  indicadorDinheiro,
  indicadorNotaPrazo,
  indicadorTicket,
  indicadorQRLinx,
  pOSDTEFIndicadorDinheiro,
  pOSDTEFIndicadorTicket
) {
  return axios.post(`LxApi/v1/Maquina/IncluirMaquinasPerifericos`, {
    cnpjEstabelecimento,
    nome,
    descricao,
    ativo,
    almoxarifado,
    comunicaConcentrador,
    vendeCombustivel,
    valorAvisoSangria,
    valorMaxCaixa,
    tempoAviso,
    sangriaCega,
    imprimeValoresComprovSaida,
    valorInicialCaixa,
    permiteAlterarValor,
    viasComprovante,
    solicitaClienteInicioVenda,
    numSerieNFe,
    quantidadeLimiteVenda,
    imprimeLeituraX,
    perifericos,
    integraDPos,
    abreFechaTurnoAuto,
    responsavelTurnoAuto,
    viasSangria,
    indicadorSmartPos,
    responsavelTurnoSmartPos,
    indicadorTurnoAutomaticamente,
    indicadorLogin,
    indicadorLancaProdutos,
    indicadorLancaCombustivel,
    indicadorValidaEstoqueCarrinho,
    indicadorDinheiro,
    indicadorNotaPrazo,
    indicadorTicket,
    indicadorQRLinx,
    pOSDTEFIndicadorDinheiro,
    pOSDTEFIndicadorTicket
  });
}

export function alterarMaquinaPeriferico(
  codigoMaquina,
  cnpjEstabelecimento,
  nome,
  descricao,
  inativo,
  almoxarifado,
  comunicaConcentrador,
  vendeCombustivel,
  valorAvisoSangria,
  valorMaxCaixa,
  tempoAviso,
  sangriaCega,
  imprimeValoresComprovSaida,
  valorInicialCaixa,
  permiteAlterarValor,
  viasComprovante,
  solicitaClienteInicioVenda,
  numSerieNFe,
  quantidadeLimiteVenda,
  imprimeLeituraX,
  perifericos,
  integraDPos,
  abreFechaTurnoAuto,
  responsavelTurnoAuto,
  viasSangria,
  rowVersion,
  indicadorSmartPos,
  responsavelTurnoSmartPos,
  indicadorTurnoAutomaticamente,
  indicadorLogin,
  indicadorLancaProdutos,
  indicadorLancaCombustivel,
  indicadorValidaEstoqueCarrinho,
  indicadorDinheiro,
  indicadorNotaPrazo,
  indicadorTicket,
  indicadorQRLinx,
  pOSDTEFIndicadorDinheiro,
  pOSDTEFIndicadorTicket
) {
  return axios.put(`LxApi/v1/Maquina/AtualizaMaquinasPerifericos`, {
    codigoMaquina,
    cnpjEstabelecimento,
    nome,
    descricao,
    inativo,
    almoxarifado,
    comunicaConcentrador,
    vendeCombustivel,
    valorAvisoSangria,
    valorMaxCaixa,
    tempoAviso,
    sangriaCega,
    imprimeValoresComprovSaida,
    valorInicialCaixa,
    permiteAlterarValor,
    viasComprovante,
    solicitaClienteInicioVenda,
    numSerieNFe,
    quantidadeLimiteVenda,
    imprimeLeituraX,
    perifericos,
    integraDPos,
    abreFechaTurnoAuto,
    responsavelTurnoAuto,
    viasSangria,
    rowVersion,
    indicadorSmartPos,
    responsavelTurnoSmartPos,
    indicadorTurnoAutomaticamente,
    indicadorLogin,
    indicadorLancaProdutos,
    indicadorLancaCombustivel,
    indicadorValidaEstoqueCarrinho,
    indicadorDinheiro,
    indicadorNotaPrazo,
    indicadorTicket,
    indicadorQRLinx,
    pOSDTEFIndicadorDinheiro,
    pOSDTEFIndicadorTicket
  });
}

export function excluirMaquinaPeriferico(codigoMaquina) {
  return axios.delete(`/LxApi/v1/Maquina/ExcluirMaquinasPerifericos`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoMaquina
    }
  });
}
