import React, { useEffect, useRef, useState } from 'react';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { withRouter } from 'react-router-dom';
import {
  toFloatFormattedDisplay,
  emptyText,
  getNomePagina,
  validaDadosLogin,
  menuPermiteExclusao,
  operacaoAcesso,
  sameInt
} from '../../../shared/utils/Utils';
import {
  excluiParcelaUnica,
  excluiParcelaRecorrente,
  consultaParcela,
  cancelaQuitacao
} from './GerenciamentoFinanceiro.service';
import { FormLancamento } from './FormLancamento/FormLancamento';
import { FormQuitacao } from './Quitacao/FormQuitacao';
import { FormQuitacaoLote } from './Quitacao/FormQuitacaoLote';
import { showRecibo } from './FormRecibo/FormRecibo';

import { menus } from '../../../shared/constants/MenuConstants';
import { GerenciamentoFinanceiroFilters } from '../../../components/business/GerenciamentoFinanceiroFilters/GerenciamentoFinanceiroFilters';
import styles from './GerenciamentoFinanceiro.module.scss';
import DateToolTip from '../../../components/dateTooltip/DateToolTip';
import TextToolTip from '../../../components/textToolTip/TextToolTip';
import ListToolTip from '../../../components/listToolTip/ListToolTip';
import { Accordion, DataGrid } from 'devextreme-react';
import { LinxMoneyLabel } from '../../../components/linx/MoneyLabel/LinxMoneyLabel';
import { Export } from 'devextreme-react/bar-gauge';
import {
  ColumnChooser,
  ColumnFixing,
  Grouping,
  GroupItem,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
  Summary,
  TotalItem,
  Sorting,
  Selection,
  Column,
  FilterRow,
  FilterPanel,
  HeaderFilter

} from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { ModalParcela } from './ModalParcela/ModalParcela';
import { gerenciamentoFinanceiroService } from '../../../components/business/GerenciamentoFinanceiroFilters/GerenciamentoFinanceiroFilters.service';
import { quitacaoService } from './Quitacao/Quitacao.service';
import { ModalCancelamentoQuitacaoLote } from './Quitacao/ModalCancelamentoQuitacaoLote/ModalCancelamentoQuitacaoLote';
import BotaoCarregamento from '../../../components/botao/BotaoCarregamento/BotaoCarregamento';
import { gerenciamentoService } from './CancelamentoTransferencia/GerenciamentoFinanceiro.service';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';
import { lancamentoService } from './FormLancamento/FormLancamento.service';

const PATH_MENU = menus.COD_60690;
export const ALTERAR_CONCILIADO = 60691;

const title = 'Gerenciamento financeiro';

const myURL = '/financeiro/gerenciamento-financeiro/';

export const defaultSearchText =
  'Pesquise por cliente ou fornecedor (nome, ' +
  'razão social, apelido, CPF ou CNPJ)';

export const TiposLancamentos = {
  None: '',
  APagar: '1',
  AReceber: '2',
  DespesasDiversas: '3',
  ReceitasDiversas: '4',
  Transferencias: '5'
};

export const Situacoes = {
  None: '',
  Todas: '0',
  EmAberto: '1',
  Pago: '2',
  Atrasado: '3',
  Conciliado: '4',
  NaoConciliado: '5'
};

let dataGridDebitoPago = [];
let dataGridDebitoNaoPago = null;
let dataGridCreditoNaoPago = null;
let dataGridCreditoPago = [];
let dataGridTransferenciaNaoConciliada = [];


const tituloCredito = 'Contas a receber/Receitas';

export function GerenciamentoFinanceiro(props) {
  const [tituloSel, setTituloSel] = useState({});
  const [quitaParcial, setQuitaParcial] = useState(null);
  const [filters, setFilters] = useState(null);
  const [disableControls, setDisableControls] = useState(false);
  const [agrupamentos, setAgrupamentos] = useState([]);
  const [excluindoParcela, setExcluindoParcela] = useState(false);
  const [modalParcela, setModalParcela] = useState(false);
  const [numeroParcelas, setNumeroParcelas] = useState(0);
  const [valorParcelas, setValorParcelas] = useState(0);
  const [infoParcela, setInfoParcela] = useState({});
  const [titulos, setTitulos] = useState([]);
  const [emitindoRecibo, setEmitindoRecibo] = useState(false);
  const [reciboRow, setReciboRow] = useState(null);
  const [valorNaoPagoDentroDoPrazoDebito, setValorNaoPagoDentroDoPrazoDebito] = useState(0);
  const [valorNaoPagoAtrasadoDebito, setValorNaoPagoAtrasadoDebito] = useState(0);
  const [valorNaoPagoDentroDoPrazoCredito, setValorNaoPagoDentroDoPrazoCredito] = useState(0);
  const [valorNaoPagoAtrasadoCredito, setValorNaoPagoAtrasadoCredito] = useState(0);

  const [dadosLoteQuitacao, setDadosLoteQuitacao] = useState(null);
  const [modalCancelamentoQuitacao, setModalCancelamentoQuitacao] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loteTitulosSel, setLoteTitulosSel] = useState([]);

  const [canceling, setCanceling] = useState(false);  

  const [selectedRowKeysCreditoNaoPago, setSelectedRowKeysCreditoNaoPago] = useState([]);
  const [selectedRowKeysDebitoNaoPago, setSelectedRowKeysDebitoNaoPago] = useState([]);

  const [itensSelecionados, setItensSelecionados] = useState([]);

  useEffect(()=> {
    let agrupamentoDebito = agrupamentos.find(a=> sameInt(a.tipoOperacaoAgrupada, TiposLancamentos.APagar));
    if(agrupamentoDebito){
      let dentroDoPrazoDebito = agrupamentoDebito.extratosSituacaoNaoPago.reduce((total, elemento) => {
        if(sameInt(elemento.situacao, Situacoes.EmAberto)) return total += elemento.valorLiquido
        else return total},0);
      
      let atrasadoDebito = agrupamentoDebito.extratosSituacaoNaoPago.reduce((total, elemento) => {
          if(sameInt(elemento.situacao, Situacoes.Atrasado)) return total += elemento.valorLiquido
          else return total},0);       
          
      setValorNaoPagoAtrasadoDebito(atrasadoDebito);            
      setValorNaoPagoDentroDoPrazoDebito(dentroDoPrazoDebito);
    }

    let agrupamentoCredito = agrupamentos.find(a=> sameInt(a.tipoOperacaoAgrupada, TiposLancamentos.AReceber));
    if(agrupamentoCredito){
      let dentroDoPrazoCredito = agrupamentoCredito.extratosSituacaoNaoPago.reduce((total, elemento) => {
        if(sameInt(elemento.situacao, Situacoes.EmAberto)) return total += elemento.valorLiquido
        else return total},0);
      
      let atrasadoCredito = agrupamentoCredito.extratosSituacaoNaoPago.reduce((total, elemento) => {
          if(sameInt(elemento.situacao, Situacoes.Atrasado)) return total += elemento.valorLiquido
          else return total},0);                   

      setValorNaoPagoAtrasadoCredito(atrasadoCredito);            
      setValorNaoPagoDentroDoPrazoCredito(dentroDoPrazoCredito);
    }               
},[agrupamentos]);

  const calculateTotals = titulos => {
    if (!titulos || titulos.length === 0) {
      titulos.valorAPagar = '0';
      titulos.valorEmAtraso = '0';
      titulos.valorPago = '0';
      titulos.valorTotal = '0';
      return;
    }

    let totalPagar = 0,
      totalAtraso = 0,
      totalPago = 0,
      totalTotal = 0;
    titulos.forEach(t => {
      switch (String(t.situacao.codigo)) {
        case Situacoes.EmAberto:
          totalPagar += t.valor;
          break;
        case Situacoes.Pago:
          totalPago += t.valor;
          break;
        case Situacoes.Atrasado:
          totalAtraso += t.valor;
          break;
        default:
          break;
      }
      totalTotal += t.valor;
    });

    titulos.valorAPagar = toFloatFormattedDisplay(String(totalPagar));
    titulos.valorEmAtraso = toFloatFormattedDisplay(String(totalAtraso));
    titulos.valorPago = toFloatFormattedDisplay(String(totalPago));
    titulos.valorTotal = toFloatFormattedDisplay(String(totalTotal));
  };

  const acRef = useRef();

  let history = useHistory();

  const isProcessing = () => {
    return excluindoParcela;
  };

  const carregarSelecionados = () => {
    itensSelecionados.forEach(element => {
      acRef.current.instance.expandItem(element);
    });
    if(dataGridCreditoNaoPago != null)
    {
      let selecionadosCredito = [...new Set(selectedRowKeysCreditoNaoPago.map(item => item.codigoTitulo))];
      dataGridCreditoNaoPago.selectRows(selecionadosCredito, false);
    }    
    if(dataGridDebitoNaoPago != null)
    {
      let selecionadosDebito = [...new Set(selectedRowKeysDebitoNaoPago.map(item => item.codigoTitulo))];
      dataGridDebitoNaoPago.selectRows(selecionadosDebito, false);
    }    
  }

  const handleQuitacaoLoteCredito = () => {
    if (isProcessing()) return;
    dataGridCreditoNaoPago.getSelectedRowsData().then(rowData => {
      let lote = [...new Set(rowData)];      
      if(lote.length === 0) {
        notify('Deve ser selecionado ao menos um título para quitação', 'error', 2500);
      }     
      else{
        setLoteTitulosSel(lote);
        setSelectedRowKeysCreditoNaoPago(rowData);
        history.push('/financeiro/gerenciamento-financeiro/quitaLote');
      }    
    })
  }

  const handleQuitacaoLoteDebito = () => {
    let tempItensSelecionados = [];
    if (isProcessing()) return;
    if (acRef.current) {
      for (let i = 0; i < agrupamentos.length; i++) {
        if (acRef.current.instance.isItemSelected(i)) {
          tempItensSelecionados.push(i);
        }
      }
    }
    dataGridDebitoNaoPago.getSelectedRowsData().then(rowData => {
      let lote = [...new Set(rowData)];
      if(lote.length === 0) {
        notify('Deve ser selecionado ao menos um título para quitação', 'error', 2500);
      }     
      else{
        setLoteTitulosSel(lote);
        setSelectedRowKeysDebitoNaoPago(rowData);
        setItensSelecionados(tempItensSelecionados);      
        history.push('/financeiro/gerenciamento-financeiro/quitaLote');
      }        
    })    
  }

  const handleCancelamentoQuitacaoLoteCredito = () => {   
    let continuaCancelamento = true;
    if (isProcessing()) return;
    dataGridCreditoPago.getSelectedRowsData().then(rowData => {
      let lote = [...new Set(rowData)]; 
      let lotesProibidos = lote.find(t=> sameInt(t.tipoOperacao, TiposLancamentos.ReceitasDiversas) || ((t.formaPagamento && t.formaPagamento.id === 1 && t.indicadorVenda)) || t.conciliado);
      let lotesPermitidos = lote.filter(t=> sameInt(t.tipoOperacao, TiposLancamentos.AReceber) && !(t.formaPagamento && t.formaPagamento.id === 1 && t.indicadorVenda) && !t.conciliado);
      if(lote.length === 0) {
        notify('Deve ser selecionado ao menos um título para cancelamento', 'error', 2500);
        continuaCancelamento = false;
      }
      if(lotesProibidos && lotesPermitidos.length === 0) {
        notify('Devem ser selecionados lançamentos diferentes de receitas diversas e conciliados e não vinculados a conferência de caixa com pagamento em dinheiro', 'error', 5000);
        continuaCancelamento = false;
      }  
      if(lotesProibidos && lotesPermitidos.length > 0) {
        notify('Os lançamentos de receitas diversas, conciliados e títulos vinculados a conferência de caixa com pagamento em dinheiro não serão cancelados', 'error', 5000);
        continuaCancelamento = true;
      }  
      if(continuaCancelamento) cancelamentoQuitacaoLote(TiposLancamentos.AReceber, lotesPermitidos);
    })    
  }

  const handleCancelamentoQuitacaoLoteDebito = () => {   
    let continuaCancelamento = true;
    if (isProcessing()) return;
    dataGridDebitoPago.getSelectedRowsData().then(rowData => {
      let lote = [...new Set(rowData)]; 
      let lotesProibidos = lote.find(t=> sameInt(t.tipoOperacao, TiposLancamentos.DespesasDiversas));
      let lotesPermitidos = lote.filter(t=> sameInt(t.tipoOperacao, TiposLancamentos.APagar) && !t.conciliado);
      if(lote.length === 0) {
        notify('Deve ser selecionado ao menos um título para cancelamento', 'error', 2500);
        continuaCancelamento = false;
      }
      if(lotesProibidos && lotesPermitidos.length === 0) {
        notify('Devem ser selecionados lançamentos diferentes de despesas diversas.', 'error', 5000);
        continuaCancelamento = false;
      }  
      if(lotesProibidos && lotesPermitidos.length > 0) {
        notify('Os lançamentos de despesas diversas não serão cancelados', 'error', 5000);
        continuaCancelamento = true;
      }  
      if(continuaCancelamento) cancelamentoQuitacaoLote(TiposLancamentos.APagar, lotesPermitidos);
    })    
  }

  const processarCancelamentoTransferenciasSelecionadas = async (registrosTransferencia) => {
    let resp = await confirm(
      '<h1>Deseja realmente cancelar as transferências selecionadas?</h1>',
      title
    );
    if (resp) {
      setCanceling(true);
      await cancelarTransferenciasSelecionadas(registrosTransferencia);
      doNewSearch();
    }
  }

  const handleCancelarTransferenciasSelecionadas = () => {
    dataGridTransferenciaNaoConciliada.getSelectedRowsData().then(rowData => {
      if(rowData.length === 0) {
        notify('Deve ser selecionado ao menos uma transferência para cancelamento', 'error', 2500);
      } else {
        processarCancelamentoTransferenciasSelecionadas(rowData);
      }
    });         
  }

  const cancelarTransferenciasSelecionadas = async (registrosTransferencia) => {
    let loteTransferencias = [];
    registrosTransferencia.forEach(transf => {
    let transferencia = 
      {
        id: transf.codigoTitulo,
        dataOperacao: transf.dataEmissao,
        centroMonetarioOrigem: transf.centroMonetario,
        centroMonetarioDestino: transf.centroMonetarioDestino,
        valor: transf.valor,
        historico: transf.historico,
        conciliado: transf.conciliado,
        numeroDocumento: transf.numeroDocumento.toString()
    };

    loteTransferencias.push(transferencia);
  });

    try {   

      const response = await gerenciamentoService.realizarEstornoLoteTransferencia(loteTransferencias);

      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        notify('Transferências canceladas com sucesso','success',2000,'top');
      }
    } catch (e) {
      notify(e, 'error', 2000, 'top');
    }
  }

  const cancelamentoQuitacaoLote = async (tipoOperacao, loteTitulos) => {
    setLoading(true); 
    let codigosMovimentoQuitacao = [...new Set(loteTitulos.map(item => item.codigoMovimentoQuitacao))];
    let lotesQuitacaoConsultados = await consultarLoteQuitacao(codigosMovimentoQuitacao);
    lotesQuitacaoConsultados = lotesQuitacaoConsultados.data;
    if(lotesQuitacaoConsultados.length === 0) {
      setLoading(false);
      notify('Deve ser selecionado ao menos um título para cancelamento', 'error', 2500);
    }      
    else {  
      lotesQuitacaoConsultados.tipoOperacao = tipoOperacao;
      setLoading(false);
      abrirModalCancelamentoQuitacao(lotesQuitacaoConsultados);
    }    
  }

  const handleQuitacaoTotalButtonClick = e => {
    if (isProcessing()) return;
    setQuitaParcial(false);
    const tituloSelecionado = e.row.data;
    tituloSelecionado.tipoLancamento = String(tituloSelecionado.tipoOperacao);
    tituloSelecionado.centroMonetario = tituloSelecionado.centroMonetario ? tituloSelecionado.centroMonetario.id : '';
    tituloSelecionado.formaPagamento = "";
    tituloSelecionado.id = tituloSelecionado.codigoTitulo;
    tituloSelecionado.tipoOcorrencia = String(tituloSelecionado.tipoOcorrencia);
    setTituloSel(tituloSelecionado);
    history.push('/financeiro/gerenciamento-financeiro/quita');
  };

  const handleQuitacaoParcialButtonClick = e => {
    if (isProcessing()) return;
    setQuitaParcial(true);
    const tituloSelecionado = e.row.data;
    tituloSelecionado.tipoLancamento = String(tituloSelecionado.tipoOperacao);
    tituloSelecionado.centroMonetario = tituloSelecionado.centroMonetario ? tituloSelecionado.centroMonetario.id : '';
    tituloSelecionado.formaPagamento = "";
    tituloSelecionado.id = tituloSelecionado.codigoTitulo;
    tituloSelecionado.tipoOcorrencia = String(tituloSelecionado.tipoOcorrencia);
    setTituloSel(tituloSelecionado);
    history.push('/financeiro/gerenciamento-financeiro/quita');
  };

  const handleEdicaoButtonClick = e => {
    const situacao = e.row.data.situacao;
    const indicadorVenda = e.row.data.indicadorVenda;
    const tituloSelecionado = e.row.data;
    tituloSelecionado.tipoLancamento = String(tituloSelecionado.tipoOperacao);
    tituloSelecionado.id = tituloSelecionado.codigoTitulo;
    tituloSelecionado.tipoOcorrencia = String(tituloSelecionado.tipoOcorrencia);
    tituloSelecionado.isEditing = true;
    tituloSelecionado.conta = tituloSelecionado.conta ? tituloSelecionado.conta.id : '';
    if(tituloSelecionado.tipoLancamento !== TiposLancamentos.Transferencias)
      tituloSelecionado.centroMonetarioOrigem = tituloSelecionado.centroMonetario ? tituloSelecionado.centroMonetario.id : '';
    else   
      tituloSelecionado.centroMonetarioOrigem = tituloSelecionado.centroMonetario ? tituloSelecionado.centroMonetario : '';
    if (isProcessing()) return;
    setTituloSel(tituloSelecionado);
    setDisableControls(tituloSelecionado.tipoLancamento !== TiposLancamentos.Transferencias && (!podeAlterarValores(situacao) || indicadorVenda));
    history.push(myURL + 'new');
  };  

  const podeQuitar = e => {
    const situacao = e.row.data.situacao;
    const tipoLancamento = e.row.data.tipoOperacao;
    if (!situacao || !tipoLancamento) return false;
    const s = String(situacao);
    return (
      s !== Situacoes.Pago && s !== Situacoes.Conciliado &&
      (sameInt(tipoLancamento, TiposLancamentos.APagar) ||
        sameInt(tipoLancamento, TiposLancamentos.AReceber))
    );
  };

  const podeCancelarQuitacao = e => {
    const situacao = e.row.data.situacao;
    const tipoLancamento = e.row.data.tipoOperacao;
    const conciliado = e.row.data.conciliado;
    const lancamentoDinheiro = e.row.data.formaPagamento && e.row.data.formaPagamento.id === 1 && e.row.data.indicadorVenda;
    if (!situacao || !tipoLancamento) return false;
    if(lancamentoDinheiro) return false;
    const s = String(situacao);
    return (
      (s === Situacoes.Pago) && !conciliado &&
      (sameInt(tipoLancamento, TiposLancamentos.APagar) ||
        sameInt(tipoLancamento, TiposLancamentos.AReceber))
    );
  };

  const deletarParcelaRecorrente = async (codigoRecorrencia, dataVencimento) => {
    if (!validaDadosLogin()) {
      props.history.push('/');
    }
    setExcluindoParcela(true);
    setModalParcela(false);
    try {
      await excluiParcelaRecorrente(codigoRecorrencia, dataVencimento);
      notify('Parcela excluída com sucesso', 'info', 2000);
    } catch (error) {
      notify(error, 'error', 2500);
    } finally {
      setExcluindoParcela(false);
      setModalParcela(true);
    }
  };  

  const deletarParcelaUnica = async (registroID, codigoTipoLancamento) => {
    if (!validaDadosLogin()) {
      history.push('/');
    }
    const titulosNovo = [];
    const titulosAtual = titulos;
    setExcluindoParcela(true);
    try {
      await excluiParcelaUnica(registroID, codigoTipoLancamento);
      titulosAtual.forEach(element => {
        if (element.codigoTitulo !== registroID) titulosNovo.push(element);
      });
      calculateTotals(titulosNovo);
      setTitulos(titulosNovo);
      notify('Parcela excluída com sucesso', 'info', 2000);
      doNewSearch();
    } catch (error) {
      notify(error, 'error', 2500);
    } finally {
      setExcluindoParcela(false);
    }
  };
  

  const deletarParcelaRecorrencia = async (codigoParcela, dataVencimento) => {
    if (!validaDadosLogin()) {
      props.history.push('/');
    }
    const { data: parcela } = await consultaParcela(codigoParcela);
    if (!parcela) return;
    abrirModalParcela(parcela, codigoParcela, dataVencimento);
  };

  const deletarLancamento = async (registro) => {
    
    if(sameInt(registro.tipoOperacao, TiposLancamentos.Transferencias))
      await lancamentoService.excluirTransferencia(
        registro.codigoTitulo,
        registro.dataEmissao,
        registro.valor,
        registro.centroMonetario,
        registro.centroMonetarioDestino
      );
    else    
      await lancamentoService.excluirLancamento(
        registro.codigoTitulo,
        registro.tipoOperacao,
        registro.dataEmissao,
        registro.valor,
        registro.centroMonetario
      );

    notify((sameInt(registro.tipoOperacao, TiposLancamentos.Transferencias) ? 'Transferência excluída' : 'Lançamento excluído')+' com sucesso', 'info', 2000);
    doNewSearch();
  }

  const handleExclusaoButtonClick = async e => {
    let registro = e.row.data;

    if (isProcessing()) return;
    if(sameInt(registro.tipoOperacao, TiposLancamentos.AReceber) || sameInt(registro.tipoOperacao, TiposLancamentos.APagar))
    {
      if (registro.recorrencia) {
        //parcela recorrente
        deletarParcelaRecorrencia(registro.codigoTitulo, registro.dataVencimento);
      } else {
        //parcela unica
        let resp = await confirm(
          '<h1>Deseja realmente excluir essa parcela?</h1>',
          title
        );
        if (resp) {
          deletarParcelaUnica(
            registro.codigoTitulo,
            registro.tipoOperacao
          );
        }
      }
    } else
    {
        let resp = await confirm(
          '<h1>Deseja realmente excluir '+ (sameInt(registro.tipoOperacao, TiposLancamentos.Transferencias) ? 'a transferência' : 'o lançamento')+'? </h1>',
          title
        );
        if (resp) {
          deletarLancamento(registro);
        }
    } 
  };  

  const handleCancelarQuitacaoButtonClick = async e => {
    setLoading(true);
    let registro = e.row.data;
    if (!registro || isProcessing()) {
      return;
    }

    const doCancelaQuitacao = async () => {
      try {
        await cancelaQuitacao(registro.codigoTitulo, Number(registro.tipoOperacao));
        doNewSearch();
        notify('Quitação cancelada com sucesso','success',2000,'top');
      } catch (e) {
        notify(e, 'error', 2000, 'top');
      }
    };

    let loteQuitacaoConsultado = await consultarLoteQuitacao([registro.codigoMovimentoQuitacao]);
    loteQuitacaoConsultado = loteQuitacaoConsultado.data;
    setLoading(false);
    if (loteQuitacaoConsultado && loteQuitacaoConsultado[0].qtdeTitulos > 1)
    {
      loteQuitacaoConsultado.tipoOperacao = registro.tipoOperacao;
      abrirModalCancelamentoQuitacao(loteQuitacaoConsultado);
    }
    else 
    {
      let resp = await confirm('Deseja realmente cancelar essa quitação?', title);

      if (resp) {
        doCancelaQuitacao();
      } 
    }

  };

  const abrirModalCancelamentoQuitacao = (lotesQuitacaoConsultado) => {
    setDadosLoteQuitacao(lotesQuitacaoConsultado);
    setModalCancelamentoQuitacao(true);
  }

  const abrirModalParcela = (parcelaRecorrencia, idParcela, dataVencimento) => {
    var valorParcelas = parcelaRecorrencia
      ? toFloatFormattedDisplay(parcelaRecorrencia.valor)
      : '0';
  
    const infoParcela = {
      id: idParcela,
      codigoRecorrencia: parcelaRecorrencia.codigoRecorrencia,
      dataVencimento
    };
    setModalParcela(true);
    setNumeroParcelas(parcelaRecorrencia.numeroParcelas);
    setValorParcelas(valorParcelas);
    setInfoParcela(infoParcela);
  };  

  const handleCloseModalParcela = async modoExclusao => {
    try {
      switch (modoExclusao) {
        case 1:
          deletarParcelaUnica(infoParcela.id, 1);
          break;
        case 2:
          await deletarParcelaRecorrente(
            infoParcela.codigoRecorrencia,
            infoParcela.dataVencimento
          );
          doNewSearch();
          break;
        default:
          break;
      }
    } finally {
        setModalParcela(false);
    }
  };

  const cancelarLoteQuitacao = async () => {
    let lotesQuitacao = [];

    dadosLoteQuitacao.forEach(lote => {
      let titulosCancelar = [];
      lote.titulos.forEach(titulo => {
        let tituloCancelar = 
          {
            ...titulo,
            conciliado: false,
            tipoOperacaoFinanceira: parseInt(dadosLoteQuitacao.tipoOperacao)
        };
        titulosCancelar.push(tituloCancelar);
      });         

      lotesQuitacao.push({
        dataPagamento: lote.dataPagamento,
        qtdeTitulos: lote.qtdeTitulos,
        valorPago: lote.valorPago,
        titulos: titulosCancelar
      });
    });

    try {
      const data = 
        {
          lotesQuitacao
        };          

      const response = await quitacaoService.realizarCancelamentoQuitacaoLote(data);

      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        notify("Quitação cancelada com sucesso", "success", 500);
      }
    } catch (e) {     
      notify(e, 'error', 5000, 'top');
    }

    setModalCancelamentoQuitacao(false);
    setCanceling(false);
  }

  const handleCloseModalCancelamentoQuitacao = async closeMode => {
    switch (closeMode) {
      case 1:
        setCanceling(true);
        await cancelarLoteQuitacao();
        doNewSearch();
        break;
      default:
        setModalCancelamentoQuitacao(false);
        break;
    }
  };

  const podeAlterarValores = situacao => {
    if (!situacao) return false;
    const s = String(situacao);
    return (
      s === Situacoes.NaoConciliado ||
      s === Situacoes.EmAberto ||
      s === Situacoes.Atrasado ||
      (s === Situacoes.Conciliado && operacaoAcesso(ALTERAR_CONCILIADO))
    );
  };

  const podeExcluirParcela = e => {
    const situacao = e.row.data.situacao;
    const indicadorVenda = e.row.data.indicadorVenda;
    if (!situacao) return false;
    if (indicadorVenda) return false;
    if (!menuPermiteExclusao(PATH_MENU)) return false;
    const s = String(situacao);
    return (s !== Situacoes.Conciliado && s !== Situacoes.Pago);
  };

  const podeEmitirRecibo = e => {
    const situacao = e.row.data.situacao;
    const tipoLancamento = e.row.data.tipoOperacao;
    if (!situacao || !tipoLancamento) return false;
    return (sameInt(situacao, Situacoes.Pago) || sameInt(situacao, Situacoes.Conciliado))  && sameInt(tipoLancamento, TiposLancamentos.AReceber);
  };

  const handleEmissaoReciboButtonClick = e => {
    let registro = e.row.data;
    registro.id = registro.codigoTitulo;
    setReciboRow(registro);
    setEmitindoRecibo(true);
  }  

  const limpaCampos = () => {
    setTituloSel({});
    setDisableControls(false);
  }

  const doNewSearch = async () => {
    if(filters)
    {
      setCanceling(false);

      const response = await gerenciamentoFinanceiroService.find(true, filters);
      if (response.notificationLevel === 1) {
        if (response.data.length) {
          limpaCampos();
          setAgrupamentos(response.data);
        } else {
          setAgrupamentos([]);
          notify(
            'Não foram encontrados registros para esta pesquisa.',
            'info',
            2000,
            'top'
          );
        }
      } else {
        notify(
          response.message + ' Refaça a pesquisa informando um período menor.',
          'info',
          2000,
          'top'
        );
      }
    }
  }; 

  const format = {
    type: 'currency',
    precision: 2
  };

 

  const consultarLoteQuitacao = async (codigoMovimentoQuitacao) => {
    const response = await quitacaoService.consultaLotesQuitacao(codigoMovimentoQuitacao);
    if (response) return response;
  }
  
  const resultGroup = () => {
    return (
      <>
        <ModalParcela
          active={modalParcela}
          numeroParcelas={numeroParcelas}
          valorParcelas={valorParcelas}
          infoParcela={infoParcela}
          codigoRecorrencia={infoParcela.codigoRecorrencia}
          idParcela={infoParcela.id}
          handleCloseModal={handleCloseModalParcela}
        /> 
        <ModalCancelamentoQuitacaoLote
          active={modalCancelamentoQuitacao}
          lotes={dadosLoteQuitacao}
          handleCloseModal={handleCloseModalCancelamentoQuitacao}
          canceling={canceling}
        /> 
        {showRecibo({emitindoRecibo, reciboRow}, resp => {
          setEmitindoRecibo(false);
          setReciboRow(null);
        })}
        <div className={`${styles.gerenciamentoFinanceiroResult} gerenciamentoFinanceiroResult mt-3`}>
          <div className={`${styles.results} shadow-sm p-3 bg-white`}>
            <center>
              <h2>RESULTADO</h2>
            </center>
            <div className="row">
              <div className="col-md-12">
                <div className={`${styles.appliedFilters} appliedFilters`}>
                  <DateToolTip
                    inicial={filters.dataInicio}
                    final={filters.dataFim}
                  />
                  <ListToolTip
                    data={filters.conta}
                    message={'Contas selecionadas'}
                  />
                  <ListToolTip
                    data={filters.operacaoFinanceira}
                    message={'Operações selecionadas'}
                  />
                  <ListToolTip
                    data={filters.centroMonetario}
                    message={'Centros monetários selecionados'}
                  />
                  <ListToolTip
                    data={filters.situacao}
                    message={'Situações selecionadas'}
                  />
                  <ListToolTip
                    data={filters.tipoOperacao}
                    message={'Tipos de lançamentos selecionados'}
                  />
                  <ListToolTip
                    data={filters.pessoa}
                    message={'Pessoas selecionadas'}
                  />
                  <ListToolTip
                    data={filters.estruturas}
                    message={'Estruturas de cartão selecionadas'}
                  />
                  {filters.tipoDataDesc ? (
                    <TextToolTip
                      text={`Tipo de período selecionado: ${filters.tipoDataDesc}`}
                    />
                  ) : (
                    <></>
                  )}

                  {!emptyText(filters.descricao) ? (
                    <TextToolTip text={'Descrição informada'} />
                  ) : (
                    <></>
                  )}
                </div>
                <hr />
                <div className={styles.accordionContent}>
                  <Accordion
                    ref={acRef}
                    dataSource={agrupamentos}
                    collapsible={true}
                    multiple={true}
                    animationDuration={0}
                    width="100%"
                    onItemTitleClick={(e) => {  
                      if (acRef.current.instance.isItemSelected(e.itemIndex))
                      {
                        acRef.current.instance.collapseItem(e.itemIndex);
                        acRef.current.instance.expandItem(e.itemIndex);
                      } 
                      else
                      {
                        acRef.current.instance.expandItem(e.itemIndex);
                        acRef.current.instance.collapseItem(e.itemIndex);
                      }  
                      
                    }}  
                    itemTitleRender={agrupamento => {
                      return (
                        <>
                          <div className="row">
                            <div className="col-4">
                              <h1 className={`${styles.tipoOperacaoColumn} tipoOperacaoColumn`}>
                                {agrupamento.tipoOperacaoAgrupadaDescricao}
                              </h1>
                            </div>
                            {sameInt(agrupamento.tipoOperacaoAgrupada, TiposLancamentos.Transferencias) ? (
                              <>
                              <div className="col-2">
                                <LinxMoneyLabel
                                  title="Não conciliado"
                                  value={agrupamento.valoresTotais.valorNaoConciliado}
                                  showMoneySymbol
                                  color="linx"
                                />
                              </div>
                              <div className="col-2">
                                <LinxMoneyLabel
                                  title="Conciliado"
                                  value={agrupamento.valoresTotais.valorConciliado}
                                  showMoneySymbol
                                  color="linx"
                                />
                              </div>
                              </>
                            ):(
                              <>
                                <div className="col-2">
                                  <LinxMoneyLabel
                                    title="Não pago"
                                    value={agrupamento.valoresTotais.valorNaoPago}
                                    showMoneySymbol
                                    color="linx"
                                  />
                                </div>
                                <div className="col-2">
                                  <LinxMoneyLabel
                                    title="Pago"
                                    value={agrupamento.valoresTotais.valorPago}
                                    showMoneySymbol
                                    color="linx"
                                  />
                                </div>
                              </>
                            )}
                            <div className="col-2">
                              <LinxMoneyLabel
                                title="Total"
                                value={agrupamento.valoresTotais.valorTotal}
                                showMoneySymbol
                                color="black"
                              />
                            </div>
                          </div>
                        </>
                      );
                    }}
                    itemRender={itemAgrupamento => {
                      return (
                        <div className={styles.accordionContent}>
                            {itemAgrupamento.extratosSituacaoNaoPago && itemAgrupamento.extratosSituacaoNaoPago.length > 0 ? (
                            <div className={`${styles.quadroDados} quadroDados`}>
                              <div className="row">
                                <div className="col-2">
                                  <div className={`${styles.naoPago}`}>
                                    <span>Não pago</span>
                                  </div>  
                                </div>  
                                <div className="col-2">
                                <LinxMoneyLabel
                                    title="Atrasados"
                                    value={itemAgrupamento.tipoOperacaoAgrupadaDescricao === tituloCredito ? valorNaoPagoAtrasadoCredito : valorNaoPagoAtrasadoDebito}
                                    showMoneySymbol
                                    color="linx"
                                  />
                                </div>
                                <div className="col-2">
                                <LinxMoneyLabel
                                    title="Dentro do prazo"
                                    value={itemAgrupamento.tipoOperacaoAgrupadaDescricao === tituloCredito ? valorNaoPagoDentroDoPrazoCredito : valorNaoPagoDentroDoPrazoDebito}
                                    showMoneySymbol
                                    color="linx"
                                  />
                                </div>                                
                                <div className="col-2">
                                </div>                 
                                <div className="col-1" />
                                <div className={`${styles.quitacaoLoteButton}`}>
                                  <button onClick={itemAgrupamento.tipoOperacaoAgrupadaDescricao === tituloCredito ? handleQuitacaoLoteCredito : handleQuitacaoLoteDebito} className={`btn btn-primary`}>
                                    QUITAR SELECIONADOS
                                  </button>
                                </div>                                  
                              </div> 
                              <DataGrid
                                dataSource={itemAgrupamento.extratosSituacaoNaoPago}
                                showBorders={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                columnResizingMode={'widget'}
                                columnMinWidth={80}
                                rowAlternationEnabled={true}
                                onInitialized={(e) => {
                                  itemAgrupamento.tipoOperacaoAgrupadaDescricao === tituloCredito ? dataGridCreditoNaoPago = e.component : dataGridDebitoNaoPago = e.component
                                }}
                                keyExpr='codigoTitulo'
                                disabled={loading}                                                           
                              >
                              <FilterRow visible={true} />
                              <FilterPanel visible={true} />
                              <HeaderFilter visible={true} />    
                              {itemAgrupamento.tipoOperacaoAgrupadaDescricao === 'Contas a receber/Receitas' ? 
                                <Column dataField= 'estruturaCartao'  caption= 'Estrutura cartão' width= '250' groupIndex= {1}/>
                                :<></>}
                              <Column dataField= 'tipoOperacaoDescricao' visible={false} caption= 'Tipo de operação' width= '130'  allowEditing= {false} />
                              <Column dataField= 'dataEmissao' caption= 'Emissão' dataType= 'date' width= '90'  allowEditing= {false} />
                              <Column dataField= 'dataPrevisao' caption= 'Previsão' dataType= 'date' width= '90'   allowEditing= {false} />
                              <Column dataField= 'dataVencimento' caption= 'Vencimento' dataType= 'date' width= '120' allowEditing= {false} groupIndex={0} />
                              <Column dataField= 'dataPagamento' caption= 'Pagamento' dataType= 'date' width= '120'  allowEditing= {false} />
                              <Column dataField= 'pessoa.nome' caption= 'Pessoa' width= '170'  allowEditing= {false} />
                              <Column dataField= 'pessoa.cnpjCpf' caption= 'CPF/CNPJ' width= '170'  allowEditing= {false} />
                              <Column dataField= 'descricao' caption= 'Descrição' width= '90'  allowEditing= {false} />
                              <Column dataField= 'conta.descricao' caption= 'Conta' width= '130'  allowEditing= {false} />
                              <Column dataField= 'numeroDocumento' caption= 'N° documento' width= '110'  allowEditing= {false} />
                              <Column dataField= 'nsu' caption= 'NSU' width= '70'  allowEditing= {false} />
                              <Column dataField= 'serie' caption= 'Serie' width= '70' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'tipoDocumentoFiscalSigla' caption= 'Modelo' width= '70' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'operacao.descricao' visible={false} caption= 'Operação' width= '130'  allowEditing= {false} />
                              <Column dataField= 'parcela' caption= 'Parcela' width= '70'  allowEditing= {false} />
                              <Column dataField= 'valor' caption= 'Valor bruto' format= {format} width= '100'  allowEditing= {false} />
                              <Column dataField= 'acrescimo' caption= 'Acréscimo' format= {format} width= '100'  allowEditing= {false} />
                              <Column dataField= 'desconto' caption= 'Desconto' format= {format} width= '100'  allowEditing= {false} />
                              <Column dataField= 'percentualTaxaCartao' caption= '% Taxa cartão' width= '110' format= {{precision: 2}} visible= {false}  allowEditing= {false} />
                              <Column dataField= 'valorTaxaCartao' caption= 'Valor taxa cartão' format= {format} width= '110'  allowEditing= {false} />
                              <Column dataField= 'valorLiquido' caption= 'Valor líquido' format= {format} width= '110'  allowEditing= {false} />
                              <Column dataField= 'centroMonetario.nome' caption= 'Centro monetário' width= '100'  allowEditing= {false} />
                              <Column dataField= 'tipoOcorrenciaDescricao' caption= 'Ocorrência' width= '100' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'tipoDocumento.descricao' caption= 'Tipo de documento' width= '150' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'formaPagamento.descricao' visible={false} caption= 'Forma pagamento' width= '150'  allowEditing= {false} />
                              <Column dataField= 'rede_Descricao' visible={false} caption= 'Rede' width= '70'  allowEditing= {false} />
                              <Column dataField= 'bandeira_Descricao' visible={false} caption= 'Bandeira' width= '70'  allowEditing= {false} />
                              <Column dataField= 'produtoCartao_Descricao' visible={false} caption= 'Produto cartão' width= '70'  allowEditing= {false} />
                              <Column dataField= 'numeroAutorizacao' visible={false} caption= 'Número autorização' width= '70'  allowEditing= {false} />
                              <Column dataField= 'situacaoDescricao' caption= 'Situação' width= '70'  allowEditing= {false} />
                              <Column dataField= 'conciliado' caption= 'Conciliado' width= '100'  allowEditing= {false} />
                              <Column dataField= 'historico' caption= 'Histórico' width= '110' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'empresa.cnpj' caption= 'CNPJ' width= '100' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'empresa.razaoSocial' caption= 'Razao social' width= '100' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'empresa.nomeFantasia' caption= 'Nome fantasia' width= '100' visible= {false}  allowEditing= {false} />
                              <Column dataField= 'numeroCheque' caption= 'Nº cheque' width= '100' visible= {false}  allowEditing= {false} />
                              <Column type='buttons' caption='Ações' fixed= {false} width= '150' cssClass={`${styles.colunaAcoes}`}
                              buttons={[{ hint: 'Editar', icon: 'edit', visible: true, onClick: handleEdicaoButtonClick },
                                        { hint: 'Quitação total', icon:'ic icon-lx-payments', visible: podeQuitar, onClick: handleQuitacaoTotalButtonClick },
                                        { hint: 'Quitação parcial', icon:'ic icon-lx-money', visible: podeQuitar, onClick: handleQuitacaoParcialButtonClick },
                                        { hint: 'Excluir parcela', icon:'trash', visible: podeExcluirParcela, onClick: handleExclusaoButtonClick },
                                        { hint: 'Cancelar quitação', icon:'close', visible: podeCancelarQuitacao, onClick: handleCancelarQuitacaoButtonClick },
                                        { hint: 'Emissão de recibo', icon:'print', visible: podeEmitirRecibo, onClick: handleEmissaoReciboButtonClick }           
                              ]}
                              />
                              <Grouping autoExpandAll={true} />
                                <Selection
                                  mode="multiple"
                                  selectAllMode='allPages'
                                  showCheckBoxesMode='always'
                                  deferred={true}
                                />
                                <Sorting mode="multiple" />
                                <GroupPanel visible={true} />
                                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                <Export
                                  enabled={true}
                                  fileName="GerenciamentoFinanceiro"
                                />
                                <SearchPanel
                                  visible={true}
                                  highlightCaseSensitive={true}
                                />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={false} />
                                <Summary>
                                      <TotalItem column="acrescimo" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="desconto" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="valorTaxaCartao" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="valorLiquido" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />

                                      <GroupItem column="acrescimo" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="desconto" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="valorTaxaCartao" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="valorLiquido" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                  </Summary>

                                <Pager
                                  showPageSizeSelector={true}
                                  allowedPageSizes={[5, 15, 20, 50]}
                                  showInfo={true}
                                />
                                <Paging defaultPageSize={15} />
                              </DataGrid>
                            </div>
                          ) : (
                            <></>
                          )}
                            {itemAgrupamento.extratosSituacaoPago && itemAgrupamento.extratosSituacaoPago.length > 0 ? (
                            <div className={`${styles.quadroDados}`}>
                              <div className="row">
                                <div className="col-4">
                                  <div className={`${styles.pago}`}>
                                    <span>Pago</span>
                                  </div>  
                                </div>  
                                <div className="col-2"/>
                                <div className="col-2">
                                </div>                 
                                <div className="col-1" />
                                <div className={`${styles.quitacaoLoteButton}`}>
                                  <BotaoCarregamento click={itemAgrupamento.tipoOperacaoAgrupadaDescricao === tituloCredito ? handleCancelamentoQuitacaoLoteCredito : handleCancelamentoQuitacaoLoteDebito} loading={loading} disabled={loading} carregando='CARREGANDO QUITAÇÕES...' carregar='CANCELAR QUITAÇÕES' />
                                </div>                                  
                              </div> 
                              <DataGrid
                                dataSource={itemAgrupamento.extratosSituacaoPago}
                                showBorders={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                columnResizingMode={'widget'}
                                columnMinWidth={80}
                                rowAlternationEnabled={true}
                                onInitialized={(e) => {
                                  itemAgrupamento.tipoOperacaoAgrupadaDescricao === tituloCredito ? dataGridCreditoPago = e.component : dataGridDebitoPago = e.component
                                }}
                                keyExpr='codigoTitulo'
                                disabled={loading}
                              >
                              <FilterRow visible={true} />
                              <FilterPanel visible={true} />
                              <HeaderFilter visible={true} />   
                                {itemAgrupamento.tipoOperacaoAgrupadaDescricao === tituloCredito? 
                                <Column dataField= 'estruturaCartao' caption= 'Estrutura cartão' width= '250' groupIndex= {1}  />
                                :<></>}
                                <Column dataField= 'tipoOperacaoDescricao' visible={false} caption= 'Tipo de operação' width= '130'  allowEditing= {false} />
                                <Column dataField= 'dataEmissao' caption= 'Emissão' dataType= 'date' width= '90'  allowEditing= {false} />
                                <Column dataField= 'dataPrevisao' caption= 'Previsão' dataType= 'date' width= '90'   allowEditing= {false} />
                                <Column dataField= 'dataVencimento' caption= 'Vencimento' dataType= 'date' width= '120'  allowEditing= {false} groupIndex={0} />
                                <Column dataField= 'dataPagamento' caption= 'Pagamento' dataType= 'date' width= '120'  allowEditing= {false} />
                                <Column dataField= 'pessoa.nome' caption= 'Pessoa' width= '170'  allowEditing= {false} />
                                <Column dataField= 'pessoa.cnpjCpf' caption= 'CPF/CNPJ' width= '170'  allowEditing= {false} />
                                <Column dataField= 'descricao' caption= 'Descrição' width= '90'  allowEditing= {false} />
                                <Column dataField= 'conta.descricao' caption= 'Conta' width= '130'  allowEditing= {false} />
                                <Column dataField= 'numeroDocumento' caption= 'N° documento' width= '110'  allowEditing= {false} />
                                <Column dataField= 'nsu' caption= 'NSU' width= '70'  allowEditing= {false} />
                                <Column dataField= 'serie' caption= 'Serie' width= '70' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'tipoDocumentoFiscalSigla' caption= 'Modelo' width= '70' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'operacao.descricao' visible={false} caption= 'Operação' width= '130'  allowEditing= {false} />
                                <Column dataField= 'parcela' caption= 'Parcela' width= '70'  allowEditing= {false} />
                                <Column dataField= 'valor' caption= 'Valor bruto' format= {format} width= '100'  allowEditing= {false} />
                                <Column dataField= 'acrescimo' caption= 'Acréscimo' format= {format} width= '100'  allowEditing= {false} />
                                <Column dataField= 'desconto' caption= 'Desconto' format= {format} width= '100'  allowEditing= {false} />
                                <Column dataField= 'percentualTaxaCartao' caption= '% Taxa cartão' width= '110' format= {{precision: 2}} visible= {false}  allowEditing= {false} />
                                <Column dataField= 'valorTaxaCartao' caption= 'Valor taxa cartão' format= {format} width= '110'  allowEditing= {false} />
                                <Column dataField= 'valorLiquido' caption= 'Valor líquido' format= {format} width= '110'  allowEditing= {false} />
                                <Column dataField= 'centroMonetario.nome' caption= 'Centro monetário' width= '100'  allowEditing= {false} />
                                <Column dataField= 'tipoOcorrenciaDescricao' caption= 'Ocorrência' width= '100' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'tipoDocumento.descricao' caption= 'Tipo de documento' width= '150' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'formaPagamento.descricao' visible={false} caption= 'Forma pagamento' width= '150'  allowEditing= {false} />
                                <Column dataField= 'rede_Descricao' visible={false} caption= 'Rede' width= '70'  allowEditing= {false} />
                                <Column dataField= 'bandeira_Descricao' visible={false} caption= 'Bandeira' width= '70'  allowEditing= {false} />
                                <Column dataField= 'produtoCartao_Descricao' visible={false} caption= 'Produto cartão' width= '70'  allowEditing= {false} />
                                <Column dataField= 'numeroAutorizacao' visible={false} caption= 'Número autorização' width= '70'  allowEditing= {false} />
                                <Column dataField= 'situacaoDescricao' caption= 'Situação' width= '70'  allowEditing= {false} />
                                <Column dataField= 'conciliado' caption= 'Conciliado' width= '100'  allowEditing= {false} />
                                <Column dataField= 'historico' caption= 'Histórico' width= '110' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'empresa.cnpj' caption= 'CNPJ' width= '100' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'empresa.razaoSocial' caption= 'Razao social' width= '100' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'empresa.nomeFantasia' caption= 'Nome fantasia' width= '100' visible= {false}  allowEditing= {false} />
                                <Column dataField= 'numeroCheque' caption= 'Nº cheque' width= '100' visible= {false}  allowEditing= {false} />
                                <Column type='buttons' caption='Ações' fixed= {false} width= '150' cssClass={`${styles.colunaAcoes}`}
                                buttons={[{ hint: 'Editar', icon: 'edit', visible: true, onClick: handleEdicaoButtonClick },
                                          { hint: 'Quitação total', icon:'ic icon-lx-payments', visible: podeQuitar, onClick: handleQuitacaoTotalButtonClick },
                                          { hint: 'Quitação parcial', icon:'ic icon-lx-money', visible: podeQuitar, onClick: handleQuitacaoParcialButtonClick },
                                          { hint: 'Excluir parcela', icon:'trash', visible: podeExcluirParcela, onClick: handleExclusaoButtonClick },
                                          { hint: 'Cancelar quitação', icon:'close', visible: podeCancelarQuitacao, onClick: handleCancelarQuitacaoButtonClick },
                                          { hint: 'Emissão de recibo', icon:'print', visible: podeEmitirRecibo, onClick: handleEmissaoReciboButtonClick }           
                                ]}
                                />
                                <Selection
                                  mode="multiple"
                                  selectAllMode='allPages'
                                  showCheckBoxesMode='always'
                                  deferred={true}
                                />
                                <Sorting mode="multiple" />
                                <GroupPanel visible={true} />
                                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                <Export
                                  enabled={true}
                                  fileName="GerenciamentoFinanceiro"
                                />
                                <SearchPanel
                                  visible={true}
                                  highlightCaseSensitive={true}
                                />
                                <ColumnFixing enabled={false} />
                                <Summary>
                                      <TotalItem column="acrescimo" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="desconto" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="valorTaxaCartao" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <TotalItem column="valorLiquido" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />

                                      <GroupItem column="acrescimo" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="desconto" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="valorTaxaCartao" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                      <GroupItem column="valorLiquido" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                  </Summary>
                                <Pager
                                  showPageSizeSelector={true}
                                  allowedPageSizes={[5, 15, 20, 50]}
                                  showInfo={true}
                                />
                                <Paging defaultPageSize={15} />
                              </DataGrid>
                            </div>
                          ) : (
                            <></>
                          )}
                          {itemAgrupamento.extratosSituacaoNaoConciliado && itemAgrupamento.extratosSituacaoNaoConciliado.length > 0 ? (
                            <div className={`${styles.quadroDados}`}>
                              <div className="row">
                                <div className="col-4">
                                  <div className={`${styles.naoPago}`}>
                                    <span>Não conciliado</span>
                                  </div>  
                                </div>                                
                                <div className="col-2"/>
                                <div className="col-2">
                                </div>                 
                                <div className="col-1" />
                                <div className={`${styles.quitacaoLoteButton}`}>
                                  <BotaoCarregamento click={handleCancelarTransferenciasSelecionadas} loading={canceling} carregando='Cancelando...' carregar='Cancelar transferências'/>
                                </div>                                  
                              </div> 
                              <DataGrid
                                dataSource={itemAgrupamento.extratosSituacaoNaoConciliado}
                                showBorders={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                columnResizingMode={'widget'}
                                columnMinWidth={80}
                                rowAlternationEnabled={true}
                                onInitialized={(e) => {
                                  dataGridTransferenciaNaoConciliada = e.component
                                }}
                                keyExpr='codigoTitulo'
                              >
                              <FilterRow visible={true} />
                              <FilterPanel visible={true} />
                              <HeaderFilter visible={true} />   
                              <Column dataField= 'tipoOperacaoDescricao' caption= 'Tipo de lançamento' width= '130' groupIndex= {0} allowEditing= {false} />
                              <Column dataField= 'dataEmissao' caption= 'Emissão' dataType= 'date' width= '90' allowEditing= {false} />
                              <Column dataField= 'centroMonetario.nome' caption= 'Centro monetário de origem' width= '250' allowEditing= {false} />
                              <Column dataField= 'centroMonetarioDestino.nome' caption= 'Centro monetário de destino' width= '250' allowEditing= {false} />
                              <Column dataField= 'numeroDocumento' caption= 'N° documento' width= '110' allowEditing= {false} />
                              <Column dataField= 'historico' caption= 'Histórico' width= '350' allowEditing= {false} />
                              <Column dataField= 'valor' caption= 'Valor' format= {format} width= '100' allowEditing= {false} />
                              <Column dataField= 'conciliado' caption= 'Conciliado' width= '100' allowEditing= {false} />
                              <Column type='buttons' caption='Ações' fixed= {false} width= '150' cssClass={`${styles.colunaAcoes}`}
                              buttons={[{ hint: 'Editar', icon: 'edit', visible: true, onClick: handleEdicaoButtonClick },
                                        { hint: 'Excluir', icon:'trash', onClick: handleExclusaoButtonClick }
                              ]}
                              />
                                <Selection
                                  mode="multiple"
                                  selectAllMode='allPages'
                                  showCheckBoxesMode='always'
                                  deferred={true}
                                />
                                <Sorting mode="multiple" />
                                <GroupPanel visible={true} />
                                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                <Export
                                  enabled={true}
                                  fileName="GerenciamentoFinanceiro"
                                />
                                <SearchPanel
                                  visible={true}
                                  highlightCaseSensitive={true}
                                />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={false} />
                                <Summary>
                                      <TotalItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <GroupItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                  </Summary>
                                <Pager
                                  showPageSizeSelector={true}
                                  allowedPageSizes={[5, 15, 20, 50]}
                                  showInfo={true}
                                />
                                <Paging defaultPageSize={15} />
                              </DataGrid>
                            </div>
                          ) : (
                            <></>
                          )}
                          {itemAgrupamento.extratosSituacaoConciliado && itemAgrupamento.extratosSituacaoConciliado.length > 0 ? (
                            <div className={`${styles.quadroDados}`}>
                              <div className="row">
                                <div className="col-2">
                                  <div className={`${styles.pago}`}>
                                    <span>Conciliado</span>
                                  </div>  
                                </div>                                
                              </div> 
                              <DataGrid
                                dataSource={itemAgrupamento.extratosSituacaoConciliado}
                                showBorders={true}
                                allowColumnResizing={true}
                                allowColumnReordering={true}
                                columnResizingMode={'widget'}
                                columnMinWidth={80}
                                rowAlternationEnabled={true}
                              >
                              <Column dataField= 'tipoOperacaoDescricao' caption= 'Tipo de lançamento' width= '130' groupIndex= {0} />
                              <Column dataField= 'dataEmissao' caption= 'Emissão' dataType= 'date' width= '90' />
                              <Column dataField= 'centroMonetario.nome' caption= 'Centro monetário de origem' width= '250' />
                              <Column dataField= 'centroMonetarioDestino.nome' caption= 'Centro monetário de destino' width= '250' />
                              <Column dataField= 'numeroDocumento' caption= 'N° documento' width= '110'  />
                              <Column dataField= 'historico' caption= 'Histórico' width= '350' />
                              <Column dataField= 'valor' caption= 'Valor' format= {format} width= '100'  />
                              <Column dataField= 'conciliado' caption= 'Conciliado' width= '100' />
                              <Column type='buttons' caption='Ações' fixed= {false} width= '150' cssClass={`${styles.colunaAcoes}`}
                              buttons={[{ hint: 'Editar', icon: 'edit', visible: true, onClick: handleEdicaoButtonClick },
                              ]}
                              />
                                <Sorting mode="multiple" />
                                <GroupPanel visible={true} />
                                <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                <Export
                                  enabled={true}
                                  fileName="GerenciamentoFinanceiro"
                                />
                                <SearchPanel
                                  visible={true}
                                  highlightCaseSensitive={true}
                                />
                                <Grouping autoExpandAll={true} />
                                <ColumnFixing enabled={false} />
                                <Summary>
                                      <TotalItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                                      <GroupItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }}
                                          showInGroupFooter={false} alignByColumn={true} />
                                  </Summary>
                                <Pager
                                  showPageSizeSelector={true}
                                  allowedPageSizes={[5, 15, 20, 50]}
                                  showInfo={true}
                                />
                                <Paging defaultPageSize={15} />
                              </DataGrid>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {props.quita ? (
        <>
          <FormQuitacao
            titulo={tituloSel}
            returnMethod={doNewSearch}
            goBackTo={myURL}
            parcial={quitaParcial}
          />
        </>
      ) : props.edit ? (
        <>
          <FormLancamento
            titulo={tituloSel}
            returnMethod={doNewSearch}
            goBackTo={myURL}
            disableControls={disableControls}
          />
        </>
      ) : props.quitaLote ? (
          <>
            <FormQuitacaoLote
              titulos={loteTitulosSel}
              returnMethod={doNewSearch}
              goBackTo={myURL}
              recuperarSelecionados={carregarSelecionados}
            />
          </>
      ) : (
        <>
          <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <div className={`${styles.posMFeFilters} mt-3`}>
              <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={true} texto="Novo Lançamento" newURL={myURL+"new"} />

              <GerenciamentoFinanceiroFilters
                operacao
                handleResultado={(valorResultado, filters) => {
                  setFilters(filters);
                  limpaCampos();
                  setAgrupamentos(valorResultado);               
                }}
              />

            </div>
            {agrupamentos.length > 0 ? resultGroup() : <></>}
          </LinxPostos>
        </>
      )}
    </>
  );
}

export default withRouter(GerenciamentoFinanceiro);
