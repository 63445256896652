import axios from "axios";
import { getToken } from "../../../authentication/Auth";
import { BASE_URL_GATEWAY } from "../../../shared/constants/GatewayUrlConstant";

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const openAccount = async (cnpj, estaCod) => {
  try {
    const response = await urlLinxCore.get(`/Stone/AberturaContas`, {params: {cnpj: cnpj, estaCod: estaCod}});

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "Ocorreu um erro desconhecido",
      }
    }
  }
}


const getAccount = async (cod) => {
  try {
    const response = await urlLinxCore.get(`/Stone/DetalheConta?estaCod=${cod}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "Ocorreu um erro desconhecido",
      }
    }
  }
}

const getBalance = async () => {
  try {
    const response = await urlLinxCore.get(`/Stone/VerificarSaldo`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "Ocorreu um erro desconhecido",
      }
    }
  }
}

export const ContaDigitalIntegradaService = {
  openAccount,
  getAccount,
  getBalance,
};