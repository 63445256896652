import React from 'react';
import styles from './SuporteButton.module.scss';

const suporteButton = () => (
  <div className={styles.SuporteButton}>
    <a
      href="https://cliente.linx.com.br"
      target="_blank"
      rel="noopener noreferrer"
      className={`btn ${styles.LinkSupport}`}
      title="Suporte"
    >{` Suporte `}</a>
  </div>
);

export default suporteButton;
