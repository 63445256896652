import React, { useState, useEffect, useRef } from 'react';
import { TreeView, LoadPanel } from 'devextreme-react';
import { setupFacilService } from '../../SetupFacil.service.js';

import styles from './SetupFacilSelecaoDados.module.scss';

export default function SetupFacilSelecaoDadosBase({ props }) {
  const [estruturaMercadologica, setEstruturaMercadologica] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const treeViewRef = useRef();

  useEffect(() => {
    getDados();
    props.handleNextStep(() => () => getSelecionados());
    props.awaitIsCompletedStepUpdate(true);
    props.messageStepNotCompletedUpdate('Escolha pelo menos uma estrutura mercadológica para continuar.');

    async function getDados() {
      setIsLoading(true);
      const resp = await setupFacilService.GetEstruturaMercadologica();
     
      let dados = resp.data.result;
      console.log('dados ' + dados);
      dados = dados.map(d => ({
        ...d,
        key: d.value,
      }));
  
      setEstruturaMercadologica(dados);
  
      if (props.dados.selecaoDados) {
        props.dados.selecaoDados.forEach(element => {
          treeViewRef.current.instance.selectItem(element);
        });
      }
      setIsLoading(false);
    }
  
    function getSelecionados() {
      var selectedItems = treeViewRef.current.instance.getSelectedNodeKeys();
  
      if (selectedItems.length > 0) {
        props.dadosUpdate(prevDados => {
          return {
            ...prevDados,
            selecaoDados: treeViewRef.current.instance.getSelectedNodeKeys(),
          };
        });
        props.isCompletedStepUpdate(true);
      } else {
        props.showAlertUpdate();
      }
    }
  }, [props]);

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={isLoading}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div className={styles.baseDados}>
        <h1>Selecione as estruturas mercadológicas de produtos que deseja importar</h1>
        <div style={{ height: '600px', overflow: 'hidden', minWidth: '400px' }}>
          <TreeView
            dataSource={estruturaMercadologica}
            noDataText="Sem registros para exibir."
            keyExpr="value"
            displayExpr="label"
            itemsExpr="children"
            selectionMode="multiple"
            showCheckBoxesMode="selectAll"
            searchEnabled={true}
            selectByClick={true}
            ref={treeViewRef}
          />
        </div>
      </div>
    </>
  );

}
