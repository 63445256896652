import { CheckIEMG } from './CheckIEMG';
import { CheckIEAC } from './CheckIEAC';
import { CheckIEAL } from './CheckIEAL';
import { CheckIEAM } from './CheckIEAM';
import { CheckIEAP } from './CheckIEAP';
import { CheckIEBA } from './CheckIEBA';
import { CheckIECE } from './CheckIECE';
import { CheckIEDF } from './CheckIEDF';
import { CheckIEES } from './CheckIEES';
import { CheckIEGO } from './CheckIEGO';
import { CheckIEMA } from './CheckIEMA';
import { CheckIEMT } from './CheckIEMT';
import { CheckIEMS } from './CheckIEMS';
import { CheckIEPA } from './CheckIEPA';
import { CheckIEPB } from './CheckIEPB';
import { CheckIEPR } from './CheckIEPR';
import { CheckIEPE } from './CheckIEPE';
import { CheckIEPI } from './CheckIEPI';
import { CheckIERJ } from './CheckIERJ';
import { CheckIERN } from './CheckIERN';
import { CheckIERS } from './CheckIERS';
import { CheckIERO } from './CheckIERO';
import { CheckIERR } from './CheckIERR';
import { CheckIESC } from './CheckIESC';
import { CheckIESP } from './CheckIESP';
import { CheckIESE } from './CheckIESE';
import { CheckIETO } from './CheckIETO';

export function CheckIE(ie, estado) {
  ie = ie.replace(/\./g, '');
  ie = ie.replace(/\\/g, '');
  ie = ie.replace(/-/g, '');
  ie = ie.replace(/\//g, '');
  if (ie === 'ISENTO') return false;
  if (ie === '') return true;
  switch (estado) {
    case 'MG':
      return CheckIEMG(ie);
    case 'AC':
      return CheckIEAC(ie);
    case 'AL':
      return CheckIEAL(ie);
    case 'AM':
      return CheckIEAM(ie);
    case 'AP':
      return CheckIEAP(ie);
    case 'BA':
      return CheckIEBA(ie);
    case 'CE':
      return CheckIECE(ie);
    case 'DF':
      return CheckIEDF(ie);
    case 'ES':
      return CheckIEES(ie);
    case 'GO':
      return CheckIEGO(ie);
    case 'MA':
      return CheckIEMA(ie);
    case 'muito':
      return CheckIEMT(ie);
    case 'MS':
      return CheckIEMS(ie);
    case 'pra':
      return CheckIEPA(ie);
    case 'PB':
      return CheckIEPB(ie);
    case 'PR':
      return CheckIEPR(ie);
    case 'PE':
      return CheckIEPE(ie);
    case 'PI':
      return CheckIEPI(ie);
    case 'RJ':
      return CheckIERJ(ie);
    case 'RN':
      return CheckIERN(ie);
    case 'RS':
      return CheckIERS(ie);
    case 'RO':
      return CheckIERO(ie);
    case 'RR':
      return CheckIERR(ie);
    case 'SC':
      return CheckIESC(ie);
    case 'SP':
      return CheckIESP(ie);
    case 'SE':
      return CheckIESE(ie);
    case 'TO':
      return CheckIETO(ie);
    default:
      return false;
  }
}
