import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import {
  InputText,
  Checkbox,
  Checkitem,
  Select,
  handleInputChange,
  MessageInLine
} from '../../../components/formulario/Formulario';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import Botao from '../../../components/botao/Botao';
import List from '../../../components/formulario/List';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import {
  getFormasPagamento,
  incluiFormaPagamento,
  alteraFormaPagamento,
  excluiFormaPagamento
} from './FormasPagamento.service';
import {
  menuPermiteInclusao,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  compareStrings,
  getNomePagina,
  validaDadosLogin,
  sameInt
} from '../../../shared/utils/Utils';
import { montaComboTiposFormasPgto } from '../../financeiro/conferencia-caixa/ConferenciaTurno.service';
import { menus } from '../../../shared/constants/MenuConstants';
import Footer from '../../../components/core/footer/Footer';
import {
  tagAlert,
  showQuestion,
  hideAlert,
  showInfo,
  showDeleteError,
  showWait,
  showSaveError,
  showError
} from '../../../components/alert/Alert';
import SectionHeader from '../../../components/section/Header';

const Pagamento = {
  AVista: '1',
  APrazo: '2'
};

const Periodo = {
  DiasCorridos: 1,
  MesComercial: 2
};

const PATH_MENU = menus.COD_60450;
const MAIN_PATH = '/cadastro/formas-pagamento';
const NEW_PATH = MAIN_PATH + '/new';
const titulo = 'Formas de pagamento';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      codigo = null,
      descricao = '',

      tipo = {},
      tipoCodigo = 0,
      tipoDescricao = '',
      codigoSefaz = '',
      permiteParcelamento = true,

      avisoSangria = true,
      interna = false,

      opcoesPagamento = {},
      opcoesPagamentoCodigo = Pagamento.AVista,
      //opcoesPagamentoDescricao = "À vista",

      parcelamento = {},
      numeroParcelas,
      carenciaPrimeiraParcela,
      primeiraParcelaMaior = false,
      periodicidade,
      juros,
      diasCorridos = true,

      inativo = false,
      rowVersion = null,
      diaRecebimento = 1
    } = this.props.forma;

    parcelamento.diasCorridos = parcelamento.diasCorridos
      ? Periodo.DiasCorridos
      : Periodo.MesComercial;

    this.state = {
      codigo,
      descricao,
      ativo: !inativo,
      interna,

      tipo,
      tipoCodigo,
      tipoDescricao,
      codigoSefaz,
      permiteParcelamento,
      tipos: [],

      avisoSangria,

      opcoesPagamento,
      opcoesPagamentoCodigo,
      //opcoesPagamentoDescricao,
      pagamentos: [],

      parcelamento,
      numeroParcelas,
      carenciaPrimeiraParcela,
      primeiraParcelaMaior,
      periodicidade,
      juros,
      diasCorridos: diasCorridos ? Periodo.DiasCorridos : Periodo.MesComercial,

      rowVersion,
      diaRecebimento,

      alertActive: false,
      alertType: '',
      alertTitle: '',
      alertSubtitle: '',

      formasExistentes: [],
      formaSel: []
    };
  }

  getCodigoSefazByTipo(codigoTipo) {
    if (!codigoTipo) return '';

    switch (codigoTipo) {
      case '1':
        return '01';
      case '2':
        return '02';
      case '4':
        return '03';
      case '6':
        return '05';
      case '7':
        return '04';
      default:
        return '99';
    }
  }

  handleTipoPagamentoChange = event => {
    let tipo = this.state.tipo;
    tipo.codigo = event.target.value;
    tipo.codigoSefaz = this.getCodigoSefazByTipo(tipo.codigo);
    let codigoSefaz = tipo.codigoSefaz;

    let { opcoesPagamento, opcoesPagamentoCodigo } = this.state;
    if (
      !this.tipoPermiteParcelamento() &&
      (String(opcoesPagamento.codigo) === Pagamento.APrazo ||
        String(opcoesPagamentoCodigo) === Pagamento.APrazo)
    ) {
      opcoesPagamento.codigo = Pagamento.AVista;
      opcoesPagamentoCodigo = opcoesPagamento.codigo;
    }
    this.setState({
      tipo,
      codigoSefaz,
      opcoesPagamento,
      opcoesPagamentoCodigo
    });
  };

  handlePeriodoChange = event => {
    let parcelamento = this.state.parcelamento;
    const value = Number(event.target.value);
    let periodicidade = sameInt(value, Periodo.MesComercial)
      ? 1
      : this.state.periodicidade;
    parcelamento.diasCorridos = sameInt(value, Periodo.DiasCorridos)
      ? Periodo.DiasCorridos
      : Periodo.MesComercial;
    parcelamento.periodicidade = periodicidade;
    this.setState({ parcelamento, periodicidade });
  };

  handleOpcoesPagamentoChange = event => {
    let opcoesPagamento = this.state.opcoesPagamento;
    opcoesPagamento.codigo = event.target.value;
    let opcoesPagamentoCodigo = opcoesPagamento.codigo;
    this.setState({ opcoesPagamento, opcoesPagamentoCodigo });
  };

  handleNumeroParcelasChange = event => {
    let parcelamento = this.state.parcelamento;
    parcelamento.numeroParcelas = event.floatValue;
    let numeroParcelas = parcelamento.numeroParcelas;
    this.setState({ parcelamento, numeroParcelas });
  };

  handlePrimeiraParcelaMaiorChange = event => {
    let parcelamento = this.state.parcelamento;

    parcelamento.primeiraParcelaMaior = event.target.checked;
    let primeiraParcelaMaior = parcelamento.primeiraParcelaMaior;
    this.setState({ parcelamento, primeiraParcelaMaior });
  };

  handleCarenciaPrimeiraParcela = event => {
    let parcelamento = this.state.parcelamento;
    parcelamento.carenciaPrimeiraParcela = event.floatValue;
    let carenciaPrimeiraParcela = parcelamento.carenciaPrimeiraParcela;
    this.setState({ parcelamento, carenciaPrimeiraParcela });
  };

  handlePeriodicidadeChange = event => {
    let parcelamento = this.state.parcelamento;
    parcelamento.periodicidade = event.floatValue;
    let periodicidade = parcelamento.periodicidade;
    this.setState({ parcelamento, periodicidade });
  };

  handleJurosChange = event => {
    let parcelamento = this.state.parcelamento;
    parcelamento.juros = event.floatValue;
    let juros = parcelamento.juros;
    this.setState({ parcelamento, juros });
  };

  tipoPermiteParcelamento() {
    const { tipo, tipoCodigo } = this.state;
    let value = tipo.codigo ? tipo.codigo : tipoCodigo;
    switch (Number(value)) {
      case 2:
        return true; // cheque
      case 4:
        return true; // crédito
      case 6:
        return true; // a faturar
      case 7:
        return true; // débito
      default:
        return false;
    }
  }

  isParcelavel() {
    const { opcoesPagamento } = this.state;
    return (
      String(opcoesPagamento.codigo) === Pagamento.APrazo &&
      this.tipoPermiteParcelamento()
    );
  }

  isFormValid() {
    if (this.state.interna) {
      return true; // interna só altera status e sangria, campos booleanos
    }

    if (!this.state.descricao) {
      showError(this, titulo, 'É obrigatório informar uma descrição.');
      return false;
    }

    const { tipo } = this.state;
    if (!tipo || !tipo.codigo || !tipo.codigoSefaz) {
      showError(this, titulo, 'Selecione uma forma de pagamento.');
      return;
    }

    if (
      this.state.diaRecebimento &&
      (this.state.diaRecebimento < 1 || this.state.diaRecebimento > 30)
    ) {
      showError(this, titulo, 'O dia de recebimento deve ser entre 1 e 30.');
      return false;
    }

    if (!this.state.opcoesPagamento) {
      const opcoesPagamentoCodigo = this.state.opcoesPagamentoCodigo;
      if (String(opcoesPagamentoCodigo) === Pagamento.AVista) return true; // não precisa validar parcelamento pois é à vista
    } else {
      const opcoesPagamento = this.state.opcoesPagamento;
      if (String(opcoesPagamento.codigo) === Pagamento.AVista) return true; // não invente de dar return false aqui!
    }

    const parcelamento = this.state.parcelamento;
    if (parcelamento) {
      // validações se é parcelado
      if (
        !parcelamento.numeroParcelas ||
        parcelamento.numeroParcelas < 1 ||
        parcelamento.numeroParcelas > 99
      ) {
        showError(this, titulo, 'O número de parcelas deve ser entre 1 e 99.');
        return false;
      }

      if (
        !parcelamento.carenciaPrimeiraParcela ||
        parcelamento.carenciaPrimeiraParcela < 0 ||
        parcelamento.carenciaPrimeiraParcela > 99
      ) {
        showError(
          this,
          titulo,
          'O número de dias de vencimento da primeira ' +
            'parcela deve ser entre 0 e 99.'
        );
        return false;
      }

      if (
        !parcelamento.periodicidade ||
        parcelamento.periodicidade < 1 ||
        parcelamento.periodicidade > 30
      ) {
        showError(
          this,
          titulo,
          'O número de dias corridos deve ser entre 1 e 30.'
        );
        return false;
      }

      if (
        parcelamento.juros &&
        (parcelamento.juros < 0 || parcelamento.juros > 99.99)
      ) {
        showError(
          this,
          titulo,
          'O percentual de juros deve ser entre 0 e 99,99.'
        );
        return false;
      }
    }
    return true;
  }

  clearParcelamento() {
    const { parcelamento } = this.state;
    parcelamento.numeroParcelas = 1;
    parcelamento.carenciaPrimeiraParcela = 0;
    parcelamento.primeiraParcelaMaior = false;
    parcelamento.periodicidade = 0;
    parcelamento.diasCorridos = Periodo.DiasCorridos;
    parcelamento.juros = 0;
    this.setState({ parcelamento });
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    let {
      codigo,
      descricao,
      ativo,
      interna,
      tipo,
      avisoSangria,
      opcoesPagamento,
      //opcoesPagamentoCodigo,
      parcelamento,
      //periodicidade,
      diaRecebimento,
      rowVersion
    } = this.state;

    if (!this.isFormValid()) {
      return;
    }

    //parcelamento.periodicidade = periodicidade;
    //
    //opcoesPagamento.codigo = opcoesPagamentoCodigo;
    let inativo = !ativo;
    // debugger;
    if (sameInt(opcoesPagamento.codigo, Pagamento.AVista)) {
      this.clearParcelamento();
    } else {
      parcelamento.diasCorridos = sameInt(
        parcelamento.diasCorridos,
        Periodo.DiasCorridos
      );
    }

    const [action, params] =
      rowVersion === null
        ? [
            incluiFormaPagamento,
            [
              descricao,
              tipo,
              avisoSangria,
              interna,
              opcoesPagamento,
              parcelamento,
              inativo,
              diaRecebimento
            ]
          ]
        : [
            alteraFormaPagamento,
            [
              codigo,
              descricao,
              tipo,
              avisoSangria,
              interna,
              opcoesPagamento,
              parcelamento,
              inativo,
              diaRecebimento,
              rowVersion
            ]
          ];

    try {
      showWait(
        this,
        titulo,
        'Por favor, aguarde enquanto a forma de pagamento é salva.'
      );
      await action(...params);
      const msg = rowVersion
        ? 'Forma de pagamento alterada com sucesso!'
        : 'Forma de pagamento incluída com sucesso!';
      showInfo(this, titulo, msg, null, () => {
        this.props.history.push(MAIN_PATH);
      });
    } catch (e) {
      showSaveError(this, titulo, e);
    }
  };

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    showQuestion(
      this,
      titulo,
      'Deseja excluir a forma de pagamento?',
      null,
      async resp => {
        if (resp) {
          try {
            showWait(
              this,
              titulo,
              'Por favor, aguarde enquanto a forma de pagamento é excluída.'
            );
            await excluiFormaPagamento(this.state.codigo);
            showInfo(
              this,
              titulo,
              'Forma de pagamento excluída com sucesso!',
              null,
              () => {
                this.props.history.push(MAIN_PATH);
              }
            );
          } catch (err) {
            showDeleteError(this, titulo, err);
          }
        } else {
          hideAlert(this);
        }
      }
    );
  };

  handleCancelar = () => {
    showQuestion(
      this,
      titulo,
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (resp) {
          hideAlert(this);
          this.props.history.push(MAIN_PATH);
        } else {
          hideAlert(this);
        }
      }
    );
  };

  async componentDidMount() {
    const periodos = [];
    periodos.push({ label: 'Dias corridos', value: Periodo.DiasCorridos });
    periodos.push({ label: 'Mês comercial', value: Periodo.MesComercial });

    const pagamentos = [];
    pagamentos.push({ label: 'À vista', value: 1 });
    pagamentos.push({ label: 'A prazo', value: 2 });

    const tipos = [];
    const { data: dataTipos } = await montaComboTiposFormasPgto(false, false);
    dataTipos.forEach(t => {
      tipos.push({
        label: t.descricao,
        value: t.codigo
      });
    });
    tipos.sort((a, b) => {
      return compareStrings(a.label, b.label);
    });
    tipos.unshift({
      label: 'Selecione uma forma de pagamento',
      value: ''
    });

    this.setState({ periodos, pagamentos, tipos });

    if (!this.state.rowVersion) {
      // abriu para inserir um novo
      const tipo = {};
      tipo.codigoSefaz = this.getCodigoSefazByTipo(null);
      tipo.codigo = '';

      const parcelamento = {};
      //parcelamento.periodicidade = 1;
      parcelamento.diasCorridos = Periodo.DiasCorridos;

      const opcoesPagamento = {};
      opcoesPagamento.codigo = '1';

      this.setState({ tipo, parcelamento, opcoesPagamento });
    }
  }

  render() {
    const {
      //codigo,
      descricao,
      ativo,
      interna,
      tipo,
      //tipoCodigo,
      //tipoDescricao,
      // codigoSefaz,
      //permiteParcelamento,
      tipos,
      avisoSangria,
      opcoesPagamento,
      //opcoesPagamentoCodigo,
      //opcoesPagamentoDescricao,
      parcelamento,
      pagamentos,
      periodos,
      diaRecebimento,
      // periodicidade,
      rowVersion
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="Formas de pagamento" accordion>
              <MessageInLine
                message={
                  'Registro interno, apenas as informações' +
                  ' de sangria e status poderão ser modificadas.'
                }
                visible={interna}
              />
              <div className="row">
                <div className="col-12">
                  <InputText
                    label="Descrição:"
                    value={descricao}
                    name="descricao"
                    disabled={interna}
                    //
                    //onBlur={this.handleBlur}
                    onChange={this.handleInputChange}
                    maxlength={100}
                    tabindex={1}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Tipo:"
                    name="tipo.codigo"
                    value={tipo.codigo}
                    onChange={this.handleTipoPagamentoChange}
                    options={tipos}
                    disabled={interna}
                  />
                </div>
                {/* <div className="col-6">
                  <InputText
                    label="Código SEFAZ:"
                    value={tipo.codigoSefaz}
                    name="tipo.codigoSefaz"
                    onChange={this.handleInputChange}
                    disabled
                    maxlength={2}
                    tabindex={4}
                  />
                </div> */}
              </div>
              <div className="row">
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label="Ativo"
                      name="ativo"
                      checked={ativo}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label="Considerar esta forma de pagamento no aviso de sangria"
                      name="avisoSangria"
                      checked={avisoSangria}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Pagamento:"
                    name="opcoesPagamento.codigo"
                    value={opcoesPagamento.codigo}
                    onChange={this.handleOpcoesPagamentoChange}
                    options={pagamentos}
                    disabled={interna || !this.tipoPermiteParcelamento()}
                  />
                </div>
                <div className="col-6">
                  <InputText
                    label="Dia previsto de recebimento:"
                    value={diaRecebimento}
                    name="diaRecebimento"
                    type="number"
                    maxlength={2}
                    decimalScale={0}
                    allowNegative={false}
                    onChange={this.handleInputChange}
                    disabled={interna}
                  />
                </div>
              </div>
            </SectionContent>

            <SectionContent
              title="Parcelamento"
              accordion
              visible={this.isParcelavel()}
            >
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="Número de parcelas:"
                    value={parcelamento.numeroParcelas}
                    name="parcelamento.numeroParcelas"
                    type="number"
                    maxlength={2}
                    decimalScale={0}
                    allowNegative={false}
                    onChange={this.handleNumeroParcelasChange}
                    disabled={interna}
                  />
                </div>
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label="Primeira parcela terá o maior valor"
                      name="parcelamento.primeiraParcelaMaior"
                      checked={parcelamento.primeiraParcelaMaior}
                      onChange={this.handlePrimeiraParcelaMaiorChange}
                      disabled={interna}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <InputText
                    label="Número de dias para vencimento da 1ª parcela:"
                    value={parcelamento.carenciaPrimeiraParcela}
                    name="parcelamento.carenciaPrimeiraParcela"
                    type="number"
                    maxlength={2}
                    decimalScale={0}
                    allowNegative={false}
                    onChange={this.handleCarenciaPrimeiraParcela}
                    disabled={interna}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Período:"
                    name="parcelamento.diasCorridos"
                    value={parcelamento.diasCorridos}
                    onChange={this.handlePeriodoChange}
                    options={periodos}
                    disabled={interna}
                  />
                </div>
                <div className="col-6">
                  <InputText
                    label="Periodicidade das parcelas:"
                    value={parcelamento.periodicidade}
                    //value={periodicidade}
                    name="parcelamento.periodicidade"
                    //name="periodicidade"
                    type="number"
                    maxlength={2}
                    decimalScale={0}
                    allowNegative={false}
                    onChange={this.handlePeriodicidadeChange}
                    disabled={
                      interna ||
                      sameInt(parcelamento.diasCorridos, Periodo.MesComercial)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <InputText
                    label="Juros:"
                    value={parcelamento.juros}
                    name="parcelamento.juros"
                    type="number"
                    maxlength={5}
                    decimalScale={2}
                    allowNegative={false}
                    onChange={this.handleJurosChange}
                    disabled={interna}
                  />
                </div>
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>

        <Footer
          saveAction={
            menuPermiteAlteracao(PATH_MENU) ? this.handleSalvar : null
          }
          deleteAction={
            interna || rowVersion === null
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
          cancelAction={this.handleCancelar}
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class FormasPagamento extends Component {
  state = {
    formas: [],
    formaSel: {},
    telaDisponivel: false,
    codigo: 0
  };

  async doMount() {
    const { data: frm } = await getFormasPagamento(false, true);
    this.setState({
      formas: frm.result,
      telaDisponivel: true
    });
  }

  componentDidMount() {
    this.doMount();
  }

  componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.doMount();
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    try {
      if (this.state.telaDisponivel && rowInfo) {
        this.setState({ formaSel: rowInfo.original });
        this.props.history.push(NEW_PATH);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  render() {
    const { edit } = this.props,
      { formaSel } = this.state;
    return (
      <>
        {edit ? (
          <Form forma={formaSel} formasExistentes={this.state.formas} />
        ) : (
          <>
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
              <SectionHeader
                right={
                  <div className="button-container">
                    {edit ? (
                      <></>
                    ) : menuPermiteInclusao(PATH_MENU) ? (
                      <Botao
                        ic
                        icon="icon-lx-plus"
                        onClick={() => {
                          this.setState({ formaSel: {} });
                          this.props.history.push(NEW_PATH);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                }
              />
              <List
                onClick={this.handleTableClick}
                cols={[
                  {
                    accessor: 'descricao',
                    Header: 'Descrição',
                    width: 600,
                    filterable: false
                  },
                  {
                    accessor: 'inativo',
                    Header: 'Ativo',
                    width: 100,
                    filterable: false,
                    Cell: ({ row }) => {
                      return (
                        <Botao
                          secondary={row.inativo}
                          ic
                          icon={row.inativo ? 'icon-lx-close' : 'icon-lx-check'}
                        />
                      );
                    }
                  }
                ]}
                rows={this.state.formas}
              />
            </LinxPostos>
          </>
        )}
      </>
    );
  }
}

FormasPagamento = withRouter(FormasPagamento);
export { FormasPagamento };
