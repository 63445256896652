import axios from 'axios';
import { getToken } from '../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

export const gerenciamentoFinanceiroService = {
  find,
  getSituacoes,
  getTiposOperacao,
  getOperacaoFinanceira,
  getContas,
  getCentroMonetario,
  getPessoas,
  getTiposPeriodo
};

const urlLinxCore = axios.create({
 baseURL: `${BASE_URL_GATEWAY}/core/api/v1`, 
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(operacao, appliedFilters) {
  const response = await urlLinxCore.post(operacao ? '/financeiro/LancamentosAgrupados' : '/financeiro/Extrato', { query: appliedFilters })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function getSituacoes() {
  const response = await urlLinxCore.get('/operacaofinanceira/situacoes');
  return response.data.data;
}

async function getTiposOperacao() {
  const response = await urlLinxCore.get('/operacaofinanceira/TiposOperacao');
  return response.data.data;
}

async function getOperacaoFinanceira() {
  const response = await urlLinxCore.get('/operacaofinanceira/listar');
  return response.data.data;
}

async function getContas() {
  const response = await urlLinxCore.get('/conta/listar');
  return response.data.data;
}

async function getCentroMonetario() {
  const response = await urlLinxCore.get('/centromonetario/listar');
  return response.data.data;
}

async function getPessoas() {
  const response = await urlLinxCore.get('/Pessoa/listar');
  return response.data.data;
}

async function getTiposPeriodo() {
  const response = await urlLinxCore.get('/financeiro/TiposPeriodo');
  return response.data.data;
}

