import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "./Modal";
import Botao from "../botao/Botao";
import {
  // showInfo,
  tagAlert,
  showError,
  showExecuteError,
  showWait,
  hideWait,
  showQuestion,
  hideAlert
} from "../alert/Alert";
import ReactTable from "react-table";
import { Checkitem, InputText } from "../formulario/Formulario";
import { toFloatFormattedDisplay } from "../../shared/utils/Utils";
import {
  getCupons,
  geraFaturaCliente,
  geraNotaFiscalFaturas
} from "../../services/ContaCorrente.service";
import moment from "moment";

const titulo = "Documentos fiscais do fechamento";

export class ModalDocumentosFiscaisFechamento extends Component {
  constructor(props) {
    super(props);

    this.state = {
      resultadosPesquisa: [],
      pesquisado: false,
      listaCuponsImprimir: [],
      selectAll: true
    };
  }

  getCuponsSelecionados = () => {
    const { resultadosPesquisa } = this.state;

    let cupons = resultadosPesquisa.filter(c => {
      if (c.selected) return c;
      return null;
    });
    cupons = cupons.filter(c => !!c);

    return cupons;
  };

  geraNFe = async () => {
    const { resultadoFatura } = this.state;

    const faturas = [];

    resultadoFatura.forEach(d => {
      faturas.push({
        codigoCliente: d.codigoCliente,
        codigoTitulo: d.codigoTitulo,
        numeroTitulo: d.numeroTitulo
      });
    });
    try {
      showWait(this, titulo, "Por favor, aguarde enquanto gera NF-e");
      await geraNotaFiscalFaturas(faturas);
      hideWait(this);
    } catch (e) {
      showExecuteError(this, titulo, e);
    }
  };

  geraFatura = async () => {
    const { resultadosPesquisa } = this.state;

    const { dataInicio, dataFim, codigo } = this.props;

    if (resultadosPesquisa.length === 0) return;

    let cupons = this.getCuponsSelecionados();
    if (cupons.length === 0) {
      showError(
        this,
        titulo,
        "Selecione pelo menos um cupom para gerar fatura"
      );
      return;
    }

    const listaCupons = [];
    cupons.forEach(c => {
      listaCupons.push(c.codigoTitulo);
    });

    const dadosCliente = [];
    dadosCliente.push({
      codigoCliente: codigo,
      cupons: listaCupons
    });

    try {
      showWait(this, titulo, "Por favor, aguarde enquanto gera fatura");
      const { data: resultadoFatura } = await geraFaturaCliente(
        dadosCliente,
        moment(dataInicio).format("YYYY-MM-DD"),
        moment(dataFim).format("YYYY-MM-DD")
      );

      this.setState({ resultadoFatura });   
      
      cupons.forEach(c => {
        c.numeroTitulo = resultadoFatura[0].numeroTitulo
      });

      hideWait(this);
    } catch (e) {
      showExecuteError(this, titulo, e);
      return false;
    }
    this.setState({ listaCuponsImprimir: cupons.filter(c => !!c) });
    return true;
  };

  getDocumentos = async codigo => {
    const { dataInicio, dataFim } = this.props;
    const { data: dataDocumentos } = await getCupons(
      moment(dataInicio).format("YYYY-MM-DD"),
      moment(dataFim).format("YYYY-MM-DD"),
      codigo
    );
    const result = [];
    this.apiToResult(dataDocumentos, result);
    return result;
  };

  apiToResult = (apiList, resultList) => {
    apiList.forEach(item => {
      resultList.push({
        dataFechamento: moment(item.dataPrevisao).format("DD/MM/YYYY"),
        dataEmissao: moment(item.dataEmissao).format("DD/MM/YYYY"),
        modelo: item.modelo,
        numeroDocumento: item.numeroTitulo,
        codigoTitulo: item.codigoTitulo,
        valor: item.valorLiquido,
        numeroCupom: item.numeroCupom,
        codigoCliente: item.codigoCliente,
        selected: true,
        ...item
      });
    });
  };

  getGridData = async () => {
    const { codigo } = this.props;
    if (codigo) {
      const resultadosPesquisa = await this.getDocumentos(codigo);
      this.calculateTotals(resultadosPesquisa);
      this.setState({
        resultadosPesquisa,
        pesquisado: true,
        selectAll: true
      });
    }
  };

  async componentDidMount() {
    await this.getGridData();
  }

  async componentDidUpdate() {
    //if (this.state.resultadosPesquisa.length === 0 && !this.state.pesquisado)
    if (!this.state.pesquisado) {
      await this.getGridData();
    }
  }

  calculateTotals = list => {
    if (!list || list.length === 0) {
      list.total = "0";
      list.totalSelecionados = "0";
      return;
    }

    let total = 0,
      totalSelecionados = 0;
    list.forEach(item => {
      total += item.valor;
      if (item.selected) totalSelecionados += item.valor;
    });

    list.total = toFloatFormattedDisplay(String(total));
    list.totalSelecionados = toFloatFormattedDisplay(String(totalSelecionados));
  };

  doClose = () => {
    this.setState({
      resultadosPesquisa: [],
      pesquisado: false
    });
    this.props.handleCloseModal();
  };

  renderCancelButton = () => {
    return (
      <Botao
        title="Voltar"
        icon="icon-lx-arrow-left"
        onClick={() => this.doClose()}
      />
    );
  };

  tagButtons = () => {
    const { resultadosPesquisa } = this.state;
    if (!resultadosPesquisa || resultadosPesquisa.length === 0)
      return this.renderCancelButton();

    let totalSelected = 0;
    resultadosPesquisa.forEach(item => {
      if (item.selected) {
        totalSelected++;
      }
    });

    return (
      <>
        {totalSelected > 0 ? (
          <>
            <Botao
              title="Gerar fatura e NF-e"
              secondary
              icon="icon-lx-files"
              onClick={async () => {
                showQuestion(
                  this,
                  titulo,
                  "Deseja gerar fatura e NF-e?",
                  null,
                  async resp => {
                    if (resp) {
                      let gerouFatura = false;
                      gerouFatura = await this.geraFatura();
                      if (gerouFatura) {
                        await this.geraNFe();
                        this.props.handleCloseModal(true, [
                          this.state.listaCuponsImprimir
                        ]);
                        this.doClose();
                      }
                    } else {
                      hideAlert(this);
                    }
                  }
                );
              }}
            />
            <Botao
              title="Gerar apenas fatura"
              icon="icon-lx-invoice"
              onClick={async () => {
                showQuestion(
                  this,
                  titulo,
                  "Deseja gerar apenas fatura?",
                  null,
                  async resp => {
                    if (resp) {
                      let gerouFatura = false;
                      gerouFatura = await this.geraFatura();
                      if (gerouFatura) {
                        this.props.handleCloseModal(true, [
                          this.state.listaCuponsImprimir
                        ]);
                        this.doClose();
                      }
                    } else {
                      hideAlert(this);
                    }
                  }
                );
              }}
            />
          </>
        ) : (
          <></>
        )}
        {this.renderCancelButton()}
      </>
    );
  };

  getSelectAllText = () => {
    return this.state.selectAll ? "Desmarcar todos" : "Marcar todos";
  };

  render() {
    const {
      active
      //handleCloseModal
    } = this.props;

    const { resultadosPesquisa, selectAll } = this.state;

    return (
      <div>
        <Modal
          //className={`${active ? "active" : null} modal-filter`}
          className={active ? "active modal-filter" : null}
          onClose={() => this.doClose()}
        >
          <ModalHeader
            title={titulo}
            //subtitle="lorem ipsum"
          />
          <ModalBody>
            <div className="table-ctn table-select-all-column0">
              <ReactTable
                defaultPageSize={5}
                //onClick={this.handleTableClick}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                pageText="Página"
                ofText="de"
                rowsText="registros"
                noDataText="Nenhum registro encontrado"
                data={resultadosPesquisa}
                columns={[
                  {
                    accessor: "selected",
                    width: 50,
                    sortable: false,
                    filterable: false,
                    Header: props => {
                      return (
                        <Checkitem
                          name="selectAll"
                          //onChange={this.handleInputChange}
                          title={this.getSelectAllText()}
                          checked={selectAll}
                          onChange={event => {
                            resultadosPesquisa.forEach(item => {
                              item.selected = event.target.checked;
                            });
                            this.calculateTotals(resultadosPesquisa);
                            this.setState({
                              resultadosPesquisa,
                              selectAll: event.target.checked
                            });
                          }}
                        />
                      );
                    },
                    Cell: props => {
                      return (
                        <>
                          <Checkitem
                            label=""
                            name="selected"
                            checked={props.row.selected}
                            //onChange={this.handleInputChange}
                            onChange={event => {
                              resultadosPesquisa.forEach(item => {
                                if (
                                  Number(item.codigoTitulo) ===
                                  Number(props.row.codigoTitulo)
                                ) {
                                  item.selected = event.target.checked;
                                  return;
                                }
                              });
                              props.row.selected = event.target.checked;
                              this.calculateTotals(resultadosPesquisa);
                              this.setState({ resultadosPesquisa });
                            }}
                          />
                        </>
                      );
                    }
                  },
                  {
                    accessor: "dataFechamento",
                    Header: "Vencimento"
                  },
                  {
                    accessor: "dataEmissao",
                    Header: "Data emissão",
                    Cell: props => (
                      <div>{moment(props.value).format("DD/MM/YYYY")}</div>
                    )
                  },
                  {
                    accessor: "modelo",
                    Header: "Modelo"
                  },
                  {
                    accessor: "numeroDocumento",
                    Header: "Nº documento"
                  },
                  {
                    accessor: "autorizado",
                    Header: "Autorizado"
                  }, 
                  {
                    accessor: "placa",
                    Header: "Placa"
                  }, 
                  {
                    accessor: "odometro",
                    Header: "KM"
                  }, 
                  {
                    accessor: "codigoTitulo",
                    show: false
                  },
                  {
                    accessor: "valor",
                    Header: "Valor (R$)",
                    Cell: props => (
                      <div>
                        <InputText
                          value={toFloatFormattedDisplay(props.value)}
                          disabled
                        />
                      </div>
                    )
                  }
                ]}
                /* getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    this.handleTableClick(state, rowInfo,
                      column, instance, e);
                  }
                };
              }} */
              />
            </div>
            <div className="row">
              <div className="col-12">
                <label>Total (R$):</label>
              </div>
              <div className="col-12">
                <InputText
                  value={resultadosPesquisa.total}
                  //label="Total para o período selecionado (R$):"
                  label=""
                  type="text"
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label>Total selecionados (R$):</label>
              </div>
              <div className="col-12">
                <InputText
                  value={resultadosPesquisa.totalSelecionados}
                  //label="Total para o período selecionado (R$):"
                  label=""
                  type="text"
                  disabled
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>{this.tagButtons()}</ModalFooter>
        </Modal>
        {tagAlert(this)}
      </div>
    );
  }
}
