import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';
import dateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import { maskedCnpjCpf } from '../../../../shared/utils/Utils';

export const promocaoDinamicoService = {
  find,
  GetColumns
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {

  appliedFilters.dataInicio = appliedFilters.dataInicio.split('T')[0];
  appliedFilters.dataFim = appliedFilters.dataFim.split('T')[0];

  const response = await urlLinxCore.post('/Promocao/VendasPromocoes', appliedFilters)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

const formatDocument = data => {
  return maskedCnpjCpf(data.value);
};

function GetColumns() {
  let colunas = [
    { 
      dataField: 'cnpj', 
      caption: 'CNPJ da Empresa', 
      width: '130', 
      visible: true ,
      groupIndex: 1
    },
    {
      dataField: 'razaoSocial',
      caption: 'Razao Social',
      width: '200',
      visible: false
    },
    {
      dataField: 'nomeFantasia',
      caption: 'Nome Fantasia',
      width: '200',
      visible: false
    },
    {
      dataField: 'data',
      caption: 'Emissão',
      dataType: 'date',
      width: '80'
    },
    {
      dataField: 'diaSemana',
      caption: 'Dia da Semana',
      visible: false,
      width: '120'
    },    
    {
      dataField: 'codigoVoucher',
      caption: 'Código Voucher',
      width: '150',
    },
    {
      dataField: 'codigoPromocao',
      caption: 'Cód. Promoção',
      width: '130',
      visible: false,
    },
    {
      dataField: 'descricaoPromocao',
      caption: 'Promoção',
      minWidth: '200',
    },
    {
      dataField: 'codigoProduto',
      caption: 'Cód. Produto',
      width: '120',
      visible: false
    },
    {
      dataField: 'descricaoProduto',
      caption: 'Produto',
      minWidth: '200'
    },
    {
      dataField: 'origemVenda',
      caption: 'Origem venda',
      width: '130',
      alignment: 'left',
    },
    {
      dataField: 'precoBruto',
      caption: 'Bruto R$',
      format: { type: 'currency', precision: 2 },
      width: '100'
    },
    {
      dataField: 'descontoPromocao',
      caption: 'Desconto R$',
      format: { type: 'currency', precision: 2 },
      width: '100'
    },
    {
      dataField: 'acrescimoPromocao',
      caption: 'Acréscimo R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
      visible: false
    },
    {
      dataField: 'precoLiquido',
      caption: 'Líquido R$',
      format: { type: 'currency', precision: 2 },
      width: '100',
    },
    {
      dataField: 'quantidadePromocao',
      caption: 'Quantidade',
      width: '80',
      visible: false
    },
    {
      dataField: 'numeroDocumento',
      caption: 'Nº Documento',
      width: '100',
      alignment: 'left',
      visible: false
    } 
  ];
 
  return colunas;
}

export default urlLinxCore;
