import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import moment from 'moment';

export const notasFiscaisService = {
    getFiltros,
    getReports,
    getReportColumns,
    find,
    getTipoOperacoes,
    getOperacoes,
    getSituacoesDocFiscal,
    getSituacoesManifestacao,
    getPessoas
};

const urlLinxCore = axios.create({
    baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

//// chamadas API
async function find(filters)  {
    const response = await urlLinxCore.post('/DocumentoFiscal/ListarNotasFiscais', {query: filters})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    })
    return response;
}

async function getTipoOperacoes() {
    return [
        {'codigo': 1, 'descricao': 'Saída'},
        {'codigo': 2, 'descricao': 'Entrada'}
    ];
}

async function getOperacoes() {
    const response = await urlLinxCore.get('/Operacao/ListarOperacoesNotaFiscal');
    return response.data.data;
}

async function getSituacoesDocFiscal() {
    const response = await urlLinxCore.get('/StatusNotaFiscal/ListarSefaz');
    return response.data.data;
}

async function getSituacoesManifestacao() {
    return [
        {'descricao': 'Confirmação da operação'},
        {'descricao': 'Desconhecimento de operação'},
        {'descricao': 'Operação não realizada'},
        {'descricao': 'Ciência da emissão'},
        {'descricao': 'Sem manifestação'}
    ];
}

async function getPessoas() {
    const response = await urlLinxCore.get('/Pessoa/listar');
    return response.data.data;
}

//// filtros 
function getFiltros() {
    return {
        dataInicio: moment().startOf('month').format('YYYY-MM-DD') + 'T00:00:00',
        dataFim: moment().endOf('month').format('YYYY-MM-DD') + 'T23:59:59',
        tipoOperacoes: [],
        operacoes: [],
        situacoesDocFiscal: [],
        situacoesManifestacao: [],
        pessoas: [],
        numero: '',
        chaveAcesso: ''
    }
}

//// colunas relatório
function getReportColumns() {
    return [
        { dataField: 'dataEmissao', caption: 'Data emissão', width: '100', dataType: 'date', sortIndex: 1, sortOrder: 'asc' },
        { dataField: 'tipoOperacao', caption: 'Tipo', width: '70', sortIndex: 0, sortOrder: 'asc' },
        { dataField: 'operacaoFiscal', caption: 'Operação', width: '190' },
        { dataField: 'pessoa', caption: 'Pessoa', width: '190' },
        { dataField: 'numero', caption: 'Número', width: '80', sortIndex: 2, sortOrder: 'asc' },
        { dataField: 'serie', caption: 'Série', width: '50' },
        { dataField: 'valorDocumento', caption: 'Valor documento', width: '120', format: { type: 'currency', precision: 2 } },
        { dataField: 'situacaoDocFiscal', caption: 'Situação', width: '100' },
        { dataField: 'modelo', caption: 'Modelo', width: '200' },
        { dataField: 'situacaoManifestacao', caption: 'Situação manifestação', width: '200' },
        { dataField: 'chaveAcesso', caption: 'Chave de acesso', width: '317' },
        { dataField: 'dataMovimento', caption: 'Data movimento', width: '110', dataType: 'date' },
        { dataField: 'cnpjCpf', caption: 'CNPJ/CPF', width: '125' },
        { dataField: 'subserie', caption: 'Subsérie', width: '50' },
        { dataField: 'observacao', caption: 'Observação', width: '225' },
        { dataField: 'valorItens', caption: 'Valor itens', width: '100', format: { type: 'currency', precision: 2 } },
        { dataField: 'desconto', caption: 'Desconto', width: '100', format: { type: 'currency', precision: 2 } },
        { dataField: 'acrescimo', caption: 'Acréscimo', width: '100', format: { type: 'currency', precision: 2 } },
        { dataField: 'valorICMS', caption: 'Valor ICMS', width: '100', format: { type: 'currency', precision: 2 } },
        { dataField: 'valorICMSST', caption: 'Valor ICMS ST', width: '100', format: { type: 'currency', precision: 2 } },
        { dataField: 'valorPIS', caption: 'Valor PIS', width: '100', format: { type: 'currency', precision: 2 } },
        { dataField: 'valorCOFINS', caption: 'Valor COFINS', width: '100', format: { type: 'currency', precision: 2 } }
    ];
}

//// tipos de relatórios
function getReports() {
    return [
        {
            id: 0,
            title: 'PERSONALIZAR RELATÓRIO',
            icon: 'icon-lx-search',
            type: 2
        },
        {
            id: 1,
            title: 'NOTAS FISCAIS DE SAÍDA',
            icon: 'icon-lx-invoice',
            type: 0,
            preReports: [
                {
                    id:11,
                    title: 'HOJE',
                    icon: 'icon-lx-clock',
                    type: 1,
                    filters: {
                        dataInicio: moment().utc().startOf('day').format(),
                        dataFim: moment().utc().endOf('day').format(),
                        tipoOperacoes: [1]
                    } 
                },
                {
                    id:12,
                    title: 'DIAS ANTERIORES',
                    text: '7',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(7, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        tipoOperacoes: [1]
                    }
                },
                {
                    id:13,
                    title: 'DIAS ANTERIORES',
                    text: '15',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(15, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        tipoOperacoes: [1]
                    }
                },
                {
                    id:14,
                    title: 'DIAS ANTERIORES',
                    text: '30',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(30, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        tipoOperacoes: [1]
                    }
                }
            ]
        },
        {
            id: 2,
            title: 'NOTAS FISCAIS DE ENTRADA',
            icon: 'icon-lx-receipt',
            type: 0,
            preReports: [
                {
                    id:21,
                    title: 'HOJE',
                    icon: 'icon-lx-clock',
                    type: 1,
                    filters: {
                        dataInicio: moment().utc().startOf('day').format(),
                        dataFim: moment().utc().endOf('day').format(),
                        tipoOperacoes: [2]
                    } 
                },
                {
                    id:22,
                    title: 'DIAS ANTERIORES',
                    text: '7',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(7, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        tipoOperacoes: [2]
                    }
                },
                {
                    id:23,
                    title: 'DIAS ANTERIORES',
                    text: '15',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(15, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        tipoOperacoes: [2]
                    }
                },
                {
                    id:24,
                    title: 'DIAS ANTERIORES',
                    text: '30',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(30, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        tipoOperacoes: [2]
                    }
                }
            ]
        },
        {
            id: 3,
            title: 'NOTAS DE AQUISIÇÕES DE SERVIÇOS',
            icon: 'icon-lx-archives',
            type: 0,
            preReports: [
                {
                    id:31,
                    title: 'HOJE',
                    icon: 'icon-lx-clock',
                    type: 1,
                    filters: {
                        dataInicio: moment().utc().startOf('day').format(),
                        dataFim: moment().utc().endOf('day').format(),
                        operacoes: [17]
                    } 
                },
                {
                    id:32,
                    title: 'DIAS ANTERIORES',
                    text: '7',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(7, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        operacoes: [17]
                    } 
                },
                {
                    id:33,
                    title: 'DIAS ANTERIORES',
                    text: '15',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(15, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        operacoes: [17]
                    } 
                },
                {
                    id:34,
                    title: 'DIAS ANTERIORES',
                    text: '30',
                    type: 0,
                    filters: {
                        dataInicio: moment().utc().startOf('day').subtract(30, 'days').format(),
                        dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
                        operacoes: [17]
                    } 
                }
            ]
        }
    ]
}
