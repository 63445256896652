import React, { useState } from "react";
import notify from "devextreme/ui/notify";
import { LoadIndicator } from "devextreme-react/load-indicator";

import { CustomButton } from "../CustomButton/CustomButton";
import { TitulosFinanceiros } from "../TitulosFinanceiros/TitulosFinanceiros";

import { getDadosLogin } from "../../../../../shared/utils/Utils";
import { CobrancasService } from "../../components/Cobrancas/Cobrancas.service";

import mockData from "./mockData.json";

export const VincularTitulos = ({
  message = "Deseja vincular o item a um título do financeiro?",
  isShow,
  onFinish,
  typeTitle,
}) => {
  const [allInvoice, setAllInvoice] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const getAllInvoice = async () => {
    const dadosLogin = getDadosLogin();

    const response = await CobrancasService.getInvoices(dadosLogin.codigoEstabelecimento, typeTitle);

    if (response.notifications && Array.isArray(response.notifications) && response.notifications.length > 0) {
      notify(response.notifications[0].message, "error", 5000);
    }else{
      if (response && response.result) {
        setAllInvoice(response.result);
      } else {
        notify(response.message, "error", 5000);
        setError(response.message);
        setAllInvoice(mockData);
      }
    }
  }

  const showInvoice = async () => {
    setIsLoading(true);
    await getAllInvoice();
    setIsLoading(false);
  }

  return (
    <>
      {isShow &&
        <>
          <div className="d-flex flex-column justify-content-start">
            <h3>{message}</h3>
            <div className="d-flex justify-content-start" style={{ gap: 16 }}>
              <CustomButton
                className="rounded"
                variant="secondary"
                icon="clear"
                onClick={onFinish}
                disabled={isLoading}
              >
                Não vincular
              </CustomButton>
              <CustomButton
                className="rounded"
                icon="chevronnext"
                iconPosition="right"
                duplicateIcon={true}
                disabled={isLoading}
                onClick={showInvoice}
              >
                Vincular
              </CustomButton>
            </div>
          </div>
          <TitulosFinanceiros
            isLoading={isLoading}
            allInvoice={allInvoice}
            error={error}
            onFinish={onFinish}
          />
        </>
      }
    </>
  )
}
