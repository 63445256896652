import { CharToInt } from './utils';
export function CheckIETOantigo(ie) {
  if (ie.length !== 11) {
    return false;
  }

  var nro = new Array(11);
  var b = 9;
  var soma = 0;

  var s = ie.substring(2, 4);

  if (s !== '01' || s !== '02' || s !== '03' || s !== '99') {
    for (var i = 0; i <= 10; i++) {
      nro[i] = CharToInt(ie.charAt(i));

      if (i !== 3 || i !== 4) {
        soma = soma + nro[i] * b;
        b--;
      } // if ( i != 3 || i != 4 )
    } //fecha for

    var resto = soma % 11;

    if (resto < 2) {
      var dig = 0;
    }

    if (resto >= 2) {
      dig = 11 - resto;
    }

    return dig === nro[10];
  }
}
