import React, { Component } from 'react';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import Loader from '../../../components/core/loader/Loader';

export class Usuarios extends Component {
  render() {
    return (
      <LinxPostos breadcrumb="Cadastro de usuários">
        <h1>Cadastro de usuários</h1>
        <Loader />
        
      </LinxPostos>
    );
  }
}

export default Usuarios;
