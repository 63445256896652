import { LoadPanel } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { locale } from 'devextreme/localization';
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { HorusBiService } from '../../../services/horus-bi/HorusBi.service';
import { FuncionarioService } from '../../../services/funcionarios/Funcionario.service';

export function HorusBi() {
  locale('pt-BR');
  const history = useHistory();
  // const PATH_MENU = menus.COD_40000;
  const [searching, setSearching] = useState(true);
  const [horusUrl, setHorusUrl] = useState(null);
  const [popupBloqueado, setPopupBloqueado] = useState(false);
  const [searchingMessage, setSearchingMessage] = useState('Carregando Pedidos...');

  useEffect(() => {
    setSearching(true);
    setSearchingMessage('carregando dados do relatório...');
    const doEffect = async () => {
      try {
        var permissao = await FuncionarioService.getInstance().recuperarAcessosPorMenuId(40001);
        if (!(permissao || []).find(x => x.codigoOperacao === 50)) {
          setSearching(false);
          history.push('/dashboard/vazio');
          return;
        }
        var url = await HorusBiService.getInstance().token();
        setHorusUrl(url);
        setSearching(false);
        if (!url) {
          history.push('/dashboard/gerencial');
          return;
        }
      } catch (error) {
        setSearching(false);
        history.push('/dashboard/vazio');
      }
    };
    doEffect().then();
  }, [history]);
  const styleIFrame = {
    display: 'block',
    width: '100%',
    height: 'calc(100vh - 90px)',
    border: 'none',
  };

  const onShowPopUp = () => {
    setPopupBloqueado(true);
  };

  const onHidePopUp = () => {
    setPopupBloqueado(false);
  };


  return (
    <>
      <LinxPostos breadcrumb={'Dashboard'} id="page" onShowPopUp={onShowPopUp} onHidePopUp={onHidePopUp} dashboard={true}>
        <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={searchingMessage} showPane={true} />
        {!horusUrl && <p>Carregando...</p>}
        {horusUrl && <iframe style={styleIFrame} title="Horus BI" src={horusUrl} />}
      </LinxPostos>
    </>
  );
}

export default withRouter(HorusBi);
