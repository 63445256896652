import axios from "axios";
import { getCnpj } from "../../../shared/utils/Utils";

export function getTiposLancamentos() {
  return axios.get(`LxApi/v1/Financeiro/FluxoCaixa/MontaComboTipoLancamento/`);
}

export function getCashflow(dataInicial, dataFinal, tipoLancamento) {
  return axios.post(`LxApi/v1/Financeiro/FluxoCaixa/Listar/`, {
    cnpjEstabelecimento: getCnpj(),
    dataInicial,
    dataFinal,
    tipoLancamento
  });
}
