import axios from 'axios';
import {
  getToken
} from '../../../authentication/Auth';

import {
  BASE_URL_GATEWAY
} from '../../../shared/constants/GatewayUrlConstant';

export const fortesFiscalService = {
  getTipoArquivo,
  gerar,
  getUltimos
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

const urlLinxFiles = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/files/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

urlLinxFiles.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

async function getTipoArquivo() {
  const response = await urlLinxFiles.get(`/tipoarquivo?tipo=${0}`)
    .then(response => {
      return response.data.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function getUltimos() {
    const response = await urlLinxFiles.get(`/fortes`)
      .then(response => {
        return response.data
      })
      .catch(error => {
        return error.response
      })
    return response;
  }

async function gerar(entity) {
  const response = await urlLinxFiles.post('/fortes', entity)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}
