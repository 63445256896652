export class NovaOSItemValidateService {
  execute(values) {
    if (!values) {
      return;
    }
    const errors = { isValid: true };
    if (!values.produto) {
      errors.produto = 'Por favor, informe um produto';
      errors.isValid = false;
    }
    if (!values.tipoPedido) {
      errors.tipoPedido = 'Selecione o tipo do pedido';
      errors.isValid = false;
    }
    if (values.precoTotal <= 0 && values.tipoPedido.id !== 7) {
      errors.precoTotal = 'O Total deve ser maior que zero';
      errors.isValid = false;
    }
    return errors;
  }
}
