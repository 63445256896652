import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { pillMain, pill } from '../InfoPill/InfoPill';

export const LinxDatePill = props => {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [format, setFormat] = useState(
    props.format ? props.format : 'DD/MM/YYYY'
  );
  const [showClock, setShowClock] = useState(props.showClock);

  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
    setFormat(props.format ? props.format : 'DD/MM/YYYY');
    setShowClock(props.showClock);
  }, [props]);

  return (
    <div style={pillMain}>
      <div style={pill}>
        <span style={pill.span}>
          <i
            className={
              showClock ? 'icon icon-lx-clock' : 'icon icon-lx-calendar'
            }
            style={pill.i}
          ></i>
          {moment(startDate).format(format)}{' '}
          {endDate
            ? moment(startDate).isSame(moment(endDate))
              ? ''
              : `a ${moment(endDate).format(format)}`
            : ''}
        </span>
      </div>
    </div>
  );
};
