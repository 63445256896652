import { CharToInt } from './utils';
export function CheckIEDF(ie) {
  if (ie.length !== 13) return false;
  var nro = new Array(13);
  for (var i = 0; i <= 12; i++) nro[i] = CharToInt(ie.charAt(i));
  var b = 4;
  var soma = 0;
  for (i = 0; i <= 10; i++) {
    soma += nro[i] * b;
    b--;
    if (b === 1) b = 9;
  }
  var dig = 11 - (soma % 11);
  if (dig >= 10) dig = 0;
  var resultado = dig === nro[11];
  if (!resultado) return false;
  b = 5;
  soma = 0;
  for (i = 0; i <= 11; i++) {
    soma += nro[i] * b;
    b--;
    if (b === 1) b = 9;
  }
  dig = 11 - (soma % 11);
  if (dig >= 10) dig = 0;
  return dig === nro[12];
}
