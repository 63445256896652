import React, { useState } from 'react';
import moment from 'moment';
import BotaoCarregamento from '../../../../components/botao/BotaoCarregamento/BotaoCarregamento';
import DateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import notify from 'devextreme/ui/notify';
import config from 'devextreme/core/config';

import styles from './RaioXDashboard.module.scss';
import { locale } from 'devextreme/localization';


export default function RaioXDashboardFilter({onSearch}){
  locale(navigator.language);
  moment.locale('pt-br');
  config({
    defaultCurrency: 'BRL'
  });

  const [dataInicio, setDataInicio] = useState(moment(new Date().toISOString()).subtract(6, "days").format('YYYY-MM-DD'));
  const [dataFim, setDataFim] = useState(moment(new Date().toISOString()).format('YYYY-MM-DD'));
  const [filters, setFilters] = useState({ dataInicio: dataInicio, dataFim: dataFim});

  const onValueChanged = event => {
    if(!isValidPeriodo(event.element.id, event.value)) {
      notify(
        "Período inválido! A data de início deve ser menor que a data de fim.",
        'warning',
        2000,
        'top'
      );
      return;
    }
    console.log("IS VALID");
    if (!moment(event.value).isValid()) {
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim'
              ? moment(event.value).format('YYYY-MM-DD')
              : moment(event.value).format('YYYY-MM-DD')
        };
      });
    }
  };

  function isValidPeriodo(nomeCampo, data) {
    if(nomeCampo === 'dataInicio'){
      return moment(data).isBefore(moment(filters.dataFim).format('YYYY-MM-DDT23:59:59'))
    } else {
      return moment(filters.dataInicio).isBefore(moment(data).format('YYYY-MM-DDT23:59:59'))
    }
  }

  return(
    <div className={`shadow-sm p-1 bg-white ${styles.periodoContainer}`}>
        <div className={styles.periodoDateTimeBox}>
          <span>De</span>
          <DateTimeBox
            type="date"
            id="dataInicio"
            date={{...filters.dataInicio}}
            disabled={false}
            value={filters.dataInicio}
            onValueChanged={onValueChanged}
          />
        </div>
        <div className={styles.periodoDateTimeBox}>
          <span>Até</span>
          <div className={styles.periodoDateTimeBoxButton}>
            <DateTimeBox
              type="date"
              id="dataFim"
              date={{...filters.dataFim}}
              disabled={false}
              value={filters.dataFim}
              onValueChanged={onValueChanged}
            />
            <BotaoCarregamento icon="dx-icon dx-icon-search" click={() => onSearch(filters)} loading={false}/>
          </div>
        </div>
    </div>
  )
}
