import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Popup } from 'devextreme-react';
import Switch from 'devextreme-react/switch';
import { DataGrid, Column, Pager, Paging, Sorting, SearchPanel, FilterRow, ColumnChooser } from 'devextreme-react/data-grid';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';

import { jsPDF } from 'jspdf';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';

import { CustomButton } from '../../SharedComponents';
import { RealizarPagamento } from '../Pagamentos/RealizarPagamento/RealizarPagamento';

import { DDAService } from './DDA.service';
import { getDadosLogin } from '../../../../../shared/utils/Utils';

export function DDA() {
  const [isShow, setIsShow] = useState(false);
  const [value, setValue] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    const fetchSubscription = async () => {
      setIsLoading(true);
      const dadosLogin = getDadosLogin();
      try {
        const response = await DDAService.consultSubscription(dadosLogin.codigoEstabelecimento);
        if (response.result.status === 'registered' || response.result.status === 'registration_requested') {
          setData(response.result);
          setIsSubscribed(true);
          setValue(true);
        } else {
          setIsSubscribed(false);
          setValue(false);
        }
      } catch (error) {
        console.error('Erro ao consultar inscrição DDA', error);
      } finally {
        setIsLoading(false);
        setInitialLoad(false);
      }
    };

    fetchSubscription();
  }, []);

  const openModal = () => {
    setIsShow(!isShow);
  };

  function formatarDataParaDDMMYYYY(dataISO) {
    if (!dataISO) return '';
    const data = new Date(dataISO);

    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
  }

  const handleSubscriptionChange = async (newValue) => {
    const dadosLogin = getDadosLogin();
    try {
      if (newValue) {
        // Subscribe
        const response = await DDAService.subscribeDDA(dadosLogin.codigoEstabelecimento);
        const subscriptionResponse = await DDAService.consultSubscription(dadosLogin.codigoEstabelecimento);
        setData(subscriptionResponse.result);
        setIsSubscribed(true);
        setValue(true);

      } else {
        // Unsubscribe
        await DDAService.cancelDDA(dadosLogin.codigoEstabelecimento);
        setIsSubscribed(false);
        setValue(false);
        setData(null);
      }
    } catch (error) {
      console.error('Erro ao mudar inscrição DDA', error);
    }
  };

  const onSwitchChange = (e) => {
    if (!initialLoad) {
      setValue(e.value);
      handleSubscriptionChange(e.value);
    }
  };

  const exportToPDF = e => {
    const doc = new jsPDF();

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(18);

    doc.text('Pagamentos realizados', 15, 10);

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
      autoTableOptions: {
        styles: { fillColor: [255, 255, 255] },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        headerStyles: { textColor: [0, 0, 0], fontStyle: 'normal' },
        bodyStyles: { textColor: [0, 0, 0] },
        margin: { top: 20, right: 10, bottom: 10, left: 10 },
      },
    }).then(() => {
      doc.save('pagamentos_realizados.pdf');
    });

    e.cancel = true;
  };

  const exportToExcel = e => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Planilha1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'transferencias_realizadas.xlsx');
      });
    });
  };

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'exportpdf',
          onClick: () => exportToPDF(e),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'xlsxfile',
          onClick: () => exportToExcel(e),
        },
      },
    );
  };

  const pay = () => {
    setIsPay(!isPay);
  };

  const renderActionButtons = () => {
    return (
      <div>
        <CustomButton className="rounded" variant="primary" icon="money" title="Realizar pagamento" onClick={pay}>
          Pagar
        </CustomButton>
      </div>
    );
  };

  return (
    <Col>
      <Container fluid>
        <Row as="header">
          <h2>Boletos DDA (Buscador de boletos)</h2>
        </Row>
        <Row>
          {!value && !isLoading && !isSubscribed ? (
            <>
              <p>
                Com a ativação do recurso de Débito Direto Autorizado (DDA), os boletos emitidos contra o seu CNPJ serão automaticamente exibidos na operação de Pagamentos, eliminando a
                necessidade de inserir o código de barras manualmente para efetuar o pagamento.
              </p>
              <p>
                Para começar a usar essa facilidade, ative-a{' '}
                <a href="#" onClick={openModal}>
                  <strong>Clicando Aqui</strong>
                </a>
                .
              </p>
            </>
          ) : data && (
            <>
              <p className="w-100">
                O seu Débito Direto Autorizado (DDA) já está ativo e aprovado. Assim, todos os boletos emitidos contra o seu CNPJ serão automaticamente exibidos na operação de Pagamentos.
              </p>
              <p className="w-100">
                Data da solicitação: <strong>{formatarDataParaDDMMYYYY(data.registration_requested_at)}</strong>
                <br />
                Data da aprovação: <strong>{formatarDataParaDDMMYYYY(data.registered_at)}</strong>
                <br />
                Última sincronização: <strong>{formatarDataParaDDMMYYYY(data.registration_requested_at)}</strong>
              </p>
              <p className="w-100">
                Se desejar desativar essa funcionalidade, por favor, desative-a,{' '}
                <a href="#" onClick={openModal}>
                  <strong>Clicando Aqui</strong>
                </a>
              </p>
            </>
          )}
        </Row>
        <Row className='d-flex justify-content-center align-items-center h-100'>
          {!isLoading ? (
            <>
              {value && listingData.length > 0 && (
                <div className="d-flex mh-100">
                  <DataGrid
                    key="id"
                    dataSource={listingData}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    width="100%"
                    height="100%"
                    onToolbarPreparing={onToolbarPreparing}
                    onCellPrepared={e => {
                      if (e.rowType === 'filter') {
                        e.cellElement.style.backgroundColor = 'white';
                      }
                    }}
                  >
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <FilterRow visible={true} applyFilter="auto" />
                    <Column dataField="vencimento" caption="Vencimento" dataType="date" format="dd/MM/yyyy" />
                    <Column dataField="fornecedor" caption="Fornecedor" />
                    <Column dataField="valor" caption="Valor do boleto" format="R$ #,##0.00" />
                    <Column dataField="barras" caption="Código de barras" />
                    <Column dataField="status" caption="Status" />
                    <Column cellRender={renderActionButtons} caption="Opções" width="auto" />
                    <ColumnChooser enabled={true} mode={'select'} allowSearch={true} title="Escolher colunas" />
                    <Paging enabled={true} defaultPageSize={10} />
                    <Pager visible={true} displayMode="full" />
                    <Sorting mode="multiple" />
                  </DataGrid>
                </div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100">
              <LoadIndicator id="large-indicator" height={60} width={60} />
            </div>
          )}
        </Row>
        <Popup
          visible={isShow}
          dragEnabled={false}
          showTitle={true}
          title="Configurar DDA"
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={openModal}
          width="50%"
          height="auto"
          minHeight="471px"
        >
          {!value && (
            <p>
              Ao ativar o DDA (Buscador de Boletos), os boletos emitidos contra o seu CNPJ serão automaticamente exibidos na operação de Pagamentos, sujeitos a um processo de aprovação que pode durar
              alguns dias úteis. É essencial retornar periodicamente a esta página para acompanhar o status da ativação e acessar os boletos pendentes de pagamento.
            </p>
          )}
          <p>
            <strong>Status do DDA: </strong>
          </p>
          <Switch
            style={{
              width: '150px',
              height: '40px',
              fontSize: '16px',
            }}
            value={value}
            onValueChanged={onSwitchChange}
            switchedOnText="Ativo"
            switchedOffText="Desativado"
          />
        </Popup>
        <Popup
          visible={isPay}
          dragEnabled={false}
          showTitle={true}
          title="Realizar pagamento"
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={pay}
          width="80%"
          height="auto"
          minHeight="471px"
        >
          <RealizarPagamento isShow={isPay} />
        </Popup>
      </Container>
    </Col>
  );
}
