import axios from "axios";
import { TOKEN_PASS_AXIOS } from "../shared/constants/Const";
import { getUrl } from "../shared/utils/Utils";
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getBancos() {
  return axios.post(`LxApi/v1/Banco/Listar/`, {
    query: {}
  });
}

export function getBancosByQuery(codigo, nome, tipo) {
  return axios.post(`LxApi/v1/Banco/Listar/`, {
    query: {
      codigo,
      nome,
      tipo: tipo ? tipo : 1
    }
  });
}

export function getBanco(codigoBanco) {
  return axios.get(`LxApi/v1/Banco/Selecionar/`, {
    params: { codigoBanco }
  });
}

export function getTiposBancos() {
  return axios.get(`LxApi/v1/Banco/MontarComboTipoBanco/`);
}

export function incluiBanco(codigo, nome, tipo, restrito, inativo) {
  return axios.post(`LxApi/v1/Banco/Incluir`, {
    codigo,
    nome,
    tipo,
    restrito,
    inativo
  });
}

export function alteraBanco(
  codigo,
  nome,
  tipo,
  restrito,
  inativo,
  rowVersion,
  codigoAlterado,
  novoCodigo
) {
  return axios.put(`LxApi/v1/Banco/Alterar`, {
    codigo,
    nome,
    tipo,
    restrito,
    inativo,
    rowVersion,
    codigoAlterado,
    novoCodigo
  });
}

export function excluiBanco(codigoBanco) {
  return axios.delete(`LxApi/v1/Banco/Excluir`, {
    params: { codigoBanco }
  });
}
