import React from 'react';
import styles from './EmpresaOptions.module.scss';

const empresaOptions = props => {

  return (
    <div className={styles.empresaOptions} onClick={props.selecionaEmpresa}>
      <li className={`${styles.navItem} d-none d-sm-block`}>
        <span className={`${styles.navLink} active pt-0 pb-0 text-uppercase`}>
          <i className={`${styles.storeIcon} icon icon-lx-store`}></i> {props.nome}
        </span>
      </li>
    </div>
  );
};

export default empresaOptions;
