import axios from 'axios';

import { TOKEN_PASS_AXIOS } from '../shared/constants/Const';
import { getUrl } from '../shared/utils/Utils';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { FileError } from '../../app/components/errors/Errors';
import { validaImportacaoProdutos } from '../../app/pages/cadastro/produtos/Produtos.service';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function getCombustivel(cnpjEstabelecimento) {
  return axios.get(`LxApi/v1/Tanque/MontarComboProdutosCombustivel`, {
    params: { cnpjEstabelecimento },
  });
}

export function getTipoAlmoxarifadoComb(cnpjEstabelecimento) {
  return axios.get(`LxApi/v1/Tanque/MontarComboTipoAlmoxarifadoCombustivel`, {
    params: { cnpjEstabelecimento },
  });
}

//#region Exportação de produtos
export async function exportExcelModelo(produtos, listas, setores, unidadeMedida, simplesNacional) {
  console.log(listas);
  console.log(simplesNacional);

  //#region LISTAS
  const columnsExport = [
    { header: 'Código', key: 'codigo', width: 20 },
    { header: 'Código Rápido', key: 'codigoRapido', width: 20 },
    { header: 'Descrição', key: 'descricao', width: 40 },
    { header: 'Descrição Resumida', key: 'descricaoResumida', width: 30 },
    { header: 'Marca', key: 'marca', width: 30 },
    { header: 'Tipo', key: 'tipo', width: 30 },
    { header: 'SubTipo', key: 'subTipo', width: 20 },
    { header: 'Grupo', key: 'grupo', width: 20 },
    { header: 'SubGrupo', key: 'subGrupo', width: 20 },
    { header: 'Artigo', key: 'artigo', width: 20 },
    { header: 'Setor', key: 'setor', width: 20 },
    { header: 'Código NCM', key: 'codigoNcm', width: 20 },
    { header: 'Código CEST', key: 'codigoCest', width: 20 },
    { header: 'Unidade de Compra', key: 'unidadeCompraDescricao', width: 20 },
    { header: 'Unidade de Venda', key: 'unidadeVendaDescricao', width: 20 },
    { header: 'Permite Venda', key: 'permiteVendaDescricao', width: 20 },
    { header: 'Controla Estoque', key: 'controlaEstoqueDescricao', width: 20 },
    { header: 'Usa Balança', key: 'usaBalancaDescricao', width: 20 },
    { header: 'Fator de Conversão', key: 'fatorConversao', width: 20 },
    { header: 'Preço de Venda R$', key: 'precoVenda', width: 20 },
    { header: 'Forma de Reajuste', key: 'formaReajusteDescricao', width: 20 },
    { header: 'Percentual Reajuste', key: 'percentualReajuste', width: 20 },
    { header: 'Situação Tributária Venda - ICMS', key: 'cstIcmsVenda', width: 20 },
    { header: 'Alíquota Tributação - ICMS', key: 'aliquotaTributacaoIcms', width: 20 },
    { header: 'Alíquota Efetiva %', key: 'aliquotaEfeitvaIcms', width: 20 },
  ];

  const excelLists = {
    tipo: [
      '"' +
        listas.tipo
          .map(t => t.label)
          .join(',')
          .toString() +
        '"',
    ],
    subtipo: [
      '"' +
        listas.subTipo
          .map(t => t.label)
          .join(',')
          .toString() +
        '"',
    ],
    setor: [
      '"' +
        setores
          .map(t => t.descricao)
          .join(',')
          .toString() +
        '"',
    ],
    simNao: [
      '"' +
        listas.boolean
          .map(t => t.label)
          .join(',')
          .toString() +
        '"',
    ],
    unidadeCompra: [
      '"' +
        unidadeMedida
          .map(t => t.descricao)
          .join(',')
          .toString() +
        '"',
    ],
    unidadeVenda: [
      '"' +
        unidadeMedida
          .map(t => t.descricao)
          .join(',')
          .toString() +
        '"',
    ],
    formaReajuste: [
      '"' +
        listas.formaReajuste
          .map(t => t.label)
          .join(',')
          .toString() +
        '"',
    ],
  };

  const excelDataValidation = [
    { column: 'F2:F9999', formulae: excelLists.tipo },
    { column: 'G2:G9999', formulae: excelLists.subtipo },
    { column: 'K2:K9999', formulae: excelLists.setor },
    { column: 'N2:N9999', formulae: excelLists.unidadeCompra },
    { column: 'O2:O9999', formulae: excelLists.unidadeVenda },
    { column: 'P2:P9999', formulae: excelLists.simNao },
    { column: 'Q2:Q9999', formulae: excelLists.simNao },
    { column: 'O2:O9999', formulae: excelLists.unidadeVenda },
    { column: 'P2:P9999', formulae: excelLists.simNao },
    { column: 'Q2:Q9999', formulae: excelLists.simNao },
    { column: 'R2:R9999', formulae: excelLists.simNao },
    { column: 'U2:U9999', formulae: excelLists.formaReajuste },
  ];
  //#endregion

  console.log(excelDataValidation);

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Lista de Produtos');

  worksheet.columns = columnsExport;
  worksheet.addRows(produtos);

  excelDataValidation.map(data => {
    worksheet.dataValidations.add(data.column, {
      type: 'list',
      allowBlank: true,
      formulae: data.formulae,
      showErrorMessage: true,
      errorStyle: 'error',
    });
    return null;
  });

  worksheet.getRow(1).font = {
    name: 'Calibri',
    family: 2,
    size: 12,
    bold: true,
    color: { argb: 'FFFFFF' },
  };

  worksheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '6935A1' },
  };

  // save under export.xlsx
  workbook.xlsx.writeBuffer().then(buffer => {
    saveAs(
      new Blob([buffer], {
        type: 'application/octet-stream',
      }),
      'importacao_produtos.xlsx',
    );
  });
}
//#endregion

//#region IMPORTAÇÃO DE PRODUTOS

export async function importCsv(arquivoCSV) {
  if (arquivoCSV && arquivoCSV.length > 0 && arquivoCSV[0].size > 0) {
    try {
      const dados = await doImport(arquivoCSV[0]);
      return dados;
    } catch (e) {
      if (e.name && e.name === 'FileError') return e.message;
      else {
        return e.response.data.message;
      }
    }
  } else {
    return 'Escolha um arquivo csv válido para importar';
  }
}

const doImport = async arquivoCSV => {
  if (!arquivoCSV) throw new FileError('Falha no arquivo .csv. \n Tente novamente.');

  const { data: dataProdutos } = await validaImportacaoProdutos(arquivoCSV);

  const dados = dataProdutos;

  return dados;
};
//#endregion
