import React from 'react';
import Chart, {
  CommonSeriesSettings,
  Series,
  Legend,
  Tooltip,
  ValueAxis,
  Animation,
  Title,
  Font,
  CommonAxisSettings,
  Label
} from 'devextreme-react/chart';
import RaioXDashboardLoading from './RaioXDashboardLoading';
import { isObjectEmpty } from '../../../../shared/utils/Utils';
import styles from './RaioXDashboard.module.scss';


export default function RaioXDashboardChart({title, dados, coresRef, isLoading}) {

  const tooltipTemplate = arg => {
    return (
      <div className={styles.chartTooltip}>
        <h4>{arg.seriesName} | {arg.argument}</h4>
        <span><b>Percentual:</b> {arg.percentText}</span>
        <br />
        <span><b>Valor:</b> R$ {arg.valueText}</span>
      </div>
    );
  }

  return(
    <>
    { isLoading
      ? (
        <div className={styles.chartLoadingContainer}>
          <RaioXDashboardLoading />
        </div>
      )
      : (
        <div className={styles.chartInfoContainer}>
          <Chart
            id="chart"
            dataSource={dados}
            className="bg-white shadow-sm p-3"
          >
            <Title text={title}>
              <Font family="Dosis, sans-serif" size="1.75rem"/>
            </Title>
            <CommonSeriesSettings
              argumentField="data"
              type="stackedbar"
            />
            { isObjectEmpty(coresRef)
              ? <Series axis="valor"/>
              : coresRef.map(({ nome, nomeFormatado, cor }) => {
                  return (
                  <Series 
                    key={nomeFormatado} 
                    valueField={nomeFormatado} 
                    name={nome}
                    color={cor}
                    axis="valor"
                  />
                );
              })
            }
            <ValueAxis
              name="valor"
              position="left"
              tickInterval={250}
              allowDecimals={true}
            />
            <Legend
              verticalAlignment="top"
              horizontalAlignment="center"
              itemTextPosition="right"
            />
            <Tooltip
              enabled={true}
              contentRender={tooltipTemplate}
              format={{ type: 'fixedPoint', precision: 3 }}
            />
            <CommonAxisSettings>
              <Label>
                <Font family="Roboto, sans-serif" size="0.75rem"/>
              </Label>
            </CommonAxisSettings>
            <Animation enabled={true} />
          </Chart>
        </div>
      )}
    </>
  )
}
