import DataGrid, { Scrolling, Paging, Pager, Column } from 'devextreme-react/data-grid';
import React from 'react';

import styles from './SetupFacilConfirmacao.module.scss';

export default function SetupFacilConfirmacaoGridProdutos({ isImportacao, dados, gridRef, unidadesMedida, gridListas }) {
  const title = isImportacao ? 'Produtos Recusados' : 'Produtos Prontos';

  const subtitle = isImportacao
    ? 'Estes produtos não serão importados, pois são exatamente iguais aos já cadastrados no sistema'
    : 'Estes são produtos novos e estão prontos para a importação';
  
  const headerCellTemplate = data => {
    return <div className={styles.gridColumnHeader}>{data.column.caption}</div>;
  };

  const cellTemplate = data => {

    if (data.column.dataField === 'formaReajuste') {
      const result = gridListas.formaReajuste.filter(f => f.value === data.value);
      data.value = (result.length === 0 ? data.value : result[0].label);
    }

    if (data.column.dataField === 'permiteVenda' || data.column.dataField === 'controlaEstoque' || data.column.dataField === 'usaBalanca') {
      const result = gridListas.boolean.filter(f => f.value === data.value);
      data.value = (result.length === 0 ? data.value : result[0].label);
    }

    if (data.column.dataField === 'unidadeCompra' || data.column.dataField === 'unidadeVenda') {
      const result = unidadesMedida.filter(f => f.sigla === data.value);
      data.value = (result.length === 0 ? data.value : result[0].descricao);
    }

    return <span style={{ fontSize: '12px' }}>{data.value}</span>;
  };

  return (
    <div>
      <div className={`${styles.gridTitle} ${isImportacao ? styles.bgRed : styles.bgGreen}`}>
        <p>{title}</p>
      </div>
      <span className={`${styles.subTitle}`}>{subtitle}</span>
      <DataGrid
        dataSource={dados}
        ref={gridRef}
        showBorders={true}
        rowAlternationEnabled={true}
        allowColumnResizing={true}
        allowColumnReordering={true}
        columnResizingMode={'widget'}
        columnMinWidth={80}
        columnAutoWidth={true}
        noDataText="Sem itens para mostrar"
        // onRowPrepared={handleOnRowPrepared}
      >
        #region Colunas
        <Column dataField="estruturaMercadologicaDescricao" caption="Grupo" groupIndex={0} />
        <Column dataField="codigo" caption="Código" minWidth={120} headerCellRender={headerCellTemplate} />
        <Column dataField="codigoRapido" caption="Código Rapido" minWidth={120} headerCellRender={headerCellTemplate} />
        <Column dataField="descricao" caption="Descrição" minWidth={250} headerCellRender={headerCellTemplate} />
        <Column dataField="descricaoResumida" caption="Descrição Resumida" minWidth={250} headerCellRender={headerCellTemplate} />
        <Column dataField="marca" caption="Marca" minWidth={250} headerCellRender={headerCellTemplate} />
        <Column dataField="tipo" caption="Tipo" minWidth={200} headerCellRender={headerCellTemplate} />
        <Column dataField="subTipo" caption="SubTipo" minWidth={200} headerCellRender={headerCellTemplate} />
        <Column dataField="grupo" caption="Grupo" minWidth={200} headerCellRender={headerCellTemplate} />
        <Column dataField="subGrupo" caption="SubGrupo" minWidth={200} headerCellRender={headerCellTemplate} />
        <Column dataField="artigo" caption="Artigo" minWidth={200} headerCellRender={headerCellTemplate} />
        <Column dataField="setor" caption="Setor" minWidth={200} headerCellRender={headerCellTemplate} />
        <Column dataField="codigoNcm" caption="Código NCM" minWidth={100} headerCellRender={headerCellTemplate} />
        <Column dataField="codigoCest" caption="Código CEST" minWidth={100} headerCellRender={headerCellTemplate} />
        <Column dataField="unidadeCompra" caption="Unidade de Compra" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplate} />
        <Column dataField="unidadeVenda" caption="Unidade de Venda" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplate} />
        <Column dataField="permiteVenda" caption="Permite Venda" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplate}/>
        <Column dataField="controlaEstoque" caption="Controla Estoque" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplate}/>
        <Column dataField="usaBalanca" caption="Usa Balança" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplate}/>
        <Column
          dataField="fatorConversao"
          caption="Fator de Conversão"
          alignment="right"
          minWidth={120}
          format={{
            type: 'fixedPoint',
            precision: 3,
          }}
          headerCellRender={headerCellTemplate}
        />
        <Column
          dataField="precoVenda"
          caption="Preço de Venda R$"
          alignment="right"
          minWidth={120}
          format={{
            type: 'currency',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
        />
        <Column dataField="formaReajuste" caption="Forma de Reajuste" minWidth={150} headerCellRender={headerCellTemplate} cellRender={cellTemplate} />
        <Column 
          dataField="percentualReajuste"
          caption="Percentual de Reajuste"
          alignment="right"
          minWidth={150}
          format={{
            type: 'fixedPoint',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
        />
        <Column dataField="cstIcmsVenda" caption="Situação Tributária Venda - ICMS" width={200} headerCellRender={headerCellTemplate} cellRender={cellTemplate} />
        <Column
          dataField="aliquotaTributacaoIcms"
          caption="Alíquota Tributação - ICMS"
          alignment="right"
          minWidth={80}
          format={{
            type: 'fixedPoint',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
        />
        <Column
          dataField="aliquotaEfeitvaIcms"
          caption="Alíquota Efetiva %"
          alignment="right"
          minWidth={80}
          format={{
            type: 'fixedPoint',
            precision: 2,
          }}
          headerCellRender={headerCellTemplate}
        />
        #endregion
        <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
        <Paging defaultPageSize={15} />
        <Scrolling showScrollbar={'always'} columnRenderingMode="virtual" />
      </DataGrid>
    </div>
  );
}
