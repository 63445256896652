import { LoadPanel } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { locale } from 'devextreme/localization';
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { selectSolicitacaoReservaSharedData, useSolicitacaoReservaSharedData } from '../../../redux/slices/solicitacaoReserva/SharedData.slice';

import { menus } from '../../../shared/constants/MenuConstants';
import { getDadosLogin, getNomePagina } from '../../../shared/utils/Utils';
import { SessionManager } from '../../../shared/utils/sessionManager';
import {  fetchFiltroSolResInit, selectFiltroSolRes, useFiltroSolRes } from '../../../redux/slices/solicitacaoReserva/filtroSolSlice';
import styles from './SolicitacaoReserva.module.scss';

import { HeaderList } from './components/HeaderList';
import { SolicitacaoReservaForm } from './components/SolicitacaoReservaForm';

import { ApiListarSolResService } from './usercases/ApiListarSolRes.service';
import { GridReservaMercadoria } from './components/GridReservaMercadoria';
import { ApiRecuperaAtualSolResService } from './usercases/ApiRecuperaAtualSolRes.service';
import { ApiAbrirSolResService } from './usercases/ApiAbrirSolRes.service';

export function SolicitacaoReserva() {
  locale('pt-BR');
  const [gridOpened, setGridOpened] = useState(false);
  
  const PATH_MENU = menus.COD_17850;
  const [searching, setSearching] = useState(true);
  const [searchingMessage, setSearchingMessage] = useState('Carregando solicitações de reserva...');
  const { changeValue } = useFiltroSolRes();
  const { values, initialized: filterInitialized } = useSelector(selectFiltroSolRes);
  const { updateState } = useSolicitacaoReservaSharedData();


  const [ formData, setFormData ] = useState({});
  const [ recordOpened, setRecordOpened] = useState(false);


  const { initialized: dadosInicializados, loading: sharedLoading, error: sharedError, turnoAtual } = useSelector(selectSolicitacaoReservaSharedData);

  const dispatch = useDispatch();
  const history = useHistory();

  const headerFilter = React.createRef(null);

  async function buildNewRecord()
  {
    setSearching(true);
    setSearchingMessage('Criando nova solicitação de reserva...');
    try {
      const response = await new ApiAbrirSolResService().execute();
      setSearching(false);
      return response;
  
    }
    catch (Exx)
    {
      setSearching(false);
      await Swal.fire({
        title: 'Falha carregando dados iniciais da lista!',
        text: Exx.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    finally {
      setSearching(false);
    }
   
  }

  async function createNewRecord() {
    const rec = await buildNewRecord();
    
    if (rec && rec.itens && rec.itens.length)
    {
      setFormData(rec);
      setRecordOpened(true);
    }
    else
    {
      setRecordOpened(false);
    }
  }
    
  let [carregado, setCarregado] = useState(false);

  async function carregarForm()
  {
    setSearching(true);
    setSearchingMessage('Carregando dados iniciais...');
    try {
      
      const response = await new ApiRecuperaAtualSolResService().execute();
      
      if (response && response.itens && response.itens.length)
      {
        setFormData(response);
        setRecordOpened(true);
      }
    }
    catch (Exx)
    {
      await Swal.fire({
        title: 'Falha carregando dados iniciais da lista!',
        text: Exx.message,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
    setSearching(false);
  }

  useEffect(async () => {
    await carregarForm();
    
  }, [carregado]);

  useEffect(() => {
    setCarregado(true);
  });
  
  const toggleGrid = useCallback(() => {
    if (gridOpened) setGridOpened(false);
    else setGridOpened(true);
  }, [gridOpened]);

  return (
    <LinxPostos className={styles.container} breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel shadingColor="rgba(0,0,0,0.4)" position={{ of: '#page' }} visible={searching} showIndicator={true} shading={true} message={searchingMessage} showPane={true} />
      {values && (
        <>
          <HeaderList recordOpened={recordOpened} headerFilter={headerFilter} setSearchingMessage={setSearchingMessage} setSearching={setSearching} createNewRecord={createNewRecord} />
          <Card>
            
              <Card.Header>
                <div className="d-flex align-items-center justify-content-between">
                  <span className="text-success" title=''></span>
                </div>
              </Card.Header>
            
            <Card.Body>
                {!searching && <SolicitacaoReservaForm reloadForm={carregarForm} recordOpened={recordOpened} formData={formData} createNewRecord={createNewRecord} setFormData={setFormData} />}
                  <button style={{ width: 'auto' }} className={`btn btn-primary`} onClick={toggleGrid}>
                    {gridOpened ? <span>OCULTAR SOLICITAÇÕES ANTERIORES</span> : <span>VISUALIZAR SOLICITAÇÕES ANTERIORES</span>}
                  
                  </button>

                  {gridOpened && (
                    <GridReservaMercadoria />)
                  }

            </Card.Body>

          </Card>
           
        </>
      )}
    </LinxPostos>
  );
}

export default withRouter(SolicitacaoReserva);
