import { SelectBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { AutoComplete } from '../../../../components/formulario/Formulario';
import { montaComboEstruturaCartoes } from '../../../../pages/financeiro/gerenciamento-financeiro/GerenciamentoFinanceiro.service';
import { sameInt } from '../../../../shared/utils/Utils';
import styles from './EstruturaCartoesSelect.module.scss';
import { estruturaCartoesSelectService } from './EstruturaCartoesSelect.service';

export default function EstruturaCartoesSelect(props) {

  const [listaEstruturaCartoes, setListaEstruturaCartoes] = useState([]);   
  const [listaEstruturaCartoesLayoutAntigo, setListaEstruturaCartoesLayoutAntigo] = useState([]);   

  useEffect(() => {
    const loadEstruturas = async () => {
      const tempListaEstruturaCartoes = await estruturaCartoesSelectService.getEstruturaCartoes();

      setListaEstruturaCartoes(tempListaEstruturaCartoes);

      const tempListaEstruturaCartoesLayoutAntigo = await montaComboEstruturaCartoes();

      setListaEstruturaCartoesLayoutAntigo(tempListaEstruturaCartoesLayoutAntigo);
      
    };
    loadEstruturas();
  }, []);

  return (
    props.layoutAntigo ? (
      <AutoComplete
        value={props.selectedEstruturaCartoes}
        name="estruturaCartoes"
        label="Estrutura de cartões"
        options={listaEstruturaCartoesLayoutAntigo}
        noOptionsMessage='Não foi encontrado(a) nenhuma estrutura de cartões que contenha o texto pesquisado'
        onChange={e => {
          props.selectTaxaEstruturaCartoes(listaEstruturaCartoes.find(l => sameInt(l.id, e.value)).taxa)
          props.selectEstruturaCartoes(e.label, e.value);
        }}
        disabled={props.disabled}
      />
    ) : (
    <SelectBox
      dataSource={listaEstruturaCartoes}
      placeholder="Selecione..."
      displayExpr="estrutura"
      valueExpr="id"
      value={props.selectedEstruturaCartoes}
      onValueChanged={e => {
        props.selectTaxaEstruturaCartoes(listaEstruturaCartoes.find(l => l.id === e.value).taxa)
        props.selectEstruturaCartoes(e.value);
      }}
      disabled={props.disabled}
    />
    )
  );
}
