// import { VALUES_OS_FILTER, VALUES_OS_FILTER_RESULT } from './../../pages/operacoes/consignado-comodato-nova-os/valuesOsKey';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NovaOSValidateService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/novaOsValidate.service';

export const fetchFiltroOsInit = createAsyncThunk('filtroOs/init', async () => {
  return {};
});

const newFilter = () => {
  var firstTime = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7);
  var currentTime = new Date();
  currentTime.setHours(23, 59, 59, 999);
  firstTime.setHours(0, 0, 0, 0);

  return {
    itens: [],
    itensPedido: [],
    status: [],
    statusType: [],
    saldoPendente: [],
    consignadoPendente: [],
    comodatoPendente: [],
    locacaoPendente: [],
    filtrosAvancados: false,
  };
};

const initialState = {
  values: newFilter(),
  errors: {},
  touched: {},
  result: [],
  initialized: false,
}
const slice = createSlice({
  name: 'filtroOs',
  initialState,
  reducers: {
    changeValue: (state, { payload }) => {
      console.log('changeValue');
      state.values[payload.fieldName] = payload.value;
      state.errors = {};
      // new SessionManager().saveEncryptedData(VALUES_OS_FILTER, state.values);
      return state;
    },

    setResult: (state, { payload }) => {
      state.values.itens = payload.itens;
      if (!state.values.pagination) {
        state.values.pagination = { posStart: 1, posEnd: 1, paginas: 0, currentPage: 1, itensPorPagina: 10 };
      }
      state.values.pagination.currentPage = payload.pagina;
      state.values.pagination.paginas = payload.paginas;
      state.values.pagination.itensPorPagina = payload.itensPorPagina;
      // new SessionManager().saveEncryptedData(VALUES_OS_FILTER, state.values);
      return state;
    },

    setItensResult: (state, { payload }) => {
      state.values.itensPedido = payload.itens;
      if (!state.values.paginationItens) {
        state.values.paginationItens = { posStart: 1, posEnd: 1, paginas: 0, currentPage: 1, itensPorPagina: 10 };
      }
      state.values.paginationItens.currentPage = payload.pagina;
      state.values.paginationItens.paginas = payload.paginas;
      state.values.paginationItens.itensPorPagina = payload.itensPorPagina;
      // new SessionManager().saveEncryptedData(VALUES_OS_FILTER, state.values);
      return state;
    },

    changeBlur: (state, { payload }) => {
      state.touched = { ...state.touched, [payload.fieldName]: true };
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFiltroOsInit.fulfilled, (state) => {
      console.log('fetchFiltroOsInit.fulfilled', state.initialized);
      state.values = /*new SessionManager().getEncryptedData(VALUES_OS_FILTER) ||*/ newFilter();
      state.errors = new NovaOSValidateService().execute(state.values) || {};
      state.initialized = true;
      state.touched = {};
      state.result = /*new SessionManager().getEncryptedData(VALUES_OS_FILTER_RESULT) ||*/ [];
      return state;
    });
  },
});

export const useFiltroOs = () => slice.actions;
export const selectFiltroOs = state => state.filtroOs;
export default slice.reducer;
