import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';
import notify from 'devextreme/ui/notify';

export const AlterarPagamentoPedidoService = {
  GetFormas,
  GetFormasShared,
  SalvaTurnoPedidosPagamentos,
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function GetFormas() {
  const response = await urlLinxCore.get('/FormaPagamento/CarregarInformacoesParaPagamento')
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}
//adicionado o shared para ser compartilhado com outras telas sem precisar todos os tratamentos abaixo
async function GetFormasShared() {
  const responsePagamentos = await GetFormas();
  console.log(responsePagamentos);
  if (responsePagamentos.notifications.length > 0) {
    responsePagamentos.notifications.forEach(notification => {
      notify(
        notification.message,
        'info',
        2000,
        'top'
      );
    });
  }
  if (!responsePagamentos.result)
    notify(
      'Não foram encontradas informações de pagamentos disponíveis no sistema.',
      'error',
      2000,
      'top'
    );
  else
    if (!responsePagamentos.result.formaPagamentos.length || !responsePagamentos.result.redeCartoes.length) {
      if (!responsePagamentos.result.formaPagamentos.length)
        notify(
          'Não foram encontradas informações de formas de pagamentos disponíveis no sistema.',
          'error',
          2000,
          'top'
        );

      if (!responsePagamentos.result.redeCartoes.length)
        notify(
          'Não foram encontradas informações de cartões disponíveis no sistema.',
          'error',
          2000,
          'top'
        );
    }
    else {
      return responsePagamentos.result;
    }
  //esse ponto deve ser inatingível 
  return null;
}

async function SalvaTurnoPedidosPagamentos(body) {
  const response = await urlLinxCore.post('/Pagamento/SalvaTurnoPedidosPagamentos', body)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}






export default urlLinxCore;
