import { DataGrid, DropDownBox } from 'devextreme-react';
import { Column, FilterRow, Scrolling, Selection } from 'devextreme-react/data-grid';
import React, { useCallback, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { selectFiltroOs, useFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { DropDownImage } from '../../consignado-comodato-nova-os/components/drop-down-image';
import styles from './Component.module.scss';

export const DropboxTiposPedidos = forwardRef(({ statusId, onChanged }, ref) => {
  const [opened, setOpened] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const { values } = useSelector(selectFiltroOs);
  const tiposPedidos = useSelector(selectConsignadoComodatoSharedData).tiposPedidos;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { changeValue } = useFiltroOs();

  useEffect(() => {
    if (!tiposPedidos) return;
    const src = (values.tiposPedidos || '').split('_').map(x => parseInt(x, 10));
    const its = [];
    const data = tiposPedidos.filter(x => x.id > -1);
    for (const it of src) {
      const res = data.findIndex(x => x.id === it);
      if (res === null || res === undefined) continue;
      its.push(res + 1);
    }
    setSelectedItemKeys(its);
  }, [statusId, values.tiposPedidos, tiposPedidos]);

  useImperativeHandle(ref, () => ({
    getValue: () => data,
  }));

  const onSelectedItemKeysChange = useCallback(
    args => {
      const its = (tiposPedidos || []).filter(x => args.selectedRowKeys.includes(x.id));
      setData(its);
      if (typeof onChanged === 'function') {
        onChanged(its);
      }
      console.log('onSelectedItemKeysChange', its.map(x => x.id).join('_'), (values.tiposPedidos || ''));
      if (its.map(x => x.id).join('_') === (values.tiposPedidos || '')) return;
      const ids = [];
      for (const it of its) {
        ids.push(it.id);
      }
      dispatch(changeValue({ fieldName: 'tiposPedidos', value: ids.join('_') }));
      setSelectedItemKeys(args.selectedRowKeys);
    },
    [dispatch, changeValue, tiposPedidos, onChanged, values.tiposPedidos],
  );
  return (
    <DropDownBox
      showClearButton={false}
      placeholder="Escolha os tipos de pedidos"
      onValueChanged={e => {
        if (!e.event) return;
        if (((e.event.target || {}).className || '').includes('clear')) {
          setSelectedItemKeys([]);
          setData([]);
          if (typeof onChanged === 'function') {
            onChanged([]);
          }
        }
      }}
      className={`${styles.bottonBorder} ml-1`}
      valueExpr="id"
      displayExpr="nome"
      showDropDownButton={true}
      dropDownButtonRender={DropDownImage}
      dataSource={tiposPedidos.filter(x => x.id > -1)}
      openOnFieldClick={true}
      value={selectedItemKeys}
      onOpenedChange={e => {
        setOpened(e);
      }}
      opened={opened}
      onKeyUp={e => {
        if (opened) e.preventDefault();
        if (
          e.event.originalEvent.code === 'ArrowDown' ||
          e.event.originalEvent.key === 'ArrowDown' ||
          e.event.originalEvent.code === 'Enter' ||
          e.event.originalEvent.key === 'Enter' ||
          e.event.originalEvent.code === 'Space' ||
          e.event.originalEvent.key === 'Space'
        ) {
          setOpened(true);
        }
      }}
      contentRender={() => {
        return (
          <DataGrid
            dataSource={(tiposPedidos || []).filter(x => x.id !== -1)}
            keyExpr="id"
            showBorders={false}
            selectedRowKeys={selectedItemKeys}
            onSelectionChanged={onSelectedItemKeysChange}
          >
            <Selection mode="multiple" selectAllMode={'allPages'} showCheckBoxesMode={'always'} />
            <FilterRow visible={true} />
            <Scrolling mode="normal" />
            <Column dataField="nome" caption="Nome" />
          </DataGrid>
        );
      }}
    />
  );
});
