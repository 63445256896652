import Tooltip from 'rc-tooltip';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { hideAlert, showWait, tagAlert } from '../../../components/alert/Alert';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import SectionHeader from '../../../components/section/Header';
import { getEstruturasMercadologicas } from '../../../services/EstruturaMercadologica.service';
import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../shared/utils/Utils';
import { FormEstruturaMercadologica } from './EstruturaMercadologicaDetalhe';

export const Nivel = {
  None: 0,
  Raiz: 1,
  Grupo: 2,
  SubGrupo: 3,
  Artigo: 4
};

const PATH_MENU = menus.COD_60440;
const DETAIL_PATH = '/cadastro/estrutura-mercadologica/new';
const titulo = 'Estrutura mercadológica';

class TreeEstruturaMercadologica extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      estruturas: [],
      estruturasPreparadas: [],
      selectedKeys: [],
      expandedKeys: []
    };
  }

  async componentDidMount() {
    this.getContainer();

    showWait(
      this.props.sender,
      titulo,
      'Por favor, aguarde enquanto os dados são carregados'
    );
    try {
      const { data } = await getEstruturasMercadologicas();

      const estruturas = [];
      data.result.forEach(e => {
        estruturas.push(e);
      });

      const estruturasPreparadas = [];
      estruturasPreparadas.push({
        title: 'Estrutura mercadológica',
        key: 0,
        children: [],
        self: {},
        isLeaf: false,
        nivel: Nivel.Raiz
      });

      estruturas.forEach(e => {
        const children = [];
        if (e.codigoPai) {
          estruturasPreparadas[0].children.forEach(grupo => {
            if (grupo && e.codigoPai === grupo.key) {
              grupo.children.push({
                title: e.descricao,
                key: e.codigo,
                children,
                self: e,
                nivel: Nivel.SubGrupo,
                isLeaf: false
              });
            } else {
              grupo.children.forEach(subGrupo => {
                if (subGrupo && e.codigoPai === subGrupo.key) {
                  subGrupo.children.push({
                    title: e.descricao,
                    key: e.codigo,
                    children,
                    self: e,
                    nivel: Nivel.Artigo,
                    isLeaf: true
                  });
                }
              });
            }
          });
        } else {
          estruturasPreparadas[0].children.push({
            title: e.descricao,
            key: e.codigo,
            children,
            self: e,
            nivel: Nivel.Grupo,
            isLeaf: false
          });
        }
      });

      this.setState({ estruturasPreparadas, estruturas });
    } finally {
      hideAlert(this.props.sender);
    }
  }
  componentWillUnmount() {
    if (this.cmContainer) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
    }
  }
  onRightClick = info => {
    this.renderCm(info);
  };
  getContainer() {
    if (!this.cmContainer) {
      this.cmContainer = document.createElement('div');
      document.body.appendChild(this.cmContainer);
    }
    return this.cmContainer;
  }
  onSelect = (
    selectedKeys,
    e /*:{selected: bool, selectedNodes, node, event, nativeEvent}*/
  ) => {
    const { expandedKeys } = this.state;
    const key = selectedKeys[0];
    if (expandedKeys.includes(key)) {
      this.setState({ expandedKeys: expandedKeys.filter(k => k !== key) });
    } else {
      this.setState({ expandedKeys: [...expandedKeys, key] });
    }
  };

  onExpand = (expandedKeys, e) => {
    this.setState({ expandedKeys });
  };

  handleItem(item) {
    this.setState({ estruturaSel: item.object });
    this.props.setEstrutura(
      item.object,
      this.state.estruturas,
      item.insert,
      item.nivel,
      item.nivelPai
    );
  }

  newItem = info => {
    const handleInfo = {};
    handleInfo.nivelPai = info.props.nivel;
    handleInfo.nivel = info.props.nivel + 1;
    handleInfo.object = info.props.self;
    handleInfo.insert = true;
    this.handleItem(handleInfo);
  };

  changeItem = info => {
    const handleInfo = {};
    handleInfo.nivel = info.props.nivel;
    handleInfo.nivelPai = info.props.nivel - 1;
    handleInfo.object = info.props.self;
    handleInfo.insert = false;
    this.handleItem(handleInfo);
  };

  renderCm(info) {
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    this.toolTip = (
      <Tooltip
        trigger="click"
        placement="bottomRight"
        prefixCls="rc-tree-contextmenu"
        defaultVisible
        overlay={
          <ContextMenu
            info={info}
            newItem={this.newItem}
            changeItem={this.changeItem}
          />
        }
      >
        <span />
      </Tooltip>
    );

    const container = this.getContainer();
    Object.assign(this.cmContainer.style, {
      position: 'absolute',
      left: `${info.event.pageX}px`,
      top: `${info.event.pageY}px`
    });

    ReactDOM.render(this.toolTip, container);
  }

  render() {
    return (
      <>
        <Tree
          onRightClick={this.onRightClick}
          defaultExpandAll
          showLine
          selectedKeys={this.state.selectedKeys}
          expandedKeys={this.state.expandedKeys}
          onExpand={this.onExpand}
          showIcon={false}
          onSelect={this.onSelect}
          treeData={this.state.estruturasPreparadas}
        />
      </>
    );
  }
}

class ContextMenu extends Component {
  render() {
    const {
      node,
      node: {
        props: { pos }
      }
    } = this.props.info;
    const { newItem, changeItem } = this.props;
    return (
      <div>
        <>
          {!node.isLeaf() && (
            <h4 onClick={() => newItem(node)}>Incluir sub-item</h4>
          )}
          {pos !== '0-0' && (
            <h4 onClick={() => changeItem(node)}>Alterar item</h4>
          )}
        </>
      </div>
    );
  }
}

class EstruturaMercadologica extends Component {
  state = {
    estruturas: [],
    estruturaSel: {},
    insert: false,
    nivel: Nivel.None,
    nivelPai: Nivel.Raiz,
    telaDisponivel: false
  };

  setEstrutura = (estrutura, estruturas, insert, nivel, nivelPai) => {
    this.setState({
      estruturaSel: estrutura,
      estruturas,
      insert,
      nivel,
      nivelPai
    });
    this.props.history.push(DETAIL_PATH);
  };

  doMount() {
    this.setState({
      telaDisponivel: true
    });
  }

  componentDidMount() {
    this.doMount();
  }

  componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.doMount();
    }
  }

  render() {
    const { edit } = this.props;

    const {
      estruturas,
      estruturaSel = {},
      insert,
      nivel,
      nivelPai
    } = this.state;
    return (
      <>
        {edit ? (
          <FormEstruturaMercadologica
            estrutura={estruturaSel}
            estruturasExistentes={estruturas}
            insert={insert}
            nivel={nivel}
            nivelPai={nivelPai}
          />
        ) : (
          <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <SectionHeader
              title=""
              right={
                <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={false} />
              }
            />
            <SectionContainer>
              <SectionContent>
                <TreeEstruturaMercadologica
                  setEstrutura={this.setEstrutura}
                  sender={this}
                />
              </SectionContent>
            </SectionContainer>
          </LinxPostos>
        )}
        {tagAlert(this)}
      </>
    );
  }
}
EstruturaMercadologica = withRouter(EstruturaMercadologica);
export { EstruturaMercadologica };

