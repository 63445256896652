import React, { useCallback, useEffect } from 'react';

import { PaginaRecibo } from './PaginaRecibo';
import styles from './ImpressaoRecibo.module.scss';
import { useDispatch } from 'react-redux';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';

export function ImpressaoRecibo() {
  const dispatch = useDispatch();
  const { showImpressaoRecibo } = useNovaOs();
  const onPrint = useCallback(() => {
    const w = 900;
    const h = 680;
    let myWindow = window.open('', 'PRINT', `height=${h},width=${w},top=100,left=${window.screen.width / 2 - w / 2}`);
    myWindow.document.write(`<html><head><title>Impressão de Recibo</title>`);
    myWindow.document.write(`<meta charset="utf-8">`);
    myWindow.document.write(`<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>`);
    myWindow.document.write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">`);
    myWindow.document.write('</head><body>');
    myWindow.document.write(document.getElementById('impressaoRecibo').innerHTML);
    myWindow.document.write('</body>');
    myWindow.document.write('<script>window.onload=() => {window.print(); this.close()}</script>');
    myWindow.document.write('</html>');
    myWindow.document.close(); // necessary for IE >= 10
    myWindow.focus(); // necessary for IE >= 10*/
    return true;
  }, []);
  const onVoltar = useCallback(() => {
    document.getElementById('root').classList.remove(styles.noScroll);
    document.body.classList.remove(styles.overflowVisible);
    dispatch(showImpressaoRecibo(false));
  }, [dispatch, showImpressaoRecibo]);

  useEffect(() => {
    document.getElementById('root').classList.add(styles.noScroll);
    document.body.classList.add(styles.overflowVisible);
  });

  return (
    <>
      <div className={`${styles['contrato-header']} d-print-none`}>
        <h1>RECIBO</h1>
        <button className="btn btn-secondary" onClick={onVoltar}>
          <i className="icon icon-lx-previews"></i>&nbsp; VOLTAR
        </button>
        <button className="btn btn-primary" onClick={onPrint}>
          <i className="icon icon-lx-print"></i>&nbsp; IMPRIMIR
        </button>
      </div>
      <PaginaRecibo styles={styles} />
    </>
  );
}
