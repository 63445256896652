import React, { Component } from 'react';
import {
  getUrl,
  getDadosSellerPop,
  getNomePagina
} from '../../../shared/utils/Utils';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import SellerPopFrame from '../../../components/SellerPopFrame/SellerPopFrame';

import { menus } from '../../../shared/constants/MenuConstants';


const PATH_MENU = menus.COD_60770;

export class SpedFiscal extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <SellerPopFrame url = {
            getUrl() +
            '/GeraArqSpedF.aspx?' +
            getDadosSellerPop(PATH_MENU)
          }
        />
      </LinxPostos>
    );
  }
}
