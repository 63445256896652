import { CharToInt } from './utils';
export function CheckIEROantigo(ie) {
  if (ie.length !== 9) {
    return false;
  }

  var nro = new Array(9);
  var b = 6;
  var soma = 0;

  for (var i = 3; i <= 8; i++) {
    nro[i] = CharToInt(ie.charAt(i));

    if (i !== 8) {
      soma = soma + nro[i] * b;
      b--;
    }
  }

  var dig = 11 - (soma % 11);
  if (dig >= 10) dig = dig - 10;

  return dig === nro[8];
} //ro-antiga
