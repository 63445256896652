import axios from "axios";
import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";
import { getUrl, getCnpj } from "../../../shared/utils/Utils";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getConsultaTelefoneEmpresa() {
    
    var cnpj = getCnpj();

    return axios.post(`LxApi/v1/Empresa/ConsultaTelefone`, {
      
        cnpjEstabelecimento : cnpj,
      
    });
  }