import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import { Tanques } from './tanques/Tanques';
import { Bombas } from './bombas/Bombas';
import { Bicos } from './bicos/Bicos';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina } from "../../../shared/utils/Utils";


export const MAPA_PATH = '/cadastro/tanques-bombas-bicos/';

const PATH_MENU = menus.COD_60530;


class MapaDePista extends Component {
  render() {
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="">
              <Tanques noLinxPostos />
              <div className="content-divider1" />
              <Bombas noLinxPostos />
              <div className="content-divider1" />
              <Bicos noLinxPostos />
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
      </>
    );
  }
}

MapaDePista = withRouter(MapaDePista);
export { MapaDePista };
