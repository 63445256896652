import React, { useState, useEffect } from 'react';

export const pillMain = {
  minHeight: '45px',
  marginBottom: '15px',
  display: 'flex',
  alignItems: 'baseline'
};

export const pill = {
  backgroundColor: '#ebebeb',
  color: '#000000',
  maxWidth: 'fit-conten',
  borderRadius: '9px',
  paddingLeft: '10px',
  paddingRight: '10px',
  paddingBottom: '2px',

  marginRight: '10px',
  height: '45px',
  display: 'flex',
  alignItems: 'center',
  span: {
    fontSize: '14px'
  },
  i: {
    marginRight: '5px',
    fontSize: '18px'
  }
};

export default function LinxInfoPill(props) {
  const [info, setInfo] = useState(props.info);

  useEffect(() => {
    setInfo(props.info);
  }, [props]);

  return (
    <div style={pillMain}>
      <div style={pill}>
        <span style={pill.span}>{info ? info : null}</span>
      </div>
    </div>
  );
}
