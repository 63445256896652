import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import TableTree, {
  Headers,
  Header,
  Rows,
  Row,
  Cell
} from '@atlaskit/table-tree';

import SectionHeader from '../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import Botao from '../../../components/botao/Botao';
import List from '../../../components/formulario/List';
import {
  InputText,
  handleInputChange
} from '../../../components/formulario/Formulario';
import {
  Alert,
  showInfo,
  showError,
  showWait,
  showQuestion,
  hideAlert,
  hideWait
} from '../../../components/alert/Alert';

import {
  getPerfis,
  getPerfil,
  getEstruturaPermissaoPadrao,
  incluirPerfil,
  alterarPerfil,
  excluirPerfil
} from './PerfisAcesso.service';
import {
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  sameInt
} from '../../../shared/utils/Utils';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../shared/constants/MenuConstants';
import Footer from '../../../components/core/footer/Footer';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';

const PATH_MENU = menus.COD_60620;
const myURL = '/configuracoes/perfil-acesso/';

export function unflatten(arr, idProp, parentProp) {
  var tree = [],
    mappedArr = {},
    arrElem,
    mappedElem;

  // First map the nodes of the array to an object -> create a hash table.
  for (var i = 0, len = arr.length; i < len; i++) {
    arrElem = arr[i];
    mappedArr[arrElem[idProp]] = { ...arrElem };
    mappedArr[arrElem[idProp]]['children'] = [];
    mappedArr[arrElem[idProp]]['hasChildren'] = false;
    mappedArr[arrElem[idProp]]['id'] = arr[i][idProp];
    mappedArr[arrElem[idProp]]['content'] = { ...arr[i], id: undefined };
  }

  for (var id in mappedArr) {
    if (mappedArr.hasOwnProperty(id)) {
      mappedElem = mappedArr[id];
      // If the element is not at the root level, add it to its parent array of children.
      if (mappedElem[parentProp] && mappedArr[mappedElem[parentProp]]) {
        mappedArr[mappedElem[parentProp]]['hasChildren'] = true;
        mappedArr[mappedElem[parentProp]]['children'].push(mappedElem);
      }
      // If the element is at the root level, add it to first level elements array.
      else {
        tree.push(mappedElem);
      }
    }
  }
  return tree;
}

export function flatten(arr) {
  let ret = [];
  for (const obj of arr) {
    ret = ret.concat(
      obj.content.permissoes.map(p => {
        return {
          codigoMenu: p.codigoMenu,
          codigoOperacao: p.codigoOperacao,
          temPermissao: p.temPermissao
        };
      })
    );
    if (obj.hasChildren) {
      ret = ret.concat(flatten(obj.children));
    }
  }
  return ret;
}

const Permissao = permissao => ({ permissoes, codigoMenu, onClick }) => {
  const perm = permissoes.find(p => p.codigoOperacao === permissao);
  if (perm) {
    return (
      <Botao
        ic
        onClick={() => onClick(codigoMenu, permissao)}
        secondary={!perm.temPermissao}
        icon={!perm.temPermissao ? 'icon-lx-close' : 'icon-lx-check'}
      />
    );
  } else {
    return <div style={{ width: '50px', textAlign: 'center' }}>—</div>;
  }
};

const Inclusao = Permissao(10);
const Alteracao = Permissao(20);
const Exclusao = Permissao(30);
//const Consulta = Permissao(40);
const Acesso = Permissao(50);

class Form extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const { perfil } = this.props;
    if (perfil) {
      this.state = {
        descricao: perfil.descricao,
        codigoPerfil: perfil.codigoPerfil,
        rowVersion: perfil.rowVersion,
        estrutura: unflatten(perfil.acessos, 'codigoMenu', 'codigoMenuPai')
      };
    } else {
      this.state = {
        descricao: '',
        estrutura: null
      };
    }
    this.state = {
      ...this.state,
      alertOptions: {
        active: false,
        type: '',
        title: '',
        subtitle: '',
        action: null
      }
    };
  }

  async componentDidMount() {
    const { estrutura } = this.state;
    if (!estrutura || !estrutura.length) {
      const { data } = await getEstruturaPermissaoPadrao();
      this.setState({
        estrutura: unflatten(data, 'codigoMenu', 'codigoMenuPai')
      });
    }
  }

  validateData = permissoes => {
    let count = 0;
    if (permissoes) {
      permissoes.forEach(p => {
        if (!sameInt(p.codigoOperacao, 40) && p.temPermissao) {
          count++;
        }
      });
    } else {
      return true;
    }
    return !!count;
  };

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    const { rowVersion, descricao, estrutura, codigoPerfil } = this.state,
      perfil = {
        descricao,
        rowVersion,
        codigoPerfil,
        permissoes: flatten(estrutura)
      };

    const [action] = rowVersion ? [alterarPerfil] : [incluirPerfil];

    if (this.validateData(perfil.permissoes)) {
      try {
        showWait(this, 'Perfil de acesso', `Por favor, aguarde enquanto o perfil é salvo..`);
        await action(perfil);
        hideWait(this);
        showInfo(
          this,
          '',
          `Perfil ${!rowVersion ? 'cadastrado' : 'alterado'} com sucesso!`,
          null,
          () => hideAlert(this, this.props.history, myURL)
        );
      } catch (err) {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        )
          showError(this, '', err.response.data.message);
        else showError(this, '', 'Ocorreu um erro inesperado na gravação.');
      }
    }
  };

  canDelete = () => {
    // todo: validar se tem usuario?
    return true;
  };

  handleCancelar = async () => {
    showQuestion(
      this,
      'Pefil de Acesso',
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (resp) hideAlert(this, this.props.history, myURL);
        else hideAlert(this);
      }
    );
  };

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    if (this.canDelete()) {
      showQuestion(
        this,
        '',
        'Deseja realmente excluir esse perfil?',
        null,
        async resp => {
          if (resp) {
            try {
              await excluirPerfil(this.state.codigoPerfil);
              showInfo(this, null, 'Perfil excluído com sucesso!', null, () =>
                hideAlert(this, this.props.history, myURL)
              );
            } catch (err) {
              if (
                err &&
                err.response &&
                err.response.data &&
                err.response.data.message
              )
                showError(this, '', err.response.data.message);
              else
                showError(this, '', 'Ocorreu um erro inesperado na exclusão.');
            }
          } else {
            hideAlert(this);
          }
        }
      );
    }
  };

  handleTogglePermission = (codigoMenu, permissao) => {
    let { estrutura } = this.state;
    this.treeHelper(estrutura, codigoMenu, permissao);

    this.setState({ estrutura });
  };

  treeHelper = (tree, codigoMenu, permissao) => {
    for (let obj of tree) {
      if (obj.codigoMenu === codigoMenu) {
        for (let perm of obj.permissoes) {
          if (perm.codigoOperacao === permissao) {
            perm.temPermissao = !perm.temPermissao;
            break;
          }
        }
        break;
      } else if (obj.hasChildren) {
        this.treeHelper(obj.children, codigoMenu, permissao);
      }
    }
  };

  render() {
    const { descricao, estrutura, alertOptions, rowVersion } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="">
              <div className="row">
                <div className="col-3">
                  <InputText
                    label="Descrição:"
                    name="descricao"
                    maxlength={30}
                    value={descricao}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent title="">
              <TableTree>
                <Headers>
                  <Header width={300}>Descrição</Header>
                  <Header width={80}>Acesso</Header>
                  <Header width={80}>Exclusão</Header>
                  <Header width={80}>Alteração</Header>
                  <Header width={80}>Inclusão</Header>
                </Headers>
                <Rows
                  items={estrutura}
                  render={({
                    descricao,
                    codigoMenu,
                    permissoes,
                    hasChildren,
                    children
                  }) => {
                    return (
                      <Row
                        expandLabel={'Expandir'}
                        collapseLabel={'Fechar'}
                        itemId={codigoMenu}
                        key={codigoMenu}
                        items={children}
                        hasChildren={hasChildren}
                      >
                        <Cell>{descricao}</Cell>
                        <Cell>
                          <Acesso
                            permissoes={permissoes}
                            codigoMenu={codigoMenu}
                            onClick={this.handleTogglePermission}
                          />
                        </Cell>
                        <Cell>
                          <Exclusao
                            permissoes={permissoes}
                            codigoMenu={codigoMenu}
                            onClick={this.handleTogglePermission}
                          />
                        </Cell>
                        <Cell>
                          <Alteracao
                            permissoes={permissoes}
                            codigoMenu={codigoMenu}
                            onClick={this.handleTogglePermission}
                          />
                        </Cell>
                        <Cell>
                          <Inclusao
                            permissoes={permissoes}
                            codigoMenu={codigoMenu}
                            onClick={this.handleTogglePermission}
                          />
                        </Cell>
                      </Row>
                    );
                  }}
                />
              </TableTree>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
        <Footer
          saveAction={
            rowVersion
              ? menuPermiteAlteracao(PATH_MENU)
                ? this.handleSalvar
                : null
              : this.handleSalvar
          }
          deleteAction={
            !rowVersion
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
          cancelAction={this.handleCancelar}
        />

        <Alert
          active={alertOptions.active}
          type={alertOptions.type}
          title={alertOptions.title}
          subtitle={alertOptions.subtitle}
          handleAction={alertOptions.action}
        />
      </>
    );
  }
}

Form = withRouter(Form);

class PerfilAcesso extends Component {
  constructor(props) {
    super(props);
    this.state = { perfis: [], searchText: '', perfilSel: null };

    this.handleInputChange = handleInputChange.bind(this);
  }

  componentDidMount() {
    this.loadPerfisAcesso();
  }

  componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.loadPerfisAcesso();
    }
  }

  loadPerfisAcesso = async (searchText) => {
    this.setState({ searchText})
    const {
      data: { result: perfisU }
    } = await getPerfis({ Search: searchText });

    const perfis = perfisU.sort(function(a, b) {
      if (a.descricao.toLowerCase() < b.descricao.toLowerCase()) return -1;
      if (a.descricao.toLowerCase() > b.descricao.toLowerCase()) return 1;
      return 0;
    });

    this.setState({ perfis });
  };

  handleTableClick = async (state, rowInfo, column, instance, e) => {
    try {
      if (rowInfo) {
        const { data: perfil } = await getPerfil(rowInfo.original.codigoPerfil);
        this.setState({ perfilSel: perfil });
        this.props.history.push(myURL + 'new');
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  limparCadastro = () => {
    this.setState({ perfilSel: null });
  }

  setarValorCampoBusca = (texto) => {
    this.setState({ searchText: texto });
  }

  render() {
    const { edit } = this.props;
    const { perfis, searchText, perfilSel } = this.state;
    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <Form perfil={perfilSel} />
          ) : (
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
              <SectionHeader
                title=""
                subtitle=""
                right={
                  !edit && (
                    <ScreenHeader search={true} pathMenu={PATH_MENU} newButton={true} texto="Novo Lançamento" newURL={myURL+"new"} searchText={searchText} load={this.loadPerfisAcesso} limparCadastro={this.limparCadastro} setarValorCampoBusca={this.setarValorCampoBusca} />
                  )
                }
              />
              <List
                onClick={this.handleTableClick}
                cols={[
                  {
                    accessor: 'descricao',
                    Header: 'Descrição',
                    width: 600,
                    filterable: false
                  }
                ]}
                rows={perfis}
              />
            </LinxPostos>
          )}
        </section>
      </main>
    );
  }
}

PerfilAcesso = withRouter(PerfilAcesso);
export { PerfilAcesso };
