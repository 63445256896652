import { Button, Tooltip } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import React, { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { TurnoPedidoService } from '../../../../../../services/turno-pedido/TurnoPedido.service';

/**
 * Componente `ItemTurno` para renderizar os detalhes de um turno específico em um cartão.
 *
 * @component
 * @param {Object} props - Propriedades para o componente.
 * @param {Object} props.turno - Dados do turno a serem exibidos.
 * @param {string} props.turno.turnoId - Id do turno.
 * @param {string} props.turno.nomeOperador - Nome do operador do turno.
 * @param {string} props.turno.codigoOperador - Id do operador do turno.
 * @param {Date|string} props.turno.dataAbertura - Data e hora de início do turno.
 * @param {Date|string} props.turno.dataFechamento - Data e hora de término do turno.
 * @param {string} props.turno.turnoConferido - Status atual do turno (por exemplo, true, false).
 * @param {number} props.turno.valorSuprido - Valor total dos suprimentos para o turno.
 * @param {number} props.turno.valorRecebido - Valor total dos recebimentos para o turno.
 * @param {number} props.turno.valorSangrado - Valor total das sangrias para o turno.
 * @param {Function} [props.onBusy] - Função de retorno de chamada acionada quando o componente está ocupado.
 * @param {Function} [props.onUnBusy] - Função de retorno de chamada acionada quando o componente não está mais ocupado.
 * @param {Function} [props.selectedTurno] - Função de retorno de chamada quando um turno é selecionado.
 *
 * @returns {React.Component} Componente `ItemTurno` renderizado.
 */
const ItemTurno = props => {
  const handlerLoadTurno = useCallback(
    async turnoId => {
      try {
        if (props.onBusy) props.onBusy('Carregando detalhes do turno...');
        const ret = await TurnoPedidoService.getInstance().recuperarTurnoPedidoCompleto(turnoId);
        if (props.onUnBusy) props.onUnBusy();
        if (props.selectedTurno) props.selectedTurno(ret);
      } catch (error) {
        if (props.onUnBusy) props.onUnBusy();
        alert(error.message, 'Erro ao carregar turno');
      }
    },
    [props],
  );

  const { nomeOperador, nomeConferente, turnoId, dataAbertura, dataFechamento, turnoConferido, valorRecebido, valorSuprido, valorSangrado } = props.turno;

  const cardFooterContentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
  };

  const footerColumnStyle = {
    flex: 1,
    textAlign: 'center',
  };

  const styleColor = 'white';
  const styleColorText = 'dark';

  return (
    <div className="col-sm-6 col-md-6 col-1 col-lg-4 pb-3">
      <Card bg={styleColor} variant={styleColor} key={styleColor} text={styleColorText} style={{ position: 'relative' }} className="position-relative">
        <Card.Body>
          <Card.Title style={{ color: 'purple' }}>{(nomeOperador || '').toUpperCase()}</Card.Title>
          <Card.Text style={{ fontSize: '9px' }}>{`De: ${new Date(dataAbertura).toLocaleString()} Até ${
            dataFechamento ? new Date(dataFechamento).toLocaleString() : 'Pendente'
          }`}</Card.Text>
          <Card.Text style={{ fontSize: '9px' }}>{`Conferido por: ${nomeConferente || '---'}`}</Card.Text>
          <div className="card-content">
            <span className={!turnoConferido ? 'badge badge-dark' : 'badge badge-success'}>{turnoConferido ? 'CONFERIDO' : 'PENDENTE'}</span>
          </div>
          <Card.Text></Card.Text>
          <Card.Footer>
            <div style={cardFooterContentStyle}>
              <div style={footerColumnStyle}>
                <strong>
                  <small>
                    <b>Suprimentos:</b>
                  </small>
                </strong>
                <br />
                {`R$ ${(valorSuprido || 0).toFixed(2)}`}
              </div>
              <div style={footerColumnStyle}>
                <strong>
                  <small>
                    <b>Recebimentos:</b>
                  </small>
                </strong>
                <br />
                {`R$ ${valorRecebido.toFixed(2)}`}
              </div>
              <div style={footerColumnStyle}>
                <strong>
                  <small>
                    <b>Sangrias:</b>
                  </small>
                </strong>
                <br />
                {`R$ ${(valorSangrado || 0).toFixed(2)}`}
              </div>
            </div>
          </Card.Footer>
        </Card.Body>

        <Button
          id={`btnEditarTurno_${turnoId}`}
          variant={turnoConferido ? 'primary' : 'secondary'}
          className={`position-absolute p-0 btn-${!turnoConferido ? 'primary' : 'secondary'}`}
          style={{ top: '16px', right: '16px' }}
          onClick={() => handlerLoadTurno(turnoId)}
        >
          <i className="fas fa-edit"></i>
        </Button>

        <Tooltip
          target={`#btnEditarTurno_${turnoId}`}
          showEvent="mouseenter"
          hideEvent="mouseleave"
          contentRender={() => {
            return <div>Visualizar o Turno</div>;
          }}
        />
      </Card>
    </div>
  );
};

export default ItemTurno;
