import React from 'react';
import styles from './SituacaoBadge.module.scss';
import conferenciaCaixa from '../../service/ConferenciaCaixa.service';

const situacaoBadge = props => {
  const getStyle = () => {
    let situacoes = conferenciaCaixa.Situacoes();
    if (props.situacao === situacoes.AbertoNoPDV) {
      return styles.Aberto;
    } else if (props.situacao === situacoes.Conferido) {
      return styles.Conferido;
    } else if (props.situacao === situacoes.ConferidoParcialmente) {
      return styles.ConferidoParcialmente;
    } else {
      return styles.NaoConferido;
    }
  };

  const getText = () => {
    let situacoes = conferenciaCaixa.Situacoes();
    if (props.situacao === situacoes.AbertoNoPDV) {
      return 'Aberto';
    } else if (props.situacao === situacoes.Conferido) {
      return 'Conferido';
    } else if (props.situacao === situacoes.ConferidoParcialmente) {
      return 'Conferido Parcialmente';
    } else {
      return 'Não Conferido';
    }
  };

  return (
    <div className={`${styles.SituacaoBadge} ${getStyle()}`}>
      <span>{getText()}</span>
    </div>
  );
};

export default situacaoBadge;
