import { getUnidadeMedida } from "../../../../cadastro/produtos/Produtos.service";

export const ajusteProdutosUtils = {
  getFormat,
  tratarUnidadeMedida,
  tratarProdutosIn,
  tratarProdutosPlanilha,
  getFieldMaxValue,
  getFieldErros,
};

function getFormat(format) {
  switch (format) {
    case 'currency':
      return {
        type: 'currency',
        precision: 2,
      };
    case 'percent':
      return {
        type: 'fixedPoint',
        precision: 2,
      };
    case 'decimalScale_3':
      return {
        type: 'fixedPoint',
        precision: 3,
      };
    default:
      break;
  }
}

function tratarUnidadeMedida(lista) {
  return lista.map(item => ({
    ...item,
    sigla: item.sigla.trim(),
  }));
}

function tratarProdutosIn(produtos, gridListas, unidadeMedida) {
  return produtos.map(produto => ({
    ...produto,
    estruturaMercadologicaDescricao: `${produto.grupo} | ${produto.subGrupo} | ${produto.artigo}`,
    permiteVenda: getValueGridLista(gridListas.boolean, produto.permiteVenda, true),
    controlaEstoque: getValueGridLista(gridListas.boolean, produto.controlaEstoque, true),
    usaBalanca: getValueGridLista(gridListas.boolean, produto.usaBalanca, true),
    unidadeCompra: getSiglaUnidadeMedida(unidadeMedida, produto.unidadeCompra, 'UNIDADE'),
    unidadeVenda: getSiglaUnidadeMedida(unidadeMedida, produto.unidadeVenda, 'UNIDADE'),
    formaReajuste: getValueGridLista(gridListas.formaReajuste, produto.formaReajuste, 'Margem Markup'),
  }));
}

function tratarProdutosPlanilha (produtos, gridListas, unidadeMedida) {
  return produtos.map(produto => {
    return {
      ...produto,
      permiteVendaDescricao: getLabelGridLista(gridListas.boolean, produto.permiteVenda, true),
      controlaEstoqueDescricao: getLabelGridLista(gridListas.boolean, produto.controlaEstoque, true),
      usaBalancaDescricao: getLabelGridLista(gridListas.boolean, produto.usaBalanca, true),
      unidadeCompraDescricao: getDescricaoUnidadeMedida(unidadeMedida, produto.unidadeCompra, 'UN'),
      unidadeVendaDescricao: getDescricaoUnidadeMedida(unidadeMedida, produto.unidadeVenda, 'UN'),
      formaReajusteDescricao: getLabelGridLista(gridListas.formaReajuste, produto.formaReajuste, 'MargemMarkup'),
    };
  });
};

function getValueGridLista(lista, dataValue, defaultValue) {
  const result = lista.filter(f => f.label === dataValue);
  return result.length === 0 ? defaultValue : result[0].value;
}

function getLabelGridLista(lista, dataValue, defaultValue) {
  const result = lista.filter(f => f.value === dataValue);
  return result.length === 0 ? defaultValue : result[0].label;
}

function getSiglaUnidadeMedida(lista, dataValue, defaultValue) {
  const result = lista.filter(f => f.descricao === dataValue);
  return result.length === 0 ? defaultValue : result[0].sigla;
}

function getDescricaoUnidadeMedida(lista, dataValue, defaultValue) {
  const result = lista.filter(f => f.sigla === dataValue);
  return result.length === 0 ? defaultValue : result[0].descricao;
}


function getFieldMaxValue(dataField, row) {
  if (typeof dataField === 'undefined') return 0;

  if (dataField === 'precoVenda') {
    return 999999.99;
  } else if (dataField === 'percentualReajuste') {
    if (typeof row.formaReajuste === 'undefined' || row.formaReajuste === 'Margem Markup') {
      return 100;
    } else if (row.formaReajuste === 'Margem Bruta') {
      return 1000;
    }
  } else {
    return 0;
  }
}

function getFieldErros(dataField, id, validation) {
  
  if (validation.length > 0) {

    const produtoErros = validation.filter(produto => produto.id === id);

    if (produtoErros.length > 0) {
      const alertas = produtoErros[0].alertas.filter(
        alerta => (alerta.campo.toUpperCase()) === dataField.toUpperCase(),
      );

      if (alertas.length > 0) {
        return alertas;
      }
      
    }

  }

  return [];
}
