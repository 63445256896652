import React, { Component } from "react";

import { SectionContainer, SectionContent } from "../section/Content";

import ReactTable from "react-table";
import "react-table/react-table.css";

export function sortInt(a, b, desc) {
  // converte string para integer e depois compara
  const n1 = Number.parseInt(a);
  const n2 = Number.parseInt(b);

  if (n1 > n2) {
    return -1;
  } else if (n1 < n2) {
    return 1;
  } else {
    return 0;
  }
}

export function sortFloat(a, b, desc) {
  const n1 = Number(a);
  const n2 = Number(b);
  if (n1 > n2) return -1;
  if (n1 < n2) return 1;
  return 0;
}

export default class List extends Component {
  constructor(props, context) {
    super(props, context);
    this._columns = this.props.cols;

    this.state = { filters: {} };
  }

  render() {
    const {
      title = "Lista",
      noDataText = "Sem registros para exibir"
    } = this.props;
    return (
      <SectionContainer>
        <SectionContent title={title}>
          <div className="table-ctn">
            <ReactTable
              data={this.props.rows}
              columns={this._columns}
              getTdProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e, handleOriginal) => {
                    if (this.props.onClick) {
                      this.props.onClick(state, rowInfo, column, instance, e);
                    }

                    if (handleOriginal) {
                      handleOriginal();
                    }
                  }
                };
              }}
              previousText="Anterior"
              nextText="Próximo"
              loadingText="Carregando registros..."
              noDataText={noDataText} //"Sem registros para exibir"
              pageText="Página"
              ofText="de"
              rowsText="registros"
              defaultPageSize={this.props.defaultPageSize}
            />
          </div>
        </SectionContent>
      </SectionContainer>
    );
  }
}
