import React from 'react';
import { Popup } from 'devextreme-react/popup';
import icsuccess from '../../../../../../assets/images/postopop/lx_check2.svg';
import icerro from '../../../../../../assets/images/postopop/lx_close-2.svg';
import { locale } from 'devextreme/localization';

export function ModalImportacaoResultado(props) {

  locale('pt-BR');

  function updateWidth() {
    let widthTotal = window.innerWidth;
    let min = window.innerWidth < 700 ? window.innerWidth : 700;
    let widthPercent = widthTotal < 1400 ? widthTotal * 0.55 : widthTotal * 0.2;
    return widthPercent < min ? min : widthPercent;
  }

  const getStatusIcon = status => {
    let src = '',
      alt = '';

    switch (status) {
      case 0:
        src = icerro;
        alt = 'Erro';
        break;

      case 1:
        src = icsuccess;
        alt = 'Sucesso';
        break;

      default:
        break;
    }

    return (
      <div>
        <img src={src} alt={alt} height="30" width="30" />
      </div>
    );
  };
  
  console.log(props.dadosResultadoConfirmacao);

  const contentRender = () => {
    return (
      <scrollview
        id="scrollview"
        scrollbycontent={true}
        showscrollbar={'onscroll'}
        scrollbythumb={true}>
        <div>
          <div className='table-responsive-sm'>
            <table className="table table-striped table-bordered">
              <thead style={{backgroundColor: '#5b2e90', color: 'white'}}>
                <tr>
                  <td>Status</td>
                  <td>Código EAN</td>
                  <td>Descrição</td>
                  <td>Mensagem</td>
                </tr>
              </thead>
              <tbody>
                {props.dadosResultadoConfirmacao.map((item, index) => (<tr key={index}>
                  <td>{getStatusIcon(item.status)}</td>
                  <td>{item.codigo}</td>
                  <td>{item.descricao}</td>
                  <td>{item.mensagem}</td>
                </tr>))}
              </tbody>
            </table>
          </div>
        </div>
      </scrollview>
    );
  };

  return (
    props.active && (
      <Popup
        visible={props.active}
        onHidden={() => props.handleCloseModal(0)}
        dragEnabled={false}
        closeOnOutsideClick={false}
        title="Retorno da importação"
        showTitle={true}
        width={updateWidth()}
        contentRender={contentRender}
        height={650}
      />
    )
  );
}
