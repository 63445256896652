import React, { memo, useCallback } from 'react';

import { DropDownImage } from '../drop-down-image';
import { Form } from 'react-bootstrap';
import { SelectBox } from 'devextreme-react';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';


const DropBoxBandeiras = forwardRef(({ title, onSelected, setCurrentBandCode }, ref) => {
  const bandeiras = useSelector(selectConsignadoComodatoSharedData).bandeiras;
  const [bandeira, setBandeira] = useState(null);
  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem) {
        if (bandeira) return;
        setBandeira(null);
        return;
      }
      const data = { ...e.selectedItem };
      if (data.id === (bandeira || {}).id) return;
      setBandeira(data);
      setCurrentBandCode(data.id);
    },
    [bandeira],
  );

  const onValueChanged = useCallback(
    data => {
      if (!data.event) return;
      if (typeof onSelected === 'function') {
        onSelected(data.value)
      }
    },
    [onSelected],
  );

  useImperativeHandle(
    ref,
    () => ({
      getBandeira: () => {
        return bandeira;
      },
      clear: () => {
        setBandeira(null);
        if (onSelected) onSelected(null);
      }
    }),
    [bandeira, onSelected],
  );

  return (
    <>
      <Form.Label htmlFor="bandeira" className="text-primary">
        {title ? title : 'Bandeira'}
        <span className="text-danger">*</span>
      </Form.Label>
      <SelectBox
        dropDownButtonRender={DropDownImage}
        showDropDownButton={true}
        searchEnabled={true}
        hint="Selecione a Bandeira"
        placeholder="Selecione a Bandeira"
        displayExpr="descricao"
        acceptCustomValue={false} // Não permite que o valor digitado seja aceito
        height={40}
        valueExpr="id"
        value={bandeira}
        onSelectionChanged={onSelectionChanged}
        onValueChanged={onValueChanged}
        dataSource={bandeiras}
        selectedItem={bandeira || {}}
        name="bandeira"
      />
    </>
  );
});

export default memo(DropBoxBandeiras);
