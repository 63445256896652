import { ApiGetParamentroByIdService } from './ApiGetParametroById.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ConfiguracoesGeraisService {
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }
  static instance = null;
  static getInstance() {
    if (!ConfiguracoesGeraisService.instance) {
      ConfiguracoesGeraisService.instance = new ConfiguracoesGeraisService();
    }
    return ConfiguracoesGeraisService.instance;
  }
  apiGetParameteroById = new ApiGetParamentroByIdService();

  byId = async (id) => {
    return await this.apiGetParameteroById.execute(id);
  }

  async byFiltro(filtro) {
    var body =
    {
      ListaCodigosParametros: filtro
    }
    const response = await this.urlLinxCore.post(`ConfiguracoesGerais/RecuperarPorFiltro`, body);
    const data = response.data.itens;
    return data;
  }

}
