import axios from 'axios';

import { TOKEN_PASS_AXIOS } from '../shared/constants/Const';
import { getUrl, getCnpj } from '../shared/utils/Utils';

axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function getMontagemMenu(codigoUsuario) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v1/Menus/Montagem`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoUsuario,
      cadastroCompleto: true
    }
  });
}

export function getAcessosMenu(codigoUsuario, codigoMenu) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v1/Menus/Acessos`, {
    params: { cnpjEstabelecimento: getCnpj(), codigoUsuario, codigoMenu }
  });
}

export function salvaFavorito(codigoUsuario, codigoMenu, favoritar) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v1/Menus/SalvaFavorito`, {
    params: { cnpjEstabelecimento: getCnpj(), codigoUsuario, codigoMenu, favoritar }
  });
}

export function getMontagemMenuFavoritos(codigoUsuario) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v1/Menus/Favoritos`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoUsuario
    }
  });
}
