import { CheckIETOantigo } from './CheckIETOantigo';
import { CharToInt } from './utils';

export function CheckIETO(ie) {
  return CheckIETONova(ie) || CheckIETOantigo(ie);
}
function CheckIETONova(ie) {
  if (ie.length !== 9) {
    return false;
  }
  var nro = new Array(9);
  var b = 9;
  var soma = 0;
  for (var i = 0; i <= 8; i++) {
    nro[i] = CharToInt(ie.charAt(i));

    if (i !== 8) {
      soma = soma + nro[i] * b;
      b--;
    }
  }
  var ver = soma % 11;
  var dig = 0;
  if (ver < 2) dig = 0;
  if (ver >= 2) dig = 11 - ver;
  return dig === nro[8];
} //to
