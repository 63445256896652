import React, { Component } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import {
  getUrl,
  getNomePagina,
  getDadosSellerPop
} from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_7320;

export class SpedUnificacaoPisCofins extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={'icon-lx-receipt Informações#Fiscais SPED-PIS/COFINS Unificação'}>
        <div className='row'>
          <Iframe
            url={
              getUrl() + '/SpedUnificacaoPisCofins.aspx?' + getDadosSellerPop(PATH_MENU)
            }
            className='iframePop'
          />
        </div>
      </LinxPostos>
    );
  }
}