import React, { useCallback, useEffect, useState } from 'react';
import { toCurrency, toFormat } from './inputCurrency.service';

import { formatStringToCurrency } from './../../shared/utils/Utils';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useRef } from 'react';

export const InputCurrency = forwardRef((props, ref) => {
  const { onChange, max } = props;
  const [value, setValue] = useState(props.value ? formatStringToCurrency(props.value) : '');
  const [money, setMoney] = useState(0);
  const input = useRef();

  var onFocus = useCallback(e => {
    e.target.select();
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      setFocus: () => {
        if (input.current) {
          input.current.focus();
        }
      },
      setNewValueAndSelect: v => {
        if (input.current) {
          const formated = formatStringToCurrency(v);
          input.current.value = formated;
          setMoney(v);
          if (onChange) {
            onChange(formated, v);
          }
          input.current.select();
        }
      },
      getMoney: () => {
        return money;
      },
    }),
    [money, onChange],
  );

  useEffect(() => {
    setValue(props.value ? formatStringToCurrency(props.value) : '');
  }, [props.value]);

  const handleChange = useCallback(
    event => {
      const formated = toFormat(event.target.value);
      let valueAsCurrency = toCurrency(event.target.value);
      if (!event.target.value) {
        if (onChange) {
          event.persist();
          onChange(event, 0);
        }
        setValue('');
        return;
      }
      if (valueAsCurrency > max) {
        valueAsCurrency = max;
        const maxFormated = toFormat(max.toFixed(2).toString());
        setValue(maxFormated);
        setMoney(max);
        if (onChange) {
          event.persist();
          onChange(event, max);
        }
        return;
      }

      setValue(formated);
      setMoney(valueAsCurrency);
      if (onChange) {
        event.persist();
        onChange(event, valueAsCurrency);
      }
    },
    [onChange, max],
  );

  return (
    <input
      type="text"
      id={props.id}
      pattern="\d*"
      ref={input}
      placeholder={props.placeholder}
      className={props.className}
      disabled={props.disabled}
      onFocus={onFocus}
      onBlur={(e) => {
        if (typeof props.onBlur === 'function') {
          props.onBlur(e, money);
        }
      }}
      onKeyDown={e => {
        if (typeof props.onKeyDown === 'function') {
          props.onKeyDown(e);
        }
      }}
      step="0.01"
      value={value}
      onChange={handleChange}
    />
  );
});
