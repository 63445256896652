import React from "react";
import styled from "styled-components";
import styles from './Spinner.module.scss';

const Spinner = () => (
  <svg className={`${styles.Spinner} Spinner`} viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </svg>
);

// const StyledSpinner = styled.svg`
//   animation: rotate 2s linear infinite;
//   width: 65px;
//   height: 65px;

//   & .path {
//     stroke: #5c2f90;
//     stroke-linecap: round;
//     animation: dash 1.5s ease-in-out infinite;
//   }

//   @keyframes rotate {
//     100% {
//       transform: rotate(360deg);
//     }
//   }
//   @keyframes dash {
//     0% {
//       stroke-dasharray: 1, 150;
//       stroke-dashoffset: 0;
//     }
//     50% {
//       stroke-dasharray: 90, 150;
//       stroke-dashoffset: -35;
//     }
//     100% {
//       stroke-dasharray: 90, 150;
//       stroke-dashoffset: -124;
//     }
//   }
// `;

export default Spinner;
