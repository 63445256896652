import { UrlLinxCoreService } from "../core/urlLinxCore.service";

export class BuscarClienteService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(arg) {
    try {
      const response = await this.urlLinxCore.get(
        `/Cliente/Buscar?args=${arg}&limit=10`
      );
      return response.data.data;
    } catch (error) {
      return error.response.data;
    }
  }
}
