import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { selectNovaOs, useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';

export function DropBoxStatus() {
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(selectNovaOs);
  const status = useSelector(selectConsignadoComodatoSharedData).status;
  const dispatch = useDispatch();

  const handlerStatus = useCallback(
    e => {
      const it = status.find(x => x.id === parseInt(e.target.value));
      dispatch(changeValue({ fieldName: 'status', value: it }));
    },
    [dispatch, changeValue, status],
  );

  return (
    <>
      <Form.Label htmlFor="status" className="text-primary">
        {'Status'}
        <span className="text-danger">*</span>
      </Form.Label>
      <select
        onBlur={e => dispatch(changeBlur({ fieldName: 'status' }))}
        onChange={handlerStatus}
        value={((values || {}).status || {}).id || ''}
        id="status"
        name="status"
        className="form-control"
      >
        {(status || []).map((status, index) => (
          <option key={index} value={status.id}>
            {status.nome}
          </option>
        ))}
      </select>
      {(touched || {}).status && (errors || {}).status && <div className="text-danger validationError">{(errors || {}).status || ''}</div>}
    </>
  );
}
