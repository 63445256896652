import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import moment from 'moment';

import styles from './NotasFiscais.module.scss';
import { notasFiscaisService } from './NotasFiscais.service';

import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina, sortList, emptyText, scrollToRef, getErrorMessage, sleep } from '../../../../shared/utils/Utils';

import { DateBox, DropDownBox, TextBox } from 'devextreme-react';
import { locale, loadMessages } from 'devextreme/localization';
import { DataGrid, Pager, SearchPanel, Grouping, GroupPanel, ColumnChooser, Export, Paging, ColumnFixing, Sorting, Summary, TotalItem } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';

import ptMessages from '../../../../../assets/js/pt.json';
import LinxListAsContent from '../../../../components/linx/ListAsContent/LinxListAsContent';

export function NotasFiscaisReport(props){

    const PATH_MENU = menus.COD_80555; //// AcSiCod do Menu
    let validationErrorMessage = '';
    const refResultados = useRef(null);

    const [showPreReports, setShowPreReports] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [reports, setReports] = useState([]);
    const [searching, setSearching] = useState(false);
    const [preSearching, setPreSearching] = useState(false);
    const [chosenReport, setChosenReport] = useState([]);
    const [preReportId, setpreReportId] = useState(0);
    const [disabledSearch, setDisabledSearch] = useState(false);
    const [syncLists, setSyncLists] = useState({});
    const [resultFilters, setResultFilters] = useState(notasFiscaisService.getFiltros());
    const [appliedFilters, setAppliedFilters] = useState(notasFiscaisService.getFiltros());
    const [reportColumns] = useState(notasFiscaisService.getReportColumns());
    const [reportData, setReportData] = useState([]);
    
    //// combos 
    const [tipoOperacoes, setTipoOperacoes] = useState([]);
    const [operacoes, setOperacoes] = useState([]);
    const [situacoesDocFiscal, setSituacoesDocFiscal] = useState([]);
    const [situacoesManifestacao, setSituacoesManifestacao] = useState([]);
    const [pessoas, setPessoas] = useState([]);

    const [operacoesFiltradas, setOperacoesFiltradas] = useState([]);

    useEffect(() => {
        getReports();
        loadMessages(ptMessages);
        locale(navigator.language);
    }, []);

    //// métodos combos
    const getTipoOperacoes = async () => {
        const response = await notasFiscaisService.getTipoOperacoes();
        if (response.length) setTipoOperacoes(sortList(response, 'descricao'));
    };

    const getOperacoes = async () => {
        const response = await notasFiscaisService.getOperacoes();
        if (response.length) setOperacoes(sortList(response, 'descricao'));
    };

    const getSituacoesDocFiscal = async () => {
        const response = await notasFiscaisService.getSituacoesDocFiscal();
        if (response.length) setSituacoesDocFiscal(sortList(response, 'descricao'));
    };

    const getSituacoesManifestacao = async () => {
        const response = await notasFiscaisService.getSituacoesManifestacao();
        if (response.length) setSituacoesManifestacao(sortList(response, 'descricao'));
    };

    const getPessoas = async () => {
        const response = await notasFiscaisService.getPessoas();
        if (response.length) setPessoas(sortList(response, 'nome'));
    };

    //// métodos filtros
    const clearfilter = (event) => {
        let prop = event.target.id;

        setAppliedFilters(filters => {
            return {
                ...filters,
                [prop]: notasFiscaisService.getFiltros()[prop]
            }
        });

        setSyncLists(lists => {
            return { 
                ...lists,
                [prop]: notasFiscaisService.getFiltros()[prop]
            }
        });
    };

    const clearFilters = () => {
        setAppliedFilters(notasFiscaisService.getFiltros());
        clearSyncLists();

        ////específico para este relatório
        setOperacoesFiltradas([]);
    };

    const onDateValueChanged = ((event) => {    
        if (!moment(event.value).isValid()) {
            setDisabledSearch(true);
            notify('Data inválida.', 'error', 2000, 'top');
        } else {
            setAppliedFilters(filters => {
                return {
                    ...filters,
                    [event.element.id]: event.element.id === 'dataFim' 
                        ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59'
                        : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00'
                }
            });
            setDisabledSearch(false);
        }
    });

    const clearSyncLists = () => {
        let retorno = {};
        for(let prop in notasFiscaisService.getFiltros()){
            retorno[prop] = notasFiscaisService.getFiltros()[prop];
        }

        setSyncLists(retorno);
    };
    
    const syncSelection = (e, prop) => {
        setSyncLists(lists => {
            return {
                ...lists,
                [prop]: e.value || notasFiscaisService.getFiltros()[prop]
            }
        });

        clearfilter({target: {id: prop}});
    };

    const onSyncSelection = (e, prop, id) => {
        setSyncLists(lists => {
            return {
                ...lists,
                [prop]: e || notasFiscaisService.getFiltros()[prop]
            }
        });

        setAppliedFilters(filters => {
            return {
                ...filters,
                [prop]: Array.isArray(e)? e.map(item => { return item[id]; }) : e
            }
        });
    };

    //// específicos para ajustar operações de acordo com o tipo, neste relatório
    const onSyncTipoOperacoesSelection = (e, prop, id) => {
        onSyncSelection(e, prop, id);
        clearfilter({target: {id: 'operacoes'}});

        switch (e.length) {
            case 0:
                setOperacoesFiltradas([]);
                break;
            case 1:
                setOperacoesFiltradas(operacoes.filter(operacao =>{ return operacao.tipo === e[0].codigo}));
                break;
            default:
                setOperacoesFiltradas(operacoes);
                break;
        }
    };

    const syncSelectionTipoOperacoes = (e, prop) => {
        syncSelection(e, prop);
        setOperacoesFiltradas([]);
        clearfilter({target: {id: 'operacoes'}});
    };
    //// fim - específicos para ajustar operações de acordo com o tipo, neste relatório

    //// métodos de pesquisa
    const toggleSearch = () => {
        //// chamada de métodos combos
        getTipoOperacoes();
        getOperacoes();
        getSituacoesDocFiscal();
        getSituacoesManifestacao();
        getPessoas();
        
        clearSyncLists();
        clearFilters();
        setShowPreReports(false);
        setShowSearch(true);
    };

    const getReports = async () => {
        const reponse = notasFiscaisService.getReports();

        if(reponse.length) { 
            setReports(reponse);
        } else {
            toggleSearch(); //se não tem relatorios sugeridos, vai direto para busca personalizada
        }
    };

    const setReport = (report) => {
        setShowSearch(false);
        if (chosenReport.id === report.id) {
            setShowPreReports(true);
        } else {
            setChosenReport(report);
            setShowPreReports(true);
        }
    };

    //// validações para envio, editar de acordo com report
    const isValidFilters = (filters) => {
        validationErrorMessage = '';

        if (!filters) {
            validationErrorMessage = 'Informe ao menos um filtro para pesquisa.';
            return false;
        }

        if (moment(filters.dataInicio).isAfter(filters.dataFim)) {
            validationErrorMessage = 'A data final deve ser maior que a data inicial.';
            return false;
        }
        
        return true;
    }
    
    const getPreReport = async (preReport) => {
        setpreReportId(preReport.id);
        setPreSearching(true);

        await find(preReport.filters);
        
        setPreSearching(false);
    };

    const find = async (filters) => {
        setSearching(true);

        if(!isValidFilters(filters)) {
            notify(validationErrorMessage, 'error', 2000, 'top');
            setSearching(false);
            return;
        }

        const response = await notasFiscaisService.find(filters);

        if (response.notificationLevel === 1) {
            if(response.data.length){
                setReportData(response.data);
                setResultFilters(filters);
                await sleep(200);
                scrollToRef(refResultados);
            } else {
                setReportData([]);
                setResultFilters(notasFiscaisService.getFiltros());
                notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
            }
        } else {
            setReportData([]);
            setResultFilters(notasFiscaisService.getFiltros());
            notify(getErrorMessage(response), 'error', 2000, 'top');
        }
        setSearching(false);
    };

    return(
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <div className={`${styles.notasFiscaisReport} mt-3`}>
                <div className="shadow-sm bg-white p-3">
                    <div className={`${styles.filters} ${showPreReports ? styles.expanded : ''} ${showSearch ? styles.search : ''}`}>
                        <div className="container-fluid">
                            <div className={`${styles.filtersrWrapper} row`}>
                                <div className="col-md-12">
                                    <h2>FILTROS</h2>
                                    {reports.length ? <hr /> : <></>}
                                    <div className={styles.buttonWrapper}>
                                        {reports.map(report => (
                                            <div className={styles.reportButton} key={report.id}>
                                                <button className={`${report.type === 1 ? 'btn-secondary' : ''} ${report.type === 2 ? 'btn-light' : ''} btn btn-primary`}
                                                    onClick={report.type === 2 ? toggleSearch : () => setReport(report)}
                                                    disabled={searching}
                                                >
                                                    <i className={report.icon}></i>
                                                </button>
                                                <p>{report.title}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {showPreReports &&
                                <div className={`${styles.preReportsWrapper} ${showPreReports ? styles.fadeIn : '' }`}>
                                    {chosenReport.type === 1 ?
                                        <h3>{chosenReport.title}</h3>
                                    :
                                        <h3>FILTROS SUGERIDOS DE {chosenReport.title}</h3>
                                    }
                                    <hr />
                                    {chosenReport.title &&
                                        <div className={`${styles.optionButtonWrapper} ${showPreReports ? styles.fadeIn: '' }`}>
                                            {chosenReport.preReports.map(preReport => (
                                                <div className={styles.reportButton} key={preReport.id}>
                                                    <button className={`${preReport.type === 1 ? 'btn-secondary' : '' } ${preReport.type === 2 ? 'btn-light' : '' } btn btn-primary`} 
                                                        onClick={() => getPreReport(preReport)} disabled={preSearching}>
                                                        {' '}
                                                        {preSearching && preReport.id === preReportId ? (
                                                            <svg className={styles.spinner} viewBox="0 0 50 50">
                                                                <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                                </circle>
                                                            </svg>
                                                        ) : (
                                                            <>
                                                            {preReport.icon ?
                                                            <i className={preReport.icon}></i>
                                                            :
                                                            <span>{preReport.text}</span>
                                                            }
                                                            </>
                                                        )}
                                                    </button>
                                                    {preSearching && preReport.id === preReportId ?
                                                        <p>PESQUISANDO...</p>
                                                        :
                                                        <p>{preReport.title}</p>
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            }        
                            {showSearch &&
                                <div className={`${showSearch ? styles.fadeIn : '' } ${styles.searchWrapper}`}>
                                    <hr className={`${showSearch ? styles.fadeIn : '' }`}/>
                                    <div className="row">
                                        <div className={`col-md-2 form-group mb-1`}>
                                            <div className={styles.dateWrapper}>
                                                <div className={styles.datebox}>
                                                    <span>De</span>
                                                    <DateBox id="dataInicio" type="date"
                                                    onValueChanged={onDateValueChanged} disabled={searching} value={appliedFilters.dataInicio} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-md-2 form-group mb-1`}>
                                            <div className={styles.dateWrapper}>
                                                <div className={styles.datebox}>
                                                    <span>Até</span>
                                                    <DateBox id="dataFim" type="date"
                                                    onValueChanged={onDateValueChanged} disabled={searching} value={appliedFilters.dataFim} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`${styles.dropDownBox} col-md-2 form-group mb-1`}>
                                            <span>Tipo de Operação</span>
                                            <DropDownBox
                                            value={syncLists.tipoOperacoes}
                                            valueExpr={item => {return item;}}
                                            displayExpr='descricao'
                                            placeholder="Selecione..."
                                            showClearButton={true}
                                            disabled={searching}
                                            dataSource={tipoOperacoes}
                                            onValueChanged={e => syncSelectionTipoOperacoes(e, 'tipoOperacoes')}
                                            contentRender={() => {
                                                return (
                                                    <LinxListAsContent
                                                    dataSource={tipoOperacoes}
                                                    selectedItems={syncLists.tipoOperacoes}
                                                    methodThatSets={e => onSyncTipoOperacoesSelection(e, 'tipoOperacoes', 'codigo')}
                                                    displayExpr={item => {return item.descricao;}}
                                                    searchExpr={item => {return item.descricao;}}
                                                    searchEnabled={false}
                                                    />
                                                );
                                            }}
                                            />
                                        </div>
                                        <div className={`${styles.dropDownBox} col-md-6 form-group mb-1`}>
                                            <span>Operação</span>
                                            <DropDownBox
                                            value={syncLists.operacoes}
                                            valueExpr={item => {return item;}}
                                            displayExpr='descricao'
                                            placeholder="Selecione..."
                                            showClearButton={true}
                                            disabled={searching || operacoesFiltradas.length < 1}
                                            dataSource={operacoesFiltradas}
                                            onValueChanged={e => syncSelection(e, 'operacoes')}
                                            contentRender={() => {
                                                return (
                                                    <LinxListAsContent
                                                    dataSource={operacoesFiltradas}
                                                    selectedItems={syncLists.operacoes}
                                                    methodThatSets={e => onSyncSelection(e, 'operacoes', 'codigo')}
                                                    displayExpr={item => {return item.descricao;}}
                                                    searchExpr={item => {return item.descricao;}}
                                                    searchEnabled={true}
                                                    />
                                                );
                                            }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`${styles.dropDownBox} col-md-3 form-group mb-1`}>
                                            <span>Situação do documento fiscal</span>
                                            <DropDownBox
                                            value={syncLists.situacoesDocFiscal}
                                            valueExpr={item => {return item;}}
                                            displayExpr='descricao'
                                            placeholder="Selecione..."
                                            showClearButton={true}
                                            disabled={searching}
                                            dataSource={situacoesDocFiscal}
                                            onValueChanged={e => syncSelection(e, 'situacoesDocFiscal')}
                                            contentRender={() => {
                                                return (
                                                    <LinxListAsContent
                                                    dataSource={situacoesDocFiscal}
                                                    selectedItems={syncLists.situacoesDocFiscal}
                                                    methodThatSets={e => onSyncSelection(e, 'situacoesDocFiscal', 'descricao')}
                                                    displayExpr={item => {return item.descricao;}}
                                                    searchExpr={item => {return item.descricao;}}
                                                    searchEnabled={false}
                                                    />
                                                );
                                            }}
                                            />
                                        </div>
                                        <div className={`${styles.dropDownBox} col-md-3 form-group mb-1`}>
                                            <span>Situação manifestação</span>
                                            <DropDownBox
                                            value={syncLists.situacoesManifestacao}
                                            valueExpr={item => {return item;}}
                                            displayExpr='descricao'
                                            placeholder="Selecione..."
                                            showClearButton={true}
                                            disabled={searching}
                                            dataSource={situacoesManifestacao}
                                            onValueChanged={e => syncSelection(e, 'situacoesManifestacao')}
                                            contentRender={() => {
                                                return (
                                                    <LinxListAsContent
                                                    dataSource={situacoesManifestacao}
                                                    selectedItems={syncLists.situacoesManifestacao}
                                                    methodThatSets={e => onSyncSelection(e, 'situacoesManifestacao', 'descricao')}
                                                    displayExpr={item => {return item.descricao;}}
                                                    searchExpr={item => {return item.descricao;}}
                                                    searchEnabled={false}
                                                    />
                                                );
                                            }}
                                            />
                                        </div>
                                        <div className={`${styles.dropDownBox} col-md-6 form-group mb-1`}>
                                            <span>Pessoa (destinatário ou emitente)</span>
                                            <DropDownBox
                                            value={syncLists.pessoas}
                                            valueExpr={item => {return item;}}
                                            displayExpr='nome'
                                            placeholder="Selecione..."
                                            showClearButton={true}
                                            disabled={searching}
                                            dataSource={pessoas}
                                            onValueChanged={e => syncSelection(e, 'pessoas')}
                                            contentRender={() => {
                                                return (
                                                    <LinxListAsContent
                                                    dataSource={pessoas}
                                                    selectedItems={syncLists.pessoas}
                                                    methodThatSets={e => onSyncSelection(e, 'pessoas', 'id')}
                                                    displayExpr='nome'
                                                    searchExpr='nome'
                                                    searchEnabled={true}
                                                    />
                                                );
                                            }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className={`${styles.input} col-md-3 form-group mb-1`}>
                                            <span>Número</span>
                                            <TextBox
                                            value={syncLists.numero}
                                            showClearButton={true}
                                            disabled={searching}
                                            valueChangeEvent="change keypress"
                                            maxLength={9}
                                            onValueChanged={event => {
                                                onSyncSelection(event.value, 'numero');
                                            }}
                                            onKeyPress={e => {
                                                const event = e.event,
                                                str = String.fromCharCode(event.keyCode);
                                                if (!/[0-9]/.test(str)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                        </div>
                                        <div className={`${styles.input} col-md-3 form-group mb-1`}>
                                            <span>Chave de acesso</span>
                                            <TextBox
                                            value={syncLists.chaveAcesso}
                                            showClearButton={true}
                                            disabled={searching}
                                            valueChangeEvent="change keypress"
                                            maxLength={44}
                                            onValueChanged={event => {
                                                onSyncSelection(event.value, 'chaveAcesso');
                                            }}
                                            onKeyPress={e => {
                                                const event = e.event,
                                                str = String.fromCharCode(event.keyCode);
                                                if (!/[0-9]/.test(str)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                            />
                                        </div> 
                                    </div>
                                
                                    <footer className="text-right mt-4">
                                        <button className="btn btn-light" onClick={clearFilters} disabled={searching}> Limpar filtros </button>
                                        <button className="btn btn-primary" onClick={() => find(appliedFilters)} disabled={disabledSearch || searching}>
                                            {' '}
                                            {searching ? (
                                            <>
                                            <svg className={styles.spinner} viewBox="0 0 50 50">
                                                <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5">
                                                </circle>
                                            </svg>
                                            PESQUISANDO...
                                            </>
                                            ) : (
                                            `PESQUISAR`
                                            )}
                                        </button>
                                    </footer>
                                </div>
                            }                                                    
                        </div>
                    </div>
                </div>
            </div>

            {reportData.length > 0 &&
                <div className={`${styles.notasFiscaisResult} mt-3`}>
                    <div className={`${styles.results} shadow-sm p-3 bg-white`}>
                        <h2 ref={refResultados}>RESULTADOS</h2>
                        <div className="row" ref={refResultados}>
                            <div className="col-md-12">
                                <div className={`${styles.appliedFilters}`}>
                                {resultFilters.dataInicio && resultFilters.dataFim &&
                                    <div className={`${styles.pill} ${styles.disabled}`}>
                                        <span>
                                            <i className="icon icon-lx-calendar"></i>
                                            {moment(resultFilters.dataInicio).format("DD/MM/YYYY")} a {moment(resultFilters.dataFim).format("DD/MM/YYYY")}
                                        </span>
                                    </div>
                                }
                                {resultFilters.tipoOperacoes && resultFilters.tipoOperacoes.length > 0 &&
                                    <div className={styles.pill}>
                                        <span>
                                            {`Tipos de operações selecionadas: ${resultFilters.tipoOperacoes.length}`}
                                        </span>
                                    </div>
                                }
                                {resultFilters.operacoes && resultFilters.operacoes.length > 0 &&
                                    <div className={styles.pill}>
                                        <span>
                                            {`Operações selecionadas: ${resultFilters.operacoes.length}`}
                                        </span>
                                    </div>
                                }
                                {resultFilters.situacoesDocFiscal && resultFilters.situacoesDocFiscal.length > 0 &&
                                    <div className={styles.pill}>
                                        <span>
                                            {`Situações documento selecionadas: ${resultFilters.situacoesDocFiscal.length}`}
                                        </span>
                                    </div>
                                }
                                {resultFilters.situacoesManifestacao && resultFilters.situacoesManifestacao.length > 0 &&
                                    <div className={styles.pill}>
                                        <span>
                                            {`Situações manifestação selecionadas: ${resultFilters.situacoesManifestacao.length}`}
                                        </span>
                                    </div>
                                }
                                {resultFilters.pessoas && resultFilters.pessoas.length > 0 &&
                                    <div className={styles.pill}>
                                        <span>
                                            {`Pessoas selecionadas: ${resultFilters.pessoas.length}`}
                                        </span>
                                    </div>
                                }
                                {!emptyText(resultFilters.numero) &&
                                    <div className={styles.pill}>
                                        <span>Número selecionado</span>
                                    </div>
                                }
                                {!emptyText(resultFilters.chaveAcesso) &&
                                    <div className={styles.pill}>
                                        <span>Chave de acesso selecionada</span>
                                    </div>
                                }
                                </div>
                                <hr />
                                <DataGrid dataSource={reportData} defaultColumns={reportColumns} showBorders={true} allowColumnResizing={true}
                                    allowColumnReordering={true} columnResizingMode={'widget'} columnMinWidth={50} rowAlternationEnabled={true}>
                                    <Sorting mode="multiple" />
                                    <GroupPanel visible={true} />
                                    <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                                    <Export enabled={true} fileName="NotasFiscais" />
                                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                                    <Grouping autoExpandAll={true} />
                                    <ColumnFixing enabled={true} />
                                    <Pager showPageSizeSelector={true} allowedPageSizes={[5, 15, 20, 50]} showInfo={true} />
                                    <Paging defaultPageSize={20} />
                                    <Summary>
                                        <TotalItem column="dataEmissao" summaryType="count" />
                                        <TotalItem column="valorDocumento" summaryType="sum" valueFormat={{ type: 'currency', precision: 2}} />
                                    </Summary>
                                </DataGrid>
                                <br />
                            </div>
                        </div>
                    </div>
                </div >
            }

        </LinxPostos>
    );
}

export default withRouter(NotasFiscaisReport);
