import axios from 'axios';
import {
  getToken
} from '../../../authentication/Auth';
import {
  Classificacao,
  none,
  Categoria
} from './Pessoas';
import {
  TOKEN_PASS_AXIOS
} from '../../../shared/constants/Const';
import {
  getUrl,
  getCnpj,
  filledArray
} from '../../../shared/utils/Utils';
import {
  BASE_URL_GATEWAY
} from '../../../shared/constants/GatewayUrlConstant';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

function _classificacoes(
  isCliente,
  categoriaCliente,
  isFornecedor,
  categoriaFornecedor,
  crc,
  nomeContador,
  isFabricante,
  categoriaFabricante,
  isTransportadora
) {
  let classificacoes = [];

  if (isCliente) {
    const classificacao = {
      codigo: Classificacao.Cliente
    };
    if (categoriaCliente !== none)
      classificacao.categoria = {
        codigo: categoriaCliente
      };
    classificacoes.push(classificacao);
  }

  if (isFornecedor) {
    const classificacao = {
      codigo: Classificacao.Fornecedor,
      categoria: {
        codigo: categoriaFornecedor
      }
    };
    if (Number(categoriaFornecedor) === Categoria.Contador) {
      classificacao.categoria.crc = crc;
      classificacao.categoria.nomeContador = nomeContador;
    }
    classificacoes.push(classificacao);
  }

  if (isFabricante) {
    classificacoes.push({
      codigo: Classificacao.Fabricante,
      categoria: {
        codigo: categoriaFabricante
      }
    });
  }
  if (isTransportadora) {
    classificacoes.push({
      codigo: Classificacao.Transportadora
    });
  }
  return classificacoes;
}

function _endereco(
  codigoUF,
  codigoMunicipio,
  endereco,
  numero,
  complemento,
  referencia,
  bairro,
  cep
) {
  return {
    codigoUF: codigoUF,
    codigoMunicipio: codigoMunicipio,
    endereco: endereco,
    numero: numero,
    complemento: complemento,
    referencia: referencia,
    bairro: bairro,
    cep: cep
  };
}

function _contatos(nome, descricao, ddd, numero, email) {
  if (!!nome || !!descricao || !!ddd || !!numero || !!email) {
    return [{
      nome: nome,
      descricao: descricao,
      ddd: ddd,
      numero: numero,
      email: email
    }];
  } else return [];
}

function _creditoFaturamento(
  utilizaFatura,
  obrigaAutorizado,
  tipoPrazo,
  diasFechamento,
  carenciaDiaVencimento,
  limiteCredito,
  emitirNFe,
  enviarEmailFatura,
  bloqueado
) {
  return {
    utilizaFatura: utilizaFatura,
    obrigaAutorizado: obrigaAutorizado,
    tipoPrazo: tipoPrazo,
    diasFechamento: diasFechamento,
    carenciaDiaVencimento: carenciaDiaVencimento,
    limiteCredito: limiteCredito,
    emitirNFe: emitirNFe,
    enviarEmailFatura: enviarEmailFatura,
    bloqueado: bloqueado
  };
}

export function incluiPessoa(
  cnpjCpf,
  inscricaoEstadual,
  inscricaoMunicipal,
  nome,
  apelido,
  isentoICMS,
  contribuinte,
  dataNascimento,
  identidade,
  orgaoEmissor,
  emiteNFe,
  ativo,
  tipo,
  isCliente,
  categoriaCliente,
  isFornecedor,
  categoriaFornecedor,
  crc,
  nomeContador,
  isFabricante,
  categoriaFabricante,
  isTransportadora,
  cep,
  codigoUF,
  codigoMunicipio,
  endereco,
  numero,
  complemento,
  referencia,
  bairro,
  contatoNome,
  contatoDescricao,
  contatoDDD,
  contatoNumero,
  contatoEMail,
  site,
  utilizaFatura,
  obrigaAutorizado,
  tipoPrazo,
  diasFechamento,
  carenciaDiaVencimento,
  limiteCredito,
  emitirNFe,
  enviarEmailFatura,
  bloqueado,
  saldoDisponivel,
  creditoUtilizado,
  autorizados
) {
  return axios.post(`LxApi/v1/Pessoa/Incluir`, {
    cnpjEstabelecimento: getCnpj(),

    cnpjCpf,
    inscricaoEstadual,
    inscricaoMunicipal,

    nome,
    apelido,
    isentoICMS,
    contribuinte,
    dataNascimento,
    identidade,
    orgaoEmissor,
    emiteNFe,
    inativo: !ativo,
    tipo: {
      codigo: tipo
    },

    classificacoes: _classificacoes(
      isCliente,
      categoriaCliente,
      isFornecedor,
      categoriaFornecedor,
      crc,
      nomeContador,
      isFabricante,
      categoriaFabricante,
      isTransportadora
    ),

    endereco: _endereco(
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,
      cep
    ),

    contatos: _contatos(
      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      contatoEMail
    ),

    creditoFaturamento: _creditoFaturamento(
      utilizaFatura,
      obrigaAutorizado,
      tipoPrazo,
      diasFechamento,
      carenciaDiaVencimento,
      limiteCredito,
      emitirNFe,
      enviarEmailFatura,
      bloqueado,
      saldoDisponivel,
      creditoUtilizado
    ),

    autorizados,

    site
  });
}

export function alteraPessoa(
  codigo,
  cnpjCpf,
  inscricaoEstadual,
  inscricaoMunicipal,
  nome,
  apelido,
  isentoICMS,
  contribuinte,
  dataNascimento,
  identidade,
  orgaoEmissor,
  emiteNFe,
  ativo,
  tipo,
  isCliente,
  categoriaCliente,
  isFornecedor,
  categoriaFornecedor,
  crc,
  nomeContador,
  isFabricante,
  categoriaFabricante,
  isTransportadora,
  cep,
  codigoUF,
  codigoMunicipio,
  endereco,
  numero,
  complemento,
  referencia,
  bairro,
  contatoNome,
  contatoDescricao,
  contatoDDD,
  contatoNumero,
  contatoEMail,
  site,
  utilizaFatura,
  obrigaAutorizado,
  tipoPrazo,
  diasFechamento,
  carenciaDiaVencimento,
  limiteCredito,
  emitirNFe,
  enviarEmailFatura,
  bloqueado,
  saldoDisponivel,
  creditoUtilizado,
  autorizados,
  rowVersion
) {
  return axios.put(`LxApi/v1/Pessoa/Alterar`, {
    cnpjEstabelecimento: getCnpj(),

    codigo,

    cnpjCpf,
    inscricaoEstadual,
    inscricaoMunicipal,

    nome,
    apelido,
    isentoICMS,
    contribuinte,
    dataNascimento,
    identidade,
    orgaoEmissor,
    emiteNFe,
    inativo: !ativo,
    tipo: {
      codigo: tipo
    },

    classificacoes: _classificacoes(
      isCliente,
      categoriaCliente,
      isFornecedor,
      categoriaFornecedor,
      crc,
      nomeContador,
      isFabricante,
      categoriaFabricante,
      isTransportadora
    ),

    endereco: _endereco(
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,
      cep
    ),

    contatos: _contatos(
      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      contatoEMail
    ),

    creditoFaturamento: _creditoFaturamento(
      utilizaFatura,
      obrigaAutorizado,
      tipoPrazo,
      diasFechamento,
      carenciaDiaVencimento,
      limiteCredito,
      emitirNFe,
      enviarEmailFatura,
      bloqueado,
      saldoDisponivel,
      creditoUtilizado
    ),

    autorizados,

    site,
    rowVersion
  });
}

export function excluiPessoa(codigo) {
  const response = urlLinxCore.delete(`/pessoa?id=${codigo}`)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

export function getPessoas(consulta) {
  consulta.cnpjEstabelecimento = getCnpj();
  consulta.cadastroCompleto = true;

  return axios.post(`LxApi/v1/Pessoa/Listar/`, {
    query: consulta
  });
}

export function getPessoa(codigo) {
  return axios.get('LxApi/v1/Pessoa/Selecionar/', {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigo
    }
  });
}

export function verifyPessoa(cnpjCpf) {
  return axios.get(`LxApi/v1/Pessoa/Verificar/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      cpfCnpj: cnpjCpf // ATENÇÃO! nessa api não foi mantido o padrão cnpjCpf (está invertido)
    }
  });
}

export function getTipos() {
  return axios.get(`LxApi/v1/Pessoa/MontarComboTipo/`, {
    params: {}
  });
}

export function getClassificacoes() {
  return axios.get(`LxApi/v1/Pessoa/MontarComboClassificacao/`, {
    params: {}
  });
}

export function getCategorias(classificacaoPessoa) {
  return axios.get(`LxApi/v1/Pessoa/MontarComboCategoria/`, {
    params: {
      classificacaoPessoa: classificacaoPessoa
    }
  });
}

export function getPessoasFiltradas(classificacaoPessoa, codigoCategoria) {
  return axios.get(`LxApi/v1/Pessoa/MontarCombo/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      classificacaoPessoa,
      codigoCategoria
    }
  });
}

export function montarComboClientes() {
  return axios.get(`LxApi/v1/Pessoa/MontarCombo`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      classificacaoPessoa: 1
    }
  });
}

//export function montaComboTiposPrazos(todos = false) {
export function montaComboTiposPrazos() {
  return axios.get(`LxApi/v1/Pessoa/MontarComboTipoPrazo`);
  /* const listaTiposPrazos = [];
  listaTiposPrazos.push({
    label: todos ? "Todos" : "Selecione um tipo de prazo",
    value: TiposPrazos.None
  });
  listaTiposPrazos.push({ label: "Semanal", value: TiposPrazos.Semanal });
  listaTiposPrazos.push({ label: "Quinzenal", value: TiposPrazos.Quinzenal });
  listaTiposPrazos.push({ label: "Mensal", value: TiposPrazos.Mensal });
  return listaTiposPrazos; */
}

export function montaComboAutorizados(codigoCliente) {
  return axios.get('LxApi/v1/Pessoa/MontarComboAutorizados', {
    params: {
      cnpjEstabelecimento: getCnpj(),
      codigoCliente
    }
  });
}

export function validaImportacaoPessoas(file) {
  const formData = new FormData();
  formData.append('file', new Blob([file], {
    type: 'text/csv'
  }));
  return axios.post('LxApi/v1/Pessoa/ValidarImportacao', formData, {
    params: {
      cnpjEstabelecimento: getCnpj()
    },
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function salvaImportacaoPessoas(importData) {
  if (filledArray(importData))
    importData.forEach(item => {
      item.cnpjEstabelecimento = getCnpj();
    });
  return axios.post('LxApi/v1/Pessoa/Importar', importData);
}
