import axios from "axios";
import { TOKEN_PASS_AXIOS } from "../shared/constants/Const";
import { getUrl, getCnpj } from "../shared/utils/Utils";
import { SituacoesFatura } from "../pages/financeiro/faturamento-vendas-prazo/RelatorioFaturamentoVendaPrazo";

axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getContasCorrentes(
  dataInicio,
  dataFim,
  codigoCliente,
  codigoTipoPrazo,
  codigoAgrupadorCliente
) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/Listar`, {
    cnpjEstabelecimento: getCnpj(),
    dataInicio,
    dataFim,
    codigoCliente,
    codigoTipoPrazo,
    codigoAgrupadorCliente
  });
}

export function getCupons(dataInicio, dataFim, codigoCliente) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/ListarCupons`, {
    cnpjEstabelecimento: getCnpj(),
    dataInicio,
    dataFim,
    codigoCliente
  });
}

export function geraFaturaCliente(clientes, dataInicio, dataFim) {
  /*   clientes = [
      {
        codigoCliente: 853,
        cupons: [278, 279]
      }
    ] */
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/GerarFaturaCliente`, {
    cnpjEstabelecimento: getCnpj(),
    dataInicio,
    dataFim,
    clientes
  });
}

export function geraNotaFiscalFaturas(faturas) {
  /*   
  faturas: [
		{
			codigoCliente: 853,
			codigoTitulo: 842049,
			numeroTitulo: "00002789"
		},
		{
			codigoCliente: 854,
			codigoTitulo: 842050,
			numeroTitulo: "00002790"
		}
	]
  */
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/GerarNotaFiscalFatura`, {
    cnpjEstabelecimento: getCnpj(),
    faturas
  });
}

export function getFaturados(
  dataInicio,
  dataFim,
  codigoCliente,
  codigoTipoPrazo,
  codigoAgrupadorCliente
) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/ListarFaturasGeradas`, {
    cnpjEstabelecimento: getCnpj(),
    dataInicio,
    dataFim,
    codigoCliente,
    codigoTipoPrazo,
    codigoAgrupadorCliente
  });
}

export function getCuponsFaturados(codigoTitulo) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/ListarCuponsFaturaGerada`, {
    cnpjEstabelecimento: getCnpj(),
    codigoTitulo
  });
}

export function cancelaFaturaCliente(codigoTitulo, rowVersion) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/CancelarFatura`, {
    cnpjEstabelecimento: getCnpj(),
    codigoTitulo,
    rowVersion
  });
}

export function reenviarEmail(codigoTitulo, emails) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/ContaCorrente/ReenviarEmail`, {
    cnpjEstabelecimento: getCnpj(),
    codigoTitulo,
    emails
  });
}

export function montaComboSituacoesFatura() {
  const result = [
    {
      codigo: SituacoesFatura.NaoFaturados,
      descricao: "Não faturados"
    },
    {
      codigo: SituacoesFatura.Faturados,
      descricao: "Faturados"
    }
  ];
  return result;
}