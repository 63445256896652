import React from 'react';

import { locale } from 'devextreme/localization';
import moment from 'moment';
import { PrintStyle } from './PrintStyles';

export function ReciboObs() {
  moment.locale('pt-br');
  locale('pt-br');
  const sty = new PrintStyle();
  return (
    <>
      <h5>OBSERVAÇÕES</h5>
      <table className="w-100" style={sty.borderSolid}>
        <thead>
          <tr style={sty.borderSolid}>
            <th width="100%" className="text-center pl-1 pr-1" style={sty.tableTh}>

            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
          </tr>
          <tr>
            <td className="text-left" style={{ ...sty.borderSolid, height: '15px' }}></td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
