import { CharToInt } from './utils';
export function CheckIEGO(ie) {
  if (ie.length !== 9) return false;
  var s = ie.substring(0, 2);
  if (s === '10' || s === '11' || s === '15') {
    var nro = new Array(9);
    for (var i = 0; i <= 8; i++) nro[i] = CharToInt(ie.charAt(i));
    var n = Math.floor(ie / 10);
    if (n === 11094402) {
      if (nro[8] === 0 || nro[8] === 1) return true;
    }
    var b = 9;
    var soma = 0;
    for (i = 0; i <= 7; i++) {
      soma += nro[i] * b;
      b--;
    }
    i = soma % 11;
    var dig = 0;
    if (i !== 0) {
      if (i === 1) {
        if (n >= 10103105 && n <= 10119997) dig = 1;
        else dig = 0;
      } else dig = 11 - i;
    }
    return dig === nro[8];
  }
}
