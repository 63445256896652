import axios from 'axios';
import { getUrl } from '../../../shared/utils/Utils';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { getToken } from '../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function getGrupoImpostos(cnpjEstabelecimento, descricao, imposto, tipoAliquota, situacao, cadastroCompleto) {
  return axios.post(`LxApi/v1/GrupoImposto/Listar`, {
    query: {
      cnpjEstabelecimento,
      descricao,
      imposto,
      tipoAliquota,
      situacao,
      cadastroCompleto,
    },
  });
}

export function getGrupoImposto(cnpjEstabelecimento, codigoGrupo) {
  return axios.get(`LxApi/v1/GrupoImposto/Selecionar`, {
    params: {
      cnpjEstabelecimento,
      codigoGrupo,
    },
  });
}

export function incluirGrupoImposto(cnpjEstabelecimento, descricao, ativo, temProduto, aliquota) {
  return axios.post(`LxApi/v1/GrupoImposto/Incluir`, {
    cnpjEstabelecimento,
    descricao,
    ativo,
    temProduto,
    aliquota,
  });
}

export function alterarGrupoImposto(cnpjEstabelecimento, descricao, ativo, temProduto, codigo, aliquota, rowVersion) {
  return axios.put(`LxApi/v1/GrupoImposto/Alterar`, {
    cnpjEstabelecimento,
    descricao,
    ativo,
    temProduto,
    codigo,
    aliquota,
    rowVersion,
  });
}

export function excluirGrupoImposto(cnpjEstabelecimento, codigoGrupo) {
  return axios.delete(`/LxApi/v1/GrupoImposto/Excluir`, {
    params: {
      cnpjEstabelecimento,
      codigoGrupo,
    },
  });
}

export function montaComboImpostos(cnpjEstabelecimento) {
  return axios.get(`LxApi/v1/GrupoImposto/MontarComboImpostos`, {
    params: { cnpjEstabelecimento },
  });
}

export function montaComboTipoAliquota() {
  return axios.get(`LxApi/v1/GrupoImposto/MontarComboTipoAliquota`, {});
  //params:{ cnpjEstabelecimento }
}

export function montaComboSituacaoTributaria(codigoTipoImposto, EntradaSaida) {
  return axios.get(`LxApi/v1/SituacaoTributaria/MontarCombo`, {
    params: { codigoTipoImposto, EntradaSaida },
  });
}

export function montaComboTipoOperacao() {
  return axios.get(`LxApi/v1/GrupoImposto/MontarComboTipoOperacao`, {});
  //params:{ codigoTipoImposto,EntradaSaida }
}

export function MontarComboNaturezaReceita(codigoTipoImposto, codigoSituacaoTributaria) {
  return axios.get(`LxApi/v1/GrupoImposto/MontarComboNaturezaReceita`, {
    params: { codigoTipoImposto, codigoSituacaoTributaria },
  });
}
