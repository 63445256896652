import DataGrid, { Column, FilterRow } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import queryString from 'query-string';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import logoEmporio from '../../../../assets/images/linx/logo-emporio-new.png';
import logoSeller from '../../../../assets/images/linx/logo-seller-new.png';
import { getEmpresaCadastrada, login, logout, permissionamento } from '../../../authentication/Auth';
import { hideAlert } from '../../../components/alert/Alert';
import FacebookButton from '../../../components/botao/FacebookButton/FacebookButton';
import InstagramButton from '../../../components/botao/InstagramButton/InstagramButton';
import LinkedinButton from '../../../components/botao/LinkedinButton/LinkedinButton';
import TwitterButton from '../../../components/botao/TwitterButton/TwitterButton';
import YouTubeButton from '../../../components/botao/YouTubeButton/YouTubeButton';
import { handleInputChange, InputText } from '../../../components/formulario/Formulario';
import SuporteButton from '../../../components/Suporte/SuporteButton/SuporteButton';
import { lais } from '../../../services/Lais.service';
import { usuariosRequestService } from '../../../services/RequestServices/UsuariosRequest.service';
import { MAX_LENGTH_PASSWORD } from '../../../shared/constants/Const';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import { baseURL, BASE_URL_AXIOS, getDadosLogin, getErrorMessage, getSystemInfoByTipoSite, maskCpfCnpj, maskedCnpj, TipoSiteOption, validaCNPJ } from '../../../shared/utils/Utils';
import { CadastroEstabelecimento } from '../../configuracoes/empresas/CadastroEstabelecimento';
import { solicitaTrocaSenha } from '../boas-vindas/BoasVindas.service';
import styles from './Login.module.scss';
let logoSite;
if (TIPO_SITE === 'RetaguardaSellerWeb') {
  logoSite = logoSeller;
} else {
  logoSite = logoEmporio;
}

let yearNow = new Date().getFullYear();

export class Login extends Component {
  handleInputChange = handleInputChange.bind(this);
  systemInfo = getSystemInfoByTipoSite();

  state = {
    email: '',
    password: '',
    submitted: false,
    loggingIn: false,
    error: '',
    linxProduct: '',
    systemInfo: {},
    authorizedEmpresas: [],
    showEmpresas: false,
    connecting: false,
    searchFilter: '',
    empresasName: [],
    empresasCNPJ: [],
    modoEsqueciSenha: false,
    urlOrigem: '',
    informarCNPJ: false,
    cnpj: '',
    tipo: null,
    empresaCadastrada: true,
    validado: false,
    operacoesFiltro: {
      between: 'Entre',
      contains: 'Contém',
      endsWith: 'Termina com',
      equal: 'Igual à',
      greaterThan: 'Maior que',
      greaterThanOrEqual: 'Maior ou igual à',
      lessThan: 'Menor que',
      lessThanOrEqual: 'Menor ou igual à',
      notContains: 'Não contém',
      notEqual: 'Diferente de',
      startsWith: 'Começa com',
    },
  };

  async componentDidMount() {
    const systemInfo = getSystemInfoByTipoSite();

    this.setState({ linxProduct: systemInfo.nome, systemInfo: systemInfo });

    let dadosLogin = getDadosLogin();
    if (dadosLogin.url !== BASE_URL_AXIOS) {
      this.setState({ urlOrigem: dadosLogin.url });
      this.setState({ validado: true, empresaCadastrada: dadosLogin.cnpj !== '' });
    } else {
      let urlOrigem = '';
      let tipoSite = '';
      if (this.props.location.state && this.props.location.state.from) {
        const values = queryString.parse(this.props.location.state.from.search);
        urlOrigem = baseURL(values.urlOrigem);
        tipoSite = values.tipoSite;
        this.setState({ urlOrigem: urlOrigem, tipoSite: tipoSite });
      } else {
        if (this.props.location && this.props.location.search) {
          const values = queryString.parse(this.props.location.search);
          urlOrigem = baseURL(values.urlOrigem);
          tipoSite = values.tipoSite;
          this.setState({ urlOrigem: urlOrigem, tipoSite: tipoSite });
        }
      }

      const { data: respDadosAcesso } = await getEmpresaCadastrada(urlOrigem);
      this.setState({ validado: true, empresaCadastrada: respDadosAcesso });
    }

    logout();
    lais.Finalizar();
  }

  componentDidUpdate(_, prevState) {
    const { email } = this.state;

    if (prevState.email !== email) {
      sessionStorage.setItem('user', JSON.stringify(email));
    }
  }

  getLinxProduct = path => {
    if (path.length > 7) {
      this.setState({ linxProduct: path.substring(7).toUpperCase() });
    }
  };

  returnToLogin = () => {
    this.setState({
      authorizedEmpresas: [],
      showEmpresas: false,
      connecting: false,
      loggingIn: false,
      selectedEmpresa: null,
    });
  };

  filterCNPJs = event => {
    this.setState({ searchFilter: event.target.value });
  };

  selectEmpresa = row => {
    const selectedEmpresa = row.selectedRowsData[0];
    // Aqui precisa verificar se a empresa esta adimplente
    if (selectedEmpresa.inadimplente) {
      this.setState({ selectedEmpresa: null });
      // Informe o usuário que a empresa está inadimplente
      notify('Informamos que seu sistema encontra-se Bloqueado', 'error', 5000);

      setTimeout(() => {
        notify(
          {
            contentTemplate: function(e) {
              let span = document.createElement('span');
              let textBeforeLink = document.createTextNode(
                'Entre em contato com nosso time para liberação do acesso.  '
              );
              span.appendChild(textBeforeLink);

              let a = document.createElement('a');
              let linkText = document.createTextNode('Relacionamento ao Cliente');
              a.appendChild(linkText);
              a.title = 'Relacionamento ao Cliente';
              a.href = 'https://wa.me/551121034321';
              a.target = '_blank';
              a.style.color = '#ff9200';
              a.style.fontSize = '20px'
              a.style.cursor = 'pointer';


              span.appendChild(a);

              return span;
            },
          },
          'info',
          10000,
        );
      }, "6000");



      // Entre em contato com nosso time para liberação do acesso. Relacionamento ao Cliente

    } else this.setState({ selectedEmpresa: selectedEmpresa });
  };

  connect = async data => {
    try {
      const { email, password } = this.state;
      this.setState({ connecting: true });

      login(data.token.accessToken);

      const empresa = data.empresas[0];
      const empresaTipoSite = empresa.urLs[0];
      const tema = empresa.urLs.find(
        u =>
          u.tipoSite === TipoSiteOption.RetaguardaPostoPop ||
          u.tipoSite === TipoSiteOption.RetaguardaEmporioPop ||
          u.tipoSite === TipoSiteOption.RetaguardaEmporioPro ||
          u.tipoSite === TipoSiteOption.RetaguardaSellerWeb,
      ).templateTema;
      const share = empresa.urLs.find(
        u =>
          u.tipoSite === TipoSiteOption.RetaguardaPostoPop ||
          u.tipoSite === TipoSiteOption.RetaguardaEmporioPop ||
          u.tipoSite === TipoSiteOption.RetaguardaEmporioPro ||
          u.tipoSite === TipoSiteOption.RetaguardaSellerWeb,
      ).share;

      const empresasAdimplentes = data.empresas.filter(e => !e.inadimplente);

      await permissionamento(
        empresa.cnpj,
        empresa.urLs.find(
          u =>
            u.tipoSite === TipoSiteOption.RetaguardaPostoPop ||
            u.tipoSite === TipoSiteOption.RetaguardaEmporioPop ||
            u.tipoSite === TipoSiteOption.RetaguardaEmporioPro ||
            u.tipoSite === TipoSiteOption.RetaguardaSellerWeb,
        ).url,
        email,
        password,
        empresaTipoSite.logo,
        empresaTipoSite.logoSmall,
        empresaTipoSite.tipoSiteUrl,
        empresasAdimplentes,
        empresaTipoSite.tipoSite,
        tema,
        share,
      );

      sessionStorage.setItem('user', JSON.stringify(email));

      this.changeTheme(tema);

      this.props.history.push('/');
    } finally {
      this.setState({ connecting: false });
    }
  };

  GenerateTokenAndConnect = async () => {
    const { email } = this.state;
    this.setState({ connecting: true });
    try {
      // const tokenData = {
      //   Email: this.state.email,
      //   Senha: this.state.password,
      //   Chave: this.state.selectedEmpresa.chave,
      //   TipoSite: TIPO_SITE
      // };

      if (this.state.selectedEmpresa.inadimplente) {
        // Informe o usuário que a empresa está inadimplente
        notify('Informamos que seu sistema encontra-se Bloqueado', 'error', 5000);
      }

      try {
        //const response = await usuariosRequestService.token(tokenData);
        login(this.state.selectedEmpresa.token);

        const empresaTipoSite = this.state.selectedEmpresa.urLs[0];

        const tema = this.state.selectedEmpresa.urLs.find(
          u =>
            u.tipoSite === TipoSiteOption.RetaguardaPostoPop ||
            u.tipoSite === TipoSiteOption.RetaguardaEmporioPop ||
            u.tipoSite === TipoSiteOption.RetaguardaEmporioPro ||
            u.tipoSite === TipoSiteOption.RetaguardaSellerWeb,
        ).templateTema;
        const share = this.state.selectedEmpresa.urLs.find(
          u =>
            u.tipoSite === TipoSiteOption.RetaguardaPostoPop ||
            u.tipoSite === TipoSiteOption.RetaguardaEmporioPop ||
            u.tipoSite === TipoSiteOption.RetaguardaEmporioPro ||
            u.tipoSite === TipoSiteOption.RetaguardaSellerWeb,
        ).share;

        const empresasAdimplentes = this.state.authorizedEmpresas.filter(e => !e.inadimplente);

        await permissionamento(
          this.state.selectedEmpresa.cnpj,
          this.state.selectedEmpresa.urLs.find(
            u =>
              u.tipoSite === TipoSiteOption.RetaguardaPostoPop ||
              u.tipoSite === TipoSiteOption.RetaguardaEmporioPop ||
              u.tipoSite === TipoSiteOption.RetaguardaEmporioPro ||
              u.tipoSite === TipoSiteOption.RetaguardaSellerWeb,
          ).url,
          this.state.email,
          this.state.password,
          empresaTipoSite.logo,
          empresaTipoSite.logoSmall,
          empresaTipoSite.tipoSiteUrl,
          empresasAdimplentes,
          empresaTipoSite.tipoSite,
          tema,
          share,
        );

        sessionStorage.setItem('user', JSON.stringify(email));

        this.changeTheme(tema);

        this.props.history.push('/');
      } catch (err) {
        notify(getErrorMessage(err), 'error', 5000);
      }
    } finally {
      this.setState({ connecting: false });
    }
  };

  preparaEsqueciSenha = e => {
    e.preventDefault();
    this.setState({ modoEsqueciSenha: true });
  };

  voltaLogin = e => {
    //e.preventDefault();
    this.setState({ modoEsqueciSenha: false, email: '' });
  };

  cellRender = e => {
    return maskedCnpj(e.value);
  };

  changeTheme = theme => {
    const classList = document.body.classList;
    while (classList.length > 0) {
      classList.remove(classList.item(0));
    }

    document.body.classList.add(theme);
  };

  recuperarSenha = async () => {
    var urlBase = 'http://' + window.location.hostname;

    if (urlBase === 'http://localhost') urlBase += ':3000';

    if (this.state.email === '') {
      notify('Informe o usuário.', 'warning', 2000, 'top');
    } else {
      try {
        let resp = await solicitaTrocaSenha(this.state.email, urlBase + '/esqueceu-senha/{chave}/' + this.state.email, this.state.urlOrigem);
        if (resp) {
          if (resp.response) {
            resp = resp;
            hideAlert(this);
            notify(!resp.response ? resp.value : 'Chave de recuperação de senha enviada para o e-mail do usuário.', resp.response ? 'info' : 'error', 2000, 'top');
            this.setState({ loggingIn: false });
            this.setState({
              modoEsqueciSenha: !resp.response,
              email: resp.response ? '' : this.state.email,
            });
            this.props.history.push(`/recuperacao-senha/?urlOrigem=` + this.state.urlOrigem + `&tipoSite=` + this.state.tipoSite);
          } else {
            hideAlert(this);
            notify(resp.value, 'error', 2000, 'top');
          }
        } else {
          hideAlert(this);
          notify('Não obteve retorno da troca de senha', 'error', 2000, 'top');
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          hideAlert(this);
          notify(err.response.data.message, 'error', 2000, 'top');
        } else {
          hideAlert(this);
          notify('Erro na solicitação de troca de senha', 'error', 2000, 'top');
        }
      }
    }
  };

  tryLogin = async e => {
    const { email, password, urlOrigem, cnpj } = this.state;
    this.setState({ loggingIn: true });
    try {
      const loginData = {
        Email: email,
        Senha: password,
        TipoSite: TIPO_SITE,
        UrlOrigem: urlOrigem,
        cnpj: cnpj,
      };
      e.preventDefault();
      if (this.state.modoEsqueciSenha) {
        this.recuperarSenha();
      } else {
        if (!email || !password) {
          notify('Preencha e-mail e senha para continuar!', 'error', 5000);
        } else {
          if (this.state.informarCNPJ && !cnpj) {
            notify('Preencha o CNPJ para continuar!', 'error', 5000);
          } else {
            try {
              let response;

              if (TIPO_SITE === TipoSiteOption.RetaguardaPostoPop) {
                response = await usuariosRequestService.loginV2(loginData);
              } else {
                response = await usuariosRequestService.loginV4(loginData);
              }

              if (response.success) {
                try {
                  const empresas = response.data.empresas;

                  this.setState({
                    authorizedEmpresas: empresas,
                    showEmpresas: empresas.length > 1 ? true : false,
                  });
                  if (this.state.authorizedEmpresas.length === 1) {
                    await this.connect(response.data);
                  }
                } catch (err) {
                  notify('Não foi possível realizar o login, tente novamente.', 'error', 5000);
                }
              } else {
                notify(response.message, 'error', 5000);
              }
            } catch (err) {
              notify(getErrorMessage(err), 'error', 5000);
            }
          }
        }
      }
    } finally {
      this.setState({ loggingIn: false });
    }
  };

  handleEmpresaCadastrada = value => {
    this.setState({ empresaCadastrada: value });
  };

  isVerifying = () => {
    return this.state.tipo === null;
  };

  loginOnBlurHandle = () => {
    // if (
    //   TIPO_SITE !== TipoSiteOption.RetaguardaPostoPop &&
    //   this.state.urlOrigem.length === 0
    // ) {
    //   var informar = !isValidEmail(this.state.email);
    //   this.setState({ informarCNPJ: informar });
    //   if (!informar) {
    //     this.setState({ cnpj: '' });
    //   }
    // }
  };

  render() {
    return this.state.validado && this.state.empresaCadastrada ? (
      <>
        <Helmet>
          <title>{this.systemInfo.nomeLower}</title>
          <meta name="description" content={this.systemInfo.message} />
        </Helmet>
        <div className={`${styles.Login} login`}>
          <div className={`container-fluid ${styles.pageLogin}`}>
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div
                    className={`col-xs-3 col-md-3 col-sm-12 ${TIPO_SITE === TipoSiteOption.RetaguardaPostoPop ? styles.bgPrimary : styles.bgPrimaryEmporio} ${
                      TIPO_SITE === TipoSiteOption.RetaguardaPostoPop ? `bgPrimary` : `bgPrimaryEmporio`
                    } ${styles.columnHeight}
                  ${styles.columnHeightA} d-flex align-items-center`}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-12">
                          {/* <h1 className={`${styles.textBrandLinx} textBrandLinx`} title="Linx">
                          Linx
                        </h1>
                        <h2
                          className={`${styles.textBrandProduct} text-uppercase text-truncate`}
                          title={this.state.linxProduct}
                        >
                          {this.state.linxProduct}
                        </h2> */}

                          <center className="message">{this.state.systemInfo.message}</center>
                        </div>
                        <p className={`${styles.copyright}`}> &copy; Linx {yearNow}. Todos direitos reservados.</p>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className={`${styles.columnHeight} ${styles.columnHeightB} d-flex align-items-center`}>
                      <div className="row">
                        <div className="col-12">
                          <picture className="text-center logotipo center-align">
                            <source media="(max-width: 768px)" srcSet={logoSite} />
                            <source srcSet={logoSite} />
                            <img srcet={logoSite} alt="Logo Linx" />
                          </picture>
                        </div>
                        <div className="col-12">
                          {this.state.showEmpresas ? (
                            <div className={styles.authorizedCNPJs}>
                              <h3>Selecione a empresa para continuar</h3>
                              <div className={styles.empresas}>
                                <DataGrid
                                  dataSource={this.state.authorizedEmpresas}
                                  selection={{ mode: 'single' }}
                                  focusedRowEnabled={true}
                                  showBorders={true}
                                  hoverStateEnabled={true}
                                  keyExpr="chave"
                                  height={250}
                                  width={500}
                                  rowAlternationEnabled={true}
                                  onSelectionChanged={this.selectEmpresa}
                                  noDataText="Não há dados a exibir"
                                >
                                  <FilterRow visible={true} operationDescriptions={this.state.operacoesFiltro} />
                                  <Column dataField="cnpj" caption="CNPJ" cellRender={this.cellRender} width={150} />
                                  <Column dataField="nomeFantasia" caption="Nome fantasia" />
                                </DataGrid>
                              </div>

                              <div className={styles.buttons}>
                                <button type="submit" className="btn btn-light" disabled={this.state.connecting} onClick={this.returnToLogin}>
                                  VOLTAR AO LOGIN
                                </button>
                                <button className="btn btn-primary" disabled={!this.state.selectedEmpresa || this.state.connecting} onClick={this.GenerateTokenAndConnect}>
                                  {this.state.connecting ? (
                                    <>
                                      <svg className={styles.spinner} viewBox="0 0 50 50">
                                        <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                      </svg>
                                      ACESSANDO EMPRESA
                                    </>
                                  ) : (
                                    'CONFIRMAR'
                                  )}
                                </button>
                              </div>
                            </div>
                          ) : (
                            <form className={styles.formLogin} onSubmit={this.tryLogin} autoComplete="off">
                              <div className="form-group">
                                <label htmlFor="email">Usuário</label>
                                <input
                                  type={TIPO_SITE === TipoSiteOption.RetaguardaPostoPop || TIPO_SITE === TipoSiteOption.RetaguardaEmporioPop ? 'email' : undefined}
                                  id="email"
                                  name="email"
                                  value={this.state.email}
                                  onChange={e =>
                                    this.setState({
                                      email: e.target.value,
                                    })
                                  }
                                  onBlur={this.loginOnBlurHandle}
                                  className="form-control"
                                  placeholder="Usuário"
                                  autoFocus
                                  required
                                  disabled={this.state.loggingIn || this.state.connecting}
                                />
                              </div>
                              {!this.state.modoEsqueciSenha ? (
                                <>
                                  <div className="form-group">
                                    <label htmlFor="password">Senha</label>
                                    <input
                                      type="password"
                                      id="password"
                                      name="password"
                                      onChange={e =>
                                        this.setState({
                                          password: e.target.value,
                                        })
                                      }
                                      className="form-control"
                                      placeholder="Senha"
                                      required
                                      disabled={this.state.loggingIn || this.state.connecting}
                                      maxLength={MAX_LENGTH_PASSWORD}
                                    />
                                  </div>
                                  {this.state.informarCNPJ ? (
                                    <>
                                      <div className="form-group">
                                        <label htmlFor="password">CNPJ</label>
                                        {/* <input
                                        id="cnpj"
                                        name="cnpj"
                                        onChange={e =>
                                          this.setState({
                                            cnpj: e.target.value
                                          })
                                        }
                                        className="form-control"
                                        placeholder="CNPJ"
                                        required
                                        disabled={
                                          this.state.loggingIn ||
                                          this.state.connecting
                                        }
                                        maxLength={18}
                                      /> */}
                                        <InputText
                                          value={this.state.cnpj}
                                          name="cnpj"
                                          type="number"
                                          onKeyDown={event => {
                                            if (event.keyCode === 13 && validaCNPJ(this.state.cnpj)) this.cnpjCpfIconeClick();
                                            else if (event.keyCode === 27) this.setState({ cnpj: '' });
                                          }}
                                          format={maskCpfCnpj(this.state.cnpj)}
                                          onChange={this.handleInputChange}
                                          disabled={!this.isVerifying()}
                                          help={`${
                                            this.isVerifying()
                                              ? String(this.state.cnpj).length === 14
                                                ? validaCNPJ(this.state.cnpj)
                                                  ? ''
                                                  : 'Não é um CNPJ válido'
                                                : 'CNPJ (somente números)'
                                              : ''
                                          }`}
                                        />
                                      </div>
                                    </>
                                  ) : null}
                                  <div className="form-group row">
                                    <div className="col text-center">
                                      <span className={`btn ${styles.controlLabel}`} onClick={e => this.preparaEsqueciSenha(e)}>
                                        ESQUECI MINHA SENHA{' '}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                              <div className={styles.buttons}>
                                {this.state.modoEsqueciSenha ? (
                                  <button type="submit" className="btn btn-light" disabled={this.state.loggingIn} onClick={() => this.voltaLogin()}>
                                    {' '}
                                    <>VOLTAR AO LOGIN</>
                                  </button>
                                ) : (
                                  <></>
                                )}
                                {this.state.modoEsqueciSenha ? (
                                  <button type="submit" className="btn btn-primary" disabled={this.state.loggingIn}>
                                    {' '}
                                    {this.state.loggingIn ? (
                                      <>
                                        <svg className={styles.spinner} viewBox="0 0 50 50">
                                          <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                        </svg>
                                        {`RECUPERANDO SENHA...`}
                                      </>
                                    ) : (
                                      `RECUPERAR SENHA`
                                    )}
                                  </button>
                                ) : (
                                  <button type="submit" className="btn btn-secondary btn-block" disabled={this.state.loggingIn}>
                                    {' '}
                                    {this.state.loggingIn ? (
                                      <>
                                        <svg className={styles.spinner} viewBox="0 0 50 50">
                                          <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                        </svg>
                                        REALIZANDO LOGIN...
                                      </>
                                    ) : (
                                      `CONECTAR`
                                    )}
                                  </button>
                                )}
                              </div>
                            </form>
                          )}
                          <div className="row">
                            <div className={`col-12 justify-content-center ${styles.midiasSociais}`}>
                              <FacebookButton />
                              <TwitterButton />
                              <LinkedinButton />
                              <YouTubeButton />
                              <InstagramButton />
                            </div>
                          </div>
                        </div>
                        <SuporteButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : this.state.validado ? (
      <>
        <CadastroEstabelecimento handleEmpresaCadastrada={this.handleEmpresaCadastrada} baseUrl={this.state.urlOrigem} history={this.props.history}></CadastroEstabelecimento>
      </>
    ) : (
      <></>
    );
  }
}

export default withRouter(Login);
