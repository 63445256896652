import { CharToInt } from './utils';
export function CheckIEMA(ie) {
  if (ie.length !== 9) return false;
  var nro = new Array(9);
  for (var i = 0; i <= 8; i++) nro[i] = CharToInt(ie.charAt(i));
  var b = 9;
  var soma = 0;
  for (i = 0; i <= 7; i++) {
    soma += nro[i] * b;
    b--;
  }
  i = soma % 11;
  if (i <= 1) var dig = 0;
  else dig = 11 - i;
  return dig === nro[8];
}
