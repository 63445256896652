import React, { useState, useEffect, useRef } from 'react';
import { LoadPanel } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { NotificationLevel } from '../../../../../shared/constants/Const';
import { setupFacilService } from '../../SetupFacil.service.js';
import SetupFacilConfirmacaoGridProdutos from './SetupFacilConfirmacaoGridProdutos';
import SetupFacilConfirmacaoGridProdutosMerge from './SetupFacilConfirmacaoGridProdutosMerge';

import styles from './SetupFacilConfirmacao.module.scss';
import { ModalImportacaoResultado } from '../SetupFacil_Resultado/ModalImportacaoResultado';

export default function SetupFacilConfirmacao({ ...props }) {
  const [produtosComDiferenca, setProdutosComDiferenca] = useState([]);
  const [produtosInvalidos, setProdutosInvalidos] = useState([]);
  const [produtosValidos, setProdutosValidos] = useState([]);
  const [camposDiferentes, setCamposDiferentes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dadosResultadoConfirmacao, setDadosResultadoConfirmacao] = useState([]);
  const [modalImportacaoResultado, setModalImportacaoResultado] = useState(false);
  const gridMergeRef = useRef();
  const gridInvalidosRef = useRef();
  const gridValidosRef = useRef();

  useEffect(() => {
    async function getDados() {
      props.handleNextStep(() => () => confirmaImportacao());
      props.awaitIsCompletedStepUpdate(true);
      props.messageStepNotCompletedUpdate(
        'Não existem produtos válidos para importar. Selecione os produtos para continuar.'
      );

      setIsLoading(true);

      console.log(props.dados.ajusteProdutos.produtos);
      const resp = await setupFacilService.GetPreparacaoProdutosImportacao(
        props.dados.ajusteProdutos.produtos
      );

      if (resp.notificationLevel === NotificationLevel.Success) {
        setProdutosComDiferenca(
          tratarProdutosComDiferenca(resp.data.produtosComDiferenca)
        );
        setProdutosInvalidos(tratarProdutos(resp.data.produtosInvalidos));
        setProdutosValidos(tratarProdutos(resp.data.produtosValidos));
      } else {
        notify(resp.message, 'error', 2000, 'top');
      }
      setIsLoading(false);
    }

    getDados();
  }, []);

  const preparaProdutosMergeImportar = () => {
    let dadosMerge = gridMergeRef.current.props.dataSource;
    return dadosMerge.filter(
      d => d.isSelected === true && d.possuiCadastro === true
    );
  };

  const apresentaResultadoConfirmacao = resultado => {
    let dadosRetorno = [];
    resultado.forEach(res => {
      dadosRetorno.push({
        codigo: res.produto.codigo,
        descricao: res.produto.descricao,
        mensagem: res.mensagem,
        status: res.status
      })
    });
    setDadosResultadoConfirmacao(dadosRetorno);
    setModalImportacaoResultado(true);
  };

  const handleCloseModal = () => {
    setModalImportacaoResultado(false);
    props.setCurrentStep(0);
  };

  async function confirmaImportacao() {
    const dadosValidos = gridValidosRef.current.props.dataSource;

    const dadosMergeSelecionados = preparaProdutosMergeImportar();

    const produtosImportacao = dadosValidos.concat(dadosMergeSelecionados);

    props.setConfirming(true);

    const resp = await setupFacilService.RealizaImportacao(produtosImportacao);

    if (resp.notificationLevel === NotificationLevel.Success) {
      props.setConfirming(false);
      apresentaResultadoConfirmacao(resp.data);
    } else {
      props.setConfirming(false);
      notify(resp.message, 'error', 2000, 'top');
    }
  }

  const tratarProdutosComDiferenca = dados => {
    const campos = dados.map(info => {
      return {
        codigo: info.produtoSistema.codigo,
        campos: info.camposDiferentes
      };
    });

    setCamposDiferentes(campos);

    const produtosImportacao = dados.map(info => {
      return {
        ...info.produtoImportacao,
        fonte: 'Importação',
        possuiCadastro: true,
        isSelected: false,
        estruturaMercadologicaDescricao: `${info.produtoImportacao.grupo} | ${info.produtoImportacao.subGrupo} | ${info.produtoImportacao.artigo}`,
      };
    });

    const produtosSistema = dados.map(info => {
      return {
        ...info.produtoSistema,
        fonte: 'Sistema',
        isSelected: false,
        estruturaMercadologicaDescricao: `${info.produtoSistema.grupo} | ${info.produtoSistema.subGrupo} | ${info.produtoSistema.artigo}`,
      };
    });

    return produtosImportacao.concat(produtosSistema);
  };

  const tratarProdutos = dados => {
    return dados.map(info => {
      return {
        ...info,
        estruturaMercadologicaDescricao: `${info.grupo} | ${info.subGrupo} | ${info.artigo}`,
      };
    });
  };

  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={isLoading}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div className={styles.container}>
        <h1>Confirme os dados para importação</h1>
        <span className={`${styles.subTitle}`}>
          Verifique a situação dos produtos abaixo e caso seja necessário
          retorne ao passo anterior para realizar os ajustes
        </span>
        <br />
        <br />
        <SetupFacilConfirmacaoGridProdutosMerge
          dados={produtosComDiferenca}
          camposDiferentes={camposDiferentes}
          gridRef={gridMergeRef}
          unidadesMedida={props.dados.ajusteProdutos.unidadesMedida}
          gridListas={props.dados.ajusteProdutos.gridListas}
          isLoading={isLoading}
        />
        <br />
        <SetupFacilConfirmacaoGridProdutos
          isImportacao={false}
          dados={produtosValidos}
          gridRef={gridValidosRef}
          unidadesMedida={props.dados.ajusteProdutos.unidadesMedida}
          gridListas={props.dados.ajusteProdutos.gridListas}
        />
        <br />
        <SetupFacilConfirmacaoGridProdutos
          isImportacao={true}
          dados={produtosInvalidos}
          gridRef={gridInvalidosRef}
          unidadesMedida={props.dados.ajusteProdutos.unidadesMedida}
          gridListas={props.dados.ajusteProdutos.gridListas}
        />
        <ModalImportacaoResultado
          active={modalImportacaoResultado}
          dadosResultadoConfirmacao={dadosResultadoConfirmacao}
          handleCloseModal={handleCloseModal}
        />
      </div>
    </>
  );
}
