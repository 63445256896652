import { Popup } from 'devextreme-react';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { maskedCnpjCpf } from '../../../../../shared/utils/Utils';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { ApiMarcarClienteAlternativoComoResolvidoService } from '../../../../../services/con-comodato/ApiMarcarClienteAlternativoComoResolvido.service';
import { alert } from 'devextreme/ui/dialog';
import { OsSubmitService } from '../../usercases/osSubmit.service';

export const Modal2Clientes = forwardRef(({ clienteAtual, clienteAlternativo }, ref) => {
  const [show, setShow] = useState(false);
  const [resolverDepois, setResolverDepois] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { changeValue, reload } = useNovaOs();
  const { values } = useSelector(state => state.novaOs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (resolverDepois) return;
    if (clienteAlternativo && !clienteAlternativo.resolvido) {
      setShow(true);
    } else setShow(false);
    // setIsLoading(false);
  }, [show, clienteAlternativo, clienteAtual, resolverDepois]);

  useImperativeHandle(ref, () => {
    return {
      open: () => {
        setShow(true);
      },
      close: () => {
        setShow(false);
      },
    };
  });

  const closePopup = useCallback(() => {
    setShow(false);
    setResolverDepois(true);
  }, []);
  const resolveCliente = useCallback(
    async cAlt => {
      setIsLoading(true);
      try {
        if (!cAlt) {
          await ApiMarcarClienteAlternativoComoResolvidoService.getInstance().execute(clienteAlternativo.id);
          dispatch(changeValue({ fieldName: 'clienteAlternativo', value: { ...clienteAlternativo, resolvido: true } }));
          setIsLoading(false);
          return;
        }
        await ApiMarcarClienteAlternativoComoResolvidoService.getInstance().execute(clienteAlternativo.id);
        const service = new OsSubmitService();
        var savedOs = await service.execute({ ...values, cliente: cAlt.cliente });
        dispatch(reload(savedOs));
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        alert('Erro ao resolver cliente alternativo: ' + e.message, 'Ops!');
      }
    },
    [dispatch, values, reload, clienteAlternativo, changeValue],
  );

  return (
    <Popup
      title="Neste pedido existem dados de 2 possíveis clientes."
      overlayClassName="custom-overlay"
      visible={show}
      hideOnOutsideClick={false}
      width={400}
      height={450}
      onHiding={closePopup}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <div className="text-center">
            <h1>Salvando...</h1>
            <div className="spinner-border" role="status"></div>
          </div>
        </div>
      ) : (
        <div>
          <h1 className="text-center">Confirme para qual cliente deseja faturar o pedido:</h1>
          <hr></hr>
          <div className="row mt-4">
            <div className="col-12" style={{ borderBottom: '1px solid gray' }}>
              <h3>{(clienteAtual || {}).nome}</h3>
              <div className="row align-items-center">
                <div className="col-6 text-center">
                  <p>{maskedCnpjCpf((clienteAtual || {}).cnpjCpf)}</p>
                </div>
                <div className="col-6">
                  <button title="Adicionar" className={`w-100 btn btn-primary`} onClick={() => resolveCliente(null)}>
                    Escolher
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <h3>{((clienteAlternativo || {}).cliente || {}).nome}</h3>
              <div className="row align-items-center">
                <div className="col-6 text-center">
                  <p>{maskedCnpjCpf(((clienteAlternativo || {}).cliente || {}).cnpjCpf)}</p>
                </div>
                <div className="col-6">
                  <button title="Adicionar" className={`w-100 btn btn-primary`} onClick={() => resolveCliente(clienteAlternativo)}>
                    Escolher
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              <button title="Adicionar" className={`w-100 btn btn-secondary`} onClick={closePopup}>
                Resolver Depois
              </button>
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
});
