import React, { Component, Fragment } from 'react';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';

// import Table from 'react-dt';
// import Paper from '@material-ui/core/Paper';
import {
  getCnpj,
  // menuPermiteConsulta,
} from '../../../shared/utils/Utils';
// import Botao from '../../../components/botao/Botao';
// import SectionHeader from '../../../components/section/Header';
import moment from 'moment';
import { getTanques } from '../../cadastro/tanques-bombas-bicos/tanques/Tanques.service';
import { getEstoqueProdutos } from '../../../pages/relatorios/estoque/consulta-estoque/ConsultaEstoqueProduto.service';
import { getConsultaFinanceiro } from '../../../pages/relatorios/financeiros/consulta-financeira/ConsultaFinanceiro.service';
import { getConsultaVendas } from '../../../pages/relatorios/financeiros/consulta-vendas/ConsultaVendas.service';
import Chart, { CommonSeriesSettings, Series, Legend, Tooltip } from 'devextreme-react/chart';
import PieChart, { Label, Connector } from 'devextreme-react/pie-chart';
import DataGrid, { Scrolling, Column, Summary, TotalItem } from 'devextreme-react/data-grid';
import config from 'devextreme/core/config';
import { locale, loadMessages } from 'devextreme/localization';
import ptMessages from '../../../../assets/js/pt.json';
import styles from './ContasReceber.module.scss';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import 'devextreme/dist/css/dx.light.css';
import { useHistory } from 'react-router-dom';
config({
  defaultCurrency: 'BRL',
});

const columnsVendas = [
  { dataField: 'dia', caption: 'Dia', alignment: 'left' },
  {
    dataField: 'vendaBruta',
    caption: 'Venda Bruta',
    format: { type: 'currency', precision: 2 },
  },
  {
    dataField: 'descontos',
    caption: 'Descontos',
    format: { type: 'currency', precision: 2 },
  },
  {
    dataField: 'acrescimos',
    caption: 'Acréscimos',
    format: { type: 'currency', precision: 2 },
  },
  {
    dataField: 'vendasLiquidasCanceladas',
    caption: 'Cancelamentos',
    width: 120,
    format: { type: 'currency', precision: 2 },
  },
  {
    dataField: 'vendaLiquida',
    caption: 'Venda Líquida',
    format: { type: 'currency', precision: 2 },
  },
  {
    dataField: 'qtdeDocsFiscais',
    caption: 'Qtde. Documentos',
    alignment: 'right',
  },
  {
    dataField: 'ticketMedio',
    caption: 'Ticket Médio',
    format: { type: 'currency', precision: 2 },
  },
];

const columnsFormasPagamento = [
  {
    dataField: 'formaPagamento',
    caption: 'Forma de Pagamento',
    alignment: 'left',
  },
  { dataField: 'bandeira', caption: 'Bandeira', alignment: 'left' },
  {
    dataField: 'valor',
    caption: 'Valor',
    format: { type: 'currency', precision: 2 },
  },
];

const renderGridCell = data => {
  return <div style={data.value < 0 ? { color: 'red' } : {}}>{data.value.toLocaleString('br', { minimumFractionDigits: 3 })}</div>;
};

//o TIMER é definido em milissegundos
const TIMER_INTERVAL_DASHBOARD = 900000; //(900 segundos = 15 minutos)

export default class ContasReceber extends Component {
  state = {
    estoqueCombustiveis: [],
    resumoVendas: [],
    resumoFinanceiro: [],
    posicaoEstoque: [],
    combustiveis: 0,
    pesquisando: true,
    popup_bloqueado: false
  };

  timeout = null;

  async doMount(atualizandoTela) {
    console.log('doMount start');

    if (atualizandoTela) this.setState({ pesquisando: true });

    var horaAtual = moment().format('HH:mm');
    this.setState({ horaAtualizacao: horaAtual });
    var dataAtual = moment().format('DD/MM/YYYY');
    this.setState({ diaAtual: dataAtual });

    //console.log("doMount atualizaEstoqueCombustiveis");
    await this.atualizaEstoqueCombustiveis();

    //console.log("doMount atualizaPosicaoEstoque");
    await this.atualizaPosicaoEstoque();

    //console.log("doMount atualizaResumoFinanceiro");
    await this.atualizaResumoFinanceiro();

    //console.log("doMount atualizaResumoVendas");
    await this.atualizaResumoVendas();

    this.setState({ pesquisando: false });

    console.log('doMount end');
  }

  async componentDidMount() {
    console.log('>>>>>>>>>>>>  componentDidMount start');

    console.log(this.props.history);
    // this.props.history.push('/dashboard/horusbi');

    this.doMount();
    this.timeout = setInterval(() => {
      //console.log("componentDidMount setInterval  timer dashboard");
      console.log('this.popup_bloqueado = ' + this.state.popup_bloqueado);

      if (!this.state.popup_bloqueado) {
        console.log('setInterval  timer dashboard atualizando...');
        this.atualizaEstoqueCombustiveis();
        this.atualizaPosicaoEstoque();
        this.atualizaResumoFinanceiro();
        this.atualizaResumoVendas();
      }
    }, TIMER_INTERVAL_DASHBOARD);

    //console.log("componentDidMount this.timeout = " + this.timeout);

    loadMessages(ptMessages);
    locale(navigator.language);

    console.log('componentDidMount end');
  }

  async componentDidUpdate(prevProps) {
    if ((this.props.edit !== prevProps.edit && !this.props.edit) || (this.props.location && this.props.location.key !== prevProps.location.key)) {
      console.log('componentDidUpdate IF');
      this.doMount(this.props.location && this.props.location.key !== prevProps.location.key);
    }
  }

  componentWillUnmount() {
    console.log('***************** componentWillUnmount *****************');
    console.log('clearTimeout - this.timeout = ' + this.timeout);

    clearTimeout(this.timeout);
  }

  onShowPopUp = () => {
    //console.log("onShowPopUp");
    this.setState({ popup_bloqueado: true });
  };

  onHidePopUp = () => {
    //console.log("onHidePopUp");
    this.setState({ popup_bloqueado: false });
  };

  atualizaEstoqueCombustiveis = async () => {
    const { data: t } = await getTanques(getCnpj());
    const tanqueArray = t.result;

    const tanquesAtivos = tanqueArray.filter(tq => !tq.inativo && !tq.excluido);

    const tanqs = tanquesAtivos.map(tq => {
      return {
        numeroTanque: tq.numeroTanque,
        capacidade: tq.capacidade,
        combustivel: tq.produto,
        volumeAtual: tq.volumeAtual,
        capacidadeRestante: tq.volumeAtual < 0 ? tq.capacidade : tq.capacidade - tq.volumeAtual,
      };
    });

    const tqMoOrd = tanqs.sort(function(a, b) {
      if (parseInt(a.numeroTanque) < parseInt(b.numeroTanque)) return -1;
      if (parseInt(a.numeroTanque) > parseInt(b.numeroTanque)) return 1;
      return 0;
    });

    let combustiveis = [];

    tqMoOrd.forEach(tanque => {
      tanque.numeroTanque = `Tanque ${tanque.numeroTanque}`;
      if (!combustiveis.some(x => x === tanque.combustivel)) combustiveis.push(tanque.combustivel);
    });

    const estoqueCombustiveisGrafico = tqMoOrd.map(tq => {
      return {
        numeroTanque: tq.numeroTanque,
        capacidade: tq.capacidade,
        combustivel: tq.produto,
        volumeAtual: tq.volumeAtual < 0 ? 0 : tq.volumeAtual,
        capacidadeRestante: tq.capacidadeRestante,
      };
    });

    this.setState({ combustiveis: combustiveis.length });

    this.setState({ estoqueCombustiveis: tqMoOrd, estoqueCombustiveisGrafico });

    var horaAtual = moment().format('HH:mm');
    this.setState({ horaAtualizacao: horaAtual });
    var dataAtual = moment().format('DD/MM/YYYY');
    this.setState({ diaAtual: dataAtual });
    this.setState({ pesquisando: false });
  };

  atualizaPosicaoEstoque = async () => {
    const { data: estProd } = await getEstoqueProdutos(getCnpj(), moment().format('YYYY-MM-DD HH:mm:ss'), [], [], [], true);
    const estoques = estProd.result;

    if (estoques.length > 0) {
      const estoque = estoques.map(est => {
        return {
          produto: est.descricao,
          estoque: est.estoque.toLocaleString('pt-BR'),
          qtdMinima: est.quantidadeMinima.toLocaleString('pt-BR'),
          qtdMaxima: est.quantidadeMaxima.toLocaleString('pt-BR'),
        };
      });

      this.setState({ posicaoEstoque: estoque });
    }
  };

  atualizaResumoVendas = async () => {
    const { data: consVendas } = await getConsultaVendas(getCnpj(), [], moment().format('YYYY-MM-DD'), [], [], true);
    const consultaVendas = consVendas.result;

    if (consultaVendas.length > 0) {
      const venda = consultaVendas
        .map(v => {
          return {
            data: v.data,
            dia: moment(v.data).format('DD/MM'),
            qtdeDocsFiscais: v.totalDocumento,
            vendaBruta: v.vendasBrutas,
            vendaLiquida: v.vendasLiquidas,
            ticketMedio: v.ticketMedio,
            vendasLiquidasCanceladas: v.vendasLiquidasCanceladas,
            descontos: v.descontos,
            acrescimos: v.acrescimos,
          };
        })
        .sort(function(a, b) {
          return new Date(b.data) - new Date(a.data);
        });
      this.setState({ resumoVendas: venda });
    }
  };

  atualizaResumoFinanceiro = async () => {
    const { data: financ } = await getConsultaFinanceiro(getCnpj(), moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), [], [], [], [], [], true);
    const financeiro = financ.result;

    if (financeiro.length > 0) {
      const fin = financeiro.map(f => {
        return {
          data: moment(f.data).format('DD/MM/YYYY'),
          formaPagamento: `${f.formaPagamento} (R$)`,
          formaPagamentoComBandeira: `${f.formaPagamento} ${f.bandeira !== 'Bandeira não informada' ? f.bandeira : ''}(R$)`,
          bandeira: f.bandeira !== 'Bandeira não informada' ? f.bandeira : null,
          valor: f.valor,
        };
      });

      this.setState({ resumoFinanceiro: fin });
    }
  };

  customizeTooltip = arg => {
    return {
      text: `${arg.percentText}, ${arg.valueText} litros`,
    };
  };

  formatCurrency = num => {
    return new Intl.NumberFormat('pt-br', {
      style: 'currency',
      currency: 'BRL',
    }).format(num);
  };

  customizeTooltipResumoVendas = arg => {
    return {
      text: `${arg.seriesName}: ${arg.seriesName === 'Quantidade de Documentos Fiscais' ? arg.valueText : this.formatCurrency(arg.valueText)}`,
    };
  };

  render() {
    // const {
    //   estoqueCombustiveis,
    //   resumoVendas,
    //   resumoFinanceiro,
    //   posicaoEstoque,
    //   horaAtualizacao,
    //   diaAtual
    // } = this.state;

    return (
      <LinxPostos breadcrumb={'Início'} onShowPopUp={() => this.onShowPopUp()} onHidePopUp={() => this.onHidePopUp()} dashboard={true}>
        {!this.state.pesquisando ? (
          <>
            {TIPO_SITE === 'RetaguardaSellerWeb' && this.state.estoqueCombustiveis.length > 0 ? (
              <Fragment>
                <h1 className="text-center mb-0 pt-3">ESTOQUE DE COMBUSTÍVEIS</h1>
                <div className="flex mb-3">
                  <hr />
                  <div className="row">
                    <div className="col-md-6">
                                        <Chart id="chart" dataSource={this.state.estoqueCombustiveisGrafico} className="bg-white shadow-sm p-3" style={{ minHeight: '432px' }}>
                        <CommonSeriesSettings argumentField="numeroTanque" type="fullstackedbar" />
                        <Series valueField="volumeAtual" name="Volume Atual" color="#5B2E90" />
                        <Series valueField="capacidadeRestante" name="Capacidade restante" color="#FF9200" />

                        <Legend verticalAlignment="top" horizontalAlignment="center" itemTextPosition="right" />
                        <Tooltip enabled={true} customizeTooltip={this.customizeTooltip} format={{ type: 'fixedPoint', precision: 3 }} />
                      </Chart>
                    </div>
                    <div className="col-md-6">
                      <div className="row mb-0">
                        <div className="col-md-2 d-flex flex-column justify-content-between">
                          <div className="row bg-white shadow-sm flex-lg-column align-items-center p-2">
                            <h5>Tanques</h5>
                            <h1 className="display-2 pt-4">{this.state.estoqueCombustiveis.length}</h1>
                          </div>
                          <div className="row bg-white shadow-sm flex-lg-column align-items-center p-2 mb-0">
                            <h5>Combustíveis</h5>
                            <h1 className="display-2 pt-4">{this.state.combustiveis}</h1>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <DataGrid
                            dataSource={this.state.estoqueCombustiveis}
                            //defaultColumns={columnsCombustiveis}
                            showBorders={true}
                            className="bg-white shadow-sm p-3"
                            style={{
                              minHeight: '400px',
                            }}
                            noDataText="Sem dados"
                            rowAlternationEnabled={true}
                          >
                            <Column dataField="numeroTanque" caption="Tanque" alignment="left" />
                            <Column dataField="combustivel" caption="Combustível" alignment="left" />
                            <Column dataField="capacidade" caption="Capacidade Tanque" alignment="right" cellRender={renderGridCell} width={140} />
                            <Column dataField="volumeAtual" caption="Volume Atual" alignment="right" cellRender={renderGridCell} />
                            <Scrolling mode="virtual" />
                          </DataGrid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <br />
            )}

            <h1 className="text-center mb-0">RESUMO DE VENDAS (ÚLTIMOS 8 DIAS COM MOVIMENTAÇÃO)</h1>
            <hr />
            <div className="row">
              <div className="col-md-6">
                <DataGrid
                  dataSource={this.state.resumoVendas}
                  defaultColumns={columnsVendas}
                  showBorders={true}
                  className="bg-white shadow-sm p-3"
                  style={{ minHeight: '400px' }}
                  noDataText="Sem dados"
                  rowAlternationEnabled={true}
                  allowColumnResizing={true}
                  columnResizingMode={'widget'}
                >
                  <Summary>
                    <TotalItem column="vendaBruta" summaryType="avg" valueFormat={{ type: 'currency', precision: 2 }} />
                    <TotalItem column="descontos" summaryType="avg" valueFormat={{ type: 'currency', precision: 2 }} />
                    <TotalItem column="acrescimos" summaryType="avg" valueFormat={{ type: 'currency', precision: 2 }} />
                    <TotalItem column="vendasLiquidasCanceladas" summaryType="avg" valueFormat={{ type: 'currency', precision: 2 }} />
                    <TotalItem column="vendaLiquida" summaryType="avg" valueFormat={{ type: 'currency', precision: 2 }} />
                    <TotalItem column="qtdeDocsFiscais" summaryType="avg" valueFormat={{ precision: 2 }} />
                    <TotalItem column="ticketMedio" summaryType="avg" valueFormat={{ type: 'currency', precision: 2 }} />
                  </Summary>
                  <Scrolling mode="virtual" />
                </DataGrid>
              </div>
              <div className="col-md-6">
                <div className="flex mb-3">
                  <div className="row">
                    <div className="col-md-12">
                      <Chart id="chart" palette="Soft Pastel" style={{ minHeight: '432px' }} dataSource={this.state.resumoVendas} className="bg-white shadow-sm p-3">
                        <CommonSeriesSettings argumentField="dia" />
                        <Series name="Venda Líquida" valueField="vendaLiquida" type="bar" color="#5B2E90"></Series>
                        <Series name="Venda Bruta" valueField="vendaBruta" type="bar" color="#FF9200"></Series>

                        <Series type="spline" valueField="ticketMedio" name="Ticket Médio" color="#2C004B" />
                        <Series type="spline" valueField="qtdeDocsFiscais" name="Quantidade de Documentos Fiscais" color="#FFB200" />

                        <Legend verticalAlignment="bottom" horizontalAlignment="center" />
                        <Tooltip enabled={true} customizeTooltip={this.customizeTooltipResumoVendas} />
                      </Chart>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 className="text-center mb-0">
              RESUMO FINANCEIRO - DIA {this.state.resumoFinanceiro && this.state.resumoFinanceiro.length > 0 ? this.state.resumoFinanceiro[0].data : ''}
            </h1>
            <hr />
            <div className="row">
              <div className="col-md-8">
                <div className="flex mb-3">
                  <PieChart
                    id="pie"
                    dataSource={this.state.resumoFinanceiro}
                    palette="Material"
                    style={{ minHeight: '432px' }}
                    paletteExtensionMode="extrapolate"
                    className="bg-white shadow-sm p-3"
                  >
                    <Series argumentField="formaPagamentoComBandeira" valueField="valor">
                      <Label visible={true}>
                        <Connector visible={true} width={1} />
                      </Label>
                    </Series>
                  </PieChart>
                </div>
              </div>
              <div className="col-md-4">
                <div className="flex mb-3">
                  <DataGrid
                    dataSource={this.state.resumoFinanceiro}
                    defaultColumns={columnsFormasPagamento}
                    showBorders={true}
                    className="bg-white shadow-sm p-3"
                    style={{ minHeight: '400px' }}
                    noDataText="Sem dados"
                    rowAlternationEnabled={true}
                  >
                    <Summary>
                      <TotalItem column="valor" summaryType="sum" valueFormat={{ type: 'currency', precision: 2 }} />
                    </Summary>
                    <Scrolling mode="virtual" />
                  </DataGrid>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={styles.loader}>
            <h1>Carregando Dashboard</h1>
            <svg className={styles.spinner} viewBox="0 0 50 50">
              <circle className={styles.path} cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          </div>
        )}
      </LinxPostos>
    );
  }
}
