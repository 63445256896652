import React, { useState, useEffect, useRef } from 'react';
import { SelectBox } from 'devextreme-react/select-box';
import { DataGrid, Column, Pager, Paging, Sorting, SearchPanel, ColumnChooser, FilterRow } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import { Popup } from 'devextreme-react';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { CustomButton } from '../../../SharedComponents';
import { Cancelar } from '../../../SharedComponents/Cancelar/Cancelar';
import { CobrancasService } from '../Cobrancas.service';

export const Realizadas = () => {
  const [charges, setCharges] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(7);
  const [isShow, setIsShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null); // Adicionando estado para armazenar o ID selecionado


  const dateRangeOptions = [
    { label: 'Últimos 7 dias', value: 7 },
    { label: 'Últimos 15 dias', value: 15 },
    { label: 'Últimos 30 dias', value: 30 },
  ];

  const exportToPDF = e => {
    const doc = new jsPDF('landscape');
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(14);
    doc.text('Cobranças realizadas', 15, 10);

    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: e.component,
      autoTableOptions: {
        styles: {
          fillColor: [255, 255, 255],
        },
        tableLineColor: [0, 0, 0],
        tableLineWidth: 1,
        headerStyles: { textColor: [0, 0, 0], fontStyle: 'normal' },
        bodyStyles: { textColor: [0, 0, 0] },
        margin: { top: 20, right: 10, bottom: 10, left: 10 },
      },
    }).then(() => {
      doc.save('cobrancas_realizadas.pdf');
    });

    e.cancel = true;
  };

  const exportToExcel = e => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Planilha1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'cobrancas_realizadas.xlsx');
      });
    });
  };

  const handleBeforeExporting = e => {
    const columns = e.component.getVisibleColumns();
    const clientColumn = columns.find(column => column.dataField === 'client');
    if (clientColumn) {
      clientColumn.width = 200; // Defina a largura desejada para a coluna "Cliente"
    }
  };

  const onToolbarPreparing = e => {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'exportpdf',
          onClick: () => exportToPDF(e),
        },
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'xlsxfile',
          onClick: () => exportToExcel(e),
        },
      },
    );
  };

  const download = async id => {
    notify('Gerando download do boleto. Aguarde...', 'info', 2000);

    await CobrancasService.getPdfDownload(id);
  };

  const renderActionButtons = cellData => {
    const { status, id } = cellData.row.data;

    if (status === 'Pendente') {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <CustomButton className="rounded" variant="primary" icon="download" title="Baixar boleto" onClick={() => download(id)}></CustomButton>
          <CustomButton className="rounded" variant="secondary" icon="clear" title="Cancelar" onClick={() => openModal(id)}>
            Cancelar
          </CustomButton>
        </div>
      );
    }
  };

  const handleValueChange = e => {
    setSelectedValue(e.value);
  };

  const openModal = id => {
    setSelectedId(id); // Armazena o ID do registro
    setIsShow(!isShow);
  };

  const onConfirm = async () => {
    if (selectedId) {
      const payload = { id: selectedId };
      try {
        const result = await CobrancasService.postCancelInvoice(payload);
        if (result.data) {
          notify(result.message, 'success', 2000);
          fetchChargesData(); // Atualize a lista de cobranças após o cancelamento
        } else {
          notify(result.message, 'error', 2000);
        }
      } catch (error) {
        notify("Erro ao processar o cancelamento", 'error', 2000);
      }
    } else {
      notify("ID de cobrança não encontrado", 'error', 2000);
    }

    setIsShow(false); // Fechar o modal após a confirmação
  };

  const fetchChargesData = async () => {
    setIsLoading(true);
    const allCharges = await CobrancasService.getCharges({
      ultimosDias: selectedValue,
    });

    if (allCharges && allCharges.result) {
      setCharges(allCharges.result);
    } else {
      notify(allCharges.message, 'error', 5000);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchChargesData();
  }, [selectedValue]);

  return (
    <div className="d-flex flex-column h-100 w-100">
      <div className="dx-field">
        <div className="dx-field-label">Filtro</div>
        <div style={{ display: 'flex', width: '100%' }}>
          <SelectBox dataSource={dateRangeOptions} displayExpr="label" valueExpr="value" value={selectedValue} onValueChanged={handleValueChange} />
        </div>
      </div>
      {!isLoading ? (
        <DataGrid
          key="id"
          dataSource={charges}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          showBorders={true}
          width="100%"
          height="100%"
          onToolbarPreparing={onToolbarPreparing}
          onCellPrepared={e => {
            if (e.rowType === 'filter') {
              e.cellElement.style.backgroundColor = 'white';
            }
          }}
          onBeforeExporting={handleBeforeExporting} // Adicionando o evento onBeforeExporting
        >
          <SearchPanel visible={true} highlightCaseSensitive={true} />
          <FilterRow visible={true} applyFilter="auto" />
          <Column dataField="create" caption="Data de geração:" />
          <Column dataField="client" caption="Cliente" />
          <Column dataField="value" caption="Valor do boleto" format="R$ #,##0.00" />
          <Column dataField="barCode" caption="Código de barras" />
          <Column dataField="originalValue" caption="Valor original" format="R$ #,##0.00" />
          <Column dataField="paidValue" caption="Valor pago" format="R$ #,##0.00" />
          <Column dataField="status" caption="Status do boleto" />
          <Column cellRender={renderActionButtons} caption="Ações" width={250} />
          <ColumnChooser enabled={true} mode={'select'} allowSearch={true} title="Escolher colunas" />
          <Paging defaultPageSize={10} />
          <Pager visible={true} displayMode="full" />
          <Sorting mode="multiple" />
        </DataGrid>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100">
          <LoadIndicator id="large-indicator" height={60} width={60} />
        </div>
      )}
      <Popup
        visible={isShow}
        dragEnabled={false}
        showTitle={true}
        title="Confirmar Cancelamento"
        showCloseButton={true}
        hideOnOutsideClick={true}
        onHiding={() => setIsShow(false)}
        width={300}
        height={300}
      >
        <Cancelar onCancel={() => setIsShow(false)} onConfirm={onConfirm} />
      </Popup>
    </div>
  );
};
