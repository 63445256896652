import { SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import React, { useCallback, useEffect, useState } from 'react';
import clienteService from '../../../../../services/cliente/Cliente.service';
import { DropDownImage } from './../drop-down-image';

export const DropBoxUf = ({ onSelected, value }) => {
  const [uf, setUf] = useState(null);
  const dataSource = new DataSource({
    key: 'id',
    byKey: key => {
      return [uf || null];
    },
    load: async function(arg) {
      if (arg.searchOperation !== 'contains') {
        return [];
      }
      if (!arg.searchValue) {
        return [];
      }

      const resultUfs = await clienteService.listarUfs(arg.searchValue);
      return resultUfs;
    },
  });

  useEffect(() => {
    setUf(value);
  }, [value]);

  const onSelectionChanged = useCallback(
    e => {
      if ((uf || {}).id === (e.selectedItem || {}).id) {
        return;
      }
      setUf(e.selectedItem);
      if (onSelected) {
        onSelected(e.selectedItem);
      }
    },
    [onSelected, uf],
  );

  return (
        <SelectBox
          id="uf"
          name="uf"
          dropDownButtonRender={DropDownImage}
          autoComplete="off"
          autocomplete="off"
          required={true}
          searchEnabled={true}
          hint="UF"
          placeholder="UF"
          displayExpr="sigla"
          height={40}
          valueExpr="id"
          value={uf || {}}
          acceptCustomValue={false} // Não permite que o valor digitado seja aceito
          dataSource={dataSource}
          selectedItem={uf || {}}
          onSelectionChanged={onSelectionChanged}
          searchTimeoutOption={500}
        ></SelectBox>
  );
};
