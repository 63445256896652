import { Col, Form, Row } from 'react-bootstrap';
import { DateBox, TextBox } from 'devextreme-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { removeDiasDaData } from '../../../../../shared/utils/Utils';

function DataRecolhaDevolucao() {
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const dispatch = useDispatch();

  const [minDate, setMinDate] = useState(removeDiasDaData(30));
  useEffect(() => {
    if (values.id !== -1 && values.dataCadastro !== null) {
      setMinDate(removeDiasDaData(30, new Date(values.dataCadastro)));
      return;
    }
    setMinDate(removeDiasDaData(30));
  }, [values.dataCadastro, values.id]);
  function diferencaEmDias(data1, data2) {
    // Um dia tem 24 * 60 * 60 * 1000 = 86400000 milissegundos
    const UM_DIA = 86400000;

    // Convertendo as datas para milissegundos
    const data1Milissegundos = data1.getTime();
    const data2Milissegundos = data2.getTime();

    // Encontrando a diferença em milissegundos
    const diferencaMilissegundos = Math.abs(data1Milissegundos - data2Milissegundos);

    // Convertendo a diferença para dias
    const diferencaDias = diferencaMilissegundos / UM_DIA;

    return Math.round(diferencaDias);
  }
  return (
    <Row>
      <Col lg={4} className="form-group">
        <Form.Label htmlFor="dataRecolhaDevolucao" className="text-primary">
          Recolha/Devolução<span className="text-danger">*</span>
        </Form.Label>
        <DateBox
          type="date"
          displayFormat="dd/MM/yyyy"
          name="dataRecolhaDevolucao"
          id="dataRecolhaDevolucao"
          height={40}
          min={minDate}
          onValueChange={e => {
            const dt = new Date(e);
            dt.setHours(0, 0, 0, 0);
            dispatch(
              changeValue({
                fieldName: 'dataRecolhaDevolucao',
                value: dt.toISOString(),
              }),
            );
            dispatch(changeBlur({ fieldName: 'dataRecolhaDevolucao' }));
            try {
              dispatch(changeValue({ fieldName: 'recolhaDevolucaoDias', value: diferencaEmDias(dt, new Date(values.dataEntregaRetirada)) }));
            } catch (error) {}
          }}
          defaultValue={minDate}
          value={new Date(values.dataRecolhaDevolucao)}
        />
        {(touched || {}).dataRecolhaDevolucao && (errors || {}).dataRecolhaDevolucao && (
          <div className="text-danger validationError">{(errors || {}).dataRecolhaDevolucao || ''}</div>
        )}
      </Col>
      <Col lg={3} className="form-group">
        <Form.Label htmlFor="recolhaDevolucaoHoraDe" className="text-primary">
          Hora de<span className="text-danger"></span>
        </Form.Label>
        <TextBox
          mode="text"
          name="recolhaDevolucaoHoraDe"
          height={40}
          onValueChange={e => {
            if (e > '23:59') {
              e = '23:59';
            }
            dispatch(
              changeValue({
                fieldName: 'recolhaDevolucaoHoraDe',
                value: e,
              }),
            );
            dispatch(changeBlur({ fieldName: 'recolhaDevolucaoHoraDe' }));
          }}
          interval={30}
          mask="00:00"
          useMaskedValue={true}
          value={(values || {}).recolhaDevolucaoHoraDe || ''}
        />
      </Col>
      <Col lg={3} className="form-group">
        <Form.Label htmlFor="recolhaDevolucaoHoraAte" className="text-primary">
          Até<span className="text-danger"></span>
        </Form.Label>
        <TextBox
          height={40}
          name="recolhaDevolucaoHoraAte"
          mask="00:00"
          mode="text"
          useMaskedValue={true}
          onValueChange={e => {
            if (e > '23:59') {
              e = '23:59';
            }
            dispatch(
              changeValue({
                fieldName: 'recolhaDevolucaoHoraAte',
                value: e,
              }),
            );
            dispatch(changeBlur({ fieldName: 'recolhaDevolucaoHoraAte' }));
          }}
          value={(values || {}).recolhaDevolucaoHoraAte || ''}
        />
        {(touched || {}).recolhaDevolucaoHoraAte && (errors || {}).recolhaDevolucaoHoraAte && (
          <div className="text-danger validationError">{(errors || {}).recolhaDevolucaoHoraAte || ''}</div>
        )}
      </Col>
      <Col lg={2} className="form-group">
        <Form.Label htmlFor="recolhaDevolucaoDias" className="text-primary">
          Dias<span className="text-danger"></span>
        </Form.Label>
        <Form.Control
          type="number"
          name="recolhaDevolucaoDias"
          id="recolhaDevolucaoDias"
          value={(values || {}).recolhaDevolucaoDias || ''}
          onChange={e => {
            dispatch(
              changeValue({
                fieldName: 'recolhaDevolucaoDias',
                value: e.target.value,
              }),
            );
          }}
          onBlur={() => dispatch(changeBlur({ fieldName: 'recolhaDevolucaoDias' }))}
        />
      </Col>
    </Row>
  );
}

export default DataRecolhaDevolucao;
