import 'devextreme/dist/css/dx.light.css';
import { locale } from 'devextreme/localization';
import React from 'react';
import { withRouter } from 'react-router-dom';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';

export function DashVazio() {
  locale('pt-BR');

  return (
    <>
      <LinxPostos breadcrumb={'Dashboard'} id="page">
      </LinxPostos>
    </>
  );
}

export default withRouter(DashVazio);
