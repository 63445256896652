import { Form, Popup, ScrollView } from 'devextreme-react';
import { SimpleItem } from 'devextreme-react/form';
import { alert } from 'devextreme/ui/dialog';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { ApiSeriesUtilizadasService } from '../../usercases/apiSeriesUtilizadas.service';

export const ModalNumeroDeSerie = forwardRef(({ item, tipoPedidoId, comodatoId, dtEntrega, dtRecolha }, ref) => {
  const { atualizarNumeroSerie } = useNovaOs();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [seriesUtilizadas, setSeriesUtilizadas] = useState([]);
  const [message] = useState('Verificando números de séries disponíveis...');
  const [dados, setDados] = useState([]);
  const [series, setSeries] = useState([]);
  const [selectedValues, setSelectedValues] = React.useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!item) {
      setSeries([]);
      return;
    }
    if (!show) {
      return;
    }
    setSeries(item.produto.series);
    setIsLoading(true);
    const service = new ApiSeriesUtilizadasService();
    service
      .execute(comodatoId, item.produto.id, dtEntrega, dtRecolha)
      .then(res => {
        console.log('Series Utilizadas', res);
        setSeriesUtilizadas(res);
        if ((item.series || []).length === 0) {
          const arr = [];
          for (let i = 1; i <= item.quantidade; i++) {
            arr.push({
              id: -1,
              numeroItem: item.numeroItem,
              numeroItemQuantidade: i,
              serie: '',
            });
          }
          setDados(arr);
        } else {
          setDados(JSON.parse(JSON.stringify(item.series)));
          setSelectedValues(item.series.map(s => s.serie));
        }
        setIsLoading(false);
      })
      .catch(error => {
        alert(error.message, 'Erro');
        setIsLoading(false);
        setShow(false);
        return;
      });
    // setIsLoading(false);
  }, [item, comodatoId, dtEntrega, dtRecolha, show]);

  const serieEstaUtilizada = serie => {
    const idx = seriesUtilizadas.findIndex(s => s.serie === serie);
    return idx !== -1;
  };

  const handleSaveSeries = useCallback(() => {
    dispatch(atualizarNumeroSerie({ series: dados, numeroItem: item.numeroItem, tipoPedidoId }));
    setShow(false);
  }, [atualizarNumeroSerie, dados, dispatch, item, tipoPedidoId]);

  useImperativeHandle(ref, () => {
    return {
      open: () => {
        setShow(true);
      },
      close: () => {
        setShow(false);
      },
    };
  });
  const handlerOnChange = useCallback(
    e => {
      const { name, value } = e.target;
      const index = name.split('_')[1];
      const arr = [...dados];
      arr[index].serie = value;
      setDados(arr);
      setSelectedValues(prevState => {
        const updatedValues = [...prevState];
        updatedValues[index] = value;
        return updatedValues;
      });
    },
    [dados],
  );

  const closePopup = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Popup
      title="Informe os Números de Série do produto"
      overlayClassName="custom-overlay"
      visible={show}
      hideOnOutsideClick={false}
      width={400}
      height={500}
      onHiding={closePopup}
    >
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <div className="text-center">
            <h1>{message}</h1>
            <div className="spinner-border" role="status"></div>
          </div>
        </div>
      ) : (
        <ScrollView height="100%" width="100%">
          <div style={{ backgroundColor: '#f5f5f5', padding: '10px' }}>
            <p style={{ color: '#777' }}>{((item || {}).produto || {}).descricao}</p>
          </div>

          <Form formData={dados} className="mt-3">
            {dados.map((d, i) => (
              <SimpleItem key={i} dataField="serie" label={`Número de Série ${d.numeroItemQuantidade}`}>
                <select value={d.serie} id={`serie_${i}`} name={`serie_${i}`} className="form-control" onChange={handlerOnChange}>
                  <option value={-1}>SELECIONE</option>
                  {series
                    .map(sd => {
                      return sd.serie;
                    })
                    .filter(s => {
                      if (!d.serie) return !selectedValues.includes(s);
                      return !selectedValues.includes(s) || d.serie === s;
                    })
                    .map((s, idx) => (
                      <option key={idx} disabled={serieEstaUtilizada(s)} value={`${s}`}>
                        {serieEstaUtilizada(s) ? `${s} EM USO` : s}
                      </option>
                    ))}
                </select>
              </SimpleItem>
            ))}
          </Form>

          <Button variant="primary" title="Adicionar" className={`mt-4 float-right`} onClick={handleSaveSeries}>
            Ok
          </Button>
        </ScrollView>
      )}
    </Popup>
  );
});
