import axios from 'axios';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';
import { getToken } from '../../../authentication/Auth';
//axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export function getConfiguracoesGerais() {
  return urlLinxCore.get(`ConfiguracoesGerais/Listar`, {});
}

export function getConfiguracaoGeral(idConfiguracao) {
  return urlLinxCore.get(
    `ConfiguracoesGerais/Recuperar?idConfiguracao=${idConfiguracao}`,
    {}
  );
}

export function atualizaConfiguracaoGeral(configuracao) {
  return urlLinxCore.put(`ConfiguracoesGerais/Editar`, configuracao);
}

export function testaConfiguracaoEmail(configEmail) {
  return axios.post('LxApi/v1/Configuracao/TestarEnvioEmail', {
    servidorSmtp: configEmail.smtp,
    porta: configEmail.porta,
    autenticacaoSegura: configEmail.autenticacaoSegura,
    enderecoEmail: configEmail.email,
    nomeExibicao: configEmail.nome,
    usuario: configEmail.usuario,
    senha: configEmail.senha
  });
}
