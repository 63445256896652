import { DateBox } from 'devextreme-react';
import React, { useCallback, forwardRef, useState, useImperativeHandle } from 'react';

export const DateInput = forwardRef(({ initialValue, id, label, placeholder, displayFormat = 'dd/MM/yyyy', isMinTime = true }, ref) => {
  const [value, setValue] = useState(initialValue);
  const getMaxTime = (date = null) => {
    if (!date) date = new Date();
    return new Date(date.setHours(23, 59, 59, 999));
  };
  const getMinTime = (date = null) => {
    if (!date) date = new Date();
    return new Date(date.setHours(0, 0, 0, 0));
  };
  const onChange = useCallback(
    e => {
      setValue(e ? (isMinTime ? getMinTime(e).toISOString() : getMaxTime(e).toISOString()) : null);
    },
    [isMinTime],
  );

  useImperativeHandle(
    ref,
    () => ({
      getValue() {
        return value;
      },
      setValue(data) {
        setValue(data);
      },
    }),
    [value],
  );

  return (
    <div className="form-group text-left">
      <label htmlFor="selectEstabelecimentos" className="text-primary">
        {label}
      </label>
      <DateBox
        id={id}
        className={`border-top-0 border-left-0 border-right-0 float-date-input`}
        placeholder={placeholder}
        value={value ? (isMinTime ? getMinTime(new Date(value)) : getMaxTime(new Date(value))) : null}
        displayFormat={displayFormat}
        onValueChange={onChange}
        defaultValue={isMinTime ? getMinTime() : getMaxTime()}
        type={displayFormat.indexOf('hh:mm') > -1 ? 'datetime' : 'date'}
      />
    </div>
  );
});
