import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

export class ApiAdicionarPagamentoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(body) {
    try {

      const response = await this.urlLinxCore.post(`/ConComodato/AdicionarPagamento`, body);
      return response.data.data;
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
