import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Select,
  handleInputChange,
  Checkbox,
  Checkitem
} from '../../../components/formulario/Formulario';
import List from '../../../components/formulario/List';
import SectionHeader from '../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  tagAlert,
  showInfo,
  showError,
  showDeleteError,
  showQuestion,
  showSaveError,
  showWait,
  hideWait,
  hideAlert
} from '../../../components/alert/Alert';

import { withRouter } from 'react-router-dom';
import {
  getCnpj,
  menuPermiteInclusao,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  sameInt,
  emptyText
} from '../../../shared/utils/Utils';
import ReactTable from 'react-table';
import '../../../../../node_modules/react-table/react-table.css';

import {
  getGrupoImpostos,
  incluirGrupoImposto,
  alterarGrupoImposto,
  excluirGrupoImposto,
  montaComboImpostos,
  montaComboTipoAliquota,
  montaComboTipoOperacao,
  MontarComboNaturezaReceita,
  montaComboSituacaoTributaria
} from './Impostos.service';
import { deleteInList, isAllowed } from '../../../shared/utils/TableUtils';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import Footer from '../../../components/core/footer/Footer';
import { menus } from '../../../shared/constants/MenuConstants';

export const None = '';
export const none = '';

export const TipoImposto = {
  AliqEstadoOrigem: '7',
  Cofins: '4',
  Icms: '1',
  Iss: '5',
  Pis: '3'
};

export const TipoAliquota = {
  Percentual: '0',
  Valor: '1'
};

export const TipoImpostos = [
  { value: TipoImposto.AliqEstadoOrigem, label: 'Alíquota estado de origem' },
  { value: TipoImposto.Cofins, label: 'COFINS' },
  { value: TipoImposto.Icms, label: 'ICMS' },
  //{ value: TipoImposto.Iss, label: "ISS" },
  { value: TipoImposto.Pis, label: 'PIS' }
];

export const Operacao = {
  None: '',
  Entrada: '0',
  Saida: '1'
};

const PATH_MENU = menus.COD_60720;
const MAIN_PATH = '/fiscal/impostos/';
const NEW_PATH = MAIN_PATH + 'new';

const titulo = 'Grupo de impostos';

class FormGrupoImpostos extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      //Grupo de imposto
      cnpjEstabelecimento = getCnpj(),
      descricaoGrupoImposto = '',
      ativo = true,
      temProduto = false,
      codigoGrupoImposto = null,

      //lista de aliquotas dentro daquele grupo de impostos
      aliquota = [],
      rowVersion = null,

      //Para as descricoes do combo
      descricaoTipoImposto,
      descricaoAliquota,
      descricaoOperacao,
      descricaoSituacao
    } = this.props.grupoImposto;

    this.state = {
      //Grupo de impostos
      cnpjEstabelecimento,
      descricaoGrupoImposto,
      ativo,
      temProduto,
      codigoGrupoImposto,
      rowVersion,

      //lista aliquotas
      aliquota,

      //Imposto contido na aliquota
      codigoTipoImposto: aliquota.codigoTipoImposto,
      descricaoTipoImposto,

      //combo natureza receita
      codigoNaturezaReceita: aliquota.codigoNaturezaReceita,
      //descricaoNaturezaReceita: "",

      //combo tipo aliquota
      tipoAliquota: aliquota.tipoAliquota,
      descricaoAliquota,

      //combo tipo operação
      tipoOperacao: aliquota.tipoOperacao,
      descricaoOperacao,

      //combo situacao tributaria
      situacaoTributaria: aliquota.situacaoTributaria,
      descricaoSituacao,

      //Alerts
      alertActive: false,
      alertType: '',
      alertTitle: '',
      alertSubtitle: '',

      grupoTipoExistentes: [],
      sitTributarias: [],

      exibirCadastro: false,
      exibirListImposto: false,
      naturezas: [],
      situacoes: [],
      isIcmsSelected: false,
      salvando: false
    };
  }

  //Para o combo tipo aliquotas (exemplo)
  async componentDidMount() {
    const { data: tipoAliquotas } = await montaComboTipoAliquota(getCnpj());
    this.setState({
      tipoAliquotas: tipoAliquotas.map(tipoAliquota => {
        return {
          label: tipoAliquota.descricao,
          value: String(tipoAliquota.codigo)
        };
      })
    });
    let primeiroAliq = String(tipoAliquotas.codigo);
    let descricaoAliq = tipoAliquotas.descricao;
    //if (!this.props.grupoImposto.rowVersion) {
    for (let i = 0; i < tipoAliquotas.length; i++) {
      if (
        !this.props.grupoImposto.rowVersion ||
        !this.props.grupoImposto.aliquota.rowVersion
      ) {
        const aliq = tipoAliquotas[i];
        primeiroAliq = String(aliq.codigo);
        descricaoAliq = aliq.descricao;
        break;
      }
      //this.setState({ tipoAliquota: primeiroAliq, descricaoAliquota: descricaoAliq });
    }
    this.setState({
      tipoAliquota: primeiroAliq,
      descricaoAliquota: descricaoAliq
    });

    const { data } = await montaComboTipoOperacao();
    const tipoOperacoes = data.map(tipoOp => {
      return {
        label: tipoOp.descricao,
        value: tipoOp.codigo
      };
    });
    tipoOperacoes.unshift({ value: -1, label: 'Selecione uma operação' });

    this.setState({ tipoOperacoes });

    let primeiraOp = tipoOperacoes.codigo;
    let descricaoOp = tipoOperacoes.descricao;
    if (!this.props.grupoImposto.rowVersion) {
      for (let i = 0; i < tipoOperacoes.length; i++) {
        const op = tipoOperacoes[i];
        primeiraOp = op.codigo;
        descricaoOp = op.descricao;
        break;
      }
      this.setState({
        tipoOperacao: primeiraOp,
        descricaoOperacao: descricaoOp
      });
    }

    const { data: dataImpostos } = await montaComboImpostos(getCnpj());
    const tipoImpostos = dataImpostos.map(ti => {
      return {
        label: ti.descricao,
        value: ti.codigo
      };
    });
    tipoImpostos.unshift({ value: '', label: 'Selecione um imposto' });
    this.setState({ tipoImpostos });

    let primeiroImp = tipoImpostos.codigo;
    let descricaoImp = tipoImpostos.descricao;
    if (!this.props.grupoImposto.rowVersion) {
      for (let i = 0; i < tipoImpostos.length; i++) {
        const tipoImp = tipoImpostos[i];
        primeiroImp = tipoImp.codigo;
        descricaoImp = tipoImp.descricao;
        break;
      }
      this.setState({
        codigoTipoImposto: primeiroImp,
        descricaoTipoImposto: descricaoImp
      });
    }

    this.updateNatureza(
      this.state.codigoTipoImposto,
      this.state.situacaoTributaria
    );

    const listaCompleta = [];
    let isIcmsSelected = false;
    if (this.state.aliquota.length > 0) {
      for (let i = 0; i < this.state.aliquota.length; i++) {
        const lista = this.state.aliquota[i];

        lista.descricaoTipoImposto = this.getDescricaoImposto(
          lista.codigoTipoImposto
        );
        lista.descricaoOperacao = this.getDescricaoOperacao(lista.tipoOperacao);
        lista.descricaoAliquota = this.getDescricaoAliquota(lista.tipoAliquota);

        if (
          lista.codigoTipoImposto === parseInt(TipoImposto.Pis) ||
          lista.codigoTipoImposto === parseInt(TipoImposto.Cofins)
        )
          lista.descricaoSituacao = String(lista.situacaoTributaria).substr(
            1,
            3
          );

        if (sameInt(lista.codigoTipoImposto, TipoImposto.Icms)) {
          isIcmsSelected = true;
          lista.descricaoSituacao = lista.situacaoTributaria;
        }
        listaCompleta.push(lista);
      }
      this.setState({
        isIcmsSelected,
        aliquota: listaCompleta
      });
    }
  }

  handleAlertAction = async resp => {
    const { alertType, cnpjEstabelecimento, codigoGrupoImposto } = this.state;

    this.setState({
      alertActive: false,
      alertType: '',
      alertTitle: '',
      alertSubtitle: ''
    });

    switch (alertType) {
      case 'success':
        this.props.history.push(MAIN_PATH);
        break;

      case 'question':
        if (resp) {
          try {
            if (this.state.temProduto)
              showError(
                this,
                titulo,
                'Grupo de imposto não pode ser excluído, ' +
                  'pois possui produto vinculado.'
              );
            else {
              await excluirGrupoImposto(
                cnpjEstabelecimento,
                codigoGrupoImposto
              );
              showInfo(this, titulo, 'Grupo de imposto excluído com sucesso!');
            }
          } catch (err) {
            showDeleteError(this, titulo, err);
          }
        }
        break;

      case 'cancel':
        if (resp) {
          this.props.history.push(MAIN_PATH);
        }
        break;

      default:
        break;
    }
  };

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    showQuestion(this, titulo, 'Deseja excluir?', null, async resp => {
      if (resp) {
        try {
          if (this.state.temProduto)
            showError(
              this,
              titulo,
              'Grupo de imposto não pode ser excluído, ' +
                'pois possui produto vinculado.'
            );
          else {
            await excluirGrupoImposto(
              this.state.cnpjEstabelecimento,
              this.state.codigoGrupoImposto
            );
            showInfo(
              this,
              titulo,
              'Grupo de imposto excluído com sucesso!',
              null,
              () => {
                hideAlert(this, this.props.history, PATH_MENU);
              }
            );
          }
        } catch (err) {
          showDeleteError(this, titulo, err, null, () => {
            hideAlert(this);
          });
        }
      } else {
        hideAlert(this);
      }
    });
  };

  handleCancelar = () => {
    showQuestion(
      this,
      titulo,
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (resp) {
          this.props.history.push(MAIN_PATH);
        } else {
          hideAlert(this);
        }
      }
    );
  };

  validacaoGrid = () => {
    const {
      codigoTipoImposto,
      situacoes,
      situacaoTributaria,
      naturezas,
      codigoNaturezaReceita,
      tipoOperacao
    } = this.state;

    if (!codigoTipoImposto) {
      showError(this, titulo, 'É obrigatório informar o imposto');
      return false;
    }
    if (
      sameInt(codigoTipoImposto, TipoImposto.Icms) ||
      sameInt(codigoTipoImposto, TipoImposto.Pis) ||
      sameInt(codigoTipoImposto, TipoImposto.Cofins)
    ) {
      if (situacoes.length > 1 && !situacaoTributaria) {
        showError(this, titulo, 'É obrigatório informar a situação tributária');
        return false;
      }
    }
    if (
      sameInt(codigoTipoImposto, TipoImposto.Pis) ||
      sameInt(codigoTipoImposto, TipoImposto.Cofins)
    ) {
      if (naturezas.length > 1 && !codigoNaturezaReceita) {
        showError(this, titulo, 'É obrigatório informar a natureza da receita');
        return false;
      }
    }
    if (
      sameInt(codigoTipoImposto, TipoImposto.Pis) ||
      sameInt(codigoTipoImposto, TipoImposto.Cofins)
    ) {
      if (emptyText(tipoOperacao)) {
        showError(this, titulo, 'É obrigatório informar a operação');
        return false;
      }
    }
    if (this.state.aliquota.length > 0) {
      let list = this.state.aliquota;
      let result = true;
      for (let i = 0; i < list.length; i++) {
        const aliquota = list[i];
        if (
          sameInt(aliquota.codigoTipoImposto, codigoTipoImposto) &&
          sameInt(tipoOperacao, aliquota.tipoOperacao)
        ) {
          showError(
            this,
            titulo,
            'Grupo de imposto não deve conter impostos de mesmo tipo'
          );
          result = false;
          return;
        }

        /* // Icms e Iss não deve estar juntas
        if (
          aliquota.codigoTipoImposto === parseInt(TipoImposto.Icms) &&
          this.state.codigoTipoImposto === TipoImposto.Iss
        ) {
          showError(this, titulo,
            "Grupo de imposto que contenha ICMS não deve conter imposto do tipo ISS"
          );
          return false;
        }
        if (
          aliquota.codigoTipoImposto === parseInt(TipoImposto.Iss) &&
          this.state.codigoTipoImposto === TipoImposto.Icms
        ) {
          showError(this, titulo,
            "Grupo de imposto que contenha ISS não deve conter imposto do tipo ICMS"
          );
          return false;
        }
        */
        //Se tiver Pis ou Cofins
        if (
          sameInt(aliquota.codigoTipoImposto, TipoImposto.Pis) ||
          sameInt(aliquota.codigoTipoImposto, TipoImposto.Cofins)
        ) {
          //Não deve ter ICMS e ISS
          if (sameInt(codigoTipoImposto, TipoImposto.Icms)) {
            showError(
              this,
              titulo,
              'Grupo de imposto que contenha PIS e COFINS não deve conter imposto do tipo ICMS'
            );
            result = false;
            return;
          }
        }
        if (sameInt(aliquota.codigoTipoImposto, TipoImposto.Icms)) {
          //Não deve ter ICMS e ISS
          if (
            sameInt(codigoTipoImposto, TipoImposto.Pis) ||
            sameInt(codigoTipoImposto, TipoImposto.Cofins)
          ) {
            showError(
              this,
              titulo,
              'Grupo de imposto que contenha ICMS não deve conter imposto do tipo PIS e COFINS'
            );
            result = false;
            return;
          }
        }
      }
      return result;
    }
    return true;
  };

  handleAdicionar = () => {
    let validou = this.validacaoGrid();

    const { codigoTipoImposto } = this.state;

    let descOperacao,
      descSitTrib = '';
    let isIcmsSelected = false;
    if (
      sameInt(codigoTipoImposto, TipoImposto.Pis) ||
      sameInt(codigoTipoImposto, TipoImposto.Cofins)
    ) {
      descOperacao = this.state.descricaoOperacao;
      descSitTrib = String(this.state.descricaoSituacao).substr(1, 3);
    }
    if (sameInt(codigoTipoImposto, TipoImposto.Icms)) {
      //descNatRec = this.state.descricaoNaturezaReceita;
      descSitTrib = this.state.descricaoSituacao;
      isIcmsSelected = true;
    }
    if (validou) {
      const { aliquota } = this.state;

      this.setState({
        isIcmsSelected,
        aliquota: [
          ...aliquota,
          {
            //codigo: this.state.codigoGrupoImposto, criado pela API na inserção
            tipoAliquota: this.state.tipoAliquota,
            descricaoAliquota: this.state.descricaoAliquota,
            descricao: '',
            percentualValor: '',
            quantidade: '',

            situacaoTributaria: this.state.situacaoTributaria,
            descricaoSituacao: descSitTrib,

            ativo: true,

            tipoOperacao: this.state.tipoOperacao,
            descricaoOperacao: descOperacao,

            codigoTipoImposto: this.state.codigoTipoImposto,
            descricaoTipoImposto: this.state.descricaoTipoImposto,

            codigoNaturezaReceita: this.state.codigoNaturezaReceita

            /*
            percentualReducao: ""
            percentualEfetivo,
            percentualReducaoBaseEfetivo,
            percentualDesonerado */
            //rowVersion: null
          }
        ]
      });
    }
  };

  handleExibir = () => {
    let exibe;
    if (
      this.state.descricaoGrupoImposto === '' ||
      this.state.descricaoGrupoImposto.trim() === ''
    ) {
      showError(
        this,
        titulo,
        'É obrigatório informar a descrição do grupo de imposto'
      );
    } else {
      exibe = !this.state.exibirCadastro;
    }

    if (exibe) {
      let exibirList = !this.state.exibirListImposto;
      this.setState({
        exibirListImposto: exibirList,
        exibirCadastro: exibe
      });
    }
  };

  handleChangeGrid = (value, name, id) => {
    const aliquotas = this.state.aliquota.map((aliq, index) => {
      if (sameInt(index, id)) {
        return { ...aliq, [name]: value };
      } else {
        return aliq;
      }
    });
    this.setState({ aliquota: aliquotas });
  };

  updateNatureza = async (codImposto, codTributaria) => {
    if (!codImposto && !codTributaria) {
      this.setState({
        naturezas: [
          { value: none, label: 'Selecione um imposto e situaçao primeiro' }
        ]
      });
    } else {
      const { data } = await MontarComboNaturezaReceita(
        codImposto,
        codTributaria
      );
      const naturezas = data.map(list => {
        return {
          label: list.codigo + ' - ' + list.descricao.substr(0, 330),
          value: list.codigo,
          hint: list.codigo + ' - ' + list.descricao
        };
      });
      naturezas.unshift({ value: '', label: 'Selecione uma natureza' });
      this.setState({ naturezas });
    }
  };

  updateSituacao = async (codImposto, codOperacao) => {
    if (codImposto === TipoImposto.Icms && !codOperacao) {
      codOperacao = 1;
    }

    if (
      !codOperacao &&
      (codImposto === TipoImposto.Pis || codImposto === TipoImposto.Cofins)
    ) {
      this.setState({
        situacoes: [{ value: '', label: 'Selecione primeiro uma operação' }]
      });
    }

    const { data } = await montaComboSituacaoTributaria(
      codImposto,
      codOperacao
    );
    const situacoes = data.map(sit => {
      return {
        label: sit.descricao.substr(0, 120),
        value: sit.codigo
      };
    });
    situacoes.unshift({ value: '', label: 'Selecione uma situação' });
    this.setState({ situacoes });
    this.setState({ descricaoSituacao: situacoes.value });
  };

  handleSalvar = async () => {
    this.setState({ salvando: true });
    showWait(this, titulo, 'Por favor, aguarde enquanto o registro é salvo.');

    if (!validaDadosLogin()) {
      hideWait(this);
      this.props.history.push('/');
    }
    const {
        cnpjEstabelecimento,
        codigoGrupoImposto,
        descricaoGrupoImposto,
        temProduto,
        aliquota,
        ativo,
        rowVersion
      } = this.state,
      //lista de aliquota
      Aliquotas = [];

    for (let i = 0; i < aliquota.length; i++) {
      const element = aliquota[i];
      const objAliquota = {
        //valores da api que estarao na list de cadastro
        codigo: element.codigo,
        tipoAliquota: element.tipoAliquota,
        descricao: element.descricao,
        percentualValor: element.percentualValor,
        quantidade: element.quantidade,
        situacaoTributaria: element.situacaoTributaria,
        ativo: element.ativo,
        tipoOperacao: element.tipoOperacao,
        codigoTipoImposto: element.codigoTipoImposto,
        codigoNaturezaReceita: element.codigoNaturezaReceita,
        percentualReducao: element.percentualReducao,
        rowVersion: element.rowVersion,
        percentualEfetivo: element.percentualEfetivo,
        percentualReducaoBaseEfetivo: element.percentualReducaoBaseEfetivo,
        percentualDesonerado: element.percentualDesonerado
      };
      Aliquotas.push(objAliquota);
    }

    const [action, params] =
      codigoGrupoImposto !== null
        ? [
            alterarGrupoImposto,
            [
              cnpjEstabelecimento,
              descricaoGrupoImposto,
              ativo,
              temProduto,
              codigoGrupoImposto,
              Aliquotas,
              rowVersion
            ]
          ]
        : [
            incluirGrupoImposto,
            [
              cnpjEstabelecimento,
              descricaoGrupoImposto,
              ativo,
              temProduto,
              Aliquotas
            ]
          ];

    let temErros = this.verificaGrupoAliquota();

    if (!temErros && action === incluirGrupoImposto) {
      temErros = this.verificaImpostoExistente(this.state.codigoGrupoImposto);
    }
    if (!temErros) {
      try {
        await action(...params);
        let message = '';
        if (this.isEditing()) {
          message = 'Grupo de impostos alterado com sucesso!';
        } else {
          message = 'Grupo de impostos cadastrado com sucesso!';
        }
        hideWait(this);
        this.setState({ salvando: false });
        showInfo(this, titulo, message, null, () => {
          this.props.history.push(MAIN_PATH);
        });
      } catch (err) {
        hideWait(this);
        this.setState({ salvando: false });
        showSaveError(this, titulo, err);
      }
    }
  };

  isEditing = () => {
    return !!this.state.codigoGrupoImposto;
  };

  verificaGrupoAliquota = () => {
    //verifica preenchimento dos campos
    //Grupo de impostos
    if (
      this.state.descricaoGrupoImposto === '' ||
      this.state.descricaoGrupoImposto.trim() === ''
    ) {
      showError(
        this,
        titulo,
        'É obrigatório informar a descrição do grupo de imposto'
      );
      this.setState({ salvando: false });
      return true;
    }
    // aliquotas
    if (this.state.aliquota.length > 0) {
      let list = this.state.aliquota;
      for (let i = 0; i < list.length; i++) {
        const aliquota = list[i];

        if (parseInt(aliquota.codigoTipoImposto) < 0) {
          showError(this, titulo, 'É obrigatório informar o tipo de imposto');
          this.setState({ salvando: false });
          return true;
        }
        if (parseInt(aliquota.tipoAliquota) < 0) {
          showError(this, titulo, 'É obrigatório informar o tipo de alíquota');
          this.setState({ salvando: false });
          return true;
        }
        if (
          aliquota.codigoTipoImposto === TipoImposto.Pis ||
          aliquota.codigoTipoImposto === TipoImposto.Cofins
        ) {
          if (parseInt(aliquota.tipoOperacao) < 0) {
            showError(
              this,
              titulo,
              'É obrigatório informar o tipo da operação'
            );
            this.setState({ salvando: false });
            return true;
          }
        }
        if (
          aliquota.codigoTipoImposto === TipoImposto.Pis ||
          aliquota.codigoTipoImposto === TipoImposto.Cofins ||
          aliquota.codigoTipoImposto === TipoImposto.Icms
        ) {
          if (
            this.state.situacoes.length > 1 &&
            parseInt(aliquota.situacaoTributaria) < 0
          ) {
            showError(
              this,
              titulo,
              'É obrigatório informar a situação tributária'
            );
            this.setState({ salvando: false });
            return true;
          }
        }

        if (
          aliquota.percentualValor === '' &&
          aliquota.tipoAliquota === TipoAliquota.Percentual
        ) {
          showError(
            this,
            titulo,
            'É obrigatório informar o percentual da alíquota'
          );
          this.setState({ salvando: false });
          return true;
        }
        if (
          aliquota.percentualValor === '' &&
          aliquota.tipoAliquota === TipoAliquota.Valor
        ) {
          showError(this, titulo, 'É obrigatório informar o valor da alíquota');
          this.setState({ salvando: false });
          return true;
        }
        if (aliquota.percentualValor < 0 || aliquota.percentualValor > 100) {
          showError(
            this,
            titulo,
            'O percentual da alíquota deve ser entre 0 e 100'
          );
          this.setState({ salvando: false });
          return true;
        }
        if (
          aliquota.codigoTipoImposto ===
          TipoImposto.Icms /*||
          aliquota.codigoTipoImposto === TipoImposto.Iss */
        ) {
          if (
            aliquota.percentualReducao < 0 ||
            aliquota.percentualReducao > 100
          ) {
            showError(
              this,
              titulo,
              'O percentual da base de cálculo deve ser entre 0 e 100'
            );
            this.setState({ salvando: false });
            return true;
          }
        }
        if (aliquota.descricao === '') {
          showError(
            this,
            titulo,
            'É obrigatório informar a descrição do imposto'
          );
          this.setState({ salvando: false });
          return true;
        }
        if (
          aliquota.quantidade === '' &&
          String(aliquota.tipoAliquota) === TipoAliquota.Valor
        ) {
          showError(
            this,
            titulo,
            'É obrigatório informar a quantidade do imposto'
          );
          this.setState({ salvando: false });
          return true;
        }

        //Percorre lista de aliquotas, para checar se existe imposto já inserido no grid
        let listCopia = this.state.aliquota;
        for (let j = 1; j < listCopia.length; j++) {
          const aliquotaCopia = listCopia[j];
          if (aliquotaCopia.descricao === aliquota.descricao) {
            showError(
              this,
              titulo,
              'Já existe uma Aliquota registrada com a mesma descrição'
            );
            this.setState({ salvando: false });
            return true;
          }
          break;
        }
        break;
      }
    }
    //Verifica existe alguma aliquota inserida
    if (this.state.aliquota.length <= 0) {
      showError(this, titulo, 'Deve ser informado ao menos um imposto');
      this.setState({ salvando: false });
      return true;
    }
    return false;
  };

  verificaImpostoExistente = desc => {
    let existe = false;
    let cod = '';
    let lista = this.state.grupoTipoExistentes;
    for (let i = 0; i < lista.length; i++) {
      const gi = lista[i];
      if (parseInt(gi.descricaoGrupoImposto) === desc) {
        cod = gi.codigoGrupoImposto;
        existe = true;
      }
    }
    if (existe) {
      showError(
        this,
        titulo,
        'Já existe um grupo com a mesma descrição, com código: ' + cod
      );
    }
    return existe;
  };

  eliminarImposto = e => {
    const { aliquota } = this.state;
    const listaAliquotaNovo = [];
    const nome = e.currentTarget.className;
    for (let i = 0; i < aliquota.length; i++) {
      const element = aliquota[i];
      if (nome.indexOf(element.codigoTipoImposto) === -1) {
        listaAliquotaNovo.push(element);
      }
    }
    this.setState({ aliquota: listaAliquotaNovo });
  };

  changeAliquota = event => {
    let value, name;
    let descAliq = '';

    value = event.target.value;
    name = event.name;

    const list = this.state.tipoAliquotas;
    for (let i = 0; i < list.length; i++) {
      const aliq = list[i];
      if (parseInt(aliq.value) === parseInt(value)) {
        descAliq = aliq.label;
      }
    }
    this.setState({ tipoAliquota: value, descricaoAliquota: descAliq });
    this.setState({ [name]: value });
  };

  changeNatReceita = event => {
    let value, name;
    let descNat = '';

    value = event.target.value;
    name = event.name;

    const list = this.state.codigoNaturezaReceitas;
    for (let i = 0; i < list.length; i++) {
      const nat = list[i];
      if (parseInt(nat.value) === parseInt(value)) {
        descNat = nat.label;
      }
    }
    this.setState({
      codigoNaturezaReceita: value,
      descricaoNaturezaReceita: descNat
    });
    this.setState({ [name]: value });
  };

  changeImposto = event => {
    let value, name;
    let descImp = '';

    value = event.target.value;
    name = event.name;

    const list = this.state.tipoImpostos;
    for (let i = 0; i < list.length; i++) {
      const imp = list[i];
      if (parseInt(imp.value) === parseInt(value)) {
        descImp = imp.label;
      }
    }

    this.updateNatureza(
      this.state.codigoTipoImposto,
      this.state.situacaoTributaria
    );
    this.updateSituacao(value, this.state.tipoOperacao);
    this.setState({
      situacaoTributaria: '',
      codigoNaturezaReceita: '',
      codigoTipoImposto: value,
      descricaoTipoImposto: descImp,
      [name]: value
    });
  };

  changeOperacao = event => {
    let value, name;
    let descOp = '';

    value = event.target.value;
    name = event.name;

    const list = this.state.tipoOperacoes;
    for (let i = 0; i < list.length; i++) {
      const ops = list[i];
      if (parseInt(ops.value) === parseInt(value)) {
        descOp = ops.label;
      }
    }

    this.updateSituacao(this.state.codigoTipoImposto, value);
    this.setState({
      situacaoTributaria: '',
      codigoNaturezaReceita: '',
      tipoOperacao: value,
      descricaoOperacao: descOp,
      [name]: value
    });
  };

  changeTributacao = event => {
    let value, name;

    value = event.target.value;
    name = event.name;

    this.setState({
      codigoNaturezaReceita: '',
      situacaoTributaria: value,
      descricaoSituacao: value,
      [name]: value
    });
    this.updateNatureza(this.state.codigoTipoImposto, value);
  };

  getApresentaBase = codigo => {
    return sameInt(codigo, 20) || sameInt(codigo, 90);
  };

  showAliquota = codigo => {
    return sameInt(codigo, 60) || sameInt(codigo, 500);
  };

  showDesoneracao = codigo => {
    //20, 40, 41, 50 e 90
    return (
      sameInt(codigo, 20) ||
      sameInt(codigo, 40) ||
      sameInt(codigo, 41) ||
      sameInt(codigo, 50) ||
      sameInt(codigo, 90)
    );
  };

  getDescricaoImposto = codImposto => {
    let descricaoImposto = '';
    let listImp = this.state.tipoImpostos;
    if (listImp && listImp.length > 0) {
      for (let i = 0; i < listImp.length; i++) {
        const imp = listImp[i];
        if (parseInt(imp.value) === parseInt(codImposto)) {
          descricaoImposto = imp.label;
          break;
        }
      }
    }
    return descricaoImposto;
  };

  getDescricaoOperacao = codOperacao => {
    let descricaoOper = '';
    let listOp = this.state.tipoOperacoes;
    for (let i = 0; i < listOp.length; i++) {
      const op = listOp[i];
      if (parseInt(op.value) === parseInt(codOperacao)) {
        descricaoOper = op.label;
        break;
      }
    }
    return descricaoOper;
  };

  getDescricaoAliquota = codAliquota => {
    let descricaoAliq = '';
    let listAliq = this.state.tipoAliquotas;
    for (let i = 0; i < listAliq.length; i++) {
      const aliq = listAliq[i];
      if (parseInt(aliq.value) === parseInt(codAliquota)) {
        descricaoAliq = aliq.label;
        break;
      }
    }
    return descricaoAliq;
  };

  render() {
    const {
      codigoGrupoImposto,
      descricaoGrupoImposto,
      ativo,
      //lista de aliquota
      tipoAliquota,
      situacaoTributaria,
      tipoOperacao,
      codigoTipoImposto,
      codigoNaturezaReceita,

      //combos
      tipoOperacoes,
      tipoAliquotas,
      tipoImpostos,

      rowVersion,
      isIcmsSelected
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="Grupo de Impostos">
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="Descrição"
                    value={descricaoGrupoImposto}
                    name="descricaoGrupoImposto"
                    icone={
                      String(descricaoGrupoImposto) !== '' ? 'icon-lx-plus' : ''
                    }
                    maxlength="50"
                    onChange={this.handleInputChange}
                    onIconeClick={this.handleExibir}
                    onKeyDown={event => {
                      if (
                        event.keyCode === 13 &&
                        String(descricaoGrupoImposto) !== ''
                      )
                        this.handleExibir();
                    }}
                  />
                </div>
                <div className="col-1">
                  <Checkbox label="Ativo">
                    <Checkitem
                      label=""
                      name="ativo"
                      checked={ativo}
                      onChange={this.handleInputChange}
                      disabled={codigoGrupoImposto === null}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div
                  className={ifShow(
                    !this.state.codigoGrupoImposto,
                    'button-container'
                  )}
                >
                  <Botao
                    title="Adicionar"
                    icon="icon-lx-plus"
                    onClick={this.handleExibir}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title="Impostos"
              accordion="false"
              visible={
                this.state.exibirCadastro || this.state.rowVersion !== null
              }
            >
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Imposto"
                    name="codigoTipoImposto"
                    value={codigoTipoImposto}
                    onChange={this.changeImposto}
                    options={tipoImpostos}
                    //disabled={codigoGrupoImposto !== null}
                  />
                </div>
                <div
                  className={ifShow(
                    this.state.codigoTipoImposto === TipoImposto.Pis ||
                      this.state.codigoTipoImposto === TipoImposto.Cofins,
                    'col-6'
                  )}
                >
                  <Select
                    label="Operação"
                    name="tipoOperacao"
                    value={tipoOperacao}
                    options={tipoOperacoes}
                    //disabled={codigoGrupoImposto !== null}
                    onChange={this.changeOperacao}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={ifShow(this.state.codigoTipoImposto > 0, 'col-6')}
                >
                  <Select
                    label="Tipo de alíquota"
                    name="tipoAliquota"
                    value={tipoAliquota}
                    onChange={this.changeAliquota}
                    //disabled={codigoGrupoImposto !== null}
                    options={tipoAliquotas}
                  />
                </div>
                <div
                  className={ifShow(
                    this.state.codigoTipoImposto === TipoImposto.Pis ||
                      this.state.codigoTipoImposto === TipoImposto.Cofins ||
                      this.state.codigoTipoImposto === TipoImposto.Icms,
                    'col-6'
                  )}
                >
                  <Select
                    label="Situação tributária"
                    name="situacaoTributaria"
                    value={situacaoTributaria}
                    onChange={this.changeTributacao}
                    //disabled={codigoGrupoImposto !== null}
                    //options={this.getSituacaoTribut(codigoTipoImposto, tipoOperacao)}
                    options={this.state.situacoes}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className={ifShow(
                    this.state.codigoTipoImposto === TipoImposto.Pis ||
                      this.state.codigoTipoImposto === TipoImposto.Cofins,
                    'col-12'
                  )}
                >
                  <Select
                    label="Natureza da receita"
                    name="codigoNaturezaReceita"
                    value={codigoNaturezaReceita}
                    onChange={this.handleInputChange}
                    //disabled={codigoGrupoImposto !== null}
                    options={this.state.naturezas}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <div className="button-container">
                    <Botao
                      title="Adicionar"
                      icon="icon-lx-plus"
                      onClick={this.handleAdicionar}
                    />
                  </div>
                </div>
              </div>
              <ReactTable
                show={this.state.exibirListImposto}
                defaultPageSize={5}
                onClick={this.handleTableClick}
                data={this.state.aliquota}
                columns={[
                  {
                    accessor: 'codigoTipoImposto',
                    Header: '',
                    show: false,
                    style: { 'white-space': 'unset' }
                  },
                  {
                    accessor: 'descricaoTipoImposto',
                    Header: 'Imposto',
                    width: 70,
                    filterable: false,
                    style: { 'white-space': 'unset' },
                    sortable: false
                  },
                  {
                    accessor: 'tipoOperacao',
                    Header: '',
                    show: false,
                    style: { 'white-space': 'unset' }
                  },
                  {
                    accessor: 'descricaoOperacao',
                    Header: 'Operação',
                    width: 70,
                    filterable: false,
                    style: { 'white-space': 'unset' },
                    sortable: false
                  },
                  {
                    accessor: 'situacaoTributaria',
                    Header: '',
                    show: false,
                    style: { 'white-space': 'unset' }
                  },
                  {
                    accessor: 'descricaoSituacao',
                    Header: 'Situação tributária',
                    width: 70,
                    filterable: false,
                    style: { 'white-space': 'unset' },
                    sortable: false
                  },
                  {
                    accessor: 'codigoNaturezaReceita',
                    Header: 'Natureza receita',
                    width: 70,
                    filterable: false,
                    style: { 'white-space': 'unset' },
                    sortable: false
                  },
                  {
                    accessor: 'tipoAliquota',
                    Header: '',
                    show: false,
                    style: { 'white-space': 'unset' }
                  },
                  {
                    accessor: 'descricaoAliquota',
                    Header: 'Tipo alíquota',
                    width: 70,
                    filterable: false,
                    style: { 'white-space': 'unset' },
                    sortable: false
                  },
                  {
                    accessor: 'percentualValor',
                    Header: 'Alíquota',
                    sortable: false,
                    //width: 100,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.percentualValor}
                          name="percentualValor"
                          type="number"
                          maxlength="6"
                          decimalScale={2}
                          onChange={event =>
                            this.handleChangeGrid(
                              event.floatValue,
                              'percentualValor',
                              //props.original.codigoTipoImposto
                              props.row._index
                            )
                          }
                          allowNegative={false}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'descricao',
                    Header: 'Descrição',
                    sortable: false,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.descricao}
                          name="descricao"
                          maxlength="30"
                          onChange={event =>
                            this.handleChangeGrid(
                              event.target.value,
                              'descricao',
                              //props.original.codigoTipoImposto
                              props.row._index
                            )
                          }
                        />
                      );
                    }
                  },
                  {
                    accessor: 'quantidade',
                    Header: 'Quantidade',
                    sortable: false,
                    //width: 80,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.quantidade}
                          name="quantidade"
                          type="number"
                          maxlength="4"
                          onChange={event =>
                            this.handleChangeGrid(
                              event.floatValue,
                              'quantidade',
                              //props.original.codigoTipoImposto
                              props.row._index
                            )
                          }
                          allowNegative={false}
                          disabled={sameInt(
                            props.original.tipoAliquota,
                            TipoAliquota.Percentual
                          )}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'percentualReducao',
                    Header: '% Redução Base Cálculo',
                    sortable: false,
                    //width: 80,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.percentualReducao}
                          name="percentualReducao"
                          type="number"
                          decimalScale={2}
                          maxlength="6"
                          onChange={event =>
                            this.handleChangeGrid(
                              event.floatValue,
                              'percentualReducao',
                              props.row._index
                              //props.original.codigoTipoImposto
                            )
                          }
                          allowNegative={false}
                          disabled={
                            !this.getApresentaBase(
                              props.original.situacaoTributaria
                            )
                          }
                          isAllowed={values => {
                            return isAllowed(values, 100);
                          }}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'percentualEfetivo',
                    Header: 'Alíquota Efetiva',
                    sortable: false,
                    show: isIcmsSelected,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.percentualEfetivo}
                          name="percentualEfetivo"
                          type="number"
                          decimalScale={2}
                          onChange={event =>
                            this.handleChangeGrid(
                              event.floatValue,
                              'percentualEfetivo',
                              props.row._index
                              //props.original.codigoTipoImposto
                            )
                          }
                          allowNegative={false}
                          disabled={
                            !this.showAliquota(
                              props.original.situacaoTributaria
                            )
                          }
                          isAllowed={values => {
                            return isAllowed(values, 100);
                          }}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'percentualReducaoBaseEfetivo',
                    Header: '% Redução Alíquota Efetiva',
                    sortable: false,
                    show: isIcmsSelected,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.percentualReducaoBaseEfetivo}
                          name="percentualReducaoBaseEfetivo"
                          type="number"
                          decimalScale={2}
                          onChange={event =>
                            this.handleChangeGrid(
                              event.floatValue,
                              'percentualReducaoBaseEfetivo',
                              props.row._index
                              //props.original.codigoTipoImposto
                            )
                          }
                          allowNegative={false}
                          disabled={
                            !this.showAliquota(
                              props.original.situacaoTributaria
                            )
                          }
                          isAllowed={values => {
                            return isAllowed(values, 100);
                          }}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'percentualDesonerado',
                    Header: '% Desoneração',
                    sortable: false,
                    show: isIcmsSelected,
                    Cell: props => {
                      return (
                        <InputText
                          value={props.row.percentualDesonerado}
                          name="percentualDesonerado"
                          type="number"
                          decimalScale={2}
                          onChange={event =>
                            this.handleChangeGrid(
                              event.floatValue,
                              'percentualDesonerado',
                              props.row._index
                              //props.original.codigoTipoImposto
                            )
                          }
                          allowNegative={false}
                          disabled={
                            !this.showDesoneracao(
                              props.original.situacaoTributaria
                            )
                          }
                          isAllowed={values => {
                            return isAllowed(values, 100);
                          }}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'rowVersion',
                    Header: '',
                    show: false,
                    style: { 'white-space': 'unset' }
                  },
                  {
                    accessor: 'ativo',
                    Header: 'Ativo',
                    sortable: false,
                    width: 72,
                    Cell: props => {
                      return (
                        <Checkbox label="">
                          {' '}
                          {/* deixar com label="" */}
                          <Checkitem
                            name="ativo"
                            checked={props.row.ativo}
                            onChange={event =>
                              this.handleChangeGrid(
                                event.target.checked,
                                'ativo',
                                //props.original.codigoTipoImposto
                                props.row._index
                              )
                            }
                          />
                        </Checkbox>
                      );
                    }
                  },
                  {
                    accessor: 'acao',
                    Header: 'Excluir',
                    sortable: false,
                    width: 75,
                    filterable: false,
                    Cell: props => {
                      return (
                        <Botao
                          ic
                          icon={'icon-lx-trash'}
                          //onClick={this.eliminarImposto}
                          onClick={() => {
                            deleteInList(this, 'aliquota', props.row._index);
                          }}
                        />
                      );
                    }
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (this.props.onClick) {
                        this.props.onClick(state, rowInfo, column, instance, e);
                      }

                      if (handleOriginal) {
                        handleOriginal();
                      }
                    }
                  };
                }}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
              />
            </SectionContent>
          </SectionContainer>
        </LinxPostos>

        <Footer
          saveAction={
            !rowVersion
              ? !this.state.salvando
                ? this.handleSalvar
                : null
              : menuPermiteAlteracao(PATH_MENU)
              ? !this.state.salvando
                ? this.handleSalvar
                : null
              : null
          }
          deleteAction={
            !codigoGrupoImposto
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
          cancelAction={this.handleCancelar}
        />
        {tagAlert(this)}
      </>
    );
  }
}

FormGrupoImpostos = withRouter(FormGrupoImpostos);

class Impostos extends Component {
  state = {
    grupoImpostos: [],
    grupoImpostoSel: {}
  };

  async doMount() {
    const { data: gi } = await getGrupoImpostos(
      getCnpj(),
      '',
      -1,
      -1,
      -1,
      true
    );
    const impostoArray = gi.result;
    const imps = impostoArray.map(g => {
      return {
        codigoGrupoImposto: g.codigo,
        descricaoGrupoImposto: g.descricao,
        rowVersion: g.rowVersion,
        aliquota: g.aliquota,
        ativo: g.ativo,
        temProduto: g.temProduto
        //rowVersion: g.rowVersion
      };
    });

    //const listaNova = this.removeOlho(imps);

    const giMoOrd = imps.sort(function(a, b) {
      if (parseInt(a.descricaoGrupoImposto) < parseInt(b.descricaoGrupoImposto))
        return -1;
      if (parseInt(a.descricaoGrupoImposto) > parseInt(b.descricaoGrupoImposto))
        return 1;
      return 0;
    });

    this.setState({ grupoImpostos: giMoOrd });
  }

  async componentDidMount() {
    this.doMount();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.doMount();
    }
  }

  removeOlho = list => {
    let result = [];
    list.forEach(i => {
      if (i.descricaoGrupoImposto.substr(0, 10) !== 'LEI 12.741')
        result.push(i);
    });
    return result;
  };

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.setState({ grupoImpostoSel: rowInfo.original });
      //const g = this.stategrupoImpostoSel;
      this.props.history.push(NEW_PATH, {
        grupoImpostoSel: rowInfo.original,
        grupoimpostos: this.state.grupoImposto
      });
    }
  };

  render() {
    const { edit } = this.props;
    let grupoImpostoSel = {};
    //let grupoTipoExistentes = [];
    if (edit) {
      // this.props.location.state por padrao eh undefined
      if (this.props.location.state) {
        grupoImpostoSel = this.props.location.state.grupoImpostoSel;
        //grupoTipoExistentes = this.props.location.state.grupoTipoExistentes;
      }
    }
    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <FormGrupoImpostos
              grupoImposto={grupoImpostoSel}
              grupoTipoExistentes={this.state.grupoImposto}
            />
          ) : (
            <>
              <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                <SectionHeader
                  subtitle=""
                  right={
                    <div className="button-container">
                      {edit ? (
                        <></>
                      ) : menuPermiteInclusao(PATH_MENU) ? (
                        <Botao
                          ic
                          icon="icon-lx-plus"
                          onClick={() => {
                            this.setState({ grupoImpostoSel: {} });
                            this.props.history.push(NEW_PATH, {
                              grupoImpostoSel: {},
                              grupoTipoExistentes: this.state.grupoImposto
                            });
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  }
                />
                <List
                  title="Grupos de Impostos"
                  onClick={this.handleTableClick}
                  cols={[
                    {
                      accessor: 'descricaoGrupoImposto',
                      Header: 'Descrição',
                      width: 500,
                      filterable: false
                    },
                    {
                      accessor: 'ativo',
                      Header: 'Ativo',
                      width: 100,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <Botao
                            secondary={!row.ativo}
                            ic
                            icon={row.ativo ? 'icon-lx-check' : 'icon-lx-close'}
                          />
                        );
                      }
                    }
                  ]}
                  rows={this.state.grupoImpostos}
                />
              </LinxPostos>
            </>
          )}
        </section>
      </main>
    );
  }
}

function ifShow(condition, divName) {
  return condition ? divName : 'content-hidden';
}

Impostos = withRouter(Impostos);
export { Impostos };
