import { DateBox } from 'devextreme-react';
import React from 'react';
import { useDispatch } from 'react-redux';

export function FloatDateInput({ value, onChange, id, label, placeholder, displayFormat = 'dd/MM/yyyy', isMinTime = true }) {
  const dispatch = useDispatch();
  const getMaxTime = (date = null) => {
    if(!date)date = new Date();
    return new Date(date.setHours(23, 59, 59, 999));
  };
  const getMinTime = (date = null) => {
    if(!date)date = new Date();
    return new Date(date.setHours(0, 0, 0, 0));
  };
  return (

    <div className="form-group input-group">
      <span className="has-float-label w-100">
        <DateBox
          id={id}
          className={`border-top-0 border-left-0 border-right-0 float-date-input`}
          placeholder={placeholder}
          value={value ? (isMinTime ? getMinTime(new Date(value)) : getMaxTime(new Date(value))) : null}
          displayFormat={displayFormat}
          onValueChange={e => {
            dispatch(
              onChange({
                fieldName: id,
                value: e ? (isMinTime ? getMinTime(e).toISOString() : getMaxTime(e).toISOString()) : null,
              }),
            );
          }}
          defaultValue={isMinTime ? getMinTime() : getMaxTime()}
          type={displayFormat.indexOf('hh:mm') > -1 ? 'datetime' : 'date'}
        />
        <label htmlFor={id}>{label}</label>
      </span>
    </div>
  );
}
