import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import {
  getUrl,
  getNomePagina,
  getDadosSellerPop,
  getNomePaginaAmigavel
} from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';
import styles from '../dashboard/contas-receber/ContasReceber.module.scss';
import { TIPO_SITE } from '../../shared/constants/TipoSiteConstant';

export function ManterLMCeMedicao(props) {
  const PATH_MENU1 = menus.COD_17110;
  const PATH_MENU2 = menus.COD_17300;

  const [showFrame1, setShowFrame1] = useState(false);
  const [showFrame2, setShowFrame2] = useState(false);
  const [loadedFrame1, setLoadedFrame1] = useState(false);
  const [loadedFrame2, setLoadedFrame2] = useState(false);
  const [loadingFrame1, setLoadingFrame1] = useState(false);
  const [loadingFrame2, setLoadingFrame2] = useState(false);

  useEffect(() => {
    if (props.lmc) {
      onClickFrame1();
    } else if (props.medicao) {
      onClickFrame2();
    }
  }, []);

  const onClickFrame1 = () => {
    if (!loadedFrame1) {
      setLoadingFrame1(true);
    }
    setLoadedFrame1(true);
    setShowFrame2(false);
    setShowFrame1(true);
  };

  const onClickFrame2 = () => {
    if (!loadedFrame2) {
      setLoadingFrame2(true);
    }
    setLoadedFrame2(true);
    setShowFrame1(false);
    setShowFrame2(true);
  };

  const hideSpinner = () => {
    console.log('Loaded.')
    setLoadingFrame1(false);
    setLoadingFrame2(false);
  };

  return (
    <LinxPostos
      breadcrumb={getNomePagina(showFrame1 ? PATH_MENU1 : PATH_MENU2)}
    >
      <div className="row">
        <button className={showFrame1 ? 'btn' : 'btn btn-primary'} onClick={onClickFrame1}>
          LMC
        </button>
        <button className={showFrame2 ? 'btn' : 'btn btn-primary'} onClick={onClickFrame2}>
          Medição de Tanques/Compressor GNV
        </button>
      </div>
      {loadedFrame1 && (
        <div id="Frame1" style={{ display: showFrame1 ? 'block' : 'none' }}>
          {loadingFrame1 ? (
            <div className={styles.loader}>
              <h1>Carregando {getNomePaginaAmigavel(PATH_MENU1)}</h1>
              <svg className={`${styles.spinner} spinner`} viewBox="0 0 50 50">
                <circle
                  className={`${styles.path} path`}
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          ) : null}
          <Iframe
            url={getUrl() + '/ManterLMC.aspx?' + getDadosSellerPop(PATH_MENU1)}
            className={`${TIPO_SITE === "RetaguardaSellerWeb" ? "iframePopReduzidoMenuTop" : "iframePopReduzidoMenuLeft"}`}
            onLoad={hideSpinner}
          />
        </div>
      )}
      {loadedFrame2 && (
        <div id="Frame2" style={{ display: showFrame2 ? 'block' : 'none' }}>
          {loadingFrame2 ? (
            <div className={styles.loader}>
              <h1>Carregando {getNomePaginaAmigavel(PATH_MENU2)}</h1>
              <svg className={`${styles.spinner} spinner`} viewBox="0 0 50 50">
                <circle
                  className={`${styles.path} path`}
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          ) : null}
          <Iframe
            url={getUrl() + '/ManterMedicao.aspx?' + getDadosSellerPop(PATH_MENU2)}
            className={`${TIPO_SITE === "RetaguardaSellerWeb" ? "iframePopReduzidoMenuTop" : "iframePopReduzidoMenuLeft"}`}
            onLoad={hideSpinner}
          />
        </div>
      )}
    </LinxPostos>
  );
}

export default ManterLMCeMedicao;
