import React from 'react';
import { Card } from 'react-bootstrap';
import ItemTurno from '../item-turno/ItemTurno';

/**
 * Componente `ListaDeTurnos` responsável por renderizar uma lista de cards de turnos.
 *
 * @param {Object} props Propriedades do componente.
 * @param {Array} props.turnos Lista de dados de turnos a serem exibidos.
 *
 * @returns {React.Component} Retorna uma lista de componentes `ItemTurno`.
 */
const ListaDeTurnos = props => {
  const { turnos } = props;
  return (
    <>
      {turnos && turnos.length > 0 && (
        <Card>
          <Card.Body>
            <Card.Title>
              {`${turnos.length} Turno${turnos.length > 1 ? 's' : ''} localizado${turnos.length > 1 ? 's' : ''}`}
            </Card.Title>
            <Card.Text>

            </Card.Text>

            <div className="row">
              {turnos.map((turno, index) => (
                <ItemTurno
                  key={index}
                  turno={turno}
                  onBusy={msg => {
                    if (props.onBusy) props.onBusy(msg);
                  }}
                  onUnBusy={() => {
                    if (props.onUnBusy) props.onUnBusy();
                  }}
                  selectedTurno={t => {
                    console.log('selectedTurno', t);
                    if (props.selectedTurno) props.selectedTurno(t);
                  }}
                />
              ))}
            </div>
          </Card.Body>
        </Card>
      )}
      {turnos && turnos.length === 0 && (
        <Card>
          <Card.Body>
            <Card.Title>NENHUM TURNO LOCALIZADO</Card.Title>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default ListaDeTurnos;
