import { DropDownBox, List } from 'devextreme-react';
import React, { useCallback, useEffect, useState } from 'react';
import { selectFiltroOs, useFiltroOs } from '../../../../redux/slices/consignadoComodato/filtroOsSlice';
import { useDispatch, useSelector } from 'react-redux';

import { Card } from 'react-bootstrap';
import { DropDownImage } from '../../consignado-comodato-nova-os/components/drop-down-image';
import styles from './Component.module.scss';
import { selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';

export function DropboxOrigens({ statusCss, className, label }) {
  const [opened, setOpened] = useState(false);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);
  const { values } = useSelector(selectFiltroOs);
  const origens = useSelector(selectConsignadoComodatoSharedData).origens;
  const dispatch = useDispatch();
  const { changeValue } = useFiltroOs();

  useEffect(() => {
    if(!origens) return;
    const src = (values.origens || '').split('_').map(x => parseInt(x, 10));
    const its = [];
    const data = origens.filter(x => x.id > -1);
    for (const it of src) {
      const res = data.findIndex(x => x.id === it);
      if (res === null || res === undefined) continue;
      its.push(res + 1);
    }
    setSelectedItemKeys(its);
  }, [values.origens, origens]);

  const onSelectedItemKeysChange = useCallback(
    args => {
      if (args.name === 'selectedItemKeys') {
        if (args.value.join('_') === selectedItemKeys.join('_')) return;
        const ids = [];
        for (const it of args.value) {
          ids.push(origens.filter(x => x.id > -1)[it - 1].id);
        }
        dispatch(changeValue({ fieldName: 'origens', value: ids.join('_') }));
        setSelectedItemKeys(args.value);
      }
    },
    [dispatch, changeValue, selectedItemKeys, origens],
  );
  return (
    <div className={`${className} mt-1`}>
      <DropDownBox
        showClearButton={false}
        className={`${styles.bottonBorder} ml-1`}
        placeholder="Escolha as origens"
        valueExpr="id"
        displayExpr="nome"
        showDropDownButton={true}
        dropDownButtonRender={DropDownImage}
        dataSource={origens.filter(x => x.id > -1)}
        openOnFieldClick={true}
        value={selectedItemKeys}
        onOpenedChange={e => {
          setOpened(e);
        }}
        opened={opened}
        onKeyUp={e => {
          if (opened) e.preventDefault();
          if (
            e.event.originalEvent.code === 'ArrowDown' ||
            e.event.originalEvent.key === 'ArrowDown' ||
            e.event.originalEvent.code === 'Enter' ||
            e.event.originalEvent.key === 'Enter' ||
            e.event.originalEvent.code === 'Space' ||
            e.event.originalEvent.key === 'Space'
          ) {
            setOpened(true);
          }
        }}
        contentRender={() => {
          return (
            <>
              <Card>
                <Card.Body>
                  <List
                    noDataText="Sem origem"
                    keyExpr="id"
                    displayExpr="nome"
                    selectAllText="TODOS"
                    dataSource={origens.filter(x => x.id > -1)}
                    checkedExpr="checked"
                    showSelectionControls={true}
                    selectionMode={'all'}
                    onOptionChanged={onSelectedItemKeysChange}
                    selectedItemKeys={selectedItemKeys}
                  />
                </Card.Body>
                <Card.Footer>
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      setOpened(false);
                    }}
                  >
                    OK
                  </button>
                </Card.Footer>
              </Card>
            </>
          );
        }}
      />
      <span className={`${styles[statusCss]}`}>&nbsp;{label}</span>
    </div>
  );
}
