import axios from "axios";
import { TOKEN_PASS_AXIOS } from "../shared/constants/Const";
import { getUrl, getCodigoEstabelecimento, getCnpj } from "../shared/utils/Utils";
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function montaComboSetor() {
  var codigoEstabelecimento = getCodigoEstabelecimento();
  return axios.get(`LxApi/v1/Setor/MontarCombo/`, {
    params: { codigoEstabelecimento }
  });
}

export function getSetores(cnpjEstabelecimento) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v2/Setor/MontarCombo`, {
    params: { cnpjEstabelecimento: getCnpj() }
  });
}
