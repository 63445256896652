import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Sidenav.module.scss';
 
const MenuItem = ({ menu, toggleMenu, nivelPai = 0 }) => {
  const [nivel, setNivel] = useState(0);
  useEffect(() => {
    var i = nivelPai + 1;
    setNivel(i);
  }, [nivelPai]);
 
  return (
    <div className={`${styles.menuChildren} menuChildren ${nivel === 2 ? styles.menuChildrenChildren : '' } ${nivel > 2 ? styles[`menu${nivel}nivel`] : '' } ${menu.expanded ? styles.expanded : ''}`}>
      {menu.expanded &&
        menu.children.map(children => (
          <ul key={children.codigo}>
            {children.link ? (
              <Link to={children.link}>
                <li className={`${styles.children} children ${nivel === 2 ? styles.childrenChildren : ''} ${nivel > 2 ? styles[`menu${nivel}nivel`] : '' } ${menu.expanded ? styles.fadeInChildren : ''}`} key={children.codigo}>
                  {children.icon && <i className={`${children.icon} icon`}>&nbsp;&nbsp;</i>}
                  {children.title}
                </li>
              </Link>
            ) : (
              <>
                <li className={`${styles.children} children ${nivel === 2 ? styles.childrenChildren : ''} ${nivel > 2 ? styles[`menu${nivel}nivel`] : '' } ${menu.expanded ? styles.fadeInChildren : ''}`} onClick={() => toggleMenu(children)} key={children.codigo}>
                  {children.title}
                  <i
                    className={`${styles.arrow} arrow ${children.expanded ? styles.rotate : ''} icon icon-lx-angle-right
                                        float-right`}
                  ></i>
                </li>
                <MenuItem menu={children} toggleMenu={toggleMenu} nivelPai={nivel}></MenuItem>
              </>
            )}
          </ul>
        ))}
    </div>
  );
};
 
export default MenuItem;