import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputDate,
  InputText,
  Select,
  Checkbox,
  Checkitem,
  FormOptions,
  handleInputChange
} from '../../../components/formulario/Formulario';
import { sortFloat } from '../../../components/formulario/List';
import {
  SectionContainer,
  SectionContent,
  SectionContentSpecial
} from '../../../components/section/Content';
import {
  Alert,
  tagAlert,
  showError,
  showWait,
  hideWait,
  showLoadError,
  showInfo,
  hideAlert,
  showSaveError
} from '../../../components/alert/Alert';
import ReactTable from 'react-table';
import 'react-table/react-table.css';

import { withRouter } from 'react-router-dom';

import {
  menuPermiteAlteracao,
  getNomePagina,
  validaDadosLogin,
  getMaxFinalSmallDateString,
  emptyText,
  isObjectSelected,
  menuPermiteConsulta,
  sameInt,
  sameString
} from '../../../shared/utils/Utils';
import moment from 'moment';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from '../../../components/modal/Modal';

import {
  listarConferencias,
  listarTurnos,
  listarFormasPagamento,
  listarDocumentosFiscais,
  salvarTurno,
  conferir,
  reabrirTurno,
  montaComboSituacao,
  montaComboOperadores,
  montaComboMaquinas,
  selecionarFormaPgto,
  salvarFormaPgto,
  montaComboFormasPagamento
} from './ConferenciaTurno.service';

import {
  montaComboRede,
  montaComboBandeira,
  montaComboProdutoRede
} from '../../configuracoes/estrutura-cartoes/EstruturaCartoes.service';

import {
  montarComboClientes,
  getPessoa,
  getPessoas,
  montaComboAutorizados
} from '../../cadastro/pessoas/Pessoas.service';

import { LAST_SAFE_SMALLDATE } from '../../../shared/constants/Const';
import { ModalClienteFornecedor } from '../gerenciamento-financeiro/FormLancamento/FormLancamento';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';

import { menus } from '../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60680;
export const titulo = 'Conferência de turno';

const CODIGO_CONSUMIDOR_FINAL = 2;

export const CodigoFormaPgto = {
  Dinheiro: '-1',
  Cheque: '-2',
  Ticket: '-3',
  Credito: '-4',
  Vale: '-5',
  AFaturar: '-6',
  Debito: '-7',
  Troco: '-11',
};

export const IdFormaPgto = {
  Dinheiro: '1',
  Cheque: '2',
  Ticket: '3',
  Credito: '4',
  Vale: '5',
  AFaturar: '6',
  Debito: '7'
};

export const DocumentoFiscal = {
  Sangria: 'Sangria',
  NFCe: 'Nota Fiscal Eletrônica ao Consumidor Final – NFC-e'
};

//BACKEND trata diferente ID de Código;
//ID = Positivo
//Codigo = Negativo

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    if (!this.props.listaDatas) {
      // para não acessarem a página /new direto
      this.props.history.push('/financeiro/conferencia-caixa/');
    }

    const listaTurnosOperadores = this.props.listaOperadores;
    const listaDatas = this.props.listaDatas;
    const mostrarBotaoGeral = this.props.mostrarBotaoGeral;

    this.state = {
      modalAlteracaoForma: false,
      modalInfo: false,
      tesouraria: 0,
      diferenca: 0,
      listaTurnosOperadores,
      listaDatas,
      displayInfoCompl: false,
      listaClientesSelecionados: [],
      listaFormasPgtoSelecionadas: [],
      mostrarBotaoGeral
    };
  }

  forcarUpdate = async () => {
    const listaDatas = this.state.listaDatas;
    var consulta = listaDatas.consulta;
    const { data: listaTurnos } = await listarTurnos(consulta.dia);

    const resultListaTurnos = listaTurnos.result;
    this.atualizaListaOperadores(resultListaTurnos);
    this.forceUpdate();
  };

  atualizaListaOperadores = listaTurnosDia => {
    const listaTurnosOperadores = [];
    var mostrarBotaoGeral = false;
    var cCheck = '✓';
    for (let i = 0; i < listaTurnosDia.length; i++) {
      const e = listaTurnosDia[i];
      var itemTurno = {};
      itemTurno.id = i + 1;
      itemTurno.codigoTurno = e.codigoTurno;
      itemTurno.codigoOperador = e.operador.codigo;

      var dataInicioTurno = moment(e.inicioTurno).format('HH:mm');
      itemTurno.dataInicioTurno = dataInicioTurno;
      if (e.fimTurno) {
        var dataFimTurno = moment(e.fimTurno).format('HH:mm');
        itemTurno.dataFimTurno = dataFimTurno;
        itemTurno.label =
          e.operador.descricao + '  |  ' + dataInicioTurno + '-' + dataFimTurno;
      } else
        itemTurno.label =
          e.operador.descricao + '  |  ' + dataInicioTurno + '- ';
      if (e.dataConferencia) {
        itemTurno.label = itemTurno.label + cCheck;
        itemTurno.dataConferencia = e.dataConferencia;
        itemTurno.responsavelConferencia = e.usuarioConferente.descricao;
      }
      //itemTurno.label = itemTurno.label + {" "};
      itemTurno.label =
        itemTurno.label +
        ' Total Suprimentos: R$ ' +
        parseFloat(e.valores.valorSuprimentos).toFixed(2) +
        ' | Total Recebimentos: R$ ' +
        parseFloat(e.valores.valorRecebimentos).toFixed(2) +
        ' | Total Sangrias: R$ ' +
        parseFloat(e.valores.valorSangrias).toFixed(2) +
        ' | ';
      itemTurno.formasPgto = [];

      if (!itemTurno.dataConferencia)
        if (itemTurno.dataFimTurno) mostrarBotaoGeral = true;

      listaTurnosOperadores.push(itemTurno);
    }
    this.setState({ listaTurnosOperadores, mostrarBotaoGeral });
  };

  abrirModalAlteracaoForma = doc => {
    this.setState({ modalAlteracaoForma: true });
  };

  abrirModalInfo = (responsavel, data) => {
    //console.log('abriu info');

    var dataC = moment(data).format('DD-MM-YYYY');
    var horaC = moment(data).format('HH:mm');
    this.setState({
      modalInfo: true,
      responsavelConferencia: responsavel,
      dataConferencia: dataC,
      horaConferencia: horaC
    });
  };

  handleCloseModalForma = async (
    modificarEstrutura,
    codigoEcf,
    codigoVenda,
    codigoMovimento,
    data
  ) => {
    this.setState({ modalAlteracaoForma: false });
    if (modificarEstrutura) {
      const salvou = await this.salvarFormaPagamento(
        codigoEcf,
        codigoVenda,
        codigoMovimento,
        data
      );
      if (salvou) {
        this.forcarUpdate();
      }
    }
  };

  handleAdicionaPagamento = lista => {
    this.setState({ listaFormasPgtoDocumentoFiscal: lista });
  };

  handleAdicionaCliente = lista => {
    this.setState({ listaClientesSelecionados: lista });
  };

  handleCloseModalInfo = () => {
    this.setState({ modalInfo: false });
  };

  carregarListaFormasPgto = async codigoTurno => {
    const listaDatas = this.state.listaDatas;
    var consulta = listaDatas.consulta;
    consulta.codigoTurno = codigoTurno;
    try {
      const { data: listaFormasPgto } = await listarFormasPagamento(
        codigoTurno
      );
      const resultListaFormasPgto = listaFormasPgto.result;
      this.inserirFormasPgtoTurno(resultListaFormasPgto, codigoTurno);
    } catch (e) {
      showLoadError(this, titulo, e);
    }

    listaDatas.consulta = consulta;
    this.setState({ listaDatas });
  };

  inserirFormasPgtoTurno = (listaFormasPgtoTurno, codigoTurno) => {
    var listaTO = this.state.listaTurnosOperadores;
    const listaFormasPgtoTratada = [];
    for (let i = 0; i < listaFormasPgtoTurno.length; i++) {
      const e = listaFormasPgtoTurno[i];
      var itemFormaPgto = {};
      itemFormaPgto.id = e.formaPagamento.codigo;
      itemFormaPgto.descricao = e.formaPagamento.descricao;
      itemFormaPgto.label = e.formaPagamento.descricao;
      itemFormaPgto.codigoTurno = codigoTurno;

      itemFormaPgto.suprimentos = e.valores.valorSuprimentos;
      itemFormaPgto.recebimentos = e.valores.valorRecebimentos;
      itemFormaPgto.sangrias = e.valores.valorSangrias;
      itemFormaPgto.valorRecebidoTesouraria = e.valores.valorRecebidoTesouraria;
      itemFormaPgto.valorDiferenca =
        itemFormaPgto.valorRecebidoTesouraria -
        (itemFormaPgto.recebimentos + itemFormaPgto.suprimentos);
      itemFormaPgto.valorDiferenca =
        'R$ ' + parseFloat(itemFormaPgto.valorDiferenca).toFixed(2);

      //itemFormaPgto.label = itemFormaPgto.label + {" "};
      itemFormaPgto.label =
        itemFormaPgto.label +
        ' Suprimentos: R$ ' +
        parseFloat(e.valores.valorSuprimentos).toFixed(2) +
        ' | Recebimentos: R$ ' +
        parseFloat(e.valores.valorRecebimentos).toFixed(2) +
        ' | Sangrias: R$ ' +
        parseFloat(e.valores.valorSangrias).toFixed(2) +
        ' | ';
      itemFormaPgto.docs = [];
      itemFormaPgto.tiposOperacao = [];
      listaFormasPgtoTratada.push(itemFormaPgto);
    }

    for (let i = 0; i < listaTO.length; i++) {
      const itemTO = listaTO[i];
      if (itemTO.codigoTurno === codigoTurno) {
        listaTO[i].formasPgto = listaFormasPgtoTratada;
      }
    }
    this.setState({ listaTurnosOperadores: listaTO });
  };

  carregarListaDocumentosFiscais = async (
    codigoFormaPagamento,
    codigoOperador
  ) => {
    const listaDatas = this.state.listaDatas;
    var consulta = listaDatas.consulta;
    consulta.operadores = [codigoOperador];
    consulta.formaPagamento = codigoFormaPagamento;
    const { data: listaDocumentosFiscais } = await listarDocumentosFiscais(
      consulta
    );
    const resultListaDocsFiscais = listaDocumentosFiscais.result;

    const docsOrd = resultListaDocsFiscais.sort(function(a, b) {
      if (a.valor < b.valor) return 1;
      if (a.valor > b.valor) return -1;
      return 0;
    });

    this.inserirDocumentosFiscais(
      docsOrd,
      consulta.codigoTurno,
      codigoFormaPagamento
    );

    listaDatas.consulta = consulta;
    this.setState({ listaDatas });
  };

  inserirDocumentosFiscais = (
    listaDocumentosFiscais,
    codigoTurno,
    codigoFormaPagamento
  ) => {
    var listaTO = this.state.listaTurnosOperadores;
    var listaDocsFiscaisTratada = [];
    const listaTipoEmissaoTratada = [];
    // const listaProdutoCartaoTratada = [];

    const listaTipoEmissaoOriginal = [];

    var listaTipoEmissaoExistente = false;
    var listaProdutoCartaoExistente = false;

    for (let i = 0; i < listaDocumentosFiscais.length; i++) {
      const docFiscal = listaDocumentosFiscais[i];
      if (
        parseInt(codigoFormaPagamento) !== parseInt(IdFormaPgto.Ticket) &&
        parseInt(codigoFormaPagamento) !== parseInt(IdFormaPgto.Credito) &&
        parseInt(codigoFormaPagamento) !== parseInt(IdFormaPgto.Debito)
      ) {
        if (!docFiscal.tipoEmissao) {
          let tempDocFiscal = {};
          tempDocFiscal.tipoDocumentoFiscal =
            docFiscal.tipoDocumentoFiscal.codigo;
          tempDocFiscal.documentoFiscal =
            docFiscal.tipoDocumentoFiscal.descricao;
          tempDocFiscal.numero = docFiscal.numeroDocumento;
          tempDocFiscal.equipamento = docFiscal.codigoEcf;
          tempDocFiscal.codigoVenda = docFiscal.codigoVenda;
          tempDocFiscal.codigoMovimento = docFiscal.codigoMovimento;
          if (docFiscal.dataHora)
            tempDocFiscal.dataHora = moment(docFiscal.dataHora).format(
              'DD/MM/YYYY HH:mm'
            );
          tempDocFiscal.valor = 'R$' + parseFloat(docFiscal.valor).toFixed(2);

          const objFormaPgto = {
            formaPgto: this.codigoFormaPgtoToDescricao(
              parseInt(codigoFormaPagamento)
            ),
            formaPgtoId: codigoFormaPagamento,
            valor: tempDocFiscal.valor
          };

          tempDocFiscal.listaFormasPgtoDocumentoFiscal = [];
          tempDocFiscal.listaFormasPgtoDocumentoFiscal.push(objFormaPgto);

          listaDocsFiscaisTratada.push(tempDocFiscal);
        }
      } else {
        var itemListaTipoEmissao = {};
        if (docFiscal.tipoEmissao) {
          //cartões
          if (listaTipoEmissaoOriginal.length > 0)
            if (
              listaTipoEmissaoOriginal.filter(
                item => item.idTipoEmissao === docFiscal.tipoEmissao.codigo
              ).length > 0
            )
              listaTipoEmissaoExistente = true;
            else listaTipoEmissaoExistente = false;

          if (!listaTipoEmissaoExistente) {
            var listaFiltradaTipoEmissao = [];
            itemListaTipoEmissao.idTipoEmissao = docFiscal.tipoEmissao.codigo;
            itemListaTipoEmissao.descricaoTipoEmissao =
              docFiscal.tipoEmissao.descricao;

            listaFiltradaTipoEmissao = listaDocumentosFiscais.filter(
              doc =>
                doc.tipoEmissao &&
                doc.tipoEmissao.codigo === docFiscal.tipoEmissao.codigo
            );

            itemListaTipoEmissao.docs = listaFiltradaTipoEmissao;
            listaTipoEmissaoOriginal.push(itemListaTipoEmissao);
          }
        } else if (
          parseInt(codigoFormaPagamento) === parseInt(IdFormaPgto.Ticket)
        ) {
          itemListaTipoEmissao.idTipoEmissao = 3;
          itemListaTipoEmissao.descricaoTipoEmissao = 'TICKET';
          listaFiltradaTipoEmissao = listaDocumentosFiscais.filter(
            doc =>
              !doc.tipoEmissao && parseInt(doc.tipoDocumentoFiscal.codigo) !== 0
          );
          itemListaTipoEmissao.docs = listaFiltradaTipoEmissao;
          listaTipoEmissaoOriginal.push(itemListaTipoEmissao);
          break;
        }
      }
    }

    for (let m = 0; m < listaTipoEmissaoOriginal.length; m++) {
      const itemListaTE = listaTipoEmissaoOriginal[m];

      const tempTotalizadorTipoEmissao = {};
      tempTotalizadorTipoEmissao.codigo = itemListaTE.idTipoEmissao;
      tempTotalizadorTipoEmissao.descricao = itemListaTE.descricaoTipoEmissao;
      tempTotalizadorTipoEmissao.qtde = itemListaTE.docs.length;
      let totalSuprimentosTE = 0;
      let totalRecebimentosTE = 0;
      let totalSangriasTE = 0;
      itemListaTE.docs.forEach(element => {
        if (parseInt(element.tipoValor) === 1)
          totalSuprimentosTE += element.valor;
        else if (parseInt(element.tipoValor) === 2)
          totalRecebimentosTE += element.valor;
        else if (parseInt(element.tipoValor) === 3)
          totalSangriasTE += element.valor;
      });
      tempTotalizadorTipoEmissao.label =
        tempTotalizadorTipoEmissao.descricao +
        ' Qtde:' +
        tempTotalizadorTipoEmissao.qtde +
        ' Total Suprimentos: R$ ' +
        parseFloat(totalSuprimentosTE).toFixed(2) +
        ' | Total Recebimentos: R$ ' +
        parseFloat(totalRecebimentosTE).toFixed(2) +
        ' | Total Sangrias: R$ ' +
        parseFloat(totalSangriasTE).toFixed(2);

      tempTotalizadorTipoEmissao.docs = itemListaTE.docs;

      listaTipoEmissaoTratada.push(tempTotalizadorTipoEmissao);
    }

    for (let n = 0; n < listaTipoEmissaoTratada.length; n++) {
      const itemTipoEmissao = listaTipoEmissaoTratada[n];
      var listaProdutoCartaoOriginal = [];

      itemTipoEmissao.docs.forEach(element => {
        if (!element.tipoEmissao && element.rede) {
          //caso de ticket que tem rede mas não tem tipoEmissao
          element.tipoEmissao = {
            codigo: 3,
            descricao: 'TICKET'
          };
        } else if (
          !element.tipoEmissao &&
          parseInt(element.tipoDocumentoFiscal.codigo) !== 0
        ) {
          element.tipoEmissao = {
            codigo: 3,
            descricao: 'TICKET'
          };
          element.rede = {
            codigo: 3,
            descricao: 'TICKET'
          };
          element.bandeira = {
            codigo: 3,
            descricao: 'TICKET'
          };
          element.produto = {
            codigo: 3,
            descricao: 'TICKET'
          };
        }
      });

      for (let o = 0; o < itemTipoEmissao.docs.length; o++) {
        const docFiscal = itemTipoEmissao.docs[o];
        if (listaProdutoCartaoOriginal.length > 0) {
          if (
            listaProdutoCartaoOriginal.filter(
              item => item.idProdutoCartao === docFiscal.produto.codigo
            ).length > 0
          )
            listaProdutoCartaoExistente = true;
          else listaProdutoCartaoExistente = false;
        }

        if (!listaProdutoCartaoExistente) {
          listaDocsFiscaisTratada = [];
          var listaFiltradaProdutoCartao = [];
          var itemListaProdutoCartao = {};
          if (docFiscal.produto) {
            itemListaProdutoCartao.idProdutoCartao = docFiscal.produto.codigo;
            itemListaProdutoCartao.descricao = docFiscal.produto.descricao;
            listaFiltradaProdutoCartao = itemTipoEmissao.docs.filter(
              doc => doc.produto.codigo === docFiscal.produto.codigo
            );
            itemListaProdutoCartao.docs = listaFiltradaProdutoCartao;
          }

          for (let g = 0; g < itemListaProdutoCartao.docs.length; g++) {
            const element = itemListaProdutoCartao.docs[g];

            if (
              element.produto.codigo === itemListaProdutoCartao.idProdutoCartao
            ) {
              let tempDocFiscal = {};
              tempDocFiscal.tipoDocumentoFiscal =
                element.tipoDocumentoFiscal.codigo;
              tempDocFiscal.documentoFiscal =
                element.tipoDocumentoFiscal.descricao;
              tempDocFiscal.numero = element.numeroDocumento;
              tempDocFiscal.equipamento = element.codigoEcf;
              tempDocFiscal.codigoVenda = element.codigoVenda;
              tempDocFiscal.codigoMovimento = element.codigoMovimento;
              tempDocFiscal.nsu = element.nsu;
              if (element.nsuRede !== '0')
                tempDocFiscal.nsurede = element.nsuRede;
              if (element.rede) {
                tempDocFiscal.rede = element.rede.descricao;
                tempDocFiscal.redeId = element.rede.codigo;
              }
              if (element.bandeira) {
                tempDocFiscal.bandeiraId = element.bandeira.codigo;
                tempDocFiscal.bandeira = element.bandeira.descricao;
              }
              if (element.produto)
                tempDocFiscal.produtoId = element.produto.codigo;

              // const objFormaPgto = {
              //   formaPgto: this.codigoFormaPgtoToDescricao(
              //     parseInt(codigoFormaPagamento)
              //   ),
              //   formaPgtoId: parseInt(codigoFormaPagamento),
              //   valor: "R$" + parseFloat(element.valor).toFixed(2),
              //   rede: element.rede.codigo,
              //   bandeira: element.bandeira.codigo,
              //   produto: element.produto.codigo,
              //   nsu: element.nsu
              // };

              /*tempDocFiscal.listaFormasPgtoDocumentoFiscal = [];
              tempDocFiscal.listaFormasPgtoDocumentoFiscal.push(objFormaPgto);
              tempDocFiscal.clienteSelecionado = element.clienteSelecionado;*/
              if (element.dataHora)
                tempDocFiscal.dataHora = moment(element.dataHora).format(
                  'DD/MM/YYYY HH:mm'
                );
              tempDocFiscal.tipoValor = element.tipoValor;
              tempDocFiscal.valorSemFormatacao = element.valor;
              tempDocFiscal.valor = 'R$' + parseFloat(element.valor).toFixed(2);
              listaDocsFiscaisTratada.push(tempDocFiscal);
            }
          }

          itemListaProdutoCartao.docs = listaDocsFiscaisTratada;

          itemListaProdutoCartao.qtde = itemListaProdutoCartao.docs.length;
          let totalSuprimentosPC = 0;
          let totalRecebimentosPC = 0;
          let totalSangriasPC = 0;
          itemListaProdutoCartao.docs.forEach(element => {
            if (parseInt(element.tipoValor) === 1)
              totalSuprimentosPC += element.valorSemFormatacao;
            else if (parseInt(element.tipoValor) === 2)
              totalRecebimentosPC += element.valorSemFormatacao;
            else if (parseInt(element.tipoValor) === 3)
              totalSangriasPC += element.valorSemFormatacao;
          });
          itemListaProdutoCartao.label =
            itemListaProdutoCartao.descricao +
            ' Qtde:' +
            itemListaProdutoCartao.qtde +
            ' Total Suprimentos: R$ ' +
            parseFloat(totalSuprimentosPC).toFixed(2) +
            ' | Total Recebimentos: R$ ' +
            parseFloat(totalRecebimentosPC).toFixed(2) +
            ' | Total Sangrias: R$ ' +
            parseFloat(totalSangriasPC).toFixed(2);

          listaProdutoCartaoOriginal.push(itemListaProdutoCartao);
        }
      }
      //listaTipoEmissaoTratada[n].produtosCartao = listaProdutoCartaoOriginal.filter(lpc => lpc.tipoEmissao.codigo === listaTipoEmissaoTratada[n].codigo);
      listaTipoEmissaoTratada[n].produtosCartao = listaProdutoCartaoOriginal;
    }

    if (
      parseInt(codigoFormaPagamento) !== parseInt(IdFormaPgto.Ticket) &&
      parseInt(codigoFormaPagamento) !== parseInt(IdFormaPgto.Credito) &&
      parseInt(codigoFormaPagamento) !== parseInt(IdFormaPgto.Debito)
    ) {
      for (let j = 0; j < listaTO.length; j++) {
        const itemTO = listaTO[j];
        if (itemTO.codigoTurno === codigoTurno) {
          for (let k = 0; k < itemTO.formasPgto.length; k++) {
            const itemFormaPgto = itemTO.formasPgto[k];
            if (itemFormaPgto.id === codigoFormaPagamento) {
              listaTO[j].formasPgto[k].docs = listaDocsFiscaisTratada;
            }
          }
        }
      }
    } else {
      for (let a = 0; a < listaTO.length; a++) {
        const itemTO = listaTO[a];
        if (itemTO.codigoTurno === codigoTurno) {
          for (let b = 0; b < itemTO.formasPgto.length; b++) {
            const itemFormaPgto = itemTO.formasPgto[b];
            if (itemFormaPgto.id === codigoFormaPagamento) {
              listaTO[a].formasPgto[b].tiposOperacao = listaTipoEmissaoTratada;
            }
          }
        }
      }
    }

    this.setState({ listaTurnosOperadores: listaTO });
  };

  backToScreen = () => {
    hideAlert(this);
    this.props.history.push('/financeiro/conferencia-caixa');
  };

  setFocus = elementID => {
    const node = document.getElementById(elementID);
    if (node) {
      node.focus();
      node.scrollIntoView();
    }
  };

  handleVoltar = async () => {
    this.backToScreen();
  };

  reabrirConferenciaTurno = async codigoTurno => {
    var listaTO = this.state.listaTurnosOperadores;

    try {
      var resp = await reabrirTurno(codigoTurno);
      //sucesso
      if (resp) {
        var cCheck = '✓';
        for (let i = 0; i < listaTO.length; i++) {
          const itemTurno = listaTO[i];
          if (itemTurno.codigoTurno === codigoTurno) {
            listaTO[i].dataConferencia = null;
            listaTO[i].label = listaTO[i].label.replace(cCheck, ' ');
          }
        }
        this.setState({
          listaTurnosOperadores: listaTO,
          mostrarBotaoGeral: true
        });
        //this.forcarUpdate();
      }
    } catch (err) {
      // falha
      showLoadError(this, titulo, err);
    }
  };

  realizarConferencia = listaTurnosConferencia => {
    var turnos = [];
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    var temErro = false;

    for (let i = 0; i < listaTurnosConferencia.length; i++) {
      if (temErro) {
        break;
      }

      const itemTurno = listaTurnosConferencia[i];
      var conferenciaTurno = {};
      conferenciaTurno.codigoTurno = itemTurno.codigoTurno;
      const conferencias = [];

      for (let j = 0; j < itemTurno.formasPgto.length; j++) {
        const itemFormaPgto = itemTurno.formasPgto[j];

        if (parseInt(itemFormaPgto.id) !== parseInt(IdFormaPgto.Dinheiro)) {
          if (
            itemFormaPgto.valorRecebidoTesouraria !== itemFormaPgto.recebimentos
          ) {
            temErro = true;
            showError(
              this,
              titulo,
              'Não é permitido valor de tesouraria diferente do valor de ' +
                'recebimento para a forma de pagamento ' +
                itemFormaPgto.descricao
            );
          }

          if (
            parseFloat(itemFormaPgto.valorRecebidoTesouraria) === 0 &&
            parseFloat(itemFormaPgto.recebimentos) !== 0
          ) {
            temErro = true;
            showError(
              this,
              titulo,
              'Não é permitido valor de tesouraria zerado para a ' +
                'forma de pagamento ' +
                itemFormaPgto.descricao
            );
          }
        }

        if (temErro) {
          break;
        }

        var objFormaPgto = {};

        objFormaPgto.formaPagamento = {};
        objFormaPgto.formaPagamento.codigo = itemFormaPgto.id;
        objFormaPgto.formaPagamento.descricao = itemFormaPgto.descricao;
        objFormaPgto.valores = {};

        objFormaPgto.valores.valorSuprimentos = itemFormaPgto.suprimentos;
        objFormaPgto.valores.valorRecebimentos = itemFormaPgto.recebimentos;
        objFormaPgto.valores.valorSangrias = itemFormaPgto.sangrias;
        objFormaPgto.valores.valorRecebidoTesouraria =
          itemFormaPgto.valorRecebidoTesouraria;
        conferencias.push(objFormaPgto);
      }

      conferenciaTurno.conferencias = conferencias;

      conferenciaTurno.codigoUsuario = 1;
      conferenciaTurno.codigoOperador = itemTurno.codigoOperador;

      turnos.push(conferenciaTurno);
    }

    if (temErro) {
      return null;
    }

    return turnos;
  };

  conferirTurno = async listaTurnosConferencia => {
    var listaTO = this.state.listaTurnosOperadores;
    var turnosConferir = [];
    var codigosTurnoConferir = [];
    if (!listaTurnosConferencia)
      turnosConferir = this.state.listaTurnosOperadores;
    else turnosConferir.push(listaTurnosConferencia);

    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    var turnos = this.realizarConferencia(turnosConferir);

    if (turnos) {
      const resp = await conferir(turnos);
      if (resp) {
        showInfo(this, titulo, 'Turno conferido com sucesso');

        turnos.forEach(element => {
          codigosTurnoConferir.push(element.codigoTurno);
        });

        for (let i = 0; i < listaTO.length; i++) {
          const itemTurno = listaTO[i];
          if (
            codigosTurnoConferir.filter(ct => ct === itemTurno.codigoTurno)
              .length > 0
          ) {
            listaTO[i].dataConferencia = moment();
          }
        }
        this.setState({ listaTurnosOperadores: listaTO });
        this.forcarUpdate();
      }
    }
    this.setState({ desabilitaConferirTurno: false });
  };

  gravarConferencia = listaTurnosConferencia => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    var turnosConferir = [];

    turnosConferir.push(listaTurnosConferencia);

    var turnos = this.realizarConferencia(turnosConferir);

    if (turnos) {
      try {
        salvarTurno(turnos);
        showInfo(this, titulo, 'Conferência salva com sucesso');
        this.forcarUpdate();
      } catch (e) {
        showSaveError(this, titulo, e);
      }
    }
  };

  realizarConferenciaFacil = (codigoTurno = 0) => {
    var listaTO = this.state.listaTurnosOperadores;
    for (let i = 0; i < listaTO.length; i++) {
      const itemTurno = listaTO[i];
      if (itemTurno.codigoTurno === codigoTurno || codigoTurno === 0) {
        for (let j = 0; j < itemTurno.formasPgto.length; j++) {
          listaTO[i].formasPgto[j].valorRecebidoTesouraria =
            listaTO[i].formasPgto[j].recebimentos;
        }
        this.conferirTurno(listaTO[i]);
      }
    }
    this.setState({ listaTurnosOperadores: listaTO });

    //setar tesouraria igual a recebimentos
  };

  defineValorTesouraria = (codigoTurno, idFormaPgto, valor) => {
    var listaTO = this.state.listaTurnosOperadores;
    for (let i = 0; i < listaTO.length; i++) {
      const itemTurno = listaTO[i];
      if (itemTurno.codigoTurno === codigoTurno)
        for (let j = 0; j < itemTurno.formasPgto.length; j++) {
          const itemFormaPgto = itemTurno.formasPgto[j];
          if (itemFormaPgto.id === idFormaPgto) {
            if (valor === '' || valor === ',' || valor === '.') valor = 0;
            listaTO[i].formasPgto[j].valorRecebidoTesouraria = parseFloat(
              valor
            );
            listaTO[i].formasPgto[j].valorDiferenca =
              listaTO[i].formasPgto[j].valorRecebidoTesouraria -
              (listaTO[i].formasPgto[j].recebimentos +
                listaTO[i].formasPgto[j].suprimentos);
            listaTO[i].formasPgto[j].valorDiferenca =
              'R$ ' +
              parseFloat(listaTO[i].formasPgto[j].valorDiferenca).toFixed(2);
          }
        }
    }
    this.setState({ listaTurnosOperadores: listaTO });
  };

  codigoFormaPgtoToDescricao = codigo => {
    switch (codigo) {
      case -1:
        return 'Dinheiro';

      case -2:
        return 'Cheque';

      case -3:
        return 'Ticket';

      case -4:
        return 'Crédito';

      case -5:
        return 'Vale';

      case -6:
        return 'A Faturar';

      case -7:
        return 'Débito';

      default:
        break;
    }
  };

  handleTableDocClick = (
    state,
    rowInfo,
    column,
    instance,
    e,
    dataConferencia
  ) => {
    if (dataConferencia) {
      this.setState({ camposDesabilitados: true });
    } else this.setState({ camposDesabilitados: false });

    this.setState({ camposDesabilitados: !menuPermiteAlteracao(PATH_MENU) });

    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada

      this.selecionarFormaPagamento(
        rowInfo.original.equipamento,
        rowInfo.original.codigoVenda,
        rowInfo.original.codigoMovimento
      );
      this.setState({
        documentoFiscalSelecionado: rowInfo.original.documentoFiscal,
        equipamentoDocSelecionado: rowInfo.original.equipamento,
        numeroDocSelecionado: rowInfo.original.numero,
        valorDocSelecionado: rowInfo.original.valor.replace('.', ','),

        codigoEcfSelecionado: rowInfo.original.equipamento,
        codigoVendaSelecionado: rowInfo.original.codigoVenda,
        codigoMovimentoSelecionado: rowInfo.original.codigoMovimento
      });

      if (sameString(rowInfo.original.documentoFiscal, DocumentoFiscal.Sangria))
        this.setState({ camposDesabilitadosSangria: true });
      else this.setState({ camposDesabilitadosSangria: false });
      this.abrirModalAlteracaoForma(rowInfo.original);
    }
  };

  salvarFormaPagamento = async (
    codigoEcf,
    codigoVenda,
    codigoMovimento,
    data
  ) => {
    var listaFPSelecionadas = this.state.listaFormasPgtoDocumentoFiscal;
    var listaClientSelecionado = this.state.listaClientesSelecionados;

    var dataFormatada = moment(data).format('YYYY-MM-DD');

    var formaPagamentos = [];
    listaFPSelecionadas.forEach(fp => {
      //var valorFormatado = fp.valor.substring(2).replace(',', '.');
      const valorFormatado = Number(fp.valor.substring(2).replace(',', '.'));
      var objFormaPgto = {};
      objFormaPgto.codigoFormaPagamento = fp.codigoFormaPagamento;
      objFormaPgto.valor = valorFormatado;

      if (fp.formaPgto === 'Troco') {
        objFormaPgto.codigoFormaPagamento = CodigoFormaPgto.Dinheiro;
        objFormaPgto.valor = objFormaPgto.valor * -1;
      }

      if (
        sameInt(objFormaPgto.codigoFormaPagamento, CodigoFormaPgto.Ticket) ||
        sameInt(objFormaPgto.codigoFormaPagamento, CodigoFormaPgto.Credito) ||
        sameInt(objFormaPgto.codigoFormaPagamento, CodigoFormaPgto.Debito)
      ) {
        objFormaPgto.cartao = {};
        objFormaPgto.cartao.codigoRede = fp.rede;
        objFormaPgto.cartao.codigoBandeira = fp.bandeira;
        objFormaPgto.cartao.codigoProduto = fp.produto;
        objFormaPgto.cartao.nsu = fp.nsu;
        objFormaPgto.cartao.NsuRede = fp.nsuRede;
        objFormaPgto.cartao.valor = valorFormatado;
      }

      if (sameInt(objFormaPgto.codigoFormaPagamento, CodigoFormaPgto.Cheque)) {
        objFormaPgto.cheque = {};

        objFormaPgto.cheque.chequepar = fp.chequepar;
        objFormaPgto.cheque.chequecpf = fp.chequecpf;
        objFormaPgto.cheque.chequenum = fp.chequenum;
        objFormaPgto.cheque.chequeven = fp.chequeven;
        objFormaPgto.cheque.chequevlr = fp.chequevlr;
        objFormaPgto.cheque.chequeban = fp.chequeban;
        objFormaPgto.cheque.chequeagn = fp.chequeagn;
        objFormaPgto.cheque.chequecta = fp.chequecta;
        objFormaPgto.cheque.chequetit = fp.chequetit;
        objFormaPgto.cheque.chequeend = fp.chequeend;
        objFormaPgto.cheque.chequeddd = fp.chequeddd;
        objFormaPgto.cheque.chequetel = fp.chequetel;
      }

      formaPagamentos.push(objFormaPgto);
    });

    const c = listaClientSelecionado[0];

    const cliente = {
      codigoCliente: c ? c.codigoCliente : null,
      nome: c ? c.razaoSocial : '',
      CPF_CNPJ: c ? c.cnpj : '',
      codigoAutorizado: c ? c.codigoAutorizado : null,
      placaAutorizado: c ? c.placa : ''
    };
    try {
      await salvarFormaPgto(
        codigoEcf,
        codigoVenda,
        codigoMovimento,
        formaPagamentos,
        cliente,
        dataFormatada
      );
    } catch (e) {
      showSaveError(this, titulo, e);
      return false;
    }
    return true;
  };

  selecionarFormaPagamento = async (
    codigoEcf,
    codigoVenda,
    codigoMovimento
  ) => {
    const { data: objDocumentoFiscal } = await selecionarFormaPgto(
      codigoEcf,
      codigoVenda,
      codigoMovimento
    );

    if (objDocumentoFiscal) {
      var objDocumentoSelecionado = {};
      objDocumentoSelecionado.listaFormasPgtoDocumentoFiscal = [];
      objDocumentoSelecionado.listaClientesSelecionados = [];

      objDocumentoFiscal.formaPagamentos.forEach(fp => {
        var objFormaPgto = {};
        objFormaPgto.id =
          objDocumentoSelecionado.listaFormasPgtoDocumentoFiscal.length + 1;
        objFormaPgto.formaPgtoId = fp.codigoFormaPagamento;
        objFormaPgto.codigoFormaPagamento = fp.codigoFormaPagamento;
        objFormaPgto.formaPgto = fp.formaPagamento;
        objFormaPgto.valor = 'R$' + parseFloat(fp.valor).toFixed(2);

        objFormaPgto.valor = objFormaPgto.valor.replace('.', ',');
        if (fp.cartao) {
          objFormaPgto.cartao = {};
          objFormaPgto.rede = fp.cartao.codigoRede;
          objFormaPgto.bandeira = fp.cartao.codigoBandeira;
          objFormaPgto.produto = fp.cartao.codigoProduto;
          objFormaPgto.nsu = fp.cartao.nsu;
          objFormaPgto.nsuRede = fp.cartao.nsuRede;
          objFormaPgto.valorCartao = fp.cartao.Valor;
        }
        objDocumentoSelecionado.listaFormasPgtoDocumentoFiscal.push(
          objFormaPgto
        );
      });

      let clienteSelecionado = null;
      if (objDocumentoFiscal.cliente) {
        clienteSelecionado = {
          codigo: objDocumentoFiscal.cliente.codigoCliente,
          codigoCliente: objDocumentoFiscal.cliente.codigoCliente,
          cnpj: objDocumentoFiscal.cliente.cpF_CNPJ,
          razaoSocial: objDocumentoFiscal.cliente.nome,
          utilizaFatura: !!objDocumentoFiscal.cliente.codigoAutorizado,
          possuiAutorizados: objDocumentoFiscal.cliente.codigoAutorizado !== 0,
          obrigaAutorizado: objDocumentoFiscal.cliente.obrigaAutorizado,
          codigoAutorizado: objDocumentoFiscal.cliente.codigoAutorizado,
          autorizado: objDocumentoFiscal.cliente.nomeAutorizado,
          placa: objDocumentoFiscal.cliente.placaAutorizado
        };
      }

      if (clienteSelecionado)
        objDocumentoSelecionado.listaClientesSelecionados.push(
          clienteSelecionado
        );
      else objDocumentoSelecionado.listaClientesSelecionados = [];

      objDocumentoSelecionado.data = objDocumentoFiscal.data;

      this.setState({
        listaFormasPgtoDocumentoFiscal:
          objDocumentoSelecionado.listaFormasPgtoDocumentoFiscal,
        listaClientesSelecionados:
          objDocumentoSelecionado.listaClientesSelecionados,
        dataDocumento: objDocumentoSelecionado.data
      });
    }
  };

  render() {
    const {
      // tesouraria,
      // diferenca,
      listaTurnosOperadores,
      alertActive,
      alertType,
      alertTitle,
      alertSubtitle,
      // clicouBotao,
      // displayInfoCompl,

      equipamentoDocSelecionado,
      numeroDocSelecionado,
      valorDocSelecionado,
      documentoFiscalSelecionado,

      mostrarBotaoGeral,

      camposDesabilitados,
      camposDesabilitadosSangria,

      // clienteSelecionado,

      codigoEcfSelecionado,
      codigoVendaSelecionado,
      codigoMovimentoSelecionado,

      dataDocumento,

      desabilitaConferirTurno
    } = this.state;

    const { modalAlteracaoForma } = this.state;

    const { modalInfo } = this.state;

    const dataEscolhida = this.props.dataLista;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <div className="row">
              <div className="col-9">
                <div className="col-5">
                  <InputDate
                    label="Data:"
                    //value={moment(dataEscolhida).format("DD/MM/YYYY")}
                    value={dataEscolhida}
                    name="dataLista"
                    disabled
                  />
                </div>
              </div>

              {mostrarBotaoGeral ? (
                <>
                  <div className="col-2">
                    <Botao
                      title="Conferência Fácil"
                      tooltip="Conferência Fácil"
                      icon={'icon-lx-check-2'}
                      onClick={() => {
                        this.realizarConferenciaFacil();
                      }}
                    />
                  </div>
                  <div className="col-1">
                    <Botao
                      ic
                      tooltip="Conferir Turno"
                      icon={'icon-lx-check'}
                      disabled={desabilitaConferirTurno}
                      onClick={() => {
                        this.setState({ desabilitaConferirTurno: true });
                        this.conferirTurno();
                      }}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <ul>
              {listaTurnosOperadores.map(itOperador => {
                return (
                  <SectionContentSpecial
                    title={itOperador.label}
                    accordion
                    accordionDefaultDisplay={false}
                    onChange={open =>
                      open
                        ? this.carregarListaFormasPgto(itOperador.codigoTurno)
                        : null
                    }
                    right={
                      <div className="button-container">
                        {menuPermiteAlteracao(PATH_MENU) ? (
                          <>
                            {itOperador.dataFimTurno ? (
                              itOperador.dataConferencia ? (
                                <>
                                  <Botao
                                    ic
                                    tooltip="Reabrir Turno"
                                    icon={'icon-lx-eraser'}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.reabrirConferenciaTurno(
                                        itOperador.codigoTurno
                                      );
                                    }}
                                  />
                                  <Botao
                                    ic
                                    tooltip="Informações"
                                    icon={'icon-lx-info'}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.setState({ clicouBotao: true });
                                      this.abrirModalInfo(
                                        itOperador.responsavelConferencia,
                                        itOperador.dataConferencia
                                      );
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  <Botao
                                    ic
                                    tooltip="Conferência Fácil"
                                    icon={'icon-lx-check-2'}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.realizarConferenciaFacil(
                                        itOperador.codigoTurno
                                      );
                                    }}
                                  />
                                  <Botao
                                    ic
                                    tooltip="Gravar Conferência"
                                    icon={'icon-lx-edit'}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.gravarConferencia(itOperador);
                                    }}
                                  />
                                  <Botao
                                    ic
                                    tooltip="Conferir Turno"
                                    icon={'icon-lx-check'}
                                    onClick={e => {
                                      e.stopPropagation();
                                      this.conferirTurno(itOperador);
                                    }}
                                  />
                                </>
                              )
                            ) : null}
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    }
                  >
                    {itOperador.formasPgto
                      ? itOperador.formasPgto.map(itFormaPgto => {
                          return (
                            <ul>
                              <SectionContentSpecial
                                title={itFormaPgto.label}
                                accordion
                                accordionDefaultDisplay={false}
                                onChange={open =>
                                  this.carregarListaDocumentosFiscais(
                                    itFormaPgto.id,
                                    itOperador.codigoOperador
                                  )
                                }
                                /*onChange={
                                    open => {
                                        open ?
                                            this.carregarListaDocumentosFiscais(itFormaPgto.id)
                                            : null
                                    }
                                }*/

                                right={
                                  <div className="row">
                                    <div className="col-5">
                                      <InputText
                                        label="Tesouraria:"
                                        value={
                                          itFormaPgto.valorRecebidoTesouraria ===
                                          0
                                            ? ''
                                            : itFormaPgto.valorRecebidoTesouraria
                                        }
                                        name="tesouraria"
                                        type="number"
                                        maxlength="14"
                                        decimalScale={2}
                                        disabled={
                                          itOperador.dataConferencia ||
                                          !itOperador.dataFimTurno
                                        }
                                        allowNegative={false}
                                        onChange={event =>
                                          this.defineValorTesouraria(
                                            itFormaPgto.codigoTurno,
                                            itFormaPgto.id,
                                            event.value
                                          )
                                        }
                                        onClick={e => {
                                          e.stopPropagation();
                                        }}
                                      />
                                    </div>
                                    <div className="col-5">
                                      <InputText
                                        label="Diferença:"
                                        disabled
                                        value={itFormaPgto.valorDiferenca}
                                        name="diferenca"
                                      />
                                    </div>
                                  </div>
                                }
                              >
                                {parseInt(itFormaPgto.id) !==
                                  parseInt(IdFormaPgto.Ticket) &&
                                parseInt(itFormaPgto.id) !==
                                  parseInt(IdFormaPgto.Credito) &&
                                parseInt(itFormaPgto.id) !==
                                  parseInt(IdFormaPgto.Debito) ? ( //diferente de cartões
                                  <ul>
                                    <ReactTable
                                      data={itFormaPgto.docs}
                                      getTdProps={(
                                        state,
                                        rowInfo,
                                        column,
                                        instance
                                      ) => {
                                        return {
                                          onClick: (e, handleOriginal) => {
                                            if (this.handleTableDocClick) {
                                              this.handleTableDocClick(
                                                state,
                                                rowInfo,
                                                column,
                                                instance,
                                                e,
                                                itOperador.dataConferencia
                                              );
                                            }

                                            if (handleOriginal) {
                                              handleOriginal();
                                            }
                                          }
                                        };
                                      }}
                                      columns={[
                                        {
                                          accessor: 'id',
                                          Header: 'id',
                                          width: 20,
                                          show: false
                                        },
                                        {
                                          accessor: 'documentoFiscal',
                                          Header: 'Documento Fiscal',
                                          width: 200
                                        },
                                        {
                                          accessor: 'equipamento',
                                          Header: 'Equipamento',
                                          width: 150
                                        },
                                        {
                                          accessor: 'numero',
                                          Header: 'Número',
                                          width: 150
                                        },
                                        {
                                          accessor: 'dataHora',
                                          Header: 'Data/Hora',
                                          width: 180
                                        },
                                        {
                                          accessor: 'valor',
                                          Header: 'Valor',
                                          width: 100,
                                          sortMethod: sortFloat
                                        }
                                      ]}
                                      defaultPageSize={5}
                                      previousText="Anterior"
                                      nextText="Próximo"
                                      loadingText="Carregando registros..."
                                      noDataText="Sem registros para exibir"
                                      pageText="Página"
                                      ofText="de"
                                      rowsText="registros"
                                    />
                                  </ul>
                                ) : itFormaPgto.tiposOperacao ? (
                                  itFormaPgto.tiposOperacao.map(
                                    itTipoOperacao => {
                                      return (
                                        <ul>
                                          <SectionContentSpecial
                                            title={itTipoOperacao.label}
                                            accordion
                                            accordionDefaultDisplay={false}
                                          >
                                            {itTipoOperacao.produtosCartao
                                              ? itTipoOperacao.produtosCartao.map(
                                                  itProdutoCartao => {
                                                    return (
                                                      <ul>
                                                        <SectionContentSpecial
                                                          title={
                                                            itProdutoCartao.label
                                                          }
                                                          accordion
                                                          accordionDefaultDisplay={
                                                            false
                                                          }
                                                        >
                                                          {
                                                            <ul>
                                                              <ReactTable
                                                                data={
                                                                  itProdutoCartao.docs
                                                                }
                                                                getTdProps={(
                                                                  state,
                                                                  rowInfo,
                                                                  column,
                                                                  instance
                                                                ) => {
                                                                  return {
                                                                    onClick: (
                                                                      e,
                                                                      handleOriginal
                                                                    ) => {
                                                                      if (
                                                                        this
                                                                          .handleTableDocClick
                                                                      ) {
                                                                        this.handleTableDocClick(
                                                                          state,
                                                                          rowInfo,
                                                                          column,
                                                                          instance,
                                                                          e,
                                                                          itOperador.dataConferencia
                                                                        );
                                                                      }

                                                                      if (
                                                                        handleOriginal
                                                                      ) {
                                                                        handleOriginal();
                                                                      }
                                                                    }
                                                                  };
                                                                }}
                                                                columns={[
                                                                  {
                                                                    accessor:
                                                                      'id',
                                                                    Header:
                                                                      'id',
                                                                    width: 20,
                                                                    show: false
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'documentoFiscal',
                                                                    Header:
                                                                      'Documento Fiscal',
                                                                    width: 200
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'equipamento',
                                                                    Header:
                                                                      'Equipamento',
                                                                    width: 150
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'numero',
                                                                    Header:
                                                                      'Número',
                                                                    width: 150
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'rede',
                                                                    Header:
                                                                      'Rede',
                                                                    width: 150
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'bandeira',
                                                                    Header:
                                                                      'Bandeira',
                                                                    width: 150
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'nsu',
                                                                    Header:
                                                                      'NSU',
                                                                    width: 150
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'nsurede',
                                                                    Header:
                                                                      'NSU Rede',
                                                                    width: 150
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'dataHora',
                                                                    Header:
                                                                      'Data/Hora',
                                                                    width: 180
                                                                  },
                                                                  {
                                                                    accessor:
                                                                      'valor',
                                                                    Header:
                                                                      'Valor',
                                                                    width: 100
                                                                  }
                                                                ]}
                                                                defaultPageSize={
                                                                  5
                                                                }
                                                                previousText="Anterior"
                                                                nextText="Próximo"
                                                                loadingText="Carregando registros..."
                                                                noDataText="Sem registros para exibir"
                                                                pageText="Página"
                                                                ofText="de"
                                                                rowsText="registros"
                                                              />
                                                            </ul>
                                                          }
                                                        </SectionContentSpecial>
                                                      </ul>
                                                    );
                                                  }
                                                )
                                              : null}
                                          </SectionContentSpecial>
                                        </ul>
                                      );
                                    }
                                  )
                                ) : null}
                              </SectionContentSpecial>
                            </ul>
                          );
                        })
                      : null}
                  </SectionContentSpecial>
                );
              })}
            </ul>
          </SectionContainer>
        </LinxPostos>

        <FormOptions handleVoltar={this.handleVoltar} />

        <Alert
          active={alertActive}
          type={alertType}
          title={alertTitle}
          subtitle={alertSubtitle}
          handleAction={this.state.alertAction}
        />

        <ModalInfo
          active={modalInfo}
          handleCloseModal={this.handleCloseModalInfo}
          responsavel={this.state.responsavelConferencia}
          data={this.state.dataConferencia}
          hora={this.state.horaConferencia}
        />

        <ModalAlteracaoForma
          active={modalAlteracaoForma}
          handleCloseModal={this.handleCloseModalForma}
          handleAdicionaPagamento={this.handleAdicionaPagamento}
          listaFormasPgtoSelecionadas={
            this.state.listaFormasPgtoDocumentoFiscal
          }
          handleAdicionaCliente={this.handleAdicionaCliente}
          listaClientesSelecionados={this.state.listaClientesSelecionados}
          documentoFiscal={documentoFiscalSelecionado}
          equipamento={equipamentoDocSelecionado}
          numero={numeroDocSelecionado}
          valor={valorDocSelecionado}
          camposDesabilitados={camposDesabilitados}
          camposDesabilitadosSangria={camposDesabilitadosSangria}
          codigoEcf={codigoEcfSelecionado}
          codigoVenda={codigoVendaSelecionado}
          codigoMovimento={codigoMovimentoSelecionado}
          dataDocumento={dataDocumento}
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class ConferenciaTurno extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    this.state = {
      periodoDe: moment().startOf('month'),
      periodoAte: moment().endOf('month'),
      operadores: [],
      situacoes: [],
      maquinas: [],
      clientes: [],
      documentoFiscal: ''
    };
  }

  async componentDidMount() {
    const { data: listaSituacoesOriginal } = await montaComboSituacao();

    var listaSituacoes = listaSituacoesOriginal.map(s => {
      return {
        label: s.descricao,
        value: s.codigo
      };
    });

    this.setState({ listaSituacoes });

    const { data: clientesLista } = await montarComboClientes(1);

    const clientesListaOrd = clientesLista.sort(function(a, b) {
      if (a.descricao < b.descricao) return -1;
      if (a.descricao > b.descricao) return 1;
      return 0;
    });

    const clientesOrd = this.state.clientes.sort(function(a, b) {
      if (a.nomeCliente < b.nomeCliente) return -1;
      if (a.nomeCliente > b.nomeCliente) return 1;
      return 0;
    });

    this.setState({
      clientesLista:
        clientesListaOrd === null
          ? []
          : clientesListaOrd.map(cliente => {
              return { label: cliente.descricao, value: cliente.codigo };
            }),
      clientes: clientesOrd.map(cli => {
        return cli.codigoCliente;
      })
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.setState({
        operadores: [],
        situacoes: [],
        maquinas: [],
        clientes: [],
        periodoDe: moment().startOf('month'),
        periodoAte: moment().endOf('month'),
        documentoFiscal: '',
        listaDatas: []
      });
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.abrirDia(rowInfo.original);
    }
  };

  isFormValid = () => {
    const { periodoDe, periodoAte } = this.state;

    const de = moment(periodoDe);
    const ate = moment(periodoAte);

    if (!de || !de.isValid()) {
      showError(this, titulo, 'Escolha uma data inicial válida', 'peridoDe');
      return false;
    }

    if (de.isAfter(moment(LAST_SAFE_SMALLDATE))) {
      showError(
        this,
        titulo,
        'Escolha uma data inicial anterior a ' + getMaxFinalSmallDateString(),
        'periodoDe'
      );
      return false;
    }

    if (!ate || !ate.isValid()) {
      showError(this, titulo, 'Escolha uma data final válida', 'periodoAte');
      return false;
    }

    if (ate.isAfter(moment(LAST_SAFE_SMALLDATE))) {
      showError(
        this,
        titulo,
        'Escolha uma data final anterior a ' + getMaxFinalSmallDateString(),
        'periodoAte'
      );
      return false;
    }

    if (de.isAfter(ate)) {
      showError(
        this,
        titulo,
        'A data inicial deve ser anterior a data final',
        'periodoDe'
      );
      return false;
    }
    return true;
  };

  buscarListaConferencias = async () => {
    if (!this.isFormValid()) return;

    const {
      periodoDe,
      periodoAte,
      operadores,
      situacoes,
      maquinas,
      clientes,
      documentoFiscal
    } = this.state;

    const consulta = {
      periodoDe: '',
      periodoAte: '',
      operadores: [],
      situacoes: [],
      maquinas: [],
      clientes: [],
      documentoFiscal: ''
    };
    if (periodoDe) consulta.periodoDe = periodoDe;
    if (periodoAte) consulta.periodoAte = periodoAte;
    if (operadores) consulta.operadores = operadores;
    if (situacoes) consulta.situacoes = situacoes;
    if (maquinas) consulta.maquinas = maquinas;
    if (clientes) consulta.clientes = clientes;
    if (documentoFiscal) consulta.documentoFiscal = documentoFiscal;

    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto a consulta é realizada'
    );
    const { data: listaConferencias } = await listarConferencias(consulta);
    hideWait(this);
    const resultListaDatas = listaConferencias.result;
    if (resultListaDatas.length === 0) {
      showError(
        this,
        titulo,
        'Não existem datas para conferência para os filtros informados'
      );
    } else {
      const listaDatas = [];
      for (let i = 0; i < resultListaDatas.length; i++) {
        const e = resultListaDatas[i];
        var itemData = {};
        itemData.dataOriginal = e.data;
        itemData.data = moment(e.data).format('DD-MM-YYYY');
        itemData.situacao = e.situacao.descricao;
        listaDatas.push(itemData);
      }
      listaDatas.consulta = consulta;
      this.setState({ listaDatas });
    }
  };

  buscaListaOperadoresDia = (listaTurnosDia, dia) => {
    const listaOperadoresDia = [];
    var mostrarBotaoGeral = false;
    var cCheck = '✓';
    for (let i = 0; i < listaTurnosDia.length; i++) {
      const e = listaTurnosDia[i];
      var itemTurno = {};
      itemTurno.id = i + 1;
      itemTurno.codigoTurno = e.codigoTurno;
      itemTurno.codigoOperador = e.operador.codigo;

      var dataInicioTurno = moment(e.inicioTurno).format('HH:mm');
      itemTurno.dataInicioTurno = dataInicioTurno;
      if (e.fimTurno) {
        var dataFimTurno = moment(e.fimTurno).format('HH:mm');
        itemTurno.dataFimTurno = dataFimTurno;
        itemTurno.label =
          e.operador.descricao + '  |  ' + dataInicioTurno + '-' + dataFimTurno;
      } else
        itemTurno.label =
          e.operador.descricao + '  |  ' + dataInicioTurno + '- ';
      if (e.dataConferencia) {
        itemTurno.label = itemTurno.label + cCheck;
        itemTurno.dataConferencia = e.dataConferencia;
        itemTurno.responsavelConferencia = e.usuarioConferente.descricao;
      }
      //itemTurno.label = itemTurno.label + {" "};
      itemTurno.label =
        itemTurno.label +
        ' | Total Suprimentos: R$ ' +
        parseFloat(e.valores.valorSuprimentos).toFixed(2) +
        ' | Total Recebimentos: R$ ' +
        parseFloat(e.valores.valorRecebimentos).toFixed(2) +
        ' | Total Sangrias: R$ ' +
        parseFloat(e.valores.valorSangrias).toFixed(2) +
        ' | ';
      itemTurno.formasPgto = [];

      if (!itemTurno.dataConferencia)
        if (itemTurno.dataFimTurno) mostrarBotaoGeral = true;

      listaOperadoresDia.push(itemTurno);
    }

    mostrarBotaoGeral = mostrarBotaoGeral
      ? menuPermiteAlteracao(PATH_MENU)
      : mostrarBotaoGeral;

    this.setState({ listaOperadoresDia, dataLista: dia, mostrarBotaoGeral });
  };

  abrirDia = async dataEscolhida => {
    const listaDatas = this.state.listaDatas;
    var consulta = listaDatas.consulta;
    consulta.dia = dataEscolhida.dataOriginal;
    const { data: listaTurnos } = await listarTurnos(
      dataEscolhida.dataOriginal
    );
    const resultListaTurnos = listaTurnos.result;
    this.buscaListaOperadoresDia(resultListaTurnos, dataEscolhida.dataOriginal);
    listaDatas.consulta = consulta;
    this.setState({ listaDatas });
    this.props.history.push('/financeiro/conferencia-caixa/new');
  };

  handlePeriodoDeChange = e => {
    const newDate = e.date;
    if (newDate) {
      if (!moment(newDate).isValid()) return;

      if (newDate.isAfter(moment(this.state.periodoAte), 'day')) {
        showError(
          this,
          titulo,
          'Data inicial não pode ser maior que a data final.',
          'periodoDe'
        );
        return;
      }

      const periodoAte =
        moment(this.state.periodoAte).format('YYYY-MM-DD') + 'T23:59:59';
      const periodoDe = newDate.format('YYYY-MM-DD') + 'T00:00:00';
      this.carregarCombos(periodoDe, periodoAte);
      this.setState({ periodoDe });
    } else {
      this.setState({ periodoDe: null });
    }
  };

  handlePeriodoAteChange = e => {
    const newDate = e.date;
    if (newDate) {
      if (!moment(newDate).isValid()) return;

      if (newDate.isBefore(moment(this.state.periodoDe), 'day')) {
        showError(
          this,
          titulo,
          'Data final não pode ser menor que a data inicial.',
          'periodoAte'
        );
        return;
      }

      const periodoDe =
        moment(this.state.periodoDe).format('YYYY-MM-DD') + 'T00:00:00';
      const periodoAte = newDate.format('YYYY-MM-DD') + 'T23:59:59';
      this.carregarCombos(periodoDe, periodoAte);
      this.setState({ periodoAte });
    } else {
      this.setState({ periodoAte: null });
    }
  };

  carregarCombos = async (periodoDe, periodoAte) => {
    const { data: listaOperadoresOriginal } = await montaComboOperadores(
      periodoDe,
      periodoAte
    );

    var listaOperadores = !listaOperadoresOriginal
      ? []
      : listaOperadoresOriginal.map(s => {
          return {
            label: s.descricao,
            value: s.codigo
          };
        });

    const { data: listaMaquinasOriginal } = await montaComboMaquinas(
      periodoDe,
      periodoAte
    );

    var listaMaquinas = !listaMaquinasOriginal
      ? []
      : listaMaquinasOriginal.map(s => {
          return {
            label: s.descricao,
            value: s.codigo
          };
        });

    this.setState({
      listaOperadores,
      listaMaquinas
    });
  };

  render() {
    const { edit } = this.props;
    const {
      // operadores,
      // situacoes,
      // maquinas,
      // clientes,
      periodoDe = moment().startOf('month'),
      periodoAte = moment().endOf('month'),
      documentoFiscal = '',
      listaOperadores = [],
      listaMaquinas = [],
      clientesLista = [],
      listaSituacoes = [],

      listaOperadoresDia = [],
      dataLista,
      listaDatas,

      alertActive,
      alertType,
      alertTitle,
      alertSubtitle,

      mostrarBotaoGeral
    } = this.state;

    return (
      <>
        {edit ? (
          <Form
            dataLista={dataLista}
            listaOperadores={listaOperadoresDia}
            listaDatas={listaDatas}
            mostrarBotaoGeral={mostrarBotaoGeral}
          />
        ) : (
          <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <SectionContainer>
              <SectionContent title="">
                <div className="row">
                  <div
                    className="col-3"
                    style={{ position: 'relative', zIndex: '11' }}
                  >
                    <InputDate
                      label="De:"
                      value={periodoDe}
                      name="periodoDe"
                      onChange={this.handlePeriodoDeChange}
                      tabindex={1}
                    />
                  </div>
                  <div
                    className="col-3"
                    style={{ position: 'relative', zIndex: '11' }}
                  >
                    <InputDate
                      label="Até:"
                      value={periodoAte}
                      name="periodoAte"
                      onChange={this.handlePeriodoAteChange}
                      tabindex={2}
                    />
                  </div>
                  <div className="col-6">
                    <Select
                      multi
                      label="Operador:"
                      name="operadores"
                      //value={operadores}
                      sender={this}
                      options={listaOperadores}
                      tabindex={3}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <Select
                      multi
                      label="Situação:"
                      name="situacoes"
                      //value={situacoes}
                      onChange={this.handleInputChange}
                      sender={this}
                      options={listaSituacoes}
                      tabindex={4}
                    />
                  </div>
                  <div className="col-6">
                    <Select
                      multi
                      label="Máquina:"
                      name="maquinas"
                      //value={maquinas}
                      sender={this}
                      options={listaMaquinas}
                      tabindex={5}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <Select
                      multi
                      label="Cliente:"
                      name="clientes"
                      //value={clientes}
                      sender={this}
                      options={clientesLista}
                      tabindex={6}
                    />
                  </div>
                  <div className="col-5">
                    <InputText
                      label="Documento Fiscal:"
                      type="number"
                      name="documentoFiscal"
                      value={documentoFiscal}
                      onChange={this.handleInputChange}
                      tabindex={7}
                      maxlength="45"
                    />
                  </div>
                  {menuPermiteConsulta(PATH_MENU) ? (
                    <div style={{ paddingTop: 20 }}>
                      <div className="col-1">
                        <Botao
                          ic
                          icon="icon-lx-search"
                          onClick={() => {
                            this.buscarListaConferencias();
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </SectionContent>

              <SectionContent>
                <ReactTable
                  defaultPageSize={5}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando registros..."
                  noDataText="Sem registros para exibir"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  data={this.state.listaDatas}
                  columns={[
                    {
                      accessor: 'data',
                      Header: 'Data',
                      width: 200,
                      Cell: props => {
                        return (
                          <div>
                            {moment(props.value, 'DD-MM-YYYY').format(
                              'DD/MM/YYYY'
                            )}
                          </div>
                        );
                      }
                    },
                    {
                      accessor: 'situacao',
                      Header: 'Situacao'
                    }
                  ]}
                  getTdProps={(state, rowInfo, column, instance) => {
                    return {
                      onClick: (e, handleOriginal) => {
                        if (this.handleTableClick) {
                          this.handleTableClick(
                            state,
                            rowInfo,
                            column,
                            instance,
                            e
                          );
                        }

                        if (handleOriginal) {
                          handleOriginal();
                        }
                      }
                    };
                  }}
                />
              </SectionContent>
              <Alert
                active={alertActive}
                type={alertType}
                title={alertTitle}
                subtitle={alertSubtitle}
                handleAction={this.state.alertAction}
              />
            </SectionContainer>
          </LinxPostos>
        )}
        {tagAlert(this)}
      </>
    );
  }
}

export class ModalAlteracaoForma extends Component {
  constructor(props) {
    super(props);

    //const { handleAdicionaPagamento } = this.props;

    this.handleInputChange = handleInputChange.bind(this);
    this.state = {
      formaPgto: CodigoFormaPgto.Dinheiro,
      campoPessoa: '',
      cliente: {},
      clientePreparado: [],
      showModalCliente: false,
      clientes: [],
      listaAutorizados: [],
      autorizado: '',
      placa: '',
      isConsumidorFinal: false
    };
  }

  async componentDidMount() {
    const { data: listaRedeOriginal } = await montaComboRede(true);

    var listaRede = listaRedeOriginal.map(r => {
      return {
        label: r.descricao,
        value: r.codigo
      };
    });

    this.setState({ listaRede, rede: listaRedeOriginal[0].codigo });

    const { data: listaBandeiraOriginal } = await montaComboBandeira(true);

    var listaBandeira = listaBandeiraOriginal.map(b => {
      return {
        label: b.descricao,
        value: b.codigo
      };
    });

    this.setState({ listaBandeira, bandeira: listaBandeiraOriginal[0].codigo });

    this.carregarProdutoRede();

    const { data: listaFormasPgtoOriginal } = await montaComboFormasPagamento();

    var listaFormasPgto = listaFormasPgtoOriginal.map(b => {
      return {
        label: b.descricao,
        value: b.codigo
      };
    });

    this.setState({ listaFormasPgto });
  }

  handleAdicionarFormaPgto = () => {
    var valorTotalDocumento = this.props.valor.substring(2).replace(',', '.');
    var formaPgto = this.state.formaPgto;
    if (formaPgto > 0) formaPgto = formaPgto * -1;
    var valorDigitado = this.state.valorFormaPgto;
    var somaTotalFormasPgto = 0;
    var temTroco = false;
    var temErro = false;

    if (!valorDigitado) {
      temErro = true;
      showError(
        this,
        titulo,
        'O valor da forma de pagamento deve ser definido'
      );
    }

    if (formaPgto === CodigoFormaPgto.Ticket && !this.state.nsu) {
      temErro = true;
      showError(
        this,
        titulo,
        'Para forma de pagamento Ticket o valor de NSU deve ser definido'
      );
    }

    var listaFormasPgtoSelecionadas = this.props.listaFormasPgtoSelecionadas;
    const objFormaPgto = {
      id: listaFormasPgtoSelecionadas.length + 1,
      codigoFormaPagamento: formaPgto,
      formaPgto: this.codigoFormaPgtoToDescricao(parseInt(formaPgto)),
      formaPgtoId: parseInt(formaPgto),
      valor: 'R$' + parseFloat(valorDigitado).toFixed(2),
      rede: this.state.rede,
      bandeira: this.state.bandeira,
      produto: this.state.produto,
      nsu: this.state.nsu,

      chequepar: this.state.chequepar,
      chequecpf: this.state.chequecpf,
      chequenum: this.state.chequenum,
      chequeven: this.state.chequeven,
      chequevlr: this.state.chequevlr,
      chequeban: this.state.chequeban,
      chequeagn: this.state.chequeagn,
      chequecta: this.state.chequecta,
      chequetit: this.state.chequetit,
      chequeend: this.state.chequeend,
      chequeddd: this.state.chequeddd,
      chequetel: this.state.chequetel
    };

    objFormaPgto.valor = objFormaPgto.valor.replace('.', ',');

    for (let i = 0; i < listaFormasPgtoSelecionadas.length; i++) {
      const element = listaFormasPgtoSelecionadas[i];
      somaTotalFormasPgto += parseFloat(
        element.valor.substring(2).replace(',', '.')
      );
    }

    if (valorDigitado + somaTotalFormasPgto > valorTotalDocumento) {
      if (parseInt(formaPgto) === parseInt(CodigoFormaPgto.Dinheiro))
        //dinheiro
        temTroco = true;
      else {
        temErro = true;
        showError(
          this,
          titulo,
          'Para ' +
            objFormaPgto.formaPgto +
            ' não é permitido inserir valor maior do que o valor total do documento'
        );
      }
    }

    if (!temErro) {
      listaFormasPgtoSelecionadas.push(objFormaPgto);

      if (temTroco) {
        var objFormaPgtoTroco = {};
        objFormaPgtoTroco.formaPgto = 'Troco';
        objFormaPgtoTroco.valor =
          'R$' +
          parseFloat(
            valorDigitado + somaTotalFormasPgto - valorTotalDocumento
          ).toFixed(2);
        objFormaPgtoTroco.valor = objFormaPgtoTroco.valor.replace('.', ',');
        listaFormasPgtoSelecionadas.push(objFormaPgtoTroco);
      }

      this.setState({ listaFormasPgtoSelecionadas });
      this.props.handleAdicionaPagamento(listaFormasPgtoSelecionadas);
    }
  };

  carregarProdutoRede = async codigoRede => {
    if (!codigoRede) codigoRede = this.props.rede;
    const { data: listaProdutoRedeOriginal } = await montaComboProdutoRede(
      codigoRede,
      true
    );

    var listaProdutoRede = listaProdutoRedeOriginal.map(pr => {
      return {
        label: pr.descricao,
        value: pr.codigo
      };
    });

    this.setState({
      listaProdutoRede,
      produto: listaProdutoRedeOriginal[0].codigo
    });
  };

  codigoFormaPgtoToDescricao = codigo => {
    switch (codigo) {
      case -1:
        return 'Dinheiro';

      case -2:
        return 'Cheque';

      case -3:
        return 'Ticket';

      case -4:
        return 'Crédito';

      case -5:
        return 'Vale';

      case -6:
        return 'A Faturar';

      case -7:
        return 'Débito';

      default:
        break;
    }
  };

  handleAdicionarCliente = () => {
    const listaClientesSelecionados = this.props.listaClientesSelecionados;
    const clienteEscolhido = this.state.cliente;

    if (!clienteEscolhido) {
      showError(this, titulo, 'Selecione um cliente');
      return;
    }
    if (listaClientesSelecionados.length > 0) {
      showError(this, titulo, 'Permitida a seleção de apenas um cliente');
      return;
    }
    let objCliente = {};
    if (this.state.isConsumidorFinal) {
      objCliente = {
        autorizado: null,
        cnpj: '00000000000',
        codigo: CODIGO_CONSUMIDOR_FINAL,
        codigoAutorizado: 0,
        codigoCliente: CODIGO_CONSUMIDOR_FINAL,
        placa: null,
        razaoSocial: 'Consumidor Final',
        utilizaFatura: false
      };
    } else {
      const autorizadoEscolhido = this.getAutorizado(this.state.autorizado);

      objCliente = {
        codigoCliente: clienteEscolhido.codigo,
        cnpj: clienteEscolhido.cnpjCpf,
        razaoSocial: clienteEscolhido.nome,
        placa: autorizadoEscolhido ? autorizadoEscolhido.placa : '',
        autorizado: autorizadoEscolhido ? autorizadoEscolhido.nome : '',
        codigoAutorizado: autorizadoEscolhido
          ? Number(autorizadoEscolhido.codigo)
          : null,
        utilizaFatura: clienteEscolhido.creditoFaturamento
          ? !!clienteEscolhido.creditoFaturamento.utilizaFatura
          : false
      };
    }
    listaClientesSelecionados.push(objCliente);
    this.props.handleAdicionaCliente(listaClientesSelecionados);
  };

  retirarCliente = () => {
    this.props.handleAdicionaCliente([]);
  };

  handleRedeChange = e => {
    let value = '';

    value = e.target.value;

    this.setState({
      rede: value
    });

    this.carregarProdutoRede(value);
  };

  limparFormaPgto = e => {
    var listaFormasPgtoSelecionadas = this.props.listaFormasPgtoSelecionadas;
    var listaFormasPgtoNova = [];
    var nome = e.currentTarget.className;

    for (let i = 0; i < listaFormasPgtoSelecionadas.length; i++) {
      const element = listaFormasPgtoSelecionadas[i];
      if (nome.indexOf(element.id) === -1) listaFormasPgtoNova.push(element);
    }

    this.props.handleAdicionaPagamento(listaFormasPgtoNova);
  };

  alterarFormaPgto = e => {
    var listaFormasPgtoSelecionadas = this.props.listaFormasPgtoSelecionadas;
    var listaFormasPgtoNova = [];
    var nome = e.currentTarget.className;

    for (let i = 0; i < listaFormasPgtoSelecionadas.length; i++) {
      const element = listaFormasPgtoSelecionadas[i];
      if (nome.indexOf(element.id) === -1) listaFormasPgtoNova.push(element);
      else {
        element.valor = element.valor.replace(',', '.');
        if (
          parseInt(element.formaPgtoId) !== parseInt(CodigoFormaPgto.Ticket) &&
          parseInt(element.formaPgtoId) !== parseInt(CodigoFormaPgto.Credito) &&
          parseInt(element.formaPgtoId) !== parseInt(CodigoFormaPgto.Debito)
        )
          //diferente de cartão
          this.setState({
            formaPgto: element.formaPgtoId,
            valorFormaPgto: parseFloat(element.valor.substring(2))
          });
        else
          this.setState({
            formaPgto: element.formaPgtoId,
            valorFormaPgto: parseFloat(element.valor.substring(2)),
            rede: element.rede,
            bandeira: element.bandeira,
            produto: element.produto,
            nsu: element.nsu
          });
      }
    }

    this.props.handleAdicionaPagamento(listaFormasPgtoNova);
  };

  getAutorizado = aut => {
    const { listaCompletaAutorizados } = this.state;
    if (listaCompletaAutorizados && listaCompletaAutorizados.length > 0) {
      const escolhido = listaCompletaAutorizados.find(
        a => Number(a.codigo) === Number(aut)
      );
      return escolhido;
    } else {
      return null;
    }
  };

  hasPessoaSelected = () => {
    return isObjectSelected(this.state.cliente);
  };

  hasCampoPessoa = () => {
    return (
      !this.hasPessoaSelected() && !emptyText(String(this.state.campoPessoa))
    );
  };

  isClienteHabilitadoVendaPrazo = () => {
    return (
      this.hasPessoaSelected() &&
      this.state.cliente.creditoFaturamento.utilizaFatura
    );
  };

  clearState = () => {
    this.setState({
      formaPgto: CodigoFormaPgto.Dinheiro,
      valorFormaPgto: '',
      rede: '',
      bandeira: '',
      produto: '',
      nsu: '',
      cliente: {},
      campoPessoa: '',
      isConsumidorFinal: false
    });
  };

  clearPessoa = () => {
    this.setState({
      cliente: {},
      campoPessoa: '',
      isConsumidorFinal: false
    });
  };

  prepareAutorizados = autorizados => {
    const list = [];
    if (autorizados && autorizados.length > 0) {
      list.push({ label: 'Selecione um autorizado', value: '' });
      autorizados.forEach(item => {
        list.push({
          label: item.nome + (item.placa ? ' - ' + item.placa : ''),
          value: item.codigo
        });
      });
    }
    return list;
  };

  handlePesquisarPessoa = async search => {
    const consulta = {
      classificacao: [1],
      search
    };
    const { data: dataPessoas } = await getPessoas(consulta);
    if (
      !dataPessoas ||
      !dataPessoas.result ||
      dataPessoas.result.length === 0
    ) {
      showError(
        this,
        titulo,
        'Nenhum cliente encontrado com a pesquisa "' + search + '" informada',
        'campoPessoa'
      );
    } else if (dataPessoas.result.length === 1) {
      //ASSOCIACAO CATOLICA RAINHA DAS VIRGENS
      const { data: dataPessoa } = await getPessoa(
        dataPessoas.result[0].codigo
      );
      //const cliente = dataPessoas.result[0];
      const c = { ...dataPessoa };
      c.isSelected = true;
      const { data: dataAutorizados } = await montaComboAutorizados(c.codigo);
      let autorizados = [];
      if (dataAutorizados && dataAutorizados.length > 0) {
        dataAutorizados.forEach(a => {
          autorizados.push({ ...a });
        });
      }
      this.setState({
        cliente: c,
        campoPessoa: c.nome,
        listaAutorizados: this.prepareAutorizados(autorizados),
        listaCompletaAutorizados: autorizados
        //listaAutorizados : autorizados
      });
    } else {
      this.setState({
        showModalCliente: true,
        listaClientesSelecionados: dataPessoas.result
      });
    }
  };

  handleCloseModalCliente = async cliente => {
    const campoPessoa = cliente ? cliente.nome : this.state.campoPessoa;

    let c = this.state.cliente,
      autorizados = [];
    // se vier null, não pode apagar uma pesquisa prévia
    if (cliente) {
      const { data: dataPessoa } = await getPessoa(cliente.codigo);
      c = { ...dataPessoa };
      c.isSelected = true;
      const { data: dataAutorizados } = await montaComboAutorizados(
        cliente.codigo
      );
      if (dataAutorizados && dataAutorizados.length > 0) {
        dataAutorizados.forEach(a => {
          autorizados.push({ ...a });
        });
      }
    }

    this.setState({
      cliente: c,
      listaAutorizados: this.prepareAutorizados(autorizados),
      listaCompletaAutorizados: autorizados,
      //listaAutorizados : autorizados,
      campoPessoa,
      showModalCliente: false
    });
  };

  render() {
    const {
      active,
      handleCloseModal,
      documentoFiscal,
      equipamento,
      numero,
      valor,
      camposDesabilitados,
      camposDesabilitadosSangria
    } = this.props;

    const {
      valorFormaPgto,
      formaPgto = -1,
      listaRede,
      listaBandeira,
      listaProdutoRede,
      rede,
      bandeira,
      produto,
      nsu,
      listaFormasPgto,
      campoPessoa,
      isConsumidorFinal,
      listaAutorizados = [],
      parcela,
      cpfCNPJ,
      vencimento,
      banco,
      agencia,
      conta,
      numeroCheque,
      nomeTitular,
      endereco,
      ddd,
      telefone
    } = this.state;

    return (
      <div>
        <Modal
          className={active ? 'active modal-filter' : 'modal-filter'}
          onClose={async () => {
            this.clearState();
            await handleCloseModal(false);
          }}
        >
          <ModalHeader title="Alteração de Forma de Pagamento" subtitle="" />

          <ModalBody>
            <SectionContent
              title="Documento Fiscal"
              accordion
              accordionDefaultDisplay={true}
            >
              <div className="row">
                <div className="col-5">
                  <InputText
                    label="Documento Fiscal:"
                    name="documentoFiscal"
                    value={documentoFiscal}
                    onChange={this.handleInputChange}
                    tabindex={1}
                    disabled
                  />
                </div>
                <div className="col-5">
                  <InputText
                    label="Equipamento:"
                    name="equipamento"
                    value={equipamento}
                    onChange={this.handleInputChange}
                    tabindex={2}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <InputText
                    label="Número:"
                    name="numero"
                    value={numero}
                    onChange={this.handleInputChange}
                    tabindex={3}
                    disabled
                  />
                </div>
                <div className="col-5">
                  <InputText
                    label="Valor:"
                    name="valor"
                    value={valor}
                    onChange={this.handleInputChange}
                    tabindex={4}
                    disabled
                  />
                </div>
              </div>
            </SectionContent>

            <SectionContent
              title="Forma de Pagamento"
              accordion
              accordionDefaultDisplay={false}
            >
              <div className="row">
                <div className="col-5">
                  <Select
                    label="Forma de Pagamento:"
                    name="formaPgto"
                    value={formaPgto}
                    onChange={this.handleInputChange}
                    options={listaFormasPgto}
                    tabindex={5}
                    disabled={camposDesabilitados || camposDesabilitadosSangria}
                  />
                </div>
                <div className="col-40">
                  <InputText
                    label="Valor:"
                    name="valorFormaPgto"
                    value={valorFormaPgto}
                    onChange={this.handleInputChange}
                    tabindex={6}
                    type="number"
                    allowNegative={false}
                    maxlength="45"
                    disabled={camposDesabilitados || camposDesabilitadosSangria}
                  />
                </div>
                {parseInt(formaPgto) !== parseInt(CodigoFormaPgto.Ticket) &&
                parseInt(formaPgto) !== parseInt(CodigoFormaPgto.Credito) &&
                parseInt(formaPgto) !== parseInt(CodigoFormaPgto.Debito) ? (
                  <div className="col-1">
                    <Botao
                      ic
                      tooltip="Adicionar"
                      icon={'icon-lx-plus'}
                      onClick={() => {
                        this.handleAdicionarFormaPgto();
                      }}
                      disabled={
                        camposDesabilitados || camposDesabilitadosSangria
                      }
                    />
                  </div>
                ) : null}
              </div>

              {parseInt(formaPgto) === parseInt(CodigoFormaPgto.Cheque) ? (
                <>
                  <div className="row">
                    <div className="col-3">
                      <InputText
                        label="Parcela:"
                        name="parcela"
                        value={parcela}
                        onChange={this.handleInputChange}
                        tabindex={7}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="CPF/CNPJ:"
                        name="cpfCNPJ"
                        value={cpfCNPJ}
                        onChange={this.handleInputChange}
                        tabindex={8}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Vencimento:"
                        name="vencimento"
                        value={vencimento}
                        onChange={this.handleInputChange}
                        tabindex={9}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <InputText
                        label="Banco:"
                        name="banco"
                        value={banco}
                        onChange={this.handleInputChange}
                        tabindex={10}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Agência:"
                        name="agencia"
                        value={agencia}
                        onChange={this.handleInputChange}
                        tabindex={11}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-3">
                      <InputText
                        label="Conta:"
                        name="conta"
                        value={conta}
                        onChange={this.handleInputChange}
                        tabindex={12}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <InputText
                        label="Nº Cheque:"
                        name="numeroCheque"
                        value={numeroCheque}
                        onChange={this.handleInputChange}
                        tabindex={13}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-5">
                      <InputText
                        label="Nome titular:"
                        name="nomeTitular"
                        value={nomeTitular}
                        onChange={this.handleInputChange}
                        tabindex={14}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <InputText
                        label="Endereço:"
                        name="endereco"
                        value={endereco}
                        onChange={this.handleInputChange}
                        tabindex={15}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <InputText
                        label="DDD:"
                        name="ddd"
                        value={ddd}
                        onChange={this.handleInputChange}
                        tabindex={16}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-5">
                      <InputText
                        label="Telefone:"
                        name="telefone"
                        value={telefone}
                        onChange={this.handleInputChange}
                        tabindex={17}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {parseInt(formaPgto) === parseInt(CodigoFormaPgto.Ticket) ||
              parseInt(formaPgto) === parseInt(CodigoFormaPgto.Credito) ||
              parseInt(formaPgto) === parseInt(CodigoFormaPgto.Debito) ? (
                <>
                  <div className="row">
                    <div className="col-5">
                      <Select
                        label="Rede:"
                        name="rede"
                        value={rede}
                        options={listaRede}
                        onChange={this.handleRedeChange}
                        tabindex={6}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-5">
                      <Select
                        label="Bandeira:"
                        name="bandeira"
                        value={bandeira}
                        onChange={this.handleInputChange}
                        options={listaBandeira}
                        tabindex={7}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <Select
                        label="Produto:"
                        name="produto"
                        value={produto}
                        onChange={this.handleInputChange}
                        options={listaProdutoRede}
                        tabindex={8}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                    <div className="col-40">
                      <InputText
                        label="NSU:"
                        name="nsu"
                        value={nsu}
                        onChange={this.handleInputChange}
                        tabindex={9}
                        maxlength="45"
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>

                    <div className="col-1">
                      <Botao
                        ic
                        tooltip="Adicionar"
                        icon={'icon-lx-plus'}
                        onClick={() => {
                          this.handleAdicionarFormaPgto();
                        }}
                        disabled={
                          camposDesabilitados || camposDesabilitadosSangria
                        }
                      />
                    </div>
                  </div>
                </>
              ) : null}
              <div className="row">
                <div className="col-8">
                  <ReactTable
                    data={this.props.listaFormasPgtoSelecionadas}
                    columns={[
                      {
                        accessor: 'id',
                        Header: 'id',
                        width: 20,
                        show: false
                      },
                      {
                        accessor: 'formaPgto',
                        Header: 'Forma de Pagamento',
                        width: 250
                      },
                      {
                        accessor: 'valor',
                        Header: 'Valor',
                        width: 250
                      },
                      {
                        accessor: 'acoes',
                        Header: 'Ações',
                        width: 150,
                        filterable: false,
                        Cell: ({ row }) => {
                          return (
                            <>
                              {row.formaPgto !== 'Troco' &&
                              row.formaPgto !== 'DINHEIRO (TROCO)' ? (
                                <Botao
                                  ic
                                  disabled={
                                    camposDesabilitados ||
                                    camposDesabilitadosSangria
                                  }
                                  className={row.id}
                                  icon={'icon-lx-edit'}
                                  onClick={this.alterarFormaPgto}
                                />
                              ) : null}
                              <Botao
                                ic
                                disabled={
                                  camposDesabilitados ||
                                  camposDesabilitadosSangria
                                }
                                className={row.id}
                                icon={'icon-lx-trash'}
                                onClick={this.limparFormaPgto}
                              />
                            </>
                          );
                        }
                      }
                    ]}
                    defaultPageSize={5}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando registros..."
                    noDataText="Sem registros para exibir"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                  />
                </div>
              </div>
            </SectionContent>

            <SectionContent
              title="Cliente"
              accordion
              accordionDefaultDisplay={false}
            >
              <div className="row">
                <div className="col-5">
                  <Checkbox>
                    <Checkitem
                      checked={isConsumidorFinal}
                      name="isConsumidorFinal"
                      label="Utilizar consumidor final"
                      onChange={this.handleInputChange}
                      disabled={camposDesabilitadosSangria}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  <ModalClienteFornecedor
                    active={this.state.showModalCliente}
                    handleCloseModal={this.handleCloseModalCliente}
                    lista={this.state.listaClientesSelecionados}
                    headerTitle="Pesquisar Cliente"
                    bodyTitle="Selecione um cliente"
                  />
                  <InputText
                    disabled={isConsumidorFinal || camposDesabilitadosSangria}
                    value={campoPessoa}
                    name="campoPessoa"
                    label="Cliente:"
                    placeholder={
                      'Pesquise o cliente por nome, razão social, ' +
                      'apelido, CPF ou CNPJ'
                    }
                    maxlength={40}
                    onChange={this.handleInputChange}
                    icone={
                      this.hasPessoaSelected()
                        ? 'icon-lx-close'
                        : 'icon-lx-search'
                    }
                    help={
                      this.hasPessoaSelected()
                        ? 'Clique no "x" para limpar o campo'
                        : this.hasCampoPessoa()
                        ? 'Clique na "lupa" para pesquisar'
                        : null
                    }
                    onIconeClick={() => {
                      if (this.hasPessoaSelected()) {
                        this.clearPessoa();
                      } else this.handlePesquisarPessoa(campoPessoa);
                    }}
                    onKeyDown={
                      campoPessoa
                        ? event => {
                            if (event.keyCode === 13)
                              this.handlePesquisarPessoa(campoPessoa);
                            else if (event.keyCode === 27) this.clearPessoa();
                          }
                        : null
                    }
                  />
                </div>
                <div className="col-2">
                  <Botao
                    ic
                    tooltip="Adicionar"
                    icon={'icon-lx-plus'}
                    onClick={() => {
                      this.handleAdicionarCliente();
                      this.forceUpdate();
                    }}
                    disabled={camposDesabilitadosSangria}
                  />
                </div>
              </div>
              {this.isClienteHabilitadoVendaPrazo() && !isConsumidorFinal ? (
                <div className="row">
                  {/* <div className="col-5">
                    <InputText
                      label="Placa:"
                      type="text"
                      className="input"
                      name="placa"
                      value={this.state.placa}
                      maxlength={7}
                      placeholder="Digite a placa"
                      autocomplete="off"
                      pattern={ALPHANUMERIC_PATTERN}
                      onChange={this.handleInputChange}
                    />
                  </div> */}
                  {listaAutorizados.length > 0 ? (
                    <div className="col-1">
                      <Select
                        label="Autorizado:"
                        name="autorizado"
                        value={this.state.autorizado}
                        onChange={this.handleInputChange}
                        options={listaAutorizados}
                        disabled={camposDesabilitadosSangria}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
              <div className="table-ctn">
                <ReactTable
                  data={this.props.listaClientesSelecionados}
                  columns={[
                    {
                      accessor: 'id',
                      Header: 'id',
                      show: false
                    },
                    {
                      accessor: 'codigoCliente',
                      show: false
                    },
                    {
                      accessor: 'utilizaFatura',
                      show: false
                    },
                    {
                      accessor: 'cnpj',
                      Header: 'CPF/CNPJ'
                    },
                    {
                      accessor: 'razaoSocial',
                      Header: 'Nome/Razão Social'
                    },
                    {
                      accessor: 'codigoAutorizado',
                      show: false
                    },
                    {
                      accessor: 'autorizado',
                      Header: 'Autorizado',
                      show:
                        this.props.listaClientesSelecionados &&
                        this.props.listaClientesSelecionados[0] &&
                        this.props.listaClientesSelecionados[0].hasOwnProperty(
                          'utilizaFatura'
                        )
                          ? !!this.props.listaClientesSelecionados[0]
                              .utilizaFatura
                          : false,
                      Cell: props => {
                        return (
                          <div>
                            {props.row.autorizado +
                              (props.row.placa ? ' - ' + props.row.placa : '')}
                          </div>
                        );
                      }
                    },
                    {
                      accessor: 'placa',
                      Header: 'Placa',
                      show: false
                      /* show: this.props.listaClientesSelecionados &&
                        this.props.listaClientesSelecionados[0] &&
                        this.props.listaClientesSelecionados[0]
                          .hasOwnProperty("utilizaFatura") ?
                        !!this.props.listaClientesSelecionados[0].utilizaFatura : false */
                    },
                    {
                      accessor: 'acoes',
                      Header: 'Ações',
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <>
                            <Botao
                              ic
                              className={row.id}
                              icon={'icon-lx-trash'}
                              onClick={() => {
                                this.retirarCliente();
                              }}
                            />
                          </>
                        );
                      }
                    }
                  ]}
                  defaultPageSize={1}
                  previousText="Anterior"
                  nextText="Próximo"
                  loadingText="Carregando registros..."
                  noDataText="Nenhum cliente selecionado"
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                />
              </div>
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={async () => {
                this.clearState();
                await handleCloseModal(false);
              }}
            />
            <Botao
              icon="icon-lx-check"
              title={'Salvar'}
              disabled={camposDesabilitados || camposDesabilitadosSangria}
              onClick={async () => {
                const {
                  listaFormasPgtoSelecionadas,
                  listaClientesSelecionados
                } = this.props;

                const valorTotalDocumento = this.props.valor
                  .substring(2)
                  .replace(',', '.');
                let somaTotalFormasPgto = 0;

                for (let i = 0; i < listaFormasPgtoSelecionadas.length; i++) {
                  const element = listaFormasPgtoSelecionadas[i];
                  if (element.formaPgto === 'Troco')
                    somaTotalFormasPgto -= parseFloat(
                      element.valor.substring(2).replace(',', '.')
                    );
                  else
                    somaTotalFormasPgto += parseFloat(
                      element.valor.substring(2).replace(',', '.')
                    );
                }

                if (somaTotalFormasPgto !== parseFloat(valorTotalDocumento)) {
                  showError(
                    this,
                    titulo,
                    'A soma das formas de pagamento deve ser igual ao ' +
                      'valor total do documento'
                  );
                  return;
                }
                if (listaClientesSelecionados.length === 0) {
                  showError(
                    this,
                    titulo,
                    'Não é permitido documento fiscal sem cliente definido'
                  );
                  return;
                }

                this.clearState();

                await handleCloseModal(
                  true,
                  this.props.codigoEcf,
                  this.props.codigoVenda,
                  this.props.codigoMovimento,
                  this.props.dataDocumento
                );
              }}
            />
          </ModalFooter>
          {tagAlert(this)}
        </Modal>
      </div>
    );
  }
}

export class ModalInfo extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
  }

  render() {
    const { active, handleCloseModal, responsavel, data, hora } = this.props;

    return (
      <div>
        <Modal className={`${active ? 'active' : null} modal-filter`}>
          <ModalHeader title="Dados da conferência" subtitle="" />

          <ModalBody>
            {/* Conteúdo principal */}
            <SectionContent>
              <div className="row">
                <div className="col-12">
                  <div className="form-input">
                    <input
                      type="input"
                      className="input"
                      name="responsavel"
                      value={responsavel}
                      onChange={this.handleInputChange}
                      disabled
                    />
                    <label className="input-label">Responsável:</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-input">
                    <input
                      type="input"
                      className="input"
                      name="data"
                      value={data}
                      onChange={this.handleInputChange}
                      disabled
                    />
                    <label className="input-label">Data:</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-input">
                    <input
                      type="input"
                      className="input"
                      name="hora"
                      value={hora}
                      onChange={this.handleInputChange}
                      disabled
                    />
                    <label className="input-label">Hora:</label>
                  </div>
                </div>
              </div>
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              icon="icon-lx-check"
              title={'OK'}
              onClick={() => {
                handleCloseModal();
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ConferenciaTurno = withRouter(ConferenciaTurno);
export { ConferenciaTurno };
