import axios from 'axios';
import { getToken } from '../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

export const posMFeService = {
  insert,
  update,
  find,
  situacao,
  getSituacoes,
  getEquipamentos,
  getAdquirentes
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function insert(entity) {
  const response = await urlLinxCore.put('/periferico', entity)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function situacao(entity) {
  const response = await urlLinxCore.post('/periferico/situacao', entity)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function update(entity) {
  const response = await urlLinxCore.post('/periferico', entity)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function find(appliedFilters) {
  const response = await urlLinxCore.post('/periferico/listarcomfiltro', { query: appliedFilters })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function getSituacoes() {
  return [
    {
      descricao: "Ativo",
      valor: 0
    },
    {
      descricao: "Inativo",
      valor: 1
    }
  ]
}

async function getEquipamentos() {
  const response = await urlLinxCore.post('/equipamento/listarcomfiltro', {
    query: {
      tipos: [12],
      inativo: false
    }
  });
  return response.data.data;
}

async function getAdquirentes() {
  const response = await urlLinxCore.post('/fornecedor/listarcomfiltro', {
    query: {
      tipos: [6],
      inativo: false
    }
  });
  return response.data.data;
}

