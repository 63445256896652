import React from 'react';

const configuracaoGeralItem = props => (
  <div className="row align-items-center">
    <div className={props.size}>
      <span className="align-middle">{props.titulo}</span>
    </div>
    <div className={props.sizeValue}>{props.children}</div>
  </div>
);

export default configuracaoGeralItem;
