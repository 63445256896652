import axios from 'axios';
import { getToken } from '../../../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../../../shared/constants/GatewayUrlConstant';

export const ExtratoService = {
  listStatement,
  GetColumns
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function listStatement(appliedFilters) {
  try {
    const response = await urlLinxCore.post(`/Stone/ListarExtrato`, appliedFilters);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

function GetColumns() {

  let colunas = [
    { dataField: 'data', caption: 'Data', dataType: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
    { dataField: 'conta', caption: 'Conta' },
    { dataField: 'valor', caption: 'Valor', format: { type: 'currency', precision: 2 } },
    { dataField: 'creditodebito', caption: 'Crédito/Débito' },
    { dataField: 'status', caption: 'Status' },
    { dataField: 'categoria', caption: 'Categoria' },
    { dataField: 'metodopagamento', caption: 'Método de Pagamento' },
    { dataField: 'informacaoadicional', caption: 'Informação Adicional' }
  ];

  return colunas;
}

export default urlLinxCore;
