import { DropDownBox, List } from 'devextreme-react';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Card } from 'react-bootstrap';
import { ComodatoService } from '../../../../../services/con-comodato/Comodato.service';

export const SelectStatusPedido = forwardRef((_, ref) => {
  const [opened, setOpened] = useState(false);
  const [itens, setItens] = useState([]);
  const [selectedItemKeys, setSelectedItemKeys] = useState([]);

  useEffect(() => {
    ComodatoService.getInstance()
      .listarStatus()
      .then(its => {
        setItens(its);
      })
      .catch(err => {
        console.log(err);
      });
  }, [itens]);

  useImperativeHandle(
    ref,
    () => ({
      limpar() {
        setSelectedItemKeys([])
      },
      ids() {
        return selectedItemKeys.join('_');
      },
      nomes() {
        return (selectedItemKeys || []).map(x => itens.find(it => it.id.toString() === x.toString()).nome).join(', ');
      },
    }),
    [selectedItemKeys, itens],
  );
  const onSelectedItemKeysChange = useCallback(
    args => {
      if (args.name === 'selectedItemKeys') {
        if (args.value.join('_') === selectedItemKeys.join('_')) return;
        setSelectedItemKeys(args.value);
      }
    },
    [selectedItemKeys],
  );

  return (
    <div className="form-group text-left">
      <label htmlFor="selectEstabelecimentos" className="text-primary">Status do pedido:</label>
      <DropDownBox
        showClearButton={false}
        placeholder="Escolha o estado do pedido"
        valueExpr="id"
        displayExpr="nome"
        showDropDownButton={true}
        dropDownButtonRender={() => <i className="dx-icon-chevrondown"></i>}
        dataSource={itens.filter(x => x.id > -1)}
        openOnFieldClick={true}
        value={selectedItemKeys}
        onOpenedChange={e => {
          setOpened(e);
        }}
        opened={opened}
        onKeyUp={e => {
          if (opened) e.preventDefault();
          if (
            e.event.originalEvent.code === 'ArrowDown' ||
            e.event.originalEvent.key === 'ArrowDown' ||
            e.event.originalEvent.code === 'Enter' ||
            e.event.originalEvent.key === 'Enter' ||
            e.event.originalEvent.code === 'Space' ||
            e.event.originalEvent.key === 'Space'
          ) {
            setOpened(true);
          }
        }}
        contentRender={() => {
          return (
            <>
              <Card>
                <Card.Body>
                  <List
                    noDataText="Sem origem"
                    keyExpr="id"
                    displayExpr="nome"
                    selectAllText="TODOS"
                    dataSource={itens.filter(x => x.id > -1)}
                    checkedExpr="checked"
                    showSelectionControls={true}
                    selectionMode={'all'}
                    onOptionChanged={onSelectedItemKeysChange}
                    selectedItemKeys={selectedItemKeys}
                  />
                </Card.Body>
                <Card.Footer>
                  <button
                    className="btn btn-primary w-100"
                    onClick={() => {
                      setOpened(false);
                    }}
                  >
                    OK
                  </button>
                </Card.Footer>
              </Card>
            </>
          );
        }}
      />
    </div>
  );
});
