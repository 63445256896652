import React from 'react';
import moment from 'moment';
import styles from './DateToolTip.module.scss'

export default function DateToolTip(props) {
    return (
        <div className={`${styles.pill} ${styles.disabled}`}>
            <span>
                <i className="icon icon-lx-calendar"></i>
                {`${moment(props.inicial).format("DD/MM/YYYY")} a ${moment(props.final).format("DD/MM/YYYY")}`}
            </span>
        </div>

    )
}