import { CharToInt } from './utils';
export // CHRISTOPHE T. C. <wG @ codingz.info>
function CheckIERN(ie) {
  if (ie.length !== 9) return false;
  var nro = new Array(9);
  for (var i = 0; i <= 8; i++) nro[i] = CharToInt(ie.charAt(i));
  var b = 9;
  var soma = 0;
  for (i = 0; i <= 7; i++) {
    soma += nro[i] * b;
    b--;
  }
  soma *= 10;
  var dig = soma % 11;
  if (dig === 10) dig = 0;
  return dig === nro[8];
} //rn
