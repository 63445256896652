import React, { Component } from 'react';

import Botao from '../botao/Botao';
import { Modal, ModalBody, ModalFooter } from '../modal/Modal';
import icsuccess from '../../../assets/images/postopop/modal-alerta-ic-sucesso.png';
import icerro from '../../../assets/images/postopop/modal-alerta-ic-block.png';
import icalerta from '../../../assets/images/postopop/modal-alerta-ic-alerta.png';
import icquestion from '../../../assets/images/postopop/modal-alerta-ic-questao.png';
import Spinner from './Spinner';
import { AGUARDE } from '../../shared/constants/Const';
import { alert } from 'devextreme/ui/dialog';

export const AlertType = {
  None: '',
  Success: 'success',
  Error: 'error',
  Alert: 'alert',
  Question: 'question',
  Cancel: 'cancel',
  Recreate: 'recreate',
  Wait: 'wait',
  WaitCancel: 'waitCancel'
};

export class Alert extends Component {
  renderSwitch(param) {
    const { title, subtitle, handleAction } = this.props;

    switch (param) {
      case 'success':
        return (
          <div>
            <ModalBody>
              <AlertBody icon={icsuccess} title={title} subtitle={subtitle} />
            </ModalBody>
            <ModalFooter>
              <Botao
                secondary
                icon="icon-lx-check"
                title={'OK'}
                onClick={handleAction}
              />
            </ModalFooter>
          </div>
        );
      case 'superError':
      case 'error':
        return (
          <div>
            <ModalBody>
              <AlertBody icon={icerro} title={title} subtitle={subtitle} />
            </ModalBody>
            <ModalFooter>
              <Botao
                secondary
                icon="icon-lx-check"
                title={'OK'}
                onClick={handleAction}
              />
            </ModalFooter>
          </div>
        );

      case 'alert':
        return (
          <div>
            <ModalBody>
              <AlertBody icon={icalerta} title={title} subtitle={subtitle} />
            </ModalBody>
            <ModalFooter>
              <Botao
                secondary
                icon="icon-lx-check"
                title={'OK'}
                onClick={handleAction}
              />
            </ModalFooter>
          </div>
        );

      case 'question':
        return (
          <div>
            <ModalBody>
              <AlertBody icon={icquestion} title={title} subtitle={subtitle} />
            </ModalBody>
            <ModalFooter>
              <Botao
                icon="icon-lx-check"
                title={'Sim'}
                onClick={() => {
                  handleAction(true);
                }}
              />
              <Botao
                secondary
                icon="icon-lx-close"
                title={'Não'}
                onClick={() => {
                  handleAction(false);
                }}
              />
            </ModalFooter>
          </div>
        );

      case 'cancel':
        return (
          <div>
            <ModalBody>
              <AlertBody icon={icquestion} title={title} subtitle={subtitle} />
            </ModalBody>
            <ModalFooter>
              <Botao
                icon="icon-lx-check"
                title={'Sim'}
                onClick={() => {
                  handleAction(true);
                }}
              />
              <Botao
                secondary
                icon="icon-lx-trash"
                title={'Não'}
                onClick={() => {
                  handleAction(false);
                }}
              />
            </ModalFooter>
          </div>
        );

      case 'recreate':
        return (
          <div>
            <ModalBody>
              <AlertBody icon={icquestion} title={title} subtitle={subtitle} />
            </ModalBody>
            <ModalFooter>
              <Botao
                icon="icon-lx-check"
                title={'Sim'}
                onClick={() => {
                  handleAction(true);
                }}
              />
              <Botao
                secondary
                icon="icon-lx-trash"
                title={'Não'}
                onClick={() => {
                  handleAction(false);
                }}
              />
            </ModalFooter>
          </div>
        );
      case 'wait':
        return (
          <div>
            <ModalBody>
              <AlertBody
                icon={null} //icalerta
                title={title}
                subtitle={subtitle ? subtitle : AGUARDE}
              />
            </ModalBody>
          </div>
        );
      case AlertType.WaitCancel:
        return (
          <div>
            <ModalBody>
              <AlertBody
                icon={null} //icalerta
                title={title}
                subtitle={subtitle ? subtitle : AGUARDE}
              />
            </ModalBody>
            <ModalFooter>
              <Botao
                secondary
                icon="icon-lx-close"
                title="Cancelar"
                onClick={handleAction}
              />
            </ModalFooter>
          </div>
        );
      default:
        return <div>PostoPop</div>;
    }
  }

  render() {
    const { type, active, onRequestClose } = this.props;

    return (
      <Modal className={`${active ? 'active' : null}`} onClose={onRequestClose}>
        {this.renderSwitch(type)}
      </Modal>
    );
  }
}

export class AlertBody extends Component {
  render() {
    const { icon, title, subtitle } = this.props;

    return (
      <table>
        <tbody>
          <tr>
            <th rowSpan={2} style={{ padding: 20 }}>
              {icon !== null ? (
                <img src={icon} className='ic' alt='Ícone' />
              ) : (
                <Spinner />
              )}
            </th>
            <th className='title'>{title}</th>
          </tr>
          <tr>
            <td className='subtitle'>{subtitle}</td>
          </tr>
        </tbody>
      </table>
    );
  }
}

const setAlert = (
  sender,
  type = Alert.None,
  title,
  subtitle,
  focus,
  action
) => {
  sender.setState({
    alertOptions: {
      active: type !== Alert.None,
      type,
      title,
      subtitle,
      focus,
      action: action ? action : () => hideAlert(sender)
    }
  });
};

export const showInfo = (sender, title, message, focus, action) => {
  alert(message, title);
  //setAlert(sender, AlertType.Success, title, message, focus, action);
};

export const showWarning = (sender, title, message, focus, action) => {
  alert(message, title);
  //setAlert(sender, AlertType.Alert, title, message, focus, action);
};

export const showQuestion = (sender, title, message, focus, action) => {
  alert(message, title);
  //setAlert(sender, AlertType.Question, title, message, focus, action);
};

export const showError = (sender, title, message, focus, action) => {
  alert(message, title);
  //setAlert(sender, AlertType.Error, title, message, focus, action);
};

export const showWait = (sender, title, message, focus, action) => {
  alert(message, title);
  //setAlert(sender, AlertType.Wait, title, message, focus, action);
};

export const showWaitCancel = (sender, title, message, focus, action) => {
  alert(message, title);
  //setAlert(sender, AlertType.WaitCancel, title, message, focus, action);
};

export const showServerError = (
  sender,
  title,
  e,
  serverMessage,
  errorMessage,
  focus = null,
  action = null
) => {
  if (e && e.response && e.response.data && e.response.data.message)
    showError(
      sender,
      title,
      serverMessage + e.response.data.message,
      focus,
      action
    );
  else showError(sender, title, e.message, focus, action);
};

export const showLoadError = (sender, title, e, focus, action) => {
  return showServerError(
    sender,
    title,
    e,
    'Mensagem do servidor: ',
    'Ocorreu um erro inesperado na leitura.',
    focus,
    action
  );
};

export const showSaveError = (sender, title, e, focus, action) => {
  return showServerError(
    sender,
    title,
    e,
    'Mensagem do servidor: ',
    'Ocorreu um erro inesperado na gravação.',
    focus,
    action
  );
};

export const showDeleteError = (sender, title, e, focus, action) => {
  return showServerError(
    sender,
    title,
    e,
    'Mensagem do servidor: ',
    'Ocorreu um erro inesperado na exclusão.',
    focus,
    action
  );
};

export const showExecuteError = (sender, title, e, focus, action) => {
  return showServerError(
    sender,
    title,
    e,
    'Mensagem do servidor: ',
    'Ocorreu um erro inesperado na execução.',
    focus,
    action
  );
};

export const setFocus = elementID => {
  const node = document.getElementById(elementID);
  node && node.focus({ preventScroll: false });
};

export const hideAlert = (sender, history, urlToBack) => {
  const focus = sender.state.alertOptions
    ? sender.state.alertOptions.focus
    : null;
  setAlert(sender);
  if (urlToBack) history.push(urlToBack);
  else if (focus) setFocus(focus);
};

export const hideWait = sender => {
  if (sender && sender instanceof Component) {
    sender.forceUpdate();
    hideAlert(sender);
  }
};

export const tagAlert = sender => {
  const { alertOptions } = sender.state;
  if (alertOptions)
    return (
      <Alert
        active={alertOptions.active}
        type={alertOptions.type}
        title={alertOptions.title}
        subtitle={alertOptions.subtitle}
        handleAction={alertOptions.action}
      />
    );
};
