import axios from "axios";
import { getToken } from "../../../../../authentication/Auth";
import { BASE_URL_GATEWAY } from "../../../../../shared/constants/GatewayUrlConstant";

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

const getCharges = async (filter) => {
  const queryString = "?" + Object.entries(filter)
  .map(([key, value]) => `${key}=${value}`)
  .join("&");

  try {
    const response = await urlLinxCore.get(`/Stone/ListaBoleto${queryString}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const getInvoices = async (cod, titleType) => {
  try {
    const response = await urlLinxCore.get(`Stone/Titles?estaCod=${cod}&tipoTitulo=${titleType}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const postCancelInvoice = async (payload) => {
  try {
    const response = await urlLinxCore.post("/Stone/CancelarBoleto", payload);

    return {
      status: response.status,
      notificationLevel: 1,
      data: response.data,
      message: "Cancelamento realizado com sucesso",
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data.message || "Erro ao cancelar o boleto",
      };
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      };
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "Ocorreu um erro desconhecido",
      };
    }
  }
};



const postGenerateInvoice = async (payload) => {
  try {
    const response = await urlLinxCore.post("/Stone/GerarBoleto", payload);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const getFormattedBillName = () => {
  const now = new Date();

  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const hour = now.getHours().toString().padStart(2, "0");
  const minute = now.getMinutes().toString().padStart(2, "0");
  const second = now.getSeconds().toString().padStart(2, "0");

  return `boleto_${year}${month}${day}${hour}${minute}${second}`;
}

const getPdfDownload = async (id) => {
  try {
    const response = await urlLinxCore.get(`/Stone/ObterBoletoPDF?id=${id}`, {
      responseType: "blob",
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${getFormattedBillName()}.pdf`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error during PDF download", error);
  }
};

const getCustomerByName = async (cod, name) => {
  try {
    const response = await urlLinxCore.get(`Cliente/BuscarStone?nome=${name}&estaCod=${cod}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
};

export const CobrancasService = {
  getCharges,
  getInvoices,
  postGenerateInvoice,
  postCancelInvoice,
  getPdfDownload,
  getCustomerByName
};
