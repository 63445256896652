import React from 'react';
import ContentLoader from 'react-content-loader';

const CardLoader = () => (
  <ContentLoader
    speed={1}
    width={355}
    height={260}
    viewBox="0 0 355 260"
    backgroundColor="#f3f3f3"
    foregroundColor="#dbdbdb"
  >
    <rect x="38" y="78" rx="3" ry="3" width="117" height="22" />
    <rect x="38" y="4" rx="3" ry="3" width="112" height="15" />
    <rect x="38" y="155" rx="3" ry="3" width="150" height="14" />
    <rect x="38" y="30" rx="3" ry="3" width="201" height="13" />
    <rect x="205" y="135" rx="3" ry="3" width="150" height="14" />
    <rect x="38" y="135" rx="3" ry="3" width="150" height="14" />
    <rect x="309" y="1" rx="5" ry="5" width="40" height="37" />
    <rect x="205" y="155" rx="3" ry="3" width="150" height="14" />
    <rect x="38" y="210" rx="3" ry="3" width="150" height="14" />
    <rect x="38" y="230" rx="3" ry="3" width="150" height="14" />
  </ContentLoader>
);

export default CardLoader;
