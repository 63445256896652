import { GetTokenHorusBiService } from "./GetTokenHorusBi.service";

export class HorusBiService {
  static instance = null;
  static getInstance() {
    if (!HorusBiService.instance) {
      HorusBiService.instance = new HorusBiService();
    }
    return HorusBiService.instance;
  }

  token = async () => {
    return await GetTokenHorusBiService.getInstance().execute();
  };
}
