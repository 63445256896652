import { getMenusAtuais, menuPermiteAcesso, sameInt } from '../../shared/utils/Utils';

export const applicationService = {
  getMenus
};

const getSubMenusTratado = (subMenuLocalStorage, menusLocalStorage) => {
  const children = [];
  if(subMenuLocalStorage.length>0 && sameInt(subMenuLocalStorage[0].codigoMenuPai,80480)){
    subMenuLocalStorage = subMenuLocalStorage.sort(function (a, b) {
      if (a.descricao < b.descricao) return -1;
      if (a.descricao > b.descricao) return 1;
      return 0;
    });
  }
  subMenuLocalStorage.forEach(element => {
    if (
      element.codigoMenuPai === 60010 &&
      !menuPermiteAcesso(element.caminhoMenu)
    ) {
      return null;
    } else {
      if (element.caminhoMenu) {
        let itemMenu = {};
        itemMenu.codigo = element.codigoMenu;
        itemMenu.title = element.descricao;
        itemMenu.link = element.caminhoMenu;
        itemMenu.icon = element.caminhoImagem;
        let subMenuSub = menusLocalStorage.filter(
          sm => sm.codigoMenuPai === element.codigoMenu
        );
        if (subMenuSub.length > 0) {
          if (parseInt(element.codigoMenu) !== 60990)
            itemMenu.submenu = getSubMenusTratado(
              subMenuSub,
              menusLocalStorage
            );
        }
        children.push(itemMenu);
      } else {
        let subMenuSub = menusLocalStorage.filter(
          sm => sm.codigoMenuPai === element.codigoMenu
        );
        if (subMenuSub.length > 0) {
          let itemMenu = {};
          itemMenu.codigo = element.codigoMenu;
          itemMenu.title = element.descricao;
          itemMenu.children = getSubMenusTratado(subMenuSub, menusLocalStorage);

          children.push(itemMenu);
        }
      }
    }
  });
  return children;
};

function getMenus() {
  const menu = [];
  let menusLocalStorage = getMenusAtuais();

  menusLocalStorage.forEach(element => {
    if (element.codigoMenuPai === 0) {
      var itemMenu = {};
      itemMenu.codigo = element.codigoMenu;
      itemMenu.title = element.descricao;
      itemMenu.icon = element.caminhoImagem;
      //   element.caminhoImagem[0] === '/'
      //     ? element.caminhoImagem
      //     : '/' + element.caminhoImagem;
      itemMenu.children = getSubMenusTratado(
        menusLocalStorage.filter(m => m.codigoMenuPai === element.codigoMenu),
        menusLocalStorage
      );
      menu.push(itemMenu);
    }
  });

  return menu;
}
