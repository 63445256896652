import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Select,
  Checkbox,
  Checkitem,
  handleInputChange
} from '../../../components/formulario/Formulario';
import List, { sortInt } from '../../../components/formulario/List';

import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  tagAlert,
  hideAlert,
  showError,
  showInfo,
  showQuestion,
  showWait,
  showDeleteError,
  showSaveError,
  hideWait
} from '../../../components/alert/Alert';

import { withRouter } from 'react-router-dom';

import {
  getCentrosMonetarios,
  incluirCentroMonetario,
  alterarCentroMonetario,
  excluirCentroMonetario
} from '../../../services/CentrosMonetarios.service';

import { getBancos } from '../../../services/Bancos.service';

import {
  toFloatFormattedDisplay,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  menuPermiteInclusao,
  getNomePagina,
  validaDadosLogin
} from '../../../shared/utils/Utils';
import { menus } from '../../../shared/constants/MenuConstants';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import SectionHeader from '../../../components/section/Header';
import Footer from '../../../components/core/footer/Footer';

const PATH_MENU = menus.COD_60550;
const titulo = 'Centros monetários';
const MAIN_PATH = '/configuracoes/centro-monetarios';
const NEW_PATH = MAIN_PATH + '/new';

class Form extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      codigoCentroMonetario,
      codigoBanco,
      agencia,
      digitoAgencia,
      conta,
      digitoConta,
      descricao,
      codigoEmpresa,
      cnpjEmpresa,
      inativo = false,
      restrito,
      rowVersion = null,
      saldo = 0,
      possuiMovimentacao = false
    } = this.props.CentroMonetario;

    this.state = {
      codigoCentroMonetario,
      codigoBanco,
      agencia,
      digitoAgencia,
      conta,
      digitoConta,
      descricao,
      codigoEmpresa,
      cnpjEmpresa,
      ativo: !inativo,
      restrito,
      rowVersion,
      alertActive: false,
      alertType: '',
      alertTitle: '',
      alertSubtitle: '',

      centrosExistentes: this.props.centrosExistentes,
      centrosListaTransfere: [],

      bancos: [],
      bancosarray: [],

      saldo,
      possuiMovimentacao,

      restritoOriginal: restrito
    };
  }

  async componentDidMount() {
    const bancosArray = this.props.bancosArray;
    let primeiroBanco = 1;
    if (bancosArray) {
      this.setState({
        bancos: bancosArray
          .filter(ba => ba.tipo.descricao === 'Banco')
          .map(b => {
            return {
              label: ('000' + b.codigo).slice(-3) + ' - ' + b.nome,
              value: b.codigo
            };
          })
      });

      if (!this.props.CentroMonetario.rowVersion) {
        for (let i = 0; i < bancosArray.length; i++) {
          const banco = bancosArray[i];
          if (banco.tipo.descricao === 'Banco') {
            primeiroBanco = banco.codigo;
            break;
          }
        }

        this.setState({ codigoBanco: primeiroBanco });
      }
    }
  }

  handleSalvar = async () => {
    const {
        //state de centro monetario
        codigoCentroMonetario,
        codigoBanco,
        agencia,
        digitoAgencia,
        conta,
        digitoConta,
        descricao,
        codigoEmpresa,
        ativo,
        restrito,
        rowVersion
      } = this.state,
      inativo = !ativo;

    const [action, params] =
      rowVersion !== null
        ? [
            alterarCentroMonetario,
            [
              codigoCentroMonetario,
              codigoBanco,
              agencia,
              digitoAgencia,
              conta,
              digitoConta,
              descricao,
              inativo,
              restrito,
              rowVersion
            ]
          ]
        : [
            incluirCentroMonetario,
            [
              codigoCentroMonetario,
              codigoBanco,
              agencia,
              digitoAgencia,
              conta,
              digitoConta,
              descricao,
              codigoEmpresa,
              inativo,
              restrito
            ]
          ];

    let temErros = this.verificaCentroMonetario();

    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    if (!temErros) {
      temErros = this.verificaCentroExistente(
        codigoBanco,
        agencia,
        conta,
        codigoCentroMonetario
      );
    }

    if (!temErros) {
      try {
        showWait(
          this,
          titulo,
          'Por favor, aguarde enquanto o centro monetário é salvo.'
        );

        await action(...params);

        showInfo(
          this,
          titulo,
          `Centro Monetário  ${
            rowVersion === null ? 'cadastrado' : 'alterado'
          } com sucesso!`,
          null,
          () => {
            this.props.history.push(MAIN_PATH);
            hideAlert(this);
          }
        );
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
  };

  handleExcluir = async () => {
    var temErros = false;

    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    if (this.state.possuiMovimentacao) {
      showError(
        this,
        titulo,
        'Não é permitido excluir Centro Monetário com histórico'
      );
      temErros = true;
    }

    if (!temErros)
      if (parseInt(this.state.saldo) !== 0) {
        showError(
          this,
          titulo,
          'Não é permitido excluir Centro Monetário com saldo diferente de zero'
        );
        temErros = true;
      }

    if (!temErros) {
      showQuestion(
        this,
        titulo,
        'Deseja cancelar a operação?',
        null,
        async resp => {
          if (resp) {
            try {
              showWait(
                this,
                titulo,
                'Por favor, aguarde enquanto centro monetário é excluido.'
              );

              await excluirCentroMonetario(this.state.codigoCentroMonetario);

              showInfo(
                this,
                titulo,
                'Centro Monetário excluído com sucesso!',
                null,
                () => {
                  this.props.history.push(MAIN_PATH);
                  hideAlert(this);
                }
              );
            } catch (err) {
              showDeleteError(this, titulo, err);
            }
          } else {
            hideAlert(this);
          }
        }
      );
    }
  };

  handleCancelar = () => {
    showQuestion(this, titulo, 'Deseja cancelar a operação?', null, resp => {
      if (resp) {
        this.props.history.push(MAIN_PATH);
        hideAlert(this);
      } else {
        hideAlert(this);
      }
    });
  };

  verificaCentroMonetario = () => {
    //verifica preenchimento dos campos
    var temErros = false;

    if (!this.state.descricao || this.state.descricao.trim() === '') {
      showError(this, titulo, 'É necessário informar a descrição.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.codigoBanco) {
      showError(this, titulo, 'É necessário informar o código do banco.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.agencia) {
      showError(this, titulo, 'É necessário informar o número da Agência.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.conta) {
      showError(this, titulo, 'É necessário informar o número da Conta.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.ativo && !this.state.rowVersion) {
      showError(
        this,
        titulo,
        'Não é permitido inclusão de centro monetário inativo'
      );
      temErros = true;
    }

    if (temErros) return temErros;

    /*  if ((!this.state.ativo) && (this.state.possuiMovimentacao)) {
            showError(this, titulo, "Não é permitido inativar Centro Monetário com histórico");
            temErros = true;
        }

        if (temErros)
            return temErros;*/

    if (!this.state.ativo && parseInt(this.state.saldo) !== 0) {
      showError(
        this,
        titulo,
        'Não é permitido inativar Centro Monetário com saldo diferente de zero'
      );
      temErros = true;
    }

    if (temErros) return temErros;
  };

  validaTeclaDigitada = e => {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }

    if (e.key === 'ç') {
      e.preventDefault();
    }
  };

  validaTextoColado = event => {
    console.log(event.clipboardData.getData('Text'));

    var textoNovo = '';

    var textoOriginal = '';

    var tamMax = 0;
    var tamTexto = 0;

    tamMax = event.target.maxLength;

    var caracterEspecial = false;

    var campo = event.target.name;

    textoOriginal = event.clipboardData.getData('Text');

    for (let i = 0; i < textoOriginal.length; i++) {
      const re = /[0-9a-zA-Z]+/g;
      const c = textoOriginal[i];
      tamTexto = textoNovo.length;
      caracterEspecial = false;
      if (!re.test(c)) {
        caracterEspecial = true;
      }

      if (c === 'ç') {
        caracterEspecial = true;
      }

      if (tamTexto < tamMax) if (!caracterEspecial) textoNovo += c;
    }

    if (caracterEspecial) event.preventDefault();

    this.setState({
      [campo]: textoNovo
    });
  };

  verificaCentroExistente = (
    idBanco,
    idAgencia,
    idConta,
    codigoCentroMonetario
  ) => {
    let existeCentro = false;

    let lista = this.state.centrosExistentes;
    for (let i = 0; i < lista.length; i++) {
      const centro = lista[i];
      if (centro.codigoCentroMonetario !== codigoCentroMonetario)
        if (
          parseInt(centro.codigoBanco) === parseInt(idBanco) &&
          centro.agencia === idAgencia &&
          centro.conta === idConta
        ) {
          existeCentro = true;
          break;
        }
    }
    if (existeCentro) {
      showError(this, titulo, 'Conta já cadastrada!');
    }

    return existeCentro;
  };

  render() {
    const {
      //campos tela
      codigoCentroMonetario,
      codigoBanco,
      agencia,
      digitoAgencia,
      conta,
      digitoConta,
      descricao,
      ativo,
      restrito,
      rowVersion,
      restritoOriginal,
      saldo,

      bancos
    } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent>
              <div className="row">
                <div className="col-1">
                  <InputText
                    label="Código:"
                    value={codigoCentroMonetario}
                    name="codigoCentroMonetario"
                    type="number"
                    onChange={this.handleInputChange}
                    allowNegative={false}
                    disabled
                    tabindex={1}
                  />
                </div>
                <div className="col-6">
                  <InputText
                    label="Descrição:"
                    value={descricao}
                    name="descricao"
                    maxlength="50"
                    onChange={this.handleInputChange}
                    tabindex={2}
                  />
                </div>
              </div>
            </SectionContent>
            <div className="content-divider" />
            <SectionContent title="Dados Bancários">
              <div className="row">
                <div className="col-3">
                  <Select
                    label="Banco:"
                    name="codigoBanco"
                    value={codigoBanco}
                    onChange={this.handleInputChange}
                    options={bancos}
                    tabindex={3}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <InputText
                    label="Agência:"
                    value={agencia}
                    name="agencia"
                    maxlength="4"
                    onChange={this.handleInputChange}
                    tabindex={4}
                    onKeyDown={this.validaTeclaDigitada}
                    onPaste={this.validaTextoColado}
                  />
                </div>
                <div className="col-2">
                  <InputText
                    label="Dígito:"
                    value={digitoAgencia}
                    name="digitoAgencia"
                    maxlength="1"
                    onChange={this.handleInputChange}
                    tabindex={5}
                    onKeyDown={this.validaTeclaDigitada}
                    onPaste={this.validaTextoColado}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-1">
                  <InputText
                    label="Conta:"
                    value={conta}
                    name="conta"
                    maxlength="10"
                    onChange={this.handleInputChange}
                    tabindex={6}
                    onKeyDown={this.validaTeclaDigitada}
                    onPaste={this.validaTextoColado}
                  />
                </div>
                <div className="col-2">
                  <InputText
                    label="Dígito:"
                    value={digitoConta}
                    name="digitoConta"
                    maxlength="1"
                    onChange={this.handleInputChange}
                    tabindex={7}
                    onKeyDown={this.validaTeclaDigitada}
                    onPaste={this.validaTextoColado}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <InputText
                    label="Saldo:"
                    value={toFloatFormattedDisplay(saldo)}
                    name="saldo"
                    onChange={this.handleInputChange}
                    tabindex={8}
                    disabled
                    onKeyDown={this.validaTeclaDigitada}
                    decimalScale={2}
                  />
                </div>
              </div>
            </SectionContent>
            <div className="content-divider" />
            <SectionContent title="Opções">
              <div className="row">
                <div className="col-1">
                  <Checkbox label="Ativo:">
                    <Checkitem
                      label=""
                      name="ativo"
                      checked={ativo}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>

                <div className="col-1">
                  <Checkbox label="Restrito:">
                    <Checkitem
                      label=""
                      name="restrito"
                      checked={restrito}
                      onChange={this.handleInputChange}
                      disabled={rowVersion === null}
                    />
                  </Checkbox>
                </div>
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
        <Footer
          saveAction={
            rowVersion === null
              ? this.handleSalvar
              : restritoOriginal
              ? null
              : menuPermiteAlteracao(PATH_MENU)
              ? this.handleSalvar
              : null
          }
          deleteAction={
            rowVersion === null
              ? null
              : restritoOriginal
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
          cancelAction={this.handleCancelar}
        />

        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class CentrosMonetarios extends Component {
  state = {
    centroMonetarios: [],
    centroMonetariosel: {}
  };

  doMount = async () => {
    const { data: responsecentroMonetarios } = await getCentrosMonetarios();
    var centroMonetarios = responsecentroMonetarios.result;

    const { data: bc } = await getBancos();
    const bancosArray = bc.result;
    this.setState({ bancosArray });

    for (let i = 0; i < centroMonetarios.length; i++) {
      centroMonetarios[i].descricaoBanco = this.buscaDescricaoBanco(
        centroMonetarios[i].codigoBanco
      );
    }

    const ceMoOrd = centroMonetarios.sort(function(a, b) {
      if (a.descricaoBanco < b.descricaoBanco) return -1;
      if (a.descricaoBanco > b.descricaoBanco) return 1;
      return 0;
    });

    this.setState({ centroMonetarios: ceMoOrd });
  };

  async componentDidMount() {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto os dados são carregados.'
    );

    try {
      await this.doMount();
    } finally {
      hideWait(this);
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      showWait(
        this,
        titulo,
        'Por favor, aguarde enquanto os dados são carregados.'
      );

      try {
        await this.doMount();
      } finally {
        hideWait(this);
      }
    }
  }

  buscaDescricaoBanco = codigoBanco => {
    var arBancos = this.state.bancosArray;
    for (let i = 0; i < arBancos.length; i++) {
      const banco = arBancos[i];
      if (parseInt(banco.codigo) === parseInt(codigoBanco)) {
        var descricao = banco.nome;
        break;
      }
    }

    return descricao;
  };

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.setState({ centroMonetariosel: rowInfo.original });
      this.props.history.push(NEW_PATH);
    }
  };

  render() {
    const { edit } = this.props,
      { centroMonetariosel } = this.state;
    return (
      <>
        {edit ? (
          <Form
            CentroMonetario={centroMonetariosel}
            centrosExistentes={this.state.centroMonetarios}
            bancosArray={this.state.bancosArray}
          />
        ) : (
          <>
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
              <SectionHeader
                right={
                  <div className="button-container">
                    {edit ? (
                      <></>
                    ) : menuPermiteInclusao(PATH_MENU) ? (
                      <Botao
                        ic
                        icon="icon-lx-plus"
                        onClick={() => {
                          this.setState({ centroMonetariosel: {} });
                          this.props.history.push(NEW_PATH);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                }
              />

              <List
                defaultPageSize={10}
                onClick={this.handleTableClick}
                cols={[
                  {
                    accessor: 'codigoCentroMonetario',
                    Header: 'Código',
                    width: 100,
                    filterable: false
                  },
                  {
                    accessor: 'descricaoBanco',
                    Header: 'Banco',
                    width: 350,
                    filterable: false
                  },
                  {
                    accessor: 'agencia',
                    Header: 'Agência',
                    width: 100,
                    filterable: false,
                    sortMethod: sortInt
                  },
                  {
                    accessor: 'conta',
                    Header: 'Conta',
                    width: 100,
                    filterable: false,
                    sortMethod: sortInt
                  },
                  {
                    accessor: 'descricao',
                    Header: 'Descrição',
                    width: 200,
                    filterable: false
                  },
                  {
                    accessor: 'inativo',
                    Header: 'Ativo',
                    width: 100,
                    filterable: false,
                    Cell: ({ row }) => {
                      return (
                        <Botao
                          secondary={row.inativo}
                          ic
                          icon={row.inativo ? 'icon-lx-close' : 'icon-lx-check'}
                        />
                      );
                    }
                  }
                ]}
                rows={this.state.centroMonetarios}
              />
            </LinxPostos>
          </>
        )}

        {tagAlert(this)}
      </>
    );
  }
}

CentrosMonetarios = withRouter(CentrosMonetarios);
export { CentrosMonetarios };
