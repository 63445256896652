import React, { memo, useCallback, useState, forwardRef, useEffect, useImperativeHandle } from 'react';
import { DropDownImage } from '../drop-down-image';
import { Form } from 'react-bootstrap';
import { SelectBox } from 'devextreme-react';
import { RegraParcelamentoService } from '../../../../../services/regra-parcelamento/RegraParcelamento.service';
import { alert } from 'devextreme/ui/dialog';

const DropBoxRegrasParcelamento = forwardRef(({ title, onSelected, produtoRede, redeCod, bandCod, valorPagamento }, ref) => {
  const [regrasParcelamentos, setRegrasParcelamentos] = useState(null);
  const [regraParcelamento, setRegraPacelamento] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //produto rede vem com id 0
    //então a validação !produtoRede estava considerando zero como não preenchido
    if((produtoRede === null || produtoRede === undefined) ||
       (redeCod === null || redeCod === undefined) ||
       (bandCod === null || bandCod === undefined) ||
       (!valorPagamento)
    ) return;

    const fetchData = async () => {
      try {
        //Aqui fazer a requisição para a API de RegraParcelamento e setar o estado de regrasParcelamento
        console.log('produtoRede' + produtoRede);
        //if (produtoRede ) {
          setLoading(true);
          var payload = {
            bandCod: bandCod,
            redeCod: redeCod,
            prReCod: produtoRede,
            valorPagamento: valorPagamento,
          };
          const data = await RegraParcelamentoService.getInstance().listarRegras(payload);

          const formattedData = data.map((item, index) => ({
            id: index + 1,
            regraParcelamentoId: item.regraParcelamentoId,
            descricao: item.descricao,
            qtParcelas: item.qtParcelas,
            juros: item.juros,
            valorComJuros: item.valorComJuros,
            valorParcela: item.valorParcela,
            taxa: item.taxa
          }));

          setRegrasParcelamentos(formattedData);

          setLoading(false);
        // } else {
        //   setRegrasParcelamentos(null);
        // }
      } catch (error) {
        setLoading(false);
        alert('Erro', 'Erro ao consultar RegraParcelamento', 'error');
        console.error('Consulta RegraParcelamento => ', (error || {}).message);
      }
    };

    fetchData();
  }, [produtoRede, bandCod, redeCod, setRegrasParcelamentos, valorPagamento]);


  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem) {
        if (regraParcelamento) return;
        setRegraPacelamento(null);
        return;
      }
      const data = { ...e.selectedItem };
      if (data.id === (regraParcelamento || {}).id) return;
      setRegraPacelamento(data);
      if (typeof onSelected === 'function') {
        onSelected(data);
      }
    },
    [regraParcelamento,onSelected],
  );

  useImperativeHandle(
    ref,
    () => ({
      getRegraParcelamento: () => {
        return regraParcelamento;
      },
      clear: () => {
        setRegraPacelamento(null);
        if (onSelected) onSelected(null);
      },
    }),
    [regraParcelamento, onSelected],
  );

  const onValueChanged = useCallback(
    data => {
      if (!data.event) return;
      if (onSelected) onSelected(data.value);
    },
    [onSelected],
  );

  return (
    <>
      <Form.Label htmlFor="produtoRede" className="text-primary">
        {title ? title : 'Regras de Parcelamento'}
        <span className="text-danger">*</span>
      </Form.Label>
      <SelectBox
        dropDownButtonRender={DropDownImage}
        showDropDownButton={true}
        // searchEnabled={true}
        hint="Selecione a Regra de Parcelamento"
        placeholder={loading ? 'CARREGANDO...' : 'Selecione a Regra Parcelamento'}
        displayExpr="descricao"
        acceptCustomValue={false}
        height={40}
        valueExpr="id"
        value={regraParcelamento}
        onSelectionChanged={onSelectionChanged}
        onValueChanged={onValueChanged}
        dataSource={regrasParcelamentos}
        selectedItem={regraParcelamento || {}}
        name="regraParcelamento"
      />
    </>
  );
});

export default memo(DropBoxRegrasParcelamento);
