import React, { useState, useRef } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Popup } from "devextreme-react";

import { CustomButton } from "../../SharedComponents";
import { EntreContas } from "./EntreContas";
import { Realizadas } from "./Realizadas";
import { Externas } from "./Externas";

import { locale } from "devextreme/localization";
locale("br");

export const Transferencias = () => {
  const [isShow, setIsShow] = useState(false);
  const [typeModal, setModalType] = useState(1);

  const optionsButton = [
    {
      id: 1,
      icon: "textdocument",
      title: "Transferência Interna",
    },
    {
      id: 2,
      icon: "exportselected",
      title: "Transferência Externa",
    },

  ];

  const externasRef = useRef();

  const getModalTitle = () => typeModal && optionsButton.find(({ id }) => id === typeModal).title;

  const openModal = () => {
    setIsShow(!isShow);

    if (externasRef.current) {
      externasRef.current.resetStep();
    }
  };

  const getChildren = () => {
    switch (typeModal) {
      case 1: return <EntreContas onSuccess={openModal} />;
      case 2: return <Externas ref={externasRef} onSuccess={openModal} />;
      default: return null;
    }
  }

  const configModal = (id) => {
    setModalType(id);
    getModalTitle();
    getChildren();
    openModal();
  }

  return (
    <Col>
      <Container fluid>
        <Row as="header">
          <h2>Transferência</h2>
        </Row>
        <Row style={{gap: 8}}>
          {optionsButton.map(({ id, icon, title,  }) => (
            <CustomButton className="rounded" key={id} icon={icon} onClick={() => configModal(id)}>{ title }</CustomButton>
          ))}
        </Row>
        <Row>
          <Realizadas />
        </Row>
        <Popup
          visible={isShow}
          dragEnabled={false}
          showTitle={true}
          title={getModalTitle()}
          showCloseButton={true}
          hideOnOutsideClick={true}
          onHiding={openModal}
          width={776}
          height={556}
        >
          {getChildren()}
        </Popup>
      </Container>
    </Col>
  );
}
