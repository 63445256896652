import { getDadosLogin } from './Utils';

var CryptoJS = require('crypto-js');

export class SessionManager {
  static instance = null;
  static getInstance() {
    if (!SessionManager.instance) {
      SessionManager.instance = new SessionManager();
    }
    return SessionManager.instance;
  }
  $key = null;

  constructor() {
    this.$key = '123456$#@$^@1ERF';
  }

  saveEncryptedData = (key, data, asSession = true) => {
    if (!data) {
      this.saveEncryptedString(key, null, asSession);
      return;
    }
    const str = JSON.stringify(data);
    this.saveEncryptedString(key, str, asSession);
  };
  getEncryptedData = (key, asSession = true) => {
    try {
      const str = this.getEncryptedString(key, asSession);
      if (!str) return null;
      return JSON.parse(str);
    } catch (error) {
      this.remove(key, asSession);
      return null;
    }
  };
  saveEncryptedString = (key, data, asSession = true) => {
    try {
      const login = getDadosLogin();
      const individualKey = `empresa_${login.cnpj}_${key}`;
      if (!data) {
        localStorage.removeItem(individualKey);
        return;
      }

      const hash = CryptoJS.AES.encrypt(data, this.$key).toString();
      asSession ? sessionStorage.setItem(individualKey, hash) : localStorage.setItem(individualKey, hash);
    } catch (error) {
      throw error;
    }
  };
  getEncryptedString = (key, asSession = true) => {
    const individualKey = `empresa_${getDadosLogin().cnpj}_${key}`;
    try {
      const str = asSession ? sessionStorage.getItem(individualKey) : localStorage.getItem(individualKey);
      if (!str) return null;
      const bytes = CryptoJS.AES.decrypt(str, this.$key);
      return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      this.remove(key, asSession);
      return null;
    }
  };
  remove = (key, asSession = true) => {
    const individualKey = `empresa_${getDadosLogin().cnpj}_${key}`;
    asSession ? sessionStorage.removeItem(individualKey) : localStorage.removeItem(individualKey);
  };
}
