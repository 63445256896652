import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'react-bootstrap';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { EnderecoAdicional } from '../endereco-adicional/EnderecoAdicional';

function DropBoxEntrega({ title }) {
  const dispatch = useDispatch();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const { changeValue, changeBlur } = useNovaOs();

  const onSelectionChanged = useCallback(
    e => {
      const id = parseInt(e.target.value, 10);
      const enderecoEntrega = values.cliente.enderecos.find(x => id === x.id);
      dispatch(
        changeValue({
          fieldName: 'enderecoEntrega',
          value: enderecoEntrega,
        }),
      );
    },
    [dispatch, changeValue, values.cliente],
  );

  const handlerAddAddress = useCallback(
    async endereco => {
      var addresses = [...values.cliente.enderecos];
      var index = addresses.findIndex(x => x.id === endereco.id);
      if (index === -1) addresses.push(endereco);
      else addresses[index] = endereco;
      dispatch(changeValue({ fieldName: 'cliente', value: { ...values.cliente, enderecos: addresses } }));
    },
    [dispatch, changeValue, values.cliente],
  );
  const atualizaEnderecoEntrega = useCallback(
    async endereco => {
      dispatch(changeValue({ fieldName: 'enderecoEntrega', value: { ...endereco } }));
    },
    [dispatch, changeValue],
  );

  return (
    <div className="form-group">
      <Form.Label htmlFor="enderecoEntrega" className="text-primary">
        {title}
        <span className="text-danger">*</span>
      </Form.Label>
      <div className="d-flex">
        <select
          onBlur={() => dispatch(changeBlur({ fieldName: 'enderecoEntrega' }))}
          onChange={onSelectionChanged}
          value={(values.enderecoEntrega || {}).id}
          disabled={values.cliente ? (values.status || {}).id !== 1 : true}
          id="enderecoEntrega"
          name="enderecoEntrega"
          className="form-control"
        >
          {((values.cliente || {}).enderecos || [{ enderecoCompleto: 'SELECIONE UM ENDERECO' }]).map((address, index) => (
            <option key={index} value={address.id}>
              {address.enderecoCompleto}
            </option>
          ))}
        </select>
        <EnderecoAdicional onUpdated={handlerAddAddress} cliente={values.cliente} action="ADD" title="Adicionar um novo endereço de entrega"></EnderecoAdicional>
        {(touched || {}).enderecoEntrega && (errors || {}).enderecoEntrega && <div className="text-danger validationError">{(errors || {}).enderecoEntrega || ''}</div>}
        <EnderecoAdicional
        title="Editar o endereço de entrega"
          action="EDIT"
          onUpdated={endereco => {
            handlerAddAddress(endereco);
            atualizaEnderecoEntrega(endereco);
          }}
          cliente={values.cliente}
          address={values.enderecoEntrega}
        ></EnderecoAdicional>
        {(touched || {}).enderecoEntrega && (errors || {}).enderecoEntrega && <div className="text-danger validationError">{(errors || {}).enderecoEntrega || ''}</div>}
      </div>
    </div>
  );
}

export default DropBoxEntrega;
