import React from 'react';

import { locale } from 'devextreme/localization';
import moment from 'moment';
import { PrintStyle } from './PrintStyles';

export function ReciboAssinatura({ via }) {
  moment.locale('pt-br');
  locale('pt-br');
  const sty = new PrintStyle();
  return (
    <>
      <div className="w-100" style={{ borderTop: '1px dashed #000000' }}>
        <p className="pr-1" style={sty.tdP}>
          ASSINATURA DO {via === 1 ? `CLIENTE` : `ENTREGADOR`}
        </p>
      </div>
      <p className="pr-1" style={{ ...sty.tdP, borderBottom: '1px dashed #000000' }}>
        NOME {via === 1 ? `CLIENTE` : ''}:
      </p>
      {via === 1 && (
        <p className="pr-1" style={{ ...sty.tdP, borderBottom: '1px dashed #000000' }}>
          CPF OU RG:
        </p>
      )}
      <p className="pr-1 mt-1" style={{ ...sty.tdP }}>
        DATA: ______/______/________
      </p>
    </>
  );
}
