import React, { Component } from 'react';
import Botao from '../../../../components/botao/Botao';
import {
  InputText,
  Select,
  // FormOptions,
  handleInputChange,
  Checkbox,
  Checkitem,
  InputDate,
  InputTime
} from '../../../../components/formulario/Formulario';
import List from '../../../../components/formulario/List';
import SectionHeader from '../../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from '../../../../components/section/Content';
import {
  tagAlert,
  showError,
  showInfo,
  hideAlert,
  showQuestion,
  showWait,
  hideWait,
} from '../../../../components/alert/Alert';

import { withRouter } from 'react-router-dom';

import {
  toFloatFormattedDisplay,
  menuPermiteInclusao,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  validaDadosLogin,
  getCnpj,
  getNomePagina
} from '../../../../shared/utils/Utils';
import moment from 'moment';
import {
  getTanques,
  incluirTanque,
  alterarTanque,
  excluirTanque
} from './Tanques.service';

import {
  getCombustivel,
  getTipoAlmoxarifadoComb
} from '../../../../services/Produto.service';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { MAPA_PATH } from '../MapaDePista';
import Footer from '../../../../components/core/footer/Footer';

import { menus } from '../../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60530;
const NEW_PATH = '/cadastro/tanques-bombas-bicos/tanque/new';
const titulo = 'Tanque';

class FormCadastroTanque extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      cnpjEstabelecimento = getCnpj(),
      codigoTanque = null,
      numeroTanque = '',
      codigoProduto = 0,
      capacidade = '',
      percentualErroMedicao = '',
      dataAtivacao = moment(),
      motivoAlteracao = '',
      codigoTipoAlmoxarifado = 2,
      possuiBicosAtivos = false,
      possuiMovimentacaoEstoque = false,
      inativo = false,
      rowVersion = null,

      dataAlteracao = moment(),
      horaAlteracao = '',

      tipoAlmoxarifado = '',
      produto = '',

      dataAtivAnterior,
      tipoTanqueAnterior,
      produtoAnterior,

      volumeAtual = '0,00',

      salvando = false
    } = this.props.tanque;

    this.state = {
      cnpjEstabelecimento,
      codigoTanque,
      numeroTanque,
      codigoProduto,
      capacidade,
      percentualErroMedicao,
      dataAtivacao,
      motivoAlteracao,
      codigoTipoAlmoxarifado,
      possuiBicosAtivos,
      possuiMovimentacaoEstoque,
      ativo: !inativo,
      rowVersion,

      horaAlteracao,
      dataAlteracao,

      tipoAlmoxarifado,
      produto,

      volumeAtual,
      //para verificação da data de ativação
      dataAtivAnterior,
      tipoTanqueAnterior,
      produtoAnterior,

      tanquesExistentes: [],

      salvando
    };
  }

  //Para o combo produto
  async componentDidMount() {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto o formulário é carregado.'
    );

    const { data: produtos } = await getCombustivel(
      this.state.cnpjEstabelecimento
    );
    this.setState({
      produtos: produtos.map(produto => {
        return {
          label: produto.descricao,
          value: produto.codigo
        };
      }),
      codigoProduto:
        this.state.codigoProduto === 0
          ? !!produtos && produtos.length > 0
            ? produtos[0].codigo
            : 0
          : this.state.codigoProduto
    });

    const { data: almoxarifados } = await getTipoAlmoxarifadoComb(
      this.state.cnpjEstabelecimento
    );

    this.setState({
      almoxarifados: almoxarifados.map(almoxarifado => {
        return {
          label: almoxarifado.descricao,
          value: almoxarifado.codigo
        };
      })
    });
    hideWait(this);
  }

  handleExcluir = async () => {
    const {
      cnpjEstabelecimento,
      codigoTanque,
      numeroTanque,
      codigoProduto,
      capacidade,
      percentualErroMedicao,
      dataAtivacao,
      dataAlteracao,
      horaAlteracao,
      motivoAlteracao,
      rowVersion,
      codigoTipoAlmoxarifado
    } = this.state;

    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    showQuestion(
      this,
      titulo,
      'Deseja realmente excluir?',
      null,
      async resp => {
        if (resp) {
          hideAlert(this);

          try {
            //caso for tanque
            //somente é permitido excluir com volume atual zerado
            if (
              parseFloat(this.state.volumeAtual) !== 0 &&
              codigoTipoAlmoxarifado === 2
            ) {
              showError(
                this,
                titulo,
                'Não é permitido excluir tanque com volume diferente de zero'
              );
            } else {
              if (
                this.state.possuiBicosAtivos &&
                codigoTipoAlmoxarifado === 2
              ) {
                showError(
                  this,
                  titulo,
                  'Não é possível excluir tanques com bicos vinculados e ativos. Os bicos devem ser direcionados para o outro tanque ou inativados'
                );
              } else {
                if (this.state.possuiMovimentacaoEstoque) {
                  await alterarTanque(
                    cnpjEstabelecimento,
                    codigoTanque,
                    numeroTanque,
                    codigoProduto,
                    capacidade,
                    percentualErroMedicao,
                    moment(dataAtivacao).format('YYYY-MM-DD') +
                      'T' +
                      moment(dataAtivacao).format('HH:mm:ss'),
                    moment(dataAlteracao).format('YYYY-MM-DD') +
                      'T' +
                      horaAlteracao +
                      ' ' +
                      motivoAlteracao,
                    true,
                    rowVersion,
                    codigoTipoAlmoxarifado
                  );
                  showInfo(
                    this,
                    titulo,
                    'Não é possível excluir um tanque com movimentação, ele será apenas desativado!',
                    null,
                    () => {
                      hideAlert(this, this.props.history, PATH_MENU);
                    }
                  );
                } else {
                  await excluirTanque(codigoTanque, cnpjEstabelecimento);
                  showInfo(
                    this,
                    titulo,
                    'Tanque excluído com sucesso!',
                    null,
                    () => {
                      hideAlert(this, this.props.history, PATH_MENU);
                    }
                  );
                }
              }
            }
          } catch (err) {
            this.showError(err.response.data.message);
          }
        } else {
          hideAlert(this);
        }
      }
    );
  };

  handleCancelar = () => {
    showQuestion(this, titulo, 'Deseja realmente cancelar?', null, resp => {
      if (resp) {
        hideAlert(this);
        this.props.history.push(MAPA_PATH);
      } else {
        hideAlert(this);
      }
    });
  };

  handleSalvar = async () => {
    this.setState({ salvando: true });
    
    if (!validaDadosLogin()) {
      this.setState({ salvando: false });
      hideWait(this);
      this.props.history.push('/');
    }
    const {
        cnpjEstabelecimento,
        codigoTanque,
        numeroTanque,
        codigoProduto,
        capacidade,
        percentualErroMedicao,
        dataAtivacao,
        motivoAlteracao,
        codigoTipoAlmoxarifado,
        ativo,
        rowVersion,

        horaAlteracao,
        dataAlteracao
      } = this.state,
      inativo = !ativo;

    //Se for compressor não salva os dados volume e capacidade, pois não estarão nem visiveis.
    if (parseInt(this.state.codigoTipoAlmoxarifado) !== 2) {
      //volumeAtual = "";
      this.state.capacidade = '';
    }

    //caso não seja compressor valida capacidade

    const [action, params] =
      codigoTanque !== null
        ? [
            alterarTanque,
            [
              cnpjEstabelecimento,
              codigoTanque,
              numeroTanque,
              codigoProduto,
              capacidade,
              percentualErroMedicao,
              moment(dataAtivacao).format('YYYY-MM-DD') +
                'T' +
                moment(dataAtivacao).format('HH:mm:ss'),
              moment(dataAlteracao).format('YYYY-MM-DD') +
                'T' +
                horaAlteracao +
                ' ' +
                motivoAlteracao,
              inativo,
              rowVersion,
              codigoTipoAlmoxarifado
            ]
          ]
        : [
            incluirTanque,
            [
              cnpjEstabelecimento,
              numeroTanque,
              codigoTipoAlmoxarifado,
              codigoProduto,
              capacidade,
              percentualErroMedicao,
              dataAtivacao
            ]
          ];

    let temErros = this.verificaTanque();

    if (!temErros && action === incluirTanque) {
      temErros = this.verificaTanqueExistente(this.state.numeroTanque);
    }

    if (!temErros) {
      //salvar
      try {
        showWait(this, titulo, 'Por favor, aguarde enquanto o registro é salvo.');
        await action(...params);
        hideWait(this);
        this.setState({ salvando: false });
        let message = '';
        if (rowVersion === null){
          message = 'Tanque cadastrado com sucesso!';
        }
        else{
          message = 'Tanque alterado com sucesso!';
        }

        showInfo(this, titulo, message, null, () => {
          this.props.history.push(PATH_MENU);
        });
      } catch (e) {
        const msg =
          e && e.response && e.response.data && e.response.data.message
            ? 'Mensagem do servidor: ' + e.response.data.message
            : 'Ocorreu um erro inesperado na gravação.';
        showInfo(this, titulo, msg, null, () => {
          hideAlert(this);
        });
      }
    }
    this.setState({ salvando: false });
  };

  verificaTanque = () => {
    //verifica preenchimento dos campos
    if (this.state.codigoTipoAlmoxarifado === '') {
      showError(this, titulo, 'É obrigatório informar o tipo de tanque');
      return true;
    }
    if (this.state.numeroTanque === '') {
      showError(this, titulo, 'É obrigatório informar o número do tanque');
      return true;
    }
    if (this.state.codigoProduto === '') {
      showError(this, titulo, 'É obrigatório informar o combustível');
      return true;
    }
    if (this.state.percentualErro) {
      showError(
        this,
        titulo,
        'É obrigatório informar o percentual de erro na medição'
      );
      return true;
    }
    if (this.state.percentualErro <= 0 || this.state.percentualErro > 99.99) {
      showError(
        this,
        titulo,
        'O percentual de erro na medição deve ser entre 0,01 e 99,99'
      );
      return true;
    }
    if (this.state.dataAtivacao === '') {
      showError(this, titulo, 'É obrigatório informar a data de ativação');
      return true;
    }
    if (!moment(this.state.dataAtivacao, 'YYYY-MM-DD').isValid()) {
      showError(this, titulo, 'Data de ativação inválida');
      return true;
    }
    if (moment(this.state.dataAtivacao).isAfter(moment().add(1, 'months'))) {
      showError(
        this,
        titulo,
        'Data de ativação não deve ser superior a um mês.'
      );
      return true;
    }

    if (
      !this.state.capacidade &&
      parseInt(this.state.codigoTipoAlmoxarifado) === 2
    ) {
      showError(this, titulo, 'É obrigatório informar capacidade');
      return true;
    }

    //somente é permitido inativar com volume atual zerado
    if(!this.state.ativo && parseFloat(this.state.volumeAtual) !== 0) {
      showError(this, titulo, 'Não é permitido inativar tanque com volume diferente de zero');
      return true;
    }

    //Se tiver em alteração e for um tanque.
    //Se o produto tiver mudado e a data de ativação permanecer igual, a mesma deve ser alterada.
    if (
      this.state.codigoTanque !== null &&
      parseInt(this.state.codigoTipoAlmoxarifado) === 2
    ) {
      if (
        this.state.produtoAnterior !== this.state.codigoProduto &&
        moment(this.state.dataAtivAnterior).isSame(this.state.dataAtivacao)
      ) {
        showError(
          this,
          titulo,
          'Você está alterando o produto do tanque. Deve ser informada a data da alteração no campo Data de ativação'
        );
        return true;
      }
    }

    //Validações para o momento de alteração
    if (this.state.codigoTanque !== null) {
      if (this.state.dataAlteracao === '') {
        showError(this, titulo, 'É obrigatório informar a data de alteração');
        return true;
      }
      if (!moment(this.state.dataAlteracao, 'YYYY-MM-DD').isValid()) {
        showError(this, titulo, 'Data de alteração inválida');
        return true;
      }
      if (
        moment(this.state.dataAlteracao, 'YYYY-MM-DD') <
        moment(this.state.dataAtivacao, 'YYYY-MM-DD')
      ) {
        showError(
          this,
          titulo,
          'A data de alteração deve ser igual ou superior a data de ativação informada'
        );
        return true;
      }
      if (this.state.horaAlteracao === '') {
        showError(this, titulo, 'É obrigatório informar a hora de alteração');
        return true;
      }
      if (!moment(this.state.horaAlteracao, 'HH:mm:ss').isValid()) {
        showError(this, titulo, 'Hora de alteração inválida');
        return true;
      }
      if (!this.state.motivoAlteracao) {
        showError(this, titulo, 'É obrigatório informar o motivo de alteração');
        return true;
      }
    }
  };

  verificaTanqueExistente = numTanque => {
    let existeTanque = false;
    let lista = this.state.tanquesExistentes;
    for (let i = 0; i < lista.length; i++) {
      const tanque = lista[i];
      if (parseInt(tanque.numeroTanque) === numTanque) {
        existeTanque = true;
      }
    }
    if (existeTanque) {
      showError(
        this,
        titulo,
        'Já existe tanque cadastrado com os valores informados'
      );
    }
    return existeTanque;
  };

  changeSelect = event => {
    let value;
    value = this.state.codigoProduto;

    //validação apenas válida para alteração
    if (this.state.codigoTanque !== null) {
      //Adicionar o bicos associados a validação (esperar API)
      if (parseInt(this.state.volumeAtual) !== 0) {
        showError(
          this,
          titulo,
          'Alteração de produto permitida apenas para volume atual zerado'
        );
      } else {
        if (this.state.possuiBicosAtivos) {
          showError(
            this,
            titulo,
            'Alteração de produto permitida apenas para tanque sem bicos associadas com turno em aberto'
          );
        } else {
          value = event.target.value;
        }
      }
    } else {
      value = event.target.value;
    }
    this.setState({ codigoProduto: value });
  };

  render() {
    const {
      codigoTanque,
      numeroTanque,
      codigoProduto,
      capacidade,
      percentualErroMedicao,
      dataAtivacao,
      codigoTipoAlmoxarifado,
      ativo,

      volumeAtual,
      produtos,
      almoxarifados,

      horaAlteracao,
      dataAlteracao,
      motivoAlteracao,

      rowVersion
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="">
              <div className="row">
                <div className="col-5">
                  <Select
                    label="Tipo"
                    name="codigoTipoAlmoxarifado"
                    value={codigoTipoAlmoxarifado}
                    onChange={this.handleInputChange}
                    options={almoxarifados}
                    tabindex={1}
                    disabled={codigoTanque !== null}
                  />
                </div>
                <div className="col-5">
                  <InputText
                    label="Número tanque"
                    value={numeroTanque}
                    name="numeroTanque"
                    type="number"
                    maxlength="4"
                    onChange={this.handleInputChange}
                    disabled={codigoTanque !== null}
                    allowNegative={false}
                    tabindex={2}
                    decimalScale={0}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <Select
                    label="Produto"
                    name="codigoProduto"
                    value={codigoProduto}
                    onChange={this.changeSelect}
                    options={produtos}
                    required
                    tabindex={3}
                  />
                </div>
                <div className="col-5">
                  <InputText
                    label="% de erro na medição"
                    value={percentualErroMedicao}
                    name="percentualErroMedicao"
                    type="number"
                    maxlength="5"
                    decimalScale={2}
                    required
                    onChange={this.handleInputChange}
                    allowNegative={false}
                    tabindex={4}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <InputDate
                    label="Data de ativação"
                    name="dataAtivacao"
                    value={dataAtivacao ? moment(dataAtivacao) : moment()}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-5">
                  <Checkbox label="Ativo:">
                    <Checkitem
                      label=""
                      name="ativo"
                      checked={ativo}
                      onChange={this.handleInputChange}
                      disabled={codigoTanque === null}
                    />
                  </Checkbox>
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title=""
              visible={
                parseInt(this.state.codigoTipoAlmoxarifado) === 2 ? true : false
              }
            >
              <div className="row">
                <div className="col-5">
                  <InputText
                    label="Volume atual"
                    value={volumeAtual}
                    name="volumeAtual"
                    type="number"
                    maxlength="12"
                    decimalScale={2}
                    required
                    onChange={this.handleInputChange}
                    allowNegative={true}
                    tabindex={7}
                    //nunca deixa editar
                    disabled={true}
                  />
                </div>
                <div className="col-5">
                  <InputText
                    label="Capacidade"
                    value={capacidade}
                    name="capacidade"
                    type="number"
                    maxlength="12"
                    decimalScale={2}
                    required
                    onChange={this.handleInputChange}
                    allowNegative={false}
                    tabindex={8}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title=""
              visible={this.state.codigoTanque !== null ? true : false}
            >
              <div className="row">
                <div className="col-5">
                  <InputDate
                    label="Data da alteração"
                    name="dataAlteracao"
                    value={dataAlteracao ? moment(dataAlteracao) : moment()}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-5">
                  <InputTime
                    className="input time"
                    required
                    label="Hora de alteração"
                    value={horaAlteracao}
                    name="horaAlteracao"
                    showSeconds
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-10">
                  <InputText
                    label="Motivo da alteração"
                    value={motivoAlteracao}
                    name="motivoAlteracao"
                    maxlength="108"
                    required
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
          </SectionContainer>          
        </LinxPostos>
        <Footer
            saveAction={              
              !rowVersion
              ? !this.state.salvando
                ? this.handleSalvar
                : null
              : menuPermiteAlteracao(PATH_MENU)
              ? !this.state.salvando
                ? this.handleSalvar
                : null
              : null
            }
            cancelAction={this.handleCancelar}
            deleteAction={
              codigoTanque === null
                ? null
                : menuPermiteExclusao(PATH_MENU)
                ? this.handleExcluir
                : null
            }
          />
        {tagAlert(this)}
      </>
    );
  }
}

FormCadastroTanque = withRouter(FormCadastroTanque);

class Tanques extends Component {
  state = {
    tanques: [],
    tanqueSel: {}
  };

  async doMount() {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto os dados são carregados.'
    );
    try {
      const { data: t } = await getTanques(getCnpj());
      const tanqueArray = t.result;
      const tanqs = tanqueArray.map(tq => {
        return {
          codigoTanque: tq.codigoTanque,
          numeroTanque: tq.numeroTanque,
          codigoProduto: tq.codigoProduto,
          capacidade: tq.capacidade,
          percentualErroMedicao: tq.percentualErroMedicao,
          dataAtivacao: tq.dataAtivacao,
          motivoAlteracao: tq.motivoAlteracao,
          codigoTipoAlmoxarifado: tq.codigoTipoAlmoxarifado,
          possuiBicosAtivos: tq.possuiBicosAtivos,
          possuiMovimentacaoEstoque: tq.possuiMovimentacaoEstoque,
          inativo: tq.inativo,
          rowVersion: tq.rowVersion,
          dataAlteracao: moment().format('YYYY-MM-DD'),
          horaAlteracao: moment().format('HH:mm:ss'),

          tipoAlmoxarifado: tq.tipoAlmoxarifado,
          produto: tq.produto,

          volumeAtual: tq.volumeAtual,
          //para verificação da data de ativação
          dataAtivAnterior: tq.dataAtivacao,
          tipoTanqueAnterior: tq.codigoTipoAlmoxarifado,
          produtoAnterior: tq.codigoProduto
        };
      });

      const { data: tp } = await getTipoAlmoxarifadoComb(getCnpj());
      const tiposArray = tp;
      this.setState({ tiposArray });

      const { data: c } = await getCombustivel(getCnpj());
      const combsArray = c;
      this.setState({ combsArray });

      for (let i = 0; i < tanqs.length; i++) {
        tanqs[i].descricaoComb = this.buscaDescricaoComb(
          tanqs[i].codigoProduto
        );
        tanqs[i].descricaoTipo = this.buscaDescricaoTipo(
          tanqs[i].codigoTipoAlmoxarifado
        );
      }

      const tqMoOrd = tanqs.sort(function(a, b) {
        if (parseInt(a.numeroTanque) < parseInt(b.numeroTanque)) return -1;
        if (parseInt(a.numeroTanque) > parseInt(b.numeroTanque)) return 1;
        return 0;
      });

      this.setState({ tanques: tqMoOrd });
    } finally {
      hideWait(this);
    }
  }

  async componentDidMount() {
    this.doMount();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      this.doMount();
    }
  }

  buscaDescricaoTipo = codigotipo => {
    let tipos = this.state.tiposArray;
    for (let i = 0; i < tipos.length; i++) {
      const t = tipos[i];
      if (parseInt(t.codigo) === parseInt(codigotipo)) {
        var descTipo = t.descricao;
        break;
      }
    }
    return descTipo;
  };

  buscaDescricaoComb = codigoProd => {
    let combs = this.state.combsArray;
    for (let i = 0; i < combs.length; i++) {
      const comb = combs[i];
      if (parseInt(comb.codigo) === parseInt(codigoProd)) {
        var descComb = comb.descricao;
        break;
      }
    }
    return descComb;
  };

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.setState({ tanqueSel: rowInfo.original });
      this.props.history.push(NEW_PATH, {
        tanqueSel: rowInfo.original,
        tanquesExistentes: this.state.tanque
      });
    }
  };

  renderHelper = (edit, showTitle) => {
    return (
      <>
        <SectionHeader
          title={showTitle ? 'Tanques' : ''}
          subtitle=""
          right={
            <div className="button-container">
              {edit ? (
                <></>
              ) : menuPermiteInclusao(PATH_MENU) ? (
                <Botao
                  ic
                  icon="icon-lx-plus"
                  onClick={() => {
                    this.setState({ tanqueSel: {} });
                    this.props.history.push(NEW_PATH, { tanqueSel: {} });
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          }
        />
        <List
          defaultPageSize={5}
          onClick={this.handleTableClick}
          cols={[
            {
              accessor: 'descricaoTipo',
              Header: 'Tipo',
              width: 150,
              filterable: false
            },
            {
              accessor: 'numeroTanque',
              Header: 'Número',
              width: 100,
              filterable: false
            },
            {
              accessor: 'descricaoComb',
              Header: 'Produto',
              width: 250,
              filterable: false
            },
            {
              accessor: 'capacidade',
              Header: 'Capacidade',
              width: 200,
              filterable: false,
              Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
            },
            {
              accessor: 'volumeAtual',
              Header: 'Volume atual',
              width: 200,
              filterable: false,
              Cell: props => <div>{toFloatFormattedDisplay(props.value)}</div>
            },
            {
              accessor: 'inativo',
              Header: 'Ativo',
              width: 100,
              filterable: false,
              Cell: ({ row }) => {
                return (
                  <Botao
                    secondary={row.inativo}
                    ic
                    icon={row.inativo ? 'icon-lx-close' : 'icon-lx-check'}
                  />
                );
              }
            }
          ]}
          rows={this.state.tanques}
        />
      </>
    );
  };

  render() {
    const { edit } = this.props;
    let tanqueSel = {};
    if (edit) {
      // this.props.location.state por padrao é undefined
      if (this.props.location.state)
        tanqueSel = this.props.location.state.tanqueSel;
    }
    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <FormCadastroTanque
              tanque={tanqueSel}
              tanquesExistentes={this.state.tanque}
            />
          ) : (
            <>
              {this.props.noLinxPostos ? (
                this.renderHelper(edit, true)
              ) : (
                <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                  {this.renderHelper(edit, false)}
                </LinxPostos>
              )}
            </>
          )}
        </section>
      </main>
    );
  }
}

Tanques = withRouter(Tanques);
export { Tanques };
