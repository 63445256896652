import { LoadPanel } from 'devextreme-react';
import 'devextreme/dist/css/dx.light.css';
import { locale } from 'devextreme/localization';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { fetchSharedDataInit, selectConsignadoComodatoSharedData } from '../../../../redux/slices/consignadoComodato/SharedData.slice';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../../shared/utils/Utils';
import TurnoNavigation from './components/turno-navgation/TurnoNavigation';

/**
 * Um componente para a página de conferência de turnos de pedidos.
 * @returns {JSX.Element} O componente da página de conferência de turnos de pedidos.
 */
export function ConferenciaTurnoPedidos() {
  locale('pt-BR');
  const PATH_MENU = menus.COD_17540;
  const [searching, setSearching] = useState(true);

  const [searchingMessage, setSearchingMessage] = useState('Carregando turnos...');

  const sharedDataInitialized = useSelector(selectConsignadoComodatoSharedData).initialized;
  const sharedLoading = useSelector(selectConsignadoComodatoSharedData).loading;
  const dispatch = useDispatch();

  const handlerSearchingOn = useCallback((searchingMessage = 'Aguarde...') => {
    setSearching(true);
    setSearchingMessage(searchingMessage);
  }, []);
  const handlerSearchingOff = useCallback(() => {
    setSearching(false);
    setSearchingMessage('Aguarde...');
  }, []);

  useEffect(() => {
    if (sharedLoading) return;
    if (!sharedDataInitialized) {
      handlerSearchingOn('Carregando turnos...');
      dispatch(fetchSharedDataInit());
    } else {
      handlerSearchingOff();
    }
  }, [dispatch, sharedDataInitialized, sharedLoading, handlerSearchingOff, handlerSearchingOn]);

  return (
    <LinxPostos className={''} breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        searching={searching}
        showIndicator={true}
        shading={true}
        message={searchingMessage}
        showPane={true}
      />
      <TurnoNavigation onBusy={handlerSearchingOn} onUnBusy={handlerSearchingOff}></TurnoNavigation>
    </LinxPostos>
  );
}

export default withRouter(ConferenciaTurnoPedidos);
