export default function valorFreteNaLocacao(os) {
  const hasPedidoLocacao = os.pedidos.find(pedido => pedido.tipoPedido.id === 8);
  if (!hasPedidoLocacao) return 0;
  const hasPedidoVenda = os.pedidos.find(pedido => pedido.tipoPedido.id === 1);
  let freteLocacao = Math.round(os.valorFrete * 100) / 100;

  if (hasPedidoVenda) {
    freteLocacao = 0; // não precisa cobrar frete pois SERÁ recebido na venda
  }
  return freteLocacao;
}
