import React, { useState, useEffect } from "react";
import notify from "devextreme/ui/notify";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faEye, faEyeSlash, faRefresh } from "@fortawesome/free-solid-svg-icons";

import logoContaDigital from "../../../../../assets/images/contadigital/Linx Conta Digital Integrada_Positivo.png";
import { getDadosLogin } from "../../../../shared/utils/Utils";

import { FuncionarioService } from "../../../../services/funcionarios/Funcionario.service";
import { ContaDigitalIntegradaService } from "../ContaDigitalIntegrada.service";

import { CustomButton } from "../SharedComponents";

import styles from "../ContaDigitalIntegrada.module.scss";


export const LateralContaDigitalIntegrada = ({ onSelectFuncao }) => {
  const [valuesHide, setValuesHide] = useState(true);
  const [menu, setMenu] = useState(null);
  const [isLoading, setIsisLoading] = useState(false);
  const [accountError, setAccountError] = useState(null);
  const [balanceError, setBalanceError] = useState(null);


  const [accountData, setaccounData] = useState({
    nome: "",
    documento: "",
    tipoDocumento: "",
    email: "",
    conta: "",
    agencia: "",
  });

  const [saldo, setSaldo] = useState("0,00");

  const MENU_CONTA_DIGITAL_INTEGRADA = 5700;
  const VISUALIZA_EXTRATO = 5710;
  const VISUALIZA_PAGAMENTO = 5715;
  const VISUALIZA_COBRANCA = 5720;
  const VISUALIZA_TRANSFERENCIA = 5725;
  const VISUALIZA_DDA = 5730;
  const VISUALIZA_APLICATIVO = 5735;
  const VISUALIZA_CONFIG = 5740;

  const selectFuncao = (funcao) => {
    onSelectFuncao(funcao);
  }

  const getSaldo = async () => {
    const response = await ContaDigitalIntegradaService.getBalance();

    if (response && response.result) {
      setSaldo(response.result);
    } else {
      setBalanceError(response.message);
      notify(response.message, "error", 5000);
    }
  }

  const getMenu = async () => {
    try {
      const newMenu = [];

      FuncionarioService.getInstance().recuperarAcessosPorMenuPaiId(MENU_CONTA_DIGITAL_INTEGRADA).then((res) => {
        const result = Array.isArray(res) ? res : res.itens;

        for (let i = 0; i < result.length; i++) {
          if (result[i].menuId === VISUALIZA_EXTRATO) {
            newMenu.push({
              menuId: res[i].menuId,
              icon: "textdocument",
              title: "Extrato",
              action: () => selectFuncao("extrato")
            });
          } else if (result[i].menuId === VISUALIZA_PAGAMENTO) {
            newMenu.push({
              menuId: res[i].menuId,
              icon: "check",
              title: "Pagamentos",
              action: () => selectFuncao("pagamentos")
            });
          } else if (result[i].menuId === VISUALIZA_COBRANCA) {
            newMenu.push({
              menuId: res[i].menuId,
              icon: "money",
              title: "Cobranças",
              action: () => selectFuncao("cobrancas")
            });
          } else if (result[i].menuId === VISUALIZA_TRANSFERENCIA) {
            newMenu.push({
              menuId: res[i].menuId,
              icon: "showpanel",
              title: "Transferências",
              action: () => selectFuncao("transferencias")
            });
          } else if (result[i].menuId === VISUALIZA_DDA) {
            newMenu.push({
              menuId: res[i].menuId,
              icon: "checklist",
              title: "DDA Débito Direto Autorizado",
              action: () => selectFuncao("dda")
            });
          } else if (result[i].menuId === VISUALIZA_APLICATIVO) {
            newMenu.push({
              menuId: res[i].menuId,
              icon: "download",
              title: "Aplicativo Aprovador",
              action: () => selectFuncao("aplicativo")
            });
          } else if (result[i].menuId === VISUALIZA_CONFIG) {
            newMenu.push({
              menuId: res[i].menuId,
              icon: "preferences",
              title: "Configurações",
              action: () => selectFuncao("configuracoes")
            });
          }
        }

        setMenu(newMenu);
      });
    } catch (e) {
      console.log("Error menu: ", e);
    }
  }

  const setNotice = (message) => {
    notify(message, "info", 2000)
  }

  const getDetalheConta = async () => {
    const dadosLogin = getDadosLogin();
    const response = await ContaDigitalIntegradaService.getAccount(dadosLogin.codigoEstabelecimento);

    if (response && response.result) {
      setaccounData(response.result);
    } else {
      setAccountError(response.message);
    }

    setIsisLoading(false);
  }

  const refreshSaldo = async () => {
   setValuesHide(true)

    await getSaldo();

    setValuesHide(false);

  }


  const refreshData = () => {
    setAccountError(null);
    setIsisLoading(true);

    getDetalheConta();

    if (balanceError) {
      setBalanceError(null);
      getSaldo();
    }
  }

  useEffect(() => {
    getSaldo();
    getMenu();
    // getMockMenu();
    getDetalheConta();
  }, []);

  return (
    <>
      <div className="d-flex w-100 mb-3">
        <img src={logoContaDigital} alt="Logotipo Linx" width="100%" />
      </div>
      <div className="d-flex flex-row justify-content-end">
        <button className="bg-white border-0 d-flex" style={{paddingRight: '10px'}} onClick={() => refreshSaldo()}>
          <FontAwesomeIcon icon={faRefresh} />
        </button>
        <button className="bg-white border-0 d-flex" onClick={() => setValuesHide(!valuesHide)}>
          <FontAwesomeIcon icon={!valuesHide ? faEye : faEyeSlash} />
        </button>
      </div>
      <div className="position-relative my-2 py-3">
        <div className="d-flex w-100 align-items-center justify-content-between mb-2">
          <div className="d-flex flex-column w-100">
            <p>Razão Social</p>
            <p className="d-flex align-items-center w-100" style={{ gap: 8 }}>
              <span className={`${!isLoading ? "bg-light" : styles.skeleton} rounded w-100 d-flex px-2 py-1`} style={{ minHeight: 32 }}>
                {!valuesHide && accountData.nome}
              </span>
              <CopyToClipboard text={accountData.nome} onCopy={() => setNotice("Razão Social copiado")} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faCopy} />
              </CopyToClipboard>
            </p>
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mb-2">
          <div className="d-flex flex-column w-100">
            <p>Agência</p>
            <p className="d-flex align-items-center w-100" style={{ gap: 8 }}>
              <span className={`${!isLoading ? "bg-light" : styles.skeleton} rounded w-100 d-flex px-2 py-1`} style={{ minHeight: 32 }}>
                {!valuesHide && accountData.agencia}
              </span>
              <CopyToClipboard text={accountData.agencia} onCopy={() => setNotice("Agência copiada")} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faCopy} />
              </CopyToClipboard>
            </p>
          </div>
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between mb-2">
          <div className="d-flex flex-column w-100">
            <p>Conta</p>
            <p className="d-flex align-items-center w-100" style={{ gap: 8 }}>
              <span className={`${!isLoading ? "bg-light" : styles.skeleton} rounded w-100 d-flex px-2 py-1`} style={{ minHeight: 32 }}>
                {!valuesHide && accountData.conta}
              </span>
              <CopyToClipboard text={accountData.conta} onCopy={() => setNotice("Conta copiada")} style={{ cursor: "pointer" }}>
                <FontAwesomeIcon icon={faCopy} />
              </CopyToClipboard>
            </p>
          </div>
        </div>
        {accountError &&
          <div className="position-absolute d-flex flex-column rounded justify-content-center align-items-center w-100 h-100"
            style={{ backgroundColor: "rgba(241, 243, 246, 0.9)", zIndex: 1000, top: 0, left: 0 }}>
            <p className="mb-3 text-center p-3">
              <strong>Erro ao carregar os detalhes da conta</strong>
            </p>
            <p className="mb-3 text-center p-3">{accountError}</p>
            <CustomButton className="rounded" icon="refresh" title="Recarregar" onClick={refreshData}>Recarregar</CustomButton>
          </div>
        }
      </div>
      <div className="d-flex w-100 align-items-center justify-content-between mb-4">
        <div className="d-flex flex-column w-100">
          <p>Saldo</p>
          {!balanceError ?
            <p className="d-flex align-items-center w-100" style={{ gap: 8 }}>
              <span id="saldoContaDigital" className={`${!isLoading ? "bg-light" : styles.skeleton} rounded w-100 d-flex px-2 py-1`} style={{ minHeight: 32 }}>
                {!valuesHide && saldo}
              </span>
            </p>
            :
            <p>Saldo indisponível no momento. </p>
          }
        </div>
      </div>
      {menu && menu.map(({ icon, title, action }, index) => (
        <div className="d-flex w-100 mb-3" key={index}>
          <CustomButton className="rounded w-100" icon={icon} onClick={action}>{title}</CustomButton>
        </div>
      ))}
    </>
  );
}
