import { getDadosLogin } from '../../../../shared/utils/Utils';
import { v4 } from 'uuid';

export class CriarNovaOsVaziaService {
  execute() {
    const dadosLogin = getDadosLogin();
    if (!dadosLogin.login) return {};
    const dt = new Date();
    dt.setHours(0, 0, 0, 0);
    const pedidoPadrao = {
      id: -1,
      osId: v4(),
      estaCod: dadosLogin.codigoEstabelecimento,
      numeroOS: -1,
      cliente: undefined,
      transportadora: undefined,
      origem: { id: '-1', nome: 'SELECIONE' },
      status: { id: 1, nome: 'EM ABERTO' },
      enderecoEntrega: undefined,
      dataEvento: new Date().toISOString(),
      dataEntregaRetirada: dt.toISOString(),
      entregaRetiradaHoraDe: `${new Date()
        .getHours()
        .toString()
        .padStart(2, '0')}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
      entregaRetiradaHoraAte: `${new Date()
        .getHours()
        .toString()
        .padStart(2, '0')}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
      dataRecolhaDevolucao: dt.toISOString(),
      recolhaDevolucaoHoraDe: `${new Date()
        .getHours()
        .toString()
        .padStart(2, '0')}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
      recolhaDevolucaoHoraAte: `${new Date()
        .getHours()
        .toString()
        .padStart(2, '0')}:${new Date()
        .getMinutes()
        .toString()
        .padStart(2, '0')}`,
      recolhaDevolucaoDias: '1',
      quantidade: 1,
      precoTotal: 0,
      valorFrete: 0,
      observacoes: '',
      representante: dadosLogin.login.toUpperCase(),
      tipoFreteId: -1,
      pedidos: [],
      total: 0,
      desconto: 0,
      acrescimo: 0,
      recebido: 0,
      aReceber: 0,
      totalGeral: 0,
    };
    return pedidoPadrao;
  }
}
