import axios from "axios";

import { getUrl, sameInt } from '../shared/utils/Utils';
import { TOKEN_PASS_AXIOS } from '../shared/constants/Const';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getEstados() {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v1/UnidadeFederativa/MontarComboUF`, {});
}

export function getCidades(codigoUF) {
  axios.defaults.baseURL = getUrl();
  return axios.get(`LxApi/v1/Municipio/MontarComboCidades`, {
    params: { codigoUF }
  });
}

export function getCep(cep) {
  try {
    axios.defaults.baseURL =
      "https://lx-smb-api-qa.azurewebsites.net/api/v1/cep/";
    return axios.get(`${cep}`, {});
  } finally {
    axios.defaults.baseURL = getUrl();
  }
}

export async function getEstado(codigoUF) {
  const { data: estados } = await getEstados();
  const estado = estados.find(e => {
    return sameInt(e.codigo, codigoUF);
  });
  return estado ? estado.descricao : null;
}

export async function getCidade(codigoUF, codigoCidade) {
  const { data: cidades } = await getCidades(codigoUF);
  if (cidades) {
    const cidade = cidades.find(c => {
      return sameInt(c.codigo, codigoCidade);
    });
    return cidade ? cidade.descricao : null;
  }
}
