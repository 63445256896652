import axios from "axios";

import { TOKEN_PASS_AXIOS } from '../../../../shared/constants/Const';
import { getUrl } from '../../../../shared/utils/Utils';
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getConsultaFinanceiro(
  cnpjEstabelecimento,
  dataInicial,
  dataFinal,
  codigosSetor,
  codigosAlmoxarifado,
  codigosMaquina,
  codigosOperador,
  codigosFormaPagamento,
  consultaDashBoard
) {
  return axios.post(`LxApi/v1/Financeiro/Consulta`, {
    query: {
      cnpjEstabelecimento,
      dataInicial,
      dataFinal,
      codigosSetor,
      codigosAlmoxarifado,
      codigosMaquina,
      codigosOperador,
      codigosFormaPagamento,
      consultaDashBoard
    }
  });
}

export function getTotaisConsultaFinanc(
  cnpjEstabelecimento,
  dataInicial,
  dataFinal,
  codigosSetor,
  codigosAlmoxarifado,
  codigosMaquina,
  codigosOperador,
  codigosFormaPagamento
) {
  return axios.post(`LxApi/v1/Financeiro/ConsultaTotais`, {
    query: {
      cnpjEstabelecimento,
      dataInicial,
      dataFinal,
      codigosSetor,
      codigosAlmoxarifado,
      codigosMaquina,
      codigosOperador,
      codigosFormaPagamento
    }
  });
}
