import React, { Component } from "react";
import {
  InputText,
  Checkbox,
  Checkitem,
  handleInputChange,
  AutoComplete
} from '../../../components/formulario/Formulario';
import List from '../../../components/formulario/List';
import Botao from '../../../components/botao/Botao';
import SectionHeader from '../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from "../../../components/section/Content";

import { withRouter } from "react-router-dom";

import {
  buscarGrupoClientes,
  incluirGrupoClientes,
  alterarGrupoClientes,
  excluirGrupoClientes,
  getClientesGrupo
} from "../../../services/GrupoClientes.service";
import {
  menuPermiteInclusao,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  isObjectSelected,
  emptyText
} from '../../../shared/utils/Utils';
import ReactTable from "react-table";
import "react-table/react-table.css";
import { menus } from "../../../shared/constants/MenuConstants";
import LinxPostos from "../../../components/core/linxPostos/LinxPostos";
import Footer from "../../../components/core/footer/Footer";
import { tagAlert, showError, showInfo, showDeleteError, showWait, showQuestion, hideAlert, showSaveError } from "../../../components/alert/Alert";

const PATH_MENU = menus.COD_60470;
const MAIN_PATH = "/cadastro/grupo-pessoas";
const NEW_PATH = MAIN_PATH + "/new";
const titulo = "Grupo de pessoas";

const autoComplete = {
  cliente: {
    min: 2,
    text: ""
  }
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      id = null,
      mostraAgrupado = false,
      descricao = "",
      clientes = [],
      rowVersion = null,
    } = this.props.grupoCliente;

    this.state = {
      id,
      mostraAgrupado,
      descricao,
      rowVersion,
      clientes,
      clientesLista: [],
      alertActive: false, // se o alert deve ser apresentado
      alertType: "", // tipo de alert (sucesso, erro...)
      alertTitle: "", // titulo do alert
      alertSubtitle: "", // subtitulo/mensagem do alert

      gruposClienteExistentes: this.props.gruposClienteExistentes
    };
  }

  verificaDescricaoExistente = (valorDescricao) => {
    let existeDescricao = false;
    let lista = this.state.gruposClienteExistentes;
    for (let i = 0; i < lista.length; i++) {
      const gc = lista[i];
      if (gc.id !== this.state.id)
        if (gc.descricao.toUpperCase() === valorDescricao.toUpperCase())
          existeDescricao = true;
    }
    if (existeDescricao)
      showError(this, titulo, "Já existe um grupo de clientes com a descrição informada");

    return existeDescricao;
  }

  verificaGrupoClientes = () => {
    var temErros = false;
    //verifica preenchimento dos campos
    if (this.state.descricao.trim() === "") {
      showError(this, titulo, "Descrição não informada");
      temErros = true;
    }

    if (temErros)
      return temErros;

    if (this.state.clientes.length === 0) {
      showError(this, titulo, "Inclua ao menos um cliente");
      temErros = true;
    }

    if (temErros)
      return temErros;

    //if (!this.state.id)
    temErros = this.verificaDescricaoExistente(this.state.descricao);

    return temErros;
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }
    const {
      id,
      mostraAgrupado,
      descricao,
      rowVersion,
      clientes
    } = this.state;

    const clientesSalvar = clientes.map(cli => {
      return cli.codigoCliente
    });

    const [action, params] =
      id !== null
        ? [
          alterarGrupoClientes,
          [
            id,
            mostraAgrupado,
            descricao,
            rowVersion,
            clientesSalvar
          ]
        ]
        : [
          incluirGrupoClientes,
          [
            descricao,
            mostraAgrupado,
            clientesSalvar
          ]
        ];

    var temErros = this.verificaGrupoClientes();

    if (!temErros) {

      try {
        showWait(this, titulo,
          "Por favor, aguarde enquanto o grupo de pessoas é salvo.");
        await action(...params);
        const msg = id ?
          "Grupo de pessoas alterado com sucesso!" :
          "Grupo de pessoas incluído com sucesso!";
        showInfo(this, titulo, msg, null,
          () => {
            this.props.history.push(MAIN_PATH);
          }
        );
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
  };

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }

    showQuestion(
      this,
      titulo,
      "Deseja excluir o grupo de pessoas?",
      null,
      async (resp) => {
        if (resp) {
          try {
            showWait(this, titulo,
              "Por favor, aguarde enquanto o grupo de pessoas é excluído");
            await excluirGrupoClientes(this.state.id);
            showInfo(
              this,
              titulo,
              "Grupo de pessoas excluído com sucesso!",
              null,
              () => {
                this.props.history.push(MAIN_PATH);
              }
            );
          } catch (err) {
            showDeleteError(this, titulo, err);
          }
        } else {
          hideAlert(this);
        }
      }
    );
  }

  handleCancelar = () => {
    showQuestion(
      this,
      titulo,
      "Deseja realmente cancelar a operação?",
      null,
      (resp) => {
        if (resp) {
          hideAlert(this);
          this.props.history.push(MAIN_PATH);
        } else {
          hideAlert(this);
        }
      }
    );
  };

  hasPessoaSelected = () => {
    return isObjectSelected(this.state.cliente);
  };

  hasCampoPessoa = () => {
    return !this.hasPessoaSelected() && !emptyText(
      String(this.state.campoPessoa));
  };

  clearState = () => {
    this.setState({
      campoPessoa: ""
    });
  }

  clearPessoa = () => {
    this.setState({
      cliente: {},
      campoPessoa: ""
    });
  };

  handleAdicionarCliente = () => {
    var listaClientesSelecionados = this.state.clientes;
    var clienteEscolhido = this.state.cliente;
    var erro = false;

    if (!clienteEscolhido)
      showError(this, titulo, "Selecione um cliente!");
    else {

      listaClientesSelecionados.forEach(element => {
        if (element.codigoCliente === clienteEscolhido.value) {
          showError(this, titulo, "Não é permitido selecionar um mesmo cliente!");
          erro = true;
        }
      });

      if (!erro) {
        const objCliente = {
          codigoCliente: clienteEscolhido.value,
          nomeCliente: clienteEscolhido.label.substring(clienteEscolhido.label.indexOf('-') + 2),
        };

        listaClientesSelecionados.push(objCliente);
      }

    }

    this.setState({ clientes: listaClientesSelecionados });

  }

  autoCompleteMessage = (ctrl) => {
    if (ctrl.text.length < ctrl.min)
      return "Inicie digitando pelo menos 2 caracteres para obter uma lista de clientes"

    return "Não foi encontrado nenhum cliente que contenha " + ctrl.text + '"'
  }

  loadClientes = inputText => {
    this.forceUpdate()
    autoComplete.cliente.text = inputText
    if (String(inputText).length >= autoComplete.cliente.min)
      return getClientesGrupo(inputText)
        .then(response => response.data.result.map(item => {
          return {
            value: item.codigo,
            label: item.cnpjCpf + ' - ' + item.nome
          }
        }));
    return Promise.resolve()
  }

  retirarCliente = (codigoCliente) => {

    var listaClientesSelecionados = this.state.clientes;
    const novaLista = [];

    listaClientesSelecionados.forEach(element => {
      if (element.codigoCliente !== codigoCliente)
        novaLista.push(element);
    });

    this.setState({ clientes: novaLista });

  }

  render() {
    const {
      id,
      descricao,
      mostraAgrupado,
      cliente
    } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="">
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="Descrição"
                    name="descricao"
                    value={descricao}
                    required
                    disabled={id !== null}
                    onChange={this.handleInputChange}
                    autofocus="true"
                    maxlength={100}
                  />
                </div>
                <div className="col-6">
                  <Checkbox >
                    <Checkitem
                      label="Mostra agrupado"
                      name="mostraAgrupado"
                      checked={mostraAgrupado}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <SectionContent title="Cliente" accordion accordionDefaultDisplay={true}>
                    <div className="row">
                      <div className="col-8" style={{ position: 'relative', zIndex: '11' }}>
                        <AutoComplete
                          value={cliente}
                          name="cliente"
                          label="Clientes:"
                          loadOptions={this.loadClientes}
                          noOptionsMessage={this.autoCompleteMessage(autoComplete.cliente)}
                          onChange={this.handleInputChange}
                          onFocus={() => {
                            autoComplete.cliente.text = ""
                            this.forceUpdate()
                          }}

                        />
                      </div>
                      <div className="col-2">
                        <div className="button-wrapper">
                          <Botao
                            ic
                            tooltip="Adicionar"
                            icon={"icon-lx-plus"}
                            onClick={() => {
                              this.handleAdicionarCliente();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="table-ctn">
                      <ReactTable
                        data={this.state.clientes}
                        columns={[
                          {
                            accessor: "id",
                            Header: "id",
                            show: false
                          },
                          {
                            accessor: "codigoCliente",
                            show: false
                          },
                          {
                            accessor: "nomeCliente",
                            Header: "Nome",
                          },
                          {
                            accessor: "acoes",
                            Header: "Ações",
                            filterable: false,
                            Cell: ({ row }) => {
                              return (
                                <>
                                  <Botao
                                    ic
                                    className={row.id}
                                    icon={"icon-lx-trash"}
                                    onClick={() => {
                                      this.retirarCliente(row.codigoCliente);
                                    }}
                                  />
                                </>
                              )
                            }
                          }
                        ]}
                        defaultPageSize={5}
                        previousText="Anterior"
                        nextText="Próximo"
                        loadingText="Carregando registros..."
                        noDataText="Nenhum cliente selecionado"
                        pageText="Página"
                        ofText="de"
                        rowsText="registros"
                      />
                    </div>
                  </SectionContent>
                </div>
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
        <Footer
          saveAction={
            id === null ? this.handleSalvar : (
              menuPermiteAlteracao(PATH_MENU) ? this.handleSalvar : null)}
          deleteAction={id === null ? null : (
            menuPermiteExclusao(PATH_MENU) ? this.handleExcluir : null)
          }
          cancelAction={this.handleCancelar}
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class GrupoPessoas extends Component {
  state = { resultgrupoClientes: {}, grupoClientes: [], grupoClienteSel: {} };

  async componentDidMount() {
    const { data: resultgrupoClientes } = await buscarGrupoClientes(1, true);
    this.setState({ resultgrupoClientes });

    const grupoClientesOrd = resultgrupoClientes.result.sort(function (a, b) {
      if (a.descricao.toLowerCase() < b.descricao.toLowerCase()) return -1;
      if (a.descricao.toLowerCase() > b.descricao.toLowerCase()) return 1;
      return 0;
    });

    this.setState({ grupoClientes: grupoClientesOrd })
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      const { data: resultgrupoClientes } = await buscarGrupoClientes(1, true);
      this.setState({ resultgrupoClientes });

      const grupoClientesOrd = resultgrupoClientes.result.sort(function (a, b) {
        if (a.descricao.toLowerCase() < b.descricao.toLowerCase()) return -1;
        if (a.descricao.toLowerCase() > b.descricao.toLowerCase()) return 1;
        return 0;
      });

      this.setState({ grupoClientes: grupoClientesOrd })

    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      this.setState({ grupoClienteSel: rowInfo.original });
      this.props.history.push(NEW_PATH)
    }
  }

  render() {
    const { edit } = this.props,
      { grupoClienteSel } = this.state;
    return (
      <>
        {edit ? (
          <Form grupoCliente={grupoClienteSel}
            gruposClienteExistentes={this.state.grupoClientes}
          />
        ) : (
            <>
              <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                <SectionHeader
                  right={
                    <div className="button-container">
                      {edit ? (
                        <></>
                      ) : (
                          menuPermiteInclusao(PATH_MENU) ? (
                            <Botao ic icon="icon-lx-plus" onClick={() => {
                              this.setState({ grupoClienteSel: {} });
                              this.props.history.push(NEW_PATH)
                            }} />
                          ) : (
                              <></>
                            )
                        )}
                    </div>
                  }
                />
                <List
                  onClick={this.handleTableClick}
                  cols={[
                    {
                      accessor: "descricao",
                      Header: "Descrição",
                      width: 300,
                      filterable: false,
                    },
                    {
                      accessor: "mostraAgrupado",
                      Header: "Mostra Agrupado",
                      width: 300,
                      filterable: false,
                      Cell: ({ row }) => {
                        return (
                          <Botao
                            secondary={!row.mostraAgrupado}
                            ic
                            icon={row.mostraAgrupado ?
                              "icon-lx-check" : "icon-lx-close"}
                          />
                        );
                      }
                    }
                  ]}
                  rows={this.state.grupoClientes}
                />
              </LinxPostos>
            </>
          )}
      </>
    );
  }
}

GrupoPessoas = withRouter(GrupoPessoas);
export { GrupoPessoas };
