import { DataGrid } from 'devextreme-react';
import { Column, Selection } from 'devextreme-react/data-grid';
import styles from './SearchProdutos.module.scss'
import React, { useState } from 'react';
import { searchProdutoService } from './SearchProdutos.service'
import _ from 'lodash';
import { useEffect } from 'react';

const SearchProdutos = props => {

    const [searchedProdutos, setSearchedProdutos] = useState([]);
    const [searchingProdutos, setSearchingProdutos] = useState(false);
    const [noResults, setNoResults] = useState(true);
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {

        if (!props.selectedProdutos || props.selectedProdutos.length < 1) {
            setSearchInput('');
        }

    }, [props.selectedProdutos])

    const onSearchProdutos = e => {
        setSearchedProdutos([]);

        if (e.target.value.length > 2) {
            setSearchingProdutos(true);
            searchProdutos(e.target.value);
            setNoResults(false);
        } else {
            setSearchedProdutos([]);
            setSearchingProdutos(false);
        }
    };

    const closeSearchingProdutos = () => {
        setSearchingProdutos(false);
    };

    const inputOnChange = e => {
        setSearchInput(e.currentTarget.value);
        e.persist();
        onSearchProdutos(e);
    }

    const searchProdutos = _.debounce(async e => {
        const response = await searchProdutoService.find(e);
        if (!response.length) setNoResults(true);
        setSearchedProdutos(response);
    }, 900);

    return (<div className={`${styles.SearchProdutos} SearchProdutos`}>
        <div className="input-group">
            <div className="input-group-prepend">
                <span className={styles.inputGroupTextIcon}>
                    <i className="icon icon-lx-search"></i>
                </span>
            </div>
            <input
                type="text"
                className="form-control"
                disabled={props.searching}
                placeholder="Busque produtos por código ou descrição - informe ao menos 3 caracteres"
                value={searchInput}
                //defaultValue={searchInput}
                onFocus={e => {
                    e.persist();
                    onSearchProdutos(e);
                }}
                onChange={(e) => inputOnChange(e)}
            />
        </div>
        {searchingProdutos ? (
            <>
                <div className={`${styles.inputResults} inputResults bg-white`}>
                    {!searchedProdutos.length && !noResults ? (
                        <div className={`${styles.searchingWrapper} searchingWrapper`}>
                            <svg
                                className={`${styles.spinner} spinner`}
                                viewBox="0 0 50 50"
                            >
                                <circle
                                    className={`${styles.path} path`}
                                    cx="25"
                                    cy="25"
                                    r="20"
                                    fill="none"
                                    strokeWidth="5"
                                ></circle>
                            </svg>
                        PESQUISANDO PRODUTOS...
                        </div>
                    ) : searchedProdutos.length ? (
                        <DataGrid
                            id="grid-container"
                            dataSource={searchedProdutos}
                            disabled={props.searching}
                            keyExpr="id"
                            onSelectionChanged={props.onSelectionChanged}
                            selectedRowKeys={props.selectedProdutos}
                            showBorders={true}
                            hoverStateEnabled={true}
                        >
                            <Selection mode="multiple" />
                            <Column
                                dataField="codigo"
                                caption="Código"
                                width={150}
                            />
                            <Column
                                dataField="descricao"
                                caption="Descrição"
                            />
                            <Column
                                dataField="subTipo.descricao"
                                caption="Subtipo"
                            />
                            <Column
                                dataField="situacaoDescricao"
                                caption="Situação"
                            />
                        </DataGrid>
                    ) : (
                        <></>
                    )}
                    {noResults && (
                        <div className={styles.noResults}>
                            A BUSCA NÃO ENCONTROU NENHUM PRODUTO
                            CORRESPONDENTE
                        </div>
                    )}
                </div>
                <div
                    className={styles.produtosOverlay}
                    onClick={closeSearchingProdutos}>

                </div>
            </>
        ) : (
            <></>
        )}
    </div>)

}

export default SearchProdutos;