import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Select,
  Checkbox,
  Checkitem,
  handleInputChange,
  InputDate,
  handleInputChangeRegex
} from '../../../components/formulario/Formulario';
import List, { sortInt } from '../../../components/formulario/List';
import SectionHeader from '../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';

import { withRouter } from 'react-router-dom';

import { consultaCep } from '../../../shared/utils/ConsultaCep';

import {
  getEstabelecimentos,
  montarComboContador,
  incluirEstabelecimento,
  alterarEstabelecimento,
  montarComboUfs,
  montarComboMunicipios,
  listarCNAEs,
  montaComboRegimeIncidencia,
  montaComboCodigoTipoContribuicao,
  montaComboIndicadorPisCofins,
  montaCombometodoApropriacaoCreditos
} from './Empresas.service';

import {
  validaCNPJ,
  isValidIE,
  validaCPF,
  menuPermiteAlteracao,
  getNomePagina,
  validaDadosLogin,
  sameInt
} from '../../../shared/utils/Utils';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import 'rc-tree/assets/index.css';
import Tree from 'rc-tree';
import Tooltip from 'rc-tooltip';
import moment from 'moment';
import { getSetores } from '../../../services/Setores.service';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import Footer from '../../../components/core/footer/Footer';
import { menus } from '../../../shared/constants/MenuConstants';
import {
  tagAlert,
  showInfo,
  showSaveError,
  showWait,
  hideWait,
  showQuestion,
  hideAlert,
  showError,
  showWarning
} from '../../../components/alert/Alert';
import { ALPHANUMERIC_WITH_HYPHEN_PATTERN } from '../../../shared/constants/Const';
import { DateBox } from 'devextreme-react';
import { locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';

const PATH_MENU = menus.COD_60600;
const MAIN_PATH = '/configuracoes/empresa';
const NEW_PATH = MAIN_PATH + '/new';
const titulo = 'Empresas';

const SetoresEmpresa = {
  Loja: 'Loja',
  Pista: 'Pista',
  CentroTecnico: 'Centro Técnico'
};

const PerfisSpedFiscal = {
  A: '1',
  B: '2',
  C: '3'
};

const RegimesTributarios = {
  LucroPresumido: '1',
  LucroReal: '2',
  SimplesNacional: '4',
  SimplesNacionalExcessoSublimite: '8'
};

const RegimesIncidencia = {
  NaoCumulativa: '1',
  Cumulativa: '2'
};

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleCNAE = this.handleCNAE.bind(this);
    this.handleInputChangeRegex = handleInputChangeRegex.bind(this);
    locale(navigator.language);
    const {
      //empresas
      codigoEstabelecimento = null,
      razaoSocial = null,
      inativo = false,
      nomeFantasia = null,
      cnpj = null,
      inscricaoEstadual = '',
      inscricaoMunicipal = '',
      codigoANP = null,
      dataConstituicao = null,
      email = null,
      enderecoInternet = '',
      rowVersion = null,
      //endereco
      endereco = {},
      //setor
      setor = [],
      //representante legal
      representante = {},
      //informacoes Fiscais
      fiscais = {},
      //CNAE
      cnae = [],
      inscritaROT = false,
      dataInscricaoROT,
      dataInativacaoROT,
      rotNaoObrigaCPF,
      chave
    } = this.props.estabelecimento;

    var codigoSpedFiscalEmpresa = fiscais.codigoSpedFical;
    var representanteEmpresa = {};
    if (representante !== null) {
      representanteEmpresa = representante;
    }

    this.state = {
      //empresas
      codigoEstabelecimento,
      razaoSocial,
      ativo: !inativo,
      nomeFantasia,
      cnpj,
      chave,
      inscricaoEstadual,
      inscricaoMunicipal,
      codigoANP,
      dataConstituicao,
      email,
      enderecoInternet,
      rowVersion,
      //endereço
      Cep: endereco.cep,
      Logradouro: endereco.logradouro,
      Numero: endereco.numero,
      Complemento: endereco.complemento,
      Bairro: endereco.bairro,
      CodigoUF: endereco.unidadeFederativa.id,
      CodigoMunicipio: endereco.localidade.id,
      //setor
      setorLoja: setor.filter(s => parseInt(s.id) === 2).length !== 0, //Loja
      setorPista: setor.filter(s => parseInt(s.id) === 3).length !== 0, // Pista
      setorTrocaOleo: setor.filter(s => parseInt(s.id) === 4).length !== 0, // Centro de Serviços
      //representante legal
      Nome: representanteEmpresa.nome,
      CPF: representanteEmpresa.cpf,
      FuncaoCargo: representanteEmpresa.funcaoCargo,
      //informacoes Fiscais
      perfilSpedFiscal: codigoSpedFiscalEmpresa
        ? codigoSpedFiscalEmpresa
        : PerfisSpedFiscal.A,
      RegimeTributario: fiscais.regimeTributario,
      CodigoContador: fiscais.contador ? fiscais.contador.id : 0,
      apropriarCreditosCompras: fiscais.apropriarCreditosCompras,
      codigoReceitaE116: fiscais.codigoReceitaE116,
      codigoTipoContribuicao: fiscais.tipoContribuicao
        ? fiscais.tipoContribuicao
        : 1,
      gerarIpiLivrosFiscais: fiscais.gerarIpiLivrosFiscais,
      gerarValorOperNfeSusbst: fiscais.gerarValorOperNfeSusbst,
      indicadorPisCofins: fiscais.indicadorPisCofins,
      metodoApropriacaoCreditos: fiscais.metodoApropriacaoCreditos,
      regimeIncidencia: fiscais.regimeIncidencia
        ? fiscais.regimeIncidencia
        : RegimesIncidencia.NaoCumulativa,
      //CNAE
      cnae,
      listaContadores: [],
      listaUFs: [],
      listaCNAE: [],
      listaCNAEEmpresa: [],
      listaSetores: [],
      // listas fiscais
      listaRegimesIncidencia: [],
      listaTiposContribuicao: [],
      listaIndicadoresPisCofins: [],
      listaMetodosApropCreditos: [],
      inscritaROT,
      inscritaOriginalROT: inscritaROT,
      dataInscricaoROT,
      dataInscricaoOriginalROT: dataInscricaoROT,
      dataInativacaoROT,
      rotNaoObrigaCPF
    };
  }

  async componentDidMount() {
    function mapCombo(data, showNone) {
      const combo = data.map(item => {
        return {
          value: item.valor,
          label: item.descricao
        };
      });
      if (showNone) {
        combo.unshift({ value: '', label: showNone });
      }
      return combo;
    }

    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto os dados são carregados.'
    );
    try {
      const { data: resultListaContadores } = await montarComboContador();

      const listaContadores = resultListaContadores.data;

      const contadorVazio = {
        id: 0,
        nome: 'Selecione'
      };

      listaContadores.unshift(contadorVazio);

      this.setState({
        listaContadores: listaContadores.map(contador => {
          return {
            label: contador.nome,
            value: contador.id
          };
        })
      });

      const { data: listaUFs } = await montarComboUfs();

      this.setState({
        listaUFs: listaUFs.data.map(uf => {
          return {
            label: uf.sigla,
            value: uf.id
          };
        })
      });

      this.carregaComboMunicipios();

      const { data: listSetores } = await getSetores();

      this.setState({
        listaSetores: listSetores.map(setor => {
          return {
            descricao: setor.descricao,
            setorFlag:
              setor.descricao === 'Centro Técnico'
                ? 'setorTrocaOleo'
                : 'setor' + setor.descricao
          };
        })
      });

      const { data: dataRegimeIncidencia } = await montaComboRegimeIncidencia();
      const listaRegimesIncidencia = mapCombo(
        dataRegimeIncidencia.data,
        'Selecione o regime de incidência'
      );

      const {
        data: dataTipoContribuicao
      } = await montaComboCodigoTipoContribuicao();
      const listaTiposContribuicao = mapCombo(dataTipoContribuicao.data);

      const {
        data: dataIndicadorPisCofins
      } = await montaComboIndicadorPisCofins();
      const listaIndicadoresPisCofins = mapCombo(
        dataIndicadorPisCofins.data,
        'Selecione o indicador'
      );

      const { data: dataMetodo } = await montaCombometodoApropriacaoCreditos();
      const listaMetodosApropCreditos = mapCombo(
        dataMetodo.data,
        'Selecione o indicador'
      );

      this.setState({
        listaRegimesIncidencia,
        listaTiposContribuicao,
        listaIndicadoresPisCofins,
        listaMetodosApropCreditos
      });
    } finally {
      hideWait(this);
    }
  }

  isFormValid = () => {
    if (!this.validaDataEscolhida()) {
      showError(
        this,
        titulo,
        'Campo Data de Constituição deve ser uma data válida'
      );
      return false;
    }

    if (this.state.inscritaROT && !this.validaDataInscricao()) {
      return false;
    }

    if (
      this.state.inscritaOriginalROT &&
      !this.state.inscritaROT &&
      !this.validaDataInativacao()
    ) {
      return false;
    }

    if (this.verificaestabelecimento()) {
      return false;
    }

    const {
      perfilSpedFiscal,
      RegimeTributario,
      regimeIncidencia,
      codigoTipoContribuicao,
      metodoApropriacaoCreditos,
      codigoReceitaE116
    } = this.state;

    if (!perfilSpedFiscal) {
      showError(this, titulo, 'Selecione o perfil SPED fiscal.');
      return false;
    }

    if (!RegimeTributario) {
      showError(this, titulo, 'Selecione o regime tributário.');
      return false;
    }

    if (!regimeIncidencia) {
      showError(this, titulo, 'Selecione o regime de incidência.');
      return false;
    }

    if (!codigoTipoContribuicao) {
      showError(this, titulo, 'Selecione o código do tipo de contribuição');
      return false;
    }

    if (
      !metodoApropriacaoCreditos &&
      sameInt(regimeIncidencia, RegimesIncidencia.NaoCumulativa)
    ) {
      showError(this, titulo, 'Selecione o método de apropriação de créditos');
      return false;
    }

    if (!codigoReceitaE116) {
      showError(
        this,
        titulo,
        'Digite o código da receita padrão no registro E116'
      );
      return false;
    }

    return true;
  };

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    if (!this.isFormValid()) {
      return;
    }

    const {
        razaoSocial,
        ativo,
        nomeFantasia,
        chave,
        codigoANP,
        dataConstituicao,
        cnpj,
        inscricaoEstadual,
        inscricaoMunicipal,
        email,
        enderecoInternet,
        Cep,
        Logradouro,
        Numero,
        Complemento,
        Bairro,
        CodigoUF,
        CodigoMunicipio,
        Nome,
        CPF,
        FuncaoCargo,
        setorLoja,
        setorPista,
        setorTrocaOleo,
        perfilSpedFiscal,
        RegimeTributario,
        CodigoContador,
        apropriarCreditosCompras,
        codigoReceitaE116,
        codigoTipoContribuicao,
        gerarIpiLivrosFiscais,
        gerarValorOperNfeSusbst,
        indicadorPisCofins,
        metodoApropriacaoCreditos,
        regimeIncidencia,
        cnae,
        rowVersion,
        inscritaROT,
        dataInscricaoROT,
        dataInativacaoROT,
        rotNaoObrigaCPF
      } = this.state,
      inativo = !ativo,
      objEndereco = {
        Cep,
        Logradouro,
        Numero: parseInt(Numero),
        Complemento: Complemento === null ? '' : Complemento,
        Bairro,
        UnidadeFederativa: {
          id: parseInt(CodigoUF)
        },
        Localidade: {
          id: parseInt(CodigoMunicipio)
        }
      },
      representante = {
        Nome,
        CPF,
        FuncaoCargo
      },
      infoFiscais = {
        CodigoSpedFical: perfilSpedFiscal,
        RegimeTributario: parseInt(RegimeTributario),
        Contador: {
          id: parseInt(CodigoContador)
        },
        apropriarCreditosCompras,
        codigoReceitaE116,
        tipoContribuicao: parseInt(codigoTipoContribuicao),
        gerarIpiLivrosFiscais,
        gerarValorOperNfeSusbst,
        indicadorPisCofins: parseInt(indicadorPisCofins),
        metodoApropriacaoCreditos: parseInt(metodoApropriacaoCreditos),
        regimeIncidencia: parseInt(regimeIncidencia)
      },
      objSetorLoja = {
        id: 2,
        descricao: 'Loja'
      },
      objSetorPista = {
        id: 3,
        descricao: 'Pista'
      },
      objSetorCS = {
        id: 4,
        descricao: 'Centro de Serviços'
      },
      cnaes = [],
      setores = [];

    if (cnae)
      for (let i = 0; i < cnae.length; i++) {
        const element = cnae[i];
        const objCNAE = {
          id: '',
          cnaeCodigo: '',
          cnaeDescricao: '',
          primario: false
        };
        objCNAE.id = parseInt(element.id);
        objCNAE.cnaeCodigo = element.cnaeCodigo.toString();
        objCNAE.cnaeDescricao = element.cnaeDescricao;
        objCNAE.primario = element.primario;
        cnaes.push(objCNAE);
      }

    if (setorLoja) {
      setores.push(objSetorLoja);
    }
    if (setorPista) {
      setores.push(objSetorPista);
    }
    if (setorTrocaOleo) {
      setores.push(objSetorCS);
    }

    const [action, params] =
      rowVersion !== null
        ? [
            alterarEstabelecimento,
            [
              1,
              razaoSocial,
              inativo,
              nomeFantasia,
              codigoANP,
              dataConstituicao,
              cnpj,
              inscricaoEstadual,
              inscricaoMunicipal,
              email,
              enderecoInternet,
              objEndereco,
              representante,
              infoFiscais,
              setores,
              cnaes,
              rowVersion,
              inscritaROT,
              dataInscricaoROT,
              dataInativacaoROT,
              rotNaoObrigaCPF,
              chave
            ]
          ]
        : [
            incluirEstabelecimento,
            [
              razaoSocial,
              inativo,
              nomeFantasia,
              codigoANP,
              dataConstituicao,
              cnpj,
              inscricaoEstadual,
              inscricaoMunicipal,
              email,
              enderecoInternet,
              objEndereco,
              representante,
              setores,
              infoFiscais,
              cnaes
            ]
          ];

    try {
      showWait(this, titulo, 'Por favor, aguarde enquanto a empresa é salva.');
      await action(...params);
      const msg = rowVersion
        ? 'Empresa alterada com sucesso!'
        : 'Empresa cadastrada com sucesso!';
      if (sameInt(CodigoContador, 0)) {
        notify(
          'Para o SPED Fiscal os dados do contador são obrigatórios. Caso exista a necessidade de entrega do arquivo fiscal, configure corretamente o contador para evitar possíveis problemas',
          'warning',
          10000,
          'top'
        );
      }

      showInfo(this, titulo, msg);
    } catch (err) {
      showSaveError(this, titulo, err);
    }
  };

  handleCancelar = () => {
    showQuestion(
      this,
      titulo,
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (resp) {
          hideAlert(this);
          this.props.history.push(MAIN_PATH);
        } else {
          hideAlert(this);
        }
      }
    );
  };

  verificaestabelecimento = () => {
    //verifica preenchimento dos campos
    var temErros = false;

    if (!this.state.razaoSocial || this.state.razaoSocial.trim() === '') {
      showError(this, titulo, 'É necessário informar a razão social.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.nomeFantasia || this.state.nomeFantasia.trim() === '') {
      showError(this, titulo, 'É necessário informar o nome fantasia.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (
      !this.state.inscricaoEstadual ||
      this.state.inscricaoEstadual.trim() === ''
    ) {
      showError(this, titulo, 'É necessário informar a inscrição estadual.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.validaEmail()) {
      temErros = true;
      return temErros;
    }

    if (!this.state.Cep || this.state.Cep.trim() === '') {
      showError(this, titulo, 'É necessário informar o cep.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.Logradouro || this.state.Logradouro.trim() === '') {
      showError(this, titulo, 'É necessário informar o logradouro.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.Bairro || this.state.Bairro.trim() === '') {
      showError(this, titulo, 'É necessário informar o bairro.');
      temErros = true;
    }

    if (
      !this.state.setorLoja &&
      !this.state.setorPista &&
      !this.state.setorTrocaOleo
    ) {
      showError(this, titulo, 'Deve ser selecionado ao menos um setor');
      temErros = true;
    }

    if (!this.state.Nome || this.state.Nome.trim() === '') {
      showError(
        this,
        titulo,
        'É necessário informar o nome do representante legal.'
      );
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.CPF || this.state.CPF.trim() === '') {
      showError(
        this,
        titulo,
        'Campo CPF do Representante legal deve ser preenchido.'
      );
      temErros = true;
    }

    temErros = !this.verificaCPF();

    if (temErros) {
      return temErros;
    }

    temErros = !this.verificaIE();

    if (temErros) {
      return temErros;
    }

    if (this.state.cnae) {
      if (this.state.cnae.length > 0) {
        var existePrimario = false;

        for (let i = 0; i < this.state.cnae.length; i++) {
          const element = this.state.cnae[i];
          if (element.primario) existePrimario = true;
        }

        if (!existePrimario) {
          showError(
            this,
            titulo,
            'Deve ser informada ao menos um CNAE primário'
          );
          temErros = true;
        }

        if (temErros) return temErros;
      }
    }
    return temErros;
  };

  validaTeclaDigitada = e => {
    const re = /[0-9a-zA-Z]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }

    if (e.key === 'ç') {
      e.preventDefault();
    }
  };

  validaTextoColado = event => {
    var textoNovo = '';

    var textoOriginal = '';

    var tamMax = 0;
    var tamTexto = 0;

    tamMax = event.target.maxLength;

    var caracterEspecial = false;

    var campo = event.target.name;

    textoOriginal = event.clipboardData.getData('Text');

    for (let i = 0; i < textoOriginal.length; i++) {
      const re = /[0-9a-zA-Z]+/g;
      const c = textoOriginal[i];
      tamTexto = textoNovo.length;
      caracterEspecial = false;
      if (!re.test(c)) {
        caracterEspecial = true;
      }

      if (c === 'ç') {
        caracterEspecial = true;
      }

      if (tamTexto < tamMax) if (!caracterEspecial) textoNovo += c;
    }

    if (caracterEspecial) event.preventDefault();

    this.setState({
      [campo]: textoNovo
    });
  };

  verificaCNPJ = () => {
    if (!validaCNPJ(this.state.CNPJ)) {
      showError(this, titulo, 'CNPJ não é válido');
    }
  };

  verificaCPF = () => {
    if (!this.state.CPF || this.state.CPF.trim() === '') {
      showError(
        this,
        titulo,
        'Campo CPF do Representante legal deve ser preenchido.'
      );
      return false;
    } else if (!validaCPF(this.state.CPF)) {
      showError(this, titulo, 'CPF do Representante legal não é válido');
      return false;
    } else return true;
  };

  verificaIE = () => {
    var codigoEstado = this.state.CodigoUF;
    var ie = '';
    ie = this.state.inscricaoEstadual.toString();

    var UF = '';

    UF = this.state.listaUFs.filter(
      uf => parseInt(uf.value) === parseInt(codigoEstado)
    );
    if (!isValidIE(ie, UF[0].value)) {
      showError(this, titulo, 'Inscrição estadual não é válida');
      return false;
    } else return true;
  };

  consultaDadosCep = async () => {
    const { data: dadosCep } = await consultaCep(this.state.Cep);
    var logradouroTratado = '';
    if (dadosCep) {
      var dadosCepValue = dadosCep.value;

      if (dadosCep.value)
        logradouroTratado =
          dadosCep.value.OBS === ''
            ? dadosCepValue.LOGRADOURO
            : dadosCepValue.LOGRADOURO.substring(
                0,
                dadosCepValue.LOGRADOURO.indexOf(dadosCepValue.OBS)
              );

      if (dadosCepValue) {
        this.setState({ CodigoUF: dadosCepValue.ID_UF });
        this.carregaComboMunicipios();
        this.setState({
          Logradouro: dadosCepValue.TIPO_LOGRADOURO + ' ' + logradouroTratado,
          Bairro: dadosCepValue.BAIRRO,
          CodigoMunicipio: dadosCepValue.COD_MUNICIPIO_IBGE
        });
      } else {
        showError(this, titulo, 'Cep não encontrado');
      }
    }
  };

  carregaComboMunicipios = async codigoUFAtual => {
    if (!codigoUFAtual) codigoUFAtual = this.state.CodigoUF;
    const { data: listaMunicipios } = await montarComboMunicipios(
      codigoUFAtual
    );

    this.setState({
      listaMunicipios: listaMunicipios.data.map(cid => {
        return {
          label: cid.descricao,
          value: cid.id
        };
      })
    });
  };

  handleUFChange = event => {
    let value = '';

    value = event.target.value;

    this.setState({
      CodigoUF: value
    });

    this.carregaComboMunicipios(value);
  };

  handleCNAE(cnae) {
    this.setState({
      cnae
    });
  }

  validaDataEscolhida = () => {
    var dataValida = false;
    dataValida = moment(this.state.dataConstituicao, 'YYYY-MM-DD').isValid();
    return dataValida;
  };

  validaEmail = () => {
    if (!this.state.email || this.state.email.trim() === '') {
      showError(this, titulo, 'O campo E-mail deve ser preenchido.');
      return false;
    } else {
      let field = this.state.email;
      let usuario = field.substring(0, field.indexOf('@'));
      let dominio = field.substring(field.indexOf('@') + 1, field.length);

      if (
        !(
          usuario.length >= 1 &&
          dominio.length >= 3 &&
          usuario.search('@') === -1 &&
          dominio.search('@') === -1 &&
          usuario.search(' ') === -1 &&
          dominio.search(' ') === -1 &&
          dominio.search('.') !== -1 &&
          dominio.indexOf('.') >= 1 &&
          dominio.lastIndexOf('.') < dominio.length - 1
        )
      ) {
        showError(this, titulo, 'E-mail não é válido');
        return false;
      }
    }

    return true;
  };

  validaRegimeTributario = e => {
    const regime = e.target.value;
    const { perfilSpedFiscal } = this.state;
    if (
      sameInt(perfilSpedFiscal, PerfisSpedFiscal.C) &&
      !sameInt(regime, RegimesTributarios.SimplesNacional) &&
      !sameInt(regime, RegimesTributarios.SimplesNacionalExcessoSublimite)
    )
      showWarning(
        this,
        titulo,
        'Para o perfil C é recomendado usar o regime tributário Simples Nacional.'
      );
  };

  getRegimeIncidenciaByRegimeTributario = regimeTributario => {
    let regimeIncidencia = '';
    if (sameInt(regimeTributario, RegimesTributarios.LucroReal)) {
      regimeIncidencia = RegimesIncidencia.NaoCumulativa;
    } else if (sameInt(regimeTributario, RegimesTributarios.LucroPresumido)) {
      regimeIncidencia = RegimesIncidencia.Cumulativa;
    }
    return regimeIncidencia;
  };

  getRegimeTributarioByPerfilSped = perfilSpedFiscal => {
    let { RegimeTributario } = this.state;
    if (sameInt(perfilSpedFiscal, PerfisSpedFiscal.A)) {
      RegimeTributario = RegimesTributarios.LucroReal;
    } else if (sameInt(perfilSpedFiscal, PerfisSpedFiscal.B)) {
      RegimeTributario = RegimesTributarios.LucroPresumido;
    } else if (sameInt(perfilSpedFiscal, PerfisSpedFiscal.C)) {
      RegimeTributario = RegimesTributarios.SimplesNacional;
    }
    return RegimeTributario;
  };

  getMetodoApropriacaoCreditosByRegimeIncidencia = regimeIncidencia => {
    let { metodoApropriacaoCreditos } = this.state;
    if (!sameInt(regimeIncidencia, RegimesIncidencia.NaoCumulativa)) {
      metodoApropriacaoCreditos = 0;
    }
    return metodoApropriacaoCreditos;
  };

  buscaSituacaoSetor = setor => {
    switch (setor) {
      case SetoresEmpresa.Loja:
        return this.state.setorLoja;

      case SetoresEmpresa.Pista:
        return this.state.setorPista;

      case SetoresEmpresa.CentroTecnico:
        return this.state.setorTrocaOleo;

      default:
        break;
    }
  };

  alteraInscricaoROT = () => {
    const situacaoAtual = this.state.inscritaROT;
    const dataInscricaoROT = this.state.dataInscricaoROT;
    if (situacaoAtual) {
      if (moment(dataInscricaoROT).diff(moment(), 'years') < 1) {
        showQuestion(
          this,
          titulo,
          'A data de inscrição no ROT ST é inferior a um ano. \n' +
            'Caso já tenham sido enviados arquivos para a SEFAZ com essa opção selecionada, podem ocorrer problemas com a fiscalização.\n' +
            'Em caso de dúvidas, consulte o seu contador.\n' +
            'Deseja realmente desmarcar a opção?',
          null,
          resp => {
            if (resp) {
              hideAlert(this);
              this.setState({ inscritaROT: !situacaoAtual });
              this.setState({ dataInativacaoROT: null });
            } else {
              hideAlert(this);
            }
          }
        );
      } else {
        this.setState({ inscritaROT: !situacaoAtual });
        this.setState({ dataInativacaoROT: null });
      }
    } else {
      this.setState({ inscritaROT: !situacaoAtual });
      this.setState({ dataInativacaoROT: null });
    }
  };

  alteraPermissaoROT = () => {
    const situacaoAtual = this.state.rotNaoObrigaCPF;
    if (!situacaoAtual) {
      showQuestion(
        this,
        titulo,
        'É obrigatório informar o cliente em vendas de itens em que o ROT ST é aplicado.\n' +
          'Com essa opção habilitada, ao finalizar a venda, caso o cliente não seja identificado, o PDV não obrigará a identificação do mesmo.\n' +
          'Em caso de fiscalização, isso pode causar a exclusão da empresa do ROT. Em caso de dúvidas, consulte sua contabilidade.\n' +
          'Deseja marcar a opção mesmo assim?',
        null,
        resp => {
          if (resp) {
            hideAlert(this);
            this.setState({ rotNaoObrigaCPF: !situacaoAtual });
          } else {
            hideAlert(this);
          }
        }
      );
    } else this.setState({ rotNaoObrigaCPF: !situacaoAtual });
  };

  validaDataInscricao = () => {
    if (moment(this.state.dataInscricaoROT).isAfter(moment())) {
      showError(
        this,
        titulo,
        'A data de Inscricao no ROT ST deve ser igual ou anterior a data atual.'
      );
      return false;
    }

    if (!moment(this.state.dataInscricaoROT, 'YYYY-MM-DD').isValid()) {
      showError(
        this,
        titulo,
        'A data de inscrição no ROT ST deve ser uma data válida.'
      );
      return false;
    }

    return true;
  };

  validaDataInativacao = () => {
    if (!moment(this.state.dataInativacaoROT, 'YYYY-MM-DD').isValid()) {
      showError(
        this,
        titulo,
        'A data de inativação no ROT ST deve ser informada quando a empresa deixa de ser inscrita no ROT ST'
      );
      return false;
    }

    if (
      moment(this.state.dataInscricaoROT).isAfter(
        moment(this.state.dataInativacaoROT)
      )
    ) {
      showError(
        this,
        titulo,
        'Data de inativação no ROT ST deve ser anterior a data de inscrição no ROT ST.'
      );
      return false;
    }

    return true;
  };

  validaMudancaData = e => {
    let valorState =
      e.element.id === 'dataInscricaoROT'
        ? this.state.dataInscricaoROT
        : this.state.dataInativacaoROT;
    if (e.value !== valorState) {
      const dataOriginal = this.state.dataInscricaoOriginalROT;
      if (e.element.id === 'dataInscricaoROT' && !moment(e.value).isValid()) {
        showError(
          this,
          titulo,
          'A data de inscrição no ROT ST deve ser uma data válida.'
        );
      } else if (
        e.element.id === 'dataInativacaoROT' &&
        !moment(e.value).isValid()
      ) {
        showError(this, titulo, 'Data de inativação no ROT ST inválida.');
      } else {
        if (moment(e.value).isAfter(moment())) {
          showError(
            this,
            titulo,
            'A data deve ser igual ou anterior a data atual.'
          );
          this.setState({
            [e.element.id]:
              e.element.id === 'dataInscricaoROT' ? e.previousValue : moment()
          });
        } else {
          if (e.element.id === 'dataInscricaoROT' && !dataOriginal) {
            this.setState({
              dataInscricaoROT: moment(e.value).format('YYYY-MM-DD')
            });
          } else if (
            e.element.id === 'dataInscricaoROT' &&
            dataOriginal &&
            moment(e.value) !== dataOriginal
          ) {
            this.setState({
              dataInscricaoROT: moment(e.value).format('YYYY-MM-DD')
            });
            showQuestion(
              this,
              titulo,
              'Caso já tenha enviado registros do SPED Fiscal (ICMS/IPI) com movimentação em período diferente do informado, podem ocorrer problemas com a fiscalização.\n' +
                'Em caso de dúvidas, consulte a sua contabilidade.\n' +
                'Deseja alterar mesmo assim?',
              null,
              resp => {
                if (!resp) {
                  hideAlert(this);
                  this.setState({
                    dataInscricaoROT: moment(e.previousValue).format(
                      'YYYY-MM-DD'
                    )
                  });
                } else {
                  hideAlert(this);
                }
              }
            );
          } else if (e.element.id === 'dataInativacaoROT') {
            if (moment(e.value).isBefore(moment(this.state.dataInscricaoROT))) {
              showError(
                this,
                titulo,
                'A data de inativação no ROT ST deve ser superior a data de inscrição no ROT ST.'
              );
              this.setState({
                dataInativacaoROT: moment().format('YYYY-MM-DD')
              });
            } else {
              this.setState({
                dataInativacaoROT: moment(e.value).format('YYYY-MM-DD')
              });
            }
          }
        }
      }
    }
  };

  render() {
    const {
      //campos tela
      razaoSocial,
      nomeFantasia,
      cnpj,
      inscricaoEstadual,
      inscricaoMunicipal,
      codigoANP,
      dataConstituicao,
      email,
      enderecoInternet = '',
      rowVersion,

      //endereço

      Cep,
      Logradouro,
      Numero,
      Complemento,
      Bairro,
      CodigoUF,
      CodigoMunicipio,

      //representante legal

      Nome,
      CPF,
      FuncaoCargo,

      //informacoes Fiscais

      perfilSpedFiscal,
      RegimeTributario,
      CodigoContador,
      regimeIncidencia,
      codigoTipoContribuicao,
      indicadorPisCofins,
      metodoApropriacaoCreditos,
      apropriarCreditosCompras,
      codigoReceitaE116,
      gerarValorOperNfeSusbst,
      gerarIpiLivrosFiscais,
      listaMetodosApropCreditos,
      listaIndicadoresPisCofins,
      listaRegimesIncidencia,
      listaTiposContribuicao,

      listaUFs,
      listaContadores,
      listaMunicipios,
      listaSetores,

      inscritaROT,
      dataInscricaoROT,
      dataInativacaoROT,
      rotNaoObrigaCPF
    } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="Identificação da empresas" accordion>
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="Razão Social"
                    value={razaoSocial}
                    name="razaoSocial"
                    onChange={this.handleInputChange}
                    tabindex={1}
                    maxlength="65"
                  />
                </div>
                <div className="col-6">
                  <InputText
                    label="Nome Fantasia"
                    value={nomeFantasia}
                    name="nomeFantasia"
                    onChange={this.handleInputChange}
                    tabindex={2}
                    maxlength="30"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="CNPJ"
                    type="number"
                    value={cnpj}
                    name="cnpj"
                    onChange={this.handleInputChange}
                    onBlur={this.verificaCNPJ}
                    format="##.###.###/####-##"
                    help="Apenas números"
                    tabindex={3}
                    disabled={rowVersion !== null}
                  />
                </div>
                <div className="col-6">
                  <InputText
                    label="Inscrição Estadual"
                    value={inscricaoEstadual}
                    name="inscricaoEstadual"
                    onChange={this.handleInputChange}
                    onBlur={this.verificaIE}
                    onKeyDown={this.validaTeclaDigitada}
                    onPaste={this.validaTextoColado}
                    tabindex={4}
                    disabled={rowVersion !== null}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="Inscrição Municipal"
                    value={inscricaoMunicipal}
                    name="inscricaoMunicipal"
                    onChange={this.handleInputChange}
                    onKeyDown={this.validaTeclaDigitada}
                    onPaste={this.validaTextoColado}
                    tabindex={5}
                    maxlength="15"
                  />
                </div>
                <div className="col-6">
                  <InputText
                    label="ANP"
                    value={codigoANP}
                    name="codigoANP"
                    onChange={this.handleInputChange}
                    tabindex={6}
                    maxlength="11"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputDate
                    label="Data Constituição"
                    value={dataConstituicao}
                    name="dataConstituicao"
                    onChange={this.handleInputChange}
                    tabindex={7}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputText
                    label="E-mail"
                    value={email}
                    name="email"
                    onChange={this.handleInputChange}
                    onBlur={this.validaEmail}
                    tabindex={9}
                    maxlength="65"
                  />
                </div>
                <div className="col-6">
                  <InputText
                    label="Endereço Internet"
                    value={enderecoInternet}
                    name="enderecoInternet"
                    onChange={this.handleInputChange}
                    tabindex={10}
                    maxlength="50"
                  />
                </div>
              </div>
            </SectionContent>
            <div className="content-divider" />
            <SectionContent
              title="Endereço"
              accordion
              accordionDefaultDisplay={false}
            >
              <div className="row">
                <div className="col-6">
                  <InputText
                    type="number"
                    label="Cep"
                    name="Cep"
                    value={Cep}
                    onChange={this.handleInputChange}
                    onBlur={this.consultaDadosCep}
                    icone="icon-lx-search"
                    tabindex={11}
                    format="#####-###"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <InputText
                    label="Endereço"
                    name="Logradouro"
                    value={Logradouro}
                    onChange={this.handleInputChange}
                    tabindex={12}
                    maxlength="65"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <InputText
                    label="Número"
                    name="Numero"
                    value={Numero}
                    onChange={this.handleInputChange}
                    tabindex={13}
                  />
                </div>

                <div className="col-4">
                  <InputText
                    label="Complemento"
                    name="Complemento"
                    value={Complemento}
                    onChange={this.handleInputChange}
                    tabindex={14}
                    maxlength="45"
                  />
                </div>

                <div className="col-4">
                  <InputText
                    label="Bairro"
                    name="Bairro"
                    value={Bairro}
                    onChange={this.handleInputChange}
                    tabindex={15}
                    maxlength="45"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <Select
                    label="UF"
                    name="CodigoUF"
                    value={CodigoUF}
                    onChange={this.handleUFChange}
                    options={listaUFs}
                    required
                    autofocus="true"
                    tabindex={16}
                  />
                </div>

                <div className="col-8">
                  <Select
                    label="Cidade"
                    name="CodigoMunicipio"
                    value={CodigoMunicipio}
                    onChange={this.handleInputChange}
                    options={listaMunicipios}
                    required
                    autofocus="true"
                    tabindex={17}
                  />
                </div>
              </div>
            </SectionContent>
            <div className="content-divider" />
            <SectionContent
              title="Setor"
              accordion
              accordionDefaultDisplay={false}
            >
              <div className="col-6">
                <Checkbox label="Setor:">
                  {listaSetores.map(itSetor => {
                    return (
                      <Checkitem
                        label={itSetor.descricao}
                        name={itSetor.setorFlag}
                        checked={this.buscaSituacaoSetor(itSetor.descricao)}
                        onChange={this.handleInputChange}
                      />
                    );
                  })}
                </Checkbox>
              </div>
            </SectionContent>
            <div className="content-divider" />
            <SectionContent
              title="Representante Legal"
              accordion
              accordionDefaultDisplay={false}
            >
              <div className="row">
                <div className="col-12">
                  <InputText
                    label="Nome"
                    name="Nome"
                    value={Nome}
                    onChange={this.handleInputChange}
                    tabindex={19}
                    maxlength="50"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputText
                    type="number"
                    label="CPF"
                    name="CPF"
                    value={CPF}
                    onChange={this.handleInputChange}
                    onBlur={this.verificaCPF}
                    format="###.###.###-##"
                    tabindex={20}
                  />
                </div>

                <div className="col-6">
                  <InputText
                    label="Cargo/Função"
                    name="FuncaoCargo"
                    value={FuncaoCargo}
                    onChange={this.handleInputChange}
                    tabindex={21}
                    maxlength="30"
                  />
                </div>
              </div>
            </SectionContent>
            <div className="content-divider" />
            <SectionContent
              title="Informações Fiscais"
              accordion
              accordionDefaultDisplay={false}
            >
              <div className="row">
                <div className="col-3">
                  <Select
                    label="Perfil SPED Fiscal"
                    name="perfilSpedFiscal"
                    value={perfilSpedFiscal}
                    onChange={e => {
                      const perfilSpedFiscal = e.target.value;
                      const gerarIpiLivrosFiscais = sameInt(
                        perfilSpedFiscal,
                        PerfisSpedFiscal.C
                      )
                        ? false
                        : this.state.gerarIpiLivrosFiscais;
                      const RegimeTributario = this.getRegimeTributarioByPerfilSped(
                        perfilSpedFiscal
                      );
                      const regimeIncidencia = this.getRegimeIncidenciaByRegimeTributario(
                        RegimeTributario
                      );
                      const metodoApropriacaoCreditos = this.getMetodoApropriacaoCreditosByRegimeIncidencia(
                        regimeIncidencia
                      );
                      const apropriarCreditosCompras = sameInt(
                        regimeIncidencia,
                        RegimesIncidencia.NaoCumulativa
                      )
                        ? this.state.apropriarCreditosCompras
                        : false;
                      this.setState({
                        perfilSpedFiscal,
                        gerarIpiLivrosFiscais,
                        RegimeTributario,
                        regimeIncidencia,
                        metodoApropriacaoCreditos,
                        apropriarCreditosCompras
                      });
                    }}
                    options={[
                      { value: PerfisSpedFiscal.A, label: 'A' },
                      { value: PerfisSpedFiscal.B, label: 'B' },
                      { value: PerfisSpedFiscal.C, label: 'C' }
                    ]}
                    required
                    autofocus="true"
                    tabindex={22}
                    //onBlur={this.defineRegimeTributario}
                    help="Obrigatório"
                  />
                </div>
                <div className="col-3">
                  <Select
                    label="Regime Tributário"
                    name="RegimeTributario"
                    value={RegimeTributario}
                    onChange={e => {
                      const RegimeTributario = e.target.value;
                      const regimeIncidencia = this.getRegimeIncidenciaByRegimeTributario(
                        RegimeTributario
                      );
                      const metodoApropriacaoCreditos = this.getMetodoApropriacaoCreditosByRegimeIncidencia(
                        regimeIncidencia
                      );
                      const apropriarCreditosCompras = sameInt(
                        regimeIncidencia,
                        RegimesIncidencia.NaoCumulativa
                      )
                        ? this.state.apropriarCreditosCompras
                        : false;
                      this.setState({
                        RegimeTributario,
                        regimeIncidencia,
                        metodoApropriacaoCreditos,
                        apropriarCreditosCompras
                      });
                    }}
                    options={[
                      {
                        value: RegimesTributarios.LucroPresumido,
                        label: 'Lucro presumido'
                      },
                      {
                        value: RegimesTributarios.LucroReal,
                        label: 'Lucro real'
                      },
                      {
                        value: RegimesTributarios.SimplesNacional,
                        label: 'Simples nacional'
                      },
                      {
                        value:
                          RegimesTributarios.SimplesNacionalExcessoSublimite,
                        label: 'Simples nacional - excesso sublimite'
                      }
                    ]}
                    required
                    autofocus="true"
                    tabindex={23}
                    onBlur={this.validaRegimeTributario}
                    help="Obrigatório"
                  />
                </div>
                <div className="col-6">
                  <Select
                    label="Regime de incidência"
                    name="regimeIncidencia"
                    value={regimeIncidencia}
                    onChange={e => {
                      const regimeIncidencia = e.target.value;
                      const metodoApropriacaoCreditos = this.getMetodoApropriacaoCreditosByRegimeIncidencia(
                        regimeIncidencia
                      );
                      const apropriarCreditosCompras = sameInt(
                        regimeIncidencia,
                        RegimesIncidencia.NaoCumulativa
                      )
                        ? this.state.apropriarCreditosCompras
                        : false;
                      this.setState({
                        regimeIncidencia,
                        metodoApropriacaoCreditos,
                        apropriarCreditosCompras
                      });
                    }}
                    options={listaRegimesIncidencia}
                    help="Obrigatório"
                    disabled={sameInt(
                      RegimeTributario,
                      RegimesTributarios.LucroPresumido
                    )}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Código do tipo de contribuição"
                    name="codigoTipoContribuicao"
                    value={codigoTipoContribuicao}
                    onChange={this.handleInputChange}
                    options={listaTiposContribuicao}
                    help="Obrigatório"
                  />
                </div>
                <div className="col-6">
                  <Select
                    label="Indicador de situação especial para PIS/COFINS"
                    name="indicadorPisCofins"
                    value={indicadorPisCofins}
                    onChange={this.handleInputChange}
                    options={listaIndicadoresPisCofins}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Select
                    label="Indicador do método de apropriação de créditos"
                    name="metodoApropriacaoCreditos"
                    value={metodoApropriacaoCreditos}
                    onChange={this.handleInputChange}
                    options={listaMetodosApropCreditos}
                    help="Obrigatório"
                    disabled={
                      !sameInt(
                        regimeIncidencia,
                        RegimesIncidencia.NaoCumulativa
                      )
                    }
                  />
                </div>
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label="Apropriar créditos sobre compras"
                      name="apropriarCreditosCompras"
                      checked={apropriarCreditosCompras}
                      onChange={this.handleInputChange}
                      disabled={
                        !sameInt(
                          regimeIncidencia,
                          RegimesIncidencia.NaoCumulativa
                        )
                      }
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputText
                    label={
                      'SPED Fiscal (ICMS/IPI) - ' +
                      'Código da receita padrão no registro E116'
                    }
                    name="codigoReceitaE116"
                    value={codigoReceitaE116}
                    onChange={this.handleInputChangeRegex}
                    pattern={ALPHANUMERIC_WITH_HYPHEN_PATTERN}
                    help="Obrigatório"
                  />
                </div>
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label={
                        'SPED Fiscal (ICMS/IPI) - ' +
                        'Gerar valor da operação para NF-e emitida em ' +
                        'substituição ao cupom fiscal - CFOP 5.929 ou 6.929'
                      }
                      name="gerarValorOperNfeSusbst"
                      checked={gerarValorOperNfeSusbst}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label={
                        'SPED Fiscal (ICMS/IPI) - ' +
                        'Gerar valor de IPI nos livros fiscais'
                      }
                      name="gerarIpiLivrosFiscais"
                      checked={gerarIpiLivrosFiscais}
                      onChange={this.handleInputChange}
                      disabled={sameInt(perfilSpedFiscal, PerfisSpedFiscal.C)}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Select
                    label="Contador"
                    name="CodigoContador"
                    value={CodigoContador}
                    onChange={this.handleInputChange}
                    options={listaContadores}
                    required
                    autofocus="true"
                    tabindex={24}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label="Empresa inscrita no ROT ST"
                      name="inscritaROT"
                      checked={inscritaROT}
                      onChange={this.alteraInscricaoROT}
                    />
                  </Checkbox>
                </div>
                <div className="col-6">
                  <Checkbox>
                    <Checkitem
                      label="Permitir realizar venda sem identificação do consumidor final"
                      name="rotNaoObrigaCPF"
                      checked={inscritaROT && rotNaoObrigaCPF}
                      onChange={this.alteraPermissaoROT}
                      disabled={!inscritaROT}
                    />
                  </Checkbox>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <span>Data de inscrição no ROT ST</span>
                  <DateBox
                    defaultValue={dataInscricaoROT}
                    id="dataInscricaoROT"
                    type="date"
                    onValueChanged={this.validaMudancaData}
                    disabled={!inscritaROT}
                    useMaskBehavior={true}
                    value={dataInscricaoROT}
                  />
                </div>
                <div className="col-6">
                  <span>Data de inativação no ROT ST</span>
                  <DateBox
                    defaultValue={dataInscricaoROT}
                    id="dataInativacaoROT"
                    type="date"
                    onValueChanged={this.validaMudancaData}
                    disabled={inscritaROT}
                    useMaskBehavior={true}
                    value={dataInativacaoROT}
                  />
                </div>
              </div>
            </SectionContent>
            <div className="content-divider" />
            <SectionContent
              title="CNAE"
              accordion
              accordionDefaultDisplay={false}
            >
              <TreeCNAE
                listaCNAE={this.state.cnae}
                handleCNAE={this.handleCNAE}
              />
            </SectionContent>
          </SectionContainer>
        </LinxPostos>

        <Footer
          saveAction={
            menuPermiteAlteracao(PATH_MENU) ? this.handleSalvar : null
          }
          cancelAction={this.props.showCancel ? this.handleCancelar : null}
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class Empresas extends Component {
  state = {
    estabelecimentos: [],
    estabelecimentosel: {}
  };

  doMount = async () => {
    const { data: responseestabelecimentos } = await getEstabelecimentos();
    const estabelecimentos = responseestabelecimentos.data;

    this.setState({ estabelecimentos });
  };

  async componentDidMount() {
    await this.doMount();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      await this.doMount();
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.setState({ estabelecimentosel: rowInfo.original });
      this.props.history.push(NEW_PATH);
    }
  };

  render() {
    const { edit } = this.props,
      { estabelecimentosel, estabelecimentos } = this.state;
    return (
      <>
        {edit ? (
          <Form estabelecimento={estabelecimentosel} showCancel={true} />
        ) : estabelecimentos.length === 1 ? (
          <Form estabelecimento={estabelecimentos[0]} showCancel={false} />
        ) : (
          <>
            <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
              <SectionHeader
                right={
                  <div className="button-container">
                    {edit ? (
                      <></>
                    ) : estabelecimentos.length === 1 ? (
                      <></>
                    ) : (
                      <Botao
                        ic
                        icon="icon-lx-plus"
                        onClick={() => {
                          this.setState({ estabelecimentosel: {} });
                          this.props.history.push(NEW_PATH);
                        }}
                      />
                    )}
                  </div>
                }
              />
              <List
                onClick={this.handleTableClick}
                cols={[
                  {
                    accessor: 'cnpj',
                    Header: 'CNPJ',
                    width: 200,
                    filterable: false,
                    sortMethod: sortInt
                  },
                  {
                    accessor: 'inscricaoEstadual',
                    Header: 'Inscrição Estadual',
                    width: 200,
                    filterable: false,
                    sortMethod: sortInt
                  },
                  {
                    accessor: 'razaoSocial',
                    Header: 'Razão Social',
                    width: 420,
                    filterable: false
                  },
                  {
                    accessor: 'inativo',
                    Header: 'Ativo',
                    width: 100,
                    filterable: false,
                    Cell: ({ row }) => {
                      return (
                        <Botao
                          secondary={row.inativo}
                          ic
                          icon={row.inativo ? 'icon-lx-close' : 'icon-lx-check'}
                        />
                      );
                    }
                  }
                ]}
                rows={this.state.estabelecimentos}
              />
            </LinxPostos>
          </>
        )}
      </>
    );
  }
}

class TreeCNAE extends React.Component {
  constructor(props) {
    super(props);

    const cnaesEmpresa = this.props.listaCNAE;

    this.state = { cnaesEmpresa };
  }

  carregarCNAEEmpresa = () => {
    let listaCNAEEmpresa = [];
    let cnaeEmpresaSessao = {};

    if (this.state.cnaesEmpresa)
      for (let i = 0; i < this.state.cnaesEmpresa.length; i++) {
        const cnaeItem = this.state.cnaesEmpresa[i];
        let cnaeEmpresa = {
          subclasse: '',
          classe: '',
          grupo: '',
          divisao: '',
          sessao: '',
          primario: '',
          id: '',
          cnaeCodigo: '',
          cnaeDescricao: ''
        };

        let listaCNAEResult = this.state.listaCNAE;

        let cnaeEmpresaSubclasse = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeItem.id)
        );
        cnaeEmpresa.subclasse =
          cnaeEmpresaSubclasse[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaSubclasse[0].descricao;

        let cnaeEmpresaClasse = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaSubclasse[0].idPai)
        );
        cnaeEmpresa.classe =
          cnaeEmpresaClasse[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaClasse[0].descricao;

        let cnaeEmpresaGrupo = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaClasse[0].idPai)
        );
        cnaeEmpresa.grupo =
          cnaeEmpresaGrupo[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaGrupo[0].descricao;

        let cnaeEmpresaDivisao = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaGrupo[0].idPai)
        );
        cnaeEmpresa.divisao =
          cnaeEmpresaDivisao[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaDivisao[0].descricao;

        cnaeEmpresaSessao = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaDivisao[0].idPai)
        );
        cnaeEmpresa.sessao =
          cnaeEmpresaSessao[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaSessao[0].descricao;

        cnaeEmpresa.primario = cnaeItem.primario;

        cnaeEmpresa.id = cnaeItem.id;
        cnaeEmpresa.cnaeCodigo = cnaeEmpresaSubclasse[0].id;
        cnaeEmpresa.cnaeDescricao = cnaeEmpresaSubclasse[0].descricao;

        listaCNAEEmpresa.push(cnaeEmpresa);

        this.setState({ listaCNAEEmpresa });
      }
  };

  incluirCNAEQuadro = () => {};

  tratarArvore = listaCNAE => {
    var arvore = [];
    let listaCNAERaizes =
      listaCNAE === null || listaCNAE === undefined
        ? []
        : listaCNAE.filter(lc => lc.idPai === 0);
    for (let i = 0; i < listaCNAERaizes.length; i++) {
      var itemArvore = {
        title: '',
        key: 0,
        children: []
      };
      const raiz = listaCNAERaizes[i];
      let listaCNAEGalhos =
        listaCNAE === null || listaCNAE === undefined
          ? []
          : listaCNAE.filter(lc => lc.idPai === raiz.id);
      for (let j = 0; j < listaCNAEGalhos.length; j++) {
        const galho = listaCNAEGalhos[j];
        var itemGalho = {
          title: galho.cnaeCodigo + ' - ' + galho.descricao,
          key: galho.id,
          children: []
        };
        itemArvore.children.push(this.tratarGalhos(itemGalho));
      }
      itemArvore.title = raiz.cnaeCodigo + ' - ' + raiz.descricao;
      itemArvore.key = raiz.id;

      arvore.push(itemArvore);
    }
    this.setState({ listaCNAETratada: arvore });
  };

  tratarGalhos = raiz => {
    var listaCNAEOriginal = this.state.listaCNAE;

    //verifica se o elemento tem filhos
    let listaCNAERaizes =
      listaCNAEOriginal === null || listaCNAEOriginal === undefined
        ? []
        : listaCNAEOriginal.filter(lc => lc.idPai === raiz.key);
    if (listaCNAERaizes.length > 0) {
      for (let k = 0; k < listaCNAERaizes.length; k++) {
        const galho = listaCNAERaizes[k];
        var itemGalho = {
          title: galho.cnaeCodigo + ' - ' + galho.descricao,
          key: galho.id,
          children: []
        };
        raiz.children.push(this.tratarGalhos(itemGalho));
      }
    }
    return raiz;
  };

  state = {
    selectedKeys: [],
    expandedKeys: [],
    cnaeSelect: ''
  };

  async componentDidMount() {
    this.getContainer();

    const { data: listaCNAEResponse } = await listarCNAEs();
    var listaCNAEResult = listaCNAEResponse.data;

    this.setState({ listaCNAE: listaCNAEResult });

    this.tratarArvore(this.state.listaCNAE);

    this.carregarCNAEEmpresa();
  }
  componentWillUnmount() {
    if (this.cmContainer) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      document.body.removeChild(this.cmContainer);
      this.cmContainer = null;
    }
  }
  getContainer() {
    if (!this.cmContainer) {
      this.cmContainer = document.createElement('div');
      document.body.appendChild(this.cmContainer);
    }
    return this.cmContainer;
  }
  onSelect = (
    selectedKeys,
    e /*:{selected: bool, selectedNodes, node, event, nativeEvent}*/
  ) => {
    let { expandedKeys } = this.state;

    const key = selectedKeys[0];

    var listaCNAEResult = this.state.listaCNAE;

    let listaCNAEGalhos =
      listaCNAEResult === null || listaCNAEResult === undefined
        ? []
        : listaCNAEResult.filter(lc => lc.idPai === parseInt(key));

    if (listaCNAEGalhos.length === 0) {
      var listaCNAEEmpresa =
        this.state.listaCNAEEmpresa !== undefined
          ? this.state.listaCNAEEmpresa
          : [];
      var cnaeEmpresaClasse = {};
      var cnaeEmpresaGrupo = {};
      var cnaeEmpresaDivisao = {};
      var cnaeEmpresaSessao = {};
      var cnaeEmpresaSubclasse = {};

      var cnaeExistente = false;

      for (let i = 0; i < listaCNAEEmpresa.length; i++) {
        const element = listaCNAEEmpresa[i];
        if (parseInt(element.id) === parseInt(key)) cnaeExistente = true;
      }

      if (!cnaeExistente) {
        let cnaeEmpresa = {
          subclasse: '',
          classe: '',
          grupo: '',
          divisao: '',
          sessao: '',
          primario: '',
          id: '',
          cnaeCodigo: '',
          cnaeDescricao: ''
        };

        cnaeEmpresaSubclasse = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(key)
        );
        cnaeEmpresa.subclasse =
          cnaeEmpresaSubclasse[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaSubclasse[0].descricao;

        cnaeEmpresaClasse = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaSubclasse[0].idPai)
        );
        cnaeEmpresa.classe =
          cnaeEmpresaClasse[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaClasse[0].descricao;

        cnaeEmpresaGrupo = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaClasse[0].idPai)
        );
        cnaeEmpresa.grupo =
          cnaeEmpresaGrupo[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaGrupo[0].descricao;

        cnaeEmpresaDivisao = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaGrupo[0].idPai)
        );
        cnaeEmpresa.divisao =
          cnaeEmpresaDivisao[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaDivisao[0].descricao;

        cnaeEmpresaSessao = listaCNAEResult.filter(
          c => parseInt(c.id) === parseInt(cnaeEmpresaDivisao[0].idPai)
        );
        cnaeEmpresa.sessao =
          cnaeEmpresaSessao[0].cnaeCodigo +
          ' - ' +
          cnaeEmpresaSessao[0].descricao;

        cnaeEmpresa.primario = false;

        cnaeEmpresa.id = key;
        cnaeEmpresa.cnaeCodigo = cnaeEmpresaSubclasse[0].id;
        cnaeEmpresa.cnaeDescricao = cnaeEmpresaSubclasse[0].descricao;

        listaCNAEEmpresa.push(cnaeEmpresa);

        this.setState({ listaCNAEEmpresa });

        this.props.handleCNAE(listaCNAEEmpresa);
      }
    }

    if (!expandedKeys) expandedKeys = [];

    if (expandedKeys.includes(key)) {
      this.setState({ expandedKeys: expandedKeys.filter(k => k !== key) });
    } else {
      this.setState({ expandedKeys: [...expandedKeys, key] });
    }
  };
  onExpand = (expandedKeys, e) => {
    this.setState({ expandedKeys });
  };

  newItem = () => {
    //console.log('new item');
  };

  changeItem = () => {
    //console.log('change item');
  };

  deleteItem = () => {
    //console.log('delete item');
  };

  renderCm(info) {
    if (this.toolTip) {
      ReactDOM.unmountComponentAtNode(this.cmContainer);
      this.toolTip = null;
    }
    this.toolTip = (
      <Tooltip
        trigger="click"
        placement="bottomRight"
        prefixCls="rc-tree-contextmenu"
        defaultVisible
        overlay={
          <ContextMenu
            info={info}
            newItem={this.newItem}
            changeItem={this.changeItem}
            deleteItem={this.deleteItem}
          />
        }
      >
        <span />
      </Tooltip>
    );

    const container = this.getContainer();
    Object.assign(this.cmContainer.style, {
      position: 'absolute',
      left: `${info.event.pageX}px`,
      top: `${info.event.pageY}px`
    });

    ReactDOM.render(this.toolTip, container);
  }

  handleSelectChange = e => {
    let keys = this.buscarCodigoCNAE(e.target.value);
    this.setState({ expandedKeys: keys });
  };

  eliminarCnae = e => {
    var listaCNAEAtual = this.state.listaCNAEEmpresa;
    var listaCNAENovo = [];
    var nome = e.currentTarget.className;

    for (let i = 0; i < listaCNAEAtual.length; i++) {
      const element = listaCNAEAtual[i];
      if (nome.indexOf(element.subclasse) === -1) listaCNAENovo.push(element);
    }

    this.setState({ listaCNAEEmpresa: listaCNAENovo });
    this.props.handleCNAE(listaCNAENovo);
  };

  handleCNAEClick = e => {
    var listaCNAEAtual = this.state.listaCNAEEmpresa;

    var nome = e.target.name;
    var checked = e.target.checked;

    for (let i = 0; i < listaCNAEAtual.length; i++) {
      const element = listaCNAEAtual[i];
      if (element.subclasse === nome)
        listaCNAEAtual[i].primario = !listaCNAEAtual[i].primario;
      else if (checked) listaCNAEAtual[i].primario = false;
    }

    this.setState({ listaCNAEEmpresa: listaCNAEAtual });
    this.props.handleCNAE(this.state.listaCNAEEmpresa);
  };

  render() {
    return (
      <>
        <div>
          <Tree
            defaultExpandParent
            showLine
            selectedKeys={[]}
            expandedKeys={this.state.expandedKeys}
            onExpand={this.onExpand}
            showIcon={false}
            onSelect={this.onSelect}
            treeData={this.state.listaCNAETratada}
          />
        </div>
        <div className="table-ctn">
          <ReactTable
            data={this.state.listaCNAEEmpresa}
            columns={[
              {
                accessor: 'primario',
                Header: 'Primário',
                width: 75,
                filterable: false,
                Cell: ({ row }) => {
                  return (
                    <Checkbox label="">
                      <Checkitem
                        label=""
                        name={row.subclasse}
                        checked={row.primario}
                        onChange={this.handleCNAEClick}
                      />
                    </Checkbox>
                  );
                }
              },
              {
                accessor: 'sessao',
                Header: 'Sessão',
                style: { 'white-space': 'unset' },
                width: 150,
                filterable: false
              },
              {
                accessor: 'divisao',
                Header: 'Divisão',
                style: { 'white-space': 'unset' },
                width: 150,
                filterable: false
              },
              {
                accessor: 'grupo',
                Header: 'Grupo',
                style: { 'white-space': 'unset' },
                width: 150,
                filterable: false
              },
              {
                accessor: 'classe',
                Header: 'Classe',
                style: { 'white-space': 'unset' },
                width: 150,
                filterable: false
              },
              {
                accessor: 'subclasse',
                Header: 'Subclasse',
                style: { 'white-space': 'unset' },
                width: 150,
                filterable: false
              },
              {
                accessor: 'acao',
                Header: 'Excluir',
                width: 75,
                filterable: false,
                Cell: ({ row }) => {
                  return (
                    <Botao
                      className={row.subclasse}
                      icon={'icon-lx-trash'}
                      onClick={this.eliminarCnae}
                    />
                  );
                }
              }
            ]}
            getTdProps={(state, rowInfo, column, instance) => {
              return {
                onClick: (e, handleOriginal) => {
                  if (this.props.onClick) {
                    this.props.onClick(state, rowInfo, column, instance, e);
                  }

                  if (handleOriginal) {
                    handleOriginal();
                  }
                }
              };
            }}
            previousText="Anterior"
            nextText="Próximo"
            loadingText="Carregando registros..."
            noDataText="Sem registros para exibir"
            pageText="Página"
            ofText="de"
            rowsText="registros"
          />
        </div>
      </>
    );
  }
}

class ContextMenu extends Component {
  render() {
    const {
      node,
      node: {
        props: { pos }
      }
    } = this.props.info;
    const { newItem, changeItem, deleteItem } = this.props;
    return (
      <div>
        {!node.isLeaf() && (
          <h4 onClick={() => newItem(node)}>Incluir sub-item</h4>
        )}
        {pos !== '0-0' && (
          <h4 onClick={() => changeItem(node)}>Alterar item</h4>
        )}
        {pos !== '0-0' && (
          <h4 onClick={() => deleteItem(node)}>Excluir item</h4>
        )}
      </div>
    );
  }
}

Empresas = withRouter(Empresas);
export { Empresas };
