import * as React from "react";

export class FileSelector extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectorFiles) {
    const { sender, name } = this.props;
    if (sender) sender.setState({ [name]: selectorFiles });
  }

  render() {
    const { accept = "*.*" } = this.props;

    return (
      <div>
        <input
          className="input-file-importacao"
          type="file"
          accept={accept}
          onChange={e => this.handleChange(e.target.files)}
        />
      </div>
    );
  }
}
