import React from 'react';
import styles from './BotaoCarregamento.module.scss'

export default function BotaoCarregamento(props) {
  return (
    <button
      className="btn btn-primary"
      onClick={props.click}
      disabled={props.disabled}
      style={{width: `${props.width}px`}}
    >
      <i className={props.icon}></i>
      {' '}
      {props.loading ? (
        <>
          <svg className={`${styles.spinner} Spinner`}viewBox="0 0 50 50">
            <circle
              className={styles.path}
              cx="25"
              cy="25"
              r="20"
              fill="none"
              strokeWidth="5"
            ></circle>
          </svg>
          {props.carregando}
        </>
      ) : (
        props.carregar
      )}
    </button>
  );
}
