import React, { useEffect, useState } from 'react';
import styles from './RequisicaoLGPDDetails.module.scss';
import { LGPDService } from '../RequisicaoLGPD.service';
import { withRouter } from 'react-router-dom';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { CheckBox, DataGrid, DropDownBox, RadioGroup, TextArea, TextBox } from 'devextreme-react';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina, isValidEmail, validaCPF } from '../../../../shared/utils/Utils';
import notify from 'devextreme/ui/notify';
import Footer from '../../../../components/core/footer/Footer';
import { confirm } from 'devextreme/ui/dialog';
import moment from 'moment';

import { Selection } from 'devextreme-react/data-grid';

export function RequisicaoLGPDDetails(props) {

    const PATH_MENU = menus.COD_80620;
    let validationErrorMessage = '';
    const [showSearch] = useState(false);
    const [searching, setSearching] = useState(false);
    const [edited, setEdited] = useState(false);
    const [concluida, setConcluida] = useState(false);
    const [model, setModel] = useState({})
    const [tipoFiltro, setTipoFiltro] = useState([]);
    const [tipoSolicitacao, setTipoSolicitacao] = useState([]);

    const [selectedTipoSolicitacao, setSelectedTipoSolicitacao] = useState([]);
    const [gridBoxTipoSolicitacaoValue, setGridBoxTipoSolicitacaoValue] = useState([]);

    const [loadedTipoSolicitacao, setLoadedTipoSolicitacao] = useState(false);

    const getTipoFiltro = async () => {
        const response = await LGPDService.getTipoFiltro();
        if (response.length) setTipoFiltro(response);
    };

    const getTipoSolicitacao = async () => {
        const response = await LGPDService.getTipoSolicitacao();
        if (response.length) setTipoSolicitacao(response);
        setLoadedTipoSolicitacao(true);
    };

    useEffect(() => {
        clearSyncLists();
        if (props.location.state) {

            let entity = {
                id: props.location.state.data.id,
                dataSolicitacao: props.location.state.data.dataSolicitacao,
                tipoSolicitacao: props.location.state.data.tipoSolicitacao,
                situacao: props.location.state.data.situacao,
                tipoRequerente: props.location.state.data.tipoRequerente === 0 ? 'Titular' : 'Terceiro',
                situacaoDescricao: props.location.state.data.situacaoDescricao,
                tipoRequerenteDescricao: props.location.state.data.tipoRequerenteDescricao,
                impresso: props.location.state.data.impresso,
                email: props.location.state.data.email,
                emailResposta: props.location.state.data.emailResposta,
                observacao: props.location.state.data.observacao,
                nomeRequerente: props.location.state.data.nomeRequerente,
                documentoRequerente: props.location.state.data.documentoRequerente,
                identificaoVerificada: props.location.state.data.identificaoVerificada,
                nomeTitular: props.location.state.data.nomeTitular,
                documentoTitular: props.location.state.data.documentoTitular
            }

            setModel(entity);
            setEdited(true);
        }
    }, [props]);

    useEffect(() => {
        getTipoFiltro();
        getTipoSolicitacao();

    }, []);

    useEffect(() => {
        setConcluida(model.situacao === 2 || model.situacao === 3)
    }, [model])

    useEffect(() => {
        if (loadedTipoSolicitacao === true) {
            loadEdit()
        }
    }, [loadedTipoSolicitacao])

    const loadEdit = () => {
        if (model && model.id > 0) {
            setSelectedTipoSolicitacao(props.location.state.data.tipoSolicitacao.id);
            setGridBoxTipoSolicitacaoValue([props.location.state.data.tipoSolicitacao]);
        }
    }

    const backToScreen = () => {
        props.history.push({ pathname: '/cadastro/requisicao-LGPD' });
    };

    const cancel = async () => {

        let result = await confirm(
            '<h1>Deseja realmente cancelar a operação?</h1>',
            'Requisição LGPD'
        );

        if (result) {
            backToScreen();
        }
    }

    const back = async () => {
        backToScreen();
    }

    const isValid = (edit) => {
        validationErrorMessage = '';

        if (!edit) {

            if (model.tipoRequerente === 1) {
                if (model.documentoRequerente.length === 0) {
                    validationErrorMessage = 'Deve ser informado o documento do Requerente.';
                    return false;
                }

                if (model.documentoRequerente.length === 0) {
                    validationErrorMessage = 'Deve ser informado o nome do Requerente.';
                    return false;
                }
            }

            if (model.documentoTitular.length === 0) {
                validationErrorMessage = 'Deve ser informado o CPF do Titular.';
                return false;
            } else {
                if (!validaCPF(model.documentoTitular)) {
                    validationErrorMessage = 'Deve ser informado um CPF válido para o Titular.';
                    return false;
                }
            }

            if (model.nomeTitular.length === 0) {
                validationErrorMessage = 'Deve ser informado o nome do Titular.';
                return false;
            }

            if (selectedTipoSolicitacao.length === 0) {
                validationErrorMessage = 'Deve ser informado o tipo de requisição.';
                return false;
            }
        }

        if (model.email) {
            if (model.emailResposta.length > 0) {
                if (!isValidEmail(model.emailResposta)) {
                    validationErrorMessage = `O e-mail ${model.emailResposta} não é válido.`;
                    return false;
                }
            } else {
                validationErrorMessage = `Deve ser informado o email para resposta.`;
                return false;
            }
        }

        return true;
    }

    const add = async () => {

        let entity = {
            tipoSolicitacao: { id: selectedTipoSolicitacao },
            impresso: model.impresso,
            email: model.email,
            emailResposta: model.email ? model.emailResposta : '',
            observacao: model.observacao,
            nomeRequerente: model.nomeRequerente,
            documentoRequerente: model.documentoRequerente,
            identificaoVerificada: model.identificaoVerificada,
            nomeTitular: model.nomeTitular,
            documentoTitular: model.documentoTitular,
            tipoRequerente: model.tipoRequerente === 'Titular' ? 0 : 1
        };

        const response = await LGPDService.add(entity);
        if (response.notificationLevel === 1) {
            entity.id = response.data;
            entity.tipoRequerente = model.tipoRequerente;
            setModel(entity);
            setEdited(true);
        } else {
            notify(response.message, 'error', 2000, 'top');
            setSearching(false);
        }
    }

    const edit = async () => {

        let entity = {
            id: model.id,
            impresso: model.impresso,
            email: model.email,
            emailResposta: model.email ? model.emailResposta : '',
            observacao: model.observacao
        };

        const response = await LGPDService.edit(entity);
        if (response.notificationLevel === 1) {
            if (response.data) {
                notify('Requisição alterada com sucesso.', 'info', 2000, 'top');
            } else {
                notify(response.message, 'error', 2000, 'top');
                setSearching(false);
            }
        } else {
            notify(response.message, 'error', 2000, 'top');
            setSearching(false);
        }
    }

    const save = async () => {

        setSearching(true);

        if (model.id && model.id > 0) {
            if (!isValid(true)) {
                notify(validationErrorMessage, 'error', 2000, 'top');
                setSearching(false);
                return;
            }

            await edit();
        } else {

            if (!isValid()) {
                notify(validationErrorMessage, 'error', 2000, 'top');
                setSearching(false);
                return;
            }

            await add();
        }

        setSearching(false);
    }

    const anonimizar = async () => {

        let result = await confirm(
            'Ao anonimizar uma pessoa, ela:</br>' +
            '- Não poderá mais efetuar compras no PDV com pagamento a prazo.</br>' +
            '- Não poderá mais ter NF-e emitida sem que seus dados sejam coletados novamente.</br></br>' +
            'Os dados registrados em documentos fiscais não serão eliminados, devido ao legitimo interesse para atendimento de legislação.</br>' +
            'As informações não serão mais identificadas e essa operação não poderá ser revertida.</br>' +
            `Deseja realmente anonimizar a pessoa ${model.nomeTitular.toUpperCase()}?`,
            'Requisição LGPD'
        );

        if (!result) {
            return;
        }

        setSearching(true);
        const response = await LGPDService.anonimizar(model.id);
        if (response.notificationLevel === 1) {
            const resposta = response.data;
            setModel(lists => {
                return {
                    ...lists,
                    situacao: resposta.situacao,
                    situacaoDescricao: resposta.situacaoDescricao,
                }
            });
        } else {
            notify(response.message, 'error', 2000, 'top');
        }
        setSearching(false);
    }

    const eliminarDados = async () => {

        let result = await confirm(
            'Ao eliminar os dados de uma pessoa, caso seja necessário realizar operações com a identificação, será necessário refazer o cadastro.</br>' +
            'Os dados registrados em documentos fiscais não serão eliminados, devido ao legitimo interesse para atendimento de legislação.</br>' +
            `Deseja realmente eliminar os dados da pessoa ${model.nomeTitular.toUpperCase()}?`,
            'Requisição LGPD'
        );

        if (!result) {
            return;
        }

        setSearching(true);
        const response = await LGPDService.eliminarDados(model.id);
        if (response.notificationLevel === 1) {
            const resposta = response.data;
            setModel(lists => {
                return {
                    ...lists,
                    situacao: resposta.situacao,
                    situacaoDescricao: resposta.situacaoDescricao,
                }
            });
        } else {
            notify(response.message, 'error', 2000, 'top');
        }
        setSearching(false);
    }

    const print = async () => {

        try {
            const response = await LGPDService.Relatorio(model.id);
            window.open(response);
        } catch (e) {
            notify('Erro ao solicitar a impressão do uso de dados. ' + e.message, 'error', 5000);
        }
    }

    const concluir = async () => {
        let result = await confirm(
            'Deseja realmente concluir a requisição?',
            'Requisição LGPD'
        );

        if (!result) {
            return;
        }

        setSearching(true);
        const response = await LGPDService.concluir(model.id);
        if (response.notificationLevel === 1) {
            const resposta = response.data;
            setModel(lists => {
                return {
                    ...lists,
                    situacao: resposta.situacao,
                    situacaoDescricao: resposta.situacaoDescricao,
                }
            });
        } else {
            notify(response.message, 'error', 2000, 'top');
        }
        setSearching(false);

    }

    const remover = async () => {
        let result = await confirm(
            'Deseja realmente excluír a requisição?',
            'Requisição LGPD'
        );

        if (!result) {
            return;
        }

        setSearching(true);
        const response = await LGPDService.deletar(model.id);
        if (response.notificationLevel === 1) {
            const resposta = response.data;
            setModel(lists => {
                return {
                    ...lists,
                    situacao: resposta.situacao,
                    situacaoDescricao: resposta.situacaoDescricao,
                }
            });
        } else {
            notify(response.message, 'error', 2000, 'top');
        }
        setSearching(false);

    }

    const clearSyncLists = () => {
        let retorno = {};
        for (let prop in LGPDService.getModel()) {
            retorno[prop] = LGPDService.getModel()[prop];
        }

        setModel(retorno);
    };

    const syncSelection = (e, prop) => {
        setModel(lists => {
            return {
                ...lists,
                [prop]: e || LGPDService.getModel()[prop]
            }
        });
    };

    const onSyncTipoSelection = (e) => {
        setModel(lists => {
            return {
                ...lists,
                tipoRequerente: e
            }
        });
    };

    const syncDataGridTipoSolicitacaoSelection = (e) => {
        setGridBoxTipoSolicitacaoValue(e.value || []);
    };

    const dataGridTipoSolicitacao_onSelectionChanged = (e) => {
        setGridBoxTipoSolicitacaoValue((e.selectedRowKeys.length && e.selectedRowKeys) || []);
        setSelectedTipoSolicitacao(e.selectedRowKeys[0].id);
    };

    const dataGridTipoSolicitacaoRender = () => {
        return (
            <DataGrid
                dataSource={tipoSolicitacao}
                columns={[{ dataField: 'descricao', caption: 'Descrição' }]}
                hoverStateEnabled={true}
                showBorders={true}
                showSearch={true}
                height={180}
                selectedRowKeys={gridBoxTipoSolicitacaoValue}
                onSelectionChanged={dataGridTipoSolicitacao_onSelectionChanged}>
                <Selection mode="single" />
            </DataGrid>
        );
    };

    const renderFoot = () => {

        //Tipo
        //1	Acesso a dados
        //2	Anonimização de dados
        //4	Correção de dados
        //5	Eliminação de dados

        //Situacao
        //0 Pendente
        //1 Aguardando Conclusão
        //2 Concluída
        //3 Excluido

        if (model && model.id && model.id > 0) {
            if (model.tipoSolicitacao.id === 2) {
                if (model.situacao === 1) {
                    return (<Footer returnAction={back} printAction={print} concluirAction={concluir} />);
                } else if (model.situacao === 2) {
                    return (<Footer returnAction={back} printAction={print} />);
                } else if (model.situacao === 3) {
                    return (<Footer returnAction={back} />);
                } else {
                    return (<Footer saveAction={save} deleteAction={remover} returnAction={back} anonimizarAction={anonimizar} />);
                }
            } else if (model.tipoSolicitacao.id === 1) {
                if (model.situacao === 1) {
                    return (<Footer returnAction={back} printAction={print} concluirAction={concluir} />);
                } else if (model.situacao === 2) {
                    return (<Footer returnAction={back} printAction={print} />);
                } else if (model.situacao === 3) {
                    return (<Footer returnAction={back} />);
                } else {
                    return (<Footer saveAction={save} deleteAction={remover} returnAction={back} printAction={print} concluirAction={concluir} />);
                }
            } else if (model.tipoSolicitacao.id === 4) {
                if (model.situacao === 1) {
                    return (<Footer returnAction={back} printAction={print} concluirAction={concluir} />);
                } else if (model.situacao === 2) {
                    return (<Footer returnAction={back} printAction={print} />);
                } else if (model.situacao === 3) {
                    return (<Footer returnAction={back} />);
                } else {
                    return (<Footer saveAction={save} deleteAction={remover} returnAction={back} concluirAction={concluir} />);
                }
            }
            else if (model.tipoSolicitacao.id === 5) {
                if (model.situacao === 1) {
                    return (<Footer returnAction={back} printAction={print} concluirAction={concluir} />);
                } else if (model.situacao === 2) {
                    return (<Footer returnAction={back} printAction={print} />);
                } else if (model.situacao === 3) {
                    return (<Footer returnAction={back} />);
                } else {
                    return (<Footer saveAction={save} deleteAction={remover} returnAction={back} eliminarAction={eliminarDados} />);
                }
            } else {
                return (<Footer saveAction={save} returnAction={back} />);
            }
        } else {
            return (<Footer saveAction={save} cancelAction={cancel} />);
        }
    }

    return (

        <LinxPostos breadcrumb={getNomePagina(PATH_MENU, 'Detalhes')}>
            <div className={`${styles.lgpdDetails} lgpdDetails mt-3`}>
                <div className="shadow-sm bg-white p-3">
                    <div className={`${showSearch ? styles.search : ''}`}>
                        <div className="container-fluid">
                            <div className={`${styles.filtersrWrapper} row`}>
                                <div className="col-md-12">
                                    <h2>CADASTRO DE REQUISIÇÃO LGPD (Lei Geral de Proteção de Dados)</h2>
                                    <hr />

                                    {model.situacao === 2 ?
                                        <div className={`${styles.concluido}`}>
                                            <span>Concluída em: {moment(model.dataConclusao).format('DD/MM/YYYY')}</span>
                                        </div> : null}

                                    <div className={`${showSearch ? styles.fadeIn : ''} ${styles.searchWrapper} searchWrapper`}>

                                        <div className="row">
                                            <div className={`${styles.subTitle} subTitle col-md-12 form-group mb-1`}>
                                                <h1>Identificação do Requerente</h1>
                                                <span>Informe os dados do responsável pela requisição</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`${styles.radioBox} col-md-12 form-group mb-2`}>
                                                <span>Tipo de Requerente</span>
                                                <RadioGroup
                                                    className={styles.radioGroup}
                                                    items={tipoFiltro}
                                                    defaultValue={'Titular'}
                                                    layout="horizontal"
                                                    width={300}
                                                    value={model.tipoRequerente}
                                                    disabled={edited || searching}
                                                    onValueChanged={e => onSyncTipoSelection(e.value)} />
                                            </div>

                                            {
                                                model.tipoRequerente === 1 || model.tipoRequerente === 'Terceiro' ?
                                                    <>
                                                        <div className={`${styles.input} col-md-4 form-group mb-3`}>
                                                            <span>Documento do Requerente</span>
                                                            <TextBox placeholder="Informe o documento do Requerente"
                                                                showClearButton={true}
                                                                onValueChanged={(e) => syncSelection(e.value, 'documentoRequerente')}
                                                                value={model.documentoRequerente}
                                                                maxLength={20}
                                                                disabled={edited || searching} />
                                                        </div>
                                                        <div className={`${styles.input} col-md-8 form-group mb-3`}>
                                                            <span>Nome do Requerente</span>
                                                            <TextBox placeholder="Informe o nome do Requerente"
                                                                showClearButton={true}
                                                                onValueChanged={(e) => syncSelection(e.value, 'nomeRequerente')}
                                                                value={model.nomeRequerente}
                                                                maxLength={100}
                                                                disabled={edited || searching} />
                                                        </div>
                                                    </>
                                                    : null
                                            }

                                            <div className={`${styles.input} col-md-4 form-group mb-3`}>
                                                <span>CPF do Titular</span>
                                                <TextBox placeholder="Informe o CPF do Titular"
                                                    showClearButton={true}
                                                    onValueChanged={(e) => syncSelection(e.value, 'documentoTitular')}
                                                    value={model.documentoTitular}
                                                    mask="000.000.000-00"
                                                    maxLength={100}
                                                    disabled={edited || searching} />
                                            </div>

                                            <div className={`${styles.input} col-md-8 form-group mb-3`}>
                                                <span>Nome do Titular</span>
                                                <TextBox placeholder="Informe o nome do Titular"
                                                    showClearButton={true}
                                                    onValueChanged={(e) => syncSelection(e.value, 'nomeTitular')}
                                                    value={model.nomeTitular}
                                                    maxLength={100}
                                                    disabled={edited || searching} />
                                            </div>

                                            <div className={`${styles.input} col-md-12 form-group mb-4`}>
                                                <CheckBox
                                                    value={model.identificaoVerificada}
                                                    disabled={edited || searching}
                                                    onValueChanged={e => syncSelection(e.value, 'identificaoVerificada')}
                                                    text="Identidade Verificada" />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className={`${styles.subTitle} subTitle col-md-12 form-group mb-1`}>
                                                <h1>Informações da Requisição</h1>
                                                <span>Informe os detalhes da requisição</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className={`${styles.input} col-md-2 form-group mb-5`}>
                                                <span>Data</span>
                                                <h2>{moment(model.dataSolicitacao).format('DD/MM/YYYY')}</h2>
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-4 form-group mb-5`}>
                                                <span>Tipo de Requisição</span>
                                                <DropDownBox
                                                    value={gridBoxTipoSolicitacaoValue.map(item => item.descricao)}
                                                    valueExpr="id"
                                                    deferRendering={false}
                                                    displayExpr="descricao"
                                                    placeholder="Selecione..."
                                                    disabled={edited || searching}
                                                    dataSource={tipoSolicitacao}
                                                    onValueChanged={syncDataGridTipoSolicitacaoSelection}
                                                    contentRender={dataGridTipoSolicitacaoRender} />
                                            </div>
                                            <div className={`${styles.dropDownBox} col-md-2 form-group mb-5`}>
                                                <span>Forma de Resposta</span>
                                                <div className={styles.divChecks}>
                                                    <CheckBox
                                                        value={model.impresso}
                                                        onValueChanged={e => syncSelection(e.value, 'impresso')}
                                                        text="Impresso"
                                                        disabled={concluida}
                                                        width={90} />
                                                    <CheckBox
                                                        value={model.email}
                                                        disabled={concluida}
                                                        onValueChanged={e => syncSelection(e.value, 'email')}
                                                        text="Email" />
                                                </div>

                                            </div>
                                            {
                                                model.email ?
                                                    <div className={`${styles.input} col-md-4 form-group mb-5`}>
                                                        <span>Email de Resposta</span>
                                                        <TextBox placeholder="Informe o Email"
                                                            showClearButton={true}
                                                            disabled={concluida}
                                                            onValueChanged={(e) => syncSelection(e.value, 'emailResposta')}
                                                            value={model.emailResposta}
                                                            maxLength={150} />
                                                    </div>
                                                    : null
                                            }
                                            <div className={`${styles.dropDownBox} col-md-12 form-group mb-6`}>
                                                <span>Observações</span>
                                                <TextArea showClearButton={true}
                                                    onValueChanged={(e) => syncSelection(e.value, 'observacao')}
                                                    value={model.observacao}
                                                    maxLength={500}
                                                    height={80}
                                                    disabled={searching || concluida} />
                                            </div>
                                        </div>
                                        {renderFoot()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LinxPostos >
    );
}

export default withRouter(RequisicaoLGPDDetails);