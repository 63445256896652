import axios from 'axios';

import { getUrl, getCnpj } from '../../../shared/utils/Utils';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function getMedicoes(dataEscolhida) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/Estoque/Medicao/Listar`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      data: dataEscolhida
    }
  });
}

export function salvaMedicoes(medicoes) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/Estoque/Medicao/Salvar`, {
    cnpjEstabelecimento: getCnpj(),
    medicoes
  });
}

export function excluirMedicao(
  codigoTanque,
  codigoProduto,
  codigoAlmoxarifado,
  dataMedicao
) {
  axios.defaults.baseURL = getUrl();
  var cnpjEstabelecimento = getCnpj();
  return axios.delete(`LxApi/v2/Estoque/Medicao/Excluir`, {
    params: {
      cnpjEstabelecimento,
      codigoTanque,
      codigoProduto,
      codigoAlmoxarifado,
      dataMedicao
    }
  });
}
