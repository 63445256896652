import axios from 'axios';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { getCnpj, getUrl } from '../../../shared/utils/Utils';

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function listarConferencias(consulta) {
  consulta.cnpjEstabelecimento = getCnpj();

  /*
      periodoDe
	  periodoAte
	  operadores
	  situacoes
	  maquinas
	  clientes
      documentoFiscal
    */

  return axios.post(`LxApi/v1/ConferenciaTurno/ListarConferencias`, {
    query: consulta
  });
}

export function listarTurnos(dia) {
  /*
      periodoDe
	  periodoAte
	  operadores
	  situacoes
	  maquinas
	  clientes
      documentoFiscal
      dia
    */

  return axios.post(`LxApi/v1/ConferenciaTurno/ListarTurnos`, {
    query: {
      dia,
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function listarFormasPagamento(codigoTurno) {
  /*
      periodoDe
	  periodoAte
	  operadores
	  situacoes
	  maquinas
	  clientes
      documentoFiscal
      dia
      codigoTurno
    */

  return axios.post(`LxApi/v1/ConferenciaTurno/ListarFormasPagamento`, {
    query: {
      codigoTurno,
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function listarDocumentosFiscais(consulta) {
  consulta.cnpjEstabelecimento = getCnpj();

  /*
      periodoDe
	  periodoAte
	  operadores
	  situacoes
	  maquinas
	  clientes
      documentoFiscal
      dia
      codigoTurno
      formaPagamento
    */

  return axios.post(`LxApi/v1/ConferenciaTurno/ListarDocumentosFiscais`, {
    query: consulta
  });
}

export function salvarTurno(turnos) {
  return axios.put(`LxApi/v1/ConferenciaTurno/Salvar`, {
    cnpjEstabelecimento: getCnpj(),
    turnos
  });
}

export function conferir(turnos) {
  return axios.post(`LxApi/v1/ConferenciaTurno/Conferir`, {
    cnpjEstabelecimento: getCnpj(),
    turnos
  });
}

export function reabrirTurno(codigoTurno) {
  var codigoUsuario = 1;
  return axios.put(
    `LxApi/v1/ConferenciaTurno/Reabrir?cnpjEstabelecimento=${getCnpj()}&codigoTurno=${codigoTurno}&codigoUsuario=${codigoUsuario}`,
    {}
  );
}

export function montaComboSituacao() {
  return axios.get(`LxApi/v1/ConferenciaTurno/MontarComboSituacao`, {});
}

export function montaComboFormasPagamento() {
  return axios.get(`LxApi/v2/FormasPagamento/MontarCombo`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      tipoFormaPgto: false,
      financeiro: false
    }
  });
}

export function montaComboTiposFormasPgto(financeiro, pdv) {
  return axios.get(`LxApi/v3/FormasPagamento/MontarCombo`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      tipoFormaPgto: true,
      financeiro,
      pdv
    }
  });
}

export function montaComboOperadores(periodoDe, periodoAte) {
  return axios.get(`LxApi/v1/ConferenciaTurno/MontarComboOperador`, {
    params: { periodoDe, periodoAte, cnpjEstabelecimento: getCnpj() }
  });
}

export function montaComboMaquinas(periodoDe, periodoAte) {
  return axios.get(`LxApi/v1/ConferenciaTurno/MontarComboMaquinas`, {
    params: { periodoDe, periodoAte, cnpjEstabelecimento: getCnpj() }
  });
}

export function getClientesConferencia(search) {
  return axios.post(`LxApi/v1/Pessoa/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      classificacao: [1],
      tipo: [1],
      somenteAtivos: false,
      search
    }
  });
}

export function selecionarFormaPgto(codigoEcf, codigoVenda, codigoMovimento) {
  return axios.post(`LxApi/v1/FormaPagamento/Selecionar`, {
    cnpjEstabelecimento: getCnpj(),
    codigoEcf,
    codigoVenda,
    codigoMovimento
  });
}

export function salvarFormaPgto(
  codigoEcf,
  codigoVenda,
  codigoMovimento,
  operador,
  formaPagamentos,
  cliente,
  data,
  faturado = false,
  quitado = false,
  turno,
  caixaAgrupado
) {
  return axios.post(`LxApi/v1/FormaPagamento/Salvar`, {
    cnpjEstabelecimento: getCnpj(),
    codigoEcf,
    codigoVenda,
    operador,
    codigoMovimento,
    data,
    formaPagamentos,
    cliente,
    faturado,
    quitado,
    turno,
    caixaAgrupado
  });
}
