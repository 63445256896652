import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styles from './Header.module.scss';
import ModalTrocaSenha from '../../modal/ModalTrocaSenha';
import HelpOptions from './HelpOptions/HelpOptions';
import PerfilOptions from './PerfilOptions/PerfilOptions';
import DicaLinxOptions from './DicaLinxOptions/DicaLinxOptions';
import Lais from './Lais/Lais';
//import logo from '../../../../assets/images/linx/Logo.png';
import logoSeller from '../../../../assets/images/linx/Logo_Linx Seller_positivo.png';
import logoEmporio from '../../../../assets/images/linx/emporio-dark.png';
import EmpresaOptions from './EmpresaOptions/EmpresaOptions';
import SelecionaEmpresa from './SelecionaEmpresa/SelecionaEmpresa';
import {
  getCnpj,
  getDadosLogin,
  getSystemInfoByTipoSite,
  updateSelectedEmpresa,
  updateTema,
  menuFavoritoTratar,
  getCodigoUsuario,
  menuFavoritoRecarregar,
} from '../../../shared/utils/Utils';
import { TIPO_SITE } from '../../../shared/constants/TipoSiteConstant';
import { getMontagemMenuFavoritos, salvaFavorito } from '../../../services/MenuPermissao.service';

let logoSite;
let widthLogo;
let heightLogo;
if (TIPO_SITE === 'RetaguardaSellerWeb') {
  logoSite = logoSeller;
  widthLogo = 170;
  heightLogo = 40;
} else {
  logoSite = logoEmporio;
  widthLogo = 120;
  heightLogo = 20;
}

export default function Header(props) {
  const [showProfileOptions, setShowProfileOptions] = useState(false);
  const [showDicaLinxOptions, setShowDicaLinxOptions] = useState(false);
  const [showMobileOptions, setShowMobileOptions] = useState(false);
  const [user, setUser] = useState('');
  const [showModalTrocaSenha, setShowModalTrocaSenha] = useState(false);
  const [showModalSelecionaEmpresa, setShowModalSelecionaEmpresa] = useState(false);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const systemInfo = getSystemInfoByTipoSite();
  const history = useHistory();
  const [Favoritado, setFavoritado] = useState(props.breadcrumb.ehFavorito);

  useEffect(() => {
    const dadosLogin = getDadosLogin();
    
    const empresa = dadosLogin.empresas.find(x => x.cnpj === getCnpj());
    const userName = dadosLogin.nome;

    setNomeEmpresa(empresa.nomeFantasia);
    setUser(userName);
  }, []);

  useEffect(() => {
    const dadosLogin = getDadosLogin();
    const empresa = dadosLogin.empresas.find(x => x.cnpj === getCnpj());

    setNomeEmpresa(empresa.nomeFantasia);

    updateTema();
  }, [showModalSelecionaEmpresa]);

  const toggleProfileOptions = () => {
    setShowProfileOptions(!showProfileOptions);
  };

  const toggleDicaLinxOptions = () => {
    setShowDicaLinxOptions(!showDicaLinxOptions);
  };

  const toggleFavoritado = () => {
    let ehFavorito = !Favoritado;
    setFavoritado(ehFavorito);
    props.breadcrumb.ehFavorito = ehFavorito; //apenas para teste visual

    //Adiciona/remove o menu na lista de menus guardados no sessionStorage
    let codigoMenu = menuFavoritoTratar(props.breadcrumb.caminhoMenu, props.breadcrumb.ehFavorito);

    //Chama a API do SellerWeb para adicionar/remover registro no banco
    salvaFavorito(getCodigoUsuario(), codigoMenu, ehFavorito);

    //Busca a lista atualizada apenas do Menu Favoritos para o usuário logado
    getMontagemMenuFavoritos(getCodigoUsuario())
      .then(({ data: respPermissoesFavoritos }) => {
        //Grava no sessionStorage com o objeto atualizado (merge do Menu Favoritos com os demais Menus)
        menuFavoritoRecarregar(respPermissoesFavoritos);
        //Dispara evento que fai fazer o refresh do Menu Favoritos (useEffect --> getMenus)
        props.setFavoriteCount(count => count + 1);
      })
      .catch(erro => {
        console.error('Ocorreu um erro:', erro);
      });

    /* Manter bloco comentado.
    //Se getMontagemMenuFavoritos apresentar algum problema, trocar para getMontagemMenu que remonta todos os menus (mais lento!)
    //Busca a lista atualizada de todos os menus para o usuário logado
    getMontagemMenu(getCodigoUsuario())
      .then(({ data: respPermissoes }) => {
        //Grava no sessionStorage com o objeto atualizado
        setPermissoesMenu(respPermissoes);
        //Dispara evento que fai fazer o refresh do Menu Favoritos (useEffect --> getMenus)
        props.setFavoriteCount(count => count + 1);
      })
      .catch(erro => {
        console.error('Ocorreu um erro:', erro);
      });
    */
  };

  const renderBreadcumb = () => {
    if (props.breadcrumb) {
      let caminhoSeparado = '';
      if (props.breadcrumb.caminhoSeparado) caminhoSeparado = props.breadcrumb.caminhoSeparado;
      else caminhoSeparado = props.breadcrumb;

      if (caminhoSeparado.length > 0) {
        return (
          <ul>
            {caminhoSeparado.split(' ').length > 1 ? (
              <li>
                <span>
                  {caminhoSeparado.split('-').length && <i className={caminhoSeparado.split(' ')[0]}></i>}
                  <>{caminhoSeparado.split(' ')[1].replace(/#/g, ' ')}</>
                </span>
              </li>
            ) : (
              <li>
                <span>
                  <i></i>
                  <>{caminhoSeparado}</>
                </span>
              </li>
            )}

            {caminhoSeparado
              .split(' ')
              .splice(2)
              .map((breadcrumb, index) => (
                <li key={index}>
                  <span>
                    <i></i>
                    {breadcrumb.replace(/#/g, ' ')}
                  </span>
                </li>
              ))}

            {caminhoSeparado !== 'Início' && props.existeMenuFavoritos && (
              <li>
                <span onClick={toggleFavoritado} title={`${props.breadcrumb.ehFavorito ? 'É favorito' : 'Não é favorito'}`}>
                  <i className={`${styles.icon} icon ${props.breadcrumb.ehFavorito ? 'icon-lx-star-2' : 'icon-lx-star'} text-dark`}></i>
                </span>
              </li>
            )}
          </ul>
        );
      } else {
        return null;
      }
    } else {
      return (
        <span className="text-danger">
          Breadcrumb não infomado, utilize o métododo getNomePagina encontrado no Utils.js
        </span>
      )
    };
  };

  const toggleMobileOptions = () => {
    setShowMobileOptions(!showMobileOptions);
  };

  const trocaSenhaHandle = () => {
    if (!showModalTrocaSenha) {
      setShowModalTrocaSenha(true);
    }
  };

  const selectEmpresaHandle = empresa => {
    let currentCnpj = getCnpj();
    updateSelectedEmpresa(empresa);
    handleCloseModalSelecionaEmpresa();
    if (currentCnpj !== empresa.cnpj) history.push('/');
  };

  const selecionaEmpresaHandle = () => {
    if (!showModalSelecionaEmpresa) {
      setShowModalSelecionaEmpresa(true);
    }
  };

  const handleCloseModalTrocaSenha = () => {
    setShowModalTrocaSenha(false);
  };

  const handleCloseModalSelecionaEmpresa = () => {
    setShowModalSelecionaEmpresa(false);
  };

  return (
    <>
      <header
        className={`${styles.header} ${TIPO_SITE === 'RetaguardaSellerWeb' ? styles.topnavMenu : styles.sidenavMenu} header navbar shadow-sm fixed-top navbar-light justify-content-start`}
      >
        <ul
          className={`${styles.headerWrapper} headerWrapper ${styles.txtBrand} mr-auto`}
        >
          <li
            className={`${styles.navItem} d-none d-sm-block`}
          >
            {<div><a href="/"><img src={logoSite} alt="Logotipo Linx" width={widthLogo} height={heightLogo} /></a>&nbsp;</div>}
          </li>
          <Link to="/">
            <li className={styles.navItem}>
              <span className={`${styles.organization} pl-0 text-uppercase`}>
                {systemInfo.nome}
              </span>
            </li>
          </Link>

          <li
            className={`${styles.navItem} ${styles.breadCrumbWrapper} d-none d-sm-block`}
          >
            {renderBreadcumb()}
          </li>
        </ul>

        <ul className={`${styles.headerWrapper} ${styles.textActions}`}>
          <EmpresaOptions
            nome={nomeEmpresa}
            selecionaEmpresa={selecionaEmpresaHandle}
          />

          <PerfilOptions
            toggleProfileOptions={toggleProfileOptions}
            showProfileOptions={showProfileOptions}
            user={user}
            trocaSenhaHandle={trocaSenhaHandle}
            history={history}
          />

          <DicaLinxOptions
            toggleDicaLinxOptions={toggleDicaLinxOptions}
            showDicaLinxOptions={showDicaLinxOptions}
            urlLinxShare={getDadosLogin().share}
          />

          <HelpOptions />

          <Lais show={systemInfo.showLais} onClick={props.toggleLais} />

          <li
            className={`${styles.navItem} navItem dropdown d-block d-sm-none`}
          >
            <span
              className="nav-link pt-0 pb-0 lx-icon dropdown-toggle"
              onClick={toggleMobileOptions}
            >
              <i className={`${styles.mobileMenu} icon icon-lx-ellipsis`}></i>
            </span>
            {showMobileOptions && (
              <div className="show dropdown-menu dropdown-menu-right">
                <span
                  className={`${styles.mobileMenu} dropdown-item text-uppercase`}
                >
                  Ajuda
                </span>
                <span
                  className={`${styles.mobileMenu} dropdown-item text-uppercase`}
                >
                  Perfil
                </span>
                <div className={`${styles.mobileMenu} dropdown-divider`}></div>
                <Link
                  className={`${styles.mobileMenu} dropdown-item text-uppercase`}
                  to="/login"
                >
                  Sair
                </Link>
              </div>
            )}
            {showMobileOptions && (
              <div
                className={styles.dropdownOverlay}
                onClick={toggleMobileOptions}
              ></div>
            )}
          </li>
        </ul>
      </header>
      {showModalTrocaSenha && (
        <ModalTrocaSenha
          active={showModalTrocaSenha}
          handleCloseModal={handleCloseModalTrocaSenha}
          user={user}
        />
      )}

      {showModalSelecionaEmpresa && (
        <SelecionaEmpresa
          active={showModalSelecionaEmpresa}
          handleCloseModal={handleCloseModalSelecionaEmpresa}
          selectEmpresaHandle={selectEmpresaHandle}
        />
      )}
    </>
  );
}
