import axios from "axios";

import { TOKEN_PASS_AXIOS } from "../../../shared/constants/Const";
import { getUrl, getCnpj } from "../../../shared/utils/Utils";

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common["TokenPass"] = TOKEN_PASS_AXIOS;

export function getEstruturasCartoes(
  codigoRede,
  codigoBandeira,
  codigoProdutoRede,
  codigoFormaPagamento,
  codigoCentroMonetario,
  inicioVigencia,
  fimVigencia
) {
  return axios.post(`LxApi/v1/EstruturaCartao/Listar`, {
    query: {
      codigoRede,
      codigoBandeira,
      codigoProdutoRede,
      codigoFormaPagamento,
      codigoCentroMonetario,
      inicioVigenciaDe: inicioVigencia,
      inicioVigenciaAte: fimVigencia,
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function excluiEstruturaCartao(codigo) {
  return axios.delete(`LxApi/v1/EstruturaCartao/Excluir`, {
    params: { codigo }
  });
}

export function incluiEstruturasCartoes(
  codigoRede,
  codigoBandeira,
  codigoFormaPagamento,
  codigoProdutoRede,
  codigoCentroMonetario,
  codigoAdquirente,
  taxa,
  diasResgate,
  vigencia
) {
  return axios.post(`LxApi/v1/EstruturaCartao/Incluir`, {
    rede: { codigo: codigoRede },
    bandeira: { codigo: codigoBandeira },
    formaPagamento: { codigo: codigoFormaPagamento },
    produtoRede: { codigo: codigoProdutoRede },
    centroMonetario: { codigo: codigoCentroMonetario },
    adquirente: { codigo: codigoAdquirente },
    taxa,
    diasResgate,
    vigencia,
    cnpjEstabelecimento: getCnpj()
  });
}

export function alteraEstruturasCartoes(
  codigo,
  codigoRede,
  codigoBandeira,
  codigoFormaPagamento,
  codigoProdutoRede,
  codigoCentroMonetario,
  codigoAdquirente,
  taxa,
  diasResgate,
  vigencia,
  rowVersion
) {
  return axios.put(`LxApi/v1/EstruturaCartao/Alterar`, {
    codigo,
    rede: { codigo: codigoRede },
    bandeira: { codigo: codigoBandeira },
    formaPagamento: { codigo: codigoFormaPagamento },
    produtoRede: { codigo: codigoProdutoRede },
    centroMonetario: { codigo: codigoCentroMonetario },
    adquirente: { codigo: codigoAdquirente },
    taxa,
    diasResgate,
    vigencia,
    cnpjEstabelecimento: getCnpj(),
    rowVersion
  });
}

export function montaComboRede(somenteAtivas) {
  return axios.get(`LxApi/v1/EstruturaCartao/MontaComboRede/`, {
    params: {
      somenteAtivas
    }
  });
}

export function montaComboBandeira(somenteAtivas) {
  return axios.get(`LxApi/v1/EstruturaCartao/MontaComboBandeira/`, {
    params: {
      somenteAtivas
    }
  });
}

export function montaComboProdutoRede(codigoRede, somenteAtivas) {
  return axios.get(`LxApi/v1/EstruturaCartao/MontaComboProdutoRede/`, {
    params: {
      codigoRede,
      somenteAtivas
    }
  });
}
/* 
QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ
QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ
QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ
QQQQQQQQQQQQQQQQQQQWQQQQQWWWBBBHHHHHHHHHBWWWQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ
QQQQQQQQQQQQQQQD!`__ssaaaaaaaaaass_ass_s____.  -~""??9VWQQQQQQQQQQQQQQQQQQQ
QQQQQQQQQQQQQP'_wmQQQWWBWV?GwwwmmWQmwwwwwgmZUVVHAqwaaaac,"?9$QQQQQQQQQQQQQQ
QQQQQQQQQQQW! aQWQQQQW?qw#TTSgwawwggywawwpY?T?TYTYTXmwwgZ$ma/-?4QQQQQQQQQQQ
QQQQQQQQQQW' jQQQQWTqwDYauT9mmwwawww?WWWWQQQQQ@TT?TVTT9HQQQQQQw,-4QQQQQQQQQ
QQQQQQQQQQ[ jQQQQQyWVw2$wWWQQQWWQWWWW7WQQQQQQQQPWWQQQWQQw7WQQQWWc)WWQQQQQQQ
QQQQQQQQQf jQQQQQWWmWmmQWU???????9WWQmWQQQQQQQWjWQQQQQQQWQmQQQQWL 4QQQQQQQQ
QQQQQQQP'.yQQQQQQQQQQQP"       <wa,.!4WQQQQQQQWdWP??!"??4WWQQQWQQc ?QWQQQQQ
QQQQQP'_a.<aamQQQW!<yF "!` ..  "??$Qa "WQQQWTVP'    "??' =QQmWWV?46/ ?QQQQQ
QQQP'sdyWQP?!`.-"?46mQQQQQQT!mQQgaa. <wWQQWQaa _aawmWWQQQQQQQQQWP4a7g -WWQQ
QQ[ j@mQP'adQQP4ga, -????" <jQQQQQWQQQQQQQQQWW;)WQWWWW9QQP?"`  -?QzQ7L ]QQQ
QW jQkQ@ jWQQD'-?$QQQQQQQQQQQQQQQQQWWQWQQQWQQQc "4QQQQa   .QP4QQQQfWkl jQQQ
QE ]QkQk $D?`  waa "?9WWQQQP??T?47`_aamQQQQQQWWQw,-?QWWQQQQQ`"QQQD\Qf(.QWQQ
QQ,-Qm4Q/-QmQ6 "WWQma/  "??QQQQQQL 4W"- -?$QQQQWP`s,awT$QQQ@  "QW@?$:.yQQQQ
QQm/-4wTQgQWQQ,  ?4WWk 4waac -???$waQQQQQQQQF??'<mWWWWWQW?^  ` ]6QQ' yQQQQQ
QQQQw,-?QmWQQQQw  a,    ?QWWQQQw _.  "????9VWaamQWV???"  a j/  ]QQf jQQQQQQ
QQQQQQw,"4QQQQQQm,-$Qa     ???4F jQQQQQwc <aaas _aaaaa 4QW ]E  )WQ`=QQQQQQQ
QQQQQQWQ/ $QQQQQQQa ?H ]Wwa,     ???9WWWh dQWWW,=QWWU?  ?!     )WQ ]QQQQQQQ
QQQQQQQQQc-QWQQQQQW6,  QWQWQQQk <c                             jWQ ]QQQQQQQ
QQQQQQQQQQ,"$WQQWQQQQg,."?QQQQ'.mQQQmaa,.,                . .; QWQ.]QQQQQQQ
QQQQQQQQQWQa ?$WQQWQQQQQa,."?( mQQQQQQW[:QQQQm[ ammF jy! j( } jQQQ(:QQQQQQQ
QQQQQQQQQQWWma "9gw?9gdB?QQwa, -??T$WQQ;:QQQWQ ]WWD _Qf +?! _jQQQWf QQQQQQQ
QQQQQQQQQQQQQQQws "Tqau?9maZ?WQmaas,,    --~-- ---  . _ssawmQQQQQQk 3QQQQWQ
QQQQQQQQQQQQQQQQWQga,-?9mwad?1wdT9WQQQQQWVVTTYY?YTVWQQQQWWD5mQQPQQQ ]QQQQQQ
QQQQQQQWQQQQQQQQQQQWQQwa,-??$QwadV}<wBHHVHWWBHHUWWBVTTTV5awBQQD6QQQ ]QQQQQQ
QQQQQQQQQQQQQQQQQQQQQQWWQQga,-"9$WQQmmwwmBUUHTTVWBWQQQQWVT?96aQWQQQ ]QQQQQQ
QQQQQQQQQQWQQQQWQQQQQQQQQQQWQQma,-?9$QQWWQQQQQQQWmQmmmmmQWQQQQWQQW(.yQQQQQW
QQQQQQQQQQQQQWQQQQQQWQQQQQQQQQQQQQga%,.  -??9$QQQQQQQQQQQWQQWQQV? sWQQQQQQQ
QQQQQQQQQWQQQQQQQQQQQQQQWQQQQQQQQQQQWQQQQmywaa,;~^"!???????!^`_saQWWQQQQQQQ
QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQWWWWQQQQQmwywwwwwwmQQWQQQQQQQQQQQ
QQQQQQQWQQQWQQQQQQWQQQWQQQQQWQQQQQQQQQQQQQQQQWQQQQQWQQQWWWQQQQQQQQQQQQQQQWQ 
*/

export function montaComboFormaPagamento() {
  return [
    { descricao: "Crédito", codigo: 4 },
    { descricao: "Débito", codigo: 7 },
    { descricao: "Ticket", codigo: 3 }
  ];
}
