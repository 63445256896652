import { AxiosErrorService } from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

/**
 * Um serviço para conferir um turno de pedidos usando a API.
 */
export class ApiAdicionarSangriaService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  /**
   * Adicionar sangria
   * @param {
   *  turnoId: number;
   *  valorSangrado: number;
   *  observacao: string;
   * } sangria
   * @returns void
   */
  async execute(sangria) {
    try {
      await this.urlLinxCore.post(`/TurnoPedido/ProcessaTurnoPedidoSangria`, sangria);
      return null;
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
