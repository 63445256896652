import axios from 'axios';
import { getToken } from '../../../../authentication/Auth';

import { BASE_URL_GATEWAY } from '../../../../shared/constants/GatewayUrlConstant';

import moment from "moment";

export const gerenciamentoFinanceiroService = {
  getReports,
  getReport,
  find
};

const urlLinxCore = axios.create({
 baseURL: `${BASE_URL_GATEWAY}/core/api/v1`, 
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

function getReport() {

  return [
    {
      id: 1,
      title: 'CONTAS A PAGAR',
      subtipo: 1,
      preReports: [
        {
          id: 1,
          title: 'HOJE',
          icon: 'icon-lx-clock',
          days: 0,
          type: 1,
          dataInicio: moment().utc().startOf('day').format(),
          dataFim: moment().utc().startOf('day').format(),
          tipoOperacao: [1],
          tipoData: 1
        },
        {
          id: 2,
          title: 'ATRASADOS',
          icon: 'icon-lx-clock',
          days: 0,
          type: 1,
          dataInicio: moment().utc().startOf('day').format(),
          dataFim: moment().utc().startOf('day').format(),
          tipoOperacao: [1],
          tipoData: 5,
          situacao: [3]
        },
        {
          id: 3,
          title: 'VENCIMENTO EM 15 DIAS',
          text: '15',
          days: 15,
          type: 0,
          dataInicio: moment().utc().startOf('day').format(),
          dataFim: moment().utc().startOf('day').add(15, 'days').format(),
          tipoOperacao: [1],
          tipoData: 1
        },
        {
          id: 4,
          title: 'PAGOS A 15 DIAS',
          text: '15',
          days: 15,
          type: 0,
          dataInicio: moment().utc().startOf('day').subtract(15, 'days').format(),
          dataFim: moment().utc().startOf('day').format(),
          tipoOperacao: [1],
          tipoData: 4
        }
      ]
    },
    {
      id: 2,
      title: 'CONTAS A RECEBER',
      subtipo: 1,
      preReports: [
        {
          id: 5,
          title: 'HOJE',
          icon: 'icon-lx-clock',
          days: 0,
          type: 1,
          dataInicio: moment().utc().startOf('day').format(),
          dataFim: moment().utc().startOf('day').format(),
          tipoOperacao: [2],
          tipoData: 1,
        },
        {
          id: 6,
          title: 'ATRASADOS',
          icon: 'icon-lx-clock',
          days: 0,
          type: 1,
          dataInicio: moment().utc().startOf('day').format(),
          dataFim: moment().utc().startOf('day').format(),
          tipoOperacao: [2],
          tipoData: 5,
          situacao: [3]
        },
        {
          id: 7,
          title: 'VENCIMENTO EM 15 DIAS',
          text: '15',
          days: 15,
          type: 0,
          dataInicio: moment().utc().startOf('day').format(),
          dataFim: moment().utc().startOf('day').add(15, 'days').format(),
          tipoOperacao: [2],
          tipoData: 1
        },
        {
          id: 8,
          title: 'PAGOS A 15 DIAS',
          text: '15',
          days: 15,
          type: 0,
          dataInicio: moment().utc().startOf('day').subtract(15, 'days').format(),
          dataFim: moment().utc().startOf('day').format(),
          tipoOperacao: [2],
          tipoData: 4
        }
      ]
    },
    {
      id: 3,
      title: 'DESPESAS E RECEITAS DIVERSAS',
      subtipo: 1,
      preReports: [
        {
          id: 9,
          title: 'HOJE',
          icon: 'icon-lx-clock',
          days: 0,
          type: 1,
          dataInicio: moment().utc().startOf('day').format(),
          dataFim: moment().utc().startOf('day').format(),
          tipoOperacao: [3, 4],
          tipoData: 1
        },        
        {
          id: 10,
          title: 'DIAS ANTERIORES',
          text: '7',
          days: 7,
          type: 0,
          dataInicio: moment().utc().startOf('day').subtract(7, 'days').format(),
          dataFim: moment().utc().endOf('day').subtract(1, 'days').format(),
          tipoOperacao: [3, 4],
          tipoData: 1
        },
        {
          id: 11,
          title: 'DIAS ANTERIORES',
          text: '15',
          days: 15,
          type: 0,
          dataInicio: moment().utc().startOf('day').subtract(15, 'days').format(),
          dataFim: moment().utc().startOf('day').subtract(1, 'days').format(),
          tipoOperacao: [3, 4],
          tipoData: 1
        },
        {
          id: 12,
          title: 'DIAS ANTERIORES',
          text: '30',
          days: 30,
          type: 0,
          dataInicio: moment().utc().startOf('day').subtract(30, 'days').format(),
          dataFim: moment().utc().startOf('day').subtract(1, 'days').format(),
          tipoOperacao: [3, 4],
          tipoData: 1
        }
      ]
    }
  ]
}

async function find(appliedFilters) {
  const response = await urlLinxCore.post('/financeiro/Extrato', { query: appliedFilters })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

function getReports() {
  return [
     {
       id: 111,
       title: 'PERSONALIZAR RELATÓRIO',
       icon: 'icon-lx-search',
       type: 2
     }
    ,
    {
      id: 1,
      title: 'CONTAS A PAGAR',
      icon: 'icon-lx-coin-remove',
      type: 0
    },
    {
      id: 2,
      title: 'CONTAS A RECEBER',
      icon: 'icon-lx-coin-plus',
      type: 0
    },
    {
      id: 3,
      title: 'RECEITAS E DESPESAS DIVERSAS',
      icon: 'icon-lx-coin-2',
      type: 0
    }
  ]
}

