import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../shared/constants/MenuConstants';
import {
  getNomePagina,
  toFloatFormattedDisplayDecimals,
  sameInt,
  operacaoAcesso
} from '../../../../shared/utils/Utils';
import ptMessages from '../../../../../assets/js/pt.json';
import { locale, loadMessages } from 'devextreme/localization';
import moment from 'moment';
import SituacaoBadge from '../components/SituacaoBadge/SituacaoBadge';
import styles from './ConferenciaCaixaResult.module.scss';

import config from 'devextreme/core/config';

import useOutsideClick from './useOutsideClick';

import notify from 'devextreme/ui/notify';
import { LinxDatePill } from '../../../../components/linx/DatePill/DatePill';
import { ConferenciaCaixaTotais } from '../conferencia-caixa-totais/ConferenciaCaixaTotais';
import LinxInfoPill from '../../../../components/linx/InfoPill/InfoPill';
import { LinxButtonConferencia } from '../../../../components/linx/Button/LinxButtonConferencia';
import conferenciaCaixa from '../service/ConferenciaCaixa.service';
import { confirm, alert } from 'devextreme/ui/dialog';
import CardLoader from './CardLoader';
import {
  Situacoes,
  codigoFormaPgtoToDescricao
} from './../conferencia-caixa-totais/ConferenciaCaixaTotais';
import { getConfiguracaoGeral } from '../../../configuracoes/configuracoes-gerais/ConfiguracoesGerais.service';
import { getSetores } from '../../../../services/Setores.service';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { MovimentacaoEncerrantes } from '../../../../components/business/MovimentacaoEncerrantes/MovimentacaoEncerrantes';

export const isCartao = forma => {
  if (!forma) {
    return false;
  }

  return (
    sameInt(forma, 3) ||
    sameInt(forma, -3) ||
    sameInt(forma, 4) ||
    sameInt(forma, -4) ||
    sameInt(forma, 7) ||
    sameInt(forma, -7)
  );
};

const ModalidadesConferencia = {
  ConferenciaFacil: '1',
  ReabrirTurno: '2',
  DadosConferencia: '3',
  ImprimirVale: '4'
};

function ConferenciaCaixaResult(props) {
  const [dadosConferenciaResult, setDadosConferenciaResult] = useState(
    props.dadosConferenciaResult || []
  );
  const [dadosConferenciaFacil, setDadosConferenciaFacil] = useState([]);
  const [searching, setSearching] = useState(false);
  const [loadding, setLoadding] = useState(false);
  const [
    consideraSuprimentoDiferenca,
    setConsideraSuprimentoDiferenca
  ] = useState(true);
  const [, setState] = useState();
  const [showEncerrantes, setshowEncerrantes] = useState(false);

  const itemsRef = useRef([]);
  const REABRE_CAIXA = 60681;

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(
      0,
      props.dadosConferenciaResult.length
    );
  }, [props.dadosConferenciaResult]);

  useOutsideClick(itemsRef, () => {
    fecharMenuCard();
  });

  const PATH_MENU = menus.COD_60680;
  const codigoInternoSetorPista = 3;

  const appliedFilters = props.appliedFilters || {};

  const [totais, setTotais] = useState(null);
  const [permiteConferenciaFacil, setPermiteConferenciaFacil] = useState(true);

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, []);

  useEffect(() => {
    async function getConsideraSuprimentoDiferenca() {
      const { data } = await getConfiguracaoGeral([183]);
      if (data.notificationLevel === 1) {
        const consideraSuprimento = !sameInt(data.data.valor, 1);
        setConsideraSuprimentoDiferenca(consideraSuprimento);
      }
    }
    getConsideraSuprimentoDiferenca();
  }, []);

  useEffect(() => {
    const data = dadosConferenciaResult;

    let dadosSemTurnosAbertos = data.filter(
      item => item.fimTurno !== undefined
    );

    setDadosConferenciaFacil(dadosSemTurnosAbertos);
  }, [dadosConferenciaResult]);

  useEffect(() => {
    let permite = false;
    if (appliedFilters.situacoes && appliedFilters.situacoes.length > 0) {
      appliedFilters.situacoes.forEach(element => {
        if (
          sameInt(element.valor, Situacoes.NaoConferido) ||
          sameInt(element.valor, Situacoes.ConferidoParcialmente)
        ) {
          permite = true;
        }
      });
    } else {
      permite = true;
    }
    setPermiteConferenciaFacil(permite);
  }, []);

  useEffect(() => {
    const modifiedData = dadosConferenciaResult;
    modifiedData.forEach(turno => {
      turno.showMenu = false;
      turno.formasPgto = [];
    });
    setDadosConferenciaResult(modifiedData);
  }, [dadosConferenciaResult]);

  const setTabEncerrantes = async dataInicioTurno => {
    let setores = await getSetores();
    let temPista = setores.data.some(
      item => item.codigo === codigoInternoSetorPista
    );

    if (temPista) {
      setshowEncerrantes(true);
    } else {
      let temVendaComb = await conferenciaCaixa.DiaPossuiVendaCombustivel(
        dataInicioTurno
      );
      setshowEncerrantes(temVendaComb.data);
    }
  };

  config({
    defaultCurrency: 'BRL'
  });

  const fecharMenuCard = () => {
    const modifiedData = dadosConferenciaResult;
    modifiedData.forEach(turno => {
      turno.showMenu = false;
    });
    setDadosConferenciaResult(modifiedData);
    setState({});
  };

  const handleClick = (codigoTurno, turnoOperadorId) => {
    dadosConferenciaResult.forEach(turno => {
      if (turno.id === codigoTurno && turno.turnoOperadorId === turnoOperadorId) {
        turno.showMenu = !turno.showMenu;
      } else {
        turno.showMenu = false;
      }
    });

    setDadosConferenciaResult(dadosConferenciaResult);
    setState({});
  };

  async function realizarConferencia() {
    setSearching(true);
    setLoadding(true);
    try {
      const data = dadosConferenciaFacil
        .filter(
          d =>
            sameInt(d.situacao, Situacoes.NaoConferido) ||
            sameInt(d.situacao, Situacoes.ConferidoParcialmente)
        )
        .map(item => {
          return {
            id: item.id,
            turnoOperadorId: item.turnoOperadorId,
            operador: {
              id: item.operador.id
            }
          };
        });
      const response = await conferenciaCaixa.RealizarConferenciaFacil(data);

      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        let dadosSemTurnosAbertos = dadosConferenciaResult.filter(item => {
          if (item.fimTurno !== undefined) {
            item.situacao = 1;
          }
          return item;
        });

        setDadosConferenciaResult(dadosSemTurnosAbertos);

        notify(
          'Conferência fácil dos caixas não conferidos realizada com sucesso!',
          'success',
          5000
        );
      }
    } finally {
      setSearching(false);
      setLoadding(false);
    }
  }

  const conferenciaFacilCard = async (codigoTurno, turnoOperadorId) => {
    setLoadding(true);
    try {
      let turnoSelecionado = dadosConferenciaFacil.filter(item => {
        return item.id === codigoTurno && item.turnoOperadorId === turnoOperadorId;
      });

      const data = turnoSelecionado.map(item => {
        return {
          id: item.id,
          turnoOperadorId: item.turnoOperadorId,
          operador: {
            id: item.operador.id
          }
        };
      });

      const response = await conferenciaCaixa.RealizarConferenciaFacil(data);

      if (response.notificationLevel !== 1) {
        setLoadding(false);
        notify(response.message, 'error', 5000, 'top');
      } else {
        dadosConferenciaResult.map(item => {
          if (item.id === codigoTurno && item.turnoOperadorId === turnoOperadorId) {
            item.situacao = 1;
            item.usuarioConferente = {
              nome: item.operador.nome
            };
            item.dataConferencia = moment();
          }
          return item;
        });

        setDadosConferenciaResult(dadosConferenciaResult);

        notify('Conferência fácil realizada com sucesso!', 'success', 5000);
      }
    } finally {
      setLoadding(false);
    }
  };

  const reabrirTurnoCard = async (codigoTurno, turnoOperadorId) => {
    setLoadding(true);
    try {
      const response = await conferenciaCaixa.Reabrir(
        parseInt(codigoTurno),
        turnoOperadorId
      );
      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        dadosConferenciaResult.map(item => {
          if (item.id === codigoTurno && item.turnoOperadorId === turnoOperadorId) {
            item.situacao = 0;
          }
          return item;
        });

        setDadosConferenciaResult(dadosConferenciaResult);

        notify('Turno reaberto com sucesso.', 'success', 5000, 'top');
      }
    } finally {
      setLoadding(false);
    }
  };

  const handleClickCartao = async e => {
    setLoadding(true);
    const codigoTurno = e.currentTarget.dataset.key;
    const turnoOperadorId = e.currentTarget.dataset.operador;
    let dadosFilter = null;
    dadosConferenciaResult.forEach(value => {
      if (
        sameInt(value.id, codigoTurno) &&
        sameInt(value.turnoOperadorId, turnoOperadorId)
      ) {
        dadosFilter = { ...value };
        return;
      }
    });
    const listaFP = await conferenciaCaixa.ListarFechamentoCaixa(
      codigoTurno,
      dadosFilter.turnoOperadorId
    );
    if (listaFP) {
      let possuiVale = await conferenciaCaixa.VerificaSePossuiVale(
        codigoTurno,
        dadosFilter.turnoOperadorId
      );
      const dadosConferenciaCaixa = {
        codigoTurno,
        turnoMacro: dadosFilter.turnoMacro,
        turnoOperadorId: dadosFilter.turnoOperadorId,
        caixaAgrupado: dadosFilter.caixaAgrupado,
        gerarVale: possuiVale.data,
        responsavel: dadosFilter.operador,
        startDate: dadosFilter.inicioTurno,
        endDate: dadosFilter.fimTurno,
        situacao: dadosFilter.situacao,
        formas: [],
        dataConferencia: dadosFilter.dataConferencia,
        usuarioConferente: dadosFilter.usuarioConferente
          ? {
              nome: dadosFilter.usuarioConferente.nome
            }
          : null
      };

      listaFP.forEach((fp, index) => {
        dadosConferenciaCaixa.formas.push({
          id: index,
          formaPagamento: fp.formaPagamento,
          recebimentos: fp.valorRecebido,
          sangria: fp.valorSangria,
          suprimentos: fp.valorSuprimento,
          tesouraria: fp.valorApurado,
          cartao: isCartao(fp.formaPagamento.id),
          informacoes: []
        });
      });
      dadosConferenciaCaixa.formas = await Promise.all(
        dadosConferenciaCaixa.formas.map(async value => {
          const consulta = {
            operadores: [dadosConferenciaCaixa.responsavel.id],
            turno: parseInt(dadosConferenciaCaixa.codigoTurno),
            formaPagamento: value.formaPagamento.id
          };
          try {
            const listaDocumentosPorTurno = await conferenciaCaixa.DocumentosPorTurno(
              consulta
            );
            listaDocumentosPorTurno.forEach(documento => {
              value.informacoes.push({
                formaPagamento: {
                  id: consulta.formaPagamento * -1,
                  descricao: codigoFormaPgtoToDescricao(consulta.formaPagamento)
                },
                codigoVenda: documento.codigoVenda,
                codigoMovimento: documento.codigoMovimento,
                dataDocumento: documento.dataHora,
                dataVencimento: documento.dataVencimento,
                dataQuitacao: documento.dataQuitacao,
                faturado: documento.faturado,
                operadores: appliedFilters.operadores,
                documentoFiscal: documento.tipoDocumentoFiscal,
                equipamento: documento.codigoEcf,
                numero: documento.numeroDocumento,
                emissao: documento.tipoEmissao
                  ? documento.tipoEmissao.descricao
                  : 'Outros',
                rede: documento.rede
                  ? {
                      id: documento.rede.id,
                      descricao: documento.rede.descricao
                    }
                  : null,
                bandeira: documento.bandeira
                  ? {
                      id: documento.bandeira.id,
                      descricao: documento.bandeira.descricao
                    }
                  : null,
                produtoRede: documento.produtoRede
                  ? {
                      id: documento.produtoRede.id,
                      descricao: documento.produtoRede.descricao
                    }
                  : null,
                nsu: documento.nsu,
                nsuRede: documento.nsuRede,
                data: documento.dataHora,
                valor: documento.valor,
                cliente: documento.cliente,
                operador: documento.operador,
                serie: documento.serie,
                valorAcrescimo: documento.valorAcrescimo,
                valorDesconto: documento.valorDesconto,
                valorBruto: documento.valorBruto,
                chave: documento.chave
              });
            });
          } catch (e) {
            return null;
          }
          //value.informacoes = dataDoc.result;
          return value;
        })
      );
      setTotais(dadosConferenciaCaixa);
      setTabEncerrantes(dadosConferenciaCaixa.startDate);
      setLoadding(false);
    } else {
      notify('Não foram encontrados registros', 'info', 5000, 'top');
      setLoadding(false);
    }
  };

  const conferenciaFacil = async () => {
    let mensagem = consideraSuprimentoDiferenca
      ? '<h2>Ao realizar a conferência fácil, o campo "Tesouraria" será preenchido com a soma do valor dos campos "Recebimentos" e "Suprimentos" </br> para todas as formas de pagamento. </br> Confirma a conferência?</h2>'
      : '<h2>Ao confirmar, o campo "Tesouraria" será preenchido com o mesmo valor do campo "Recebimentos" para todas as formas de pagamento. Confirmar?</h2>';
    let result = await confirm(mensagem, 'Conferência Fácil');
    if (result) {
      realizarConferencia();
    }
  };

  const backButtonHandle = () => {
    setDadosConferenciaResult(
      dadosConferenciaResult.map(item => {
        if (
          sameInt(item.id, totais.codigoTurno) &&
          sameInt(item.turnoOperadorId, totais.turnoOperadorId)
        ) {
          item.situacao = totais.situacao;
          if (item.situacao === Situacoes.Conferido) {
            item.usuarioConferente = totais.usuarioConferente;
            item.dataConferencia = totais.dataConferencia;
          }
        }
        return item;
      })
    );

    setTotais(null);
  };

  const tabItemTitleRender = item => {
    return <h1>{item.title}</h1>;
  };

  const abrirModalInfo = async (responsavel, data) => {
    var dataC = moment(data).format('DD/MM/YYYY');
    var horaC = moment(data).format('HH:mm');
    await alert(
      `<strong>Conferido por ${responsavel}, em ${dataC} às ${horaC}.</strong>`,
      'Dados do Turno'
    );
  };

  if (totais) {
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <div className="mt-3">
            <div className="row">
              <div className={'col-md-8'}>
                <button
                  onClick={backButtonHandle}
                  type="button"
                  className="btn bg-white shadow-sm"
                >
                  <i className="icon icon-lx-arrow-left"></i>
                  Voltar para os resultados
                </button>
              </div>
            </div>
          </div>
          <TabPanel
            animationEnabled={false}
            scrollByContent={true}
            scrollingEnabled={true}
            itemTitleRender={tabItemTitleRender}
          >
            <Item title="Formas de pagamento">
              <ConferenciaCaixaTotais dados={totais} />
            </Item>
            {showEncerrantes && (
              <Item title="Encerrantes">
                <MovimentacaoEncerrantes dataInicioTurno={totais.startDate} />
              </Item>
            )}
          </TabPanel>
        </LinxPostos>
      </>
    );
  } else {
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <div className={`${styles.conferenciaCaixaResult} conferenciaCaixaResult mt-3`}>
            <div className="row">
              <div className={`col-md-8`}>
                <button
                  onClick={
                    props.backButtonHandle ? props.backButtonHandle : () => {}
                  }
                  type="button"
                  className="btn bg-white shadow-sm"
                >
                  <i className="icon icon-lx-arrow-left"></i>
                  Voltar para a busca
                </button>
              </div>
            </div>
            <div className={`${styles.results} shadow-sm p-3 bg-white`}>
              <div className="row">
                <div className="col-md-12">
                  <div className={`${styles.appliedFilters} appliedFilters`}>
                    <LinxDatePill
                      startDate={appliedFilters.periodoDe}
                      endDate={appliedFilters.periodoAte}
                      format="DD/MM/YYYY"
                    />
                    {appliedFilters.operadores &&
                    appliedFilters.operadores.length > 0 ? (
                      <LinxInfoPill
                        info={`Operadores selecionados: ${appliedFilters.operadores.length}`}
                      />
                    ) : (
                      <></>
                    )}
                    {appliedFilters.situacoes &&
                    appliedFilters.situacoes.length > 0 ? (
                      <LinxInfoPill
                        info={`Situações selecionadas: ${appliedFilters.situacoes.length}`}
                      />
                    ) : (
                      <></>
                    )}
                    {appliedFilters.maquinas &&
                    appliedFilters.maquinas.length > 0 ? (
                      <LinxInfoPill
                        info={`Máquinas selecionadas: ${appliedFilters.maquinas.length}`}
                      />
                    ) : (
                      <></>
                    )}
                    {appliedFilters.clientes &&
                    appliedFilters.clientes.length > 0 ? (
                      <LinxInfoPill
                        info={`Clientes selecionados: ${appliedFilters.clientes.length}`}
                      />
                    ) : (
                      <></>
                    )}
                    {appliedFilters.documentoFiscal && (
                      <LinxInfoPill
                        info={`Documento: ${appliedFilters.documentoFiscal}`}
                      />
                    )}
                    {permiteConferenciaFacil ? (
                      <LinxButtonConferencia
                        labelNormal="Conferência Fácil"
                        labelDisabled={'Processando...'}
                        disabled={searching}
                        onClick={conferenciaFacil}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className={`${styles.grid}`}>
                {dadosConferenciaResult.map((card, i) => {
                  const dataInicioTurno = card.inicioTurno
                    ? moment(card.inicioTurno).format('DD/MM/YYYY HH:mm')
                    : null;
                  const dataFimTurno = card.fimTurno
                    ? moment(card.fimTurno).format('DD/MM/YYYY HH:mm')
                    : null;
                  const datas =
                    dataInicioTurno +
                    (dataFimTurno ? ' - ' + dataFimTurno : '');
                  return (
                    <div key={i}>
                      {loadding ? (
                        <article
                          className={`${styles.card} ${styles.cardItem} cardItem`}
                          onClick={e => e.stopPropagation()}
                        >
                          <div
                            style={{ marginLeft: '-6%', marginTop: 'inherit' }}
                          >
                            <CardLoader />
                          </div>
                        </article>
                      ) : (
                        <article
                          className={`${styles.card} ${styles.cardItem} cardItem`}
                          onClick={handleClickCartao}
                          data-key={card.id}
                          data-operador={card.turnoOperadorId}
                        >
                          <header className={`${styles.card__header}`}>
                            <div
                              className={`${styles.botaoAcoes}`}
                              key={i}
                              ref={el => (itemsRef.current[i] = el)}
                            >
                              <button
                                className={'btn-primary'}
                                onClick={e => {
                                  e.stopPropagation();
                                  handleClick(card.id, card.turnoOperadorId);
                                }}
                              >
                                ...
                              </button>
                              {card.showMenu ? (
                                <div className={`${styles.dropdownConf}`}>
                                  <ul>
                                    {card.situacao === Situacoes.Conferido ? (
                                      <>
                                        {operacaoAcesso(REABRE_CAIXA) ? (
                                          <li
                                            onClick={async e => {
                                              e.stopPropagation();
                                              let result = await confirm(
                                                '<h1>Deseja realmente reabrir o turno?</h1>',
                                                'Conferência'
                                              );
                                              if (result) {
                                                reabrirTurnoCard(
                                                  card.id,
                                                  card.turnoOperadorId
                                                );
                                              }
                                              fecharMenuCard();
                                            }}
                                            data-id={
                                              ModalidadesConferencia.ReabrirTurno
                                            }
                                            data-key={card.id}
                                          >
                                            Reabrir Turno
                                          </li>
                                        ) : null}
                                        {
                                          <li
                                            onClick={() =>
                                              abrirModalInfo(
                                                card.usuarioConferente.nome,
                                                card.dataConferencia
                                              )
                                            }
                                            data-id={
                                              ModalidadesConferencia.DadosConferencia
                                            }
                                            data-key={card.id}
                                          >
                                            Dados do Turno
                                          </li>
                                        }
                                      </>
                                    ) : card.situacao ===
                                      Situacoes.NaoConferido ? (
                                      <>
                                        <li
                                          onClick={async e => {
                                            e.stopPropagation();

                                            let mensagem = consideraSuprimentoDiferenca
                                              ? '<h2>Ao realizar a conferência fácil, o campo "Tesouraria" será preenchido com a soma do valor dos campos "Recebimentos" e "Suprimentos" </br> para todas as formas de pagamento. </br> Confirma a conferência?</h2>'
                                              : '<h2>Ao confirmar, o campo "Tesouraria" será preenchido com o mesmo valor do campo "Recebimentos" para todas as formas de pagamento. Confirmar?</h2>';
                                            let result = await confirm(
                                              mensagem,
                                              'Conferência Fácil'
                                            );

                                            if (result) {
                                              conferenciaFacilCard(card.id, card.turnoOperadorId);
                                            }
                                            fecharMenuCard();
                                          }}
                                          data-id={
                                            ModalidadesConferencia.ConferenciaFacil
                                          }
                                          data-key={card.id}
                                        >
                                          Conferência Fácil
                                        </li>
                                      </>
                                    ) : card.situacao ===
                                      Situacoes.AbertoNoPDV ? (
                                      <li
                                        onClick={e => {
                                          e.stopPropagation();
                                          notify(
                                            'Turno aberto no PDV. Não são permitidas alterações nos lançamentos',
                                            'info',
                                            5000
                                          );
                                        }}
                                        data-id={
                                          ModalidadesConferencia.AbertoNoPDV
                                        }
                                        data-key={card.id}
                                      >
                                        Aberto no PDV
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                  </ul>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <h1>{card.operador.nome}</h1>
                            <h5>{datas}</h5>
                          </header>

                          <SituacaoBadge situacao={card.situacao} />
                          <div className={`${styles.card__content} card__content`}>
                            <div className={`${styles.totais} row`}>
                              <div className="column">
                                <h3>TOTAL DE SUPRIMENTOS</h3>
                                <h5>
                                  R${' '}
                                  {toFloatFormattedDisplayDecimals(
                                    card.valores.valorSuprimentos,
                                    2
                                  )}
                                </h5>
                              </div>
                              <div className="column">
                                <h3>TOTAL DE RECEBIMENTOS</h3>
                                <h5>
                                  R${' '}
                                  {toFloatFormattedDisplayDecimals(
                                    card.valores.valorRecebimentos,
                                    2
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className={`${styles.totais} row`}>
                              <div className="column">
                                <h3>TOTAL DE SANGRIAS</h3>
                                <h5>
                                  R${' '}
                                  {toFloatFormattedDisplayDecimals(
                                    card.valores.valorSangrias,
                                    2
                                  )}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </article>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </LinxPostos>
      </>
    );
  }
}

export default withRouter(ConferenciaCaixaResult);
