import React from 'react';
import RaioXDashboardLoading from './RaioXDashboardLoading';
import styles from './RaioXDashboard.module.scss';

export default function RaioXDashboardTotalCard({ ...props }) {
  return (
    <div className={`${styles.cardContainer}`}>
      <div className={`bg-white shadow-sm ${styles.cardContainerInfo}`}>
        <br />
        <h5>{props.title.toUpperCase()}</h5>
        <h1 className="display-4 pt-4">
          {props.isLoading ? <RaioXDashboardLoading /> : props.value}
        </h1>
      </div>
    </div>
  );
}
