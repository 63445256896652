import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Checkbox,
  Checkitem,
  InputDate,
  Select,
  handleInputChange,
  makeInputUpperCase
} from '../../../components/formulario/Formulario';
import List from '../../../components/formulario/List';
import SectionHeader from '../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  AlertType,
  tagAlert,
  showQuestion,
  hideAlert,
  showError,
  showInfo,
  showWarning,
  showDeleteError,
  showWait,
  showSaveError,
  hideWait,
  showExecuteError
} from '../../../components/alert/Alert';

import { withRouter } from 'react-router-dom';
import moment from 'moment';

import {
  incluiFuncionario,
  alteraFuncionario,
  excluiFuncionario,
  getFuncionarios,
  verifyFuncionario
} from './Funcionarios.service';

import { verifyPessoa } from '../pessoas/Pessoas.service';

import {
  validaCPF,
  maskedCnpjCpf,
  maskCpf,
  isValidEmail,
  isValidIdentfid,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina,
  validaDadosLogin,
  stringToUpperOrEmpty,
  getCnpj,
  sameString,
  compareStrings,
  updateUserName
} from '../../../shared/utils/Utils';

import {
  getEstados,
  getCidades,
  getCep
} from '../../../services/Localidades.service';
import { montaComboPerfisAcesso } from '../../configuracoes/perfil-acesso/PerfisAcesso.service';

import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import Footer from '../../../components/core/footer/Footer';
import { menus } from '../../../shared/constants/MenuConstants';
import { reenviaEmail } from '../../core/boas-vindas/BoasVindas.service';
import notify from 'devextreme/ui/notify';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';
// parece ser um bug, no options do Select se tiver um item com value: null dá uma série de erros
export const none = '';

const PATH_MENU = menus.COD_60460;
const titulo = 'Funcionários';

function apiToState(api) {
  const result = { ...api };
  result.ativo = !result.inativo;
  delete result.inativo;
  result.cep = result.endereco.cep;
  result.codigoUF = result.endereco.codigoUF;
  result.codigoMunicipio = result.endereco.codigoMunicipio;
  result.numero = result.endereco.numero;
  result.complemento = result.endereco.complemento;
  result.referencia = result.endereco.referencia;
  result.bairro = result.endereco.bairro;
  result.endereco = result.endereco.endereco;
  if (result.contatos && result.contatos.length > 0) {
    result.contatoNome =
      !result.contatos[0].nome || result.contatos[0].nome === 'null'
        ? ''
        : result.contatos[0].nome;
    result.contatoDescricao =
      !result.contatos[0].descricao || result.contatos[0].descricao === 'null'
        ? ''
        : result.contatos[0].descricao;
    result.contatoDDD =
      !result.contatos[0].ddd || result.contatos[0].ddd === 'null'
        ? null
        : result.contatos[0].ddd;
    result.contatoNumero =
      !result.contatos[0].numero || result.contatos[0].numero === 'null'
        ? null
        : result.contatos[0].numero;
    result.email =
      !result.contatos[0].email || result.contatos[0].email === 'null'
        ? result.email
        : result.contatos[0].email;
  } else {
    result.contatoNome = '';
    result.contatoDescricao = '';
    result.contatoDDD = null;
    result.contatoNumero = null;
    //result.email = "";
  }
  delete result.contatos;
  result.stillVerifying = false;
  result.emailOriginal = result.email;
  return result;
}

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
      codigoPessoa = null,
      cpf = '',
      nome = '',
      apelido = '',
      dataNascimento = null,
      dataAdmissao = null,
      dataCadastro = moment(),
      identidade = '',
      orgaoEmissor = '',
      ativo = true,
      cep = '',
      codigoUF = none,
      codigoMunicipio = none,
      endereco = '',
      numero = '',
      complemento = '',
      referencia = '',
      bairro = '',
      contatoNome = '',
      contatoDescricao = '',
      contatoDDD = null,
      contatoNumero = null,
      //contatoEMail = "",
      site = '',
      codigoUsuario = null,
      email = '',
      codigoPerfilAcesso = none,
      possuiHistorico = false,
      valorMinimoGeracaoVale = null,
      observacao = '',
      dataInativacao = null,
      excluido = false,
      rowVersion = null,
      stillVerifying = true,
      identfID = '',
      dataValidacaoEmail = null
    } = this.props.func;

    this.state = {
      codigoPessoa,
      cpf,
      nome,
      apelido,
      dataNascimento,
      dataAdmissao,
      dataCadastro,
      identidade,
      orgaoEmissor,
      ativo,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      //contatoEMail,

      site,
      codigoUsuario,
      email,
      emailOriginal: email,
      codigoPerfilAcesso,

      valorMinimoGeracaoVale,
      observacao,
      possuiHistorico,
      dataValidacaoEmail,
      dataInativacao,
      excluido,
      identfID,
      rowVersion,

      alertActive: false,
      alertType: AlertType.None,
      alertTitle: '',
      alertSubtitle: '',
      alertFocus: null,
      alertAction: null,

      categoriasClientes: [],
      categoriasFornecedores: [],
      categoriasFabricantes: [],
      estados: [],
      cidades: [],
      stillVerifying,
      searchedCEP: false
    };
  }

  async componentDidMount() {
    this.setState({
      perfis: [],
      estados: [],
      cidades: []
    });
    this.updatePerfis();
    this.updateEstados();
    this.updateCidades(this.state.codigoUF);
  }

  backToScreen = () => {
    hideAlert(this);
    this.props.history.push('/cadastro/funcionarios/');
  };

  validateData = () => {
    if (moment(this.state.dataNascimento).isAfter(moment())) {
      showError(
        this,
        titulo,
        'A data de nascimento quando informada, não pode ' +
          'ser superior a data atual.',
        'dataNascimento'
      );
    }
  };

  validateEmail = () => {
    const email = this.state.email;
    return email && isValidEmail(email);
  };

  isFormValid() {
    let erro = null;
    const email = this.state.email;

    if (!this.state.nome)
      erro = {
        field: 'nome',
        message: 'O nome do funcionário deve ser informado.'
      };
    else if (
      !this.state.dataNascimento ||
      moment(this.state.dataNascimento).isAfter(moment())
    ) {
      erro = {
        field: 'dataNascimento',
        message:
          'A data de nascimento deve ser uma data válida ' +
          'e não pode ser superior a data atual.'
      };
    } else if (!this.state.identidade) {
      erro = {
        field: 'identidade',
        message: 'A identidade deve ser informada.'
      };
    } else if (this.state.identidade && !this.state.identidade.trim()) {
      erro = {
        field: 'identidade',
        message: 'A identidade deve ser informada.'
      };
    } else if (
      !this.state.dataAdmissao ||
      moment(this.state.dataAdmissao).isAfter(moment())
    ) {
      erro = {
        field: 'dataAdmissao',
        message:
          'A data de admissão deve ser uma data válida ' +
          'e não pode ser superior a data atual.'
      };
    } else if (this.state.identfID && !isValidIdentfid(this.state.identfID)) {
      erro = {
        field: 'identfID',
        message:
          'O cartão de identificação (RFID) deve conter apenas caracteres ' +
          'hexadecimais válidos (números de 0 a 9, letras de A a F)'
      };
    } else if (!email) {
      erro = {
        field: 'email',
        message: 'O e-mail deve ser informado.'
      };
    } else if (!this.validateEmail(email)) {
      erro = {
        field: 'email',
        message: 'O e-mail "' + email + '" não é válido.'
      };
    } else if (!this.state.codigoPerfilAcesso) {
      erro = {
        field: 'codigoPerfilAcesso',
        message: 'É necessário selecionar um perfil de acesso.'
      };
    } else if (parseFloat(this.state.valorMinimoGeracaoVale) > 999999.99) {
      erro = {
        field: 'valorMinimoGeracaoVale',
        message:
          'O valor mínimo para a geração do vale não pode ser superior a 999.999,99.'
      };
    } else if (!this.state.cep)
      erro = {
        field: 'cep',
        message: 'O CEP deve ser informado.'
      };
    else if (!this.state.endereco)
      erro = {
        field: 'endereco',
        message: 'O endereço (rua, avenida, logradouro) deve ser informado.'
      };
    else if (!this.state.bairro)
      erro = {
        field: 'bairro',
        message: 'O bairro deve ser informado.'
      };
    else if (!this.state.codigoUF)
      erro = {
        field: 'codigoUF',
        message: 'A unidade federativa (uf, estado) deve ser informada.'
      };
    else if (!this.state.codigoMunicipio)
      erro = {
        field: 'codigoMunicipio',
        message: 'A cidade deve ser informada.'
      };

    if (erro) {
      showError(this, titulo, erro.message, erro.field);
      return false;
    } else return true;
  }

  handleSalvar = async () => {
    let {
      codigoPessoa,
      cpf,
      nome,
      apelido,
      dataNascimento,
      dataAdmissao,
      dataCadastro,
      identidade,
      orgaoEmissor,
      ativo,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      codigoUsuario,
      email,
      emailOriginal,
      codigoPerfilAcesso,

      valorMinimoGeracaoVale,
      observacao,
      identfID,
      rowVersion,
      dataValidacaoEmail
    } = this.state;

    nome = stringToUpperOrEmpty(nome);
    apelido = stringToUpperOrEmpty(apelido);
    identidade = stringToUpperOrEmpty(identidade);
    orgaoEmissor = stringToUpperOrEmpty(orgaoEmissor);
    identfID = stringToUpperOrEmpty(identfID);
    email = stringToUpperOrEmpty(email);
    observacao = stringToUpperOrEmpty(observacao);

    const enderecoObj = {
      codigoUF,
      codigoMunicipio,
      endereco: stringToUpperOrEmpty(endereco),
      numero,
      complemento: stringToUpperOrEmpty(complemento),
      referencia: stringToUpperOrEmpty(referencia),
      bairro: stringToUpperOrEmpty(bairro),
      cep,
      municipio: null,
      estado: null
    };

    const contatos = [];
    contatos.push({
      codigo: 1,
      codigoPessoa,
      nome: stringToUpperOrEmpty(contatoNome),
      descricao: stringToUpperOrEmpty(contatoDescricao),
      ddd: contatoDDD ? contatoDDD : '',
      numero: contatoNumero ? contatoNumero : '',
      email
    });

    //usuario = contatoEMail;

    const inativo = !ativo;
    //const dataCadastroToSend = moment(dataCadastro).format("YYYY-MM-DDTHH:mm:ss");

    const [action, params] = this.isInserting()
      ? [
          incluiFuncionario,
          [
            cpf,
            nome,
            apelido,
            dataNascimento,
            identidade,
            orgaoEmissor,
            dataAdmissao,
            identfID,
            email,
            codigoPerfilAcesso,
            valorMinimoGeracaoVale,
            observacao,
            enderecoObj,
            contatos
          ]
        ]
      : [
          alteraFuncionario,
          [
            codigoPessoa,
            cpf,
            nome,
            apelido,
            dataNascimento,
            identidade,
            orgaoEmissor,
            dataAdmissao,
            dataCadastro,
            identfID,
            codigoUsuario,
            email,
            codigoPerfilAcesso,
            valorMinimoGeracaoVale,
            observacao,
            inativo,
            rowVersion,
            enderecoObj,
            contatos
          ]
        ];

    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    if (this.isFormValid()) {
      const { data } = await getFuncionarios({ search: email });

      if (data.totalCount > 0 || data.result.length > 0) {
        if (!rowVersion || data.result[0].codigoPessoa !== codigoPessoa) {
          let nome = !!data.result[0].nome
            ? data.result[0].nome
            : 'outro funcionário';
          showError(
            this,
            titulo,
            `O e-mail ${email} já está cadastrado para ${nome}, ${email}`
          );
          return;
        }
      }

      const doSave = async () => {
        try {
          showWait(
            this,
            titulo,
            'Por favor, aguarde enquanto o funcionário é salvo.'
          );
          const { data: resp } = await action(...params);
          let msg = '';
          if (resp.message) {
            msg = resp.message;

            updateUserName(nome);
          } else {
            msg = `Funcionário ${
              this.isInserting()
                ? 'cadastrado'
                : this.isRestoring()
                ? 'restaurado'
                : 'alterado'
            } com sucesso!`;
          }
          if (
            !this.isInserting() &&
            compareStrings(emailOriginal, email) !== 0 &&
            !dataValidacaoEmail
          ) {
            msg +=
              '\nReenvio de e-mail de ativação realizado com sucesso. \nVerifique sua caixa de entrada e de spam, caso não encontre o e-mail.';
          }
          showInfo(this, titulo, msg, null, this.backToScreen);
        } catch (err) {
          showSaveError(this, titulo, err);
        }
      };

      if (this.isEditing()) {
        if (sameString(email, emailOriginal)) {
          await doSave();
        } else {
          showQuestion(
            this,
            titulo,
            `O login deste usuário será alterado de ${emailOriginal} para ${email}. Deseja prosseguir? `,
            email,
            async resp => {
              if (resp) {
                await doSave();
              } else {
                hideAlert(this);
              }
            }
          );
        }
      } else {
        // cadastro novo
        await doSave();
      }
    }
  };

  handleExcluir = () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }
    showQuestion(
      this,
      titulo,
      'Deseja realmente excluir esse funcionário?',
      null,
      async resp => {
        if (resp) {
          try {
            showWait(
              this,
              titulo,
              'Por favor, aguarde enquanto o funcionário é excluído.'
            );

            const response = await excluiFuncionario(this.state.codigoPessoa);

            if (response.notificationLevel === 1) {
              showInfo(
                this,
                titulo,
                'Funcionário excluído com sucesso!',
                null,
                this.backToScreen
              );
            } else {
              // showDeleteError(this, titulo, response.message);
              hideAlert(this);
              notify(response.message, 'error', 2000, 'top');
            }
          } catch (err) {
            showDeleteError(this, titulo, err);
          }
        } else {
          hideAlert(this);
        }
      }
    );
  };

  handleCancelar = async () => {
    if (this.isVerifying()) {
      this.backToScreen();
    } else {
      showQuestion(
        this,
        titulo,
        'Deseja realmente cancelar a operação?',
        null,
        resp => {
          if (resp) {
            this.backToScreen();
          } else {
            hideAlert(this);
          }
        }
      );
    }
  };

  makeCombo = (list, showCode, showNull) => {
    let combo = list.map(item => {
      return {
        value: item.codigo,
        label: item.descricao + `${showCode ? ' (' + item.codigo + ')' : ''}`
      };
    });
    if (showNull) {
      combo.unshift({ value: none, label: showNull });
    }
    return combo;
  };

  updatePerfis = async () => {
    const { data } = await montaComboPerfisAcesso();

    const perfisEmOrdemAlfabetica = data.sort(function(a, b) {
      const compareA = a.descricao.toUpperCase();
      const compareB = b.descricao.toUpperCase();
      if (compareA < compareB) {
        return -1;
      }
      if (compareA > compareB) {
        return 1;
      }
      return 0;
    });
    this.setState({
      perfis: this.makeCombo(
        perfisEmOrdemAlfabetica,
        false,
        'Selecione o perfil de acesso'
      )
    });
  };

  updateEstados = async () => {
    const { data: listaEstados } = await getEstados(this.state.codigoUF);
    this.setState({
      estados: this.makeCombo(listaEstados, false, 'Selecione um estado')
    });
  };

  updateCidades = async estado => {
    if (!estado) {
      this.setState({
        cidades: [
          {
            value: none,
            label: 'Selecione um estado primeiro'
          }
        ]
      });
    } else {
      const { data: listaCidades } = await getCidades(estado);
      this.setState({
        cidades: this.makeCombo(listaCidades, false, 'Selecione uma cidade')
      });
    }
  };

  changeEstado = event => {
    const codigoUF = event.target.value;
    this.setState({ codigoUF, codigoMunicipio: null, cidades: [] });
    this.updateCidades(codigoUF);
  };

  searchCep = async () => {
    const { data: dadosCep } = await getCep(this.state.cep);
    if (dadosCep && dadosCep.value) {
      const cep = dadosCep.value;
      let endereco =
        (cep.TIPO_LOGRADOURO ? String(cep.TIPO_LOGRADOURO).trim() + ' ' : '') +
        (!cep.OBS
          ? String(cep.LOGRADOURO).trim()
          : String(
              cep.LOGRADOURO.substring(0, cep.LOGRADOURO.indexOf(cep.OBS))
            ).trim());

      this.updateCidades(cep.ID_UF);
      this.setState({
        codigoUF: cep.ID_UF,
        endereco: String(endereco).toUpperCase(),
        bairro: String(cep.BAIRRO).toUpperCase(),
        codigoMunicipio: cep.COD_MUNICIPIO_IBGE,
        searchedCEP: true
      });
    } else {
      showError(
        this,
        titulo,
        'Cep "' + this.state.cep + '" não encontrado.',
        'cep'
      );
      this.setState({ searchedCEP: false });
    }
  };

  cpfIconeClick = async () => {
    const { cpf } = this.state;

    const { data: dataFunc } = await verifyFuncionario(cpf);
    if (dataFunc) {
      if (dataFunc.excluido) {
        showQuestion(
          this,
          titulo,
          `O CPF ${maskedCnpjCpf(cpf)}
                    já está cadastrado para a pessoa ${dataFunc.nome},
                    que foi excluída do sistema. Deseja restaurá-la?`,
          null,
          resp => {
            if (resp) {
              hideAlert(this);
              const func = apiToState(dataFunc);
              //this.setState(apiToState(response));
              this.setState(func);
              this.updateCidades(func.codigoUF);
            } else {
              hideAlert(this);
            }
          }
        );
      } else if (dataFunc.cpf === cpf) {
        showInfo(this, titulo, 'CPF ' + cpf + ' já cadastrado.', null);
      }
    } else {
      try {
        const { data: response } = await verifyPessoa(cpf);
        if (!response.excluido) {
          showQuestion(
            this,
            titulo,
            `O CPF ${maskedCnpjCpf(cpf)}
                        já está cadastrado para a pessoa ${response.nome}.
                        Deseja carregar os dados? \n As informações alteradas neste cadastro afetarão o cadastro da pessoa referênciada.`,
            null,
            resp => {
              if (resp) {
                hideAlert(this);
                response.rowVersion = null;
                const func = apiToState(response);
                //this.setState(apiToState(response));
                this.setState(func);
                this.updateCidades(func.codigoUF);
              } else {
                hideAlert(this);
              }
            }
          );
        } else if (validaCPF(cpf)) {
          this.setState({ stillVerifying: false });
        }
      } catch (e) {
        if (validaCPF(cpf)) {
          this.setState({ stillVerifying: false });
        }
      }
    }
  };

  getDataCadastroAsString = () => {
    if (this.state.dataCadastro) {
      try {
        return moment(this.state.dataCadastro).format('DD/MM/YYYY');
      } catch (error) {}
    }
    return '';
  };

  isVerifying = () => {
    return this.state.stillVerifying;
  }; // true se clicou no botão "+" e está digitando campo(s) chave
  isEditing = () => {
    return !this.isVerifying() && !!this.state.rowVersion;
  }; // (!! para garantir false ) true se  clicou em um item da list
  isInserting = () => {
    return !this.isVerifying() && !this.state.rowVersion;
  }; // true se clicou no botão "+" e já digitou campo(s)
  isRestoring = () => {
    return this.isEditing() && !!this.state.excluido;
  };

  reenviaEmailClickHandle = () => {
    const { emailOriginal, email } = this.state;
    if (compareStrings(emailOriginal, email) !== 0) {
      showWarning(
        this,
        titulo,
        'O e-mail foi alterado, para reenviar o e-mail de ativação, salve este cadastro.'
      );
    } else {
      showQuestion(
        this,
        titulo,
        'Deseja reenviar o e-mail de ativação para o e-mail ' +
          'cadastrado atualmente (' +
          emailOriginal +
          ')?',
        null,
        async resp => {
          try {
            if (resp) {
              //host/boas-vindas/{chave}/email/cnpj
              showWait(
                this,
                titulo,
                'Por favor, aguarde enquanto o e-mail é enviado.'
              );
              const url =
                window.location.origin +
                '/boas-vindas/{chave}/' +
                emailOriginal +
                '/' +
                getCnpj();
              const { data: result } = await reenviaEmail(
                emailOriginal,
                url,
                'Ativar'
              );
              if (result) {
                let msg = '';
                if (result.success) {
                  msg =
                    'Solicitação de reenvio feita com sucesso.\n' +
                    'Verifique sua caixa de entrada e de spam, ' +
                    'caso não encontre o e-mail.';
                  showInfo(this, titulo, msg);
                } else {
                  msg =
                    'Erro ao solicitar reenvio do e-mail.\n' + result.message;
                  showError(this, titulo, msg);
                }
              } else {
                hideAlert(this);
              }
            } else {
              hideAlert(this);
            }
          } catch (error) {
            showExecuteError(this, titulo, error);
          }
        }
      );
    }
  };

  render() {
    const {
      codigoPessoa,
      cpf,
      nome,
      apelido,
      dataNascimento,
      dataAdmissao,
      // dataCadastro,
      identidade,
      orgaoEmissor,
      ativo,

      cep,
      codigoUF,
      codigoMunicipio,
      endereco,
      numero,
      complemento,
      referencia,
      bairro,

      contatoNome,
      contatoDescricao,
      contatoDDD,
      contatoNumero,
      //contatoEMail,
      email,

      codigoPerfilAcesso,

      valorMinimoGeracaoVale,
      observacao,
      identfID,
      dataValidacaoEmail

      // rowVersion,
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="Identificação do funcionário">
              <div className="row">
                <div className="col-3">
                  <InputText
                    value={cpf}
                    name="cpf"
                    label="CPF"
                    type="number"
                    icone={`${
                      this.isVerifying() && validaCPF(cpf)
                        ? 'icon-lx-search'
                        : ''
                    }`}
                    onIconeClick={this.cpfIconeClick}
                    onKeyDown={event => {
                      if (event.keyCode === 13) this.cpfIconeClick();
                      else if (event.keyCode === 27) this.setState({ cpf: '' });
                    }}
                    format={maskCpf(cpf)}
                    onChange={this.handleInputChange}
                    disabled={!this.isVerifying()}
                    help={`${
                      this.isVerifying()
                        ? String(cpf).length === 11
                          ? validaCPF(cpf)
                            ? 'Clique na "lupa" para verificar o CPF'
                            : 'Não é um CPF válido'
                          : 'CPF (somente números) - Obrigatório'
                        : ''
                    }`}
                  />
                </div>
                <div className={ifShow(this.isEditing(), 'col-3')}>
                  <InputText
                    value={codigoPessoa}
                    name="codigoPessoa"
                    label="Código:"
                    onChange={this.handleInputChange}
                    disabled
                  />
                </div>
                <div className={ifShow(this.isEditing(), 'col-3')}>
                  <Checkbox label="Situação:">
                    <Checkitem
                      checked={ativo}
                      name="ativo"
                      label="Ativo"
                      onChange={this.handleInputChange}
                      disabled={this.isInserting()}
                    />
                  </Checkbox>
                </div>
              </div>

              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-6">
                  <InputText
                    value={nome}
                    name="nome"
                    label="Nome:"
                    maxlength={65}
                    help="Obrigatório"
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={apelido}
                    name="apelido"
                    label="Apelido:"
                    maxlength={30}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
                <div className="col-3">
                  <InputDate
                    value={dataNascimento}
                    name="dataNascimento"
                    label="Data de nascimento:"
                    onChange={this.handleInputChange}
                    onFocusChanged={this.validateData}
                    help="Obrigatório"
                  />
                </div>
              </div>

              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-3">
                  <InputText
                    value={identidade}
                    label="Identidade:"
                    name="identidade"
                    help="Obrigatório"
                    maxlength={15}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={orgaoEmissor}
                    name="orgaoEmissor"
                    label="Órgão emissor:"
                    maxlength={18}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
                <div className="col-3">
                  <InputDate
                    value={dataAdmissao}
                    name="dataAdmissao"
                    label="Data de admissão:"
                    onChange={this.handleInputChange}
                    onFocusChanged={this.validateData}
                    help="Obrigatório"
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={this.getDataCadastroAsString()}
                    name="dataCadastro"
                    label="Data do cadastro:"
                    onChange={this.handleInputChange}
                    disabled
                    help="Obrigatório" // precisa no campo desabilitado?
                  />
                </div>
              </div>
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-6">
                  <InputText
                    value={identfID}
                    name="identfID"
                    label="Cartão de identificação (RFID):"
                    maxlength={16}
                    //help="Apenas números e letras"
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title="Informações de Login"
              visible={!this.isVerifying()}
              accordion
              accordionDefaultDisplay={false}
            >
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-6">
                  <InputText
                    value={email}
                    label="E-mail:"
                    name="email"
                    maxlength={65}
                    help="Obrigatório"
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                  {this.isEditing() &&
                  !this.isRestoring() &&
                  !dataValidacaoEmail ? (
                    <div className="button-wrapper">
                      <Botao
                        title="Reenviar e-mail de ativação"
                        tooltip="Reenviar e-mail de ativação"
                        icon="icon-lx-envelope"
                        onClick={this.reenviaEmailClickHandle}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-6">
                  <Select
                    label="Perfil de Acesso:"
                    name="codigoPerfilAcesso"
                    //value={tipo}
                    value={codigoPerfilAcesso}
                    onChange={this.handleInputChange}
                    options={this.state.perfis}
                    help="Obrigatório"
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title="Configurações adicionais"
              visible={!this.isVerifying()}
              accordion
              accordionDefaultDisplay={false}
            >
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-6">
                  <InputText
                    value={valorMinimoGeracaoVale}
                    type="number"
                    name="valorMinimoGeracaoVale"
                    label="Valor mínimo para geração de vale:"
                    maxlength={9}
                    decimalScale={2}
                    //format="######,##"
                    allowNegative={false}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className={ifShow(!this.isVerifying(), 'row')}>
                <div className="col-12">
                  <InputText
                    value={observacao}
                    name="observacao"
                    label="Observação:"
                    maxlength={255}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title="Endereço"
              visible={!this.isVerifying()}
              accordion
              accordionDefaultDisplay={false}
            >
              <div className="row">
                <div className="col-4">
                  <InputText
                    value={cep}
                    label="CEP:"
                    name="cep"
                    type="number"
                    format="#####-###"
                    icone={`${
                      String(cep).length === 8 ? 'icon-lx-search' : ''
                    }`}
                    onIconeClick={this.searchCep}
                    onChange={this.handleInputChange}
                    help={`${
                      String(cep).length === 8
                        ? 'Clique na "lupa" para buscar o CEP'
                        : 'Obrigatório'
                    }`}
                    //onBlur={this.searchCep}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <InputText
                    value={endereco}
                    label="Endereço:"
                    name="endereco"
                    maxlength={65}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                    help="Obrigatório"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <InputText
                    value={numero}
                    label="Número:"
                    name="numero"
                    type="number"
                    decimalScale={0}
                    maxlength={7}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={complemento}
                    label="Complemento:"
                    name="complemento"
                    maxlength={45}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
                <div className="col-6">
                  <InputText
                    value={referencia}
                    label="Referência:"
                    name="referencia"
                    maxlength={45}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputText
                    value={bairro}
                    label="Bairro:"
                    name="bairro"
                    maxlength={45}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                    help="Obrigatório"
                  />
                </div>
                <div className="col-3">
                  <Select
                    value={codigoUF}
                    name="codigoUF"
                    label="UF:"
                    options={this.state.estados}
                    onChange={this.changeEstado}
                    disabled={this.state.searchedCEP}
                    help="Obrigatório"
                  />
                </div>
                <div className="col-3">
                  <Select
                    value={codigoMunicipio}
                    name="codigoMunicipio"
                    label="Cidade:"
                    options={this.state.cidades}
                    onChange={this.handleInputChange}
                    disabled={this.state.searchedCEP}
                    help="Obrigatório"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <InputText
                    value={contatoNome}
                    label="Contato:"
                    name="contatoNome"
                    maxlength={50}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={contatoDescricao}
                    label="Descrição:"
                    name="contatoDescricao"
                    maxlength={50}
                    onChange={this.handleInputChange}
                    onKeyDown={makeInputUpperCase}
                    onKeyUp={makeInputUpperCase}
                    onKeyPress={makeInputUpperCase}
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={contatoDDD}
                    label="DDD:"
                    name="contatoDDD"
                    type="number"
                    decimalScale={0}
                    maxlength={2}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-3">
                  <InputText
                    value={contatoNumero}
                    label="Telefone:"
                    name="contatoNumero"
                    type="number"
                    maxlength={15}
                    decimalScale={0}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>

        <Footer
          saveAction={
            !this.isInserting() && !this.isEditing()
              ? null
              : this.isInserting()
              ? this.handleSalvar
              : menuPermiteAlteracao(PATH_MENU)
              ? this.handleSalvar
              : null
          }
          cancelAction={this.handleCancelar}
          deleteAction={
            !this.isEditing()
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
        />

        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class Funcionarios extends Component {
  state = {
    funcs: [],
    funcSel: {},
    searchText: '',
    lastSearch: null,
    listas: [],
    hasSearched: false
  };

  loadFuncs = async searchText => {
    this.setState({ searchText})
    const funcs = [];
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto a pesquisa é realizada.'
    );
    try {
      const { data } = await getFuncionarios({ search: searchText });

      data.result.forEach(f => {
        funcs.push(apiToState(f));
      });
      this.setState({ lastSearch: searchText, funcs, hasSearched: true });
    } finally {
      hideWait(this);
    }
    //}
  };

  limparCadastro = () => {
    this.setState({ funcSel: {} });
  }

  setarValorCampoBusca = (texto) => {
    this.setState({ searchText: texto });
  }

  componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      if (this.state.hasSearched) {
        this.setState({ searchText: this.state.lastSearch });
        this.loadFuncs();
      }
    }
  }

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      this.setState({ funcSel: rowInfo.original });
      this.props.history.push('/cadastro/funcionarios/new');
    }
  };

  render() {
    const { edit } = this.props,
      { funcSel, searchText } = this.state;
    this.handleInputChange = handleInputChange.bind(this);

    return (
      <>
        {edit ? (
          <Form func={funcSel} funcsExistentes={this.state.funcs} />
        ) : (
          <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
            <SectionHeader
              title=""
              right={
                <div className={ifShow(!edit, 'button-container')}>
                  <ScreenHeader search={true} pathMenu={PATH_MENU} newButton={true} texto="Novo Cadastro" newURL={"/cadastro/funcionarios/new"} searchText={searchText} load={this.loadFuncs} limparCadastro={this.limparCadastro}  setarValorCampoBusca={this.setarValorCampoBusca} />
                </div>
              }
            />
            <>
              <List
                defaultPageSize={10}
                onClick={this.handleTableClick}
                noDataText={`${
                  !this.state.lastSearch
                    ? 'Inicie fazendo uma busca ou incluindo um novo funcionário.'
                    : 'Sua pesquisa "' +
                      this.state.lastSearch +
                      '" não encontrou nenhuma pessoa correspondente.'
                }`}
                cols={[
                  {
                    accessor: 'codigoPessoa',
                    Header: 'Código',
                    width: 100
                  },
                  {
                    accessor: 'nome',
                    Header: 'Nome',
                    width: 400
                  },
                  {
                    accessor: 'apelido',
                    Header: 'Apelido',
                    width: 320
                  },
                  {
                    accessor: 'cpf',
                    Header: 'CPF',
                    width: 160,
                    Cell: props => <div>{maskedCnpjCpf(props.value)}</div>
                  },
                  {
                    accessor: 'ativo',
                    Header: 'Ativo',
                    width: 100,
                    filterable: false,
                    Cell: ({ row }) => {
                      return (
                        <Botao
                          secondary={!row.ativo}
                          ic
                          icon={row.ativo ? 'icon-lx-check' : 'icon-lx-close'}
                        />
                      );
                    }
                  }
                ]}
                rows={this.state.funcs}
              />
            </>
          </LinxPostos>
        )}
        {tagAlert(this)}
      </>
    );
  }
}

function ifShow(condition, divName) {
  return condition ? divName : 'content-hidden';
}

Funcionarios = withRouter(Funcionarios);
export { Funcionarios };
