import axios from 'axios';
import {
  getToken
} from '../../../../authentication/Auth';

import {
  BASE_URL_GATEWAY
} from '../../../../shared/constants/GatewayUrlConstant';

import moment from 'moment';

export const movimentacaoEstoqueService = {
  getReports,
  getReport,
  getSituacoesProduto,
  getProdutos,
  getPosicaoEstoque,
  getOperacaoEstoque,
  searchProdutos,
  getPessoas,
  getEstruturaMercadologica,
  find,
  listarResultadoPosicaoEstoque
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function find(appliedFilters) {
  const response = await urlLinxCore.post('/MovimentoEstoque/ListarComFiltro', {
      query: appliedFilters
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

function getReport() {
  let dataHoraAtual = moment().format('YYYY-MM-DDTHH:mm:ss');
  
  return [{
    id: 1,
    title: 'POSIÇÃO DE ESTOQUE',
    subtipo: 1,
    preReports: [{
        id: 1,
        title: 'HOJE',
        icon: 'icon-lx-clock',
        type: 1,
        dateTime: dataHoraAtual
      },
      {
        id: 2,
        title: 'ESTOQUE NEGATIVO',
        icon: 'icon-lx-remove',
        type: 0,
        dateTime: dataHoraAtual,
        posicaoEstoque: 1
      },
      {
        id: 3,
        title: 'ESTOQUE POSITIVO',
        icon: 'icon-lx-plus',
        type: 0,
        dateTime: dataHoraAtual,
        posicaoEstoque: 0
      },
      {
        id: 4,
        title: 'ESTOQUE ZERADO',
        icon: 'icon-lx-exclamation-triangle',
        type: 0,
        dateTime: dataHoraAtual,
        posicaoEstoque: 2
      }
    ]
  }]
}

async function getSituacoesProduto() {
  const response = await urlLinxCore.get('/produto/ListarSituacaoProduto');
  return response.data.data;
}

async function searchProdutos(searchTerm) {
  const response = await urlLinxCore.post('/produto/listarComFiltro', {
    query: {
      descricao: searchTerm,
      subtipos: [1, 2, 6, 7, 8, 9, 10, 11, 12, 13]
    }
  });
  return response.data.data;
}

async function getProdutos() {
  const response = await urlLinxCore.post('/produto/ListarComFiltro', {
    query: {
      subtipos: [1, 2, 6, 7, 8, 9, 10, 11, 12, 13]
    }
  });
  return response.data.data;
}

async function getPosicaoEstoque() {
  const response = await urlLinxCore.get('/estoque/ListarPosicaoEstoque');
  return response.data.data;
}

async function getOperacaoEstoque() {
  const response = await urlLinxCore.get('/Operacao/ListarOperacaoesEstoque');
  return response.data.data;
}

async function getEstruturaMercadologica() {
  const response = await urlLinxCore.get('/EstruturaMercadologica/ListarSimples');
  return response.data.data;
}

async function getPessoas() {
  const response = await urlLinxCore.get('/Pessoa/listar');
  return response.data.data;
}

function getReports() {
  return [
    {
         id: 111,
         title: 'PERSONALIZAR RELATÓRIO',
         icon: 'icon-lx-search',
         type: 2
       },
     {
       id: 1,
       title: 'POSIÇÃO DE ESTOQUE',
       icon: 'icon-lx-checklist',
       type: 0
     }
  ]
}

async function listarResultadoPosicaoEstoque(appliedFilters) {
  const response = await urlLinxCore.post('/MovimentoEstoque/ListarResultadoPosicaoEstoque', {
      query: appliedFilters
    })
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}
