import { CharToInt, IntToChar } from './utils';
export function CheckIEAP(ie) {
  if (ie.length !== 9) return false;
  var p = 0,
    d = 0,
    i = ie.substring(1, 8);
  if (i >= 3000001 && i <= 3017000) {
    p = 5;
    d = 0;
  } else if (i >= 3017001 && i <= 3019022) {
    p = 9;
    d = 1;
  }
  var b = 9;
  var soma = p;
  i = 0;
  for (i = 0; i <= 7; i++) {
    soma += CharToInt(ie.charAt(i)) * b;
    b--;
  }
  var dig = 11 - (soma % 11);
  if (dig === 10) {
    dig = 0;
  } else if (dig === 11) {
    dig = d;
  }
  return IntToChar(dig) === ie.charAt(8);
} //ap
