import axios from "axios";
import { getToken } from "../../../../../authentication/Auth";
import { BASE_URL_GATEWAY } from "../../../../../shared/constants/GatewayUrlConstant";

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});


const consultSubscription = async (estaCod) => {

  try {
    const response = await urlLinxCore.get(`Stone/ConsultarDDA/?estaCod=${estaCod}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }

}

const subscribeDDA = async (estaCod) => {
  try {
    var payload = {
      estaCod: estaCod
    }
    const response = await urlLinxCore.post(`Stone/InscreverDDA`, payload);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const cancelDDA = async (estaCod) => {
  try {

    const response = await urlLinxCore.delete(`Stone/CancelarDDA/?estaCod=${estaCod}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

const listDDATitles = async (estaCod) => {
  try {
    const response = await urlLinxCore.get(`Stone/ConsultarBoletosDDA/?estaCod=${estaCod}`);

    return response.data;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        notificationLevel: 0,
        data: false,
        message: error.response.data,
      }
    } else if (error.request) {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: "Nenhuma resposta recebida para a solicitação",
      }
    } else {
      return {
        status: 0,
        notificationLevel: 0,
        data: false,
        message: error.message || "An unknown error occurred",
      }
    }
  }
}

export const DDAService = {
  consultSubscription,
  subscribeDDA,
  cancelDDA,
  listDDATitles
};


