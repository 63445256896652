import axios from 'axios';

import { BASE_URL_GATEWAY } from '../../shared/constants/GatewayUrlConstant';

const usuariosAxios = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/usuarios/api/`
});


export const usuariosRequestService = {
  login,
  loginV2,
  loginV3,
  loginV4,
  token
}

async function login(data) {
  const response = await usuariosAxios.put('login', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function loginV2(data) {
  const response = await usuariosAxios.put('v2/login', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function loginV3(data) {
  const response = await usuariosAxios.put('v3/login', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function loginV4(data) {
  const response = await usuariosAxios.put('v4/login', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}

async function token(data) {
  const response = await usuariosAxios.put('v1/login/oauth/token', data)
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response.data
    })
  return response;
}
