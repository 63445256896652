import { CharToInt } from './utils';
export function CheckIEMT(ie) {
  if (ie.length < 9) return false;
  var die = ie;
  if (die.length < 11) {
    while (die.length <= 10) die = '0' + die;
    var nro = new Array(11);
    for (var i = 0; i <= 10; i++) nro[i] = CharToInt(die[i]);
    var b = 3;
    var soma = 0;
    for (i = 0; i <= 9; i++) {
      soma += nro[i] * b;
      b--;
      if (b === 1) b = 9;
    }
    i = soma % 11;
    var dig = 0;
    if (i <= 1) dig = 0;
    else dig = 11 - i;
    return dig === nro[10];
  }
} //MT
