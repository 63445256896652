import { LoadPanel } from 'devextreme-react';

import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import 'jspdf-autotable';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ptMessages from '../../../../../assets/js/pt.json';
import DateTimeBox from '../../../../components/DateTimeBox/DateTimeBox';
import FilterPanel from '../../../../components/Report/FilterPanel/FilterPanel';
import ResultPanel from '../../../../components/Report/ResultPanel/ResultPanel';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import DateToolTip from '../../../../components/dateTooltip/DateToolTip';
import ListToolTip from '../../../../components/listToolTip/ListToolTip';
import { reportMangerService, renderSummarySumDefault } from '../../../../services/ReportManager.service';
import { NotificationLevel } from '../../../../shared/constants/Const';
import { menus } from '../../../../shared/constants/MenuConstants';
import { TIPO_SITE } from '../../../../shared/constants/TipoSiteConstant';
import { getNomePagina, getNomePaginaAmigavel } from '../../../../shared/utils/Utils';
import styles from './MovimentacaoTurnoPedidoDinamico.module.scss';
import { MovimentacaoTurnoPedidoDinamicoService } from './MovimentacaoTurnoPedidoDinamico.service';
import { Summary, TotalItem, GroupItem } from 'devextreme-react/data-grid';

export function MovimentacaoTurnoPedidoDinamico(props) {
  const PATH_MENU = menus.COD_16520;
  const CodigoFuncionalidade = 80680;
  const gridResultRef = useRef(null);
  const [showSearch, setShowSearch] = useState(false);
  const [disabledSearch, setDisabledSearch] = useState(false);
  const [searching, setSearching] = useState(false);
  const [startDate] = useState(new Date().toISOString());
  const [endDate] = useState(new Date().toISOString());
  const [filters, setFilters] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [serverReports, setServerReports] = useState([]);
  const [serverSubReports, setSubServerReports] = useState([]);
  const [chosenReport, setChosenReport] = useState([]);
  const [chosenSubReport, setChosenSubReport] = useState([]);
  const [columns, setColumns] = useState([]);
  const [title, setTitle] = useState([]);

  const [appliedFilters, setAppliedFilters] = useState({
    dataInicio: startDate,
    dataFim: endDate,
    empresas: [],
    multiEmpresa: false,
  });

  config({
    defaultCurrency: 'BRL',
  });

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [appliedFilters, startDate, endDate]);

  useEffect(() => {
    const colunas = MovimentacaoTurnoPedidoDinamicoService.GetColumns(false);
    setColumns(colunas);

    async function GetReports() {
      const result = await reportMangerService.Get(CodigoFuncionalidade);

      if (result.notificationLevel === NotificationLevel.Success) {
        let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
        if (TIPO_SITE === 'RetaguardaEmporioPro') reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');

        setServerReports(reports);
      }
    }

    GetReports();
  }, []);

  const find = async () => {
    if (!appliedFilters) {
      notify('Informe ao menos um filtro para pesquisa.', 'error', 2000, 'top');
      return;
    }

    setFilters({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: false,
    });
    await request({
      dataInicio: appliedFilters.dataInicio,
      dataFim: appliedFilters.dataFim,
      multiEmpresa: false,
    });

    updateReportInfo(chosenReport);
  };

  const request = async model => {
    try {
      setSearching(true);

      const response = await MovimentacaoTurnoPedidoDinamicoService.find(model);

      notify(`Foram encontrados [${response.itens.length}] registros para esta pesquisa.`, 'success', 2000, 'top');

      if (!response.itens.length) {
        notify('Não foram encontrados registros para esta pesquisa.', 'info', 2000, 'top');
      } else {
        setResultData(response.itens);
      }

      setShowResult(true);
      setSearching(false);
    } catch (error) {
      setSearching(false);
      notify(error.message, 'info', 2000, 'top');
    }
  };

  const subReportHandle = async report => {
    setSearching(true);

    setChosenSubReport(report);

    let model = reportMangerService.FiltersToModel(report.data.filtros);
    let periodCreated = reportMangerService.CreatePeriod(report.data.periodo);

    refreshColumns(report.data.colunas);

    model = {
      ...model,
      dataInicio: periodCreated.dataInicio,
      dataFim: periodCreated.dataFim,
      multiEmpresa: false,
    };

    setFilters(model);

    await request(model);

    updateReportInfo(report);
  };

  const refreshColumns = newColumns => {
    let refreshedColuns = reportMangerService.RefreshColumns(columns, newColumns);
    setColumns(refreshedColuns);
  };

  const reportHandle = report => {
    setChosenReport(report);

    if (report.tipo === 0) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
      subReportHandle(report);
    }
  };

  const onValueChanged = event => {
    if (!moment(event.value).isValid()) {
      setDisabledSearch(true);
      notify('Data inválida.', 'error', 2000, 'top');
    } else {
      setAppliedFilters(filters => {
        return {
          ...filters,
          [event.element.id]:
            event.element.id === 'dataFim' ? moment(event.value).format('YYYY-MM-DD') + 'T23:59:59' : moment(event.value).format('YYYY-MM-DD') + 'T00:00:00',
        };
      });
      setDisabledSearch(false);
    }
  };

  const clearFilters = () => {
    setAppliedFilters({
      dataInicio: new Date().toISOString(),
      dataFim: new Date().toISOString(),
      multiEmpresa: false,
    });
    notify('Todos os filtros aplicados foram removidos.', 'info', 2000, 'top');
  };

  const updateReportInfo = report => {
    setTitle(`RESULTADO - ${report.titulo}`);

    if (report.tipo === 0) {
      setSubServerReports([]);
      report.data = { periodo: { tipo: 0 } };
      report.publico = false;
      setChosenSubReport(report);
      refreshColumns(MovimentacaoTurnoPedidoDinamicoService.GetColumns(false));
    }
  };

  const refreshReports = async () => {
    const result = await reportMangerService.Get(CodigoFuncionalidade);

    if (result.notificationLevel === NotificationLevel.Success) {
      let reports = result.data.filter(rep => rep.titulo === 'PERSONALIZAR' || rep.subReport.length > 0);
      if (TIPO_SITE === 'RetaguardaEmporioPro') reports = reports.filter(rep => rep.titulo !== 'COMBUSTÍVEIS');
      setServerReports(reports);

      let report = result.data.find(x => {
        return x.tipo === 1;
      });

      if (report) {
        let sub = report.subReport.find(x => {
          return x.id === chosenReport.id;
        });

        if (sub) {
          setChosenReport(sub);
          subReportHandle(sub);
        } else {
          setShowResult(false);
        }
      }
    }
  };

  const resultGrid = () => {
    return (
      <ResultPanel
        dataSource={resultData}
        columns={columns}
        saveReport={true}
        gridResultRef={gridResultRef}
        codigoRetaguarda={CodigoFuncionalidade}
        fileName="MovimentacaoTurnoPedido"
        chosenSubReport={chosenSubReport}
        refreshReports={refreshReports}
        changeChosenSubReport={reportHandle}
        filtroOutros={[
          {
            chave: 0,
            titulo: 'Nenhum Filtro Adicional',
            valor: [], //filters.deposito || [],
            campo: 'Nenhum Filtro Adicional',
          },
        ]}
        disabled={searching}
        panelTitle={title}
        allowExpand={true}
        reportTitle={getNomePaginaAmigavel(PATH_MENU)}
        filters={true}
        summary={() => renderSummarySumDefault(columns)} 
      >
        <DateToolTip inicial={filters.dataInicio} final={filters.dataFim} />
        <ListToolTip
        //data={filters.produtos}
        //message={'Produtos selecionados'}
        />
      </ResultPanel>
    );
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
      />
      <FilterPanel
        onSearch={find}
        clearFilter={clearFilters}
        reports={serverReports}
        searching={searching}
        showSearch={showSearch}
        chosenReport={chosenReport}
        reportHandle={reportHandle}
        subReportHandle={subReportHandle}
        disabledSearch={disabledSearch}
        subReports={serverSubReports}
      >
        <div className="row">
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>De</span>
                <DateTimeBox
                  type="date"
                  id="dataInicio"
                  date={startDate}
                  disabled={searching}
                  value={appliedFilters.dataInicio}
                  onValueChanged={onValueChanged}
                />
              </div>
            </div>
          </div>
          <div className={`col-2`}>
            <div className={styles.dateWrapper}>
              <div className={styles.datebox}>
                <span>Até</span>
                <DateTimeBox type="date" id="dataFim" date={endDate} disabled={searching} value={appliedFilters.dataFim} onValueChanged={onValueChanged} />
              </div>
            </div>
          </div>
        </div>
      </FilterPanel>
      {showResult > 0 ? resultGrid() : <></>}
    </LinxPostos>
  );
}

export default withRouter(MovimentacaoTurnoPedidoDinamico);
