import React from 'react';
import { TextBox } from 'devextreme-react';
import {
  maskedCnpj,
  MaskedPhoneNumber
} from '../../../../shared/utils/Utils';
import { raioxService } from '../RaioX.service';
import styles from './RaioXModal.module.scss';


export default function RaioXModalAdesaoCampos({empresa}) {
  console.log('empresa', empresa);
  const {
    //dados da empresa
    nomeFantasia,
    razaoSocial,
    cpfCnpj,
    inscricaoEstadual,
    inscricaoMunicipal,
    cnae,

    //endereço
    endereco,

    //Operador - usuário proprietário
    operadores

  } = empresa;

  return (
    <>
      <div className="row">
        <div className={styles.subTitle}>
          <h1>Dados da empresa</h1>
          <span>Revise os dados abaixo da empresa para a adesão!</span>
          {
            raioxService.existeCampoObrigatorioEmpresaVazio(empresa)
            && (
              <>
                <br />
                <span style={{color: 'red'}}>Existem campos obrigatórios não preenchidos nesta seção. Favor preencher os campos que estão indicados em vermelho no cadastro da empresa.</span>
              </>
            )
          }
        </div>
      </div>
      <div className="row">
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
           <span>Nome fantasia (*)</span>
          <TextBox
            value={nomeFantasia}
            maxLength={100}
            disabled
            isValid={Boolean(nomeFantasia)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Razao social (*)</span>
          <TextBox
            value={razaoSocial}
            maxLength={100}
            disabled
            isValid={Boolean(razaoSocial)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>CNPJ (*)</span>
          <TextBox
            value={cpfCnpj && maskedCnpj(cpfCnpj)}
            maxLength={100}
            disabled
            isValid={Boolean(cpfCnpj)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Inscrição estadual</span>
          <TextBox
            value={inscricaoEstadual}
            maxLength={100}
            disabled
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Inscrição municipal</span>
          <TextBox
            value={inscricaoMunicipal}
            maxLength={100}
            disabled
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>CNAE</span>
          <TextBox
            value={cnae}
            maxLength={100}
            disabled
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className={styles.subTitle}>
          <h1>Endereço</h1>
          <span>Revise os dados de endereço da empresa para a adesão.</span>
          {
            raioxService.existeCampoObrigatorioEnderecoVazio(empresa)
            && (
              <>
                <br />
                <span style={{color: 'red'}}>Existem campos obrigatórios não preenchidos nesta seção. Favor preencher os campos que estão indicados em vermelho no cadastro da empresa.</span>
              </>
            )
          }
        </div>
      </div>
      <div className="row">
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>CEP</span>
          <TextBox
            value={endereco.cep}
            maxLength={100}
            disabled
          />
        </div>
        <div className={`${styles.input} col-md-8 form-group mb-3`}>
          <span>Endereço (*)</span>
          <TextBox
            value={endereco.logradouro}
            maxLength={100}
            disabled
            isValid={Boolean((endereco || {}).logradouro)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Número</span>
          <TextBox
            value={`${endereco.numero}`}
            maxLength={100}
            disabled
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Complemento</span>
          <TextBox
            value={endereco.complemento}
            maxLength={100}
            disabled
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Bairro (*)</span>
          <TextBox
            value={endereco.bairro}
            maxLength={100}
            disabled
            isValid={Boolean((endereco || {}).bairro)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Cidade (*)</span>
          <TextBox
            value={endereco.localidade.descricao}
            maxLength={100}
            disabled
            isValid={Boolean(((endereco || {}).localidade || {}).descricao)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>UF (*)</span>
          <TextBox
            value={endereco.unidadeFederativa.sigla}
            maxLength={100}
            disabled
            isValid={Boolean(((endereco || {}).unidadeFederativa || {}).sigla)}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className={styles.subTitle}>
          <h1>Operador</h1>
          <span>Revise os dados do operador para a adesão e clique em "Aderir ao Raio-X".</span>
          {
            raioxService.existeCampoObrigatorioFuncionarioVazio(empresa)
            && (
              <>
                <br />
                <span style={{color: 'red'}}>Existem campos obrigatórios não preenchidos nesta seção. Favor preencher os campos que estão indicados em vermelho no cadastro de funcionários.</span>
              </>
            )
          }
        </div>
      </div>
      <div className="row">
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Nome (*)</span>
          <TextBox
            value={operadores[0].nome}
            maxLength={100}
            disabled
            isValid={Boolean(operadores[0].nome)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>E-mail (*)</span>
          <TextBox
            value={operadores[0].email}
            maxLength={100}
            disabled
            isValid={Boolean(operadores[0].email)}
          />
        </div>
        <div className={`${styles.input} col-md-4 form-group mb-3`}>
          <span>Telefone (*)</span>
          <TextBox
            value={operadores[0].telefone && MaskedPhoneNumber(operadores[0].telefone)}
            maxLength={100}
            disabled
            isValid={Boolean(operadores[0].telefone)}
          />
        </div>
      </div>
    </>
  )
}
