import { LoadPanel } from 'devextreme-react';
import DataGrid, { Column, Export } from 'devextreme-react/data-grid';
import { locale } from 'devextreme/localization';
import { alert } from 'devextreme/ui/dialog';
import React, { createRef, useCallback, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { ComodatoService } from '../../../../services/con-comodato/Comodato.service';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../../shared/utils/Utils';
import styles from './RelatorioControleDeAtivos.module.scss';
import { DateInput } from './components/DateInput';
import { InputText } from './components/InputText';
import { SelectProduto } from './components/SelectProduto';
import { SelectStatusPedido } from './components/SelectStatusPedido';
import { ExportarParaExcelService } from './services/ExportarParaExcell.service';
import { ExportarParaPdfService } from './services/ExportarParaPdf.service';

export function RelatorioControleDeAtivos(props) {
  locale('pt-BR');
  const PATH_MENU = menus.COD_16360;
  const [searching, setSearching] = useState(false);
  const [filtroAtual, setFiltroAtual] = useState('Sem Filtro');
  const [data, setData] = useState([]);
  const refDtSaida = createRef();
  const refDtEntrada = createRef();
  const gridResultRef = createRef();

  const txtProdutoRef = createRef();
  const txtStatusRef = createRef();
  const txtSerieRef = createRef();

  const normalizeData = d => {
    var ret = [];
    d.forEach(x => {
      const saida = Object.assign({}, x);
      saida.dataEntradaSaida = x.dataSaida;
      saida.operacao = 'SAÍDA COMODATO';
      saida.documento = x.documentoSaida;
      const entrada = Object.assign({}, x);
      entrada.dataEntradaSaida = x.dataEntrada;
      entrada.operacao = 'RETORNO COMODATO';
      entrada.documento = x.documentoEntrada;

      ret.push(saida);
      if (x.tipoOperacaoEntrada) ret.push(entrada);
    });
    return ret;
  };

  const onBuscar = useCallback(async () => {
    try {
      setSearching(true);
      const produto = txtProdutoRef.current.selectedItem();
      const produtoId = produto ? produto.id : null;
      const dataSaida = refDtSaida.current.getValue();
      const dataEntrada = refDtEntrada.current.getValue();
      const statusIds = txtStatusRef.current.ids();
      const statusNomes = txtStatusRef.current.nomes();
      const serie = txtSerieRef.current.getValue();
      const list = await ComodatoService.getInstance().relatorioControleAtivos(produtoId, serie, statusIds, dataEntrada, dataSaida);

      const filter = factoryFiltro(produto, serie, statusNomes, dataEntrada, dataSaida);
      setFiltroAtual(filter);
      setData(normalizeData(list));
      setSearching(false);
    } catch (error) {
      setSearching(false);
      alert(error.message, 'Ops!');
    }
  }, [txtProdutoRef, refDtEntrada, refDtSaida, txtStatusRef, txtSerieRef]);

  const onLimparFiltro = useCallback(() => {
    setData([]);
    txtProdutoRef.current.limpar();
    txtSerieRef.current.setValue('');
    txtStatusRef.current.limpar();
    refDtEntrada.current.setValue(null);
    refDtSaida.current.setValue(null);
  }, [txtProdutoRef, txtSerieRef, txtStatusRef, refDtEntrada, refDtSaida]);

  const onExporting = useCallback(
    async e => {
      if (e.format === 'xlsx') {
        await new ExportarParaExcelService().execute(e, 'Controle de Ativos', filtroAtual, 'Relatorio_ControleDeAtivos');
      } else {
        await new ExportarParaPdfService().execute(e, 'Controle de Ativos', filtroAtual, 'Relatorio_ControleDeAtivos');
      }

      e.cancel = true;
    },
    [filtroAtual],
  );

  const factoryFiltro = (produto, serie, statusNomes, dataEntrada, dataSaida) => {
    let filter = 'Filtrado por: ';
    if (produto) {
      filter += `Produto: ${produto.descricao}`;
    }
    if (dataSaida) {
      filter += `${filter.length > 15 ? ' - ' : ''}Data de Saída: ${new Date(dataSaida).toLocaleDateString()}`;
    }
    if (dataEntrada) {
      filter += `${filter.length > 15 ? ' - ' : ''}Data de Entrada: ${new Date(dataEntrada).toLocaleDateString()}`;
    }
    if (statusNomes) {
      filter += `${filter.length > 15 ? ' - ' : ''}status: ${statusNomes}`;
    }
    if (serie) {
      filter += `${filter.length > 15 ? ' - ' : ''}Número de série: ${serie}`;
    }
    if (filter.length < 15) return 'Sem Filtro';
    return filter;
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
      />
      <div className={styles.container}>
        <Card>
          <Card.Header className="text-center">
            <h1>RELATÓRIO DE CONTROLE DE ATIVOS</h1>
            <Row className="p-0">
              <Col md={4} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                <Row className="m-0 pl-0 pt-0 pb-0 pr-3">
                  <Col md={12} className="pl-0 pr-0">
                    <SelectProduto ref={txtProdutoRef} tipoProduto={9}></SelectProduto>
                  </Col>
                  <Col md={12} className="pl-0 pr-0">
                    <SelectStatusPedido ref={txtStatusRef}></SelectStatusPedido>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                <Row className="m-0 pl-0 pt-0 pb-0 pr-3">
                  <Col md={12} className="pl-0 pr-0">
                    <DateInput
                      ref={refDtSaida}
                      isMinTime={true}
                      displayFormat="dd/MM/yyyy"
                      placeholder="Data de saída:"
                      label="Data de Saída"
                      initialValue={null}
                      id="dataEventoDe"
                    />
                  </Col>
                  <Col md={12} className="pl-0 pr-0">
                    <DateInput
                      ref={refDtEntrada}
                      isMinTime={false}
                      displayFormat="dd/MM/yyyy"
                      label="Data de Entrada"
                      placeholder="Data de Entrada:"
                      initialValue={null}
                      id="dataEventoAte"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                <Row className="m-0 pl-0 pt-0 pb-0 pr-3 h-100">
                  <Col md={12} className="pl-0 pr-0 pb-0">
                    <InputText id="txtNumeroDeSerie" ref={txtSerieRef} label="Número de Série" placeholder="Digite o número de série"></InputText>
                  </Col>
                  <Col md={6} className="pl-0 pr-0">
                    <button onClick={onBuscar} className="btn btn-primary w-100">
                      <i className="icon icon-lx-search"></i> APLICAR FILTRO
                    </button>
                  </Col>
                  <Col md={6} className="pl-0 pr-0">
                    <button onClick={onLimparFiltro} className="btn btn-secondary  w-100">
                      <i className="icon lg icon-lx-close"></i> LIMPAR
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col md={12} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                {filtroAtual}
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <DataGrid
              ref={gridResultRef}
              dataSource={(data || []).map(it => {
                if (!it.documento) it.documento = '---';
                return it;
              })}
              onExporting={onExporting}
              noDataText="Sem dados"
            >
              <Column dataField="empresa" caption="Estabelecimento" />
              <Column dataField="dataEntradaSaida" caption="Data Entrada/Saida" dataType="date" format={'dd/MM/yyyy'} width={120} />
              <Column dataField="numeroPedido" caption="Pedido" width={100} alignment={'left'} />
              <Column dataField="status" caption="Status" width={150} />
              <Column dataField="operacao" caption="Operação" width={150} />
              <Column dataField="documento" caption="NF-e" width={100} />
              <Column dataField="ean" caption="EAN" width={100} />
              <Column dataField="descricao" caption="Descrição" />
              <Column dataField="serie" caption="Número de Série" width={120} />
              <Column dataField="cliente" caption="Cliente" />
              <Export
                enabled={true}
                texts={{ exportAll: 'Exporte tudo para {0}', exportSelectedRows: 'Exporte a seleção para {0}', exportTo: 'Exporte para {0}' }}
                formats={['pdf', 'xlsx']}
                fileName={'RelatorioRoyalties'}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </div>
    </LinxPostos>
  );
}
