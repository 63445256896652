import React, { useState, useEffect } from 'react';
import { applicationService } from '../../../services/core/Application.service';
import styles from './Sidenav.module.scss';
import logo from '../../../../assets/images/linx/Logo.png';
import { getSystemInfoByTipoSite } from '../../../shared/utils/Utils';
import { useHistory } from 'react-router-dom';
import MenuPai from './MenuPai';

export default function Sidenav(props) {
  const history = useHistory();
  const [menus, setMenus] = useState([]);
  const [menuOpened, setMenuOpened] = useState(false);
  const [currentOpenMenu, setCurrentOpenMenu] = useState(null);
  const [searchPanelOpened] = useState(false);

  const systemInfo = getSystemInfoByTipoSite();

  useEffect(() => {
    //Sempre que "props.favoriteCount" mudar deverá executar novamente este useEffect.
    getMenus();
    //A função getMenus() nunca muda, então não tem motivo para colocar ela na lista de dependência, portando o alerta do eslint está sendo ignorado.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.favoriteCount]);

  const getMenus = () => {
    let menus = applicationService.getMenus();
    menus = menus.filter(m => m.codigo !== 40000 && m.codigo !== 50000); //Removendo os menus de Favoritos e de Configurações e o Menu Ajuda / Portal Financeiro
    let menusEmporio = menus.slice();

    setMenus(menusEmporio);

    if (menus) {
      menus.map(menu => {
        let children = menu.children.length;
        //100000 = Favoritos
        if (menu.codigo === 100000) {
          props.setExisteMenuFavoritos(true);
        }
        return children;
      });
    }
  };

  const clickSideMenu = menu => {
    console.log(menu);
    if ((menu.codigo.children || []).length === 0) {
      history.push(menu.codigo.link);
    }
    toggleMenu(menu, true);
    props.toggleMobileSidenav();
  };

  const toggleMenu = (menu, sideSelect) => {
    setMenuOpened(!menuOpened);

    if (sideSelect && menu === currentOpenMenu) return;

    if (currentOpenMenu && currentOpenMenu.expanded && menu.icon && menu !== currentOpenMenu) currentOpenMenu.expanded = false;
    menu.expanded = !menu.expanded;
    if (menu.expanded) setCurrentOpenMenu(menu);
  };

  return (
    <>
      <nav className={`${styles.sidenav} sidenav`}>
        <span className={`${styles.sidenavBrand} sidenavBrand position-relative pr-0 d-none d-sm-block`}>
          <img src={logo} alt="Logotipo Linx" />
        </span>
        <ul className={`${styles.buttons} buttons flex-column`}>
          <li className={`${styles.navItem} navItem`} key="top">
            {!searchPanelOpened && (
              <span className={`${styles.navLink} navLink`} title="Menu" onClick={props.toggleMobileSidenav}>
                <span className="d-none">Menu</span>
                <i
                  className={`${styles.icon} icon ${props.showMobileSidenav ? 'icon-lx-close' : 'icon-lx-bars'}
                            text-light`}
                ></i>
              </span>
            )}
            {searchPanelOpened && <div className={`${styles.navLinkPlaceHolder} navLinkPlaceHolder`}></div>}
          </li>
        </ul>
        <>
          {!props.showMobileSidenav && (
            <ul className={`${styles.buttons} buttons flex-column`}>
              {menus.map(menu => (
                <li className={`${styles.navSideMenuItem} navItem`} key={`${menu.codigo}_i`}>
                  <span className={`${styles.navLink} navLink`} title={menu.title} onClick={() => clickSideMenu(menu)}>
                    <span className="d-none">{menu.title}</span>
                    <i className={`${styles.icon} icon ${menu.icon} text-light`}></i>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </>
      </nav>
      <aside className={`${styles.menus} menus ${props.showMobileSidenav ? styles.active : ''} ${props.showMobileSidenav ? 'active' : ''} highlight-scroll`}>
        <div className={`${styles.productName} productName`}>
          <span>{systemInfo.nome}</span>
        </div>
        <div className={`${styles.productName} productName`}>
          <span>Menu</span>
        </div>
        {menus.map(menu => (
          <MenuPai menu={menu} showMobileSidenav={props.showMobileSidenav} toggleMenu={toggleMenu}></MenuPai>
        ))}
      </aside>

      {props.showMobileSidenav && <div className={styles.menuOverlay} onClick={props.toggleMobileSidenav}></div>}
    </>
  );
}
