import React, { Component } from "react";

export default class SectionHeader extends Component {
  render() {
    const { 
      title, 
      subtitle, 
      // back, 
      children, 
      right } = this.props;

    return (
      <header className="section-header">
        <div className="section-header-content">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>

        {children}

        <div className="section-header-right">{right}</div>
      </header>
    );
  }
}
