import axios from 'axios';
import {
  BASE_URL_GATEWAY
} from '../../shared/constants/GatewayUrlConstant';
import {
  getToken
} from '../../authentication/Auth';

export function getUrlLinxCore() {
  const urlLinxCore = axios.create({
    baseURL: `${BASE_URL_GATEWAY}/core/api/v1`
  });

  urlLinxCore.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return urlLinxCore;
}

export function getUrlLinxReports() {
  const urlLinxReports = axios.create({
    baseURL: `${BASE_URL_GATEWAY}/relatorios/api/`
  });

  urlLinxReports.interceptors.request.use(async config => {
    config.headers.responseType = 'blob';
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return urlLinxReports;
}
