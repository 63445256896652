import React from 'react';
import List from 'devextreme-react/list';

export default function LinxListAsContent(props) {
  const dataSource = props.dataSource;
  const selectedItems = props.selectedItems;
  const methodThatSets = props.methodThatSets;
  const valueExpr = props.valueExpr;
  const displayExpr = props.displayExpr;
  const searchExpr = props.searchExpr;
  const searchEnabled = props.searchEnabled == null ? true : props.searchEnabled;

  return (
    <List
      valueExpr={valueExpr}
      displayExpr={displayExpr}
      searchExpr={searchExpr}
      dataSource={dataSource}
      allowItemDeleting={false}
      showSelectionControls={true}
      selectedItems={selectedItems}
      searchEnabled={searchEnabled}
      pageLoadMode="scrollBottom"
      selectionMode={props.selectionMode ? props.selectionMode : "all"}
      selectAllMode="allPages"
      onSelectionChanged={e => {
        if (e) {
          let novaLista = selectedItems;
          if (e.addedItems && e.addedItems.length > 0) {
            novaLista = novaLista.concat(e.addedItems);
          }

          if (e.removedItems && e.removedItems.length > 0) {
            novaLista = novaLista.filter(
              item => !e.removedItems.includes(item)
            );
          }
          methodThatSets(novaLista);
        }
      }}
    />
  );
}
