import React, { useState, useEffect } from 'react';
import Iframe from 'react-iframe';
import LinxPostos from '../../components/core/linxPostos/LinxPostos';
import {
  getUrl,
  getNomePagina,
  getDadosSellerPop,
  getNomePaginaAmigavel
} from '../../shared/utils/Utils';
import { menus } from '../../shared/constants/MenuConstants';
import styles from '../dashboard/contas-receber/ContasReceber.module.scss';
import { TIPO_SITE } from '../../shared/constants/TipoSiteConstant';

export function ManterNotaFiscaleFornecedoreProduto(props) {
  const PATH_MENU1 = menus.COD_17130;
  const PATH_MENU2 = menus.COD_13240;
  const PATH_MENU3 = menus.COD_13405;

  const [showFrame1, setShowFrame1] = useState(false);
  const [showFrame2, setShowFrame2] = useState(false);
  const [showFrame3, setShowFrame3] = useState(false);
  const [loadedFrame1, setLoadedFrame1] = useState(false);
  const [loadedFrame2, setLoadedFrame2] = useState(false);
  const [loadedFrame3, setLoadedFrame3] = useState(false);
  const [loadingFrame1, setLoadingFrame1] = useState(false);
  const [loadingFrame2, setLoadingFrame2] = useState(false);
  const [loadingFrame3, setLoadingFrame3] = useState(false);

  useEffect(() => {
    if (props.NotaFiscal) {
      onClickFrame1();
    } else if (props.Fornecedor) {
      onClickFrame2();
    } else if (props.Produto) {
      onClickFrame3();
    }
  }, []);

  const onClickFrame1 = () => {
    if (!loadedFrame1) {
      setLoadingFrame1(true);
    }
    setLoadedFrame1(true);
    setShowFrame3(false);
    setShowFrame2(false);
    setShowFrame1(true);
  };

  const onClickFrame2 = () => {
    if (!loadedFrame2) {
      setLoadingFrame2(true);
    }
    setLoadedFrame2(true);
    setShowFrame1(false);
    setShowFrame3(false);
    setShowFrame2(true);
  };

  const onClickFrame3 = () => {
    if (!loadedFrame3) {
      setLoadingFrame3(true);
    }
    setLoadedFrame3(true);
    setShowFrame1(false);
    setShowFrame2(false);
    setShowFrame3(true);
  };

  const hideSpinner = () => {
    console.log('Loaded.')
    setLoadingFrame1(false);
    setLoadingFrame2(false);
    setLoadingFrame3(false);
  };

  return (
    <LinxPostos
      breadcrumb={getNomePagina(
        showFrame1 ? PATH_MENU1 : showFrame2 ? PATH_MENU2 : PATH_MENU3
      )}
    >
      <div className="row">
        <button
          className={showFrame1 ? 'btn' : 'btn btn-primary'}
          onClick={onClickFrame1}
        >
          Notas Fiscais
        </button>
        <button
          className={showFrame2 ? 'btn' : 'btn btn-primary'}
          onClick={onClickFrame2}
        >
          Fornecedores
        </button>
        <button
          className={showFrame3 ? 'btn' : 'btn btn-primary'}
          onClick={onClickFrame3}
        >
          Produtos
        </button>
      </div>
      {loadedFrame1 && (
        <div id="Frame1" style={{ display: showFrame1 ? 'block' : 'none' }}>
          {loadingFrame1 ? (
            <div className={styles.loader}>
              <h1>Carregando {getNomePaginaAmigavel(PATH_MENU1)}</h1>
              <svg className={`${styles.spinner} spinner`} viewBox="0 0 50 50">
                <circle
                  className={`${styles.path} path`}
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          ) : null}
          <Iframe
            url={
              getUrl() + '/ManterNotaFiscal.aspx?' + getDadosSellerPop(PATH_MENU1)
            }
            className={`${TIPO_SITE === "RetaguardaSellerWeb" ? "iframePopReduzidoMenuTop" : "iframePopReduzidoMenuLeft"}`}
            onLoad={hideSpinner}
          />

        </div>
      )}
      {loadedFrame2 && (
        <div id="Frame2" style={{ display: showFrame2 ? 'block' : 'none' }}>
          {loadingFrame2 ? (
            <div className={styles.loader}>
              <h1>Carregando {getNomePaginaAmigavel(PATH_MENU2)}</h1>
              <svg className={`${styles.spinner} spinner`} viewBox="0 0 50 50">
                <circle
                  className={`${styles.path} path`}
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          ) : null}
          <Iframe
            url={
              getUrl() + '/ManterForFornecedor.aspx?' + getDadosSellerPop(PATH_MENU2)
            }
            className={`${TIPO_SITE === "RetaguardaSellerWeb" ? "iframePopReduzidoMenuTop" : "iframePopReduzidoMenuLeft"}`}
            onLoad={hideSpinner}
          />
        </div>
      )}
      {loadedFrame3 && (
        <div id="Frame3" style={{ display: showFrame3 ? 'block' : 'none' }}>
          {loadingFrame3 ? (
            <div className={styles.loader}>
              <h1>Carregando {getNomePaginaAmigavel(PATH_MENU3)}</h1>
              <svg className={`${styles.spinner} spinner`} viewBox="0 0 50 50">
                <circle
                  className={`${styles.path} path`}
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  strokeWidth="5"
                ></circle>
              </svg>
            </div>
          ) : null}
          <Iframe
            url={
              getUrl() + '/ManterProdProduto.aspx?' + getDadosSellerPop(PATH_MENU3)
            }
            className={`${TIPO_SITE === "RetaguardaSellerWeb" ? "iframePopReduzidoMenuTop" : "iframePopReduzidoMenuLeft"}`}
            onLoad={hideSpinner}
          />
        </div>
      )}
    </LinxPostos>
  );
}

export default ManterNotaFiscaleFornecedoreProduto;
