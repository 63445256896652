import React, { Component } from "react";

export default class Botao extends Component {
  render() {
    const {
      secondary,
      className,
      ic,
      icon,
      onClick,
      disabled,
      tooltip,
      dashboard,
      cargaInicial,
      img
    } = this.props;

    return (
      <button
        title={tooltip}
        className={`button ${className} ${secondary ? "secondary" : null} ${
          dashboard ? "dashboard" : null
        } ${cargaInicial ? "cargaInicial" : null} ${ic ? "ic" : null}`}
        onClick={onClick}
        disabled={disabled}
      >
        {this.props.title}
        <div className={`icon ${icon}`} />
        <div className={`img ${img}`} />
      </button>
    );
  }
}
