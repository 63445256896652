export class NovaOSPagamentoValidateService {
  execute(values) {
    if (!values) {
      return;
    }
    const errors = {isValid: true};
    if (!values.valorRecebido) {
      errors.valorRecebido = 'Por favor, informe o valor recebido';
      errors.isValid = false;
    }
    if (!values.formaPagamentoId) {
      errors.valorRecebido = 'Por favor, informe a forma de pagamento';
      errors.isValid = false;
    }
    return errors;
  }
}
