import { createSlice } from '@reduxjs/toolkit';
import { VALUES_OS } from '../../../pages/operacoes/consignado-comodato-nova-os/ValuesOsKey';
import { NovaOSValidateService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/novaOsValidate.service';
import { RecalcularTotaisService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/recalcularTotais.service';
import { SessionManager } from '../../../shared/utils/sessionManager';
import { AdicionarNovoItemService } from '../usercases/adicionarNovoItem.service';
import { AdicionarPagamentoService } from '../usercases/adicionarPagamento.service';
import { AtualizarNumeroSerieService } from '../usercases/atualizarNumeroSerie.service';
import { AtualizarPagamentoService } from '../usercases/atualizarPagamento.service';
import { ReceberPagamentoService } from '../usercases/receberPagamento.service';
import { RemoverPagamentoService } from '../usercases/removerPagamento.service';

const validateService = new NovaOSValidateService();
const slice = createSlice({
  name: 'novaOs',
  initialState: {
    values: {},
    errors: validateService.execute({}),
    touched: {},
    showImpressaoPedido: false,
    showImpressaoRecibo: false,
  },
  reducers: {
    changeValue: (state, { payload }) => {
      let needSave = true;
      if(payload.fieldName === 'recolhaDevolucaoDiasCalc'){
        payload.fieldName = 'recolhaDevolucaoDias';
        needSave = false;
      }
      state.values[payload.fieldName] = payload.value;
      state.values['needSave'] = needSave;
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
      return state;
    },
    changeValuePedidos: (state, { payload }) => {
      const { pedido } = payload;
      const idx = state.values.pedidos.findIndex(p => p.pedidoId === pedido.pedidoId);
      state.values.pedidos[idx] = pedido;
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
      return state;
    },
    showContrato: (state, { payload }) => {
      state.showContrato = payload;
      return state;
    },
    showImpressaoPedido: (state, { payload }) => {
      state.showImpressaoPedido = payload;
      return state;
    },
    showImpressaoRecibo: (state, { payload }) => {
      state.showImpressaoRecibo = payload;
      return state;
    },
    changeBlur: (state, { payload }) => {
      state.touched = { ...state.touched, [payload.fieldName]: true };
      return state;
    },
    initialize: state => {
      state.values = new SessionManager().getEncryptedData(VALUES_OS) || {};
      state.errors = new NovaOSValidateService().execute(state.values) || {};
      state.touched = {};
      state.centros_monetarios = [];
      return state;
    },
    reset: state => {
      new SessionManager().remove(VALUES_OS);
      state.values = {};
      state.errors = {};
      state.touched = {};
      state.centros_monetarios = [];
      state.transportadoras = [];
      return state;
    },
    reload: (state, { payload }) => {
      new SessionManager().remove(VALUES_OS);
      state.values = payload;
      state.errors = {};
      state.touched = {};
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
      return state;
    },
    removeItem: (state, { payload }) => {
      const { numeroItem, pedidoId } = payload;
      const pedidoIdx = state.values.pedidos.findIndex(pedido => pedido.pedidoId === pedidoId);
      if (pedidoIdx === -1) return state;
      const itemIdx = state.values.pedidos[pedidoIdx].itens.findIndex(item => item.numeroItem === numeroItem);
      if (itemIdx === -1) return state;

      if (state.values.pedidos[pedidoIdx].itens.length === 1) {
        if (state.values.pedidos[pedidoIdx].tipoPedido.id !== 3) {
          state.values.pedidos[pedidoIdx].totalProdutos = 0;
        } else {
          state.values.pedidos[pedidoIdx].totalAtivos = 0;
        }

        state.values.pedidos.splice(pedidoIdx, 1);
      } else {
        if (state.values.pedidos[pedidoIdx].tipoPedido.id !== 3) {
          state.values.pedidos[pedidoIdx].totalProdutos -= state.values.pedidos[pedidoIdx].itens[itemIdx].totalProdutos;
        } else {
          state.values.pedidos[pedidoIdx].totalAtivos = 0;
          state.values.pedidos[pedidoIdx].totalAtivos -= state.values.pedidos[pedidoIdx].itens[itemIdx].totalAtivos;
        }
        state.values.pedidos[pedidoIdx].itens.splice(itemIdx, 1);
        // ajusta a numeração dos itens
        state.values.pedidos[pedidoIdx].itens.forEach((item, idx) => {
          item.numeroItem = idx + 1;
        });
      }
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
      return state;
    },
    adicionarPagamento: (state, arg) => {
      new AdicionarPagamentoService().execute(state, arg);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
      state.errors = validateService.execute(state.values);
    },
    adicionarItem: (state, args) => {
      new AdicionarNovoItemService().execute(state, args);
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
    },
    atualizarNumeroSerie: (state, args) => {
      new AtualizarNumeroSerieService().execute(state, args);
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
    },
    receberPagamento: (state, args) => {
      new ReceberPagamentoService().execute(state, args);
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
    },
    removerPagamento: (state, args) => {
      new RemoverPagamentoService().execute(state, args);
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
    },

    atualizarPagamento: (state, args) => {
      new AtualizarPagamentoService().execute(state, args);
      state.errors = validateService.execute(state.values);
      new RecalcularTotaisService().execute(state.values);
      new SessionManager().saveEncryptedData(VALUES_OS, state.values);
    },

    setOs: (state, { payload }) => {
      state.values = payload;
      return state;
    },
  },
});

export const useNovaOs = () => slice.actions;
export const selectNovaOs = state => state.novaOs;
export default slice.reducer;
