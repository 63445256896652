import { NovaOSItemValidateService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/novaOsItemValidate.service';
import { NovaOSPagamentoValidateService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/novaOsPagamentoValidate.service';
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'novaOsPagamento',
  initialState: {
    values: {},
    errors: new NovaOSPagamentoValidateService().execute({}),
    touched: {},
  },
  reducers: {
    changeValue: (state, { payload }) => {
      const validateService = new NovaOSItemValidateService();
      const newState = {
        ...state,
        values: { ...state.values, [payload.fieldName]: payload.value },
      };
      return {
        ...newState,
        errors: validateService.execute(newState),
      };
    },
    setOrigens: (state, { payload }) => {
      return { ...state, origens: payload.origens };
    },
    setAddresses: (state, { payload }) => {
      return { ...state, addresses: payload.addresses };
    },
    changeBlur: (state, { payload }) => {
      return {
        ...state,
        touched: { ...state.touched, [payload.fieldName]: true },
      };
    },
    initialize: state => {
      state.values = {
        valorRecebido: 0,
        pagamento: { id: -2, descricao: 'DINHEIRO' },
        vencimento: 'RECEBIDO',
      };
      state.touched = {};
      state.errors = new NovaOSPagamentoValidateService().execute(state.values);
      return state;
    },
  },
});

export const useNovaOsPagamento = () => slice.actions;
export const selectNovaOsPagamento = state => state.novaOsPagamento;
export default slice.reducer;
