import React from 'react';
import styles from './InstagramButton.module.scss';

export default function InstagramButton() {
  return (
    <div className={styles.InstagramButton}>
      <a
        href="https://www.instagram.com/linxretail/"
        target="_blank"
        rel="noopener noreferrer"
        ><i className={`icon icon-lx-instagram`}></i></a>
    </div>
  );
}
