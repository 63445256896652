import React from 'react';
import { withRouter } from 'react-router';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import ScreenHeader from '../../../components/header/ScreenHeader/ScreenHeader';
import { menus } from '../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../shared/utils/Utils';
import SetupFacilFonteDados from './SetupFacilSteps/SetupFacil_1_FonteDados/SetupFacilFonteDados';
import SetupFacilSelecaoDados from './SetupFacilSteps/SetupFacil_2_SelecaoDados/SetupFacilSelecaoDados';
import SetupFacilSelecaoProdutos from './SetupFacilSteps/SetupFacil_3_SelecaoProdutos/SetupFacilSelecaoProdutos';
import SetupFacilAjusteProdutos from './SetupFacilSteps/SetupFacil_4_AjusteProdutos/SetupFacilAjusteProdutos';
import SetupFacilConfirmacao from './SetupFacilSteps/SetupFacil_5_Confirmacao/SetupFacilConfirmacao';
import StepWizard from '../../../components/StepWizard/StepWizard';

export function SetupFacil() {
  const PATH_MENU = menus.COD_13480;
  console.log('passo ' + PATH_MENU);

  // steps        = Contém as propriedades dos passos que devem ser enviados para o wizard
  // text         = Nome do passo
  // component    = Componente que será renderizado quando chegar nesse passo
  // propertyName = Nome da propriedade responsável por salvar os dados e levar para os próximos passos
  const steps = [
    {
      index: 0,
      text: 'FONTE DE DADOS',
      component: SetupFacilFonteDados,
      propertyName: 'fonteDados',
    },
    {
      index: 1,
      text: 'SELEÇÃO DE DADOS PARA IMPORTAÇÃO',
      component: SetupFacilSelecaoDados,
      propertyName: 'selecaoDados',
    },
    {
      index: 2,
      text: 'SELEÇÃO DE PRODUTOS',
      component: SetupFacilSelecaoProdutos,
      propertyName: 'selecaoProdutos',
    },
    {
      index: 3,
      text: 'AJUSTE DE PRODUTOS',
      component: SetupFacilAjusteProdutos,
      propertyName: 'ajusteProdutos',
    },
    {
      index: 4,
      text: 'CONFIRMAÇÃO',
      component: SetupFacilConfirmacao,
      propertyName: 'confirmacao',
    },
  ];

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU, 'Importação')}>
      <ScreenHeader search={false} pathMenu={PATH_MENU} newButton={false} />
      <div className={`shadow-sm bg-white`} style={{ height: '90%', paddingBottom: '60px' }}>
        <div className={`container-fluid`}>
          <br />
          <StepWizard steps={steps} />
        </div>
      </div>
    </LinxPostos>
  );
}

export default withRouter(SetupFacil);
