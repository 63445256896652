import memoryCache from 'memory-cache';
import axiosErrorService from '../core/axiosError.service';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';

export class ApiBuscarUfsService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(arg) {
    try {
      const url = `/Cliente/listar-uf?uf=${arg}`;
      console.log(url);
      const cacheKey = `api_buscar_ufs_${url}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);
      if (cachedData) {
        return cachedData;
      }
      const response = await this.urlLinxCore.get(url);
      const result = response.data.itens;
      memoryCache.put(cacheKey, result, 30 * 60 * 1000);
      return result;
    } catch (error) {
      throw axiosErrorService.execute(error);
    }
  }
}
