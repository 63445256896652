import { CharToInt } from './utils';
export function CheckIERS(ie) {
  if (ie.length !== 10) return false;
  var i = ie.substring(0, 3);
  if (i >= 1 && i <= 467) {
    var nro = new Array(10);
    for (i = 0; i <= 9; i++) nro[i] = CharToInt(ie.charAt(i));
    var b = 2;
    var soma = 0;
    for (i = 0; i <= 8; i++) {
      soma += nro[i] * b;
      b--;
      if (b === 1) b = 9;
    }
    var dig = 11 - (soma % 11);
    if (dig >= 10) dig = 0;
    return dig === nro[9];
  }
} //rs
