import axios from 'axios';

import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

import { getToken } from '../../../authentication/Auth';
import { getUrl, getCnpj } from '../../../shared/utils/Utils';

export const setupFacilService = {
  GetEstruturaMercadologica,
  GetSelecaoProdutos,
  GetProdutosPorFiltro,
  GetSetores,
  GetValidacaoProdutos,
  GetPreparacaoProdutosImportacao,
  GetListasGrid,
  GetDescricaoFormaReajuste,
  GetDescricaoUnidadeMedida,
  GetDescricaoICMSCST,
  RealizaImportacao
};

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const urlLinxCoreTemp = axios.create({
  baseURL: 'http://localhost:1460/api/v1',
});

urlLinxCoreTemp.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function GetEstruturaMercadologica() {
  return axios.get(`LxApi/Nodis/GetEstruturasMercadologicasNodis/` + getCnpj(), {});
}

async function GetSelecaoProdutos(estruturasMercadologicas) {
  console.log('estruturas >> ' + estruturasMercadologicas);
  const response = await axios
    .post(`LxApi/Nodis/BuscaProdutosPorEstruturaMercadologica`, {
      query: { codigo: estruturasMercadologicas, cnpj: getCnpj() },
    })
    .then(response => {
      console.log('response >> ' + response.data);
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function GetProdutosPorFiltro(filtro) {
  const response = await axios
    .post(`LxApi/Nodis/BuscaProdutosComImpostos`, filtro)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function GetSetores() {
  const response = await urlLinxCore
    .get(`/Setor/Listar`, {})
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function GetValidacaoProdutos(produtos) {
  const response = await urlLinxCore
    .post(`/produto/ValidaImportacao`, produtos)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function GetPreparacaoProdutosImportacao(produtos) {
  const response = await urlLinxCore
    .post(`/produto/PreparaImportacao`, produtos)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

async function RealizaImportacao(produtos) {
  const response = await urlLinxCore
    .post(`/produto/Importacao`, produtos)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error.response.data;
    });
  return response;
}

function GetListasGrid() {
  return {
    formaReajuste: [
      {
        label: 'Margem Bruta',
        value: 'MargemBruta',
      },
      {
        label: 'Margem Markup',
        value: 'MargemMarkup',
      },
    ],
    cstIcms: [
      {
        label: '00 - Tributada integralmente.',
        value: '0',
      },
      {
        label: '10 - Tributada e com cobrança do ICMS por substituição tributária.',
        value: '10',
      },
      {
        label: '20 - Com redução de Base de Cálculo.',
        value: '20',
      },
      {
        label: '30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária.',
        value: '30',
      },
      {
        label: '40 - Isenta.',
        value: '40',
      },
      {
        label: '41 - Não tributada.',
        value: '41',
      },
      {
        label: '50 - Com suspensão.',
        value: '50',
      },
      {
        label: '51 - Com diferimento.',
        value: '51',
      },
      {
        label: '60 - ICMS cobrado anteriormente por substituição tributária.',
        value: '60',
      },
      {
        label: '70 - Com redução da Base de Cálculo e cobrança do ICMS por substituição tributária.',
        value: '70',
      },
      {
        label: '90 - Outras.',
        value: '90',
      },
    ],
    cstIcmsSN: [
      {
        label: '101 - Tributada pelo Simples Nacional com permissão de crédito.',
        value: '101',
      },
      {
        label: '102 - Tributada pelo Simples Nacional sem permissão de crédito.',
        value: '102',
      },
      {
        label: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta.',
        value: '103',
      },
      {
        label: '201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por Substituição Tributária.',
        value: '201',
      },
      {
        label: '202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por Substituição Tributária.',
        value: '202',
      },
      {
        label: '203 - Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por Substituição Tributária.',
        value: '203',
      },
      {
        label: '300 - Imune.',
        value: '300',
      },
      {
        label: '400 - Não tributada pelo Simples Nacional.',
        value: '400',
      },
      {
        label: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação.',
        value: '500',
      },
      {
        label: '900 - Outros',
        value: '900',
      },
    ],
    cstPisCofins: [
      {
        label: '01 - Operação Tributável com Alíquota Básica',
        value: '01',
      },
      {
        label: '02 - Operação Tributável com Alíquota Diferenciada',
        value: '02',
      },
      {
        label: '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto',
        value: '03',
      },
      {
        label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero',
        value: '04',
      },
      {
        label: '05 - Operação Tributável por Substituição Tributária',
        value: '05',
      },
      {
        label: '06 - Operação Tributável a Alíquota Zero',
        value: '06',
      },
      {
        label: '07 - Operação Isenta da Contribuição',
        value: '07',
      },
      {
        label: '08 - Operação sem Incidência da Contribuição',
        value: '08',
      },
      {
        label: '09 - Operação com Suspensão da Contribuição',
        value: '09',
      },
      {
        label: '49 - Outras Operações de Saída',
        value: '49',
      },
      {
        label: '99 - Outras Operações',
        value: '99',
      },
    ],
    tipo: [
      { value: '1', label: 'Mercadoria para Revenda' },
      { value: '8', label: 'Material de Uso e Consumo' },
      { value: '9', label: 'Ativo Imobilizado'},
      { value: '11', label: 'Outros Insumos' },
    ],
    subTipo: [
      { value: '2', label: 'Simples' },
      { value: '9', label: 'Material de Uso e Consumo' },
      { value: '10', label: 'Ativo Imobilizado' },
      { value: '12', label: 'Outros Insumos' },
    ],
    boolean: [
      { label: 'Sim', value: true },
      { label: 'Não', value: false },
    ],
  };
}

function GetDescricaoFormaReajuste(formaReajuste) {
  if (typeof formaReajuste === 'undefined') return;
  const listas = GetListasGrid();
  const retorno = listas.formaReajuste.filter(x => x.value === formaReajuste);
  return typeof retorno[0] === 'undefined' ? '' : retorno[0].label;
}

function GetDescricaoUnidadeMedida(unidadeMedida, listaUnidadesMedida) {
  if (typeof unidadeMedida === 'undefined') return;
  const retorno = listaUnidadesMedida.filter(x => x.sigla === unidadeMedida);
  return typeof retorno[0] === 'undefined' ? '' : retorno[0].descricao;
}

function GetDescricaoICMSCST(cstIcmsVenda) {
  if (typeof cstIcmsVenda === 'undefined') return;
  const listas = GetListasGrid();
  let retorno = listas.cstIcms.filter(x => x.value === cstIcmsVenda.toString());
  if (retorno.length === 0) retorno = listas.cstIcmsSN.filter(x => x.value === cstIcmsVenda.toString());
  return typeof retorno[0] === 'undefined' ? '' : retorno[0].label;
}
