import React, { useState, useEffect } from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import { applicationService } from '../../../services/core/Application.service';
import 'rc-menu/assets/index.css';
import 'rc-menu/assets/index.less';
//import { useHistory } from 'react-router-dom';
import ctrlHistory from '../../../CtrlHistory';
import styles from './HorizontalMenu.module.scss';

export default function HorizontalMenu(props) {
  const [menus, setMenus] = useState([]);
  const [openKeys, setOpenKeys] = useState([]);
  //const history = useHistory();
  const history = ctrlHistory();

  useEffect(() => {
    //Sempre que "props.favoriteCount" mudar deverá executar novamente este useEffect.
    getMenus();
    //A função getMenus() nunca muda, então não tem motivo para colocar ela na lista de dependência, portando o alerta do eslint está sendo ignorado.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.favoriteCount]);

  const getMenus = async () => {
    const menus = await applicationService.getMenus();

    let menusEmporio = menus.slice();

    setMenus(menusEmporio);

    if (menus) {
      menus.map(menu => {
        let children = menu.children.length;
        //100000 = Favoritos
        if (menu.codigo === 100000) {props.setExisteMenuFavoritos(true)}        
        return children;
      });

      let menuOrder = menus;
      menuOrder.map(menu => {
        if (menu.codigo === 16000) {
          let vendas = menu.children.find(item => item.codigo === 16100);
          let vendasIndex = menu.children.indexOf(vendas);

          menu.children.splice(vendasIndex, 1);
          menu.children.unshift(vendas);
        }
        return menu;
      });
      setMenus(menuOrder);
    }
  };

  const getSvgIcon = (style = {}, text) => {
    if (text) {
      return <i style={style}>{text}</i>;
    }
    const path =
      'M869 487.8L491.2 159.9c-2.9-2.5-6.6-3.9-10.5-3.9h' +
      '-88.5c-7.4 0-10.8 9.2-5.2 14l350.2 304H152c-4.4 0-8 3.6-8 8v' +
      '60c0 4.4 3.6 8 8 8h585.1L386.9 854c-5.6 4.9-2.2 14 5.2 14h91' +
      '.5c1.9 0 3.8-0.7 5.2-2L869 536.2c14.7-12.8 14.7-35.6 0-48.4z';
    return (
      <i style={style}>
        <svg
          viewBox="0 0 1024 1024"
          width="1em"
          height="1em"
          fill="currentColor"
          style={{ verticalAlign: '-.125em' }}
        >
          <path d={path} />
        </svg>
      </i>
    );
  };

  const itemIcon = props => {};

  const itemExpandIcon = props => {
    return getSvgIcon({
      marginLeft: '55px',
      position: 'absolute',
      right: '1rem',
      transform: `rotate(${props.isOpen ? 90 : 0}deg)`
    });
  };

  const handleClick = info => {
    console.log(info.keyPath);
    const menuPath = info.keyPath.reverse();
    let menuItem = menus.find(
      element => element.codigo === parseInt(menuPath[0])
    );
    menuPath.splice(0, 1);

    if (menuItem) {
      while (menuPath.length > 0) {
        menuItem = menuItem.children.find(
          element => element.codigo === parseInt(menuPath[0])
        );
        menuPath.splice(0, 1);
      }

      if (menuItem) history.push(menuItem.link);
    }
  };

  const getSubMenuStyle = (menu) => {
    if (menu.children.length > 10) 
      return {
        width: 400,
        height: 500,
        overflow: 'auto',
      };
    else
      return {
        width: 'auto',
        height: 'auto',
        overflow: 'auto',
      };
  }

  return (
    <Menu
      style={{ marginTop: 62, overflow: 'auto' }}
      mode="horizontal"
      openAnimation="zoom"
      onClick={handleClick}
      selectedKeys={openKeys}
      className="horizontalMenu"
      expandIcon={itemExpandIcon}
    >
      {menus.map(item => (
        <SubMenu
          className="horizontalMenuItem"
          key={item.codigo.toString()}
          title={item.title}
        >
          {item.children.map(subItem1 =>
            subItem1.link ? (
              <MenuItem key={subItem1.codigo.toString()}>
                {subItem1.icon && (
                  <i className={`${subItem1.icon} icon`} >
                    &nbsp;&nbsp;
                  </i>
                )}                
                {subItem1.title}
              </MenuItem>
            ) : (
              <SubMenu key={subItem1.codigo.toString()} className={`${styles.subMenuli}`} title={subItem1.title}>
                {subItem1.children.map(subItem2 =>
                  subItem2.link ? (
                    <MenuItem key={subItem2.codigo.toString()}>
                      {subItem2.title}
                    </MenuItem>
                  ) : (
                    <SubMenu
                      key={subItem2.codigo.toString()}
                      title={subItem2.title}
                    >
                      {subItem2.children.map(subItem3 =>
                        subItem3.link ? (
                          <MenuItem key={subItem3.codigo.toString()}>
                            {subItem3.title}
                          </MenuItem>
                        ) : (
                          <SubMenu
                            key={subItem3.codigo.toString()}
                            title={subItem3.title}
                          >
                            {subItem3.children.map(subItem4 => (
                              <MenuItem key={subItem4.codigo.toString()}>
                                {subItem4.title}
                              </MenuItem>
                            ))}
                          </SubMenu>
                        )
                      )}
                    </SubMenu>
                  )
                )}
              </SubMenu>
            )
          )}
        </SubMenu>
      ))}
    </Menu>
  );
}
