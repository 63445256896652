import React, { useState, useEffect } from 'react';
import { sameInt, operacaoAcesso } from '../../../../shared/utils/Utils';
import {
  SectionContent,
  SectionContainer
} from '../../../../components/section/Content';
import { LinxDatePill } from '../../../../components/linx/DatePill/DatePill';
import styles from './ConferenciaCaixaTotais.module.scss';
import moment from 'moment';
import { Accordion, NumberBox } from 'devextreme-react';
import { LinxMoneyLabel } from '../../../../components/linx/MoneyLabel/LinxMoneyLabel';
import {
  DataGrid,
  Pager,
  SearchPanel,
  Grouping,
  GroupPanel,
  Paging,
  ColumnFixing,
  Summary,
  GroupItem
} from 'devextreme-react/data-grid';
import ptMessages from '../../../../../assets/js/pt.json';
import { locale, loadMessages } from 'devextreme/localization';
import { confirm, alert } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import config from 'devextreme/core/config';
import { ModalAlteracaoForma } from '../modal-altera-forma/ModalAlteracaoForma';
import { LinxButtonConferencia } from '../../../../components/linx/Button/LinxButtonConferencia';
import conferenciaCaixa from '../service/ConferenciaCaixa.service';
import { getConfiguracaoGeral } from '../../../configuracoes/configuracoes-gerais/ConfiguracoesGerais.service';
import { selecionarFormaPgto } from '../ConferenciaTurno.service';
import Box, { Item } from 'devextreme-react/box';
import { isCartao } from '../conferencia-caixa-result/ConferenciaCaixaResult';
import SituacaoBadge from '../components/SituacaoBadge/SituacaoBadge';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';

export const Situacoes = {
  NaoConferido: 0,
  Conferido: 1,
  ConferidoParcialmente: 2,
  AbertoNoPDV: 3
};

export const FormasPagamento = {
  Dinheiro: '1',
  Cheque: '2',
  Ticket: '3',
  Credito: '4',
  Vale: '5',
  AFaturar: '6',
  Debito: '7',
  CTF: '9'
};

export const CodigoFormaPgto = {
  Dinheiro: '-1',
  Cheque: '-2',
  Ticket: '-3',
  Credito: '-4',
  Vale: '-5',
  AFaturar: '-6',
  Debito: '-7',
  CTF: '-9'
};

export const codigoFormaPgtoToDescricao = codigo => {
  switch (codigo) {
    case FormasPagamento.Dinheiro:
      return 'Dinheiro';

    case FormasPagamento.Cheque:
      return 'Cheque';

    case FormasPagamento.Ticket:
      return 'Ticket';

    case FormasPagamento.Credito:
      return 'Crédito';

    case FormasPagamento.Vale:
      return 'Vale';

    case FormasPagamento.AFaturar:
      return 'Nota a Prazo';

    case FormasPagamento.Debito:
      return 'Débito';

    case FormasPagamento.CTF:
      return 'CTF';

    default:
      break;
  }
};

const Modo = {
  Padrao: 1,
  Operador: 2
};

const titulo = 'Conferência';

export function ConferenciaCaixaTotais(props) {

  const acRef = React.useRef();

  let startDate = moment(),
    endDate = moment(),
    situacao = null,
    formas = [],
    dados = {};

  if (props && props.dados) {
    startDate = props.dados.startDate;
    endDate = props.dados.endDate;
    situacao = props.dados.situacao;
    formas = props.dados.formas;
    dados = props.dados;
  }

  const [modo, setModo] = useState(props.modo ? props.modo : Modo.Padrao);
  const [modalActive, setModalActive] = useState(false);
  const [modalData, setModalData] = useState({});
  const [searching, setSearching] = useState(false);
  const [valorMinGerarVale, setValorMinGerarVale] = useState(null);
  const [, setDadosTurno] = useState(props.dados);
  const [, setTesouraria] = useState(null);
  const [temVale, setTemVale] = useState(props.dados.gerarVale);
  const [totalizadores, setTotalizadores] = useState({});
  const [itensSelecionados, setItensSelecionados] = useState([]);
  const [
    consideraSuprimentoDiferenca,
    setConsideraSuprimentoDiferenca
  ] = useState(true);

  const CODIGO_CONSUMIDOR_FINAL = 2;
  const REABRE_CAIXA = 60681;
  const CONFERE_OFFLINE = 60685;

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);
  }, []);

  useEffect(() => {
    setModo(props.modo ? props.modo : Modo.Padrao);
  }, [props.modo]);

  useEffect(() => {
    async function getConsideraSuprimentoDiferenca() {
      const { data } = await getConfiguracaoGeral([183]);
      if (data.notificationLevel === 1) {
        const consideraSuprimento = !sameInt(data.data.valor, 1);
        setConsideraSuprimentoDiferenca(consideraSuprimento);
      }
    }
    getConsideraSuprimentoDiferenca();
  }, []);

  useEffect(() => {
    async function getValorVale() {
      const codigoParametroVale = 27;
      const { data } = await getConfiguracaoGeral([codigoParametroVale]);
      if (data.result) {
        const minGerarVale = parseFloat(data.result[0].valor);
        setValorMinGerarVale(minGerarVale);
      }
    }
    getValorVale();
  }, []);

  useEffect(() => {
    recalculaTotalizadores();
  }, []);

  config({
    defaultCurrency: 'BRL'
  });
  
  const dataConferencia = async (gerarVale = false) => {
    return {
      id: parseInt(dados.codigoTurno),
      turnoOperadorId: dados.turnoOperadorId,
      gerarVale: gerarVale,
      conferencias: dados.formas.map(item => {
        return {
          formaPagamento: {
            id: item.formaPagamento.id
          },
          valores: {
            valorRecebidoTesouraria: Number(item.tesouraria),
            valorRecebimentos: item.recebimentos,
            valorSuprimentos: item.suprimentos
          }
        };
      }),
      operador: {
        id: dados.responsavel.id
      }
    };
  };

  async function realizarConferencia() {
    setSearching(true);
    try {
      let data = {};
      const codigoTurno = dados.codigoTurno;
      const operador = dados.responsavel.id;
      data = await dataConferencia(false);
      const response = await conferenciaCaixa.RealizarConferencia(data);
      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        trocaSituacao(Situacoes.Conferido);
        let possuiVale = await conferenciaCaixa.VerificaSePossuiVale(
          codigoTurno,
          dados.turnoOperadorId
        );
        dados.gerarVale = possuiVale.data;
        setTemVale(dados.gerarVale);
        if (dados.gerarVale) {
          let result = await confirm(
            '<h2>Turno conferido com sucesso! Deseja realizar a impressão do(s) vale(s) agora?</h2>',
            titulo
          );
          if (result) {
            await doImprimeValeConferencia(codigoTurno, operador, '_blank');
          }
        } else notify('Turno conferido com sucesso!', 'success', 5000, 'top');
      }
    } finally {
      setSearching(false);
    }
  }

  async function salvaConferencia() {
    setSearching(true);
    try {
      const data = {
        id: parseInt(dados.codigoTurno),
        turnoOperadorId: dados.turnoOperadorId,
        conferencias: dados.formas.map(item => {
          return {
            formaPagamento: {
              id: item.formaPagamento.id
            },
            valores: {
              valorRecebidoTesouraria: Number(item.tesouraria),
              valorRecebimentos: item.recebimentos
            }
          };
        }),
        operador: {
          id: dados.responsavel.id
        }
      };
      const response = await conferenciaCaixa.SalvarConferencia(data);
      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        notify('Conferência salva com sucesso!', 'success', 5000, 'top');
      }
    } finally {
      setSearching(false);
    }
  }

  async function realizaConferenciaFacil() {
    setSearching(true);
    try {
      const data = {
        id: parseInt(dados.codigoTurno),
        turnoOperadorId: dados.turnoOperadorId,
        operador: {
          id: dados.responsavel.id
        }
      };

      const response = await conferenciaCaixa.RealizarConferenciaFacil([data]);

      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        trocaSituacao(Situacoes.Conferido);
        dados.formas = dados.formas.map(f => {
          return {
            ...f,
            tesouraria:
              f.recebimentos +
              (consideraSuprimentoDiferenca ? f.suprimentos : 0)
          };
        });
        recalculaTotalizadores();
        notify('Conferência fácil realizada com sucesso!', 'success', 5000);
      }
    } finally {
      setSearching(false);
    }
  }

  const trocaSituacao = situacao => {
    let possuiVale = conferenciaCaixa.VerificaSePossuiVale(
      dados.codigoTurno,
      dados.turnoOperadorId
    );

    dados.gerarVale = possuiVale.data;
    dados.situacao = situacao;
    if (situacao === Situacoes.Conferido) {
      dados.usuarioConferente = {
        nome: dados.responsavel.nome
      };
      dados.dataConferencia = moment();
    }
    setDadosTurno(dados);
    setTemVale(dados.gerarVale);
  };

  async function reabrirConferencia() {
    setSearching(true);
    try {
      const response = await conferenciaCaixa.Reabrir(
        parseInt(dados.codigoTurno),
        dados.turnoOperadorId
      );
      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        notify(
          `${
            sameInt(modo, Modo.Padrao)
              ? ' Turno reaberto'
              : ' Conferência reaberta'
          } com sucesso.`,
          'success',
          5000,
          'top'
        );
        trocaSituacao(Situacoes.NaoConferido);
      }
    } finally {
      setSearching(false);
    }
  }

  async function realizaConferenciaOffline() {
    try {
      let data = {};
      const codigoTurno = dados.codigoTurno;
      const operador = dados.responsavel.id;

      data = await dataConferencia(true);
      const response = await conferenciaCaixa.RealizarConferenciaOffline(data);
      if (response.notificationLevel !== 1) {
        notify(response.message, 'error', 5000, 'top');
      } else {
        trocaSituacao(Situacoes.Conferido);
        let possuiVale = await conferenciaCaixa.VerificaSePossuiVale(
          codigoTurno,
          dados.turnoOperadorId
        );

        dados.gerarVale = possuiVale.data;
        setTemVale(dados.gerarVale);

        if (possuiVale) {
          let result = await confirm(
            '<h2>Conferência offline realizada com sucesso! Deseja realizar a impressão do(s) vale(s) agora?</h2>',
            titulo
          );
          if (result) {
            const responseVale = await conferenciaCaixa.ConsultarVale(
              codigoTurno,
              operador
            );
            window.open(responseVale, '_blank');
          }
        } else {
          notify(
            'Conferência offline realizada com sucesso!',
            'success',
            5000,
            'top'
          );
        }
      }
    } finally {
      setSearching(false);
    }
  }

  async function imprimeValeConferencia() {
    setSearching(true);
    try {
      let turno = parseInt(dados.codigoTurno);
      let operador = dados.responsavel.id;
      await doImprimeValeConferencia(turno, operador);
    } finally {
      setSearching(false);
    }
  }

  const doImprimeValeConferencia = async (turno, operador, target = '') => {
    try {
      const responseVale = await conferenciaCaixa.ConsultarVale(
        turno,
        operador
      );
      window.open(responseVale);
    } catch (e) {
      notify(
        'Erro ao solicitar a impressão do vale. ' + e.message,
        'error',
        5000
      );
    }
  };

  const getInfoBySituacao = () => {
    if (situacao === Situacoes.Conferido) {
      return (
        // <>
        //   <div className={`${styles.tooltip}`}>
        //     <span className={`${styles.tooltiptext}`}>Dados do Turno</span>
        //     <button
        //       className={styles.info}
        //       onClick={() =>
        //         abrirModalInfo(
        //           props.dados.usuarioConferente.nome,
        //           props.dados.dataConferencia
        //         )
        //       }
        //     >
        //       <i className="icon icon-lx-info"></i>
        //     </button>
        //   </div>
        // </>
        <li
          className={`${styles.info} info d-none d-sm-block`}
          onClick={() =>
            abrirModalInfo(
              props.dados.usuarioConferente.nome,
              props.dados.dataConferencia
            )
          }
        >
          <Tooltip placement="bottom" overlay={<span>Dados do Turno</span>}>
            <span className={`active pt-0 pb-0 text-uppercase`}>
              <i className="icon dx-icon-info"></i>
            </span>
          </Tooltip>
        </li>
      );
    }
  };

  const getButtonsBySituacao = () => {
    const confereFacil = () => {
      return (
        <LinxButtonConferencia
          disabled={searching}
          labelNormal="Conferência Fácil"
          labelDisabled={'Processando...'}
          onClick={async () => {
            let mensagem = consideraSuprimentoDiferenca
              ? '<h2>Ao realizar a conferência fácil, o campo "Tesouraria" será preenchido com a soma do valor dos campos "Recebimentos" e "Suprimentos" </br> para todas as formas de pagamento. </br> Confirma a conferência?</h2>'
              : '<h2>Ao confirmar, o campo "Tesouraria" será preenchido com o mesmo valor do campo "Recebimentos" para todas as formas de pagamento. Confirmar?</h2>';
            let result = await confirm(mensagem, titulo);
            if (result) {
              realizaConferenciaFacil();
            }
          }}
        />
      );
    };

    const ConfereNormal = () => {
      return (
        <LinxButtonConferencia
          disabled={searching}
          labelNormal={
            'Conferir'
          }
          labelDisabled={'Processando...'}
          onClick={async () => {
            let result = await confirm(
              '<h1>Deseja efetivar a conferência?</h1>',
              titulo
            );
            if (result) {
              realizarConferencia();
            }
          }}
        />
      );
    };

    const salvaNormal = () => {
      return (
        <LinxButtonConferencia
          disabled={searching}
          labelNormal="Salvar"
          labelDisabled={'Salvar'} //Processando...
          onClick={async () => {
            let result = await confirm(
              '<h1>As informações serão salvas e o turno permanecerá com a situação "Não conferido". Deseja continuar?</h1>',
              titulo
            );
            if (result) {
              salvaConferencia();
            }
          }}
        />
      );
    };

    const reabre = () => {
      return (
        <LinxButtonConferencia
          disabled={!operacaoAcesso(REABRE_CAIXA) || searching}
          labelNormal={
            sameInt(modo, Modo.Padrao) ? 'Reabrir' : 'Reabrir Conferência'
          }
          labelDisabled={'Processando...'}
          onClick={async () => {
            let result = await confirm(
              '<h1>Deseja realmente reabrir?</h1>',
              titulo
            );
            if (result) {
              reabrirConferencia();
            }
          }}
        />
      );
    };

    const imprimeVale = () => {
      return (
        <LinxButtonConferencia
          disabled={searching}
          labelNormal="Imprimir Vale"
          labelDisabled={'Processando...'}
          onClick={async () => {
            let result = await confirm(
              '<h1>Deseja realizar a impressão do(s) vale(s) agora?</h1>',
              titulo
            );
            if (result) {
              imprimeValeConferencia();
            }
          }}
        />
      );
    };

    const confereOffline = () => {
      const confereTurnoOffline = operacaoAcesso(CONFERE_OFFLINE);

      return confereTurnoOffline ? (
        <LinxButtonConferencia
          disabled={searching}
          labelNormal="Conferência Offline"
          labelDisabled={'Processando...'}
          onClick={async () => {
            let result = await confirm(
              '<h1>Ao realizar a conferência offline, os lançamentos realizados no PDV para este turno não serão mais replicados.</br> Deseja realmente efetuar a conferência off-line?</h1>',
              titulo
            );
            if (result) {
              setSearching(true);
              realizaConferenciaOffline();
            }
          }}
        />
      ) : null;
    };

    switch (situacao) {
      case Situacoes.AbertoNoPDV:
        return (
          <>
            <div className="col-1"></div>
            <div className="section-footer">{confereOffline()}</div>
          </>
        );
      case Situacoes.NaoConferido:
        return (
          <>
            <div className="col-1"></div>
            <div className="section-footer">
              {confereFacil()} {ConfereNormal()} {salvaNormal()}
            </div>
          </>
        );
      case Situacoes.ConferidoParcialmente:
        return (
          <>
            <div className="col-1"></div>
            <div className="section-footer">
              {confereFacil()} {ConfereNormal()} {salvaNormal()} {reabre()}
            </div>
          </>
        );
      case Situacoes.Conferido:
        return (
          <>
            <div className="col-1"></div>
            {temVale || dados.gerarVale ? (
              operacaoAcesso(REABRE_CAIXA) ? (
                <div className="section-footer">
                  {reabre()} {imprimeVale()}
                </div>
              ) : (
                <div className="section-footer">{imprimeVale()}</div>
              )
            ) : operacaoAcesso(REABRE_CAIXA) ? (
              <div className="section-footer">{reabre()}</div>
            ) : (
              <div className="section-footer"></div>
            )}
          </>
        );

      default:
        return <></>;
    }
  };

  const selecionarFormaPagamento = async info => {
    const { data: objDocumentoFiscal } = await selecionarFormaPgto(
      info.equipamento,
      info.codigoVenda,
      info.codigoMovimento
    );
    if (objDocumentoFiscal) {
      objDocumentoFiscal.emissao = info.emissao;
      objDocumentoFiscal.situacaoTurno = dados.situacao;
      objDocumentoFiscal.documentoFiscal = info.documentoFiscal;
      objDocumentoFiscal.equipamento = info.equipamento;
      objDocumentoFiscal.codigoVenda = info.codigoVenda;
      objDocumentoFiscal.codigoMovimento = info.codigoMovimento;
      objDocumentoFiscal.operadorNome = info.operador;
      objDocumentoFiscal.numeroDocumento = info.numero;
      objDocumentoFiscal.faturado = info.faturado;
      objDocumentoFiscal.quitado = !!info.dataQuitacao;
      if (sameInt(info.documentoFiscal.codigo, 0)) {
        objDocumentoFiscal.valor = objDocumentoFiscal.formaPagamentos[0].valor;
      }
      if (
        info.dataQuitacao &&
        !sameInt(
          objDocumentoFiscal.formaPagamentos[0].codigoFormaPagamento,
          CodigoFormaPgto.Dinheiro
        )
      ) {
        objDocumentoFiscal.dataQuitacao = moment(info.dataQuitacao).format(
          'DD/MM/YYYY'
        );
        objDocumentoFiscal.dataVencimento = moment(info.dataVencimento).format(
          'DD/MM/YYYY'
        );
      } else {
        objDocumentoFiscal.dataQuitacao = null;
        objDocumentoFiscal.dataVencimento = null;
      }
      objDocumentoFiscal.dataDocumento = moment(info.dataDocumento).format(
        'DD/MM/YYYY'
      );
      objDocumentoFiscal.formaPagamentos.forEach(element => {
        if (element.formaPagamento === 'DINHEIRO (TROCO)') {
          element.valor = element.valor * -1;
        }
      });

      if (objDocumentoFiscal.cliente) {
        objDocumentoFiscal.cliente.descricaoAutorizado =
          objDocumentoFiscal.cliente.nomeAutorizado &&
          objDocumentoFiscal.cliente.placaAutorizado
            ? objDocumentoFiscal.cliente.nomeAutorizado +
              ' - ' +
              objDocumentoFiscal.cliente.placaAutorizado
            : objDocumentoFiscal.cliente.nomeAutorizado
            ? objDocumentoFiscal.cliente.nomeAutorizado
            : objDocumentoFiscal.cliente.placaAutorizado;
        objDocumentoFiscal.cliente = [
          {
            codigoCliente: objDocumentoFiscal.cliente.codigoCliente,
            nome: objDocumentoFiscal.cliente.nome,
            cnpjCpf: objDocumentoFiscal.cliente.cpF_CNPJ,
            codigoAutorizado: objDocumentoFiscal.cliente.codigoAutorizado,
            possuiAutorizados: objDocumentoFiscal.cliente.codigoAutorizado !== 0,
            placaAutorizado: objDocumentoFiscal.cliente.placaAutorizado,
            utilizaFatura: objDocumentoFiscal.cliente.utilizaFatura,
            obrigaAutorizado: objDocumentoFiscal.cliente.obrigaAutorizado,
            autorizado: objDocumentoFiscal.cliente.descricaoAutorizado,
            consumidorFinal: sameInt(
              objDocumentoFiscal.cliente.codigoCliente,
              CODIGO_CONSUMIDOR_FINAL
            )            
          }
        ];
        objDocumentoFiscal.turno = parseInt(dados.codigoTurno);
        objDocumentoFiscal.caixaAgrupado = dados.caixaAgrupado;
      }
      setModalActive(true);
      setModalData(objDocumentoFiscal);
    }
  };
  const getSuprimentoByForma = e => {
    if (e.formaPagamento.id !== 1) {
      return (
        <div className="col-4">
          <h1 className={`${styles.formaPagamentoColumn} formaPagamentoColumn`}>
            {e.formaPagamento.descricao}
          </h1>
        </div>
      );
    } else {
      return (
        <>
          <div className="col-2">
            <h1 className={`${styles.formaPagamentoColumn} formaPagamentoColumn`}>
              {e.formaPagamento.descricao}
            </h1>
          </div>
          <div className="col-2">
            <LinxMoneyLabel
              title={e.formaPagamento ? 'Suprimentos' : ''}
              value={e.suprimentos}
              showMoneySymbol
              color="linx"
            />
          </div>
        </>
      );
    }
  };

  const alteraFormaPagamentoClickHandle = e => {
    selecionarFormaPagamento(e.row ? e.row.data : {});
    if (acRef.current) {
      let tempItensSelecionados = [];
      for (let i = 0; i < dados.formas.length; i++) {
        if (acRef.current.instance.isItemSelected(i)) {
          acRef.current.instance.collapseItem(i);
          tempItensSelecionados.push(i);
          setItensSelecionados(tempItensSelecionados);
        }
      }
    }
  };

  const recalculaTotalizadores = () => {
    const tempTotalizadores = {
      suprimentos: 0,
      recebimentos: 0,
      sangria: 0,
      tesouraria: 0
    };
    dados.formas.forEach(f => {
      tempTotalizadores.suprimentos += f.suprimentos;
      tempTotalizadores.recebimentos += f.recebimentos;
      tempTotalizadores.sangria += f.sangria;
      tempTotalizadores.tesouraria += f.tesouraria;
    });
    setTotalizadores(tempTotalizadores);
  };

  const abrirModalInfo = async (responsavel, data) => {
    var dataC = moment(data).format('DD/MM/YYYY');
    var horaC = moment(data).format('HH:mm');
    await alert(
      `<strong>Conferido por ${responsavel}, em ${dataC} às ${horaC}.</strong>`,
      'Dados do Turno'
    );
  };

  const modalAlteracaoForma = () => {
    return (
      <ModalAlteracaoForma
        active={modalActive}
        modalData={modalData}
        handleCloseModal={async modificado => {
          if (modificado) {
            const listaFP = await conferenciaCaixa.ListarFechamentoCaixa(
              props.dados.codigoTurno,
              props.dados.turnoOperadorId
            );

            if (listaFP) {
              const dadosConferenciaCaixa = props.dados;
              dadosConferenciaCaixa.formas = [];
              listaFP.forEach((fp, index) => {
                dadosConferenciaCaixa.formas.push({
                  id: index,
                  formaPagamento: fp.formaPagamento,
                  recebimentos: fp.valorRecebido,
                  sangria: fp.valorSangria,
                  suprimentos: fp.valorSuprimento,
                  tesouraria: fp.valorApurado,
                  cartao: isCartao(fp.formaPagamento.id),
                  informacoes: []
                });
              });
              dadosConferenciaCaixa.formas = await Promise.all(
                dadosConferenciaCaixa.formas.map(async value => {
                  const consulta = {
                    operadores: [dadosConferenciaCaixa.responsavel.id],
                    turno: parseInt(dadosConferenciaCaixa.codigoTurno),
                    formaPagamento: value.formaPagamento.id
                  };
                  try {
                    const listaDocumentosPorTurno = await conferenciaCaixa.DocumentosPorTurno(
                      consulta
                    );
                    listaDocumentosPorTurno.forEach(documento => {
                      value.informacoes.push({
                        formaPagamento: {
                          id: consulta.formaPagamento * -1,
                          descricao: codigoFormaPgtoToDescricao(
                            consulta.formaPagamento
                          )
                        },
                        codigoVenda: documento.codigoVenda,
                        codigoMovimento: documento.codigoMovimento,
                        dataDocumento: documento.dataHora,
                        dataVencimento: documento.dataVencimento,
                        dataQuitacao: documento.dataQuitacao,
                        faturado: documento.faturado,
                        documentoFiscal: documento.tipoDocumentoFiscal,
                        equipamento: documento.codigoEcf,
                        numero: documento.numeroDocumento,
                        operador: documento.operador,
                        emissao: documento.tipoEmissao
                          ? documento.tipoEmissao.descricao
                          : 'Outros',
                        rede: documento.rede
                          ? {
                              id: documento.rede.id,
                              descricao: documento.rede.descricao
                            }
                          : null,
                        bandeira: documento.bandeira
                          ? {
                              id: documento.bandeira.id,
                              descricao: documento.bandeira.descricao
                            }
                          : null,
                        produtoRede: documento.produtoRede
                          ? {
                              id: documento.produtoRede.id,
                              descricao: documento.produtoRede.descricao
                            }
                          : null,
                        nsu: documento.nsu,
                        nsuRede: documento.nsuRede,
                        data: documento.dataHora,
                        valor: documento.valor,
                        cliente: documento.cliente,
                        serie: documento.serie,
                        valorAcrescimo: documento.valorAcrescimo,
                        valorDesconto: documento.valorDesconto,
                        valorBruto: documento.valorBruto,
                        chave: documento.chave
                      });
                    });
                  } catch (e) {
                    return null;
                  }
                  return value;
                })
              );
              setDadosTurno(dadosConferenciaCaixa);
            }
          } else {
            itensSelecionados.forEach(element => {
              acRef.current.instance.expandItem(element);
            });
          }
          setModalActive(false);
          setModalData({});
        }}
      />
    );
  };

  return (
    <>
      <div className={`${styles.conferenciaTotais} conferenciaTotais`}>
        {modalAlteracaoForma()}
        <SectionContainer>
          <div className="row">
            <div>
              <h1>{dados.responsavel.nome}</h1>
            </div>
            <SituacaoBadge situacao={dados.situacao} />
            {getInfoBySituacao()}
          </div>
          <div className="row">
            <LinxDatePill
              startDate={startDate}
              endDate={endDate}
              format="DD/MM/YYYY HH:mm"
            />
            {getButtonsBySituacao()}
          </div>
          <SectionContent>
            <div className={styles.conferenciaTotais}>
              <div className="row">
                <Accordion
                  ref={acRef}
                  dataSource={formas}
                  collapsible={true}
                  multiple={true}
                  width="100%"
                  //selectedItems={selectedItems}
                  //onSelectionChanged={this.selectionChanged}
                  itemTitleRender={forma => {
                    return (
                      <>
                        <div className="row">
                          {getSuprimentoByForma(forma)}
                          <div className="col-2">
                            <LinxMoneyLabel
                              title="Recebimentos"
                              value={forma.recebimentos}
                              showMoneySymbol
                              color="linx"
                            />
                          </div>
                          <div className="col-2">
                            <LinxMoneyLabel
                              title="Sangria"
                              value={forma.sangria}
                              showMoneySymbol
                              color="linx"
                            />
                          </div>
                          <div className="col-2">
                            <label>Tesouraria</label>
                            <NumberBox
                              disabled={
                                searching ||
                                dados.situacao === Situacoes.Conferido
                              }
                              placeholder="R$ "
                              showClearButton={false}
                              format={{
                                type: 'currency',
                                precision: 2
                              }}
                              value={forma.tesouraria}
                              valueChangeEvent="change keyup dxmousewheel"
                              onValueChanged={event => {
                                if (event.value >= 0) {
                                  forma.tesouraria = event.value;
                                  setTesouraria(event.value);
                                  recalculaTotalizadores();
                                } else {
                                  forma.tesouraria = 0;
                                  setTesouraria(0);
                                }
                              }}
                              onKeyPress={e => {
                                const event = e.event,
                                  str = String.fromCharCode(event.keyCode);
                                if (!/[0-9,]/.test(str)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </div>
                          <div className="col-2">
                            <LinxMoneyLabel
                              title="Diferença"
                              value={
                                forma.tesouraria -
                                ((consideraSuprimentoDiferenca
                                  ? forma.suprimentos
                                  : 0) +
                                  forma.recebimentos)
                              }
                              showMoneySymbol
                              color={
                                forma.tesouraria -
                                  ((consideraSuprimentoDiferenca
                                    ? forma.suprimentos
                                    : 0) +
                                    forma.recebimentos) <
                                0
                                  ? 'red'
                                  : 'black'
                              }
                            />
                          </div>
                        </div>
                      </>
                    );
                  }}
                  itemRender={forma => {
                    return (
                      <div className={styles.accordionContent}>
                        <DataGrid
                          dataSource={forma.informacoes}
                          defaultColumns={[
                            { dataField: 'id', visible: false },
                            {
                              dataField: 'documentoFiscal.descricao',
                              caption: 'Documento',
                              minWidth: '50'
                            },
                            {
                              dataField: 'equipamento',
                              caption: 'Equipamento',
                              width: '100',
                              visible: false
                            },
                            {
                              dataField: 'serie',
                              caption: 'Série',
                              width: '40'
                            },
                            {
                              dataField: 'chave',
                              caption: 'Chave de acesso',
                              width: '150'
                            },
                            {
                              dataField: 'numero',
                              caption: 'Nº Documento',
                              width: '100'
                            },
                            {
                              dataField: 'emissao',
                              caption: 'Tipo de emissão',
                              width: '100',
                              groupIndex: forma.cartao ? 0 : -1,
                              visible: forma.cartao
                            },
                            {
                              dataField: 'rede.descricao',
                              caption: 'Rede',
                              width: '75',
                              visible: forma.cartao
                            },
                            {
                              dataField: 'bandeira.descricao',
                              caption: 'Bandeira',
                              width: '75',
                              visible: forma.cartao
                            },
                            {
                              dataField: 'produtoRede.descricao',
                              caption: 'Produto Rede',
                              width: '75',
                              visible: forma.cartao
                            },
                            {
                              dataField: 'nsu',
                              caption: 'NSU',
                              width: '75',
                              visible: forma.cartao
                            },
                            {
                              dataField: 'nsuRede',
                              caption: 'NSU Rede',
                              width: '75',
                              visible: forma.cartao
                            },
                            {
                              dataField: 'data',
                              caption: 'Data / Hora',
                              dataType: 'datetime',
                              width: '130'
                            },
                            {
                              dataField: 'operador',
                              caption: 'Operador',
                              width: '130'
                            },
                            {
                              dataField: 'cliente',
                              caption: 'Cliente',
                              width: '120'
                            },
                            {
                              dataField: 'valorBruto',
                              caption: 'Valor Bruto',
                              format: { type: 'currency', precision: 2 },
                              width: '110'
                            },
                            {
                              dataField: 'valorAcrescimo',
                              caption: 'Acréscimo',
                              format: { type: 'currency', precision: 2 },
                              width: '110'
                            },
                            {
                              dataField: 'valorDesconto',
                              caption: 'Desconto',
                              format: { type: 'currency', precision: 2 },
                              width: '110'
                            },
                            {
                              dataField: 'valor',
                              caption: 'Valor Líquido',
                              format: { type: 'currency', precision: 2 },
                              width: '110'
                            },
                            {
                              type: 'buttons',
                              caption: 'Ações',
                              fixed: false,
                              width: '30',
                              buttons: [
                                {
                                  hint: 'Alterar informações',
                                  icon: 'edit',
                                  visible: true,
                                  onClick: alteraFormaPagamentoClickHandle
                                }
                              ]
                            }
                          ]}
                          showBorders={true}
                          allowColumnResizing={true}
                          allowColumnReordering={true}
                          columnResizingMode={'widget'}
                          columnMinWidth={80}
                          rowAlternationEnabled={true}
                        >
                          <GroupPanel visible={true} />
                          <SearchPanel
                            visible={true}
                            highlightCaseSensitive={true}
                          />
                          <Grouping autoExpandAll={true} />
                          <ColumnFixing enabled={true} />
                          <Summary>
                            <GroupItem
                              column="valor"
                              summaryType="sum"
                              valueFormat={{
                                type: 'currency',
                                precision: 2
                              }}
                              alignByColumn={true}
                            />
                          </Summary>
                          <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[5, 15, 20, 50]}
                            showInfo={true}
                          />
                          <Paging defaultPageSize={15} />
                        </DataGrid>
                      </div>
                    );
                  }}
                />
              </div>
              <Box direction="row" width="100%">
                <Item ratio={1}>
                  <h1 className={`${styles.formaPagamentoTotal} formaPagamentoTotal`}>Total</h1>
                </Item>
                <Item ratio={1}>
                  <LinxMoneyLabel
                    title="Suprimentos"
                    value={totalizadores.suprimentos}
                    showMoneySymbol
                    color="linx"
                  />
                </Item>
                <Item ratio={1}>
                  <LinxMoneyLabel
                    title="Recebimentos"
                    value={totalizadores.recebimentos}
                    showMoneySymbol
                    color="linx"
                  />
                </Item>
                <Item ratio={1}>
                  <LinxMoneyLabel
                    title="Sangria"
                    value={totalizadores.sangria}
                    showMoneySymbol
                    color="linx"
                  />
                </Item>
                <Item ratio={1}>
                  <LinxMoneyLabel
                    title="Tesouraria"
                    value={totalizadores.tesouraria}
                    showMoneySymbol
                    color="linx"
                  />
                </Item>
                <Item ratio={1}>
                  <LinxMoneyLabel
                    title="Diferença"
                    value={
                      totalizadores.tesouraria -
                      ((consideraSuprimentoDiferenca
                        ? totalizadores.suprimentos
                        : 0) +
                        totalizadores.recebimentos)
                    }
                    showMoneySymbol
                    color={
                      totalizadores.tesouraria -
                        ((consideraSuprimentoDiferenca
                          ? totalizadores.suprimentos
                          : 0) +
                          totalizadores.recebimentos) <
                      0
                        ? 'red'
                        : 'black'
                    }
                  />
                </Item>
              </Box>
            </div>
          </SectionContent>
        </SectionContainer>
      </div>
    </>
  );
}

//export default withRouter(ConferenciaCaixaTotais);
