import { DateBox } from 'devextreme-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import styles from  './Component.module.scss';

export function FloatTimeInput({ value, onChange, id, label, placeholder }) {
  const dispatch = useDispatch();
  return (
    <div className="form-group input-group">
      <span className={`has-float-label w-100 ${styles.floatTimeInputLabel}`}>
      <DateBox
          type="time"
          pickerType="native"
          id={id}
          className={`border-top-0 border-left-0 border-right-0 m-0 float-date-input ${styles.floatTimeInput}`}
          placeholder={placeholder}
          onValueChange={e => {
            dispatch(
              onChange({
                fieldName: id,
                value: e ? new Date(e).toISOString() : null,
              }),
            );
          }}
          displayFormat="HH:mm"
          value={value ? new Date(value) : null}
        />
        <label htmlFor={id}>{label}</label>
      </span>
    </div>
  );
}
