import React from "react";

export const MensagemErro = ({
  isShow,
  titulo,
  mensagem,
}) => {

  return (
    <>
      {isShow &&
        <div className="alert alert-info my-4" role="alert">
          <h4 className="alert-heading">
            ERRO
          </h4>
          <p>{titulo}</p>
          <hr />
          {mensagem &&
            <p className="mb-0"><strong>Mensagem de erro:</strong> {mensagem}</p>
          }
        </div>
      }
    </>
  )
}
