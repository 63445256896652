import { ApiListarEmpresasService } from './ApiListarEmpresas.services';
import { ApiRecuperarEmpresaService } from './ApiRecuperarEmpresas.service';

export class EmpresaService {
  static instance = null;
  static getInstance() {
    if (!EmpresaService.instance) {
      EmpresaService.instance = new EmpresaService();
    }
    return EmpresaService.instance;
  }
  apiService = new ApiRecuperarEmpresaService();
  apiListarService = new ApiListarEmpresasService();

  recuperar = async (useCache = false) => {
    return await this.apiService.execute();
  }

  listar = async () => {
    return this.apiListarService.execute();
  }
}
