import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DataSource from 'devextreme/data/data_source';
import { DropDownImage } from '../drop-down-image';
import { Form } from 'react-bootstrap';
import { SelectBox } from 'devextreme-react';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';

export const BuscarTransportadora = () => {
  const { changeValue, changeBlur } = useNovaOs();
  const { values, touched, errors } = useSelector(state => state.novaOs);
  const transportadoras = useSelector(selectConsignadoComodatoSharedData).transportadoras;
  const dispatch = useDispatch();

  const store = new DataSource({
    key: 'id',
    byKey: key => {
      return [values.transportadora || {}];
    },
    load: async function(arg) {
      if (arg.searchOperation !== 'contains') {
        return [];
      }
      if (values.tipoFreteId !== 2 && values.tipoFreteId !== 3) return [];
      console.log('Buscando transportadoras...');
      return transportadoras || [];
    },
  });

  const onSelectionChanged = useCallback(
    e => {
      if (!e.selectedItem) {
        if (!values.transportadora) return;
        dispatch(changeValue({ fieldName: 'transportadora', value: null }));
        return;
      }
      const data = { ...e.selectedItem };
      if (data.id === (values.transportadora || {}).id) return;

      dispatch(changeValue({ fieldName: 'transportadora', value: data }));
    },
    [dispatch, changeValue, values.transportadora],
  );

  return (
    <div className="form-group">
      <Form.Label htmlFor="transportadora" className="text-primary">
        Transportadora
        <span className="text-danger">*</span>
      </Form.Label>

      <SelectBox
        disabled={(values.status || {}).id !== 1}
        id="transportadora"
        name="transportadora"
        dropDownButtonRender={DropDownImage}
        showDropDownButton={true}
        searchEnabled={true}
        hint="Digite o nome ou nome fantasia da transportadora"
        placeholder="Digite o nome ou nome fantasia da transportadora"
        displayExpr="nome"
        height={40}
        valueExpr="id"
        value={values.transportadora || null}
        acceptCustomValue={false} // Não permite que o valor digitado seja aceito
        dataSource={store}
        selectedItem={values.transportadora || {}}
        onSelectionChanged={onSelectionChanged}
        searchTimeoutOption={500}
        onFocusOut={e => {
          dispatch(changeBlur({ fieldName: 'transportadora' }));
        }}
      ></SelectBox>
      {touched.transportadora && errors.transportadora && <div className="text-danger validationError">{errors.transportadora}</div>}
    </div>
  );
};
