import React, { useState, useEffect } from 'react';
import { CodigoFormaPgto, titulo } from '../ConferenciaTurno';
import {
  montaComboRede,
  montaComboBandeira,
  montaComboProdutoRede
} from '../../../configuracoes/estrutura-cartoes/EstruturaCartoes.service';
import { montaComboFormasPagamento } from '../ConferenciaTurno.service';
import {
  sameInt,
  operacaoAcesso,
  sameString,
  sortList,
  isObjectEmpty
} from '../../../../shared/utils/Utils';
import {
  getPessoas,
  getPessoa
} from '../../../cadastro/pessoas/Pessoas.service';
import styles from './ModalAlteracaoForma.module.scss';
import ptMessages from '../../../../../assets/js/pt.json';
import { locale, loadMessages } from 'devextreme/localization';
import { Popup } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import LinxTreeView, {
  syncTreeViewSelection
} from '../../../../components/linx/TreeView/LinxTreeView';
import {
  Pager,
  Paging,
  Editing,
  Column,
  Texts
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import funcionario from '../../../cadastro/pessoas/Funcionario.service';
import { salvarFormaPgto } from '../ConferenciaTurno.service';
import { confirm } from 'devextreme/ui/dialog';
import { Situacoes } from '../conferencia-caixa-totais/ConferenciaCaixaTotais';
import {
  TextBox,
  NumberBox,
  SelectBox,
  DropDownBox,
  DataGrid,
  CheckBox,
  Button
} from 'devextreme-react';
import { Selection } from 'devextreme-react/data-grid';
import { codigoFormaPgtoToDescricao } from './../conferencia-caixa-totais/ConferenciaCaixaTotais';
import { TipoPessoa } from '../../../cadastro/pessoas/Pessoas';

export function ModalAlteracaoForma(props) {
  const [objDocumentoFiscal, setObjDocumentoFiscal] = useState({});
  const [operadores, setOperadores] = useState([]);
  const [operadorValue, setOperadorValue] = useState('');
  const [operadorModificado, setOperadorModificado] = useState(false);
  const ALTERA_CLIENTE = 60684;
  const ALTERA_OPERADOR = 60682;
  const ALTERA_FORMAPAGAMENTO = 60683;

  useEffect(() => {
    const loadOperadores = async () => {
      
      var filter = props.modalData.caixaAgrupado ? {
        turno: props.modalData.turno
      } : null;

      const response = await funcionario.ListarComFiltro(filter);

      const operadoresTratado = response.map(o => {
        return {
          label: o.nome,
          value: o.id
        };
      });
      setOperadores(operadoresTratado);
    };
    loadOperadores();
  }, [props.modalData.turno]);

  useEffect(() => {
    loadMessages(ptMessages);
    locale(navigator.language);

    let formatter = new Intl.NumberFormat([], {
      style: 'currency',
      currency: 'BRL'
    });

    const { valor, documentoFiscal } = props.modalData;
    let valorFormatado = formatter.format(valor);
    let docFiscal = documentoFiscal ? documentoFiscal : {};
    if (operadores && !operadorModificado) {
      let operador = operadores.find(
        o => o.label === props.modalData.operadorNome
      );
      if (operador) setOperadorValue(operador.value);
    }

    setObjDocumentoFiscal({
      ...props.modalData,
      documentoFiscal: docFiscal,
      valorFormatado
    });
  }, [props]);

  function updateWidth() {
    let widthTotal = window.innerWidth;
    let widthPercent = widthTotal * 0.75;
    return widthPercent;
  }

  function updateHeight(min, max) {
    let menos = min ? min : 0;
    let mais = max ? max : 0;
    let heightTotal = window.innerHeight;
    let heightPercent = heightTotal * 0.75;
    return heightPercent - menos + mais;
  }

  const isDataValid = (clienteSelecionado, formasPagamentoAPI) => {
    let somaTotalFormasPgto = 0;

    for (let i = 0; i < props.modalData.formaPagamentos.length; i++) {
      const element = props.modalData.formaPagamentos[i];
      if (element.formaPagamento === 'Troco') {
        somaTotalFormasPgto -= element.valor;
      } else {
        somaTotalFormasPgto += element.valor;
      }
    }

    if (somaTotalFormasPgto !== objDocumentoFiscal.valor) {
      notify(
        'A soma das formas de pagamento deve ser igual ao valor total do documento',
        'error',
        2500
      );
      return false;
    }

    if (props.modalData.cliente.length === 0) {
      notify(
        'Não é permitido documento fiscal sem cliente definido',
        'error',
        2500
      );
      return false;
    }

    let error = false;
    if (!clienteSelecionado.consumidorFinal) {
      if (sameInt(clienteSelecionado.tipo, TipoPessoa.Fisica)) {
        error =
          clienteSelecionado.obrigaAutorizado &&
          (!clienteSelecionado.codigoAutorizado ||
            clienteSelecionado.codigoAutorizado === 0);
      } else {
        //é jurídica
        error =
          clienteSelecionado.obrigaAutorizado &&
          (!clienteSelecionado.codigoAutorizado ||
            clienteSelecionado.codigoAutorizado === 0);
      }
    }
    if (error) {
      notify('Inclua um autorizado no cliente.', 'error', 2500);
      return false;
    }

    return true;
  };

  const operadorChangeHandler = e => {
    setOperadorModificado(true);
    setOperadorValue(e.value);
  };

  const handleSalvar = async () => {
    const formasPagamentoOrdenada = props.modalData.formaPagamentos.sort(
      function(a, b) {
        if (parseInt(a.codigoFormaPagamento) < parseInt(b.codigoFormaPagamento))
          return -1;
        if (parseInt(a.codigoFormaPagamento) > parseInt(b.codigoFormaPagamento))
          return 1;
        return 0;
      }
    );
    const formasPagamentoAPI = formasPagamentoOrdenada.map(fp => {
      return {
        codigoFormaPagamento: sameInt(fp.codigoFormaPagamento, -11)
          ? -1
          : fp.codigoFormaPagamento,
        formaPagamento: fp.formaPagamento,
        valor: fp.valor,
        cartao: fp.cartao,
        cheque: fp.cheque
      };
    });

    if (props.modalData.cliente.length === 0) {
      notify(
        'Não é permitido documento fiscal sem cliente definido',
        'error',
        2500
      );
      return;
    }

    const clienteSelecionado = {
      ...props.modalData.cliente[0],
      CPF_CNPJ: props.modalData.cliente[0].cnpjCpf
    };

    if (!isDataValid(clienteSelecionado, formasPagamentoAPI)) {
      return;
    }

    let result = await confirm('<h1>Deseja salvar as alterações?</h1>', titulo);

    let operadorAtual = operadorModificado ? operadorValue : null;

    if (result) {
      try {
        await salvarFormaPgto(
          props.modalData.equipamento,
          props.modalData.codigoVenda,
          props.modalData.codigoMovimento,
          operadorAtual,
          formasPagamentoAPI,
          clienteSelecionado,
          props.modalData.data,
          props.modalData.faturado,
          props.modalData.quitado,
          props.modalData.turno,
          props.modalData.caixaAgrupado
        );
        notify(
          'Aguarde enquanto as alterações são efetivadas',
          'info',
          2000,
          'top'
        );

        props.handleCloseModal(true);
      } catch (e) {
        notify(e.response.data.message, 'error', 2500);
        return false;
      }
    }
  };

  const contentRender = () => {
    return (
      <ScrollView
        id="scrollview"
        scrollByContent={true}
        showScrollbar={'onScroll'}
        scrollByThumb={true}
      >
        <div className={styles.left}>
          {objDocumentoFiscal.documentoFiscal.descricao === 'Suprimento' ||
          objDocumentoFiscal.documentoFiscal.descricao === 'Sangria' ? (
            <div className="row">
              <div className="col-12">
                {'Este documento não pode ser alterado.'}
              </div>
            </div>
          ) : isQuitadoOuFaturado(props.modalData) ? (
            <div className="row">
              <div className="col-12">
                {
                  'Documento fiscal já quitado ou faturado apenas pode ter seu operador alterado.'
                }
              </div>
            </div>
          ) : isTurnoAbertoOuConferido(props.modalData.situacaoTurno) ? (
            <div className="row">
              <div className="col-12">
                {'Turno já conferido ou aberto no PDV não pode ser alterado.'}
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="row">
            <div className="col-8">
              <label>Operador:</label>
              <SelectBox
                items={operadores}
                displayExpr="label"
                valueExpr="value"
                value={operadorValue}
                showClearButton={false}
                onValueChanged={operadorChangeHandler}
                disabled={
                  !operacaoAcesso(ALTERA_OPERADOR) ||
                  objDocumentoFiscal.documentoFiscal.descricao ===
                    'Suprimento' ||
                  objDocumentoFiscal.documentoFiscal.descricao === 'Sangria' ||
                  (isQuitadoOuFaturado(props.modalData) &&
                    !sameInt(
                      props.modalData.situacaoTurno,
                      Situacoes.NaoConferido
                    )) ||
                  isTurnoAbertoOuConferido(props.modalData.situacaoTurno)
                }
              />
            </div>
          </div>
          <TabPanel
            height={updateHeight(120, 0)}
            animationEnabled={true}
            scrollByContent={true}
            scrollingEnabled={true}
          >
            <Item
              title={'Formas de Pagamento'}
              listaFormaPagamento={props.modalData}
              component={FormaPagamento}
            />
            {objDocumentoFiscal.documentoFiscal.descricao === 'Suprimento' ||
            objDocumentoFiscal.documentoFiscal.descricao === 'Sangria' ? (
              <></>
            ) : (
              <Item
                title={'Cliente'}
                listaClienteSelecionado={props.modalData}
                component={Clientes}
              />
            )}
          </TabPanel>
        </div>
        <div className={styles.right}>
          <div className="container">
            <div className="row-detail">
              <label>DOCUMENTO</label>
              <br />
              <h3>{objDocumentoFiscal.documentoFiscal.descricao}</h3>
            </div>
            {objDocumentoFiscal.documentoFiscal.descricao !== 'NF-e' ? (
              <>
                <div className="row-detail">
                  <label>Equipamento</label>
                  <br />
                  <h2>
                    {props.modalData.equipamento
                      ? props.modalData.equipamento
                      : 'Não informado'}
                  </h2>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="row-detail">
              <label>Número</label>
              <br />
              <h2>{props.modalData.numeroDocumento}</h2>
            </div>
            <div className="row-detail">
              <label>Valor</label>
              <br />
              <h2>{objDocumentoFiscal.valorFormatado}</h2>
            </div>
            <div className="row-detail">
              <label>Data</label>
              <br />
              <h2>{objDocumentoFiscal.dataDocumento}</h2>
            </div>
            {objDocumentoFiscal.dataQuitacao ? (
              <>
                <div className="row-detail">
                  <label>Data de Vencimento</label>
                  <br />
                  <h2>{objDocumentoFiscal.dataVencimento}</h2>
                </div>
                <div className="row-detail">
                  <label>Data da quitação</label>
                  <br />
                  <h2>{objDocumentoFiscal.dataQuitacao}</h2>
                </div>
              </>
            ) : (
              <>
                <div className="row" />
              </>
            )}
          </div>
        </div>

        <div className={`${styles.footer}`}>
          <button
            className="btn btn-light"
            onClick={async () => {
              await props.handleCloseModal(false);
            }}
          >
            Cancelar
          </button>
          {(!operacaoAcesso(ALTERA_FORMAPAGAMENTO) &&
            !operacaoAcesso(ALTERA_CLIENTE) &&
            !operacaoAcesso(ALTERA_OPERADOR)) ||
          isTurnoAbertoOuConferido(props.modalData.situacaoTurno) ||
          objDocumentoFiscal.documentoFiscal.descricao === 'Suprimento' ||
          objDocumentoFiscal.documentoFiscal.descricao === 'Sangria' ? (
            <></>
          ) : (
            <button
              className="btn btn-primary"
              disabled={
                objDocumentoFiscal.documentoFiscal.descricao === 'Suprimento' ||
                objDocumentoFiscal.documentoFiscal.descricao === 'Sangria' ||
                (isQuitadoOuFaturado(props.modalData) &&
                  !sameInt(
                    props.modalData.situacaoTurno,
                    Situacoes.NaoConferido
                  ))
              }
              onClick={handleSalvar}
            >
              Salvar
            </button>
          )}
        </div>
      </ScrollView>
    );
  };

  return (
    props.active && (
      <Popup
        visible={props.active}
        onHidden={() => props.handleCloseModal(false)}
        dragEnabled={false}
        closeOnOutsideClick={false}
        showTitle={true}
        title="Alteração de Informações"
        width={updateWidth()}
        contentRender={contentRender}
        height={updateHeight(0, 120)}
      />
    )
  );
}

function FormaPagamento(props) {
  const [dsFormaPagamento, setDsFormaPagamento] = useState([]);
  const [comboProduto, setComboProduto] = useState([]);
  const [comboBandeira, setComboBandeira] = useState([]);
  const [comboRede, setComboRede] = useState(null);
  const [comboFormaPagamento, setComboFormaPagamento] = useState([]);
  const [formaPagSelecionada, setFormaPagSelecionada] = useState(null);
  const [produtoSelecionado, setProdutoSelecionado] = useState(null);
  const [bandeiraSelecionada, setBandeiraSelecionada] = useState(null);
  const [numeroCheque, setNumeroCheque] = useState(null);
  const [valorFormaPgto, setValorFormaPgto] = useState(null);
  const [redeSelecionada, setRedeSelecionada] = useState(null);
  const [nsu, setNsu] = useState('');
  const [bloqueiaAlteracao, setBloqueiaAlteracao] = useState(false);
  const [isEditingFormaPagamento, setIsEditingFormaPagamento] = useState(false);
  const ALTERA_FORMAPAGAMENTO = 60683;

  useEffect(() => {
    if (
      props.data &&
      props.data.listaFormaPagamento &&
      props.data.listaFormaPagamento.documentoFiscal
    ) {
      setBloqueiaAlteracao(
        props.data.listaFormaPagamento.documentoFiscal.descricao ===
          'Suprimento' ||
          props.data.listaFormaPagamento.documentoFiscal.descricao ===
            'Sangria' ||
          isQuitadoOuFaturado(props.data.listaFormaPagamento) ||
          isTurnoAbertoOuConferido(
            props.data.listaFormaPagamento.situacaoTurno
          ) ||
          sameString(props.data.listaFormaPagamento.emissao, 'TEF')
      );
    }
  }, []);

  useEffect(() => {
    const fetch = async (apiMethod, apiParam, setMethod) => {
      const { data: dados } = await apiMethod(apiParam);
      const listaOrdenada = dados.sort(function(a, b) {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;
      });
      setMethod(listaOrdenada);
    };

    fetch(montaComboRede, true, setComboRede);
    fetch(montaComboBandeira, true, setComboBandeira);
  }, []);

  useEffect(() => {
    if (
      props.data &&
      props.data.listaFormaPagamento &&
      props.data.listaFormaPagamento.formaPagamentos
    ) {
      for (
        let i = 0;
        i < props.data.listaFormaPagamento.formaPagamentos.length;
        i++
      ) {
        props.data.listaFormaPagamento.formaPagamentos[i].id = i;
      }

      setDsFormaPagamento(props.data.listaFormaPagamento.formaPagamentos);
    }
  }, [props]);

  useEffect(() => {
    const fetchComboFormasPagamento = async codigoRede => {
      if (!codigoRede) codigoRede = props.rede;

      const { data: listaOriginal } = await montaComboFormasPagamento();

      const listaOrdenada = listaOriginal.sort(function(a, b) {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;
      });

      setComboFormaPagamento(listaOrdenada);
    };

    fetchComboFormasPagamento();
  }, [props.rede]);

  const buscaProdutoRede = codigoRede => {
    const fetchComboProdutoRede = async codigoRede => {
      const { data: listaProdutoRedeOriginal } = await montaComboProdutoRede(
        codigoRede,
        true
      );

      const listaOrdenada = listaProdutoRedeOriginal.sort(function(a, b) {
        if (a.descricao < b.descricao) return -1;
        if (a.descricao > b.descricao) return 1;
        return 0;
      });

      setComboProduto(listaOrdenada);
    };

    fetchComboProdutoRede(codigoRede);
  };

  const clearFormaPagamento = () => {
    setFormaPagSelecionada(null);
    setValorFormaPgto(null);
    setRedeSelecionada(null);
    setBandeiraSelecionada(null);
    setProdutoSelecionado(null);
    setNsu('');
  };

  const atualizaListagemFormaPgto = listaFormasPgtoSelecionadas => {
    let tempFormasPgto = [...listaFormasPgtoSelecionadas];
    props.data.listaFormaPagamento.formaPagamentos.length = 0;
    tempFormasPgto.forEach(fp => {
      props.data.listaFormaPagamento.formaPagamentos.push({
        formaPagamento: fp.formaPagamento,
        valor: fp.valor,
        codigoFormaPagamento: fp.codigoFormaPagamento,
        cartao:
          parseInt(fp.codigoFormaPagamento) ===
            parseInt(CodigoFormaPgto.Ticket) ||
          parseInt(fp.codigoFormaPagamento) ===
            parseInt(CodigoFormaPgto.Credito) ||
          parseInt(fp.codigoFormaPagamento) === parseInt(CodigoFormaPgto.Debito)
            ? {
                codigoRede: fp.cartao.codigoRede,
                codigoBandeira: fp.cartao.codigoBandeira,
                codigoProduto: fp.cartao.codigoProduto,
                nsu: fp.cartao.nsu
              }
            : null,
        cheque: sameInt(fp.codigoFormaPagamento, CodigoFormaPgto.Cheque)
          ? { ...fp.cheque }
          : null
      });
    });
    setDsFormaPagamento(tempFormasPgto);
  };

  const handleInsertFormaPagamento = () => {
    let valorTotalDocumento = props.data.listaFormaPagamento.valor;
    let formaPgto = formaPagSelecionada;
    if (formaPgto > 0) {
      formaPgto = formaPgto * -1;
    }
    const formaPagamentoDescricao = codigoFormaPgtoToDescricao(
      String(formaPgto * -1)
    );
    let valorDigitado = valorFormaPgto;
    let somaTotalFormasPgto = 0;
    let temTroco = false;
    let temErro = false;

    if (!formaPgto) {
      temErro = true;
      notify('A forma de pagamento deve ser informada', 'error', 2500);
    }
    let listaFormasPgtoSelecionadas = dsFormaPagamento;
    if (
      !temErro &&
      listaFormasPgtoSelecionadas &&
      formaPgto !== -4 &&
      formaPgto !== -7 &&
      formaPgto !== -2 &&
      listaFormasPgtoSelecionadas.find(fp =>
        sameInt(fp.codigoFormaPagamento, formaPgto)
      )
    ) {
      temErro = true;
      notify(
        'Já existe um registro com esta forma de pagamento para esta venda.',
        'error',
        2500
      );
    }

    if (!temErro)
      if (!valorDigitado) {
        temErro = true;
        notify(
          'O valor da forma de pagamento deve ser definido',
          'error',
          2500
        );
      }
    if (
      sameInt(formaPgto, CodigoFormaPgto.Credito) ||
      sameInt(formaPgto, CodigoFormaPgto.Debito)
    ) {
      if (!temErro && redeSelecionada === null) {
        temErro = true;
        notify(
          'Para forma de pagamento ' +
            formaPagamentoDescricao +
            ' deve ser informada a rede.',
          'error',
          2500
        );
      }
      if (!temErro && bandeiraSelecionada === null) {
        temErro = true;
        notify(
          'Para forma de pagamento ' +
            formaPagamentoDescricao +
            ' deve ser informada a bandeira.',
          'error',
          2500
        );
      }
      if (!temErro && produtoSelecionado === null) {
        temErro = true;
        notify(
          'Para forma de pagamento ' +
            formaPagamentoDescricao +
            ' deve ser informado o Produto Rede.',
          'error',
          2500
        );
      }
    }
    if (!temErro) {
      const objFormaPgto = {
        id: listaFormasPgtoSelecionadas.length + 1,
        codigoFormaPagamento: formaPgto,
        formaPagamento: formaPagamentoDescricao,
        formaPgtoId: parseInt(formaPgto),
        valor: parseFloat(valorDigitado),
        rede: redeSelecionada,
        bandeira: bandeiraSelecionada,
        produto: produtoSelecionado,
        nsu,
        cartao: {
          codigoRede: redeSelecionada,
          codigoBandeira: bandeiraSelecionada,
          codigoProduto: produtoSelecionado,
          nsu: nsu === null ? '' : nsu,
          nsuRede: null,
          valor: parseFloat(valorDigitado)
        },
        cheque: {
          numero: numeroCheque ? numeroCheque : '',
          codigoBanco: '',
          agencia: '',
          conta: ''
        }
      };

      listaFormasPgtoSelecionadas.forEach(element => {
        somaTotalFormasPgto += parseFloat(element.valor);
      });

      if (valorDigitado + somaTotalFormasPgto > valorTotalDocumento) {
        if (parseInt(formaPgto) === parseInt(CodigoFormaPgto.Dinheiro))
          //dinheiro
          temTroco = true;
        else {
          temErro = true;
          notify(
            'Para ' +
              objFormaPgto.formaPagamento +
              ' não é permitido inserir valor maior do que o valor total do documento',
            'error',
            2500
          );
        }
      }

      // if (
      //   !temErro &&
      //   valorDigitado + somaTotalFormasPgto < valorTotalDocumento &&
      //   sameInt(formaPgto, CodigoFormaPgto.AFaturar)
      // ) {
      //   temErro = true;
      //   notify(
      //     'Para a forma de pagamento Nota a Prazo não é permitida ' +
      //       'inclusão de valor diferente do que o valor do documento fiscal.',
      //     'error',
      //     2500
      //   );
      // }

      // if (
      //   !temErro &&
      //   sameInt(formaPgto, CodigoFormaPgto.AFaturar) &&
      //   listaFormasPgtoSelecionadas.length > 0
      // ) {
      //   temErro = true;
      //   notify(
      //     'Para vendas a prazo não é permitida a inclusão de novas formas de pagamento. Para realizar a alteração, é necessário primeiro remover a forma de pagamento "Nota a prazo".',
      //     'error',
      //     5500
      //   );
      // }

      // if (!temErro) {
      //   if (
      //     listaFormasPgtoSelecionadas.find(fp =>
      //       sameInt(fp.codigoFormaPagamento, CodigoFormaPgto.AFaturar)
      //     )
      //   ) {
      //     temErro = true;
      //     notify(
      //       'Para vendas a prazo não é permitida a inclusão de novas formas de pagamento. Para realizar a alteração, é necessário primeiro remover a forma de pagamento "Nota a prazo".',
      //       'error',
      //       5500
      //     );
      //   }
      // }

      if (!temErro) {
        listaFormasPgtoSelecionadas.push(objFormaPgto);

        if (temTroco) {
          var objFormaPgtoTroco = {};
          objFormaPgtoTroco.formaPagamento = 'Dinheiro (Troco)';
          objFormaPgtoTroco.valor = parseFloat(
            valorDigitado + somaTotalFormasPgto - valorTotalDocumento
          );
          objFormaPgtoTroco.valor = objFormaPgtoTroco.valor * -1;
          objFormaPgtoTroco.codigoFormaPagamento = formaPgto;
          listaFormasPgtoSelecionadas.push(objFormaPgtoTroco);
        }
        setIsEditingFormaPagamento(false);
        atualizaListagemFormaPgto(listaFormasPgtoSelecionadas);
        clearFormaPagamento();
      }
    }
  };

  const alterarFormaPgto = e => {
    var listaFormasPgtoSelecionadas = dsFormaPagamento;
    var listaFormasPgtoNova = [];
    var item = e.row.data;

    listaFormasPgtoSelecionadas.forEach(element => {
      if (item.id !== element.id) {
        listaFormasPgtoNova.push(element);
      } else {
        if (sameInt(element.codigoFormaPagamento, CodigoFormaPgto.Dinheiro)) {
          //remover o troco
          listaFormasPgtoNova = listaFormasPgtoNova.filter(
            fpn => fpn.valor > 0
          );
        }
        if (
          (parseInt(element.codigoFormaPagamento) !==
            parseInt(CodigoFormaPgto.Ticket) ||
            (parseInt(element.codigoFormaPagamento) ===
              parseInt(CodigoFormaPgto.Ticket) &&
              !element.cartao)) &&
          parseInt(element.codigoFormaPagamento) !==
            parseInt(CodigoFormaPgto.Credito) &&
          parseInt(element.codigoFormaPagamento) !==
            parseInt(CodigoFormaPgto.Debito)
        ) {
          //diferente de cartão
          setFormaPagSelecionada(element.codigoFormaPagamento);
          setValorFormaPgto(parseFloat(element.valor));
        } else {
          setFormaPagSelecionada(element.codigoFormaPagamento);
          setValorFormaPgto(parseFloat(element.valor));
          setRedeSelecionada(element.cartao.codigoRede);
          buscaProdutoRede(element.cartao.codigoRede);
          setBandeiraSelecionada(element.cartao.codigoBandeira);
          setProdutoSelecionado(element.cartao.codigoProduto);
          setNsu(element.cartao.nsu);
        }

        if (sameInt(element.codigoFormaPagamento, CodigoFormaPgto.Cheque)) {
          setNumeroCheque(element.cheque ? element.cheque.numero : null);
        }
      }
    });
    setIsEditingFormaPagamento(true);
    atualizaListagemFormaPgto(listaFormasPgtoNova);
  };

  const limparFormaPgto = async e => {
    let result = await confirm(
      '<h1>Tem certeza que deseja excluir este registro?</h1>',
      titulo
    );
    if (result) {
      var listaFormasPgtoSelecionadas = dsFormaPagamento;
      var listaFormasPgtoNova = [];
      var item = e.row.data;

      listaFormasPgtoSelecionadas.forEach(element => {
        if (item.id !== element.id) listaFormasPgtoNova.push(element);
        else {
          if (sameInt(element.codigoFormaPagamento, CodigoFormaPgto.Dinheiro)) {
            //remover o troco
            listaFormasPgtoNova = listaFormasPgtoNova.filter(
              fpn => fpn.valor > 0
            );
          }
        }
      });

      atualizaListagemFormaPgto(listaFormasPgtoNova);
    }
  };

  return (
    <ScrollView id="scrollview">
      <div className="container">
        <br />
        <div className="row">
          <div className="col-5">
            <label>Forma de Pagamento</label>
            <SelectBox
              placeholder="Selecione..."
              items={comboFormaPagamento}
              displayExpr="descricao"
              valueExpr="codigo"
              value={formaPagSelecionada}
              showClearButton={true}
              onValueChanged={e => {
                setFormaPagSelecionada(e.value);
              }}
              disabled={
                !operacaoAcesso(ALTERA_FORMAPAGAMENTO) || bloqueiaAlteracao
              }
            />
          </div>
          <div className="col-5">
            <label>Valor</label>
            <NumberBox
              disabled={
                !operacaoAcesso(ALTERA_FORMAPAGAMENTO) || bloqueiaAlteracao
              }
              placeholder="R$ "
              showClearButton={false}
              format="R$ #,###.##"
              mode="number"
              value={valorFormaPgto}
              valueChangeEvent="change keyup dxmousewheel"
              onValueChanged={event => {
                if (event.value >= 0) {
                  setValorFormaPgto(event.value);
                } else {
                  setValorFormaPgto(0);
                }
              }}
              onKeyPress={e => {
                const event = e.event,
                  str = String.fromCharCode(event.keyCode);
                if (!/[0-9,]/.test(str)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
          {parseInt(formaPagSelecionada) !== parseInt(CodigoFormaPgto.Ticket) &&
          parseInt(formaPagSelecionada) !== parseInt(CodigoFormaPgto.Credito) &&
          parseInt(formaPagSelecionada) !== parseInt(CodigoFormaPgto.Debito) ? (
            <div className="col-2">
              <div className={`${styles.buttonWrapper}`}>
                {!bloqueiaAlteracao && (
                  <Button
                    type="default"
                    hint={
                      isEditingFormaPagamento ? 'Confirmar edição' : 'Adicionar'
                    }
                    icon={isEditingFormaPagamento ? 'check' : 'plus'}
                    onClick={handleInsertFormaPagamento}
                    disabled={
                      !operacaoAcesso(ALTERA_FORMAPAGAMENTO) ||
                      bloqueiaAlteracao
                    }
                  />
                )}
              </div>
            </div>
          ) : null}
        </div>

        {parseInt(formaPagSelecionada) === parseInt(CodigoFormaPgto.Cheque) ? (
          <>
            <div className="row">
              <div className="col-5">
                <label>Nº Cheque:</label>
                <TextBox
                  placeholder="Nº Cheque"
                  showClearButton={true}
                  value={numeroCheque}
                  valueChangeEvent="change keypress"
                  onValueChanged={event => {
                    const value = event.value;
                    if (String(value).length <= 10) {
                      setNumeroCheque(value);
                    }
                  }}
                  onKeyPress={e => {
                    const event = e.event,
                      str = String.fromCharCode(event.keyCode);
                    if (!/[0-9]/.test(str)) {
                      event.preventDefault();
                    }
                  }}
                  disabled={bloqueiaAlteracao}
                  maxLength={10}
                />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {parseInt(formaPagSelecionada) === parseInt(CodigoFormaPgto.Ticket) ||
        parseInt(formaPagSelecionada) === parseInt(CodigoFormaPgto.Credito) ||
        parseInt(formaPagSelecionada) === parseInt(CodigoFormaPgto.Debito) ? (
          <>
            <div className="row">
              <div className="col-5">
                <label>Rede:</label>
                <SelectBox
                  placeholder="Selecione uma rede..."
                  items={comboRede}
                  displayExpr="descricao"
                  valueExpr="codigo"
                  value={redeSelecionada}
                  onValueChanged={e => {
                    setRedeSelecionada(e.value);
                    buscaProdutoRede(e.value);
                  }}
                  disabled={bloqueiaAlteracao}
                />
              </div>
              <div className="col-5">
                <label>Bandeira:</label>
                <SelectBox
                  placeholder="Selecione uma bandeira..."
                  items={comboBandeira}
                  displayExpr="descricao"
                  valueExpr="codigo"
                  value={bandeiraSelecionada}
                  onValueChanged={e => {
                    setBandeiraSelecionada(e.value);
                  }}
                  disabled={bloqueiaAlteracao}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <label>Produto Rede:</label>
                <SelectBox
                  placeholder="Selecione um produto..."
                  items={comboProduto}
                  displayExpr="descricao"
                  valueExpr="codigo"
                  value={produtoSelecionado}
                  onValueChanged={e => {
                    setProdutoSelecionado(e.value);
                  }}
                  disabled={bloqueiaAlteracao}
                />
              </div>
              <div className="col-5">
                <label>NSU:</label>
                  <TextBox
                  showClearButton={true}
                  value={nsu}
                  valueChangeEvent="change keypress"
                  onValueChanged={event => {
                      if (String(event.value).length <= 12) setNsu(event.value);
                  }}
                  onKeyPress={e => {
                    const event = e.event,
                      str = String.fromCharCode(event.keyCode);
                      if (!/[a-zA-Z0-9]/.test(str)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>

              <div className="col-2">
                <div className={`${styles.buttonWrapper}`}>
                  <Button
                    type="default"
                    hint={
                      isEditingFormaPagamento ? 'Confirmar edição' : 'Adicionar'
                    }
                    icon={isEditingFormaPagamento ? 'check' : 'plus'}
                    onClick={handleInsertFormaPagamento}
                    disabled={bloqueiaAlteracao}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div className="rttbl">
          <DataGrid
            dataSource={dsFormaPagamento}
            showBorders={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
          >
            <Column dataField="id" visible={false} />
            <Column
              dataField="formaPagamento"
              caption="Forma de Pagamento"
              fixed={true}
              minWidth={200}
            />
            <Column
              dataField="valor"
              caption="Valor"
              fixed={true}
              minWidth={150}
              format={{ type: 'currency', precision: 2 }}
            />
            <Column
              type="buttons"
              caption="Ações"
              visible={
                operacaoAcesso(ALTERA_FORMAPAGAMENTO) && !bloqueiaAlteracao
              }
              buttons={[
                {
                  hint: 'Excluir',
                  icon: 'trash',
                  visible: !bloqueiaAlteracao,
                  onClick: limparFormaPgto
                },
                {
                  hint: 'Editar',
                  icon: 'edit',
                  visible: !bloqueiaAlteracao,
                  onClick: alterarFormaPgto
                }
              ]}
            ></Column>
            <Editing
              mode="row"
              useIcons={true}
              allowUpdating={false}
              allowDeleting={false}
            />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[5, 15, 20, 50]}
              showInfo={true}
            />
            <Paging defaultPageSize={5} />
          </DataGrid>
        </div>
      </div>
    </ScrollView>
  );
}

function Clientes(props) {
  const [consumidorFinal, setConsumidorFinal] = useState(false);
  const [clienteSelecionado, setClienteSelecionado] = useState([]);
  const [listaCliente, setListaCliente] = useState([]);
  const [listaAutorizados, setListaAutorizados] = useState([]);
  const [autorizadoSelecionado, setAutorizadoSelecionado] = useState({});
  const [dsCliente, setDsCliente] = useState(null);
  const [objClienteSelecionado, setObjClienteSelecionado] = useState({});
  const CODIGO_CONSUMIDOR_FINAL = 2;
  const ALTERA_CLIENTE = 60684;

  const ddbRef = React.useRef();
  const autorizadoRef = React.useRef();

  useEffect(() => {
    const fetchListaCliente = async () => {
      const consulta = {
        classificacao: [1]
      };
      const { data: dataPessoas } = await getPessoas(consulta);

      let clientesOrdenados = sortList(dataPessoas.result, 'nome');

      setListaCliente(clientesOrdenados);
    };

    fetchListaCliente();
  }, []);

  useEffect(() => {
    if (props.data && props.data.listaClienteSelecionado) {
      setDsCliente(
        new DataSource({
          store: new ArrayStore(props.data.listaClienteSelecionado.cliente)
        })
      );
      if (
        props.data.listaClienteSelecionado.cliente &&
        props.data.listaClienteSelecionado.cliente.length > 0
      )
        setConsumidorFinal(
          props.data.listaClienteSelecionado.cliente[0].consumidorFinal
        );
    }
  }, [props.data]);

  const adicionaConsumidorFinal = () => {
    dsCliente.store().clear();
    dsCliente.store().insert({
      codigoCliente: CODIGO_CONSUMIDOR_FINAL,
      cnpjCpf: '',
      nome: 'Consumidor Final'
    });
    handleReloadCliente();

    const consumidorFinal = {
      codigoCliente: CODIGO_CONSUMIDOR_FINAL,
      cnpjCpf: '',
      nome: 'Consumidor Final',
      autorizado: null,
      codigoAutorizado: 0,
      placaAutorizado: null,
      possuiAutorizados: false,
      utilizaFatura: false,
      obrigaAutorizado: false,
      tipo: 0,
      consumidorFinal: true
    };

    props.data.listaClienteSelecionado.cliente[0] = { ...consumidorFinal };
  };

  const handleCheckValue = async args => {
    if (args.value) {
      let result = true;

      if (props.data.listaClienteSelecionado.cliente.length > 0) {
        result = await confirm(
          '<h1>Ao utilizar consumidor final, o cliente atual será removido do lançamento. Deseja continuar?</h1>',
          titulo
        );
      }

      if (result) {
        adicionaConsumidorFinal();
        setConsumidorFinal(args.value);
      } else {
        setConsumidorFinal(); // precisa atualizar sem nada uma vez para que o check mark suma
        setConsumidorFinal(args.previousValue);
      }
    } else {
      setConsumidorFinal(args.value);
      setObjClienteSelecionado({});
      dsCliente.store().clear();
      await handleReloadCliente();
    }
  };

  const handleInsertCliente = async () => {
    if (clienteSelecionado[0]) {
      let tempObjClienteSelecionado = objClienteSelecionado;
      if (
        !objClienteSelecionado.codigo ||
        clienteSelecionado[0].codigo !== objClienteSelecionado.codigo
      ) {
        const { data: objCliente } = await getPessoa(clienteSelecionado[0]);
        tempObjClienteSelecionado = objCliente;
      }
      if (!clienteSelecionado[0] && !consumidorFinal) {
        notify('Selecione um cliente para inserir.', 'error', 2500);
        return;
      }
      if (dsCliente._totalCount === 1) {
        notify('Permitido inserir apenas um cliente.', 'error', 2500);
        return;
      }

      if (
        tempObjClienteSelecionado.creditoFaturamento.obrigaAutorizado &&
        (!autorizadoSelecionado || isObjectEmpty(autorizadoSelecionado))
      ) {
        notify('Selecione um autorizado.', 'error', 2500);
        return;
      }
      let aut = { codigo: 0, placa: '', descricao: '', nome: '' };
      if (listaAutorizados.length > 0) {
        aut = { ...autorizadoSelecionado };
      }

      if (!consumidorFinal) {
        const clientePreparado = {
          codigoCliente: tempObjClienteSelecionado.codigo,
          cnpjCpf: tempObjClienteSelecionado.cnpjCpf,
          nome: tempObjClienteSelecionado.nome,
          autorizado: aut.descricao,
          codigoAutorizado: aut.codigo,
          placaAutorizado: aut.placa,
          possuiAutorizados: listaAutorizados.length > 0,
          obrigaAutorizado: tempObjClienteSelecionado.creditoFaturamento
            ? tempObjClienteSelecionado.creditoFaturamento.obrigaAutorizado
            : false,
          utilizaFatura: tempObjClienteSelecionado.creditoFaturamento
            ? tempObjClienteSelecionado.creditoFaturamento.utilizaFatura
            : false,
          tipo: tempObjClienteSelecionado.tipo
            ? tempObjClienteSelecionado.tipo.codigo
            : 0,
          consumidorFinal: false
        };
        dsCliente.store().insert({
          ...clientePreparado
        });

        props.data.listaClienteSelecionado.cliente[0] = {
          ...clientePreparado
        };
      }
      await handleReloadCliente();
      setClienteSelecionado([]);
      setObjClienteSelecionado({});
      setAutorizadoSelecionado({});
    }
  };

  const handleReloadCliente = async () => {
    await dsCliente.reload();
  };

  const handleRowRemoved = row => {
    setListaAutorizados([]);
    setObjClienteSelecionado({});
    if (consumidorFinal) {
      setConsumidorFinal(false);
    }
    setClienteSelecionado([]);
  };

  return (
    <ScrollView id="scrollview">
      <div className="container">
        <br />
        <div className="row">
          <div className="col-5">
            <CheckBox
              width={220}
              text="Utilizar consumidor final"
              value={consumidorFinal}
              onValueChanged={handleCheckValue}
              disabled={
                !operacaoAcesso(ALTERA_CLIENTE) ||
                isQuitadoOuFaturado(props.data.listaClienteSelecionado) ||
                isTurnoAbertoOuConferido(
                  props.data.listaClienteSelecionado.situacaoTurno
                )
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8">
            <label>Cliente:</label>
            <DropDownBox
              ref={ddbRef}
              value={clienteSelecionado}
              valueExpr="codigo"
              displayExpr="nome"
              placeholder="Pesquise o cliente por nome, razão social, apelido, CPF ou CNPJ"
              showClearButton={true}
              dataSource={listaCliente}
              onValueChanged={e => {
                syncTreeViewSelection(
                  e,
                  clienteSelecionado,
                  setClienteSelecionado
                );
              }}
              contentRender={() => {
                return (
                  <LinxTreeView
                    dataSource={listaCliente}
                    property={clienteSelecionado}
                    method={setClienteSelecionado}
                    noDataText="Nenhum cliente encontrado."
                    selectionMode="single"
                    showCheckBoxesMode="none"
                    displayExpr="nome"
                    keyExpr="codigo"
                    onItemSelectionChanged={async e => {
                      if (e.itemData.codigo === objClienteSelecionado.codigo) {
                        return; // escolheu o mesmo
                      }

                      const { data: objCliente } = await getPessoa(
                        e.itemData.codigo
                      );

                      setObjClienteSelecionado(objCliente);

                      if (objCliente.autorizados) {
                        const autAux = sortList(objCliente.autorizados, 'nome');
                        const listaAutorizadosCliente = autAux.map(a => {
                          return {
                            ...a,
                            descricao:
                              a.nome && a.placa
                                ? a.nome + ' - ' + a.placa
                                : a.nome
                                ? a.nome
                                : a.placa
                            // se tem nome e placa, coloca os dois
                            // se só tem nome, coloca o nome
                            // se não tem nome, tem a placa
                          };
                        });

                        setListaAutorizados(listaAutorizadosCliente);
                      } else {
                        setListaAutorizados([]);
                      }
                      setAutorizadoSelecionado({});
                      ddbRef.current.instance.close();
                    }}
                  />
                );
              }}
              disabled={
                !operacaoAcesso(ALTERA_CLIENTE) ||
                consumidorFinal ||
                isQuitadoOuFaturado(props.data.listaClienteSelecionado) ||
                isTurnoAbertoOuConferido(
                  props.data.listaClienteSelecionado.situacaoTurno
                )
              }
            />
          </div>
          <div className="col-2">
            <div className={`${styles.buttonWrapper}`}>
              {!operacaoAcesso(ALTERA_CLIENTE) ||
              consumidorFinal ||
              isQuitadoOuFaturado(props.data.listaClienteSelecionado) ||
              isTurnoAbertoOuConferido(
                props.data.listaClienteSelecionado.situacaoTurno
              ) ? (
                <></>
              ) : (
                <Button
                  type="default"
                  hint="Adicionar Cliente"
                  icon="plus"
                  onClick={() => {
                    handleInsertCliente();
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {!consumidorFinal ? (
          <div className="row">
            {listaAutorizados.length > 0 ? (
              <>
                <div className="col-8">
                  <span>Autorizado:</span>
                  <DropDownBox
                    ref={autorizadoRef}
                    value={autorizadoSelecionado.descricao}
                    valueExpr="codigo"
                    deferRendering={false}
                    displayExpr="descricao"
                    placeholder="Selecione..."
                    showClearButton={true}
                    dataSource={listaAutorizados}
                    onValueChanged={e => {
                      setAutorizadoSelecionado((e.value && e.value[0]) || {});
                      autorizadoRef.current.instance.close();
                    }}
                    contentRender={() => {
                      return (
                        <DataGrid
                          dataSource={listaAutorizados}
                          columns={[
                            { dataField: 'nome', caption: 'Nome' },
                            { dataField: 'placa', caption: 'Placa' }
                          ]}
                          hoverStateEnabled={true}
                          showBorders={true}
                          selectedRowKeys={autorizadoSelecionado}
                          onSelectionChanged={e => {
                            setAutorizadoSelecionado(
                              (e.selectedRowKeys.length &&
                                e.selectedRowKeys &&
                                e.selectedRowKeys[0]) ||
                                {}
                            );
                            autorizadoRef.current.instance.close();
                          }}
                          rowAlternationEnabled={true}
                        >
                          <Selection mode="single" />
                        </DataGrid>
                      );
                    }}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}

        <div className="row">
          <div className="col-12">
            <DataGrid
              dataSource={dsCliente}
              defaultColumns={[
                { dataField: 'codigo', visible: false },
                {
                  dataField: 'cnpjCpf',
                  caption: 'CPF/CNPJ',
                  fixed: true,
                  minWidth: '100'
                },
                {
                  dataField: 'nome',
                  caption: 'Nome/Razão Social',
                  fixed: true,
                  minWidth: '200'
                },
                {
                  dataField: 'autorizado',
                  caption: 'Autorizado',
                  fixed: true,
                  minWidth: '100'
                },
                {
                  type: 'buttons',
                  caption: 'Ações',
                  visible:
                    operacaoAcesso(ALTERA_CLIENTE) &&
                    !isQuitadoOuFaturado(props.data.listaClienteSelecionado) &&
                    !isTurnoAbertoOuConferido(
                      props.data.listaClienteSelecionado.situacaoTurno
                    ),
                  buttons: ['delete']
                }
              ]}
              showBorders={true}
              allowColumnResizing={true}
              allowColumnReordering={true}
              onRowRemoved={handleRowRemoved}
              rowAlternationEnabled={true}
            >
              <Editing
                mode="row"
                allowAdding={false}
                allowDeleting={
                  operacaoAcesso(ALTERA_CLIENTE) ||
                  !(
                    isQuitadoOuFaturado(props.data.listaClienteSelecionado) ||
                    isTurnoAbertoOuConferido(
                      props.data.listaClienteSelecionado.situacaoTurno
                    )
                  )
                }
                allowUpdating={false}
                useIcons={true}
              >
                <Texts
                  confirmDeleteMessage="<h1>Tem certeza que deseja excluir este registro?</h1>"
                  confirmDeleteTitle={titulo}
                />
              </Editing>
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[5, 15, 20, 50]}
                showInfo={true}
              />
              <Paging defaultPageSize={5} />
            </DataGrid>
          </div>
        </div>
      </div>
    </ScrollView>
  );
}

const isTurnoAbertoOuConferido = situacaoTurno => {
  return (
    sameInt(situacaoTurno, Situacoes.Conferido) ||
    sameInt(situacaoTurno, Situacoes.AbertoNoPDV)
  );
};

const isQuitadoOuFaturado = documento => {
  return documento.dataQuitacao || documento.faturado;
};
