import LogoPostoPopNeg from '../../../assets/images/postopop/LogoPostoPopNeg.png';
import LogoEmporioPopWhite from '../../../assets/images/emporiopop/emporio-pop-white.png';
import LogoSellerWebWhite from '../../../assets/images/sellerweb/seller-web-white.png'
import LogoEmporioProWhite from '../../../assets/images/emporiopro/emporio-pro-white.png'

import LogoEmporioPopSmall from '../../../assets/images/emporiopop/EmporioPopLogoSmall.png';
import LogoEmporioProSmall from '../../../assets/images/emporiopro/EmporioProLogoSmall.png';
import LogoSellerWebSmall from '../../../assets/images/sellerweb/SellerWebLogoSmall.png';
import LogoPostoPopSmall from '../../../assets/images/postopop/PostoPopLogoSmall.png';

import LogoEmporioWhite from '../../../assets/images/linx/emporio-white.png';
import LogoEmporioSmall from '../../../assets/images/linx/EmporioLogoSmall.png';


export const POSTOPOP = {
    nome: 'POSTO POP',
    nomeLower: 'Posto Pop',
    message: 'DA PISTA AO ESCRITÓRIO! MAIS FACILIDADE PARA O POSTO, MAIS DINHEIRO NO BOLSO',
    logo: LogoPostoPopNeg,
    logoSmall: LogoPostoPopSmall,
    showLais: true
};

export const EMPORIOPOP = {
    nome: 'EMPÓRIO POP',
    nomeLower: 'Empório Pop',
    message: '',
    logo: LogoEmporioPopWhite,
    logoSmall: LogoEmporioPopSmall,
    showLais: false
};

export const SELLERWEB = { 
    nome: '', 
    nomeLower: 'Seller Web',
    message: '', 
    logo: LogoSellerWebWhite, 
    logoSmall: LogoSellerWebSmall,
    showLais: false 
};

export const EMPORIOPRO = {
    nome: '',
    nomeLower: 'Empório Pro',
    message: '',
    logo: LogoEmporioProWhite,
    logoSmall: LogoEmporioProSmall,
    showLais: false
};

export const EMPORIO = {
    nome: 'LINX EMPÓRIO',
    nomeLower: 'Linx Empório',
    message: '',
    logo: LogoEmporioWhite,
    logoSmall: LogoEmporioSmall,
    showLais: false
};