import React, { useState, useEffect, useRef } from 'react';

import { LoadPanel } from 'devextreme-react';
import { setupFacilService } from '../../SetupFacil.service.js';
import { ajusteProdutosUtils } from './SetupFacilAjusteProdutosUtils';
import { NotificationLevel } from '../../../../../shared/constants/Const';

import styles from './SetupFacilAjusteProdutos.module.scss';
import config from 'devextreme/core/config';
import notify from 'devextreme/ui/notify';
import { getUnidadeMedida } from '../../../../cadastro/produtos/Produtos.service.js';
import { getDadosEmpresa } from '../../../../../pages/configuracoes/empresas/Empresas.service';
import { getCnpj } from '../../../../../shared/utils/Utils';
import SetupFacilAjusteProdutosDataGrid from './SetupFacilAjusteProdutosDataGrid.jsx';

export default function SetupFacilAjusteProdutos({ ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [validation, setValidation] = useState([]);
  const [setores, setSetores] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [regimeTributario, setRegimeTributario] = useState(0);
  const [gridListas, setGridListas] = useState({});
  const dataGridRef = useRef();

  config({
    defaultCurrency: 'BRL',
  });

  useEffect(() => {
    props.handleNextStep(() => () => handleNextStep());
    props.awaitIsCompletedStepUpdate(true);
    props.messageStepNotCompletedUpdate('Favor revisar os itens com erros de validação (em vermelho) para prosseguir!');
    
    getDados();

    async function getDados() {
      setIsLoading(true);

      console.log('numberPreviousStep ' + props.numberPreviousStep);

      if (props.numberPreviousStep === 4) {
        setDataSource(props.dados.ajusteProdutos.produtos);
        setUnidadesMedida(props.dados.ajusteProdutos.unidadesMedida);
        setSetores(props.dados.ajusteProdutos.setores);
        setRegimeTributario(props.dados.ajusteProdutos.simplesNacional);
        setGridListas(props.dados.ajusteProdutos.gridListas)
      } else {

        const uf = await getUF();
        setRegimeTributario(uf.fiscais.regimeTributario);

        const unidadesMedidaRetorno = await getUnidadesCombo();
        setUnidadesMedida(unidadesMedidaRetorno);

        const setoresRetorno = await getSetoresCombo();
        let setoresRetornoAjustado = [];
        setoresRetorno.forEach(element => {
          if (element.id === 4) element.descricao = 'Centro Técnico';
          setoresRetornoAjustado.push(element);
        });
        setSetores(setoresRetorno);

        const gridListasRetorno = await setupFacilService.GetListasGrid();
        setGridListas(gridListasRetorno);

        props.dadosUpdate(prevDados => {
          return {
            ...prevDados,
            ajusteProdutos: {
              setores: setoresRetorno,
              unidadesMedida: unidadesMedidaRetorno,
              simplesNacional: uf.fiscais.regimeTributario,
              gridListas: gridListasRetorno,            },
          };
        });

        if (props.dados.fonteDados === 1) {
          const produtosBase = await getProdutos();
          setDataSource(ajusteProdutosUtils.tratarProdutosIn(produtosBase, gridListasRetorno, unidadesMedidaRetorno));
        } else if (props.dados.fonteDados === 2) {
          setDataSource(ajusteProdutosUtils.tratarProdutosIn(props.dados.selecaoProdutos.produtosSelecionados, gridListasRetorno, unidadesMedidaRetorno));
        } else if (props.dados.fonteDados === 3) {
          setDataSource(ajusteProdutosUtils.tratarProdutosIn(props.dados.selecaoProdutos.produtosSelecionados, gridListasRetorno, unidadesMedidaRetorno));
        }
      }

      setIsLoading(false);
    }

    async function getUnidadesCombo() {
      const resp = await getUnidadeMedida();
  
      if (resp.status === 200) {
        const dados = resp.data;
        return ajusteProdutosUtils.tratarUnidadeMedida(dados);
      } else {
        notify(resp.statusText, 'error', 2000, 'top');
        return [];
      }
    }
    
    async function getSetoresCombo() {
      const resp = await setupFacilService.GetSetores();
  
      if (resp.notificationLevel === NotificationLevel.Success) {
        return resp.data;
      } else {
        notify(resp.message, 'error', 2000, 'top');
        return [];
      }
    }
  
    async function getProdutos() {
      const produtosId = props.dados.selecaoProdutos.produtosSelecionados.map(produto => produto.codigo);
      const cnpj = await getCnpj();
      const filter = {
        query: {
          cnpj: cnpj,
          codigo: produtosId,
        },
      };
      
      const resp = await setupFacilService.GetProdutosPorFiltro(filter);
      console.log('resp >>> ' + resp);
      console.log('resp.result >>> ' + resp.result);
      if (resp.status === 200) {
        return resp.result;
      } else {
        notify(resp.message, 'error', 2000, 'top');
        return [];
      }
    }

    async function handleNextStep() {
      setIsLoading(true);
      const produtosGrid = dataGridRef.current.props.dataSource;
  
      const produtos = produtosGrid.map(produto => {
        return {
          ...produto,
        };
      });
  
      const isValid = await getProdutosIsValid(produtos);
      if (isValid) {
        props.dadosUpdate(prevDados => {
          return {
            ...prevDados,
            ajusteProdutos: {
              ...prevDados.ajusteProdutos,
              produtos: produtos,
            },
          };
        });
        props.isCompletedStepUpdate(true);
      } else {
        props.isCompletedStepUpdate(false);
      }
      setIsLoading(false);
    }

    async function getProdutosIsValid(produtos, gridListas, unidadesMedida) {
      const resp = await setupFacilService.GetValidacaoProdutos(produtos);
      console.log(resp);
      if (resp.notificationLevel === NotificationLevel.Success) {
        if (resp.data.length > 0) {
          setValidation(resp.data);         
          return false;
        } else {
          setValidation([]);
          return true;
        }
      } else {
        notify(resp.message ? resp.message : resp.title, 'error', 2000, 'top');
        setValidation([]);
        return false;
      }
    }
  
    async function getUF() {
      const resp = await getDadosEmpresa();
      console.log(resp);
      if (resp.status === 200) {
        console.log(resp.data);
        const dados = resp.data.data;
        return dados;
      } else {
        notify(resp.statusText, 'error', 2000, 'top');
        return 0;
      }
    }

  }, []);
  
 
  return (
    <>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={isLoading}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div className={styles.container}>
        <span>Valide as informações dos produtos e faça os ajustes necessários para importação.</span>
        <div className={styles.textInfo}>
          <p>Os dados de impostos são apenas sugestões. Você deve verificar com a sua contabilidade as informações corretas para o cadastro.</p>
        </div>
        <span>
          Se preferir, você pode selecionar a opção <b> EXPORTAR </b> e enviar esta planilha para que sua contabilidade preencha as informações fiscais. Depois,
          basta realizar a importação utilizando a opção de fonte de dados: importação de planilha template.
        </span>
        <SetupFacilAjusteProdutosDataGrid
          dataSource={dataSource}
          setores={setores}
          unidadesMedida={unidadesMedida}
          validation={validation}
          dataGridRef={dataGridRef}
          simplesNacional={regimeTributario === 4 || regimeTributario === 8}
          gridListas={gridListas}
        />
      </div>
    </>
  );
}
