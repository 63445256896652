import React, { Component } from 'react';

import {
  getUrl,
  getDadosSellerPop,
  getNomePagina
} from '../../../shared/utils/Utils';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import SellerPopFrame from '../../../components/SellerPopFrame/SellerPopFrame';
import { menus } from '../../../shared/constants/MenuConstants';


const PATH_MENU = menus.COD_60960;

export class MonitoramentoNfe extends Component {
  render() {
    return (
      <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
        <SellerPopFrame
          url={
            getUrl() + '/MonitorNFe.aspx?' + getDadosSellerPop(PATH_MENU)
          }
          
        />
      </LinxPostos>
    );
  }
}
