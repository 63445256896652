import React from 'react';
import styles from './FilterPanel.module.scss';
import BotaoCarregamento from '../../botao/BotaoCarregamento/BotaoCarregamento';
import PreReportButton from '../PreReportButton/PreReportButton';

const FilterPanel = props => (
  <div className={`${styles.filterPanel} filterPanel mt-3`}>
    <div className="shadow-sm bg-white p-3">
      <div
        className={`${styles.filters} ${
          props.showPreReports ? styles.expanded : ''
        } ${props.showSearch ? styles.search : ''}`}
      >
        <div className="container-fluid">
          <div className={`${styles.filtersrWrapper} row`}>
            <div className="col-md-12">
              <div className={styles.buttonWrapper}>
                {props.reports
                  ? props.reports
                      .sort((a, b) => {
                        return a.ordem - b.ordem;
                      })
                      .map(report => (
                        <PreReportButton
                          key={report.id}
                          report={report}
                          onClick={report => props.reportHandle(report)}
                          disabled={props.searching}
                        />
                      ))
                  : null}
              </div>
            </div>
          </div>
          {props.showSearch && (
            <>
              <hr className={`${props.showSearch ? styles.fadeIn : ''}`} />
              <div
                className={`${props.showSearch ? styles.fadeIn : ''} ${
                  styles.searchWrapper
                }`}
              >
                {props.children}

                <footer className="text-right mt-4">
                  <button
                    className="btn btn-light"
                    onClick={props.clearFilters}
                    disabled={props.searching}
                  >
                    {' '}
                    Limpar filtros{' '}
                  </button>
                  <BotaoCarregamento
                    click={props.onSearch}
                    disabled={props.disabledSearch || props.searching}
                    //loading={props.searching}
                    carregar="PESQUISAR"
                    carregando="PESQUISANDO..."
                  />
                </footer>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default FilterPanel;
