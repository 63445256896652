import React from 'react';
import * as _ from 'lodash';
import { formatStringToCurrency } from '../../../../../shared/utils/Utils';

import { locale } from 'devextreme/localization';
import moment from 'moment';
import { PrintStyle } from './PrintStyles';

export function ReciboComodato({ pedido }) {
  moment.locale('pt-br');
  locale('pt-br');
  const sty = new PrintStyle();
  return (
    <>
      <h5>COMODATO</h5>
      <table className="w-100" style={sty.borderSolid}>
        <thead>
          <tr style={sty.borderSolid}>
            <th width="50%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Código - Descricao do Produto
            </th>
            <th width="5%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              UN
            </th>
            <th width="10%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Quantidade
            </th>
            <th width="5%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Retorno
            </th>
            <th width="10%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Preço Unit.
            </th>
            <th width="20%" className="text-center pl-1 pr-1" style={sty.tableTh}>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          {pedido.itens.map((item, iIdx) => (
            <tr key={iIdx}>
              <td className="text-left" style={sty.borderSolid}>
                <p className="ml-3 pr-1" style={sty.tdP}>{`${item.produto.codigo} - ${item.produto.descricao}`}</p>
              </td>
              <td className="text-right" style={sty.borderSolid}>
                <p className="pr-1" style={sty.tdP}>
                  {item.produto.estoque.unidadeMedidaVenda.sigla.toUpperCase()}
                </p>
              </td>
              <td className="text-right" style={sty.borderSolid}>
                <p className="pr-1" style={sty.tdP}>{`${item.quantidade}`}</p>
              </td>
              <td className="text-right" style={sty.borderSolid}>
                <p className="pr-1" style={sty.tdP}></p>
              </td>
              <td className="text-right" style={sty.borderSolid}>
                <p className="pr-1" style={sty.tdP}>{`${formatStringToCurrency(item.valorAtivo)}`}</p>
              </td>
              <td className="text-right" style={sty.borderSolid}>
                <p className="pr-1" style={sty.tdP}>{`${
                  pedido.tipoPedido.id === 3 ? formatStringToCurrency(item.totalAtivos) : formatStringToCurrency(item.totalProdutos)
                }`}</p>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr style={sty.borderSolid}>
            <td colSpan={4} className="text-right" style={sty.borderSolid}>
              <p className="pr-1 mr-3" style={sty.tdP}>
                TOTAL:
              </p>
            </td>
            <td colSpan={2} className="text-right" style={sty.borderSolid}>
              <p className="pr-1" style={sty.tdP}>
                {formatStringToCurrency(_.sumBy(pedido.itens, item => (pedido.tipoPedido.id === 3 ? item.totalAtivos : item.totalProdutos)))}
              </p>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}
