import { LoadPanel } from 'devextreme-react';
import DataGrid, { Column, Export, Summary, TotalItem } from 'devextreme-react/data-grid';
import { locale } from 'devextreme/localization';
import { alert } from 'devextreme/ui/dialog';
import React, { createRef, useCallback, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../../shared/utils/Utils';
import styles from './ComprasDinamico.module.scss';
import { ComprasDinamicoService } from './ComprasDinamico.service';
import { FloatDateInput } from './components/FloatDateInput';
import { ExportarParaExcelService } from './services/ExportarParaExcell.service';
import { ExportarParaPdfService } from './services/ExportarParaPdf.service';

export function ComprasDinamico(props) {
  locale('pt-BR');
  const PATH_MENU = menus.COD_16355;
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState([]);
  const service = new ComprasDinamicoService();
  const refDtEmissaoDe = createRef();
  const refDtEmissaoAte = createRef();
  const gridResultRef = createRef();

  const onClick = useCallback(() => {
    setSearching(true);
    var find = async () => {
      try {
        var result = await service.find({
          dataEmissaoDe: new Date(refDtEmissaoDe.current.getValue()).toISOString(),
          dataEmissaoAte: new Date(refDtEmissaoAte.current.getValue()).toISOString(),
        });
        setData(result);
        setSearching(false);
      } catch (error) {
        setSearching(false);
        alert(error.message, 'Ops!');
      }
    };
    find();
  }, [service, refDtEmissaoDe, refDtEmissaoAte]);

  const onExporting = useCallback(async e => {
    if (e.format === 'xlsx') {
      await new ExportarParaExcelService().execute(e, 'Relatório Royalties', '', 'Relatorio_Royalties');
    } else {
      await new ExportarParaPdfService().execute(
        e,
        'Relatório Royalties',
        `Filtro de ${new Date(refDtEmissaoDe.current.getValue()).toLocaleDateString()} até ${new Date(
          refDtEmissaoAte.current.getValue(),
        ).toLocaleDateString()}`,
        'Relatorio_Royalties',
      );
    }

    e.cancel = true;
  }, [refDtEmissaoDe, refDtEmissaoAte]);

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU)} id="page">
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={{ of: '#page' }}
        visible={searching}
        showIndicator={true}
        shading={true}
        message="Carregando..."
        showPane={true}
        closeOnOutsideClick={false}
      />
      <div className={styles.container}>
        <Card>
          <Card.Header className="text-center">
            <h1>RELATÓRIO DE ROYALTIES</h1>
            <Row className="m-0 p-0">
              <Col md={9} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}></Col>
              <Col md={2} className="m-0 p-0" style={{ borderLeft: 'solid 1px #f0f0f0' }}>
                <Row className="m-0 pl-0 pt-0 pb-0 pr-3">
                  <Col md={12} className="pl-0 pr-0">
                    <FloatDateInput
                      ref={refDtEmissaoDe}
                      isMinTime={true}
                      displayFormat="dd/MM/yyyy"
                      placeholder="de:"
                      label="Data de Emissão"
                      initialValue={new Date(new Date().getFullYear(), new Date().getMonth(), 1)}
                      id="dataEventoDe"
                    />
                  </Col>
                  <Col md={12} className="pl-0 pr-0">
                    <FloatDateInput
                      ref={refDtEmissaoAte}
                      isMinTime={false}
                      displayFormat="dd/MM/yyyy"
                      label=""
                      placeholder="até:"
                      initialValue={new Date(new Date().setHours(0, 0, 0))}
                      id="dataEventoAte"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={1} className="" style={{ borderLeft: 'solid 1px #f0f0f0', marginBottom: '25px' }}>
                <Button onClick={onClick} style={{ height: '100%', width: '100%' }}>
                  Buscar
                </Button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <DataGrid ref={gridResultRef} dataSource={data} paging={false} onExporting={onExporting}>
              <Column dataField="nomeFantasia" caption="Empresa" />
              <Column dataField="dataEntrada" caption="Data de Entrada" dataType="date" format={'dd/MM/yyyy'} />
              <Column dataField="dataEmissao" caption="Data de Emissão" dataType="date" format={'dd/MM/yyyy'} />
              <Column dataField="numeroNota" caption="Nota Fiscal" />
              <Column dataField="fornecedor" caption="Razão Social" />
              <Column dataField="codigo" caption="Código" />
              <Column dataField="descricao" caption="Produto" />
              <Column dataField="quantidade" caption="Quantidade" alignment={'right'} format="#,##0.00" />
              <Column dataField="valorCustoUnitario" caption="Preço" alignment={'right'} format="R$ #,##0.00" />
              <Column dataField="baseRoyalties" caption="Base Royalties" alignment={'right'} format="R$ #,##0.00" />
              <Column
                dataField="valorRoyalties"
                caption={`Royalties`}
                alignment={'right'}
                format="R$ #,##0.00"
              />
              <Column dataField="valorImposto" caption={`Imposto Royalties`} alignment={'right'} format="R$ #,##0.00" />
              <Column dataField="valorMarketing" caption={`Fundo Propaganda`} alignment={'right'} format="R$ #,##0.00" />
              <Column dataField="valorAPagar" caption={`Valor a pagar`} alignment={'right'} format="R$ #,##0.00" />
              <Summary>
                <TotalItem column="baseRoyalties" displayFormat={'R$ {0}'} summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
                <TotalItem column="valorRoyalties" displayFormat={'R$ {0}'} summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
                <TotalItem column="valorImposto" displayFormat={'R$ {0}'} summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
                <TotalItem column="valorMarketing" displayFormat={'R$ {0}'} summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
                <TotalItem column="valorAPagar" displayFormat={'R$ {0}'} summaryType="sum" valueFormat={{ type: 'fixedPoint', precision: 2 }} />
              </Summary>
              <Export
                enabled={true}
                texts={{ exportAll: 'Exporte tudo para {0}', exportSelectedRows: 'Exporte a seleção para {0}', exportTo: 'Exporte para {0}' }}
                formats={['pdf', 'xlsx']}
                fileName={'RelatorioRoyalties'}
              />
            </DataGrid>
          </Card.Body>
        </Card>
      </div>
    </LinxPostos>
  );
}
