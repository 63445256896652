import React, { useState, useEffect } from 'react';
import { estruturaCartoesTreeViewService } from './EstruturaCartoesTreeView.service';
import { TreeView, DropDownBox } from 'devextreme-react';
import { DropDownOptions } from 'devextreme-react/drop-down-box';
import { Position } from 'devextreme-react/popup';

export const EstruturaCartoesTreeView = React.forwardRef((props, ref) => {
  const [treeView] = useState(null);
  const [estruturas, setEstruturas] = useState();
  const [estruturasCarregadas, setEstruturasCarregadas] = useState(false);
  const [selectedEstruturas, setSelectedEstruturas] = useState([]);

  const dataSource = estruturas,
    noDataText = props.noDataText
      ? props.noDataText
      : 'Sem registros para exibir.',
    property = props.property || null,
    method = props.method ? props.method : () => {},
    selectionMode = props.selectionMode ? props.selectionMode : 'multiple',
    selectNodesRecursive = props.selectNodesRecursive
      ? props.selectNodesRecursive
      : false,
    showCheckBoxesMode = props.showCheckBoxesMode
      ? props.showCheckBoxesMode
      : 'selectAll',
    displayExpr = props.displayExpr ? props.displayExpr : 'descricao',
    keyExpr = props.keyExpr ? props.keyExpr : 'codigoEstrutura',
    disabled = props.disabled ? props.disabled : false;

  const getEstruturas = async () => {
    const result = await estruturaCartoesTreeViewService.getEstruturaCartoes();
    setEstruturas(result);
    setEstruturasCarregadas(true);
  };

  useEffect(() => {
    getEstruturas();
  }, [estruturasCarregadas]);

  const renderTreeView = () => {
    return (
      <TreeView
        dataSource={dataSource}
        noDataText={noDataText}
        ref={ref}
        keyExpr={keyExpr}
        selectionMode={selectionMode}
        showCheckBoxesMode={showCheckBoxesMode}
        searchEnabled={true}
        selectNodesRecursive={selectNodesRecursive}
        displayExpr={displayExpr}
        selectByClick={true}
        onSelectionChanged={e => {
          setSelectedEstruturas(e.component.getSelectedNodesKeys());
          method(e.component.getSelectedNodesKeys());
          props.onSelectionChanged(e);
        }}
        onContentReady={e => {
          syncTreeViewSelection(e, property, method, treeView);
        }}
        height={ 
          function() {
            return window.innerHeight / 3;
          }
        }
      />
    );
  };

  return (
    <DropDownBox
      id="targetElement"
      value={
        selectedEstruturas.length
          ? `Estruturas Selecionadas: ${selectedEstruturas.length}`
          : []
      }
      deferRendering={false}
      disabled={disabled}
      placeholder="Selecione..."
      dataSource={estruturas}
      contentRender={renderTreeView}
    >
      <DropDownOptions>
        <Position my="left bottom" at="left top" of="#targetElement" />
      </DropDownOptions>
    </DropDownBox>
  );
});

export const syncTreeViewSelection = (e, property, method, treeView) => {
  if (e === undefined) {
    let tree = treeView && treeView.instance;

    tree.unselectAll();
  } else {
    let tree =
      (e.component.selectItem && e.component) ||
      (treeView && treeView.instance);

    if (tree) {
      if (e.value === null || e === undefined) {
        tree.unselectAll();
      } else {
        let values = e.value || property;
        values &&
          values.forEach(function(value) {
            tree.selectItem(value);
          });
      }
    }
    if (e.value !== undefined && method) {
      method(e.value);
    }
  }
};

export const unselectAll = (e, treeView) => {
  let tree =
    (e.component.selectItem && e.component) || (treeView && treeView.instance);

  if (tree) {
    tree.unselectAll();
  }
};
