import axios from 'axios';
import { getCnpj } from "../../../shared/utils/Utils";

export function getCombustiveisPeriodo(dataInicial, dataFinal) {
  return axios.get(`LxApi/v1/LMC/MontarComboCombustivel/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      dataInicial,
      dataFinal
    }
  });
}

export function getParametrosLMC() {
  return axios.get(`LxApi/v1/LMC/MontaParametros/`, {
    params: {
      cnpjEstabelecimento: getCnpj()
    }
  });
}

export function getLMC(
  rebuild,
  dataInicial,
  dataFinal,
  produtos,
  distribuidora,
  numeroOrdem
) {
  return axios.post(`LxApi/v1/LMC/${rebuild ? "Gerar" : "Listar"}/`, {
    cnpjEstabelecimento: getCnpj(),
    dataInicial,
    dataFinal,
    produtos,
    parametros: { distribuidora, numeroOrdem }
  });
}

export function getLMCRecebidos(idLMC) {
  return axios.get(`LxApi/v1/LMC/ListarRecebido/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      idLMC
    }
  });
}

// export function insertLMCRecebidos(idLMC, observacao, recebidos) {
//   return axios.post(`LxApi/v1/LMC/Incluir/`, {
//     cnpjEstabelecimento: getCnpj(),
//     idLMC, observacao, recebidos
//   });
// };

export function updateLMCRecebidos(detalhesCombustivel) {
  return axios.put(`LxApi/v1/LMC/AlterarRecebido/`, {
    ...detalhesCombustivel,
    cnpjEstabelecimento: getCnpj()
  });
}

// export function deleteRecebidos(idLMC, idLMCRecebido) {
//   return axios.delete(`LxApi/v1/LMC/ExcluirRecebido/`, {
//     params: {

//       cnpjEstabelecimento: getCnpj(),
//       idLMC, idLMCRecebido
//     }
//   });
// };

export function viewLMC(
  tipoVisualizacao,
  dataInicial,
  dataFinal,
  produtos,
  distribuidora,
  numeroOrdem
) {
  return axios.post(`LxApi/v1/LMC/Visualizar`, {
    cnpjEstabelecimento: getCnpj(),
    dataInicial,
    dataFinal,
    produtos,
    tipoVisualizacao,
    parametros: { distribuidora, numeroOrdem }
  });
}

export async function getTanqueList(dataMovimento, codigoCombustivel) {
  return await axios.get(`LxApi/v1/LMC/MontarComboTanque/`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      data: dataMovimento,
      codigoProduto: codigoCombustivel
    }
  });
}
