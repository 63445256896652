import React, { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import { Form } from 'react-bootstrap';
import { useNovaOsItem } from '../../../../../redux/slices/consignadoComodato/novaOsItemSlice';
import { BuscarProdutoService } from '../../../../../services/produtos/BuscarProdutos.service';
import { DropDownImage } from '../drop-down-image';

export const BuscarProduto = memo(
  forwardRef(({ title, onNextFocus, tipoPedidoId }, ref) => {
    const { changeValue, changeBlur } = useNovaOsItem();
    const { values, touched, errors } = useSelector(state => state.novaOsItem);
    const dispatch = useDispatch();
    const select = useRef();

    const store = new DataSource({
      key: 'id',
      byKey: key => {
        return [values.produto || null];
      },
      load: async function(arg) {
        if (arg.searchOperation !== 'contains') {
          return [];
        }
        let produtos = await new BuscarProdutoService().execute(arg.searchValue, [3,8].includes(tipoPedidoId) ? 9 : 1);
        produtos = produtos.map(x => {
          x.display = `[${x.codigo || x.codigoRapido}] - ${x.descricao} [SALDO: ${((x.estoque || {}).saldoEstoque || 0)}]`;
          return x;
        })
        return produtos;
      },
    });

    useImperativeHandle(ref, () => {
      return {
        focus: () => {
          select.current.instance.focus();
          select.current.instance.reset();
        },
      };
    });

    const onSelectionChanged = useCallback(
      e => {
        if (!e.selectedItem) {
          return;
        }
        const data = { ...e.selectedItem };
        if (values.produto && data.codigo === values.produto.codigo) {
          return;
        }
        dispatch(changeValue({ fieldName: 'produto', value: data }));
        dispatch(changeValue({ fieldName: 'valorAtivo', value: data.precoVenda }));
        dispatch(changeValue({ fieldName: 'quantidade', value: 1 }));
        if (onNextFocus !== undefined) {
          onNextFocus();
        }
      },
      [dispatch, changeValue, onNextFocus, values.produto],
    );

    return (
      <>
        <Form.Label htmlFor="produto" className="text-primary">
          {title}
          <span className="text-danger">*</span>
        </Form.Label>
        <SelectBox
          name="produto"
          ref={select}
          dropDownButtonRender={DropDownImage}
          searchEnabled={true}
          hint="Digite a descrição, EAN ou o codigo rápido do produto"
          placeholder="Digite a descrição, EAN ou o codigo rápido do produto"
          displayExpr="display"
          height={40}
          valueExpr="id"
          dataSource={store}
          selectedItem={values.produto}
          searchMode="contains"
          onSelectionChanged={onSelectionChanged}
          searchTimeoutOption={500}
          onFocusOut={e => {
            dispatch(changeBlur({ fieldName: 'produto' }));
          }}
        ></SelectBox>
        {touched.produto && errors.produto && <div className="text-danger validationError">{errors.produto}</div>}
      </>
    );
  }),
);
