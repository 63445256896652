import React, { useEffect, useCallback } from "react";
import Form, { SimpleItem } from "devextreme-react/form";
import { PagamentosService } from "../../Pagamentos.service";
import { getDadosLogin } from "../../../../../../../shared/utils/Utils";
import notify from "devextreme/ui/notify";

export const Formulario = ({
  isShow,
  formRef,
  formData,
  setFormData,
  code
}) => {



  const extractBillData = (billData) => {

    const formattedValue = parseFloat((parseInt(billData.value) / 100).toFixed(2));
    const addition = parseFloat((parseInt(billData.interest_value) / 100).toFixed(2));

    return {
      expiration: billData.due_date,
      amount: formattedValue,
      supplier: billData.recipient.name,
      bank: billData.bank_name,
      addition: addition

    };
  }

const barCode = useCallback(async (event) => {
    const value = event.value.replace(/\s+/g, "");

    try {

      const dadosLogin = getDadosLogin();

      var payload = {
        barCode: value,
        estaCod: dadosLogin.codigoEstabelecimento,
      }

      var response = await PagamentosService.decodeSlip(payload);

      if(response.result) {
        const billData = extractBillData(response.result);
        setFormData({...formData,  barCode: value, ...billData});
      }
      else {
        notify("Código de barras inválido", "error", 5000);
      }

    } catch (error) {
      notify("Ocorreu um erro ao tentar decodificar o código de barras", "error", 5000);
    }
  }, [formData, setFormData]);

  useEffect(() => {

    if (code && code.trim() !== '') {
      setFormData({...formData, barCode: code});
      barCode({ value: code });
    }
  },[]);

  return (
    <>
      {isShow &&
        <Form ref={formRef} formData={formData} >
          <SimpleItem
            dataField="barCode"
            editorType="dxTextBox"
            editorOptions={{
              placeholder: "Digite ou cole o código de barras",
              mask: "00000 00000 00000 000000 00000 000000 0 00000000000000",
              maskRules: { "0": /[0-9]/ },
              maskChar: " ",
              valueChangeEvent: "keyup",
              onValueChanged: (e) => barCode(e)
            }}
            label={{ text: "Código de barras" }}
            isRequired
          />
          <SimpleItem
            dataField="amount"
            editorType="dxNumberBox"
            editorOptions={{
              format: "R$ #,##0.00",
              showSpinButtons: false,
              min: 0,
            }}
            label={{ text: "Valor a pagar" }}
            disabled
          />
          <SimpleItem
            dataField="addition"
            editorType="dxNumberBox"
            editorOptions={{
              format: "R$ #,##0.00",
              showSpinButtons: false,
              min: 0,
            }}
            label={{ text: "Acréscimo (juros/multa)" }}
            disabled
          />
          <SimpleItem
            dataField="expiration"
            editorType="dxDateBox"
            editorOptions={{
              defaultValue: "",
              type: "date",
              displayFormat: "dd/MM/yyyy"
            }}
            label={{ text: "Data vencimento" }}
            disabled
          />
          <SimpleItem
            dataField="supplier"
            editorType="dxTextBox"
            editorOptions={{
              placeholder: "Fornecedor",
            }}
            label={{ text: "Fornecedor" }}
            disabled
          />
          <SimpleItem
            dataField="bank"
            editorType="dxTextBox"
            editorOptions={{
              placeholder: "Banco",
            }}
            label={{ text: "Banco" }}
            disabled
          />
          <SimpleItem
            dataField="payDay"
            editorType="dxDateBox"
            editorOptions={{
              defaultValue: "",
              type: "date",
              displayFormat: "dd/MM/yyyy"
            }}
            label={{ text: "Data de pagamento" }}
            isRequired
          />
        </Form>
      }
    </>
  );

}
