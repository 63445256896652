import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  getBicos,
  incluirBico,
  alterarBico,
  excluirBico,
  montarComboTanque,
  montarComboBomba,
  montarComboListaPrecos
} from './Bicos.service';

import moment from 'moment';
import Botao from '../../../../components/botao/Botao';
import {
  InputText,
  Select,
  Checkbox,
  Checkitem,
  // FormOptions,
  handleInputChange,
  InputDate,
  InputTime
} from '../../../../components/formulario/Formulario';
import List from '../../../../components/formulario/List';
import {
  menuPermiteInclusao,
  menuPermiteExclusao,
  menuPermiteAlteracao,
  validaDadosLogin,
  toFloatFormattedDisplayDecimals,
  getNomePagina
} from '../../../../shared/utils/Utils';
import {
  tagAlert,
  // Alert,
  showWait,
  hideWait,
  hideAlert,
  showQuestion,
  showError,
  showInfo,
  showSaveError,
  showDeleteError
} from '../../../../components/alert/Alert';
import SectionHeader from '../../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from '../../../../components/section/Content';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { MAPA_PATH } from '../MapaDePista';
import Footer from '../../../../components/core/footer/Footer';

import { menus } from '../../../../shared/constants/MenuConstants';

const PATH_MENU = menus.COD_60530;
const NEW_PATH = '/cadastro/tanques-bombas-bicos/bico/new';
const titulo = 'Bicos';

class Form extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    const {
        codigoBico,
        codigoTanque,
        codigoBomba,
        numeroSequencial,
        numeroBicoNaBomba,
        codigoNivelPreco,
        permiteAbastecimentoManual = true,
        possuiMovimentacaoEstoque = false,
        numeroHexa,
        dataAtivacao = moment(),
        inativo = false,
        rowVersion = null
      } = this.props.Bico,
      dataAlteracao = moment().format('YYYY-MM-DD'),
      horaAlteracao = moment().format('HH:mm:ss'),
      motivoAlteracao = '';

    this.state = {
      codigoBico,
      codigoTanque,
      codigoBomba,
      numeroSequencial,
      numeroBicoNaBomba,
      codigoNivelPreco,
      permiteAbastecimentoManual,
      possuiMovimentacaoEstoque,
      numeroHexa,
      dataAtivacao,
      ativo: !inativo,

      dataAlteracao,
      horaAlteracao,
      motivoAlteracao,

      rowVersion,
      alertActive: false, // se o alert deve ser apresentado
      alertType: '', // tipo de alert (sucesso, erro...)
      alertTitle: '', // titulo do alert
      alertSubtitle: '', // subtitulo/mensagem do alert

      bicosExistentes: this.props.bicosExistentes,
      tanques: this.props.tanques,

      salvando: false
    };
  }

  validaDataEscolhida = () => {
    var dataValida = false;
    dataValida = moment(this.state.dataAtivacao, 'YYYY-MM-DD').isValid();
    return dataValida;
  };

  async componentDidMount() {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto o formulário é carregado.'
    );

    var tanques = this.state.tanques;

    this.setState({
      tanquesLista:
        tanques === null
          ? []
          : tanques.map(tanque => {
              return { label: tanque.descricao, value: tanque.codigo };
            }),
      codigoTanque:
        this.state.codigoTanque === undefined
          ? !!tanques && tanques.length > 0
            ? tanques[0].codigo
            : 0
          : this.state.codigoTanque
    });

    var ct =
      this.state.codigoTanque === undefined
        ? !!tanques && tanques.length > 0
          ? tanques[0].codigo
          : 0
        : this.state.codigoTanque;
    this.buscaListaPrecos(ct);

    const { data: bombas } = await montarComboBomba();
    this.setState({
      bombasLista:
        bombas === null
          ? []
          : bombas.map(bomba => {
              return { label: bomba.descricao, value: bomba.codigo };
            }),
      codigoBomba:
        this.state.codigoBomba === undefined
          ? !!bombas && bombas.length > 0
            ? bombas[0].codigo
            : 0
          : this.state.codigoBomba
    });
    hideAlert(this);
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    this.setState({ salvando: true });

    const {
        codigoBico,
        codigoTanque,
        codigoBomba,
        numeroSequencial,
        numeroBicoNaBomba,
        codigoNivelPreco,
        permiteAbastecimentoManual,
        // possuiMovimentacaoEstoque,
        numeroHexa,
        dataAtivacao,
        rowVersion,
        ativo
        // dataAlteracao,
        // horaAlteracao,
        // motivoAlteracao
      } = this.state,
      inativo = !ativo;

    const [action, params] =
      rowVersion !== null
        ? [
            alterarBico,
            [
              codigoBico,
              codigoTanque,
              codigoBomba,
              numeroSequencial,
              numeroBicoNaBomba,
              codigoNivelPreco,
              permiteAbastecimentoManual,
              numeroHexa,
              dataAtivacao,
              inativo,
              rowVersion
            ]
          ]
        : [
            incluirBico,
            [
              codigoTanque,
              codigoBomba,
              numeroSequencial,
              numeroBicoNaBomba,
              codigoNivelPreco,
              permiteAbastecimentoManual,
              numeroHexa,
              dataAtivacao
            ]
          ];

    let temErros = this.verificaBico();

    if (!temErros && action === incluirBico) {
      temErros = this.verificaBicoExistente(numeroSequencial);
    }

    if (!temErros) {
      temErros = this.verificaNumeroBicoExistente(
        numeroBicoNaBomba,
        codigoBomba,
        codigoBico
      );
    }

    if (!temErros) {
      try {
        showWait(
          this,
          titulo,
          'Por favor, aguarde enquanto o registro é salvo.'
        );
        await action(...params);
        hideWait(this);
        const msg = rowVersion
          ? 'Bico alterado com sucesso!'
          : 'Bico cadastrado com sucesso!';
        showInfo(this, titulo, msg, null, () => {
          this.props.history.push(MAPA_PATH);
        });
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
    this.setState({ salvando: false });
  };

  handleExcluir = () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    const { possuiMovimentacaoEstoque, codigoBico } = this.state;

    showQuestion(
      this,
      titulo,
      'Deseja realmente excluir?',
      null,
      async resp => {
        if (resp) {
          if (possuiMovimentacaoEstoque) {
            showError(
              this,
              titulo,
              'O bico não pode ser excluído, pois tem movimentação de estoque.'
            );
          } else {
            try {
              showWait(
                this,
                titulo,
                'Por favor, aguarde enquanto o registro é excluído.'
              );
              await excluirBico(codigoBico);
              hideWait(this);
              showInfo(this, titulo, 'Bico excluído com sucesso!', null, () => {
                this.props.history.push(MAPA_PATH);
              });
            } catch (err) {
              showDeleteError(this, titulo, err);
            }
          }
        } else {
          hideAlert(this);
        }
      }
    );
  };

  handleCancelar = () => {
    showQuestion(this, titulo, 'Deseja realmente cancelar?', null, resp => {
      if (resp) {
        hideAlert(this);
        this.props.history.push(MAPA_PATH);
      } else {
        hideAlert(this);
      }
    });
  };

  buscaListaPrecos = async codigoTanque => {
    const tempCodigoBico = this.state.codigoBico;
    const tempCodigoTanque = this.state.codigoTanque;
    const tempCodigoNivelPreco = this.state.codigoNivelPreco;

    const { data: precos } = await montarComboListaPrecos(codigoTanque);

    this.setState({
      listaPrecos:
        precos === null
          ? []
          : precos.map(preco => {
              return { label: preco.descricao, value: preco.codigo };
            }),
      codigoNivelPreco:
        !tempCodigoBico || codigoTanque !== tempCodigoTanque
          ? !!precos && precos.length > 0
            ? precos[0].codigo
            : 0
          : tempCodigoNivelPreco
    });
  };

  handleTanqueChange = e => {
    var codigoTanque = e.target.value;
    this.buscaListaPrecos(codigoTanque);
    this.setState({ codigoTanque });
  };

  verificaBico = () => {
    //verifica preenchimento dos campos

    var temErros = false;

    if (this.state.numeroSequencial < 1 || this.state.numeroSequencial > 99) {
      showError(
        this,
        titulo,
        'Número sequencial do bico deve ser entre 1 e 99.'
      );
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.numeroSequencial) {
      showError(
        this,
        titulo,
        'É necessário informar o número sequencial do bico.'
      );
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.state.numeroBicoNaBomba) {
      showError(
        this,
        titulo,
        'É necessário informar o número do bico na bomba.'
      );
      temErros = true;
    }

    if (temErros) return temErros;

    if (this.state.numeroBicoNaBomba < 1 || this.state.numeroBicoNaBomba > 4) {
      showError(this, titulo, 'Número do bico na bomba deve ser entre 1 e 4.');
      temErros = true;
    }

    if (temErros) return temErros;

    if (!this.validaDataEscolhida()) {
      showError(
        this,
        titulo,
        'Campo Data de Ativação deve ser uma data válida'
      );
      temErros = true;
    }

    if (temErros) return temErros;

    if (this.state.rowVersion) {
      if (!moment(this.state.dataAlteracao, 'YYYY-MM-DD').isValid()) {
        showError(this, titulo, 'Data de alteração inválida');
        return true;
      }
      if (
        moment(this.state.dataAlteracao, 'YYYY-MM-DD') <
        moment(this.state.dataAtivacao, 'YYYY-MM-DD')
      ) {
        showError(
          this,
          titulo,
          'A data de alteração deve ser igual ou superior a data de ativação informada'
        );
        return true;
      }

      if (this.state.horaAlteracao === '') {
        showError(this, titulo, 'É obrigatório informar a hora de alteração');
        return true;
      }
      if (!moment(this.state.horaAlteracao, 'HH:mm:ss').isValid()) {
        showError(this, titulo, 'Hora de alteração inválida');
        return true;
      }
      if (!this.state.motivoAlteracao) {
        showError(this, titulo, 'É obrigatório informar o motivo de alteração');
        return true;
      }
    }
  };

  validaTeclaDigitadaHexa = e => {
    const re = /[0-9a-fA-F]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
    if (e.key === 'ç') {
      e.preventDefault();
    }
  };

  handleHexaChange = e => {
    let value = e.target.value;

    //const re = /[0-9a-fA-F]+/g;
    const re = /^[0-9a-fA-F]+$/g;
    if (!re.test(value) || String(value).includes('ç')) {
      if (value && value.length === 0) {
        value = '';
      } else {
        let helper = '';
        for (let i = 0; i < value.length; i++) {
          helper += String(value).charAt(i);
        }
        value = helper;
      }
    }

    value = String(value).toUpperCase();

    this.setState({
      numeroHexa: value
    });
  };

  validaTextoColadoHexa = event => {
    var textoNovo = '';

    var textoOriginal = '';

    var tamMax = 0;
    var tamTexto = 0;

    tamMax = event.target.maxLength;

    var caracterEspecial = false;

    var campo = event.target.name;

    textoOriginal = event.clipboardData.getData('Text');

    for (let i = 0; i < textoOriginal.length; i++) {
      const re = /[0-9a-fA-F]+/g;
      const c = textoOriginal[i];
      tamTexto = textoNovo.length;
      caracterEspecial = false;
      if (!re.test(c)) {
        caracterEspecial = true;
      }

      if (c === 'ç') {
        caracterEspecial = true;
      }

      if (tamTexto < tamMax) if (!caracterEspecial) textoNovo += c;
    }

    if (caracterEspecial) event.preventDefault();

    this.setState({
      [campo]: textoNovo
    });
  };

  verificaNumeroBicoExistente = (
    numeroBicoNaBomba,
    codigoBomba,
    codigoBico
  ) => {
    let existeBico = false;
    let lista = this.state.bicosExistentes;
    let tempBico = undefined;
    if (lista) {
      !codigoBico
        ? (tempBico = lista.find(
            l =>
              parseInt(l.numeroBicoNaBomba) === parseInt(numeroBicoNaBomba) &&
              parseInt(l.codigoBomba) === parseInt(codigoBomba)
          ))
        : (tempBico = lista.find(
            l =>
              parseInt(l.numeroBicoNaBomba) === parseInt(numeroBicoNaBomba) &&
              parseInt(l.codigoBomba) === parseInt(codigoBomba) &&
              parseInt(l.codigoBico) !== parseInt(codigoBico)
          ));
      if (tempBico) {
        showError(
          this,
          titulo,
          'O número do bico na bomba já existe para a bomba selecionada.'
        );
        existeBico = true;
      }
    }
    return existeBico;
  };

  verificaBicoExistente = numeroSequencial => {
    let existeBico = false;
    let lista = this.state.bicosExistentes;
    if (lista) {
      let tempBico = lista.find(
        l => parseInt(l.numeroSequencial) === parseInt(numeroSequencial)
      );
      if (tempBico) {
        showError(
          this,
          titulo,
          'O número sequencial informado para o bico já existe.'
        );
        existeBico = true;
      }
    }
    return existeBico;
  };

  render() {
    const {
      //campos tela
      // codigoBico,
      codigoTanque,
      codigoBomba,
      numeroSequencial,
      numeroBicoNaBomba,
      codigoNivelPreco,
      permiteAbastecimentoManual,
      numeroHexa,
      dataAtivacao,
      ativo,
      rowVersion,

      horaAlteracao,
      dataAlteracao,
      motivoAlteracao,
      tanquesLista,
      bombasLista,
      listaPrecos
    } = this.state;
    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContainer>
            <SectionContent title="">
              <div className="row">
                <div className="col-5">
                  <InputText
                    label="Número sequencial do bico"
                    value={numeroSequencial}
                    name="numeroSequencial"
                    maxlength="2"
                    decimalScale={0}
                    type="number"
                    onChange={this.handleInputChange}
                    allowNegative={false}
                    disabled={rowVersion !== null}
                    tabindex={1}
                  />
                </div>
                <div className="col-5">
                  <Select
                    label="Tanque"
                    name="codigoTanque"
                    value={codigoTanque}
                    onChange={this.handleTanqueChange}
                    options={tanquesLista}
                    tabindex={2}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <Select
                    label="Bomba"
                    name="codigoBomba"
                    value={codigoBomba}
                    onChange={this.handleInputChange}
                    options={bombasLista}
                    tabindex={3}
                  />
                </div>
                <div className="col-5">
                  <InputText
                    type="number"
                    label="Número do bico  na bomba (1-4)"
                    value={numeroBicoNaBomba}
                    decimalScale={0}
                    name="numeroBicoNaBomba"
                    maxlength="1"
                    allowNegative={false}
                    onChange={this.handleInputChange}
                    tabindex={4}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <InputText
                    label="Número hexa (Endereço lógico na automação)"
                    value={numeroHexa}
                    name="numeroHexa"
                    maxlength="2"
                    onChange={this.handleHexaChange}
                    tabindex={5}
                    onKeyDown={this.validaTeclaDigitadaHexa}
                    onPaste={this.validaTextoColadoHexa}
                  />
                </div>
                <div className="col-5">
                  <Select
                    label="Nível de preço:"
                    name="codigoNivelPreco"
                    value={codigoNivelPreco}
                    onChange={this.handleInputChange}
                    options={listaPrecos}
                    tabindex={6}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <InputDate
                    label="Data de ativação"
                    value={dataAtivacao}
                    name="dataAtivacao"
                    onChange={this.handleInputChange}
                    tabindex={7}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <Checkbox label="Ativo:">
                    <Checkitem
                      label=""
                      name="ativo"
                      checked={ativo}
                      onChange={this.handleInputChange}
                      tabindex={8}
                      disabled={rowVersion === null}
                    />
                  </Checkbox>
                </div>
                <div className="col-5">
                  <Checkbox label="Permite abastecimento manual:">
                    <Checkitem
                      label=""
                      name="permiteAbastecimentoManual"
                      checked={permiteAbastecimentoManual}
                      onChange={this.handleInputChange}
                      tabindex={9}
                    />
                  </Checkbox>
                </div>
              </div>
            </SectionContent>
            <SectionContent
              title=""
              visible={rowVersion !== null ? true : false}
            >
              <div className="row">
                <div className="col-5">
                  <InputDate
                    label="Data da alteração"
                    name="dataAlteracao"
                    value={dataAlteracao ? moment(dataAlteracao) : moment()}
                    onChange={this.handleInputChange}
                  />
                </div>
                <div className="col-5">
                  <InputTime
                    className="input time"
                    required
                    label="Hora de alteração"
                    value={horaAlteracao}
                    name="horaAlteracao"
                    showSeconds
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-10">
                  <InputText
                    label="Motivo da alteração"
                    value={motivoAlteracao}
                    name="motivoAlteracao"
                    maxlength="255"
                    required
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </SectionContent>
          </SectionContainer>
        </LinxPostos>
        <Footer
          saveAction={              
            !rowVersion
            ? !this.state.salvando
              ? this.handleSalvar
              : null
            : menuPermiteAlteracao(PATH_MENU)
            ? !this.state.salvando
              ? this.handleSalvar
              : null
            : null
          }
          cancelAction={this.handleCancelar}
          deleteAction={
            rowVersion === null
              ? null
              : menuPermiteExclusao(PATH_MENU)
              ? this.handleExcluir
              : null
          }
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class Bicos extends Component {
  state = {
    Bicos: [],
    tanques: [],
    Bicosel: {}
  };

  doMount = async () => {
    showWait(
      this,
      titulo,
      'Por favor, aguarde enquanto os dados são carregados'
    );
    try {
      const { data: responseBicos } = await getBicos();
      var bicosDesord = responseBicos.result;

      const { data: tanques } = await montarComboTanque();

      const bicos = bicosDesord.sort(function(a, b) {
        if (a.numeroSequencial < b.numeroSequencial) return -1;
        if (a.numeroSequencial > b.numeroSequencial) return 1;
        return 0;
      });

      for (let i = 0; i < bicos.length; i++) {
        var tanque = tanques.filter(
          t => t.codigo === parseInt(bicos[i].codigoTanque)
        );
        if (tanque && tanque.length > 0) {
          bicos[i].descricaoTanque = tanque[0].descricao;
        }
        bicos[i].numeroSequencial = ('00' + bicos[i].numeroSequencial).slice(
          -2
        );
        bicos[i].descricaoBomba = ('00' + bicos[i].numeroBomba).slice(-2);
        bicos[i].numeroBicoNaBomba = ('00' + bicos[i].numeroBicoNaBomba).slice(
          -2
        );
        bicos[i].dataAtivacaoFormatada = moment(bicos[i].dataAtivacao).format(
          'DD/MM/YYYY'
        );
        bicos[i].valorNivelPreco =
          'R$ ' + toFloatFormattedDisplayDecimals(bicos[i].valorNivelPreco, 3);
      }
      this.setState({ bicos, tanques });
    } finally {
      hideWait(this);
    }
  };

  async componentDidMount() {
    await this.doMount();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      await this.doMount();
    }
  }

  buscaDescricaoBanco = codigoBanco => {
    var arBancos = this.state.bancosArray;
    for (let i = 0; i < arBancos.length; i++) {
      const banco = arBancos[i];
      if (parseInt(banco.codigo) === parseInt(codigoBanco)) {
        var descricao = banco.nome;
        break;
      }
    }

    return descricao;
  };

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.setState({ Bicosel: rowInfo.original });
      this.props.history.push(NEW_PATH, {
        Bicosel: rowInfo.original,
        tanques: this.state.tanques,
        bicosExistentes: this.state.bicos
      });
    }
  };

  renderHelper = (edit, showTitle) => {
    return (
      <>
        <SectionHeader
          title={showTitle ? 'Bicos' : ''}
          subtitle=""
          right={
            <div className="button-container">
              {edit ? (
                <></>
              ) : menuPermiteInclusao(PATH_MENU) ? (
                <Botao
                  ic
                  icon="icon-lx-plus"
                  onClick={() => {
                    this.setState({ Bicosel: {} });
                    this.props.history.push(NEW_PATH, {
                      Bicosel: {},
                      tanques: this.state.tanques,
                      bicosExistentes: this.state.bicos
                    });
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          }
        />
        <List
          defaultPageSize={5}
          onClick={this.handleTableClick}
          cols={[
            {
              accessor: 'numeroSequencial',
              Header: 'Nº Seq. Bico',
              width: 110,
              filterable: false
            },
            {
              accessor: 'descricaoTanque',
              Header: 'Tanque',
              width: 150,
              filterable: false
            },
            {
              accessor: 'descricaoBomba',
              Header: 'Bomba',
              width: 80,
              filterable: false
            },
            {
              accessor: 'numeroBicoNaBomba',
              Header: 'Nº bico na bomba (1-4)',
              width: 175,
              filterable: false
            },
            {
              accessor: 'numeroHexa',
              Header: 'Nº Hexa',
              width: 100,
              filterable: false
            },

            {
              accessor: 'valorNivelPreco',
              Header: 'Nível de Preço',
              width: 120,
              filterable: false
            },

            {
              accessor: 'dataAtivacaoFormatada',
              Header: 'Data ativação',
              width: 150,
              filterable: false
            },
            {
              accessor: 'inativo',
              Header: 'Ativo',
              width: 100,
              filterable: false,
              Cell: ({ row }) => {
                return (
                  <Botao
                    secondary={row.inativo}
                    ic
                    icon={row.inativo ? 'icon-lx-close' : 'icon-lx-check'}
                  />
                );
              }
            }
          ]}
          rows={this.state.bicos}
        />
      </>
    );
  };

  render() {
    const { edit } = this.props;
    let Bicosel = {};
    let tanques = [];
    let bicosExistentes = [];
    if (edit) {
      // this.props.location.state por padrao é undefined
      if (this.props.location.state) {
        Bicosel = this.props.location.state.Bicosel;
        tanques = this.props.location.state.tanques;
        bicosExistentes = this.props.location.state.bicosExistentes;
      }
    }

    return (
      <main className="main">
        <section className="section-container">
          {edit ? (
            <Form
              Bico={Bicosel}
              tanques={tanques}
              bicosExistentes={bicosExistentes}
            />
          ) : (
            <>
              {this.props.noLinxPostos ? (
                this.renderHelper(edit, true)
              ) : (
                <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
                  {this.renderHelper(edit, false)}
                </LinxPostos>
              )}
            </>
          )}
        </section>
      </main>
    );
  }
}

Bicos = withRouter(Bicos);
export { Bicos };
