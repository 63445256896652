import React from "react";
import { Col, Row, Container } from "react-bootstrap";

import googlePlay from "../../../../../../assets/images/contadigital/qr_code_android.png";
import disponivelPlay from "../../../../../../assets/images/contadigital/disponivel_play.png";

import appleStore from "../../../../../../assets/images/contadigital/qr_code_apple.png";
import disponivelApple from "../../../../../../assets/images/contadigital/disponivel_apple.png";

import { CustomButton } from "../../SharedComponents";

import { locale } from "devextreme/localization";
locale("br");

export const AplicativoAprovador = () => {
  const googlePlayLink = "https://play.google.com/store/apps/details?id=br.com.linx.aprovadorlinx&pcampaignid=web_share";
  const appStoreLink = "https://apps.apple.com/br/app/aprovadorlinx/id6449502568";

  const openLink = (link) => {
    window.open(link, "_blank");
  }

  return (
    <Col>
      <Container fluid>
        <div className="d-flex flex-column h-100 justify-content-start">
          <h2>Aplicativo aprovador</h2>
          <p>
          Para todas as operações realizadas neste menu, é necessário que sua <strong>aprovação</strong> seja feita através do aplicativo <strong>Aprovador Linx.</strong>
          </p>
          <p>
            O aplicativo está disponível para iOS e Android<br />
            Utilize os links ou escaneie os QRCodes abaixo para baixar o aplicativo.
          </p>
          <div className="d-flex justify-content-between col-6 mx-auto">
            <div className="d-flex flex-column align-items-center">
              <a href={googlePlayLink} target="_blank">
                <img src={disponivelPlay} alt="" title="" width="150" />
              </a>
              <img src={googlePlay} alt="" title="" width="200" />
              <CustomButton icon="download" className="rounded" onClick={() => openLink(googlePlayLink)}>Clique aqui</CustomButton>
            </div>
            <div className="d-flex flex-column align-items-center">
              <a href={appStoreLink} target="_blank">
                <img src={disponivelApple} alt="" title="" width="150" />
              </a>
              <img src={appleStore} alt="" title="" width="200" />
              <CustomButton icon="download" className="rounded" onClick={() => openLink(appStoreLink)}>Clique aqui</CustomButton>
            </div>
          </div>
        </div>
      </Container>
    </Col>
  );
}
