import axios from 'axios';
import { getUrl, getCnpj, updateInfoEmpresaStorage } from '../../../shared/utils/Utils';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';
import { getToken } from '../../../authentication/Auth';
//axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

const urlLinxCore = axios.create({
  baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export function getEstabelecimentos() {
  /* const cnpj = getCnpj();
  const cadastroCompleto = true; */
  return urlLinxCore.post(`Empresa/Listar`, {});
}

export function incluirEstabelecimento(
  razaoSocial,
  inativo,
  nomeFantasia,
  codigoANP,
  dataContituicao,
  cnpj,
  inscricaoEstadual,
  inscricaoMunicipal,
  email,
  enderecoInternet,
  endereco,
  representante,
  fiscais,
  setor,
) {
  axios.defaults.baseURL = getUrl();
  return axios.post(`LxApi/v1/Empresa/Incluir`, {
    razaoSocial,
    inativo,
    nomeFantasia,
    codigoANP,
    dataContituicao,
    cnpj,
    inscricaoEstadual,
    inscricaoMunicipal,
    email,
    enderecoInternet,
    endereco,
    representante,
    fiscais,
    setor,
  });
}

export function alterarEstabelecimento(
  Codigo,
  RazaoSocial,
  Inativo,
  NomeFantasia,
  CodigoANP,
  DataConstituicao,
  CNPJ,
  InscricaoEstadual,
  InscricaoMunicipal,
  Email,
  EnderecoInternet,
  Endereco,
  Representante,
  Fiscais,
  Setor,
  Cnae,
  rowVersion,
  inscritaROT,
  dataInscricaoROT,
  dataInativacaoROT,
  ROTNaoObrigaCPF,
  chave,
) {
  EnderecoInternet = EnderecoInternet === null ? '' : EnderecoInternet;
  const registroJunta = '';
  const dataRegistroJunta = '';
  let result = urlLinxCore.put(`Empresa/Editar`, {
    Codigo,
    RazaoSocial,
    Inativo,
    NomeFantasia,
    CodigoANP,
    DataConstituicao,
    CNPJ,
    InscricaoEstadual,
    InscricaoMunicipal,
    Email,
    EnderecoInternet,
    Endereco,
    Representante,
    Fiscais,
    Setor,
    Cnae,
    rowVersion,
    registroJunta,
    dataRegistroJunta,
    inscritaROT,
    dataInscricaoROT,
    dataInativacaoROT,
    ROTNaoObrigaCPF,
    chave,
  });

  updateInfoEmpresaStorage(RazaoSocial, NomeFantasia, CNPJ);

  return result;
}

export function montarComboContador() {
  return urlLinxCore.get(`Contador/Listar`, {});
}

export function montarComboUfs() {
  return urlLinxCore.get(`UnidadeFederativa/Listar`, {});
}

export function montarComboMunicipios(codigoUF) {
  return urlLinxCore.get(`Municipio/ListarPorUF`, {
    params: { idUF: codigoUF },
  });
}

export function listarCNAEs() {
  return urlLinxCore.get(`Empresa/ListarCNAEs`, {});
}

export function getDadosEmpresa() {
  return urlLinxCore.get(`Empresa/Recuperar`, {});
}

export function montaComboRegimeIncidencia() {
  return urlLinxCore.get('Empresa/ListarRegimesIncidencia');
}

export function montaComboCodigoTipoContribuicao() {
  return urlLinxCore.get('Empresa/ListarTipoContribuicao');
}

export function montaComboIndicadorPisCofins() {
  return urlLinxCore.get('Empresa/ListarIndicadoresPisCofins');
}

export function montaCombometodoApropriacaoCreditos() {
  return urlLinxCore.get('Empresa/ListarMetodoApropCreditos');
}
