import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import styles from './DadosAdicionaisResult.module.scss';
import LinxPostos from '../../../../components/core/linxPostos/LinxPostos';
import { menus } from '../../../../shared/constants/MenuConstants';
import { getNomePagina } from '../../../../shared/utils/Utils';
import ptMessages from '../../../../../assets/js/pt.json';
import { locale, loadMessages } from 'devextreme/localization';

import {
  DataGrid,
  Pager,
  SearchPanel,
  Grouping,
  GroupPanel,
  ColumnChooser,
  Export,
  Paging,
  ColumnFixing,
  Column
} from 'devextreme-react/data-grid';
import config from 'devextreme/core/config';
import LinxInfoPill from '../../../../components/linx/InfoPill/InfoPill';
import dadosAdicionaisDocumentoFiscal from '../service/DadosAdicionaisDocumentoFiscal.service';
import notify from 'devextreme/ui/notify';

export function DadosAdicionaisResult(props) {
  const PATH_MENU = menus.COD_60430;
  const [receivedData, setReceivedData] = useState([]);
  const [searching, setSearching] = useState(false);

  useEffect(() => {
    setReceivedData(props.location.state.data);
    loadMessages(ptMessages);
    locale(navigator.language);
  }, [props]);

  useEffect(() => {
    refreshResult();
  }, []);

  config({
    defaultCurrency: 'BRL'
  });

  const appliedFilters = props.location.state.appliedfilters || {};

  const editDadoAdicional = async e => {
    let pos = e.row ? e.row.data : {};

    if (pos.id) {
      await recuperaDados(pos.id);
    }
  };

  const recuperaDados = async id => {
    const response = await dadosAdicionaisDocumentoFiscal.recuperar(id);
    if (response.notificationLevel === 1) {
      props.history.push({
        pathname: '/fiscal/dados-adicionais-nf/cadastro',
        state: {
          data: response.data
        }
      });
    }
  };

  const refreshResult = async () => {
    setSearching(true);
    const response = await dadosAdicionaisDocumentoFiscal.filtraDadosAdicionais(
      appliedFilters
    );

    if (response.notificationLevel === 1) {
      const resultadoTratado = [];
      response.data.forEach(element => {
        resultadoTratado.push({
          id: element.id,
          descricaoResumida: element.descricaoResumida,
          entrada: element.entrada ? 'SIM' : 'NÃO',
          saida: element.saida ? 'SIM' : 'NÃO',
          fisco: element.fisco ? 'SIM' : 'NÃO',
          contribuinte: element.contribuinte ? 'SIM' : 'NÃO'
        });
      });
      setReceivedData(resultadoTratado);
    } else {
      notify(response.message, 'error', 2000, 'top');
    }

    setSearching(false);
  };

  return (
    <LinxPostos breadcrumb={getNomePagina(PATH_MENU, 'Resultados')}>
      <div className={`${styles.dadoAdicionalResult} mt-3`}>
        <div className="row">
          <div className={`${styles.exportExcel} col-md-10`}>
            <Link
              to="/fiscal/dados-adicionais-nf"
              type="button"
              className="btn bg-white shadow-sm"
            >
              <i className="icon icon-lx-arrow-left"></i>Voltar para a busca
            </Link>
          </div>
          <div className="col-md-2">
            <Link
              to="/fiscal/dados-adicionais-nf/cadastro"
              type="button"
              className={`${styles.newDadoAdicional} btn bg-white shadow-sm`}
            >
              Novo Cadastro
            </Link>
          </div>
        </div>
        <div
          className={`${styles.results} shadow-sm p-3 bg-white text-align: center;`}
        >
          <div className="container-fluid">
            <div className={`${styles.appliedFilters}`}>
              {appliedFilters.tiposOperacao.length > 0 ? (
                <LinxInfoPill
                  info={`Tipos de Operações selecionados: ${appliedFilters.tiposOperacao.length}`}
                />
              ) : (
                <></>
              )}{' '}
              {appliedFilters.tiposDadoAdicional.length > 0 ? (
                <LinxInfoPill
                  info={`Tipos de dados adicionais selecionados: ${appliedFilters.tiposDadoAdicional.length}`}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                <hr />
                <div className="col-md-12">
                  <DataGrid
                    dataSource={receivedData}
                    showBorders={true}
                    allowColumnResizing={true}
                    allowColumnReordering={true}
                    columnResizingMode={'widget'}
                    columnMinWidth={80}
                    disabled={searching}
                    rowAlternationEnabled={true}
                  >
                    <Column
                      dataField="descricaoResumida"
                      caption="Descrição resumida"
                      minWidth="200"
                    />
                    <Column
                      dataField="contribuinte"
                      caption="Contribuinte"
                      minWidth="100"
                    />
                    <Column dataField="fisco" caption="Fisco" minWidth="100" />
                    <Column
                      dataField="entrada"
                      caption="Entrada"
                      minWidth="100"
                    />
                    <Column dataField="saida" caption="Saída" minWidth="100" />
                    <Column
                      type="buttons"
                      caption="Ações"
                      buttons={[
                        {
                          hint: 'Alterar informações',
                          icon: 'edit',
                          visible: true,
                          onClick: editDadoAdicional
                        }
                      ]}
                    />

                    <GroupPanel visible={true} />
                    <ColumnChooser enabled={true} mode={'select'} allowSearch={true}/>
                    <Export
                      enabled={true}
                      fileName="dadosAdicionaisDocumentoFiscal"
                    />
                    <SearchPanel visible={true} highlightCaseSensitive={true} />
                    <Grouping autoExpandAll={true} />
                    <ColumnFixing enabled={true} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[5, 15, 20, 50]}
                      showInfo={true}
                    />
                    <Paging defaultPageSize={15} />
                  </DataGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LinxPostos>
  );
}

export default withRouter(DadosAdicionaisResult);
