import React from 'react';
import styles from './TwitterButton.module.scss';

export default function TwitterButton() {
  return (
    <div className={styles.TwitterButton}>
      <a
        href="https://twitter.com/LinxRetail"
        target="_blank"
        rel="noopener noreferrer"
        ><i className={`icon icon-lx-twitter`}></i></a>
    </div>
  );
}
