import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';

export function Configuracoes() {

  return (
    <Col>
      <Container fluid>
        <Row as="header">
          <h2>Configurações</h2>
        </Row>
      </Container>
    </Col>
  );
}
