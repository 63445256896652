import React, { useState, useEffect, useRef } from "react";
import Form, { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";

import { CustomButton } from "../../../SharedComponents";

import { getDadosLogin } from "../../../../../../shared/utils/Utils";
import { ContaDigitalIntegradaService } from "../../../ContaDigitalIntegrada.service";
import { TransferenciasService } from "../Transferencias.service";

export const EntreContas = ({
  onSuccess
}) => {
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    origin: "",
    destiny: "",
    avaliableValue: 0,
    trasnferValue: 0,
  });

  const formRef = useRef(null);

  const formatCurrency = (value) => {
    const currencyString = value;
    const numberString = currencyString.replace("R$", "").trim().replace(".", "").replace(",", ".");
    const number = parseFloat(numberString);

    return number;
  }

  const handleSubmit = async () => {
    const formInstance = formRef.current.instance;
    const validationResult = formInstance.validate();

    if (validationResult.isValid) {
      setFormData(formInstance.option("formData"));
      setDisabled(true);

      notify("Realizando a transferência. Aguarde...", "info", 2000);

      const updatedFormData = {
        ...formData,
        //avaliableValue: formatCurrency(formData.avaliableValue),
        avaliableValue: formData.avaliableValue,
      };

      const transfer = await TransferenciasService.postInternalTransfer(updatedFormData);

      if (transfer) {

        if (transfer.notifications && Array.isArray(transfer.notifications) && transfer.notifications.length > 0) {
          notify(transfer.notifications[0].message, "error", 5000);
        }else{

          if(transfer.result){
              const { contaOrigem, status, saldo } = transfer.result;

              notify("Para concluir a operação, aprove a solicitação no app Aprovador Linx.", "success", 2000);

              setFormData({
                origin: contaOrigem,
                destiny: "",
                avaliableValue: saldo,
                trasnferValue: 0,
              });

              if (onSuccess) {

              var campoSaldo = document.getElementById('saldoContaDigital');

              if (campoSaldo) {
                const response = await ContaDigitalIntegradaService.getBalance();

                campoSaldo.textContent = response.result;
            }

                onSuccess();
              }
          }else{
            notify("Erro ao realizar a transferencia interna.", "error", 5000);
          }
        }

      } else {
        notify(transfer.message, "error", 5000);
      }
      setDisabled(false);
    }
  };

  const getSaldo = async () => {
    const response = await ContaDigitalIntegradaService.getBalance();

    if (response && response.result) {
      setFormData(prevFormData => ({
        ...prevFormData,
        avaliableValue: parseFloat(response.result.replace(/\./g, "").replace(",", "."))
      }));
    } else {
      notify(response.message, "error", 5000);
    }
  }

  const getDetalheConta = async () => {
    const dadosLogin = getDadosLogin();
    const response = await ContaDigitalIntegradaService.getAccount(dadosLogin.codigoEstabelecimento);

    if (response && response.result) {
      const { conta } = response.result;

      setFormData(prevFormData => ({
        ...prevFormData,
        origin: conta
      }));
    } else {
      notify(response.message, "error", 5000);
    }
  }

  useEffect(() => {
    getSaldo();
    getDetalheConta();
  }, []) ;

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <Form ref={formRef} formData={formData}>
        <SimpleItem dataField="origin" editorType="dxTextBox" label={{ text: "Conta origem" }} disabled readOnly />
        <SimpleItem dataField="destiny" editorType="dxTextBox" label={{ text: "CPF/CNPJ Conta Destino" }} isRequired />
        <SimpleItem
          dataField="avaliableValue"
          editorType="dxNumberBox"
          editorOptions={{
            format: "R$ #,##0.00",
            showSpinButtons: false,
            min: 0
          }}
          label={{ text: "Valor disponível na Conta" }}
          disabled
          readOnly
        />
        <SimpleItem
          dataField="trasnferValue"
          editorType="dxNumberBox"
          editorOptions={{
            format: "R$ #,##0.00",
            showSpinButtons: false,
            min: 0
          }}
          label={{ text: "Valor a transferir" }}
          isRequired
        />
      </Form>
      <div className="d-flex justify-content-between ml-auto">
        <CustomButton
          icon="chevronnext"
          iconPosition="right"
          duplicateIcon={true}
          onClick={handleSubmit}
          disabled={disabled}
        >
          Realizar transferência
        </CustomButton>
      </div>
    </div>
  );
};
