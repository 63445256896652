import Popup from 'devextreme-react/popup';
import { TextBox } from 'devextreme-react';
import { alert } from 'devextreme/ui/dialog';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Row, Table, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { InputCurrency } from '../../../../../components/inputCurrency/inputCurrency';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { TurnoPedidoService } from '../../../../../services/turno-pedido/TurnoPedido.service';
import { formatStringToCurrency } from '../../../../../shared/utils/Utils';
import loading from './../../../../../../assets/images/loadings/loading.gif';

export function AdicionarSuprimento(data) {
  const [popupVisible, setPopupVisible] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const { turnoAtual } = useSelector(selectConsignadoComodatoSharedData);
  const [valorSuprido, setValorSuprido] = useState('');
  const [observacao, setObservacao] = useState('');
  const [itens, setItens] = useState([]);
  const obsRef = useRef(null);

  const fetchData = useCallback(async () => {
    try {
      const itens = await TurnoPedidoService.getInstance().recuperarSuprimento(turnoAtual.turnoId);
      setItens(itens);
    } catch (error) {
      alert(error.message, 'Erro');
    }
  }, [turnoAtual.turnoId]);

  useEffect(
    () => {
      // fetchData();
    },
    [
      /*turnoAtual, fetchData*/
    ],
  );

  const onChanged = useCallback(async e => {
    setObservacao(e.target.value);
  }, []);
  const handleSubmit = useCallback(
    async e => {
      if (typeof e.preventDefault === 'function') e.preventDefault();
      setIsBusy(true);
      try {
        if (Number(valorSuprido) <= 0) throw new Error('O valor da suprimento deve ser maior que zero!');
        await TurnoPedidoService.getInstance().adicionarSuprimento({
          turnoId: turnoAtual.turnoId,
          valorSuprido: Number(valorSuprido),
          observacao,
        });

        setValorSuprido('');
        setObservacao('');
        setIsBusy(false);
        alert('Suprimento adicionado com sucesso!', 'Sucesso!').then(() => {
          fetchData();
          document.getElementById('valorSuprimento').focus();
        });
      } catch (error) {
        setIsBusy(false);
        alert(error.message, 'Erro').then(() => {
          document.getElementById('valorSuprimento').focus();
        });
      }
    },
    [fetchData, observacao, turnoAtual.turnoId, valorSuprido],
  );

  const onKeySpace = useCallback(
    async e => {
      /** Por alguma razão o backspace não funciona em inputs dentro do popup do devextreme */
      if (e.key === 'Enter' && obsRef.current) {
        handleSubmit(e);
        return;
      }
      if (e.key === ' ' && obsRef.current) {
        e.preventDefault(); // Isso evita a funcionalidade de espaço padrão

        // Captura a posição atual do cursor (ou seleção)
        const cursorPosition = obsRef.current.selectionStart;

        // Atualiza o valor com o espaço na posição correta
        const newValue = observacao.slice(0, cursorPosition) + ' ' + observacao.slice(cursorPosition);

        // Atualiza o estado e o valor do ref
        setObservacao(newValue);

        // Garante que a atualização do estado aconteça antes de mover o cursor
        setTimeout(() => {
          obsRef.current.selectionStart = obsRef.current.selectionEnd = cursorPosition + 1;
        }, 0);
      }
    },
    [observacao, handleSubmit],
  );
  const show = useCallback(
    e => {
      if (popupVisible) return;
      setPopupVisible(true);
    },
    [popupVisible],
  );
  const hide = useCallback(
    e => {
      if (!popupVisible) return;
      setPopupVisible(false);
    },
    [popupVisible],
  );
  return (
    <>
      <Button className="w-100" style={{ border: 'none' }} variant="outline-dark" onClick={show}>
        Suprimento
      </Button>
      <Popup
        width="70vw"
        height="80vh"
        onHiding={hide}
        onShown={async () => {
          await fetchData();
          document.getElementById('valorSuprimento').focus();
        }}
        visible={popupVisible}
        title="Suprimento"
        showTitle={true}
        hideOnOutsideClick={false}
      >
        <Card style={{ display: 'flex', flexDirection: 'column', height: 'calc(80vh - 90px)' }}>
          <Card.Header className="" style={{ height: '80px' }}>
            <h1>Adicione um ou mais suprimentos informando os dados abaixo.</h1>
          </Card.Header>
          <Card.Body className="mt-0 pt-0" style={{ flex: 1, overflowY: 'auto' }}>
            <form onSubmit={handleSubmit} className="container mt-5 d-flex align-items-end">
              <div className="form-group m-0 p-0 mr-3">
                <label htmlFor="valorSuprido">Valor Suprimento</label>
                <InputCurrency
                  onChange={(e, money) => setValorSuprido(parseFloat(money || '0'))}
                  value={valorSuprido || 0}
                  className="form-control text-right"
                  name="Valor Suprimento"
                  id="valorSuprimento"
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                  placeholder="R$ 0,00"
                />
              </div>
              <div className="input-group m-0 p-0 ml-auto">
                <input
                  type="text"
                  className="form-control"
                  ref={obsRef}
                  id="observacao"
                  placeholder="Observações"
                  value={observacao}
                  onKeyDown={onKeySpace}
                  onChange={onChanged}
                ></input>
                <div className="input-group-append">
                  <button type="button" onClick={handleSubmit} className="btn btn-primary" style={{ height: '40px', minWidth: '180px' }} disabled={isBusy}>
                    {isBusy ? (
                      <>
                        <img src={loading} alt="plus" style={{ width: '20px', height: '20px' }} />
                        &nbsp;&nbsp;
                      </>
                    ) : (
                      <>
                        <i className="fa fa-plus"></i>&nbsp;&nbsp;
                      </>
                    )}
                    {isBusy ? 'Adicionando...' : 'Adicionar'}
                  </button>
                </div>
              </div>
            </form>
            <Table className="mt-3" striped bordered hover>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Observação</th>
                  <th>Meio Pagamento</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {itens.map((item, index) => (
                  <tr key={index}>
                    <td className="align-middle">{new Date(item.dataSuprimento).toLocaleString()}</td>
                    <td className="align-middle">{item.observacao}</td>
                    <td className="align-middle">{item.descricaoFormaPagamento.toUpperCase()}</td>
                    <td className="align-middle text-right">{formatStringToCurrency(item.valorSuprido)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="text-right">
                    <h1>
                      <strong>Total:</strong>
                    </h1>
                  </td>
                  <td className="align-middle text-right">
                    <h2>{formatStringToCurrency(itens.reduce((acc, item) => acc + Number(item.valorSuprido), 0))}</h2>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Card.Body>
          <Card.Footer className="border" style={{ height: '70px' }}>
            <Row>
              <Col md={12}>
                <div className="float-right">
                  <button className="btn btn-secondary ml-3" onClick={hide}>
                    Fechar
                  </button>
                </div>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Popup>
    </>
  );
}
