import React, { useCallback, useEffect } from 'react';

import { PaginaContratoComodato } from './paginaContratoComodato';
import styles from './contratoComodato.module.scss';
import { useDispatch } from 'react-redux';
import { useNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';

export function ContratoComodato() {
  const dispatch = useDispatch();
  const { showContrato } = useNovaOs();
  const onPrint = useCallback(() => {
    const w = 900;
    const h = 680;
    let myWindow = window.open('', 'PRINT', `height=${h},width=${w},top=100,left=${window.screen.width / 2 - w / 2}`);
    myWindow.document.write(`<html><head><title>Impressão de Pedido</title>`);
    myWindow.document.write(`<meta charset="utf-8">`);
    myWindow.document.write(`<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>`);
    myWindow.document.write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">`);
    // myWindow.document.write(`<script src="https://code.jquery.com/jquery-3.2.1.slim.min.js" integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN" crossorigin="anonymous"></script>`);
    // myWindow.document.write(`<script src="https://cdn.jsdelivr.net/npm/popper.js@1.12.9/dist/umd/popper.min.js" integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q" crossorigin="anonymous"></script>`);
    // myWindow.document.write(`<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/js/bootstrap.min.js" integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl" crossorigin="anonymous"></script>`);
    myWindow.document.write('</head><body>');
    myWindow.document.write(document.getElementById('impressaoContrato').innerHTML);
    myWindow.document.write('</body>');
    myWindow.document.write('<script>window.onload=() => {window.print(); this.close()}</script>');
    myWindow.document.write('</html>');
    myWindow.document.close(); // necessary for IE >= 10
    myWindow.focus(); // necessary for IE >= 10*/
    // myWindow.window.print();
    //myWindow.close();
    return true;
  }, []);
  const onVoltar = useCallback(() => {
    document.getElementById('root').classList.remove(styles.noScroll);
    document.body.classList.remove(styles.overflowVisible);
    dispatch(showContrato(false));
  }, [dispatch, showContrato]);

  useEffect(() => {
    document.getElementById('root').classList.add(styles.noScroll);
    document.body.classList.add(styles.overflowVisible);
  });

  return (
    <>
      <div className={`${styles['contrato-header']} d-print-none`}>
        <h1>CONTRATO COMODATO</h1>
        <button className="btn btn-secondary" onClick={onVoltar}>
          <i className="icon icon-lx-previews"></i>&nbsp; VOLTAR
        </button>
        <button className="btn btn-primary" onClick={onPrint}>
          <i className="icon icon-lx-print"></i>&nbsp; IMPRIMIR
        </button>
      </div>
      <PaginaContratoComodato styles={styles} />
      {/* <PaginaContratoComodato styles={styles} /> */}
    </>
  );
}
