import React from "react";

import { CustomButton } from "../CustomButton/CustomButton";

export const Cancelar = ({
  message = "Deseja realmente cancelar o item?",
  onConfirm,
  onCancel
}) => {

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
    }
  }

  return (
      <div className="d-flex flex-column justify-content-between h-100">
        <p>{message}</p>
        <div className="d-flex justify-content-between">
          <CustomButton variant="secondary" icon="clear" onClick={handleCancel}>Não</CustomButton>
          <CustomButton icon="chevronnext" iconPosition="right" duplicateIcon={true} onClick={handleConfirm}>Sim</CustomButton>
        </div>
      </div>
    )
}
