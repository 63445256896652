import axios from 'axios';
import { TOKEN_PASS_AXIOS } from '../../../shared/constants/Const';
import { getUrl, getCnpj, filledArray } from '../../../shared/utils/Utils';
import { Classificacao, Categoria } from '../../cadastro/pessoas/Pessoas';
axios.defaults.baseURL = getUrl();
axios.defaults.headers.common['TokenPass'] = TOKEN_PASS_AXIOS;

export function getProdutos(consulta) {
  consulta.cnpjEstabelecimento = getCnpj();
  consulta.cadastroCompleto = true;

  return axios.post(`LxApi/v1/Produto/Listar/`, {
    query: consulta,
  });
}

export function getProdutosFiltrados(
  codigo,
  codigoRapido,
  descricao,
  favoritoDe,
  favoritoAte,
  situacao,
  setor,
  almoxarifado,
  tipo,
  subTipo,
  estruturaMercadologica,
  fabricante,
  fornecedor,
  dataAlteracaoDe,
  dataAlteracaoAte,
  dataCadastroDe,
  DataCadastroAte,
  ncm,
) {
  return axios.post(`LxApi/v1/Produto/Listar`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      codigo,
      codigoRapido,
      descricao,
      favoritoDe,
      favoritoAte,
      situacao,
      setor,
      almoxarifado,
      tipo,
      subTipo,
      estruturaMercadologica,
      fabricante,
      fornecedor,
      dataAlteracaoDe,
      dataAlteracaoAte,
      dataCadastroDe,
      DataCadastroAte,
      ncm,
      cadastroCompleto: true,
    },
  });
}

export function incluirProduto(produto) {
  return axios.post(`LxApi/v1/Produto/Incluir`, {
    ...produto,
    cnpjEstabelecimento: getCnpj(),
  });
}

export function alterarProduto(produto) {
  return axios.put(`LxApi/v1/Produto/Alterar`, {
    ...produto,
    cnpjEstabelecimento: getCnpj(),
  });
}

export function excluirProduto(id) {
  return axios.delete(`LxApi/v1/Produto/Excluir`, {
    params: { cnpjEstabelecimento: getCnpj(), id },
  });
}

export function getTipos() {
  return axios.get(`LxApi/v1/Produto/MontarComboTipo`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getSubTipos() {
  return axios.get(`LxApi/v1/Produto/MontarComboSubTipo`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getANPs() {
  return axios.get(`LxApi/v1/Produto/MontarComboANP`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getUnidadeMedida() {
  return axios.get(`LxApi/v1/UnidadeMedida/MontarCombo`, {});
}

export function getSituacoes() {
  return axios.get(`LxApi/v1/Produto/MontarComboSituacao`, {});
}

export function getFormasReajustePreco() {
  return axios.get(`LxApi/v1/Produto/MontarComboFormaReajustePreco`, {});
}

export function getPlanoContasContabeis(tipoMovimentacao) {
  return axios.get(`LxApi/v1/Contas/MontarComboPlanoContaSPED`, {
    params: { tipoMovimentacao },
  });
}

export function getAlmoxarifados() {
  return axios.get(`LxApi/v2/Almoxarifado/MontarCombo`, {
    params: { cnpjEstabelecimento: getCnpj(), tipos: '' },
  });
}

export function getTributacao() {
  return axios.get(`LxApi/v1/Produto/MontarComboTributacao`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getTipoDesconto() {
  return axios.get(`LxApi/v1/Produto/MontarComboTipoDesconto`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getEstruturaMercadologica(search) {
  return axios.post(`LxApi/v1/EstruturaMercadologica/MontarCombo`, {
    query: { cnpjEstabelecimento: getCnpj(), searchUltimoNivel: true, search },
  });
}

export function getNCM(search) {
  return axios.post(`LxApi/v2/Ncm/Listar`, {
    query: { searchUltimoNivel: true, search },
  });
}

export function getCEST(search) {
  return axios.post(`LxApi/v1/Cest/Listar`, {
    query: { searchUltimoNivel: true, search },
  });
}

export function getANP(codigo, search) {
  return axios.post(`LxApi/v1/Anp/Listar`, {
    query: { codigo, search },
  });
}

export function verifyProduto(produto) {
  return axios.get(`LxApi/v1/Produto/Verificar/`, {
    params: { cnpjEstabelecimento: getCnpj(), codigoProduto: produto },
  });
}

export function getSelecionarProduto(id) {
  return axios.get(`/LxApi/v1/Produto/Selecionar`, {
    params: { cnpjEstabelecimento: getCnpj(), id },
  });
}

export function getProdutoPrecosMargens(codigoProduto) {
  var produto = parseInt(codigoProduto);
  return axios.get(`LxApi/v1/Produto/${produto}/PrecosMargens/`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getProdutoComposicao(idProduto) {
  return axios.post(`LxApi/v1/Produto/${idProduto}/Composicao?cnpjEstabelecimento=${getCnpj()}`, {});
}

export function getProdutoNiveisPreco(codigoProduto) {
  return axios.get(`LxApi/v1/Produto/${codigoProduto}/NiveisPreco/`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getProdutoInformacoesFiscais(codigoProduto) {
  return axios.get(`LxApi/v1/Produto/${codigoProduto}/InformacoesFiscais/`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getProdutoEstoque(codigoProduto) {
  return axios.get(`LxApi/v1/Produto/${codigoProduto}/Estoque/`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getProdutoFornecedores(codigoProduto) {
  return axios.get(`LxApi/v1/Produto/${codigoProduto}/Fornecedores/`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getTiposProdutos() {
  return axios.get(`LxApi/v1/Produto/MontarComboTipoProduto/`, {});
}

export function getSubTiposProdutos(codigoTipoProduto) {
  return axios.get(`LxApi/v1/Produto/MontarComboSubtipoProduto/`, {
    params: { codigoTipoProduto },
  });
}

export function getGruposImpostos() {
  return axios.get(`LxApi/v1/GrupoImposto/MontarComboGrupoImpostos/`, {
    params: { cnpjEstabelecimento: getCnpj() },
  });
}

export function getContasSPED(tipoMovimentacao) {
  return axios.get(`LxApi/v1/Contas/MontarComboPlanoContaSPED/`, {
    params: { tipoMovimentacao },
  });
}

export function getFabricantesProdutos(search) {
  // TODO: USAR MONTA COMBO
  return axios.post(`LxApi/v1/Pessoa/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      classificacao: [Classificacao.Fabricante],
      categoria: [Categoria.FabricanteProduto],
      search,
    },
  });
}

export function getFornecedoresProdutos(search) {
  return axios.post(`LxApi/v1/Pessoa/Listar/`, {
    query: {
      cnpjEstabelecimento: getCnpj(),
      classificacao: [Classificacao.Fornecedor],
      categoria: [Categoria.DistribuidorProduto, Categoria.DistribuidorCombustivel],
      cadastroCompleto: false,
      search,
    },
  });
}

export function getProdutoConsultaEstoque(cnpjEstabelecimento, search) {
  return axios.get(`LxApi/v1/Produto/MontarComboProdutos`, {
    params: {
      cnpjEstabelecimento: getCnpj(),
      search,
    },
  });
}

export async function validaImportacaoProdutos(file) {
  const formData = new FormData();
  formData.append('file', new Blob([file], { type: 'text/csv' }));
  return await axios.post('LxApi/Nodis/ValidarImportacao', formData, {
    params: {
      cnpjEstabelecimento: getCnpj(),
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function salvaImportacaoProdutos(importData) {
  if (filledArray(importData))
    importData.forEach(item => {
      item.cnpjEstabelecimento = getCnpj();
    });
  return axios.post('LxApi/v1/Produto/Importar', importData);
}
