import memoryCache from 'memory-cache';
import { UrlLinxCoreService } from '../core/urlLinxCore.service';
import { getDadosLogin } from '../../shared/utils/Utils';
export class GetTokenHorusBiService {
  static instance = null;
  static getInstance() {
    if (!GetTokenHorusBiService.instance) {
      GetTokenHorusBiService.instance = new GetTokenHorusBiService();
    }
    return GetTokenHorusBiService.instance;
  }
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  execute = async () => {
    try {
      const dadosLogin = getDadosLogin();
      const urlArg = `/DashboardHorusBi/Token`;
      const cacheKey = `GetTokenHorusBiService_${dadosLogin.cnpj}`; // Chave do cache para este serviço
      const cachedData = memoryCache.get(cacheKey);

      if (cachedData) {
        return cachedData;
      }
      const response = await this.urlLinxCore.get(urlArg);
      const result = response.data.item;
      memoryCache.put(cacheKey, result, 60 * 60 * 1000); /*1 hora de cache*/
      return result;
    } catch (error) {
      return error.response.data;
    }
  };
}
