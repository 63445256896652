import React, { Component } from "react";
import Accordion from "./Accordion";

export class SectionContainer extends Component {
  render() {
    const { visible = true } = this.props;
    return (
      <>
        {visible ? (
          <div className="section-content">
            <div className="secion-body">{this.props.children}</div>
          </div>
        ) : (
          <div className="content-hidden">
            <div className="secion-body">{this.props.children}</div>
          </div>
        )}
      </>
    );
  }
}

export class SectionContent extends Component {
  render() {
    const {
      title,
      children,
      visible = true,
      accordion = false,
      accordionDefaultDisplay = true,
      accordionDisplay,
      onChange
    } = this.props;

    return (
      <>
        {visible ? (
          <div className="content">
            {accordion ? (
              <Accordion
                accordionTitle={title}
                defaultDisplay={accordionDefaultDisplay}
                display={accordionDisplay}
                onChange={onChange}
              >
                {children}
              </Accordion>
            ) : (
              <>
                {title && <h3 className="heading">{title}</h3>}
                {children}
              </>
            )}
          </div>
        ) : (
          <div className="content-hidden">
            {title && <h3 className="heading">{title}</h3>}
            {children}
          </div>
        )}
      </>
    );
  }
}

export class SectionContentSpecial extends Component {
  render() {
    const {
      title,
      children,
      visible = true,
      accordion = false,
      accordionDefaultDisplay = true,
      accordionDisplay,
      onChange,
      right
    } = this.props;

    return (
      <>
        {visible ? (
          <div className="secion-body">
            {accordion ? (
              <Accordion
                accordionTitle={title}
                defaultDisplay={accordionDefaultDisplay}
                display={accordionDisplay}
                onChange={onChange}
                right={right}
              >
                {children}
              </Accordion>
            ) : (
              <>
                {title && <h3 className="heading">{title}</h3>}
                {children}
              </>
            )}
          </div>
        ) : (
          <div className="content-hidden">
            {title && <h3 className="heading">{title}</h3>}
            {children}
          </div>
        )}
      </>
    );
  }
}
