import React, { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { FiltroTurno } from '../filtro-turno/FiltroTurno';
import ListaDeTurnos from '../lista-de-turnos/ListaDeTurnos';

const ListaTurnoFiltro = props => {
  const handlerTurnoSelecionado = useCallback(
    turno => {
      if (props.selectedTurno) props.selectedTurno(turno);
    },
    [props],
  );
  return (
    <Card>
      <Card.Header>FILTRO PARA LOCALIZAÇÃO DE TURNOS</Card.Header>
      <Card.Body>
        <FiltroTurno
          onBusy={msg => {
            if (props && typeof props.onBusy === 'function') {
              props.onBusy(msg);
            }
          }}
          onUnBusy={() => {
            if (props && typeof props.onUnBusy === 'function') {
              props.onUnBusy();
            }
          }}
          onFilter={t => {
            if (props && typeof props.filterFound === 'function') {
              props.filterFound(t);
            }
          }}
          filter={props.filter}
          onCleaned={() => {
            if (props && typeof props.onCleaned === 'function') {
              props.onCleaned();
            }
          }}
          onFilterChange={p => {
            if (props && typeof props.filterChange === 'function') {
              props.filterChange(p);
            }
          }}
        ></FiltroTurno>
      </Card.Body>
      <Card.Footer>
        <ListaDeTurnos
          selectedTurno={handlerTurnoSelecionado}
          turnos={props.turnos}
          onBusy={msg => {
            if (props.onBusy) props.onBusy(msg);
          }}
          onUnBusy={() => {
            if (props.onUnBusy) props.onUnBusy();
          }}
        ></ListaDeTurnos>
      </Card.Footer>
    </Card>
  );
};

export default ListaTurnoFiltro;
