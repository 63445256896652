import { AxiosErrorService } from '../../../../services/core/axiosError.service';
import { UrlLinxCoreService } from './../../../../services/core/urlLinxCore.service';

export class ApiAlterarPagamentoService {
  urlLinxCore = null;
  constructor() {
    this.urlLinxCore = new UrlLinxCoreService().urlBase();
  }

  async execute(body) {
    try {
      await this.urlLinxCore.post(`/ConComodato/AlterarPagamento`, body);
    } catch (error) {
      await new AxiosErrorService().execute(error);
    }
  }
}
