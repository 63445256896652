import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "./Modal";
import Botao from "../botao/Botao";
import { Visualizar } from "../../pages/financeiro/faturamento-vendas-prazo/RelatorioFaturamentoVendaPrazo";

export class ModalVisualizadorRelatorioFaturado extends Component {
  doClose = () => {
    if (this.props.handleCloseModal) {
      this.props.handleCloseModal();
    }
  };

  tagButtons = () => {
    return (
      <Botao
        title="Voltar"
        icon="icon-lx-arrow-left"
        onClick={() => this.doClose()}
      />
    );
  };

  render() {
    const {
      titulo = "",
      subtitulo = "",
      active = false,
      visualizarProps = {}
    } = this.props;
    return (
      <div>
        <Modal
          className={active ? "active modal-filter" : null}
          onClose={() => this.doClose()}
        >
          <ModalHeader title={titulo} subtitle={subtitulo} />
          <ModalBody>
            <center>
              <Visualizar
                dados={visualizarProps.dados}
                docs={visualizarProps.docs}
                nomeFantasia={visualizarProps.nomeFantasia}
                show
              />
            </center>
          </ModalBody>

          <ModalFooter>{this.tagButtons()}</ModalFooter>
        </Modal>
      </div>
    );
  }
}
