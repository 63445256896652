import React, { Component } from 'react';
import { Modal, ModalBody } from './Modal';
import { FormLancamento } from '../../pages/financeiro/gerenciamento-financeiro/FormLancamento/FormLancamento';

export class ModalLancamentoFinanceiro extends Component {
  getLancamento = lancamentoState => {
    if (lancamentoState) {
      const lancamento = {
        tipoLancamento: lancamentoState.tipoLancamento,
        valor: lancamentoState.valor,
        descricao: lancamentoState.descricao,
        dataVencimento: lancamentoState.dataVencimento,
        pago: lancamentoState.pago,
        conciliado: lancamentoState.conciliado,
        conta: lancamentoState.conta,
        centroMonetarioOrigem: lancamentoState.centroMonetarioOrigem,
        centroMonetarioDestino: lancamentoState.centroMonetarioDestino,
        tipoOcorrencia: lancamentoState.tipoOcorrencia,
        diaVencimento: lancamentoState.diaVencimento,
        numeroParcelas: lancamentoState.numeroParcelas,
        pessoa: lancamentoState.pessoa,
        numeroDocumento: lancamentoState.numeroDocumento,
        tipoDocumento: lancamentoState.tipoDocumento,
        banco: lancamentoState.banco,
        contaBanco: lancamentoState.contaBanco,
        agencia: lancamentoState.agencia,
        estruturaCartoes: lancamentoState.estruturaCartoes,
        operacao: lancamentoState.operacao,
        historico: lancamentoState.historico
      };
      this.props.handleCloseModal(lancamento);
    } else {
      this.props.handleCloseModal(null);
    }
  };

  render() {
    const { active, handleCloseModal } = this.props;

    return (
      <Modal
        className={`${active ? 'active' : null} modal-filter`}
        onClose={() => handleCloseModal(null)}
      >
        <ModalBody>
          <FormLancamento
            titulo={{}}
            goBackTo={null}
            getStateMethod={this.getLancamento}
          />
        </ModalBody>
      </Modal>
    );
  }
}
