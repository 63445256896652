export class ReceberPagamentoService {
  execute = (state, { payload }) => {
    const { payment, pedidoId } = payload;
    const idx = state.values.pedidos.findIndex(p => p.pedidoId === pedidoId);
    if (!state.values.pedidos[idx]) return;
    const itIdx = payload.idx;

    if (itIdx === -1) return;
    state.values.pedidos[idx].pagamentos[itIdx] = payment;
  };
}

