// import { Button as ButtonGrid, Column } from 'devextreme-react/data-grid';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { formatStringToCurrency } from '../../../../../../shared/utils/Utils';
const TurnoViewItemSummary = ({ valorRecebido, valorConferido, valorDiferenca, valorSangrado, valorSuprido }) => {
  return (
    <>
      <Card>
        <Card.Footer>
          <Row style={{ marginRight: '32px' }}>
            <Col col={2}>
              <small>&nbsp;</small>
              <br />
              <strong>TOTAL:</strong>
            </Col>
            <Col col={2}>
              <small>Suprimento</small>
              <br />
              <strong>{formatStringToCurrency(valorSuprido || 0)}</strong>
            </Col>
            <Col col={4}>
              <small>Recebido</small>
              <br />
              <strong>{formatStringToCurrency(valorRecebido || 0)}</strong>
            </Col>
            <Col col={2}>
              <small>Sangria</small>
              <br />
              <strong>{formatStringToCurrency(valorSangrado || 0)}</strong>
            </Col>
            <Col col={2}>
              <small>&nbsp;</small>
              <br />
              <strong>{formatStringToCurrency(valorConferido || 0)}</strong>
            </Col>
            <Col col={2}>
              <small>&nbsp;</small>
              <br />
              <strong>{formatStringToCurrency(valorDiferenca || 0)}</strong>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default TurnoViewItemSummary;
