import { createSlice } from '@reduxjs/toolkit';
import { NovaOSItemValidateService } from '../../../pages/operacoes/consignado-comodato-nova-os/usercases/novaOsItemValidate.service';

const validateService = new NovaOSItemValidateService();

const tipos = [
  { id: 1, nome: 'VENDA' },
  { id: 2, nome: 'CONSIGNADO' },
  { id: 3, nome: 'COMODATO' },
  { id: 7, nome: 'VENDA EM BONIFICAÇÃO' },
  { id: 8, nome: 'LOCAÇÃO' },
];

const calcularPrecoTotal = (quantidade, valorAtivo) => quantidade * valorAtivo;

const slice = createSlice({
  name: 'novaOsItem',
  initialState: {
    buffer: '',
    values: {
      valorAtivo: 0,
      produto: null,
      quantidade: 0,
      precoTotal: 0,
      tipoPedido: tipos[0], // Setar o valor inicial aqui
    },
    errors: {},
    touched: {},
    tipos: tipos,
  },
  reducers: {
    changeValue: (state, { payload }) => {
      const { fieldName, value } = payload;
      state.values[fieldName] = value;
      state.values.precoTotal = calcularPrecoTotal(state.values.quantidade, state.values.valorAtivo);
      state.errors = validateService.execute(state.values);
    },
    changeBlur: (state, { payload }) => {
      const { fieldName } = payload;
      state.touched[fieldName] = true;
    },
    initialize: state => {
      state.values = {
        valorAtivo: 0,
        produto: null,
        quantidade: 0,
        precoTotal: 0,
        tipoPedido: state.values.tipoPedido || tipos[0],
      };
      state.tipos = tipos;
    },
  },
});

export const useNovaOsItem = () => slice.actions;
export const selectNovaOsItem = state => state.novaOsItem;
export default slice.reducer;
