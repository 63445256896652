import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Select,
  handleInputChange,
  InputDate,
  Checkbox,
  Checkitem
} from '../../../components/formulario/Formulario';
import Header from '../../../components/section/Header';
import {
  SectionContainer,
  SectionContent
} from '../../../components/section/Content';
import {
  tagAlert,
  showError,
  showInfo,
  hideAlert,
  showQuestion,
  showSaveError,
  showWait
} from '../../../components/alert/Alert';
import LinxPostos from '../../../components/core/linxPostos/LinxPostos';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from '../../../components/modal/Modal';

import {
  incluirMovimentacaoEstoque,
  montarComboOperacaoEstoque
} from './AjusteEstoque.service';

import {
  getProdutos,
  getProdutoEstoque,
  getAlmoxarifados
} from '../../cadastro/produtos/Produtos.service';

import {
  menuPermiteInclusao,
  getNomePagina,
  validaDadosLogin,
  sameInt,
  sameString
} from '../../../shared/utils/Utils';
import { Subtipo } from '../../cadastro/produtos/Produtos';

import { menus } from '../../../shared/constants/MenuConstants';
import Footer from '../../../components/core/footer/Footer';

const PATH_MENU = menus.COD_60630;
const titulo = 'Ajuste de estoque';

export const TipoOperacao = {
  Entrada: '1',
  Saida: '2',
  Transferencia: '3'
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    this.state = {
      iniciarOperacao: false,
      operacao: TipoOperacao.Entrada,
      retroativo: false,
      lancSequencia: false,
      itensProduto: [],
      codigoDigitado: ''
    };
  }

  handleCloseModalJustificativa = justificativa => {
    const { itensProduto } = this.state;
    this.setState({ modalJustificativa: false });

    var busca = this.state.buscaAtual;

    if (justificativa !== '') {
      for (let i = 0; i < itensProduto.length; i++) {
        const e = itensProduto[i];
        if (e.codigo === busca) itensProduto[i].justificativa = justificativa;
      }

      this.setState({ itensProduto });
    }
  };

  incluirItem = async (objProd, search) => {
    const { data: estoque } = await getProdutoEstoque(objProd.id);
    const almoxarifados = estoque.almoxarifados;

    this.setState({ almoxarifadosProduto: almoxarifados });

    var primeiroAlmoxarifado = 0;
    var primeiroAlmoxarifadoSaldo = 0;
    var segundoAlmoxarifado = 0;
    var segundoAlmoxarifadoSaldo = 0;
    if (almoxarifados.length > 0) {
      primeiroAlmoxarifado = almoxarifados[0].codigoAlmoxarifado;
      primeiroAlmoxarifadoSaldo = almoxarifados[0].saldo;
    }
    if (almoxarifados.length > 1) {
      segundoAlmoxarifado = almoxarifados[1].codigoAlmoxarifado;
      segundoAlmoxarifadoSaldo = almoxarifados[1].saldo;
    }

    const almoxarifadosCombo = almoxarifados.map(a => {
      return {
        label: a.descricao,
        value: a.codigoAlmoxarifado
      };
    });

    const objProduto = {
      busca: search,
      id: objProd.id,
      codigo: objProd.codigo,
      descricao: objProd.descricao,
      almoxarifado: primeiroAlmoxarifado,
      almoxarifadoDestino: segundoAlmoxarifado,
      qtdeAtualDestino: segundoAlmoxarifadoSaldo,
      almoxarifadosInfo: almoxarifados,
      almoxarifadosLista: almoxarifadosCombo,
      qtdeAtual: primeiroAlmoxarifadoSaldo,
      qtdeAtualOrigem: primeiroAlmoxarifadoSaldo,
      qtde: 0,
      justificativa: '',
      DataRetroativa: this.state.dataRetroativa,
      HoraRetroativa: this.state.horaRetroativa
    };

    this.handleCloseModalProdutos(objProduto);
  };

  handleCloseModalProdutos = objProduto => {
    const { itensProduto, operacao } = this.state;
    this.setState({ modalProdutos: false });
    var temErro = false;

    if (objProduto) {
      if (!objProduto.busca) objProduto.busca = this.state.buscaAtual;

      objProduto.DataRetroativa = this.state.dataRetroativa;
      objProduto.HoraRetroativa = this.state.horaRetroativa;

      if (
        operacao === TipoOperacao.Transferencia &&
        objProduto.almoxarifadosInfo.length < 2
      ) {
        showError(
          this,
          titulo,
          'Produto não possui mais de um almoxarifado vinculado!'
        );
        temErro = true;
      }
      if (!temErro) {
        if (
          itensProduto.filter(
            iP =>
              sameInt(iP.codigo, objProduto.codigo) &&
              !sameString(iP.descricao, '')
          ).length === 0
        ) {
          for (let i = 0; i < itensProduto.length; i++) {
            const e = itensProduto[i];
            if (sameInt(e.codigo, objProduto.busca)) {
              itensProduto[i] = objProduto;
            }
          }

          this.iniciarNovaLinha();

          this.setState({ itensProduto });

          const node = document.getElementsByClassName('input');
          if (node) {
            if (node.codigo1) node.codigo1.focus();
            if (node.codigo2) node.codigo2.focus();
            if (node.codigo3) node.codigo3.focus();
            if (node.codigo4) node.codigo4.focus();
            if (node.codigo5) node.codigo5.focus();
          }
        } else showError(this, titulo, 'Item já adicionado!');
      }
    }
  };

  validaTeclaDigitada = e => {
    //var codigoDigitado = this.state.codigoDigitado;
    if (
      e.keyCode !== 8 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 46
    ) {
      const re = /[0-9.]+/g;
      if (!re.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  async componentDidMount() {
    const { data: operacoesEstoque } = await montarComboOperacaoEstoque();

    this.setState({
      operacoes: operacoesEstoque.map(oP => {
        return {
          label: oP.descricao,
          value: oP.codigo
        };
      })
    });

    const { data: almoxarifados } = await getAlmoxarifados();

    this.setState({
      almoxarifadosCombo: almoxarifados.map(a => {
        return {
          label: a.descricao,
          value: a.codigo
        };
      })
    });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.edit !== prevProps.edit && !this.props.edit) {
      const { data: operacoesEstoque } = await montarComboOperacaoEstoque();

      this.setState({
        operacoes: operacoesEstoque.map(oP => {
          return {
            label: oP.descricao,
            value: oP.codigo
          };
        })
      });

      const { data: almoxarifados } = await getAlmoxarifados();

      this.setState({
        almoxarifadosCombo: almoxarifados.map(a => {
          return {
            label: a.descricao,
            value: a.codigo
          };
        })
      });
    }
  }

  iniciarNovaLinha = () => {
    const itensProduto = this.state.itensProduto;
    let objProduto = {};
    const ultimoId = itensProduto.length
      ? itensProduto[itensProduto.length - 1].id
      : 0;

    objProduto = {
      id: ultimoId + 1,
      codigo: '',
      descricao: '',
      almoxarifado: '',
      almoxarifadosLista: [],
      qtdAtual: '',
      qtde: '',
      justificativa: ''
    };

    itensProduto.push(objProduto);

    const operacaoTransferencia = sameInt(
      this.state.operacao,
      TipoOperacao.Transferencia
    );

    this.setState({
      operacaoAjuste: !operacaoTransferencia,
      operacaoTransferencia
    });
  };

  validarQuantidadeNegativaTransf = (id, qtdeAtual, qtdeATransferir) => {
    const itens = this.state.itensProduto;
    if (
      sameInt(this.state.operacao, TipoOperacao.Transferencia) &&
      parseInt(qtdeATransferir) > qtdeAtual &&
      parseInt(qtdeATransferir) > 0
    ) {
      showQuestion(
        this,
        titulo,
        'Foi definida uma quantidade maior do que existe no almoxarifado ' +
          'origem. Deseja continuar?',
        null,
        async resp => {
          if (resp) {
            hideAlert(this);
          } else {
            hideAlert(this);
            itens.filter(i => i.id === id)[0].qtde = 0;
            this.setState({ itensProduto: itens });
          }
        }
      );
    }
  };

  validaDataEscolhida = () => {
    var dataValida = false;
    dataValida = moment(this.state.dataRetroativa, 'YYYY-MM-DD').isValid();
    return dataValida;
  };

  validaHoraEscolhida = () => {
    var horaValida = false;
    horaValida = moment(this.state.horaRetroativa, 'h:mm:ss').isValid();
    return horaValida;
  };

  validarMovimentos = itProd => {
    let temErros = false;
    for (let i = 0; i < itProd.length; i++) {
      const element = itProd[i];
      if (element.descricao !== '') {
        if (
          parseInt(element.qtde) === 0 ||
          element.qtde === '' ||
          element.qtde === '0'
        ) {
          showError(
            this,
            titulo,
            'O produto ' + element.descricao + ' não possui quantidade definida'
          );
          temErros = true;
          break;
        }

        if (this.state.operacao === TipoOperacao.Transferencia)
          if (
            parseInt(element.almoxarifado) ===
            parseInt(element.almoxarifadoDestino)
          ) {
            showError(
              this,
              titulo,
              'O produto ' +
                element.descricao +
                ' não pode ter o mesmo almoxarifado para a origem e o destino.'
            );
            temErros = true;
            break;
          }

        if (element.justificativa === '') {
          showError(
            this,
            titulo,
            'O produto ' + element.descricao + ' não possui justificativa'
          );
          temErros = true;
          break;
        }
      }
    }

    if (temErros) return temErros;

    if (this.state.retroativo) {
      if (!this.validaDataEscolhida()) {
        showError(
          this,
          titulo,
          'Campo Data Retroativa deve ser uma data válida'
        );
        temErros = true;
      }

      if (temErros) return temErros;

      if (!this.state.horaRetroativa) {
        showError(this, titulo, 'O campo Hora Retroativa deve ser preenchido');
        temErros = true;
      }

      if (temErros) return temErros;

      if (!this.validaHoraEscolhida()) {
        showError(
          this,
          titulo,
          'Campo Hora Retroativa deve ser um horário válido'
        );
        temErros = true;
      }

      if (temErros) return temErros;
    }
  };

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push('/');
    }

    const {
      //state de ajusteEstoque
      itensProduto,
      operacao,
      operacoes
    } = this.state;

    let temErros = false;

    temErros = this.validarMovimentos(itensProduto);

    const operacaoEstoque = {
      codigo: operacao,
      descricao: operacoes.filter(o => o.value === parseInt(operacao))[0].label
    };

    const movimentos = [];

    const objJustificativa = {
      codigo: 0,
      descricao: '[Informar texto livre]'
    };

    if (itensProduto.length === 1 && itensProduto[0].descricao === '') {
      showError(this, titulo, 'Inclua ao menos um ajuste');
      temErros = true;
    }

    for (let i = 0; i < itensProduto.length; i++) {
      const iP = itensProduto[i];
      if (iP.descricao !== '') {
        const objMovimento = {};
        movimentos.push(objMovimento);
        movimentos[i].justificativa = objJustificativa;
        movimentos[i].quantidade = iP.qtde;
        movimentos[i].produto = {};
        movimentos[i].produto.id = iP.id;
        movimentos[i].produto.codigo = iP.codigo;
        movimentos[i].produto.descricao = iP.descricao;
        movimentos[i].almoxarifado = {};
        movimentos[i].almoxarifado.codigoAlmoxarifado = iP.almoxarifado;
        movimentos[i].almoxarifado.numeroAlmoxarifado = 0;
        movimentos[i].almoxarifado.descricao = 'teste';
        if (operacao === TipoOperacao.Transferencia) {
          movimentos[i].almoxarifadoDestino = {};
          movimentos[i].almoxarifadoDestino.codigoAlmoxarifado =
            iP.almoxarifadoDestino;
          movimentos[i].almoxarifadoDestino.numeroAlmoxarifado = 0;
          movimentos[i].almoxarifadoDestino.descricao = 'teste';
        } else movimentos[i].almoxarifadoDestino = null;
        objMovimento.observaocao = iP.justificativa;
        if (!this.state.retroativo)
          movimentos[i].dataMovimentacao = moment().format(
            'YYYY-MM-DD HH:mm:ss'
          );
        else
          movimentos[i].dataMovimentacao = moment(
            iP.DataRetroativa + ' ' + iP.HoraRetroativa,
            'YYYY-MM-DD HH:mm:ss'
          ).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    const [action, params] = [
      incluirMovimentacaoEstoque,
      [operacaoEstoque, movimentos]
    ];

    if (!temErros) {
      try {
        showWait(
          this,
          titulo,
          'Por favor, aguarde enquanto o ajuste de estoque é salvo.'
        );
        await action(...params);
        showInfo(
          this,
          titulo,
          'Ajuste de estoque realizado com sucesso!',
          null,
          () => {
            hideAlert(this);
            this.backToScreen();
          }
        );
      } catch (err) {
        showSaveError(this, titulo, err);
      }
    }
  };

  backToScreen = () => {
    this.setState({
      operacao: TipoOperacao.Entrada,
      lancSequencia: false,
      retroativo: false,
      itensProduto: [],
      iniciarOperacao: false,
      operacaoAjuste: false,
      operacaoTransferencia: false,
      dataRetroativa: null,
      horaRetroativa: null
    });
  };

  handleCancelar = async () => {
    showQuestion(
      this,
      titulo,
      'Deseja realmente cancelar a operação?',
      null,
      resp => {
        if (resp) {
          this.backToScreen();
          hideAlert(this);
        } else {
          hideAlert(this);
        }
      }
    );
  };

  handlePesquisar = search => {
    if (search.length > 0) this.buscarProdutosFiltrados(search);
  };

  buscarProdutosFiltrados = async search => {
    const consulta = {};
    if (search) {
      consulta.search = search;
      consulta.situacao = [0, 1];
      consulta.subTipo = [
        Subtipo.Simples,
        Subtipo.Embalagem,
        Subtipo.Lubrificante,
        Subtipo.Filtro,
        Subtipo.ElementoFiltrante,
        Subtipo.MaterialUsoConsumo,
        Subtipo.AtivoImobilizado,
        Subtipo.OutrosInsumos,
        Subtipo.Fluidos
      ];
      consulta.transferenciaEstoque =
        this.state.operacao === TipoOperacao.Transferencia;
      const { data: produtosFiltradosresponse } = await getProdutos(consulta);

      const produtosFiltrados = produtosFiltradosresponse.result;

      let produtosFiltradosNovo = [];

      if (produtosFiltrados.length > 1) {
        produtosFiltradosNovo = produtosFiltrados;

        if (produtosFiltradosNovo.length > 0)
          this.setState({ modalProdutos: true, buscaAtual: search });
        else
          showInfo(
            this,
            titulo,
            'Produto não possui dois almoxarifados ativos.'
          );
      } else if (produtosFiltrados.length === 1) {
        produtosFiltradosNovo = produtosFiltrados;

        if (produtosFiltradosNovo.length > 0)
          this.incluirItem(produtosFiltradosNovo[0], search);
        else
          showInfo(
            this,
            titulo,
            'Produto não possui dois almoxarifados ativos.'
          );
      } else
        showInfo(
          this,
          titulo,
          'Não foi encontrado produtos com o termo de busca atual'
        );
      this.setState({ produtosFiltrados: produtosFiltradosNovo });
    }
  };

  handleSearchTextChange = e => {
    var nome = e.target.name;
    var valor = e.target.value;
    this.setState({
      searchText: valor,
      [nome]: valor
    });
  };

  handleChangeGrid = (
    id,
    codigoProduto,
    qtdeProduto,
    almoxarifado,
    dataRetro,
    horaRetro,
    almoxarifadoDest
  ) => {
    var qtdeAlmSelecionado = 0;
    var qtdeAlmDestSelecionado = 0;
    var temErros = false;
    const itens = this.state.itensProduto.map(iP => {
      if (iP.id === id) {
        if (moment(dataRetro).isAfter(moment())) {
          //alert("Não é permitido selecionar data posterior a data atual.");
          showInfo(
            this,
            titulo,
            'Não é permitido selecionar data posterior a data atual.'
          );
          temErros = true;
        }

        if (!temErros) {
          if (iP.almoxarifadosInfo) {
            var almSeleciona = iP.almoxarifadosInfo.filter(
              a => a.codigoAlmoxarifado === parseInt(almoxarifado)
            );
            if (almSeleciona.length > 0)
              qtdeAlmSelecionado = almSeleciona[0].saldo;
            var almSelecionaDest = iP.almoxarifadosInfo.filter(
              a => a.codigoAlmoxarifado === parseInt(almoxarifadoDest)
            );
            if (almSelecionaDest.length > 0)
              qtdeAlmDestSelecionado = almSelecionaDest[0].saldo;
          }

          if (qtdeProduto !== 0)
            this.validarQuantidadeNegativaTransf(
              iP.id,
              qtdeAlmSelecionado,
              qtdeProduto
            );

          return {
            ...iP,
            codigo: codigoProduto,
            qtde: qtdeProduto,
            almoxarifado,
            almoxarifadoDestino: almoxarifadoDest,
            qtdeAtual: qtdeAlmSelecionado,
            qtdeAtualOrigem: qtdeAlmSelecionado,
            qtdeAtualDestino: qtdeAlmDestSelecionado,
            DataRetroativa: dataRetro,
            HoraRetroativa: horaRetro
          };
        } else {
          return iP;
        }
      } else {
        return iP;
      }
    });

    this.setState({ itensProduto: itens });
  };

  abrirJustificativa = e => {
    var codigoProduto = e.currentTarget.className.substring(
      7,
      e.currentTarget.className.indexOf(null) - 1
    );
    this.setState({ modalJustificativa: true, buscaAtual: codigoProduto });
  };

  eliminarMovimento = e => {
    var listaMovimentoAtual = this.state.itensProduto;
    var listaMovimentoNovo = [];
    var nome = e.currentTarget.className;

    for (let i = 0; i < listaMovimentoAtual.length; i++) {
      const element = listaMovimentoAtual[i];
      if (nome.indexOf(element.codigo) === -1) listaMovimentoNovo.push(element);
    }

    let objProduto = {};
    var ultimoId = 0;

    for (let i = 0; i < listaMovimentoNovo.length; i++) {
      ultimoId = i;
    }

    objProduto = {
      id: ultimoId + 1,
      codigo: '',
      descricao: '',
      almoxarifado: '',
      almoxarifadosLista: [],
      qtdAtual: '',
      qtde: '',
      justificativa: ''
    };

    listaMovimentoNovo.push(objProduto);

    this.setState({ itensProduto: listaMovimentoNovo });
  };

  validarEdicaoCodigo = id => {
    var itProd = this.state.itensProduto;
    if (this.state.lancSequencia) return false;
    else {
      if (itProd.length > 1)
        if (id === itProd.length - 1)
          if (itProd[itProd.length - 2].qtde === 0) return true;
    }
  };

  handleRetroChange = e => {
    if (e.date) {
      let dataRetro = e.date.format('YYYY-MM-DD');
      if (moment(dataRetro).isAfter(moment())) {
        //alert("Não é permitido selecionar data posterior a data atual.");
        showInfo(
          this,
          titulo,
          'Não é permitido selecionar data posterior a data atual.'
        );
      } else {
        this.setState({ dataRetroativa: dataRetro });
        const itens = this.state.itensProduto.map(iP => {
          return { ...iP, DataRetroativa: dataRetro };
        });

        this.setState({ itensProduto: itens });
      }
    }
  };

  handleRetroHoraChange = e => {
    let horaRetro = e.value;
    let dataRetro = this.state.dataRetroativa;

    let dataRetroFormatada = moment(dataRetro).format('YYYY-MM-DD');
    let dataAtualFormatada = moment().format('YYYY-MM-DD');

    let horaRetroFormatada = moment(horaRetro, 'hmmss').format('HH:mm:ss');
    let horaAtualFormatada = moment().format('HH:mm:ss');

    let dataCompletaRetro = dataRetroFormatada + 'T' + horaRetroFormatada;
    let dataAtualCompleta = dataAtualFormatada + 'T' + horaAtualFormatada;

    if (horaRetro.length === 6) {
      if (moment(dataCompletaRetro).isAfter(dataAtualCompleta)) {
        showInfo(
          this,
          titulo,
          'Quando informada a data vigente, o horário não pode ser superior ao atual.'
        );

        horaRetro = null;
      }
    }
    this.setState({ horaRetroativa: horaRetro });
    const itens = this.state.itensProduto.map(iP => {
      return { ...iP, HoraRetroativa: horaRetro };
    });

    this.setState({ itensProduto: itens });
  };

  render() {
    const { modalJustificativa } = this.state;
    const { modalProdutos } = this.state;

    const {
      //campos tela
      operacao,
      lancSequencia,
      retroativo,
      operacoes,
      iniciarOperacao,
      dataRetroativa,
      horaRetroativa,
      operacaoAjuste,
      operacaoTransferencia
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <Header
            subtitle=""
            right={
              iniciarOperacao ? (
                <></>
              ) : menuPermiteInclusao(PATH_MENU) ? (
                <div className="button-container">
                  {
                    <Botao
                      ic
                      icon="icon-lx-plus"
                      disabled={iniciarOperacao}
                      onClick={() => {
                        this.setState({ iniciarOperacao: true });
                        this.iniciarNovaLinha();
                      }}
                    />
                  }
                </div>
              ) : (
                <></>
              )
            }
          />
          <SectionContainer>
            <SectionContent title="">
              <div className="row">
                <div className="col-5">
                  <Select
                    label="Operação:"
                    name="operacao"
                    value={operacao}
                    onChange={this.handleInputChange}
                    options={operacoes}
                    tabindex={1}
                    disabled={iniciarOperacao}
                  />
                </div>
              </div>
            </SectionContent>
          </SectionContainer>
          <SectionContainer visible={this.state.iniciarOperacao}>
            <SectionContent visible={!this.state.retroativo}>
              <div className="row">
                <div className="col-4">
                  <Checkbox label="Lançamento retroativo:">
                    <Checkitem
                      label=""
                      name="retroativo"
                      checked={retroativo}
                      onChange={this.handleInputChange}
                      tabindex={2}
                    />
                  </Checkbox>
                </div>
              </div>
            </SectionContent>
            <SectionContent visible={this.state.retroativo}>
              <div className="row">
                <div className="col-4">
                  <Checkbox label="Lançamento retroativo:">
                    <Checkitem
                      label=""
                      name="retroativo"
                      checked={retroativo}
                      onChange={this.handleInputChange}
                      tabindex={2}
                    />
                  </Checkbox>
                </div>

                <div
                  className="col-4"
                  style={{
                    position: 'relative',
                    zIndex: '11'
                  }}
                >
                  <InputDate
                    label="Data:"
                    value={dataRetroativa}
                    name="dataRetroativa"
                    onChange={this.handleRetroChange}
                    tabindex={3}
                  />
                </div>
                <div className="col-4">
                  <InputText
                    label="Hora:"
                    value={horaRetroativa}
                    name="horaRetroativa"
                    type="number"
                    format="##:##:##"
                    onChange={this.handleRetroHoraChange}
                    allowNegative={false}
                    tabindex={4}
                    text={moment(horaRetroativa)
                      .utc()
                      .format('HH:mm:ss')}
                    onBlur={this.handleBlur}
                  />
                </div>
              </div>
            </SectionContent>
            <SectionContent>
              <div className="row">
                <div className="col-4">
                  <Checkbox label="Lançamento em sequência:">
                    <Checkitem
                      label=""
                      name="lancSequencia"
                      checked={lancSequencia}
                      onChange={this.handleInputChange}
                    />
                  </Checkbox>
                </div>
              </div>

              <ModalJustificativa
                active={modalJustificativa}
                handleCloseModal={this.handleCloseModalJustificativa}
              />

              <ModalProdutos
                active={modalProdutos}
                handleCloseModal={this.handleCloseModalProdutos}
                produtosFiltrados={this.state.produtosFiltrados}
              />

              <ReactTable
                defaultPageSize={5}
                onClick={this.handleTableClick}
                data={this.state.itensProduto}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
                columns={[
                  {
                    accessor: 'id',
                    Header: 'id',
                    width: '20',
                    show: false
                  },
                  {
                    accessor: 'almoxarifadosLista',
                    Header: 'al',
                    width: '20',
                    show: false
                  },
                  {
                    accessor: 'codigo',
                    Header: 'Código',
                    width: '250',
                    Cell: props => {
                      return (
                        <InputText
                          className={'codigo' + props.row.id}
                          value={props.row.codigo}
                          name={'codigo' + props.row.id}
                          maxlength="20"
                          type="number"
                          allowNegative={false}
                          disabled={this.validarEdicaoCodigo(props.row.id)}
                          onFocus={this.validaSequencial}
                          tabindex={1}
                          onChange={event => {
                            this.handleChangeGrid(
                              props.row.id,
                              event.value,
                              props.row.qtde,
                              props.row.almoxarifado,
                              props.row.DataRetroativa,
                              props.row.HoraRetroativa,
                              props.row.almoxarifadoDestino
                            );
                          }}
                          icone={
                            props.row.codigo.length > 0 ? 'icon-lx-search' : ''
                          }
                          onIconeClick={() => {
                            this.handlePesquisar(props.row.codigo);
                          }}
                          onKeyDown={
                            props.row.codigo
                              ? event => {
                                  if (event.keyCode === 13)
                                    this.handlePesquisar(props.row.codigo);
                                }
                              : null
                          }
                          help={`${
                            props.row.codigo.length > 0
                              ? 'Clique na "lupa" para buscar'
                              : 'Digite pelo menos 1 caracter'
                          }`}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'descricao',
                    Header: 'Descrição',
                    width: '200',
                    style: { whiteSpace: 'unset' },
                    filterable: false
                  },
                  {
                    accessor: 'DataRetroativa',
                    Header: 'Data',
                    width: '200',
                    style: { whiteSpace: 'unset' },
                    filterable: false,
                    show: retroativo,
                    Cell: props => {
                      return (
                        <InputDate
                          className="inputd"
                          value={props.row.DataRetroativa}
                          name="dataRetroativa"
                          disabled={props.row.descricao === ''}
                          onChange={event =>
                            this.handleChangeGrid(
                              props.row.id,
                              props.row.codigo,
                              props.row.qtde,
                              props.row.almoxarifado,
                              event.date
                                ? event.date.format('YYYY-MM-DD')
                                : null,
                              props.row.HoraRetroativa,
                              props.row.almoxarifadoDestino
                            )
                          }
                        />
                      );
                    }
                  },
                  {
                    accessor: 'HoraRetroativa',
                    Header: 'Hora',
                    width: '200',
                    style: { whiteSpace: 'unset' },
                    filterable: false,
                    show: retroativo,
                    Cell: props => {
                      return (
                        <InputText
                          className="inputh"
                          label=""
                          value={props.row.HoraRetroativa}
                          name="horaRetroativa"
                          disabled={props.row.descricao === ''}
                          type="number"
                          format="##:##:##"
                          onChange={event =>
                            this.handleChangeGrid(
                              props.row.id,
                              props.row.codigo,
                              props.row.qtde,
                              props.row.almoxarifado,
                              props.row.DataRetroativa,
                              event.value,
                              props.row.almoxarifadoDestino
                            )
                          }
                          allowNegative={false}
                          tabindex={4}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'almoxarifado',
                    Header: 'Almoxarifado',
                    width: '200',
                    style: { whiteSpace: 'unset' },
                    show: operacaoAjuste,
                    Cell: props => {
                      return (
                        <Select
                          label=""
                          name="almoxarifado"
                          value={props.row.almoxarifado}
                          disabled={props.row.descricao === ''}
                          onChange={event =>
                            this.handleChangeGrid(
                              props.row.id,
                              props.row.codigo,
                              props.row.qtde,
                              event.target.value,
                              props.row.DataRetroativa,
                              props.row.HoraRetroativa,
                              props.row.almoxarifadoDestino
                            )
                          }
                          options={props.row.almoxarifadosLista}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'qtdeAtual',
                    Header: 'Qtd. atual',
                    width: '150',
                    style: { whiteSpace: 'unset' },
                    filterable: false,
                    show: operacaoAjuste
                  },
                  {
                    accessor: 'almoxarifadoOrigem',
                    Header: 'Almox. Origem',
                    width: '200',
                    style: { whiteSpace: 'unset' },
                    show: operacaoTransferencia,
                    Cell: props => {
                      return (
                        <Select
                          label=""
                          name="operacao"
                          value={props.row.almoxarifado}
                          disabled={props.row.descricao === ''}
                          onChange={event =>
                            this.handleChangeGrid(
                              props.row.id,
                              props.row.codigo,
                              props.row.qtde,
                              event.target.value,
                              props.row.DataRetroativa,
                              props.row.HoraRetroativa,
                              props.row.almoxarifadoDestino
                            )
                          }
                          options={props.row.almoxarifadosLista}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'qtdeAtualOrigem',
                    Header: 'Qtd. atual',
                    width: '150',
                    style: { whiteSpace: 'unset' },
                    show: operacaoTransferencia,
                    filterable: false
                  },
                  {
                    accessor: 'almoxarifadoDestino',
                    Header: 'Almox. Destino',
                    width: '200',
                    style: { whiteSpace: 'unset' },
                    show: operacaoTransferencia,
                    Cell: props => {
                      return (
                        <Select
                          label=""
                          name="operacao"
                          value={props.row.almoxarifadoDestino}
                          disabled={props.row.descricao === ''}
                          onChange={event =>
                            this.handleChangeGrid(
                              props.row.id,
                              props.row.codigo,
                              props.row.qtde,
                              props.row.almoxarifado,
                              props.row.DataRetroativa,
                              props.row.HoraRetroativa,
                              event.target.value
                            )
                          }
                          options={props.row.almoxarifadosLista}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'qtdeAtualDestino',
                    Header: 'Qtd. atual',
                    width: '150',
                    style: { whiteSpace: 'unset' },
                    show: operacaoTransferencia,
                    filterable: false
                  },
                  {
                    accessor: 'qtde',
                    Header: 'Quantidade',
                    width: '130',
                    style: { whiteSpace: 'unset' },
                    filterable: false,
                    Cell: props => {
                      return (
                        <InputText
                          className="input"
                          value={props.row.qtde}
                          name="qtde"
                          maxlength="20"
                          onKeyDown={this.validaTeclaDigitada}
                          disabled={props.row.descricao === ''}
                          onChange={event =>
                            this.handleChangeGrid(
                              props.row.id,
                              props.row.codigo,
                              event.target.value,
                              props.row.almoxarifado,
                              props.row.DataRetroativa,
                              props.row.HoraRetroativa,
                              props.row.almoxarifadoDestino
                            )
                          }
                        />
                      );
                    }
                  },
                  {
                    accessor: 'acao',
                    Header: 'Justificativa',
                    width: 100,
                    filterable: false,
                    Cell: ({ row }) => {
                      return (
                        <Botao
                          ic
                          className={row.codigo}
                          disabled={row.descricao === ''}
                          icon={'icon-lx-edit'}
                          onClick={this.abrirJustificativa}
                        />
                      );
                    }
                  },
                  {
                    accessor: 'acao',
                    Header: 'Excluir',
                    width: 100,
                    filterable: false,
                    Cell: ({ row }) => {
                      return (
                        <Botao
                          ic
                          className={row.codigo}
                          disabled={row.descricao === ''}
                          icon={'icon-lx-trash'}
                          onClick={this.eliminarMovimento}
                        />
                      );
                    }
                  }
                ]}
              />
            </SectionContent>
          </SectionContainer>
        </LinxPostos>

        <Footer
          saveAction={iniciarOperacao ? this.handleSalvar : null}
          cancelAction={iniciarOperacao ? this.handleCancelar : null}
        />
        {tagAlert(this)}
      </>
    );
  }
}

Form = withRouter(Form);

class AjusteEstoque extends Component {
  render() {
    return (
      <main className="main">
        <section className="section-container">{<Form />}</section>
      </main>
    );
  }
}

export class ModalProdutos extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = { objProduto: {} };
  }

  adicionarItem = async objProd => {
    const { handleCloseModal } = this.props;

    const { data: estoque } = await getProdutoEstoque(objProd.id);
    const almoxarifados = estoque.almoxarifados;

    this.setState({ almoxarifadosProduto: almoxarifados });

    var primeiroAlmoxarifado = 0;
    var primeiroAlmoxarifadoSaldo = 0;
    var segundoAlmoxarifado = 0;
    var segundoAlmoxarifadoSaldo = 0;
    if (almoxarifados.length > 0) {
      primeiroAlmoxarifado = almoxarifados[0].codigoAlmoxarifado;
      primeiroAlmoxarifadoSaldo = almoxarifados[0].saldo;
    }
    if (almoxarifados.length > 1) {
      segundoAlmoxarifado = almoxarifados[1].codigoAlmoxarifado;
      segundoAlmoxarifadoSaldo = almoxarifados[1].saldo;
    }

    const almoxarifadosCombo = almoxarifados.map(a => {
      return {
        label: a.descricao,
        value: a.codigoAlmoxarifado
      };
    });

    const objProduto = {
      id: objProd.id,
      codigo: objProd.codigo,
      descricao: objProd.descricao,
      almoxarifado: primeiroAlmoxarifado,
      almoxarifadoOrigem: primeiroAlmoxarifado,
      almoxarifadoDestino: segundoAlmoxarifado,
      almoxarifadosInfo: almoxarifados,
      almoxarifadosLista: almoxarifadosCombo,
      qtdeAtual: primeiroAlmoxarifadoSaldo,
      qtdeAtualOrigem: primeiroAlmoxarifadoSaldo,
      qtdeAtualDestino: segundoAlmoxarifadoSaldo,
      qtde: 0,
      justificativa: '',
      DataRetroativa: this.state.dataRetroativa,
      HoraRetroativa: this.state.horaRetroativa
    };

    handleCloseModal(objProduto);
  };

  /*    handleAdicionar = (e) => {
              t { produtosFiltrados } = this.props;
              codigoProduto = e.currentTarget.className.substring(7, e.currentTarget.className.indexOf(null) - 1);
          var objProd = produtosFiltrados.filter(p => p.codigo === codigoProduto);
          this.adicionarItem(objProd[0]);
        */

  handleTableClick = (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      this.adicionarItem(rowInfo.original);
    }
  };

  render() {
    const { active, handleCloseModal, produtosFiltrados } = this.props;
    const { password } = this.state;

    return (
      <div>
        <Modal
          className={`${active ? 'active' : null} modal-filter`}
          onClose={() => handleCloseModal(false)}
        >
          <ModalHeader title="Pesquisar Produtos" subtitle="" />

          <ModalBody>
            {/* Conteúdo principal */}
            <SectionContent title="Selecione um produto">
              <ReactTable
                defaultPageSize={5}
                onClick={this.handleTableClick}
                data={produtosFiltrados}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
                columns={[
                  {
                    accessor: 'descricao',
                    Header: 'Produto',
                    width: '300',
                    style: { whiteSpace: 'unset' },
                    filterable: false
                  },
                  {
                    accessor: 'codigo',
                    Header: 'Código',
                    width: '250'
                  },
                  {
                    accessor: 'codigoRapido',
                    Header: 'Código rápido',
                    width: '150'
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (this.handleTableClick) {
                        this.handleTableClick(
                          state,
                          rowInfo,
                          column,
                          instance,
                          e
                        );
                      }

                      if (handleOriginal) {
                        handleOriginal();
                      }
                    }
                  };
                }}
              />
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal(false, password);
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export class ModalJustificativa extends Component {
  constructor(props) {
    super(props);

    this.handleInputChange = handleInputChange.bind(this);
    this.state = { objProduto: {} };
  }

  render() {
    const { active, handleCloseModal } = this.props;
    const { justificativaTexto } = this.state;
    return (
      <div>
        <Modal
          className={`${active ? 'active' : null} modal-filter`}
          onClose={() => handleCloseModal(false)}
        >
          <ModalHeader title="Justificativa" subtitle="" />

          <ModalBody>
            {/* Conteúdo principal */}
            <SectionContent>
              <InputText
                value={justificativaTexto}
                name="justificativaTexto"
                maxlength="200"
                onChange={e => {
                  this.setState({ justificativaTexto: e.target.value });
                }}
              />
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              icon="icon-lx-check"
              title={'Salvar'}
              onClick={() => {
                var jt = justificativaTexto;
                handleCloseModal(jt);
                this.setState({ justificativaTexto: '' });
              }}
            />
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal('');
              }}
            />
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

AjusteEstoque = withRouter(AjusteEstoque);
export { AjusteEstoque };
