import React, { Component } from 'react';
import Botao from '../../../components/botao/Botao';
import {
  InputText,
  Select,
  Checkbox,
  Checkitem,
  handleInputChange
} from '../../../components/formulario/Formulario';
import {
  SectionContainer,
  SectionContent
} from './../../../components/section/Content';
import { withRouter } from 'react-router-dom';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from '../../../components/modal/Modal';
import { tagAlert, showError, showInfo } from '../../../components/alert/Alert';

import { getProdutos, getSelecionarProduto } from './Produtos.service';
import {
  toFloatFormattedDisplay,
  sameInt,
  stringToFloatHelper
} from '../../../shared/utils/Utils';

const titulo = 'Produto';

class Form extends React.Component {
  constructor(props) {
    super(props);

    const {
      kit = true,
      composto = false,
      apresentaQuadro = false,
      codigoProduto,
      codigoRapido,
      descricaoProduto,
      situacao,
      setor = [],
      almoxarifado = [],
      tipo = [],
      subTipo = [],
      fabricante = [],
      fornecedor = [],
      dataCadastroDe,
      dataCadastroAte,
      dataAlteracaoDe,
      dataAlteracaoAte,
      ncm = [],
      infoProduto,
      precoVenda,
      percentualReajuste,
      formaReajuste
    } = this.props;

    const precoVendaTotal = this.props.itensProduto.precoVendaTotal;
    const valorCustoTotal = this.props.itensProduto.valorCustoTotal;

    this.state = {
      kit,
      composto,
      apresentaQuadro,
      codigoProduto,
      codigoRapido,
      descricaoProduto,
      situacao,
      setor,
      almoxarifado,
      tipo,
      subTipo,
      estruturaMercadologica: [],
      fabricante,
      fornecedor,
      dataCadastroDe,
      dataCadastroAte,
      dataAlteracaoDe,
      dataAlteracaoAte,
      ncm,
      fornecedores: [],
      fabricantes: [],
      setores: [],
      tipos: [],
      subtipos: [],
      almoxarifados: [],
      ncms: [],

      mostraGridProdutos: false,
      favoritoDe: 0,
      favoritoAte: 0,

      produtosFiltrados: [],
      searchText: null,
      valorSugestao: precoVendaTotal,
      precoVendaDigitado: precoVenda,
      valorCustoTotal,
      infoProduto,

      percentualReajuste,
      formaReajuste
    };
  }

  handleSearchTextChange = e => {
    this.setState({ searchText: e.target.value });
  };

  handlePesquisar = () => {
    if (this.props.itensProduto.length > 0 && this.props.embalagem) {
      showError(
        this,
        titulo,
        'Para subtipo embalagem é permitido apenas 1 item'
      );
    } else if (
      this.props.almoxarifadosProduto.length === 0 ||
      (this.props.almoxarifadosProduto.length === 1 &&
        this.props.almoxarifadosProduto[0].codigo === '')
    ) {
      showError(
        this,
        titulo,
        'Selecione ao menos um almoxarifado antes de incluir itens na composição.'
      );
    } else {
      this.buscarProdutosFiltrados();
    }
  };

  focaNaArvore = () => {
    this.setState({ mostraGridProdutos: false });
  };

  buscarProdutosFiltrados = async () => {
    var almoxProd = this.props.almoxarifadosProduto;
    const almoxarifadosFiltrar = [];

    for (let i = 0; i < almoxProd.length; i++) {
      const e = almoxProd[i];
      almoxarifadosFiltrar.push(e.codigo);
    }
    const search = this.state.searchText;
    const consulta = {};
    if (search) {
      consulta.search = search;
      consulta.almoxarifado = almoxarifadosFiltrar;
      consulta.situacao = [0, 1];
      if (this.props.composto || this.props.embalagem)
        consulta.subtipo = [2, 6, 7, 8, 12, 13];
      else if (this.props.kit) consulta.subtipo = [2, 3, 5, 6, 7, 8, 13];
      consulta.naoPermiteVenda = false;
      const { data: produtosFiltradosresponse } = await getProdutos(consulta);

      const produtosFiltrados = produtosFiltradosresponse.result;

      for (let i = 0; i < produtosFiltrados.length; i++) {
        produtosFiltrados[i].descricaoSubtipo = produtosFiltrados[i].subtipo;
        produtosFiltrados[i].descricaoPrecoVenda =
          produtosFiltrados[i].precoVenda;
      }

      if (produtosFiltrados.length > 1) {
        /* showWait(this, titulo,
          "Por favor, aguarde enquanto a pesquisa é realizada.");
        await Promise.all(
          produtosFiltrados.map(async p => {
            if (p) {
              const { data: produtoSelecionado } =
                await getSelecionarProduto(p.id);
              //return dataPessoa;
              p.unidadeVenda =
                produtoSelecionado && produtoSelecionado.unidadeVenda ?
                  produtoSelecionado.unidadeVenda.codigo : null
            }
            return p;
          })
        );
        hideWait(this); */
        this.setState({ modalComposicao: true });
      } else if (produtosFiltrados.length === 1) {
        if (sameInt(produtosFiltrados[0].id, this.props.idBase)) {
          showError(
            this,
            titulo,
            'Produto base não pode ser adicionado como item da composição'
          );
          return;
        }
        const { data: produtoSelecionado } = await getSelecionarProduto(
          produtosFiltrados[0].id
        );

        const objProduto = {
          id: produtosFiltrados[0].id,
          codigoProduto: produtosFiltrados[0].codigo,
          codigoRapido: produtosFiltrados[0].codigoRapido,
          descricaoProduto: produtosFiltrados[0].descricao,
          qtde: 0,
          valorVendaAtual: produtosFiltrados[0].precoVenda
            ? produtosFiltrados[0].precoVenda.toString().replace('.', ',')
            : 0,
          custoAtual: produtosFiltrados[0].custo
            ? produtosFiltrados[0].custo.toString().replace('.', ',')
            : 0,
          ativo: true,
          tipoDesconto: 1,
          unidadeVenda:
            produtoSelecionado && produtoSelecionado.unidadeVenda
              ? produtoSelecionado.unidadeVenda.codigo
              : null
        };
        this.handleCloseModal(objProduto);
      } else {
        showInfo(
          this,
          titulo,
          'Não foram encontrados produtos para a composição'
        );
      }
      this.setState({ produtosFiltrados });
    }
  };

  changeFavoritosDe = newRating => {
    this.setState({
      favoritoDe: newRating
    });
  };

  changeFavoritosAte = newRating => {
    this.setState({
      favoritoAte: newRating
    });
  };

  handleCheckTreeChange = (currentNode, selectedNodes) => {
    var em = [];

    selectedNodes.forEach(n => {
      em.push(n);
    });
    this.setState({ estruturaMercadologica: em });
  };

  toFloatFormattedDisplay(numberString) {
    let number = parseFloat(numberString).toFixed(2);
    return number;
    //let s = number.toLocaleString("pt-br");
    //return number % 1 === 0 ? (s + ",00") : (s);
  }

  handleChangeGrid = (
    id,
    tipoDesconto,
    qtdeProdutoDesconto,
    valorDesconto,
    ativo
  ) => {
    const produtosF = this.props.itensProduto;
    var objProd = produtosF.filter(p => p.codigoProduto === id);

    qtdeProdutoDesconto = qtdeProdutoDesconto.toString().replace(',', '.');
    if (this.props.kit)
      if (valorDesconto)
        valorDesconto = valorDesconto.toString().replace(',', '.');

    var precoVendaAtual = 0;
    var precoVendaKit = 0;

    var valorFinalCalculado = 0;

    if (objProd && !this.props.embalagem) {
      var precoVendaItem = objProd[0].precoVenda
        ? objProd[0].precoVenda
        : objProd[0].valorVendaAtual;
      if (parseInt(this.props.formaReajuste) === 1) {
        valorFinalCalculado =
          (objProd[0].custoAtual.toString().replace(',', '.') /
            (1 - this.props.percentualReajuste / 100)) *
          qtdeProdutoDesconto;
      } else if (parseInt(this.props.formaReajuste) === 2) {
        valorFinalCalculado =
          (1 + this.props.percentualReajuste / 100) *
          objProd[0].custoAtual.toString().replace(',', '.') *
          qtdeProdutoDesconto;
      }
      valorFinalCalculado = this.toFloatFormattedDisplay(valorFinalCalculado);
    }

    let valorVendaAtualKit =
      stringToFloatHelper(qtdeProdutoDesconto) *
      stringToFloatHelper(precoVendaItem);
    valorVendaAtualKit = valorVendaAtualKit.toString().replace('.', ',');

    let valorVendaAtualComposto = valorVendaAtualKit;

    //para o caso de composto
    let valorTotalItem = 0;
    if (qtdeProdutoDesconto) {
      valorTotalItem =
        stringToFloatHelper(qtdeProdutoDesconto) *
        stringToFloatHelper(precoVendaItem);
      valorTotalItem = this.toFloatFormattedDisplay(valorTotalItem);
    }

    precoVendaAtual = valorVendaAtualKit.replace(',', '.');

    if (valorDesconto && qtdeProdutoDesconto)
      switch (parseInt(tipoDesconto)) {
        case 1:
          precoVendaKit =
            precoVendaAtual -
            stringToFloatHelper(qtdeProdutoDesconto) *
              stringToFloatHelper(valorDesconto);
          break;
        case 2:
          precoVendaKit = precoVendaAtual - stringToFloatHelper(valorDesconto);
          break;
        case 3:
          precoVendaKit = stringToFloatHelper(valorDesconto);
          break;
        case 4:
          precoVendaKit =
            stringToFloatHelper(precoVendaAtual) -
            (stringToFloatHelper(precoVendaAtual) *
              stringToFloatHelper(valorDesconto)) /
              100;
          break;

        default:
          precoVendaKit = stringToFloatHelper(precoVendaAtual);
          break;
      }
    else precoVendaKit = stringToFloatHelper(precoVendaAtual);

    precoVendaKit = this.toFloatFormattedDisplay(precoVendaKit);
    precoVendaKit = precoVendaKit.replace('.', ',');

    qtdeProdutoDesconto = qtdeProdutoDesconto.toString().replace('.', ',');
    if (this.props.kit) {
      if (valorDesconto) {
        valorDesconto = valorDesconto.toString().replace('.', ',');
      }
    }
    if (!this.props.embalagem)
      valorFinalCalculado = valorFinalCalculado.replace('.', ',');

    const itens = this.props.itensProduto.map(iP => {
      if (iP.codigoProduto === id) {
        return {
          ...iP,
          qtdeProduto: qtdeProdutoDesconto,
          valorVendaAtualKit,
          valorVendaAtualComposto,
          valorFinal: valorTotalItem,
          tipoDesconto,
          desconto: valorDesconto,
          ativo,
          valorFinalCompostoCalculado: valorFinalCalculado,
          valorFinalKitCalculado: precoVendaKit
        };
      } else {
        return iP;
      }
    });

    var precoVendaTotal = 0;
    var valorCustoTotal = 0;

    for (let i = 0; i < itens.length; i++) {
      const e = itens[i];
      if (e.valorFinal === 'NaN') e.valorFinal = 0;

      var qtdeFormataPonto = e.qtdeProduto.toString().replace(',', '.');

      if (e.ativo) {
        if (this.props.kit) {
          precoVendaTotal += e.valorFinalKitCalculado
            ? parseFloat(e.valorFinalKitCalculado.replace(',', '.'))
            : 0;
        }
        valorCustoTotal += e.custoAtual
          ? e.custoAtual.toString().replace(',', '.') * qtdeFormataPonto
          : 0;
      }
    }
    if (this.props.composto) {
      if (parseInt(this.props.formaReajuste) === 1) {
        precoVendaTotal =
          valorCustoTotal / (1 - this.props.percentualReajuste / 100);
      } else if (parseInt(this.props.formaReajuste) === 2) {
        precoVendaTotal =
          valorCustoTotal * (1 + this.props.percentualReajuste / 100);
      }
    }

    precoVendaTotal = this.toFloatFormattedDisplay(precoVendaTotal);
    valorCustoTotal = parseFloat(valorCustoTotal).toFixed(4);

    if (this.props.composto) {
      this.setState({ valorSugestao: precoVendaTotal, valorCustoTotal });
      itens.valorCustoTotal = valorCustoTotal.replace('.', ',');
      itens.valorSugestao = precoVendaTotal.replace('.', ',');
    }

    if (this.props.kit) {
      itens.precoVenda = precoVendaTotal;
      itens.precoVendaDigitado = precoVendaTotal.replace('.', ',');
      this.setState({ precoVendaDigitado: precoVendaTotal });
    }

    this.setState({ itensProduto: itens });

    this.props.handleItensProdutos(itens);
  };

  handleCloseModal = objProduto => {
    const itensProduto = this.props.itensProduto;
    this.setState({ modalComposicao: false });

    if (objProduto) {
      if (
        itensProduto.filter(iP => iP.codigoProduto === objProduto.codigoProduto)
          .length === 0
      )
        itensProduto.push(objProduto);
    }

    var precoVendaTotal = 0;
    var valorCustoTotal = 0;

    for (let i = 0; i < itensProduto.length; i++) {
      const e = itensProduto[i];
      if (e.valorFinal === 'NaN') e.valorFinal = 0;
      if (!e.qtdeProduto) e.qtdeProduto = 0;
      if (e.ativo) {
        precoVendaTotal += e.valorFinalKitCalculado
          ? parseFloat(e.valorFinalKitCalculado.replace(',', '.'))
          : 0;
        if (this.props.composto)
          valorCustoTotal +=
            e.custoAtual.toString().replace(',', '.') * e.qtdeProduto;
      }
    }

    if (this.props.composto) {
      if (parseInt(this.props.formaReajuste) === 1) {
        precoVendaTotal =
          valorCustoTotal / (1 - this.props.percentualReajuste / 100);
      } else if (parseInt(this.props.formaReajuste) === 2) {
        precoVendaTotal =
          valorCustoTotal * (1 + this.props.percentualReajuste / 100);
      }
    }

    if (this.props.composto) {
      this.setState({
        valorSugestao: precoVendaTotal,
        valorCustoTotal
      });
      itensProduto.valorCustoTotal = valorCustoTotal
        .toString()
        .replace('.', ',');
      itensProduto.valorSugestao = parseFloat(precoVendaTotal)
        .toFixed(2)
        .toString()
        .replace('.', ',');
    }

    if (this.props.kit) {
      itensProduto.precoVenda = precoVendaTotal;
      itensProduto.precoVendaDigitado = precoVendaTotal
        .toString()
        .replace('.', ',');
      this.setState({ precoVendaDigitado: precoVendaTotal });
    }

    this.setState({ itensProduto });
    this.props.handleItensProdutos(itensProduto);
  };

  eliminarProduto = e => {
    var listaProdutosComposicaoAtual = this.props.itensProduto;
    var listaProdutosComposicaoNovo = [];
    var nome = e.currentTarget.className;

    for (let i = 0; i < listaProdutosComposicaoAtual.length; i++) {
      const element = listaProdutosComposicaoAtual[i];
      if (nome.indexOf(element.codigoProduto) === -1)
        listaProdutosComposicaoNovo.push(element);
    }

    var precoVendaTotal = 0;
    var valorCustoTotal = 0;

    for (let i = 0; i < listaProdutosComposicaoNovo.length; i++) {
      const prod = listaProdutosComposicaoNovo[i];
      if (prod.valorFinal === 'NaN') prod.valorFinal = 0;
      if (prod.ativo) {
        if (this.props.kit) {
          precoVendaTotal += prod.valorFinalKitCalculado
            ? parseFloat(prod.valorFinalKitCalculado.replace(',', '.'))
            : 0;
        }
        valorCustoTotal += prod.custoAtual
          ? parseFloat(prod.custoAtual.toString().replace(',', '.'))
          : 0;
      }
    }

    if (this.props.formaReajuste === '1') {
      precoVendaTotal =
        valorCustoTotal / (1 - this.props.percentualReajuste / 100);
    } else if (this.props.formaReajuste === '2') {
      precoVendaTotal =
        valorCustoTotal * (1 + this.props.percentualReajuste / 100);
    }

    precoVendaTotal = this.toFloatFormattedDisplay(precoVendaTotal);
    valorCustoTotal = parseFloat(valorCustoTotal).toFixed(4);

    if (this.props.composto) {
      this.setState({
        valorSugestao: precoVendaTotal,
        valorCustoTotal
      });
      listaProdutosComposicaoNovo.valorCustoTotal = valorCustoTotal.replace(
        '.',
        ','
      );
      listaProdutosComposicaoNovo.valorSugestao = precoVendaTotal.replace(
        '.',
        ','
      );
    }

    if (this.props.kit) {
      listaProdutosComposicaoNovo.precoVenda = precoVendaTotal;
      listaProdutosComposicaoNovo.precoVendaDigitado = precoVendaTotal.replace(
        '.',
        ','
      );
      this.setState({ precoVendaDigitado: precoVendaTotal });
    }

    this.setState({ itensProduto: listaProdutosComposicaoNovo });

    this.props.handleItensProdutos(listaProdutosComposicaoNovo);
  };

  validaTeclaDigitada = e => {
    if (
      e.keyCode !== 8 &&
      e.keyCode !== 37 &&
      e.keyCode !== 39 &&
      e.keyCode !== 46
    ) {
      const re = /[0-9,]+/g;
      if (!re.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  getDecimalScaleByUnit = unit => {
    const list = this.props.unidadesMedida;
    if (list && unit) {
      let result = 0;
      list.forEach(u => {
        if (sameInt(u.codigo, unit)) {
          result = u.numeroCasasDecimais;
          return; // finaliza o forEach
        }
      });
      return result;
    }
    return 0;
  };

  render() {
    const { searchText, modalComposicao } = this.state;

    return (
      <main className="main">
        <section className="section-container">
          {
            <>
              <SectionContainer title="Filtros">
                <SectionContent title="">
                  <div className="row">
                    <div className="col-12">
                      <InputText
                        value={searchText}
                        name="searchText"
                        tabindex={1}
                        icone="icon-lx-search"
                        placeholder="Search"
                        help={searchText ? 'Clique na "lupa" para buscar' : ''}
                        onIconeClick={() => {
                          this.handlePesquisar();
                        }}
                        onChange={this.handleSearchTextChange}
                        onKeyDown={
                          searchText
                            ? event => {
                                if (event.keyCode === 13)
                                  this.handlePesquisar();
                                else if (event.keyCode === 27)
                                  this.setState({
                                    searchText: this.state.lastSearch
                                  });
                              }
                            : null
                        }
                      />
                    </div>
                  </div>
                  <ModalComposicao
                    active={modalComposicao}
                    handleCloseModal={this.handleCloseModal}
                    produtosFiltrados={this.state.produtosFiltrados}
                    itensProduto={this.props.itensProduto}
                    idBase={this.props.idBase}
                  />

                  <ReactTable
                    defaultPageSize={5}
                    data={this.props.itensProduto}
                    previousText="Anterior"
                    nextText="Próximo"
                    loadingText="Carregando registros..."
                    noDataText="Sem registros para exibir"
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    columns={[
                      {
                        accessor: 'codigoProduto',
                        Header: 'Código',
                        width: '250'
                      },
                      {
                        accessor: 'descricaoProduto',
                        Header: 'Produto',
                        width: '200',
                        style: { 'white-space': 'unset' },
                        filterable: false
                      },
                      {
                        accessor: 'custoAtual',
                        Header: 'Custo atual',
                        width: '100',
                        style: { 'white-space': 'unset' },
                        filterable: false,
                        show: this.props.composto
                      },
                      {
                        accessor: 'unidadeVenda',
                        show: false
                      },
                      {
                        accessor: 'qtdeProduto',
                        Header: 'Quantidade',
                        width: '150',
                        style: { 'white-space': 'unset' },
                        Cell: props => {
                          return (
                            <InputText
                              className="input"
                              value={props.row.qtdeProduto}
                              name="qtdeProduto"
                              type="number"
                              maxlength={9}
                              //decimalScale={this.props.composto ? 2 : 0}
                              decimalScale={this.getDecimalScaleByUnit(
                                props.row.unidadeVenda
                              )}
                              allowNegative={false}
                              onChange={event => {
                                this.handleChangeGrid(
                                  props.original.codigoProduto,
                                  props.row.tipoDesconto,
                                  event.value,
                                  props.row.desconto,
                                  props.row.ativo
                                );
                              }}
                              isAllowed={values => {
                                const { formattedValue, floatValue } = values;
                                return (
                                  formattedValue === '' ||
                                  floatValue <= 99999.999
                                );
                              }}
                            />
                          );
                        }
                      },
                      {
                        accessor: 'valorVendaAtualComposto',
                        Header: 'Valor Venda atual',
                        width: '150',
                        style: { 'white-space': 'unset' },
                        filterable: false,
                        show: this.props.composto
                      },
                      {
                        accessor: 'valorVendaAtualKit',
                        Header: 'Valor Venda atual',
                        width: '150',
                        style: { 'white-space': 'unset' },
                        filterable: false,
                        show: this.props.kit
                      },
                      {
                        accessor: 'valorFinalCompostoCalculado',
                        Header: 'Valor final',
                        width: '150',
                        style: { 'white-space': 'unset' },
                        filterable: false,
                        show: this.props.composto
                      },
                      {
                        accessor: 'tipoDesconto',
                        Header: 'Tipo de desconto',
                        width: '260',
                        style: { 'white-space': 'unset' },
                        filterable: false,
                        show: this.props.kit,
                        Cell: props => {
                          return (
                            <Select
                              label=""
                              name=""
                              value={props.row.tipoDesconto}
                              onChange={event => {
                                this.handleChangeGrid(
                                  props.original.codigoProduto,
                                  event.target.value,
                                  props.row.qtdeProduto,
                                  props.row.desconto,
                                  props.row.ativo
                                );
                              }}
                              options={[
                                { value: '1', label: 'Valor sobre unitário' },
                                { value: '2', label: 'Valor sobre total' },
                                { value: '3', label: 'Fixo' },
                                { value: '4', label: 'Percentual' }
                              ]}
                            />
                          );
                        }
                      },
                      {
                        accessor: 'desconto',
                        Header: 'Desconto',
                        width: '100',
                        style: { 'white-space': 'unset' },
                        filterable: false,
                        show: this.props.kit,
                        Cell: props => {
                          return (
                            <InputText
                              className="input"
                              type="number"
                              decimalScale={2}
                              allowNegative={false}
                              value={props.row.desconto}
                              name="desconto"
                              maxlength="20"
                              onChange={event => {
                                this.handleChangeGrid(
                                  props.original.codigoProduto,
                                  props.row.tipoDesconto,
                                  props.row.qtdeProduto,
                                  event.value,
                                  props.row.ativo
                                );
                              }}
                            />
                          );
                        }
                      },
                      {
                        accessor: 'valorFinalKitCalculado',
                        Header: 'Valor final',
                        width: '150',
                        style: { 'white-space': 'unset' },
                        filterable: false,
                        show: this.props.kit
                      },
                      {
                        accessor: 'ativo',
                        Header: 'Ativo',
                        width: 80,
                        filterable: false,
                        Cell: props => {
                          return (
                            <Checkbox label="">
                              <Checkitem
                                name={props.row.ativo}
                                checked={props.row.ativo}
                                onChange={event => {
                                  this.handleChangeGrid(
                                    props.original.codigoProduto,
                                    props.row.tipoDesconto,
                                    props.row.qtdeProduto,
                                    props.row.desconto,
                                    event.target.checked
                                  );
                                }}
                              />
                            </Checkbox>
                          );
                        },
                        show: this.props.kit || this.props.composto
                      },
                      {
                        accessor: 'acao',
                        Header: 'Excluir',
                        width: 75,
                        filterable: false,
                        Cell: ({ row }) => {
                          return (
                            <Botao
                              ic
                              className={row.codigoProduto}
                              icon={'icon-lx-trash'}
                              onClick={this.eliminarProduto}
                            />
                          );
                        }
                      }
                    ]}
                  />
                </SectionContent>
                <SectionContent visible={this.props.composto}>
                  <div className="row">
                    <div className="col-4">
                      <InputText
                        label="Valor custo total"
                        value={this.props.itensProduto.valorCustoTotal}
                        name="valorCustoTotal"
                        tabindex={2}
                        disabled
                      />
                    </div>

                    <div className="col-4">
                      <InputText
                        label="Valor sugestão"
                        value={this.props.itensProduto.valorSugestao}
                        name="valorSugestao"
                        tabindex={3}
                        disabled
                      />
                    </div>

                    <div className="col-4">
                      <InputText
                        label="Preço de venda"
                        onKeyDown={this.validaTeclaDigitada}
                        value={this.props.itensProduto.precoVendaDigitado}
                        name="precoVendaDigitado"
                        tabindex={4}
                        onChange={e => {
                          var itensProduto = this.props.itensProduto;
                          this.setState({ precoVendaDigitado: e.target.value });
                          itensProduto.precoVendaDigitado = e.target.value;
                          this.setState({ itensProduto });
                          this.props.handleItensProdutos(itensProduto);
                        }}
                      />
                    </div>
                  </div>
                </SectionContent>

                <SectionContent visible={this.props.kit}>
                  <div className="col-5">
                    <div className="col-4">
                      <InputText
                        label="Preço de venda"
                        value={this.props.itensProduto.precoVendaDigitado}
                        name="precoVendaDigitado"
                        tabindex={4}
                        disabled
                      />
                    </div>
                  </div>
                </SectionContent>
              </SectionContainer>
              {tagAlert(this)}
            </>
          }
        </section>
      </main>
    );
  }
}

Form = withRouter(Form);

class ComposicaoProduto extends Component {
  render() {
    return (
      <main className="main">
        <section className="section-container">
          {
            <Form
              kit={this.props.kit}
              composto={this.props.composto}
              embalagem={this.props.embalagem}
              handleItensProdutos={this.props.handleItensProdutos}
              itensProduto={this.props.itensProduto}
              precoVendaProduto={this.props.precoVenda}
              formaReajuste={this.props.formaReajuste}
              percentualReajuste={this.props.percentualReajuste}
              almoxarifadosProduto={this.props.almoxarifadosProduto}
              unidadesMedida={this.props.unidadesMedida}
              idBase={this.props.idBase}
            />
          }
        </section>
      </main>
    );
  }
}

export class ModalComposicao extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);
    this.state = { objProduto: {} };
  }

  adicionarItem = async objProd => {
    const { handleCloseModal } = this.props;
    if (sameInt(objProd.id, this.props.idBase)) {
      showError(
        this,
        titulo,
        'Produto base não pode ser adicionado como item da composição'
      );
      return;
    }

    const { data: produtoSelecionado } = await getSelecionarProduto(objProd.id);

    const objProduto = {
      id: objProd.id,
      codigoProduto: objProd.codigo,
      codigoRapido: objProd.codigoRapido,
      descricaoProduto: objProd.descricao,
      qtde: 0,
      valorVendaAtual: objProd.precoVenda,
      custoAtual: objProd.custo
        ? objProd.custo.toString().replace('.', ',')
        : 0,
      ativo: true,
      tipoDesconto: 1,
      unidadeVenda:
        produtoSelecionado && produtoSelecionado.unidadeVenda
          ? produtoSelecionado.unidadeVenda.codigo
          : null
    };

    handleCloseModal(objProduto);
  };

  handleTableClick = async (state, rowInfo, column, instance, e) => {
    if (rowInfo) {
      // se clicar numa linha vazia, não faz nada
      await this.adicionarItem(rowInfo.original);
    }
  };

  render() {
    const { active, handleCloseModal, produtosFiltrados } = this.props;
    const { password } = this.state;

    return (
      <div>
        <Modal
          className={`${active ? 'active' : null} modal-filter`}
          onClose={() => handleCloseModal(false)}
        >
          <ModalHeader title="Pesquisar Produtos" subtitle="" />

          <ModalBody>
            {/* Conteúdo principal */}
            <SectionContent title="Selecione um produto">
              <ReactTable
                defaultPageSize={5}
                data={produtosFiltrados}
                columns={[
                  {
                    accessor: 'codigo',
                    Header: 'Código',
                    width: 120
                  },
                  {
                    accessor: 'unidadeVenda',
                    show: false
                  },
                  {
                    accessor: 'codigoRapido',
                    Header: 'Código Rápido',
                    width: 120
                  },
                  {
                    accessor: 'descricao',
                    Header: 'Produto',
                    width: 200,
                    style: { 'white-space': 'unset' },
                    filterable: false
                  },
                  {
                    accessor: 'descricaoSubtipo',
                    Header: 'Subtipo',
                    width: 100,
                    style: { 'white-space': 'unset' },
                    filterable: false
                  },
                  {
                    accessor: 'descricaoPrecoVenda',
                    Header: 'Preço de Venda (R$)',
                    width: 150,
                    style: { 'white-space': 'unset' },
                    filterable: false,
                    Cell: props => (
                      <div>{toFloatFormattedDisplay(String(props.value))}</div>
                    )
                  },
                  {
                    accessor: 'situacao',
                    Header: 'Situação',
                    width: 100
                  }
                ]}
                getTdProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      if (this.handleTableClick) {
                        this.handleTableClick(
                          state,
                          rowInfo,
                          column,
                          instance,
                          e
                        );
                      }

                      if (handleOriginal) {
                        handleOriginal();
                      }
                    }
                  };
                }}
                previousText="Anterior"
                nextText="Próximo"
                loadingText="Carregando registros..."
                noDataText="Sem registros para exibir"
                pageText="Página"
                ofText="de"
                rowsText="registros"
              />
            </SectionContent>
          </ModalBody>

          <ModalFooter>
            <Botao
              secondary
              icon="icon-lx-close"
              title={'Cancelar'}
              onClick={() => {
                handleCloseModal(false, password);
              }}
            />
          </ModalFooter>
        </Modal>
        {tagAlert(this)}
      </div>
    );
  }
}

ComposicaoProduto = withRouter(ComposicaoProduto);
export { ComposicaoProduto };
