import { locale } from 'devextreme/localization';
import * as _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectConsignadoComodatoSharedData } from '../../../../../redux/slices/consignadoComodato/SharedData.slice';
import { selectNovaOs } from '../../../../../redux/slices/consignadoComodato/novaOsSlice';
import { formatStringToCurrency, maskedCnpjCpf } from '../../../../../shared/utils/Utils';
import { getConsultaTelefoneEmpresa } from '../../../../relatorios/empresa/ConsultaEmpresa.service';
import { getConsultaFranquia } from '../../../../relatorios/franquia/ConsultaFranquia.service';
import { PrintStyle } from './PrintStyles';

export function PaginaPedido({ styles }) {
  moment.locale('pt-br');
  locale('pt-br');
  const { values } = useSelector(selectNovaOs);
  const empresa = useSelector(selectConsignadoComodatoSharedData).empresa;
  const lastElement = useRef(null);

  const sty = new PrintStyle();

  const [franq, setUsers] = useState([]);
  const [fone, setFone] = useState([]);

  useEffect(() => {
    getConsultaFranquia().then(function(franq) {
      setUsers(franq.data);
    });

    getConsultaTelefoneEmpresa().then(function(fon) {
      setFone(fon.data);
    });
  }, [values.pedidos]);

  const valorRecebido = (pedidos = []) => {
    let valor = 0;
    pedidos.forEach(pedido => {
      (pedido.pagamentos || []).forEach(pagamento => {
        valor += pagamento.vencimento !== 'A RECEBER' ? pagamento.valorPagamento : 0;
      });
    });
    return valor;
  };

  const ns = () => {
    const idx = values.pedidos.findIndex(x => [3, 8].includes(x.tipoPedido.id));
    if (idx === -1) return '';
    let ret = '';
    var its = values.pedidos[idx].itens.filter(x => x.series && x.series.length);
    for (const it of its) {
      ret += `${it.produto.descricao} - ${it.series.map(x => x.serie).join(', ')}\n`;
    }
    return ret;
  };

  return (
    <>
      {values.cliente && empresa && (
        <div id="impressaoPedido">
          <section className={`${styles.sheet}`} style={sty.section}>
            <div className="text-center">{empresa.nomeFantasia}</div>
            <div className="text-center">CNPJ: {formatCNPJ(empresa.cnpj)}</div>
            <div className="text-center">
              {empresa.endereco.logradouro}, {empresa.endereco.numero} - {empresa.endereco.bairro} - {empresa.endereco.localidade.descricao} -{' '}
              {empresa.endereco.unidadeFederativa.sigla} - {formatCEP(empresa.endereco.cep)}
            </div>
            <div className="text-center">{fone}</div>
            <br />
            <div style={sty.divHeader}>
              <h1 className="ml-1 p-0 mb-0" style={sty.h1}>
                Número do Pedido: <small style={{ ...sty.h1Small }}>{values.numeroOs.toString().padStart(6, '0')}</small>
              </h1>
              <h1 className="ml-1 p-0 mb-0 float-right" style={{ ...sty.h1 }}>
                Data do Pedido: <small style={{ ...sty.h1Small }}>{moment(new Date(values.dataPedido)).format('DD/MM/YYYY')}</small>
              </h1>
            </div>
            <Row>
              <Col xs={9} className="p-0">
                <Row className="m-0 p-0">
                  <Col xs={12} className="pt-0 pb-0 mt-0 mb-0">
                    <h3 className="mt-3 mb-0" style={sty.h3}>
                      <b>DADOS DO CLIENTE:</b>
                    </h3>
                  </Col>
                  <Col xs={12} className="pt-0 pb-0 mt-0 mb-0">
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      Nome do Cliente: {values.cliente.nome.toUpperCase()}
                    </p>
                  </Col>
                  <Col xs={6}>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      CPF/CNPJ: {maskedCnpjCpf(values.cliente.cnpjCpf)}
                    </p>
                  </Col>
                  <Col xs={6}>
                    {values.cliente.inscricaoEstadual && (
                      <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                        InscricaoEstadual: {maskedCnpjCpf(values.cliente.inscricaoEstadual)}
                      </p>
                    )}
                  </Col>
                  <Col xs={12}>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      Celular: {values.cliente.contato.dddCel} {values.cliente.contato.celular}
                    </p>
                  </Col>
                </Row>
                <hr className="ml-3" />
                <Row className="m-0 p-0">
                  <Col xs={12} className="pt-0 pb-0">
                    <h3 className="mt-1 mb-0 pb-0" style={sty.h3}>
                      <b>ENDEREÇO DE ENTREGA:</b>
                    </h3>
                  </Col>
                  <Col xs={12}>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      Endereço: {values.enderecoEntrega.logradouro.toUpperCase()}, {values.enderecoEntrega.numero} {values.enderecoEntrega.complemento}
                    </p>
                  </Col>
                  <Col xs={4}>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      Bairro: {values.enderecoEntrega.bairro}
                    </p>
                  </Col>
                  <Col xs={4}>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      Município: {values.enderecoEntrega.municipio.descricao}
                    </p>
                  </Col>
                  <Col xs={1} className={`p-0`}>
                    <p style={sty.p}>UF: {values.enderecoEntrega.uf.sigla}</p>
                  </Col>
                  <Col xs={3}>
                    <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                      CEP: {`${values.enderecoEntrega.cep.substring(0, 5)}-${values.enderecoEntrega.cep.substring(5)}`}
                    </p>
                  </Col>
                </Row>
                {values.enderecoRecolha && (
                  <Row className="m-0 p-0">
                    <Col xs={12} className="pt-0 pb-0">
                      <h3 className="mt-1 mb-0 pb-0" style={sty.h3}>
                        <b>ENDEREÇO DE RECOLHA:</b>
                      </h3>
                    </Col>
                    <Col xs={12}>
                      <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                        Endereço: {values.enderecoRecolha.logradouro.toUpperCase()}, {values.enderecoRecolha.numero} {values.enderecoRecolha.complemento}
                      </p>
                    </Col>
                    <Col xs={4}>
                      <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                        Bairro: {values.enderecoRecolha.bairro}
                      </p>
                    </Col>
                    <Col xs={4}>
                      <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                        Município: {values.enderecoRecolha.municipio.descricao}
                      </p>
                    </Col>
                    <Col xs={1} className={`p-0`}>
                      <p style={sty.p}>UF: {values.enderecoRecolha.uf.sigla}</p>
                    </Col>
                    <Col xs={3}>
                      <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
                        CEP: {`${values.enderecoRecolha.cep.substring(0, 5)}-${values.enderecoRecolha.cep.substring(5)}`}
                      </p>
                    </Col>
                  </Row>
                )}

                {franq === 5 && dadosEvento()}

                <hr className="ml-3" />
                <Row className="m-0 p-0">
                  <Col xs={12}>
                    <h3 className="mt-3" style={sty.h3}>
                      <b>ITENS DO PEDIDO:</b>
                    </h3>
                  </Col>
                  <Col xs={12} className="pr-0">
                    <table className="w-100" style={sty.borderSolid}>
                      <thead>
                        <tr style={sty.borderSolid}>
                          <th width="50%" className="text-center pl-1 pr-1" style={sty.tableTh}>
                            Código - Descricao do Produto
                          </th>
                          <th width="14%" className="text-center pl-1 pr-1" style={sty.tableTh}>
                            Valor
                          </th>
                          <th width="13%" className="text-center pl-1 pr-1" style={sty.tableTh}>
                            Quantidade
                          </th>

                          {franq === 5 && (
                            <>
                              <th width="8%" className="text-center pl-1 pr-1" style={sty.tableTh}>
                                Retorno
                              </th>
                            </>
                          )}
                          <th width="13%" className="text-center pl-1 pr-1" style={sty.tableTh}>
                            Preço Unitário
                          </th>
                          <th width="10%" className="text-center pl-1 pr-1" style={sty.tableTh}>
                            Total
                          </th>
                        </tr>
                      </thead>
                      {values.pedidos.map(
                        (pedido, pIdx) =>
                          [1, 2, 3, 7, 8].includes(pedido.tipoPedido.id) && (
                            <tbody key={pIdx}>
                              <tr style={sty.borderSolid}>
                                <td colSpan={5} style={sty.borderSolid}>
                                  <p className="ml-3 pr-1" style={sty.tdP}>
                                    <b>{pedido.tipoPedido.nome}</b>
                                    <strong>
                                      {'  '}
                                      {pedido.vtexOrderId && `(${pedido.vtexOrderId})`}
                                    </strong>
                                  </p>
                                </td>
                              </tr>
                              {pedido.itens.map((item, iIdx) => (
                                <tr key={iIdx}>
                                  <td className="text-left" style={sty.borderSolid}>
                                    <p className="ml-3 pr-1" style={sty.tdP}>{`${item.produto.codigo} - ${item.produto.descricao}`}</p>
                                  </td>
                                  <td className="text-right" style={sty.borderSolid}>
                                    <p className="pr-1" style={sty.tdP}>{`${formatStringToCurrency(pedido.tipoPedido.id === 8 ? item.produto.precoVenda : item.valorAtivo)}`}</p>
                                  </td>
                                  <td className="text-right" style={sty.borderSolid}>
                                    <p className="pr-1" style={sty.tdP}>{`${item.quantidade}`}</p>
                                  </td>

                                  {franq === 5 && (
                                    <>
                                      <td className="text-right" style={sty.borderSolid}>
                                        <p className="pr-1" style={sty.tdP}></p>
                                      </td>
                                    </>
                                  )}

                                  <td className="text-right" style={sty.borderSolid}>
                                    <p className="pr-1" style={sty.tdP}>{`${formatStringToCurrency(pedido.tipoPedido.id === 8 ? item.valorAtivo : item.valorProduto)}`}</p>
                                  </td>
                                  <td className="text-right" style={sty.borderSolid}>
                                    <p className="pr-1" style={sty.tdP}>{`${
                                      [3, 8].includes(pedido.tipoPedido.id) ? formatStringToCurrency(item.totalAtivos) : formatStringToCurrency(item.totalProdutos)
                                    }`}</p>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ),
                      )}
                      <tfoot>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            &nbsp;
                          </td>
                        </tr>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            <p className="pr-1" style={sty.tdP}>
                              Total Consignado:
                            </p>
                          </td>
                          <td className="text-right pr-1" style={sty.borderSolid}>
                            <p style={sty.tdP}>
                              {formatStringToCurrency(_.sumBy(values.pedidos.filter(p => p.tipoPedido.id === 2) || [{ totalProdutos: 0 }], x => x.totalProdutos))}
                            </p>
                          </td>
                        </tr>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            <p className="pr-1" style={sty.tdP}>
                              Frete:
                            </p>
                          </td>
                          <td className="text-right pr-1" style={sty.borderSolid}>
                            <p style={sty.tdP}>{formatStringToCurrency(values.valorFrete)}</p>
                          </td>
                        </tr>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            <p className="pr-1" style={sty.tdP}>
                              Acréscimo:
                            </p>
                          </td>
                          <td className="text-right pr-1" style={sty.borderSolid}>
                            <p style={sty.tdP}>{formatStringToCurrency(values.acrescimo)}</p>
                          </td>
                        </tr>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            <p className="pr-1" style={sty.tdP}>
                              Desconto:
                            </p>
                          </td>
                          <td className="text-right pr-1" style={sty.borderSolid}>
                            <p style={sty.tdP}>{formatStringToCurrency(values.desconto)}</p>
                          </td>
                        </tr>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            <p className="pr-1" style={sty.tdP}>
                              Total do Pedido:
                            </p>
                          </td>
                          <td className="text-right pr-1" style={sty.borderSolid}>
                            <p style={sty.tdP}>
                              {formatStringToCurrency(
                                values.totalGeral
                              )}
                            </p>
                          </td>
                        </tr>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            <p className="pr-1" style={sty.tdP}>
                              Total do Recebido:
                            </p>
                          </td>
                          <td className="text-right pr-1" style={sty.borderSolid}>
                            <p style={sty.tdP}>{formatStringToCurrency(valorRecebido(values.pedidos))}</p>
                          </td>
                        </tr>
                        <tr style={sty.borderSolid}>
                          <td colSpan={5} className="text-right" style={sty.borderSolid}>
                            <p className="pr-1" style={sty.tdP}>
                              Pendente:
                            </p>
                          </td>
                          <td className="text-right pr-1" style={sty.borderSolid}>
                            <p style={sty.tdP}>{formatStringToCurrency(values.aReceber)}</p>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </Col>
                </Row>
                <br />

                <hr className="ml-3" />

                {franq === 5 && newPage()}

                <Row>
                  <Col xs={4} className="pt-3"></Col>
                  <Col xs={4} className="pt-3 text-center">
                    <hr></hr>
                    <p ref={lastElement} id="lastElement" className="ml-3" style={sty.p}>
                      Visto do Cliente
                    </p>
                  </Col>
                  <Col xs={4} className="pt-3"></Col>
                </Row>
              </Col>
              <Col xs={3} className="pt-3">
                <p style={sty.p}>Pagamento:</p>
                {values.pedidos
                  .filter(x => x.pagamentos && x.pagamentos.length && _.sumBy(x.pagamentos, p => p.valorPagamento) > 0)
                  .map((pedido, pIdx) => (
                    <div key={pIdx}>
                      {(pedido.pagamentos || []).map((pgt, pgIdx) => (
                        <p key={pgIdx} style={sty.p}>
                          <small className="ml-3 float-left">
                            <b>{(pgt.pagamento || {}).descricao || ''}</b>&nbsp;({pgt.vencimento}):
                          </small>
                          <small className="ml-3 float-right">
                            <b>{formatStringToCurrency(pgt.valorPagamento)}</b>
                          </small>
                        </p>
                      ))}
                    </div>
                  ))}
                <br />
                <hr className="ml-0" />
                <p style={sty.p}>Notas Fiscais:</p>
                {(values.pedidos || [])
                  .filter(x => x.numeroNF)
                  .map((pedido, pIdx) => (
                    <div key={`notas_${pIdx}`}>
                      <p className="ml-3 float-" style={sty.p}>{`${pedido.numeroNF} - ${tipoRemessaStr(pedido.tipoPedido.id)}`}</p>
                    </div>
                  ))}

                {franq === 5 && dadosCBE()}
              </Col>
            </Row>
          </section>
        </div>
      )}
    </>
  );

  function tipoRemessaStr(id) {
    switch (id) {
      default:
        return 'Venda';
      case 2:
        return 'Remessa Consignado';
      case 3:
        return 'Remessa Comodato';
      case 4:
        return 'Retorno Consignado';
      case 5:
        return 'Retorno Comodato';
      case 6:
        return 'Venda em Consignação';
      case 7:
        return 'Venda em Bonificação';
      case 8:
        return 'Locação';
      case 9:
        return 'Retorno de Locação';
    }
  }

  function newPage() {
    return (
      <>
        <br />
        <h1 className="mt-3 ml-3" style={sty.h1}>
          <strong style={sty.h1Strong}>INFORMAÇÕES ADICIONAIS</strong>
        </h1>
        <p className="ml-3" style={sty.p}>
          {values.informacaoAdicional || ''}
        </p>
        <br />
        <br />
        <hr className="ml-3" />
        <br />
        <h1 className="mt-3 ml-3" style={sty.h1}>
          <strong style={sty.h1Strong}>CHECKLIST EQUIPE DE MONTAGEM:</strong>
        </h1>
        <p className="ml-3" style={sty.p}>
          NÃO TRANSPORTAR A MÁQUINA NA HORIZONTAL(DEITADA).
        </p>
        <p className="ml-3" style={sty.p}>
          O pedido foi retirado na loja? ( )SIM ( )NÃO
        </p>
        <p className="ml-3" style={sty.p}>
          Pressão da válvula de CO2 foi verificada? ( )SIM ( )NÃO
        </p>
        <p className="ml-3" style={sty.p}>
          Há uma tomada exclusiva para chopeira? ( )SIM ( )NÃO
        </p>
        <p className="ml-3" style={sty.p}>
          Qual a tensão média da rede elétrica?<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>(Tensões abaixo de 215 podem prejudicar o desempenho das chopeiras.)
        </p>
        <p className="ml-3" style={sty.p}>
          O Local onde foi instalado o equipamento é coberto? ( )SIM ( )NÃO
        </p>
        <p className="ml-3" style={sty.p}>
          Foram verificadas as mangueiras e conexões para ver se há vazamentos? ( )SIM ( )NÃO
        </p>
        <p className="ml-3" style={sty.p}>
          A chopeira acompanha dispenser? ( )SIM ( )NÃO
        </p>
        <p className="ml-3" style={sty.p}>
          Foi deixado algum material que não foi comodato? ( )SIM ( )NÃO
        </p>
        <hr className="ml-3" />
        <br />
        <br />
        <br />
      </>
    );
  }

  function dadosCBE() {
    return (
      <>
        <hr className="ml-0" />
        <p style={sty.p}>Entregue:</p>
        <br />
        <br />
        <br />

        <hr className="ml-0" />
        <p style={sty.p}>Nº de Série:</p>
        {ns().length > 0 ? (
          <span style={{ fontSize: '8px' }}>{ns()}</span>
        ) : (
          <>
            <br />
            <br />
            <br />
          </>
        )}
        <hr className="ml-0" />
        <p style={sty.p}>Carga de Gás:</p>
        <br />
        <br />
        <br />

        <hr className="ml-0" />
        <p style={sty.p}>Saída:</p>
        <br />
        <br />
        <br />
        <hr className="ml-0" />
        <p style={sty.p}>Recolha:</p>
        <br />
        <br />
        <br />
        <hr className="ml-0" />
        <p style={sty.p}>Entrada:</p>
        <br />
        <br />
        <br />
        <hr className="ml-0" />
        <p style={sty.p}>Pós Venda:</p>
        <br />
        <br />
        <br />
        <hr className="ml-0" />
      </>
    );
  }

  function dadosEvento() {
    return (
      <>
        <hr className="ml-3" />
        <Row className="m-0 p-0">
          <Col xs={12}>
            <h3 className="mt-1 mb-0" style={sty.h3}>
              <b>DADOS DO EVENTO:</b>
            </h3>
          </Col>
          <Col xs={4}>
            <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
              <b>Entrega/Retirada:</b>{' '}
              <p style={sty.p}>
                {moment(new Date(values.dataEntregaRetirada)).format('DD/MM/YYYY')} de {values.entregaRetiradaHoraDe} até {values.entregaRetiradaHoraAte}
              </p>
            </p>
          </Col>
          <Col xs={4}>
            <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
              <b>Data do Evento:</b> <p style={sty.p}>{moment(new Date(values.dataEvento)).format('DD/MM/YYYY HH:mm')}</p>
            </p>
          </Col>
          <Col xs={4}>
            <p className="mt-0 mb-0 pt-0 pb-0" style={sty.p}>
              <b>Recolha/Devolução:</b>{' '}
              <p style={sty.p}>
                {moment(new Date(values.dataRecolhaDevolucao)).format('DD/MM/YYYY')} de {values.recolhaDevolucaoHoraDe} até&nbsp;
                {values.recolhaDevolucaoHoraAte}
              </p>
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

function formatCNPJ(data) {
  var result = data.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');

  return result;
}

function formatCEP(data) {
  var cep = data
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');

  return cep;
}
