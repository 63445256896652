import axios from 'axios';
import { getToken } from '../../../authentication/Auth';
import { BASE_URL_GATEWAY } from '../../../shared/constants/GatewayUrlConstant';

export const searchProdutoService = {
    find
};

const urlLinxCore = axios.create({
    baseURL: `${BASE_URL_GATEWAY}/core/api/v1`,
});

urlLinxCore.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

async function find(searchTerm) {
    const response = await urlLinxCore.post('/produto/listarComFiltro', { query: { descricao: searchTerm } });
    return response.data.data;
}