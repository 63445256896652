import moment from 'moment';
import { passarOrigensSelecionadasParaTexto } from './PassarOrigensSelecionadasParaTexto';
import { passarStatusSelecionadosParaTexto } from './PassarStatusSelecionadosParaTexto';
import { passarStringArraySelecionadosParaTexto } from './PassarStringArraySelecionadosParaTexto';
import { passarTipoOperacaoSelecionadosParaTexto } from './PassarTipoOperacaoSelecionadosParaTexto';

export function fabricarFiltroString(values, origens, pagamentos = [], tiposFrete = [], tiposPedidos = []) {
  let filter = '';
  if ((values || {}).numeroOs) {
    filter += `${filter.length ? ', ' : ''}Nº Pedido: ${values.numeroOs.toString().replace(/\D/g, '')} ou ID da VTEX contendo o valor ${
      (values || {}).numeroOs
    }`;
  }

  if ((values || {}).origens) {
    filter += `${filter.length ? ', ' : ''}Orige${values.origens.indexOf('_') === -1 ? 'm' : 'ns'}: ${passarOrigensSelecionadasParaTexto(
      values.origens,
      origens,
    )}`;
  }
  if ((values || {}).pagamentos) {
    filter += `${filter.length ? ', ' : ''}Pagamento${values.pagamentos.indexOf('_') === -1 ? '' : 's'}: ${passarStringArraySelecionadosParaTexto(
      values.pagamentos,
      pagamentos,
    )}`;
  }
  if ((values || {}).tiposFrete) {
    filter += `${filter.length ? ', ' : ''}Tipos de Frete${values.tiposFrete.indexOf('_') === -1 ? '' : 's'}: ${passarStringArraySelecionadosParaTexto(
      values.tiposFrete,
      tiposFrete,
    )}`;
  }
  if ((values || {}).tiposPedidos) {
    filter += `${filter.length ? ', ' : ''}Tipos de Pedido${values.tiposPedidos.indexOf('_') === -1 ? '' : 's'}: ${passarStringArraySelecionadosParaTexto(
      values.tiposPedidos,
      tiposPedidos,
    )}`;
  }

  if ((values || {}).produto) {
    filter += `${filter.length ? ', ' : ''}Produto: ${values.produto.descricao}`;
  }
  if ((values || {}).status && (values || {}).status.length) {
    filter += `${filter.length ? ', ' : ''}Status: ${passarStatusSelecionadosParaTexto(values.status)}`;
  }
  if ((values || {}).statusType && (values || {}).statusType.length) {
    filter += `${filter.length ? ', ' : ''}Operação: ${passarTipoOperacaoSelecionadosParaTexto(values.statusType)}`;
  }
  if ((values || {}).saldoPendente && (values || {}).saldoPendente.length) {
    filter += `${filter.length ? ', ' : ''}${'Possui saldo em aberto'}`;
  }
  if ((values || {}).consignadoPendente && (values || {}).consignadoPendente.length) {
    filter += `${filter.length ? ', ' : ''}${'Possui consignação em aberto'}`;
  }
  if ((values || {}).comodatoPendente && (values || {}).comodatoPendente.length) {
    filter += `${filter.length ? ', ' : ''}${'Possui comodato em aberto'}`;
  }
  if ((values || {}).locacaoPendente && (values || {}).locacaoPendente.length) {
    filter += `${filter.length ? ', ' : ''}${'Possui locação em aberto'}`;
  }

  if ((values || {}).dadosCliente) {
    filter += `${filter.length ? ', ' : ''}Nome do cliente: ${values.dadosCliente}`;
  }

  if ((values || {}).numeroNf) {
    filter += `${filter.length ? ', ' : ''}Nº NF-e: ${values.numeroNf.toString().replace(/\D/g, '')}`;
  }
  if ((values || {}).dataPedidoDe && !(values || {}).dataPedidoAte) {
    filter += `${filter.length ? ', ' : ''}Data do Pedido a partir de ${moment((values || {}).dataPedidoDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataPedidoDe,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataPedidoDe && (values || {}).dataPedidoAte) {
    filter += `${filter.length ? ', ' : ''}Data do Pedido a partir de ${moment((values || {}).dataPedidoDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataPedidoAte,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataEventoDe && !(values || {}).dataEventoAte) {
    filter += `${filter.length ? ', ' : ''}Data do Evento a partir de ${moment((values || {}).dataEventoDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataEventoDe,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataEventoDe && (values || {}).dataEventoAte) {
    filter += `${filter.length ? ', ' : ''}Data do Evento a partir de ${moment((values || {}).dataEventoDe).format('DD-MM-YYYY HH:mm')} até ${moment(
      (values || {}).dataEventoAte,
    ).format('DD-MM-YYYY HH:mm')}`;
  }
  if ((values || {}).dataEntregaRetiradaDe && !(values || {}).dataEntregaRetiradaAte) {
    filter += `${filter.length ? ', ' : ''}Data da Entrega/Retirada ${moment((values || {}).dataEntregaRetiradaDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataEntregaRetiradaDe,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataEntregaRetiradaDe && (values || {}).dataEntregaRetiradaAte) {
    filter += `${filter.length ? ', ' : ''}Data da Entrega/Retirada ${moment((values || {}).dataEntregaRetiradaDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataEntregaRetiradaAte,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataRecolhaDevolucaoDe && !(values || {}).dataRecolhaDevolucaoAte) {
    filter += `${filter.length ? ', ' : ''}Data da Recolha/Devolução ${moment((values || {}).dataRecolhaDevolucaoDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataRecolhaDevolucaoDe,
    ).format('DD-MM-YYYY')}`;
  }
  if ((values || {}).dataRecolhaDevolucaoDe && (values || {}).dataRecolhaDevolucaoAte) {
    filter += `${filter.length ? ', ' : ''}Data da Recolha/Devolução ${moment((values || {}).dataRecolhaDevolucaoDe).format('DD-MM-YYYY')} até ${moment(
      (values || {}).dataRecolhaDevolucaoAte,
    ).format('DD-MM-YYYY')}`;
  }

  if (!filter.length) {
    filter += `Data do Pedido entre ${moment(new Date().getTime() - 1000 * 60 * 60 * 24 * 7).format('DD-MM-YYYY')} e ${moment().format('DD-MM-YYYY')}`;
  }
  return `Filtrado por ${filter}`;
}
