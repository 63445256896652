import { NumberBox } from 'devextreme-react';
import { loadMessages, locale } from 'devextreme/localization';
import ptMessages from 'devextreme/localization/messages/pt.json';
import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './totais.module.scss';

export function Totais() {
  loadMessages(ptMessages);
  locale(navigator.language);
  const { values } = useSelector(state => state.novaOs);
  return (
    <div className="mr-3">
      <div className="text-center">
        <h1>TOTAIS</h1>
      </div>
      <hr />
      <div className={`${''}`}>
        <NumberBox
          label="TOTAL VENDAS"
          readOnly={true}
          className={`${styles.currency} currency`}
          format="R$ #,##0.00"
          height={45}
          defaultValue={0}
          value={values.total}
        ></NumberBox>
      </div>
      {(values.pedidos || []).find(x => (x.tipoPedido || {}).id === 8) && (
        <div>
          <NumberBox
            label="TOTAL LOCAÇÃO"
            readOnly={true}
            className={`${styles.currency} currency`}
            height={50}
            format="R$ #,##0.00"
            defaultValue={0}
            value={_.sumBy(values.pedidos || [], x => ((x.tipoPedido || {}).id === 8 ? x.totalAtivos : 0))}
          ></NumberBox>
        </div>
      )}
      {(values.pedidos || []).find(x => (x.tipoPedido || {}).id === 2) && (
        <div>
          <NumberBox
            label="TOTAL CONSIGNADO"
            readOnly={true}
            className={`${styles.currency} currency`}
            height={50}
            format="R$ #,##0.00"
            defaultValue={0}
            value={_.sumBy(values.pedidos || [], x => ((x.tipoPedido || {}).id === 2 ? x.totalProdutos : 0))}
          ></NumberBox>
        </div>
      )}
      { /*a validação é com o 2 para exibição, já que o normal é depois do 2 ter o 4*/ }
      {(values.pedidos || []).find(x => (x.tipoPedido || {}).id === 2) && (
        <div>
          <NumberBox
            label="CONSIGNADO RETORNADO"
            readOnly={true}
            className={`${styles.currency} currency`}
            height={50}
            format="R$ #,##0.00"
            defaultValue={0}
            value={_.sumBy(values.pedidos || [], x => {
              if ((x.tipoPedido || {}).id === 4) {
                return _.sumBy(x.itens || [], item => item.valorTotalRetornado);
              } else {
                return 0;
              }
            })}
          ></NumberBox>
        </div>
      )}
      {(values.pedidos || []).find(x => (x.tipoPedido || {}).id === 6) && (
        <div>
          <NumberBox
            label="CONSIGNADO VENDIDO"
            readOnly={true}
            className={`${styles.currency} currency`}
            height={50}
            format="R$ #,##0.00"
            defaultValue={0}
            value={_.sumBy(values.pedidos || [], x => ((x.tipoPedido || {}).id === 6 ? x.totalProdutos : 0))}
          ></NumberBox>
        </div>
      )}
      <div>
        <NumberBox
          label="ACRÉSCIMO"
          readOnly={true}
          className={`${styles.currency} currency`}
          format="R$ #,##0.00"
          height={45}
          defaultValue={0}
          value={values.acrescimo}
        ></NumberBox>
      </div>
      <div>
        <NumberBox
          label="DESCONTO"
          readOnly={true}
          className={`${styles.currency} currency`}
          height={45}
          format="R$ #,##0.00"
          defaultValue={0}
          value={values.desconto}
        ></NumberBox>
      </div>
      <div>
        <NumberBox
          label="FRETE"
          readOnly={true}
          className={`${styles.currency} currency`}
          height={45}
          format="R$ #,##0.00"
          defaultValue={0}
          value={values.valorFrete || 0}
        ></NumberBox>
      </div>
      <div>
        <NumberBox
          label="RECEBIDO"
          readOnly={true}
          className={`${styles.currency} currency`}
          height={50}
          format="R$ #,##0.00"
          defaultValue={0}
          value={values.recebido}
        ></NumberBox>
      </div>
      <div>
        <NumberBox
          label="A RECEBER"
          readOnly={true}
          className={`${styles.currency} currency`}
          height={50}
          format="R$ #,##0.00"
          defaultValue={0}
          value={values.aReceber || 0}
        ></NumberBox>
      </div>
      <hr />
      <div>
        <NumberBox
          label="TOTAL GERAL"
          readOnly={true}
          className={`${styles.currency} currency`}
          height={50}
          format="R$ #,##0.00"
          defaultValue={0}
          value={
            values.totalGeral
          }
        ></NumberBox>
      </div>
    </div>
  );
}
