import React from "react";
import { withRouter } from "react-router-dom";
import {
  InputText,
  handleInputChange
} from '../../../components/formulario/Formulario';

import {
  SectionContent
} from '../../../components/section/Content';

import {
  excluiEstruturaMercadologica,
  incluiEstruturaMercadologica,
  alteraEstruturaMercadologica
} from "../../../services/EstruturaMercadologica.service";
import { Nivel } from "./EstruturaMercadologica";
import {
  validaDadosLogin,
  menuPermiteAlteracao,
  menuPermiteExclusao,
  getNomePagina
} from '../../../shared/utils/Utils';
import { menus } from "../../../shared/constants/MenuConstants";
import {
  tagAlert,
  showQuestion,
  showWait,
  showInfo,
  showDeleteError,
  showError,
  showSaveError,
  hideAlert
} from "../../../components/alert/Alert";
import Footer from "../../../components/core/footer/Footer";
import LinxPostos from "../../../components/core/linxPostos/LinxPostos";

const PATH_MENU = menus.COD_60440;
const titulo = "Estrutura mercadológica";
const MAIN_PATH = "/cadastro/estrutura-mercadologica";

class FormEstruturaMercadologica extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = handleInputChange.bind(this);

    let codigo = null,
      codigoPai = null,
      descricao = "",
      descricaoPai = "",
      tituloCodigoPai = "",
      tituloDescPai = "",
      rowVersion = null;

    if (this.props.insert) {
      codigo = null;
      codigoPai = this.props.estrutura.codigo;
      descricao = "";
      descricaoPai = this.props.estrutura.descricao;
      rowVersion = null;
    } else {
      codigo = this.props.estrutura.codigo;
      codigoPai = this.props.estrutura.codigoPai;
      descricao = this.props.estrutura.descricao;
      descricaoPai = "";
      rowVersion = this.props.estrutura.rowVersion;
    }

    const { nivel = Nivel.None, nivelPai = Nivel.None } = this.props;

    switch (this.props.nivelPai) {
      case Nivel.Grupo:
        tituloCodigoPai = "Código do grupo:";
        tituloDescPai = "Descricação do grupo:";
        break;
      case Nivel.SubGrupo:
        tituloCodigoPai = "Código do sub-grupo";
        tituloDescPai = "Descrição do sub-grupo";
        break;
      default:
        break;
    }

    this.state = {
      codigo,
      codigoPai,
      descricao,
      descricaoPai,
      tituloCodigoPai,
      tituloDescPai,
      rowVersion,
      nivel,
      nivelPai,

      alertActive: false,
      alertType: "",
      alertTitle: "",
      alertSubtitle: ""
    };
  }

  isFormValid() {
    const { codigo, codigoPai, descricao } = this.state;

    const { nivel } = this.props;

    if (!descricao) {
      showError(this, titulo, "A descrição não pode ficar vazia");
      return false;
    }

    let result = true;
    this.props.estruturasExistentes.forEach(e => {
      if (nivel === Nivel.Grupo) {
        if (e.codigo !== codigo && !e.codigoPai && e.descricao === descricao) {
          showError(this, titulo,
            "A descrição escolhida já existe num outro grupo"
          );
          result = false;
        }
      } else {
        // Nivel.SubGrupo ou Nivel.Artigo
        if (
          e.codigo !== codigo &&
          e.codigoPai === codigoPai &&
          e.descricao === descricao
        ) {
          nivel === Nivel.SubGrupo
            ? showError(this, titulo,
              "A descrição escolhida já existe " +
              "num outro subgrupo desse mesmo grupo"
            )
            : showError(this, titulo,
              "A descrição escolhida já existe " +
              "num outro artigo desse mesmo subgrupo"
            );

          result = false;
        }
      }
    });
    return result;
  }

  handleSalvar = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }

    if (!this.isFormValid()) {
      return;
    }

    let { codigo, codigoPai, descricao, nivel, rowVersion } = this.state;

    const [action, params] =
      rowVersion === null
        ? [
          incluiEstruturaMercadologica,
          [codigoPai, descricao, nivel - 1]
        ]
        : [
          alteraEstruturaMercadologica,
          [codigo, codigoPai, descricao, nivel - 1, rowVersion]
        ];

    try {
      showWait(this, titulo, "Por favor, aguarde enquanto o registro é salvo");
      await action(...params);
      const msg = rowVersion ?
        "Estrutura mercadológica alterada com sucesso!" :
        "Estrutura mercadológica incluída com sucesso!";
      showInfo(this, titulo, msg, null, () => {
        this.props.history.push(MAIN_PATH);
      });
    } catch (err) {
      showSaveError(this, titulo, err);
    }
  };

  handleExcluir = async () => {
    if (!validaDadosLogin()) {
      this.props.history.push("/");
    }

    showQuestion(this, titulo, "Deseja excluir a estrutura?", null,
      async (resp) => {
        if (resp) {
          try {
            showWait(this, titulo,
              "Por favor, aguarde enquanto o registro é excluído");
            await excluiEstruturaMercadologica(this.state.codigo);
            showInfo(this, titulo, "Estrutura excluída com sucesso!", null,
              () => {
                this.props.history.push(MAIN_PATH);
              }
            );
          } catch (err) {
            showDeleteError(this, titulo, err);
          }
        } else {
          hideAlert(this);
        }
      })
  };

  handleCancelar = () => {
    showQuestion(
      this,
      titulo,
      "Deseja realmente cancelar a operação?",
      null,
      (resp) => {
        if (resp) {
          hideAlert(this);
          this.props.history.push(MAIN_PATH);
        } else {
          hideAlert(this);
        }
      }
    );
  };

  componentDidMount() {
    let { codigoPai, descricaoPai, nivel } = this.state;

    if (nivel === Nivel.None) {
      this.props.history.push(MAIN_PATH);
    }

    this.props.estruturasExistentes.forEach(e => {
      if (codigoPai && codigoPai === e.codigo) {
        descricaoPai = e.descricao;
        return;
      }
    });

    this.setState({ descricaoPai });
  }

  canDelete() {
    const { codigo, rowVersion } = this.state;

    if (!rowVersion) {
      return false; // está inserindo um novo
    }

    if (!menuPermiteExclusao(PATH_MENU)) return false;

    let result = true;
    this.props.estruturasExistentes.forEach(e => {
      if (e.codigoPai === codigo) {
        // possui subgrupo ou artigo vinculado
        result = false;
        return;
      }
    });
    return result;
  }

  render() {
    const {
      codigo,
      codigoPai,
      descricao,
      descricaoPai,
      tituloCodigoPai,
      tituloDescPai,
      nivel,
      nivelPai,
      rowVersion
    } = this.state;

    return (
      <>
        <LinxPostos breadcrumb={getNomePagina(PATH_MENU)}>
          <SectionContent title="Estrutura mercadológica">
            <div className="row">
              <div className="col-5">
                <InputText
                  label="Descrição:"
                  value={descricao}
                  name="descricao"
                  onChange={this.handleInputChange}
                  maxlength={65}
                />
              </div>
              {rowVersion ? (
                <div className="col-5">
                  <InputText
                    label="Código:"
                    value={codigo}
                    name="codigo"
                    onChange={this.handleInputChange}
                    maxlength={20}
                    disabled
                  />
                </div>
              ) : (
                  <></>
                )}
            </div>
            {nivelPai === Nivel.Raiz ||
              (!this.state.insert && nivel === Nivel.Grupo) ? (
                <></>
              ) : (
                <div className="row">
                  <div className="col-5">
                    <InputText
                      label={tituloCodigoPai}
                      value={codigoPai}
                      name="codigoPai"
                      onChange={this.handleInputChange}
                      maxlength={20}
                      disabled
                    />
                  </div>
                  <div className="col-5">
                    <InputText
                      label={tituloDescPai}
                      value={descricaoPai}
                      name="descricaoPai"
                      onChange={this.handleInputChange}
                      maxlength={65}
                      disabled
                    />
                  </div>
                </div>
              )}
          </SectionContent>
        </LinxPostos>

        <Footer
          saveAction={
            rowVersion !== null && !menuPermiteAlteracao(PATH_MENU)
              ? null
              : this.handleSalvar
          }
          deleteAction={this.canDelete() ? this.handleExcluir : null}
          cancelAction={this.handleCancelar}
        />
        {tagAlert(this)}
      </>
    );
  }
}

FormEstruturaMercadologica = withRouter(FormEstruturaMercadologica);
export { FormEstruturaMercadologica };
