import { TextBox } from 'devextreme-react';
import React from 'react';

export default function SearchHeader(props) {

    const onKeyPress = (e) => {
        let keyCode = e.event.keyCode;
        if(keyCode === 13) props.load(props.searchText);
    }

    return <div className="col-6">
    <TextBox
        placeholder="Buscar"
        showClearButton={true}  
        onKeyPress={onKeyPress}       
        valueChangeEvent="keyup"     
        value={props.searchText}
        onValueChanged={e => {
            props.setarValorCampoBusca(e.value);
        }}
        mode="search"
    />
  </div>
}

