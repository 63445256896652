import { CharToInt } from './utils';
export function CheckIEMG(ie) {
  if (ie.substring(0, 2) === 'PR') return false;
  if (ie.substring(0, 5) === 'ISENT') return false;
  if (ie.length !== 13) return false;
  var dig1 = ie.substring(11, 12);
  var dig2 = ie.substring(12, 13);
  var inscC = ie.substring(0, 3) + '0' + ie.substring(3, 11);
  var insc = inscC.split('');
  var npos = 11;
  var i = 1;
  var ptotal = 0;
  var psoma = 0;
  while (npos >= 0) {
    i++;
    psoma = CharToInt(insc[npos]) * i;
    if (psoma >= 10) psoma -= 9;
    ptotal += psoma;
    if (i === 2) i = 0;
    npos--;
  }
  var nresto = ptotal % 10;
  if (nresto === 0) nresto = 10;
  nresto = 10 - nresto;
  if (nresto !== CharToInt(dig1)) return false;
  npos = 11;
  i = 1;
  ptotal = 0;
  var is = ie.split('');
  while (npos >= 0) {
    i++;
    if (i === 12) i = 2;
    ptotal += CharToInt(is[npos]) * i;
    npos--;
  }
  nresto = ptotal % 11;
  if (nresto === 0 || nresto === 1) nresto = 11;
  nresto = 11 - nresto;
  return nresto === CharToInt(dig2);
}
